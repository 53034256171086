import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { lightTableTheme } from '../../../config/tableStyles';

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  height: 100%;

  h2 {
	  font-size: 16px;
    font-weight: 700;	
  }
	

  .rdt_TableRow{
	font-size:0.90rem;  
  }
  
  .rdt_TableHeadRow .rdt_TableCol{
	 font-weight:600;
  }
  

  div[role='rowgroup'] div[role='row'] div[role='gridcell']:first-child {
    text-align: left;
  }

   .rdt_TableCell{
	font-size: 0.9rem; 
 }

  .rdt_TableHeadRow .rdt_TableCol {
  
	padding: 8px;
  }

   .rdt_TableHeadRow {
   padding-right: 14px;
 }

/* Works on Firefox */
.rdt_Table {
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.tableColor1} #fff;
}


.rdt_TableBody::-webkit-scrollbar {
    width: 14px;
}

.rdt_TableBody::-webkit-scrollbar-track {
	background: ${({ theme }) => theme.primary};
    border-left: 6px solid white;
    border-right: 7px solid white;
	
}

.rdt_TableBody::-webkit-scrollbar-thumb {
    box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme }) => theme.primary} !important; 
}
`;

const BasketOverviewTable = ({ data, keys, isRollingDateRange }) => {
  const columns = [
    {
      selector: ((data[0]?.field) ? 'field' : 'productDesc'),
      name: keys?.field ?? 'ProductDesc',
      sortable: true,
      wrap: true,
    },
    {
      selector: 'valueTY',
      name: keys?.valueTY ?? `Value ${isRollingDateRange ? '- Current' : 'This Year'}`,
      sortable: true,

      sortFunction: (rowA, rowB) =>
        parseInt(rowA.valueTY.replace(',', '').replace('£', '')) -
        parseInt(rowB.valueTY.replace(',', '').replace('£', '')),
    },

    {
      selector: 'valueLY',
      name: keys?.valueLY ?? `Value ${isRollingDateRange ? '- Previous' : 'Last Year'}`,
      sortable: true,

      sortFunction: (rowA, rowB) =>
        parseInt(rowA.valueLY.replace(',', '').replace('£', '')) -
        parseInt(rowB.valueLY.replace(',', '').replace('£', '')),
    },

    {
      selector: 'change',
      name: keys?.change ?? 'Change',
      sortable: true,

      sortFunction: (rowA, rowB) =>
        rowA.change.replace('%', '') - rowB.change.replace('%', ''),
      cell: row => (
        <div
          className={
            row.change.replace('%', '') > 0 ? 'pos-change' : 'neg-change'
          }
          style={{ width: '5rem', color: '#fff', padding: '.2rem' }}>{row.change}
        </div>
      ),
    },
  ];

  return (
    <StyledCard>
      <DataTable
        data={data}
        columns={columns}
        customStyles={lightTableTheme}
        striped
        defaultSortField={'ValueTY'}
        defaultSortAsc={false}
        highlightOnHover
        noHeader
        sortIcon={<ArrowDownwardIcon />}
      />
    </StyledCard>
  );
};

export default BasketOverviewTable;
