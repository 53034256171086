import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import ScrollArea from 'react-scrollbar';
import { CircularProgressbar } from 'react-circular-progressbar';
import savingGif from '../../assets/images/saving.gif';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from 'react-dialog';
import 'react-dialog/css/index.css';
import {
  selectors,
  fetchWholesalers,
  clearProduct,
  fetchDefaults,
  setDepartment,
  setCategory,
  setSubCategory,
  setBrand,
  setLevel4,
  setLevel5,
  setSupplier,
  searchMaster,
  searchAllMaster,
  searchMasterCodes,
  setMasterCodeText,
  setMasterCode,
  setEditProduct,
  selectMaster,
  selectFilter,
  addFilterData,
  saveData,
  exportData,
  removeFilterData,
} from '../redux/reducers/admin';
import AlchContextMenu from '../components/AlchUI/AlchContextMenu';
import TableWholeview from '../components/TableWholeview';
import Autocomplete from 'react-autocomplete';
import ReactModal from 'react-modal';
import AddProduct from '../components/modals/AddProduct';

const ClassificationPage = ({
  data,
  wholesalers,
  wholeSaler,
  departments,
  product_department,
  department,
  categories,
  product_categories,
  category,
  subcategories,
  product_subcategories,
  subcategory,
  brands,
  product_brands,
  brand,
  level4s,
  product_level4s,
  level4,
  level5s,
  product_level5s,
  level5,
  textPercentage,
  suppliers,
  product_suppliers,
  supplier,
  filterData,
  masterData,
  mastercodes,
  masterCode,
  masterCodeText,
  selectedData,
  onBulkCleansingClick,
  onSelectedFilter,
  onSelectedMaster,
  onSearchMaster,
  onSearchAllMaster,
  onSearchMasterCodes,
  onAddFilterData,
  onRemoveFilterData,
  handleOpenAddProductModal,
  handleCloseProductModal,
  handleOpenEditProductModal,
  handleOpenEntityModal,
  handleCloseEntityModal,
  handleOpenSupplierModal,
  handleCloseSupplierModal,
  onSetMasterCode,
  onSubmit,
  onChange,
  onEnterPress,
  onWholeviewChange,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onBrandChange,
  onLevel4Change,
  onLevel5Change,
  onSupplierChange,
  onExportData,
  onValid,
  isLoading,
}) => (
  <div className="o-canvas-wrap">
    {isLoading === true ? <img src={savingGif} style={{ position: 'absolute', margin: '0px auto', top: '30%', left: '45%', width: '150px', zIndex: '10000' }} /> : ''}
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout">
          <h2 className="o-canvas__title u-100">Wholeview Management</h2>
          <div className="c-select-wrap-component c-select-header-row--green">
            <Select style={{ minWidth: '200px' }} className="c-select-header" name="wholesaler" id="wholesaler" value={wholeSaler.ID || ''} onChange={onWholeviewChange} options={wholesalers} valueKey="wholeviewId" labelKey="wholeviewName" placeholder="Select Wholeview" />
          </div>
          <div className="u-layout--start">
            <CircularProgressbar percentage={textPercentage} strokeWidth={5} className="circle-progress" textForPercentage={(percentage) => `${percentage}%`} />
          </div>
        </div>
        <div className="o-canvas__header-item">
          <div className="u-right">
            <AlchContextMenu label="Export" onSelect={onExportData}>
              {[
                { id: 'csv', label: 'CSV' },
                { id: 'category_file', label: 'Category File' },
                { id: 'level', label: 'Export Level 1 & 2' },
                { id: 'file', label: 'Export Cleansing File' },
                { id: 'coreRange', label: 'Export Core Range' },
              ]}
            </AlchContextMenu>
            <button onClick={onBulkCleansingClick} style={{ marginLeft: '10px' }}>
              Bulk Cleansing
            </button>
          </div>
        </div>
      </div>
      <div className="o-canvas__header-search u-layout">
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Department</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="department" id="department" value={department ? department.ID : ''} onChange={onDepartmentChange} options={departments} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Category</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="category" id="category" value={category ? category.ID : ''} onChange={onCategoryChange} options={categories} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Sub Category</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="subcategory" id="subcategory" value={subcategory ? subcategory.ID : ''} onChange={onSubCategoryChange} options={subcategories} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Level 4</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="level4" id="level4" value={level4 ? level4.ID : ''} onChange={onLevel4Change} options={level4s} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Level 5</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="level5" id="level5" value={level5 ? level5.ID : ''} onChange={onLevel5Change} options={level5s} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Brand</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="brand" id="brand" value={brand ? brand.ID : ''} onChange={onBrandChange} options={brands} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <h3 className="o-canvas__search-title">Supplier</h3>
          <Select style={{ minWidth: '100px' }} className="c-select-header c-select-header-row--green" name="supplier" id="supplier" value={supplier ? supplier.ID : ''} onChange={onSupplierChange} options={suppliers} valueKey="ID" labelKey="Text" placeholder="Select or Search" />
        </div>
        <div className="u-1_8">
          <div className="t-brand-c t-brand-c--search">
            <button onClick={onSearchAllMaster}>Search All</button>
          </div>
        </div>
      </div>
      <div className="o-canvas__body u-layout u-layout--spaced t-brand-a">
        <div className="u-1_6-8 o-results" style={{ margin: '0px', height: '100%' }}>
          <div className="o-results__input">
            <div className="o-results__input-wrapper o-results__input--left">
              <div className="u-1_100">
                <div className="u-input-group">
                  <label className="fa fa-search o-results__input-icon"></label>
                  <input type="text" placeholder="Search by keyword or press Enter top 20 records" onKeyPress={onEnterPress} onChange={onSearchMaster} />
                </div>
              </div>
            </div>
            <TableWholeview id="wholeview_master_table" data={masterData} selectedData={selectedData} onSelectedRow={onSelectedMaster} />
          </div>
        </div>
        <div className="u-1_2-8" style={{ height: '100%', margin: '0px' }}>
          <div className="o-results__buttons" style={{ height: '500px' }}>
            <a className="o-arrow__right" onClick={onAddFilterData}>
              Add
            </a>
            <a className="o-arrow__left" onClick={onRemoveFilterData}>
              Remove
            </a>
          </div>
        </div>
        <div className="u-1_6-8 o-results" style={{ height: '100%', margin: '0px' }}>
          <div className="o-results__input">
            <div className="o-results__input-wrapper">
              <div className="u-1_100">
                <div className="u-input-group">
                  <label className="fa fa-search o-results__input-icon"></label>
                  <Autocomplete
                    wrapperStyle={{ width: '100%', display: 'inline-block' }}
                    getItemValue={(item) => item.WVProdCode}
                    items={mastercodes}
                    placeholder="Search for Parent Product by Keywords or Id"
                    inputProps={{ placeholder: 'Search for Parent Product by Keywords or Id' }}
                    renderItem={(item, isHighlighted) => (
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.WVProdCode}>
                        {item.WVProdDesc}
                      </div>
                    )}
                    renderMenu={(items, value) => {
                      return (
                        <div
                          style={{
                            zIndex: '1000',
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 0.9)',
                            padding: '2px 0',
                            fontSize: '90%',
                            position: 'fixed',
                            overflow: 'auto',
                            marginTop: '50px',
                            maxHeight: '50%',
                          }}
                          children={items}
                        ></div>
                      );
                    }}
                    value={masterCodeText}
                    onChange={onSearchMasterCodes}
                    onSelect={onSetMasterCode}
                  />
                  <span className="u-input-group-btn">
                    <div className="t-plus-button">
                      <button onClick={handleOpenAddProductModal}></button>
                    </div>
                    <div className="t-brand-c">
                      <button onClick={handleOpenEditProductModal}>Edit</button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*


                            <ReactModal isOpen={data.showProductModal}  contentLabel="Minimal Modal Example">
                               <AddProduct wholeSaler={wholeSaler}
                                           masterCode={masterCode}
                                           departments={departments}
                                           department={department}
                                           categories={categories}
                                           category={category}
                                           subcategories={subcategories}
                                           subcategory={subcategory}
                                           brands={brands}
                                           brand={brand}
                                           suppliers={suppliers}
                                           isAddProduct={data.isAddProduct}
                                           closeModal={handleCloseProductModal}
                                           supplier={supplier}/>
                               <button onClick={handleCloseProductModal}>Close Modal</button>
                             </ReactModal>*/}
          {data.showProductModal && (
            <Dialog
              title="Product"
              width={1200}
              height={650}
              style={{ top: '10px' }}
              isResizable={true}
              modal={false}
              allowMinimize={true}
              isDraggable={true}
              onClose={handleCloseProductModal}
              buttons={[
                {
                  text: 'Close',
                  onClick: () => handleCloseProductModal(),
                },
              ]}
            >
              <AddProduct
                wholeSaler={wholeSaler}
                masterCode={masterCode}
                departments={departments}
                product_department={product_department}
                department={department}
                categories={categories}
                product_categories={product_categories}
                category={category}
                subcategories={subcategories}
                product_subcategories={product_subcategories}
                subcategory={subcategory}
                brands={brands}
                product_brands={product_brands}
                brand={brand}
                level4s={level4s}
                product_level4s={product_level4s}
                level4={level4}
                level5s={level5s}
                product_level5s={product_level5s}
                level5={level5}
                suppliers={suppliers}
                product_suppliers={product_suppliers}
                isAddProduct={data.isAddProduct}
                closeModal={handleCloseProductModal}
                supplier={supplier}
              />
            </Dialog>
          )}
          <TableWholeview id="wholeview_filter_table" data={filterData} selectedData={selectedData} onSelectedRow={onSelectedFilter} />
        </div>
      </div>
      <div className="o-block t-brand-c u-right" style={{ textAlign: 'center', margin: '0px' }}>
        <button style={{ width: '300px', margin: '0px auto' }} onClick={onSubmit}>
          Save
        </button>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    wholesalers: state.admin.wholesalers,
    wholeSaler: state.admin.wholeSaler,
    departments: state.admin.departments,
    product_department: state.admin.product_department,
    department: state.admin.department,
    categories: state.admin.categories,
    product_categories: state.admin.product_categories,
    category: state.admin.category,
    subcategories: state.admin.subcategories,
    level4s: state.admin.level4s,
    level5s: state.admin.level5s,
    product_subcategories: state.admin.product_subcategories,
    brands: state.admin.brands,
    product_level4s: state.admin.product_level4s,
    product_level5s: state.admin.product_level5s,
    subcategory: state.admin.subcategory,
    brand: state.admin.brand,
    level4: state.admin.level4,
    level5: state.admin.level5,
    suppliers: state.admin.suppliers,
    product_suppliers: state.admin.product_suppliers,
    supplier: state.admin.supplier,
    filterData: state.admin.filterData,
    masterData: state.admin.masterData,
    selectedData: state.admin.selectedData,
    mastercodes: state.admin.mastercodes,
    masterCode: state.admin.masterCode,
    masterCodeText: state.admin.masterCodeText,
    textPercentage: state.admin.textPercentage,
    error: state.admin.error,
    message: state.admin.message,
    isLoading: state.admin.isLoading,
    isProductModified: state.admin.isProductModified,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchWholesalers,
    fetchDefaults,
    setDepartment,
    setCategory,
    setSubCategory,
    setBrand,
    setLevel4,
    setLevel5,
    setSupplier,
    searchMasterCodes,
    setMasterCodeText,
    setEditProduct,
    searchMaster,
    searchAllMaster,
    selectMaster,
    selectFilter,
    addFilterData,
    removeFilterData,
    clearProduct,
    saveData,
    exportData,
    setMasterCode,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchWholesalers();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isLoading === false && this.props.isLoading === true) {
        toast.info('Processing, Please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (prevProps.isLoading === true && this.props.isLoading === false && this.props.error.length > 0) {
        toast.dismiss();
        toast.info(this.props.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (prevProps.isLoading === true && this.props.isLoading === false && this.props.message.length > 0) {
        toast.dismiss();
        toast.info(this.props.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (prevProps.isProductModified === false && this.props.isProductModified === true) {
        this.props.fetchWholesalers();
      }
    },
  }),
  withState('data', 'handleData', {
    showProductModal: false,
    showDepartmentModal: false,
    showCategoryModal: false,
    showSubCategoryModal: false,
    showSupplierModal: false,
    isAddProduct: false,
  }),
  withHandlers({
    onChange: ({ data, handleData, fetchDefaults: getDefaultData, setDepartment, setCategory, setSubCategory, setSupplier, searchMasterCodes, searchMaster }) => (target) => {
      const { name, value } = target;
      if (name === 'wholesaler') {
        getDefaultData(value);
      } else if (name === 'department') {
        setDepartment(value);
      } else if (name === 'category') {
        setCategory(value);
      } else if (name === 'subcategory') {
        setSubCategory(value);
      } else if (name === 'supplier') {
        setSupplier(value);
      }
      handleData({ ...data, [name]: value });
    },
    onWholeviewChange: ({ data, handleData, fetchDefaults }) => (selectedOption) => {
      if (selectedOption === null) {
        fetchDefaults(0);
      } else {
        fetchDefaults(selectedOption.wholeviewId);
      }
    },
    onBulkCleansingClick: ({ data, handleData, history }) => () => {
      history.push(`${process.env.APP_ROOT}admin/bulk-cleansing`);
    },
    onDepartmentChange: ({ data, handleData, setDepartment }) => (selectedOption) => {
      if (selectedOption === null) {
        setDepartment(0);
      } else {
        setDepartment(selectedOption.ID);
      }
    },
    onCategoryChange: ({ data, handleData, setCategory }) => (selectedOption) => {
      if (selectedOption === null) {
        setCategory(0);
      } else {
        setCategory(selectedOption.ID);
      }
    },
    onSubCategoryChange: ({ data, handleData, setSubCategory }) => (selectedOption) => {
      if (selectedOption === null) {
        setSubCategory(0);
      } else {
        setSubCategory(selectedOption.ID);
      }
    },
    onBrandChange: ({ data, handleData, setBrand }) => (selectedOption) => {
      if (selectedOption === null) {
        setBrand(0);
      } else {
        setBrand(selectedOption.ID);
      }
    },
    onLevel4Change: ({ data, handleData, setLevel4 }) => (selectedOption) => {
      if (selectedOption === null) {
        setLevel4(0);
      } else {
        setLevel4(selectedOption.ID);
      }
    },
    onLevel5Change: ({ data, handleData, setLevel5 }) => (selectedOption) => {
      if (selectedOption === null) {
        setLevel5(0);
      } else {
        setLevel5(selectedOption.ID);
      }
    },
    onSupplierChange: ({ data, handleData, setSupplier }) => (selectedOption) => {
      if (selectedOption === null) {
        setSupplier(0);
      } else {
        setSupplier(selectedOption.ID);
      }
    },
    onValid: ({ data, handleData }) => (value) => {
      handleData({ ...data, invalid: false });
    },
    onSelectedMaster: ({ data, handleData, selectMaster }) => (event) => {
      selectMaster(event.target.id);
    },
    onSelectedFilter: ({ data, handleData, selectFilter }) => (event) => {
      selectFilter(event.target.id);
    },
    onRemoveFilterData: ({ data, handleData, removeFilterData }) => (value) => {
      removeFilterData();
    },
    onAddFilterData: ({ data, handleData, addFilterData }) => (value) => {
      addFilterData();
    },
    onSearchMasterCodes: ({ data, handleData, searchMasterCodes, setMasterCodeText }) => (event) => {
      let searchFor = event.target.value;
      setMasterCodeText(searchFor);
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (searchFor.length > 3) {
          searchMasterCodes(searchFor);
        }
      }, 300);
    },
    onSetMasterCode: ({ data, handleData, setMasterCode, mastercodes }) => (val) => {
      _.each(mastercodes, (code) => {
        if (code.WVProdCode === val) {
          setMasterCode(code);
        }
      });
    },
    handleOpenAddProductModal: ({ data, handleData, clearProduct }) => (val) => {
      if (!data.isAddProduct) {
        clearProduct();
      }
      handleData({ ...data, showProductModal: true, isAddProduct: true });
    },
    handleCloseProductModal: ({ data, handleData }) => (val) => {
      handleData({ ...data, showProductModal: false });
    },
    handleOpenEditProductModal: ({ data, handleData, setEditProduct }) => (val) => {
      handleData({ ...data, showProductModal: true, isAddProduct: false });
      setEditProduct();
    },
    handleOpenEntityModal: ({ data, handleData }) => (val) => {
      handleData({ ...data, showEntityModal: true });
    },
    handleCloseEntityModal: ({ data, handleData }) => (val) => {
      handleData({ ...data, showEntityModal: false });
    },
    handleOpenSupplierModal: ({ data, handleData }) => (val) => {
      handleData({ ...data, showSupplierModal: true });
    },
    handleCloseSupplierModal: ({ data, handleData }) => (val) => {
      handleData({ ...data, showSupplierModal: false });
    },
    onExportData: ({ data, handleData, exportData }) => (opt) => {
      exportData(opt);
    },
    onEnterPress: ({ data, handleData, searchMaster }) => (event) => {
      if (event.key === 'Enter') {
        searchMaster('');
      }
    },
    onSearchMaster: ({ data, handleData, searchMaster }) => (event) => {
      let searchFor = event.target.value;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (searchFor.length > 3) {
          searchMaster(searchFor);
        }
      }, 300);
    },
    onSearchAllMaster: ({ data, handleData, searchAllMaster }) => (event) => {
      searchAllMaster();
    },
    onSubmit: ({ data, handleData, saveData }) => (event) => {
      event.preventDefault();
      saveData();
    },
  }),
)(ClassificationPage);
