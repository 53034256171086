import { createGlobalStyle } from 'styled-components';
import theme from '../../config/Themes.js';

const GlobalStyle = createGlobalStyle`
#chartjs-tooltip {
  border-top: 4px solid ${theme.primary};
  color: ${theme.primary};
}

.tippy-tooltip.dark-theme > .tippy-tooltip-content, .tippy-tooltip > .tippy-tooltip-content {
  background-color: #fff !important;
  color: ${theme.primary} !important;
  border: 0px;
  border-top: 4px solid ${theme.primary};
  padding: 2rem;
}

 .ReactModal__Body--open .themed-wrapper {
   filter: blur(8px);
   background: transparent 0% 0% no-repeat padding-box;
 }

  .ReactModal__Body--open .themed-wrapper.dashboard-wrapper {
   filter: initial;
 }
`;

export default GlobalStyle;
