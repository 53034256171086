import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import Npd from '../models/NPD';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';
import { stringify } from 'qs';
import { debug } from 'util';

const SET_IS_NPD_CREATED = 'pfpNpd/IS_NPD_CREATED';
const SET_IS_NPD_DELETED = 'pfpNpd/IS_NPD_DELETED';
const SET_NPD = 'pfpNpd/SET_NPD';
const FETCH_NPDS = 'pfpNpd/FETCH_NPDS';
const SET_LOADING = 'pfpNpd/SET_LOADING';
const SET_DEPARTMENT = 'pfpNpd/SET_DEPARTMENT';
const SET_CATEGORY = 'pfpNpd/SET_CATEGORY';
const FETCH_CATEGORIES = 'pfpNpd/FETCH_CATEGORIES';
const SET_FORM = 'pfpNpd/SET_FORM';
const RESET_ME = 'pfpNpd/RESET_ME';

const baseURL = 'https://api.wsale.co.uk';

const initialState = {
  isLoading: false,
  department: '',
  departments: [],
  category: '',
  categories: [],
  isNpdCreated: false,
  isNpdDeleted: false,
  npds: [],
  npd:Npd,
  npdForm: {...Npd}
  };
  

const setNpdAction = createAction(SET_NPD);
const fetchNpdsAction = createAction(FETCH_NPDS);
const setIsNpdCreatedAction = createAction(SET_IS_NPD_CREATED);
const setIsNpdDeletedAction = createAction(SET_IS_NPD_DELETED);
const setLoadingAction = createAction(SET_LOADING);

const setDepartmentAction = createAction(SET_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const fetchCategoriesAction = createAction(FETCH_CATEGORIES);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const fetchCategories = () => (dispatch) => {
    dispatch(setLoadingAction(true));
    let url = '/route.php?c=pfp/npd/getCategoryStructure';
    customRequest({url: baseURL + url,
            method: 'get',
            headers: {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization': getToken(),
                    'UserId': getUserId(),
                    'System': getUserSystem()
            }
    }).then((response) => {
      dispatch(setLoadingAction(false));
      const departments =  [];
      const categories =  [];
      if (response.Departments ) {
          _.each(response.Departments, (department) => {
			  departments.push({'ID' : department.Name, 'Text' : department.Name, categories: department.Categories});
			  if (department.Name == store.getState().pfpDropship.department.ID) {
				_.each(department.Categories, (category) => {
					categories.push({'ID': category.Name, 'Text': category.Name});
				});
			}
          })
      }
      dispatch(fetchCategoriesAction({departments, categories}));
    });
}

export const onDepartmentChange = (department) => (dispatch) => {
    const categories = [];
    if(department && department.categories){
        _.each(department.categories, (category) => {
            categories.push({'ID': category.Name, 'Text': category.Name});
        });
    }
    dispatch(setDepartmentAction({department, categories}));
}


export const onCategoryChange = (category) => (dispatch) => {
    if( category ) {
        category = category.ID;
    } else {
        category = '';
    }
    dispatch(setCategoryAction({category: category}));
    dispatch(fetchNpds());
}


export const onSaveNpd = (uploadFiles) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsNpdCreatedAction(false));
  const { npdForm } = store.getState().pfpNpd;
  let data = {...npdForm};
  //remove extra fields and normalize Department field
  data.Department = data.Department.ID;
  delete data.departments;
  delete data.categories;
  delete data.Updated;
  let url = '/route.php?c=pfp/npd/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/npd/update';
  } else {
	  delete data.ID;
  }
//   data = stringify(data);
  const formData = new FormData();

  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map((fieldName) =>{
	const file = uploadFiles[fieldName];
	formData.append(fieldName, file, file.name);
	delete data[fieldName];
  });

  Object.keys(data).map((fieldName) =>{
	formData.append(fieldName, data[fieldName]);
  });

  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          },
          data: formData
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsNpdCreatedAction(true));
  });
}



export const fetchNpds = () => (dispatch) => {
    const category =  store.getState().pfpNpd.category || '';
    dispatch(setLoadingAction(true));
    if(category) {
        let url = '/route.php?c=pfp/npd/getNPDByCategory&category=' + escape(category) + '&inc_future=1';
        customRequest({url: baseURL + url,
            method: 'get',
            headers: {
                    'Content-Type' : 'application/x-www-form-urlencoded',
                    'Authorization': getToken(),
                    'UserId': getUserId(),
                    'System': getUserSystem()
            }
        }).then((response) => {
          dispatch(setLoadingAction(false));
          dispatch(fetchNpdsAction(response || []));
        });
    } else {
        dispatch(setLoadingAction(false));
        dispatch(fetchNpdsAction([]));
    }

}

export const onSetNpd = (id, isAddNpd, isDelete) => (dispatch) => {
  const { npds, department, departments, category, categories } = store.getState().pfpNpd;
  isDelete = isDelete || false;
  let formData = {...Npd};
  if (isDelete && id) {
    formData.ID = id;
    dispatch(setNpdAction({npdForm: {...formData, Department:department, departments, Category:category, categories} }));
  } else if(!isAddNpd && id) {
	dispatch(setLoadingAction(true));
	let url = '/route.php?c=pfp/npd/getByID&id=' + id  + '';
	customRequest({url: baseURL + url,
		method: 'get',
		headers: {
				'Content-Type' : 'application/x-www-form-urlencoded',
				'Authorization': getToken(),
				'UserId': getUserId(),
				'System': getUserSystem()
		}
	}).then((response) => {
		dispatch(setLoadingAction(false));
		if(_.isArray(response)) {
			response = response[0];
		}
		if(_.isObject(response) && response.ID == id) {
			formData = {...Npd, ...response};
		}
		dispatch(setNpdAction({npdForm: {...formData, Department:department, departments, Category:category, categories} }));
	});
  } else {
    dispatch(setLoadingAction(false));
	  dispatch(setNpdAction({npdForm: {...formData, Department:department, departments, Category:category, categories} }));
  }

}

export const onSetForm = (fieldName, fieldValue) => (dispatch) => {
    const { npdForm } = store.getState().pfpNpd;
    if (fieldName == 'Department') {
        const department = fieldValue;
        const categories = [];
        if(department) {
            if(department && department.categories){
                _.each(department.categories, (category) => {
                    categories.push({'ID': category.name, 'Text': category.name});
                });
            }
            npdForm[fieldName] = department;
        } else {
            npdForm[fieldName] = '';
        }
        npdForm.categories = categories;
    } else if (fieldName == 'Category') {
        npdForm[fieldName] = fieldValue.ID || '';
    } else {
        npdForm[fieldName] = fieldValue;
    }
    dispatch(setFormAction({npdForm: {...npdForm} }));
  }


export const onDeleteNpd = (id) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsNpdDeletedAction(false));
  let url = `/route.php?c=pfp/npd/delete&id=${id}`;
  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsNpdDeletedAction(true));
  });
}

export const resetMe = () => (dispatch) => {
  dispatch(resetMeAction());
}

export default handleActions({
        [SET_LOADING] : (state, {payload}) => ({ ...state, isLoading: payload}),
        [FETCH_CATEGORIES]: (state, {payload}) => ({ ...state,
            departments: payload.departments,
            categories: payload.categories
        }),
        [SET_DEPARTMENT] : (state, {payload}) => ({ ...state, department: payload.department, categories: payload.categories }),
        [SET_CATEGORY] : (state, {payload}) => ({ ...state, category: payload.category }),
        [SET_NPD] : (state, {payload}) => ({ ...state, npdForm: payload.npdForm }),
        [FETCH_NPDS] : (state, {payload}) => ({ ...state, npds: payload }),
        [SET_IS_NPD_CREATED] : (state, {payload}) => ({ ...state, isNpdCreated: payload }),
        [SET_IS_NPD_DELETED] : (state, {payload}) => ({ ...state, isNpdDeleted: payload }),
        [SET_FORM] : (state, {payload}) => ({ ...state, npdForm: payload.npdForm }),
        [RESET_ME]: (state, {payload}) => ({...state, ...initialState}),
}, initialState);

export const selectors = {};

