import { handleActions, createAction } from 'redux-actions';
import { request, requestGet, customRequest } from '../../../helpers/axios';
import {
  encodeText,
  getUserSystem,
  getToken,
  getUserId,
} from '../../../helpers/util';
import { each, map, orderBy } from 'lodash';
import store from '../store';
import { setDisableCategoryStructure } from './app';

const FETCH_PERFORMANCE_DATA = 'price/FETCH_PERFORMANCE_DATA';
const SET_PERFORMANCE_DATA = 'price/SET_PERFORMANCE_DATA';
const FETCH_PRODUCTS = 'price/FETCH_PRODUCTS';
const SET_PURCHASE = 'price/SET_PURCHASE';
const SET_SELECTED_MEMBERS = 'price/SET_SELECTED_MEMBERS';
const SET_SORT_ORDER = 'price/SET_SORT_ORDER';
const SET_REPORT_DEPARTMENT = 'price/SET_DEPARTMENT';
const SET_REPORT_CATEGORY = 'price/SET_CATEGORY';
const SET_SUBCATEGORY = 'price/SET_SUBCATEGORY';
const SET_LEVEL4 = 'price/SET_LEVEL4';
const SET_LEVEL5 = 'price/SET_LEVEL5';
const SET_PMP = 'price/SET_PMP';
const FETCH_PRICE_SENSITIVITY_GRAPH = 'price/FETCH_PRICE_SENSITIVITY_GRAPH';
const SET_LOADING = 'price/SET_LOADING';
const SET_EXPORTING = 'price/SET_EXPORTING';
const SET_HIDE_PRODUCTS = 'price/SET_HIDE_PRODUCTS';
const EXPORT_TO_PDF_SUCCESS = 'price/EXPORT_TO_PDF_SUCCESS';
const RESET = 'price/RESET';
const SET_DEPOT = 'price/SET_DEPOT';
const SET_CHART_PARAM = 'price/SET_CHART_PARAM';
const SET_DEPOTS = 'price/SET_DEPOTS';
const SET_CHART_LOADED = 'price/SET_CHART_LOADED';
const SET_SELECTED_GROUP = 'price/SET_SELECTED_GROUP';
const SET_MEMBERS = 'price/SET_MEMBERS';
const SET_BUSINESS_TYPE = 'price/SET_BUSINESS_TYPE';
const SET_BUSINESS_TYPES = 'price/SET_BUSINESS_TYPES';

const initialState = {
  selectedPerformanceData: {},
  performanceData: [],
  departments: [],
  department: {},
  categories: [],
  category: {},
  subCategories: [],
  subCategory: {},
  level4s: [],
  level4: {},
  level5s: [],
  level5: {},
  reportType: '',
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  purchase: '',
  filterId: '',
  filterValue: '',
  filter: '',
  members: [],
  selectedMembers: [],
  sortBySales: false,
  priceSensitivityGraph: {},
  hideProducts: true,
  isExporting: false,
  isExported: false,
  pdfURL: '',
  isLoading: false,
  depots: [],
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  selectedDepots: [],
  chartParam: 0,
  isChartLoaded: false,
  selectedGroup: [],
  groups: [],
  businessTypes: [],
  selectedBusinessType: '',
};

const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setPurchaseAction = createAction(SET_PURCHASE);
const fetchPerformanceDataAction = createAction(FETCH_PERFORMANCE_DATA);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setPerformanceDataAction = createAction(SET_PERFORMANCE_DATA);
const setSortOrderAction = createAction(SET_SORT_ORDER);
const setPmpAction = createAction(SET_PMP);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const fetchPriceSensitivityGraphAction = createAction(
  FETCH_PRICE_SENSITIVITY_GRAPH,
);
const setLoadingAction = createAction(SET_LOADING);
const setHideProductsAction = createAction(SET_HIDE_PRODUCTS);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const resetAction = createAction(RESET);
const setDepotAction = createAction(SET_DEPOT);
const setChartParamAction = createAction(SET_CHART_PARAM);
const setDepotsAction = createAction(SET_DEPOTS);
const setChartLoadedAction = createAction(SET_CHART_LOADED);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setBusinessTypesAction = createAction(SET_BUSINESS_TYPES);

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'sku-tracker-report-' + new Date().toISOString(),
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  loadChart(store, dispatch);
};

export const setPurchase = val => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction({}));
  dispatch(setPurchaseAction(val));
  dispatch(setChartParamAction(0));
};

export const setDepot = val => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction({}));
  dispatch(setDepotAction({ selectedDepots: val }));
  dispatch(
    setChartParamAction(
      store.getState().priceSensitivityReport.selectedPerformanceData
        .WVProdCode,
    ),
  );
};

export const setDepartment = val => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction([]));
  if (parseInt(val) > 0) {
    let department = _.filter(
      store.getState().priceSensitivityReport.departments,
      dpt => {
        if (dpt.ID === parseInt(val)) {
          return dpt;
        }
      },
    );
    let dpt = department[0];
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand' &&
      store.getState().priceSensitivityReport.reportType !== 'level4' &&
      store.getState().priceSensitivityReport.reportType !== 'level5'
    ) {
      dpt.reportType = 'category';
    } else {
      dpt.reportType = store.getState().priceSensitivityReport.reportType;
    }
    dispatch(setReportDepartmentAction(dpt));
    if (dpt.Categories.length === 1) {
      let cat = dpt.Categories[0];
      if (
        store.getState().priceSensitivityReport.reportType !== 'depot' &&
        store.getState().priceSensitivityReport.reportType !== 'supplier' &&
        store.getState().priceSensitivityReport.reportType !== 'member' &&
        store.getState().priceSensitivityReport.reportType !== 'brand'
      ) {
        cat.reportType = 'subcategory';
      } else {
        cat.reportType = store.getState().priceSensitivityReport.reportType;
      }
      dispatch(setReportCategoryAction(cat));
      if (cat.SubCategories.length === 1) {
        let subcat = cat.SubCategories[0];
        if (
          store.getState().priceSensitivityReport.reportType !== 'depot' &&
          store.getState().priceSensitivityReport.reportType !== 'supplier' &&
          store.getState().priceSensitivityReport.reportType !== 'member' &&
          store.getState().priceSensitivityReport.reportType !== 'brand'
        ) {
          subcat.reportType = 'level4';
        } else {
          subcat.reportType =
            store.getState().priceSensitivityReport.reportType;
        }
        dispatch(setSubCategoryAction(subcat));
      }
    }
    loadProducts(store, dispatch);
    dispatch(setHideProductsAction(false));
    dispatch(setChartParamAction(0));
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().priceSensitivityReport.reportType;
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setSubCategoryAction({ Level4: [], reportType: reportType }));
    dispatch(
      setReportCategoryAction({ SubCategories: [], reportType: reportType }),
    );
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand'
    ) {
      reportType = 'department';
    }
    dispatch(
      setReportDepartmentAction({ reportType: reportType, Categories: [] }),
    );
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
  } else {
    let department = _.filter(
      store.getState().priceSensitivityReport.departments,
      dpt => {
        if (dpt.Text === val) {
          return dpt;
        }
      },
    );
    let dpt = department[0];
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand' &&
      store.getState().priceSensitivityReport.reportType !== 'level4' &&
      store.getState().priceSensitivityReport.reportType !== 'level5'
    ) {
      dpt.reportType = 'category';
    } else {
      dpt.reportType = store.getState().priceSensitivityReport.reportType;
    }
    dispatch(setReportDepartmentAction(dpt));
  }
};

export const setCategory = val => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction([]));
  if (parseInt(val) > 0) {
    let category = _.filter(
      store.getState().priceSensitivityReport.categories,
      cat => {
        if (cat.ID === parseInt(val)) {
          return cat;
        }
      },
    );
    let catt = category[0];
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand'
    ) {
      catt.reportType = 'subcategory';
    } else {
      catt.reportType = store.getState().priceSensitivityReport.reportType;
    }

    dispatch(setReportCategoryAction(catt));
    if (catt.SubCategories.length === 1) {
      let subcat = catt.SubCategories[0];
      if (
        store.getState().priceSensitivityReport.reportType !== 'depot' &&
        store.getState().priceSensitivityReport.reportType !== 'supplier' &&
        store.getState().priceSensitivityReport.reportType !== 'member' &&
        store.getState().priceSensitivityReport.reportType !== 'brand'
      ) {
        subcat.reportType = 'level4';
      } else {
        subcat.reportType = store.getState().priceSensitivityReport.reportType;
      }
      dispatch(setSubCategoryAction(subcat));
    }
    loadProducts(store, dispatch);
    dispatch(setHideProductsAction(false));
    dispatch(setChartParamAction(0));
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().priceSensitivityReport.reportType;
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setSubCategoryAction({ Level4: [], reportType: reportType }));
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand' &&
      store.getState().priceSensitivityReport.reportType !== 'level4' &&
      store.getState().priceSensitivityReport.reportType !== 'level5'
    ) {
      reportType = 'category';
    }
    dispatch(
      setReportCategoryAction({ reportType: reportType, SubCategories: [] }),
    );
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
  } else {
    let category = _.filter(
      store.getState().priceSensitivityReport.categories,
      cat => {
        if (cat.Text === val) {
          return cat;
        }
      },
    );
    let catt = category[0];
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand'
    ) {
      catt.reportType = 'subcategory';
    } else {
      catt.reportType = store.getState().priceSensitivityReport.reportType;
    }
    dispatch(setReportCategoryAction(catt));
  }
};

export const setPmp = val => dispatch => {
  dispatch(setPerformanceDataAction([]));
  if (val) {
    dispatch(setPmpAction(val));
    loadProducts(store, dispatch);
  } else {
    dispatch(setPmpAction(''));
  }
};

const loadProducts = (store, dispatch) => {
  let state = store.getState().priceSensitivityReport;
  let url = '/route.php?c=product/getAll';

  const user = store.getState().user.authUser;
  if (user.showCompetitor) {
    url += `&show_competitor=0&supplier=${encodeURIComponent(
      user.supplier.Text,
    )}`;
  }
  if (state.department.Text !== undefined) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }
  if (state.category.Text !== undefined) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }
  if (state.subCategory.Text !== undefined) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }
  if (state.level4.Text !== undefined) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }
  if (state.level5.Text !== undefined) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }
  if (state.pmp) {
    url += '&pmp=' + state.pmp;
  }
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    each(response, prod => {
      prod.WVProdDesc = encodeText(prod.WVProdDesc);
    });
    dispatch(fetchProductsAction({ wholesalers: response }));
  });
};

export const setSubCategory = val => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction([]));
  if (parseInt(val) > 0) {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.ID === val) {
          if (
            store.getState().priceSensitivityReport.reportType !== 'depot' &&
            store.getState().priceSensitivityReport.reportType !== 'supplier' &&
            store.getState().priceSensitivityReport.reportType !== 'member' &&
            store.getState().priceSensitivityReport.reportType !== 'brand'
          ) {
            subcat.reportType = 'level4';
          } else {
            subcat.reportType =
              store.getState().priceSensitivityReport.reportType;
          }
          dispatch(setSubCategoryAction(subcat));
          loadProducts(store, dispatch);
          dispatch(setHideProductsAction(false));
          dispatch(setChartParamAction(0));
        }
      });
    });
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().priceSensitivityReport.reportType;
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand'
    ) {
      reportType = 'subcategory';
    }
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.Text === val) {
          if (
            store.getState().priceSensitivityReport.reportType !== 'depot' &&
            store.getState().priceSensitivityReport.reportType !== 'supplier' &&
            store.getState().priceSensitivityReport.reportType !== 'member' &&
            store.getState().priceSensitivityReport.reportType !== 'brand'
          ) {
            subcat.reportType = 'level4';
          } else {
            subcat.reportType =
              store.getState().priceSensitivityReport.reportType;
          }
          dispatch(setSubCategoryAction(subcat));
        }
      });
    });
  }
};

export const setLevel4 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      if (cat.ID === store.getState().priceSensitivityReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (
            subcat.ID === store.getState().priceSensitivityReport.subCategory.ID
          ) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === val) {
                if (
                  store.getState().priceSensitivityReport.reportType !==
                    'depot' &&
                  store.getState().priceSensitivityReport.reportType !==
                    'supplier' &&
                  store.getState().priceSensitivityReport.reportType !==
                    'member' &&
                  store.getState().priceSensitivityReport.reportType !== 'brand'
                ) {
                  lvl4.reportType = 'level5';
                } else {
                  lvl4.reportType =
                    store.getState().priceSensitivityReport.reportType;
                }
                dispatch(setLevel4Action(lvl4));
                loadProducts(store, dispatch);
                dispatch(setHideProductsAction(false));
                dispatch(setChartParamAction(0));
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().priceSensitivityReport.reportType;
    if (
      store.getState().priceSensitivityReport.reportType !== 'depot' &&
      store.getState().priceSensitivityReport.reportType !== 'supplier' &&
      store.getState().priceSensitivityReport.reportType !== 'member' &&
      store.getState().priceSensitivityReport.reportType !== 'brand'
    ) {
      reportType = 'level4';
    }
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
    dispatch(setChartParamAction(0));
  } else {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      if (cat.ID === store.getState().priceSensitivityReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (
            subcat.ID === store.getState().priceSensitivityReport.subCategory.ID
          ) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.Text === val) {
                if (
                  store.getState().priceSensitivityReport.reportType !==
                    'depot' &&
                  store.getState().priceSensitivityReport.reportType !==
                    'supplier' &&
                  store.getState().priceSensitivityReport.reportType !==
                    'member' &&
                  store.getState().priceSensitivityReport.reportType !== 'brand'
                ) {
                  lvl4.reportType = 'level5';
                } else {
                  lvl4.reportType =
                    store.getState().priceSensitivityReport.reportType;
                }
                dispatch(setLevel4Action(lvl4));
                dispatch(setChartParamAction(0));
              }
            });
          }
        });
      }
    });
  }
};

export const setLevel5 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      if (cat.ID === store.getState().priceSensitivityReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (
            subcat.ID === store.getState().priceSensitivityReport.subCategory.ID
          ) {
            _.each(subcat.Level4, lvl4 => {
              if (
                lvl4.ID === store.getState().priceSensitivityReport.level4.ID
              ) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.ID === val) {
                    dispatch(setLevel5Action(lvl5));
                    loadProducts(store, dispatch);
                    dispatch(setHideProductsAction(false));
                    dispatch(setChartParamAction(0));
                  }
                });
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
    dispatch(setChartParamAction(0));
  } else {
    _.each(store.getState().priceSensitivityReport.categories, cat => {
      if (cat.ID === store.getState().priceSensitivityReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (
            subcat.ID === store.getState().priceSensitivityReport.subCategory.ID
          ) {
            _.each(subcat.Level4, lvl4 => {
              if (
                lvl4.ID === store.getState().priceSensitivityReport.level4.ID
              ) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.Text === val) {
                    dispatch(setLevel5Action(lvl5));
                    dispatch(setChartParamAction(0));
                  }
                });
              }
            });
          }
        });
      }
    });
  }
};

export const fetchPerformanceData = () => dispatch => {
  dispatch(fetchPriceSensitivityGraphAction({}));
  let url2 = '/route.php?c=category/getUserHierarchy';
  const state = store.getState().priceSensitivityReport;
  if (state.selectedMembers.length === 1) {
    url2 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }
  dispatch(setLoadingAction(true));
  request({
    url: url2,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(data => {
    if (
      (data.Categories && data.Categories.length > 1) ||
      (data.Departments && data.Departments.length > 1)
    ) {
      dispatch(setHideProductsAction(true));
    } else {
      dispatch(setHideProductsAction(false));
      loadProducts(store, dispatch);
    }
    let url1 = '/route.php?c=wholesaler/getAll';

    request({
      url: url1,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(members => {
      each(members, mem => {
        mem.ActualName = encodeText(mem.ActualName);
      });

      if (members.length === 1) {
        dispatch(setDisableCategoryStructure(false));
      }

      url1 = '/route.php?c=depot/getDepots';

      request({
        url: url1,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      }).then(depots => {
        each(depots, depot => {
          depot.DepotName = encodeText(depot.DepotName);
        });

        if (
          members.length === 1
        ) {
          dispatch(
            setSelectedMembersAction([
              { Text: members[0].ActualName, ID: members[0].WholesalerNo },
            ]),
          );
          members = [members[0]];
        }

        requestGet({ url: '/route.php?c=groupSupplier/getAll' }).then(
          groups => {
            let groupsArr =
              (groups && groups.map((group, i) => ({ Text: group, ID: i }))) ||
              [];
            let orderedGroups = orderBy(groupsArr, ['Text']);
            dispatch(
              fetchPerformanceDataAction({
                pmp: '0',
                members: members,
                categories: data.Categories ? data.Categories : [],
                departments: data.Departments ? data.Departments : [],
                purchase: 'out',
                depots: depots,
                groups: orderedGroups,
              }),
            );

            if (data.Departments.length === 1) {
              dispatch(setDepartment(data.Departments[0].ID));
            }

            const url = '/route.php?c=customer/getAllBusinessTypes';
            requestGet({ url: url })
              .then(busTypes => {
                let businessTypes = busTypes.map(bus => ({
                  BusinessName: bus,
                }));
                dispatch(setBusinessTypesAction(businessTypes));
                dispatch(setLoadingAction(false));
              })
              .catch(err => {
                dispatch(setLoadingAction(false));
              });
          },
        );
      });
    });
  });
};

export const setPerformanceData = val => dispatch => {
  dispatch(setPerformanceDataAction(val));
  if (val) {
    dispatch(setChartParamAction(val.WVProdCode));
  } else {
    dispatch(setChartParamAction(0));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));
  dispatch(
    setChartParamAction(
      store.getState().priceSensitivityReport.selectedPerformanceData
        .WVProdCode,
    ),
  );
  const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v.Text).join(
    ',',
  ))}`;

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};
export const setSortOrder = () => dispatch => {
  dispatch(setSortOrderAction());
};

const loadChart = (store, dispatch) => {
  dispatch(setChartLoadedAction(false));
  let graphData = {
    tick: '',
    soldQty: '',
    price: '',
  };
  const { selectedAdvancedBusinessTypes } = store.getState().filters;
  if (store.getState().priceSensitivityReport.chartParam !== 0) {
    let memberIds = [];

    _.each(store.getState().priceSensitivityReport.selectedMembers, member => {
      memberIds.push(member.Text);
    });

    let state = store.getState().priceSensitivityReport;
    let url =
      '/route.php?c=priceSensitivity/getPriceSensitivityReport&prod1=' +
      state.chartParam +
      '&type=' +
      state.purchase +
      '&pmp=' +
      state.pmp;

    url += state?.selectedGroup?.length
      ? '&group=' +
        encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
      : '';

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }

    url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';

    if (state.selectedDepots?.length > 0) {
      url +=
        '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',');
    }

    selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
      if (value) {
        url += `&${encodeURIComponent(ApiName)}=${value
          .map(val => encodeURIComponent(val.value))
          .join(',')}`;
      }
    });

    if (state.ownBrand && state.ownBrand.Text) {
      url += `&ownBrand=${state.ownBrand.Text}`;
    }

    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    })
      .then(result => {
        dispatch(setLoadingAction(false));
        dispatch(setChartLoadedAction(true));
        if (result.length > 0) {
          let valn = '[';
          let qty = '[';
          let prc = '[';
          _.each(result[0].Values, (val, i) => {
            let valu = '[' + parseInt(i + 1) + ',' + val.YearNoWeekNo + '],';
            valn += valu;
            let qtys = '[' + parseInt(i + 1) + ',' + val.Volume + '],';
            qty += qtys;
            let prcs =
              '[' + parseInt(i + 1) + ',' + val.AverageCostPrice + '],';
            prc += prcs;
          });
          valn = valn.substring(0, valn.length - 1) + ']';
          qty = qty.substring(0, qty.length - 1) + ']';
          prc = prc.substring(0, prc.length - 1) + ']';
          graphData.tick = valn;
          graphData.soldQty = qty;
          graphData.price = prc;
          dispatch(fetchPriceSensitivityGraphAction(graphData));
        } else {
          dispatch(fetchPriceSensitivityGraphAction([]));
        }
      })
      .catch(err => {
        dispatch(setLoadingAction(false));
        dispatch(setChartLoadedAction(true));
      });
  } else {
    dispatch(setLoadingAction(false));
    dispatch(fetchPriceSensitivityGraphAction(graphData));
    dispatch(setChartLoadedAction(true));
  }
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export const setBusinessType = val => dispatch => {
  dispatch(setBusinessTypeAction(val));
};

export default handleActions(
  {
    [FETCH_PERFORMANCE_DATA]: (state, { payload }) => ({
      ...state,
      pmp: payload.pmp,
      members: payload.members,
      categories: payload.categories,
      departments: payload.departments,
      purchase: payload.purchase,
      depots: payload.depots,
      groups: payload.groups,
    }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_BUSINESS_TYPES]: (state, { payload }) => ({
      ...state,
      businessTypes: payload,
    }),
    [SET_PERFORMANCE_DATA]: (state, { payload }) => ({
      ...state,
      selectedPerformanceData: payload,
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      performanceData: payload.wholesalers,
    }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_HIDE_PRODUCTS]: (state, { payload }) => ({
      ...state,
      hideProducts: payload,
    }),
    [SET_SORT_ORDER]: (state, { payload }) => ({
      ...state,
      sortBySales: !state.sortBySales,
    }),
    [SET_CHART_PARAM]: (state, { payload }) => ({
      ...state,
      chartParam: payload,
    }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [FETCH_PRICE_SENSITIVITY_GRAPH]: (state, { payload }) => ({
      ...state,
      priceSensitivityGraph: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_REPORT_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      department: payload,
      categories: payload.Categories,
      reportType: payload.reportType,
    }),
    [SET_REPORT_CATEGORY]: (state, { payload }) => ({
      ...state,
      category: payload,
      subCategories: payload.SubCategories,
      reportType: payload.reportType,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload,
    }),
    [SET_SUBCATEGORY]: (state, { payload }) => ({
      ...state,
      subCategory: payload,
      level4s: payload.Level4,
      reportType: payload.reportType,
    }),
    [SET_LEVEL4]: (state, { payload }) => ({
      ...state,
      level4: payload,
      level5s: payload.Level5,
      reportType: payload.reportType,
    }),
    [SET_LEVEL5]: (state, { payload }) => ({ ...state, level5: payload }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_CHART_LOADED]: (state, { payload }) => ({
      ...state,
      isChartLoaded: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...initialState,
    }),
  },
  initialState,
);

export const selectors = {};
