function alerts(state = {}, action) {
	switch (action.type) {
	case 'ADD_ALERT':
		return [
			...state,
			{
				id: state.length || 0,
				type: action.alertType,
				text: action.text,
				status: 'open',
				timeToClose: action.timeToClose,
			},
		];
	case 'CLOSE_ALERT':
		if (!state[action.alertId]) return;
		return [
			...state.slice(0, action.alertId),
			{ ...state[action.alertId], status: 'closed' },
			...state.slice(action.alertId + 1),
		];

	case 'USER_LOGOUT':
		return [];
		
	default:
		return state;
	}
}

export default alerts;
