import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getUserSystem } from '../../helpers/util';
import { removeItemFromArray } from '../../helpers/helpers';
import AlchSelectableBox from './AlchUI/AlchSelectableBox';
import * as _ from 'lodash';
import WindowSizeListener from 'react-window-size-listener';

class QueryFilter extends Component {
  state = {
    filtersAvailable: this.props.filtersAvailable,
    filtersSelected: [],
    tmpSelected: [],
    search: '',
    viewportWidth: 0,
    viewportHeight: 0,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      filtersAvailable: nextProps.filtersAvailable,
      filtersSelected: nextProps.filtersSelected,
      search: nextProps.name !== this.props.name ? '' : this.state.search,
    });
  }

  close = e => {
    e.preventDefault();
    this.props.menuToggle('query-filter');
    this.props.hideBlur();
  };

  search = e => {
    const searchKeyword = e.target.value;

    this.setState(
      {
        search: searchKeyword,
      },
      () => {
        const { onSearch } = this.props;
        if (onSearch) {
          onSearch(searchKeyword, [...this.state.tmpSelected]);
        }
      },
    );
  };

  addFilter = (filter, i) => {
    const filtersAvailable = removeItemFromArray(
      [...this.state.filtersAvailable],
      i,
    );
    const filtersSelected = [...this.state.filtersSelected];
    filter.name = this.props.name;
    filtersSelected.push(filter);
    this.setState({
      filtersAvailable,
      filtersSelected,
      tmpSelected: [...filtersSelected],
    });
  };

  removeFilter = (filter, i) => {
    const filtersSelected = removeItemFromArray(
      [...this.state.filtersSelected],
      i,
    );
    const filtersAvailable = [...this.state.filtersAvailable];
    filtersAvailable.push(filter);
    this.setState({
      filtersAvailable,
      filtersSelected,
      tmpSelected: [...filtersSelected],
    });
  };

  saveFilter = () => {
    this.props.menuToggle('query-filter');
    this.props.onChange(this.state.filtersSelected, this.props.name);
    this.props.hideBlur();
  };

  renderFilters = (filters, onClick, isSelected) => {
    return _.uniqBy(filters, 'fieldValue').map((filter, i) => {
      if (
        isNaN(this.state.search) &&
        this.state.search !== '' &&
        !isSelected &&
        this.props.name !== 'productsForCode'
      ) {
        if (
          filter.fieldValue
            .toLowerCase()
            .indexOf(this.state.search.toLowerCase()) === -1
        ) {
          return null;
        }
      }
      return (
        <AlchSelectableBox
          key={i}
          className="c-selectable-small u-1_1"
          label={filter.label || filter.fieldValue}
          id={`${filter.name}${filter.fieldKey}`}
          type="utility"
          onChange={() => {
            onClick(filter, i);
          }}
        />
      );
    });
  };
  calculateFiltersInputHeight = () => {
    const { viewportHeight } = this.state;
    const searchInputHeight = 48;
    const filtersLabelHeight = 126;
    const filterAddBtnHeight = 45;
    const filtersInputMinHeight = 70;
    let newHeight = filtersInputMinHeight;
    newHeight =
      viewportHeight -
      (searchInputHeight +
        filtersLabelHeight * 2 +
        filterAddBtnHeight +
        60 +
        100);
    if (newHeight > filtersInputMinHeight) {
      newHeight = Math.ceil(newHeight / 2);
    }
    if (newHeight < filtersInputMinHeight) {
      newHeight = filtersInputMinHeight;
    }
    return newHeight;
  };

  render() {
    const filtersInputHeight = this.calculateFiltersInputHeight();
    return (
      <div className="o-offscreen o-filter o-filter--query t-brand-c">
        <WindowSizeListener
          onResize={windowSize => {
            this.setState({
              viewportHeight: windowSize.windowHeight,
              viewportWidth: windowSize.windowWidth,
            });
          }}
        />
        <div className="o-filter__action">
          <a href="#" onClick={this.close}>
            <span className="u-icon u-icon--back">back</span>
          </a>
          <h2>{this.props.name}</h2>
        </div>
        <hr className="mr-0" />
        <div className="o-filter__content">
          <div style={{ display: 'none' }}>
            <p>DO YOU WISH TO FILTER THE RESULTS BY CATEGORY NAME ?</p>
            <div className="o-block u-layout u-layout--between">
              <button className="c-button--neg">No</button>
              <button>Yes</button>
            </div>
          </div>
          <div>
            <input
              type="search"
              className="form-control query-builder__half filtet-text"
              value={this.state.search}
              placeholder="Type here to search"
              onChange={_.debounce(this.search.bind(this), 100, {
                leading: true,
                trailing: false,
              })}
            />
            <hr className="mr-0" />
            <div className="o-block">
              <h4>Available Filters</h4>
              <div
                className="o-wrap u-pad-0"
                style={{ overflowY: 'auto', height: filtersInputHeight }}>
                {this.renderFilters(
                  this.state.filtersAvailable,
                  this.addFilter,
                  false,
                )}
              </div>
            </div>
            <hr className="mr-0" />
            <div className="o-block">
              <h4>Selected Filters</h4>
              <div
                className="o-wrap u-pad-0"
                style={{ overflowY: 'auto', height: filtersInputHeight }}>
                {this.renderFilters(
                  this.state.filtersSelected,
                  this.removeFilter,
                  true,
                )}
              </div>
            </div>
            <button
              className={`btn alchemy-btn--others`}
              onClick={this.saveFilter}>
              Add Filter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

QueryFilter.defaultProps = {
  onChange: Function.prototype,
  onSearch: Function.prototype,
};

QueryFilter.propTypes = {
  filtersAvailable: PropTypes.array.isRequired,
  menuToggle: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default QueryFilter;
