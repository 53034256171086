import React, { useContext } from 'react';
import LoginForm from '../components/LoginForm';
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import { pageVariants } from '../../config/pageVariants';
import { motion } from 'framer-motion';
import LogoutPopup from '../components/popups/LogoutPopup';
import BrandLogo from '../components/util/BrandLogo';
import { getUserSystem, isUAT } from '../../helpers/util';
import { useDashboardIfAuthed } from '../hooks/useDashboardIfAuthed';

const LoginPage = props => {
  const { name } = useContext(ThemeContext);
  useDashboardIfAuthed();

  return (
    <motion.div
      key={'LOGIN-ID-12341775151s'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="splash">
      <LogoutPopup />
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="splash__buttons text-center mb-2">
          <div className="d-block splash__image mx-auto">
            <BrandLogo />
          </div>

          <div className="d-block mx-auto mt-5">
            {/* Used to hide login for specific systems on live */}
            {/* {name === 'confex' && !isUAT() && (
              <h5>This site will go live at 9am on Monday 11th October</h5>
            )} */}
            {/* {(name !== 'confex' || isUAT()) && } */}
            <LoginForm {...props} />
          </div>
        </div>
      </div>

      <div className="purple-triangle purple-triangle__left"></div>
      <div className="purple-triangle purple-triangle__right"></div>
    </motion.div>
  );
};

export default LoginPage;
