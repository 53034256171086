import React from 'react';
import PropTypes from 'prop-types';

import { validateId } from '../../../helpers/helpers';

class AlchSelectableBox extends React.Component {
  state = {
    on: this.props.on,
    uid: this.props.uid,
    isDisabled: this.props.isDisabled,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.on !== this.state.on) {
      this.setState({
        ...this.state,
        on: nextProps.on,
      });
    }
  }

  onChange = () => {
    const selectId = validateId(this.props.id);
    const newValue = !this.state.on;
    this.setState({
      on: newValue,
    });
    this.props.onChange(newValue, selectId, this.state.uid);
  };

  render() {
    const selectableId = validateId(this.props.id);
    const compClass = this.props.type === 'component' ? ['c-selectable-box'] : ['u-selectable'];
    compClass.push(this.props.className);
    return (
      <div className={compClass.join(' ')}>
        <input type="checkbox" id={selectableId} name={selectableId} checked={this.state.on} onChange={this.onChange} disabled={this.state.isDisabled ? 'disabled' : ''} />
        <label htmlFor={selectableId}>{this.props.label}</label>
      </div>
    );
  }
}

AlchSelectableBox.defaultProps = {
  on: false,
  className: '',
  onChange: () => {},
  uid: false,
  type: 'component',
  isDisabled: false,
};

AlchSelectableBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  uid: PropTypes.any /* anything that helps identify instance within context */,
  on: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default AlchSelectableBox;
