import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import uuid from 'uuid/v4';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Collapsible from 'react-collapsible';
import FileBase64 from 'react-file-base64';
import { getActivitiesForUser, responseNotesLocalChange, addUserResponse, addUserResponseLocal, deleteUserResponse, deleteUserResponseLocal, fetchUserResponsesForRecipient } from '../../redux/reducers/psa';

const tableClass = ['o-table'];

const UploadDetails = ({ data, onTextChange, userActivities, onFileChange, onAddNewDistributionList, onAddResponse, onResponseNotesChange, onDeleteResponse, onRemoveUserResponseLocal, onSaveResponse, userResponsesLocal, userResponses, isLoading }) => (
  <div className="o-canvas-wrap">
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between" style={{ width: '100%' }}>
        <div className="o-canvas__header-item u-layout" style={{ width: '100%' }}>
          {userActivities.length > 0 && (
            <div className="form-group" style={{ height: '30px' }}>
              <h2 className="o-canvas__title u-100">{userActivities[0].CampaignName}</h2>
            </div>
          )}
        </div>
        <div className="o-canvas__header-item u-layout" style={{ width: '100%' }}>
          <div className="form-group" style={{ height: '80px' }}>
            <h2 className="o-canvas__title u-100">Upload Details</h2>
          </div>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
          {userActivities.length > 0 &&
            userActivities.map((act, idx) => {
              return (
                <Collapsible trigger={act.ActivityName} key={idx}>
                  {act.ExampleImage.length > 0 && (
                    <div className="form-group" style={{ margin: '10px', width: '150px' }} key={idx}>
                      <img src={act.ExampleImage} style={{ width: '150px' }} />
                    </div>
                  )}
                  {act.Description}
                  <div className="o-canvas__header-search u-layout">
                    {userResponses
                      .filter(resp => resp.ActivityID === act.ActivityID)
                      .map((uResp, idx) => {
                        return (
                          <div className="form-group" style={{ margin: '10px', width: '150px' }} key={idx}>
                            <img src={uResp.Image} style={{ width: '150px' }} />
                            {uResp.Notes}
                            <button className="u-right mt-10 mr-10 btn-psa" style={{ width: '150px', marginTop: '5px', margin: '0 auto' }} onClick={() => onDeleteResponse(uResp.ResponseID)}>
                              Delete
                            </button>
                          </div>
                        );
                      })}
                    {userResponsesLocal &&
                      data.selectedActivityId === act.ActivityID &&
                      userResponsesLocal.length > 0 &&
                      userResponsesLocal.map((resp, idx) => {
                        return (
                          <div className="form-group" style={{ margin: '10px', width: '150px', position: 'relative' }} key={idx}>
                            <img src={resp.image} style={{ width: '150px' }} />
                            <a href="javascript:void(0)" className="psa-upload-remove" onClick={() => onRemoveUserResponseLocal(resp.id)}>
                              <span className="fa fa-minus-circle"></span>
                            </a>
                            Notes:
                            <input type="text" id={resp.id} className="ReactModal--input-green collapsible-text" style={{ width: '150px', marginBottom: '5px' }} name={resp.id} value={resp.notes || ''} onChange={onResponseNotesChange} />
                            <button className="u-right mt-10 mr-10 btn-psa" style={{ width: '150px', marginTop: '5px', margin: '0 auto' }} onClick={() => onSaveResponse(resp.id)}>
                              Save
                            </button>
                          </div>
                        );
                      })}
                    <div className="form-group" style={{ margin: '10px' }}>
                      <label className="uploader" style={{ outlineColor: 'green' }}>
                        <input type="file" accept="image/*" name={`${act.ActivityID}_${act.RecipientID}`} onChange={e => onFileChange(e)} style={{ display: 'none' }} multiple />
                      </label>
                    </div>
                  </div>
                </Collapsible>
              );
            })}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.psa.isLoading,
    userActivities: state.psa.userActivities,
    isUserResponseAdded: state.psa.isUserResponseAdded,
    isUserResponseDeleted: state.psa.isUserResponseDeleted,
    userResponsesLocal: state.psa.userResponsesLocal,
    isSavingResponse: state.psa.isSavingResponse,
    userResponses: state.psa.userResponses,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    getActivitiesForUser,
    addUserResponse,
    addUserResponseLocal,
    deleteUserResponse,
    deleteUserResponseLocal,
    responseNotesLocalChange,
    fetchUserResponsesForRecipient,
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.match.params.id) {
        this.props.getActivitiesForUser(this.props.match.params.id, true);
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.userActivities !== this.props.userActivities && this.props.userActivities.length > 0) {
        const ids = this.props.match.params.id.split('_');
        this.props.fetchUserResponsesForRecipient(ids, true);
      }
      if (prevProps.isUserResponseAdded !== this.props.isUserResponseAdded) {
        toast.dismiss();
        toast.info('User response added successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
        const ids = this.props.match.params.id.split('_');
        this.props.fetchUserResponsesForRecipient(ids, true);
      }
      if (prevProps.isSavingResponse !== this.props.isSavingResponse && this.props.isSavingResponse) {
        toast.dismiss();
        toast.info('Saving response, please wait...', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
      }
      if (prevProps.isSavingResponse !== this.props.isSavingResponse && !this.props.isSavingResponse) {
        toast.dismiss();
      }
      if (prevProps.isUserResponseDeleted !== this.props.isUserResponseDeleted) {
        toast.dismiss();
        toast.info('User response deleted successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
        const ids = this.props.match.params.id.split('_');
        this.props.fetchUserResponsesForRecipient(ids, true);
      }
    },
  }),
  withState('data', 'handleData', {
    selectedActivityId: '',
    userResponses: [],
    userResponse: {
      id: '',
      notes: '',
      responseId: '',
      image: '',
    },
  }),
  withHandlers({
    onResponseNotesChange: ({ data, handleData, responseNotesLocalChange }) => ev => {
      const { name, value } = ev.target;
      responseNotesLocalChange(name, value);
    },
    onRemoveUserResponseLocal: ({ data, handleData, deleteUserResponseLocal }) => id => {
      deleteUserResponseLocal(id);
    },
    onDeleteResponse: ({ data, handleData, deleteUserResponse }) => id => {
      deleteUserResponse(id, true);
    },
    onSaveResponse: ({ data, handleData, addUserResponse, userResponsesLocal }) => id => {
      const response = userResponsesLocal.filter(resp => resp.id === id)[0];
      addUserResponse(response, true);
    },
    onFileChange: ({ data, handleData, addUserResponseLocal }) => e => {
      const { name } = e.target;
      const ids = name.split('_');
      const activityId = ids[0];
      const recipientId = ids[1];
      for (var i = 0; i < e.target.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[i]);
        reader.onloadend = () => {
          const base64data = reader.result;
          addUserResponseLocal({
            id: uuid(),
            notes: '',
            responseId: recipientId,
            image: base64data,
          });
          handleData({ ...data, selectedActivityId: activityId });
        };
      }
    },
    onTextChange: ({ data, handleData }) => ev => {
      const { name, value } = ev.target;
    },
    onAddNewActivity: ({ data, handleData, history }) => event => {
      history.push(`${process.env.APP_ROOT}psa/campaign`);
    },
  }),
)(UploadDetails);
