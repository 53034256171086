import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { getUserSystem, hasPermission } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import LineGraphDualAxis from '../../components/LineGraphDualAxis';
import Loading from '../../components/Loading';
import SalesByWeekTable from '../../components/retail/SalesByWeekTable';

import { salesByWeek as explanation } from '../../components/Explainer/Explanations';
import { pageVariants } from '../../../config/pageVariants';
import {
  setAccountName,
  fetchAccountNames,
} from '../../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  resetReport,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRetailType,
  setRefreshDisabledStatus,
  loadSalesByWeekGraphData,
  setSelectedProduct,
} from '../../redux/reducers/retail';

const TableContainer = styled.div`
margin-bottom: 3rem;
scrollbar-width: thin;
scrollbar-color: ${({ theme }) => theme.tableColor1} #fff;

&::-webkit-scrollbar {
    width: 14px;
}

&::-webkit-scrollbar-track {
	background: ${({ theme }) => theme.primary};
    border-top: 6px solid white;
    border-bottom: 7px solid white;
	
}

&::-webkit-scrollbar-thumb {
    box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme }) => theme.primary} !important; 
}

`;

const SalesByWeek = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  onAccountNameChange,
  likeForLike,
  likeForLikes,
  setLoading,
  fetchCategories,
  fetchAccountNames,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  onLikeForLikeChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  level4,
  level4s,
  onLevel5Change,
  level5,
  level5s,
  isLoading,
  salesByWeekGraphData,
  loadSalesByWeekGraphData,
  salesByWeekTableData,
  isRefreshDisabled,
  unlockRefresh,
  onProductChange,
  products,
  selectedProduct,
  location,
  onResetReport,
  onRetailTypeChange,
  retailType,
  retailTypes,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('sales_by_week', false, { timeframe: 'MAT' });

    return () => onUnmount();
  }, []);
  const formattedExportTableData =
    salesByWeekTableData &&
    salesByWeekTableData.datasets.map(data => data.data);
  return (
    <motion.div
      key={'SalesByWeek-REPORT-ID'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        explanationTooltip={explanation.page.tooltip}
        refresh={{
          refreshHandler: () => loadSalesByWeekGraphData(),
          isRefreshDisabled,
        }}
        exports={{
          CSV: {
            headers: salesByWeekTableData && salesByWeekTableData.headers,
            data: formattedExportTableData,
          },
        }}
        title={'Sales By Week'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Sales By Week for latest MAT' },
          {
            name: 'accountName',
            valueKey: 'RegNo',
            labelKey: 'CustomerName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
            clearable: true,
          },
        ]}>
        <DataDropdownsContainer
          unlockRefresh={() => unlockRefresh()}
          dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            },
            {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level4,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            },
            {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level5,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            },
            {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSuppliers,
              options: suppliers,
              handler: option => onSupplierChange(option),
              isMulti: true,
            },
            {
              name: 'Brand',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrands,
              options: brands,
              handler: option => onBrandChange(option),
              isMulti: true,
            },
            {
              name: 'Product',
              valueKey: 'WVProdCode',
              labelKey: 'WVProdDesc',
              value: selectedProduct,
              options: products,
              handler: option => onProductChange(option),
              isMulti: false,
            },
            {
              name: 'retailType',
              condition: hasPermission(`role_view_${getUserSystem().toLowerCase()}_independent_data`) ? ['display']  : [],
              valueKey: 'ID',
              labelKey: 'Text',
              value: retailType,
              options: retailTypes,
              handler: option => onRetailTypeChange(option),
              isMulti: false,
            },
          ]}
        />
      </ReportSettingsContainer>
      <ReportContainer padding={3}>
        <div className="container pt-3">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="nam-dash nam-dash-table">
                <div>{isLoading && <Loading />}</div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}>
                  <LineGraphDualAxis
                    isLoading={isLoading}
                    data={salesByWeekGraphData}
                    isSingleYAxes
                    isCurrency
                  />
                </motion.div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div>{isLoading && <Loading />}</div>
              {salesByWeekTableData && !isLoading && (
                <TableContainer
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                  className="overflow-auto">
                  <SalesByWeekTable
                    id="sku_table"
                    data={salesByWeekTableData}
                    className="c-objects-wrap--sticky"
                    tableClassName="c-table c-table--standard"
                  />
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  likeForLike: state.retail.likeForLike,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategory: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrands,
  level4: state.retail.level4,
  level4s: state.retail.level4s,
  level5: state.retail.level5,
  level5s: state.retail.level5s,
  retailType: state.retail.retailType,
  retailTypes: state.retail.retailTypes,
  isLoading: state.retail.isLoading,
  salesByWeekGraphData:
    state.retail.salesByWeekGraphData &&
    state.retail.salesByWeekGraphData.graph,
  salesByWeekTableData:
    state.retail.salesByWeekGraphData &&
    state.retail.salesByWeekGraphData.table,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  selectedProduct: state.retail.selectedProduct,
  products: state.retail.products,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),

    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    unlockRefresh: () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: option => dispatch(setSuppliers(option)),
    onBrandChange: option => dispatch(setBrands(option)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onRetailTypeChange: option => dispatch(setRetailType(option || '')),
    loadSalesByWeekGraphData: () => dispatch(loadSalesByWeekGraphData()),
    onProductChange: option => dispatch(setSelectedProduct(option)),
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(
        fetchCategories('retailer_performance', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesByWeek);
