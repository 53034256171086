import { REHYDRATE } from 'redux-persist/constants';

function systemSettings(state = {}, action) {
  switch (action.type) {
    case 'SET_DEPOT_SELECT_TYPE':
      return {
        ...state,
        depotSelectType: action.depotSelectType,
      };
    case 'SET_DEPOT_SELECT_FILTER_DATA':
      return {
        ...state,
        depotDataUpdateDue: true,
        depotSelectFilterData: action.data,
      };

    case 'UPDATED_VIEW':
      return {
        ...state,
        depotDataUpdateDue: false,
      };

    case 'TOGGLE_OFFSCREEN_ELEMENT': {
      let elements = state.offscreenOpen ? [...state.offscreenOpen] : [];
      const position = elements.indexOf(action.element);
      if (position === -1) {
        elements.push(action.element);
      } else {
        elements = [...elements.slice(0, position), ...elements.slice(position + 1)];
      }

      return {
        ...state,
        offscreenOpen: elements,
      };
    }
    case 'USER_LOGOUT':
      return {
        offscreenOpen: [],
        depotSelectType: 'filter',
        rehydrated: true,
      };

    case REHYDRATE: {
      const hydratedSettings = action.payload.systemSettings ? { ...action.payload.systemSettings } : {};
      hydratedSettings.rehydrated = true;

      return hydratedSettings;
    }
    default:
      return state;
  }
}

export default systemSettings;
