import React from 'react';
import PropTypes from 'prop-types';
import { validateId } from '../../../helpers/helpers';


class AlchToggle extends React.Component {

	state = {
		on: this.props.on,
		uid: this.props.uid,
        }

        componentDidUpdate(prevProps){
         if(prevProps.on !== this.props.on){
          this.setState({
                  on: this.props.on,
          });
         }
        }

	onChange = ()=>{
		const toggleId = validateId(this.props.id);
		const newValue = !this.state.on;
		this.setState({
			on: newValue,
		});
		this.props.onChange(newValue, toggleId, this.state.uid);
	}

	render() {
		const toggleId = validateId(this.props.id);
		return (
			<div className="c-toggle">
				<input
					type="checkbox"
					id={toggleId}
					name={toggleId}
					checked={this.state.on}
					onChange={this.onChange}
				/>
				<label htmlFor={toggleId}>{this.props.label}</label>
			</div>
		);
	}
}

AlchToggle.defaultProps = {
	on: false,
	onChange: ()=>{},
	uid: false,
};

AlchToggle.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	on: PropTypes.bool,
	onChange: PropTypes.func,
	uid: PropTypes.any, /* anything that helps identify instance within context */
};

export default AlchToggle;
