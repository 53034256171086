import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-family: 'Gotham-Medium';
  font-size: 0.8rem;
  color: ${props => props.theme.fontColor};
  margin-top: 0.6rem;
`;
export default function DateLabel({ weeks }) {
  return (
    <div>
      <Text>Displaying {weeks} weeks data</Text>
    </div>
  );
}
