import React, { useState } from 'react';
import styled from 'styled-components';
import { BasketAnalysis } from '../../pages/Retail/BasketAnalysis';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
`;

const TableHeader = styled.div`
  background: ${({ theme: { tableColor1 } }) => tableColor1};
  color: #fff;
  text-transform: uppercase;
  padding: 0.5rem 2rem;
`;

const TableRow = styled.div`
  text-transform: uppercase;
  padding: 0.5rem 2rem;
  display: flex;
  .row-text {
    color: #000;
    font-weight: 700;
  }

  div:first-of-type {
    flex: 2;
    padding: 0.5rem 0;
  }

  div:last-of-type {
    flex: 0;
  }
`;

const BasketAnalysisTable = () => {
  const [rows, setRows] = useState([
    { product: 'Product 1', data: 65 },
    { product: 'Product 2', data: 23 },
    { product: 'Product 3', data: 89 },
    { product: 'Product 4', data: 77 },
    { product: 'Product 5', data: 35 },
    { product: 'Product 6', data: 64 },
    { product: 'Product 7', data: 25 },
    { product: 'Product 8', data: 6 },
  ]);
  return (
    <Table>
      <TableHeader>Top 5 implules Skus Purchaes with product x</TableHeader>
      {rows.map(({ product, data }) => (
        <BasketAnalysisTableRow product={product} data={data} />
      ))}
    </Table>
  );
};

const BasketAnalysisTableRow = ({ product, data }) => {
  return (
    <TableRow>
      <div>
        <span className="row-text">
          {product} | Purchased {data}% of the time
        </span>
      </div>
      <div className="btn btn-primary">View Sku Performance</div>
    </TableRow>
  );
};

export default BasketAnalysisTable;
