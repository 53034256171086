import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';

const ForgotPasswordForm = ({ data, email, onSubmitForm, onChange, error }) => (
  <div>
    <form action="#" onSubmit={onSubmitForm}>
      <div>
        <div className="form-group row">
          <div className="col-sm-12">
            <input className="form-control" id="inputUser" type="email" name="email" placeholder="Email" tabIndex="1" value={data.email} onChange={ev => onChange(ev)} required autoFocus />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <button type="submit" className="btn alchemy-btn--others" tabIndex="3">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
    {error.length > 0 && <p style={{ color: '#fff', width: '250px' }}>{error}</p>}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {};
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {}),
  lifecycle({}),
  withState('data', 'handleData', { message: '', showMessage: false, email: '' }),
  withHandlers({
    onChange: ({ data, handleData }) => ev => {
      const { value } = ev.target;
      handleData({ ...data, email: value });
    },
    onSubmitForm: ({ data, handleData, onResetPassword }) => event => {
      event.preventDefault();
      onResetPassword(data.email);
    },
  }),
)(ForgotPasswordForm);
