import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withTheme } from 'styled-components';

class TableCustomerComplianceReport extends React.Component {
  renderHeader = head => {
    return (
      <tr>
        <th
          className="w-200"
          style={{
            width: '100px',
            backgroundColor: this.props.theme.primaryDark,
            color: '#fff',
            paddingLeft: '10px',
          }}>
          &nbsp;
        </th>
        <th
          className="w-200"
          style={{
            width: '100px',
            backgroundColor: this.props.theme.primaryDark,
            color: '#fff',
          }}>
          No. of Undefined Customers
        </th>
        <th
          className="w-200"
          style={{
            width: '100px',
            backgroundColor: this.props.theme.primaryDark,
            color: '#fff',
          }}>
          Value of Undefined Customers
        </th>
        <th
          className="w-200"
          style={{
            width: '100px',
            backgroundColor: this.props.theme.primaryDark,
            color: '#fff',
          }}>
          No. of Other Customers
        </th>
        <th
          className="w-200"
          style={{
            width: '100px',
            backgroundColor: this.props.theme.primaryDark,
            color: '#fff',
          }}>
          Value of Other
        </th>
      </tr>
    );
  };

  renderRow = (row, i) => {
    return (
      <tr key={i} style={{ fontWeight: !row.wholesaler ? 'bold' : 'none' }}>
        <td className="w-200">
          {!row.wholesaler ? 'Total' : row.wholesaler.replace('_', ' ')}
        </td>
        <td className="w-200">{row.customers_undefined_qty}</td>
        <td className="w-200">
          <span>
            <NumberFormat
              displayType="text"
              thousandSeparator={true}
              prefix={'£'}
              value={parseFloat(
                row.customers_undefined_revenue.replace(/[$,]/g, ''),
              ).toFixed(0)}
            />
          </span>
        </td>
        <td className="w-200">{row.customers_other_qty}</td>
        <td className="w-200">
          <span>
            <NumberFormat
              displayType="text"
              thousandSeparator={true}
              prefix={'£'}
              value={parseFloat(
                row.customers_other_revenue.replace(/[$,]/g, ''),
              ).toFixed(0)}
            />
          </span>
        </td>
      </tr>
    );
  };

  render() {
    const tableClass = [
      'o-table o-product-performance o-product-performance--product-performance table-striped',
    ];
    /*  
 *  Obsolete as API returns a total value for all columns
 *  
    let totalNoOfUndefinedCustomers = 0;
    let totalValueOfUndefinedCustomers = 0;
    let totalNoOfOtherCustomers = 0;
    let totalValueOfOther = 0;

    this.props.data.map(row => {
      totalNoOfUndefinedCustomers += parseFloat(row.customers_undefined_qty);
      totalValueOfUndefinedCustomers += parseFloat(
        row.customers_undefined_revenue
      );
      totalNoOfOtherCustomers += parseFloat(row.customers_other_qty);
      totalValueOfOther += parseFloat(row.customers_other_revenue);
    });
    */
    return (
      <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
        <table className={tableClass.join(' ')} id={this.props.id}>
          <thead>{this.renderHeader(this.props.data[0])}</thead>
          <tbody className="table-fixed-body">
            {this.props.data &&
              this.props.data.map((r, i) => {
                return this.renderRow(r, i);
              })}
            {/*   <tr>
              <td className="w-200">&nbsp;</td>
              <td className="w-200">
                <b>{totalNoOfUndefinedCustomers}</b>
              </td>
              <td className="w-200">
                <span>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={true}
                    prefix={"£"}
                    value={parseFloat(totalValueOfUndefinedCustomers).toFixed(
                      0
                    )}
                  />
                </span>
              </td>
              <td className="w-200">
                <b>{totalNoOfOtherCustomers}</b>
              </td>
              <td className="w-200">
                <span>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator={true}
                    prefix={"£"}
                    value={parseFloat(totalValueOfOther).toFixed(0)}
                  />
                </span>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    );
  }
}

TableCustomerComplianceReport.defaultProps = {
  data: [],
  isLoading: false,
};

TableCustomerComplianceReport.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default withTheme(TableCustomerComplianceReport);
