export default {
  dealCommonLink: '',
  wholesalerLink: '',
  listingType: '',
  backgroundImageOverride: '',
  internalDealTypeId: '',
  parentId: '',
  templateID: '',
  category: '',
  pmp: '',
  displayLine1: '',
  displayLine2: '',
  dealDescription: '',
  brand: '',
  productDescription: '',
  c20CharDesc: '',
  sellAt: '',
  buyAt: '',
  youMake: '',
  por: '',
  imageUrl: '',
  showAt: '',
  hideAt: ''
}
