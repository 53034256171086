import React from 'react';

import styled from 'styled-components';
import BarChart from './BarChart';
import { spaceAndRange as explanation } from '../Explainer/Explanations';

const GraphHolder = styled.div`
  display: flex;
  justify-content: space-around;
  
`;

const CategoryPenetrationGraph = ({ data, drillGraphResults }) => {
  return (
    <GraphHolder className="pb-2 col-12">
      <BarChart
        data={data}
        id="summary"
		className="col-6"
        title="Turnover"
        value={'SummaryValue'}
        drillGraphResults={val => drillGraphResults(val)}
        tooltip={explanation.bars.value}
      />
      <BarChart
        data={data}
        id="sku"
        className="col-6"
		title="No of Sku's"
        value={'SkuCount'}
        drillGraphResults={val => drillGraphResults(val)}
        tooltip={explanation.bars.sku}
      />
    </GraphHolder>
  );
};

export default CategoryPenetrationGraph;
