import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import { each, isArray } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';

const SET_TITLE = 'qareport/SET_TITLE';
const SET_LOADING = 'qareport/SET_LOADING';
const FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_WEEK = 'qareport/FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_WEEK';
const FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_DAY = 'qareport/FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_DAY';
const FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_DAY = 'qareport/FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_DAY';
const FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_WEEK = 'qareport/FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_WEEK';

const initialState = {
  title: '',
  systemSalesOutByWeekReport: [],
  systemSalesOutByDayReport: [],
  systemSalesInByDayReport: [],
};

const fetchQAReportSystemSalesOutByWeekAction = createAction(FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_WEEK);
const fetchQAReportSystemSalesOutByDayAction = createAction(FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_DAY);
const fetchQAReportSystemSalesInByDayAction = createAction(FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_DAY)
const fetchQAReportSystemSalesInByWeekAction = createAction(FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_WEEK);
const setLoadingAction = createAction(SET_LOADING);

export const fetchQAReportSystemSalesOutByWeek = () => dispatch => {
  let url = '/route.php?c=qareport/getSystemSalesOutByWeekReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(categories => {
    dispatch(fetchQAReportSystemSalesOutByWeekAction(categories));
    dispatch(setLoadingAction(false));
  });
};

export const fetchQAReportSystemSalesInByDay = () => dispatch => {
  let url = '/route.php?c=qareport/getSystemSalesInByDayReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(categories => {
    dispatch(fetchQAReportSystemSalesInByDayAction(categories));
    dispatch(setLoadingAction(false));
  });
};

export const fetchQAReportSystemSalesInByWeek = () => dispatch => {
  let url = '/route.php?c=qareport/getSystemSalesInByWeekReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(categories => {
    dispatch(fetchQAReportSystemSalesInByWeekAction(categories));
    dispatch(setLoadingAction(false));
  });
};


export const fetchQAReportSystemSalesOutByDay = () => dispatch => {
  let url = '/route.php?c=qareport/getSystemSalesOutByDayReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(categories => {
    dispatch(fetchQAReportSystemSalesOutByDayAction(categories));
    dispatch(setLoadingAction(false));
  });
};

export default handleActions(
  {
    [FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_WEEK]: (state, { payload }) => ({ ...state, systemSalesOutByWeekReport: payload }),
    [FETCH_QA_REPORT_SYSTEM_SALES_OUT_BY_DAY]: (state, { payload }) => ({ ...state, systemSalesOutByDayReport: payload }),
    [FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_DAY]: (state, { payload }) => ({ ...state, systemSalesInByDayReport: payload }),
    [FETCH_QA_REPORT_SYSTEM_SALES_IN_BY_WEEK]: (state, { payload }) => ({ ...state, systemSalesInByWeekReport: payload }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
  },
  initialState,
);

export const selectors = {};
