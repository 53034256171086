import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import Planogram from '../models/Planogram';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';


const SET_IS_PLANOGRAM_CREATED = 'pfpPlanogram/IS_PLANOGRAM_CREATED';
const SET_IS_PLANOGRAM_DELETED = 'pfpPlanogram/IS_PLANOGRAM_DELETED';
const SET_PLANOGRAM = 'pfpPlanogram/SET_PLANOGRAM';
const FETCH_PLANOGRAMS = 'pfpPlanogram/FETCH_PLANOGRAMS';
const SET_LOADING = 'pfpPlanogram/SET_LOADING';
const SET_DEPARTMENT = 'pfpPlanogram/SET_DEPARTMENT';
const SET_CATEGORY = 'pfpPlanogram/SET_CATEGORY';
const FETCH_CATEGORIES = 'pfpPlanogram/FETCH_CATEGORIES';
const SET_FORM = 'pfpPlanogram/SET_FORM';
const RESET_ME = 'pfpPlanogram/RESET_ME';

const baseURL = 'https://api.wsale.co.uk';

const initialState = {
  isLoading: false,
  department: '',
  departments: [],
  category: '',
  categories: [],
  isPlanogramCreated: false,
  isPlanogramDeleted: false,
  planograms: [],
  planogram:Planogram,
  planogramForm: {...Planogram}
  };

  
const setPlanogramAction = createAction(SET_PLANOGRAM);
const fetchPlanogramsAction = createAction(FETCH_PLANOGRAMS);
const setIsPlanogramCreatedAction = createAction(SET_IS_PLANOGRAM_CREATED);
const setIsPlanogramDeletedAction = createAction(SET_IS_PLANOGRAM_DELETED);
const setLoadingAction = createAction(SET_LOADING);

const setDepartmentAction = createAction(SET_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const fetchCategoriesAction = createAction(FETCH_CATEGORIES);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const fetchCategories = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  let url =  '/route.php?c=pfp/planogram/getAllPlanogramCategories';
  customRequest({url: baseURL + url,
      method: 'get',
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
          'Authorization': getToken(),
          'UserId': getUserId(),
          'System': getUserSystem()
      }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    const departments =  [];
    const categories =  [];
    if (response.Departments ) {
      _.each(response.Departments, (department) => {
        departments.push({'ID' : department.Name, 'Text' : department.Name, categories: department.Categories});
        if (department.Name == store.getState().pfpPlanogram.department.ID) {
          _.each(department.Categories, (category) => {
            categories.push({'ID': category.Name, 'Text': category.Name});
          });
        }
      })
    }
    dispatch(fetchCategoriesAction({departments, categories}));
  });
}

export const onDepartmentChange = (department) => (dispatch) => {
  const categories = [];
  if(department && department.categories){
    _.each(department.categories, (category) => {
      categories.push({'ID': category.Name, 'Text': category.Name});
    });
  }
  dispatch(setDepartmentAction({department, categories}));
}


export const onCategoryChange = (category) => (dispatch) => {
  if( category ) {
    category = category.ID;
  } else {
    category = '';
  }
  dispatch(setCategoryAction({category: category}));
  dispatch(fetchPlanograms());
}


export const onSavePlanogram = (uploadFiles) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsPlanogramCreatedAction(false));
  const { planogramForm } = store.getState().pfpPlanogram;
  let data = {...planogramForm};
  //remove extra fields and normalize Department field
  data.Department = data.Department.ID;
  delete data.departments;
  delete data.categories;
  delete data.Updated;
  let url = '/route.php?c=pfp/planogram/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/planogram/update';
  } else {
    delete data.ID;
  }
  let formData = new FormData();
  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map((fieldName) =>{
    const file = uploadFiles[fieldName];
    formData.append(fieldName, file, file.name);
    delete data[fieldName];
  });

  Object.keys(data).map((fieldName) =>{
    formData.append(fieldName, data[fieldName]);
  });

  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          },
          data: formData
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsPlanogramCreatedAction(true));
  });
}



export const fetchPlanograms = () => (dispatch) => {
  const category =  store.getState().pfpPlanogram.category || '';
  dispatch(setLoadingAction(true));
  if(category) {
    let url = '/route.php?c=pfp/planogram/getPlanogramByCategoryWithID&category=' + escape(category)  + '';
    customRequest({url: baseURL + url,
      method: 'get',
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
          'Authorization': getToken(),
          'UserId': getUserId(),
          'System': getUserSystem()
      }
    }).then((response) => {
      dispatch(setLoadingAction(false));
      dispatch(fetchPlanogramsAction(response || []));
    });
  } else {
    dispatch(setLoadingAction(false));
    dispatch(fetchPlanogramsAction([]));
  }

}

export const onSetPlanogram = (record, isAddPlanogram, isDelete) => (dispatch) => {
  const { department, departments, category, categories } = store.getState().pfpPlanogram;
  let formData = {...Planogram};
  isDelete = isDelete || false;
  if (isDelete && record) {
    formData.ID = record.ID;
    dispatch(setPlanogramAction({planogramForm: {...formData, Department:department, departments, Category:category, categories} }));
  }  else if(!isAddPlanogram && record) {
    dispatch(setLoadingAction(true));
    let url = '/route.php?c=pfp/planogram/getListingById&id=' + record.ID + '';
    customRequest({url: baseURL + url,
      method: 'get',
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
          'Authorization': getToken(),
          'UserId': getUserId(),
          'System': getUserSystem()
      }
    }).then((response) => {
      dispatch(setLoadingAction(false));
      if(_.isArray(response)) {
        response = response[0];
      }
      if(_.isObject(response) && response.ID == record.ID) {
        formData = {...Planogram, ...response};
      }
      dispatch(setPlanogramAction({planogramForm: {...formData, Department:department, departments, Category:category, categories} }));
    });
  } else {
    dispatch(setPlanogramAction({planogramForm: {...formData, Department:department, departments, Category:category, categories} }));
  }

}

export const onSetForm = (fieldName, fieldValue) => (dispatch) => {
  const { planogramForm } = store.getState().pfpPlanogram;
  if (fieldName == 'Department') {
    const department = fieldValue;
    const categories = [];
    if(department) {
      if(department && department.categories){
        _.each(department.categories, (category) => {
          categories.push({'ID': category.Name, 'Text': category.Name});
        });
      }
      planogramForm[fieldName] = department;
    } else {
      planogramForm[fieldName] = '';
    }
    planogramForm.categories = categories;
  } else if (fieldName == 'Category') {
    planogramForm[fieldName] = fieldValue.ID || '';
  } else {
    planogramForm[fieldName] = fieldValue;
  }
  dispatch(setFormAction({planogramForm: {...planogramForm}}));
}


export const onDeletePlanogram = (id) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsPlanogramDeletedAction(false));
  let url = `/route.php?c=pfp/planogram/delete&id=${id}`;
  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsPlanogramDeletedAction(true));
  });
}

export const resetMe = () => (dispatch) => {
  dispatch(resetMeAction());
}

export default handleActions({
    [SET_LOADING] : (state, {payload}) => ({ ...state, isLoading: payload}),
    [FETCH_CATEGORIES]: (state, {payload}) => ({ ...state,
      departments: payload.departments,
      categories: payload.categories
    }),
    [SET_DEPARTMENT] : (state, {payload}) => ({ ...state, department: payload.department, categories: payload.categories }),
    [SET_CATEGORY] : (state, {payload}) => ({ ...state, category: payload.category }),
        [SET_PLANOGRAM] : (state, {payload}) => ({ ...state, planogramForm: payload.planogramForm }),
        [FETCH_PLANOGRAMS] : (state, {payload}) => ({ ...state, planograms: payload }),
        [SET_IS_PLANOGRAM_CREATED] : (state, {payload}) => ({ ...state, isPlanogramCreated: payload }),
    [SET_IS_PLANOGRAM_DELETED] : (state, {payload}) => ({ ...state, isPlanogramDeleted: payload }),
    [SET_FORM] : (state, {payload}) => ({ ...state, planogramForm: payload.planogramForm }),
    [RESET_ME]: (state, {payload}) => ({...state, ...initialState}),
}, initialState);

export const selectors = {};

