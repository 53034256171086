import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import {
  getUserSystem,
  getUserRoles,
  hasPermission,
} from '../../../helpers/util';
import { filter } from 'lodash';

const NAV_BUTTON_WIDTH = 380;
const NAV_ARROW_WIDTH = 50;

const Navigation = styled.nav`
  display: flex;
`

const NavigationList = styled.ul.attrs({
  className: "d-flex flex-row flex-nowrap text-nowrap p-0 m-0"
})`
  justify-content: center;
  position: relative;
  right: ${props => props.scroll}px;
  transition: right .5s ease-in-out;
  overflow-x: hidden;
  width: calc(100% + ${props => props.scroll}px);
`

const NavigationArrow = styled.button`
  background: #fff;  
  border: 0;
  height: ${NAV_ARROW_WIDTH}px;
  flex: ${NAV_ARROW_WIDTH}px;
  padding: 0;
  z-index: 10;
  width: 10%;
  align-self: center;
`
function tintHex(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

const NavigationItem = styled.li`
  align-content: center;
  background: ${props => props.theme.subNavbar};
  flex: ${NAV_BUTTON_WIDTH}px;
  font-size: 0.8rem;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  max-width: 300px;
  text-wrap: normal;

  & > a {
    align-content: center;
    display: grid;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    white-space: normal;
    width: 100%;
    color: ${props => props.theme.subNavBarText};
  }

  &.active {
    background: ${props => props.theme.subNavBarActive};
  }

  &:hover {
    background: ${props => props.theme.subNavBarActive};
  }
`

const BottomNav = (props) => {
  const [scroll, setScroll] = React.useState(0);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const currentUrl = props.currentUrl;
    const previousUrl = props.currentUrl;
    if (currentUrl.split('/')[1] !== previousUrl.split('/')[1]) {
      setScroll(0)
    }
  }, [props.currentUrl])

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setScroll(0)
    })
  }, [])

  const navigationRef = React.useRef(null);

  const scrollNav = (direction, links) => {
    const navButtonWidth = navigationRef.current.children[0].clientWidth;
    const maxWidth = NAV_BUTTON_WIDTH * (links.length - 1);

    // Prevent scrolling past max width
    if (scroll >= maxWidth && direction === 'right') {
      return;
    }

    // Prevent scrolling left of the first item
    if (scroll <= 0 && direction === 'left') {
      return;
    }

    switch (direction) {
      case 'left':
        return setScroll(scroll - navButtonWidth)
      case 'right':
        return setScroll(scroll + navButtonWidth)
      default:
        return setScroll(0)
    }
  }

  const clearDrill = (lnk) => {
    if (lnk.indexOf('query') >= 0) {
      props.resetQueryBuilder();
    }
    localStorage.removeItem('drill_data');
  }

  const renderLinkItem = (item, i) => {
    const itemClasses = [
      'nav-item',
      `o-bottom-nav__item_${getUserSystem() === 'unitas' ? 'unitas' : 'others'}`,
    ];

    if (
      props.currentUrl.indexOf(item.link) >= 0 &&
      props.currentUrl.indexOf('query') < 0 &&
      item.link !== "/nam_dash/"
    ) {
      itemClasses.push('active');
    }

    return (
      <NavigationItem className={itemClasses.join(' ')} key={i} theme={theme}>
        <Link
          onClick={() => clearDrill(item.link)}
          className={`${item.reportName.split(' ').length > 2 && 'three-word-link'}`}
          to={item.link}
        >
          {item.reportName}
        </Link>
      </NavigationItem>
    );
  };

  const filterLinks = (links) => {
    if (getUserRoles().filter(role => role.Title === 'role_view_unitas_demo_data').length > 0) {
      links = filter(links, link => {
        if (link?.reportName !== 'Wholesaler Performance' && link?.reportName !== 'Depot Performance') {
          return link;
        }
      });
    }

    // Hide Basket Analysis
    links = links.filter(link => link?.reportName !== 'Basket Analysis');

    // Only show key facts for unitas and demo
    if (getUserSystem() !== 'unitas' && getUserSystem() !== 'demo') {
      links.filter(link => link?.reportName !== 'Key Facts Report');
    }

    // Order  perofrmance tabs on DEMO
    // IMPORTANT : To add a new demo to namdash page you will need to add it to the sort order
    if (
      hasPermission('role_view_demo_group_performance') &&
      props.currentUrl.indexOf('nam_dash') >= 0 &&
      getUserSystem() == 'demo'
    ) {
      links = links.sort((a, b) => a.id - b.id);

      const group = links.filter(
        link => link?.reportName === 'Group Performance',
      )[0];
      const member = links.filter(
        link => link?.reportName === 'Wholesaler Performance',
      )[0];
      const category = links.filter(
        link => link?.reportName === 'Category Performance',
      )[0];
      const supplier = links.filter(
        link => link?.reportName === 'Supplier Performance',
      )[0];
      const brand = links.filter(
        link => link?.reportName === 'Brand Performance',
      )[0];
      const product = links.filter(
        link => link?.reportName === 'Product Performance',
      )[0];
      const business = links.filter(
        link => link?.reportName === 'Business Type Performance',
      )[0];

      if (
        group &&
        member &&
        category &&
        supplier &&
        brand &&
        product &&
        business
      ) {
        links = links.filter(link => !link?.reportName.includes('Performance'));
        links.unshift(
          group,
          member,
          category,
          supplier,
          brand,
          product,
          business,
        );
      }

      return links
    }

    // Hide Brand and Wholesaler Performance if IsOwnCategoryStructure

    if (props.isOwnCategoryStructure) {
      links = links.filter(link => link?.reportName !== 'Brand Performance');
      links = links.filter(link => link?.reportName !== 'Wholesaler Performance');
    }

    let tempLinks = [];
    //  Reorder Retail Links
    // IMPORTANT : To add a new retail page you will need to add it to the sort order
    if (props.currentUrl.indexOf('retail') >= 0) {
      // links = links.sort((a, b) => a.id - b.id);

      

      tempLinks.push(links.find(
        link => link?.reportName === 'Basket Overview',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Basket Detail',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Space & Ranging',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Boston Matrix - Categories',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Boston Matrix - Stores',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Analytics Overview Dashboard',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Analytics KPI Dashboard',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Sales By Week',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Sku Performance',
      ) ?? undefined);

      tempLinks.push(links.find(
        link => link?.reportName === 'Gap Analysis Retail',
      ) ?? undefined);
    }
    return tempLinks.length > 0 ? tempLinks : links
  }

  if (props.links.length < 1) {
    return null;
  }

  const navClasses = ['o-bottom-nav'];

  if (props.brand) {
    navClasses.push(`t-brand-${props.brand}`);
  }

  let links = filterLinks(props.links)

  return (
    <Navigation className="lower-menu lower-menu--nam-dash-bg">
      <NavigationArrow onClick={e => scrollNav('left', links)} className="left lower-menu--nam-dash-bg">
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </NavigationArrow>
      <NavigationList scroll={scroll} ref={navigationRef}>
        {filter(links, item => item?.reportName.length > 0).map((item, i) => {
          return renderLinkItem(item, i)
        })}
      </NavigationList>
      <NavigationArrow onClick={e => scrollNav('right', links)} className="right lower-menu--nam-dash-bg">
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </NavigationArrow>
    </Navigation>
  )
}

BottomNav.defaultProps = {
  currentUrl: '/',
  brand: '',
  links: [],
  isOwnCategoryStructure: false,
};

BottomNav.propTypes = {
  currentUrl: PropTypes.string,
  brand: PropTypes.string,
  links: PropTypes.array,
  isOwnCategoryStructure: PropTypes.bool,
};

export default BottomNav;
