import React from 'react';
export default ({ pdf, type, closeToast }) => {
  let style = {
    background: 'transparent',
    border: 'none',
    color: '#fff',
    float: 'right',
  };

  return (
    <div>
      <a
        style={{ lineHeight: '50px', color: '#fff' }}
        target="_blank"
        href={pdf}>
        Click here to download
      </a>
      <button style={style} onClick={closeToast}>
        X
      </button>
    </div>
  );
};
