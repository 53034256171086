import * as Sentry from '@sentry/react';
import { BrowserTracing, Integrations } from '@sentry/tracing';
import { isUAT } from './helpers/util';

let ENVIRONMENT = 'uat';

if (!isUAT()) {
  ENVIRONMENT = 'production';
}

Sentry.init({
  dsn: 'https://e7f42023e74248038d23b0ede97e5671@o1289160.ingest.sentry.io/6507179',
  environment: ENVIRONMENT,
  integrations: [new BrowserTracing()],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
      });
    }
  
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
