import React from 'react';
import PropTypes from 'prop-types';
import { getUserSystem } from '../../helpers/util';

class Modal extends React.Component {
  componentWillMount() {
    if (this.props.open) {
      this.openModal();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }

  componentWillUnmount() {
    this.closeModal();
  }

  openModal = () => {
    $('body').addClass('is-modal-open');

    $('.has-offscreen').each((i, el) => {
      $(el).removeClass('has-offscreen');
      this.changedEls = this.changedEls ? this.changedEls : [];
      this.changedEls.push(el);
    });
  };

  closeModal = () => {
    $('body').removeClass('is-modal-open');

    if (this.changedEls) {
      this.changedEls.forEach(el => {
        $(el).addClass('has-offscreen');
      });
      this.changedEls = [];
    }
  };

  render() {
    if (!this.props.children || !this.props.open) {
      return null;
    }

    const modalClasses = ['c-modal', 'u-center-child'];
    if (this.props.size) {
      modalClasses.push(`c-modal--${this.props.size}`);
    }

    return (
      <div className="c-modal-backdrop-wrap u-center-wrap">
        <div className={modalClasses.join(' ')}>
          <button className={`btn c-modal__close alchemy-btn--${getUserSystem() === 'unitas' ? 'unitas' : 'others'}`} onClick={this.props.onCloseClick}>
            Close
          </button>
          <div className="c-modal__content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  open: false,
  children: false,
  onCloseClick: Function.prototype,
  size: 'default',
};

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  onCloseClick: PropTypes.func,
  size: PropTypes.string,
};

export default Modal;
