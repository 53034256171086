import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from '../../components/navigations/BottomNav';
import { getSubmenuPages } from '../../../helpers/api';

import ClassificationPage from '../ClassificationPage';
import BulkCleansingPage from '../BulkCleansingPage';

export default class Admin extends React.Component {
  state = {
    subpage_nav: [],
  };

  render() {
    return (
      <div>
        <Switch>
          <Route
            path={`${process.env.APP_ROOT}admin/:subpage(classification)`}
            render={pageProps => (
              <ClassificationPage {...this.props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}admin/:subpage(bulk-cleansing)`}
            render={pageProps => (
              <BulkCleansingPage {...this.props} {...pageProps} />
            )}
          />
        </Switch>
        <BottomNav
          links={this.state.subpage_nav}
          currentUrl={this.props.location.pathname}
          brand="a"
        />
      </div>
    );
  }
}

Admin.defaultProps = {
  location: {},
};

Admin.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
