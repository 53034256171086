import ExampleUrl from '../../../assets/images/example.png';

export default [
  {
    name: 'Supplier Trend Report',
    reportKey: 'supp_trend',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: false,
    sortOrder: 1,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 1,
    day: '',
    showLimit: false,
  },
  {
    name: 'Category Trend Report',
    reportKey: 'cat_trend',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: false,
    sortOrder: 2,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 1,
    day: '',
    showLimit: false,
  },
  {
    name: 'Supplier Performance',
    reportKey: 'sup_perf',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: true,
    sortOrder: 3,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 10,
    day: '',
    showLimit: true,
  },
  {
    name: 'Category Performance',
    reportKey: 'cat_perf',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: true,
    sortOrder: 4,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 10,
    day: '',
    showLimit: true,
  },
  /*{
    name: 'Department Performance',
    reportKey: 'dep_perf',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: true,
    sortOrder: 5,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 10,
    day: '',
    showLimit: true,
  },*/
  {
    name: 'Member Performance',
    reportKey: 'mem_perf',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: true,
    sortOrder: 6,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 10,
    day: '',
    showLimit: true,
  },
  {
    name: 'Product Performance',
    reportKey: 'top_skus',
    measure: '',
    timeframe: '',
    disableClone: true,
    showInterval: true,
    sortOrder: 7,
    imageUrl: ExampleUrl,
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    allCategories: [],
    allSubCategories: [],
    allLevel4s: [],
    allLevel5s: [],
    members: [],
    suppliers: [],
    isCloned: false,
    isIncluded: true,
    isValidationFailed: false,
    limit: 10,
    day: '',
    showLimit: true,
  },
];
