import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from '../../components/navigations/BottomNav';
import { getSubmenuPages } from '../../../helpers/api';

import Adverts from '../PFP/adverts';
// import Deals from '../PFP/deals';
import Dropship from '../PFP/dropship';
import CoreRange from '../PFP/corerange';
import NPD from '../PFP/npd';
import Planogram from '../PFP/planogram';
import Wholesalers from '../PFP/wholesalers';

export default class PlanForProfit extends React.Component {
        state = {
                subpage_nav: [],
        }

        render() {
                return (
                                <Switch>
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/adverts`}
                                          render={pageProps=>(<Adverts {...this.props} {...this.pageProps} />)}
                                        />
                                        {/* <Route
                                          path={`${process.env.APP_ROOT}pfp/deals`}
                                          render={pageProps=>(<Deals {...this.props} {...this.pageProps} />)}
                                        /> */}
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/dropship`}
                                          render={pageProps=>(<Dropship {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/corerange`}
                                          render={pageProps=>(<CoreRange {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/npd`}
                                          render={pageProps=>(<NPD {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/planogram`}
                                          render={pageProps=>(<Planogram {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}pfp/wholesalers`}
                                          render={pageProps=>(<Wholesalers {...this.props} {...this.pageProps} />)}
                                        />
                                        <Redirect
                                                from={`${process.env.APP_ROOT}pfp`}
                                                to={`${process.env.APP_ROOT}pfp/adverts`}
                                        />
                                </Switch>
                );
        }
}

PlanForProfit.defaultProps = {
        location: {},
};

PlanForProfit.propTypes = {
        alertAdd: PropTypes.func.isRequired,
        location: PropTypes.shape({ pathname: PropTypes.string }),
};

