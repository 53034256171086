import React, { Component } from 'react';
import PropTypes, { array } from 'prop-types';
import Dragula from 'react-dragula';
import AlchSelectableBox from '../../components/AlchUI/AlchSelectableBox';
import 'dragula/dist/dragula.min.css';
import { toast } from 'react-toastify';


class PivotTable extends Component {
    state = {
        tempColumn_Order: [],
      };
      
	componentDidMount() {
		this.dragulaInit();
        if (!this.state.tempColumn_Order.length > 0) {
            this.buildBaseData();
        }
	}

	componentDidUpdate(nextProps) {
        if (this.props.orderArray.length != nextProps.orderArray.length) {
            this.resolveAddRemove(nextProps)
            this.props.onTempOrderChange(this.props.orderArray);
        }
	}

    resolveAddRemove = (nextProps) => {
        let tempArray = this.state.tempColumn_Order;

        if (this.props.orderArray.length > nextProps.orderArray.length) {
            tempArray.push(this.props.orderArray[this.props.orderArray.length - 1])
            this.setState({ tempColumn_Order: tempArray });
        } else {
            const index = tempArray.indexOf(nextProps.orderArray[nextProps.orderArray.length - 1])
            tempArray.splice(index, 1);
            this.setState({ tempColumn_Order: tempArray });
        }
    }

	dragulaInit = ()=>{
		this.Drag = Dragula(
            [
                document.querySelector('#available'), 
                document.querySelector('#rows'),
                document.querySelector('#columns'),
                document.querySelector('#values'),
            ]).on('drop', this.reorderColumns);

    }

    buildBaseData = () => {
        let tempColArray = [];
        let newArray = this.state.tempColumn_Order;
        //TODO: MASSIVE refactoring, not happy with this at all.
        this.props.builtQuery.Column_Order.forEach(item => {
            let array = item.label.split("-");
            //value-13wk_ty becomes [value, 13wk_ty]
            if (array.length > 1) {
                //If it was volume-9l or such, keeps the name together
                if (!array[1].includes("_")) {
                    array[0] = array[0] + "-" + array [1];
                    if (array.length > 2) {
                        let timeArray = array[2].split("_");
                        array.push(timeArray[0]);
                        //After splitting it up into [value] and [13wk, ty]. 
                        //We are only keeping [value, 13wk] and adding year seperately
                        array.splice(1,2);
                    } else {
                        array.splice(1,1);
                    }
                    
                } else {
                    let timeArray = array[1].split("_");
                    array.push(timeArray[0]);
                    array.splice(1,1); 
                }
                tempColArray.push(array[0]);
                tempColArray.push(array[1]);
            } else {
                newArray.push(item);
            }
        });
        tempColArray = tempColArray.filter(function(item, pos) {
            return tempColArray.indexOf(item) == pos;
        })

        tempColArray.forEach(item => {
            newArray.push({
                    label: item,
                    name: item,
                    resultset: item,
                    sort_name: item,
                })
        });

        this.setState({ tempColumn_Order: newArray });
    }

    reorderData = (target, el, nextEl)=>{
        const columnOrder = [...this.props.builtQuery.Pivot_Table[target]];
        const elSortName = $(el).data('label');
        const elIndex = columnOrder.findIndex(item=>item.sort_name === elSortName);
        /* if there no next element insert to the end of array */
        if (!nextEl) {
            const elItem = columnOrder.splice(elIndex, 1)[0];
            columnOrder.push(elItem);

        /* if there is insert before this element in an array */
        } else {
            const nextElSortName = $(nextEl).data('label');
            const elItem = columnOrder.splice(elIndex, 1)[0];
            const nextElIndex = columnOrder.findIndex(item=>item.sort_name === nextElSortName);
            columnOrder.splice(nextElIndex, 0, elItem);
        }
        this.props.onColChange(target, columnOrder);
    }

    measureValidation = (source, target) => {
        //TODO: Find better way of resolving the measures into a list.
        const measures = ["value", 'volume', 'value-in', 'volume-in', 'volume-hectolitres', 'volume-9l', 'volume-stick', 'volume-grams', 'customer count'];
        if (target.id != "values" && measures.includes(source.resultset)) {
            this.Drag.cancel(true);
            toast.info("Measures can only be assigned to fields");
            return false;
        } else if (target.id == "values" && !measures.includes(source.resultset)){
            this.Drag.cancel(true);
            toast.info("Only measures can be assigned to fields");
            return false;
        } 
        return true;
    }

	reorderColumns = (el, target, source, nextEl)=>{
            if (source.id !== target.id) {
                let data = this.props.builtQuery.Pivot_Table;
                let columnOrder = [...this.state.tempColumn_Order];
                if (source.id == "available") {
                    //if item is moved to a new section FROM the original list
                    this.Drag.cancel(true);
                    const elSortName = $(el).data('label');
                    const elIndex = columnOrder.findIndex(item=>item.sort_name === elSortName);
                    if (this.measureValidation(columnOrder[elIndex], target)) {
                        if (!nextEl) {
                            data[target.id].push(columnOrder[elIndex]);
                        } else {
                            const nextElSortName = $(nextEl).data('label');
                            const nextElIndex = data[target.id].findIndex(item=>item.sort_name === nextElSortName);
                            data[target.id].splice(nextElIndex, 0, columnOrder[elIndex]);
                        }
                        columnOrder.splice(elIndex, 1);
                        this.props.onChange(columnOrder);
                        this.props.onAddValue(data);
                    }
                    

                } else if (target.id == "available") {
                    //if item is moved INTO the original list
                    this.Drag.cancel(true);
                    const elSortName = $(el).data('label');
                    const elSourceIndex = data[source.id].findIndex(item=>item.sort_name === elSortName);

                    if (!nextEl) {
                        columnOrder.push(data[source.id][elSourceIndex]);
                    } else {
                        const nextElSortName = $(nextEl).data('label');
                        const nextElIndex = columnOrder.findIndex(item=>item.sort_name === nextElSortName);
                        columnOrder.splice(nextElIndex, 0, data[source.id][elSourceIndex]);
                    }

                    data[source.id].splice(elSourceIndex, 1);
                    this.props.onChange(columnOrder);
                    this.props.onAddValue(data);
                } else {
                    //If item is moved from any list NOT the original TO AND FROM
                    const elSortName = $(el).data('label');
                    const elSourceIndex = data[source.id].findIndex(item=>item.sort_name === elSortName);

                    if (this.measureValidation(data[source.id][elSourceIndex], target)) {
                        if (!nextEl) {
                            data[target.id].push(data[source.id][elSourceIndex]);
                        } else {
                            const nextElSortName = $(nextEl).data('label');
                            const nextElIndex = data[target.id].findIndex(item=>item.sort_name === nextElSortName);
                            data[target.id].splice(nextElIndex, 0, data[source.id][elSourceIndex]);
                        }
                        data[source.id].splice(elSourceIndex, 1);
                        this.props.onAddValue(data);
                    }
                }
            } else {
                if (target.id != "available") {
                    //If items are reordered NOT in the original list TODO: Refactor
                    this.reorderData(target.id, el, nextEl);
                } else {
                    //Original reordering
                    const columnOrder = [...this.state.tempColumn_Order];
                    const elSortName = $(el).data('label');
                    const elIndex = columnOrder.findIndex(item=>item.sort_name === elSortName);
                    /* if there no next element insert to the end of array */
                    if (!nextEl) {
                        const elItem = columnOrder.splice(elIndex, 1)[0];
                        columnOrder.push(elItem);
        
                    /* if there is insert before this element in an array */
                    } else {
                        const nextElSortName = $(nextEl).data('label');
                        const elItem = columnOrder.splice(elIndex, 1)[0];
                        const nextElIndex = columnOrder.findIndex(item=>item.sort_name === nextElSortName);
                        columnOrder.splice(nextElIndex, 0, elItem);
                    }
                    this.props.onChange(columnOrder);
                }
            }
	}

	render() {
		return (
			<div className="row mt-3">
				<div className="col-3 base-column">
                    <div className="default-content p-3 h-100">
                        <h3>Available Fields</h3>
                        <ul
                        className="d-flex h-100 flex-column o-multiselect u-reset-list u-layout" 
                        id="available">
                            {this.state.tempColumn_Order.map(item => {
                                return (
                                <li key={`${item.sort_name}`} data-label={`${item.sort_name}`}>
                                    <AlchSelectableBox
                                    label={item.label}
                                    id={item.sort_name}
                                    className="c-selectable-box"
                                    on={false}
                                    />
                                </li>
                            )})}
                        </ul>
                    </div>
					
				</div>
				<div className="col-3">
                    <div className="h-100 column-content p-3">
                        <h3>Rows</h3>
                        <ul
                        className="d-flex flex-column o-multiselect u-reset-list u-layout h-100" 
                        id="rows">
                            {this.props.builtQuery.Pivot_Table.rows.map(item => {
                                return (
                                <li key={`${item.sort_name}`} data-label={`${item.sort_name}`}>
                                    <AlchSelectableBox
                                    label={item.label}
                                    id={item.sort_name}
                                    className="c-selectable-box"
                                    on={false}
                                    />
                                </li>
                            )})}
                        </ul>
                    </div>
				</div>
				<div className="col-6">
                    <div className="h-100 column-content">
                        <div className="row p-3 h-25">
                            <h3>Columns</h3>
                            <ul 
                            className="d-flex ml-2 o-multiselect h-100 u-reset-list u-layout h-100" 
                            id="columns">
                                {this.props.builtQuery.Pivot_Table.columns.map(item => {
                                return (
                                <li key={`${item.sort_name}`} data-label={`${item.sort_name}`}>
                                    <AlchSelectableBox
                                    label={item.label}
                                    id={item.sort_name}
                                    className="c-selectable-box"
                                    on={false}
                                    />
                                </li>
                            )})}
                            </ul>						
                        </div>
                        <div className="row p-3 mt-2 h-73">
                            <h3>Fields</h3>
                            <ul 
                            className="d-flex ml-2 o-multiselect u-reset-list u-layout h-100" 
                            id="values">
                                {this.props.builtQuery.Pivot_Table.values.map(item => {
                                return (
                                <li key={`${item.sort_name}`} data-label={`${item.sort_name}`}>
                                    <AlchSelectableBox
                                    label={item.label}
                                    id={item.sort_name}
                                    className="c-selectable-box"
                                    on={false}
                                    />
                                </li>
                            )})}
                            </ul>
                        </div>
                    </div>
				</div>
			</div>
		);
	}
}

PivotTable.defaultProps = {
	onChange: Function.prototype,
    onTempOrderChange: Function.prototype,
    onColChange: Function.prototype,
    onAddValue: Function.prototype,
    tempColumn_Order: [],
};

PivotTable.propTypes = {
	orderArray: PropTypes.array.isRequired,
	onChange: PropTypes.func,
    onTempOrderChange: PropTypes.func,
    onColChange: PropTypes.func,
    onAddValue: PropTypes.func,
	builtQuery: PropTypes.object,
};


export default PivotTable;
