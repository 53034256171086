import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { request } from '../../helpers/axios';
import { ToastContainer } from 'react-toastify';
import { motion } from 'framer-motion';
import BrandLogo from '../components/util/BrandLogo';

const ForgotPasswordPage = ({ data, onResetPassword }) => {
  return (
    <motion.div key="forgotpassword" className="splash">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="splash__buttons text-center mb-2">
          <div className="d-block splash__image mx-auto">
            <BrandLogo />
          </div>
          <div className="d-block mx-auto mt-5">
            <ForgotPasswordForm
              error={data.errorMsg}
              onResetPassword={obj => onResetPassword(obj)}
            />
          </div>
        </div>
      </div>

      <div className="purple-triangle purple-triangle__left"></div>
      <div className="purple-triangle purple-triangle__right"></div>
    </motion.div>
  );
};
function mapStateToProps(state, ownProps) {
  const data = {};
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {}),
  lifecycle({}),
  withState('data', 'handleData', { errorMsg: '' }),
  withHandlers({
    onResetPassword: ({ data, handleData }) => email => {
      handleData({ ...data, errorMsg: '' });
      let url = '/route.php?c=security/resetPassword&email=' + email;
      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          handleData({
            ...data,
            errorMsg:
              'Please check your email for details on your new password.',
          });
        })
        .catch(err => {
          handleData({
            ...data,
            errorMsg: 'Something went wrong, please try again.',
          });
        });
    },
  }),
)(ForgotPasswordPage);
