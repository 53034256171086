import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddNPD from '../../components/modals/AddNPD';
import { fetchCategories, fetchNpds, onDepartmentChange, onCategoryChange, onSetForm, onDeleteNpd, onSaveNpd, onSetNpd, resetMe } from '../../redux/reducers/pfpNpd';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';

ReactModal.setAppElement('.alchemy_app');
const Npds = ({
  data,
  isLoading,
  npds,
  department,
  departments,
  category,
  categories,
  npdForm,
  onDepartmentChange,
  onCategoryChange,
  onFormSelectChange,
  onFormTextChange,
  onFormDepartmentChange,
  onFormCategoryChange,
  onFormFileChange,
  onSaveNpd,
  onDeleteNpd,
  handleNpdDeleteModal,
  handleNpdAddModal,
}) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openNpdAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handleNpdAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openNpdAddModal} contentLabel="" className="performance-modal">
          <AddNPD
            handleNpdAddModal={() => handleNpdAddModal(null)}
            isLoading={isLoading}
            npdForm={npdForm}
            onSaveNpd={onSaveNpd}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
            onFormDepartmentChange={onFormDepartmentChange}
            onFormCategoryChange={onFormCategoryChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>NPD</h4>
          <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
            <div className="u-1_3 mb-0">
              <div className="form-group">
                <h3 className="o-canvas__search-title ">Department</h3>
                <Select value={department} onChange={onDepartmentChange} options={departments} valueKey="ID" labelKey="Text" />
              </div>
            </div>
            {categories.length ? (
              <div className="u-1_3 mb-0">
                <div className="form-group">
                  <h3 className="o-canvas__search-title ">Category</h3>
                  <Select value={category} onChange={onCategoryChange} options={categories} valueKey="ID" labelKey="Text" />
                </div>
              </div>
            ) : null}
          </div>

          {npds.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Supplier</th>
                  <th>Brand</th>
                  <th>Category</th>
                  <th>Department</th>
                  <th>Advert1</th>
                  <th>Advert2</th>
                  <th>Advert3</th>
                  <th>Advert4</th>
                  <th>Advert5</th>
                  <th>Image</th>
                  <th>StartDate</th>
                  <th>EndDate</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                {npds.map((npd, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleNpdAddModal(npd.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleNpdDeleteModal(npd.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{npd.Supplier}</td>
                      <td>{npd.Brand}</td>
                      <td>{npd.Category}</td>
                      <td>{npd.Department}</td>
                      <td>{npd.Advert1}</td>
                      <td>{npd.Advert2}</td>
                      <td>{npd.Advert3}</td>
                      <td>{npd.Advert4}</td>
                      <td>{npd.Advert5}</td>
                      <td>{npd.Image}</td>
                      <td>{npd.StartDate}</td>
                      <td>{npd.EndDate}</td>
                      <td>{npd.Updated}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openNpdDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this Npd?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteNpd()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleNpdDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Npds found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpNpd.isLoading,
    department: state.pfpNpd.department,
    departments: state.pfpNpd.departments,
    category: state.pfpNpd.category,
    categories: state.pfpNpd.categories,
    isNpdCreated: state.pfpNpd.isNpdCreated,
    isNpdDeleted: state.pfpNpd.isNpdDeleted,
    npds: state.pfpNpd.npds,
    npdForm: state.pfpNpd.npdForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    fetchNpds,
    onDepartmentChange,
    onCategoryChange,
    onSetForm,
    onDeleteNpd,
    onSaveNpd,
    onSetNpd,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchCategories();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isNpdCreated !== this.props.isNpdCreated && this.props.isNpdCreated) {
        this.props.fetchNpds();
        toast.info((this.props.npdForm.ID ? 'Updated' : 'Created') + ' NPD Successfully...');
      }
      if (prevProps.isNpdDeleted !== this.props.isNpdDeleted && this.props.isNpdDeleted) {
        this.props.fetchNpds();
        toast.info('Deleted NPD Successfully...');
      }
    },
  }),
  withState('data', 'handleData', { isAddNpd: false, openNpdAddModal: false, openNpdDeleteModal: false }),
  withHandlers({
    onDepartmentChange: ({ data, handleData, onDepartmentChange }) => target => {
      onDepartmentChange(target);
    },
    onCategoryChange: ({ data, handleData, onCategoryChange }) => target => {
      onCategoryChange(target);
    },
    onFormDepartmentChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Department', target);
    },
    onFormCategoryChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Category', target);
    },
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.ID);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSaveNpd: ({ data, handleData, onSaveNpd }) => () => {
      onSaveNpd(this.uploadFiles || []);
      handleData({ ...data, isAddNpd: false, openNpdAddModal: false });
    },
    onDeleteNpd: ({ data, handleData, onDeleteNpd, npdForm }) => () => {
      onDeleteNpd(npdForm.ID);
      handleData({ ...data, isAddNpd: false, openNpdDeleteModal: false });
    },
    handleNpdDeleteModal: ({ data, handleData, npds, onSetNpd }) => id => {
      handleData({ ...data, openNpdDeleteModal: !data.openNpdDeleteModal });
      onSetNpd(id || '', false, true);
    },
    handleNpdAddModal: ({ data, handleData, onSetNpd }) => id => {
      const isAddNpd = id ? false : true;
      this.uploadFiles = []; //reset uploadFiles
      const openNpdAddModal = !data.openNpdAddModal;
      handleData({ ...data, isAddNpd, openNpdAddModal: openNpdAddModal });
      if (openNpdAddModal) {
        onSetNpd(id, isAddNpd);
      } else {
        onSetNpd(null, isAddNpd);
      }
    },
  }),
)(Npds);
