'use strict';
import { handleActions, createAction } from 'redux-actions';
import { request, customRequest, requestGet } from '../../../helpers/axios';
import {
  encodeText,
  getUserSystem,
  getUserNamDashMeasures,
  getToken,
  getUserId,
  addDrillInfo,
  removeDrillInfo,
} from '../../../helpers/util';
import { setDisableCategoryStructure } from './app';
import { DrillSetter } from '../../../helpers/reduxHelpers/performanceReport';
import {
  formatUrlWithParameters,
  sortDuplicateNextLevelDown,
} from '../../../helpers/helpers';
import { Drill } from '../models/Drill';
import store from '../store';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import { push } from 'react-router-redux';
import { setBrandUrl } from '../../../helpers/reduxHelpers/setBrandUrl';
import { fetchAccountNames, setAccountName } from './filters';
import Json2csv from 'json2csv';

const Json2csvParser = Json2csv.Parser;

const FETCH_DATA = 'report/FETCH_DATA';
const FETCH_PERFORMANCE_DATA_GRAPH = 'report/FETCH_PERFORMANCE_DATA_GRAPH';
const FETCH_PERFORMANCE_DATA_TABLE = 'report/FETCH_PERFORMANCE_DATA_TABLE';
const FETCH_PERFORMANCE_ALL_DATA_TABLE =
  'report/FETCH_PERFORMANCE_ALL_DATA_TABLE';
const SET_REPORT_DEPARTMENTS = 'report/SET_DEPARTMENTS';
const SET_REPORT_CATEGORIES = 'report/SET_CATEGORIES';
const SET_SUBCATEGORIES = 'report/SET_SUBCATEGORIES';
const SET_LEVEL4S = 'report/SET_LEVEL4S';
const SET_LEVEL5S = 'report/SET_LEVEL5S';
const SET_MEASURE = 'report/SET_MEASURE';
const SET_CORE_RANGE = 'report/SET_CORE_RANGE';
const SET_MEMBER_TYPE = 'report/SET_MEMBER_TYPE';
const SET_RETAIL_TYPE = 'report/SET_RETAIL_TYPE';
const SET_LIKE_FOR_LIKE = 'report/SET_LIKE_FOR_LIKE';
const SET_PURCHASE = 'report/SET_PURCHASE';
const SET_TIMEFRAME = 'report/SET_TIMEFRAME';
const SET_OWN_BRAND = 'report/SET_OWN_BRAND';
const SET_BRAND = 'report/SET_BRAND';
const SET_REGION = 'report/SET_REGION';
const SET_FASCIA = 'report/SET_FASCIA';
const SET_TITLE = 'report/SET_TITLE';
const SET_SUBPAGE = 'report/SET_SUBPAGE';
const SET_SELECTED_MEMBERS = 'report/SET_SELECTED_MEMBERS';
const SET_SUPPLIER = 'report/SET_SUPPLIER';
const SET_SUPPLIERS = 'report/SET_SUPPLIERS';
const SET_BRANDS = 'report/SET_BRANDS';
const SET_DEPOT = 'report/SET_DEPOT';
const SET_REPORT_SUPPLIER = 'report/SET_REPORT_SUPPLIER';
const SET_REPORT_MEMBER = 'report/SET_REPORT_MEMBER';
const SET_LOADING = 'report/SET_LOADING';
const SET_EXPORTING = 'report/SET_EXPORTING';
const SET_LOADED = 'report/SET_LOADED';
const SET_GRAPH_LOADED = 'report/SET_GRAPH_LOADED';
const SET_TABLE_LOADED = 'report/SET_TABLE_LOADED';
const SET_HIDE_ALL = 'report/SET_HIDE_ALL';
const EXPORT_TO_PDF_SUCCESS = 'report/EXPORT_TO_PDF_SUCCESS';
const RESET = 'report/RESET';
const SET_BUSINESS_TYPE = 'report/SET_BUSINESS_TYPE';
const SET_CURRENT_REPORT_PAGE = 'report/SET_CURRENT_REPORT_PAGE';
const SET_REPORT_ERROR = 'report/SET_REPORT_ERROR';
const SET_DEPOTS = 'report/SET_DEPOTS';
const ADD_MEASURE = 'report/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'report/RESET_MEASURE_OPTIONS';
const SET_REPORT_TYPE = 'report/SET_REPORT_TYPE';
const SET_DATA_TYPE = 'report/SET_DATA_TYPE';
const SET_COMMISSION = 'report/SET_COMMISSION';
const SET_EXCLUDED_DEPARTMENTS = 'report/SET_EXCLUDED_DEPARTMENTS';
const SET_SELECTED_GROUP = 'report/SET_SELECTED_GROUP';
const SET_MEMBERS = 'report/SET_MEMBERS';
const SET_IS_EXPORTING_TO_PDF = 'report/SET_IS_EXPORTING_TO_PDF';
const SET_IS_DRILL = 'report/SET_IS_DRILL';

const initialState = {
  // baseUrl: '/react',
  baseUrl: '',
  departments: [],
  selectedDepartments: [],
  categories: [],
  selectedCategories: [],
  subCategories: [],
  selectedSubCategories: [],
  members: [],
  brands: [],
  brand: '',
  region: '',
  regions: [],
  fascia: '',
  fascias: [],
  selectedMembers: [],
  suppliers: [],
  supplier: '',
  depots: [],
  selectedDepots: [],
  level4s: [],
  selectedLevel4s: [],
  level5s: [],
  selectedLevel5s: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },    
    ...(getUserSystem() === 'cjlang'
    ? [
        { ID: 'cjl_ytd', Text: 'Financial Year To Date'},
        { ID: 'cjl_fy', Text: 'Financial Year'},
      ]
    : []),
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: '',
  accountName: [],
  accountNames: [],
  excludedDepartments: [],
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  coreRanges: [
    { ID: 'yes', Text: 'Yes' },
    { ID: 'no', Text: 'No' },
  ],
  coreRange: '',
  memberTypes: [
    { ID: 'retail', Text: 'Retail' },
    { ID: 'foodservice', Text: 'Foodservice' },
  ],
  memberType: '',
  retailTypes: [
    { ID: 'independent', Text: 'Independent' },
    { ID: 'company_owned', Text: 'Company Owned' },
  ],
  retailType: '',
  measure: '',
  likeForLike: '',
  purchase: 'out',
  ownBrand: '',
  ownBrands: [
    { ID: 0, Text: 'All' },
    { ID: 1, Text: 'Yes' },
    { ID: 2, Text: 'No' },
  ],
  performanceDataGraph: [],
  performanceDataTable: [],
  performanceAllDataTable: [],
  title: '',
  subPage: '',
  isLoading: false,
  isGraphLoaded: false,
  isTableLoaded: false,
  isExporting: false,
  isExported: false,
  reportType: '',
  currentReportPage: '',
  isLoaded: false,
  hideAll: false,
  pdfURL: '',
  businessTypes: [],
  selectedBusinessType: '',
  reportError: '',
  isInitialLoadComplete: false,
  dataType: { ID: 0, Text: 'Wholesale' },
  dataTypes: [
    { ID: 0, Text: 'Wholesale' },
    { ID: 1, Text: 'Retail' },
  ],
  commission: { ID: 0, Text: 'include' },
  commissions: [
    { ID: 0, Text: 'include' },
    { ID: 1, Text: 'exclude' },
  ],
  selectedGroup: [],
  groups: [],
  isExportingToPDF: false,
  isRollingDateRange: false,
  isDrill: false,
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchPerformanceDataGraphAction = createAction(
  FETCH_PERFORMANCE_DATA_GRAPH,
);
const fetchPerformanceDataTableAction = createAction(
  FETCH_PERFORMANCE_DATA_TABLE,
);
const fetchPerformanceAllDataTableAction = createAction(
  FETCH_PERFORMANCE_ALL_DATA_TABLE,
);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setMeasureAction = createAction(SET_MEASURE);
const setCoreRangeAction = createAction(SET_CORE_RANGE);
const setMemberTypeAction = createAction(SET_MEMBER_TYPE);
const setRetailTypeAction = createAction(SET_RETAIL_TYPE);
const setLikeForLikeAction = createAction(SET_LIKE_FOR_LIKE);
const setPurchaseAction = createAction(SET_PURCHASE);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setCurrentReportPageAction = createAction(SET_CURRENT_REPORT_PAGE);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setOwnBrandAction = createAction(SET_OWN_BRAND);
const setBrandAction = createAction(SET_BRAND);
const setRegionAction = createAction(SET_REGION);
const setFasciaAction = createAction(SET_FASCIA);
const setSupplierAction = createAction(SET_SUPPLIER);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setDepotAction = createAction(SET_DEPOT);
const setTitleAction = createAction(SET_TITLE);
const setSubPageAction = createAction(SET_SUBPAGE);
const setReportSupplierAction = createAction(SET_REPORT_SUPPLIER);
const setReportMemberAction = createAction(SET_REPORT_MEMBER);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setLoadingAction = createAction(SET_LOADING);
const setGraphLoadedAction = createAction(SET_GRAPH_LOADED);
const setTableLoadedAction = createAction(SET_TABLE_LOADED);
const setLoadedAction = createAction(SET_LOADED);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const resetAction = createAction(RESET);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setDepotsAction = createAction(SET_DEPOTS);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setReportTypeAction = createAction(SET_REPORT_TYPE);
const setIsExportingToPDFAction = createAction(SET_IS_EXPORTING_TO_PDF);
const setDataTypeAction = createAction(SET_DATA_TYPE);
const setCommissionAction = createAction(SET_COMMISSION);
const setExcludedDepartmentsAction = createAction(SET_EXCLUDED_DEPARTMENTS);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const setIsDrillAction = createAction(SET_IS_DRILL);

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));

  dispatch(resetAction());
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  const fileName = 'performance-report-' + new Date().toISOString() + '.pdf';
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName,
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const setCurrentReportPage = type => dispatch => {
  dispatch(setCurrentReportPageAction(type));
};

export const loadReport = () => dispatch => {
  dispatch(fetchPerformanceDataTableAction([]));
  dispatch(fetchPerformanceDataGraphAction([]));
  dispatch(setLoadingAction(true));
  dispatch(setTableLoadedAction(false));
  dispatch(setGraphLoadedAction(false));
  loadChart(store, dispatch);
  loadTable(store, dispatch);
  dispatch(setIsDrillAction(false));
};

export const setIsDrill = payload => dispatch => {
  dispatch(setIsDrillAction(payload));
};

export const fetchCategories =
  (type, isReferal, drillObj) => async dispatch => {
    //TODO: investigate why this might not be firing for brand and supplier
    dispatch(setLoadedAction(false));
    const userObj = JSON.parse(localStorage.user_data);
    let url = '/route.php';
    if (userObj.departments.length === 0 && userObj.categories.length === 0) {
      url += '?c=category/getHierarchy';
    } else {
      url += '?c=category/getUserHierarchy';
    }
    const state = store.getState().performanceReport;
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    dispatch(setLoadingAction(true));
    dispatch(fetchAccountNames());
    const result = await requestGet({ url });

    let regions = [];
    let fascias = [];

    if (getUserSystem() == 'smartviewRetail') {
      let urlRegion = '/route.php?c=region/getRegions';
      let regionArray = await requestGet({ url: urlRegion });
      regionArray.forEach(region => {
        regions.push({"value": region})
      });
      
      let urlFascias = '/route.php?c=fascia/getFascias';
      let fasciaArray = await requestGet({ url: urlFascias });
      fasciaArray.forEach(fascia => {
        fascias.push({"value": fascia})
      });
    }

    let url1 = '/route.php?c=wholesaler/getAll';

    let members = await requestGet({ url: url1 });
    
    if (members.length == 1) {
      dispatch(setDisableCategoryStructure(false));
    }

    let url3 = '/route.php?c=brand/getAll';

    if (isReferal) {
      if (drillObj.departments && drillObj.departments.length > 0) {
        url3 += `&department=${encodeURIComponent(drillObj.departments)}`;
      }
      if (drillObj.categories && drillObj.categories.length > 0) {
        url3 += `&category=${encodeURIComponent(drillObj.categories)}`;
      }
      if (drillObj.subCategories && drillObj.subCategories.length > 0) {
        url3 += `&subcategory=${encodeURIComponent(drillObj.subCategories)}`;
      }
      if (drillObj.level4s && drillObj.level4s.length > 0) {
        url3 += `&level4=${encodeURIComponent(drillObj.level4s)}`;
      }
      if (drillObj.level5s && drillObj.level5s.length > 0) {
        url3 += `&level5=${encodeURIComponent(drillObj.level5s)}`;
      }
      if (drillObj.supplier && drillObj.supplier.length > 0) {
        url3 += `&supplier=${encodeURIComponent(drillObj.supplier)}`;
      }
      if (drillObj.businessType && drillObj.businessType.length > 0) {
        url3 += `&supplier=${encodeURIComponent(drillObj.supplier)}`;
      }
    }
    if (state.selectedMembers.length === 1) {
      url3 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands= await requestGet({ url: url3 });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });

    url = '/route.php?c=supplier/getAll';

    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    each(members, mem => {
      mem.Text = encodeText(mem.Text);
    });

    if (
      members.length === 1
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(
        setSelectedMembersAction([
          { Text: members[0].ActualName, ID: members[0].WholesalerNo },
        ]),
      );
      if (type === 'depot') {
        members = [members[0]];
      }
    }

    const orderedMembers = uniqBy(
      orderBy(members, ['ActualName']),
      'ActualName',
    );
    let response;
    
    try {
      response = await requestGet({ url: url });
    } catch (e) {
      console.warn(e);
      response = [];
    }

    let suppliers = [];

    each(response, supp => {
      supp.SupplierName = encodeText(supp.SupplierName);
      suppliers.push(supp);
    });

    url = '/route.php?c=customer/getAllBusinessTypes';

    let businessTypes = [];

    try {
      businessTypes = await requestGet({ url: url });
    } catch {
      console.warn('No business types permission');
    }

    url = '/route.php?c=depot/getDepots';

    if (isReferal && drillObj.members && drillObj.members.length > 0) {
      url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(drillObj.members.split(
        ',',
      ))}`;
    }

    businessTypes =
      businessTypes.map(businessType => {
        return { BusinessName: businessType };
      }) || [];

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    if (type !== store.getState().performanceReport.currentReportPage) {
      return;
    }

    // url = '/route.php?c=customer/getRetailers';

    // const accountNames = await requestGet({ url: url });

    url = '/route.php?c=groupSupplier/getAll';

    let orderedGroups = [];
    try {
      let groups = await requestGet({ url: url });
      groups = groups.map((group, i) => ({ Text: group, ID: i }));
      orderedGroups = orderBy(groups, ['Text']);
    } catch (e) {
      console.warn('Error Fetching Groups', e);
    }

    let departments = result.Departments ? result.Departments : [];
    let orderedDeprtments = orderBy(departments, ['Text']);
    let categories = result.Categories ? result.Categories : [];
    let orderedCategories = orderBy(categories, ['Text']);
    if (departments.length > 1) {
      orderedDeprtments.unshift({ ID: 0, Text: 'Select all', Categories: [] });
    }

    if (categories.length > 1) {
      orderedCategories.unshift({
        ID: 0,
        Text: 'Select all',
        SubCategories: [],
      });
    }
    let defaultMeasure = 'Value';

    let NamDashMeasures = getUserNamDashMeasures(store.getState().performanceReport.dataType.Text);

    if (NamDashMeasures?.length > 0) {
      defaultMeasure = NamDashMeasures[0].charAt(0).toUpperCase() + NamDashMeasures[0].slice(1)
    }

    dispatch(
      fetchDataAction({
        departments: orderedDeprtments,
        categories: orderedCategories,
        suppliers: suppliers,
        brands: brands,
        regions: regions,
        fascias: fascias,
        members: orderedMembers,
        depots: depots,
        groups: orderedGroups,
        purchase: isReferal
          ? store.getState().performanceReport.purchase
          : 'out',
        measure: isReferal
          ? store.getState().performanceReport.measure
          : defaultMeasure,
        coreRange: isReferal
          ? store.getState().performanceReport.coreRange
          : '',
        memberType: isReferal
          ? store.getState().performanceReport.memberType
          : '',
        retailType: isReferal
          ? store.getState().performanceReport.retailType
          : '',
        likeForLike: isReferal
          ? store.getState().performanceReport.likeForLike
          : 'like_for_like',
        timeframe: isReferal
          ? store.getState().performanceReport.timeframe
          : !drillObj.timeframe
          ? (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks')
          : drillObj.timeframe,
        businessTypes: businessTypes,
      }),
    );

    each(result.Categories, cat => {
      cat.Text = encodeText(cat.Text);
    });
    const rptType = store.getState().performanceReport.reportType;
    if (result.Departments && result.Departments.length === 1) {
      let dept = result.Departments[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot' &&
        rptType !== 'level4' &&
        rptType !== 'level5' &&
        rptType !== 'group'
      ) {
        reportType = 'category';
      } else {
        reportType = store.getState().performanceReport.reportType;
      }
      dispatch(setReportDepartmentsAction({ departments: [dept], reportType }));
      each(dept.Categories, cat => {
        cat.Text = encodeText(cat.Text);
      });
      if (dept.Categories.length === 1) {
        let cat = dept.Categories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'subcategory';
        } else {
          reportType = store.getState().performanceReport.reportType;
        }

        dispatch(setReportCategoriesAction({ categories: [cat], reportType }));
        each(cat.SubCategories, subCat => {
          subCat.Text = encodeText(subCat.Text);
        });
        if (cat.SubCategories.length === 1) {
          let subcat = cat.SubCategories[0];
          if (
            rptType !== 'supplier' &&
            rptType !== 'business_type' &&
            rptType !== 'member' &&
            rptType !== 'brand' &&
            rptType !== 'depot'
          ) {
            reportType = 'level4';
          } else {
            reportType = store.getState().performanceReport.reportType;
          }
          dispatch(
            setSubCategoriesAction({ subCategories: [subcat], reportType }),
          );
        }
      }
    }
    if (result.Categories && result.Categories.length === 1) {
      let catt = result.Categories[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot'
      ) {
        reportType = 'subcategory';
      } else {
        reportType = store.getState().performanceReport.reportType;
      }
      dispatch(setReportCategoriesAction({ categories: [catt], reportType }));
      each(catt.SubCategories, subCat => {
        subCat.Text = encodeText(subCat.Text);
      });
      if (catt.SubCategories.length === 1) {
        let subcat = catt.SubCategories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'level4';
        } else {
          reportType = store.getState().performanceReport.reportType;
        }
        dispatch(
          setSubCategoriesAction({ subCategories: [subcat], reportType }),
        );
      }
    } else if (!isReferal) {
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
      return;
    }

    if (!isReferal && store.getState().performanceReport.hideAll === false) {
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
    }
  };

export const setOnLoadParams = (params, reportType) => async dispatch => {
  dispatch(setLoadedAction(false));
  const state = store.getState().performanceReport;
  if (params.measure) dispatch(setMeasureAction(params.measure));
  if (params.coreRange) dispatch(setCoreRangeAction(params.coreRange));
  if (params.memberType) dispatch(setMemberTypeAction(params.memberType));
  if (params.retailType) dispatch(setRetailTypeAction(params.retailType));
  if (params.type) dispatch(setPurchaseAction(params.type));
  if (params.timeframe) dispatch(setTimeFrameAction(params.timeframe));
  if (params.accountName) dispatch(setAccountName(params.accountName));
  if (params.likeForLike) dispatch(setLikeForLikeAction(params.likeForLike));
  if (params.ownBrand)
    dispatch(setOwnBrandAction({ ID: params.ownBrand, Text: params.ownBrand }));
  if (params.dataType) dispatch(setDataTypeAction(params.dataType));
  if (params.commission) dispatch(setCommissionAction(params.commission));
  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (params.departments && params.departments.length > 0) {
    _.each(params.departments, dept => {
      if (_.filter(state.departments, { Text: dept }).length > 0) {
        departments.push(_.filter(state.departments, { Text: dept })[0]);
      }
    });
    dispatch(setReportDepartmentsAction({ departments, reportType }));
  }
  if (params.categories && params.categories.length > 0) {
    _.each(params.categories, catt => {
      if (
        _.filter(flatten(map(departments, dept => dept.Categories)), {
          Text: catt,
        }).length > 0
      ) {
        categories.push(
          _.filter(flatten(map(departments, dept => dept.Categories)), {
            Text: catt,
          })[0],
        );
      }
    });
    if (categories.length > 0) {
      dispatch(setReportCategoriesAction({ categories, reportType }));
    }
  }
  if (params.subCategories && params.subCategories.length > 0) {
    _.each(params.subCategories, subcat => {
      if (
        _.filter(flatten(map(categories, cat => cat.SubCategories)), {
          Text: subcat,
        }).length > 0
      ) {
        subCategories.push(
          _.filter(flatten(map(categories, cat => cat.SubCategories)), {
            Text: subcat,
          })[0],
        );
      }
    });
    if (subCategories.length > 0) {
      dispatch(setSubCategoriesAction({ subCategories, reportType }));
    }
  }
  if (params.level4s && params.level4s.length > 0) {
    _.each(params.level4s, lvl4 => {
      if (
        _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
          Text: lvl4,
        }).length > 0
      ) {
        level4s.push(
          _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
            Text: lvl4,
          })[0],
        );
      }
    });
    if (level4s.length > 0) {
      dispatch(setLevel4sAction({ level4s, reportType }));
    }
  }
  if (params.level5s && params.level5s.length > 0) {
    _.each(params.level5s, lvl5 => {
      if (
        _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
          ID: parseInt(lvl5),
        }).length > 0
      ) {
        level5s.push(
          _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
            ID: parseInt(lvl5),
          })[0],
        );
      }
    });
    if (level5s.length > 0) {
      dispatch(setLevel5sAction({ level5s, reportType }));
    }
  }
  if (typeof params.supplier !== 'undefined' && params.supplier.length > 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName.trim() === params.supplier.trim()) {
        dispatch(
          setSupplierAction({
            supplier: sup,
            reportType: store.getState().performanceReport.reportType,
          }),
        );
      }
    });
  }

  if (params.groups && params.groups.length > 0) {
    dispatch(setSelectedGroupAction(params.groups));
  }

  if (params.brand) {
    let brand = _.filter(state.brands, brd => {
      if (brd.Brand.trim() === params.brand.trim()) {
        return brd;
      }
    });
    let brnd = brand[0];
    if (brnd) {
      brnd.reportType = reportType;
      dispatch(setBrandAction(brnd));
    }
  }

  if (params.members.length > 0) {
    let members = [];
    if (typeof params.members === 'string') {
      const memb = state.members.find(
        member => member.WholesalerNo === params.member,
      );
      members.push({ Text: memb.ActualName, ID: memb.WholesalerNo });
    } else {
      _.each(params.members, memb => {
        members.push({ Text: memb.ActualName, ID: memb.WholesalerNo });
      });
    }

    dispatch(setSelectedMembersAction(members));

    if (params.depots.length > 0) {
      let procDepots = [];
      each(params.depots, dept => {
        each(state.depots, dpt => {
          if (dpt.DepotName === dept) {
            procDepots.push(dpt);
          }
        });
      });
      dispatch(
        setDepotAction({
          selectedDepots: procDepots,
          reportType: state.reportType,
        }),
      );
    }
  } else {
    let members = state.members;
    if (
      members.length === 2 &&
      _.filter(members, member => member.Text === 'ALL').length > 0
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(setSelectedMembersAction([members[0]]));
    }
    dispatch(setDepotsAction([]));
  }

  if (params.businessType) {
    const paramsBusinessTypes = params.businessType.split(',');
    const selectedBusinessTypes = state.businessTypes.filter(type =>
      paramsBusinessTypes.find(busType => busType === type.BusinessName),
    );
    dispatch(
      setBusinessTypeAction({
        selectedBusinessType: selectedBusinessTypes,
        reportType: state.reportType,
      }),
    );
  }

  dispatch(setHideAllAction(false));

  if (state.departments.length === 1 && state.reportType === 'department') {
    dispatch(setReportTypeAction('category'));
  }
};

export const setIsExportingToPDF = value => dispatch => {
  dispatch(setIsExportingToPDFAction(value));
};

export const brandSelect = value => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/brand_performance';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value);
  drillSetter.setDrillProperty('to', '/nam_dash/brand_performance');
  drillSetter.setDrillObj();
  addDrillInfo(drillSetter.drillObj);
  dispatch(setIsDrillAction(true));
  dispatch(push(url));
};
export const productSelect = value => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/product_performance';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value);
  drillSetter.setDrillProperty('to', '/nam_dash/product_performance');
  drillSetter.setDrillObj();
  dispatch(setIsDrillAction(true));
  addDrillInfo(drillSetter.drillObj);
  dispatch(push(url));
};
export const depotSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/depot_performance';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value)
  drillSetter.setDrillProperty('to', '/nam_dash/depot_performance');
  drillSetter.setDrillObj();
  dispatch(setIsDrillAction(true));
  addDrillInfo(drillSetter.drillObj);
  dispatch(push(url));
};
export const supplierSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/supplier_performance?';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value)
  drillSetter.setDrillProperty('to', '/nam_dash/supplier_performance');
  drillSetter.setDrillObj();
  dispatch(setIsDrillAction(true));
  addDrillInfo(drillSetter.drillObj);
  dispatch(push(url));
};
export const wholesalerSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/wholesaler_performance';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value)
  drillSetter.setDrillProperty('to', '/nam_dash/wholesaler_performance');
  drillSetter.setDrillObj();
  dispatch(setIsDrillAction(true));
  addDrillInfo(drillSetter.drillObj);
  dispatch(push(url));
};

export const businessSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/business_type_performance';
  let drillObj = new Drill();
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  const drillSetter = new DrillSetter(selectedAdvancedBusinessTypes, accountName, state, drillObj, value)
  drillSetter.setDrillProperty('to', '/nam_dash/business_type_performance');
  drillSetter.setDrillObj();
  dispatch(setIsDrillAction(true));
  addDrillInfo(drillSetter.drillObj);
  dispatch(push(url));
};

export const goToReferal = (val, to, skip) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + val;
  removeDrillInfo(to);

  dispatch(setIsDrillAction(true));
  dispatch(push(url));
};

export const setReportSupplier = val => dispatch => {
  let supplier = _.filter(
    store.getState().performanceReport.performanceDataTable,
    sup => {
      if (sup.field === val) {
        return sup;
      }
    },
  );

  dispatch(setReportSupplierAction(supplier[0]));
};

export const setReportMember = val => dispatch => {
  let member = _.filter(
    store.getState().performanceReport.performanceDataTable,
    mem => {
      if (mem.field === val) {
        return mem;
      }
    },
  );

  dispatch(setReportMemberAction(member[0]));
};

export const setDepartment = depts => async dispatch => {
  const state = store.getState().performanceReport;

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept.Text === 'TOBACCO') {
        return dept;
      }
    });

    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    let reportType = '';
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand' &&
      state.reportType !== 'subcategory' &&
      state.reportType !== 'level4' &&
      state.reportType !== 'level5' &&
      state.reportType !== 'business_type'
    ) {
      reportType = 'category';
    } else {
      reportType = state.reportType;
    }
    dispatch(setReportDepartmentsAction({ departments: depts, reportType }));

    let supplierUrl = `/route.php?c=supplier/getAll`;
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    supplierUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    let suppliers = await requestGet({ url: supplierUrl });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    brandUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands= await requestGet({ url: brandUrl });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  } else {
    let rptType = state.reportType;
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand' &&
      rptType !== 'business_type'
    ) {
      rptType = 'department';
    }
    dispatch(
      setReportDepartmentsAction({ reportType: rptType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: rptType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: rptType, level5s: [] }));

    let supplierUrl = '/route.php?c=supplier/getAll';

    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url: supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url:brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  }
};

export const setCategory = cats => async dispatch => {
  const state = store.getState().performanceReport;
  let rptType = state.reportType;
  if (cats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setReportCategoriesAction({ categories: cats, reportType: rptType }),
    );
    let supplierUrl = '/route.php?c=supplier/getAll&category=' +
    encodeURIComponent(map(cats, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url: supplierUrl,
        
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brandUrl = `/route.php?c=brand/getAll&category=${encodeURIComponent(
      map(cats, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    brands = await requestGet({
      url:brandUrl,
    });
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand' &&
      rptType !== 'level4' &&
      rptType !== 'level5'
    ) {
      rptType = 'category';
    }
    dispatch(
      setReportCategoriesAction({ reportType: rptType, categories: [] }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: rptType, level5s: [] }));

    let supplierUrl = '/route.php?c=supplier/getAll';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url: supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brandUrl = '/route.php?c=brand/getAll';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url:brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  }
};

export const setSubCategory = subCats => async dispatch => {
  let state = store.getState().performanceReport;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = state.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(
      setSubCategoriesAction({ subCategories: subCats, reportType: rptType }),
    );
    state = store.getState().performanceReport;
    let suppliers;
    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(subCats, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    try {
      suppliers = await requestGet({
        url:supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;


    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(setSubCategoriesAction({ reportType: rptType, subCategories: [] }));
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction({ level5s: [], reportType: rptType }));
    state = store.getState().performanceReport;
    let suppliers;
    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    try {
      suppliers = await requestGet({
        url:
          supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brand Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }

  state = store.getState().performanceReport;
};

export const setLevel4 = opts => async dispatch => {
  let state = store.getState().performanceReport;
  let rptType = state.reportType;
  if (opts && opts.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level5';
    }
    dispatch(setLevel4sAction({ level4s: opts, reportType: rptType }));
    state = store.getState().performanceReport;
    let suppliers;
    try {
      let supplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      ) +
      '&level4=' +
      encodeURIComponent(map(opts, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:
          supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: rptType, level5s: [] }));
    state = store.getState().performanceReport;
    let suppliers;
    try {
      let supplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      ) +
      '';
      if (state.selectedMembers.length === 1) {
        supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:
          supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setLevel5 = lvl5s => async dispatch => {
  let state = store.getState().performanceReport;
  let rptType = state.reportType;
  if (lvl5s.length > 0) {
    dispatch(setLevel5sAction({ reportType: rptType, level5s: lvl5s }));
    state = store.getState().performanceReport;
    let suppliers;
    try {
      let supplierUrl =  '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      ) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '&level5=' +
      encodeURIComponent(map(lvl5s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:
         supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '&level5=' +
          encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brand Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setLevel5sAction({ level5s: [], reportType: rptType }));
    state = store.getState().performanceReport;
    let suppliers;
    try {
      let supplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      ) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:
          supplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));

  const depots = await requestGet({
    url: `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v.Text).join(
      ',',
    ))}`,
  });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};

export const setSupplier = val => async dispatch => {
  const state = store.getState().performanceReport;
  let supplier = {};
  if (val !== 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName === val) {
        supplier = sup;
        dispatch(
          setSupplierAction({
            supplier: sup,
            reportType: store.getState().performanceReport.reportType,
          }),
        );
      }
    });

    let url = '/route.php?c=brand/getAll';
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    url = setBrandUrl(state, url, ['supplier', supplier]);

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(
      setSupplierAction({
        reportType: store.getState().performanceReport.reportType,
        supplier: '',
      }),
    );
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state?.selectedLevel5s?.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    let brands= await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setDepot = val => dispatch => {
  dispatch(
    setDepotAction({
      selectedDepots: val,
      reportType: store.getState().performanceReport.reportType,
    }),
  );
};

export const setTimeFrame = val => dispatch => {
  dispatch(setTimeFrameAction(val));
};

export const setBrand = val => dispatch => {
  dispatch(setBrandAction(val));
};

export const setRegion = val => dispatch => {
  dispatch(setRegionAction(val));
};

export const setFascia = val => dispatch => {
  dispatch(setFasciaAction(val));
};

export const setOwnBrand = val => dispatch => {
  dispatch(setOwnBrandAction(val));
};

export const setTitle = (val, type) => dispatch => {
  dispatch(setLoadingAction(true));
  const state = store.getState().performanceReport;

  if (type === 'department' && state.departments.length === 1) {
    type = 'category';
  }
  dispatch(setTitleAction({ title: val, reportType: type }));
  let members = state.members;
  if (
    members.length === 2 &&
    _.filter(members, member => member.Text === 'ALL').length > 0
  ) {
    if (result.Categories && result.Categories.length > 0) {
      dispatch(setHideAllAction(true));
    }
    dispatch(setSelectedMembersAction([members[0]]));
  }
};

export const setSubPage = (val, type) => dispatch => {
  dispatch(setSubPageAction(val));
};

export const setMeasure = val => dispatch => {
  dispatch(setMeasureAction(val));
};

export const setCoreRange = val => dispatch => {
  dispatch(setCoreRangeAction(val));
};

export const setMemberType = val => dispatch => {
  dispatch(setMemberTypeAction(val));
};

export const setRetailType = val => dispatch => {
  dispatch(setRetailTypeAction(val));
}

export const setLikeForLike = val => dispatch => {
  dispatch(setLikeForLikeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const setBusinessType = val => dispatch => {
  dispatch(
    setBusinessTypeAction({
      selectedBusinessType: val,
      reportType: store.getState().performanceReport.reportType,
    }),
  );
};

const loadChart = async (store, dispatch) => {
  dispatch(setGraphLoadedAction(false));
  let state = store.getState().performanceReport;


  if (state.measure == 'customer_count') {
    dispatch(fetchPerformanceDataGraphAction([]));
    dispatch(setLoadingAction(false));
    dispatch(setGraphLoadedAction(true));
    return;
  }

  const currentPage = window.location.pathname.split('/')[2];

  const MAP = {
    business_type_performance: 'business_type',
    supplier_performance: 'supplier',
    brand_performance: 'brand',
    wholesaler_performance: 'member',
    group_performance: 'group',
    region_performance: 'region',
    fascia_performance: 'fascia'
  };

  if (MAP[currentPage]) {
    state.reportType = MAP[currentPage];
  }

  // If current report is category and user has access to multiple departments set report type as department on first load

  if (
    state.reportType === 'category' &&
    state.departments &&
    state.departments.length > 1 &&
    state.selectedDepartments.length === 0
  ) {
    state.reportType = 'department';
  }
  const user = store.getState().user.authUser;
  const url = formatUrlWithParameters(
    '/route.php?c=performance/getPerformance&primary_field=' +
      state.reportType,
    state,
    user,
  );
  try {
    const chartData = await requestGet({ url });
    dispatch(fetchPerformanceDataGraphAction(chartData.results));
    dispatch(setLoadingAction(false));
    dispatch(setGraphLoadedAction(true));
  } catch (e) {
    dispatch(setLoadingAction(false));
    dispatch(setGraphLoadedAction(true));
  }
};

export const exportAllToCSV = (page) => async dispatch => {
  let state = store.getState().performanceReport;

  let url =
    '/route.php?c=performance/getPerformanceTable&limit=1000&primary_field=' +
    state.reportType +
    '&timeframe=' +
    state.timeframe +
    '&measure=' +
    state.measure +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&retail_type=' +
    (state.retailType && state.retailType.ID ? state.retailType.ID : '') +
    '&like_for_like=' +
    state.likeForLike +
    '&type=' +
    state.purchase;

  const user = store.getState().user.authUser;
  if (user.showCompetitor && user.supplier.Text) {
    url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
  }

  url +=
    state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';
  url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  let memberIds = [];

  if (
    _.filter(state.selectedMembers, member => member.Text === 'ALL').length > 0
  ) {
    url += '&member=';
  } else {
    _.each(state.selectedMembers, member => {
      memberIds.push(member.Text);
    });

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }
  }

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${encodeURIComponent(state.ownBrand.Text)}`;
  }
  if (state.dataType && state.dataType.Text) {
    url += `&dataType=${encodeURIComponent(state.dataType.Text)}`;
  }
  if (state.commission && state.commission.Text) {
    url += `&commission=${encodeURIComponent(state.commission.Text)}`;
  }

  const response = await requestGet({ url: url });
  response.results.map(result => {
    result.change = result.change * 100 + '%';
    result.marketShare = result.marketShare * 100 + '%';
  });
  const parser = new Json2csvParser([
    'field',
    'change',
    'marketShare',
    'salesLY',
    'salesTY',
  ]);
  const url1 = window.URL.createObjectURL(
    new Blob([parser.parse(response.results)]),
  );
  const link = document.createElement('a');
  link.href = url1;
  link.setAttribute('download', page+'.csv');
  document.body.appendChild(link);
  link.click();
};

const loadTable = async (store, dispatch) => {
  dispatch(setTableLoadedAction(false));
  dispatch(setReportErrorAction(''));
  let state = store.getState().performanceReport;

  const currentPage = window.location.pathname.split('/')[2];

  const MAP = {
    business_type_performance: 'business_type',
    supplier_performance: 'supplier',
    brand_performance: 'brand',
    wholesaler_performance: 'member',
    region_performance: 'region',
    fascia_performance: 'fascia'
  };

  if (MAP[currentPage]) {
    state.reportType = MAP[currentPage];
  }
  const user = store.getState().user.authUser;
  const url = formatUrlWithParameters(
    '/route.php?c=performance/getPerformanceTable&primary_field=' +
      state.reportType,
    state,
    user,
  );
  try {
    const tableData = await requestGet({ url });
    dispatch(fetchPerformanceDataTableAction(tableData.results));
    dispatch(setLoadingAction(false));
    dispatch(setTableLoadedAction(true));
  } catch (e) {
    dispatch(setReportErrorAction('Failed to load report, please try again.'));
    dispatch(setLoadingAction(false));
    dispatch(setTableLoadedAction(true));
  }
};

export const setDataType = val => dispatch => {
  dispatch(setDataTypeAction(val));
};

export const setCommission = val => dispatch => {
  dispatch(setCommissionAction(val))
}

export const setExcludedDepartments = val => dispatch => {
  dispatch(setExcludedDepartmentsAction(val))
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      departments: payload.departments,
      categories: payload.categories,
      members: payload.members,
      brands: payload.brands,
      regions: payload.regions,
      fascias: payload.fascias,
      suppliers: payload.suppliers,
      depots: payload.depots,
      // accountNames: payload.accountNames,
      groups: payload.groups,
      purchase: payload.purchase,
      measure: payload.measure,
      coreRange: payload.coreRange,
      memberType: payload.memberType,
      retailType: payload.retailType,
      likeForLike: payload.likeForLike,
      timeframe: payload.timeframe,
      businessTypes: payload.businessTypes,
      isInitialLoadComplete: !state.isInitialLoadComplete,
    }),
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        payload,
      ],
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      let orderedCategories = uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedCategories.length > 1) {
        orderedCategories.unshift({
          ID: 0,
          Text: 'Select all',
          SubCategories: [],
        });
      }
      return {
        ...state,
        selectedDepartments: payload.departments,
        categories: orderedCategories,
        reportType: payload.reportType,
      };
    },
    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );

      let orderedSubCategories = orderBy(
        filter(allSubCategories, subCat => subCat !== undefined),
        ['Text'],
      );
      orderedSubCategories = sortDuplicateNextLevelDown(
        orderedSubCategories,
        'Level4',
      );

      orderedSubCategories = uniqBy(orderedSubCategories, 'Text');

      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType,
      };
    },
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );

      let orderedLvl4s = uniqBy(
        orderBy(
          filter(allLevel4s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl4s.length > 1) {
        orderedLvl4s.unshift({ ID: 0, Text: 'Select all', Level5s: [] });
      }
      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: orderedLvl4s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );

      let orderedLvl5s = uniqBy(
        orderBy(
          filter(allLevel5s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl5s.length > 1) {
        orderedLvl5s.unshift({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: orderedLvl5s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL5S]: (state, { payload }) => ({
      ...state,
      selectedLevel5s: payload.level5s,
      reportType: payload.reportType,
    }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload }),
    [SET_REGION]: (state, { payload }) => ({ ...state, region: payload }),
    [SET_FASCIA]: (state, { payload }) => ({ ...state, fascia: payload }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload }),
    [SET_OWN_BRAND]: (state, { payload }) => ({
      ...state,
      ownBrand: payload,
    }),
    [SET_CURRENT_REPORT_PAGE]: (state, { payload }) => ({
      ...state,
      currentReportPage: payload,
    }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_SUPPLIERS]: (state, { payload }) => ({ ...state, suppliers: payload }),
    [SET_BRANDS]: (state, { payload }) => ({ ...state, brands: payload }),
    [SET_SUPPLIER]: (state, { payload }) => ({
      ...state,
      supplier: payload.supplier,
      reportType: payload.reportType,
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
      reportType: payload.reportType,
    }),
    [SET_MEASURE]: (state, { payload }) => ({ ...state, measure: payload }),
    [SET_CORE_RANGE]: (state, { payload }) => ({ ...state, coreRange: payload }),
    [SET_MEMBER_TYPE]: (state, { payload }) => ({ ...state, memberType: payload }),
    [SET_RETAIL_TYPE]: (state, { payload }) => ({ ...state, retailType: payload }),
    [SET_LIKE_FOR_LIKE]: (state, { payload }) => ({
      ...state,
      likeForLike: payload,
    }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_TIMEFRAME]: (state, { payload }) => ({ ...state, timeframe: payload }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_SUBPAGE]: (state, { payload }) => ({ ...state, subPage: payload }),
    [SET_REPORT_TYPE]: (state, { payload }) => ({
      ...state,
      reportType: payload,
    }),
    [SET_DATA_TYPE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: payload.Text === 'Retail' ? 'Retail Units' : 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
      dataType: payload,
    }),
    [SET_COMMISSION]: (state, { payload }) => ({
      ...state,
      commission: payload,
    }),
    [SET_EXCLUDED_DEPARTMENTS]: (state, { payload }) => ({
      ...state,
      excludedDepartments: payload,
    }),
    [SET_IS_EXPORTING_TO_PDF]: (state, { payload }) => ({
      ...state,
      isExportingToPDF: payload,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_TITLE]: (state, { payload }) => {
      return {
        ...state,
        title: payload.title,
        reportType: payload.reportType,
        departments: [],
        selectedDepartments: [],
        categories: [],
        selectedCategories: [],
        subCategories: [],
        selectedSubCategories: [],
        level4s: [],
        selectedLevel4s: [],
        level5s: [],
        selectedLevel5s: [],
        members: [],
        supplier: '',
        suppliers: [],
        isLoaded: false,
        brands: [],
        brand: '',
        region: '',
        regions: [],
        fascia: '',
        fascias: [],
        performanceDataGraph: [],
        performanceDataTable: [],
        depots: [],
        selectedDepots: [],
        businessTypes: [],
        selectedBusinessType: '',
      };
    },
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_GRAPH_LOADED]: (state, { payload }) => ({
      ...state,
      isGraphLoaded: payload,
    }),
    [SET_TABLE_LOADED]: (state, { payload }) => ({
      ...state,
      isTableLoaded: payload,
    }),
    [FETCH_PERFORMANCE_DATA_GRAPH]: (state, { payload }) => ({
      ...state,
      performanceDataGraph: payload,
    }),
    [FETCH_PERFORMANCE_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      performanceDataTable: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [FETCH_PERFORMANCE_ALL_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      performanceAllDataTable: payload,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload.selectedBusinessType,
      reportType: payload.reportType,
    }),
    [SET_IS_DRILL]: (state, { payload }) => ({
      ...state,
      isDrill: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...initialState,
      isDrill: state.isDrill,
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
