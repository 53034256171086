import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';
import { createSelector } from 'reselect';
import store from '../store';
import { each, flatMap, map, filter } from 'lodash';
import { push } from 'react-router-redux';

const SET_SELECTED_ACCOUNT_TYPE = 'user/SET_SELECTED_ACCOUNT_TYPE';
const SET_ACCOUNT_NAME = 'user/SET_ACCOUNT_NAME;'
const SET_SELECTED_SUPPLIERS = 'user/SET_SELECTED_SUPPLIERS';
const SET_SELECTED_MULTI_SUPPLIERS = 'user/SET_SELECTED_MULTI_SUPPLIERS';
const SET_SELECTED_CATEGORIES = 'user/SET_SELECTED_CATEGORIES';
const SET_SELECTED_MULTI_CATEGORIES = 'user/SET_SELECTED_MULTI_CATEGORIES';
const SET_SELECTED_DEPARTMENTS = 'user/SET_SELECTED_DEPARTMENTS';
const SET_SELECTED_MULTI_DEPARTMENTS = 'user/SET_SELECTED_MULTI_DEPARTMENTS';
const SET_SELECTED_SUBCATEGORIES = 'user/SET_SELECTED_SUBCATEGORIES';
const SET_SELECTED_MULTI_SUBCATEGORIES =
  'user/SET_SELECTED_MULTI_SUBCATEGORIES';
const SET_SELECTED_LEVEL4S = 'user/SET_SELECTED_LEVEL4S';
const SET_SELECTED_MULTI_LEVEL4S = 'user/SET_SELECTED_MULTI_LEVEL4S';
const SET_SELECTED_LEVEL5S = 'user/SET_SELECTED_LEVEL5S';
const SET_SELECTED_DEPOT = 'user/SET_SELECTED_DEPOT';
const SET_SELECTED_MEMBER = 'user/SET_SELECTED_MEMBER';
const ADD_PERMISSION = 'user/ADD_PERMISSION';
const REMOVE_PERMISSION = 'user/REMOVE_PERMISSION';
const FETCH_ALL_PERMISSIONS = 'user/FETCH_ALL_PERMISSIONS';
const FETCH_SUPPLIERS = 'user/FETCH_SUPPLIERS';
const FETCH_DEPOTS = 'user/FETCH_DEPOTS';
const FETCH_MEMBERS = 'user/FETCH_MEMBERS';
const FETCH_USERS = 'user/FETCH_USERS';
const FETCH_USER = 'user/FETCH_USER';
const FETCH_DEPARTMENTS = 'user/FETCH_DEPARTMENTS';
const FETCH_CATEGORIES = 'user/FETCH_CATEGORIES';
const SET_USERS_PAGE_NO = 'user/SET_USERS_PAGE_NO';
const CREATE_USER_SUCCEED = 'user/CREATE_USER_SUCCEED';
const CREATE_USER_FAILED = 'user/CREATE_USER_FAILED';
const DELETE_USER_SUCCEED = 'user/DELETE_USER_SUCCEED';
const DELETE_USER_FAILED = 'user/DELETE_USER_FAILED';
const SET_LOADING = 'user/SET_LOADING';
const SET_DELETE_USER_MODAL = 'user/SET_DELETE_USER_MODAL';
const SET_USER_PROPS = 'users/SET_USER_PROPS';
const RESET_USER = 'users/RESET_USER';
const SET_USER_PERMISSIONS = 'users/SET_USER_PERMISSIONS';
const SET_AUTH_USER = 'users/SET_AUTH_USER';
const IS_PASSWORD_RESET = 'users/IS_PASSWORD_RESET';
const ADD_USER_TAG = 'users/ADD_USER_TAG';
const REMOVE_USER_TAG = 'users/REMOVE_USER_TAG';
const SET_SHOW_LOGOUT_SCREEN = 'user/SET_SHOW_LOGOUT_SCREEN';

const initialState = {
  permissions: {
    namDash: [],
    analysis: [],
    system: [],
    other: [],
    competitiors: [],
    queryBuilders: [],
    qa: [],
    retail: [],
  },
  accountTypes: [
    { ID: 'parfetts', Text: 'parfetts' },
    { ID: 'unitas', Text: 'unitas' },
    { ID: 'countryRangeGroup', Text: 'countryRangeGroup' },
    { ID: 'todays', Text: 'todays' },
    { ID: 'cjlang', Text: 'cjlang' },
    { ID: 'sugro', Text: 'sugro' },
    { ID: 'confex', Text: 'confex' },
    { ID: 'caterforce', Text: 'caterforce' },
  ],
  suppliers: [],
  selectedSuppliers: [],
  depots: [],
  members: [],
  departments: [],
  selectedDepartments: [],
  categories: [],
  selectedCategories: [],
  subCategories: [],
  selectedSubCategories: [],
  level4s: [],
  selectedLevel4s: [],
  level5s: [],
  selectedLevel5s: [],
  accountName: [],
  isLoading: false,
  isSuccess: false,
  isDeleteUserSuccess: false,
  isPasswordResetSuccess: false,
  openDeleteUserModal: false,
  message: '',
  usersCount: 0,
  usersPageNo: 1,
  users: [],
  authUser: {
    userId: '',
    showCompetitor: false,
    supplier: {
      Id: 0,
      Text: '',
    },
    departments: [],
    categories: [],
    subCategories: [],
    level4s: [],
    level5s: [],
    roles: [],
  },
  user: {
    email: '',
    firstName: '',
    lastName: '',
    telephone: '',
    tags: '',
    accountName: [],
    selectedAccountType: '',
    selectedPermissions: [],
    selectedDepot: {},
    selectedMember: {},
  },
  showLogoutScreen: false,
};

const setAuthUserAction = createAction(SET_AUTH_USER);
const setSelectedAccountTypeAction = createAction(SET_SELECTED_ACCOUNT_TYPE);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const setSelectedSuppliersAction = createAction(SET_SELECTED_SUPPLIERS);
const setSelectedMultiSuppliersAction = createAction(
  SET_SELECTED_MULTI_SUPPLIERS,
);
const setSelectedCategoriesAction = createAction(SET_SELECTED_CATEGORIES);
const setSelectedMultiCategoriesAction = createAction(
  SET_SELECTED_MULTI_CATEGORIES,
);
const setSelectedDepartmentsAction = createAction(SET_SELECTED_DEPARTMENTS);
const setSelectedMultiDepartmentsAction = createAction(
  SET_SELECTED_MULTI_DEPARTMENTS,
);
const setSelectedSubCategoriesAction = createAction(SET_SELECTED_SUBCATEGORIES);
const setSelectedMultiSubCategoriesAction = createAction(
  SET_SELECTED_MULTI_SUBCATEGORIES,
);
const setSelectedLevel4sAction = createAction(SET_SELECTED_LEVEL4S);
const setSelectedMultiLevel4sAction = createAction(SET_SELECTED_MULTI_LEVEL4S);
const setSelectedLevel5sAction = createAction(SET_SELECTED_LEVEL5S);
const setSelectedDepotAction = createAction(SET_SELECTED_DEPOT);
const setSelectedMemberAction = createAction(SET_SELECTED_MEMBER);
const addPermissionAction = createAction(ADD_PERMISSION);
const removePermissionAction = createAction(REMOVE_PERMISSION);
const fetchAllPermissionsAction = createAction(FETCH_ALL_PERMISSIONS);
const fetchSuppliersAction = createAction(FETCH_SUPPLIERS);
const fetchMembersAction = createAction(FETCH_MEMBERS);
const fetchDepotsAction = createAction(FETCH_DEPOTS);
const fetchCategoriesAction = createAction(FETCH_CATEGORIES);
const fetchDepartmentsAction = createAction(FETCH_DEPARTMENTS);
const fetchUsersAction = createAction(FETCH_USERS);
const fetchUserAction = createAction(FETCH_USER);
const setUsersPageNoAction = createAction(SET_USERS_PAGE_NO);
const createUserSucceedAction = createAction(CREATE_USER_SUCCEED);
const createUserFailedAction = createAction(CREATE_USER_FAILED);
const deleteUserSucceedAction = createAction(DELETE_USER_SUCCEED);
const deleteUserFailedAction = createAction(DELETE_USER_FAILED);
const setLoadingAction = createAction(SET_LOADING);
const setDeleteUserModalAction = createAction(SET_DELETE_USER_MODAL);
const setUserPropsAction = createAction(SET_USER_PROPS);
const resetUserAction = createAction(RESET_USER);
const setUserPermissionsAction = createAction(SET_USER_PERMISSIONS);
const isPasswordResetAction = createAction(IS_PASSWORD_RESET);
const addUserTagAction = createAction(ADD_USER_TAG);
const removeUserTagAction = createAction(REMOVE_USER_TAG);
const setShowLogoutScreen = createAction(SET_SHOW_LOGOUT_SCREEN);

export const setAuthUser = () => dispatch => {
  const userData = JSON.parse(localStorage.user_data);
  const roleCompetitor = userData.roles.filter(role => {
    if (role.Title === 'role_view_countryrangegroup_hide_competitior') {
      return role;
    }
  });
  const supplier = userData.dataSets.filter(
    dt => dt.className === getUserSystem(),
  );
  const user = {
    userId: userData.userId.substring(1, userData.userId.length - 1),
    showCompetitor: roleCompetitor.length > 0 ? true : false,
    departments: userData.departments,
    categories: userData.categories,
    subCategories: userData.subCategories,
    level4s: userData.level4s,
    level5s: userData.level5s,
    supplier: {
      Id: supplier[0]?.SupplierId || '',
      Text: supplier[0]?.SupplierName || '',
    },
    roles: userData.roles.map(role => role.Title),
  };
  dispatch(setAuthUserAction(user));
};

export const resetUser = () => dispatch => {
  dispatch(resetUserAction());
};

export const onChangeUserProps = (name, value) => dispatch => {
  if (name === 'tags') {
    const user = store.getState().user.user;
    const exists = _.filter(user.tags.split(','), tag => {
      if (tag === value.text) {
        return tag;
      }
    });
    if (exists.length > 0) {
      dispatch(removeUserTagAction(value.text));
    } else {
      dispatch(addUserTagAction(value.text));
    }
  } else {
    dispatch(setUserPropsAction({ name, value }));
  }
};

export const setSelectedAccountType = option => dispatch => {
  dispatch(setSelectedAccountTypeAction(option.ID));
};

export const setAccountName = option => dispatch => {
  dispatch(setAccountNameAction(option))
}

export const setSelectedSuppliers = option => dispatch => {
  dispatch(setSelectedMultiSuppliersAction(option));
};

export const setSelectedMember = option => dispatch => {
  dispatch(setSelectedMemberAction(option));
};

export const setSelectedDepot = option => dispatch => {
  dispatch(setSelectedDepotAction(option));
};

export const handleDeleteUserModal = () => dispatch => {
  dispatch(setDeleteUserModalAction());
};

export const setSelectedCategories = cats => dispatch => {
  let categories = [];
  map(cats, cat => {
    if (cat.subCategories) {
      categories.push({
        category: cat,
        ID: cat.ID,
        Text: cat.Text,
        subCategories: cat.subCategories,
      });
    } else {
      categories.push({
        category: cat,
        ID: cat.ID,
        Text: cat.Text,
        subCategories: cat.SubCategories,
      });
    }
  });
  dispatch(setSelectedMultiCategoriesAction(categories));
};

export const setSelectedDepartments = depts => dispatch => {
  let departments = [];
  map(depts, dept => {
    if (dept.categories) {
      departments.push({
        department: dept,
        ID: dept.ID,
        Text: dept.Text,
        categories: dept.categories,
      });
    } else {
      departments.push({
        department: dept,
        ID: dept.ID,
        Text: dept.Text,
        categories: dept.Categories,
      });
    }
  });
  dispatch(setSelectedMultiDepartmentsAction(departments));
};

export const setSelectedSubCategories = subcats => dispatch => {
  let subCategories = [];
  map(subcats, subcat => {
    if (subcat.level4s) {
      subCategories.push({
        subCategory: subcat,
        ID: subcat.ID,
        Text: subcat.Text,
        level4s: subcat.level4s,
      });
    } else {
      subCategories.push({
        subCategory: subcat,
        ID: subcat.ID,
        Text: subcat.Text,
        level4s: subcat.Level4,
      });
    }
  });
  dispatch(setSelectedMultiSubCategoriesAction(subCategories));
};

export const setSelectedLevel4s = lvl4s => dispatch => {
  let level4s = [];
  map(lvl4s, lvl4 => {
    if (lvl4.level5s) {
      level4s.push({
        level4: lvl4,
        ID: lvl4.ID,
        Text: lvl4.Text,
        level5s: lvl4.level5s,
      });
    } else {
      level4s.push({
        level4: lvl4,
        ID: lvl4.ID,
        Text: lvl4.Text,
        level5s: lvl4.Level5,
      });
    }
  });
  dispatch(setSelectedMultiLevel4sAction(level4s));
};

export const setSelectedLevel5s = lvl5s => dispatch => {
  let level5s = [];
  map(lvl5s, lvl5 => {
    if (lvl5.filter) {
      level5s.push(lvl5);
    } else {
      level5s.push({
        level5: lvl5,
        ID: lvl5.ID,
        Text: lvl5.Text,
        filter: 'level5',
        filterValue: lvl5.Text,
        filterId: lvl5.Text,
      });
    }
  });
  dispatch(setSelectedLevel5sAction(level5s));
};

export const addPermission = (name, type) => dispatch => {
  const permissions = store.getState().user.permissions;
  let permission = {};
  if (type === 'namdash') {
    permission = store
      .getState()
      .user.permissions.namDash.filter(per => per.name === name)[0];
  } else if (type === 'analysis') {
    permission = store
      .getState()
      .user.permissions.analysis.filter(per => per.name === name)[0];
  } else if (type === 'retail') {
    permission = store
      .getState()
      .user.permissions.retail.filter(per => per.name === name)[0];
  } else if (type === 'querybuilder') {
    permission = store
      .getState()
      .user.permissions.queryBuilders.filter(per => per.name === name)[0];
  } else if (type === 'other') {
    permission = store
      .getState()
      .user.permissions.other.filter(per => per.name === name)[0];
  } else if (type === 'qa') {
    permission = store
      .getState()
      .user.permissions.qa.filter(per => per.name === name)[0];
  } else {
    permission = store
      .getState()
      .user.permissions.system.filter(per => per.name === name)[0];
  }
  dispatch(addPermissionAction(permission));
};

export const removePermission = id => dispatch => {
  dispatch(removePermissionAction(id));
};

export const resetPassword = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(isPasswordResetAction(false));
  const url = `/route.php?c=security/resetPassword&userId=${encodeURIComponent(
    id,
  )}&accountType=${getUserSystem()}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(isPasswordResetAction(true));
    dispatch(setLoadingAction(false));
  });
};

export const fetchUserCategories = () => dispatch => {
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=category/getUserHierarchy';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    if (response.Departments) {
      dispatch(fetchDepartmentsAction(response.Departments));
    } else {
      dispatch(fetchCategoriesAction(response.Categories));
    }
    dispatch(setLoadingAction(false));
  });
};

export const fetchAllCategories = () => dispatch => {
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=category/getHierarchy';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    if (response.Departments) {
      dispatch(fetchDepartmentsAction(response.Departments));
    } else {
      dispatch(fetchCategoriesAction(response.Categories));
    }
    dispatch(setLoadingAction(false));
  });
};

export const fetchUser = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetUserAction());
  const url = '/route.php?c=category/getHierarchy';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(catResponse => {
    const url = '/route.php?c=supplier/getAllWithCode';
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(suppliers => {
      if (catResponse.Departments) {
        dispatch(fetchDepartmentsAction(catResponse.Departments));
      }
      if (catResponse.Categories) {
        dispatch(fetchCategoriesAction(catResponse.Categories));
      }
      //dispatch(fetchCategoriesAction(catResponse.Departments ? catResponse.Departments : catResponse.Categories));
      const url = '/route.php?c=users/getUserById&id=' + id;
      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      }).then(response => {
        if (
          response.selectedSuppliers &&
          response.selectedSuppliers.length > 0
        ) {
          response.selectedSuppliers.split(',').map(sup => {
            if (
              filter(suppliers, supp => supp.SupplierCode === sup).length > 0
            ) {
              const supplier = filter(
                suppliers,
                supp => supp.SupplierCode === sup,
              )[0];
              dispatch(
                setSelectedSuppliersAction({
                  ID: supplier.SupplierCode,
                  Text: supplier.SupplierName,
                }),
              );
            }
          });
        }
        if (catResponse.Departments) {
          catResponse.Departments.map(dept => {
            if (
              dept.ID &&
              (response.selectedDepartments &&
              response.selectedDepartments.indexOf(',') >= 0
                ? filter(
                    response.selectedDepartments.split(','),
                    fil => fil === dept.Text.toString(),
                  ).length > 0
                : response.selectedDepartments === dept.Text.toString())
            ) {
              const shallowCategories = dept.Categories.map(({ ID, Text }) => ({
                ID,
                Text,
              }));
              dispatch(setSelectedDepartmentsAction(dept));
              dept.Categories.map(cat => {
                if (
                  cat.ID &&
                  (response.selectedCategories.indexOf(',') >= 0
                    ? filter(
                        response.selectedCategories.split(','),
                        fl => fl === cat.Text.toString(),
                      ).length > 0
                    : response.selectedCategories === cat.Text.toString()) &&
                  (response.selectedDepartments.indexOf(',') >= 0
                    ? filter(
                        response.selectedDepartments.split(','),
                        fil => fil === dept.Text.toString(),
                      ).length > 0
                    : response.selectedDepartments === dept.Text.toString())
                ) {
                  dispatch(setSelectedCategoriesAction(cat));
                  cat.SubCategories.map(subCat => {
                    if (
                      subCat.ID &&
                      (response.selectedSubCategories.indexOf(',') >= 0
                        ? filter(
                            response.selectedSubCategories.split(','),
                            fl => fl === subCat.Text.toString(),
                          ).length > 0
                        : response.selectedSubCategories ===
                          subCat.Text.toString()) &&
                      (response.selectedCategories.length > 0 &&
                      response.selectedCategories.indexOf(',') >= 0
                        ? filter(
                            response.selectedCategories.split(','),
                            fl => fl === cat.Text.toString(),
                          ).length > 0
                        : response.selectedCategories === cat.Text.toString())
                    ) {
                      dispatch(setSelectedSubCategoriesAction(subCat));
                      subCat.Level4.map(lvl4 => {
                        if (
                          lvl4.ID &&
                          (response.selectedLevel4s.indexOf(',') >= 0
                            ? filter(
                                response.selectedLevel4s.split(','),
                                fl => fl === lvl4.Text.toString(),
                              ).length > 0
                            : response.selectedLevel4s ===
                              lvl4.Text.toString()) &&
                          (response.selectedSubCategories.length > 0 &&
                          response.selectedSubCategories.indexOf(',') >= 0
                            ? filter(
                                response.selectedSubCategories.split(','),
                                fl => fl === subCat.Text.toString(),
                              ).length > 0
                            : response.selectedSubCategories ===
                              subCat.Text.toString())
                        ) {
                          dispatch(setSelectedLevel4sAction(lvl4));
                          lvl4.Level5.map(lvl5 => {
                            if (
                              lvl5.ID &&
                              (response.selectedLevel4s.indexOf(',') >= 0
                                ? filter(
                                    response.selectedLevel4s.split(','),
                                    fl => fl === lvl4.Text.toString(),
                                  ).length > 0
                                : response.selectedLevel4s ===
                                  lvl4.Text.toString()) &&
                              (response.selectedLevel5s.indexOf(',') >= 0
                                ? filter(
                                    response.selectedLevel5s.split(','),
                                    fl => fl === lvl5.Text.toString(),
                                  ).length > 0
                                : response.selectedLevel5s ===
                                  lvl5.Text.toString())
                            ) {
                              dispatch(
                                setSelectedLevel5sAction({
                                  department: dept,
                                  ID: lvl5.ID,
                                  Text: lvl5.Text,
                                  category: cat,
                                  subCategory: subCat,
                                  level4: lvl4,
                                  level5: lvl5,
                                  departments: catResponse.Departments,
                                  categories: dept.Categories,
                                  subCategories: cat.SubCategories,
                                  level4s: subCat.Level4,
                                }),
                              );
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }

        if (!response.tags) {
          response = {
            ...response,
            tags: '',
          };
        }

        let accountNameObjectArray = [];
        if (response.accountName.length > 1) {
          let accountNameArray = response.accountName.split(",");
          
          accountNameArray.forEach(accountName => {
            accountNameObjectArray.push({0: accountName, 1: accountName, RegNo: accountName, CustomerName: accountName})
          })
        }
        let user = {
          ...response,
          selectedPermissions: response.permissions
            .split(',')
            .map(per => ({ name: per })),
          selectedDepot:
            response.selectedAccountType === 'parfetts'
              ? { ID: parseInt(response.WholesalerNo), Text: '' }
              : {},
          accountName: accountNameObjectArray,
          selectedMember:
            response.selectedAccountType !== 'parfetts'
              ? { ID: parseInt(response.WholesalerNo), Text: '' }
              : {},
        };
        dispatch(fetchUserAction(user));
        dispatch(setLoadingAction(false));
      });
    });
  });
};

export const filterUsers = (pageNo, name, email, tag, supplier, member) => dispatch => {
  dispatch(setUsersPageNoAction(pageNo));
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=users/getAllWithFilter&pageNo=${pageNo}`;
  if (name && name.length > 0) {
    url += `&name=${name}`;
  }
  if (email && email.length > 0) {
    url += `&email=${email}`;
  }
  if (tag && tag.length > 0) {
    url += `&tag=${tag}`;
  }
  if (supplier && supplier.length > 0) {
    url += `&supplier=${supplier}`;
  }
  if (member && member.length > 0) {
    url += `&member=${encodeURIComponent(member)}`;
  }
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(fetchUsersAction(response));
    dispatch(setLoadingAction(false));
  });
};

export const fetchUsers = pageNo => dispatch => {
  dispatch(setUsersPageNoAction(pageNo));
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=users/getAll&pageNo=' + pageNo;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(fetchUsersAction(response));
    dispatch(setLoadingAction(false));
  });
};

export const fetchAllMembers = () => dispatch => {
  dispatch(setLoadingAction(true));
  const hint = 'hint=1';
  let url = `/route.php?c=wholesaler/getAll&${hint}`;

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    let memberList = [];
    each(response, member => {
      memberList.push({
        ID:
          typeof member.WholesalerNo === 'string'
            ? member.WholesalerNo.trim()
            : member.WholesalerNo.toString(),
        Text: encodeText(member.ActualName.trim()),
      });
    });
    dispatch(fetchMembersAction(memberList));
    dispatch(setLoadingAction(false));
  });
};

export const fetchAllDepots = () => dispatch => {
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=depot/getDepotsWithID';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    let depotList = [];
    each(response, depot => {
      depotList.push({
        ID: depot.DepotNo.trim(),
        Text: encodeText(depot.DepotName.trim()),
      });
    });
    if (store.getState().user.user.selectedDepot.ID) {
      const option = depotList.filter(depot => {
        if (
          parseInt(depot.ID) ===
          parseInt(store.getState().user.user.selectedDepot.ID)
        ) {
          return depot;
        }
      });
      dispatch(setSelectedDepotAction(option[0]));
    }
    dispatch(fetchDepotsAction(depotList));
    dispatch(setLoadingAction(false));
  });
};

export const fetchAllSuppliers = () => dispatch => {
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=supplier/getAllWithCode';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    let supplierList = [];
    each(response, supp => {
      supplierList.push({
        ID: encodeText(supp.SupplierCode.trim()),
        Text: encodeText(supp.SupplierName.trim()),
      });
    });
    dispatch(fetchSuppliersAction(supplierList));
    dispatch(setLoadingAction(false));
  });
};

export const fetchAllPermissions = () => dispatch => {
  dispatch(setLoadingAction(true));
  const url = '/route.php?c=security/getAllPermissions';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    let permissions = {
      namDash: [],
      analysis: [],
      system: [],
      other: [],
      competitiors: [],
      queryBuilders: [],
      qa: [],
      retail: [],
    };
    const userPermissions = store.getState().user.user.selectedPermissions;
    let finalPermissions = [];
    response.map(resp => {
      if (
        resp.Description.indexOf('NAMDASH') >= 0 &&
        resp.Description.indexOf(
          store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.namDash.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'namdash',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'namdash',
          });
        }
      } else if (
        resp.Description.indexOf('ANALYSIS') >= 0 &&
        resp.Description.indexOf(
          store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.analysis.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'analysis',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'analysis',
          });
        }
      } else if (resp.Description.indexOf('RETAIL') >= 0) {
        permissions.retail.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'retail',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'retail',
          });
        }
      } else if (resp.Description.indexOf('SYSTEM') >= 0) {
        permissions.system.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'system',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'system',
          });
        }
      } else if (
        resp.Description.indexOf(
          'SYSTEM' +
            '_' +
            store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.system.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'system',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'system',
          });
        }
      } else if (resp.Description.indexOf('OTHER') >= 0) {
        permissions.other.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'other',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'other',
          });
        }
      } else if (
        resp.Description.indexOf('QA') >= 0 &&
        resp.Description.indexOf(
          store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.qa.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'qa',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'qa',
          });
        }
      } else if (
        resp.Title.indexOf('competitiors') >= 0 &&
        resp.Title.toUpperCase().indexOf(
          store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.competitiors.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'competitiors',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'competitiors',
          });
        }
      } else if (
        (resp.Title.indexOf('builder') >= 0 ||
          resp.Title.indexOf('update') >= 0 ||
          resp.Title.indexOf('supplier_engagament') >= 0) &&
        resp.Title.toUpperCase().indexOf(
          store.getState().user.user.selectedAccountType.toUpperCase(),
        ) >= 0
      ) {
        permissions.queryBuilders.push({
          name: resp.ID,
          label: resp.Type,
          value: resp.Title,
          type: 'querybuilder',
        });
        if (userPermissions.filter(pr => pr.label === resp.Title).length > 0) {
          finalPermissions.push({
            name: resp.ID,
            label: resp.Type,
            value: resp.Title,
            type: 'querybuilder',
          });
        }
      }
    });
    if (finalPermissions.length > 0) {
      dispatch(setUserPermissionsAction(finalPermissions));
    }
    dispatch(fetchAllPermissionsAction(permissions));
    dispatch(setLoadingAction(false));
  });
};

export const setAccountDetails = data => dispatch => {
  dispatch(setAccountDetailsAction(data));
};

export const createUser = data => dispatch => {
  dispatch(createUserSucceedAction());
  const user = store.getState().user;
  let filter = '';
  switch (user.filter) {
    case 'department':
      filter = 'DeptDesc';
      break;
    case 'category':
      filter = 'CatDesc';
      break;
    case 'subcategory':
      filter = 'SubCatDesc';
      break;
    case 'level4':
      filter = 'Level4Desc';
      break;
    case 'level5':
      filter = 'Level5Desc';
      break;
  }

  const userObj = {
    ...user.user,
    selectedDepartments: user.selectedDepartments
      .map(dept => dept.Text)
      .join(','),
    selectedCategories: user.selectedCategories.map(cat => cat.Text).join(','),
    selectedSubCategories: user.selectedSubCategories
      .map(subcat => subcat.Text)
      .join(','),
    selectedLevel4s: user.selectedLevel4s.map(lvl4 => lvl4.Text).join(','),
    selectedLevel5s: user.selectedLevel5s.map(lvl5 => lvl5.Text).join(','),
    selectedPermissions:
      getUserSystem() === 'todays'
        ? [
            // { value: 'wholesaler_admin' },
            { value: 'todays_user' },
          ]
        : user.user.selectedPermissions,
    selectedSuppliers: user.selectedSuppliers.map(sup => sup.ID),
  };
  let url = '/route.php?c=security/createUser';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
    data: userObj,
  }).then(result => {
    if (result.success === 'true') {
      dispatch(createUserSucceedAction());
    } else {
      dispatch(createUserFailedAction(result.message));
    }
  });
};

export const updateUser = id => dispatch => {
  dispatch(createUserSucceedAction());
  const user = store.getState().user;
  let permissions = [];
  map(user.permissions.analysis, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.namDash, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.retail, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.other, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.qa, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.queryBuilders, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });
  map(user.permissions.system, perr => {
    if (
      filter(user.user.selectedPermissions, perm => perm.name === perr.name)
        .length > 0
    ) {
      permissions.push(perr);
    }
  });

  const userObj = {
    ...user.user,
    userId: id,
    selectedDepartments: user.selectedDepartments
      .map(dept => dept.Text)
      .join(','),
    selectedCategories: user.selectedCategories.map(cat => cat.Text).join(','),
    selectedSubCategories: user.selectedSubCategories
      .map(subcat => subcat.Text)
      .join(','),
    selectedLevel4s: user.selectedLevel4s.map(lvl4 => lvl4.Text).join(','),
    selectedLevel5s: user.selectedLevel5s.map(lvl5 => lvl5.Text).join(','),
    selectedSuppliers: user.selectedSuppliers.map(sup => sup.ID),
    selectedPermissions:
      getUserSystem() === 'todays'
        ? [{ value: 'wholesaler_admin' }, { value: 'todays_user' }]
        : permissions,
    permissions: permissions.map(perm => perm.name).join(','),
  };
  let url = '/route.php?c=security/updateUser';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
    data: userObj,
  }).then(result => {
    if (result.success === 'true') {
      dispatch(createUserSucceedAction());
    } else {
      dispatch(createUserFailedAction(result.message));
    }
  });
};

export const deleteUser = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(
    deleteUserSucceedAction({
      isDeleteUserSuccess: false,
      openDeleteUserModal: false,
      isLoading: true,
    }),
  );
  const params = {
    userId: id,
  };
  let url = '/route.php?c=security/deleteUser';
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
    data: params,
  }).then(result => {
    if (result.success === 'true') {
      dispatch(
        deleteUserSucceedAction({
          isDeleteUserSuccess: true,
          openDeleteUserModal: false,
          isLoading: false,
        }),
      );
    } else {
      dispatch(deleteUserFailedAction(result.message));
    }
  });
};

export const showLogoutScreen = () => dispatch => {
  dispatch(setShowLogoutScreen());
};

export default handleActions(
  {
    [ADD_PERMISSION]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        selectedPermissions: [...state.user.selectedPermissions, payload],
      },
    }),
    [SET_SHOW_LOGOUT_SCREEN]: (state, { payload }) => ({
      ...state,
      showLogoutScreen: !state.showLogoutScreen,
    }),
    [REMOVE_PERMISSION]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        selectedPermissions: state.user.selectedPermissions.filter(
          per => per.name !== payload,
        ),
      },
    }),
    [FETCH_ALL_PERMISSIONS]: (state, { payload }) => ({
      ...state,
      permissions: payload,
    }),
    [FETCH_CATEGORIES]: (state, { payload }) => ({
      ...state,
      categories: payload,
    }),
    [FETCH_DEPARTMENTS]: (state, { payload }) => ({
      ...state,
      departments: payload,
    }),
    [FETCH_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [FETCH_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      suppliers: payload,
    }),
    [FETCH_MEMBERS]: (state, { payload }) => ({ ...state, members: payload }),
    [FETCH_USERS]: (state, { payload }) => ({
      ...state,
      users: payload.result,
      usersCount: payload.total,
    }),
    [FETCH_USER]: (state, { payload }) => ({ ...state, user: payload }),
    [SET_DELETE_USER_MODAL]: (state, { payload }) => ({
      ...state,
      openDeleteUserModal: !state.openDeleteUserModal,
    }),
    [SET_LOADING]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
      isSuccess: payload ? false : state.isSuccess,
    }),
    [SET_AUTH_USER]: (state, { payload }) => ({ ...state, authUser: payload }),
    [SET_USER_PERMISSIONS]: (state, { payload }) => ({
      ...state,
      user: { ...state.user, selectedPermissions: payload },
    }),
    [IS_PASSWORD_RESET]: (state, { payload }) => ({
      ...state,
      isPasswordResetSuccess: payload,
    }),
    [ADD_USER_TAG]: (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          tags:
            state.user.tags.length > 0
              ? state.user.tags + ',' + payload
              : payload,
        },
      };
    },
    [REMOVE_USER_TAG]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        tags: _.filter(state.user.tags.split(','), tag => {
          if (tag !== payload) {
            return tag;
          }
        }).join(','),
      },
    }),
    [RESET_USER]: (state, { payload }) => ({
      ...state,
      selectedDepartments: [],
      selectedCategories: [],
      selectedSubCategories: [],
      selectedLevel4s: [],
      selectedLevel5s: [],
      selectedSuppliers: [],
      user: {
        email: '',
        firstName: '',
        lastName: '',
        telephone: '',
        tags: '',
        accountName:[],
        selectedAccountType: '',
        selectedPermissions: [],
        selectedDepot: {},
        selectedMember: {},
      },
    }),
    [SET_SELECTED_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      selectedSuppliers: [...state.selectedSuppliers, payload],
    }),
    [SET_SELECTED_MULTI_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      selectedSuppliers: payload,
    }),
    [SET_SELECTED_DEPOT]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        selectedDepot: payload,
      },
    }),
    [SET_SELECTED_DEPARTMENTS]: (state, { payload }) => {
      return {
        ...state,
        selectedDepartments: [...state.selectedDepartments, payload],
        categories: [...(state?.categories || []), ...payload.Categories],
      };
    },
    [SET_SELECTED_MULTI_DEPARTMENTS]: (state, { payload }) => ({
      ...state,
      selectedDepartments: payload,
      categories: flatMap(payload, dept => {
        return dept.categories;
      }),
    }),
    [SET_SELECTED_CATEGORIES]: (state, { payload }) => {
      return {
        ...state,

        selectedCategories: [...state.selectedCategories, payload],
        subCategories: [
          ...(state?.subCategories || []),
          ...payload.SubCategories,
        ],
      };
    },
    [SET_SELECTED_MULTI_CATEGORIES]: (state, { payload }) => ({
      ...state,
      selectedCategories: payload,
      subCategories: flatMap(payload, dept => {
        return dept.subCategories;
      }),
    }),
    [SET_SELECTED_SUBCATEGORIES]: (state, { payload }) => {
      return {
        ...state,
        selectedSubCategories: [...state.selectedSubCategories, payload],
        level4s: [...(state?.level4s || []), ...payload.Level4],
      };
    },
    [SET_SELECTED_MULTI_SUBCATEGORIES]: (state, { payload }) => ({
      ...state,
      selectedSubCategories: payload,
      level4s: flatMap(payload, dept => {
        return dept.level4s;
      }),
    }),
    [SET_SELECTED_LEVEL4S]: (state, { payload }) => {
      return {
        ...state,

        selectedLevel4s: [...state.selectedLevel4s, payload],
        level5s: [...(state?.level5s || []), ...payload.Level5],
      };
    },
    [SET_SELECTED_MULTI_LEVEL4S]: (state, { payload }) => ({
      ...state,
      selectedLevel4s: payload,
      level5s: flatMap(payload, dept => {
        return dept.level5s;
      }),
    }),
    [SET_SELECTED_LEVEL5S]: (state, { payload }) => ({
      ...state,

      selectedLevel5s: [...state.selectedLevel5s, payload],
    }),
    [SET_SELECTED_MEMBER]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        selectedMember: payload,
      },
    }),
    [SET_SELECTED_ACCOUNT_TYPE]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        selectedAccountType: payload,
      },
    }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        accountName: payload,
      },
    }),
    [SET_USERS_PAGE_NO]: (state, { payload }) => ({
      ...state,
      usersPageNo: payload,
    }),
    [SET_USER_PROPS]: (state, { payload }) => ({
      ...state,
      user: { ...state.user, [payload.name]: payload.value },
    }),
    [DELETE_USER_SUCCEED]: (state, { payload }) => ({
      ...state,
      isDeleteUserSuccess: payload.isDeleteUserSuccess,
      openDeleteUserModal: payload.openDeleteUserModal,
      isLoading: payload.isLoading,
    }),
    [DELETE_USER_FAILED]: (state, { payload }) => ({
      ...state,
      isDeleteSuccess: false,
      message: payload,
      isLoading: false,
    }),
    [CREATE_USER_SUCCEED]: (state, { payload }) => ({
      ...state,
      isSuccess: true,
      isLoading: false,
    }),
    [CREATE_USER_FAILED]: (state, { payload }) => ({
      ...state,
      isSuccess: false,
      message: payload,
      isLoading: false,
    }),
  },
  initialState,
);
