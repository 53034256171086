import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import store, { history } from './react/redux/store';
import ReduxRouteMap from './react/components/ReduxMapping';
import { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import theme from './config/Themes';

if (!process.env.APP_ROOT) {
  throw new Error('APP_ROOT process variable needs to be set!');
}

const Routing = () => {
  const handleOnIdle = event => {
    localStorage.removeItem('show_qb_popup');
    localStorage.removeItem('user_data');
    localStorage.removeItem('menus');
    localStorage.removeItem('system');
    localStorage.removeItem('drill_data');
    window.location.href = `${process.env.APP_ROOT}`;
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <Route path={process.env.APP_ROOT} component={ReduxRouteMap} />
          </CookiesProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default Routing;
