import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import moment from 'moment';
import { filter } from 'lodash';
import Loading from './Loading';
import GreenTick from '../../assets/images/green-check.svg';
import RedTick from '../../assets/images/icons/twc_red_numbers-01.svg';

import RedTick01 from '../../assets/images/icons/twc_red_numbers-01.svg';
import RedTick02 from '../../assets/images/icons/twc_red_numbers-02.svg';
import RedTick03 from '../../assets/images/icons/twc_red_numbers-03.svg';
import RedTick04 from '../../assets/images/icons/twc_red_numbers-04.svg';
import RedTick05 from '../../assets/images/icons/twc_red_numbers-05.svg';
import RedTick06 from '../../assets/images/icons/twc_red_numbers-06.svg';
import RedTick07 from '../../assets/images/icons/twc_red_numbers-07.svg';
import { isAfterToday } from '../../helpers/helpers';

const imageNumber = num => {
  if (num === '1') {
    return RedTick01;
  } else if (num === '2') {
    return RedTick02;
  } else if (num === '3') {
    return RedTick03;
  } else if (num === '4') {
    return RedTick04;
  } else if (num === '5') {
    return RedTick05;
  } else if (num === '6') {
    return RedTick06;
  } else if (num === '7') {
    return RedTick07;
  }
};

class TableStatusReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  renderHeader = (header, i) => {
    if (header.text.indexOf('MS_Days') === -1) {
      return (
        <th
          className="u-text-center"
          colSpan={header.colspan}
          style={{ fontSize: '0.75em', wordWrap: 'break-word' }}
          key={i}>
          {this.props.parseEachCell(header.text.replace(/_/g, ' '), -1, i)}
        </th>
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isLoading === true && prevProps.data !== this.props.data) {
      this.setState({ isLoading: false });
    }
  };

  renderRow = (row, i) => {
    return (
      <tr key={i}>
        {row.map((column, j, element) => {
          const tooltip = filter(this.props.tooltipsData || [], tooltip => {
            if (tooltip.WholeSAlerName === column.header) {
              return tooltip;
            }
          });

          if (isNaN(column.text)) {
            return (
              <td
                className={
                  j === 0
                    ? 'first-col u-text-center'
                    : 'u-text-center tooltip-table-fix'
                }
                colSpan={column.colspan}
                key={j}>
                {column.text !== 'GREEN' &&
                column.text !== 'ORANGE' &&
                column.text !== 'RED'
                  ? 'W/E ' + this.props.parseEachCell(column.text, i, j)
                  : ''}
                {column.text === 'GREEN' ? (
                  tooltip.length > 0 && !isAfterToday(tooltip[0].EndDate) ? (
                    <Tooltip
                      trigger="mouseenter"
                      position="bottom"
                      animation="scale"
                      theme="green"
                      arrow="true"
                      title={tooltip[0].Reason}>
                      <img src={GreenTick} alt="green" />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      trigger="mouseenter"
                      position="bottom"
                      animation="scale"
                      theme="green"
                      arrow="true"
                      title="All data received and processed">
                      <img src={GreenTick} alt="green" />
                    </Tooltip>
                  )
                ) : (
                  ''
                )}
                {column.text === 'RED' ? (
                  tooltip.length > 0 && !isAfterToday(tooltip[0].EndDate) ? (
                    <Tooltip
                      trigger="mouseenter"
                      position="bottom"
                      animation="scale"
                      theme="red"
                      arrow="true"
                      title={tooltip[0].Reason}>
                      <img src={imageNumber(element[j + 1].text)} alt="red" />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      trigger="mouseenter"
                      position="bottom"
                      animation="scale"
                      theme="green"
                      arrow="true"
                      title="Data has been chased with provider">
                      <img src={imageNumber(element[j + 1].text)} alt="red" />
                    </Tooltip>
                  )
                ) : (
                  ''
                )}
              </td>
            );
          }
        })}
      </tr>
    );
  };

  render() {
    if (!this.props.data || !this.props.data.headers) return null;
    const { data, timeLabel } = this.props;
    const rows = filter(data.rows, (row, idx) => {
      if (
        moment(row[0].text, 'DD/MM/YY').isBefore(
          moment(timeLabel, 'YYYY-MM-DD').add(1, 'days'),
        )
      ) {
        return row;
      }
    });
    return this.state.isLoading ? (
      <Loading />
    ) : (
      <div
        className={`analysis analysis-table table-responsive ${this.props.className}`}>
        <table className={this.props.tableClassName} id={this.props.id}>
          <thead style={{ fontSize: '0.75em !important' }}>
            <tr>
              <th className="u-text-center first-col"></th>
              {this.props.data.headers.map(this.renderHeader)}
            </tr>
          </thead>
          <tbody>{rows.map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  }
}

TableStatusReport.defaultProps = {
  data: [],
  parseEachCell: cell => cell,
  className: '',
  tableClassName: '',
};

TableStatusReport.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  parseEachCell: PropTypes.func,
  className: PropTypes.string,
  tableClassName: PropTypes.string,
};

export default TableStatusReport;
