import React from 'react';
import Select from 'react-select';
import Button from '../components/Button';
import DropdownButton from '../components/DropdownButton';
import Collapsible from 'react-collapsible';
import ConditionalWrapper from '../components/util/ConditionalWrapper';
import LaunchIcon from '@material-ui/icons/Launch';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Explainer from '../components/Explainer/Explainer';
import AccountNames from '../components/filters/AccountNames/AccountNames';
const ReportSettingsContainer = ({
  title,
  dropdowns,
  children,
  exports,
  refresh,
  currentReportType,
  switchableReportTypes,
  onSwitchReportType,
  isCollapsible,
  explanationTooltip,
  isDisabled,
  pathname,
  resetReport,
  warningTooltip,
}) => {
  return (
    <>
      <ConditionalWrapper
        condition={isCollapsible}
        FalseWrapper={children => (
          <div className="sub-menu-border bg-white">{children}</div>
        )}
        TrueWrapper={children => (
          <Collapsible
            open
            trigger={<Button iconClass="fa fa-caret-down" text="Filters" />}
            className="sub-menu-border bg-white"
            openedClassName="sub-menu-border bg-white is-open"
            triggerClassName="collapse-trigger d-flex justify-content-end m-2"
            triggerOpenedClassName="collapse-trigger d-flex justify-content-end m-2"
            overflowWhenOpen="visible">
            {children}
          </Collapsible>
        )}>
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between pb-3">
              <div className="sub-menu__left">
                <div className="d-flex justify-content-start">
                  {switchableReportTypes ? (
                    <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                      <h2
                        className="text-uppercase mb-0 mr-2"
                        style={{ paddingTop: '0.6rem', fontWeight: '700' }}>
                        {title}
                      </h2>
                      <Select
                        className={`wd-130 small-dropdown c-select-header c-select-header-row--others ml-0`}
                        name={'ReportType'}
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id={'ReportType'}
                        placeholder={'Report Type'}
                        value={currentReportType}
                        onChange={option => onSwitchReportType(option)}
                        options={switchableReportTypes}
                        valueKey={'ID'}
                        labelKey={'Text'}
                        clearable={false}
                        searchable={false}
                        disabled={isDisabled}
                      />
                    </div>
                  ) : (
                    <Explainer
                      explainerText={title}
                      warningTooltip={warningTooltip}
                      explanationTooltip={explanationTooltip}
                    />
                  )}
                </div>

                {dropdowns &&
                  dropdowns.map(
                    (
                      {
                        value,
                        options,
                        handler,
                        valueKey,
                        labelKey,
                        name,
                        isText,
                        text,
                        clearable,
                      },
                      i,
                    ) => {
                      return text ? (
                        <div
                          key={`dropdown-${i}`}
                          className="btn-group text-uppercase">
                          <span className="dropdown-align-fix pr-2">{text}</span>
                        </div>
                      ) : name === 'accountName' ? (
                        options?.length > 0 && (
                          <AccountNames
                            value={value}
                            options={options}
                            onChange={handler}
                          />
                        )
                      ) : (
                        <div
                          key={`dropdown-${i}`}
                          className="btn-group text-uppercase dropdown-align-fix-placeholder">
                          <Select
                            className={`${
                              name === 'timeframe' ? 'wd-130' : 'wd-200'
                            } small-dropdown c-select-header-row--others mx-0`}
                            name={name}
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id={name}
                            placeholder={name}
                            value={value}
                            onChange={option => handler(option)}
                            options={options}
                            valueKey={valueKey}
                            labelKey={labelKey}
                            clearable={!!clearable}
                            searchable={false}
                          />
                        </div>
                      );
                    },
                  )}
              </div>

              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <Button
                      onClickHandler={() => {
                        resetReport();
                      }}
                      iconComponent={
                        <RotateLeftIcon
                          style={{
                            height: '1rem',
                            width: '1rem',
                            marginLeft: '2px',
                            marginTop: '1px',
                          }}
                        />
                      }
                      text="Reset"
                      classes="btn alchemy-btn--others d-flex mr-2"
                    />
                  </div>
                  <div>
                    <Button
                      onClickHandler={() => {
                        const win = window.open(pathname, '_blank');
                        win.focus();
                      }}
                      iconComponent={
                        <LaunchIcon
                          style={{
                            height: '1rem',
                            width: '1rem',
                            marginLeft: '2px',
                            marginTop: '1px',
                          }}
                        />
                      }
                      text="New tab"
                      classes="btn alchemy-btn--others d-flex mr-2"
                    />
                  </div>
                  {exports && (
                    <div>
                      <DropdownButton
                        classes="mr-2"
                        isOpen={true}
                        dropdownItems={[
                          {
                            text: 'csv',
                            exportData: (exports && exports.CSV) || {},
                          },
                        ]}
                      />
                    </div>
                  )}
                  {refresh && (
                    <div>
                      <Button
                        onClickHandler={refresh.refreshHandler}
                        isDisabled={refresh.isRefreshDisabled}
                        iconClass="fa fa-refresh"
                        text="Refresh"
                        classes="btn alchemy-btn--others mr-2"
                      />
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </ConditionalWrapper>
    </>
  );
};

export default ReportSettingsContainer;
