import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import StepCrumbs from './StepCrumbs';
import { Link } from 'react-router-dom';
import { getUserSystem, hasPermission } from '../../../helpers/util';
import { submitQuery, setQueryName } from '../../redux/reducers/query';
import ChangeIconUrl from '../../../assets/images/icons/change-icon-unitas.svg';
import { motion } from 'framer-motion';
import { pageVariants } from '../../../config/pageVariants';

const Step3 = ({
  name,
  builtQuery,
  getMeasureFields,
  getDateRanges,
  getSelectedFields,
  getWholesalers,
  getActiveFilters,
  submitQuery,
  onQueryNameChange,
  theme,
}) => (
  <motion.main
    key={'query-step3-id'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <div className="container mt-40">
      <div className="row">
        <div className="col">
          <div className="query-builder query-builder__steps">
            <h2 className="text-uppercase mb-4">Query Builder</h2>
            <StepCrumbs step={3} />
            <p className="mt-3">
              Please review the query you have built. You can use the red
              refresh links to edit a selections in a previous step
            </p>
            <hr />
          </div>
          <div className="query-builder">
            <h2 className="text-uppercase">Query Name - for easy reference</h2>
            <label htmlFor="queryName" className="sr-only">
              Query Name
            </label>
            <input
              type="text"
              onChange={onQueryNameChange}
              className="form-control query-builder__half"
              name="name"
              value={name}
              placeholder="Enter query name"
            />
            <hr />
          </div>
          <div className="row query-builder">
            <div className="col-12">
              <h2 className="text-uppercase">Query Summary</h2>
            </div>
            <div className="col col-md-4">
              <h3 className="text-uppercase">Mandatory options</h3>
              <div className="query-builder__options pt-2 pb-2 mb-3">
                <span className="color-3-text">
                  <Link to="/query/1" style={{ color: theme.primaryDark }}>
                    Change Options
                  </Link>
                  <Link to="/query/1">
                    {' '}
                    <img
                      src={ChangeIconUrl}
                      alt="change icon"
                      className="ml-2"
                    />
                  </Link>
                </span>
              </div>
              <div className="query-builder__row mb-3">
                <h4 className="text-uppercase">Measure</h4>
                {getMeasureFields()}
              </div>
              <div className="query-builder__row mb-3">
                <h4 className="text-uppercase">Date Range</h4>
                {getDateRanges()}
              </div>
              <div className="query-builder__row mb-3">
                <h4 className="text-uppercase">Include Competitor Products</h4>
                {builtQuery.Show_Competitor === 1 ? 'Yes' : 'No'}
              </div>
              {theme.name !== 'cjlang' && (
                <div className="query-builder__row mb-3">
                  <h4 className="text-uppercase">Include Like for Like</h4>
                  {builtQuery.Show_LikeForLike === 1 ? 'Yes' : 'No'}
                </div>
              )}
              {theme.name !== 'cjlang' && (
                <div className="query-builder__row mb-3">
                  <h4 className="text-uppercase">Wholesalers</h4>
                  {getWholesalers()}
                </div>
              )}
              { hasPermission(`role_view_${getUserSystem().toLowerCase()}_query_builder_retailer`) && (
                <div className="query-builder__row mb-3">
                  <h4 className="text-uppercase">Wholesale or Retail Data</h4>
                  {builtQuery.dataType === 1 ? "Retail" : "Wholesale"}
                </div>  
              )}
            </div>
            <div className="col col-md-4">
              <h3 className="text-uppercase">Chosen Fields</h3>
              <div className="query-builder__options pt-2 pb-2 mb-3">
                <span className="color-3-text  mr-xl-4">
                  <Link to="/query/2" style={{ color: theme.primaryDark }}>
                    Change Options
                  </Link>
                  <Link to="/query/2">
                    <img
                      src={ChangeIconUrl}
                      alt="change icon"
                      className="ml-2"
                    />
                  </Link>
                </span>
              </div>
              <div className="query-builder__row">{getSelectedFields()}</div>
            </div>
            <div className="col col-md-4">
              <h3 className="text-uppercase">Chosen Filters</h3>
              <div className="query-builder__options pt-2 pb-2 mb-3">
                <span className="color-3-text">
                  <Link to="/query/2" style={{ color: theme.primaryDark }}>
                    Chosen filters
                  </Link>
                  <Link to="/query/2">
                    <img
                      src={ChangeIconUrl}
                      alt="change icon"
                      className="ml-2"
                    />
                  </Link>
                </span>
              </div>
              <div className="query-builder__row">{getActiveFilters()}</div>
            </div>
          </div>
          <div className="query-builder mt-5">
            <button
              type="button"
              onClick={submitQuery}
              className={`btn btn-info alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              } text-uppercase float-right`}>
              Run query now
            </button>
          </div>
        </div>
      </div>
    </div>
  </motion.main>
);
function mapStateToProps(state) {
  let data = {
    queryId: state.query.queryId,
    name: state.query.name,
    builtQuery: state.query.builtQuery,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, { submitQuery, setQueryName }),
  lifecycle({
    componentDidMount() {
      if (this.props.builtQuery.newDateRanges.length === 0) {
        this.props.history.push('/query/1/');
      }
    },
    componentDidUpdate(prevProps, test) {
      if (
        prevProps.queryId !== this.props.queryId &&
        this.props.queryId &&
        this.props.queryId.length > 0
      ) {
        this.props.history.push('/query/4/');
      }
    },
  }),
  withState('data', 'handleData', {
    measure_fields:
      getUserSystem() === 'countryRangeGroup'
        ? [
            { label: 'value', name: 'Value', list: false },
            { label: 'value in', name: 'ValueIn', list: false },
            { label: 'volume', name: 'Volume', list: false },
            { label: 'volume in', name: 'VolumeIn', list: false },
            { label: 'customer count', name: 'CustomerCount', list: false },
          ]
        : [
            { label: 'value', name: 'Value', list: false },
            { label: 'volume', name: 'Volume', list: false },
            {
              label: 'volume-hectolitres',
              name: 'QtyHectolitres',
              list: false,
            },
            { label: 'volume-9l', name: 'Qty9L', list: false },
            { label: 'volume-stick', name: 'QtyStick', list: false },
            { label: 'volume-grams', name: 'QtyGrams', list: false },
            { label: 'customer count', name: 'CustomerCount', list: false },
          ],
  }),
  withHandlers({
    onQueryNameChange: ({ setQueryName }) => event => {
      const { value } = event.target;
      setQueryName(value);
    },
    getMeasureFields: props => () => {
      const fieldLabels = [];
      Object.keys(props.builtQuery).forEach(key => {
        const measureField = props.data.measure_fields.find(field => {
          const queryKey = key.replace('Show_', '');

          return field.name === queryKey && props.builtQuery[key] !== 0;
        });

        if (measureField) {
          return fieldLabels.push(measureField.label);
        }
      });

      return fieldLabels.join(', ');
    },
    getDateRanges: props => () => {
      const { builtQuery } = props;
      return _.map(builtQuery.newDateRanges, dateRange => {
        return `${dateRange.startWeek}-${dateRange.endWeek}`;
      }).join(', ');
    },
    getWholesalers: props => () => {
      const { builtQuery } = props;
      let wholesalers = [];
      if (builtQuery.Wholesalers.length > 0) {
        _.each(builtQuery.Wholesalers, saler => {
          wholesalers.push(saler.label);
        });
        return wholesalers.join(',');
      }
    },
    getSelectedFields: props => () => {
      const { builtQuery } = props;
      const fieldList = builtQuery.Column_Order.map(field => {
        return field.label.toUpperCase();
      });

      return fieldList.join(', ');
    },
    getActiveFilters: props => () => {
      const { builtQuery } = props;
      const filterKey = /Filter_/;
      const filterFields = Object.keys(builtQuery).reduce((all, key) => {
        if (!filterKey.test(key)) return all;
        if (Array.isArray(builtQuery[key]) && builtQuery[key].length > 0) {
          all.push(key);
        }
        return all;
      }, []);

      return filterFields.join(', ');
    },
    submitQuery: props => () => {
      props.submitQuery();
    },
  }),
)(Step3);
