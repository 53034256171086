import { handleActions, createAction } from 'redux-actions';
import { request, requestGet, customRequest } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';
import {
  getLastSelectedFilterBNB,
  formatBnBUrl,
} from '../../../helpers/helpers';
import { each, map, isArray, isEmpty } from 'lodash';
import store from '../store';

let altDepartmentController,
  altCategoryController,
  altSubCategoryController,
  altLevel5Controller,
  altLevel4Controller,
  altProductController,
  productController;

const SET_TITLE = 'builder/SET_TITLE';
const SET_BNB_PRODUCT = 'builder/SET_BNB_PRODUCT';
const SET_BNB_CATEGORY = 'builder/SET_BNB_CATEGORY';
const SET_BNB_BRANDS = 'builder/SET_BNB_BRANDS';
const SET_BNB_BRAND = 'builder/SET_BNB_BRAND';
const SET_ALT_CATEGORIES = 'builder/SET_ALT_CATEGORIES';
const SET_ALT_CATEGORY = 'builder/SET_ALT_CATEGORY';
const SET_BNB_MEMBER = 'builder/SET_BNB_MEMBER';
const SET_SELECTED_CUSTOMER_TYPES = 'builder/SET_SELECTED_CUSTOMER_TYPES';
const FETCH_BNB_DEFAULTS = 'builder/FETCH_BNB_DEFAULTS';
const FETCH_BNB_DATA = 'builder/FETCH_BNB_DATA';
const FETCH_PRODUCTS = 'builder/FETCH_PRODUCTS';
const SET_HIDE_PRODUCTS = 'builder/SET_HIDE_PRODUCTS';
const SET_EXPORTING = 'builder/SET_EXPORTING';
const SET_EXPORTED = 'builder/SET_EXPORTED';
const EXPORT_TO_PDF_SUCCESS = 'builder/EXPORT_TO_PDF_SUCCESS';
const EXPORT_TO_PDF_FAILED = 'builder/EXPORT_TO_PDF_FAILED';
const SET_LOADING = 'builder/SET_LOADING';
const SET_DEPOT = 'builder/SET_DEPOT';
const SET_PMP = 'builder/SET_PMP';
const SET_REPORT_DEPARTMENT = 'builder/SET_DEPARTMENT';
const SET_REPORT_CATEGORY = 'builder/SET_CATEGORY';
const SET_SUBCATEGORY = 'builder/SET_SUBCATEGORY';
const SET_LEVEL4 = 'builder/SET_LEVEL4';
const SET_LEVEL5 = 'builder/SET_LEVEL5';
const SET_BRAND = 'builder/SET_BRAND';
const SET_BRANDS = 'builder/SET_BRANDS';
const SET_BUSINESS_TYPE = 'builder/SET_BUSINESS_TYPE';
const SET_REPORT_ERROR = 'builder/SET_REPORT_ERROR';
const SET_DEPOTS = 'builder/SET_DEPOTS';
const SET_COMPANY_TYPE = 'builder/SET_COMPANY_TYPE';
const SET_SELECTED_GROUP = 'builder/SET_SELECTED_GROUP';
const SET_MEMBERS = 'builder/SET_MEMBERS';
const SET_TIMEFRAME = 'builder/SET_TIMEFRAME';
const SET_ALT_DEPARTMENT = 'builder/SET_ALT_DEPARTMENT';
const SET_ALT_DEPARTMENTS = 'builder/SET_ALT_DEPARTMENTS';
const SET_ALT_SUBCATEGORY = 'builder/SET_ALT_SUBCATEGORY';
const SET_ALT_LEVEL4 = 'builder/SET_ALT_LEVEL4';
const SET_ALT_LEVEL5 = 'builder/SET_ALT_LEVEL5';
const SET_ALT_BRAND = 'builder/SET_ALT_BRAND';
const SET_ALT_BRANDS = 'builder/SET_ALT_BRANDS';
const FETCH_ALT_PRODUCTS = 'builder/FETCH_ALT_PRODUCTS';
const SET_ALT_PRODUCT = 'builder/SET_ALT_PRODUCT';
const SET_ALT_PRODUCTS_LOADING = 'builder/SET_ALT_PRODUCTS_LOADING';
const SET_PRODUCTS_LOADING = 'builder/SET_PRODUCTS_LOADING';
const SET_ALT_BRANDS_LOADING = 'builder/SET_ALT_BRANDS_LOADING';
const RESET = 'builder/RESET';

const initialState = {
  title: '',
  bnbTotal: 0,
  hideProducts: true,
  bnbGraphData: [],
  bnbTableData: [],
  selectedBnBMembers: [],
  filter: '',
  filterValue: '',
  filterId: '',
  selectedBnBProduct: {},
  reportType: '',
  selectedCustomerTypes: [],
  pdfURL: '',
  bnbProducts: {
    title: "but haven't bought in the last 13 Weeks",
    options: [],
  },
  bnbCategoryHierarchy: {
    title: 'and have bought in the last 13 Weeks',
    options: [],
  },
  bnbMembers: {
    title: 'Show me all customers that have shopped at',
    options: [],
  },
  customerTypes: {
    title: 'and have a business type of',
    options: [
      { Text: 'Company Owned', ID: 'company owned' },
      { Text: 'Independany Retailers', ID: 'independant retailers' },
    ],
  },
  companyTypes: {
    title: 'and have a business type of',
    options: [
      { Text: 'Company Owned', ID: 'company owned' },
      { Text: 'Independant Retailers', ID: 'independant retailers' },
    ],
  },
  selectedCompanyType: '',
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  isExported: false,
  isExporting: false,
  isLoading: false,
  depots: [],
  departments: [],
  department: {},
  categories: [],
  category: {},
  subCategories: [],
  subCategory: {},
  level4s: [],
  level4: {},
  level5s: [],
  level5: {},
  alt_departments: [],
  alt_department: {},
  alt_categories: [],
  alt_category: {},
  alt_subCategories: [],
  alt_subCategory: {},
  alt_level4s: [],
  alt_level4: {},
  alt_level5s: [],
  alt_level5: {},
  alt_brands: [],
  alt_brand: {},
  alt_products: [],
  alt_product: '',
  BnBBrands: [],
  selectedBnBBrand: {},
  BnBCategories: [],
  selectedBnBCategory: {},
  brands: [],
  brand: {},
  selectedDepots: [],
  bnbBusinessTypes: {
    title: 'and have a business type of',
    options: [],
  },
  selectedBusinessType: '',
  reportError: '',
  selectedGroup: [],
  groups: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
        { ID: '4wks', Text: '4 Wk' },
        { ID: '13wks', Text: '13 Wk' },
        { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
        { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
        { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
        { ID: 'YTD', Text: 'YTD' },
        { ID: 'MAT', Text: 'MAT' },
        ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
      ],
  selectedTimeFrame: { ID: 'YTD', Text: 'YTD' },
  alt_productsIsLoading: false,
  productsIsLoading: false,
};

const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setBnBBrandsAction = createAction(SET_BNB_BRANDS);
const setBrandAction = createAction(SET_BRAND);
const setBrandsAction = createAction(SET_BRANDS);
const setAltCategoryAction = createAction(SET_ALT_CATEGORY);
const setAltCategoriesAction = createAction(SET_ALT_CATEGORIES);
const setAltDepartmentAction = createAction(SET_ALT_DEPARTMENT);
const setAltDepartmentsAction = createAction(SET_ALT_DEPARTMENTS);
const setAltSubCategoryAction = createAction(SET_ALT_SUBCATEGORY);
const setAltLevel4Action = createAction(SET_ALT_LEVEL4);
const setAltLevel5Action = createAction(SET_ALT_LEVEL5);
const setAltBrandAction = createAction(SET_ALT_BRAND);
const setAltBrandsAction = createAction(SET_ALT_BRANDS);
const fetchAltProductsAction = createAction(FETCH_ALT_PRODUCTS);
const setAltProductAction = createAction(SET_ALT_PRODUCT);
const setAltBrandsIsLoadingAction = createAction(SET_ALT_BRANDS_LOADING);
const setAltProductsIsLoadingAction = createAction(SET_ALT_PRODUCTS_LOADING);
const setProductsIsLoadingAction = createAction(SET_PRODUCTS_LOADING);
const setTitleAction = createAction(SET_TITLE);
const setPmpAction = createAction(SET_PMP);
const setBnBMemberAction = createAction(SET_BNB_MEMBER);
const setBnBProductAction = createAction(SET_BNB_PRODUCT);
const setBnBBrandAction = createAction(SET_BNB_BRAND);
const setSelectedCustomerTypesAction = createAction(
  SET_SELECTED_CUSTOMER_TYPES,
);
const setSelectedCompanyTypeAction = createAction(SET_COMPANY_TYPE);
const setBnBCategoryAction = createAction(SET_BNB_CATEGORY);
const fetchBnBDefaultsAction = createAction(FETCH_BNB_DEFAULTS);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const fetchBnBDataAction = createAction(FETCH_BNB_DATA);
const setHideProductsAction = createAction(SET_HIDE_PRODUCTS);
const setExportingAction = createAction(SET_EXPORTING);
const setExportedAction = createAction(SET_EXPORTED);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const exportToPdfFailedAction = createAction(EXPORT_TO_PDF_FAILED);
const setLoadingAction = createAction(SET_LOADING);
const setDepotAction = createAction(SET_DEPOT);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setDepotsAction = createAction(SET_DEPOTS);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const setTimeframeAction = createAction(SET_TIMEFRAME);
const resetAction = createAction(RESET);

export const reset = () => dispatch => {
  dispatch(resetAction());
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'bought-not-bought-' + new Date().toISOString(),
      layout: 'landscape',
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const fetchDefaultData = reportType => dispatch => {
  if (reportType === 'BNB') {
    let url = '/route.php?c=category/getUserHierarchy';
    dispatch(setLoadingAction(true));
    request({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(categories => {
      url = '/route.php?c=depot/getDepots';

      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      }).then(depots => {
        if (depots.length > 0) {
          depots.push({ DepotName: 'All' });

          each(depots, depot => {
            depot.DepotName = encodeText(depot.DepotName);
          });
        }

        url = '/route.php?c=wholesaler/getAll';
        request({
          url,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
            UserId: getUserId(),
            System: getUserSystem(),
          },
        }).then(members => {
          each(members, mem => {
            mem.ActualName = encodeText(mem.ActualName);
          });
          if (
            members.length === 1
          ) {
            dispatch(
              setBnBMemberAction({
                members: [
                  { Text: members[0].ActualName, ID: members[0].WholesalerNo },
                ],
                customerTypes: [],
              }),
            );
            members = [members[0]];
          }
          url = '/route.php?c=customer/getAllBusinessTypes';
          request({
            url,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: getToken(),
              UserId: getUserId(),
              System: getUserSystem(),
            },
          })
            .then(businessTypes => {
              businessTypes = businessTypes.map(businessType => {
                return { BusinessName: businessType };
              });
              dispatch(setLoadingAction(false));
              dispatch(
                fetchBnBDefaultsAction({
                  categories: categories.Categories
                    ? categories.Categories
                    : [],
                  departments: categories.Departments
                    ? categories.Departments
                    : [],

                  pmp: '0',
                  members: members,
                  depots: depots,
                  businessTypes: businessTypes,
                  //brands: brands,
                }),
              );
              if (categories.Departments.length === 1) {
                dispatch(setDepartment(categories?.Departments[0]?.ID));
              }
            })
            .catch(err => {
              console.warn(err);
              dispatch(setLoadingAction(false));
              dispatch(
                fetchBnBDefaultsAction({
                  categories: categories.Categories
                    ? categories.Categories
                    : [],
                  departments: categories.Departments
                    ? categories.Departments
                    : [],
                  pmp: '0',
                  members: members,
                  depots: depots,
                  businessTypes: [],
                }),
              );
              if (categories.Departments.length === 1) {
                dispatch(setDepartment(categories?.Departments[0]?.ID));
              }
            });
        });
      });
    });
  }
};

export const setTitle = val => dispatch => {
  dispatch(setTitleAction(val));
};

export const setPmp = val => dispatch => {
  if (val) {
    dispatch(setPmpAction(val));
    loadProducts(store, dispatch);
  } else {
    dispatch(setPmpAction(''));
  }
};

const loadProducts = (store, dispatch, isAlt = false) => {
  let state = store.getState().reportBuilder;
  let signal;
  if (isAlt) {
    if (typeof altProductController !== "undefined") {
      try {
        altProductController.abort();
      } catch (e) {
        console.warn(e);
      }
    }
    altProductController = new AbortController();
    signal = altProductController.signal;
  } else {
    if (typeof productController !== "undefined") {
      try {
        productController.abort();
      } catch (e) {
        console.warn(e);
      }
    }
    productController = new AbortController();
    signal = productController.signal;
  }

  isAlt
    ? dispatch(setAltProductsIsLoadingAction(true))
    : dispatch(setProductsIsLoadingAction(true));
  const altPrefix = isAlt ? 'alt_' : '';
  let url = '/route.php?c=product/getAll';

  const user = store.getState().user.authUser;
  if (user.showCompetitor) {
    url += `&show_competitor=0&supplier=${encodeURIComponent(
      user.supplier.Text,
    )}`;
  }

  if (state[`${altPrefix}department`].Text !== undefined) {
    url +=
      '&department=' + encodeURIComponent(state[`${altPrefix}department`].Text);
  }
  if (state[`${altPrefix}category`].Text !== undefined) {
    url +=
      '&category=' + encodeURIComponent(state[`${altPrefix}category`].Text);
  }
  if (state[`${altPrefix}subCategory`].Text !== undefined) {
    url +=
      '&subcategory=' +
      encodeURIComponent(state[`${altPrefix}subCategory`].Text);
  }
  if (state[`${altPrefix}level4`].Text !== undefined) {
    url += '&level4=' + encodeURIComponent(state[`${altPrefix}level4`].Text);
  }
  if (state[`${altPrefix}level5`].Text !== undefined) {
    url += '&level5=' + encodeURIComponent(state[`${altPrefix}level5`].Text);
  }
  if (state.pmp) {
    url += '&pmp=' + state.pmp;
  }

  if (state[`${altPrefix}brand`].Brand !== undefined) {
    url += '&brand=' + encodeURIComponent(state[`${altPrefix}brand`].Brand);
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    signal,
  }).then(products => {
    each(products, prod => {
      prod.WVProdDesc = encodeText(prod.WVProdDesc);
    });
    if (isAlt) {
      dispatch(fetchAltProductsAction({ products: products }));
    } else {
      dispatch(fetchProductsAction({ products: products }));
    }
    isAlt
      ? dispatch(setAltProductsIsLoadingAction(false))
      : dispatch(setProductsIsLoadingAction(false));
  }).catch(e => {
    console.warn("Request cancelled");
  });
};

const loadBnBBrands = (store, dispatch) => {
  let state = store.getState().reportBuilder;
  let url = '/route.php?c=brand/getAll';

  if (state.department.Text !== undefined) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }
  if (state.category.Text !== undefined) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }
  if (state.subCategory.Text !== undefined) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }
  if (state.level4.Text !== undefined) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }
  if (state.level5.Text !== undefined) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(brands => {
    if (brands.length > 0) {
      each(brands, brd => {
        brd.Brand = encodeText(brd.Brand);
      });
    }

    if (state.brand.Brand !== undefined) {
      dispatch(
        setBnBBrandsAction(
          brands.filter(brand => brand.Brand !== state.brand.Brand),
        ),
      );
    } else {
      dispatch(setBnBBrandsAction(brands));
    }
  }).catch(e => {
    console.warn("Request cancelled");
  });
};

const loadBnBCategories = (store, dispatch) => {
  let state = store.getState().reportBuilder;

  if (state.department.Categories !== undefined) {
    if (state.category.ID !== undefined) {
      dispatch(
        setAltCategoriesAction(
          state.department.Categories.filter(
            category => category.ID !== state.category.ID,
          ),
        ),
      );
    } else {
      dispatch(setAltCategoriesAction(state.department.Categories));
    }
  } else {
    dispatch(setAltCategoriesAction([]));
  }
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  loadChart(store, dispatch);
};

export const bnbMemberChanged = val => async dispatch => {
  if (val.length > 0) {
    dispatch(setBnBMemberAction({ members: val, customerTypes: [] }));
  } else {
    dispatch(setBnBMemberAction({ members: [], customerTypes: [] }));
  }
};

export const setDepot = val => dispatch => {
  const allDepot = val.filter(dpt => dpt.DepotName === 'All');
  if (allDepot.length > 0) {
    const depots = store.getState().reportBuilder.depots;
    dispatch(
      setDepotAction({
        selectedDepots: depots.filter(dpt => dpt.DepotName !== 'All'),
      }),
    );
  } else {
    dispatch(setDepotAction({ selectedDepots: val }));
  }
};

export const setDepartment =
  (val, isAlt = false) =>
  async dispatch => {
    altDepartmentController = new AbortController();
    const { signal } = altDepartmentController;
    const state = store.getState().reportBuilder;
    if (parseInt(val) > 0) {
      let department = isAlt
        ? _.filter(state.alt_departments, dpt => {
            if (dpt.ID === parseInt(val)) {
              return dpt;
            }
          })
        : _.filter(state.departments, dpt => {
            if (dpt.ID === parseInt(val)) {
              return dpt;
            }
          });
      let dpt = department[0];
      if (
        state.reportType !== 'depot' &&
        state.reportType !== 'supplier' &&
        state.reportType !== 'member' &&
        state.reportType !== 'brand' &&
        state.reportType !== 'level4' &&
        state.reportType !== 'level5'
      ) {
        dpt.reportType = 'category';
      } else {
        dpt.reportType = state.reportType;
      }
      let cat = null;
      let subcat = null;
      let catQueryString = '';
      let subcatQueryString = '';

      if (dpt.Categories.length === 1) {
        cat = dpt.Categories[0];
        catQueryString = '&category=' + encodeURIComponent(cat.Text);
        if (
          state.reportType !== 'depot' &&
          state.reportType !== 'supplier' &&
          state.reportType !== 'member' &&
          state.reportType !== 'brand'
        ) {
          cat.reportType = 'subcategory';
        } else {
          cat.reportType = state.reportType;
        }
        if (cat.SubCategories.length === 1) {
          subcat = cat.SubCategories[0];
          subcatQueryString = '&subcategory=' + encodeURIComponent(subcat.Text);
          if (
            state.reportType !== 'depot' &&
            state.reportType !== 'supplier' &&
            state.reportType !== 'member' &&
            state.reportType !== 'brand'
          ) {
            subcat.reportType = 'level4';
          } else {
            subcat.reportType = state.reportType;
          }
        }
      }

      if (isAlt) {
        dispatch(
          setAltDepartmentAction({
            department: dpt,
          }),
        );
      } else {
        dispatch(
          setReportDepartmentAction({
            department: dpt,
          }),
        );
      }
      let url =
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(dpt.Text) +
        catQueryString +
        subcatQueryString;
      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
        signal,
      }).then(brands => {
        if (brands.length > 0) {
          each(brands, brd => {
            brd.Brand = encodeText(brd.Brand);
          });
        }

        if (isAlt) {
          dispatch(setAltBrandsAction(brands));
        } else {
          dispatch(setBrandsAction(brands));
        }

        if (cat) {
          if (isAlt) {
            dispatch(
              setAltCategoryAction({
                category: cat,
                brands: brands,
              }),
            );
          } else {
            dispatch(
              setReportCategoryAction({
                category: cat,
                brands: brands,
              }),
            );
          }
        }
        if (subcat) {
          if (isAlt) {
            dispatch(
              setAltSubCategoryAction({
                subCategory: subcat,
                brands: brands,
              }),
            );
          } else {
            dispatch(
              setSubCategoryAction({
                subCategory: subcat,
                brands: brands,
              }),
            );
          }
        }
        loadProducts(store, dispatch, isAlt);

        dispatch(setHideProductsAction(false));
      }).catch(e => {
        console.warn("Request cancelled");
      });
    } else if (parseInt(val) === 0) {
      if (isAlt) {
        dispatch(fetchAltProductsAction({ products: [] }));
        dispatch(
          setAltLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setAltLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setAltSubCategoryAction({
            subCategory: {},
          }),
        );
        dispatch(
          setAltCategoryAction({
            category: {},
          }),
        );

        dispatch(
          setAltDepartmentAction({
            deparment: {},
          }),
        );
        dispatch(setAltBrandAction({}));
      } else {
        dispatch(fetchProductsAction({ products: [] }));
        dispatch(
          setLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setSubCategoryAction({
            subCategory: {},
          }),
        );
        dispatch(
          setReportCategoryAction({
            category: {},
          }),
        );

        dispatch(
          setReportDepartmentAction({
            deparment: {},
          }),
        );
        dispatch(setBrandAction({}));
      }
    } else {
      let department = _.filter(state.departments, dpt => {
        if (dpt.Text === val) {
          return dpt;
        }
      });
      let dpt = department[0];

      dispatch(setReportDepartmentAction(dpt));
    }
  };

export const setCategory =
  (val, isAlt = false) =>
  async dispatch => {
    if (parseInt(val) > 0) {
      if (typeof altDepartmentController !== "undefined") {
        altDepartmentController.abort();
      }
      altCategoryController = new AbortController();
      const { signal } = altCategoryController;

      try {
        isAlt ? altProductController.abort() : productController.abort();
      } catch (e) {
        console.warn(e);
      }
      isAlt ? dispatch(setAltBrandsAction([])) : dispatch(setBrandsAction([]));
      isAlt
        ? dispatch(fetchAltProductsAction({ products: [] }))
        : dispatch(fetchProductsAction({ products: [] }));

      let category = isAlt
        ? _.filter(store.getState().reportBuilder.alt_categories, cat => {
            if (cat.ID === parseInt(val)) {
              return cat;
            }
          })
        : _.filter(store.getState().reportBuilder.categories, cat => {
            if (cat.ID === parseInt(val)) {
              return cat;
            }
          });
      let catt = category[0];
      if (
        store.getState().reportBuilder.reportType !== 'depot' &&
        store.getState().reportBuilder.reportType !== 'supplier' &&
        store.getState().reportBuilder.reportType !== 'member' &&
        store.getState().reportBuilder.reportType !== 'brand'
      ) {
        catt.reportType = 'subcategory';
      } else {
        catt.reportType = store.getState().reportBuilder.reportType;
      }

      let subcatQueryString = '';
      let subcat = null;

      if (catt.SubCategories.length === 1) {
        subcat = catt.SubCategories[0];
        subcatQueryString = '&subcategory=' + encodeURIComponent(subcat.Text);
        if (
          store.getState().reportBuilder.reportType !== 'depot' &&
          store.getState().reportBuilder.reportType !== 'supplier' &&
          store.getState().reportBuilder.reportType !== 'member' &&
          store.getState().reportBuilder.reportType !== 'brand'
        ) {
          subcat.reportType = 'level4';
        } else {
          subcat.reportType = store.getState().reportBuilder.reportType;
        }
      }

      let department = isAlt
        ? store.getState().reportBuilder.alt_department
        : store.getState().reportBuilder.department;

      let url =
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(department.Text) +
        '&category=' +
        encodeURIComponent(catt.Text) +
        subcatQueryString;

      if (isAlt) {
        dispatch(
          setAltCategoryAction({
            category: catt,
          }),
        );
      } else {
        dispatch(
          setReportCategoryAction({
            category: catt,
          }),
        );
      }

      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
        signal,
      }).then(brands => {
        if (brands.length > 0) {
          each(brands, brd => {
            brd.Brand = encodeText(brd.Brand);
          });
        }

        if (isAlt) {
          dispatch(setAltBrandsAction(brands));
        } else {
          dispatch(setBrandsAction(brands));
        }

        if (subcat) {
          if (isAlt) {
            dispatch(
              setAltSubCategoryAction({
                subCategory: subcat,
                brands: brands,
              }),
            );
          } else {
            dispatch(
              setSubCategoryAction({
                subCategory: subcat,
                brands: brands,
              }),
            );
          }
        }
        loadProducts(store, dispatch, isAlt);

        dispatch(setHideProductsAction(false));
      }).catch(e => {
        console.warn("Request cancelled");
      });
    } else if (parseInt(val) === 0) {
      if (isAlt) {
        dispatch(fetchAltProductsAction({ products: [] }));
        dispatch(
          setAltLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setAltLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setAltSubCategoryAction({
            subCategory: {},
          }),
        );
        dispatch(
          setAltCategoryAction({
            category: {},
          }),
        );

        dispatch(setAltBrandAction({}));
      } else {
        dispatch(fetchProductsAction({ products: [] }));
        dispatch(
          setLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setSubCategoryAction({
            subCategory: {},
          }),
        );
        dispatch(
          setReportCategoryAction({
            category: {},
          }),
        );

        dispatch(setBrandAction({}));
      }
    } else {
      let category = _.filter(
        store.getState().reportBuilder.categories,
        cat => {
          if (cat.Text === val) {
            return cat;
          }
        },
      );
      let catt = category[0];
      if (
        store.getState().reportBuilder.reportType !== 'depot' &&
        store.getState().reportBuilder.reportType !== 'supplier' &&
        store.getState().reportBuilder.reportType !== 'member' &&
        store.getState().reportBuilder.reportType !== 'brand'
      ) {
        catt.reportType = 'subcategory';
      } else {
        catt.reportType = store.getState().reportBuilder.reportType;
      }
      dispatch(setReportCategoryAction(catt));
    }
  };

export const setSubCategory =
  (val, isAlt = false) =>
  async dispatch => {
    if (parseInt(val) > 0) {
      if (typeof altCategoryController !== "undefined") {
        altCategoryController.abort();
      }
      altSubCategoryController = new AbortController();
      const { signal } = altSubCategoryController;
      try {
        isAlt ? altProductController.abort() : productController.abort();
      } catch (e) {
        console.warn(e);
      }
      isAlt ? dispatch(setAltBrandsAction([])) : dispatch(setBrandsAction([]));
      isAlt
        ? dispatch(fetchAltProductsAction({ products: [] }))
        : dispatch(fetchProductsAction({ products: [] }));
      if (!isAlt) {
        _.each(store.getState().reportBuilder.categories, cat => {
          _.each(cat.SubCategories, subcat => {
            if (subcat.ID === val) {
              if (
                store.getState().reportBuilder.reportType !== 'depot' &&
                store.getState().reportBuilder.reportType !== 'supplier' &&
                store.getState().reportBuilder.reportType !== 'member' &&
                store.getState().reportBuilder.reportType !== 'brand'
              ) {
                subcat.reportType = 'level4';
              } else {
                subcat.reportType = store.getState().reportBuilder.reportType;
              }
              let department = store.getState().reportBuilder.department;

              let url =
                '/route.php?c=brand/getAll&department=' +
                encodeURIComponent(department.Text) +
                '&category=' +
                encodeURIComponent(cat.Text) +
                '&subcategory=' +
                encodeURIComponent(subcat.Text);

              dispatch(
                setSubCategoryAction({
                  subCategory: subcat,
                }),
              );

              request({
                url: url,
                method: 'get',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: getToken(),
                  UserId: getUserId(),
                  System: getUserSystem(),
                },
                signal,
              }).then(brands => {
                if (brands.length > 0) {
                  each(brands, brd => {
                    brd.Brand = encodeText(brd.Brand);
                  });
                }

                dispatch(setBrandsAction(brands));

                loadProducts(store, dispatch, isAlt);

                dispatch(setHideProductsAction(false));
              }).catch(e => {
                console.warn("Request cancelled");
              });
            }
          });
        });
      } else {
        _.each(store.getState().reportBuilder.alt_categories, cat => {
          _.each(cat.SubCategories, subcat => {
            if (subcat.ID === val) {
              if (
                store.getState().reportBuilder.reportType !== 'depot' &&
                store.getState().reportBuilder.reportType !== 'supplier' &&
                store.getState().reportBuilder.reportType !== 'member' &&
                store.getState().reportBuilder.reportType !== 'brand'
              ) {
                subcat.reportType = 'level4';
              } else {
                subcat.reportType = store.getState().reportBuilder.reportType;
              }
              let department = store.getState().reportBuilder.alt_department;

              let url =
                '/route.php?c=brand/getAll&department=' +
                encodeURIComponent(department.Text) +
                '&category=' +
                encodeURIComponent(cat.Text) +
                '&subcategory=' +
                encodeURIComponent(subcat.Text);
              dispatch(
                setAltSubCategoryAction({
                  subCategory: subcat,
                }),
              );
              request({
                url: url,
                method: 'get',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: getToken(),
                  UserId: getUserId(),
                  System: getUserSystem(),
                },
                signal,
              }).then(brands => {
                if (brands.length > 0) {
                  each(brands, brd => {
                    brd.Brand = encodeText(brd.Brand);
                  });
                }

                dispatch(setAltBrandsAction(brands));
                loadProducts(store, dispatch, isAlt);

                dispatch(setHideProductsAction(false));
              }).catch(e => {
                console.warn("Request cancelled");
              });
            }
          });
        });
      }
    } else if (parseInt(val) === 0) {
      if (isAlt) {
        dispatch(fetchAltProductsAction({ products: [] }));
        dispatch(
          setAltLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setAltLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setAltSubCategoryAction({
            subCategory: {},
          }),
        );

        dispatch(setAltBrandAction({}));
      } else {
        dispatch(fetchProductsAction({ products: [] }));
        dispatch(
          setLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setLevel4Action({
            level4: {},
          }),
        );
        dispatch(
          setSubCategoryAction({
            subCategory: {},
          }),
        );

        dispatch(setBrandAction({}));
      }
    } else {
      _.each(store.getState().reportBuilder.categories, cat => {
        _.each(cat.SubCategories, subcat => {
          if (subcat.Text === val) {
            if (
              store.getState().reportBuilder.reportType !== 'depot' &&
              store.getState().reportBuilder.reportType !== 'supplier' &&
              store.getState().reportBuilder.reportType !== 'member' &&
              store.getState().reportBuilder.reportType !== 'brand'
            ) {
              subcat.reportType = 'level4';
            } else {
              subcat.reportType = store.getState().reportBuilder.reportType;
            }
            let department = store.getState().reportBuilder.department;

            let url =
              '/route.php?c=brand/getAll&department=' +
              encodeURIComponent(department.Text) +
              '&category=' +
              encodeURIComponent(cat.Text) +
              '&subcategory=' +
              encodeURIComponent(subcat.Text);

            request({
              url: url,
              method: 'get',
              headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
                UserId: getUserId(),
                System: getUserSystem(),
              },
              signal,
            }).then(brands => {
              if (brands.length > 0) {
                each(brands, brd => {
                  brd.Brand = encodeText(brd.Brand);
                });
              }
              dispatch(
                setSubCategoryAction({
                  subCategory: subcat,
                  brands: brands,
                }),
              );
            }).catch(e => {
              console.warn("Request cancelled");
            });
          }
        });
      });
    }
  };

export const setLevel4 =
  (val, isAlt = false) =>
  async dispatch => {
    if (parseInt(val) > 0) {
      if (typeof altSubCategoryController !== "undefined") {
        altSubCategoryController.abort();
      }
      altLevel4Controller = new AbortController();
      const { signal } = altLevel4Controller;
      try {
        isAlt ? altProductController.abort() : productController.abort();
      } catch (e) {
        console.warn(e);
      }
      isAlt ? dispatch(setAltBrandsAction([])) : dispatch(setBrandsAction([]));
      isAlt
        ? dispatch(fetchAltProductsAction({ products: [] }))
        : dispatch(fetchProductsAction({ products: [] }));
      if (!isAlt) {
        _.each(store.getState().reportBuilder.categories, cat => {
          if (cat.ID === store.getState().reportBuilder.category.ID) {
            _.each(cat.SubCategories, subcat => {
              if (subcat.ID === store.getState().reportBuilder.subCategory.ID) {
                _.each(subcat.Level4, lvl4 => {
                  if (lvl4.ID === val) {
                    if (
                      store.getState().reportBuilder.reportType !== 'depot' &&
                      store.getState().reportBuilder.reportType !==
                        'supplier' &&
                      store.getState().reportBuilder.reportType !== 'member' &&
                      store.getState().reportBuilder.reportType !== 'brand'
                    ) {
                      lvl4.reportType = 'level5';
                    } else {
                      lvl4.reportType =
                        store.getState().reportBuilder.reportType;
                    }
                    let department = store.getState().reportBuilder.department;

                    let url =
                      '/route.php?c=brand/getAll&department=' +
                      encodeURIComponent(department.Text) +
                      '&category=' +
                      encodeURIComponent(cat.Text) +
                      '&subcategory=' +
                      encodeURIComponent(subcat.Text) +
                      '&level4=' +
                      encodeURIComponent(lvl4.Text);

                    dispatch(
                      setLevel4Action({
                        level4: lvl4,
                      }),
                    );

                    request({
                      url: url,
                      method: 'get',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: getToken(),
                        UserId: getUserId(),
                        System: getUserSystem(),
                      },
                      signal,
                    }).then(brands => {
                      if (brands.length > 0) {
                        each(brands, brd => {
                          brd.Brand = encodeText(brd.Brand);
                        });
                      }

                      dispatch(setBrandsAction(brands));
                      loadProducts(store, dispatch, isAlt);

                      dispatch(setHideProductsAction(false));
                    }).catch(e => {
                      console.warn("Request cancelled");
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        _.each(store.getState().reportBuilder.alt_categories, cat => {
          if (cat.ID === store.getState().reportBuilder.alt_category.ID) {
            _.each(cat.SubCategories, subcat => {
              if (
                subcat.ID === store.getState().reportBuilder.alt_subCategory.ID
              ) {
                _.each(subcat.Level4, lvl4 => {
                  if (lvl4.ID === val) {
                    if (
                      store.getState().reportBuilder.reportType !== 'depot' &&
                      store.getState().reportBuilder.reportType !==
                        'supplier' &&
                      store.getState().reportBuilder.reportType !== 'member' &&
                      store.getState().reportBuilder.reportType !== 'brand'
                    ) {
                      lvl4.reportType = 'level5';
                    } else {
                      lvl4.reportType =
                        store.getState().reportBuilder.reportType;
                    }
                    let department =
                      store.getState().reportBuilder.alt_department;

                    let url =
                      '/route.php?c=brand/getAll&department=' +
                      encodeURIComponent(department.Text) +
                      '&category=' +
                      encodeURIComponent(cat.Text) +
                      '&subcategory=' +
                      encodeURIComponent(subcat.Text) +
                      '&level4=' +
                      encodeURIComponent(lvl4.Text);

                    dispatch(
                      setAltLevel4Action({
                        level4: lvl4,
                      }),
                    );

                    request({
                      url: url,
                      method: 'get',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: getToken(),
                        UserId: getUserId(),
                        System: getUserSystem(),
                      },
                      signal,
                    }).then(brands => {
                      if (brands.length > 0) {
                        each(brands, brd => {
                          brd.Brand = encodeText(brd.Brand);
                        });
                      }
                      dispatch(setAltBrandsAction(brands));
                      loadProducts(store, dispatch, isAlt);

                      dispatch(setHideProductsAction(false));
                    }).catch(e => {
                      console.warn("Request cancelled");
                    });
                  }
                });
              }
            });
          }
        });
      }
    } else if (parseInt(val) === 0) {
      if (isAlt) {
        dispatch(fetchAltProductsAction({ products: [] }));
        dispatch(
          setAltLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setAltLevel4Action({
            level4: {},
          }),
        );

        dispatch(setAltBrandAction({}));
      } else {
        dispatch(fetchProductsAction({ products: [] }));
        dispatch(
          setLevel5Action({
            level5: {},
          }),
        );
        dispatch(
          setLevel4Action({
            level4: {},
          }),
        );

        dispatch(setBrandAction({}));
      }
    } else {
      _.each(store.getState().reportBuilder.categories, cat => {
        if (cat.ID === store.getState().reportBuilder.category.ID) {
          _.each(cat.SubCategories, subcat => {
            if (subcat.ID === store.getState().reportBuilder.subCategory.ID) {
              _.each(subcat.Level4, lvl4 => {
                if (lvl4.Text === val) {
                  if (
                    store.getState().reportBuilder.reportType !== 'depot' &&
                    store.getState().reportBuilder.reportType !== 'supplier' &&
                    store.getState().reportBuilder.reportType !== 'member' &&
                    store.getState().reportBuilder.reportType !== 'brand'
                  ) {
                    lvl4.reportType = 'level5';
                  } else {
                    lvl4.reportType = store.getState().reportBuilder.reportType;
                  }
                  let department = store.getState().reportBuilder.department;

                  let url =
                    '/route.php?c=brand/getAll&department=' +
                    encodeURIComponent(department.Text) +
                    '&category=' +
                    encodeURIComponent(cat.Text) +
                    '&subcategory=' +
                    encodeURIComponent(subcat.Text) +
                    '&level4=' +
                    encodeURIComponent(lvl4.Text);

                  request({
                    url: url,
                    method: 'get',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: getToken(),
                      UserId: getUserId(),
                      System: getUserSystem(),
                    },
                  }).then(brands => {
                    if (brands.length > 0) {
                      each(brands, brd => {
                        brd.Brand = encodeText(brd.Brand);
                      });
                    }
                    dispatch(
                      setLevel4Action({
                        level4: lvl4,
                        brands: brands,
                      }),
                    );
                  }).catch(e => {
                    console.warn("Request cancelled");
                  });
                }
              });
            }
          });
        }
      });
    }
  };

export const setLevel5 =
  (val, isAlt = false) =>
  async dispatch => {
    if (parseInt(val) > 0) {
      if (typeof altLevel4Controller !== "undefined") {
        altLevel4Controller.abort();
      }
      altLevel5Controller = new AbortController();
      const { signal } = altLevel5Controller;
      try {
        isAlt ? altProductController.abort() : productController.abort();
      } catch (e) {
        console.warn(e);
      }
      isAlt ? dispatch(setAltBrandsAction([])) : dispatch(setBrandsAction([]));
      isAlt
        ? dispatch(fetchAltProductsAction({ products: [] }))
        : dispatch(fetchProductsAction({ products: [] }));
      if (isAlt) {
        _.each(store.getState().reportBuilder.alt_categories, cat => {
          if (cat.ID === store.getState().reportBuilder.alt_category.ID) {
            _.each(cat.SubCategories, subcat => {
              if (
                subcat.ID === store.getState().reportBuilder.alt_subCategory.ID
              ) {
                _.each(subcat.Level4, lvl4 => {
                  if (
                    lvl4.ID === store.getState().reportBuilder.alt_level4.ID
                  ) {
                    _.each(lvl4.Level5, lvl5 => {
                      if (lvl5.ID === val) {
                        let department =
                          store.getState().reportBuilder.alt_department;

                        let url =
                          '/route.php?c=brand/getAll&department=' +
                          encodeURIComponent(department.Text) +
                          '&category=' +
                          encodeURIComponent(cat.Text) +
                          '&subcategory=' +
                          encodeURIComponent(subcat.Text) +
                          '&level4=' +
                          encodeURIComponent(lvl4.Text) +
                          '&level5=' +
                          encodeURIComponent(lvl5.Text);

                        dispatch(
                          setAltLevel5Action({
                            level5: lvl5,
                          }),
                        );

                        request({
                          url: url,
                          method: 'get',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: getToken(),
                            UserId: getUserId(),
                            System: getUserSystem(),
                          },
                          signal,
                        }).then(brands => {
                          if (brands.length > 0) {
                            each(brands, brd => {
                              brd.Brand = encodeText(brd.Brand);
                            });
                          }
                          dispatch(setAltBrandsAction(brands));
                          loadProducts(store, dispatch, isAlt);

                          dispatch(setHideProductsAction(false));
                        }).catch(e => {
                          console.warn("Request cancelled");
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      } else {
        _.each(store.getState().reportBuilder.categories, cat => {
          if (cat.ID === store.getState().reportBuilder.category.ID) {
            _.each(cat.SubCategories, subcat => {
              if (subcat.ID === store.getState().reportBuilder.subCategory.ID) {
                _.each(subcat.Level4, lvl4 => {
                  if (lvl4.ID === store.getState().reportBuilder.level4.ID) {
                    _.each(lvl4.Level5, lvl5 => {
                      if (lvl5.ID === val) {
                        let department =
                          store.getState().reportBuilder.department;

                        let url =
                          '/route.php?c=brand/getAll&department=' +
                          encodeURIComponent(department.Text) +
                          '&category=' +
                          encodeURIComponent(cat.Text) +
                          '&subcategory=' +
                          encodeURIComponent(subcat.Text) +
                          '&level4=' +
                          encodeURIComponent(lvl4.Text) +
                          '&level5=' +
                          encodeURIComponent(lvl5.Text);

                        dispatch(
                          setLevel5Action({
                            level5: lvl5,
                          }),
                        );

                        request({
                          url: url,
                          method: 'get',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: getToken(),
                            UserId: getUserId(),
                            System: getUserSystem(),
                          },
                          signal,
                        }).then(brands => {
                          if (brands.length > 0) {
                            each(brands, brd => {
                              brd.Brand = encodeText(brd.Brand);
                            });
                          }
                          dispatch(setBrandsAction(brands));
                          loadProducts(store, dispatch, isAlt);

                          dispatch(setHideProductsAction(false));
                        }).catch(e => {
                          console.warn("Request cancelled");
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    } else if (parseInt(val) === 0) {
      if (isAlt) {
        dispatch(fetchAltProductsAction({ products: [] }));
        dispatch(
          setAltLevel5Action({
            level5: {},
          }),
        );

        dispatch(setAltBrandAction({}));
      } else {
        dispatch(fetchProductsAction({ products: [] }));
        dispatch(
          setLevel5Action({
            level5: {},
          }),
        );

        dispatch(setBrandAction({}));
      }
    } else {
      _.each(store.getState().reportBuilder.categories, cat => {
        if (cat.ID === store.getState().reportBuilder.category.ID) {
          _.each(cat.SubCategories, subcat => {
            if (subcat.ID === store.getState().reportBuilder.subCategory.ID) {
              _.each(subcat.Level4, lvl4 => {
                if (lvl4.ID === store.getState().reportBuilder.level4.ID) {
                  _.each(lvl4.Level5, lvl5 => {
                    if (lvl5.Text === val) {
                      let department =
                        store.getState().reportBuilder.department;

                      let url =
                        '/route.php?c=brand/getAll&department=' +
                        encodeURIComponent(department.Text) +
                        '&category=' +
                        encodeURIComponent(cat.Text) +
                        '&subcategory=' +
                        encodeURIComponent(subcat.Text) +
                        '&level4=' +
                        encodeURIComponent(lvl4.Text) +
                        '&level5=' +
                        encodeURIComponent(lvl5.Text);

                      request({
                        url: url,
                        method: 'get',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: getToken(),
                          UserId: getUserId(),
                          System: getUserSystem(),
                        },
                      }).then(brands => {
                        if (brands.length > 0) {
                          each(brands, brd => {
                            brd.Brand = encodeText(brd.Brand);
                          });
                        }
                        dispatch(
                          setLevel5Action({
                            level5: lvl5,
                            brands: brands,
                          }),
                        );
                      }).catch(e => {
                        console.warn("Request cancelled");
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  };

export const setBrand =
  (val, isAlt = false) =>
  async dispatch => {
    if (val.length > 0) {
      if (isAlt) {
        _.each(store.getState().reportBuilder.alt_brands, brand => {
          if (brand.Brand === val) {
            dispatch(setAltBrandAction(brand));

            loadProducts(store, dispatch, isAlt);
            dispatch(setHideProductsAction(false));
          }
        });
      } else {
        _.each(store.getState().reportBuilder.brands, brand => {
          if (brand.Brand === val) {
            dispatch(setBrandAction(brand));

            loadProducts(store, dispatch);
            dispatch(setHideProductsAction(false));
          }
        });
      }
    } else {
      dispatch(setBrandAction({}));
    }
  };

export const customerTypeChanged = val => dispatch => {
  let state = store.getState().reportBuilder;
  dispatch(setSelectedCustomerTypesAction(val));
};

export const selectAllCustomerTypes = val => dispatch => {
  let options = _.filter(
    store.getState().reportBuilder.customerTypes.options,
    opt => {
      return opt.CustomerType.length > 0;
    },
  );
  dispatch(setSelectedCustomerTypesAction(options));
};

export const deselectAllCustomerTypes = val => dispatch => {
  dispatch(setSelectedCustomerTypesAction([]));
};

export const bnbProductChanged =
  (val, isAlt = false) =>
  dispatch => {
    if (isAlt) {
      if (val !== null) {
        dispatch(setAltProductAction(val));
      } else {
        dispatch(setAltProductAction({}));
      }
    } else {
      if (val !== null) {
        dispatch(setBnBProductAction(val));
      } else {
        dispatch(setBnBProductAction({}));
      }
    }
  };

export const bnbBrandChanged = val => dispatch => {
  if (val !== null) {
    dispatch(setBnBBrandAction(val));
  } else {
    dispatch(setBnBBrandAction({}));
  }
};

export const bnbCategoryChanged = val => dispatch => {
  if (val !== null) {
    dispatch(setAltCategoryAction(val));
  } else {
    dispatch(setAltCategoryAction({}));
  }
};

export const setBusinessType = val => dispatch => {
  dispatch(setBusinessTypeAction(val));
};

export const setTimeFrame = val => dispatch => {
  dispatch(setTimeframeAction(val));
};

const loadChart = (store, dispatch) => {
  dispatch(setReportErrorAction(''));
  let state = store.getState().reportBuilder;
  const filterValue = getLastSelectedFilterBNB(state, false, false);
  let members = [];
  _.each(state.selectedBnBMembers, mems => {
    members.push(mems.Text);
  });

  const valueKeys = {
    brand: 'Brand',
    product: 'WVProdCode',
  };

  const valueKey = valueKeys[filterValue?.id] || 'Text';

  let commonParams =
    '&filter=' +
    encodeURIComponent(filterValue?.id) +
    '&filterValue=' +
    encodeURIComponent(filterValue?.value[valueKey]) +
    '&product=' +
    encodeURIComponent(state.selectedBnBProduct.WVProdDesc);

  commonParams += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  commonParams +=
    state.brand && state.brand.Brand
      ? '&brand=' + encodeURIComponent(state.brand.Brand)
      : '';

  commonParams +=
    '&pmp=' +
    state.pmp +
    '&timeframe=' +
    (state.selectedTimeFrame?.ID || state.selectedTimeFrame);

  if (members.length) {
    commonParams += '&wholesaler=' + encodeURIComponent(members.join(','));
  } else {
    commonParams += '&wholesaler=';
  }
  if (state.selectedDepots?.length > 0) {
    commonParams +=
      '&depot=' +
      state.selectedDepots
        .map(depot => encodeURIComponent(depot.DepotName))
        .join(',');
  }

  commonParams +=
    state.selectedBusinessType.length > 0
      ? '&business_type=' +
        encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';

  if (state.selectedCompanyType) {
    commonParams += `&company_type=${encodeURIComponent(
      state.selectedCompanyType?.ID,
    )}`;
  }

  if (state.ownBrand && state.ownBrand.Text) {
    commonParams += `&ownBrand=${state.ownBrand.Text}`;
  }

  const AltMap = {
    department: state.alt_department,
    category: state.alt_category,
    subcategory: state.alt_subCategory,
    level4: state.alt_level4,
    level5: state.alt_level5,
    brand: state.alt_brand,
    product: state.alt_product,
  };
  const { selectedAdvancedBusinessTypes } = store.getState().filters;
  const altFilter = getLastSelectedFilterBNB(state, true, false);
  if (altFilter) {
    commonParams += `&alt_filter=${encodeURIComponent(altFilter.id)}`;
  }

  const altValueKey = valueKeys[altFilter?.id] || 'Text';
  if (altFilter?.value && altFilter?.value[altValueKey]) {
    commonParams += `&alt_filterValue=${encodeURIComponent(
      altFilter?.value[altValueKey],
    )}`;
  }

  if (state.alt_brand) {
    commonParams += `&alt_brand=${encodeURIComponent(state.alt_brand.Brand)}`;
  }

  if (state.alt_product) {
    commonParams += `&alt_product=${encodeURIComponent(
      state.alt_product.WVProdDesc,
    )}`;
  }

  selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
    if (value) {
      commonParams += value.length > 0 ? `&${encodeURIComponent(ApiName)}=${value
        .map(val => encodeURIComponent(val.value))
        .join(',')}`
        : '';

      commonParams += value.length > 0 ? `&alt_${encodeURIComponent(ApiName)}=${value
        .map(val => encodeURIComponent(val.value))
        .join(',')}`
        : '';
    }
  });

  let url =
    '/route.php?c=boughtNotBought/getboughtNotBoughtReport' + commonParams;

  url = formatBnBUrl(url, state);
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      const tableData = {
        headers: [{ text: 'ACCOUNT NUMBER' }, { text: 'CATEGORY SPEND' }],
        rows: [],
      };
      let total = 0;
      _.each(response, resp => {
        total += parseInt(resp.value);
      });
      let result = { table: {}, chart: [], total: total };
      result.chart = response;
      url =
        '/route.php?c=boughtNotBought/getboughtNotBoughtReportTable' +
        commonParams;
      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      })
        .then(respTable => {
          dispatch(setLoadingAction(false));
          if (isArray(respTable) && respTable.length && respTable.length > 0) {
            respTable.forEach(row => {
              const cells = [{ text: row.RegNo }, { text: row.Value }];
              tableData.rows.push(cells);
            });
          }
          result.table = tableData;
          dispatch(fetchBnBDataAction(result));
        })
        .catch(err => {
          dispatch(setLoadingAction(false));
          dispatch(
            setReportErrorAction('Could not load report, please try again.'),
          );
        });
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(
        setReportErrorAction('Could not load report, please try again.'),
      );
    });
};

export const setCompanyType = val => dispatch => {
  dispatch(setSelectedCompanyTypeAction(val));
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export default handleActions(
  {
    [SET_TITLE]: (state, { payload }) => ({
      ...state,
      title: payload,
      bnbTotal: 0,
      bnbGraphData: [],
      bnbTableData: [],
      selectedBnBMembers: [],
      filter: '',
      filterValue: '',
      filterId: '',
      selectedBnBProduct: {},
      selectedCustomerTypes: [],
      bnbProducts: {
        title: "but haven't bought",
        options: [],
      },
      bnbCategoryHierarchy: {
        title: 'and have bought',
        options: [],
      },
      bnbMembers: {
        title: 'Show me all customers that have shopped at',
        options: [],
      },
      customerTypes: {
        title: 'and have a business type of',
        options: [],
      },
      isLoading: false,
      bnbBusinessTypes: {
        title: 'and have a business type of',
        options: [],
      },
    }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [RESET]: (state, { payload }) => ({
      ...state,
      title: '',
      bnbTotal: 0,
      hideProducts: true,
      bnbGraphData: [],
      bnbTableData: [],
      selectedBnBMembers: [],
      filter: '',
      filterValue: '',
      filterId: '',
      selectedBnBProduct: {},
      reportType: '',
      selectedCustomerTypes: [],
      pdfURL: '',
      bnbProducts: {
        title: "but haven't bought in the last 13 Weeks",
        options: [],
      },
      bnbCategoryHierarchy: {
        title: 'and have bought in the last 13 Weeks',
        options: [],
      },
      bnbMembers: {
        title: 'Show me all customers that have shopped at',
        options: [],
      },
      customerTypes: {
        title: 'and have a business type of',
        options: [],
      },
      pmpList: [
        { ID: '0', Text: 'Parent' },
        { ID: '1', Text: 'Child' },
      ],
      pmp: '',
      isExported: false,
      isExporting: false,
      isLoading: false,
      depots: [],
      departments: [],
      department: {},
      categories: [],
      category: {},
      subCategories: [],
      subCategory: {},
      level4s: [],
      level4: {},
      level5s: [],
      level5: {},
      brands: [],
      brand: {},

      alt_departments: [],
      alt_department: {},
      alt_categories: [],
      alt_category: {},
      alt_subCategories: [],
      alt_subCategory: {},
      alt_level4s: [],
      alt_level4: {},
      alt_level5s: [],
      alt_level5: {},
      alt_brands: [],
      alt_brand: {},
      alt_products: [],
      alt_product: {},
      bnbBrands: [],
      selectedBnBBrand: {},
      BnBCategories: [],
      selectedBnBCategory: {},
      selectedDepots: [],
      alt_departments: [],
      alt_department: {},
      alt_categories: [],
      alt_category: {},
      alt_subCategories: [],
      alt_subCategory: {},
      alt_level4s: [],
      alt_level4: {},
      alt_level5s: [],
      alt_level5: {},
      alt_brands: [],
      alt_brand: {},
      alt_products: [],
      alt_product: {},
      bnbBusinessTypes: {
        title: 'and have a business type of',
        options: [],
      },
      selectedBusinessType: '',
      reportError: '',
    }),
    [SET_BNB_MEMBER]: (state, { payload }) => ({
      ...state,
      selectedBnBMembers: payload.members,
      customerTypes: {
        title: state.customerTypes.title,
        options: payload.customerTypes,
      },
    }),
    [SET_BNB_PRODUCT]: (state, { payload }) => ({
      ...state,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, 'product')
        : 'product',
      selectedBnBProduct: payload,
    }),
    [SET_BNB_CATEGORY]: (state, { payload }) => ({
      ...state,
      filter: payload.filter,
      filterValue: payload.filterValue,
      filterId: payload.filterId,
    }),
    [SET_SELECTED_CUSTOMER_TYPES]: (state, { payload }) => ({
      ...state,
      selectedCustomerTypes: payload,
    }),
    [FETCH_BNB_DATA]: (state, { payload }) => ({
      ...state,
      bnbTableData: payload.table,
      bnbGraphData: payload.chart,
      bnbTotal: payload.total,
    }),
    [SET_HIDE_PRODUCTS]: (state, { payload }) => ({
      ...state,
      hideProducts: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      bnbProducts: {
        title: state.bnbProducts.title,
        options: payload.products,
      },
    }),
    [FETCH_ALT_PRODUCTS]: (state, { payload }) => ({
      ...state,
      alt_products: payload.products,
    }),
    [SET_ALT_PRODUCT]: (state, { payload }) => ({
      ...state,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, 'product')
        : 'product',
      alt_product: payload,
    }),
    [SET_ALT_PRODUCTS_LOADING]: (state, { payload }) => ({
      ...state,
      alt_productsIsLoading: payload,
    }),
    [SET_PRODUCTS_LOADING]: (state, { payload }) => ({
      ...state,
      productsIsLoading: payload,
    }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_REPORT_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      department: payload.department,
      filter: 'department',
      filterValue: (payload.department && payload.department.Text) || '',
      filterId: (payload.department && payload.department.ID) || '',
      categories: (payload.department && payload.department.Categories) || {},
    }),
    [SET_ALT_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      alt_department: payload.department,
      alt_filter: 'department',
      filterValue: (payload.department && payload.department.Text) || '',
      filterId: (payload.department && payload.department.ID) || '',
      alt_categories:
        (payload.department && payload.department.Categories) || {},
    }),
    [SET_REPORT_CATEGORY]: (state, { payload }) => ({
      ...state,
      category: payload.category,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, false)
        : 'category',
      filterValue: (payload.category && payload.category.Text) || '',
      filterId: (payload.category && payload.category.ID) || '',
      subCategories: (payload.category && payload.category.SubCategories) || {},
    }),
    [SET_ALT_CATEGORY]: (state, { payload }) => ({
      ...state,
      alt_category: payload.category,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, false)
        : 'category',
      filterValue: (payload.category && payload.category.Text) || '',
      filterId: (payload.category && payload.category.ID) || '',
      alt_subCategories:
        (payload.category && payload.category.SubCategories) || {},
    }),
    [SET_SUBCATEGORY]: (state, { payload }) => ({
      ...state,
      subCategory: payload.subCategory,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, false)
        : 'subcategory',
      filterValue: (payload.subCategory && payload.subCategory.Text) || '',
      filterId: (payload.subCategory && payload.subCategory.ID) || '',
      level4s: (payload.subCategory && payload.subCategory.Level4) || {},
    }),
    [SET_ALT_SUBCATEGORY]: (state, { payload }) => ({
      ...state,
      alt_subCategory: payload.subCategory,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, false)
        : 'subcategory',
      filterValue: (payload.subCategory && payload.subCategory.Text) || '',
      filterId: (payload.subCategory && payload.subCategory.ID) || '',
      alt_level4s: (payload.subCategory && payload.subCategory.Level4) || {},
    }),
    [SET_LEVEL4]: (state, { payload }) => ({
      ...state,
      level4: payload.level4,
      filter: isEmpty(payload.level4)
        ? getLastSelectedFilterBNB(state, false, false)
        : 'level4',
      filterValue: (payload.level4 && payload.level4.Text) || '',
      filterId: (payload.level4 && payload.level4.ID) || '',
      level5s: (payload.level4 && payload.level4.Level5) || {},
    }),
    [SET_ALT_LEVEL4]: (state, { payload }) => ({
      ...state,
      alt_level4: payload.level4,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, false)
        : 'level4',
      filterValue: (payload.level4 && payload.level4.Text) || '',
      filterId: (payload.level4 && payload.level4.ID) || '',
      alt_level5s: (payload.level4 && payload.level4.Level5) || {},
    }),
    [SET_LEVEL5]: (state, { payload }) => ({
      ...state,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, false)
        : 'level5',
      filterValue: (payload.level5 && payload.level5.Text) || '',
      filterId: (payload.level5 && payload.level5.ID) || '',
      level5: payload.level5,
    }),
    [SET_ALT_LEVEL5]: (state, { payload }) => ({
      ...state,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, false)
        : 'level5',
      filterValue: (payload.level5 && payload.level5.Text) || '',
      filterId: (payload.level5 && payload.level5.ID) || '',
      alt_level5: payload.level5,
    }),
    [SET_BNB_BRANDS]: (state, { payload }) => ({
      ...state,
      bnbBrands: payload,
    }),
    [SET_BNB_BRAND]: (state, { payload }) => ({
      ...state,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, 'brand')
        : 'brand',
      selectedBnBBrand: payload,
    }),
    [SET_ALT_CATEGORIES]: (state, { payload }) => ({
      ...state,
      bnbCategories: payload,
    }),
    // [SET_ALT_CATEGORY]: (state, { payload }) => ({
    //   ...state,
    //   selectedBnBCategory: payload,
    // }),
    [SET_BRAND]: (state, { payload }) => ({
      ...state,
      filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, false, 'brand')
        : 'brand',
      brand: payload,
    }),
    [SET_BRANDS]: (state, { payload }) => ({
      ...state,
      brands: payload,
    }),
    [SET_ALT_BRAND]: (state, { payload }) => ({
      ...state,
      alt_filter: isEmpty(payload)
        ? getLastSelectedFilterBNB(state, true, 'brand')
        : 'brand',
      alt_brand: payload,
    }),
    [SET_ALT_BRANDS]: (state, { payload }) => ({
      ...state,
      alt_brands: payload,
    }),
    [SET_COMPANY_TYPE]: (state, { payload }) => ({
      ...state,
      selectedCompanyType: payload,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_TIMEFRAME]: (state, { payload }) => ({
      ...state,
      selectedTimeFrame: payload,
    }),
    [FETCH_BNB_DEFAULTS]: (state, { payload }) => ({
      ...state,
      pmp: payload.pmp,
      bnbCategoryHierarchy: {
        title: state.bnbCategoryHierarchy.title,
        options: payload.categories,
        departments: payload.departments,
      },
      categories: payload.categories,
      departments: payload.departments,
      alt_categories: payload.categories,
      alt_departments: payload.departments,
      bnbMembers: { title: state.bnbMembers.title, options: payload.members },
      depots: payload.depots,
      bnbBusinessTypes: {
        title: state.bnbBusinessTypes.title,
        options: payload.businessTypes,
      },
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload,
    }),
  },
  initialState,
);

export const selectors = {};
