import React from 'react';
import PropTypes from 'prop-types';
import { formatToCurrency } from '../../helpers/helpers';
import { getUserSystem } from '../../helpers/util';

class LineGraphDualAxis extends React.Component {
  componentWillMount() {
    window._ = require('underscore');
    window.chartJS = require('chart.js');
    this.setState({
      getChartJS: Promise.resolve(),
    });
  }

  /* eslint no-new:0, new-cap:0 */
  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      return;
    }
    if ((getUserSystem() === 'confex' || getUserSystem() === 'smartviewRetail') 
    && typeof nextProps.data.datasets !== 'undefined') {
      nextProps.data.datasets.splice(1, 1);
    }

    if (nextProps.page == "catPen" ||
      (!_.isEqual(this.props.data, nextProps.data) &&
      nextProps.data.labels !== undefined)
    ) {
      this.state.getChartJS.then(() => {

        var lineChartData = {
          labels: nextProps.data.labels,
          datasets: [],
        };
        _.each(nextProps.data.datasets, dataset => {
          lineChartData.datasets.push({
            label: dataset.label,
            fill: dataset.fill,
            borderColor: dataset.borderColor,
            borderDash: dataset.borderDash ? dataset.borderDash : [],
            backgroundColor: dataset.backgroundColor,
            yAxisID: dataset.yAxisID,
            data: dataset.data,
          });
        });
        if (document.getElementById('canvas') == null) {
          return;
        }
        var ctx = document.getElementById('canvas').getContext('2d');
        if (window.myLine) {
          window.myLine.destroy();
        }
        const isCurrency = this.props.isCurrency;

        const totalDuration = 100000;
        const delayBetweenPoints = totalDuration / lineChartData.length;
        const previousY = ctx =>
          ctx.index === 0
            ? ctx.chart.scales.y.getPixelForValue(100)
            : ctx.chart
                .getDatasetMeta(ctx.datasetIndex)
                .data[ctx.index - 1].getProps(['y'], true).y;
        const animation = {
          x: {
            type: 'number',
            easing: 'linear',
            duration: delayBetweenPoints,
            from: NaN, // the point is initially skipped
            delay(ctx) {
              if (ctx.type !== 'data' || ctx.xStarted) {
                return 0;
              }
              ctx.xStarted = true;
              return ctx.index * delayBetweenPoints;
            },
          },
          y: {
            type: 'number',
            easing: 'linear',
            duration: delayBetweenPoints,
            from: previousY,
            delay(ctx) {
              if (ctx.type !== 'data' || ctx.yStarted) {
                return 0;
              }
              ctx.yStarted = true;
              return ctx.index * delayBetweenPoints;
            },
          },
        };
        window.myLine = Chart.Line(ctx, {
          data: lineChartData,
          options: {
            animation,
            responsive: true,
            hoverMode: 'index',
            stacked: false,
            title: {
              display: false,
              text: '',
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: this.props.xAxisLabel || 'Week Number',
                  },
                },
              ],
              yAxes: [
                {
                  type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                  scaleLabel: {
                    display: true,
                    labelString: this.props.yAxisLabel || 'Volume',
                  },
                },
                {
                  type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                  display: this.props.isSingleYAxes || getUserSystem() === 'confex' ? false : true,
                  position: 'right',
                  id: 'y-axis-2',
                  // grid line settings
                  gridLines: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.props.yAxisLabel1 || 'Customer Count',
                  },
                },
              ],
            },

            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                  return `${
                    isCurrency
                      ? `${formatToCurrency(tooltipItem.value)}`
                      : tooltipItem.value
                  }`;
                },
              },
            },
          },
        });
      });
    } else if (nextProps.data.labels === undefined) {
      if (window.myLine) {
        window.myLine.destroy();
      }
    }
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <div
        style={{ width: '100%' }}
        className={this.props.isLoading ? 'd-none' : ''}>
        <canvas id="canvas"></canvas>
      </div>
    );
  }
}

LineGraphDualAxis.defaultProps = {
  data: undefined,
  XFormat: false,
  YFormat: false,
  YFormat1: false,
  page: undefined,
};

/* eslint react/no-unused-prop-types:0 */
LineGraphDualAxis.propTypes = {
  data: PropTypes.object,
  YFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  YFormat1: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  XFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  page: PropTypes.string,
};

export default LineGraphDualAxis;
