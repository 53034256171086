import { handleActions, createAction } from 'redux-actions';
import { request, requestGet, customRequest } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import { setDisableCategoryStructure } from './app';
import store from '../store';
import theme from '../../../config/Themes';

const FETCH_DATA = 'sku/FETCH_DATA';
const FETCH_SKU_PERFORMANCE_DATA_GRAPH = 'sku/FETCH_SKU_PERFORMANCE_DATA_GRAPH';
const FETCH_SKU_PERFORMANCE_DATA_TABLE = 'sku/FETCH_SKU_PERFORMANCE_DATA_TABLE';
const SET_REPORT_DEPARTMENT = 'sku/SET_REPORT_DEPARTMENT';
const SET_REPORT_CATEGORY = 'sku/SET_CATEGORY';
const SET_SUBCATEGORY = 'sku/SET_SUBCATEGORY';
const SET_LEVEL4 = 'sku/SET_LEVEL4';
const SET_LEVEL5 = 'sku/SET_LEVEL5';
const SET_TITLE = 'sku/SET_TITLE';
const SET_PMP = 'product/SET_PMP';
const SET_CORE_RANGE = 'product/SET_CORE_RANGE';
const SET_MEMBER_TYPE = 'product/SET_MEMBER_TYPE';
const SET_RETAIL_TYPE = 'product/SET_RETAIL_TYPE';
const SET_REGION = 'product/SET_REGION';
const SET_FASCIA = 'product/SET_FASCIA';
const SET_ACCOUNT_NAME = 'sku/SET_ACCOUNT_NAME';
const SET_SELECTED_PRODUCTS = 'sku/SET_SELECTED_PRODUCTS';
const SET_SELECTED_MEMBERS = 'sku/SET_SELECTED_MEMBERS';
const SET_PRODUCTS = 'sku/SET_PRODUCTS';
const SET_LOADING = 'sku/SET_LOADING';
const SET_EXPORTING = 'sku/SET_EXPORTING';
const SET_HIDE_PRODUCTS = 'sku/SET_HIDE_PRODUCTS';
const EXPORT_TO_PDF_SUCCESS = 'sku/EXPORT_TO_PDF_SUCCESS';
const RESET = 'sku/RESET';
const SET_DEPOT = 'sku/SET_DEPOT';
const SET_DEPOTS = 'sku/SET_DEPOTS';
const SET_SELECTED_GROUP = 'sku/SET_SELECTED_GROUP';
const SET_MEMBERS = 'sku/SET_MEMBERS';
const SET_DATA_TYPE = 'sku/SET_DATA_TYPE';
const SET_PURCHASE = 'product/SET_PURCHASE';

const initialState = {
  departments: [],
  department: {},
  categories: [],
  category: {},
  subCategories: [],
  subCategory: {},
  products: [],
  selectedProducts: [],
  members: [],
  selectedMembers: [],
  level4s: [],
  level4: {},
  level5s: [],
  level5: {},
  accountName: [],
  accountNames: [],
  skuPerformanceDataGraph: [],
  skuPerformanceDataTable: [],
  hideProducts: false,
  isExporting: false,
  isExported: false,
  pdfURL: '',
  isLoading: false,
  depots: [],
  selectedDepots: [],
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  dataType: { ID: 0, Text: 'Wholesale' },
  dataTypes: [
    { ID: 0, Text: 'Wholesale' },
    { ID: 1, Text: 'Retail' },
  ],
  selectedGroup: [],
  groups: [],
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  purchase: { ID: 'out', Text: 'Out' },
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchSkuPerformanceDataGraphAction = createAction(
  FETCH_SKU_PERFORMANCE_DATA_GRAPH,
);
const fetchSkuPerformanceDataTableAction = createAction(
  FETCH_SKU_PERFORMANCE_DATA_TABLE,
);
const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setPmpAction = createAction(SET_PMP);
const setRegionAction = createAction(SET_REGION);
const setFasciaAction = createAction(SET_FASCIA);
const setCoreRangeAction = createAction(SET_CORE_RANGE);
const setMemberTypeAction = createAction(SET_MEMBER_TYPE);
const setRetailTypeAction = createAction(SET_RETAIL_TYPE);
const setSelectedProductsAction = createAction(SET_SELECTED_PRODUCTS);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setLoadingAction = createAction(SET_LOADING);
const setProductsAction = createAction(SET_PRODUCTS);
const setHideProductsAction = createAction(SET_HIDE_PRODUCTS);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const resetAction = createAction(RESET);
const setDepotAction = createAction(SET_DEPOT);
const setDepotsAction = createAction(SET_DEPOTS);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const setDataTypeAction = createAction(SET_DATA_TYPE);
const setPurchaseAction = createAction(SET_PURCHASE);

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'sku-tracker-report-' + new Date().toISOString(),
      layout: 'landscape',
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  loadTable(store, dispatch);
};

export const setAccountName = val => dispatch => {
  dispatch(setAccountNameAction(val));
};

export const setPmp = val => dispatch => {
  if (val) {
    dispatch(setPmpAction(val));
    loadProducts(store, dispatch);
  } else {
    dispatch(setPmpAction(''));
  }
};

export const setRegion = val => dispatch => {
  dispatch(setRegionAction(val));
};

export const setFascia = val => dispatch => {
  dispatch(setFasciaAction(val));
};

export const setCoreRange = val => dispatch => {
  dispatch(setCoreRangeAction(val));
};

export const setMemberType = val => dispatch => {
  dispatch(setMemberTypeAction(val));
};

export const setRetailType = val => dispatch => {
  dispatch(setRetailTypeAction(val));
};

export const fetchCategories = () => async dispatch => {
  const userObj = JSON.parse(localStorage.user_data);
  url = '/route.php?c=customer/getRetailers';
  const accountNames = await requestGet({ url: url });

  let regions = [];
  let fascias = [];

  if (getUserSystem() == 'smartviewRetail') {
    let urlRegion = '/route.php?c=region/getRegions';
    let regionArray = await requestGet({ url: urlRegion });
    regionArray.forEach(region => {
      regions.push({"value": region})
    });
    

    let urlFascias = '/route.php?c=fascia/getFascias';
    let fasciaArray = await requestGet({ url: urlFascias });
    fasciaArray.forEach(fascia => {
      fascias.push({"value": fascia})
    });
  }

  let url = '/route.php';
  if (userObj.departments.length === 0 && userObj.categories.length === 0) {
    url += '?c=category/getHierarchy';
  } else {
    url += '?c=category/getUserHierarchy';
  }

  const state = store.getState().skuTrackerReport;
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    if (
      (result.Departments && result.Departments.length > 0) ||
      (result.Categories && result.Categories.length > 1)
    ) {
      dispatch(setHideProductsAction(true));
    } else {
      let url1 = '/route.php?c=product/getAll';
      const user = store.getState().user.authUser;
      if (user.showCompetitor) {
        url1 += `&show_competitor=0&supplier=${encodeURIComponent(
          user.supplier.Text,
        )}`;
      }
      if (state.selectedMembers.length === 1) {
        url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }

      request({
        url: url1,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      }).then(response => {
        each(response, prod => {
          prod.WVProdDesc = encodeText(prod.WVProdDesc);
        });
        dispatch(setProductsAction(response));
      });
    }
    let url1 = '/route.php?c=wholesaler/getAll';

    request({
      url: url1,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(members => {
      each(members, mem => {
        mem.ActualName = encodeText(mem.ActualName);
      });

      if (members.length === 1) {
        dispatch(setDisableCategoryStructure(false));
      }

      url = '/route.php?c=depot/getDepots';

      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      }).then(depots => {
        each(depots, depot => {
          depot.DepotName = encodeText(depot.DepotName);
        });

        if (
          members.length === 1
        ) {
          dispatch(
            setSelectedMembersAction([
              { Text: members[0].ActualName, ID: members[0].WholesalerNo },
            ]),
          );
          members = [members[0]];
        }
        dispatch(setLoadingAction(false));

        let orderedGroups = [];

        requestGet({ url: '/route.php?c=groupSupplier/getAll' }).then(
          groups => {
            let groupsArr =
              (groups && groups.map((group, i) => ({ Text: group, ID: i }))) ||
              [];
            let orderedGroups = orderBy(groupsArr, ['Text']);

            dispatch(
              fetchDataAction({
                departments: result.Departments
                  ? orderBy(result.Departments, ['Text'])
                  : [],
                categories: result.Categories
                  ? orderBy(result.Categories, ['Text'])
                  : [],
                members: members,
                regions: regions,
                fascias: fascias,
                accountNames: accountNames,
                depots: depots,
                groups: orderedGroups,
                pmp: '0',
              }),
            );
            if (result.Departments && result.Departments.length === 1) {
              let dept = result.Departments[0];
              dispatch(setReportDepartmentAction(dept));
            }

            if (result.Categories && result.Categories.length === 1) {
              dispatch(setReportCategoryAction(result.Categories[0]));
              if (result.Categories[0].SubCategories.length === 1) {
                dispatch(
                  setSubCategoryAction(result.Categories[0].SubCategories[0]),
                );
              }
            }
          },
        );
      });
    });
  });
};

export const setDepartment = val => dispatch => {
  if (parseInt(val) > 0) {
    let department = _.filter(
      store.getState().skuTrackerReport.departments,
      dpt => {
        if (dpt.ID === parseInt(val)) {
          return dpt;
        }
      },
    );
    let dept = department[0];
    dispatch(setReportDepartmentAction(dept));
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [] }));
    dispatch(setSubCategoryAction({ Level4: [] }));
    dispatch(setReportCategoryAction({ SubCategories: [] }));
    dispatch(setReportDepartmentAction({ Categories: [] }));
  } else {
    dispatch(setLoadingAction(true));
    let department = _.filter(
      store.getState().skuTrackerReport.departments,
      dpt => {
        if (dpt.Text === val) {
          return dpt;
        }
      },
    );
    let dpt = department[0];
    dispatch(setReportDepartmentAction(dpt));
  }
};

export const setCategory = val => dispatch => {
  if (parseInt(val) > 0) {
    let category = _.filter(
      store.getState().skuTrackerReport.categories,
      cat => {
        if (cat.ID === parseInt(val)) {
          return cat;
        }
      },
    );
    let catt = category[0];
    dispatch(setReportCategoryAction(catt));
    loadProducts(store, dispatch);
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [] }));
    dispatch(setSubCategoryAction({ Level4: [] }));
    dispatch(setReportCategoryAction({ SubCategories: [] }));
  } else {
    let category = _.filter(
      store.getState().skuTrackerReport.categories,
      cat => {
        if (cat.Text === val) {
          return cat;
        }
      },
    );
    let catt = category[0];
    dispatch(setReportCategoryAction(catt));
    loadProducts(store, dispatch);
  }
};

export const setSubCategory = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.ID === val) {
          dispatch(setSubCategoryAction(subcat));
          loadProducts(store, dispatch);
        }
      });
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel4Action({ Level5: [] }));
    dispatch(setSubCategoryAction({ Level4: [] }));
  } else {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.Text === val) {
          dispatch(setSubCategoryAction(subcat));
        }
      });
    });
  }

  let inititalState = store.getState().skuTrackerReport;
  if (inititalState.level4s.length === 1) {
    dispatch(setLevel4Action(inititalState.level4s[0]));
  }
};

export const setLevel4 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      if (cat.ID === store.getState().skuTrackerReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().skuTrackerReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === val) {
                dispatch(setLevel4Action(lvl4));
                loadProducts(store, dispatch);
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel4Action({ Level5: [] }));
  } else {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      if (cat.ID === store.getState().skuTrackerReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().skuTrackerReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.Text === val) {
                dispatch(setLevel4Action(lvl4));
              }
            });
          }
        });
      }
    });
  }
};

export const setLevel5 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      if (cat.ID === store.getState().skuTrackerReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().skuTrackerReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().skuTrackerReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.ID === val) {
                    dispatch(setLevel5Action(lvl5));
                    loadProducts(store, dispatch);
                  }
                });
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().skuTrackerReport.categories, cat => {
      if (cat.ID === store.getState().skuTrackerReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().skuTrackerReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().skuTrackerReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.Text === val) {
                    dispatch(setLevel5Action(lvl5));
                  }
                });
              }
            });
          }
        });
      }
    });
  }
};

export const setProduct = val => dispatch => {
  if (
    store.getState().skuTrackerReport.selectedProducts.length < 3 ||
    val.length < 3
  ) {
    dispatch(setSelectedProductsAction(val));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));

  const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v.Text).join(
    ',',
  ))}`;

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};

export const setDepot = val => dispatch => {
  dispatch(setDepotAction({ selectedDepots: val }));
};

const parseTableData = data => {
  const raw = data;
  const headers = [
    { text: '', colspan: 1 },
    { text: 'WeekNo', colspan: '1' },
  ];
  const rows = [];

  raw.forEach((item, i) => {
    const rowSingle = [];
    rowSingle.push({ text: item.ProductDesc, colspan: 1 });
    Object.keys(item.Values).forEach(key => {
      let vals = _.filter(headers, header => {
        if (header.text === item.Values[key].YearNoWeekNo) {
          return header;
        }
      });
      if (vals.length === 0) {
        headers.push({ text: item.Values[key].YearNoWeekNo, colspan: 1 });
      }
      rowSingle.push({
        text: item.Values[key].Value,
        volume: parseFloat(item.Values[key].Volume),
        customerCount: parseFloat(item.Values[key].CustomerCount),
        rateOfSales: item.Values[key].RateOfSales,
        header: item.Values[key].YearNoWeekNo,
        colspan: 1,
      });
    });

    rows.push(rowSingle);
  });

  return {
    headers,
    rows,
  };
};

const loadProducts = (store, dispatch) => {
  let state = store.getState().skuTrackerReport;
  let url =
    state.dataType.Text === 'Retail'
      ? '/route.php?c=product/getAllRetail'
      : '/route.php?c=product/getAll';

  const user = store.getState().user.authUser;
  if (user.showCompetitor) {
    url += `&show_competitor=0&supplier=${encodeURIComponent(
      user.supplier.Text,
    )}`;
  }
  if (state.department.Text !== undefined) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }
  if (state.category.Text !== undefined) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }
  if (state.subCategory.Text !== undefined) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }
  if (state.level4.Text !== undefined) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }
  if (state.level5.Text !== undefined) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }
  if (state.pmp) {
    url += '&pmp=' + state.pmp;
  }
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  /* url +=
    state.accountName.length > 0
      ? '&storeid=' +
        encodeURIComponent(state.accountName)
      : ''; */

  if (state.dataType && state.dataType.Text) {
    url += `&dataType=${encodeURIComponent(state.dataType.Text)}`;
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(setProductsAction(response));
  });
};

const loadTable = (store, dispatch) => {
  let state = store.getState().skuTrackerReport;

  let productIds = [];

  _.each(state.selectedProducts, product => {
    productIds.push(product.WVProdCode);
  });

  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });

  let url = '/route.php?c=skuTracker/getSkuTrackerReport';

  const user = store.getState().user.authUser;
  if (user.showCompetitor) {
    url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
  }
  if (productIds.length > 0) {
    switch (productIds.length) {
      case 1:
        url += '&prod1=' + productIds[0];
        break;
      case 2:
        url += '&prod1=' + productIds[0] + '&prod2=' + productIds[1];
        break;
      case 3:
        url +=
          '&prod1=' +
          productIds[0] +
          '&prod2=' +
          productIds[1] +
          '&prod3=' +
          productIds[2];
        break;
    }
  }

  if (state.department.ID) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }
  if (state.category.ID) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }
  if (state.subCategory.ID) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }
  if (state.level4.ID) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }
  if (state.level5.ID) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }

  if (memberIds.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }

  if (state.selectedDepots?.length > 0) {
    url +=
      '&depot=' +
      state.selectedDepots
        .map(depot => encodeURIComponent(depot.DepotName))
        .join(',');
  }
  const { accountName } = store.getState().filters;
  url +=
    accountName.length > 0 
      ? '&storeid=' + accountName
        .map(val => encodeURIComponent(val.RegNo))
        .join(',') 
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  if (state.pmp.length > 0) {
    url += '&pmp=' + state.pmp;
  }

  url +=
    state.region && state.region.value
      ? '&region=' + encodeURIComponent(state.region.value)
      : '';

  url +=
    state.fascia && state.fascia.value
      ? '&fascia=' + encodeURIComponent(state.fascia.value)
      : '';

  url +=
    state.coreRange && state.coreRange.ID
      ? '&core_range=' + encodeURIComponent(state.coreRange.ID)
      : '';
  
  url +=
    state.memberType && state.memberType.ID
      ? '&member_type=' + encodeURIComponent(state.memberType.ID)
      : '';
    
  url +=
    state.retailType && state.retailType.ID
      ? '&retail_type=' + encodeURIComponent(state.retailType.ID)
      : '';

  if (state.dataType && state.dataType.Text) {
    url += '&dataType=' + encodeURIComponent(state.dataType.Text);
  }

  if (productIds.length > 0) {
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(response => {
      let mockData = parseTableData(response);

      let data = { headers: mockData.headers, rows: [] };
      let values = [];
      _.each(mockData.headers, header => {
        if (header.text.length > 0) {
          values = [];
          _.each(mockData.rows, (rowVal, k) => {
            _.each(rowVal, row => {
              row.rowId = k;
              values.push(row);
            });
          });
        }
      });

      _.each(values, value => {
        if (value.header === undefined) {
          data.rows.push([value]);
        }
      });
      let finalData = { headers: mockData.headers, rows: [] };
      _.each(data.rows, dtRows => {
        _.each(dtRows, (dt, k) => {
          _.each(mockData.headers, header => {
            if (header.text.length > 0) {
              let vals = _.filter(
                values,
                val => val.rowId === dt.rowId && val.header === header.text,
              );
              if (vals.length === 0) {
                dtRows.push({ text: '0', header: header.text, colspan: 1 });
              } else {
                dtRows.push(vals[0]);
              }
            }
          });
        });
      });
      dispatch(fetchSkuPerformanceDataTableAction(data));

      let graphData = { labels: [], datasets: [] };
      _.each(data.headers, dt => {
        if (dt.text.length > 0 && dt.text !== 'WeekNo') {
          graphData.labels.push(dt.text);
        }
      });
      let unitasColors = [
        { line1: '#f72b2c', line2: '#4e0091', line3: '#930606' },
        { line1: '#4a2683', line2: '#a988dd', line3: '#e2d7f4' },
        { line1: '#8c8c8c', line2: '#cccccc', line3: '#595959' },
      ];
      let colors = [
        {
          line1: theme.lineGraph1A,
          line2: theme.lineGraph2A,
          line3: theme.lineGraph3A,
        },
        {
          line1: theme.lineGraph1B,
          line2: theme.lineGraph2B,
          line3: theme.lineGraph3B,
        },
        {
          line1: theme.lineGraph1C,
          line2: theme.lineGraph2C,
          line3: theme.lineGraph3C,
        },
      ];
      _.each(data.rows, (dtRows, i) => {
        let dataRow = {
          label: dtRows[0].text + ' - Volume',
          borderColor: colors[i].line1,
          backgroundColor: colors[i].line1,
          fill: false,
          data: [],
          yAxisID: 'y-axis-1',
        };
        let dataRow1 = {
          label: dtRows[0].text + ' - CustomerCount',
          borderColor: colors[i].line2,
          backgroundColor: colors[i].line2,
          fill: false,
          data: [],
          yAxisID: 'y-axis-2',
        };
        _.each(dtRows, (row, k) => {
          if (k > 0) {
            if (row.volume || row.volume === 0) {
              dataRow.data.push(row.volume);
            }
            if (row.customerCount || row.customerCount === 0) {
              dataRow1.data.push(row.customerCount);
            }
          }
        });
        graphData.datasets.push(dataRow);
        graphData.datasets.push(dataRow1);
      });
      dispatch(fetchSkuPerformanceDataGraphAction(graphData));
      dispatch(setLoadingAction(false));
    });
  } else {
    dispatch(fetchSkuPerformanceDataTableAction([]));
    dispatch(fetchSkuPerformanceDataGraphAction([]));
    dispatch(setLoadingAction(false));
  }
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const setDataType = val => dispatch => {
  dispatch(setDataTypeAction(val));

  if (getUserSystem !== 'demo' && val.Text === 'Retail') {
    loadProducts(store, dispatch);
  }
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      pmp: payload.pmp,
      departments: payload.departments,
      categories: payload.categories,
      members: payload.members,
      regions: payload.regions,
      fascias: payload.fascias,
      accountNames: payload.accountNames,
      depots: payload.depots,
      groups: payload.groups,
    }),
    [SET_REPORT_DEPARTMENT]: (state, { payload }) => {
      let allCategories = payload.Categories;

      let orderedCategories = uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );

      return {
        ...state,
        department: payload,
        categories: orderedCategories,
      };
    },
    [SET_REPORT_CATEGORY]: (state, { payload }) => {
      let allSubCategories = payload.SubCategories;

      let orderedSubCategories = uniqBy(
        orderBy(
          filter(allSubCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );

      return {
        ...state,
        category: payload,
        subCategories: orderedSubCategories,
      };
    },
    [SET_SUBCATEGORY]: (state, { payload }) => {
      let allLvl4s = payload.Level4;

      let orderedLevel4 = uniqBy(
        orderBy(
          filter(allLvl4s, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      return {
        ...state,
        subCategory: payload,
        level4s: orderedLevel4,
      };
    },
    [SET_LEVEL4]: (state, { payload }) => {
      let allLvl5s = payload.Level5;

      let orderedLevel5 = uniqBy(
        orderBy(
          filter(allLvl5s, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      return {
        ...state,
        level4: payload,
        level5s: orderedLevel5,
      };
    },
    [SET_LEVEL5]: (state, { payload }) => ({ ...state, level5: payload }),
    [SET_SELECTED_PRODUCTS]: (state, { payload }) => ({
      ...state,
      selectedProducts: payload,
    }),
    [SET_PRODUCTS]: (state, { payload }) => ({ ...state, products: payload }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_TITLE]: (state, { payload }) => ({
      ...state,
      title: payload.title,
      departments: [],
      department: {},
      categories: [],
      category: {},
      subCategories: [],
      subCategory: {},
      level4s: [],
      level4: {},
      level5s: [],
      level5: {},
      region: '',
      regions: [],
      fascia: '',
      fascias: [],
      coreRanges: [
        { ID: 'yes', Text: 'Yes' },
        { ID: 'no', Text: 'No' },
      ],
      coreRange: '',
      memberTypes: [
        { ID: 'retail', Text: 'Retail' },
        { ID: 'foodservice', Text: 'Foodservice' },
      ],
      memberType: '',
      retailTypes: [
        { ID: 'independent', Text: 'Independent' },
        { ID: 'company_owned', Text: 'Company Owned' },
      ],
      retailType: '',
      members: [],
      products: [],
      selectedProducts: [],
      skuPerformanceDataGraph: [],
      skuPerformanceDataTable: [],
      pmp: '',
    }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_REGION]: (state, { payload }) => ({ ...state, region: payload }),
    [SET_FASCIA]: (state, { payload }) => ({ ...state, fascia: payload }),
    [SET_CORE_RANGE]: (state, { payload }) => ({ ...state, coreRange: payload }),
    [SET_MEMBER_TYPE]: (state, { payload }) => ({ ...state, memberType: payload }),
    [SET_RETAIL_TYPE]: (state, { payload }) => ({ ...state, retailType: payload }),
    [SET_DATA_TYPE]: (state, { payload }) => ({
      ...state,
      dataType: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [FETCH_SKU_PERFORMANCE_DATA_GRAPH]: (state, { payload }) => ({
      ...state,
      skuPerformanceDataGraph: payload,
    }),
    [SET_PRODUCTS]: (state, { payload }) => ({ ...state, products: payload }),
    [FETCH_SKU_PERFORMANCE_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      skuPerformanceDataTable: payload,
    }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      department: {},
      departments: [],
      category: {},
      subCategory: {},
      subCategories: [],
      selectedProducts: [],
      products: [],
      members: [],
      selectedMembers: [],
      level4: {},
      level4s: [],
      level5: {},
      level5s: [],
      accountName: [],
      accountNames: [],
      region: '',
      regions: [],
      fascia: '',
      fascias: [],
      coreRanges: [
        { ID: 'yes', Text: 'Yes' },
        { ID: 'no', Text: 'No' },
      ],
      coreRange: '',
      memberTypes: [
        { ID: 'retail', Text: 'Retail' },
        { ID: 'foodservice', Text: 'Foodservice' },
      ],
      memberType: '',
      retailTypes: [
        { ID: 'independent', Text: 'Independent' },
        { ID: 'company_owned', Text: 'Company Owned' },
      ],
      retailType: '',
      skuPerformanceDataGraph: [],
      skuPerformanceDataTable: [],
      isLoading: false,
      depots: [],
      selectedDepots: [],
      pmp: '',
      selectedGroup: [],
      groups: [],
      purchases: [
        { ID: 'in', Text: 'In' },
        { ID: 'out', Text: 'Out' },
      ],
      purchase: { ID: 'out', Text: 'Out' },
      dataType: { ID: 0, Text: 'Wholesale' },
      dataTypes: [
        { ID: 0, Text: 'Wholesale' },
        { ID: 1, Text: 'Retail' },
      ],
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
