import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import StepCrumbs from './StepCrumbs';
import { fetchAccountNames } from '../../redux/reducers/filters';
import * as _ from 'lodash';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem, hideFor } from '../../../helpers/util';
import {
  setSelectedAccountType,
  setSelectedSuppliers,
  setSelectedDepot,
  setSelectedMember,
  setSelectedDepartments,
  setSelectedCategories,
  setSelectedSubCategories,
  setSelectedLevel4s,
  setSelectedLevel5s,
  setAccountName,
  fetchAllDepots,
  fetchAllMembers,
  fetchAllSuppliers,
  addPermission,
  fetchAllCategories,
} from '../../redux/reducers/user';
import CustomCountSelect from '../../components/CustomCountSelect';

const Account = ({
  data,
  onNext,
  accountTypes,
  depots,
  members,
  suppliers,
  selectedSuppliers,
  departments,
  selectedDepartments,
  categories,
  selectedCategories,
  subCategories,
  selectedSubCategories,
  level4s,
  selectedLevel4s,
  level5s,
  selectedLevel5s,
  transformOptions,
  user,
  onAccountTypeChange,
  accountNames,
  onAccountNameChange,
  onDepotChange,
  onMemberChange,
  onSupplierChange,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
}) => (
  <div className="o-canvas-wrap">
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty`}
    />
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout u-layout--between-base">
          <h2 className="o-canvas__title">Create User</h2>
        </div>
      </div>
      <div className="o-canvas__body u-layout u-layout--spaced t-brand-c">
        <div className="u-1_1">
          <StepCrumbs
            step={{ value: 1, label: 'User' }}
            id={data.id}
            system={getUserSystem()}
          />
          <div className="u-vertical pt-3">
            <div className="o-block u-1_4__inline">
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">Account Type</label>
                  <Select
                    style={{ minWidth: '100px' }}
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`c-select-header c-select-header-row--others`}
                    name="supplier"
                    id="supplier"
                    options={accountTypes.filter(
                      act => act.ID === getUserSystem(),
                    )}
                    value={user.selectedAccountType}
                    onChange={onAccountTypeChange}
                    valueKey="ID"
                    labelKey="Text"
                    placeholder="Select or Search"
                  />
                </div>
              </div>
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">
                    Supplier (Please leave blank for all suppliers)
                  </label>
                  <Select
                    style={{ minWidth: '100px' }}
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`c-select-header c-select-header-row--${
                      getUserSystem() === 'unitas' ? 'unitas' : 'others'
                    }`}
                    name="supplier"
                    id="supplier"
                    multi
                    options={suppliers}
                    value={selectedSuppliers}
                    onChange={onSupplierChange}
                    valueKey="ID"
                    labelKey="Text"
                    placeholder="Select or Search"
                  />
                </div>
              </div>
              {departments.length > 0 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Department (Please leave blank for all departments)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="department"
                      id="department"
                      multi
                      value={selectedDepartments}
                      onChange={onDepartmentChange}
                      options={departments}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {categories.length > 0 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Category (Please leave blank for all categories)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="category"
                      id="category"
                      multi
                      value={selectedCategories}
                      onChange={onCategoryChange}
                      options={categories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {subCategories.length > 0 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Sub Category (Please leave blank for all sub categories)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="subcategory"
                      id="subcategory"
                      multi
                      value={selectedSubCategories}
                      onChange={onSubCategoryChange}
                      options={subCategories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {level4s.length > 1 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Level4 (Please leave blank for all level4s)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="level4"
                      id="level4"
                      multi
                      value={selectedLevel4s}
                      onChange={onLevel4Change}
                      options={level4s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {level5s.length > 1 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Level5 (Please leave blank for all level5s)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="level5"
                      id="level5"
                      multi
                      value={selectedLevel5s}
                      onChange={onLevel5Change}
                      options={level5s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {members.length > 1 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Member (Please leave blank for all members)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="member"
                      id="member"
                      value={user.selectedMember ? user.selectedMember.ID : ''}
                      onChange={onMemberChange}
                      options={members}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                </div>
              )}
              {getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Depot (Please leave blank for all depots)
                    </label>
                    <Select
                      style={{ minWidth: '200px' }}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="depot"
                      id="depot"
                      value={user.selectedDepot ? user.selectedDepot.ID : ''}
                      onChange={onDepotChange}
                      options={depots}
                      valueKey="ID"
                      labelKey="Text"
                      placeholder="Select or Search"
                    />
                  </div>
                </div>
              )}
              
              {getUserSystem() == 'cjlangRetail' && accountNames.length > 0 && (
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub">
                      Customer Names
                    </label>
                    <CustomCountSelect
                      className={`c-select-header c-select-header-row--${
                        getUserSystem() === 'unitas' ? 'unitas' : 'others'
                      }`}
                      name="accountName"
                      id="accountName"
                      onChangeCallback={ev => onAccountNameChange(ev)}
                      isMulti={true}
                      value={user.accountName}
                      options={accountNames}
                      valueKey="RegNo"
                      labelKey="CustomerName"
                      type={"Customer Name"}
                      types={"Customer Names"}
                      placeholder={"Customer Name"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={onNext}
            className={`btn alchemy-btn--${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }  u-right`}>
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.user.isLoading,
    accountTypes: state.user.accountTypes,
    accountNames: state.filters.accountNames,
    user: state.user.user,
    depots: state.user.depots,
    members: state.user.members,
    departments: state.user.departments,
    selectedDepartments: state.user.selectedDepartments,
    categories: state.user.categories,
    selectedCategories: state.user.selectedCategories,
    subCategories: state.user.subCategories,
    selectedSubCategories: state.user.selectedSubCategories,
    level4s: state.user.level4s,
    selectedLevel4s: state.user.selectedLevel4s,
    level5s: state.user.level5s,
    selectedLevel5s: state.user.selectedLevel5s,
    suppliers: state.user.suppliers,
    selectedSuppliers: state.user.selectedSuppliers,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    setSelectedSuppliers,
    setSelectedDepot,
    setSelectedMember,
    setSelectedAccountType,
    setSelectedDepartments,
    setSelectedCategories,
    setSelectedSubCategories,
    setSelectedLevel4s,
    setSelectedLevel5s,
    fetchAllSuppliers,
    fetchAllDepots,
    fetchAccountNames,
    setAccountName,
    fetchAllMembers,
    addPermission,
    fetchAllCategories,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchAllSuppliers();
      this.props.fetchAccountNames();
      this.props.setSelectedAccountType({ ID: getUserSystem() });
      this.props.fetchAllCategories();
      if (getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') {
        this.props.fetchAllDepots();
      } else if (hideFor('cjLang')) {
        this.props.fetchAllMembers();
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.dismiss();
        toast.info('Loading, please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (
        prevProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
      }
    },
  }),
  withState('data', 'handleData', props => ({ id: props.match.params.id })),
  withHandlers({
    onDepartmentChange: ({
      data,
      handleData,
      setSelectedDepartments,
    }) => selectedOption => {
      if (selectedOption === null) {
        setSelectedDepartments([]);
      } else {
        setSelectedDepartments(selectedOption);
      }
      handleData({ showModal: false });
    },
    onCategoryChange: ({
      data,
      handleData,
      setSelectedCategories,
    }) => selectedOption => {
      if (selectedOption === null) {
        setSelectedCategories([]);
      } else {
        setSelectedCategories(selectedOption);
      }
      handleData({ showModal: false });
    },
    onSubCategoryChange: ({
      data,
      handleData,
      setSelectedSubCategories,
    }) => selectedOption => {
      if (selectedOption === null) {
        setSelectedSubCategories([]);
      } else {
        setSelectedSubCategories(selectedOption);
      }
      handleData({ showModal: false });
    },
    onLevel5Change: ({
      data,
      handleData,
      setSelectedLevel5s,
    }) => selectedOption => {
      if (selectedOption === null) {
        setSelectedLevel5s([]);
      } else {
        setSelectedLevel5s(selectedOption);
      }
      handleData({ showModal: false });
    },
    onLevel4Change: ({
      data,
      handleData,
      setSelectedLevel4s,
    }) => selectedOption => {
      if (selectedOption === null) {
        setSelectedLevel4s([]);
      } else {
        setSelectedLevel4s(selectedOption);
      }
      handleData({ showModal: false });
    },
    onAccountNameChange:
      ({ data, handleData, setAccountName }) =>
      opt => {
        if (opt) {
          setAccountName(opt);
        } else {
          setAccountName([]);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onNext: props => () => {
      if (getUserSystem() === 'todays') {
        props.addPermission('13', 'other');
        props.addPermission('14', 'other');
        props.addPermission('15', 'other');
        props.addPermission('16', 'other');
        props.addPermission('18', 'other');
        props.addPermission('19', 'other');
        props.addPermission('20', 'other');
        props.addPermission('21', 'other');
        props.addPermission('63', 'other');
        props.addPermission('64', 'other');
        props.addPermission('65', 'other');
        props.addPermission('184', 'system');
        if (props.match.params.id) {
          props.history.push(
            `${process.env.APP_ROOT}create_user/review/${props.match.params.id}`,
          );
        } else {
          props.history.push(`${process.env.APP_ROOT}create_user/review`);
        }
      } else {
        if (props.match.params.id) {
          props.history.push(
            `${process.env.APP_ROOT}create_user/permissions/${props.match.params.id}`,
          );
        } else {
          props.history.push(`${process.env.APP_ROOT}create_user/permissions`);
        }
      }
    },
    onAccountTypeChange: ({ setSelectedAccountType }) => option => {
      setSelectedAccountType(option);
    },
    onDepotChange: ({ setSelectedDepot }) => option => {
      setSelectedDepot(option);
    },
    onMemberChange: ({ setSelectedMember }) => option => {
      setSelectedMember(option);
    },
    onSupplierChange: ({ setSelectedSuppliers }) => option => {
      setSelectedSuppliers(option);
    },
  }),
)(Account);
