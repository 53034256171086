import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-select/dist/react-select.css';
import { ToastContainer, toast } from 'react-toastify';
import {
  downloadQuery
} from '../redux/reducers/downloadCube';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  getUserSystem,
} from '../../helpers/util';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';

const DownloadCube = ({
  isDownloading,
  onDownload
}) => {
  useEffect(() => {
    if (isDownloading) {
      toast.info('Loading Data.Please wait...');
    } else {
      toast.dismiss();
    }
  }, [isDownloading]);

  return (
    <motion.div
      key={'downloadCube'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
      />
      <div className="sub-menu-border bg-white">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between pb-3">
              <div className="sub-menu__left">
                <div className="d-flex justify-content-start">
                  <h2 className="text-uppercase mr-2">Download Cube</h2>
                </div>
              </div>

              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <button
                      onClick={() => {
                        onDownload();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      Download
                      <LaunchIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  isLoading: state.downloadCube.isLoading,
  isDownloading: state.downloadCube.isDownloading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onDownload:() => dispatch(downloadQuery()),
    };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadCube);