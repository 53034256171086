import React from 'react';
import Select from 'react-select';

import { connect } from 'react-redux';

const Commissions = ({
  commission,
  commissions,
  onCommissionChange,
}) => {
  return (
    <>
      <div className="btn-group mr-1 text-uppercase dropdown-align-fix-placeholder">
        <Select
          placeholder="Include/Exclude Commission"
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'off',
          }}
          className={`small-dropdown c-select-header c-select-header-row--others`}
          name="commission"
          id="commission"
          value={commission}
          onChange={onCommissionChange}
          options={commissions}
          valueKey="ID"
          labelKey="Text"
          clearable={false}
          searchable={false}
          isMulti={false}
          style={{
            margin: '0px 5px',
            fontSize: '12px',
            padding: '0px',
          }}
        />
      </div>
      <div className="btn-group text-uppercase ml-1 mr-4">
        <span className="dropdown-align-fix">Commission</span>
      </div>
    </>
  );
};

export default Commissions;
