import React from 'react';
import { TopNavLinks } from '../../../AlchemySettings';
import { selectors } from '../../redux/reducers/app';
import {
  getUserSystem,
  getUserRoles,
  getShowQBPopUp,
} from '../../../helpers/util';

const navState = {
  showQBPopUp: false,
  linkURL: '',
};

class NavMainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = navState;
  }

  onLinkClicked = link => {
    // if (link.label === 'Query' && getShowQBPopUp()) {
    //   this.setState({ ...this.state, linkURL: link.url });
    //   this.props.showQBPopUp(getShowQBPopUp());
    // } else {
    this.props.goToURL(link.url);
    // }
  };

  formatLinks = link => {
    if (getUserSystem() === 'cjlang') {
      if (link.toLowerCase() === 'retail') {
        return 'Retail Analysis';
      } else if (link.toLowerCase() === 'analysis') {
        return 'Wholesale Analysis';
      } else {
        return link;
      }
    } else if (getUserSystem() === 'cjlangRetail') {
      if (link.toLowerCase() === 'retail') {
        return 'Store Analysis';
      } else if (link.toLowerCase() === 'nam dash') {
        return 'My Dashboard';
      } else {
        return link;
      }
    } else {
      return link;
    }
  };

  renderLinks = page => {
    if (page !== 'forgot_password') {
      const analysisMenus = selectors.getMenus().analysis;
      return TopNavLinks.filter(link => {
        if (
          link.label === 'Analysis' &&
          analysisMenus &&
          analysisMenus.length === 0
        ) {
          return false;
        }
        if (
          link.label === 'Nam Dash' &&
          getUserRoles().filter(role => role.Description === 'NAMDASH')
            .length === 0
        ) {
          return false;
        }
        if (
          link.label === 'Analysis' &&
          getUserRoles().filter(role => role.Description === 'ANALYSIS')
            .length === 0
        ) {
          return false;
        }
        if (
          link.label === 'Query' &&
          getUserRoles().filter(role => role.Description === 'QUERYBUILDER')
            .length === 0
        ) {
          return false;
        }
        if (
          link.label === 'Retail' &&
          getUserRoles().filter(role => role.Description === 'RETAIL')
            .length === 0
        ) {
          return false;
        }
        return true;
      }).map((link, i) => {
        return (
          <li
            style={{height: '48px'}}
            key={i}
            className={`text-center list-inline-item pb-2 px-3 pt-2 d-flex align-items-center ${
              link.label.replace(' ', '_').toLowerCase() ===
              page.replace('_new', '')
                ? 'active'
                : ''
            }`}>
            <div
              onClick={() => this.onLinkClicked(link)}
              className={`${
                link.label.replace(' ', '_').toLowerCase() ===
                page.replace('_new', '')
                  ? getUserSystem() === 'unitas'
                    ? 'active--unitas'
                    : 'active--others'
                  : getUserSystem() === 'unitas'
                  ? 'alchemy-lnk--unitas'
                  : 'alchemy-lnk--others'
              }`}>
              <span>{this.formatLinks(link.label)}</span>
            </div>
          </li>
        );
      });
    }
  };

  render() {
    const { page } = this.props;
    return (
      <ul className="list-inline text-uppercase d-flex justify-content-center mb-0 h-100 align-items-center">
        {this.renderLinks(page)}
      </ul>
    );
  }
}

export default NavMainMenu;
