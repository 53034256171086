import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  background: #f8f8f8;
  position: relative;
  z-index: 0;
  min-height: 80vh;
  padding-bottom: 0 !important;
`;

const ReportContainer = ({
  padding,
  layout,
  display,
  children,
  justifyContent,
  ...props
}) => {
  return (
    <StyledContainer
      style={{
        padding: `${padding}rem`,
        flexDirection: `${layout ? layout : 'row'}`,
        display: `${display ? display : 'flex'}`,
        justifyContent: `${justifyContent ? justifyContent : 'initial'}`,
      }}>
      {children}
    </StyledContainer>
  );
};

export default ReportContainer;
