import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getRandomNumber, formatNumber } from '../../helpers/helpers';


class StackedGraph extends Component {
	state = {
		graphID: getRandomNumber('graph_'),
	}


	componentWillMount() {
		require.ensure([], (require)=> {
			require('../../assets/js/flot/jquery.flot');
			require('../../assets/js/flot/jquery.flot.stack.min');
		});
	}

	componentDidMount() {
		this.renderGraph();
	}

	shouldComponentUpdate(nextProps) {
		const oldData = JSON.stringify(this.props.data);
		const newData = JSON.stringify(nextProps.data);
		if (oldData === newData) { return false; }
		return true;
	}

	componentDidUpdate() {
		this.renderGraph();
	}

	renderGraph = ()=>{
		if (!this.props.data.graphCollection) return;

		require.ensure([], (require)=> {
			require('../../assets/js/flot/jquery.flot');
			require('../../assets/js/flot/jquery.flot.stack.min');
			// require('../../assets/js/flot/jquery.flot.resize.min');
			// require('../../assets/js/flot/jquery.flot.time.min');
			// require('../../assets/js/flot/jquery.flot.orderBars');
			const data = this.props.data;
			const barWidth = data.graphCollection[0].barWidth || 0.2;
			$.plot(`#${this.state.graphID}`, data.graphCollection, {
				series: {
					stack: true,
					bars: {
						show: true,
						barWidth,
					},
				},

				xaxes: [{
					mode: 'categories',
					ticks: data.xticks,
					position: 'bottom',
				}],

				yaxes: [{
					tickFormatter: (v)=> { return formatNumber(v, data.yPrefix, data.ySuffix); },
				}],
			});
		});
	}

	render() {
		return (
			<div
				className={`c-objects-wrap c-objects-wrap--plot-graph ${this.props.className}`}
			>
				<div id={this.state.graphID} className="plot-graph plot-graph--stacked" />
				<div className="c-charts-tooltip" />
			</div>
		);
	}
}


StackedGraph.defaultProps = {
	data: {},
	className: '',
};

StackedGraph.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
};

export default StackedGraph;
