import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AlchToolTip extends Component {

	state = {
		hovered: false,
	}

	hoverState = (hovered)=>{
		this.setState({
			hovered,
		});
	}

	render() {
		const tooltipClassname = ['c-text-tooltip'];
		if (this.state.hovered) {
			tooltipClassname.push('c-text-tooltip--open');
		}

		return (
			<div
				className={tooltipClassname.join(' ')}
				onMouseEnter={()=>{ this.hoverState(true); }}
				onMouseLeave={()=>{ this.hoverState(false); }}
			>
				{this.props.label}
				<div className="c-text-tooltip--text">
					{this.props.children}
				</div>
			</div>
		);
	}
}

AlchToolTip.defaultProps = {
	children: '',
};

AlchToolTip.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.any,
};

export default AlchToolTip;

