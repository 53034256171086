import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  SubCategorySupplierTable,
  SubCategoryOpportunityTable,
  SubCategoryDriversTable,
} from '../../components/SupplierEngagement';
import { getUserSystem } from '../../../helpers/util';

const SubCategory = ({ data, ...props }) => {
  return (
    <>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category/supplier`,
          )
        }>
        Performance
      </Button>

      <Button
        className="mr-4"
        onClick={() => {
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category/category_opportunity`,
          );
        }}>
        Category Opportunity
      </Button>
      {getUserSystem().toLowerCase() != 'confex' && (
        <Button
          className="mr-4"
          onClick={() => {
            props.history.push(
              `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category/drivers`,
            );
          }}>
          Driver Of Performance
        </Button>
      )}

      <Switch>
        <Route
          path="/nam_dash/supplier_engagement_report/sub_category/supplier"
          render={pageProps => (
            <SubCategorySupplierTable
              title="Performance Table"
              {...pageProps}
              data={data && data.overview}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/sub_category/category_opportunity"
          render={pageProps => (
            <SubCategoryOpportunityTable
              title="Opportunity Table"
              {...pageProps}
              data={data && data.opportunity}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/sub_category/drivers"
          render={pageProps => (
            <SubCategoryDriversTable
              title="Drivers Table"
              {...pageProps}
              data={data && data.drivers}
            />
          )}
        />
        <Redirect
          to={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category/supplier`}
        />
      </Switch>
    </>
  );
};

export default SubCategory;
