import React from 'react';
import PropTypes from 'prop-types';
import { getUserSystem } from '../../helpers/util';

class TableSimpleSku extends React.Component {
  renderHeader = (header, i) => {
    return (
      <th colSpan={header.colspan} key={i}>
        {this.props.parseEachCell(header.text, -1, i)}
      </th>
    );
  };

  renderRow = (row, i) => {
    return [1, 2, 3, 4].map((col, k) => {
      return (col === 3 || col === 4) && (getUserSystem() === 'confex' || getUserSystem() === 'smartviewRetail') ? (
        ''
      ) : (
        <tr key={k}>
          {row.map((column, j) => {
            return (
              <td
                colSpan={column.colspan}
                key={j}
                style={
                  (col === 1 || col === 2 || col === 3 || col === 4) &&
                  (j === 0 || j === 1)
                    ? { textAlign: 'left' }
                    : {}
                }>
                {col === 1 && j === 0
                  ? this.props.parseEachCell(column.text, i, j)
                  : ''}
                {col === 1 && j === 1
                  ? this.props.parseEachCell('Value', i, j)
                  : ''}
                {col === 2 && j === 1
                  ? this.props.parseEachCell('Volume', i, j)
                  : ''}
                {col === 3 && j === 1 && (getUserSystem() !== 'confex' || getUserSystem() !== 'smartviewRetail')
                  ? this.props.parseEachCell('CustomerCount', i, j)
                  : ''}
                {col === 4 && j === 1 && (getUserSystem() !== 'confex' || getUserSystem() !== 'smartviewRetail')
                  ? this.props.parseEachCell('RateOfSales', i, j)
                  : ''}
                {col === 1 && j > 1
                  ? this.props.parseEachCell(column.text, i, j)
                  : ''}
                {col === 2 && j > 1
                  ? this.props.parseEachCell(column.volume, i, j)
                  : ''}
                {col === 3 && j > 1 && (getUserSystem() !== 'confex' || getUserSystem() !== 'smartviewRetail')
                  ? this.props.parseEachCell(column.customerCount, i, j)
                  : ''}
                {col === 4 && j > 1 && (getUserSystem() !== 'confex' || getUserSystem() !== 'smartviewRetail')
                  ? this.props.parseEachCell(column.rateOfSales, i, j)
                  : ''}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  render() {
    if (!this.props.data || !this.props.data.headers) return null;
    return (
      <div
        id="sku-table"
        className={`c-objects-wrap c-objects-wrap--table ${this.props.className}`}>
        <table className={this.props.tableClassName} id={this.props.id}>
          <thead>
            <tr>{this.props.data.headers.map(this.renderHeader)}</tr>
          </thead>
          <tbody>{this.props.data.rows.map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  }
}

TableSimpleSku.defaultProps = {
  data: [],
  parseEachCell: cell => cell,
  className: '',
  tableClassName: '',
};

TableSimpleSku.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  parseEachCell: PropTypes.func,
  className: PropTypes.string,
  tableClassName: PropTypes.string,
};

export default TableSimpleSku;
