import React from 'react';

import { connect } from 'react-redux';
import CustomCountSelect from '../../CustomCountSelect';
import { motion } from 'framer-motion';
import { capitalizeAllWords } from '../../../../helpers/helpers';
import { selectAdvancedBusinessTypes } from '../../../redux/reducers/filters';

const BusinessTypes = ({
  selectBusinessTypes,
  selectedAdvancedBusinessTypes,
  advancedBusinessTypes,
  unlockRefresh,
  inGroup = false,
}) => {
  return (
    <>
      {advancedBusinessTypes &&
        advancedBusinessTypes.map(
          ({ ApiName, DisplayName, values, isLoading = false }, i) => {
            return (
              values &&
              values.length > 0 && (
                <motion.div
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  key={`dropdown-${i}`}
                  className={`${inGroup ? 'mt-4' : 'form-group col-md-5 col-lg-3'}`}>
                  <CustomCountSelect
                    className={`Select c-select-header c-select-header-row--others`}
                    onChangeCallback={ev => {
                      selectBusinessTypes({
                        value: ev,
                        ApiName,
                        DisplayName,
                      });
                      unlockRefresh();
                    }}
                    name={DisplayName}
                    id={ApiName}
                    isMulti={true}
                    value={selectedAdvancedBusinessTypes[i]?.value}
                    options={values}
                    type={capitalizeAllWords(DisplayName)}
                    types={capitalizeAllWords(DisplayName)}
                    placeholder={capitalizeAllWords(DisplayName)}
                    required={true}
                    isLoading={isLoading}
                    valueKey={'value'}
                    labelKey={'label'}
                  />
                </motion.div>
              )
            );
          },
        )}
    </>
  );
};

const mapStateToProps = state => ({
  advancedBusinessTypes: state.filters.advancedBusinessTypes,
  selectedAdvancedBusinessTypes: state.filters.selectedAdvancedBusinessTypes,
});

const mapDispatchToProps = dispatch => {
  return {
    selectBusinessTypes: options =>
      dispatch(selectAdvancedBusinessTypes(options)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessTypes);
