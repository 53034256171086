import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { formatPerc, formatNumber } from '../../helpers/helpers';
import { getUserSystem } from '../../helpers/util';

import StarImg from '../../assets/images/Star.png';
import GrowerImg from '../../assets/images/grower.png';
import EnglandImg from '../../assets/images/icons/england.png';
import WalesImg from '../../assets/images/icons/wales.png';
import NIImg from '../../assets/images/icons/northernireland.png';
import ScotlandImg from '../../assets/images/icons/scotland.png';

const SubTotalTable = styled.tbody`
  color: #101010;
  font-size: 0.75rem;
  font-weight: bold;
`;

class TableBestSellers extends React.Component {
  // @TODO
  // - Add total

  shouldComponentUpdate(nextProps) {
    return this.props.measure === nextProps.measure;
  }

  selectRow(event) {
    this.props.onSelect(event.target.id);
  }

  renderRow = (row, i) => {
    const tdBrandClass = ['u-tac', 'u-lg', 'performance-col'];

    switch (i % 3) {
      case 1:
        tdBrandClass.push('u-bg--brand');
        break;
      case 2:
        tdBrandClass.push('u-bg--brand-d');
        break;
      default:
        tdBrandClass.push('u-bg--brand-dst');
    }
    let prefix = '';
    if (this.props.measure === 'Value') {
      prefix = '£';
    }

    const formatted = {
      share: formatPerc(row.marketShare),
      change: formatPerc(row.change),
      value_ty: formatNumber(row.salesTY, prefix),
      value_ly: formatNumber(row.salesLY, prefix),
      bestSeller: row.bestSeller,
      fastGrower: row.fastGrower,
      coreRangeEW: row.coreRangeEW,
      coreRangeNI: row.coreRangeNI,
      coreRangeScot: row.coreRangeScot,
    };

    return (
      <tr key={i}>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {row.field && row.field.trim()}
        </td>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          <span className="bg-black text-white pl-1 pr-1 pt-1 pb-1">
            {formatted.share}
          </span>
        </td>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.value_ty}
        </td>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.value_ly}
        </td>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          <span
            style={{ width: '5rem', maxWidth: '5rem' }}
            className={`bg-plus pl-1 pr-1 pt-1 pb-1 ${
              formatted.change.indexOf('-') >= 0 ? 'neg-change' : 'pos-change'
            } `}>
            {formatted.change}
          </span>
        </td>
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.bestSeller && <img src={StarImg} className="star-img" />}
        </td>
        {this.props.theme.name === 'unitas' && (
          <td
            class={
              row.field && row.field.trim() !== 'TOTAL'
                ? ''
                : 'font-weight-bold'
            }>
            <span>
              {formatted.coreRangeEW && (
                <span>
                  <img src={EnglandImg} className="star-img" />
                  <img src={WalesImg} className="star-img" />
                </span>
              )}

              {formatted.coreRangeScot && (
                <img src={ScotlandImg} className="star-img" />
              )}
              {formatted.coreRangeNI && (
                <img src={NIImg} className="star-img" />
              )}
            </span>
          </td>
        )}
        <td
          class={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.fastGrower && <img src={GrowerImg} className="star-img" />}
        </td>
        <td></td>
        {this.props.isDrill ? (
          <td>
            {row.field && row.field.trim() !== 'TOTAL' ? (
              <button
                className={`btn btn-drill btn-primary ${
                  getUserSystem() === 'unitas'
                    ? 'alchemy-btn--unitas'
                    : 'alchemy-btn--others'
                }`}
                onClick={this.selectRow.bind(this)}
                id={row.field && row.field.trim()}>
                DRILL
              </button>
            ) : (
              ''
            )}
          </td>
        ) : (
          ''
        )}
      </tr>
    );
  };

  render() {
    const tableClass = ['o-table'];
    const isRollingDateRange = this.props.isRollingDateRange;
    if (
      this.props.reportType === 'category' ||
      this.props.reportType === 'subcategory' ||
      this.props.reportType === 'level4' ||
      this.props.reportType === 'level5'
    ) {
      tableClass.push(
        'o-product-performance o-product-performance--category-performance',
      );
    } else if (this.props.reportType === 'supplier') {
      tableClass.push(
        'o-product-performance o-product-performance--supplier-performance',
      );
    } else if (this.props.reportType === 'member') {
      tableClass.push(
        'o-product-performance o-product-performance--wholesaler-performance',
      );
    } else if (this.props.reportType === 'brand') {
      tableClass.push(
        'o-product-performance o-product-performance--brand-performance',
      );
    } else {
      tableClass.push(
        'o-product-performance o-product-performance--product-performance',
      );
    }
    return (
      <table className="table text-uppercase table-responsive d-table text-center w-100">
        <thead>
          <tr>
            <th scope="col">Category</th>
            <th scope="col">Share</th>
            <th scope="col"> {isRollingDateRange ? 'Current' : 'Sales TY'}</th>
            <th scope="col">{isRollingDateRange ? 'Previous' : 'Sales LY'}</th>
            <th scope="col">Change</th>
            <th scope="col">Best Seller</th>
            {this.props.theme.name === 'unitas' && (
              <th scope="col">Core Range</th>
            )}
            <th scope="col">Fastest Grower</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.map((r, i) => {
            return this.renderRow(r, i);
          })}
        </tbody>
        <SubTotalTable>
          <tr>
            <td></td>
            <td className="u-tac">SUB TOTAL</td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.subTotal.totalTY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.subTotal.totalLY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatPerc(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.subTotal.change,
              )}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td className="u-tac">OTHER</td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.other.totalTY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.other.totalLY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatPerc(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.other.change,
              )}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td className="u-tac">GRAND TOTAL</td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.grandTotal.totalTY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatNumber(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.grandTotal.totalLY,
                this.props.measure === 'Value' ? '£' : '',
              )}
            </td>
            <td className="u-tac">
              {formatPerc(
                this.props.bestSellerDataTableTotals &&
                  this.props.bestSellerDataTableTotals.grandTotal.change,
              )}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </SubTotalTable>
      </table>
    );
  }
}

TableBestSellers.defaultProps = {
  id: null,
  measure: '',
  purchase: '',
  data: [],
  isDrill: true,
  isLoading: false,
};

TableBestSellers.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  measure: PropTypes.string,
  purchase: PropTypes.string,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  isDrill: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default withTheme(TableBestSellers);
