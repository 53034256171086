import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from '../../components/navigations/BottomNav';
import { getSubmenuPages } from '../../../helpers/api';

import SupportPage from '../SupportPage';

export default class Support extends React.Component {
        state = {
                subpage_nav: [],
        }

        render() {
                return (
                        <div>
                                <Switch>
                                        <Route
                                                path={`${process.env.APP_ROOT}support`}
                                                render={pageProps=>(<SupportPage {...this.props} {...pageProps} />)}
                                        />
                                </Switch>
                                <BottomNav
                                        links={this.state.subpage_nav}
                                        currentUrl={this.props.location.pathname}
                                        brand="a"
                                />
                        </div>
                );
        }
}

Support.defaultProps = {
        location: {},
};

Support.propTypes = {
        alertAdd: PropTypes.func.isRequired,
        location: PropTypes.shape({ pathname: PropTypes.string }),
};

