import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import * as _ from 'lodash';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddCoreRange from '../../components/modals/AddCoreRange';
import { fetchCategories, fetchCoreRanges, onDepartmentChange, onCategoryChange, onSetForm, onDeleteCoreRange, onSaveCoreRange, onSetCoreRange, onBestSellerChange, onPMPChange, resetMe } from '../../redux/reducers/pfpCoreRange';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { throws } from 'assert';

ReactModal.setAppElement('.alchemy_app');
const CoreRanges = ({
  data,
  isLoading,
  coreRanges,
  department,
  departments,
  category,
  categories,
  coreRangeForm,
  onDepartmentChange,
  onCategoryChange,
  onFormSelectChange,
  onFormTextChange,
  onFormDepartmentChange,
  onFormRangeChange,
  onFormCategoryChange,
  onFormFileChange,
  onSaveCoreRange,
  onDeleteCoreRange,
  onBestSellerChange,
  onPMPChange,
  handleCoreRangeDeleteModal,
  handleCoreRangeAddModal,
}) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openCoreRangeAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handleCoreRangeAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openCoreRangeAddModal} contentLabel="" className="performance-modal">
          <AddCoreRange
            handleCoreRangeAddModal={() => handleCoreRangeAddModal(null)}
            isLoading={isLoading}
            coreRangeForm={coreRangeForm}
            onSaveCoreRange={onSaveCoreRange}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
            onFormDepartmentChange={onFormDepartmentChange}
            onFormRangeChange={onFormRangeChange}
            onFormCategoryChange={onFormCategoryChange}
            onBestSellerChange={onBestSellerChange}
            onPMPChange={onPMPChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>Core Range</h4>
          <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
            <div className="u-1_3 mb-0">
              <div className="form-group">
                <h3 className="o-canvas__search-title ">Department</h3>
                <Select value={department} onChange={onDepartmentChange} options={departments} valueKey="ID" labelKey="Text" />
              </div>
            </div>
            {categories.length ? (
              <div className="u-1_3 mb-0">
                <div className="form-group">
                  <h3 className="o-canvas__search-title ">Category</h3>
                  <Select value={category} onChange={onCategoryChange} options={categories} valueKey="ID" labelKey="Text" />
                </div>
              </div>
            ) : null}
          </div>
          {coreRanges.core && coreRanges.core.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th colSpan="12">
                    <h4>Core</h4>
                  </th>
                </tr>
                <tr>
                  <th>Actions</th>
                  <th>Department</th>
                  <th>Range</th>
                  <th>BestSeller</th>
                  <th>DisplayOrder</th>
                  <th>CategoryOrder</th>
                  <th>DepartmentOrder</th>
                  <th>ProductName</th>
                  <th>PackSize</th>
                  <th>PMP</th>
                  <th>Category</th>
                  <th>Supplier</th>
                  <th>Country</th>
                  <th>Image</th>
                  <th>Origin</th>
                </tr>
              </thead>
              <tbody>
                {coreRanges.core.map((coreRange, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleCoreRangeAddModal(coreRange.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleCoreRangeDeleteModal(coreRange.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{coreRange.Department}</td>
                      <td>{coreRange.Range}</td>
                      <td>{coreRange.BestSeller}</td>
                      <td>{coreRange.DisplayOrder}</td>
                      <td>{coreRange.CategoryOrder}</td>
                      <td>{coreRange.DepartmentOrder}</td>
                      <td>{coreRange.ProductName}</td>
                      <td>{coreRange.PackSize}</td>
                      <td>{coreRange.PMP}</td>
                      <td>{coreRange.Category}</td>
                      <td>{coreRange.Supplier}</td>
                      <td>{coreRange.Country}</td>
                      <td>{coreRange.Image}</td>
                      <td>{coreRange.Origin}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openCoreRangeDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this core range?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteCoreRange()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleCoreRangeDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Core Ranges found.</p>
          )}

          {coreRanges.extended && coreRanges.extended.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th colSpan="12">
                    <h4>Extended</h4>
                  </th>
                </tr>
                <tr>
                  <th>Actions</th>
                  <th>Department</th>
                  <th>Range</th>
                  <th>BestSeller</th>
                  <th>DisplayOrder</th>
                  <th>CategoryOrder</th>
                  <th>DepartmentOrder</th>
                  <th>ProductName</th>
                  <th>PackSize</th>
                  <th>PMP</th>
                  <th>Category</th>
                  <th>Supplier</th>
                  <th>Country</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {_.orderBy(coreRanges.extended, ['DisplayOrder'], ['asc']).map((coreRange, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleCoreRangeAddModal(coreRange.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleCoreRangeDeleteModal(coreRange.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{coreRange.Department}</td>
                      <td>{coreRange.Range}</td>
                      <td>{coreRange.BestSeller}</td>
                      <td>{coreRange.DisplayOrder}</td>
                      <td>{coreRange.CategoryOrder}</td>
                      <td>{coreRange.DepartmentOrder}</td>
                      <td>{coreRange.ProductName}</td>
                      <td>{coreRange.PackSize}</td>
                      <td>{coreRange.PMP}</td>
                      <td>{coreRange.Category}</td>
                      <td>{coreRange.Supplier}</td>
                      <td>{coreRange.Country}</td>
                      <td>{coreRange.Image}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openCoreRangeDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this core range?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteCoreRange()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleCoreRangeDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Extended Ranges found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpCoreRange.isLoading,
    department: state.pfpCoreRange.department,
    departments: state.pfpCoreRange.departments,
    category: state.pfpCoreRange.category,
    categories: state.pfpCoreRange.categories,
    isCoreRangeCreated: state.pfpCoreRange.isCoreRangeCreated,
    isCoreRangeDeleted: state.pfpCoreRange.isCoreRangeDeleted,
    coreRanges: state.pfpCoreRange.coreRanges,
    coreRangeForm: state.pfpCoreRange.coreRangeForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    fetchCoreRanges,
    onDepartmentChange,
    onCategoryChange,
    onSetForm,
    onDeleteCoreRange,
    onSaveCoreRange,
    onSetCoreRange,
    onBestSellerChange,
    onPMPChange,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      //this.props.departments.length === 0 &&
      this.props.fetchCategories();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isCoreRangeCreated !== this.props.isCoreRangeCreated && this.props.isCoreRangeCreated) {
        this.props.fetchCoreRanges();
        this.uploadFiles = [];
        toast.info((this.props.coreRangeForm.ID ? 'Updated' : 'Created') + ' Core Range Successfully...');
      }
      if (prevProps.isCoreRangeDeleted !== this.props.isCoreRangeDeleted && this.props.isCoreRangeDeleted) {
        this.props.fetchCoreRanges();
        toast.info('Deleted Core Range Successfully...');
      }
    },
  }),
  withState('data', 'handleData', { isAddCoreRange: false, openCoreRangeAddModal: false, openCoreRangeDeleteModal: false }),
  withHandlers({
    onDepartmentChange: ({ data, handleData, onDepartmentChange }) => target => {
      onDepartmentChange(target);
    },
    onBestSellerChange: ({ data, handleData, onBestSellerChange }) => event => {
      onBestSellerChange(parseInt(event.target.id));
    },
    onPMPChange: ({ data, handleData, onPMPChange }) => event => {
      onPMPChange(parseInt(event.target.id));
    },
    onCategoryChange: ({ data, handleData, onCategoryChange }) => target => {
      onCategoryChange(target);
    },
    onFormDepartmentChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Department', target);
    },
    onFormRangeChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Range', target.ID);
    },
    onFormCategoryChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Category', target);
    },
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.ID);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSaveCoreRange: ({ data, handleData, onSaveCoreRange }) => () => {
      onSaveCoreRange(this.uploadFiles || []);
      handleData({ ...data, isAddCoreRange: false, openCoreRangeAddModal: false });
    },
    onDeleteCoreRange: ({ data, handleData, onDeleteCoreRange, coreRangeForm }) => () => {
      onDeleteCoreRange(coreRangeForm.ID);
      handleData({ ...data, isAddCoreRange: false, openCoreRangeDeleteModal: false });
    },
    handleCoreRangeDeleteModal: ({ data, handleData, coreRanges, onSetCoreRange }) => id => {
      handleData({ ...data, openCoreRangeDeleteModal: !data.openCoreRangeDeleteModal });
      onSetCoreRange(id || '', false, true);
    },
    handleCoreRangeAddModal: ({ data, handleData, onSetCoreRange }) => id => {
      const isAddCoreRange = id ? false : true;
      this.uploadFiles = []; //reset
      const openCoreRangeAddModal = !data.openCoreRangeAddModal;
      handleData({ ...data, isAddCoreRange, openCoreRangeAddModal: openCoreRangeAddModal });
      if (openCoreRangeAddModal) {
        onSetCoreRange(id, isAddCoreRange);
      } else {
        onSetCoreRange(null, isAddCoreRange);
      }
    },
  }),
)(CoreRanges);
