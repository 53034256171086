/* currentUser ACTIONS */
export function loginUser(user = {}) {
	return {
		type: 'USER_LOGON',
		username: user,
	};
}

export function logoutUser() {
	return {
		type: 'USER_LOGOUT',
	};
}

export function setUserWholesaler(wid) {
	return {
		type: 'USER_SET_WHOLESALER',
		wid,
	};
}

export function setUserSystem(system) {
        return {
                type: 'USER_SET_SYSTEM',
                system,
        };
}

export function setUserSupplier(sid) {
	return {
		type: 'USER_SET_SUPPLIER',
		sid,
	};
}

/* alerts ACTIONS */
export function alertAdd(text = '', alertType = 'info', timeToClose = 10000) {
	if (typeof text !== typeof 'str') return { type: 'NONE' };
	return {
		type: 'ADD_ALERT',
		alertType,
		timeToClose,
		text,
	};
}

export function alertClose(id) {
	if (isNaN(id)) return { type: 'NONE' };
	return {
		type: 'CLOSE_ALERT',
		alertId: id,
	};
}

export function setDepotSelectType(type) {
	if (['filter', 'select'].indexOf(type) === -1) return { type: 'NONE' };
	return {
		type: 'SET_DEPOT_SELECT_TYPE',
		depotSelectType: type,
	};
}


export function setDepotSelectFilterData(data) {
	return {
		type: 'SET_DEPOT_SELECT_FILTER_DATA',
		data,
	};
}


export function toggleOffscreen (element) {
	if (element === undefined) return { type: 'NONE' };
	return {
		type: 'TOGGLE_OFFSCREEN_ELEMENT',
		element,
	};
}


export function pageGotUpdated() {
	return {
		type: 'UPDATED_VIEW',
	};
}
