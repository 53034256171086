import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { getRandomNumber, formatNumber } from '../../helpers/helpers';
class PlotGraph extends React.Component {
  state = {
    graphID: getRandomNumber('graph_'),
  };

  componentWillMount() {
    require.ensure([], require => {
      require('../../assets/js/flot/jquery.flot');
      require('../../assets/js/flot/jquery.flot.stack.min');
      require('../../assets/js/flot/jquery.flot.time.min');
      require('../../assets/js/flot/jquery.flot.orderBars');
    });
  }

  componentDidMount() {
    this.renderGraph();
    $(`#${this.state.graphID}`).on('plothover', this.graphHoverTooltip);
  }

  shouldComponentUpdate(nextProps) {
    const oldData = JSON.stringify(this.props.data);
    const newData = JSON.stringify(nextProps.data);
    if (oldData === newData) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    this.renderGraph();
  }

  graphHoverTooltip = (e, pos, item) => {
    const offsets = $('.c-objects-wrap').offset();
    if (item) {
      const x = item.datapoint[0].toFixed(2);
      const y = item.datapoint[1].toFixed(2);

      let tooltipText = `${item.series.label} of £ ${y} = ${x} units`;
      if (item.series.label.toLowerCase().indexOf('sale') !== -1) {
        tooltipText = `${item.series.label} ${parseInt(y, 10)} units`;
      } else if (item.series.label.toLowerCase().indexOf('average') !== -1) {
        tooltipText = `${item.series.label} of £${y}`;
      }
      $('.c-charts-tooltip')
        .html(tooltipText)
        .css({
          top: item.pageY - offsets.top - 15,
          left: item.pageX - offsets.left,
        })
        .fadeIn(200);
    } else {
      $('.c-charts-tooltip').hide();
    }
  };

  renderGraph = () => {
    require.ensure([], require => {
      require('../../assets/js/flot/jquery.flot');
      require('../../assets/js/flot/jquery.flot.stack.min');
      require('../../assets/js/flot/jquery.flot.time.min');
      require('../../assets/js/flot/jquery.flot.orderBars');

      const data = this.props.data;
      const theme = this.props.theme;
      if (data === undefined || Object.keys(data).length < 1) {
        return;
      }
      $.plot(
        `#${this.state.graphID}`,
        [
          {
            data: JSON.parse(data.soldQty),
            color: theme.primary,
            opacity: 0.6,
            label: 'Sale Out (Volume)',
            hoverable: true,
            bars: {
              shadowSize: 0,
              show: true,
              align: 'center',
            },
          },
          {
            data: JSON.parse(data.price),
            label: 'Average Price',
            yaxis: 2,
            shadowSize: 0,
            color: theme.secondary,
          },
        ],
        {
          xaxes: [
            {
              mode: 'categories',
              minTickSize: 4,
              ticks: JSON.parse(data.tick),
              position: 'bottom',
            },
          ],
          yaxes: [
            { min: 0 },
            {
              // align if we are to the right
              alignTicksWithAxis: 1,
              position: 'right',
              min: 0,
              tickFormatter: v => {
                return formatNumber(v.toFixed(0), '£');
              },
            },
          ],
          x1axis: [
            {
              minTickSize: 4,
            },
          ],
          legend: {
            container: $('#legendContainer'),
            noColumns: 0,
          },
          grid: {
            labelMargin: 30,
            borderWidth: { top: 0, right: 1, bottom: 1, left: 1 },
            borderColor: { right: '#4a5758', left: '#4a5758' },
            autoHighlight: true,
            color: '#000000',
            hoverable: true,
          },
        },
      );
    });
  };

  render() {
    return (
      <div
        className={`c-objects-wrap c-objects-wrap--plot-graph ${this.props.className}`}
        style={{ overflow: 'hidden' }}>
        <div
          id="legendContainer"
          style={{ margin: '0 auto', position: 'relative', left: '40%' }}></div>
        <div id={this.state.graphID} className="plot-graph"></div>
        <div className="c-charts-tooltip" />
      </div>
    );
  }
}

PlotGraph.defaultProps = {
  data: {},
  className: '',
};

PlotGraph.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme(PlotGraph);
