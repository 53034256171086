import { API_ENDPOINTS } from '../AlchemySettings';

import { getToken, getUserId } from './util';
import {
  optionsToArray,
  removeAssets,
  optionFieldToToggleArray,
} from './helpers';

/* DESTRUCTURE VARIABLE FOR LINKS */
const {
  SupplierSelectWholesalerUrl,
  SupplierSelectUrl,
  SupplierSelectSubmit,
  DepotFilterUrl,
  DepotSelectUrl,
  DepotUpdate,
  namDashUrl,
  analysisUrl,
  psaUrl,
  queryBuilder,
  queryBuilderFilter,
  queryBuilderCSRF,
  queryBuilderSubmitQuery,
} = API_ENDPOINTS;

const requestMethod = process.env.NODE_ENV === 'development' ? 'GET' : 'POST';

/* GET CSRF */
export function getCSRF(url) {
  return new Promise((res, rej) => {
    $.ajax(url, {})
      .done(data => {
        const remoteDoc = $(removeAssets(data));
        res(remoteDoc.find('[name="__csrf_magic"]').val());
      })
      .fail(() => {
        rej('Could not connect to  (CSRF)');
      });
  });
}

/* LOGIN */
export function doLogin(url, details) {
  return new Promise((res, rej) => {
    $.ajax(
      url +
        `?c=login/login&user=${encodeURIComponent(
          details.username,
        )}&password=${encodeURIComponent(details.password)}`,
      {
        method: requestMethod,
        type: 'GET',
      },
    )
      .done((data, status) => {
        const jsonData = JSON.parse(data);
        jsonData.email = details.username;
        localStorage.setItem('user_data', JSON.stringify(jsonData));
        if (jsonData.success) {
          /* CORRECT LOGIN SKIPPING SUPPLIER SELECT, DEFAULT ACTION */
          if (jsonData.dataSets && jsonData.dataSets.length > 1) {
            res({ username: details.Name, redirect: 'select-supplier' });
          }
          const config = {
            redirect: 'dashboard',
            depotFilterType: 'select',
            username: details.username,
            dataSets: jsonData.dataSets,
            wholesaler: 2, // @TODO: Hardcoded default - make dynamic
          };

          if (jsonData.wholeSalers.length > 0) {
            config.depotFilterType = 'filter';
          }
          res(config);
        } else {
          /* CSRF ERROR */
          rej(jsonData.message);
        }
      })
      .fail((xhr, err) => {
        rej(`Could't connect to Alchemy system! - ${err}`);
      });
  });
}

export function getWholesalers() {
  return new Promise((res, rej) => {
    $.ajax(SupplierSelectWholesalerUrl)
      .done(data => {
        const $response = $(removeAssets(data));
        const $options = $response.find('[name="f_wholesaler"] option');
        const wholesalers = optionsToArray($options);

        res(wholesalers);
      })
      .fail(() => {
        rej('Could not connect to Alchemy system (Wholesaler select)');
      });
  });
}

export function getSuppliers(code) {
  return new Promise((res, rej) => {
    $.ajax(`${SupplierSelectUrl}&code=${code}`)
      .done(data => {
        const $response = $(removeAssets(data));
        const $options = $response.find('[name="f_supplierCode"] option');
        const suppliers = optionsToArray($options);

        res(suppliers);
      })
      .fail(() => {
        rej('Could not connect to Alchemy system (Wholesaler select)');
      });
  });
}

export function doSupplierSelect(data) {
  return new Promise((res, rej) => {
    $.ajax(SupplierSelectSubmit, {
      method: requestMethod,
      data,
    })
      .done(response => {
        const $response = $(removeAssets(response));
        /* Setup config to respond with */
        const config = { depotFilterType: 'select' };
        if ($response.find('.home-nav').length > 0) {
          config.redirect = 'dashboard';
        }
        if ($response.find('.js-wholesaler').length > 0) {
          config.depotFilterType = 'filter';
        }

        res(config);

        rej(
          'Supplier select failed. Please contact the support quoting this message',
        );
      })
      .fail(() => {
        rej(
          'Could not connect to Alchemy System, please try again later or contact the support.',
        );
      });
  });
}

export function getSubmenuPages(submenu) {
  //Placed KPI dashboard higher than the rest for it to appear 1st on Suntory
  const menus = {
    namdash: [
      {
        id: '15',
        reportName: 'KPI Dashboard',
        reportUrl: '/nam_dash/kpi',
      },
      { id: '5.5', reportName: 'Group Performance', reportUrl: '/nam_dash' },
      {
        id: '1',
        reportName: 'Category Performance',
        reportUrl: '/nam_dash/reports/CategoryShare.php',
      },
      {
        id: '3',
        reportName: 'Supplier Performance',
        reportUrl: '/nam_dash/reports/SupplierShare.php',
      },
      {
        id: '4',
        reportName: 'Brand Performance',
        reportUrl: '/nam_dash/reports/SupplierShare.php',
      },
      {
        id: '5',
        reportName: 'Wholesaler Performance',
        reportUrl: '/nam_dash/reports/WholesalerShare.php',
      },
      {
        id: '12',
        reportName: 'Depot Performance',
        reportUrl: '/nam_dash/reports/ProductSalesSummary.php',
      },
      {
        id: '13',
        reportName: 'Business Type Performance',
        reportUrl: '/nam_dash/reports/DepotShare.php',
      },
      {
        id: '6',
        reportName: 'Product Performance',
        reportUrl: '/nam_dash/reports/stock.php',
      },
/**      {
        id: '7',
        reportName: 'Region Performance',
        reportUrl: '/nam_dash/reports/SupplierShare.php',
      },
      {
        id: '8',
        reportName: 'Fascia Performance',
        reportUrl: '/nam_dash/reports/SupplierShare.php',
      }, */
      {
        id: '9',
        reportName: 'SKU Tracker',
        reportUrl: '/nam_dash/reports/DepotShare.php',
      },
      {
        id: '10',
        reportName: 'Product Search',
        reportUrl: '/nam_dash/reports/product_finder',
      }, 
      {
        id: '11',
        reportName: 'Best Sellers',
        reportUrl: '/nam_dash/reports/DepotShare.php',
      },
      {
        id: '14',
        reportName: 'Supplier Engagement Report',
        reportUrl: '/nam_dash/supplier_engagement_report',
      },
      {
        id: '16',
        reportName: 'Download Cube',
        reportUrl: '/nam_dash/download_cube',
      },
    ],
    analysis: [
      {
        id: '1',
        reportName: 'Price Sensitivity',
        reportUrl: '/analysis/reports/price-senstitivity.php',
      },
      {
        id: '2',
        reportName: 'Area Heatmap',
        reportUrl: '/analysis/reports/hotspots-n.php',
      },
      {
        id: '3',
        reportName: 'Depot Scorecard',
        reportUrl: '/analysis/reports/scorecard.php',
      },
      {
        id: '4',
        reportName: 'Bought Not Bought',
        reportUrl: '/analysis/reports/bought-not-bought.php',
      },
      {
        id: '5',
        reportName: 'Gap Analysis',
        reportUrl: '/analysis/reports/stocked-not-stocked.php',
      },
      {
        id: '6',
        reportName: 'Gap Analysis',
        reportUrl: '/analysis/reports/gap&#45;analysis.php',
      },
    ],
    psa: psaUrl,
  };

  return new Promise((res, rej) => {
    res(menus[submenu]);
  });
}

export function getWholesalerDepotData() {
  return new Promise((res, rej) => {
    $.ajax({
      url: DepotFilterUrl,
      headers: { Authorization: getToken(), UserId: getUserId() },
    })
      .done(response => {
        const $response = $(removeAssets(response));
        const filterData = [];

        /* Create wholesaler objects */
        $response.find('.js-wholesaler').each((i, ws) => {
          const $ws = $(ws);
          if ($ws.length === 0) {
            return;
          }
          const wsData = {
            Active: true,
            Depots: [],
          };
          wsData.ID = $ws.attr('data-id');
          wsData.WholesalerName = $ws.find('.js-wholesaler-title').text();
          /* Collect depots in array */
          $ws
            .find('.js-depot-list')
            .find('li')
            .each((y, depot) => {
              const $depot = $(depot);
              const depotData = { active: true };
              depotData.id = $depot.attr('data-depot');
              depotData.name = $depot.text();
              wsData.Depots.push(depotData);
            });

          filterData.push(wsData);
        });
        /* Resolve with data object */
        res(filterData);
      })
      .fail(() => {
        rej(`Could not connect to Alchemy System, please try again later or contact the support. 
                                Error getting depots.`);
      });
  });
}

export function getDepotCategoryData() {
  return new Promise((res, rej) => {
    $.ajax(DepotSelectUrl)
      .done(response => {
        const $response = $(removeAssets(response));
        const selectData = {
          depots: [],
          categories: [],
        };

        $response.find('.js-element').each((i, item) => {
          const $item = $(item);
          const itemData = { name: $item.text(), active: true };

          if ($item.attr('data-depotID') !== undefined) {
            itemData.id = $item.attr('data-depotID');
            selectData.depots.push(itemData);
          } else if ($item.attr('data-catID') !== undefined) {
            itemData.id = $item.attr('data-catID');
            selectData.categories.push(itemData);
          }
        });
        res(selectData);
      })
      .fail(() => {
        rej(`Could not connect to Alchemy System, please try again later or contact the support. 
                                Error getting depots.`);
      });
  });
}

/* SEND selected depots to server for storing in a session */
export function updateDepotList(data) {
  const activeDepots = [];
  const activeCats = [];

  data.depots.forEach(depot => {
    if (depot.active) {
      activeDepots.push({ depotID: depot.id });
    }
  });

  data.categories.forEach(category => {
    if (category.active) {
      activeCats.push({ catID: category.id });
    }
  });

  $.ajax(DepotUpdate, {
    data: {
      update: 1,
      depots: JSON.stringify(activeDepots),
      categories: JSON.stringify(activeCats),
    },
  });
}

/* Send selected depot filter */
export function updateDepotFilter(data) {
  data.forEach(ws => {
    const filteredDepots = [];
    ws.Depots.forEach(depot => {
      if (depot.active) {
        filteredDepots.push(depot);
      }
    });
    ws.depots = filteredDepots;
  });

  $.ajax(
    {
      url: `${DepotUpdate}?update_wholeview=1`,
      headers: { Authorization: getToken(), UserId: getUserId() },
    },
    {
      method: 'POST',
      data: {
        wholesaler: JSON.stringify(data),
      },
    },
  );
}

export function getQueryBase() {
  return new Promise((res, rej) => {
    $.ajax(queryBuilder)
      .done(response => {
        const $response = $(removeAssets(response));

        let wholesalerArray = [];
        const wholesalerOptions = $response.find('#ddl-wholesaler option');
        if (wholesalerOptions.length > 0) {
          wholesalerArray = optionsToArray(wholesalerOptions);
        }
        if (wholesalerArray[0] && wholesalerArray[0].id === '0') {
          wholesalerArray.shift();
        }

        let datesPreselect = [];
        const $optionFields = $response
          .find('.modal-content.row')
          .eq(0)
          .find('.js-preselect');
        if ($optionFields.length > 0) {
          datesPreselect = optionFieldToToggleArray($optionFields);
        }

        let dateRangeDefault = {};
        const dateRangeStart = $response
          .find('#js-start_week_1')
          .text()
          .trim();
        const dateRangeEnd = $response
          .find('#js-end_week_1')
          .text()
          .trim();
        dateRangeDefault = {
          start: new Date(dateRangeStart) || false,
          end: new Date(dateRangeEnd) || false,
        };

        const updateDate = $response.find('.currentDate').text();

        res({
          wholesalerArray,
          dateRangeDefault,
          updateDate,
          datesPreselect,
        });
      })
      .fail(() => {
        rej('Request for query page data failed.');
      });
  });
}

export function getQueryFilterItems(data) {
  return new Promise((res, rej) => {
    $.ajax(queryBuilderFilter, {
      method: requestMethod,
      data,
    })
      .done(response => {
        res(JSON.parse(response));
      })
      .fail(() => {
        rej('Request for filter details failed.');
      });
  });
}

export function submitQuery(data) {
  return new Promise((res, rej) => {
    getCSRF(queryBuilderCSRF)
      .then(csrf => {
        data['__csrf_magic'] = csrf;
        data.act = 'save-query';
        $.ajax(queryBuilderSubmitQuery, {
          method: requestMethod,
          data,
        })
          .done(response => {
            res(response);
          })
          .fail(() => {
            rej('Error submitting query');
          });
      })
      .catch(rej);
  });
}
