'use strict';
import { handleActions, createAction } from 'redux-actions';
import { requestGet } from '../../../helpers/axios';
import { encodeText, getUserSystem } from '../../../helpers/util';
import { dropdownSelectionHelper } from '../../../helpers/dropdownSelectionHelper';
import store from '../store';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';

import {
  formatUrlWithParameters,
  fetchSuppliers
} from '../../../helpers/helpers';

const FETCH_DATA = 'kpi/FETCH_DATA';
const FETCH_PRODUCTS = 'kpi/FETCH_PRODUCTS';
const SET_REPORT_DEPARTMENTS = 'kpi/SET_DEPARTMENTS';
const SET_REPORT_CATEGORIES = 'kpi/SET_CATEGORIES';
const SET_SUBCATEGORIES = 'kpi/SET_SUBCATEGORIES';
const SET_LEVEL4S = 'kpi/SET_LEVEL4S';
const SET_LEVEL5S = 'kpi/SET_LEVEL5S';
const SET_ACCOUNT_NAME = 'report/SET_ACCOUNT_NAME';
const SET_TIMEFRAME = 'kpi/SET_TIMEFRAME';
const SET_SELECTED_GROUPS = 'kpi/SET_SELECTED_GROUPS';
const SET_SUPPLIERS = 'kpi/SET_SUPPLIERS';
const SET_BRANDS = 'kpi/SET_BRANDS';
const SET_SELECTED_BRANDS = 'kpi/SET_SELECTED_BRANDS';
const SET_SELECTED_SUPPLIERS = 'kpi/SET_SELECTED_SUPPLIERS';
const SET_SELECTED_PRODUCT = 'kpi/SET_SELECTED_PRODUCT';
const SET_SUPPLIERS_LOADING = 'kpi/SET_SUPPLIERS_LOADING';
const SET_LOADED = 'kpi/SET_LOADED';
const SET_HIDE_ALL = 'kpi/SET_HIDE_ALL';
const RESET = 'kpi/RESET';
const SET_MEASURE = 'kpi/SET_MEASURE';
const ADD_MEASURE = 'kpi/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'kpi/RESET_MEASURE_OPTIONS';
const SET_IS_REFRESH_DISABLED = 'kpi/SET_IS_REFRESH_DISABLED';
const FETCH_KPI_DATA =
  'kpi/FETCH_KPI_DATA';

const initialState = {
  baseUrl: '',
  departments: [],
  selectedDepartments: [],
  selectedDepartment: '',
  categories: [],
  selectedCategories: [],
  selectedCategory: '',
  subCategories: [],
  selectedSubCategories: [],
  selectedSubCategory: '',
  accountName: [],
  accountNames: [],
  groups: [],
  brands: [],
  brand: '',
  selectedBrands: [],
  selectedGroups: [],
  suppliers: [],
  supplier: '',
  selectedSuppliers: [],
  depots: [],
  selectedDepots: [],
  level4s: [],
  selectedLevel4s: [],
  selectedLevel4: '',
  level5s: [],
  selectedLevel5s: [],
  selectedLevel5: '',
  products: [],
  selectedProduct: '',
  selectedProducts: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  measure: 'Value',
  likeForLike: '',
  purchase: 'out',
  title: '',
  subPage: '',
  reportType: '',
  currentReportPage: '',
  isLoaded: false,
  hideAll: false,
  pdfURL: '',
  businessTypes: [],
  selectedBusinessType: '',
  isInitialLoadComplete: false,
  reportType: '',
  isRefreshDisabled: false,
  kpiData: {},
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const setSelectedGroupsAction = createAction(SET_SELECTED_GROUPS);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setSelectedSuppliersAction = createAction(SET_SELECTED_SUPPLIERS);
const setSelectedBrandsAction = createAction(SET_SELECTED_BRANDS);
const setSelectedProductAction = createAction(SET_SELECTED_PRODUCT);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setSuppliersLoadingAction = createAction(SET_SUPPLIERS_LOADING);
const setLoadedAction = createAction(SET_LOADED);
const resetAction = createAction(RESET);
const setMeasureAction = createAction(SET_MEASURE);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setKpiDataAction = createAction(FETCH_KPI_DATA);
const setIsRefreshDisabled = createAction(SET_IS_REFRESH_DISABLED);

export const loadKPIData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadedAction(false));
  const state = store.getState().kpi;
  const user = store.getState().user.authUser;
  const url = formatUrlWithParameters(
    '/route.php?c=kpi/overview',
    state,
    user,
  );
  try {
    const kpiData = await requestGet({ url });

    dispatch(setKpiDataAction(kpiData));
  } catch (err) {
    console.warn(err);
  } finally {
    dispatch(setLoadedAction(true));
  }
};

export const resetReport = () => dispatch => {
  dispatch(setLoadedAction(false));
  dispatch(resetAction());
};

export const setDepartments = depts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().kpi;
  let reportType = 'basket_analysis';

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept.Text === 'TOBACCO') {
        return dept;
      }
    });

    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    dropdownSelectionHelper(
      depts,
      reportType,
      'departments',
      state.departments,
      state.selectedDepartments,
      setReportDepartmentsAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().kpi;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  } else {
    dispatch(
      setReportDepartmentsAction({ reportType: reportType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: reportType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: reportType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: reportType, level5s: [] }));

    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  }
};

export const setRefreshDisabledStatus = val => dispatch => {
  dispatch(setIsRefreshDisabled(val));
};

export const setCategories = cats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().kpi;
  let reportType = 'basket_analysis';
  if (cats.length > 0) {
    dropdownSelectionHelper(
      cats,
      reportType,
      'categories',
      state.categories,
      state.selectedCategories,
      setReportCategoriesAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().kpi;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  } else {
    dispatch(setReportCategoriesAction({ reportType, categories: [] }));
    dispatch(setSubCategoriesAction({ reportType, subCategories: [] }));
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  }
};

export const setSubCategories = subCats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().kpi;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = state.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'group' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }

    dropdownSelectionHelper(
      subCats,
      rptType,
      'subCategories',
      state.subCategories,
      state.selectedSubCategories,
      setSubCategoriesAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().kpi;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'group' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction({ level5s: [], reportType: rptType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

export const setLevel4s = opts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().kpi;
  let { reportType } = state;
  if (opts.length > 0) {
    dropdownSelectionHelper(
      opts,
      reportType,
      'level4s',
      state.level4s,
      state.selectedLevel4s,
      setLevel4sAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().kpi;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));
  } else {
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

export const setLevel5s = lvl5s => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().kpi;
  let { reportType } = state;
  if (lvl5s.length > 0) {
    dropdownSelectionHelper(
      lvl5s,
      reportType,
      'level5s',
      state.level5s,
      state.selectedLevel5s,
      setLevel5sAction,
      dispatch,
    );
    const user = store.getState().user.authUser;
    state = store.getState().kpi;
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(true));
    dispatch(setSuppliersAction(suppliers));
    dispatch(setSuppliersLoadingAction(false));
  } else {
    dispatch(setLevel5sAction({ level5s: [], reportType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

const loadProducts = async (store, dispatch) => {
  let state = store.getState().kpi;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=product/getAll',
    state,
    user,
  );

  if (user.showCompetitor) {
    url += `&show_competitor=0&supplier=${encodeURIComponent(
      user.supplier.Text,
    )}`;
  }

  let products = [];
  products = await requestGet({ url });
  each(products, prod => {
    prod.WVProdDesc = encodeText(prod.WVProdDesc);
  });

  dispatch(fetchProductsAction(products));
};

export const setAccountName = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setAccountNameAction(val));
};

export const setGroup = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedGroupsAction(val));
};

export const setMeasure = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setMeasureAction(val));
}

export const setSuppliers = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val.length !== 0) {
    await dispatch(setSelectedSuppliersAction(val));
    const state = store.getState().kpi;
    let url = '/route.php?c=brand/getAll';
    
    url += buildRequestURL(state.selectedDepartments, 'department', 'Text');

    url += buildRequestURL(state.selectedCategories, 'category', 'Text');
    
    url += buildRequestURL(state.selectedSubCategories, 'subcategory', 'Text');

    url += buildRequestURL(state.selectedLevel4s, 'level4', 'Text');

    url += buildRequestURL(state.selectedLevel5s, 'level5', 'Text');

    url += buildRequestURL(state.selectedSuppliers, 'supplier', 'Text');

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  } else {
    dispatch(setSelectedSuppliersAction([]));
    const state = store.getState().kpi;
    let url = '/route.php?c=brand/getAll';
    
    url += buildRequestURL(state.selectedDepartments, 'department', 'Text');

    url += buildRequestURL(state.selectedCategories, 'category', 'Text');
    
    url += buildRequestURL(state.selectedSubCategories, 'subcategory', 'Text');

    url += buildRequestURL(state.selectedLevel4s, 'level4', 'Text');

    url += buildRequestURL(state.selectedLevel5s, 'level5', 'Text');

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  }
};

function buildRequestURL (values, valueType, mapKey) {
  if (values.length > 0) {
    return `&${valueType}=${encodeURIComponent(
      map(values, mapKey).join(','),
    )}`;
  }
  return '';
}

export const setTimeFrame = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setTimeFrameAction(val));
};

export const setBrands = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedBrandsAction(val));
  loadProducts(store, dispatch);
};

export const setSelectedProduct = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));

  if (val) {
    dispatch(setSelectedProductAction(val));
  } else {
    dispatch(setSelectedProductAction(''));
  }
};

export const fetchCategories = () => async dispatch => {
    dispatch(setLoadedAction(false));

    const userObj = JSON.parse(localStorage.user_data);
    let url = '/route.php';
    if (userObj.departments.length === 0 && userObj.categories.length === 0) {
      url += '?c=category/getHierarchy';
    } else {
      url += '?c=category/getUserHierarchy';
    }

    dispatch(setLoadedAction(false));
    const result = await requestGet({ url });
    let url1 = '/route.php?c=wholesaler/getAll';

    let groups = await requestGet({ url: url1 });

    let url3 = '/route.php?c=brand/getAll';

    let brands = await requestGet({ url: url3 });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });

    url = '/route.php?c=supplier/getAll';

    if (
      groups.length === 1
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(
        setSelectedGroupsAction([
          { Text: groups[0].ActualName, ID: groups[0].ActualName },
        ]),
      );
    }

    const response = await requestGet({ url: url });

    let suppliers = [];

    each(response, supp => {
      supp.SupplierName = encodeText(supp.SupplierName);
      suppliers.push(supp);
    });

    url = '/route.php?c=customer/getAllBusinessTypes';

    let businessTypes = [];

    try {
      businessTypes = await requestGet({ url: url });
    } catch {
      console.warn('No business types permission');
    }

    url='/route.php?c=customer/getRetailers';

    const accountNames = await requestGet({ url: url });

    url = '/route.php?c=depot/getDepots';

    businessTypes =
      businessTypes.map(businessType => {
        return { BusinessName: businessType };
      }) || [];

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    let departments = result.Departments ? result.Departments : [];
    let orderedDepartments = orderBy(departments, ['Text']);
    let categories = result.Categories ? result.Categories : [];
    let orderedCategories = orderBy(categories, ['Text']);
    if (departments.length > 1) {
      orderedDepartments.unshift({ ID: 0, Text: 'Select all', Categories: [] });
    }

    if (categories.length > 1) {
      orderedCategories.unshift({
        ID: 0,
        Text: 'Select all',
        SubCategories: [],
      });
    }

    // Format suppliers to fit { ID: *, Text: ""}
    let formattedSuppliers;
    if (suppliers.length > 0) {
      formattedSuppliers = suppliers.map(supplier => ({
        Text: supplier.SupplierName,
        ID: supplier.SupplierName,
      }));
    }

    // Format Brands to fit { ID: *, Text: ""}
    let formattedBrands;
    if (brands.length > 0) {
      formattedBrands = brands.map(brand => ({
        Text: brand.Brand,
        ID: brand.Brand,
      }));
    }

    let state = store.getState();

    dispatch(
      fetchDataAction({
        departments: orderedDepartments,
        categories: orderedCategories,
        suppliers: formattedSuppliers || suppliers,
        brands: formattedBrands || brands,
        groups: groups,
        accountNames: accountNames,
        depots: depots,
        purchase: state.kpi.pruchase,
        measure: state.kpi.measure,
        likeForLike: state.kpi.likeForLike,
        timeframe: state.kpi.timeframe,
        businessTypes: businessTypes,
      }),
    );
  };



export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      departments: payload.departments,
      categories: payload.categories,
      groups: payload.groups,
      brands: payload.brands,
      suppliers: payload.suppliers,
      accountNames: payload.accountNames,
      depots: payload.depots,
      purchase: payload.purchase,
      measure: payload.measure,
      likeForLike: payload.likeForLike,
      timeframe: payload.timeframe,
      businessTypes: payload.businessTypes,
      isInitialLoadComplete: !state.isInitialLoadComplete,
    }),
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        payload,
      ],
    }),
    [SET_MEASURE]: (state, { payload }) => ({
      ...state,
      measure: payload
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (allCategories.length > 1) {
        allCategories.unshift({ ID: 0, Text: 'Select all', SubCategories: [] });
      }

      return {
        ...state,
        selectedDepartment: payload.departments[0],
        selectedDepartments: payload.departments,
        categories: allCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );
      let orderedSubCategories = uniqBy(
        orderBy(
          filter(allSubCategories, subCat => subCat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );
      if (allLevel4s.length > 1) {
        allLevel4s.push({ ID: 0, Text: 'Select all', Level5s: [] });
      }
      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: uniqBy(
          orderBy(
            filter(allLevel4s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );
      if (allLevel5s.length > 1) {
        allLevel5s.push({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: uniqBy(
          orderBy(
            filter(allLevel5s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_LEVEL5S]: (state, { payload }) => ({
      ...state,
      selectedLevel5s: payload.level5s,
      reportType: payload.reportType,
    }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName:  payload,
    }),
    [SET_SELECTED_PRODUCT]: (state, { payload }) => ({
      ...state,
      selectedProduct: payload,
    }),
    [SET_SELECTED_GROUPS]: (state, { payload }) => ({
      ...state,
      selectedGroups: payload,
    }),
    [SET_SUPPLIERS]: (state, { payload }) => {
      let formattedSuppliers;
      if (payload[0] && payload[0].SupplierName) {
        formattedSuppliers = payload.map(supplier => ({
          Text: supplier.SupplierName,
          ID: supplier.SupplierName,
        }));
        return { ...state, suppliers: formattedSuppliers };
      } else {
        return { ...state, suppliers: payload };
      }
    },
    [SET_BRANDS]: (state, { payload }) => {
      let formattedBrands;
      if (payload[1] && payload[1].Brand) {
        formattedBrands = payload.map(brand => ({
          Text: brand.Brand,
          ID: brand.Brand,
        }));
        return { ...state, brands: formattedBrands };
      } else {
        return { ...state, brands: payload };
      }
    },
    [SET_SELECTED_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      selectedSuppliers: payload,
    }),
    [SET_SELECTED_BRANDS]: (state, { payload }) => ({
      ...state,
      selectedBrands: payload,
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      products: payload,
    }),
    [SET_TIMEFRAME]: (state, { payload }) => ({
      ...state,
      timeframe: payload.ID ? payload.ID : payload,
    }),
    [SET_SUPPLIERS_LOADING]: (state, { payload }) => ({
      ...state,
      areSuppliersLoading: payload,
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_IS_REFRESH_DISABLED]: (state, { payload }) => ({
      ...state,
      isRefreshDisabled: payload,
    }),
    [FETCH_KPI_DATA]: (state, { payload }) => ({
      ...state,
      kpiData: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      ...initialState
    }),
  },
  initialState,
);
