import React from 'react';
import PropTypes from 'prop-types';
import { getCSRF, doLogin } from '../../helpers/api';
import { request } from '../../helpers/axios';
import { getToken, getUserId } from '../../helpers/util';
import { setMenuOptions } from '../redux/reducers/app';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import { API_ENDPOINTS } from '../../AlchemySettings';
import { toast } from 'react-toastify';

const { LoginUrl, CSRFUrl } = API_ENDPOINTS;

const systemsToDefaultToUnitas = ['twc'];
const systemsToDefaultToDemo = [];

class LoginForm extends React.Component {
  state = {
    csrf: '',
  };

  componentWillMount() {
    // this.updateCSRF();
  }

  setSystem() {
    return systemsToDefaultToUnitas.includes(this.props.theme.name)
      ? 'unitas'
      : systemsToDefaultToDemo.includes(this.props.theme.name)
      ? 'demo'
      : this.props.theme.name;
  }

  updateCSRF = () => {
    /* GET CSRF cookie from API */
    getCSRF(CSRFUrl)
      .then(csrf => {
        this.setState({
          ...this.state,
          csrf,
        });
      })
      .catch(err => this.props.alertAdd(err, 'error'));
  };

  sendLogin = e => {
    e.preventDefault();
    doLogin(LoginUrl, {
      type: 'login',
      username: this.username.value,
      password: this.pass.value,
      __csrf_magic: this.csrf.value,
    })
      .then(resp => {
        this.props.loginUser(resp.username);
        if (resp.redirect === 'select-supplier') {
          this.props.history.push(`${process.env.APP_ROOT}supplier_select`);
        }

        if (resp.redirect === 'dashboard') {
          let url = '/route.php?c=reportMenu/getNAMDashReports';
          request({
            url: url,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: getToken(),
              UserId: getUserId(),
              System: this.setSystem(),
            },
          }).then(result => {
            let url1 = '/route.php?c=reportMenu/getAnalysisReports';
            request({
              url: url1,
              method: 'get',
              headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
                UserId: getUserId(),
                System: this.setSystem(),
              },
            }).then(values => {
              this.props.setMenuOptions({
                namdash: result,
                analysis: values,
                psa: [
                  'manage_distribution',
                  'manage_campaigns',
                  'view_campaign',
                ],
              });
              this.props.setDepotSelectType(resp.depotFilterType);
              this.props.setUserWholesaler(resp.wholesaler);
              this.props.setUserSystem(this.setSystem());
              localStorage.setItem('system', this.setSystem());
              this.props.history.push(`${process.env.APP_ROOT}dashboard`);
              // Set Sales in permissions

              localStorage.setItem(
                'salesIn_permissions',
                JSON.stringify(
                  this.props.theme.name === 'countryRangeGroup' ? true : false,
                ),
              );
            });
          });
        }
      })
      .catch(err => {
        toast.info(err);
      });
  };

  render() {
    return (
      <form action="#" onSubmit={this.sendLogin}>
        <div className="form-group row">
          <div className="col-sm-12">
            <input
              className="form-control"
              ref={input => {
                this.username = input;
              }}
              id="inputUser"
              type="email"
              name="username"
              placeholder="Username"
              tabIndex="1"
              required
              autoFocus
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12">
            <input
              className="form-control"
              ref={input => {
                this.pass = input;
              }}
              id="inputPass"
              type="password"
              name="password"
              placeholder="Password"
              tabIndex="2"
              required
            />
          </div>
        </div>
        <div>
          <input
            ref={input => {
              this.csrf = input;
            }}
            type="hidden"
            name="__csrf_magic"
            value={this.state.csrf}
            tabIndex="-1"
          />
        </div>

        <div className="form-group row">
          <div className="col-sm-12">
            <div className="pull-left">
              <a tabIndex="4" href="/forgot_password">
                Lost password?
              </a>
            </div>
            <div>
              <button
                type="submit"
                className="btn alchemy-btn--login"
                tabIndex="3">
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  alertAdd: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  setDepotSelectType: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuOptions: data => dispatch(setMenuOptions(data)),
  };
};

export default withTheme(connect(null, mapDispatchToProps)(LoginForm));
