import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { supplierEnagagementSupplierBrandTable as explanation } from '../../Explainer/Explanations';
import { coloredTableTheme } from '../../../../config/tableStyles';
import { formatToCurrency } from '../../../../helpers/helpers';
import StyledContainer from './StyledContainer';

const StyledTitle = styled.h2`
  &&& {
    font-size: 1.125rem !important;
    padding: 1rem;
  }
`;

const { tableHeaders } = explanation;

const BrandSupplierTable = ({ data, title }) => {
  const columns = [
    {
      selector: 'Brand',
      name: <TableHeaderTooltip text="Brand" />,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'SalesTY',
      name: (
        <TableHeaderTooltip
          text="Sales TY"
          explanationTooltip={tableHeaders.salesTY}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.SalesTY, 0)}`,
      sortFunction: (rowA, rowB) => rowA.SalesTY - rowB.SalesTY,
    },
    {
      selector: 'ActualDifference',
      name: (
        <TableHeaderTooltip
          text="Actual Difference"
          explanationTooltip={tableHeaders.actualDifference}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ActualDifference < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {formatToCurrency(row.ActualDifference, 0)}
        </div>
      ),
      sortFunction: (rowA, rowB) =>
        rowA.ActualDifference - rowB.ActualDifference,
    },
    {
      selector: 'PerChange',
      name: (
        <TableHeaderTooltip
          text="Change"
          explanationTooltip={tableHeaders.change}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.PerChange < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {parseFloat(row.PerChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.PerChange - rowB.PerChange,
    },
    {
      selector: 'ShareTY',
      name: (
        <TableHeaderTooltip
          text="Supplier Share TY"
          explanationTooltip={tableHeaders.supplierShareTY}
        />
      ),
      sortable: true,
      format: row => `${parseFloat(row.ShareTY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.ShareTY - rowB.ShareTY,
    },
  /*  {
      selector: 'ShareLY',
      name: (
        <TableHeaderTooltip
          text="Supplier Share LY"
          explanationTooltip={tableHeaders.supplierShareLY}
        />
      ),
      sortable: true,
      format: row => `${parseFloat(row.ShareLY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.ShareLY - rowB.ShareLY,
    },*/
    {
      selector: 'ShareChange',
      name: (
        <TableHeaderTooltip
          text="Share % Point Diff"
          explanationTooltip={tableHeaders.supplierPointChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ShareChange < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {parseFloat(row.ShareChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.ShareChange - rowB.ShareChange,
    },
  ];
  return (
    <StyledContainer>
      <StyledTitle className="text-uppercase">{title}</StyledTitle>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'ActualDifference'}
        defaultSortAsc={false}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default BrandSupplierTable;
