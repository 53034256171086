export default {
  AdvertName: '',
  Type: '',
  Title: '',
  Text: '',
  TargetType: '',
  Target: '',
  Status: '',
  DisplayFrom: '',
  DisplayTo: '',
  Page: '',
  Position: '',
  DisplayOrder: '',
  Filter1: '',
  Filter2: '',
  Filter3: '',
  AssistUrl: '',
  UnquieKey: '',
  Updated: ''
}
