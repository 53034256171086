import React, { Component } from 'react';
import AlchContextMenu from '../components/AlchUI/AlchContextMenu';
import TableSimple from '../components/TableSimple';

import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import pageStandard from '../components/wrappers/pageStandard';

import { API_ENDPOINTS } from '../../AlchemySettings';
import { parseDistributionData } from '../../helpers/apiMiddleware';

import { getRandomNumber, exportTableToCSV } from '../../helpers/helpers';

const { customReportData } = API_ENDPOINTS;
class DistributionPage extends Component {
  state = {
    viewData: {
      table: {},
    },

    viewOptions: {
      c: 'customReporting/quarterlyQtyAndCustCount', // Hardcoded Option
    },
  };

  setPageConfig = () => {
    const baseURL = {
      root: undefined,
    };
    const dataURL = {
      root: customReportData,
    };
    const middleware = {
      // base: { root: JSON.parse },
      data: {
        root: parseDistributionData,
        requests: undefined,
      },
    };

    return {
      baseURL,
      dataURL,
      middleware,
    };
  };

  componentDidMount() {
    this.setDefaults();
    this.updateViewData();
  }

  refreshPage() {
    this.setDefaults();
  }

  setDefaults() {
    this.setViewOption('customReporting/quarterlyQtyAndCustCount', 'c');
  }

  exportData = (type) => {
    if (type === 'csv') {
      exportTableToCSV($('.c-table'), `${getRandomNumber('export_')}.csv`);
    }
  };

  render() {
    return (
      <motion.div key={'DISTREPORTID123415151'} initial="initial" animate="in" exit="out" variants={pageVariants}>
        <div className="o-canvas-wrap">
          <div className="o-canvas">
            <div className="o-canvas__header u-layout u-layout--between">
              <div className="o-canvas__header-item u-layout u-layout--between-base">
                <h2 className="o-canvas__title">Distribution</h2>
              </div>
              <div className="o-canvas__header-item">
                <AlchContextMenu label="Export" onSelect={this.exportData}>
                  {[{ id: 'csv', label: 'CSV' }]}
                </AlchContextMenu>
              </div>
            </div>
            <div className="o-canvas__body u-layout u-layout--spaced t-brand-a">
              <div className="u-1_1">
                <TableSimple data={this.state.viewData.table} className="c-objects-wrap--sticky" tableClassName="c-table c-table--standard" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default pageStandard(DistributionPage);
