import React from 'react';
import PropTypes from 'prop-types';

class AlertMessage extends React.Component {
	state = {
		hovered: false,
	}

	componentDidMount = () => {
		if (this.props.alert.status !== 'open') { return; }
		this.timeToClose = this.props.alert.timeToClose;
		this.countdownInterval = setInterval(this.timeToCloseCountdown, 1000);
	}

	componentWillUnmount = ()=>{
		clearInterval(this.countdownInterval);
	}

	timeToCloseCountdown = ()=> {
		if (this.state.hovered) return;
		this.timeToClose -= 1000;
		if (!this.timeToClose > 0) {
			this.closeAlert();
		}
	}

	closeAlert = ()=> {
		this.props.alertClose(this.props.alert.id);
		clearInterval(this.countdownInterval);
	}

	render() {
		const alertClass = `c-alert c-alert--${this.props.alert.type}`;
		return (
			<aside
				className={alertClass}
				onMouseEnter={()=>{ this.setState({ hovered: true }); }}
				onMouseLeave={()=>{ this.setState({ hovered: false }); }}
			>
				{this.props.alert.text}
				<button className="c-alert__close" onClick={this.closeAlert}>Close</button>
			</aside>
		);
	}
}

AlertMessage.propTypes = {
	alert: PropTypes.shape({
		id: PropTypes.number,
		type: PropTypes.string.isRequired,
		timeToClose: PropTypes.number,
		status: PropTypes.string,
		text: PropTypes.string.isRequired,
	}).isRequired,
	alertClose: PropTypes.func.isRequired,
};

export default AlertMessage;
