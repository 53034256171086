import React from 'react';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import * as _ from 'lodash';
import { getUserSystem, hasPermission } from '../../../helpers/util';
import { Route, Switch, Redirect } from 'react-router-dom';
import { dateToYearWeek, dateToString } from '../../../helpers/helpers';
import Step1 from '../QueryBuilder/step_1';
import Step2 from '../QueryBuilder/step_2';
import Step3 from '../QueryBuilder/step_3';
import Step4 from '../QueryBuilder/step_4';
import QueryHistory from '../QueryBuilder/query_history';
import WeeklyUpdate from '../QueryBuilder/weekly_update';
import QuerySchedule from '../QueryBuilder/query_schedule';
import QueryScheduled from '../QueryBuilder/query_scheduled';
import {
  onUpdateQueryFieldVisibility,
  resetQueryBuilder,
  onRemoveDateRange,
  onAddDateRanges,
  onAddWholesaler,
  onUpdateField,
  fetchCategories,
  onSaveFilter,
  onSaveReorderColumns,
  onSaveReorderData,
  onPivotChange,
  onUpdateDateRange,
  fetchProducts,
} from '../../redux/reducers/query';
import { fetchAccountNames } from '../../redux/reducers/filters';

const setDefaultQueryPage = () => {
  const user = getUserSystem().toLowerCase();

  if (hasPermission(`role_view_${user}_query_builder`)) {
    return 'query/1';
  } else if (hasPermission(`role_view_${user}_weekly_update`)) {
    return 'query/weekly_update';
  } else {
    return 'query/no-permissions';
  }
};

const Query = ({
  alertAdd,
  updateField,
  saveReorderColumns,
  saveReorderData,
  addPivotValue,
  saveFilter,
  menuToggle,
  removeDateRange,
  addDateRanges,
  addWholesaler,
  onSearchProducts,
  updateDateRange,
  updateQueryFieldVisibility,
  accountNames,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'spring', duration: 0.5 }}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch>
          <Route
            path={`${process.env.APP_ROOT}query/1`}
            render={pageProps => (
              <Step1
                {...pageProps}
                theme={theme}
                updateQueryFieldVisibility={updateQueryFieldVisibility}
                updateDateRange={updateDateRange}
                updateField={updateField}
                addDateRanges={addDateRanges}
                alertAdd={alertAdd}
                removeDateRange={removeDateRange}
                addWholesaler={addWholesaler}
              />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}query/2`}
            render={pageProps => (
              <Step2
                {...pageProps}
                theme={theme}
                updateQueryFieldVisibility={updateQueryFieldVisibility}
                updateDateRange={updateDateRange}
                menuToggle={menuToggle}
                updateField={updateField}
                saveReorderColumns={saveReorderColumns}
                saveReorderData={saveReorderData}
                addPivotValue={addPivotValue}
                saveFilter={saveFilter}
                alertAdd={alertAdd}
                onSearchProducts={onSearchProducts}
                accountNames={accountNames}
              />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}query/3`}
            render={pageProps => (
              <Step3 {...pageProps} theme={theme} alertAdd={alertAdd} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}query/4`}
            render={pageProps => (
              <Step4 {...pageProps} theme={theme} alertAdd={alertAdd} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}query/history`}
            render={pageProps => <QueryHistory {...pageProps} theme={theme} />}
          />
          <Route
            path={`${process.env.APP_ROOT}query/weekly_update`}
            render={pageProps => <WeeklyUpdate {...pageProps} theme={theme} />}
          />
          <Route
            path={`${process.env.APP_ROOT}query/schedule`}
            render={pageProps => <QuerySchedule {...pageProps} theme={theme} />}
          />
          <Route
            path={`${process.env.APP_ROOT}query/scheduled`}
            render={pageProps => (
              <QueryScheduled {...pageProps} theme={theme} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}query/no-permissions`}
            render={pageProps => <p>You do not have any permissions set, please ask your administrator for access.</p>}
          />
          <Redirect
            from={`${process.env.APP_ROOT}query`}
            to={`${process.env.APP_ROOT}${setDefaultQueryPage()}`}
          />
        </Switch>
      </AnimatePresence>
    </motion.div>
  );
};
function mapStateToProps(state) {
  let data = {
    isLoading: state.query.isLoading,
    defaultDateRange: state.query.defaultDateRange,
    builtQuery: state.query.builtQuery,
    accountNames: state.filters.accountNames,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    onUpdateQueryFieldVisibility,
    onAddDateRanges,
    onRemoveDateRange,
    onAddWholesaler,
    onUpdateField,
    fetchCategories,
    onSaveFilter,
    onSaveReorderColumns,
    onSaveReorderData,
    onPivotChange,
    onUpdateDateRange,
    resetQueryBuilder,
    fetchProducts,
    fetchAccountNames,
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetQueryBuilder();
      this.unlisten = this.props.history.listen((location, action) => {
        if (location.pathname === '/query') {
          this.props.resetQueryBuilder();
        }
      });
    },
    componentDidUpdate(prevProps) {},
    componentWillUnmount() {
      this.unlisten();
      // Reset on unmount
      this.props.resetQueryBuilder();
    },
  }),
  withState('data', 'handleData', {}),
  withHandlers({
    updateQueryFieldVisibility:
      ({ data, handleData, builtQuery, onUpdateQueryFieldVisibility }) =>
      (...args) => {
        let tempPivotObject = {};
        if (typeof args[0] === 'object') {
          const updatedMeasures = [];
          let columnOrder = [...builtQuery.Column_Order];
          let dataColumnOrder = [...builtQuery.Pivot_Table.columns];
          let dataValueOrder = [...builtQuery.Pivot_Table.values];
          let dataRowOrder = [...builtQuery.Pivot_Table.rows];
          let columnOrderMultiple;
          args[0].forEach(measure => {
            const { key, value, label, name } = measure;
            if (!key) {
              return;
            }
            const newVal = value ? 1 : 0;
            // Add or remove from column order
            if (!value) {
              // Remove Measure from Column Order
              columnOrder = _.filter(columnOrder, col => {
                const colName = col.resultset;
                if (colName) {
                  return colName !== name;
                }
              });
              dataColumnOrder = _.filter(dataColumnOrder, col => {
                const colName = col.resultset;
                if (colName) {
                  return colName !== name;
                }
              });
              dataValueOrder = _.filter(dataValueOrder, value => {
                const valName = value.resultset;
                if (valName) {
                  return valName !== name;
                }
              });
              dataRowOrder = _.filter(dataRowOrder, row => {
                const rowName = row.resultset;
                if (rowName) {
                  return rowName !== name;
                }
              });
            } else {
              if (
                label === 'value' ||
                label === 'volume' ||
                label === 'customer count' ||
                label === 'volume-hectolitres' ||
                label === 'volume-9l' ||
                label === 'volume-stick' ||
                label === 'volume-grams' ||
                label === 'value in' ||
                label === 'volume in'
              ) {
                _.each(builtQuery.newDateRanges, (dateRange, index) => {
                  columnOrder.push({
                    label: `${label}-${
                      dateRange.label === 'custom'
                        ? dateRange.label + (index + 1)
                        : dateRange.label
                    }`,
                    name: `${name}-${dateRange.startWeek}-${dateRange.endWeek}`,
                    sort_name: `ord_${key}_${dateRange.id}`,
                    resultset: key,
                  });
                });
              } else {
                columnOrder.push({
                  label,
                  name,
                  sort_name: `ord_${key}`,
                  resultset: key,
                });
              }
            }
            const updatedMeasure = { newVal, key };
            columnOrderMultiple = columnOrder;
            tempPivotObject.rows = dataRowOrder;
            tempPivotObject.values = dataValueOrder;
            tempPivotObject.columns = dataColumnOrder;
            updatedMeasures.push(updatedMeasure);
          });
          onUpdateQueryFieldVisibility({
            updatedValues: [...updatedMeasures],
            columnOrder: columnOrderMultiple,
            pivotObject: tempPivotObject,

          });
        } else {
          const key = args[0];
          const value = args[1];
          const label = args[2];
          const name = args[3];
          if (!key) {
            return;
          }
          const newVal = value ? 1 : 0;
          // Add or remove from column order
          let columnOrder = [...builtQuery.Column_Order];
          let dataColumnOrder = [...builtQuery.Pivot_Table.columns];
          let dataValueOrder = [...builtQuery.Pivot_Table.values];
          let dataRowOrder = [...builtQuery.Pivot_Table.rows];
          if (!value) {
            // Remove Measure from Column Order
            columnOrder = _.filter(columnOrder, col => {
              const colName = col.name.split('-')[0];
              if (colName) {
                return colName !== name;
              }
            });
            dataColumnOrder = _.filter(dataColumnOrder, col => {
              const colName = col.name.split('-')[0];
              if (colName) {
                return colName !== name;
              }
            });
            dataValueOrder = _.filter(dataValueOrder, value => {
              const valName = value.name.split('-')[0];
              if (valName) {
                return valName !== name;
              }
            });
            dataRowOrder = _.filter(dataRowOrder, row => {
              const rowName = row.name.split('-')[0];
              if (rowName) {
                return rowName !== name;
              }
            });

          } else {
            if (
              label === 'value' ||
              label === 'volume' ||
              label === 'customer count' ||
              label === 'volume-hectolitres' ||
              label === 'volume-9l' ||
              label === 'volume-stick' ||
              label === 'volume-grams' ||
              label === 'value in' ||
              label === 'volume in'
            ) {
              _.each(builtQuery.newDateRanges, (dateRange, index) => {
                columnOrder.push({
                  label: `${label}-${
                    dateRange.label === 'custom'
                      ? dateRange.label + (index + 1)
                      : dateRange.label
                  }`,
                  name: `${name}-${dateRange.startWeek}-${dateRange.endWeek}`,
                  sort_name: `ord_${key}_${dateRange.id}`,
                  resultset: key,
                });
              });
            } else {
              columnOrder.push({
                label,
                name,
                sort_name: `ord_${key}`,
                resultset: key,
              });
            }
          }
          tempPivotObject.rows = dataRowOrder;
          tempPivotObject.values = dataValueOrder;
          tempPivotObject.columns = dataColumnOrder;
          onUpdateQueryFieldVisibility({
            columnOrder,
            updatedValues: [{ newVal, key }],
            pivotObject: tempPivotObject,
          });
        }
      },
    removeDateRange:
      ({ onRemoveDateRange, builtQuery }) =>
      id => {
        builtQuery.Column_Order = builtQuery.Column_Order.filter(
          ord => ord.sort_name.indexOf(id) < 0,
        );
        builtQuery.newDateRanges = builtQuery.newDateRanges.filter(
          dateRange => dateRange.id !== id,
        );
        onRemoveDateRange({ builtQuery });
      },
    addDateRanges:
      ({ builtQuery, defaultDateRange, onAddDateRanges }) =>
      () => {
        let columnOrder = [...builtQuery.Column_Order];
        let label = '';
        let name = '';
        let newDateRange = { ...defaultDateRange };
        newDateRange.id = +new Date(); //update new id here
        if (builtQuery.Show_Value) {
          label = 'value';
          name = 'Value';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_Volume) {
          label = 'volume';
          name = 'Volume';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_QtyHectolitres) {
          label = 'volume-hectolitres';
          name = 'QtyHectolitres';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_Qty9L) {
          label = 'volume-9l';
          name = 'Qty9L';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_QtyStick) {
          label = 'volume-stick';
          name = 'QtyStick';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_QtyGrams) {
          label = 'volume-grams';
          name = 'QtyGrams';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        if (builtQuery.Show_CustomerCount) {
          label = 'customer count';
          name = 'CustomerCount';
          columnOrder.push({
            label: `${label}-${
              newDateRange.label === 'custom'
                ? newDateRange.label + (index + 1)
                : newDateRange.label
            }`,
            name: `${name}-${newDateRange.startWeek}-${newDateRange.endWeek}`,
            sort_name: `ord_${name}_${newDateRange.id}`,
            resultset: name,
          });
        }
        onAddDateRanges({
          dateRange: newDateRange,
          columnOrder,
        });
      },
    addWholesaler:
      ({ onAddWholesaler }) =>
      option => {
        onAddWholesaler({
          option,
        });
      },
    updateField:
      ({ onUpdateField, fetchCategories }) =>
      (key, value) => {
        if (key === 'Show_Competitor') {
          fetchCategories(value);
        }
        onUpdateField({
          key,
          value,
        });
      },
    saveFilter:
      ({ onSaveFilter }) =>
      (filters, name) => {
        onSaveFilter({
          filters,
          name,
        });
      },
    saveReorderColumns:
      ({ onSaveReorderColumns }) =>
      newColumnOrder => {
        onSaveReorderColumns({
          newColumnOrder,
        });
      },
    saveReorderData:
      ({ onSaveReorderData, builtQuery }) =>
      (target, newColumnOrder) => {
        let pivotObject = builtQuery.Pivot_Table;
        pivotObject[target] = [...newColumnOrder];
        onSaveReorderData(pivotObject);
      },
      addPivotValue: ({onPivotChange}) => (pivotObject) => {
        onPivotChange(pivotObject);
      },
    updateDateRange:
      ({ onUpdateDateRange }) =>
      (date, index) => {
        const startWeek = dateToYearWeek(date.start);
        const endWeek = dateToYearWeek(date.end);
        const startDate = dateToString(date.start);
        const endDate = dateToString(date.end);
        onUpdateDateRange({
          label: date.label,
          start: startDate,
          end: endDate,
          startWeek: startWeek,
          endWeek: endWeek,
          index,
        });
      },
    onSearchProducts:
      ({ fetchProducts }) =>
      (keyword, filterType) => {
        if (keyword.length > 3) {
          fetchProducts(keyword, filterType);
        }
      },
  }),
)(Query);
