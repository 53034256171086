import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-select/dist/react-select.css';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setSelectedProduct,
  setMeasure,
  resetReport,
  loadKPIData,
} from '../../redux/reducers/retail';
import { ToastContainer } from 'react-toastify';
import {
  getUserSystem,
} from '../../../helpers/util';
import Loading from '../../components/Loading';
import { motion } from 'framer-motion';
import { pageVariants } from '../../../config/pageVariants';
import ReportContainer from '../../components/ReportContainer';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import TablePerformance from '../../components/TablePerformance';

const AnalyticsKpi = ({
  timeframe,
  timeframes,
  measure,
  measures,
  onMeasureChange,
  fetchCategories,
  loadKPIData,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  selectedLevel4s,
  level4s,
  onLevel5Change,
  selectedLevel5s,
  level5s,
  onProductChange,
  selectedProduct,
  products,
  isRefreshDisabled,
  location,
  onResetReport,
  onRefreshReport,
  isLoaded,
  kpiData,
}) => {
  useEffect(() => {
    fetchCategories();
    
    loadKPIData();
    return () => onUnmount();
  }, []);

  return (
    <motion.div
      key={'kpi'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
      />
      <ReportSettingsContainer
        refresh={{
          refreshHandler: () =>
            onRefreshReport(),
          isRefreshDisabled: isRefreshDisabled,
        }}
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        title={'Analytics KPI Dashboard'}
        dropdowns={[
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          { text: 'By: ' },
          {
            name: 'measure',
            valueKey: 'ID',
            labelKey: 'Text',
            value: measure,
            options: measures,
            handler: option => onMeasureChange(option),
          },
        ]}>
        {isLoaded && (
          <DataDropdownsContainer
            retail={false}
            dropdowns={[
              {
                name: 'departments',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedDepartments,
                options: departments,
                handler: option => onDepartmentChange(option),
                isMulti: true,
              },
              {
                name: 'categories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedCategories,
                options: categories,
                handler: option => onCategoryChange(option),
                isMulti: true,
              },
              {
                name: 'subCategories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSubCategories,
                options: subCategories,
                handler: option => onSubCategoryChange(option),
                isMulti: true,
              },
              {
                name: 'level4',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel4s,
                options: level4s,
                handler: option => onLevel4Change(option),
                isMulti: true,
              },
              {
                name: 'level5',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel5s,
                options: level5s,
                handler: option => onLevel5Change(option),
                isMulti: true,
              },
              {
                name: 'Product',
                valueKey: 'WVProdCode',
                labelKey: 'WVProdDesc',
                value: selectedProduct,
                options: products,
                handler: option => onProductChange(option),
                isMulti: false,
              },
              {
                name: 'Brands',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedBrands,
                options: brands,
                handler: option => onBrandChange(option),
                isMulti: true,
              },
              {
                name: 'Suppliers',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSuppliers,
                options: suppliers,
                handler: option => onSupplierChange(option),
                isMulti: true,
              },
            ]}
          />
        )}
      </ReportSettingsContainer>
      <ReportContainer padding={3} display="block">
        <div className={'container'}>
          {
            isLoaded
              ? DataTables(kpiData, isLoaded, measure) 
              : <Loading /> 
          }
        </div>
      </ReportContainer>
    </motion.div>
  );
};

const DataTables = (data, isLoaded, measure) =>
  <div style={{overflow: 'auto'}}>
      <div className='kpi-data row'>
        {data.map((tableData, index) => 
          <div key={`table-${index}`} className='col-6 mb-4'>
            <h3 className='mb-2'>{tableData.title}</h3>
            <div className="nam-dash nam-dash-table">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: 'spring', duration: 0.5 }}>
                <TablePerformance
                  id={"kpi-table" + tableData.title}
                  reportType={'category'}
                  data={tableData.table}
                  titleOverride={tableData.keys}
                  measure={measure}
                  isLoading={!isLoaded}
                  isDrill={false}
                  />
              </motion.div>
            </div>
          </div>
        )}
      </div>
  </div>

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategories: state.retail.selectedSubCategories,
  timeframes: state.retail.timeframes,
  suppliers: state.retail.suppliers,
  selectedSuppliers: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrands: state.retail.selectedBrands,
  selectedLevel4s: state.retail.selectedLevel4s,
  level4s: state.retail.level4s,
  selectedLevel5s: state.retail.selectedLevel5s,
  level5s: state.retail.level5s,
  selectedProduct: state.retail.selectedProduct,
  products: state.retail.products,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  kpiData: state.retail.kpiData,
  isLoaded: state.retail.isLoaded,
  measures: state.retail.measures,
  measure: state.retail.measure,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    loadKPIData: () => dispatch(loadKPIData()),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onProductChange: option => dispatch(setSelectedProduct(option)),
    onMeasureChange: option => dispatch(setMeasure(option.ID)),
    onResetReport: () => {
      dispatch(resetReport());
      dispatch(
        fetchCategories(),
      );
    },
    onRefreshReport: () => dispatch(loadKPIData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsKpi);