import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { AnimatePresence } from 'framer-motion';
import initHelpHero from 'helphero';
import 'react-image-gallery/styles/css/image-gallery.css';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Dashboard from './Dashboard';
import NamDash from './SectionWrappers/NamDash';
import Retail from './SectionWrappers/Retail';
import LikeForLike from './SectionWrappers/LikeForLike';
import Admin from './SectionWrappers/Admin';
import Support from './SectionWrappers/Support';
import ContactUs from './SectionWrappers/ContactUs';
import PlanForProfit from './SectionWrappers/PlanForProfit';
import Analysis from './SectionWrappers/Analysis';
import Query from './SectionWrappers/Query';
import PSA from './SectionWrappers/PSA';
import CreateUser from './SectionWrappers/CreateUser';
import ListUsers from './ListUsers';
import QAReport1 from './QAReport1';
import QAReport2 from './QAReport2';
import QAReport3 from './QAReport3';
import QAReport4 from './QAReport4';
import {
  getUserId,
  getUserRoles,
  getUserSystem,
  setShowQBPopUp,
} from '../../helpers/util';
import CustomerComplianceReport from './CustomerComplianceReport';
import BarcodeComplianceReport from './BarcodeComplianceReport';
import TopNav from '../components/navigations/TopNav';
import BottomNav from '../components/navigations/BottomNav';
import {
  getMenus,
  getSubPageNav,
  setBrand,
  setSubPageNav,
  fetchMembers,
  getTimeLabel,
} from '../redux/reducers/app';

import { fetchBusinessTypes } from '../redux/reducers/filters';
import { showLogoutScreen } from '../redux/reducers/user';
import { resetQueryBuilder } from '../redux/reducers/query';
import { getSubmenuPages } from '../../helpers/api';
import { filter } from 'lodash';

import queryBuilderImage from '../../assets/qb/query_builder.png';

let help = initHelpHero('4GgzMsn8Pp');

const SystemWrap = props => (
  <div className={props.wrapperClasses.join(' ')}>
    {props.data.showQBPopUp && (
      <div className="setup-wizard">
        <div className="wizard-outer">
          <div className="wizard">
            <div className="holder">
              <div className="d">
                {/* <ImageGallery items={props.data.queryBuilderImage} infinite={false} showPlayButton={false} showFullscreenButton={false} showThumbnails={false} /> */}
                <button
                  onClick={props.onQBGoToApp}
                  className={`btn alchemy-btn--${
                    getUserSystem() === 'unitas' ? 'unitas' : 'others'
                  }`}>
                  PROCEED TO APP <i className="fa fa-arrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {props.location.pathname.indexOf('dashboard') < 0 && (
      <TopNav
        toggleOffscreen={props.toggleOffscreen}
        systemSettings={props.systemSettings}
        setDepotSelectFilterData={props.setDepotSelectFilterData}
        menuToggle={props.menuToggle}
        currentPage={props.match.params.page}
        alertAdd={props.alertAdd}
        isUploadDetails={
          props.location.pathname.indexOf('upload-details') >= 0 ? true : false
        }
        logout={props.logout}
        showBlur={props.showBlur}
        showAlert={props.statusReportExists}
        logoutUser={props.logoutUser}
        label={props.timeLabel}
        showQBPopUp={val => props.showQBPopUp(val)}
        goToURL={url => {
          props.history.push(url);
        }}
        {...props}
      />
    )}
    {(props.location.pathname.indexOf('/psa/') >= 0 ||
      props.location.pathname.indexOf('/query/') >= 0 ||
      props.location.pathname.indexOf('/nam_dash/') >= 0 ||
      props.location.pathname.indexOf('/analysis/') >= 0 ||
      props.location.pathname.indexOf('/retail/') >= 0) &&
    props.location.pathname.indexOf('upload-details') < 0 ? (
      <BottomNav
        showBlur={props.showBlur}
        resetQueryBuilder={props.resetQueryBuilder}
        links={props.subpage_nav}
        currentUrl={props.location.pathname}
        brand={props.brand}
        isOwnCategoryStructure={props.isOwnCategoryStructure}
      />
    ) : (
      ''
    )}
    <AnimatePresence key="main-component-animatepresence" initial={false}>
      <Switch>
        <Route
          path={`${process.env.APP_ROOT}dashboard`}
          render={pops => <Dashboard {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}psa`}
          render={pops => <PSA {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}nam_dash`}
          render={pops => <NamDash {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}analysis`}
          render={pops => <Analysis {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}admin`}
          render={pops => <Admin {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}pfp`}
          render={pops => <PlanForProfit {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}support`}
          render={pops => <Support {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}contact-us`}
          render={pops => <ContactUs {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}likeforlike`}
          render={pops => <LikeForLike {...props} {...pops} />}
        />
        <Route
          path={`${process.env.APP_ROOT}query`}
          render={pops => (
            <Query
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}create_user`}
          render={pops => (
            <CreateUser
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}list_users`}
          render={pops => (
            <ListUsers
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}qa_report`}
          render={pops => (
            <QAReport1
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}qa_report_days_out`}
          render={pops => (
            <QAReport2
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}qa_report_days_in`}
          render={pops => (
            <QAReport3
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}qa_report_weeks_in`}
          render={pops => (
            <QAReport4
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}customer_compliance_report`}
          render={pops => (
            <CustomerComplianceReport
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}barcode_compliance_report`}
          render={pops => (
            <BarcodeComplianceReport
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
            />
          )}
        />
        <Route
          path={`${process.env.APP_ROOT}retail`}
          render={pops => (
            <Retail
              {...props}
              {...pops}
              menuToggle={props.menuToggle}
              alertAdd={props.alertAdd}
              menus={props.menus}
            />
          )}
        />
      </Switch>
    </AnimatePresence>
  </div>
);
function mapStateToProps(state, ownProps) {
  const offscreenOpen = ownProps.systemSettings.offscreenOpen || [];
  const wrapperClasses = ['o-alchemy', 'has-offscreen'];

  offscreenOpen.forEach(offItem =>
    wrapperClasses.push(`has-offscreen--${offItem}-open`),
  );
  let data = {
    menus: state.app.menus,
    brand: state.app.brand,
    timeLabel: state.app.timeLabel,
    statusReportExists: state.likeForLike.statusReportExists,
    subpage_nav: state.app.subpage_nav,
    showBlur: state.app.showBlur,
    showWeeklyUpdate: state.app.showWeeklyUpdate,
    wrapperClasses,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    getMenus,
    setSubPageNav,
    setBrand,
    getSubPageNav,
    resetQueryBuilder,
    fetchMembers,
    getTimeLabel,
    showLogoutScreen,
    fetchBusinessTypes,
  }),
  lifecycle({
    componentDidMount() {
      help.identify(getUserId());
      this.props.fetchBusinessTypes();
      if (this.props.location.pathname.indexOf('forgot_password') < 0) {
        this.props.fetchMembers();
        this.props.getTimeLabel();
        const menus = this.props.getMenus();
        if (this.props.location.pathname.indexOf('/nam_dash/') >= 0) {
          getSubmenuPages('namdash')
            .then(data => {
              data.map(item => {
                switch (item.id) {
                  case '1':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'category_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/category_performance/`;
                    }
                    break;
                  case '3':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'supplier_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/supplier_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '4':
                    if (
                      filter(menus.namdash, opt => opt === 'brand_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/brand_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '5':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'wholesaler_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/wholesaler_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '5.5':
                    if (
                      filter(menus.namdash, opt => opt === 'group_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/group_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '6':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'product_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/product_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '7':
                    if (
                      filter(menus.namdash, opt => opt === 'region_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/region_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '8':
                      if (
                        filter(menus.namdash, opt => opt === 'fascia_performance')
                          .length > 0
                      ) {
                        item.link = `${process.env.APP_ROOT}nam_dash/fascia_performance/`;
                        item.icon = require('../../assets/images/icons/line.png');
                      }
                      break;
                  case '9':
                    if (
                      filter(menus.namdash, opt => opt === 'sku_tracker')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/sku_tracker/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '10':
                    if (
                      filter(menus.namdash, opt => opt === 'product_finder')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/product_finder/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '11':
                    if (
                      filter(menus.namdash, opt => opt === 'best_sellers')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/best_sellers/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '12':
                    if (
                      filter(menus.namdash, opt => opt === 'depot_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/depot_performance/`;
                    }
                    break;
                  case '13':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'business_type_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/business_type_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '14':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'supplier_engagement',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/supplier_engagement_report`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '15':
                    if (
                      filter
                        (menus.namdash,
                          opt => opt === 'kpi_dashboard'
                        ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/kpi`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '16':
                    if (
                      filter
                        (menus.namdash,
                          opt => opt === 'download_cube'
                        ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/download_cube`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  default:
                    item.link = `${process.env.APP_ROOT}nam_dash/`;
                }
                return item;
              });

              const subNav = filter(data, itm => itm.link !== undefined);
              if (
                filter(subNav, nav => {
                  if (nav.link === this.props.location.pathname) {
                    return nav;
                  }
                }).length === 0
              ) {
                this.props.history.push(process.env.APP_ROOT);
              }

              this.props.setSubPageNav(subNav);
            })
            .catch(err => {
              this.props.alertAdd(err, 'error');
            });
        } else if (this.props.location.pathname.indexOf('/analysis/') >= 0) {
          getSubmenuPages('analysis')
            .then(data => {
              data.map(item => {
                switch (item.id) {
                  case '1':
                    if (
                      filter(menus.analysis, opt => opt === 'price_sensitivity')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/price_sensitivity/`;
                      item.icon = require('../../assets/images/icons/bar-chart.png');
                    }
                    break;
                  case '2':
                    if (
                      filter(menus.analysis, opt => opt === 'area_heatmap')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/area_heatmap/`;
                      item.icon = require('../../assets/images/icons/earth.png');
                    }
                    break;
                  case '3':
                    if (
                      filter(menus.analysis, opt => opt === 'depot_scorecard')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/depot_scorecard/`;
                      item.icon = require('../../assets/images/icons/scorecard.png');
                    }
                    break;
                  case '4':
                    if (
                      filter(menus.analysis, opt => opt === 'bought_not_bought')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/bought_not_bought/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;
                  case '5':
                    if (
                      filter(
                        menus.analysis,
                        opt => opt === 'stocked_not_stocked',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/stocked_not_stocked/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;
                  case '6':
                    if (
                      filter(menus.analysis, opt => opt === 'gap_analysis')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/gap_analysis/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;

                  default:
                    item.link = process.env.APP_ROOT;
                }
                return item;
              });

              const subNav = filter(data, itm => itm.link !== undefined);
              if (
                filter(subNav, nav => {
                  if (nav.link === this.props.location.pathname) {
                    return nav;
                  }
                }).length === 0
              ) {
                this.props.history.push(process.env.APP_ROOT);
              }
              this.props.setSubPageNav(subNav);
            })
            .catch(err => {
              this.props.alertAdd(err, 'error');
            });
        } else if (this.props.location.pathname.indexOf('/query/') >= 0) {
          let subpage_nav = [];
          if (
            filter(menus.queryBuilder, opt => opt === 'query_builder').length >
            0
          ) {
            subpage_nav = [
              {
                id: 1,
                reportName: 'Query Builder',
                link: `${process.env.APP_ROOT}query/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
              {
                id: 2,
                reportName: 'Query History',
                link: `${process.env.APP_ROOT}query/history/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
              {
                id: 3,
                reportName: 'Query Scheduler',
                link: `${process.env.APP_ROOT}query/scheduled/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
            ];
          }
          if (
            this.props.showWeeklyUpdate &&
            filter(menus.queryBuilder, opt => opt === 'weekly_update').length >
              0
          ) {
            subpage_nav.push({
              id: 4,
              reportName: 'Key Facts Report',
              link: `${process.env.APP_ROOT}query/weekly_update/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            });
          }
          const value = this.props.location.pathname.match(/\/(.*)\//).pop();
          if (
            filter(subpage_nav, nav => {
              if (
                nav.link === this.props.location.pathname ||
                (value.length > 0 && '/' + value + '/' === nav.link)
              ) {
                return nav;
              }
            }).length === 0
          ) {
            this.props.history.push(process.env.APP_ROOT);
          }
          this.props.setSubPageNav(subpage_nav);
        } else if (this.props.location.pathname.indexOf('/psa/') >= 0) {
          const subpage_nav = [
            {
              id: 1,
              reportName: 'Manage Distribution Lists',
              link: `${process.env.APP_ROOT}psa/distribution-lists/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
            {
              id: 2,
              reportName: 'Manage Campaigns',
              link: `${process.env.APP_ROOT}psa/campaigns/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
            {
              id: 3,
              reportName: 'View Campaigns',
              link: `${process.env.APP_ROOT}psa/view-campaigns/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
          ];
          if (
            filter(subpage_nav, nav => {
              if (nav.link + '/' === this.props.location.pathname) {
                return nav;
              }
            }).length === 0
          ) {
            this.props.history.push(process.env.APP_ROOT);
          }
          this.props.setSubPageNav(subpage_nav);
        } else if (this.props.location.pathname.indexOf('/retail/') >= 0) {
          if (menus?.retail?.length > 0) {
            const retailMenuItemsByRole = menus.retail?.map(item => {

              if (item === 'category_penetration_retail') {
                return {
                  reportName: 'Space & Ranging',
                  link: '/retail/category-penetration',
                };
              } else if (item === 'category_performance_matrix_retail') {
                return {
                  reportName: 'Boston Matrix - Categories',
                  link: '/retail/category-performance',
                };
              } else if (item === 'basket_product_analysis_retail') {
                return {
                  reportName: 'Basket Detail',
                  link: '/retail/basket-product-analysis',
                };
              } else if (item === 'basket_overview_retail') {
                return {
                  reportName: 'Basket Overview',
                  link: '/retail/basket-overview',
                };
              } else if (item === 'sales_by_week_retail') {
                return {
                  reportName: 'Sales By Week',
                  link: '/retail/sales-by-week',
                };
              } else if (item === 'sku_performance_retail') {
                return {
                  reportName: 'Sku Performance',
                  link: '/retail/sku-performance',
                };
              } else if (item === 'retailer_performance_retail') {
                return {
                  reportName: 'Boston Matrix - Stores',
                  link: '/retail/retailer-performance',
                };
              } else if (item === 'analytics_overview_dashboard') {
                return {
                  reportName: 'Analytics Overview Dashboard',
                  link: '/retail/analytics-overview',
                };
              } else if (item === 'analytics_kpi_dashboard') {
                return {
                  reportName: 'Analytics KPI Dashboard',
                  link: '/retail/analytics-kpi',
                };
              } else if (item === 'gap_analysis_retail') {
                return {
                  reportName: 'Gap Analysis Retail',
                  link: '/retail/gap-analysis-retail',
                };
              } else if (item === 'basket_category_analysis_retail') {
                return {
                  reportName: 'Basket Analysis',
                  link: '/retail/basket-analysis',
                };
              }
            });
            this.props.setSubPageNav(retailMenuItemsByRole);
          }
        } else {
          const userRoles = getUserRoles();
          if (
            filter(userRoles, role => {
              if (
                role.Title === 'role_system_admin' ||
                role.Title === 'role_wholesaler_admin'
              ) {
                return role;
              }
            }).length === 0 &&
            this.props.location.pathname.indexOf('dashboard') <= 0 &&
            this.props.location.pathname.indexOf('select-supplier') <= 0
          ) {
            this.props.history.push(process.env.APP_ROOT);
          }
        }
      }
    },
    componentDidUpdate(prevProps) {
      help.identify(getUserId());
      const { menus, subpage_nav } = this.props;
      if (
        prevProps.showWeeklyUpdate !== this.props.showWeeklyUpdate &&
        this.props.showWeeklyUpdate &&
        this.props.location.pathname.indexOf('/query/') >= 0 &&
        filter(menus.queryBuilder, opt => opt === 'weekly_update').length > 0
      ) {
        this.props.setSubPageNav([
          ...subpage_nav,
          {
            id: 4,
            reportName: 'Key Facts Report',
            link: `${process.env.APP_ROOT}query/weekly_update/`,
            icon: require('../../assets/images/icons/bar-chart.png'),
          },
        ]);
      }
      if (prevProps.location.pathname !== this.props.location.pathname) {
        if (this.props.location.pathname.indexOf('/nam_dash/') >= 0) {
          getSubmenuPages('namdash')
            .then(data => {
              data.map(item => {
                switch (item.id) {
                  case '1':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'category_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/category_performance/`;
                    }
                    break;
                  case '3':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'supplier_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/supplier_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '4':
                    if (
                      filter(menus.namdash, opt => opt === 'brand_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/brand_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '5':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'wholesaler_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/wholesaler_performance/`;
                      item.icon = require('../../assets/images/icons/pie-detailed.png');
                    }
                    break;
                  case '5.5':
                    if (
                      filter(menus.namdash, opt => opt === 'group_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/group_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '6':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'product_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/product_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '7':
                    if (
                      filter(menus.namdash, opt => opt === 'region_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/region_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '8':
                      if (
                        filter(menus.namdash, opt => opt === 'fascia_performance')
                          .length > 0
                      ) {
                        item.link = `${process.env.APP_ROOT}nam_dash/fascia_performance/`;
                        item.icon = require('../../assets/images/icons/line.png');
                      }
                      break;
                  case '9':
                    if (
                      filter(menus.namdash, opt => opt === 'sku_tracker')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/sku_tracker/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '10':
                    if (
                      filter(menus.namdash, opt => opt === 'product_finder')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/product_finder/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '11':
                    if (
                      filter(menus.namdash, opt => opt === 'best_sellers')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/best_sellers/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '12':
                    if (
                      filter(menus.namdash, opt => opt === 'depot_performance')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/depot_performance/`;
                    }
                    break;
                  case '13':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'business_type_performance',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/business_type_performance/`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '14':
                    if (
                      filter(
                        menus.namdash,
                        opt => opt === 'supplier_engagement',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/supplier_engagement_report`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;  
                  case '15':
                    if (
                      filter
                        (menus.namdash,
                          opt => opt === 'kpi_dashboard'
                        ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/kpi`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  case '16':
                    if (
                      filter
                        (menus.namdash,
                          opt => opt === 'download_cube'
                        ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}nam_dash/download_cube`;
                      item.icon = require('../../assets/images/icons/line.png');
                    }
                    break;
                  default:
                    item.link = `${process.env.APP_ROOT}nam_dash/`;
                }
                return item;
              });

              this.props.setSubPageNav(
                filter(data, itm => itm.link !== undefined),
              );
            })
            .catch(err => {
              this.props.alertAdd(err, 'error');
            });
        } else if (this.props.location.pathname.indexOf('/analysis/') >= 0) {
          getSubmenuPages('analysis')
            .then(data => {
              data.map(item => {
                switch (item.id) {
                  case '1':
                    if (
                      filter(menus.analysis, opt => opt === 'price_sensitivity')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/price_sensitivity/`;
                      item.icon = require('../../assets/images/icons/bar-chart.png');
                    }
                    break;
                  case '2':
                    if (
                      filter(menus.analysis, opt => opt === 'area_heatmap')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/area_heatmap/`;
                      item.icon = require('../../assets/images/icons/earth.png');
                    }
                    break;
                  case '3':
                    if (
                      filter(menus.analysis, opt => opt === 'depot_scorecard')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/depot_scorecard/`;
                      item.icon = require('../../assets/images/icons/scorecard.png');
                    }
                    break;
                  case '4':
                    if (
                      filter(menus.analysis, opt => opt === 'bought_not_bought')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/bought_not_bought/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;
                  case '5':
                    if (
                      filter(
                        menus.analysis,
                        opt => opt === 'stocked_not_stocked',
                      ).length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/stocked_not_stocked/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;
                  case '6':
                    if (
                      filter(menus.analysis, opt => opt === 'gap_analysis')
                        .length > 0
                    ) {
                      item.link = `${process.env.APP_ROOT}analysis/gap_analysis/`;
                      item.icon = require('../../assets/images/icons/pie-simple.png');
                    }
                    break;
                  default:
                    item.link = process.env.APP_ROOT;
                }
                return item;
              });

              this.props.setSubPageNav(
                filter(data, itm => itm.link !== undefined),
              );
            })
            .catch(err => {
              this.props.alertAdd(err, 'error');
            });
        } else if (this.props.location.pathname.indexOf('/query/') >= 0) {
          let subpage_nav = [];
          if (
            filter(menus.queryBuilder, opt => opt === 'query_builder').length >
            0
          ) {
            subpage_nav = [
              {
                id: 1,
                reportName: 'Query Builder',
                link: `${process.env.APP_ROOT}query/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
              {
                id: 2,
                reportName: 'Query History',
                link: `${process.env.APP_ROOT}query/history/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
              {
                id: 3,
                reportName: 'Query Scheduler',
                link: `${process.env.APP_ROOT}query/scheduled/`,
                icon: require('../../assets/images/icons/bar-chart.png'),
              },
            ];
          }
          if (
            this.props.showWeeklyUpdate &&
            filter(menus.queryBuilder, opt => opt === 'weekly_update').length >
              0
          ) {
            subpage_nav.push({
              id: 4,
              reportName: 'Key Facts Report',
              link: `${process.env.APP_ROOT}query/weekly_update/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            });
          }
          this.props.setSubPageNav(subpage_nav);
        } else if (this.props.location.pathname.indexOf('/psa/') >= 0) {
          this.props.setSubPageNav([
            {
              id: 1,
              reportName: 'Manage Distribution Lists',
              link: `${process.env.APP_ROOT}psa/distribution-lists/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
            {
              id: 2,
              reportName: 'Manage Campaigns',
              link: `${process.env.APP_ROOT}psa/campaigns/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
            {
              id: 3,
              reportName: 'View Campaigns',
              link: `${process.env.APP_ROOT}psa/view-campaigns/`,
              icon: require('../../assets/images/icons/bar-chart.png'),
            },
          ]);
        } else if (this.props.location.pathname.indexOf('/retail/') >= 0) {
          if (menus?.retail?.length > 0) {
            const retailMenuItemsByRole = menus.retail.map(item => {
              if (item === 'category_penetration_retail') {
                return {
                  reportName: 'Space & Ranging',
                  link: '/retail/category-penetration',
                };
              } else if (item === 'category_performance_matrix_retail') {
                return {
                  reportName: 'Boston Matrix - Categories',
                  link: '/retail/category-performance',
                };
              } else if (item === 'basket_product_analysis_retail') {
                return {
                  reportName: 'Basket Detail',
                  link: '/retail/basket-product-analysis',
                };
              } else if (item === 'basket_overview_retail') {
                return {
                  reportName: 'Basket Overview',
                  link: '/retail/basket-overview',
                };
              } else if (item === 'sales_by_week_retail') {
                return {
                  reportName: 'Sales By Week',
                  link: '/retail/sales-by-week',
                };
              } else if (item === 'sku_performance_retail') {
                return {
                  reportName: 'Sku Performance',
                  link: '/retail/sku-performance',
                };
              } else if (item === 'retailer_performance_retail') {
                return {
                  reportName: 'Boston Matrix - Stores',
                  link: '/retail/retailer-performance',
                };
              } else if (item === 'analytics_overview_dashboard') {
                return {
                  reportName: 'Analytics Overview Dashboard',
                  link: '/retail/analytics-overview',
                };
              } else if (item === 'analytics_kpi_dashboard') {
                return {
                  reportName: 'Analytics KPI Dashboard',
                  link: '/retail/analytics-kpi',
                };
              } else if (item === 'gap_analysis_retail') {
                return {
                  reportName: 'Gap Analysis Retail',
                  link: '/retail/gap-analysis-retail',
                };
              } else if (item === 'basket_category_analysis_retail') {
                return {
                  reportName: 'Basket Analysis',
                  link: '/retail/basket-analysis',
                };
              }
            });
            this.props.setSubPageNav(retailMenuItemsByRole);
          }
        }
      }
    },
  }),
  withState('data', 'handleData', {
    showQBPopUp: false,
    queryBuilderImage: [{ original: queryBuilderImage }],
  }),
  withHandlers({
    menuToggle:
      ({ toggleOffscreen }) =>
      menu => {
        toggleOffscreen(menu);
      },
    logout:
      ({ history, showLogoutScreen }) =>
      () => {
        sessionStorage.removeItem('show_qb_popup');
        showLogoutScreen();
        history.push(`${process.env.APP_ROOT}`);
      },
    showQBPopUp:
      ({ data, handleData }) =>
      val => {
        handleData({ ...data, showQBPopUp: val });
      },
    onQBGoToApp:
      ({ history, data, handleData }) =>
      val => {
        setShowQBPopUp();
        handleData({ ...data, showQBPopUp: false });
        history.push('/query/');
      },
  }),
)(SystemWrap);
