import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import { selectors,
         fetchWholesalers,
         fetchDefaults,
         searchMaster,
         searchMasterCodes,
         setMasterCode,
         selectMaster,
         selectFilter,
         addFilterData,
         saveProduct,
         setProductDepartment,
         setProductCategory,
         setProductSubCategory,
         setProductLevel4,
         setProductLevel5,
         setProductBrand,
         setProductSupplier,
         removeFilterData } from '../../redux/reducers/admin';

const AddProduct = ({data,
                      product_department,
                      product_category,
                      product_subcategory,
                      product_level4,
                      product_level5,
                      product_brand,
                     product_supplier,
                     wholeSaler,
                     masterCode,
  	             masterCodeText,
                     departments,
                     categories,
                     subcategories,
                     brands,
	                 level4s,
	                 level5s,
                     suppliers,
                     onSubmit,
                     onChange,
                     onDepartmentChange,
                     onCategoryChange,
                    onSubCategoryChange,
                    onToggleChange,
                     onBrandChange,
	                 onLevel4Change,
	                 onLevel5Change,
                     onSupplierChange,
                     isAddProduct,
                     onTextChange,
                     onValid}) => (
                       <form className="vk-contact-form" onSubmit={onSubmit}>
                      <div className="u-layout user-info">
                        <div className="u-1_3">
                          <h1 className="o-canvas__modal-form-title u-tac">
                              {isAddProduct === true ? <span>Add a new Wholeview Product</span> :<span>Edit a Wholeview Product</span>} </h1>
                        </div>
                        <div className="u-1_3">
                          <h2 className="o-canvas__modal-form-title u-tac">MasterCode: {masterCode}</h2>
                        </div>
                      </div>
                      <div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
                        <div className="u-1_3">
                          <div className="form-group" style={{height: '80px'}}>
                            <h3 className="o-canvas__search-title">Department</h3>
                              <Select
                                style={{minWidth:'200px'}}
                                className="c-select-header c-select-header-row--green"
                                name="department"
                                id="department"
                                value={ product_department ? product_department.ID : ''}
                                onChange={onDepartmentChange}
                                options={departments}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title ">Add New</h3>
                              <input type="text" id="department" className="ReactModal--input-green" name="department" value={data.new_department || ''} onChange={onTextChange}/>
                            </div>
                          </div>
                        </div>
                        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title">Category</h3>
                                <Select
                                  style={{minWidth:'200px'}}
                                  className="c-select-header c-select-header-row--green"
                                  name="category"
                                  id="category"
                                  value={ product_category ? product_category.ID : '' }
                                  onChange={onCategoryChange}
                                  options={ isAddProduct ? data.categories : categories }
                                  valueKey="ID"
                                  labelKey="Text"
                                />
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title">Add New</h3>
                              <input type="text" id="category" className="ReactModal--input-green" name="new_category" value={data.new_category || ''} onChange={onTextChange}/>
                            </div>
                          </div>
                        </div>
                        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title">Sub Category</h3>
                                <Select
                                  style={{minWidth:'200px'}}
                                  className="c-select-header c-select-header-row--green"
                                  name="subcategory"
                                  id="subcategory"
                                  value={ product_subcategory ? product_subcategory.ID : ''}
                                  onChange={onSubCategoryChange}
                                  options={ isAddProduct ? data.subcategories : subcategories }
                                  valueKey="ID"
                                  labelKey="Text"
                                />
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
                            </div>
                          </div>
                          <div className="u-1_3">
                            <div className="form-group" style={{height: '80px'}}>
                              <h3 className="o-canvas__search-title">Add New</h3>
                                <input type="text" id="subcategory" className="ReactModal--input-green" name="new_subcategory" value={data.new_subcategory || ''} onChange={onTextChange}/>
                            </div>
                          </div>
                        </div>
 						<div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Level 4</h3>
									<Select
										style={{minWidth:'200px'}}
										className="c-select-header c-select-header-row--green"
										name="level4"
										id="level4"
										value={ product_level4 ? product_level4.ID : ''}
										onChange={onLevel4Change}
										options={ isAddProduct ? data.level4s : level4s }
										valueKey="ID"
										labelKey="Text"
									/>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Add New</h3>
									<input type="text" id="level4" className="ReactModal--input-green" className="ReactModal--input-green" name="new_level4" value={data.new_level4 || ''} onChange={onTextChange}/>
								</div>
							</div>
						</div>
						<div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Level 5</h3>
									<Select
										style={{minWidth:'200px'}}
										className="c-select-header c-select-header-row--green"
										name="level5"
										id="level5"
										value={ product_level5 ? product_level5.ID : ''}
										onChange={onLevel5Change}
										options={ isAddProduct ? data.level5s : level5s }
										valueKey="ID"
										labelKey="Text"
									/>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Add New</h3>
									<input type="text" id="level5" className="ReactModal--input-green" className="ReactModal--input-green" name="new_level5" value={data.new_level5 || ''} onChange={onTextChange}/>
								</div>
							</div>
						</div>
						<div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Brand</h3>
									<Select
										style={{minWidth:'200px'}}
										className="c-select-header c-select-header-row--green"
										name="brand"
										id="brand"
										value={ product_brand ? product_brand.ID : ''}
										onChange={onBrandChange}
										options={ isAddProduct ? data.brands : brands }
										valueKey="ID"
										labelKey="Text"
									/>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
								</div>
							</div>
							<div className="u-1_3">
								<div className="form-group" style={{height: '80px'}}>
									<h3 className="o-canvas__search-title">Add New</h3>
									<input type="text" id="brand" className="ReactModal--input-green" className="ReactModal--input-green" name="new_brand" value={data.new_brand || ''} onChange={onTextChange}/>
								</div>
							</div>
						</div>
                      <div className="o-canvas__header-search u-layout u-layout--spaced" style={{width: '80%', margin: '0px auto'}}>
                        <div className="u-1_3">
                          <div className="form-group" style={{height: '80px'}}>
                          <h3 className="o-canvas__search-title">Supplier</h3>
                            <Select
                                style={{minWidth:'200px'}}
                                className="c-select-header c-select-header-row--green"
                                name="supplier"
                                id="supplier"
                                value={ product_supplier ? product_supplier.ID : ''}
                                onChange={onSupplierChange}
                                options={ isAddProduct ? data.suppliers : suppliers }
                                valueKey="ID"
                                labelKey="Text"
                               />
                          </div>
                        </div>
                        <div className="u-1_3">
                          <div className="form-group" style={{height: '80px'}}>
                            <h3 className="o-canvas__search-title u-tac ReactModal--heading">OR</h3>
                         </div>
                        </div>
                        <div className="u-1_3">
                          <div className="form-group" style={{height: '80px'}}>
                            <h3 className="o-canvas__search-title">Add New</h3>
                              <input type="text" id="supplier" className="ReactModal--input-green" name="new_supplier" value={data.new_supplier || ''} onChange={onTextChange}/>
                          </div>
                        </div>
						  <div style={{ width: '100%', textAlign: 'center'}}>
							  <p>{masterCodeText}</p>
						  </div><br/>
						  <div className="normal-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Brand:</label><input type="text" id="description" className="ReactModal--input-green" name="description" value={data.description || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="normal-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Sub Brand:</label><input type="text" id="subbrand" className="ReactModal--input-green" name="subbrand" value={data.subbrand || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="normal-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Variant:</label><input type="text" id="variant" className="ReactModal--input-green" name="variant" value={data.variant || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="normal-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Flavour:</label><input type="text" id="flavour" className="ReactModal--input-green" name="flavour" value={data.flavour || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="small-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Pack:</label><input type="text" id="pack" className="ReactModal--input-green" name="pack" value={data.pack || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="small-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>M Pack:</label><input type="text" id="multipack" className="ReactModal--input-green" name="multipack" value={data.multipack || ''} onChange={onTextChange}/>
							  </div>
						  </div>
						  <div className="small-width">
							  <div className="form-group" style={{height: '80px'}}>
								  <label>Case:</label><input type="text" id="case" className="ReactModal--input-green" name="case" value={data.case || ''} onChange={onTextChange}/>
							  </div>
						  </div>
                                                  <div className="small-width">
                                                          <div className="form-group" style={{height: '80px'}}>
                                                                  <label>Core Range</label>
                                                                  <AlchToggle id="Core_Range" label="" on={data.core_range} onChange={onToggleChange}/>
                                                          </div>
                                                        </div>
                                                  <div className="small-width">
                                                          <div className="form-group" style={{height: '80px'}}>
                                                                  <label>Best Seller</label>
                                                                  <AlchToggle id="Best_Seller" label="" on={data.bestseller} onChange={onToggleChange}/>
                                                          </div>
                                                  </div>
                      </div>

                           {data.invalid === true ? <div className="c-error-block">
                        <h2>Please enter all the required fields and try again.</h2>
                       </div>: ''}
                       <div className="o-canvas__header-search" style={{width: '80%', margin: '0px auto'}}>
                      <div className="o-block t-brand-c">
                          <button className="u-right vk-btn vk-btn-default vk-btn-l vk-fullwidth" type="submit">
                              SAVE
                          </button>
                        </div>
                        </div>
                    </form>
);

function mapStateToProps(state){
	let data = {
		masterCodeNew: state.admin.masterCode,
          masterCodeText: state.admin.masterCodeText,
          product_category: state.admin.product_category,
          product_subcategory: state.admin.product_subcategory,
          product_level4: state.admin.product_level4,
          product_level5: state.admin.product_level5,
          product_brand: state.admin.product_brand,
          product_supplier: state.admin.product_supplier,
          departments: state.admin.departments,
          categories: state.admin.categories,
          subcategories: state.admin.subcategories,
          level4s: state.admin.level4s,
          level5s: state.admin.level5s,
          brands: state.admin.brands,
          suppliers: state.admin.suppliers,
          bestseller: state.admin.bestseller,
          core_range: state.admin.core_range,
        ...this.props

        };
	return data;
}

export default compose(
withRouter,
  connect( mapStateToProps , { saveProduct,
    setProductDepartment,
    setProductCategory,
    setProductSubCategory,
    setProductLevel5,
    setProductLevel4,
    setProductBrand,
    setProductSupplier
  }),
  lifecycle({
       componentDidUpdate(prevProps){
           if(this.props.isAddProduct === true && this.props.masterCode !== undefined && this.props.masterCode.length > 0){
               this.props.closeModal();
           }
       }
    }),
  withState('data', 'handleData', (props) =>  ({ department: props.isAddProduct ? props.department : props.product_department,
                                    category: props.isAddProduct ? props.category : props.product_category,
                                    subcategory: props.isAddProduct ? props.subcategory : props.product_subcategory,
                                    brand: props.isAddProduct ? props.brand : props.product_brand,
	                            level4: props.isAddProduct ? props.level4 : props.product_level4,
	                            level5: props.isAddProduct ? props.level5 : props.product_level5,
                                    supplier: props.isAddProduct ? props.supplier : props.product_supplier,
                                    suppliers: props.isAddProduct ? props.suppliers : [],
                                    departments: props.departments,
                                    categories: props.isAddProduct ? props.categories : [],
                                    subcategories: props.isAddProduct ? props.subcategories : [],
                                    level4s: props.isAddProduct ? props.level4s : props.product_level4s,
                                    level5s: props.isAddProduct ? props.level5s : props.product_level5s,
                                    brands: props.isAddProduct ? props.brands : props.product_brands,
                                    new_department: '',
                                    new_category: '',
                                    new_subcategory: '',
                                    new_brand: '',
                                    new_level4: '',
                                    new_level5: '',
                                    new_supplier: '',
                                    description: '',
	                            subbrand: '',
                                    variant: '',
	                            flavour: '',
	                            multipack: '',
	                            pack: '',
                                    case: '',
                                    core_range: props.isAddProduct ? props.core_range : false,
                                    bestseller: props.isAddProduct ? props.bestseller : false,
                                    id: '',
                                    invalid: false})),
  withHandlers({
    onChange: ({ data, handleData }) => ({ target }) => {
        const { name, value } = target;
        if(name === 'department'){
          handleData({ ...data, department: value });
        }
        else if(name === 'category'){
          handleData({ ...data, category: value });
        }
        else if(name === 'subcategory'){
          handleData({ ...data, subcategory: value });
        }
        else if(name === 'supplier'){
          handleData({ ...data, supplier: value });
        }
        handleData({ ...data, [name]: value });
    },
    onToggleChange: ({ data, handleData }) => (val, id)=>{
      handleData({ ...data, core_range: id === 'Core_Range' ? val : data.core_range,  bestseller: id === 'Best_Seller' ? val : data.bestseller});
    },
    onDepartmentChange: ({ data, handleData, setProductDepartment, departments, product_department, isAddProduct }) => (selectedOption) => {
      let val = selectedOption === null ? 0 : selectedOption.ID;
      let department = _.filter(departments, dept => {
       if(parseInt(dept.ID) === parseInt(val)){
        return dept;
       }
      });
      let categories = [{ID: 0, Text: 'Select Category'}];
      let subcategories = [{ID: 0, Text: 'Select Sub Category'}];
      let brands = [{ID: 0, Text: 'Select Brand'}];
      let level4s = [{ ID: 0, Text : 'Select Level 4'}];
      let level5s = [{ ID: 0, Text: 'Select Level 5'}];
      if(isAddProduct){
        if(department[0].Categories !== undefined){
          handleData({ ...data, department: department[0],
            categories: department[0].Categories,
            subcategories: department[0].Categories[0].SubCategories,
            level4s: department[0].Categories[0].SubCategories[0].Level4,
            level5s: department[0].Categories[0].SubCategories[0].Level4[0].Level5,
            brands: department[0].Categories[0].SubCategories[0].Level4[0].Level5[0].Brands,
          });
        }
        else{
          handleData({ ...data, department: {},
            categories: categories,
                    category: {},
            subcategories: subcategories,
                    subcategory: {},
                                            level4s: level4s,
                    level4: {},
                                            level5s: level5s,
                    level5: {},
            brands: brands,
            brand: {}
          });
        }
      }
      else{
        setProductDepartment(department[0].ID);
      }
    },
    onCategoryChange: ({ data, setProductCategory, categories, handleData, product_category, isAddProduct }) => (selectedOption) => {
      let val = selectedOption === null ? 0 : selectedOption.ID;
      let category = _.filter(categories, cat => {
       if(parseInt(cat.ID) === parseInt(val)){
        return cat;
       }
      });
      let subcategories = [{ID: 0, Text: 'Select Sub Category'}];
      let brands = [{ID: 0, Text: 'Select Brand'}];
      let level4s = [{ ID: 0, Text : 'Select Level 4'}];
      let level5s = [{ ID: 0, Text: 'Select Level 5'}];
      if(isAddProduct){
      if(category[0].SubCategories !== undefined){
        handleData({ ...data, category: category[0],
          subcategories: category[0].SubCategories,
          level4s: category[0].SubCategories[0].Level4,
          level5s: category[0].SubCategories[0].Level4[0].Level5,
          brands: category[0].SubCategories[0].Level4[0].Level5[0].Brands,
        });
      }
      else{
        handleData({ ...data,
                  category: {},
          subcategories: subcategories,
                  subcategory: {},
                                          level4s: level4s,
                  level4: {},
                                          level5s: level5s,
                  level5: {},
          brands: brands,
          brand: {}
        });
      }
      }
      else{
       setProductCategory(category[0].ID);
      }
    },
    onTextChange: ({ data, handleData }) => ({ target }) => {
        const { name, value } = target;
        handleData({ ...data, [name]: value });
    },
    onSubCategoryChange: ({ data, handleData, subcategories, setProductSubCategory, product_subcategory, isAddProduct }) => (selectedOption) => {
      let val = selectedOption === null ? 0 : selectedOption.ID;
      let subCategory = _.filter(subcategories, subcat => {
       if(parseInt(subcat.ID) === parseInt(val)){
        return subcat;
       }
      });
      let brands = [{ID: 0, Text: 'Select Brand'}];
      let level4s = [{ ID: 0, Text : 'Select Level 4'}];
      let level5s = [{ ID: 0, Text: 'Select Level 5'}];
      if(isAddProduct){
        if(subCategory[0].Level4 !== undefined){
          handleData({ ...data, subcategory: subCategory[0],
            level4s: subCategory[0].Level4,
            level5s: subCategory[0].Level4[0].Level5,
            brands: subCategory[0].Level4[0].Level5[0].Brands,
          });
        }
        else{
          handleData({ ...data,
                    subcategory: {},
                                            level4s: level4s,
                    level4: {},
                                            level5s: level5s,
                    level5: {},
            brands: brands,
            brand: {}
          });
        }
      }
      else{
       setProductSubCategory(subCategory[0].ID);
      }
    },
    onBrandChange: ({ data, handleData, setProductBrand, brands, product_brand, isAddProduct }) => (selectedOption) => {
      let val = selectedOption === null ? 0 : selectedOption.ID;
      let brand = _.filter(brands, brd => {
       if(parseInt(brd.ID) === parseInt(val)){
        return brd;
       }
      });
      if(isAddProduct){
        handleData({ ...data, brand: brand[0] });
      }
      else{
       setProductBrand(brand[0].ID);
      }
    },
	  onLevel4Change: ({ data, handleData, level4s, setProductLevel4, product_level4, isAddProduct }) => (selectedOption) => {
            let val = selectedOption === null ? 0 : selectedOption.ID;
            let level4 = _.filter(level4s, lvl => {
             if(parseInt(lvl.ID) === parseInt(val)){
              return lvl;
             }
            });
            let level5s = [{ ID: 0, Text: 'Select Level 5'}];
            let brands = [{ID: 0, Text: 'Select Brand'}];
            if(isAddProduct){
              if(level4[0].Level5 !== undefined){
                handleData({ ...data, level4: level4[0],
                  level5s: level4[0].Level5,
                  brands: level4[0].Level5[0].Brands,
                });
              }
              else{
                handleData({ ...data,
                          level4: {},
                                                  level5s: level5s,
                          level5: {},
                  brands: brands,
                  brand: {}
                });
              }
            }
            else{
             setProductLevel4(level4[0].ID)
            }
	  },
	  onLevel5Change: ({ data, handleData, setProductLevel5, level5s, product_level5, isAddProduct }) => (selectedOption) => {
            let val = selectedOption === null ? 0 : selectedOption.ID;
            let level5 = _.filter(level5s, lvl => {
             if(parseInt(lvl.ID) === parseInt(val)){
              return lvl;
             }
            });
            let brands = [{ID: 0, Text: 'Select Brand'}];
            if(isAddProduct){
              if(level5[0].Brands !== undefined){
                handleData({ ...data, level5: level5[0],
                  brands: level5[0].Brands,
                });
              }
              else{
                handleData({ ...data,
                          level5: {},
                  brands: brands,
                  brand: {}
                });
              }
            }
            else{
             setProductLevel5(level5[0].ID);
            }
	  },
    onSupplierChange: ({ data, handleData, setProductSupplier, isAddProduct }) => (selectedOption) => {
      let val = selectedOption === null ? 0 : selectedOption.ID;
      let supplier = _.filter(data.suppliers, sup => {
       if(parseInt(sup.ID) === parseInt(val)){
        return sup;
       }
      });
      if(isAddProduct){
        handleData({ ...data, supplier: supplier[0] });
      }
      else{
        setProductSupplier(supplier[0].ID);
      }
    },
    onValid: ({data, handleData }) => (value) => {
      handleData({ ...data, invalid: false});
    },
    onSubmit: props => (event) => {
      event.preventDefault();
      if(props.isAddProduct === true){
        props.data.id = undefined;
      }
      // if((props.data.department === 0 && props.data.new_department.length === 0) ||
      //    (props.data.category === 0 && props.data.new_category.length === 0) ||
      //    (props.data.subcategory === 0 && props.data.new_subcategory.length === 0) ||
      //    (props.data.brand === 0 && props.data.new_brand.length === 0) ||
	// 	  (props.data.level4 === 0 && props.data.new_level4.length === 0) ||
	// 	  (props.data.level5 === 0 && props.data.new_level5.length === 0) ||
      //    (props.data.supplier === 0 && props.data.new_supplier.length === 0) ||
	// 	  props.data.description.length === 0 ||
	// 	  props.data.subbrand.length === 0 ||
	// 	  props.data.pack.length === 0 ||
      //    props.data.multipack.length === 0 ||
	// 	  props.data.flavour.length === 0 ||
	// 	  props.data.case.length === 0 ||
      //    props.data.variant.length === 0){
      //   props.handleData({ ...props.data, invalid: true});
      //  return;
      // }
      props.handleData({ ...props.data, invalid: false });
      props.saveProduct(props.data, props.isAddProduct);
    },
  })
)(AddProduct);


