import { handleActions, createAction } from 'redux-actions';
import { request, requestGet, customRequest } from '../../../helpers/axios';
import { setDisableCategoryStructure } from './app';

import { sortDuplicateNextLevelDown } from '../../../helpers/helpers';
import {
  encodeText,
  getUserSystem,
  getToken,
  getUserId,
  removeDrillInfo,
} from '../../../helpers/util';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import moment from 'moment';
import store from '../store';
import { push } from 'react-router-redux';
import Json2csv from 'json2csv';
import uuid from 'uuid/v4';

const Json2csvParser = Json2csv.Parser;

const FETCH_DATA = 'bestSeller/FETCH_DATA';
const FETCH_BEST_SELLER_DATA_TABLE = 'bestSeller/FETCH_BEST_SELLER_DATA_TABLE';
const SET_REPORT_DEPARTMENTS = 'bestSeller/SET_REPORT_DEPARTMENTS';
const SET_REPORT_CATEGORIES = 'bestSeller/SET_CATEGORIES';
const SET_MEASURE = 'bestSeller/SET_MEASURE';
const SET_LIKE_FOR_LIKE = 'bestSeller/SET_LIKE_FOR_LIKE';
const SET_PMP = 'bestSeller/SET_PMP';
const SET_SORT = 'bestSeller/SET_SORT';
const SET_PURCHASE = 'bestSeller/SET_PURCHASE';
const SET_ACCOUNT_NAME = 'bestSeller/SET_ACCOUNT_NAME';
const SET_TIMEFRAME = 'bestSeller/SET_TIMEFRAME';
const SET_SUBCATEGORIES = 'bestSeller/SET_SUBCATEGORIES';
const SET_LEVEL4S = 'bestSeller/SET_LEVEL4S';
const SET_LEVEL5S = 'bestSeller/SET_LEVEL5S';
const SET_SUPPLIER = 'bestSeller/SET_SUPPLIER';
const SET_SUPPLIERS = 'bestSeller/SET_SUPPLIERS';
const SET_BRANDS = 'bestSeller/SET_BRANDS';
const SET_BRAND = 'bestSeller/SET_BRAND';
const SET_SELECTED_MEMBERS = 'bestSeller/SET_SELECTED_MEMBERS';
const SET_LOADING = 'bestSeller/SET_LOADING';
const SET_LOADED = 'bestSeller/SET_LOADED';
const SET_GRAPH_LOADED = 'bestSeller/SET_GRAPH_LOADED';
const SET_TABLE_LOADED = 'bestSeller/SET_TABLE_LOADED';
const SET_HIDE_ALL = 'bestSeller/SET_HIDE_ALL';
const SET_LIMIT = 'bestSeller/SET_LIMIT';
const SET_CORE_RANGE = 'bestSeller/SET_CORE_RANGE';
const SET_MEMBER_TYPE = 'bestSeller/SET_MEMBER_TYPE';
const RESET = 'bestSeller/RESET';
const SET_TABLE_REQUEST_ID = 'bestSeller/SET_TABLE_REQUEST_ID';
const SET_CHART_REQUEST_ID = 'bestSeller/SET_CHART_REQUEST_ID';
const SET_DEPOT = 'bestSeller/SET_DEPOT';
const SET_REPORT_ERROR = 'bestSeller/SET_REPORT_ERROR';
const SET_DEPOTS = 'bestSeller/SET_DEPOTS';
const ADD_MEASURE = 'bestSeller/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'bestSeller/RESET_MEASURE_OPTIONS';
const SET_SELECTED_GROUP = 'bestSeller/SET_SELECTED_GROUP';
const SET_MEMBERS = 'bestSeller/SET_MEMBERS';
const SET_BUSINESS_TYPE = 'bestSeller/SET_BUSINESS_TYPE';

const initialState = {
  departments: [],
  selectedDepartments: [],
  categories: [],
  selectedCategories: [],
  subCategories: [],
  selectedSubCategories: [],
  suppliers: [],
  supplier: {},
  accountNames: [],
  accountName: [],
  members: [],
  brands: [],
  brand: {},
  selectedMembers: [],
  level4s: [],
  pdfURL: '',
  selectedLevel4s: [],
  level5s: [],
  selectedLevel5s: [],
  sortList: [
    { ID: 'bestSeller', Text: 'Best Seller' },
    { ID: 'fastestGrower', Text: 'Fastest Grower' },
  ],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: '',
  coreRanges: [
    { ID: 'yes', Text: 'Yes' },
    { ID: 'no', Text: 'No' },
  ],
  coreRange: '',
  memberTypes: [
    { ID: 'retail', Text: 'Retail' },
    { ID: 'foodservice', Text: 'Foodservice' },
  ],
  memberType: '',
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  measure: '',
  likeForLike: '',
  purchase: '',
  limit: 25,
  bestSellerDataTable: [],
  bestSellerAllDataTable: [],
  bestSellerDataTableTotals: {
    subTotal: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
    grandTotal: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
    other: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
  },
  isLoading: false,
  isExporting: false,
  isExported: false,
  isLoaded: false,
  isGraphLoaded: false,
  isTableLoaded: false,
  hideAll: false,
  tableRequestId: '',
  chartRequestId: '',
  reportType: 'category',
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  sort: 'bestSeller',
  depots: [],
  selectedDepots: [],
  reportError: '',
  selectedGroup: [],
  groups: [],
  businessTypes: [],
  selectedBusinessType: '',
  isRollingDateRange: false,
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchBestSellerDataTableAction = createAction(
  FETCH_BEST_SELLER_DATA_TABLE,
);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setMeasureAction = createAction(SET_MEASURE);
const setCoreRangeAction = createAction(SET_CORE_RANGE);
const setMemberTypeAction = createAction(SET_MEMBER_TYPE);
const setLikeForLikeAction = createAction(SET_LIKE_FOR_LIKE);
const setPmpAction = createAction(SET_PMP);
const setSortAction = createAction(SET_SORT);
const setPurchaseAction = createAction(SET_PURCHASE);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setSupplierAction = createAction(SET_SUPPLIER);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setBrandAction = createAction(SET_BRAND);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setLoadingAction = createAction(SET_LOADING);
const setLoadedAction = createAction(SET_LOADED);
const setGraphLoadedAction = createAction(SET_GRAPH_LOADED);
const setTableLoadedAction = createAction(SET_TABLE_LOADED);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setLimitAction = createAction(SET_LIMIT);
const setTableRequestIdAction = createAction(SET_TABLE_REQUEST_ID);
const setChartRequestIdAction = createAction(SET_CHART_REQUEST_ID);
const setDepotAction = createAction(SET_DEPOT);
const resetAction = createAction(RESET);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setDepotsAction = createAction(SET_DEPOTS);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);

export const exportToCSV = () => async dispatch => {
  let state = store.getState().bestSellerReport;

  const guid = uuid();
  dispatch(setChartRequestIdAction(guid));

  let url =
    '/route.php?c=performance/getBestSellersReport&limit=' +
    state.limit +
    '&measure=' +
    state.measure +
    '&timeframe=' +
    state.timeframe +
    '&like_for_like=' +
    state.likeForLike +
    '&requestId=' +
    guid +
    '&type=' +
    state.purchase +
    '&pmp=' +
    state.pmp +
    '&sort=' +
    state.sort;

  const user = store.getState().user.authUser;
  url += user.showCompetitor
    ? `&supplier=${encodeURIComponent(user.supplier.Text)}`
    : '';
  url +=
    state.selectedDepartments && state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories && state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories && state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s && state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s && state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';

  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });
  if (memberIds.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }

  const response = await requestGet({ url: url });
  response.results.map(result => {
    result.change = result.change * 100 + '%';
    result.marketShare = result.marketShare * 100 + '%';
  });

  const parser = new Json2csvParser([
    'ProductDescription',
    'change',
    'marketShare',
    'salesLY',
    'salesTY',
  ]);
  const url1 = window.URL.createObjectURL(
    new Blob([parser.parse(response.results)]),
  );
  const link = document.createElement('a');
  link.href = url1;
  link.setAttribute('download', `best_seller_${moment().format('X')}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setTableLoadedAction(false));
  dispatch(setGraphLoadedAction(false));
  loadTable(store, dispatch);
};

export const fetchCategories = isReferal => async dispatch => {
  const userObj = JSON.parse(localStorage.user_data);
  let url = '/route.php';
  if (userObj.departments.length === 0 && userObj.categories.length === 0) {
    url += '?c=category/getHierarchy';
  } else {
    url += '?c=category/getUserHierarchy';
  }
  const state = store.getState().bestSellerReport;
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  dispatch(setLoadingAction(true));
  const result = await requestGet({ url: url });

  let url1 = '/route.php?c=supplier/getAll';
  if (state.selectedMembers.length === 1) {
    url1 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }
  const response = await requestGet({ url: url1 });

  url = '/route.php?c=depot/getDepots';

  each(response, supp => {
    supp.SupplierName = encodeText(supp.SupplierName);
  });

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  url = '/route.php?c=customer/getRetailers';

  const accountNames = await requestGet({ url: url });

  let url2 = '/route.php?c=wholesaler/getAll';

  let members = await requestGet({ url: url2 });

  if (members.length === 1) {
    dispatch(setDisableCategoryStructure(false));
  }

  let url3 = '/route.php?c=brand/getAll';
  if (state.selectedMembers.length === 1) {
    url3 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }
  let brands = await requestGet({ url: url3 });
  brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

  each(brands, brd => {
    brd.Brand = encodeText(brd.Brand);
  });

  each(members, mem => {
    mem.Text = encodeText(mem.Text);
  });
  if (
    members.length === 1
  ) {
    if (result.Categories && result.Categories.length > 0) {
      dispatch(setHideAllAction(true));
    }
    dispatch(
      setSelectedMembersAction([
        { Text: members[0].ActualName, ID: members[0].WholesalerNo },
      ]),
    );
    members = [members[0]];
  }

  url = '/route.php?c=groupSupplier/getAll';

  let orderedGroups = [];
  try {
    let groups = await requestGet({ url: url });
    groups = groups.map((group, i) => ({ Text: group, ID: i }));
    orderedGroups = orderBy(groups, ['Text']);
  } catch (e) {
    console.warn('Error Fetching Groups', e);
  }

  let allDepartments = result.Departments ? result.Departments : [];

  let allCategories = result.Categories ? result.Categories : [];

  if (allDepartments.length > 1) {
    allDepartments.push({ ID: 0, Text: 'Select all', Categories: [] });
  }

  if (allCategories.length > 1) {
    allCategories.push({ ID: 0, Text: 'Select all', SubCategories: [] });
  }

  url = '/route.php?c=customer/getAllBusinessTypes';

  let businessTypes = [];

  try {
    businessTypes = await requestGet({ url: url });
  } catch {
    console.warn('No business types permission');
  }

  businessTypes =
    businessTypes.map((businessType, index) => {
      return { Text: businessType, ID: index };
    }) || [];

  dispatch(
    fetchDataAction({
      departments: orderBy(allDepartments, ['ID']),
      categories: orderBy(allCategories, ['ID']),
      suppliers: response,
      members: members,
      accountNames: accountNames,
      brands: brands,
      depots: depots,
      groups: orderedGroups,
      purchase: isReferal ? store.getState().bestSellerReport.purchase : 'out',
      measure: isReferal ? store.getState().bestSellerReport.measure : 'Value',
      coreRange: isReferal ? store.getState().bestSellerReport.coreRange : '',
      memberType: isReferal ? store.getState().bestSellerReport.memberType : '',
      likeForLike: isReferal
        ? store.getState().bestSellerReport.likeForLike
        : 'like_for_like',
      pmp: isReferal ? store.getState().bestSellerReport.pmp : '0',
      timeframe: isReferal
        ? store.getState().bestSellerReport.timeframe
        : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
      businessTypes,
    }),
  );

  let categories = result.Categories || [];
  if (result.Departments && result.Departments.length === 1) {
    let dept = result.Departments[0];
    let reportType = '';
    if (
      store.getState().bestSellerReport.reportType !== 'supplier' &&
      store.getState().bestSellerReport.reportType !== 'member' &&
      store.getState().bestSellerReport.reportType !== 'brand'
    ) {
      reportType = 'category';
    } else {
      reportType = store.getState().bestSellerReport.reportType;
    }
    dispatch(setReportDepartmentsAction({ departments: [dept], reportType }));
    //set categories;
    categories = dept.Categories;
  }
  each(categories, cat => {
    cat.Text = encodeText(cat.Text);
  });

  if (categories && categories.length === 1) {
    let catt = categories[0];
    let reportType = '';
    if (
      store.getState().bestSellerReport.reportType !== 'supplier' &&
      store.getState().bestSellerReport.reportType !== 'member'
    ) {
      reportType = 'subcategory';
    } else {
      reportType = store.getState().bestSellerReport.reportType;
    }
    dispatch(setReportCategoriesAction({ categories: [catt], reportType }));
    if (categories[0].SubCategories.length === 1) {
      let subcat = categories[0].SubCategories[0];
      let reportType = '';
      if (
        store.getState().bestSellerReport.reportType !== 'supplier' &&
        store.getState().bestSellerReport.reportType !== 'member'
      ) {
        reportType = 'level4';
      } else {
        reportType = store.getState().bestSellerReport.reportType;
      }
      dispatch(setSubCategoriesAction({ subCategories: [subcat], reportType }));
    }
  }

  if (!store.getState().bestSellerReport.hideAll && !isReferal) {
    dispatch(setLoadingAction(false));
    dispatch(setLoadedAction(true));
  } else {
    dispatch(setLoadingAction(false));
    dispatch(setLoadedAction(true));
  }
};

export const setOnLoadParams = (params, reportType) => async dispatch => {
  const state = store.getState().bestSellerReport;
  dispatch(setLoadedAction(false));
  if (params.measure) dispatch(setMeasureAction(params.measure));
  if (params.coreRange) dispatch(setMeasureAction(params.coreRange));
  if (params.memberType) dispatch(setMeasureAction(params.memberType));
  if (params.pmp) {
    dispatch(setPmpAction(params.pmp));
  } else {
    dispatch(setPmpAction('0'));
  }
  if (params.accountName) dispatch(setAccountNameAction(params.accountName));
  if (params.type) dispatch(setPurchaseAction(params.type));
  if (params.timeframe) dispatch(setTimeFrameAction(params.timeframe));
  if (params.likeForLike) dispatch(setLikeForLikeAction(params.likeForLike));
  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (params.departments && params.departments.length > 0) {
    _.each(params.departments, dept => {
      if (_.filter(state.departments, { Text: dept }).length > 0) {
        departments.push(_.filter(state.departments, { Text: dept })[0]);
      }
    });
    dispatch(setReportDepartmentsAction({ departments, reportType }));
  }
  if (params.categories && params.categories.length > 0) {
    _.each(params.categories, catt => {
      if (
        _.filter(flatten(map(departments, dept => dept.Categories)), {
          Text: catt,
        }).length > 0
      ) {
        categories.push(
          _.filter(flatten(map(departments, dept => dept.Categories)), {
            Text: catt,
          })[0],
        );
      }
    });
    if (categories.length > 0) {
      dispatch(setReportCategoriesAction({ categories, reportType }));
    }
  }
  if (params.subCategories && params.subCategories.length > 0) {
    _.each(params.subCategories, subcat => {
      if (
        _.filter(flatten(map(categories, cat => cat.SubCategories)), {
          Text: subcat,
        }).length > 0
      ) {
        subCategories.push(
          _.filter(flatten(map(categories, cat => cat.SubCategories)), {
            Text: subcat,
          })[0],
        );
      }
    });
    if (subCategories.length > 0) {
      dispatch(setSubCategoriesAction({ subCategories, reportType }));
    }
  }
  if (params.level4s && params.level4s.length > 0) {
    _.each(params.level4s, lvl4 => {
      if (
        _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
          Text: lvl4,
        }).length > 0
      ) {
        level4s.push(
          _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
            Text: lvl4,
          })[0],
        );
      }
    });
    if (level4s.length > 0) {
      dispatch(setLevel4sAction({ level4s, reportType }));
    }
  }
  if (params.level5s && params.level5s.length > 0) {
    _.each(params.level5s, lvl5 => {
      if (
        _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
          ID: parseInt(lvl5),
        }).length > 0
      ) {
        level5s.push(
          _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
            ID: parseInt(lvl5),
          })[0],
        );
      }
    });
    if (level5s.length > 0) {
      dispatch(setLevel5sAction({ level5s, reportType }));
    }
  }
  if (params.brand.length > 0) {
    let brand = _.filter(state.brands, brd => {
      if (brd.Brand.trim() === params.brand.trim()) {
        return brd;
      }
    });
    if (brand.length > 0) {
      let brnd = brand[0];
      brnd.reportType = reportType;
      dispatch(setBrandAction(brnd));
    }
  }
  if (params.members.length > 0) {
    dispatch(setSelectedMembersAction(params.members));
    const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(
      params.members,
      v => v.Text,
    ).join(','))}`;

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    dispatch(setDepotsAction(depots));

    if (params.depots.length > 0) {
      let procDepots = [];
      each(params.depots, dept => {
        each(depots, dpt => {
          if (dpt.DepotName === dept) {
            procDepots.push(dpt);
          }
        });
      });
      dispatch(setDepotAction({ selectedDepots: procDepots }));
    }
  } else {
    dispatch(setDepotsAction([]));
  }
  if (params.supplier.length > 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName.trim() === params.supplier.trim()) {
        dispatch(setSupplierAction(sup));
      }
    });
  }

  dispatch(setHideAllAction(false));
  dispatch(setLoadedAction(true));
};

export const setSupplier = val => async dispatch => {
  const state = store.getState().bestSellerReport;
  if (val.length > 0) {
    let supplier = _.filter(state.suppliers, sup => {
      if (sup.SupplierName === val) {
        return sup;
      }
    });

    dispatch(setSupplierAction(supplier[0]));

    let url = '/route.php?c=brand/getAll';
    if (state?.department?.Text) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state?.category?.Text) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state?.subCategory?.Text) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state?.level4?.Text) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state?.level5?.Text) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    url += `&supplier=${encodeURIComponent(supplier[0].SupplierName)}`;

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setSupplierAction({}));

    let url = '/route.php?c=brand/getAll';
    if (state?.department?.Text) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state?.category?.Text) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state?.subCategory?.Text) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state?.level4?.Text) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state?.level5?.Text) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));

  const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v?.Text).join(
    ',',
  ))}`;

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};

export const setBrand = val => dispatch => {
  dispatch(setBrandAction(val));
};

export const setDepot = val => dispatch => {
  dispatch(setDepotAction({ selectedDepots: val }));
};

export const setDepartment = depts => async dispatch => {
  const state = store.getState().bestSellerReport;

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept?.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept?.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept?.Text === 'TOBACCO') {
        return dept;
      }
    });
    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    let reportType = '';
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand' &&
      state.reportType !== 'level4' &&
      state.reportType !== 'level5'
    ) {
      reportType = 'category';
    } else {
      reportType = state.reportType;
    }

    dispatch(setReportDepartmentsAction({ departments: depts, reportType }));

    let supplierUrl = `/route.php?c=supplier/getAll`;

    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    supplierUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    let suppliers = await requestGet({ url: supplierUrl });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    brandUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({ url: brandUrl });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  } else {
    let rptType = state.reportType;
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'department';
    }
    dispatch(
      setReportDepartmentsAction({ reportType: rptType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: rptType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));

    let url = '/route.php?c=supplier/getAll';
    const state = store.getState().bestSellerReport;
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url: url,
    });
    
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  }
};

export const setCategory = cats => async dispatch => {
  const state = store.getState().bestSellerReport;
  let rptType = state.reportType;
  if (cats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setReportCategoriesAction({ categories: cats, reportType: rptType }),
    );
    let supplierUrl =  '/route.php?c=supplier/getAll&category=' +
    encodeURIComponent(map(cats, 'Text').join(',')) +
    '';
    const state = store.getState().bestSellerReport;
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:
       supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brandUrl =  `/route.php?c=brand/getAll&category=${encodeURIComponent(
      map(cats, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand' &&
      rptType !== 'level4' &&
      rptType !== 'level5'
    ) {
      rptType = 'category';
    }
    dispatch(
      setReportCategoriesAction({ reportType: rptType, categories: [] }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));


    let supplierUrl = '/route.php?c=supplier/getAll';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url: supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' || typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;

    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  }
};

export const goToReferal = (val, to, skip) => dispatch => {
  removeDrillInfo(to);
  dispatch(push(val));
};

export const setSubCategory = subCats => async dispatch => {
  let inititalState = store.getState().bestSellerReport;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = inititalState.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    await dispatch(
      setSubCategoriesAction({ subCategories: subCats, reportType: rptType }),
    );
    const state = store.getState().bestSellerReport;
    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl =  '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '';

    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction([]));
    const state = store.getState().bestSellerReport;
    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '';

    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url:brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }

  inititalState = store.getState().bestSellerReport;
  if (inititalState.level4s.length === 1) {
    dispatch(
      setLevel4sAction({ level4s: inititalState.level4s, reportType: rptType }),
    );
  }
};

export const setLevel4 = opts => async dispatch => {
  const initialState = store.getState().bestSellerReport;
  let rptType = initialState.reportType;
  if (opts.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level5';
    }
    await dispatch(setLevel4sAction({ level4s: opts, reportType: rptType }));
    const state = store.getState().bestSellerReport;

    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));
    const state = store.getState().bestSellerReport;
    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brandUrl = '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setLevel5 = lvl5s => async dispatch => {
  const initialState = store.getState().bestSellerReport;
  if (lvl5s.length > 0) {
    await dispatch(setLevel5sAction({ level5s: lvl5s }));
    const state = store.getState().bestSellerReport;

    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '&level5=' +
    encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '&level5=' +
    encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setLevel5sAction([]));
    const state = store.getState().bestSellerReport;

    let supplierUrl = '/route.php?c=supplier/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      supplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:supplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = '/route.php?c=brand/getAll&department=' +
    encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
    '&category=' +
    encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
    '&subcategory=' +
    encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
    '&level4=' +
    encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setTimeFrame = val => dispatch => {
  dispatch(setTimeFrameAction(val));
};

export const setCoreRange = val => dispatch => {
  dispatch(setCoreRangeAction(val));
};

export const setMemberType = val => dispatch => {
  dispatch(setMemberTypeAction(val));
};

export const setMeasure = val => dispatch => {
  dispatch(setMeasureAction(val));
};

export const setLikeForLike = val => dispatch => {
  dispatch(setLikeForLikeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const setAccountName = val => dispatch => {
  dispatch(setAccountNameAction(val));
};

export const setPmp = val => dispatch => {
  dispatch(setPmpAction(val));
};

export const setSort = val => dispatch => {
  dispatch(setSortAction(val));
};

export const setLimit = limit => dispatch => {
  dispatch(setLimitAction(limit.value));
};

export const exportAllToCSV = () => async dispatch => {
  let state = store.getState().bestSellerReport;

  const guid = uuid();
  dispatch(setChartRequestIdAction(guid));

  let url =
    '/route.php?c=performance/getBestSellersReport&limit=75&measure=' +
    state.measure +
    '&timeframe=' +
    state.timeframe +
    '&like_for_like=' +
    state.likeForLike +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&requestId=' +
    guid +
    '&type=' +
    state.purchase +
    '&pmp=' +
    state.pmp +
    '&sort=' +
    state.sort;

  const user = store.getState().user.authUser;
  url += user.showCompetitor
    ? `&supplier=${encodeURIComponent(user.supplier.Text)}`
    : '';
  url +=
    state.selectedDepartments && state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories && state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories && state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s && state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s && state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';

  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });
  if (memberIds.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }

  const response = await requestGet({ url: url });
  response.results.map(result => {
    result.change = result.change * 100 + '%';
    result.marketShare = result.marketShare * 100 + '%';
  });
  const parser = new Json2csvParser([
    'ProductDescription',
    'change',
    'marketShare',
    'salesLY',
    'salesTY',
  ]);
  const url1 = window.URL.createObjectURL(
    new Blob([parser.parse(response.results)]),
  );
  const link = document.createElement('a');
  link.href = url1;
  link.setAttribute('download', `best_seller_${moment().format('X')}.csv`);
  document.body.appendChild(link);
  link.click();
};

const loadTable = (store, dispatch) => {
  dispatch(setTableLoadedAction(false));
  dispatch(setReportErrorAction(''));
  let state = store.getState().bestSellerReport;
  const guid = uuid();
  dispatch(setTableRequestIdAction(guid));
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;

  let url =
    '/route.php?c=performance/getBestSellersReport&limit=' +
    state.limit +
    '&measure=' +
    state.measure +
    '&timeframe=' +
    state.timeframe +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&like_for_like=' +
    state.likeForLike +
    '&requestId=' +
    guid +
    '&type=' +
    state.purchase +
    '&pmp=' +
    state.pmp +
    '&sort=' +
    state.sort;

  const user = store.getState().user.authUser;
  url += user.showCompetitor
    ? `&supplier=${encodeURIComponent(user.supplier.Text)}`
    : '';
  url +=
    state.selectedDepartments && state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories && state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories && state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s && state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s && state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  url +=
    state.selectedBusinessType.length > 0
      ? '&business_type=' +
        encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });
  url += memberIds.length > 0 ? '&member=' + encodeURIComponent(memberIds) : '';

  url +=
    accountName && accountName.length > 0
      ? '&storeid=' + accountName
          .map(val => encodeURIComponent(val.RegNo))
          .join(',')
      : '';

  selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
    if (value && value.length > 0) {
      url += `&${encodeURIComponent(ApiName)}=${value
        .map(val => encodeURIComponent(val.value))
        .join(',')}`;
    }
  });

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      if (response.requestId) {
        if (
          response.requestId ===
          store.getState().bestSellerReport.tableRequestId
        ) {
          let data = [];
          _.each(response.results, result => {
            data.push({
              field: result.ProductDescription,
              marketShare: result.marketShare,
              bestSeller: result.BestSeller,
              fastGrower: result.FastGrower,
              salesTY: result.salesTY,
              salesLY: result.salesLY,
              change: result.change,
              coreRangeEW:
                result.CoreRangeEW === null || result.CoreRangeEW === '0'
                  ? false
                  : true,
              coreRangeNI:
                result.CoreRangeNI === null || result.CoreRangeNI === '0'
                  ? false
                  : true,
              coreRangeScot:
                result.CoreRangeScot === null || result.CoreRangeScot === '0'
                  ? false
                  : true,
            });
          });
          dispatch(
            fetchBestSellerDataTableAction({
              data,
              totals: response.totals,
            }),
          );
          dispatch(setLoadingAction(false));
          dispatch(setTableLoadedAction(true));
        }
      } else {
        dispatch(setLoadingAction(false));
        dispatch(setTableLoadedAction(true));
        dispatch(
          setReportErrorAction('Failed to load report, please try again.'),
        );
      }
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setTableLoadedAction(true));
    });
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export const setBusinessType = val => dispatch => {
  dispatch(setBusinessTypeAction(val));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => {
      let orderedDepartments = uniqBy(
        orderBy(
          filter(
            payload.departments,
            cat => cat !== undefined && cat.Text !== 'Select All',
          ),
          ['Text'],
        ),
        'Text',
      );
      if (orderedDepartments.length > 1) {
        orderedDepartments.unshift({
          ID: 0,
          Text: 'Select all',
          SubCategories: [],
        });
      }
      return {
        ...state,
        departments: orderedDepartments,
        categories: payload.categories,
        accountNames: payload.accountNames,
        suppliers: payload.suppliers,
        brands: payload.brands,
        members: payload.members,
        purchase: payload.purchase,
        measure: payload.measure,
        coreRange: payload.coreRange,
        memberType: payload.memberType,
        likeForLike: payload.likeForLike,
        pmp: payload.pmp,
        timeframe: payload.timeframe,
        depots: payload.depots,
        groups: payload.groups,
        businessTypes: payload.businessTypes,
      };
    },
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        payload,
      ],
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      let orderedCategories = uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedCategories.length > 1) {
        orderedCategories.unshift({
          ID: 0,
          Text: 'Select all',
          SubCategories: [],
        });
      }
      return {
        ...state,
        selectedDepartments: payload.departments,
        categories: orderedCategories,
        reportType: payload.reportType,
      };
    },
    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );

      let orderedSubCategories = orderBy(
        filter(allSubCategories, subCat => subCat !== undefined),
        ['Text'],
      );
      orderedSubCategories = sortDuplicateNextLevelDown(
        orderedSubCategories,
        'Level4',
      );

      orderedSubCategories = uniqBy(orderedSubCategories, 'Text');

      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType,
      };
    },
    [SET_CHART_REQUEST_ID]: (state, { payload }) => ({
      ...state,
      chartRequestId: payload,
    }),
    [SET_TABLE_REQUEST_ID]: (state, { payload }) => ({
      ...state,
      tableRequestId: payload,
    }),
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );

      let orderedLvl4s = uniqBy(
        orderBy(
          filter(allLevel4s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl4s.length > 1) {
        orderedLvl4s.unshift({ ID: 0, Text: 'Select all', Level5s: [] });
      }

      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: orderedLvl4s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );

      let orderedLvl5s = uniqBy(
        orderBy(
          filter(allLevel5s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl5s.length > 1) {
        orderedLvl5s.unshift({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: orderedLvl5s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL5S]: (state, { payload }) => {
      return {
        ...state,
        selectedLevel5s: payload.level5s,
        reportType: payload.reportType,
      };
    },
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [SET_SUPPLIER]: (state, { payload }) => ({ ...state, supplier: payload }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload }),
    [SET_BRANDS]: (state, { payload }) => ({ ...state, brands: payload }),
    [SET_SUPPLIERS]: (state, { payload }) => ({ ...state, suppliers: payload }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName: payload,
    }),
    [SET_MEASURE]: (state, { payload }) => ({ ...state, measure: payload }),
    [SET_CORE_RANGE]: (state, { payload }) => ({ ...state, coreRange: payload }),
    [SET_MEMBER_TYPE]: (state, { payload }) => ({ ...state, memberType: payload }),
    [SET_LIKE_FOR_LIKE]: (state, { payload }) => ({
      ...state,
      likeForLike: payload,
    }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_SORT]: (state, { payload }) => ({ ...state, sort: payload }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_TIMEFRAME]: (state, { payload }) => ({ ...state, timeframe: payload }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_GRAPH_LOADED]: (state, { payload }) => ({
      ...state,
      isGraphLoaded: payload,
    }),
    [SET_TABLE_LOADED]: (state, { payload }) => ({
      ...state,
      isTableLoaded: payload,
    }),
    [SET_LIMIT]: (state, { payload }) => ({ ...state, limit: payload }),
    [FETCH_BEST_SELLER_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      bestSellerDataTable: payload.data,
      bestSellerDataTableTotals: payload.totals,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      departments: [],
      selectedDepartments: [],
      categories: [],
      selectedCategories: [],
      selectedSubCategories: [],
      subCategories: [],
      supplier: {},
      suppliers: [],
      selectedLevel4s: [],
      level4s: [],
      selectedLevel5s: [],
      isLoaded: false,
      level5s: [],
      accountName: [],
      timeframe: '',
      purchase: '',
      measure: '',
      coreRange: '',
      memberType: '',
      likeForLike: '',
      pmp: '',
      bestSellerDataTable: [],
      isLoading: false,
      reportType: '',
      depots: [],
      selectedMembers: [],
      selectedDepots: [],
      brand: {},
      selectedGroup: [],
      groups: [],

      businessTypes: [],
      selectedBusinessType: '',
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
