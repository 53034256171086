import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import User from '../CreateUser/user';
import Account from '../CreateUser/account';
import Permissions from '../CreateUser/permissions';
import Review from '../CreateUser/review';

const CreateUser = ({ data, isSuccess, isLoading }) => (
  <Switch>
    <Route
      path={`${process.env.APP_ROOT}create_user/user/:id?`}
      render={(pageProps) => (
        <User
          //     {...this.props}
          {...pageProps}
        />
      )}
    />
    <Route
      path={`${process.env.APP_ROOT}create_user/account/:id?`}
      render={(pageProps) => (
        <Account
          //     {...this.props}
          {...pageProps}
        />
      )}
    />
    <Route
      path={`${process.env.APP_ROOT}create_user/permissions/:id?`}
      render={(pageProps) => (
        <Permissions
          //     {...this.props}
          {...pageProps}
        />
      )}
    />
    <Route
      path={`${process.env.APP_ROOT}create_user/review/:id?`}
      render={(pageProps) => (
        <Review
          //     {...this.props}
          {...pageProps}
        />
      )}
    />
    <Redirect from={`${process.env.APP_ROOT}create_user`} to={`${process.env.APP_ROOT}create_user/user/:id?`} />
  </Switch>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.user.isLoading,
    isSuccess: state.user.isSuccess,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {}),
  lifecycle({
    componentDidMount() {},
  }),
  withState('data', 'handleData', {}),
  withHandlers({}),
)(CreateUser);
