import React, { useEffect, useState } from 'react';
import { KPIBarChart, KPITable } from '../../components/SupplierEngagement';
import { getUserSystem } from '../../../helpers/util';

const KPI = ({ data, barData }) => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    const tempData =
      (
        barData &&
        barData.map(({ Name, value }) => ({
          Name,
          SummaryValue: (value && parseFloat(value.replaceAll(',', ''))) || 0,
          SummaryValue_Percentage:
            (value && parseFloat(value.replaceAll(',', ''))) || 0,
        }))
      ).sort((a, b) => {
        if (a.SummaryValue < b.SummaryValue) {
          return -1;
        } else if (a.SummaryValue > b.SummaryValue) {
          return 1;
        } else {
          return 0;
        }
      }) || [];
    setChartData(tempData);
  }, [barData]);

  const tableValues = [
    'Value',
    'Volume',
    'Value Share of Category',
    'Volume Share of Category',
  ];
  const tableValueData = data.filter(item => tableValues.includes(item.Name));

  const tableCategory = [
    'Category Customer Size',
    'Supplier Customer Size',
    'Category Penetration',
  ];
  const tableCategoryData = data.filter(item =>
    tableCategory.includes(item.Name),
  );

  const tablePrice = [
    'Average Price',
    'Supplier Average Price',
    'Category Average Price',
  ];
  const tablePriceData = data.filter(item => tablePrice.includes(item.Name));

  const tableTrip = ['Order Frequency', 'Cases per Order', 'Rate of Sale'];
  const tableTripData = data.filter(item => tableTrip.includes(item.Name));
  return (
    <>
      {data && getUserSystem().toLowerCase() != 'confex' && (
        <KPIBarChart
          data={chartData}
          id="kpi"
          title="KPI - Contribution to Value Growth"
          value={'SummaryValue'}
        />
      )}
      <KPITable
        columnTitle={'Value & Volume'}
        title="Supplier KPIS DRIVER OF PERFORMANCE"
        data={tableValueData}
      />
      {getUserSystem().toLowerCase() != 'confex' && (
        <KPITable columnTitle={'Customers'} data={tableCategoryData} />
      )}
      <KPITable columnTitle={'Price'} data={tablePriceData} />
      {getUserSystem().toLowerCase() != 'confex' && (
        <KPITable columnTitle={'Rate of Sale'} data={tableTripData} />
      )}
    </>
  );
};

export default KPI;
