import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { Row, Col, Container } from 'reactstrap';
import { getUserSystem } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import ReportFiltersContainer from '../../components/filters/ReportFiltersContainer';
import DashboardCard from '../../components/retail/DashboardCard';
import BasketProductAnalysisTable from '../../components/retail/BasketProductAnalysisTable';
import Loading from '../../components/Loading';

import { basketProductAnalysisExportHeaders } from '../../../config/tableExportHeaders';
import { pageVariants } from '../../../config/pageVariants';
import { setAccountName } from '../../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setLikeForLike,
  setDepartment,
  setSubCategory,
  setSupplier,
  setBrand,
  setCategory,
  setLevel4,
  setLevel5,
  setSelectedProduct,
  loadBasketProductAnalysis,
  resetReport,
  setRefreshDisabledStatus,
} from '../../redux/reducers/retail';

export const BasketProductAnalysis = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  onAccountNameChange,
  likeForLike,
  likeForLikes,
  setLoading,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartment,
  departments,
  onDepartmentChange,
  selectedCategory,
  categories,
  onCategoryChange,
  onLikeForLikeChange,
  onUnmount,
  suppliers,
  selectedSupplier,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrand,
  subCategories,
  selectedSubCategory,
  onLevel4Change,
  selectedLevel4,
  level4s,
  onLevel5Change,
  selectedLevel5,
  level5s,
  products,
  selectedProduct,
  onProductChange,
  basketProductAnalysisData: { cards, product_table },
  loadBasketProductAnalysis,
  isLoading,
  isRefreshDisabled,
  addFilterGroup,
  location,
  onResetReport,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('basket_product_analysis', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });
    return () => onUnmount();
  }, []);
  return (
    <motion.div
      key={'Basket-Product-Analysis-ID'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportFiltersContainer
        resetReport={onResetReport}
        pathname={location.pathname}
        refresh={{
          refreshHandler: () => loadBasketProductAnalysis(),
          isRefreshDisabled,
        }}
        exports={{
          CSV: {
            headers: basketProductAnalysisExportHeaders,
            data: product_table,
          },
        }}
        title={'Basket Detail'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Basket Detail for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          {
            name: 'accountName',
            valueKey: 'RegNo',
            labelKey: 'CustomerName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
            clearable: true,
          },
        ]}
        reset={onResetReport}
        sections={[
          {
            title: 'Filter By Category Hierachy',
            dropdowns: [
              {
                name: 'departments',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedDepartment,
                options: departments,
                handler: option => onDepartmentChange(option),
                isMulti: false,
              },
              {
                name: 'categories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedCategory,
                options: categories,
                handler: option => onCategoryChange(option),
                isMulti: false,
              },
              {
                name: 'subCategories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSubCategory,
                options: subCategories,
                handler: option => onSubCategoryChange(option),
                isMulti: false,
              },
              {
                name: 'level4',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel4,
                options: level4s,
                handler: option => onLevel4Change(option),
                isMulti: false,
              },
              {
                name: 'level5',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel5,
                options: level5s,
                handler: option => onLevel5Change(option),
                isMulti: false,
              },
            ],
          },
          {
            title: 'Filter by Supplier and/or Brand',
            dropdowns: [
              {
                name: 'Suppliers',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSupplier,
                options: suppliers,
                handler: option => onSupplierChange(option),
              },
              {
                name: 'Brand',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedBrand,
                options: brands,
                handler: option => onBrandChange(option),
              },
              {
                name: 'Product',
                valueKey: 'WVProdCode',
                labelKey: 'WVProdDesc',
                value: selectedProduct,
                options: products,
                handler: option => onProductChange(option),
              },
            ],
          },
        ]}
      />
      <ReportContainer padding={3} display="block">
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Container>
              <Row className="mb-5">
                {cards &&
                  cards.length > 0 &&
                  cards.map(data => {
                    if (
                      data.Card === 'Sales Value' ||
                      data.Card === 'Sales Units'
                    ) {
                      return;
                    } else {
                      return (
                        <Col
                          className="d-flex align-items-stretch"
                          key={data.Card}>
                          <DashboardCard data={data} />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </Container>
            <Container fluid>
              <Row className="mb-5">
                {product_table && product_table.length > 0 && (
                  <Col>
                    <BasketProductAnalysisTable
                      title="Basket Product Analysis Table"
                      data={product_table}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          </>
        )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  departments: state.retail.departments,
  selectedDepartment: state.retail.selectedDepartment,
  categories: state.retail.categories,
  selectedCategory: state.retail.selectedCategory,
  subCategories: state.retail.subCategories,
  selectedSubCategory: state.retail.selectedSubCategory,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.supplier,
  brands: state.retail.brands,
  selectedBrand: state.retail.brand,
  selectedLevel4: state.retail.selectedLevel4,
  level4s: state.retail.level4s,
  selectedLevel5: state.retail.selectedLevel5,
  level5s: state.retail.level5s,
  products: state.retail.products,
  selectedProduct: state.retail.selectedProduct,
  basketProductAnalysisData: state.retail.basketProductAnalysisData,
  isLoading: state.retail.isLoading,
  isRefreshDisabled: state.retail.isRefreshDisabled,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartment(selectedOptions)),
    onCategoryChange: selectedOptions => dispatch(setCategory(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOption => dispatch(setSupplier(selectedOption)),
    onBrandChange: option => dispatch(setBrand(option)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategory(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5(selectedOptions)),
    onProductChange: selectedOptions =>
      dispatch(setSelectedProduct(selectedOptions)),
    loadBasketProductAnalysis: () => dispatch(loadBasketProductAnalysis()),
    addFilterGroup: () => dispatch(addFilterGroup()),
    onResetReport: () => {
      dispatch(resetReport());
      dispatch(
        fetchCategories('basket_product_analysis', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BasketProductAnalysis);
