import React from 'react';
import PropTypes from 'prop-types';
import store from '../redux/store';

import { getRandomNumber } from '../../helpers/helpers';
import AlertMessage from './AlertMessage';

class AlertHolder extends React.Component {
  renderAlert = (alert) => {
    return <AlertMessage alert={alert} key={getRandomNumber('art')} alertClose={this.props.alertClose} />;
  };

  render() {
    const alerts = store.getState().alerts;
    return (
      <div className="o-alerts-wrap o-alerts-wrap--t_r d-none">
        {alerts.map((alert) => {
          if (alert.status === 'open') {
            return this.renderAlert(alert);
          }
          return false;
        })}
      </div>
    );
  }
}

AlertHolder.propTypes = {
  alertClose: PropTypes.func.isRequired,
};

export default AlertHolder;
