import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddAdvert from '../../components/modals/AddAdvert';
import { fetchAdverts, onSetForm, onDeleteAdvert, onSaveAdvert, onSetAdvert, resetMe } from '../../redux/reducers/pfpAdvert';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { throws } from 'assert';

ReactModal.setAppElement('.alchemy_app');
const Adverts = ({ data, isLoading, adverts, advertForm, onFormSelectChange, onFormTextChange, onFormFileChange, onSaveAdvert, onDeleteAdvert, handleAdvertDeleteModal, handleAdvertAddModal }) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openAdvertAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handleAdvertAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openAdvertAddModal} contentLabel="" className="performance-modal">
          <AddAdvert
            handleAdvertAddModal={() => handleAdvertAddModal(null)}
            isLoading={isLoading}
            advertTypes={data.advertTypes}
            targetTypes={data.targetTypes}
            pages={data.pages}
            statuses={data.statuses}
            displayOrder={data.displayOrder}
            positions={data.positions}
            advertForm={advertForm}
            onSaveAdvert={onSaveAdvert}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>Content And Adverts</h4>
          {adverts.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Advert Name</th>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Text</th>
                  <th>Target Type</th>
                  <th>Target</th>
                  <th>Status</th>
                  <th>Display From</th>
                  <th>Display To</th>
                  <th>Page</th>
                  <th>Position</th>
                  <th>Display Order</th>
                  <th>Filter1</th>
                  <th>AssistUrl</th>
                  <th>Unique Key</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                {adverts.map((advert, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleAdvertAddModal(advert.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleAdvertDeleteModal(advert.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{advert.AdvertName}</td>
                      <td>{advert.Type}</td>
                      <td>{advert.Title}</td>
                      <td>{advert.Text}</td>
                      <td>{advert.TargetType}</td>
                      <td>{advert.Target}</td>
                      <td>{advert.Status}</td>
                      <td>{advert.DisplayFrom}</td>
                      <td>{advert.DisplayTo}</td>
                      <td>{advert.Page}</td>
                      <td>{advert.Position}</td>
                      <td>{advert.DisplayOrder}</td>
                      <td>{advert.Filter1}</td>
                      <td>{advert.AssistUrl}</td>
                      <td>{advert.UnquieKey}</td>
                      <td>{advert.Updated}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openAdvertDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this advert?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteAdvert()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleAdvertDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Adverts found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpAdvert.isLoading,
    isAdvertCreated: state.pfpAdvert.isAdvertCreated,
    isAdvertDeleted: state.pfpAdvert.isAdvertDeleted,
    adverts: state.pfpAdvert.adverts,
    advertForm: state.pfpAdvert.advertForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchAdverts,
    onSetForm,
    onDeleteAdvert,
    onSaveAdvert,
    onSetAdvert,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      //this.props.departments.length === 0 &&
      this.props.fetchAdverts();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isAdvertCreated !== this.props.isAdvertCreated && this.props.isAdvertCreated) {
        this.props.fetchAdverts();
        this.uploadFiles = [];
        toast.info((this.props.advertForm.ID ? 'Updated' : 'Created') + ' Advert Successfully...');
      }
      if (prevProps.isAdvertDeleted !== this.props.isAdvertDeleted && this.props.isAdvertDeleted) {
        this.props.fetchAdverts();
        toast.info('Deleted Advert Successfully...');
      }
    },
  }),
  withState('data', 'handleData', {
    isAddAdvert: false,
    openAdvertAddModal: false,
    openAdvertDeleteModal: false,
    advertTypes: [
      { label: 'ContentFeature', value: 'ContentFeature' },
      { label: 'MenuAd', value: 'MenuAd' },
      { label: 'Splash', value: 'Splash' },
      { label: 'SkyScrapper', value: 'SkyScrapper' },
      { label: 'Carousel', value: 'Carousel' },
      { label: 'Popup', value: 'Popup' },
    ],
    targetTypes: [
      { label: 'Internal', value: 'Internal' },
      { label: 'External', value: 'External' },
    ],
    statuses: [
      { label: 'Enable', value: 1 },
      { label: 'Disable', value: 0 },
    ],
    displayOrder: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
    ],
    pages: [
      { label: 'Home', value: 'Home' },
      { label: 'Menu', value: 'Menu' },
      { label: 'CoreRange', value: 'CoreRange' },
      { label: 'Planograms', value: 'Planograms' },
      { label: 'ROI', value: 'ROI' },
      { label: 'Deals', value: 'Deals' },
      { label: 'Wholesaler', value: 'Wholesaler' },
      { label: 'NPD', value: 'NPD' },
      { label: 'DropShip', value: 'DropShip' },
    ],
    positions: [
      { label: 'Home Page Feature 2', value: 'Home Page Feature 2' },
      { label: 'Home Page Feature 3', value: 'Home Page Feature 3' },
      { label: 'Home Page Feature 4', value: 'Home Page Feature 4' },
      { label: 'About Advice', value: 'About Advice' },
      { label: 'Splash', value: 'Splash' },
      { label: 'SkyScrapper', value: 'SkyScrapper' },
      { label: 'Carousel', value: 'Carousel' },
      { label: 'Popup', value: 'Popup' },
    ],
  }),
  withHandlers({
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSaveAdvert: ({ data, handleData, onSaveAdvert }) => () => {
      onSaveAdvert(this.uploadFiles || []);
      handleData({ ...data, isAddAdvert: false, openAdvertAddModal: false });
    },
    onDeleteAdvert: ({ data, handleData, onDeleteAdvert, advertForm }) => () => {
      onDeleteAdvert(advertForm.ID);
      handleData({ ...data, isAddAdvert: false, openAdvertDeleteModal: false });
    },
    handleAdvertDeleteModal: ({ data, handleData, adverts, onSetAdvert }) => id => {
      handleData({ ...data, openAdvertDeleteModal: !data.openAdvertDeleteModal });
      onSetAdvert(id || '', false, true);
    },
    handleAdvertAddModal: ({ data, handleData, onSetAdvert }) => id => {
      const isAddAdvert = id ? false : true;
      this.uploadFiles = []; //reset
      const openAdvertAddModal = !data.openAdvertAddModal;
      handleData({ ...data, isAddAdvert, openAdvertAddModal: openAdvertAddModal });
      if (openAdvertAddModal) {
        onSetAdvert(id, isAddAdvert);
      } else {
        onSetAdvert(null, isAddAdvert);
      }
    },
  }),
)(Adverts);
