import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import CategoryPenetration from '../Retail/CategoryPenetration';
import SkuPerformance from '../Retail/SkuPerformance';
import BasketAnalysis from '../Retail/BasketAnalysis';
import BasketOverview from '../Retail/BasketOverview';
import RetailerPerformance from '../Retail/RetailerPerformance';
import CategoryPerformance from '../Retail/CategoryPerformance';
import SalesByWeek from '../Retail/SalesByWeek';
import BasketProductAnalysis from '../Retail/BasketProductAnalysis';
import RetailAnalysisReport from '../Retail/RetailAnalysis';
import AnalyticsOverview from '../Retail/AnalyticsOverview';
import AnalyticsKpi from '../Retail/AnalyticsKPI';

import { getUserSystem, hasPermission } from '../../../helpers/util';

const setDefaultRetailPage = () => {
  const user = getUserSystem().toLowerCase();
  
  if (hasPermission(`role_view_${user}_basket_overview_retail`)) {
    return 'retail/basket-overview';
  } else if (hasPermission(`role_view_${user}_basket_product_analysis_retail`)) {
    return 'retail/basket-product-analysis';
  } else if (hasPermission(`role_view_${user}_category_penetration_retail`)) {
    return 'retail/category-penetration';
  } else if (hasPermission(`role_view_${user}_category_performance_matrix_retail`)) {
    return 'retail/category-performance';
  } else if (hasPermission(`role_view_${user}_retailer_performance_retail`)) {
    return 'retail/retailer-performance';
  } else if (hasPermission(`role_view_${user}_analytics_overview_dashboard`)) {
    return 'retail/analytics-overview';
  } else if (hasPermission(`role_view_${user}_analytics_kpi_dashboard`)) {
    return 'retail/analytics-kpi';
  } else if (hasPermission(`role_view_${user}_sales_by_week_retail`)) {
    return 'retail/sales-by-week';
  } else if (hasPermission(`role_view_${user}_sku_performance_retail`)) {
    return 'retail/sku-performance';
  } else {
    return 'retail/no-permissions';
  }
};

const Retail = props => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'spring', duration: 0.5 }}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch>
          <Route
            path={`${process.env.APP_ROOT}retail/category-penetration`}
            render={pageProps => (
              <CategoryPenetration {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/category-performance`}
            render={pageProps => (
              <CategoryPerformance {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/retailer-performance`}
            render={pageProps => (
              <RetailerPerformance {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/analytics-overview`}
            render={pageProps => (
              <AnalyticsOverview {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/analytics-kpi`}
            render={pageProps => (
              <AnalyticsKpi {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/sales-by-week`}
            render={pageProps => <SalesByWeek {...props} {...pageProps} />}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/sku-performance`}
            render={pageProps => <SkuPerformance {...props} {...pageProps} />}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/basket-overview`}
            render={pageProps => <BasketOverview {...props} {...pageProps} />}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/basket-analysis`}
            render={pageProps => <BasketAnalysis {...props} {...pageProps} />}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/basket-product-analysis`}
            render={pageProps => (
              <BasketProductAnalysis {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/gap-analysis-retail`}
            render={pageProps => (
              <RetailAnalysisReport {...props} {...pageProps} />
            )}
          />
          <Route
            path={`${process.env.APP_ROOT}retail/no-permissions`}
            render={pageProps => <p>You do not have any permissions set, please ask your administrator for access.</p>}
          />
          <Redirect
            from={`${process.env.APP_ROOT}retail`}
            to={`${process.env.APP_ROOT}${setDefaultRetailPage()}`}
          />
        </Switch>
      </AnimatePresence>
    </motion.div>
  );
};

Retail.defaultProps = {
  location: {},
};

Retail.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export default Retail;
