import React from 'react';
import { NavLink } from 'react-router-dom';
import { TopNavLinks } from '../../../AlchemySettings';
import { selectors } from '../../redux/reducers/app';
import { getUserSystem, getUserRoles } from '../../../helpers/util';

class NavMainMobileMenu extends React.Component {
  renderLinks = page => {
    if (page !== 'forgot_password') {
      const analysisMenus = selectors.getMenus().analysis;
      return TopNavLinks.filter(link => {
        if (link.label === 'Analysis' && analysisMenus && analysisMenus.length === 0) {
          return false;
        }
        if (link.label === 'Nam Dash' && getUserRoles().filter(role => role.Description === 'NAMDASH').length === 0) {
          return false;
        }
        if (link.label === 'Analysis' && getUserRoles().filter(role => role.Description === 'ANALYSIS').length === 0) {
          return false;
        }
        if (link.label === 'Query' && getUserRoles().filter(role => role.Description === 'QUERYBUILDER').length === 0) {
          return false;
        }
        return true;
      }).map((link, i) => {
        return (
          <li key={i} className={`list-inline-item ${link.label.replace(' ', '_').toLowerCase() === page.replace('_new', '') ? 'active' : ''}`}>
            <NavLink to={link.url} className={`${link.label.replace(' ', '_').toLowerCase() === page.replace('_new', '') ? (getUserSystem() === 'unitas' ? 'active--unitas' : 'active--others') : getUserSystem() === 'unitas' ? 'alchemy-lnk--unitas' : 'alchemy-lnk--others'}`}>
              <span>{link.label}</span>
            </NavLink>
          </li>
        );
      });
    }
  };

  render() {
    const { page } = this.props;
    return <ul className="list-inline d-flex align-items-stretch justify-content-between text-uppercase">{this.renderLinks(page)}</ul>;
  }
}

export default NavMainMobileMenu;
