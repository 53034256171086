import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import { sendContactEmail } from '../redux/reducers/admin';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem } from '../../helpers/util';

const ContactUsPage = ({ data, onSendSupportEmail, onTextChange, onGoToDashboard }) => (
  <div className="o-poster o-background o-background--data u-center-wrap">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <div className="c-contact-page">
      <div className="c-contact-page__inner">
        <div className="c-contact-page__name">
          <h1 className="o-canvas__title">Request an account</h1>
        </div>
        <div id="mc_sd" data-username="Matt Chick" data-email="support@wsale.co.uk">
          <div className="o-canvas__header-item u-layout t-brand-c">
            <div className="u-1_1">
              <div className="u-1_2">
                <label className="c-contact-page__label" htmlFor="name">
                  Name
                </label>
                <input type="text" id="name" name="name" value={data.name || ''} onChange={onTextChange} />
                <label className="c-contact-page__label" htmlFor="email">
                  Email
                </label>
                <input type="email" id="email" name="email" value={data.email || ''} onChange={onTextChange} />
                <label className="c-contact-page__label" htmlFor="email">
                  Supplier
                </label>
                <input type="text" id="supplier" name="supplier" value={data.supplier || ''} onChange={onTextChange} />
                <label className="c-contact-page__label" htmlFor="email">
                  Requestor
                </label>
                <input type="text" id="requestor" name="requestor" value={data.requestor || ''} onChange={onTextChange} />
                <button type="submit" className={`btn u-right alchemy-btn--${getUserSystem() === 'unitas' ? 'unitas' : 'others'}`} onClick={onSendSupportEmail}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isSupportEmailSent: state.admin.isSupportEmailSent,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, { sendContactEmail }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.isSupportEmailSent === false && this.props.isSupportEmailSent === true) {
        toast.info('Support Email sent successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  }),
  withState('data', 'handleData', {
    name: '',
    email: '',
    supplier: '',
    requestor: '',
  }),
  withHandlers({
    onGoToDashboard: (props) => () => {
      props.history.push(`${process.env.APP_ROOT}dashboard`);
    },
    onTextChange: ({ data, handleData }) => ({ target }) => {
      const { name, value } = target;
      handleData({ ...data, [name]: value });
    },
    onSendSupportEmail: ({ data, handleData, sendContactEmail }) => (selectedOption) => {
      sendContactEmail(data);
      handleData({ subject: '', description: '' });
    },
  }),
)(ContactUsPage);
