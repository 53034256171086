export default {
  Department: '',
  Category: '',
  SupplierName: '',
  Description: '',
  Logo: '',
  DistributionEngland: 0,
  DistributionScotland: 0,
  DistributionWales: 0,
  DistributionNI: 0,
  Retail: 0,
  Wholesale: 0,
  Foodservices: 0,
  OnTrade: 0,
  Advert1: '',
  Advert2: '',
  Advert3: '',
  Advert4: '',
  Advert5: '',
  Details: '',
  RetailerTerms: '',
  MinimumDrop: '',
  JobTitle: '',
  ContactName: '',
  TelephoneNumber: '',
  EmailAddress: '',
  ID: '',
  Updated: '',
  NewUntil: ''
}
