// Check for select all and dispatch selection

export const dropdownSelectionHelper = (
  selection,
  reportType,
  selectionType,
  allOptions,
  selectedOptions,
  action,
  dispatch,
) => {
  const isSelectAll = selection.filter(option => option.ID === 0).length > 0;

  if (isSelectAll) {
    // Select All dropdown items
    dispatch(
      action({
        [selectionType]: allOptions,
        reportType: reportType,
      }),
    );
  } else if (
    selectedOptions.filter(option => option.ID === 0).length > 0 &&
    !isSelectAll
  ) {
    // Remove Select All
    dispatch(
      action({
        [selectionType]: [],
        reportType,
      }),
    );
  } else {
    // Select Specific
    dispatch(
      action({
        [selectionType]: selection,
        reportType,
      }),
    );
  }
};
