import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import ReactModal from 'react-modal';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { getUserSystem } from '../../../helpers/util';
import { withTheme } from 'styled-components';
import {
  renameQuery,
  fetchScheduledHistory,
  setSelectedQuery,
  reRunQuery,
  downloadQuery,
  deleteQuery,
} from '../../redux/reducers/query';
import { ToastContainer, toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { pageVariants } from '../../../config/pageVariants';

const QueryScheduled = ({
  data,
  queries,
  loadMoreQueries,
  scheduleQuery,
  onDownloadQuery,
  onDeleteQuery,
  onRenameQuery,
  onQueryNameChange,
  closeModal,
  saveQuery,
  theme,
  reRunQuery,
  isLoading,
}) => (
  <motion.main
    key={'query-step1-id'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName="c-alchemy-toasty c-alchemy-toasty--query"
    />
    <div className="container mt-40">
      <div className="row">
        <div className="col">
          <div className="query-builder query-builder__steps">
            <h2 className="text-uppercase mb-4">Query Scheduler</h2>
            <hr />
          </div>
          <div className="row query-builder">
            {queries.length > 0 &&
              queries.map((query, key) => {
                return (
                  <div style={{ width: '100%' }} key={key}>
                    <div className='ml-3 mt-3'>
                      <p className="mb-0">Name: {query.Name}</p>
                      <p className="mb-0"> Query: {query.id} </p>
                      <p className="mb-0">
                        Ran at:{' '}
                        {query.LastRan &&
                          moment(query.LastRan).format('h:mm a, DD MMMM, YYYY')}
                      </p>
                      <p className="mb-0">
                        {' '}
                        {query.Run_Monthly !== null &&
                          query.Run_Monthly === '1' && (
                            <span>{'Scheduled - Monthly'}</span>
                          )}
                        {query.Run_Weekly !== null &&
                          query.Run_Weekly === '1' && (
                            <span>{'Scheduled - Weekly'}</span>
                          )}
                      </p>
                    </div>
                    <div className="u-layout u-layout--between" key={key}>
                      <div
                        className="o-wrap o-wrap--summary u-1_3"
                        style={{
                          margin: '10px',
                          marginBottom: '10px',
                          width: '100%',
                          overflow: 'hidden',
                        }}>
                        <p>
                          <strong>Measures:</strong> {query.Measures.join(', ')}
                        </p>
                        <p>
                          <strong>Time Frames:</strong>{' '}
                          {query.TimeFrames.map((timeframe, i) => {
                            return (
                              <span key={i}>
                                StartYearNoWeekNo: {timeframe.startYearNoWeekNo}{' '}
                                EndYearNoWeekNo: {timeframe.endYearNoWeekNo}
                              </span>
                            );
                          })}
                        </p>
                        <p>
                          <strong>Wholesalers:</strong>{' '}
                          {query.Wholesalers.map(
                            wholesaler => wholesaler.wholesalerName,
                          ).join(', ')}
                        </p>
                        <p>
                          <strong>Data Type: </strong>{query.IsRetail === 1 ? "Retail" : "Wholesale"}
                        </p>
                      </div>
                      <div
                        className="o-wrap o-wrap--summary u-1_3"
                        style={{
                          margin: '10px',
                          marginBottom: '10px',
                          width: '100%',
                          overflow: 'hidden',
                        }}>
                        <p>
                          <strong>Chosen Fields</strong>
                        </p>
                        <p> {query.ChosenFields.join(', ')}</p>
                      </div>
                      <div
                        className="o-wrap o-wrap--summary u-1_3"
                        style={{
                          margin: '10px',
                          marginBottom: '10px',
                          width: '100%',
                          overflow: 'hidden',
                        }}>
                        <p>
                          <strong>Chosen Filters</strong>
                        </p>
                        <p>
                          {query.Filters.map((filter, i) => {
                            return (
                              <span key={i}>
                                Field: {filter.field} Value:{' '}
                                {filter.value[0].fieldKey}
                              </span>
                            );
                          })}
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        className={`btn alchemy-btn--${
                          getUserSystem() === 'unitas' ? 'unitas' : 'others'
                        }`}
                        style={{ margin: '10px' }}
                        onClick={() => onDownloadQuery(query.id)}>
                        Download File
                      </button>
                      <button
                        className={`btn alchemy-btn--${
                          getUserSystem() === 'unitas' ? 'unitas' : 'others'
                        }`}
                        style={{ backgroundColor: '#4a5758', margin: '10px' }}
                        onClick={() => reRunQuery(query)}>
                        Rerun
                      </button>
                      <button
                        className={`btn alchemy-btn--${
                          getUserSystem() === 'unitas' ? 'unitas' : 'others'
                        }`}
                        style={{ backgroundColor: '#4a5758', margin: '10px' }}
                        onClick={() => scheduleQuery(query)}>
                        Schedule
                      </button>
                      <button
                        className={`btn alchemy-btn--${
                          getUserSystem() === 'unitas' ? 'unitas' : 'others'
                        }`}
                        style={{ backgroundColor: '#4a5758', margin: '10px' }}
                        onClick={() => onDeleteQuery(query.id)}>
                        Delete
                      </button>
                      <button
                        className={`btn alchemy-btn--${
                          getUserSystem() === 'unitas' ? 'unitas' : 'others'
                        }`}
                        style={{ backgroundColor: '#4a5758', margin: '10px' }}
                        onClick={() => onRenameQuery(query.id)}>
                        Rename
                      </button>
                      <ReactModal
                        isOpen={data.showModal}
                        contentLabel=""
                        className="performance-modal">
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%',
                            top: '30%',
                            position: 'relative',
                            margin: '0 auto',
                          }}>
                          <h4
                            style={{
                              color: theme.fontColor,
                              fontFamily: theme.font,
                            }}>
                            Query name
                          </h4>
                          <div class="d-flex justify-content-between">
                            <input
                              type="text"
                              name="queryName"
                              value={data.queryName}
                              onChange={onQueryNameChange}
                            />
                            <div class="d-flex">
                              <button
                                onClick={closeModal}
                                className={`btn mr-3`}
                                style={{
                                  backgroundColor: theme.buttons,
                                  borderColor: theme.buttons,
                                }}>
                                Cancel
                              </button>
                              <button
                                onClick={saveQuery}
                                className={`btn`}
                                style={{
                                  backgroundColor: theme.buttons,
                                  borderColor: theme.buttons,
                                }}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </ReactModal>
                    </div>
                  </div>
                );
              })}
            {queries.length > 0 && (
              <button
                style={{ margin: '20px auto' }}
                className={`btn alchemy-btn--${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                }`}
                onClick={loadMoreQueries}>
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  </motion.main>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.query.isLoading,
    queries: state.query.queries,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchScheduledHistory,
    downloadQuery,
    deleteQuery,
    setSelectedQuery,
    renameQuery,
    reRunQuery,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchScheduledHistory(10);
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Data.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
      }
    },
  }),
  withTheme,
  withState('data', 'handleData', {
    showModal: false,
    queryId: '',
    queryName: '',
  }),
  withHandlers({
    onQueryNameChange: ({ data, handleData }) => event => {
      event.preventDefault();
      const { value } = event.target;
      handleData({ ...data, queryName: value });
    },
    loadMoreQueries: ({ queries }) => value => {
      fetchScheduledHistory(queries.length + 10);
    },
    scheduleQuery: ({ setSelectedQuery }) => query => {
      setSelectedQuery(query);
    },
    onDownloadQuery: ({ downloadQuery }) => id => {
      downloadQuery(id);
    },
    onDeleteQuery: ({ deleteQuery }) => id => {
      deleteQuery(id);
    },
    onRenameQuery: ({ data, handleData, queries }) => id => {
      let query = queries.filter(qr => qr.id === id)[0];
      handleData({
        ...data,
        showModal: true,
        queryId: id,
        queryName: query.Name,
      });
    },
    closeModal: ({ data, handleData }) => () => {
      handleData({ ...data, showModal: false });
    },
    saveQuery: ({ data, handleData, renameQuery }) => () => {
      handleData({ ...data, showModal: false });
      renameQuery(data.queryId, data.queryName);
    },
  }),
)(QueryScheduled);
