import axios from 'axios';
import { handleActions, createAction } from 'redux-actions';
import { requestGet } from '../../../helpers/axios';
import { getToken, getUserId, getUserSystem } from '../../../helpers/util';
import store from '../store';
import { lte, sortBy, filter } from 'lodash';
import { setAccountNames } from './performanceReport';

const SET_ADVANCED_BUSINESS_TYPES = 'filters/SET_ADVANCED_BUSINESS_TYPES';
const SET_SELECTED_ADVANCED_BUSINESS_TYPE =
  'filters/SET_SELECTED_ADVANCED_BUSINESS_TYPE';
const SET_ACCOUNT_NAMES = 'filters/SET_ACCOUNT_NAMES';
const SET_SELECTED_ACCOUNT_NAME = 'filters/SET_SELECTED_ACCOUNT_NAME';
const RESET = 'filter/RESET';

const initialState = {
  advancedBusinessTypesEnabled: false,
  advancedBusinessTypes: [],
  selectedAdvancedBusinessTypes: [],
  accountName: [],
  accountNames: [],
};

const setBusinessTypesAction = createAction(SET_ADVANCED_BUSINESS_TYPES);
const setSelectedAdvancedBusinessTypesAction = createAction(
  SET_SELECTED_ADVANCED_BUSINESS_TYPE,
);
const setAccountNamesAction = createAction(SET_ACCOUNT_NAMES);
const setSelectedAccountNameAction = createAction(SET_SELECTED_ACCOUNT_NAME);
const resetReportAction = createAction(RESET);

export const fetchBusinessTypes = () => async dispatch => {
  const url = '/route.php?c=customer/getAdvancedBusinessTypes';
  const businessTypes = await requestGet({ url });
  const formattedBusinessTypes = businessTypes?.map(({ Values, ...rest }) => {
    let values = sortBy(
      Values?.map(val => ({ value: val, label: val })),
      'label',
    );

    values.length > 0 &&
      values.unshift({ value: 'Select All', label: 'Select All' });

    return {
      ...rest,
      values,
    };
  });
  dispatch(setBusinessTypesAction(formattedBusinessTypes));
};

export const selectAdvancedBusinessTypes = options => async dispatch => {
  const { advancedBusinessTypes, selectedAdvancedBusinessTypes } =
    store.getState().filters;
  // const state = store.getState();
  // const advancedBusinessTypes = state.filters.advancedBusinessTypes;
  // const selectedBusinessTypes = state.filters.selectedAdvancedBusinessTypes;

  const allOptionsFromCurrentDropdown = advancedBusinessTypes.find(
    businessType => businessType.ApiName === options.ApiName,
  )?.values;

  const selectedValuesFromCurrentDropdown = selectedAdvancedBusinessTypes.find(
    businessType => businessType.ApiName === options.ApiName,
  );

  if (
    options?.value?.find(businessType => businessType.label === 'Select All')
  ) {
    // Add all when select all is picked

    dispatch(
      setSelectedAdvancedBusinessTypesAction({
        value: allOptionsFromCurrentDropdown,
        ApiName: options.ApiName,
        DisplayName: options.DisplayName,
      }),
    );
  } else if (
    !options?.value?.find(
      businessType => businessType.label === 'Select All',
    ) &&
    filter(selectedValuesFromCurrentDropdown.value, { label: 'Select All' })
      .length === 1
  ) {
    // Remove all if select all is removed
    dispatch(
      setSelectedAdvancedBusinessTypesAction({
        value: [],
        ApiName: options.ApiName,
        DisplayName: options.DisplayName,
      }),
    );
  } else {
    // Add single value
    dispatch(setSelectedAdvancedBusinessTypesAction(options));
  }

  let url = '/route.php?c=customer/getRetailers';

  selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
    if (value.length) {
      url += `&${encodeURIComponent(ApiName)}=${value
        .map(val => encodeURIComponent(val.value))
        .join(',')}`;
    }
  });

  const accountNames = await requestGet({ url });

  dispatch(setAccountNamesAction(accountNames));
};

export const fetchAccountNames = () => async dispatch => {
  const allAccountsUrl = '/route.php?c=customer/getRetailers';

  const accountNames = await requestGet({ url: allAccountsUrl });

  if (getUserSystem() == 'cjlangRetail') {
    dispatch(setSelectedAccountNameAction(accountNames));
  }

  dispatch(setAccountNamesAction(accountNames));
};

export const setAccountName = val => dispatch => {
  dispatch(setSelectedAccountNameAction(val));
};

export const resetFilters = () => dispatch => {
  dispatch(resetReportAction());
};

export default handleActions(
  {
    [SET_ADVANCED_BUSINESS_TYPES]: (state, { payload }) => ({
      ...state,
      advancedBusinessTypesEnabled: payload.lenght > 0,
      advancedBusinessTypes: payload,
      selectedAdvancedBusinessTypes: payload.map(({ values, ...rest }) => ({
        value: [],
        ...rest,
      })),
    }),
    [SET_SELECTED_ADVANCED_BUSINESS_TYPE]: (state, { payload }) => {
      const index = state.advancedBusinessTypes.findIndex(
        busType => busType.ApiName === payload.ApiName,
      );
      const selectedDropdown = state.selectedAdvancedBusinessTypes[index];
      selectedDropdown.value = payload.value;
      return {
        ...state,

        selectedAdvancedBusinessTypes: [
          ...state.selectedAdvancedBusinessTypes.slice(0, index),
          selectedDropdown,
          ...state.selectedAdvancedBusinessTypes.slice(index + 1),
        ],
      };
    },
    [SET_SELECTED_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName: payload,
    }),
    [SET_ACCOUNT_NAMES]: (state, { payload }) => ({
      ...state,
      accountNames: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      selectedAdvancedBusinessTypes: state.advancedBusinessTypes.map(
        ({ values, ...rest }) => ({
          value: '',
          ...rest,
        }),
      ),
      accountName: [],
    }),
  },
  initialState,
);
