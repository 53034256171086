export default {
  Department: '',
  Category: '',
  DocumentURL: '',
  Title: '',
  Description: '',
  ImageName: '',
  DisplayOrder: '',
  Dimensions: '',
  Country: '',
  ID: '',
  Updated: ''
}
