'use strict';
import { handleActions, createAction } from 'redux-actions';

import { requestBlob } from '../../../helpers/axios';
import {
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';

const SET_LOADING = 'downloadCube/SET_LOADING';
const SET_IS_DOWNLOADING = 'downloadCube/SET_IS_DOWNLOADING';

const initialState = {
  isLoading: false,
  isDownloading: false,
};

const setLoadingAction = createAction(SET_LOADING);
const setIsDownloadingAction = createAction(SET_IS_DOWNLOADING);


export const downloadQuery = () => dispatch => {
  dispatch(setIsDownloadingAction(true));
  let url = `/route.php?c=cube/downloadCube`;
  dispatch(setLoadingAction(true));
  requestBlob({
    url: url,
    headers: {
      'content-type': 'application/vnd.ms-excel;charset=UTF-8',
      Authorization: getToken(),
      System: getUserSystem(),
      UserId: getUserId(),
    },
    responseType: 'blob',
    method: 'GET',
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click();
      dispatch(setLoadingAction(false));
      dispatch(setIsDownloadingAction(false));
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setIsDownloadingAction(false));
    });
};


export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_IS_DOWNLOADING]: (state, { payload }) => ({
      ...state,
      isDownloading: payload,
    }),
  },
  initialState,
);
