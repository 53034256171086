import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import currentUser from './currentUser';
import settings from './settings';
import alerts from './alerts';
import systemSettings from './systemSettings';
import app from './app';
import user from './user';
import admin from './admin';
import query from './query';
import pfpAdvert from './pfpAdvert';
import pfpDeal from './pfpDeal';
import performanceReport from './performanceReport';
import areaHeatmapReport from './areaHeatmapReport';
import stockedReport from './stockedReport';
import productPerformanceReport from './productPerformanceReport';
import bestSellerReport from './bestSellerReport';
import skuTrackerReport from './skuTrackerReport';
import productSearch from './productSearch';
import kpi from './kpi';
import downloadCube from './downloadCube';
import priceSensitivityReport from './priceSensitivityReport';
import reportBuilder from './reportBuilder';
import psa from './psa';
import pfpDropship from './pfpDropship';
import pfpNpd from './pfpNpd';
import pfpPlanogram from './pfpPlanogram';
import pfpWholesaler from './pfpWholesaler';
import pfpCoreRange from './pfpCoreRange';
import qaReport from './qaReport';
import weeklyUpdate from './weeklyUpdate';
import complianceReport from './complianceReport';
import likeForLike from './likeForLike';
import retail from './retail';
import supplierEngagement from './supplierEngagement';
import filters from './filters';

const rootReducer = combineReducers({
  currentUser,
  user,
  settings,
  alerts,
  systemSettings,
  psa,
  app,
  admin,
  pfpAdvert,
  pfpDeal,
  query,
  performanceReport,
  areaHeatmapReport,
  stockedReport,
  reportBuilder,
  bestSellerReport,
  productPerformanceReport,
  skuTrackerReport,
  productSearch,
  kpi,
  downloadCube,
  priceSensitivityReport,
  routing: routerReducer,
  pfpDropship,
  pfpNpd,
  pfpPlanogram,
  pfpWholesaler,
  pfpCoreRange,
  qaReport,
  complianceReport,
  weeklyUpdate,
  likeForLike,
  retail,
  supplierEngagement,
  filters,
});

export default rootReducer;
