import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import HtmlEditor from '../HtmlEditor';

const yesNoOptions = [
  { ID: 1, Text: 'Yes' },
  { ID: 0, Text: 'No' },
];

const AddWholesaler = ({
  isLoading,
  wholesalerTypes,
  targetTypes,
  pages,
  positions,
  displayOrder,
  statuses,
  wholesalerForm,
  onSaveWholesaler,
  onFormSelectChange,
  onFormTextChange,
  onFormFileChange,
  onGroceryChange,
  onImpulseChange,
  onNonFoodsChange,
  onLicensedChange,
  onTobaccoChange,
  onFrozenChange,
  onChilledChange,
  onCateringChange,
  onOnTradeChange,
  handleWholesalerAddModal,
}) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{wholesalerForm.ID ? 'Update' : 'Add New'} Wholesaler </span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Name Of Company</h3>
              <input type="text" id="nameOfCompany" className="ReactModal--input-green" name="nameOfCompany" value={wholesalerForm.NameOfCompany || ''} onChange={evt => onFormTextChange('NameOfCompany', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Company Trading Name</h3>
              <input type="text" id="companyTradingName" className="ReactModal--input-green" name="companyTradingName" value={wholesalerForm.CompanyTradingName || ''} onChange={evt => onFormTextChange('CompanyTradingName', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Street Address</h3>
              <input type="text" id="streetAddress" className="ReactModal--input-green" name="streetAddress" value={wholesalerForm.StreetAddress || ''} onChange={evt => onFormTextChange('StreetAddress', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Town</h3>
              <input type="text" id="town" className="ReactModal--input-green" name="town" value={wholesalerForm.Town || ''} onChange={evt => onFormTextChange('Town', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">City</h3>
              <input type="text" id="city" className="ReactModal--input-green" name="city" value={wholesalerForm.City || ''} onChange={evt => onFormTextChange('City', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">County</h3>
              <input type="text" id="county" className="ReactModal--input-green" name="county" value={wholesalerForm.County || ''} onChange={evt => onFormTextChange('County', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Country</h3>
              <input type="text" id="country" className="ReactModal--input-green" name="country" value={wholesalerForm.Country || ''} onChange={evt => onFormTextChange('Country', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Post Code</h3>
              <input type="text" id="postCode" className="ReactModal--input-green" name="postCode" value={wholesalerForm.PostCode || ''} onChange={evt => onFormTextChange('PostCode', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Phone No</h3>
              <input type="text" id="phoneNo" className="ReactModal--input-green" name="phoneNo" value={wholesalerForm.PhoneNo || ''} onChange={evt => onFormTextChange('PhoneNo', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Email</h3>
              <input type="text" id="email" className="ReactModal--input-green" name="email" value={wholesalerForm.Email || ''} onChange={evt => onFormTextChange('Email', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Website</h3>
              <input type="text" id="website" className="ReactModal--input-green" name="website" value={wholesalerForm.Website || ''} onChange={evt => onFormTextChange('Website', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Lat</h3>
              <input type="text" id="lat" className="ReactModal--input-green" name="lat" value={wholesalerForm.Lat || ''} onChange={evt => onFormTextChange('Lat', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Lon</h3>
              <input type="text" id="lon" className="ReactModal--input-green" name="lon" value={wholesalerForm.Lon || ''} onChange={evt => onFormTextChange('Lon', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Grocery</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Grocery) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onGroceryChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Impulse</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Impulse) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onImpulseChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Non Foods</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm['Non Foods']) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onNonFoodsChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Licensed</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Licensed) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onLicensedChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Tobacco</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Tobacco) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onTobaccoChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Frozen</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Frozen) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onFrozenChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Chilled</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Chilled) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onChilledChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Catering</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm.Catering) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onCateringChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">On Trade</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(wholesalerForm['On Trade']) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onOnTradeChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Description</h3>
              <input type="text" id="description" className="ReactModal--input-green" name="description" value={wholesalerForm.Description || ''} onChange={evt => onFormTextChange('Description', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Catalogue</h3>
              <input type="file" name="Catalogue" onChange={evt => onFormFileChange('Catalogue', evt.target.files[0])} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSaveWholesaler()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handleWholesalerAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddWholesaler;
