import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import WeeklyReport from '../../components/WeeklyReport';
//TODO: Cancel, delete and edit (pencil)
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem } from '../../../helpers/util';
import Toggle from 'react-toggle';
import { orderBy, map, filter, each } from 'lodash';
import {
  fetchDefaultData,
  saveWeeklyUpdate,
  getReportNames,
  saveRecipients,
  previewWeeklyUpdate,
  fetchUpdates,
  deleteReport,
  cloneReport,
  departmentChange,
  recipientChange,
  categoryChange,
  subCategoryChange,
  level4Change,
  level5Change,
  memberChange,
  supplierChange,
  businessTypeChange,
  measureChange,
  timeframeChange,
  updateEnabledChange,
  includedChange,
  setReportValidationFailed,
  sortReports,
  setActiveReport,
  createNewReport,
  setReportLimit,
  setReportDay,
  setReportPeriod,
  resetReports,
  clearReports,
  setIsNameEditable,
  setNewName,
} from '../../redux/reducers/weeklyUpdate';

const WeeklyUpdate = ({
  data,
  reports,
  isNameEditable,
  departments,
  suppliers,
  businessTypes,
  members,
  recipients,
  onChange,
  onReportChange,
  toggleEditName,
  updateNewName,
  onReset,
  onSave,
  onSavePreview,
  measures,
  timeframes,
  isPreviewDownloading,
  isUpdateEnabled,
  reportNames,
  newName,
  activeReportName,
  onRecipientChange,
  onSubmitRecipients,
  onDeleteReport,
  onCloneReport,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onMemberChange,
  onSupplierChange,
  onBusinessTypeChange,
  onDepartmentChange,
  onMeasureChange,
  onTimeframeChange,
  onLimitChange,
  onMoveDown,
  onMoveUp,
  onDayChange,
  onPeriodChange,
}) => (
  <main>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      autoClose={false}
      closeButton={false}
      toastClassName="c-alchemy-toasty c-alchemy-toasty--query"
    />
    <div className="container mt-40">
      <div className="row">
        <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
          <div className="sub-menu__left d-flex flex-column justify-content-between">
            <div className="d-flex align-items-center theme-font">
              <p className="mb-0 mr-3">Report: </p>
              {!isNameEditable ? (
                <div className="d-flex w-50 align-items-center">
                  <Select
                    placeholder="MY KEY FACTS REPORT"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`w-75 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                      getUserSystem() === 'unitas'
                        ? 'c-select-header-row--unitas'
                        : 'c-select-header-row--others'
                    }`}
                    name="selectedReport"
                    id="selectedReport"
                    onChange={opt => onReportChange(opt)}
                    options={reportNames}
                    value={{"label": newName, "value": newName}}
                    valueKey="value"
                    labelKey="label"
                    isMulti={false}
                    clearable={false}
                    searchable={false}
                  />
                  <EditIcon className="ml-2" style={{ cursor: 'pointer' }} onClick={() => { toggleEditName(true) }} />
                </div>
            ) : (
              <div className="d-flex align-items-center">
                <input type="text" 
                className="filter-text-box" 
                onChange={e => updateNewName(e.target.value)} 
                value={newName} autoFocus />
                <DoneIcon className="ml-2" style={{ cursor: 'pointer' }} onClick={() => { toggleEditName(false) }} />
              </div>
            )}
            </div>
            <div className="d-flex align-items-center theme-font">
              <p className="mb-0 mr-3">Copied Recipients</p>
              <input
                type="text"
                className="recipient-input mr-3"
                name="copiedRecipients"
                placeholder="example@example.com, example2@example.com"
                value={recipients}
                onInput={onRecipientChange}
              />
              <button
                onClick={onSubmitRecipients}
                className={`btn ${
                  getUserSystem() === 'unitas'
                    ? 'alchemy-btn--unitas'
                    : 'alchemy-btn--others'
                }`}>
                Submit
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div
                className={`col-4 toggle-${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                }`}>
                <p className="pull-left">YOUR KEY FACTS REPORT IS {isUpdateEnabled ? "ON" : "OFF"}</p>
                <Toggle
                  id="updateEnabled"
                  icons={false}
                  checked={isUpdateEnabled}
                  onChange={onChange}
                />
              </div>
              <div
                className={`col-8 toggle-${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                }`}>
                <p className="pull-left mb-0">
                  SEND ME REPORT ON&nbsp;
                </p> <br/>
                <Select
                  placeholder="SELECT DAY"
                  className={`wd-140 mr-3 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name={`day`}
                  id={`day`}
                  options={data.days}
                  onChange={opt => onDayChange(opt)}
                  value={data.day}
                />
                <Select
                  placeholder="SELECT PERIOD"
                  className={`wd-140 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name={`period`}
                  id={`period`}
                  options={data.periods}
                  onChange={opt => onPeriodChange(opt)}
                  value={data.period}
                />
              </div>
            </div>
          </div>
        </div>
        <p>
          Your Key Facts Report allows you to get the latest insight direct to
          your inbox, simply use the configuration settings below to tailor the
          data you receive.
        </p>
      </div>
      <hr />
      {orderBy(reports, ['sortOrder'], ['asc']).map((rpt, idx) => {
        return (
          <WeeklyReport
            onLevel4Change={onLevel4Change}
            onLevel5Change={onLevel5Change}
            onSubCategoryChange={onSubCategoryChange}
            onCategoryChange={onCategoryChange}
            onMemberChange={onMemberChange}
            onSupplierChange={onSupplierChange}
            onBusinessTypeChange={onBusinessTypeChange}
            onMeasureChange={onMeasureChange}
            onDepartmentChange={onDepartmentChange}
            onTimeframeChange={onTimeframeChange}
            measures={measures}
            timeframes={timeframes}
            report={rpt}
            index={idx + 1}
            key={idx}
            categories={rpt.allCategories}
            subCategories={rpt.allSubCategories}
            level4s={rpt.allLevel4s}
            level5s={rpt.allLevel5s}
            departments={departments}
            suppliers={suppliers}
            members={members}
            businessTypes={businessTypes}
            onChange={onChange}
            onCloneReport={onCloneReport}
            onDeleteReport={onDeleteReport}
            totalNoOfReports={reports.length}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            limits={data.limits}
            onLimitChange={onLimitChange}
          />
        );
      })}
      {data.message.length > 0 && (
        <p className="red">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
          {data.message}
        </p>
      )}
      <button
        className={`pull-right btn btn-dark-gray`}
        style={{ margin: '10px' }}
        onClick={() => onReset()}>
        Reset
      </button>
      <button
        className={`pull-right btn alchemy-btn--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
        style={{ backgroundColor: '#4a5758', margin: '10px' }}
        onClick={() => onSave()}>
        Save
      </button>
      <button
        disabled={isPreviewDownloading}
        className={`pull-right btn alchemy-btn--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
        style={{ backgroundColor: '#4a5758', margin: '10px' }}
        onClick={() => onSavePreview()}>
        Save & Preview
      </button>
    </div>
  </main>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.weeklyUpdate.isLoading,
    isLoadingReports: state.weeklyUpdate.isLoadingReports,
    isPreviewDownloading: state.weeklyUpdate.isPreviewDownloading,
    reportNames: state.weeklyUpdate.reportNames,
    newName: state.weeklyUpdate.newName,
    activeReportName: state.weeklyUpdate.activeReportName,
    departments: state.weeklyUpdate.allDepartments,
    members: state.weeklyUpdate.members,
    suppliers: state.weeklyUpdate.suppliers,
    businessTypes: state.weeklyUpdate.businessTypes,
    isUpdateSaved: state.weeklyUpdate.isUpdateSaved,
    isRecipientErrored: state.weeklyUpdate.isRecipientErrored,
    isUpdateEnabled: state.weeklyUpdate.isUpdateEnabled,
    reports: state.weeklyUpdate.reports,
    measures: state.weeklyUpdate.measures,
    isWeeklyUpdateStatusChanged: state.weeklyUpdate.isWeeklyUpdateStatusChanged,
    isWeeklyUpdatesDeleted: state.weeklyUpdate.isWeeklyUpdatesDeleted,
    isNameEditable: state.weeklyUpdate.isNameEditable,
    timeframes: state.weeklyUpdate.timeframes,
    recipients: state.weeklyUpdate.recipients,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchDefaultData,
    fetchUpdates,
    getReportNames,
    saveWeeklyUpdate,
    saveRecipients,
    previewWeeklyUpdate,
    deleteReport,
    cloneReport,
    departmentChange,
    categoryChange,
    subCategoryChange,
    level4Change,
    level5Change,
    recipientChange,
    memberChange,
    supplierChange,
    businessTypeChange,
    measureChange,
    timeframeChange,
    updateEnabledChange,
    includedChange,
    setReportValidationFailed,
    sortReports,
    setIsNameEditable,
    setNewName,
    setReportLimit,
    setReportDay,
    setActiveReport,
    createNewReport,
    setReportPeriod,
    resetReports,
    clearReports,
  }),
  withState('data', 'handleData', {
    message: '',
    limit: { label: '10', value: 10 },
    days: [
      { label: 'Sunday', value: 'Sunday' },
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' },
      { label: 'Sunday', value: 'Sunday' },
    ],
    day: '',
    periods: [
      { label: 'Weekly', value: 'Weekly'},
      { label: 'Every 4 Weeks', value: 'Every 4 Weeks'},
    ],
    period: '',
    limits: [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '15', value: 15 },
      { label: '20', value: 20 },
      { label: '25', value: 25 },
    ],
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetReports();
      this.props.fetchDefaultData();
      this.props.getReportNames();
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isWeeklyUpdatesDeleted !==
          this.props.isWeeklyUpdatesDeleted &&
        !this.props.isWeeklyUpdatesDeleted
      ) {
        toast.info('Please wait, this may take some time..');
      } else if (
        prevProps.isWeeklyUpdatesDeleted !==
          this.props.isWeeklyUpdatesDeleted &&
        this.props.isWeeklyUpdatesDeleted
      ) {
        toast.dismiss();
      }
      if (
        prevProps.isLoadingReports !== this.props.isLoadingReports &&
        this.props.isLoadingReports
      ) {
        toast.info('Please wait, this may take some time..');
      } else if (
        prevProps.isLoadingReports !== this.props.isLoadingReports &&
        !this.props.isLoadingReports
      ) {
        toast.dismiss();
      }
      if (
        prevProps.isPreviewDownloading !== this.props.isPreviewDownloading &&
        this.props.isPreviewDownloading
      ) {
        toast.info('Please wait, this may take some time..');
      } else if (
        prevProps.isPreviewDownloading !== this.props.isPreviewDownloading &&
        !this.props.isPreviewDownloading
      ) {
        toast.dismiss();
      }
      if (
        prevProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Please wait, this may take some time..');
      } else if (
        prevProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
      }
      if (
        prevProps.isUpdateSaved !== this.props.isUpdateSaved &&
        this.props.isUpdateSaved
      ) {
        toast.dismiss();
        toast.info('Update saved successfully.');
      }
      if (
        prevProps.departments !== this.props.departments &&
        this.props.departments.length > 0
      ) {
        this.props.fetchUpdates(this.props.activeReportName.value);
      }
      if (
        prevProps.isWeeklyUpdateStatusChanged !==
          this.props.isWeeklyUpdateStatusChanged &&
        this.props.isWeeklyUpdateStatusChanged
      ) {
        toast.dismiss();
        toast.info('Key Facts Report saved successfully.');
      }
      if (
        prevProps.reports !== this.props.reports &&
        this.props.reports.length > 0
      ) {
        this.props.handleData({
          ...this.props.data,
          day: this.props.reports[0].day ? this.props.reports[0].day : '',
          period: this.props.reports[0].period ? this.props.reports[0].period : '',
        });
      }
      if (prevProps.isRecipientErrored) {
        toast.info(
          'You can only add emails from the same domain as the current user.',
          {
            autoClose: 5000,
          },
        );
      }
    },
  }),
  withHandlers({
    onDayChange:
      ({ setReportDay }) =>
      opt => {
        setReportDay(opt);
      },
    onPeriodChange:
      ({ setReportPeriod }) =>
      opt => {
        setReportPeriod(opt);
      },
    onLimitChange:
      ({ setReportLimit }) =>
      (key, opt) => {
        setReportLimit(key, opt);
      },
    onCloneReport:
      ({ cloneReport }) =>
      ev => {
        cloneReport(ev.target.id);
      },
    onMoveUp:
      ({ reports, sortReports }) =>
      (id, sOrder) => {
        const curReport = filter(reports, rpt => {
          if (rpt.reportKey === id && rpt.sortOrder === sOrder) {
            return rpt;
          }
        })[0];

        let newReports = [];

        each(reports, rpt => {
          if (rpt.sortOrder === curReport.sortOrder) {
            newReports.push({ ...rpt, sortOrder: rpt.sortOrder - 1 });
          } else if (rpt.sortOrder === curReport.sortOrder - 1) {
            newReports.push({ ...rpt, sortOrder: rpt.sortOrder + 1 });
          } else {
            newReports.push(rpt);
          }
        });

        sortReports(newReports);
      },
    onMoveDown:
      ({ reports, sortReports }) =>
      (id, sOrder) => {
        const curReport = filter(reports, rpt => {
          if (rpt.reportKey === id && rpt.sortOrder === sOrder) {
            return rpt;
          }
        })[0];

        let newReports = [];

        each(reports, rpt => {
          if (rpt.sortOrder === curReport.sortOrder) {
            newReports.push({ ...rpt, sortOrder: rpt.sortOrder + 1 });
          } else if (rpt.sortOrder === curReport.sortOrder + 1) {
            newReports.push({ ...rpt, sortOrder: rpt.sortOrder - 1 });
          } else {
            newReports.push(rpt);
          }
        });

        sortReports(newReports);
      },
    onReportChange: ({ fetchUpdates, setActiveReport, createNewReport, reportNames, newName }) => opt => {
        if (opt.value !== "createNew") {
          fetchUpdates(opt.value);
        } else {
          createNewReport();
        }
        setActiveReport(opt.value);      
    },
    updateNewName: ({ setNewName }) => (value) => {
      setNewName(value);
    },
    toggleEditName: ({ setIsNameEditable }) => (value) => {
      setIsNameEditable(value);
    },
    onReset:
      ({ fetchUpdates, clearReports }) =>
      ev => {
        clearReports();
      },
    onSavePreview:
      ({
        previewWeeklyUpdate,
        data,
        handleData,
        reports,
        setReportValidationFailed,
        isUpdateEnabled,
        activeReportName
      }) =>
      () => {
        if (!isUpdateEnabled) {
          handleData({
            ...data,
            message:
              'If you would like to recieve this report every week, please remember to enable the report using the switch on the top right of the page',
          });
          return;
        }
        let validations = [];
        const finalReports = map(reports, rpt => {
          if (
            rpt.isIncluded &&
            (rpt.measure.length === 0 ||
              (rpt.showInterval && rpt.timeframe.length === 0))
          ) {
            validations.push({
              reportKey: rpt.reportKey,
              sortOrder: rpt.sortOrder,
              value: true,
              message:
                'Please select values for department, timeframe and measure.',
            });
          } else {
            validations.push({
              reportKey: rpt.reportKey,
              sortOrder: rpt.sortOrder,
              value: false,
            });
          }
          let limit = 10;
          if (rpt.reportKey === 'supp_trend' || rpt.reportKey === 'cat_trend') {
            limit = 1;
          }
          if (
            rpt.limit &&
            rpt.reportKey !== 'supp_trend' &&
            rpt.reportKey !== 'cat_trend'
          ) {
            limit = rpt.limit.value ? rpt.limit.value : rpt.limit;
          }
          return {
            system: getUserSystem(),
            reportKey: rpt.reportKey,
            measure: rpt.measure,
            timeframe: rpt.timeframe,
            sortOrder: rpt.sortOrder,
            departmentFilters:
              rpt.departments && rpt.departments.length > 0
                ? rpt.departments.map(dpt => dpt.Text).join(',')
                : '',
            categoryFilters:
              rpt.categories && rpt.categories.length > 0
                ? rpt.categories.map(cat => cat.Text).join(',')
                : '',
            subCategoryFilters:
              rpt.subCategories && rpt.subCategories.length > 0
                ? rpt.subCategories.map(sub => sub.Text).join(',')
                : '',
            level4Filters:
              rpt.level4s && rpt.level4s.length > 0
                ? rpt.level4s.map(lvl => lvl.Text).join(',')
                : '',
            level5Filters:
              rpt.level5s && rpt.level5s.length > 0
                ? rpt.level5s.map(lvl => lvl.Text).join(',')
                : '',
            memberFilters:
              rpt.members && rpt.members.length > 0
                ? rpt.members.map(mem => mem.Text).join(',')
                : '',
            supplierFilters:
              rpt.suppliers && rpt.suppliers.Text ? rpt.suppliers.Text : '',
            businessTypeFilters:
              rpt.businessTypes && rpt.businessTypes.Text ? rpt.businessTypes.Text : '',
            enabled: rpt.isIncluded,
            cloned: rpt.isCloned,
            limit,
            day: data.day,
            period: data.period,
          };
        });

        const failedValidations = filter(
          validations,
          val => val.value === true,
        );

        const passedValidations = filter(
          validations,
          val => val.value === false,
        );

        if (failedValidations.length > 0) {
          each(failedValidations, val => {
            setReportValidationFailed({
              key: val.reportKey,
              sortOrder: val.sortOrder,
              value: true,
              message: val.message,
            });
          });
          return;
        }

        if (passedValidations.length > 0) {
          each(passedValidations, val => {
            setReportValidationFailed({
              key: val.reportKey,
              sortOrder: val.sortOrder,
              value: false,
            });
          });
        }
        previewWeeklyUpdate(finalReports, activeReportName);
      },
    onSave:
      ({
        data,
        handleData,
        saveWeeklyUpdate,
        reports,
        setReportValidationFailed,
        isUpdateEnabled,
        activeReportName
      }) =>
      ev => {
        if (!isUpdateEnabled) {
          handleData({
            ...data,
            message:
              'If you would like to recieve this report every week, please remember to enable the report using the switch on the top right of the page',
          });
          return;
        }
        let validations = [];
        const finalReports = map(reports, rpt => {
          if (
            rpt.isIncluded &&
            (rpt.measure.length === 0 ||
              (rpt.showInterval && rpt.timeframe.length === 0))
          ) {
            validations.push({
              reportKey: rpt.reportKey,
              sortOrder: rpt.sortOrder,
              value: true,
              message:
                'Please select values for department, timeframe and measure.',
            });
          } else {
            validations.push({
              reportKey: rpt.reportKey,
              sortOrder: rpt.sortOrder,
              value: false,
            });
          }
          let limit = 10;
          if (rpt.reportKey === 'supp_trend' || rpt.reportKey === 'cat_trend') {
            limit = 1;
          }
          if (
            rpt.limit &&
            rpt.reportKey !== 'supp_trend' &&
            rpt.reportKey !== 'cat_trend'
          ) {
            limit = rpt.limit.value ? rpt.limit.value : rpt.limit;
          }

          if (rpt.reportKey === 'supp_trend' && !rpt.suppliers) {
            validations.push({
              reportKey: rpt.reportKey,
              sortOrder: rpt.sortOrder,
              value: true,
              message: 'Please select supplier.',
            });
          }

          return {
            system: getUserSystem(),
            reportKey: rpt.reportKey,
            measure: rpt.measure,
            timeframe: rpt.timeframe,
            sortOrder: rpt.sortOrder,
            departmentFilters:
              rpt.departments && rpt.departments.length > 0
                ? rpt.departments.map(dpt => dpt.Text).join(',')
                : '',
            categoryFilters:
              rpt.categories && rpt.categories.length > 0
                ? rpt.categories.map(cat => cat.Text).join(',')
                : '',
            subCategoryFilters:
              rpt.subCategories && rpt.subCategories.length > 0
                ? rpt.subCategories.map(sub => sub.Text).join(',')
                : '',
            level4Filters:
              rpt.level4s && rpt.level4s.length > 0
                ? rpt.level4s.map(lvl => lvl.Text).join(',')
                : '',
            level5Filters:
              rpt.level5s && rpt.level5s.length > 0
                ? rpt.level5s.map(lvl => lvl.Text).join(',')
                : '',
            memberFilters:
              rpt.members && rpt.members.length > 0
                ? rpt.members.map(mem => mem.Text).join(',')
                : '',
            supplierFilters:
              rpt.suppliers && rpt.suppliers.Text ? rpt.suppliers.Text : '',
            businessTypeFilters:
              rpt.businessTypes && rpt.businessTypes.Text ? rpt.businessTypes.Text : '',
            enabled: rpt.isIncluded,
            cloned: rpt.isCloned,
            limit,
            day: data.day,
            period: data.period,
          };
        });

        const failedValidations = filter(
          validations,
          val => val.value === true,
        );

        const passedValidations = filter(
          validations,
          val => val.value === false,
        );

        if (failedValidations.length > 0) {
          each(failedValidations, val => {
            setReportValidationFailed({
              key: val.reportKey,
              sortOrder: val.sortOrder,
              value: true,
              message: val.message,
            });
          });
          return;
        }

        if (passedValidations.length > 0) {
          each(passedValidations, val => {
            setReportValidationFailed({
              key: val.reportKey,
              sortOrder: val.sortOrder,
              value: false,
            });
          });
        }
        saveWeeklyUpdate(finalReports, activeReportName);
        handleData({ ...data, message: '' });
      },
    onDeleteReport:
      ({ deleteReport }) =>
      ev => {
        deleteReport(ev.target.id);
      },
    onDepartmentChange:
      ({ departmentChange }) =>
      (opt, idx) => {
        departmentChange({
          categories:
            opt.length > 0 ? opt.flatMap(dept => dept.Categories) : [],
          department: opt.length > 0 ? opt : [],
          idx,
        });
      },
    onCategoryChange:
      ({ categoryChange }) =>
      (opt, idx) => {
        categoryChange({
          subCategories:
            opt.length > 0 ? opt.flatMap(cat => cat.SubCategories) : [],
          category: opt.length > 0 ? opt : [],
          idx,
        });
      },
    onSubCategoryChange:
      ({ subCategoryChange }) =>
      (opt, idx) => {
        subCategoryChange({
          level4s: opt.length > 0 ? opt.flatMap(subCat => subCat.Level4) : [],
          subCategory: opt.length > 0 ? opt : [],
          idx,
        });
      },
    onLevel4Change:
      ({ level4Change }) =>
      (opt, idx) => {
        level4Change({
          level5s: opt.length > 0 ? opt.flatMap(lvl4 => lvl4.Level5) : [],
          level4: opt.length > 0 ? opt : [],
          idx,
        });
      },
    onLevel5Change:
      ({ level5Change }) =>
      (opt, idx) => {
        level5Change({ level5: opt, idx });
      },
    onMemberChange:
      ({ memberChange }) =>
      (opt, idx) => {
        memberChange({ member: opt, idx });
      },
    onSupplierChange:
      ({ supplierChange }) =>
      (opt, idx) => {
        supplierChange({ supplier: opt, idx });
      },
    onBusinessTypeChange:
      ({ businessTypeChange }) =>
      (opt, idx) => {
        businessTypeChange({ businessType: opt, idx })
      },
    onMeasureChange:
      ({ measureChange }) =>
      (opt, idx) => {
        measureChange({ measure: opt, idx });
      },
    onTimeframeChange:
      ({ timeframeChange }) =>
      (opt, idx) => {
        timeframeChange({ timeframe: opt, idx });
      },
    onRecipientChange:
      ({ recipientChange }) =>
      event => {
        event.preventDefault();
        recipientChange({ recipients: event.target.value });
      },
    onSubmitRecipients:
      ({ recipients, saveRecipients }) =>
      () => {
        saveRecipients({ copied_recipients: recipients });
      },
    onChange:
      ({
        data,
        handleData,
        isUpdateEnabled,
        updateEnabledChange,
        includedChange,
      }) =>
      ev => {
        if (ev.target.id === 'updateEnabled') {
          if (!isUpdateEnabled) {
            handleData({ ...data, message: '' });
          }
          updateEnabledChange(!isUpdateEnabled);
        } else {
          includedChange(ev.target.id);
        }
      },
  }),
)(WeeklyUpdate);
