import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { pageVariants } from '../../../config/pageVariants';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { getUserSystem, hasPermission } from '../../../helpers/util';
import { fetchAccountNames, setAccountName } from '../../redux/reducers/filters';
import ReportContainer from '../../components/ReportContainer';
import CategoryPerformanceChart from '../../components/retail/CategoryPerformanceChart';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import Loading from '..//../components/Loading';

import { bostonMatrixCategories as explanation } from '../../components/Explainer/Explanations';
import retail, {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRetailType,
  setRefreshDisabledStatus,
  loadCategoryPerformanceData,
  drillBubbleGraph,
  resetReport,
} from '../../redux/reducers/retail';

const StyledContainer = styled(Container)`
  position: relative;
  height: 100%;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #000;
  }
`;
const LabelShare = styled.div`
  position: absolute;
  position: absolute;
  color: #000;
  bottom: -30px;
  font-weight: bold;
`;
const LabelGrowth = styled.div`
  position: absolute;
  color: #000;
  bottom: 100px;
  font-weight: bold;
  left: -70px;
  transform: rotate(-90deg);
`;

export const CategoryPerformance = ({
  timeframe,
  timeframes,
  likeForLike,
  likeForLikes,
  setLoading,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  onLikeForLikeChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  selectedLevel4s,
  level4s,
  onLevel5Change,
  selectedLevel5s,
  level5s,
  accountNames,
  accountName,
  onAccountNameChange,
  data,
  graphData,
  loadCategoryPerformanceData,
  categoryPerformanceData,
  isRefreshDisabled,
  unlockRefresh,
  drillBubbleGraph,
  location,
  isLoading,
  onRetailTypeChange,
  retailType,
  retailTypes,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('category_performance', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });
    fetchAccountNames();
    return () => onUnmount();
  }, []);
  return (
    <motion.div
      key={'Category-Performance-ID'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        explanationTooltip={explanation.page.tooltip}
        refresh={{
          refreshHandler: () => loadCategoryPerformanceData(),
          isRefreshDisabled,
        }}
        title={'Boston Matrix - Categories'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Category Performance Analysis for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          { 
            name: 'accountName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
          }
        ]}>
        <DataDropdownsContainer
          unlockRefresh={() => unlockRefresh()}
          dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            },
            {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel4s,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            },
            {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel5s,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            },
            {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSuppliers,
              options: suppliers,
              handler: option => onSupplierChange(option),
              isMulti: true,
            },
            {
              name: 'Brand',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrands,
              options: brands,
              handler: option => onBrandChange(option),
              isMulti: true,
            },
            {
              name: 'retailType',
              condition: hasPermission(`role_view_${getUserSystem().toLowerCase()}_independent_data`) ? ['display']  : [],
              valueKey: 'ID',
              labelKey: 'Text',
              value: retailType,
              options: retailTypes,
              handler: option => onRetailTypeChange(option),
              isMulti: false,
            },
          ]}
        />
      </ReportSettingsContainer>
      <ReportContainer padding={3} justifyContent={isLoading ? 'center' : ''}>
        {isLoading && <Loading />}
        {categoryPerformanceData &&
          !isLoading &&
          categoryPerformanceData['QUESTION MARKS'] &&
          categoryPerformanceData['QUESTION MARKS'].plots && (
            <StyledContainer fluid>
              <>
                <LabelShare>Share</LabelShare>
                <LabelGrowth>Market Growth</LabelGrowth>
              </>

              <Row>
                {categoryPerformanceData &&
                  categoryPerformanceData['QUESTION MARKS'] &&
                  categoryPerformanceData['QUESTION MARKS'].plots && (
                    <Col md={6}>
                      <CategoryPerformanceChart
                        id="questionMarks"
                        title="QUESTION MARKS"
                        data={categoryPerformanceData['QUESTION MARKS'].plots}
                        axis={categoryPerformanceData['QUESTION MARKS'].axis}
                        tooltip={explanation.graph.questionMarks}
                        drillBubbleGraph={val => drillBubbleGraph(val)}
                      />
                    </Col>
                  )}
                {categoryPerformanceData &&
                  categoryPerformanceData['STARS'] &&
                  categoryPerformanceData['STARS'].plots && (
                    <Col md={6}>
                      <CategoryPerformanceChart
                        id="Starts"
                        title="Stars"
                        data={categoryPerformanceData['STARS'].plots}
                        axis={categoryPerformanceData['STARS'].axis}
                        tooltip={explanation.graph.stars}
                        drillBubbleGraph={val => drillBubbleGraph(val)}
                      />
                    </Col>
                  )}
                {categoryPerformanceData &&
                  categoryPerformanceData.DOGS &&
                  categoryPerformanceData.DOGS.plots && (
                    <Col md={6}>
                      <CategoryPerformanceChart
                        id="dogs"
                        title="Dogs"
                        data={categoryPerformanceData.DOGS.plots}
                        axis={categoryPerformanceData['DOGS'].axis}
                        tooltip={explanation.graph.dogs}
                        drillBubbleGraph={val => drillBubbleGraph(val)}
                      />
                    </Col>
                  )}
                {categoryPerformanceData &&
                  categoryPerformanceData['CASH COWS'] &&
                  categoryPerformanceData['CASH COWS'].plots && (
                    <Col md={6}>
                      <CategoryPerformanceChart
                        id="CashCows"
                        title="CASH COWS"
                        data={categoryPerformanceData['CASH COWS'].plots}
                        axis={categoryPerformanceData['CASH COWS'].axis}
                        tooltip={explanation.graph.cows}
                        drillBubbleGraph={val => drillBubbleGraph(val)}
                      />
                    </Col>
                  )}
              </Row>
            </StyledContainer>
          )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategories: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSuppliers: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrand,
  selectedLevel4s: state.retail.selectedLevel4s,
  level4s: state.retail.level4s,
  selectedLevel5s: state.retail.selectedLevel5s,
  level5s: state.retail.level5s,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  retailType: state.retail.retailType,
  retailTypes: state.retail.retailTypes,
  categoryPerformanceData: state.retail.categoryPerformanceData,
  isLoading: state.retail.isLoading,
  isRefreshDisabled: state.retail.isRefreshDisabled,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onLikeForLikeChange: opt => {
        if (opt) {
          dispatch(setLikeForLike(opt.ID));
        } else {
          dispatch(setLikeForLike(''));
        }
      },
    unlockRefresh:
    () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: option => dispatch(setSuppliers(option)),
    onAccountNameChange: opt => {
        if (opt) {
          dispatch(setAccountName(opt));
        } else {
          dispatch(setAccountName([]));
        }
      },
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onRetailTypeChange: option => dispatch(setRetailType(option || '')),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    loadCategoryPerformanceData: () => dispatch(loadCategoryPerformanceData()),
    drillBubbleGraph: val => dispatch(drillBubbleGraph(val)),
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(fetchAccountNames());
      dispatch(
        fetchCategories('category_performance', false, { timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') }),
        );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryPerformance);
