import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select-new';

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
      <label>{props.label}</label>
    </components.Option>
  </div>
);

const ValueContainer = ({ children, getValue, ...props }) => {
  var valueLength = getValue().length;
  var optionsLength = props.selectProps.options.length;

  var valueText = '';

  if (optionsLength === 1) {
    valueText = '1';
  } else if (valueLength === 0 && props.selectProps.name == 'excludedDepartment') {
    valueText = '0';
  } else if (optionsLength > 1 && optionsLength === valueLength) {
    valueText = 'All';
  } else if (valueLength === 0 && !props.selectProps.disableAll) {
    valueText = 'All';
  } else if (valueLength === 0 && props.selectProps.disableAll) {
    valueText = '0';
  } else {
    valueText = valueLength;
  }
  // Check if values are equal to options and display `All` instead of number.
  //var valueLength = optionsLength === valueLength ? 'All' : valueLength === 0 ? 'All' : valueLength;

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue &&
        valueLength === 1 &&
        `${
          getValue()[0].Text || getValue()[0].ActualName || getValue()[0].label || getValue()[0].RegNo
        }`}
      {!props.selectProps.inputValue &&
        valueLength !== 1 &&
        `${valueText} ${props.selectProps.types}`}
      {/*!props.selectProps.inputValue && `${valueText} ${valueLength != 1 ? props.selectProps.types : props.selectProps.type}`*/}
      {React.Children.map(children, child => {
        return child && child.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
};

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="Select-arrow" />
    </components.DropdownIndicator>
  );
};

class CustomCountSelect extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.node,
        label: PropTypes.node,
      }),
    ).isRequired,
    onChangeCallback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: [],
  };

  render() {
    const {
      options,
      isMulti,
      onChangeCallback,
      isLoading,
      valueKey,
      labelKey,
      ...otherProps
    } = this.props;
    return (
      <Select
        isLoading={isLoading}
        isDisabled={isLoading}
        getOptionLabel={opt => opt[labelKey] || opt.label || opt.Text}
        getOptionValue={opt => opt[valueKey] || opt.value || opt.ID}
        closeMenuOnSelect={false}
        classNamePrefix="ui-select"
        isMulti={isMulti}
        components={{ Option, ValueContainer, DropdownIndicator }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={e => onChangeCallback(e)}
        // getOptionLabel={data => {
        //   return data[labelKey] || data.Text;
        // }}
        // getOptionValue={data => {
        //   return data[valueKey] || data.ID;
        // }}
        {...otherProps}
      />
    );
  }
}

export default CustomCountSelect;
