'use strict';
import { handleActions, createAction } from 'redux-actions';
import { request, customRequest, requestGet } from '../../../helpers/axios';
import {
  encodeText,
  getUserSystem,
  getToken,
  getUserId,
  addDrillInfo,
  removeDrillInfo,
} from '../../../helpers/util';
import { getLastSelectedFilter } from '../../../helpers/helpers';
import { Drill } from '../models/Drill';
import store from '../store';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import { push } from 'react-router-redux';
import theme from '../../../config/Themes';
import {
  formatSalesByWeekAPIData,
  htmlDecodePound,
  formatUrlWithParameters,
  formatUrlWithSingleParameter,
} from '../../../helpers/helpers';
import { fetchAccountNames } from './filters';

const FETCH_DATA = 'retail/FETCH_DATA';
const FETCH_PERFORMANCE_DATA_GRAPH = 'retail/FETCH_PERFORMANCE_DATA_GRAPH';
const FETCH_PERFORMANCE_DATA_TABLE = 'retail/FETCH_PERFORMANCE_DATA_TABLE';
const FETCH_PERFORMANCE_ALL_DATA_TABLE =
  'retail/FETCH_PERFORMANCE_ALL_DATA_TABLE';
const FETCH_PRODUCTS = 'retail/FETCH_PRODUCTS';
const SET_REPORT_DEPARTMENTS = 'retail/SET_DEPARTMENTS';
const SET_REPORT_DEPARTMENT = 'retail/SET_DEPARTMENT';
const SET_REPORT_CATEGORIES = 'retail/SET_CATEGORIES';
const SET_REPORT_CATEGORY = 'retail/SET_CATEGORY';
const SET_SUBCATEGORIES = 'retail/SET_SUBCATEGORIES';
const SET_SUBCATEGORY = 'retail/SET_SUBCATEGORY';
const SET_LEVEL4S = 'retail/SET_LEVEL4S';
const SET_LEVEL5S = 'retail/SET_LEVEL5S';
const SET_LEVEL4 = 'retail/SET_LEVEL4';
const SET_LEVEL5 = 'retail/SET_LEVEL5';
const SET_MEASURE = 'retail/SET_MEASURE';
const SET_CORE_RANGE = 'retail/SET_CORE_RANGE';
const SET_LIKE_FOR_LIKE = 'retail/SET_LIKE_FOR_LIKE';
const SET_PURCHASE = 'retail/SET_PURCHASE';
const SET_TIMEFRAME = 'retail/SET_TIMEFRAME';
const SET_ACCOUNT_NAME = 'retail/SET_ACCOUNT_NAME';
const SET_BRAND = 'retail/SET_BRAND';
const SET_TITLE = 'retail/SET_TITLE';
const SET_SUBPAGE = 'retail/SET_SUBPAGE';
const SET_SELECTED_MEMBERS = 'retail/SET_SELECTED_MEMBERS';
const SET_SUPPLIER = 'retail/SET_SUPPLIER';
const SET_SUPPLIERS = 'retail/SET_SUPPLIERS';
const SET_BRANDS = 'retail/SET_BRANDS';
const SET_SELECTED_BRANDS = 'retail/SET_SELECTED_BRANDS';
const SET_SELECTED_SUPPLIERS = 'retail/SET_SELECTED_SUPPLIERS';
const SET_SELECTED_PRODUCT = 'retail/SET_SELECTED_PRODUCT';
const SET_DEPOT = 'retail/SET_DEPOT';
const SET_RETAIL_TYPE = 'retail/SET_RETAIL_TYPE';
const SET_REPORT_SUPPLIER = 'retail/SET_REPORT_SUPPLIER';
const SET_REPORT_MEMBER = 'retail/SET_REPORT_MEMBER';
const SET_LOADING = 'retail/SET_LOADING';
const SET_EXPORTING = 'retail/SET_EXPORTING';
const SET_LOADED = 'retail/SET_LOADED';
const SET_GRAPH_LOADED = 'retail/SET_GRAPH_LOADED';
const SET_TABLE_LOADED = 'retail/SET_TABLE_LOADED';
const FETCH_KPI_DATA = 'retail/FETCH_KPI_DATA';
const SET_HIDE_ALL = 'retail/SET_HIDE_ALL';
const EXPORT_TO_PDF_SUCCESS = 'retail/EXPORT_TO_PDF_SUCCESS';
const RESET = 'retail/RESET';
const SET_BUSINESS_TYPE = 'retail/SET_BUSINESS_TYPE';
const SET_CURRENT_REPORT_PAGE = 'retail/SET_CURRENT_REPORT_PAGE';
const SET_REPORT_ERROR = 'retail/SET_REPORT_ERROR';
const SET_DEPOTS = 'retail/SET_DEPOTS';
const ADD_MEASURE = 'retail/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'retail/RESET_MEASURE_OPTIONS';
const SET_REPORT_TYPE = 'retail/SET_REPORT_TYPE';
const SET_SALES_BY_WEEK_GRAPH_DATA = 'retail/SET_SALES_BY_WEEK_GRAPH_DATA';
const SET_CATEGORY_PENETRATION_DATA = 'retail/SET_CATEGORY_PENETRATION_DATA';
const SET_RETAIL_ANALYSIS_REPORT_DATA = 'retail/SET_RETAIL_ANALYSIS_REPORT_DATA';
const SET_DISTRIBUTION_GRAPH_DATA = 'retail/SET_DISTRIBUTION_GRAPH_DATA'
const SET_RETAILER_PERFORMANCE_DATA = 'retail/SET_RETAILER_PERFORMANCE_DATA';
const SET_RETAILER_PERFORMANCE_TABLE_DATA =
  'retail/SET_RETAILER_PERFORMANCE_TABLE_DATA';
const SET_SKU_PERFORMANCE_DATA = 'retail/SET_SKU_PERFORMANCE_DATA';
const SET_CATEGORY_PERFORMANCE_DATA = 'retail/SET_CATEGORY_PERFORMANCE_DATA';
const SET_BASKET_OVERVIEW_DATA = 'retail/SET_BASKET_OVERVIEW_DATA';
const SET_ANALYTICS_OVERVIEW_DATA = 'retail/SET_ANALYTICS_OVERVIEW_DATA';
const SET_BASKET_PRODUCT_ANALYSIS = 'retail/SET_BASKET_PRODUCT_ANALYSIS';
const SET_IS_REFRESH_DISABLED = 'retail/SET_IS_REFRESH_DISABLED';
const SET_PENETRATION_REPORT_TYPE = 'retail/SET_PENETRATION_REPORT_TYPE';
const SET_DRILL_DATA = 'retail/SET_DRILL_DATA';
const SET_IS_SKU_DRILL = 'retail/SET_IS_SKU_DRILL';
const initialState = {
  baseUrl: '',
  departments: [],
  selectedDepartments: [],
  selectedDepartment: '',
  categories: [],
  selectedCategories: [],
  selectedCategory: '',
  subCategories: [],
  selectedSubCategories: [],
  selectedSubCategory: '',
  members: [],
  brands: [],
  brand: null,
  selectedBrand: null,
  selectedBrands: [],
  selectedMembers: [],
  suppliers: [],
  supplier: '',
  selectedSuppliers: [],
  depots: [],
  selectedDepots: [],
  level4s: [],
  selectedLevel4s: [],
  selectedLevel4: '',
  level5s: [],
  selectedLevel5s: [],
  selectedLevel5: '',
  products: [],
  selectedProduct: '',
  selectedProducts: [],
  accountNames: [],
  accountName: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: '',
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  coreRanges: [
    { ID: 'yes', Text: 'Yes' },
    { ID: 'no', Text: 'No' },
  ],
  retailTypes: [
    { ID: 'independent', Text: 'Independent' },
    { ID: 'company_owned', Text: 'Company Owned' },
  ],
  retailType: '',
  coreRange: '',
  measure: '',
  likeForLike: '',
  purchase: 'out',
  performanceDataGraph: [],
  performanceDataTable: [],
  performanceAllDataTable: [],
  title: '',
  subPage: '',
  isLoading: false,
  isGraphLoaded: false,
  isTableLoaded: false,
  isExporting: false,
  isExported: false,
  reportType: '',
  currentReportPage: '',
  kpiData: [],
  isLoaded: false,
  hideAll: false,
  pdfURL: '',
  businessTypes: [],
  selectedBusinessType: '',
  retailAnalysisReportData: null,
  reportError: '',
  isInitialLoadComplete: false,
  penetrationReportType: { ID: 'department', Text: 'Category' },
  penetrationReportTypes: [
    { ID: 'department', Text: 'Category' },
    { ID: 'supplier', Text: 'Supplier' },
    { ID: 'brand', Text: 'Brand' },
  ],
  salesByWeekGraphData: {},
  categoryPenetrationData: {},
  distributionGraphData: {},
  retailerPerformanceData: {},
  retailerPerformanceTableData: {},
  skuPerformanceData: {},
  categoryPerformanceData: {},
  basketOverviewData: {},
  basketProductAnalysisData: {},
  isRefreshDisabled: false,
  drillData: {},
  isSkuDrill: false,
  isRollingDateRange: false,
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchPerformanceDataGraphAction = createAction(
  FETCH_PERFORMANCE_DATA_GRAPH,
);
const fetchPerformanceDataTableAction = createAction(
  FETCH_PERFORMANCE_DATA_TABLE,
);
const fetchPerformanceAllDataTableAction = createAction(
  FETCH_PERFORMANCE_ALL_DATA_TABLE,
);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setMeasureAction = createAction(SET_MEASURE);
const setCoreRangeAction = createAction(SET_CORE_RANGE);
const setLikeForLikeAction = createAction(SET_LIKE_FOR_LIKE);
const setPurchaseAction = createAction(SET_PURCHASE);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const setCurrentReportPageAction = createAction(SET_CURRENT_REPORT_PAGE);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setBrandAction = createAction(SET_BRAND);
const setSupplierAction = createAction(SET_SUPPLIER);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setSelectedSuppliersAction = createAction(SET_SELECTED_SUPPLIERS);
const setSelectedBrandsAction = createAction(SET_SELECTED_BRANDS);
const setSelectedProductAction = createAction(SET_SELECTED_PRODUCT);
const setDepotAction = createAction(SET_DEPOT);
const setTitleAction = createAction(SET_TITLE);
const setSubPageAction = createAction(SET_SUBPAGE);
const setReportSupplierAction = createAction(SET_REPORT_SUPPLIER);
const setReportMemberAction = createAction(SET_REPORT_MEMBER);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setLoadingAction = createAction(SET_LOADING);
const setGraphLoadedAction = createAction(SET_GRAPH_LOADED);
const setTableLoadedAction = createAction(SET_TABLE_LOADED);
const setKpiDataAction = createAction(FETCH_KPI_DATA);
const setLoadedAction = createAction(SET_LOADED);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const resetAction = createAction(RESET);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setRetailTypeAction = createAction(SET_RETAIL_TYPE);
const setDepotsAction = createAction(SET_DEPOTS);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setReportTypeAction = createAction(SET_REPORT_TYPE);
const setSalesByWeekGraphDataAction = createAction(
  SET_SALES_BY_WEEK_GRAPH_DATA,
);

const setCatPenDistributionGraphDataAction = createAction(
  SET_DISTRIBUTION_GRAPH_DATA,
);

const setCategoryPenetrationDataAction = createAction(
  SET_CATEGORY_PENETRATION_DATA,
);

const setRetailAnalysisReportDataAction = createAction(
  SET_RETAIL_ANALYSIS_REPORT_DATA,
);
const setRetailerPerformanceData = createAction(SET_RETAILER_PERFORMANCE_DATA);
const setRetailerPerformanceTableData = createAction(
  SET_RETAILER_PERFORMANCE_TABLE_DATA,
);
const setSkuPerformanceData = createAction(SET_SKU_PERFORMANCE_DATA);
const setCategoryPerformanceData = createAction(SET_CATEGORY_PERFORMANCE_DATA);
const setBasketOverviewData = createAction(SET_BASKET_OVERVIEW_DATA);
const setAnalyticsOverviewData = createAction(SET_ANALYTICS_OVERVIEW_DATA);
const setBasketProductAnalysisDataAction = createAction(
  SET_BASKET_PRODUCT_ANALYSIS,
);
const setIsRefreshDisabled = createAction(SET_IS_REFRESH_DISABLED);
const setPenetrationReportTypeAction = createAction(
  SET_PENETRATION_REPORT_TYPE,
);
const setDrillData = createAction(SET_DRILL_DATA);
const setIsSkuDrill = createAction(SET_IS_SKU_DRILL);

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  const fileName = 'performance-report-' + new Date().toISOString() + '.pdf';
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName,
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const setCurrentReportPage = type => dispatch => {
  dispatch(setCurrentReportPageAction(type));
};

export const loadReport = () => dispatch => {
  dispatch(fetchPerformanceDataTableAction([]));
  dispatch(fetchPerformanceDataGraphAction([]));
  dispatch(setLoadingAction(true));
  dispatch(setTableLoadedAction(false));
  dispatch(setGraphLoadedAction(false));
  loadChart(store, dispatch);
  loadTable(store, dispatch);
};


export const fetchRetailAnalysisReport = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;
  
  const url = formatUrlWithParameters(
    '/route.php?c=retailGapAnalysis/retailGapAnalysisReport',
    state,
    user,
  );

  const result = await requestGet({ url });
  dispatch(setLoadingAction(false));
  return dispatch(setRetailAnalysisReportDataAction(result));
}

export const loadKPIData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadedAction(false));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=kpi/analyticsOverview',
    state,
    user,
  );
  try {
    const kpiData = await requestGet({ url });

    dispatch(setKpiDataAction(kpiData));
  } catch (err) {
    console.warn(err);
  } finally {
    dispatch(setLoadedAction(true));
  }
};

export const fetchCategories = (type, isReferal, drillObj) => async dispatch => {
    dispatch(setLoadedAction(false));
    dispatch(setReportTypeAction(type));
    const userObj = JSON.parse(localStorage.user_data);
    let url = '/route.php';
    if (userObj.departments.length === 0 && userObj.categories.length === 0) {
      url += '?c=category/getHierarchy';
    } else {
      url += '?c=category/getUserHierarchy';
    }

    dispatch(setLoadingAction(true));
    const result = await requestGet({ url });
    let url1 = '/route.php?c=wholesaler/getAll';

    let members = await requestGet({ url: url1 });

    let url3 = '/route.php?c=brand/getAll';

    if (isReferal) {
      if (drillObj?.departments && drillObj?.departments.length > 0) {
        url3 += `&department=${encodeURIComponent(drillObj?.departments)}`;
      }
      if (drillObj?.categories && drillObj?.categories.length > 0) {
        url3 += `&category=${encodeURIComponent(drillObj?.categories)}`;
      }
      if (drillObj?.subCategories && drillObj?.subCategories.length > 0) {
        url3 += `&subcategory=${encodeURIComponent(drillObj?.subCategories)}`;
      }
      if (drillObj?.level4s && drillObj?.level4s.length > 0) {
        url3 += `&level4=${encodeURIComponent(drillObj?.level4s)}`;
      }
      if (drillObj?.level5s && drillObj?.level5s.length > 0) {
        url3 += `&level5=${encodeURIComponent(drillObj?.level5s)}`;
      }
      if (drillObj?.supplier && drillObj?.supplier.length > 0) {
        url3 += `&supplier=${encodeURIComponent(drillObj?.supplier)}`;
      }
    }

    let brands = await requestGet({ url: url3 });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });

    url = '/route.php?c=supplier/getAll';

    each(members, mem => {
      mem.Text = encodeText(mem.Text);
    });

    if (
      members.length === 1
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(
        setSelectedMembersAction([
          { Text: members[0].ActualName, ID: members[0].WholesalerNo },
        ]),
      );
      if (type === 'depot') {
        members = [members[0]];
      }
    }

    const response = await requestGet({ url: url });

    let suppliers = [];

    each(response, supp => {
      supp.SupplierName = encodeText(supp.SupplierName);
      suppliers.push(supp);
    });

    url = '/route.php?c=customer/getAllBusinessTypes';

    let businessTypes = [];

    try {
      businessTypes = await requestGet({ url: url });
    } catch {
      console.warn('No business types permission');
    }

    url = '/route.php?c=depot/getDepots';

    if (isReferal && drillObj?.members && drillObj?.members.length > 0) {
      url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(drillObj?.members.split(
        ',',
      ))}`;
    }

    businessTypes =
      businessTypes.map(businessType => {
        return { BusinessName: businessType };
      }) || [];

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    let departments = result.Departments ? result.Departments : [];
    let orderedDeprtments = orderBy(departments, ['Text']);
    let categories = result.Categories ? result.Categories : [];
    let orderedCategories = orderBy(categories, ['Text']);
    if (departments.length > 1) {
      orderedDeprtments.unshift({ ID: 0, Text: 'Select all', Categories: [] });
    }

    if (categories.length > 1) {
      orderedCategories.unshift({
        ID: 0,
        Text: 'Select all',
        SubCategories: [],
      });
    }

    // Format suppliers to fit { ID: *, Text: ""}
    let formattedSuppliers;
    if (suppliers.length > 0) {
      formattedSuppliers = suppliers.map(supplier => ({
        Text: supplier.SupplierName,
        ID: supplier.SupplierName,
      }));
    }

    // Format Brands to fit { ID: *, Text: ""}
    let formattedBrands;
    if (brands.length > 0) {
      formattedBrands = brands.map(brand => ({
        Text: brand.Brand,
        ID: brand.Brand,
      }));
    }

    dispatch(fetchAccountNames());

    dispatch(
      fetchDataAction({
        departments: orderedDeprtments,
        categories: orderedCategories,
        suppliers: formattedSuppliers || suppliers,
        brands: formattedBrands || brands,
        members: members,
        depots: depots,
        purchase: isReferal ? store.getState().retail.purchase : 'out',
        measure: isReferal ? store.getState().retail.measure : 'Value',
        coreRange: isReferal ? store.getState().retail.coreRange : '',
        retailType: isReferal ? tore.getState().retail.retailTypes : '',
        likeForLike: isReferal
          ? store.getState().retail.likeForLike
          : 'like_for_like',
        timeframe: isReferal
          ? store.getState().retail.timeframe
          : !drillObj?.timeframe
          ? getUserSystem() == 'united'
            ? '4wksRolling'
            : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks')
          : drillObj?.timeframe,
        businessTypes: businessTypes,
      }),
    );

    each(result.Categories, cat => {
      cat.Text = encodeText(cat.Text);
    });
    const rptType = store.getState().performanceReport.reportType;
    if (result.Departments && result.Departments.length === 1) {
      let dept = result.Departments[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot' &&
        rptType !== 'level4' &&
        rptType !== 'level5'
      ) {
        reportType = 'category';
      } else {
        reportType = store.getState().performanceReport.reportType;
      }
      dispatch(setReportDepartmentsAction({ departments: [dept], reportType }));
      each(dept.Categories, cat => {
        cat.Text = encodeText(cat.Text);
      });
      if (dept.Categories.length === 1) {
        let cat = dept.Categories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'subcategory';
        } else {
          reportType = store.getState().performanceReport.reportType;
        }

        dispatch(setReportCategoriesAction({ categories: [cat], reportType }));
        each(cat.SubCategories, subCat => {
          subCat.Text = encodeText(subCat.Text);
        });
        if (cat.SubCategories.length === 1) {
          let subcat = cat.SubCategories[0];
          if (
            rptType !== 'supplier' &&
            rptType !== 'business_type' &&
            rptType !== 'member' &&
            rptType !== 'brand' &&
            rptType !== 'depot'
          ) {
            reportType = 'level4';
          } else {
            reportType = store.getState().performanceReport.reportType;
          }
          dispatch(
            setSubCategoriesAction({ subCategories: [subcat], reportType }),
          );
        }
      }
    }
    if (result.Categories && result.Categories.length === 1) {
      let catt = result.Categories[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot'
      ) {
        reportType = 'subcategory';
      } else {
        reportType = store.getState().performanceReport.reportType;
      }
      dispatch(setReportCategoriesAction({ categories: [catt], reportType }));
      each(catt.SubCategories, subCat => {
        subCat.Text = encodeText(subCat.Text);
      });
      if (catt.SubCategories.length === 1) {
        let subcat = catt.SubCategories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'level4';
        } else {
          reportType = store.getState().performanceReport.reportType;
        }
        dispatch(
          setSubCategoriesAction({ subCategories: [subcat], reportType }),
        );
      }
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
    } else if (!isReferal) {
      const isSkuDrill = store.getState().retail.isSkuDrill;
      const drillData = store.getState().retail.drillData;
      if (isSkuDrill) {
        await dispatch(setDrillParams(drillData));
        dispatch(loadSkuPerformanceData());
      }
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
      return;
    }

    if (!isReferal && store.getState().performanceReport.hideAll === false) {
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
    }
  };

export const setDrillParams = drillData => async dispatch => {
  if (drillData.measure) await dispatch(setMeasureAction(drillData.measure));
  if (drillData.type) await dispatch(setPurchaseAction(drillData.type));
  if (drillData.timeframe)
    await dispatch(setTimeFrameAction(drillData.timeframe));
  if (drillData.likeForLike)
    await dispatch(setLikeForLikeAction(drillData.likeForLike));
  if (drillData.departments)
    await dispatch(
      setReportDepartmentsAction({
        departments: drillData.departments,
        reportType: 'sku',
      }),
    );
  if (drillData.departments)
    await dispatch(
      setReportCategoriesAction({
        categories: drillData.categories,
        reportType: 'sku',
      }),
    );
  if (drillData.subCategories)
    await dispatch(
      setSubCategoriesAction({
        subCategories: drillData.subCategories,
        reportType: 'sku',
      }),
    );
  if (drillData.level4s)
    await dispatch(
      setLevel4sAction({ level4s: drillData.level4s, reportType: 'sku' }),
    );
  if (drillData.level5s)
    await dispatch(
      setLevel5sAction({ level5s: drillData.level5s, reportType: 'sku' }),
    );
};

export const setOnLoadParams = (params, reportType) => async dispatch => {
  reportType = reportType || 'sku';
  dispatch(setLoadedAction(false));
  const state = store.getState().retail;
  if (params.measure) dispatch(setMeasureAction(params.measure));
  if (params.type) dispatch(setPurchaseAction(params.type));
  if (params.timeframe) dispatch(setTimeFrameAction(params.timeframe));
  if (params.accountName) dispatch(setAccountNameAction(params.accountName));
  if (params.likeForLike) dispatch(setLikeForLikeAction(params.likeForLike));
  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (params.departments && params.departments.length > 0) {
    _.each(params.departments, dept => {
      if (_.filter(state.departments, { Text: dept }).length > 0) {
        departments.push(_.filter(state.departments, { Text: dept })[0]);
      }
    });
    dispatch(setReportDepartmentsAction({ departments, reportType }));
  }
  if (params.categories && params.categories.length > 0) {
    _.each(params.categories, catt => {
      if (
        _.filter(flatten(map(departments, dept => dept.Categories)), {
          Text: catt,
        }).length > 0
      ) {
        categories.push(
          _.filter(flatten(map(departments, dept => dept.Categories)), {
            Text: catt,
          })[0],
        );
      }
    });
    if (categories.length > 0) {
      dispatch(setReportCategoriesAction({ categories, reportType }));
    }
  }
  if (params.subCategories && params.subCategories.length > 0) {
    _.each(params.subCategories, subcat => {
      if (
        _.filter(flatten(map(categories, cat => cat.SubCategories)), {
          Text: subcat,
        }).length > 0
      ) {
        subCategories.push(
          _.filter(flatten(map(categories, cat => cat.SubCategories)), {
            Text: subcat,
          })[0],
        );
      }
    });
    if (subCategories.length > 0) {
      dispatch(setSubCategoriesAction({ subCategories, reportType }));
    }
  }
  if (params.level4s && params.level4s.length > 0) {
    _.each(params.level4s, lvl4 => {
      if (
        _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
          Text: lvl4,
        }).length > 0
      ) {
        level4s.push(
          _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
            Text: lvl4,
          })[0],
        );
      }
    });
    if (level4s.length > 0) {
      dispatch(setLevel4sAction({ level4s, reportType }));
    }
  }
  if (params.level5s && params.level5s.length > 0) {
    _.each(params.level5s, lvl5 => {
      if (
        _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
          ID: parseInt(lvl5),
        }).length > 0
      ) {
        level5s.push(
          _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
            ID: parseInt(lvl5),
          })[0],
        );
      }
    });
    if (level5s.length > 0) {
      dispatch(setLevel5sAction({ level5s, reportType }));
    }
  }
  if (typeof params.supplier !== 'undefined' && params.supplier.length > 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName.trim() === params.supplier.trim()) {
        dispatch(
          setSupplierAction({
            supplier: sup,
            reportType: store.getState().performanceReport.reportType,
          }),
        );
      }
    });
  }

  if (params.brand) {
    let brand = _.filter(state.brands, brd => {
      if (brd.Brand.trim() === params.brand.trim()) {
        return brd;
      }
    });
    let brnd = brand[0];
    if (brnd) {
      brnd.reportType = reportType;
      dispatch(setBrandAction(brnd));
    }
  }

  if (params.members && params.members.length > 0) {
    let members = [];
    _.each(params.members, mem => {
      const memb = _.filter(
        state.members,
        memm => memm.WholesalerNo === mem.WholesalerNo,
      )[0];
      members.push({ Text: memb.ActualName, ID: memb.WholesalerNo });
    });
    dispatch(setSelectedMembersAction(members));

    if (params.depots.length > 0) {
      let procDepots = [];
      each(params.depots, dept => {
        each(state.depots, dpt => {
          if (dpt.DepotName === dept) {
            procDepots.push(dpt);
          }
        });
      });
      dispatch(
        setDepotAction({
          selectedDepots: procDepots,
          reportType: state.reportType,
        }),
      );
    }
  } else {
    let members = state.members;
    if (
      members.length === 2 &&
      _.filter(members, member => member.Text === 'ALL').length > 0
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(setSelectedMembersAction([members[0]]));
    }
    dispatch(setDepotsAction([]));
  }

  if (params.selectedBusinessType !== undefined) {
    const businessType = _.find(state.businessTypes, businessType => {
      if (
        businessType.BusinessName.trim() === params.selectedBusinessType.trim()
      ) {
        return businessType;
      }
    });
    dispatch(setBusinessTypeAction(businessType.BusinessName));
  }

  dispatch(setHideAllAction(false));

  if (state.departments.length === 1 && state.reportType === 'department') {
    dispatch(setReportTypeAction('category'));
  }
};

export const brandSelect = value => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/brand_performance';
  let drillObj = new Drill();
  drillObj.to = '/nam_dash/brand_performance';
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.accountName = state.accountName;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subcat => subcat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName);
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }

  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const productSelect = value => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/product_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.accountName = state.accountName;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/product_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }
  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName.trim() === value.trim()) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + 'nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const depotSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/depot_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/depot_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = 'category';
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }
  if (state.reportType === 'supplier') {
    drillObj.supplier = value;
  } else {
    drillObj.supplier = state.supplier.SupplierName;
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName.trim() === value.trim()) {
        drillObj.depots = dept;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const supplierSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/supplier_performance?';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.accountName = state.accountName;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/supplier_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.supplier.SupplierName !== undefined) {
    drillObj.supplier = state.supplier.SupplierName;
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.ID;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const wholesalerSelect = (value, ref) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + '/nam_dash/wholesaler_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.accountName = state.accountName;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/wholesaler_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }

  if (state.selectedMembers.length > 0) {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }

  addDrillInfo(drillObj);
  dispatch(push(url));
};

export const goToReferal = (val, to, skip) => dispatch => {
  let state = store.getState().performanceReport;
  let url = state.baseUrl + val;
  removeDrillInfo(to);
  dispatch(push(url));
};

export const setReportSupplier = val => dispatch => {
  let supplier = _.filter(
    store.getState().performanceReport.performanceDataTable,
    sup => {
      if (sup.field === val) {
        return sup;
      }
    },
  );

  dispatch(setReportSupplierAction(supplier[0]));
};

export const setReportMember = val => dispatch => {
  let member = _.filter(
    store.getState().performanceReport.performanceDataTable,
    mem => {
      if (mem.field === val) {
        return mem;
      }
    },
  );

  dispatch(setReportMemberAction(member[0]));
};

export const setRefreshDisabledStatus = val => dispatch => {
  dispatch(setIsRefreshDisabled(val));
};

export const setSelectedProduct = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));

  if (val) {
    dispatch(setSelectedProductAction(val));
  } else {
    dispatch(setSelectedProductAction(''));
  }
};

export const setDepartment = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let department = _.filter(store.getState().retail.departments, dpt => {
      if (dpt.ID === parseInt(val.ID)) {
        return dpt;
      }
    });
    let dpt = department[0];
    dispatch(setReportDepartmentAction(dpt));
    loadProducts(store, dispatch);
  } else {
    dispatch(setReportDepartmentAction(''));
    loadProducts(store, dispatch);
  }
};

export const setDepartments = depts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  const state = store.getState().retail;
  let reportType = 'basket_analysis';

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept.Text === 'TOBACCO') {
        return dept;
      }
    });

    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    const isSelectAll = depts.filter(dept => dept.ID === 0).length > 0;
    if (isSelectAll) {
      if (state.selectedDepartments.length > depts.length){
        let tempArray = [...depts];
        tempArray.shift();
        dispatch(setReportDepartmentsAction({
          departments: tempArray,
          reportType,}));
      } else {
        dispatch(setReportDepartmentsAction({
          departments: state.departments,
          reportType,}));
      }
    } else if (
      filter(state.selectedDepartments, { ID: 0 }).length > 0 &&
      filter(depts, { ID: 0 }).length === 0
    ) {
      // Remove Select All
      dispatch(setReportDepartmentsAction({ departments: [], reportType }));
    } else {
      // Select Specific
      dispatch(setReportDepartmentsAction({ departments: depts, reportType }));
    }

    let supplierUrl = `/route.php?c=supplier/getAll`;

    supplierUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    let suppliers = await requestGet({ url: supplierUrl });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    brandUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    let brands = await requestGet({ url: brandUrl });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
    dispatch(setHideAllAction(false));
  } else {
    dispatch(
      setReportDepartmentsAction({ reportType: reportType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: reportType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: reportType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: reportType, level5s: [] }));


    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url: `/route.php?c=brand/getAll`,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
    dispatch(setHideAllAction(false));
  }
};

export const setCategory = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let cat = _.filter(store.getState().retail.categories, cat => {
      if (cat.ID === parseInt(val.ID)) {
        return cat;
      }
    });
    let category = cat[0];
    dispatch(setReportCategoryAction(category));
    loadProducts(store, dispatch);
  } else {
    dispatch(setReportCategoryAction(''));
    loadProducts(store, dispatch);
  }
};

export const setCategories = cats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  const state = store.getState().retail;
  let reportType = 'basket_analysis';
  if (cats.length > 0) {
    const isSelectAll = cats.filter(cat => cat.ID === 0).length > 0;

    if (isSelectAll) {
      if (state.selectedCategories.length > cats.length){
        let tempArray = [...cats];
        tempArray.shift();
        dispatch(setReportCategoriesAction({
          categories: tempArray,
          reportType,}));
      } else {
        dispatch(setReportCategoriesAction({
          categories: state.categories,
          reportType,}));
      }
    } else if (
      state.selectedCategories.filter(cat => cat.ID === 0).length > 0 &&
      filter(cats, { ID: 0 }).length === 0
    ) {
      // Remove Select All
      dispatch(setReportCategoriesAction({ categories: [], reportType }));
    } else {
      // Select Specific
      dispatch(setReportCategoriesAction({ categories: cats, reportType }));
    }

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&category=' +
        encodeURIComponent(map(cats, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url: `/route.php?c=brand/getAll&category=${encodeURIComponent(
        map(cats, 'Text').join(','),
      )}`,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  } else {
    dispatch(setReportCategoriesAction({ reportType, categories: [] }));
    dispatch(setSubCategoriesAction({ reportType, subCategories: [] }));
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url: `/route.php?c=brand/getAll`,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  }
};

export const setSubCategory = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let subCat = _.filter(store.getState().retail.subCategories, cat => {
      if (cat.ID === parseInt(val.ID)) {
        return cat;
      }
    });
    let subCategory = subCat[0];

    dispatch(setSubCategoryAction(subCategory));
    loadProducts(store, dispatch);
  } else {
    dispatch(setSubCategoryAction(''));
    loadProducts(store, dispatch);
  }
};

export const setSubCategories = subCats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  const state = store.getState().retail;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = state.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(
      setSubCategoriesAction({ subCategories: subCats, reportType: rptType }),
    );

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(subCats, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(subCats, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction({ level5s: [], reportType: rptType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setLevel4 = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let level4 = _.filter(store.getState().retail.level4s, item => {
      if (item.ID === parseInt(val.ID)) {
        return item;
      }
    });
    let lvl4 = level4[0];
    dispatch(setLevel4Action(lvl4));
    loadProducts(store, dispatch);
  } else {
    dispatch(setLevel4Action(''));
    loadProducts(store, dispatch);
  }
};

export const setLevel4s = opts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  const state = store.getState().retail;
  let { reportType } = state;
  if (opts.length > 0) {
    dispatch(setLevel4sAction({ level4s: opts, reportType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(opts, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setLevel5 = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let level5 = _.filter(store.getState().retail.level5s, item => {
      if (item.ID === parseInt(val.ID)) {
        return item;
      }
    });
    let lvl5 = level5[0];
    dispatch(setLevel5Action(lvl5));
    loadProducts(store, dispatch);
  } else {
    dispatch(setLevel5Action(''));
    loadProducts(store, dispatch);
  }
};

export const setLevel5s = lvl5s => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  const state = store.getState().retail;
  let { reportType } = state;
  if (lvl5s.length > 0) {
    dispatch(setLevel5sAction({ reportType, level5s: lvl5s }));
    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '&level5=' +
        encodeURIComponent(map(lvl5s, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setLevel5sAction({ level5s: [], reportType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands = await requestGet({
      url:
        '/route.php?c=brand/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '',
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const drillTableHeaders = val => async dispatch => {
  const state = store.getState().retail;
  const lastSelectedFilter = getLastSelectedFilter(state);

  switch(lastSelectedFilter) {
    case 'department':
      const depts = state.departments.filter(dept => dept.Text === val)[0];
      await dispatch(setReportDepartmentsAction({ departments: [depts] }));
      await dispatch(loadCatPenDistributionGraphData(val));
      dispatch(setReportDepartmentsAction({ departments: [] }));
      break;
    case 'category':
      const cats = state.categories.filter(cat => cat.Text === val)[0];
      await dispatch(setReportCategoriesAction({ categories: [cats] }));
      await dispatch(loadCatPenDistributionGraphData(val));
      dispatch(setReportCategoriesAction({ categories: [] }));
      break;
    case 'subCategory':
      const subCats = state.subCategories.filter(
        subCat => subCat.Text === val,
      )[0];
      await dispatch(setSubCategoriesAction({ subCategories: [subCats] }));
      await dispatch(loadCatPenDistributionGraphData(val));
      dispatch(setSubCategoriesAction({ subCategories: [] }));
      break;
    case 'level4s':
      const level4s = state.level4s.filter(lvl4 => lvl4.Text === val)[0];
      await dispatch(setLevel4sAction({ level4s: [level4s] }));
      await dispatch(loadCatPenDistributionGraphData(val));
      dispatch(setLevel4sAction({ level4s: [] }));
      break;
    case 'level5s':
      const level5s = state.level5s.filter(lvl5 => lvl5.Text === val)[0];
      await dispatch(setLevel5sAction({ level5s: [level5s] }));
      await dispatch(loadCatPenDistributionGraphData(val));
      dispatch(setLevel5sAction({ level5s: [] }));
    }
};

export const drillGraphResults = val => async dispatch => {
  const state = store.getState().retail;

  const lastSelectedFilter = getLastSelectedFilter(state);

  if (lastSelectedFilter === 'department') {
    const depts = state.departments.filter(dept => dept.Text === val)[0];
    await dispatch(setReportDepartmentsAction({ departments: [depts] }));
    dispatch(loadCategoryPenetrationData());
  } else if (lastSelectedFilter === 'category') {
    const cats = state.categories.filter(cat => cat.Text === val)[0];
    await dispatch(setReportCategoriesAction({ categories: [cats] }));
    dispatch(loadCategoryPenetrationData());
  } else if (lastSelectedFilter === 'subCategory') {
    const subCats = state.subCategories.filter(
      subCat => subCat.Text === val,
    )[0];
    await dispatch(setSubCategoriesAction({ subCategories: [subCats] }));
    dispatch(loadCategoryPenetrationData());
  } else if (lastSelectedFilter === 'level4s') {
    const level4s = state.level4s.filter(lvl4 => lvl4.Text === val)[0];
    await dispatch(setLevel4sAction({ level4s: [level4s] }));
    dispatch(loadCategoryPenetrationData());
  } else if (lastSelectedFilter === 'level5s') {
    const level5s = state.level5s.filter(lvl5 => lvl5.Text === val)[0];
    await dispatch(setLevel5sAction({ level5s: [level5s] }));
    dispatch(loadCategoryPenetrationData());
  } else if (lastSelectedFilter === 'sku') {
    dispatch(
      setDrillData({
        categories: state.selectedCategories,
        departments: state.selectedDepartments,
        subCategories: state.selectedSubCategories,
        level4s: state.selectedLevel4s,
        level5s: state.selectedLevel5s,
      }),
    );
    dispatch(setIsSkuDrill(true));
    dispatch(push('/retail/sku-performance'));
  }
};

export const drillBubbleGraph = val => async dispatch => {
  const state = store.getState().retail;

  const lastSelectedFilter = getLastSelectedFilter(state);

  if (lastSelectedFilter === 'department') {
    const depts = state.departments.filter(dept => dept.Text === val)[0];
    await dispatch(setReportDepartmentsAction({ departments: [depts] }));
    dispatch(loadCategoryPerformanceData());
  } else if (lastSelectedFilter === 'category') {
    const cats = state.categories.filter(cat => cat.Text === val)[0];
    await dispatch(setReportCategoriesAction({ categories: [cats] }));
    dispatch(loadCategoryPerformanceData());
  } else if (lastSelectedFilter === 'subCategory') {
    const subCats = state.subCategories.filter(
      subCat => subCat.Text === val,
    )[0];
    await dispatch(setSubCategoriesAction({ subCategories: [subCats] }));
    dispatch(loadCategoryPerformanceData());
  } else if (lastSelectedFilter === 'level4s') {
    const level4s = state.level4s.filter(lvl4 => lvl4.Text === val)[0];
    await dispatch(setLevel4sAction({ level4s: [level4s] }));
    dispatch(loadCategoryPerformanceData());
  } else if (lastSelectedFilter === 'level5s') {
    const level5s = state.level5s.filter(lvl5 => lvl5.Text === val)[0];
    await dispatch(setLevel5sAction({ level5s: [level5s] }));
    dispatch(loadCategoryPerformanceData());
  } else if (lastSelectedFilter === 'sku') {
    dispatch(
      setDrillData({
        categories: state.selectedCategories,
        departments: state.selectedDepartments,
        subCategories: state.selectedSubCategories,
        level4s: state.selectedLevel4s,
        level5s: state.selectedLevel5s,
      }),
    );
    dispatch(setIsSkuDrill(true));
    dispatch(push('/retail/sku-performance'));
  }
};

const loadProducts = async (store, dispatch) => {
  let state = store.getState().retail;
  const user = store.getState().user.authUser;
  let productsUrl = window.location.href.includes('basket-product-analysis')
    ? formatUrlWithSingleParameter(
        '/route.php?c=product/getAllRetail',
        state,
        user,
      )
    : formatUrlWithParameters(
        '/route.php?c=product/getAllRetail',
        state,
        user,
      );
  productsUrl += `&retail=1`;
  const productsData = await requestGet({ url: productsUrl });

  each(productsData, prod => {
    prod.WVProdDesc = encodeText(prod.WVProdDesc);
  });
  dispatch(fetchProductsAction(productsData));
};

export const setMember = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedMembersAction(val));

  const depots = await requestGet({
    url: `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v.Text).join(
      ',',
    ))}`,
  });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};

export const setSupplier = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let supplier = {};
  if (val) {
    supplier = val;
    await dispatch(
      setSupplierAction({
        supplier: val,
        reportType: store.getState().retail.reportType,
      }),
    );
    loadProducts(store, dispatch);
    const state = store.getState().retail;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    url += `&supplier=${encodeURIComponent(supplier.Text)}`;

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Text = encodeText(brd.Brand);
      brd.ID = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(
      setSupplierAction({
        reportType: store.getState().retail.reportType,
        supplier: '',
      }),
    );
    loadProducts(store, dispatch);
    const state = store.getState().retail;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Text = encodeText(brd.Brand);
      brd.ID = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setSuppliers = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val.length !== 0) {
    await dispatch(setSelectedSuppliersAction(val));
    const state = store.getState().retail;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    url += `&supplier=${encodeURIComponent(
      map(state.selectedSuppliers, 'Text').join(','),
    )}`;
    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  } else {
    dispatch(setSelectedSuppliersAction([]));
    const state = store.getState().retail;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  }
};

export const setDepot = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(
    setDepotAction({
      selectedDepots: val,
      reportType: store.getState().performanceReport.reportType,
    }),
  );
};

export const setAccountName = val => dispatch => {
  dispatch(setAccountNameAction(val));
};

export const setTimeFrame = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setTimeFrameAction(val));
};

export const setBrand = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setBrandAction(val));
  loadProducts(store, dispatch);
};

export const setBrands = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedBrandsAction(val));
  loadProducts(store, dispatch);
};

export const setRetailType = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setRetailTypeAction(val));
};

export const setTitle = (val, type) => dispatch => {
  dispatch(setLoadingAction(true));
  const state = store.getState().performanceReport;
  if (type === 'department' && state.departments.length === 1) {
    type = 'category';
  }
  dispatch(setTitleAction({ title: val, reportType: type }));
  let members = state.members;
  if (
    members.length === 2 &&
    _.filter(members, member => member.Text === 'ALL').length > 0
  ) {
    if (result.Categories && result.Categories.length > 0) {
      dispatch(setHideAllAction(true));
    }
    dispatch(setSelectedMembersAction([members[0]]));
  }
};

export const setSubPage = (val, type) => dispatch => {
  dispatch(setSubPageAction(val));
};

export const setMeasure = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setMeasureAction(val));
};

export const setCoreRange = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setCoreRangeAction(val));
};

export const setLikeForLike = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setLikeForLikeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setPurchaseAction(val));
};

export const setBusinessType = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(
    setBusinessTypeAction({
      selectedBusinessType: val,
      reportType: store.getState().performanceReport.reportType,
    }),
  );
};

const loadChart = (store, dispatch) => {
  dispatch(setGraphLoadedAction(false));
  let state = store.getState().performanceReport;
  let url =
    '/route.php?c=performance/getPerformance&primary_field=' +
    state.reportType +
    '&timeframe=' +
    state.timeframe +
    '&measure=' +
    state.measure +
    '&like_for_like=' +
    state.likeForLike +
    '&type=' +
    state.purchase;

  url +=
    state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url += state.selectedLevel5s.length
    ? '&level5=' +
      encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
    : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';
  url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  url +=
    state.coreRange && state.coreRange.ID
      ? '&core_range=' + encodeURIComponent(state.coreRange.ID)
      : '';
  url +=
    state.accountName.length > 0
      ? '&storeid=' + state.accountName
          .map(val => encodeURIComponent(val.RegNo))
          .join(',')
      : '';

  let memberIds = [];

  if (
    _.filter(state.selectedMembers, member => member.Text === 'ALL').length > 0
  ) {
    url += '&member=';
  } else {
    _.each(state.selectedMembers, member => {
      memberIds.push(member.Text);
    });

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }
  }

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      dispatch(fetchPerformanceDataGraphAction(response.results));
      dispatch(setLoadingAction(false));
      dispatch(setGraphLoadedAction(true));
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setGraphLoadedAction(true));
    });
};

export const exportAllToCSV = () => dispatch => {
  let state = store.getState().performanceReport;

  let url =
    '/route.php?c=performance/getPerformanceTable&limit=1000&primary_field=' +
    state.reportType +
    '&timeframe=' +
    state.timeframe +
    '&measure=' +
    state.measure +
    '&like_for_like=' +
    state.likeForLike +
    '&type=' +
    state.purchase;

  const user = store.getState().user.authUser;
  if (user.showCompetitor && user.supplier.Text) {
    url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
  }

  url +=
    state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';
  url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  url +=
    state.accountName.length > 0
      ? '&storeid=' + state.accountName
      .map(val => encodeURIComponent(val.RegNo))
      .join(',')
      : '';
  let memberIds = [];

  if (
    _.filter(state.selectedMembers, member => member.Text === 'ALL').length > 0
  ) {
    url += '&member=';
  } else {
    _.each(state.selectedMembers, member => {
      memberIds.push(member.Text);
    });

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }
  }

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      dispatch(setLoadingAction(false));
      dispatch(setTableLoadedAction(true));
      if (response.results) {
        dispatch(fetchPerformanceAllDataTableAction(response.results));
      } else {
        dispatch(
          setReportErrorAction('Failed to load report, please try again.'),
        );
      }
    })
    .catch(err => {
      dispatch(setTableLoadedAction(true));
    });
};

const loadTable = (store, dispatch) => {
  dispatch(setTableLoadedAction(false));
  dispatch(setReportErrorAction(''));
  let state = store.getState().performanceReport;

  let url =
    '/route.php?c=performance/getPerformanceTable&primary_field=' +
    state.reportType +
    '&timeframe=' +
    state.timeframe +
    '&like_for_like=' +
    state.likeForLike +
    '&measure=' +
    state.measure +
    '&type=' +
    state.purchase;

  const user = store.getState().user.authUser;
  if (user.showCompetitor && user.supplier.Text) {
    url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
  }

  url +=
    state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';
  url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  url +=
    state.coreRange && state.coreRange.ID
      ? '&core_range=' + encodeURIComponent(state.coreRange.ID)
      : '';
  url +=
    state.accountName.length > 0
      ? '&storeid=' + state.accountName
      .map(val => encodeURIComponent(val.RegNo))
      .join(',')
      : '';
  let memberIds = [];

  if (
    _.filter(state.selectedMembers, member => member.Text === 'ALL').length > 0
  ) {
    url += '&member=';
  } else {
    _.each(state.selectedMembers, member => {
      memberIds.push(member.Text);
    });

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }
  }

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      dispatch(setLoadingAction(false));
      dispatch(setTableLoadedAction(true));
      if (response.results) {
        dispatch(fetchPerformanceDataTableAction(response.results));
      } else {
        dispatch(
          setReportErrorAction('Failed to load report, please try again.'),
        );
      }
    })
    .catch(err => {
      dispatch(setTableLoadedAction(true));
    });
};

export const setPenetrationReportType = val => dispatch => {
  dispatch(setPenetrationReportTypeAction(val));
  dispatch(setIsRefreshDisabled(false));
};

export const parseGraph = (distributionGraphData, drillVal, graphType) => {
  let data = distributionGraphData[graphType];

  let graphData = { labels: [], datasets: [] };

  let dataRow = {
    label: drillVal + ' - Value TY',
    borderColor: theme.lineGraph1A,
    backgroundColor: theme.lineGraph1A,
    fill: false,
    data: [],
    yAxisID: 'y-axis-1',
  };

  let dataRow1 = {
    label: drillVal + (graphType == 'pareto' ? ' - Cumulative % of Total Sales' : ' - Sales Per Store'),
    borderColor: theme.lineGraph2A,
    backgroundColor: theme.lineGraph2A,
    fill: false,
    data: [],
    yAxisID: 'y-axis-2',
  };

  _.each(data, dt => {
    if (dt.Primary && dt.Primary.length > 0) {
      graphData.labels.push(dt.Primary);
    }

    if (dt.Value_TY && dt.Primary) {
      dataRow.data.push(dt.Value_TY);
    }
    if (dt.Percentage && dt.Primary) {
      dataRow1.data.push(dt.Percentage);
    } else if (dt.SalesPerStore && dt.Primary) {
      dataRow1.data.push(dt.SalesPerStore);
    }
  });
  graphData.datasets.push(dataRow);
  graphData.datasets.push(dataRow1);

  return graphData;
};

export const loadCatPenDistributionGraphData = drillVal => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailDistribution/distributionGraphs',
    state,
    user,
  );

  const distributionGraphData = await requestGet({ url });

  const payload = {
    "data": {
      "pareto": parseGraph(distributionGraphData, drillVal, 'pareto'),
      "sales_by_store": parseGraph(distributionGraphData, drillVal, 'sales_by_store')
    },
    "filterVal": drillVal}

  dispatch(setCatPenDistributionGraphDataAction(payload));
  dispatch(setLoadingAction(false));
};

export const loadCategoryPenetrationData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailDistribution/distribution',
    state,
    user,
  );

  const categoryPenetrationData = await requestGet({ url });
  dispatch(setCategoryPenetrationDataAction(categoryPenetrationData));
  dispatch(setLoadingAction(false));
};

export const loadRetailerPerformanceData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailStorePerformance/bostonMatrix',
    state,
    user,
  );

  const retailerPerformanceDataResponse = await requestGet({ url });
  const retailerPerformanceData = retailerPerformanceDataResponse.map(data => ({
    value: data.Value,
    radius: data.CircleSize,
    stores: data.StoreNumber,
    text: data.Section,
    color:
      data.Section === 'Dog'
        ? 'red'
        : data.Section === 'Star'
        ? 'green'
        : 'yellow',
    sortOrder:
      data.Section === 'Dog'
        ? 3
        : data.Section === 'Star'
        ? 2
        : data.Section === 'Question Mark'
        ? 1
        : 4,
  }));
  const sortedData = retailerPerformanceData.sort(
    (a, b) => a.sortOrder - b.sortOrder,
  );

  dispatch(setRetailerPerformanceData(retailerPerformanceData));
  dispatch(setLoadingAction(false));
};

export const loadRetailerPerformanceTableData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailStorePerformance/bostonMatrixDetails',
    state,
    user,
  );

  const categoryPenetrationTableData = await requestGet({ url });
  dispatch(setRetailerPerformanceTableData(categoryPenetrationTableData));
  dispatch(setLoadingAction(false));
};

export const loadSkuPerformanceData = prevState => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;
  const url = formatUrlWithParameters(
    '/route.php?c=retailSkuPerformance/performance',
    state,
    user,
    prevState,
  );

  const skuPerformanceData = await requestGet({ url });
  dispatch(setSkuPerformanceData(skuPerformanceData));
  dispatch(setLoadingAction(false));
  dispatch(setIsSkuDrill(false));
  dispatch(setDrillData({}));
};

export const loadCategoryPerformanceData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailCategoryPerformance/bostonMatrix',
    state,
    user,
  );

  const categoryPerformanceData = await requestGet({ url });
  dispatch(setCategoryPerformanceData(categoryPerformanceData));
  dispatch(setLoadingAction(false));
};

export const loadSalesByWeekGraphData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithParameters(
    '/route.php?c=retailTrend/performance',
    state,
    user,
  );

  const salesByWeekGraphData = await requestGet({ url });
  const formattedData = formatSalesByWeekAPIData(salesByWeekGraphData);
  dispatch(setSalesByWeekGraphDataAction(formattedData));
  dispatch(setLoadingAction(false));
};

export const loadBasketProductAnalysis = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const url = formatUrlWithSingleParameter(
    '/route.php?c=retailBasketProduct/productAnalysis',
    state,
    user,
  );

  const basketProductAnalysisData = await requestGet({ url });
  // Put UNIVERSE in 1st position
  let cards = basketProductAnalysisData.cards;
  cards.forEach((item, i) => {
    if (item.Card === 'Universe') {
      cards.splice(i, 1);
      cards.unshift(item);
    }
  });
  basketProductAnalysisData.cards = cards;
  dispatch(setBasketProductAnalysisDataAction(basketProductAnalysisData));
  dispatch(setLoadingAction(false));
};

export const loadAnalyticsOverviewData= () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const graphUrl = formatUrlWithParameters(
    '/route.php?c=analyticsBasketOverview/basketCategories',
    state,
    user,
  );

  const bestPerformersUrl = formatUrlWithParameters(
    '/route.php?c=analyticsBasketOverview/basketBestPerformers',
    state,
    user,
  );

  const basketTotalsUrl = formatUrlWithParameters(
    '/route.php?c=analyticsBasketOverview/basketTotals',
    state,
    user,
  );

  const [analyticsOverviewGraphData, bestPerformersData, basketTotalsData] =
    await Promise.all([
      requestGet({ url: graphUrl }),
      requestGet({ url: bestPerformersUrl }),
      requestGet({ url: basketTotalsUrl }),
    ]);

  const basketTotalsFormatted = basketTotalsData.map(data => ({
    ...data,
    Value: htmlDecodePound(data.Value),
  }));

  const bestPerformersFormatted = bestPerformersData;

  const analyticsOverviewData = {
    analyticsOverviewGraphData,
    bestPerformersData: bestPerformersFormatted,
    basketTotalsData: basketTotalsFormatted,
  };
  dispatch(setAnalyticsOverviewData(analyticsOverviewData));
  dispatch(setLoadingAction(false));
}; 

export const loadBasketOverviewData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  const state = store.getState().retail;
  const user = store.getState().user.authUser;

  const graphUrl = formatUrlWithParameters(
    '/route.php?c=retailBasketOverview/basketCategories',
    state,
    user,
  );

  const bestPerformersUrl = formatUrlWithParameters(
    '/route.php?c=retailBasketOverview/basketBestPerformers',
    state,
    user,
  );

  const basketTotalsUrl = formatUrlWithParameters(
    '/route.php?c=retailBasketOverview/basketTotals',
    state,
    user,
  );

  const [basketOverviewGraphData, bestPerformersData, basketTotalsData] =
    await Promise.all([
      requestGet({ url: graphUrl }),
      requestGet({ url: bestPerformersUrl }),
      requestGet({ url: basketTotalsUrl }),
    ]);

  const basketTotalsFormatted = basketTotalsData.map(data => ({
    ...data,
    Value: htmlDecodePound(data.Value),
  }));

  const bestPerformersFormatted = bestPerformersData;

  const basketOverviewData = {
    basketOverviewGraphData,
    bestPerformersData: bestPerformersFormatted,
    basketTotalsData: basketTotalsFormatted,
  };
  dispatch(setBasketOverviewData(basketOverviewData));
  dispatch(setLoadingAction(false));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      departments: payload.departments,
      categories: payload.categories,
      members: payload.members,
      brands: payload.brands,
      suppliers: payload.suppliers,
      accountNames: payload.accountNames,
      depots: payload.depots,
      purchase: payload.purchase,
      measure: payload.measure,
      coreRange: payload.coreRange,
      likeForLike: payload.likeForLike,
      timeframe: payload.timeframe,
      businessTypes: payload.businessTypes,
      retailType: payload.retailType,
      isInitialLoadComplete: !state.isInitialLoadComplete,
    }),
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        payload,
      ],
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = typeof payload.departments[0] == 'undefined' ? [] : flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (allCategories.length > 1) {
        allCategories.unshift({ ID: 0, Text: 'Select all', SubCategories: [] });
      }

      return {
        ...state,
        selectedDepartment: payload.departments[0],
        selectedDepartments: payload.departments,
        categories: allCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_REPORT_DEPARTMENT]: (state, { payload: { ID, Text, Categories } }) => {
      return {
        ...state,
        selectedDepartment: ID && Text ? { ID, Text } : '',
        categories: Categories || [],
      };
    },

    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = typeof payload.categories[0] == 'undefined' ? [] : flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );
      let orderedSubCategories = uniqBy(
        orderBy(
          filter(allSubCategories, subCat => subCat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_REPORT_CATEGORY]: (
      state,
      { payload: { ID, Text, SubCategories } },
    ) => ({
      ...state,
      selectedCategory: ID && Text ? { ID, Text } : '',
      subCategories: SubCategories || [],
    }),
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = typeof payload.subCategories[0] == 'undefined' ? [] : flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );
      if (allLevel4s.length > 1) {
        allLevel4s.push({ ID: 0, Text: 'Select all', Level5s: [] });
      }
      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: uniqBy(
          orderBy(
            filter(allLevel4s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_SUBCATEGORY]: (state, { payload: { ID, Text, Level4 } }) => ({
      ...state,
      selectedSubCategory: ID && Text ? { ID, Text } : '',
      level4s: Level4 || [],
    }),
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = typeof payload.level4s[0] == 'undefined' ? [] : flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );
      if (allLevel5s.length > 1) {
        allLevel5s.push({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: uniqBy(
          orderBy(
            filter(allLevel5s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_LEVEL4]: (state, { payload: { ID, Text, Level5 } }) => ({
      ...state,
      selectedLevel4: ID && Text ? { ID, Text } : '',
      level5s: Level5 || [],
    }),
    [SET_LEVEL5S]: (state, { payload }) => ({
      ...state,
      selectedLevel5s: payload.level5s,
      reportType: payload.reportType,
    }),
    [SET_LEVEL5]: (state, { payload: { ID, Text } }) => ({
      ...state,
      selectedLevel5: ID && Text ? { ID, Text } : '',
    }),
    [SET_BRAND]: (state, { payload }) => ({
      ...state,
      brand: payload,
      selectedBrand: payload,
    }),
    [SET_SELECTED_PRODUCT]: (state, { payload }) => ({
      ...state,
      selectedProduct: payload,
    }),
    [SET_CURRENT_REPORT_PAGE]: (state, { payload }) => ({
      ...state,
      currentReportPage: payload,
    }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_SUPPLIERS]: (state, { payload }) => {
      let formattedSuppliers;
      if (payload[0] && payload[0].SupplierName) {
        formattedSuppliers = payload.map(supplier => ({
          Text: supplier.SupplierName,
          ID: supplier.SupplierName,
        }));
        return { ...state, suppliers: formattedSuppliers };
      } else {
        return { ...state, suppliers: payload };
      }
    },
    [SET_BRANDS]: (state, { payload }) => {
      let formattedBrands;
      if (payload[1] && payload[1].Brand) {
        formattedBrands = payload.map(brand => ({
          Text: brand.Brand,
          ID: brand.Brand,
        }));
        return { ...state, brands: formattedBrands };
      } else {
        return { ...state, brands: payload };
      }
    },
    [SET_SELECTED_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      selectedSuppliers: payload,
    }),
    [SET_SELECTED_BRANDS]: (state, { payload }) => ({
      ...state,
      selectedBrands: payload,
    }),
    [SET_SUPPLIER]: (state, { payload }) => ({
      ...state,
      supplier: payload.supplier,
      selectedSupplier: payload.supplier,
      reportType: payload.reportType,
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
      reportType: payload.reportType,
    }),
    [SET_MEASURE]: (state, { payload }) => ({ ...state, measure: payload }),
    [SET_CORE_RANGE]: (state, { payload }) => ({ ...state, coreRange: payload }),
    [SET_LIKE_FOR_LIKE]: (state, { payload }) => ({
      ...state,
      likeForLike: payload,
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      products: payload,
    }),

    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_TIMEFRAME]: (state, { payload }) => ({
      ...state,
      timeframe: payload.ID ? payload.ID : payload,
    }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName: payload,
    }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_SUBPAGE]: (state, { payload }) => ({ ...state, subPage: payload }),
    [SET_REPORT_TYPE]: (state, { payload }) => ({
      ...state,
      reportType: payload,
    }),
    [SET_TITLE]: (state, { payload }) => {
      return {
        ...state,
        title: payload.title,
        reportType: payload.reportType,
        departments: [],
        selectedDepartments: [],
        categories: [],
        selectedCategories: [],
        subCategories: [],
        selectedSubCategories: [],
        level4s: [],
        selectedLevel4s: [],
        level5s: [],
        selectedLevel5s: [],
        members: [],
        supplier: {},
        suppliers: [],
        kpiData: [],
        isLoaded: false,
        brands: [],
        brand: {},
        performanceDataGraph: [],
        performanceDataTable: [],
        depots: [],
        selectedDepots: [],
        businessTypes: [],
        selectedBusinessType: '',
        salesByWeekGraphData: {},
        categoryPenetrationData: {},
        distributionGraphData: {},
        retailerPerformanceData: {},
        retailerPerformanceTableData: {},
        skuPerformanceData: {},
        categoryPerformanceData: {},
        basketProductAnalysisData: {},
      };
    },
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),

    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_GRAPH_LOADED]: (state, { payload }) => ({
      ...state,
      isGraphLoaded: payload,
    }),
    [SET_TABLE_LOADED]: (state, { payload }) => ({
      ...state,
      isTableLoaded: payload,
    }),
    [FETCH_KPI_DATA]: (state, { payload }) => ({
      ...state,
      kpiData: payload,
    }),
    [FETCH_PERFORMANCE_DATA_GRAPH]: (state, { payload }) => ({
      ...state,
      performanceDataGraph: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [FETCH_PERFORMANCE_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      performanceDataTable: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [FETCH_PERFORMANCE_ALL_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      performanceAllDataTable: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload.selectedBusinessType,
      reportType: payload.reportType,
    }),
    [SET_SALES_BY_WEEK_GRAPH_DATA]: (state, { payload }) => ({
      ...state,
      salesByWeekGraphData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_CATEGORY_PENETRATION_DATA]: (state, { payload }) => ({
      ...state,
      categoryPenetrationData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_RETAIL_ANALYSIS_REPORT_DATA]: (state, { payload }) => ({
      ...state,
      retailAnalysisReportData: payload,
    }),
    [SET_DISTRIBUTION_GRAPH_DATA]: (state, { payload }) => ({
      ...state,
      distributionGraphData: payload.data,
      tableDrillValue: payload.filterVal
    }),
    [SET_RETAILER_PERFORMANCE_DATA]: (state, { payload }) => ({
      ...state,
      retailerPerformanceData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_RETAILER_PERFORMANCE_TABLE_DATA]: (state, { payload }) => ({
      ...state,
      retailerPerformanceTableData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_SKU_PERFORMANCE_DATA]: (state, { payload }) => ({
      ...state,
      skuPerformanceData: payload,

      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_CATEGORY_PERFORMANCE_DATA]: (state, { payload }) => ({
      ...state,
      categoryPerformanceData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_ANALYTICS_OVERVIEW_DATA]: (state, { payload }) => ({
      ...state,
      analyticsOverviewData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_BASKET_OVERVIEW_DATA]: (state, { payload }) => ({
      ...state,
      basketOverviewData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_BASKET_PRODUCT_ANALYSIS]: (state, { payload }) => ({
      ...state,
      basketProductAnalysisData: payload,
      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [SET_IS_REFRESH_DISABLED]: (state, { payload }) => ({
      ...state,
      isRefreshDisabled: payload,
    }),
    [SET_PENETRATION_REPORT_TYPE]: (state, { payload }) => ({
      ...state,
      penetrationReportType: payload,
    }),
    [SET_DRILL_DATA]: (state, { payload }) => ({
      ...state,
      drillData: payload,
    }),
    [SET_IS_SKU_DRILL]: (state, { payload }) => ({
      ...state,
      isSkuDrill: payload,
    }),
    [SET_RETAIL_TYPE]: (state, { payload }) => ({
      ...state,
      retailType: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      baseUrl: '',
      departments: [],
      selectedDepartments: [],
      selectedDepartment: '',
      categories: [],
      selectedCategories: [],
      selectedCategory: '',
      subCategories: [],
      selectedSubCategories: [],
      selectedSubCategory: '',
      members: [],
      brands: [],
      brand: null,
      selectedBrand: null,
      selectedBrands: [],
      selectedMembers: [],
      suppliers: [],
      supplier: '',
      selectedSuppliers: [],
      depots: [],
      selectedDepots: [],
      level4s: [],
      selectedLevel4s: [],
      selectedLevel4: '',
      level5s: [],
      selectedLevel5s: [],
      selectedLevel5: '',
      products: [],
      selectedProduct: '',
      selectedProducts: [],
      timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
        { ID: '4wks', Text: '4 Wk' },
        { ID: '13wks', Text: '13 Wk' },
        { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
        { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
        { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
        { ID: 'YTD', Text: 'YTD' },
        { ID: 'MAT', Text: 'MAT' },
      ],
      timeframe: '',
      purchases: [
        { ID: 'in', Text: 'IN' },
        { ID: 'out', Text: 'OUT' },
      ],
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
      likeForLikes: [
        { ID: 'like_for_like', Text: 'Like For Like' },
        { ID: 'absolute', Text: 'Absolute' },
      ],
      coreRanges: [
        { ID: 'yes', Text: 'Yes' },
        { ID: 'no', Text: 'No' },
      ],
      retailTypes: [
        { ID: 'independent', Text: 'Independent' },
        { ID: 'company_owned', Text: 'Company Owned' },
      ],
      retailType: '',
      coreRange: '',
      measure: '',
      likeForLike: '',
      purchase: 'out',
      performanceDataGraph: [],
      performanceDataTable: [],
      performanceAllDataTable: [],
      title: '',
      subPage: '',
      isLoading: false,
      isGraphLoaded: false,
      isTableLoaded: false,
      isExporting: false,
      isExported: false,
      reportType: '',
      currentReportPage: '',
      kpiData: [],
      isLoaded: false,
      hideAll: false,
      pdfURL: '',
      businessTypes: [],
      selectedBusinessType: '',
      reportError: '',
      isInitialLoadComplete: false,
      penetrationReportType: { ID: 'department', Text: 'Category' },
      penetrationReportTypes: [
        { ID: 'department', Text: 'Category' },
        { ID: 'supplier', Text: 'Supplier' },
        { ID: 'brand', Text: 'Brand' },
      ],
      salesByWeekGraphData: {},
      categoryPenetrationData: {},
      distributionGraphData: {},
      retailerPerformanceData: {},
      retailerPerformanceTableData: {},
      skuPerformanceData: {},
      categoryPerformanceData: {},
      basketOverviewData: {},
      basketProductAnalysisData: {},
      isRefreshDisabled: false,
      retailAnalysisReportData: null,
      // RESET DRIL DATA IF SKU DRILL IS FALSE
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
