import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  MemberDriversTable,
  MemberSupplierTable,
  MemberOpportunityTable,
} from '../../components/SupplierEngagement';
import { getUserSystem } from '../../../helpers/util';

const Member = ({ data, ...props }) => {
  return (
    <>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member/supplier`,
          )
        }>
        Performance
      </Button>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member/category`,
          )
        }>
        Category Opportunity
      </Button>
      {getUserSystem().toLowerCase() != 'confex' && (
        <Button
          onClick={() =>
            props.history.push(
              `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member/drivers`,
            )
          }>
          Drivers of Performance
        </Button>
      )}
      <Switch>
        <Route
          path="/nam_dash/supplier_engagement_report/member/category"
          render={pageProps => (
            <MemberOpportunityTable
              title="Opportunity Table"
              {...pageProps}
              data={data && data.opportunity}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/member/supplier"
          render={pageProps => (
            <MemberSupplierTable
              title="Supplier Table"
              {...pageProps}
              data={data && data.overview}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/member/drivers"
          render={pageProps => (
            <MemberDriversTable
              title="Drivers Table"
              {...pageProps}
              data={data && data.drivers}
            />
          )}
        />
        <Redirect
          to={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member/category`}
        />
      </Switch>
    </>
  );
};

export default Member;
