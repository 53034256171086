import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { getUserSystem, hasSalesInPermissions } from '../../helpers/util';
import 'react-select/dist/react-select.css';
import LaunchIcon from '@material-ui/icons/Launch';
import Select from 'react-select';
import {
  loadTable,
  exportToPDF,
  setPmp,
  setDepartment,
  setCategory,
  setSubCategory,
  setBrand,
  setLevel4,
  setLevel5,
  setPurchase,
  resetReport,
  fetchCategories,
  loadReport,
  setTimeframe,
  exportAllToCSV,
  setGroup,
} from '../redux/reducers/stockedReport';
import { exportTableToCSV, getRandomNumber } from '../../helpers/helpers';
import { hideFor, showFor } from '../../helpers/util';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ExportIcon from '../../assets/images/export-icon.svg';
import Msg from '../components/ToastExportToPDF';
import TableStocked from '../components/TableStocked';
import { ToastContainer, toast } from 'react-toastify';
import DateLabel from '../components/DateLabel';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import ReportContainer from '../components/ReportContainer';
import ReportSettingsContainer from '../components/ReportSettingsContainer';
import Loading from '../components/Loading';

import CustomCountSelect from '../components/CustomCountSelect';
import { getUserData } from '../../helpers/user';

const StockedReport = ({
  data,
  match,
  isLoading,
  onExportData,
  stockedReportData,
  departments,
  department,
  categories,
  category,
  subCategory,
  subCategories,
  level4s,
  level4,
  level5s,
  level5,
  brands,
  brand,
  pmpList,
  pmp,
  onPmpChange,
  purchases,
  purchase,
  onPurchaseChange,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onBrandChange,
  toggleExportMenu,
  onRefreshReport,
  timeframe,
  timeframes,
  onTimeFrameChange,
  history,
  exportAllData,
  onGroupChange,
  groups,
  selectedGroup,
}) => (
  <motion.div
    key={'stokcked-report-id'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty c-alchemy-toasty--others`}
    />
    <div className="sub-menu-border bg-white">
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
            <div className="sub-menu__left">
              <h2 className="text-uppercase">GAP ANALYSIS</h2>
              <div className="d-flex">
                <div className="btn-group mt-1 text-uppercase">
                  <span className="dropdown-align-fix">For Latest:</span>
                </div>
                <div className="btn-group  text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Time"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`wd-130 small-dropdown c-select-header c-select-header-row--others`}
                    name="timeframe"
                    id="timeframe"
                    value={timeframe}
                    onChange={onTimeFrameChange}
                    options={timeframes}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                    isMulti={false}
                  />
                </div>
                {hasSalesInPermissions(match.params.subpage) && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="Sales In"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 small-dropdown c-select-header c-select-header-row--others`}
                      name="purchases"
                      id="purchases"
                      value={purchase}
                      onChange={onPurchaseChange}
                      options={purchases}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
                {hideFor('caterforce', 'countryRangeGroup', 'cotswold') && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="PMPList"
                      className={`wd-100 pc-dropdown small-dropdown c-select-header c-select-header-row--others`}
                      name="pmp"
                      id="pmp"
                      value={pmp}
                      onChange={onPmpChange}
                      options={pmpList}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div>
                  <button
                    onClick={onRefreshReport}
                    disabled={data.isRefreshDisabled}
                    className={`btn alchemy-btn--others mr-2`}>
                    Refresh <i className="fa fa-refresh" />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      const win = window.open(
                        history.location.pathname,
                        '_blank',
                      );
                      win.focus();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    New tab
                    <LaunchIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <Dropdown
                    isOpen={data.isExportMenuOpen}
                    toggle={() => toggleExportMenu()}>
                    <DropdownToggle
                      className={`dropddown-toggle-btn alchemy-btn--others`}>
                      Export <img src={ExportIcon} alt="export" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv');
                        }}>
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv_all');
                        }}>
                        CSV - All
                      </DropdownItem>
                      {/* {getUserSystem() !== 'confex' &&
                        {
                          /* <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('pdf');
                          }}>
                          PDF
                        </DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container sub-menu-bottom">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <div className="form-row">
                {departments.length > 0 && (
                  <div className="form-group  col-md-5 col-lg-3">
                    <Select
                      className={`c-select-header c-select-header-row--others`}
                      name="department"
                      id="department"
                      placeholder="Department"
                      value={department ? department.ID : ''}
                      onChange={onDepartmentChange}
                      options={departments}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {categories.length > 0 && (
                  <div className="form-group  col-md-5 col-lg-3">
                    <Select
                      placeholder="Category"
                      className={`c-select-header c-select-header-row--others`}
                      name="category"
                      id="category"
                      value={category ? category.ID : ''}
                      onChange={onCategoryChange}
                      options={categories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {subCategories.length > 0 && (
                  <div className="form-group  col-md-5 col-lg-3">
                    <Select
                      className={`c-select-header c-select-header-row--others`}
                      name="subcategory"
                      id="subcategory"
                      placeholder="SubCategory"
                      value={subCategory ? subCategory.ID : ''}
                      onChange={onSubCategoryChange}
                      options={subCategories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {level4s.length > 1 && (
                  <div className="form-group  col-md-5 col-lg-3">
                    <Select
                      placeholder="Level4"
                      className={`c-select-header c-select-header-row--others`}
                      name="level4"
                      id="level4"
                      value={level4 ? level4.ID : ''}
                      onChange={onLevel4Change}
                      options={level4s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {level5s.length > 1 && (
                  <div className="form-group  col-md-5 col-lg-3">
                    <Select
                      placeholder="Level5"
                      className={`c-select-header c-select-header-row--others`}
                      name="level5"
                      id="level5"
                      value={level5 ? level5.ID : ''}
                      onChange={onLevel5Change}
                      options={level5s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}

                {brands && brands.length > 0 ? (
                  <div className="form-group  col-md-5 col-lg-3">
                    {/* <h3>BRAND:</h3>{' '} */}
                    <Select
                      style={{ minWidth: '200px' }}
                      className={`c-select-header c-select-header-row--others`}
                      name="brand"
                      id="brand"
                      value={brand.Brand}
                      onChange={onBrandChange}
                      options={brands}
                      valueKey="Brand"
                      labelKey="Brand"
                      placeholder="Brand"
                    />
                  </div>
                ) : (
                  ''
                )}
                {(getUserSystem() === 'demo' ||
                  getUserSystem() === 'caterforce' ||
                  getUserSystem() === 'countryRangeGroup') &&
                  departments &&
                  departments.length > 0 &&
                  groups &&
                  groups.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onGroupChange(ev)}
                        name="group"
                        id="group"
                        isMulti={true}
                        value={selectedGroup}
                        options={groups}
                        type={'Group Supplier'}
                        types={'Group Suppliers'}
                        placeholder={'Group Supplier'}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReportContainer justifyContent={isLoading ? 'center' : ''}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div
            className="nam-dash nam-dash-table-all"
            style={{ display: 'none' }}>
            <TableStocked
              tableClassName="o-table o-table-stock"
              data={exportAllData}
              id="stocked_table_all"
            />
          </div>
          <div className="container pt-3">
            <div className="row">
              <div className="col-12"></div>
              <div className="col-12 col-md-12">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}
                  className="nam-dash nam-dash-table">
                  <TableStocked
                    tableClassName="o-table o-table-stock"
                    data={stockedReportData}
                    id="stocked_table"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </>
      )}
    </ReportContainer>
  </motion.div>
);
function mapStateToProps(state) {
  const data = {
    stockedReportData: state.stockedReport.stockedReportData,
    isExporting: state.stockedReport.isExporting,
    isExported: state.stockedReport.isExported,
    pdfURL: state.stockedReport.pdfURL,
    isLoading: state.stockedReport.isLoading,
    departments: state.stockedReport.departments,
    department: state.stockedReport.department,
    categories: state.stockedReport.categories,
    category: state.stockedReport.category,
    subCategories: state.stockedReport.subCategories,
    subCategory: state.stockedReport.subCategory,
    pmpList: state.stockedReport.pmpList,
    pmp: state.stockedReport.pmp,
    purchases: state.stockedReport.purchases,
    purchase: state.stockedReport.purchase,
    level4s: state.stockedReport.level4s,
    level4: state.stockedReport.level4,
    level5s: state.stockedReport.level5s,
    level5: state.stockedReport.level5,
    brands: state.stockedReport.brands,
    brand: state.stockedReport.brand,
    timeframes: state.stockedReport.timeframes,
    timeframe: state.stockedReport.timeframe,
    exportAllData: state.stockedReport.exportAllData,
    groups: state.stockedReport.groups,
    selectedGroup: state.stockedReport.selectedGroup,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    loadTable,
    exportToPDF,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setLevel5,
    setPmp,
    setPurchase,
    setBrand,
    resetReport,
    fetchCategories,
    loadReport,
    setTimeframe,
    exportAllToCSV,
    setGroup,
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetReport();
      this.props.fetchCategories();
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'GAP Analysis',
            });
          }, 1000);
        }, 1000);
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} />, { autoClose: false });
      }

      if (
        nextProps.exportAllData !== this.props.exportAllData &&
        this.props.exportAllData
      ) {
        exportTableToCSV(
          $('#stocked_table_all'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onExportData: props => type => {
      if (type === 'csv') {
        exportTableToCSV(
          $('#stocked_table'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
      }

      if (type === 'csv_all') {
        props.exportAllToCSV();
      }

      if (type === 'pdf') {
        let fields = ``;
        let selectedPmp = props.pmpList.find(pmp => pmp.ID === props.pmp);
        fields += `${selectedPmp.Text.toUpperCase()}: ${selectedPmp.ID}`;
        let html = $('#stocked_table').html();
        html = html.replace(/<img/g, '<img style="width:20px"');
        html = html.replace(
          /\/assets\/images\/red-x-a2ffa.svg/g,
          'https://i.ibb.co/rcFcbGT/red.jpg',
        );
        html = html.replace(
          /\/assets\/images\/green-check-675a0.svg/g,
          'https://i.ibb.co/R7w5dRf/green.jpg',
        );
        let htmlString = `<html>
                              <head>
                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                                <style>
                                   .o-canvas__body,h1,p{font-family:"Gotham Light",Helvetica,Verdana;font-weight:100}tbody,tr{border-color:inherit}@font-face{font-family:"Gotham Light";src:url(http://alchemy.todays.co.uk/assets/fonts/gothamlight-e8bf7.woff2) format("woff2"),url(/assets/fonts/gothamlight-66252.woff) format("woff");font-weight:100;font-style:normal;font-display:fallback}@font-face{font-family:"Gotham Book";src:url(http://alchemy.todays.co.uk/assets/fonts/gothambook-a315e.woff2) format("woff2"),url(/assets/fonts/gothambook-57f47.woff) format("woff");font-weight:600;font-style:normal;font-display:fallback}p{font-size:.7em}h1{font-size:1em}table td,table th{font-family:Arial}.o-table{width:95%;margin:0 auto}table{border-spacing:0;border-collapse:separate;font-size:.48125em}table th{padding:.8em;vertical-align:top}tr{display:table-row;vertical-align:inherit}tbody{display:table-row-group;vertical-align:middle}table td{-webkit-font-smoothing:antialiased;border-bottom:1px solid #e0e8ec;height:2em;padding:.5em}.o-table .u-tac{padding-left:.8em; font-size:0.75em}.u-bg--brand{background-color:#bf73ab}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(2){background-color:#fff;border-bottom:none;border-right:0;font-weight:900}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(5){background-color:#fff;font-family:Arial;border-bottom:none;color:#384044;font-size:inherit;font-weight:900}.o-product-performance--product-performance .u-bg--brand,.o-product-performance--product-performance .u-bg--brand-dst{font-size:1.125em;text-align:center}.fa{display:block}.u-bg--brand-dst{background-color:#5a2f50}.u-bg--brand-d{background-color:#8c527e;text-align:center}[class*=u-bg--brand]{color:#fff}.o-product-performance--product-performance tbody td{height:2em;padding:.5em} #stocked_table thead { width:98.5% }
                                   .o-circle{border-radius:50%;height:1rem;width:1rem;display:block;margin:0 auto}.o-circle--red{background-color:#de4b55}.o-circle--green{background-color:#6dbe45}.hide{display:none}
                                </style>
                              </head>
                              <body style="margin:0px;padding:0px;">
                                  <h1 style="float:left;font-weight: 100;margin-left:10px">GAP ANALYSIS</h1>
                                  <div style="float:right;-webkit-margin-before: 5px;-webkit-margin-after: 0.67em;-webkit-margin-start: 0px;-webkit-margin-end: 10px;">
                                  <p>${fields}</p></div>
                                  <table class="o-table o-table-stock">
                                  ${html}
                                  </table>
                              </body>
                          </html>`;
        props.exportToPDF(htmlString);
      }
    },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onTimeFrameChange:
      ({ data, handleData, setTimeframe }) =>
      opt => {
        if (opt) {
          setTimeframe(opt);
        } else {
          setTimeframe('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDepartment(0);
        } else {
          setDepartment(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0);
        } else {
          setCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0);
        } else {
          setSubCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0);
        } else {
          setLevel4(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0);
        } else {
          setLevel5(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand(0);
        } else {
          setBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
  }),
)(StockedReport);
