export const isAuthenticated = () => {
  return !!getUserData();
}

export const getUserData = () => {
  if (localStorage.getItem('user_data') && localStorage.getItem('user_data').hasOwnProperty("message")) {
    return;
  }
  return localStorage.getItem('user_data');
}
