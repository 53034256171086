import React from 'react';
import styled from 'styled-components';
import GreenPositiveIcon from '../../../assets/images/icons/green-up.svg';
import RedNegativeIcon from '../../../assets/images/icons/red-down.svg';
import { htmlDecodePound } from '../../../helpers/helpers';

const StyledCard = styled.div`
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const MainValue = styled.h3`
  font-size: 45px;
`;

const MainValueColoured = styled(MainValue)`
  &&& {
    color: ${props => props.theme.secondary} !important;
  }
`;

const Text = styled.h4`
  font-size: 16px;
  font-weight: 700;
`;

const DifferenceContainer = styled.div`
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
`;

const DifferenceText = styled.p`
  margin-bottom: 0;
`;

const Glossary = styled.p``;

const glossary_MAP = {
  Customers:
    'The total number of transactions/baskets containing your selection',
  Universe:
    'The total number of transactions / baskets containing any products',
  'Sales Value':
    'The total sales value of every item included in those transactions not just your selection',

  'Sales Units':
    'The total number of retail units included in those transactions not just your selection',

  'Av. Spend Per Basket':
    'The avg spend on your selection in those transactions',

  'Av. Basket Spend Per Visit':
    'The avg value of the total basket or transaction.',

  'Av. Spend on Additional Products':
    'The average basket spend on additional products when purchasing the primary product(s)',
};

const DashboardCard = ({ data: { card, value, difference, title } }) => {
  return (
    <StyledCard>
      <Text>{title || ''}</Text>
      <MainValueColoured>{htmlDecodePound(value)}</MainValueColoured>

      {difference && (
        <DifferenceContainer>
          <DifferenceText>{difference}</DifferenceText>
          <StyledImage
            height="15"
            width="20"
            src={parseInt(difference) > 0 ? GreenPositiveIcon : RedNegativeIcon}
          />
        </DifferenceContainer>
      )}
      <Glossary>{glossary_MAP[card]}</Glossary>
    </StyledCard>
  );
};

export default DashboardCard;
