import React from 'react';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router';
import Collapsible from 'react-collapsible';
import {
  fetchDistributionLists,
  addNewDistributionList,
  addNewRecipientGroup,
  updateRecipientGroup,
  addNewMember,
  deleteMember,
  saveDistributionList,
  updateDistributionList,
  deleteRecipientGroupByID,
  deleteDistributionListByName,
} from '../../redux/reducers/psa';
import CollapsibleHeader from '../../components/psa/collapsibleHeader';

const tableClass = ['o-table'];

const DistributionLists = ({data,
                   onTextChange,
                   distributionLists,
                   onDeleteRecipientGroup,
                   onAddNewRecipientGroup,
                   onAddNewDistributionList,
                   onSaveDistributionList,
                   onAddNewMember,
                   onEditMember,
                   onDeleteMember,
                   onUpdateRecipientGroup,
                   onUpdateDistributionList,
                   onEditDistributionList,
                   onEditRecipientGroup,
                   onDeleteDistributionList,
                   saveMember,
                   isLoading}) => (
      <div className="o-canvas-wrap">
        <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }}/>
              <div className="o-canvas">
                      <div className="o-canvas__header u-layout u-layout--between">
                              <div className="o-canvas__header-item u-layout">
                                      <h2 className="o-canvas__title u-100">
                                        Manage Distribution Lists
                                      </h2>
                              </div>
                      </div>
                      <div className="o-canvas__header-search">
                        <div className="c-objects-wrap--table" style={{position:'relative'}}>
                          <div className="o-canvas__header-search u-layout">
                            <div className="u-1_3">
                              <div className="form-group" style={{height: '80px'}}>
                                <h3 className="o-canvas__search-title">Add new distribution list</h3>
                                 <input type="text" id="listName" className="ReactModal--input-green" name="listName" value={data.listName || ''} onChange={onTextChange}/>
                                 { data.isAddDistributionList && 
                                    <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => onAddNewDistributionList()}>
                                      ADD
                                   </button> }
                                  { !data.isAddDistributionList && 
                                     <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => onUpdateDistributionList()}>
                                       SAVE
                                    </button> }
                                </div>
                              </div>
                              <div className="u-1_3">
                                <div className="form-group" style={{height: '80px'}}>
                                  <h3 className="o-canvas__search-title">Add a new recipient groups</h3>
                                   <input type="text" id="groupName" className="ReactModal--input-green" name="groupName" value={data.groupName || ''} onChange={onTextChange}/>
                                   { data.isAddRecipientGroup && 
                                    <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => onAddNewRecipientGroup()}>
                                     ADD
                                    </button> }
                                  { !data.isAddRecipientGroup && 
                                   <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => onUpdateRecipientGroup()}>
                                    SAVE
                                   </button> }
                                  </div>
                                </div>
                              </div>
                              { distributionLists && distributionLists.length > 0 && distributionLists.map((list, idx) => {
                                return <Collapsible trigger={<CollapsibleHeader name={ list.name } />} triggerSibling={() => {
                                 return <div>
                                  <a href="javascript:void(0)" onClick={() => onEditDistributionList(list.id)}><span className="fa fa-edit collapsible-edit"></span></a>
                                  <a href="javascript:void(0)" onClick={() => onDeleteDistributionList(list.id)}><span className="fa fa-remove collapsible-remove"></span></a>
                                 </div>
                                }
                                } key={idx}>
                                   <div className="o-canvas__header-search u-layout">
                                     { list.recipientGroup && list.recipientGroup.length > 0 && list.recipientGroup.map((group, idx) => {
                                       return <table className={tableClass.join(' ')} key={idx}>
                                              <thead className="header-row">
                                                     <tr>
                                                        <th>&nbsp;</th>
                                                        <th>
                                                          <b>{group.name}</b>
                                                        </th>
                                                        <th>
                                                          <a href="javascript:void(0)" className="recipient-remove-btn" onClick={() => onDeleteRecipientGroup(group.id)}>
                                                            <span className="fa fa-remove"></span>
                                                          </a>
                                                          <a href="javascript:void(0)" className="recipient-edit-btn" onClick={() => onEditRecipientGroup(group.id)}><span className="fa fa-edit"></span></a>
                                                          <a href="javascript:void(0)" className="recipient-add-btn" onClick={() => onAddNewMember(group.id)}><span className="fa fa-plus"></span></a>
                                                        </th>
                                                      </tr>
                                              </thead>
                                              <tbody>
                                                { group.members && group.members.map((member, idx) => {
                                                  return <tr key={idx}>
                                                    <td className="align-left" colSpan="2">{member.firstName} {member.lastName}</td>
                                                    <td className="align-left">{member.email}
                                                      <a href="javascript:void(0)" className="recipient-edit-btn" onClick={() => onEditMember(member.id)}><span className="fa fa-edit"></span></a>
                                                      <a href="javascript:void(0)" className="recipient-add-btn" onClick={() => onDeleteMember(member.id)}><span className="fa fa-remove"></span></a>
                                                    </td>
                                                  </tr>;
                                                }) }
                                                { data.isAddNewMemberEnabled && data.listName === list.name && data.groupName === group.name && <tr>
                                                  <td className="align-left"><input type="text" name="firstName" onChange={onTextChange} id="firstName" value={data.firstName} placeholder="First name" /></td>
                                                  <td className="align-left"><input type="text" id="lastName" name="lastName" onChange={onTextChange} value={data.lastName} placeholder="Last name" /></td>
                                                  <td className="align-left"><input type="text" id="email" name="email" onChange={onTextChange}  value={data.email} placeholder="Email"/>
                                                    <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => saveMember()}>
                                                     SAVE
                                                    </button>
                                                  </td>
                                                </tr> }
                                              </tbody>
                                      </table>
                                     }) }
                                     </div>
                                 </Collapsible>
                              }) }
                              { distributionLists && distributionLists.length > 0 && <button className="u-right mt-10 mr-10 btn-psa" onClick={() => onSaveDistributionList()}>Save</button> }
                            </div>
                      </div>
              </div>
      </div>
);
function mapStateToProps(state){
  let data = {
         isLoading: state.psa.isLoading,
         distributionLists: state.psa.distributionLists,
         isDistributionListUpdated: state.psa.isDistributionListUpdated,
         isDistributionListAdded: state.psa.isDistributionListAdded,
         isDistributionListDeleted: state.psa.isDistributionListDeleted,
         isRecipientGroupDeleted: state.psa.isRecipientGroupDeleted,
         error: state.psa.error
             };
  return data;
}
export default compose(
withRouter,
  connect( mapStateToProps , {
    fetchDistributionLists,
    addNewDistributionList,
    addNewRecipientGroup,
    updateRecipientGroup,
    addNewMember,
    deleteMember,
    updateDistributionList,
    saveDistributionList,
    deleteRecipientGroupByID,
    deleteDistributionListByName
  }),
  lifecycle({
    componentDidMount() {
     this.props.fetchDistributionLists();
    },
    componentDidUpdate(prevProps) {
     if(prevProps.isDistributionListDeleted !== this.props.isDistributionListDeleted){
      toast.info('List deleted successfully.', {
              position: toast.POSITION.TOP_CENTER
      });
      this.props.fetchDistributionLists();
     }
      if(prevProps.isDistributionListAdded !== this.props.isDistributionListAdded){
       toast.info('List added successfully.', {
               position: toast.POSITION.TOP_CENTER
       });
       this.props.fetchDistributionLists();
      }
      if(prevProps.isDistributionListUpdated !== this.props.isDistributionListUpdated){
       toast.info('List updated successfully.', {
               position: toast.POSITION.TOP_CENTER
       });
       this.props.fetchDistributionLists();
      }
      if(prevProps.isRecipientGroupDeleted !== this.props.isRecipientGroupDeleted){
       toast.info('Group deleted successfully.', {
               position: toast.POSITION.TOP_CENTER
       });
       this.props.fetchDistributionLists();
      }
      if(prevProps.error !== this.props.error && this.props.error.length > 0){
       toast.info(this.props.error, { position: toast.POSITION.TOP_CENTER });
      }
    }
  }),
  withState('data', 'handleData', {
   isAddDistributionList: true,
   isAddRecipientGroup: true,
   isAddNewMemberEnabled: false,
   isEditMemberEnabled: false,
   listName: '',
   groupName: '',
   firstName: '',
   lastName: '',
   email: '',
   selectedList: {},
   selectedGroup: {}
  }),
  withHandlers({
    onTextChange: ({ data, handleData }) => (ev) => {
      const { name, value } = ev.target;
      handleData({ ...data, [name]: value  });
    },
    onDeleteRecipientGroup: ({ data, handleData, distributionLists, deleteRecipientGroupByID }) => (id) => {
      let list = {};
      distributionLists.map(lst => {
       lst.recipientGroup.map(grp => {
         if(grp.id === id){
          list = lst;
         }
       });
      });
      handleData({ ...data, selectedList: list });
      deleteRecipientGroupByID(id);
    },
    onEditDistributionList: ({ data, handleData, distributionLists }) => (id) => {
      const list = distributionLists.filter(list => list.id === id)[0];
      handleData({ ...data,
        listName: list.name,
        isAddDistributionList: false,
        isAddRecipientGroup: true,
        selectedList: list });
    },
    onEditRecipientGroup: ({ data, handleData, updateDistributionList, distributionLists }) => (id) => {
      let list = {};
      let group = {};
      distributionLists.map(lst => {
        lst.recipientGroup.map(grp => {
         if(grp.id === id){
           list = lst;
           group = grp;
         }
        });
      });
      handleData({ ...data,
        listName: list.name,
        groupName: group.name,
        isAddDistributionList: false,
        isAddRecipientGroup: false,
        selectedList: list,
        selectedGroup: group
      });
    },
    onUpdateDistributionList: ({ data, handleData, updateDistributionList }) => () => {
      handleData({ ...data,
        isAddDistributionList: true,
        isAddRecipientGroup: true,
        isAddNewMemberEnabled: false,
        isEditMemberEnabled: false,
        listName: '',
        groupName: '',
        firstName: '',
        lastName: '',
        email: '',
        selectedList: {},
        selectedGroup: {}
      });
      updateDistributionList({ list: data.selectedList, name: data.listName });
    },
    onSaveDistributionList: ({ data, handleData, saveDistributionList }) => () => {
       saveDistributionList({ list: data.selectedList });
      handleData({ ...data,
        isAddDistributionList: true,
        isAddRecipientGroup: true,
        isAddNewMemberEnabled: false,
        isEditMemberEnabled: false,
        listName: '',
        groupName: '',
        firstName: '',
        lastName: '',
        email: '',
        selectedList: {},
        selectedGroup: {}
      });
    },
    onAddNewDistributionList: ({ data, handleData, distributionLists, addNewDistributionList }) => () => {
      const newList = distributionLists.filter(lst => lst.name === data.listName);
      if(newList.length > 0){
        toast.info('List with same name already existis.', {
                position: toast.POSITION.TOP_CENTER
        });
        return;
      }
      const list = {
        id: uuid(),
        name: data.listName,
        recipientGroup: []
      };
      handleData({ ...data, selectedList: list });
      addNewDistributionList(list);
    },
    onAddNewRecipientGroup: ({ data, handleData, addNewRecipientGroup }) => () => {
      addNewRecipientGroup(data.selectedList.id, data.groupName);
      handleData({ ...data, groupName: '' });
    },
    onUpdateRecipientGroup: ({ data, handleData, updateRecipientGroup }) => () => {
      updateRecipientGroup(data.selectedList.id, data.selectedGroup.id, data.groupName);
    },
    onDeleteDistributionList: ({ data, handleData, deleteDistributionListByName }) => (name) => {
      deleteDistributionListByName(name);
    },
    onDeleteMember: ({ data, handleData, distributionLists, deleteMember }) => (id) => {
      let list = {};
      distributionLists.map(lst => {
       lst.recipientGroup.map(grp => {
        grp.members.map(mem => {
         if(mem.id === id){
          list = lst;
         }
        });
       });
      });
      handleData({ ...data, selectedList: list });
      deleteMember(id);
    },
    onEditMember: ({ data, handleData, distributionLists }) => (id) => {
      let list = {};
      let group = {};
      let member = {};
      distributionLists.map(lst => {
        lst.recipientGroup.map(grp => {
         grp.members.map(mem => {
          if(mem.id === id){
            list = lst;
            group = grp;
            member = mem;
          }
         });
        });
      });
      handleData({ ...data,
        selectedList: list,
        isAddNewMemberEnabled : true,
        listName: list.name,
        groupName: group.name,
        email: member.email,
        firstName: member.firstName,
        lastName: member.lastName
      });
    },
    saveMember: ({ data, handleData, addNewMember }) => () => {
      handleData({ ...data, isAddNewMemberEnabled : !data.isAddNewMemberEnabled });
      addNewMember({ listName: data.listName,
                     groupName: data.groupName,
                     firstName: data.firstName,
                     lastName: data.lastName,
                     email: data.email });
    },
    onAddNewMember: ({ data, handleData, distributionLists }) => (groupId) => {
      let list = {};
      let group = {};
      distributionLists.map(lst => {
       lst.recipientGroup.map(grp => {
        if(grp.id === groupId){
          list = lst;
          group = grp;
        }
       });
      });
      handleData({ ...data,
        isAddNewMemberEnabled: true,
        isAddDistributionList: false,
        isAddRecipientGroup: false,
        selectedList: list,
        listName: list.name,
        groupName: group.name,
        firstName: '',
        lastName: '',
        email: ''
      });
    },
  })
)(DistributionLists);


