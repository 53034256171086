import React from 'react';
import PropTypes from 'prop-types';

class AlchContextMenu extends React.Component {
  state = {
    isOpen: false,
  };

  itemSelect = id => {
    this.setState({
      isOpen: false,
    });
    this.props.onSelect(id);
  };

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  renderItem(item) {
    return (
      <li className="c-context-menu__item" key={item.id}>
        <button
          onClick={e => {
            e.preventDefault();
            this.itemSelect(item.id);
          }}
        >
          {item.label}
        </button>
      </li>
    );
  }

  render() {
    const listClasses = ['c-context-menu__list'];
    if (this.state.isOpen) {
      listClasses.push('is-open');
    }

    return (
      <div className="c-context-menu">
        <button className={this.props.btnClass ? this.props.btnClass : 'c-context-menu__title'} onClick={this.toggleMenu}>
          {this.props.label}
          {this.props.btnImage && <img src={this.props.btnImage} />}
        </button>
        <ul className={listClasses.join(' ')}>{this.props.children.map(item => this.renderItem(item))}</ul>
      </div>
    );
  }
}

AlchContextMenu.defaultProps = {
  children: [],
  label: '',
  onSelect: () => {},
};

AlchContextMenu.propTypes = {
  onSelect: PropTypes.func,
  children: PropTypes.array,
  label: PropTypes.string,
};

export default AlchContextMenu;
