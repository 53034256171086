import theme from '../config/Themes';

export const getCategoryPenetrationGraphData = data => {
  const summaryDataValues =
    data &&
    data.map(item => ({
      data: [item.SummaryValue_Percentage * 100],
      backgroundColor: 'red',
      label: item.Name,
      backgroundColor: [theme.primary],
      borderColor: ['White'],
      borderWidth: 2,
      hoverBorderColor: ['White'],
      itemName: item.Name,
      dataValue: item.SummaryValue,
    }));
  const summaryChartData = {
    datasets: summaryDataValues,
    labels: ['Turnover'],
  };

  const skuDataValues =
    data &&
    data.map(item => ({
      data: [item.SkuCount_Percentage * 100],
      backgroundColor: 'red',
      label: item.Name,
      backgroundColor: [theme.primary],
      borderColor: ['White'],
      borderWidth: 2,
      hoverBorderColor: ['White'],
      itemName: item.Name,
      dataValue: item.SkuCount,
    }));
  const skuChartData = { datasets: skuDataValues, labels: ['Sku'] };

  return { summaryChartData, skuChartData };
};

export const formatYoYData = yoydata => {
  let colors = {
    line1: theme.lineGraph1A,
    line2: theme.lineGraph2A,
    line3: theme.lineGraph3A,
    line4: theme.lineGraph1B,
    line5: theme.lineGraph2B,
    line6: theme.lineGraph3B,
    line7: theme.lineGraph1C,
    line8: theme.lineGraph2C,
    line0: theme.lineGraph3C,
  };

  const weeksInYear = Array.from([...Array(54).keys()], week =>
    week.toString(),
  );
  weeksInYear.shift();
  let dataSets = [];
  let LY = [];
  let TY = [];
  let labels = [];
  yoydata.forEach(item => {
    labels.push(item.WeekNumber || item['']);
    LY.push(item.ValueLY);
    TY.push(item.ValueTY);
  });

  const datasets = [
    {
      label: 'TY',
      borderColor: theme.primary || colors[`line${1}`],
      backgroundColor: theme.primary || colors[`line${1}`],
      fill: false,
      data: TY,
      yAxisID: `y-axis-1`,
    },
    {
      label: 'LY',
      borderColor: theme.secondary || colors[`line${2}`],
      backgroundColor: theme.secondary || colors[`line${2}`],
      fill: false,
      data: LY,
      yAxisID: `y-axis-1`,
    },
  ];
  return { labels, datasets };
};

export const format4WeekData = weekData => {
  let colors = {
    line1: theme.lineGraph1A,
    line2: theme.lineGraph2A,
    line3: theme.lineGraph3A,
    line4: theme.lineGraph1B,
    line5: theme.lineGraph2B,
    line6: theme.lineGraph3B,
    line7: theme.lineGraph1C,
    line8: theme.lineGraph2C,
    line0: theme.lineGraph3C,
  };

  const weeksInYear = Array.from([...Array(54).keys()], week =>
    week.toString(),
  );
  weeksInYear.shift();
  let dataSets = [];
  let LY = [];
  let TY = [];
  let labels = [];
  weekData.forEach(item => {
    labels.push(item.Period);
    LY.push(item.ValueLY);
    TY.push(item.ValueTY);
  });

  const datasets = [
    {
      label: 'TY',
      borderColor: theme.primary || colors[`line${1}`],
      backgroundColor: theme.primary || colors[`line${1}`],
      data: TY,
    },
    {
      label: 'LY',
      borderColor: theme.secondary || colors[`line${2}`],
      backgroundColor: theme.secondary || colors[`line${2}`],
      data: LY,
    },
  ];
  return { labels, datasets };
};
