import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { Container, Row, Col, Button } from 'reactstrap';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

import { pageVariants } from '../../../config/pageVariants';

import ErrorMessage from '../../components/util/ErrorMessage';
import Loading from '../../components/Loading';
import ReportContainer from '../../components/ReportContainer';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import {
  KPI,
  YoYCharts,
  SubCategory,
  Brand,
  Member,
  Sku,
  Supplier,
} from '../SupplierEngagement';

import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRefreshDisabledStatus,
  setAccountName,
  resetReport,
  loadSupplierEngagementData,
  setMember,
} from '../../redux/reducers/supplierEngagement';

const ButtonCol = styled(Col)`
  padding: 4rem;

  @media (max-width: 1600px) {
    padding: 4rem 1rem;
  }
`;

const SupplierEngagementReport = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  setLoading,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  selectedLevel4s,
  level4s,
  onLevel5Change,
  selectedLevel5s,
  level5s,
  loadCategoryPenetrationData,
  categoryPenetrationData,
  barChartDataSummary,
  barChartDataSku,
  isLoading,
  isRefreshDisabled,
  setReportType,
  reportType,
  reportTypes,
  drillGraphResults,
  onAccountNameChange,
  location,
  onResetReport,
  supplierEngagementData,
  onRefreshReport,
  errorMessage,
  isLoaded,
  isSupplierLoading,
  onMemberChange,
  members,
  selectedMembers,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('category_penetration', false, {
      timeframe: 'MAT',
    });
    return () => onUnmount();
  }, []);

  return (
    <motion.div
      key={'basket_analysis'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        refresh={{
          refreshHandler: selectedSuppliers =>
            onRefreshReport(selectedSuppliers),
          isRefreshDisabled: isRefreshDisabled,
        }}
        pathname={location.pathname}
        export={{}}
        resetReport={() => onResetReport()}
        dropdowns={[
          { text: 'Supplier Engagement for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
        ]}>
        {isLoaded && (
          <DataDropdownsContainer
            retail={false}
            dropdowns={[
              {
                name: 'departments',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedDepartments,
                options: departments,
                handler: option => onDepartmentChange(option),
                isMulti: true,
              },
              {
                name: 'categories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedCategories,
                options: categories,
                handler: option => onCategoryChange(option),
                isMulti: true,
              },
              {
                name: 'subCategories',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSubCategories,
                options: subCategories,
                handler: option => onSubCategoryChange(option),
                isMulti: true,
              },
              {
                name: 'level4',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel4s,
                options: level4s,
                handler: option => onLevel4Change(option),
                isMulti: true,
              },
              {
                name: 'level5',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedLevel5s,
                options: level5s,
                handler: option => onLevel5Change(option),
                isMulti: true,
              },
              {
                name: 'members',
                valueKey: 'WholesalerNo',
                labelKey: 'ActualName',
                value: selectedMembers,
                options: members,
                handler: option => onMemberChange(option),
                isMulti: true,
              },
              {
                name: 'Suppliers',
                valueKey: 'ID',
                labelKey: 'Text',
                value: selectedSuppliers,
                options: suppliers,
                handler: option => onSupplierChange(option),
                isMulti: true,
                required: true,
                isLoading: isSupplierLoading,
              },
            ]}
          />
        )}
      </ReportSettingsContainer>

      <ReportContainer padding={3} display="block">
        {errorMessage && <ErrorMessage error={errorMessage} />}
        {isLoading && <Loading withMessages />}
        {!isLoading && supplierEngagementData && supplierEngagementData.kpi && (
          <Row>
            <ButtonCol md={2}>
              <Button
                block
                color="primary"
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/kpi`,
                  )
                }>
                KPIs
              </Button>
              <Button
                block
                color="primary"
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/yoy_charts/weekly_value`,
                  )
                }>
                YOY Charts
              </Button>
              <Button
                color="primary"
                block
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category`,
                  )
                }>
                SUBCATEGORY
              </Button>
              <Button
                color="primary"
                block
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier`,
                  )
                }>
                SUPPLIER
              </Button>
              <Button
                block
                color="primary"
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand`,
                  )
                }>
                BRAND
              </Button>
              <Button
                block
                color="primary"
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member`,
                  )
                }>
                MEMBER
              </Button>
              <Button
                block
                color="primary"
                onClick={() =>
                  props.history.push(
                    `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sku`,
                  )
                }>
                SKU
              </Button>
            </ButtonCol>
            <Col md={10}>
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch>
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/kpi`}
                    render={pageProps => (
                      <KPI
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.kpi}
                        barData={supplierEngagementData.barchart}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/yoy_charts`}
                    render={pageProps => (
                      <YoYCharts
                        {...props}
                        {...pageProps}
                        dataTrend={supplierEngagementData.trend}
                        data4Week={supplierEngagementData['4wk_trend']}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sub_category`}
                    render={pageProps => (
                      <SubCategory
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.category}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand`}
                    render={pageProps => (
                      <Brand
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.brand}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/member`}
                    render={pageProps => (
                      <Member
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.member}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/sku`}
                    render={pageProps => (
                      <Sku
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.product}
                      />
                    )}
                  />
                  <Route
                    path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier`}
                    render={pageProps => (
                      <Supplier
                        {...props}
                        {...pageProps}
                        data={supplierEngagementData.supplier}
                        calculatorData={supplierEngagementData.caculator}
                      />
                    )}
                  />

                  <Redirect
                    from={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report`}
                    to={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/kpi`}
                  />
                </Switch>
              </AnimatePresence>
            </Col>
          </Row>
        )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.supplierEngagement.timeframe,
  departments: state.supplierEngagement.departments,
  selectedDepartments: state.supplierEngagement.selectedDepartments,
  categories: state.supplierEngagement.categories,
  selectedCategories: state.supplierEngagement.selectedCategories,
  subCategories: state.supplierEngagement.subCategories,
  selectedSubCategories: state.supplierEngagement.selectedSubCategories,
  members: state.supplierEngagement.members,
  accountNames: state.supplierEngagement.accountNames,
  accountName: state.supplierEngagement.accountName,
  timeframes: state.supplierEngagement.timeframes,
  suppliers: state.supplierEngagement.suppliers,
  selectedSupplier: state.supplierEngagement.selectedSuppliers,
  brands: state.supplierEngagement.brands,
  selectedBrand: state.supplierEngagement.selectedBrands,
  selectedLevel4s: state.supplierEngagement.selectedLevel4s,
  level4s: state.supplierEngagement.level4s,
  selectedLevel5s: state.supplierEngagement.selectedLevel5s,
  level5s: state.supplierEngagement.level5s,
  selectedMembers: state.supplierEngagement.selectedMembers,
  members: state.supplierEngagement.members,
  isLoading: state.supplierEngagement.isLoading,
  isRefreshDisabled: state.supplierEngagement.isRefreshDisabled,
  supplierEngagementData: state.supplierEngagement.supplierEngagementData,
  errorMessage: state.supplierEngagement.reportError,
  isLoaded: state.supplierEngagement.isLoaded,
  isSupplierLoading: state.supplierEngagement.areSuppliersLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onMemberChange: selectedOptions => dispatch(setMember(selectedOptions)),
    loadCategoryPenetrationData: () => dispatch(loadCategoryPenetrationData()),
    setReportType: option => dispatch(setPenetrationReportType(option)),
    drillGraphResults: val => dispatch(drillGraphResults(val)),
    onAccountNameChange: option => {
      dispatch(setAccountName(option))
      dispatch(setRefreshDisabledStatus(false));
    },
    onResetReport: () => {
      dispatch(resetReport());
      dispatch(
        fetchCategories('category_penetration', false, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') }),
      );
    },
    onRefreshReport: () => dispatch(loadSupplierEngagementData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierEngagementReport);
