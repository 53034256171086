import { handleActions, createAction } from 'redux-actions';
import { each, map, orderBy } from 'lodash';
import { request, customRequest, requestGet } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';
import { setDisableCategoryStructure } from './app';


import store from '../store';

const noResultsErrorMessage =
  'There were no sales for the product you selected during the period.';
const failedRequestErrorMessage =
  'There was an error with your query, please try again.';

const SET_SELECTED_MEMBERS = 'heatmap/SET_SELECTED_MEMBERS';
const SET_REPORT_DEPARTMENT = 'heatmap/SET_DEPARTMENT';
const SET_REPORT_CATEGORY = 'heatmap/SET_CATEGORY';
const SET_SUBCATEGORY = 'heatmap/SET_SUBCATEGORY';
const SET_LEVEL4 = 'heatmap/SET_LEVEL4';
const SET_LEVEL5 = 'heatmap/SET_LEVEL5';
const SET_PRODUCT = 'heatmap/SET_PRODUCT';
const FETCH_PRODUCTS = 'heatmap/FETCH_PRODUCTS';
const SET_REGION = 'heatmap/SET_REGION';
const FETCH_DATA = 'heatmap/FETCH_DATA';
const SET_PRODUCT_DATA = 'heatmap/SET_PRODUCT_DATA';
const SET_TABLE_DATA = 'heatmap/SET_TABLE_DATA';
const SET_REGION_DATA = 'heatmap/SET_REGION_DATA';
const SET_LOADING = 'heatmap/SET_LOADING';
const SET_EXPORTING = 'heatmap/SET_EXPORTING';
const EXPORT_TO_PDF_SUCCESS = 'heatmap/EXPORT_TO_PDF_SUCCESS';
const RESET = 'heatmap/RESET';
const SET_SELECTED_GROUP = 'heatmap/SET_SELECTED_GROUP';
const SET_MEMBERS = 'heatmap/SET_MEMBERS';
const SET_BUSINESS_TYPE = 'heatmap/SET_BUSINESS_TYPE';
const SET_BUSINESS_TYPES = 'heatmap/SET_BUSINESS_TYPES';
const SET_IS_REGION_TABLE_DATA = 'heatmap/SET_IS_REGION_TABLE_DATA';
const SET_REGIONAL_TABLE_DATA = 'heatmap/SET_REGIONAL_TABLE_DATA';
const SET_ERROR_MSG = 'heatmap/SET_ERROR_MSG';

const initialState = {
  filter: '',
  filterValue: '',
  filterId: '',
  region: '',
  isLoading: false,
  isExporting: false,
  isExported: false,
  departments: [],
  department: {},
  categories: [],
  category: {},
  members: [],
  selectedMembers: [],
  subCategories: [],
  subCategory: {},
  level4s: [],
  level4: {},
  level5s: [],
  level5: {},
  reportType: '',
  categoryHierarchy: [],
  productData: [],
  regionData: [],
  products: [],
  pdfURL: '',
  product: {},
  selectedGroup: [],
  groups: [],
  businessTypes: [],
  selectedBusinessType: '',
  isRegionTable: false,
  tableData: [],
  regionalTableData: [],
  errorMessage: '',
};

const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setProductAction = createAction(SET_PRODUCT);
const setRegionAction = createAction(SET_REGION);
const fetchDataAction = createAction(FETCH_DATA);
const setProductDataAction = createAction(SET_PRODUCT_DATA);
const setTableDataAction = createAction(SET_TABLE_DATA);
const setRegionalTableDataAction = createAction(SET_REGIONAL_TABLE_DATA);
const setRegionDataAction = createAction(SET_REGION_DATA);
const setLoadingAction = createAction(SET_LOADING);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);
const resetAction = createAction(RESET);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setBusinessTypesAction = createAction(SET_BUSINESS_TYPES);
const setIsRegionTableAction = createAction(SET_IS_REGION_TABLE_DATA);
const setErrorMessageAction = createAction(SET_ERROR_MSG);

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'area-heatmap-report-' + new Date().toISOString(),
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const resetReport = () => dispatch => {
  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  loadChart(store, dispatch);
  loadTable(store, dispatch, false);
};

export const fetchData = () => dispatch => {
  let url = '/route.php?c=category/getUserHierarchy';  

  const state = store.getState().areaHeatmapReport;
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text);
  }
  
  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    let url1 = '/route.php?c=wholesaler/getAll';
    request({
      url: url1,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(members => {
      each(members, mem => {
        mem.ActualName = encodeText(mem.ActualName);
      });

      if (members.length === 1) {
        dispatch(setDisableCategoryStructure(false));
      }

      if (
        members?.length === 1
      ) {
        dispatch(
          setSelectedMembersAction([
            { Text: members[0].ActualName, ID: members[0].WholesalerNo },
          ]),
        );
        members = [members[0]];
      }

      requestGet({ url: '/route.php?c=groupSupplier/getAll' }).then(groups => {
        let groupsArr =
          (groups && groups.map((group, i) => ({ Text: group, ID: i }))) || [];
        let orderedGroups = orderBy(groupsArr, ['Text']);
        dispatch(
          fetchDataAction({
            members: members,
            categories: result.Categories ? result.Categories : [],
            departments: result.Departments ? result.Departments : [],
            groups: orderedGroups,
          }),
        );

        const url = '/route.php?c=customer/getAllBusinessTypes';
        requestGet({ url: url })
          .then(busTypes => {
            let businessTypes = busTypes.map(bus => ({
              BusinessName: bus,
            }));
            dispatch(setBusinessTypesAction(businessTypes));
            dispatch(setLoadingAction(false));
          })
          .catch(err => {
            dispatch(setLoadingAction(false));
          });
      });
    });
  });
};

export const setDepartment = val => dispatch => {
  const state = store.getState().areaHeatmapReport;
  if (parseInt(val) > 0) {
    let department = _.filter(state.departments, dpt => {
      if (dpt.ID === parseInt(val)) {
        return dpt;
      }
    });
    let dpt = department[0];
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand' &&
      state.reportType !== 'level4' &&
      state.reportType !== 'level5'
    ) {
      dpt.reportType = 'category';
    } else {
      dpt.reportType = state.reportType;
    }
    dispatch(setReportDepartmentAction(dpt));
    if (dpt?.Categories?.length === 1) {
      let cat = dpt.Categories[0];
      if (
        state.reportType !== 'depot' &&
        state.reportType !== 'supplier' &&
        state.reportType !== 'member' &&
        state.reportType !== 'brand'
      ) {
        cat.reportType = 'subcategory';
      } else {
        cat.reportType = state.reportType;
      }
      dispatch(setReportCategoryAction(cat));
      if (cat?.SubCategories?.length === 1) {
        let subcat = cat.SubCategories[0];
        if (
          state.reportType !== 'depot' &&
          state.reportType !== 'supplier' &&
          state.reportType !== 'member' &&
          state.reportType !== 'brand'
        ) {
          subcat.reportType = 'level4';
        } else {
          subcat.reportType = state.reportType;
        }
        dispatch(setSubCategoryAction(subcat));
      }
    }

    let url = `/route.php?c=product/getAll&department=${encodeURIComponent(
      department[0].Text,
    )}`;
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text);
    }
    const user = store.getState().user.authUser;
    if (user.showCompetitor) {
      url += `&show_competitor=0&supplier=${encodeURIComponent(
        user.supplier.Text,
      )}`;
    }
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(result => {
      each(result, prod => {
        prod.WVProdDesc = encodeText(prod.WVProdDesc);
      });
      dispatch(fetchProductsAction(result));
    });
  } else if (parseInt(val) === 0) {
    let reportType = state.reportType;
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setSubCategoryAction({ Level4: [], reportType: reportType }));
    dispatch(
      setReportCategoryAction({ SubCategories: [], reportType: reportType }),
    );
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand'
    ) {
      reportType = 'department';
    }
    dispatch(
      setReportDepartmentAction({ reportType: reportType, Categories: [] }),
    );
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
  } else {
    let department = _.filter(state.departments, dpt => {
      if (dpt.Text === val) {
        return dpt;
      }
    });
    let dpt = department[0];
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand' &&
      state.reportType !== 'level4' &&
      state.reportType !== 'level5'
    ) {
      dpt.reportType = 'category';
    } else {
      dpt.reportType = state.reportType;
    }
    dispatch(setReportDepartmentAction(dpt));
  }
};

export const setCategory = val => dispatch => {
  if (parseInt(val) > 0) {
    let category = _.filter(
      store.getState().areaHeatmapReport.categories,
      cat => {
        if (cat.ID === parseInt(val)) {
          return cat;
        }
      },
    );
    let catt = category[0];
    if (
      store.getState().areaHeatmapReport.reportType !== 'depot' &&
      store.getState().areaHeatmapReport.reportType !== 'supplier' &&
      store.getState().areaHeatmapReport.reportType !== 'member' &&
      store.getState().areaHeatmapReport.reportType !== 'brand'
    ) {
      catt.reportType = 'subcategory';
    } else {
      catt.reportType = store.getState().areaHeatmapReport.reportType;
    }

    dispatch(setReportCategoryAction(catt));
    if (catt?.SubCategories?.length === 1) {
      let subcat = catt.SubCategories[0];
      if (
        store.getState().areaHeatmapReport.reportType !== 'depot' &&
        store.getState().areaHeatmapReport.reportType !== 'supplier' &&
        store.getState().areaHeatmapReport.reportType !== 'member' &&
        store.getState().areaHeatmapReport.reportType !== 'brand'
      ) {
        subcat.reportType = 'level4';
      } else {
        subcat.reportType = store.getState().areaHeatmapReport.reportType;
      }
      dispatch(setSubCategoryAction(subcat));
    }
    let state = store.getState().areaHeatmapReport;
    let url = `/route.php?c=product/getAll&category=${encodeURIComponent(
      category[0].Text,
    )}`;
    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    const user = store.getState().user.authUser;
    if (user.showCompetitor) {
      url += `&show_competitor=0&supplier=${encodeURIComponent(
        user.supplier.Text,
      )}`;
    }
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(result => {
      each(result, prod => {
        prod.WVProdDesc = encodeText(prod.WVProdDesc);
      });
      dispatch(fetchProductsAction(result));
    });
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().areaHeatmapReport.reportType;
    dispatch(setLevel5Action({}));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setSubCategoryAction({ Level4: [], reportType: reportType }));
    if (
      store.getState().areaHeatmapReport.reportType !== 'depot' &&
      store.getState().areaHeatmapReport.reportType !== 'supplier' &&
      store.getState().areaHeatmapReport.reportType !== 'member' &&
      store.getState().areaHeatmapReport.reportType !== 'brand' &&
      store.getState().areaHeatmapReport.reportType !== 'level4' &&
      store.getState().areaHeatmapReport.reportType !== 'level5'
    ) {
      reportType = 'category';
    }
    dispatch(
      setReportCategoryAction({ reportType: reportType, SubCategories: [] }),
    );
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
  } else {
    let category = _.filter(
      store.getState().areaHeatmapReport.categories,
      cat => {
        if (cat.Text === val) {
          return cat;
        }
      },
    );
    let catt = category[0];
    if (
      store.getState().areaHeatmapReport.reportType !== 'depot' &&
      store.getState().areaHeatmapReport.reportType !== 'supplier' &&
      store.getState().areaHeatmapReport.reportType !== 'member' &&
      store.getState().areaHeatmapReport.reportType !== 'brand'
    ) {
      catt.reportType = 'subcategory';
    } else {
      catt.reportType = store.getState().areaHeatmapReport.reportType;
    }
    dispatch(setReportCategoryAction(catt));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));
};

export const setIsRegional = val => async dispatch => {
  dispatch(setIsRegionTableAction(val));
};

export const setSubCategory = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.ID === val) {
          if (
            store.getState().areaHeatmapReport.reportType !== 'depot' &&
            store.getState().areaHeatmapReport.reportType !== 'supplier' &&
            store.getState().areaHeatmapReport.reportType !== 'member' &&
            store.getState().areaHeatmapReport.reportType !== 'brand'
          ) {
            subcat.reportType = 'level4';
          } else {
            subcat.reportType = store.getState().areaHeatmapReport.reportType;
          }
          dispatch(setSubCategoryAction(subcat));
          let state = store.getState().areaHeatmapReport;
          let url = `/route.php?c=product/getAll&subcategory=${encodeURIComponent(
            subcat.Text,
          )}`;
          if (state.selectedMembers.length === 1) {
            url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
          }
          const user = store.getState().user.authUser;
          if (user.showCompetitor) {
            url += `&show_competitor=0&supplier=${encodeURIComponent(
              user.supplier.Text,
            )}`;
          }
          request({
            url: url,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: getToken(),
              UserId: getUserId(),
              System: getUserSystem(),
            },
          }).then(result => {
            each(result, prod => {
              prod.WVProdDesc = encodeText(prod.WVProdDesc);
            });
            dispatch(fetchProductsAction(result));
          });
        }
      });
    });
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().areaHeatmapReport.reportType;
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    if (
      store.getState().areaHeatmapReport.reportType !== 'depot' &&
      store.getState().areaHeatmapReport.reportType !== 'supplier' &&
      store.getState().areaHeatmapReport.reportType !== 'member' &&
      store.getState().areaHeatmapReport.reportType !== 'brand'
    ) {
      reportType = 'subcategory';
    }
    dispatch(setSubCategoryAction({ reportType: reportType, Level4: [] }));
    dispatch(setLevel4Action({ Level5: [], reportType: reportType }));
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.Text === val) {
          if (
            store.getState().areaHeatmapReport.reportType !== 'depot' &&
            store.getState().areaHeatmapReport.reportType !== 'supplier' &&
            store.getState().areaHeatmapReport.reportType !== 'member' &&
            store.getState().areaHeatmapReport.reportType !== 'brand'
          ) {
            subcat.reportType = 'level4';
          } else {
            subcat.reportType = store.getState().areaHeatmapReport.reportType;
          }
          dispatch(setSubCategoryAction(subcat));
        }
      });
    });
  }
};

export const setLevel4 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      if (cat.ID === store.getState().areaHeatmapReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().areaHeatmapReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === val) {
                if (
                  store.getState().areaHeatmapReport.reportType !== 'depot' &&
                  store.getState().areaHeatmapReport.reportType !==
                    'supplier' &&
                  store.getState().areaHeatmapReport.reportType !== 'member' &&
                  store.getState().areaHeatmapReport.reportType !== 'brand'
                ) {
                  lvl4.reportType = 'level5';
                } else {
                  lvl4.reportType =
                    store.getState().areaHeatmapReport.reportType;
                }
                dispatch(setLevel4Action(lvl4));
                let state = store.getState().areaHeatmapReport;
                let url = `/route.php?c=product/getAll&level4=${encodeURIComponent(
                  lvl4.Text,
                )}`;
                if (state.selectedMembers.length === 1) {
                  url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
                }
                const user = store.getState().user.authUser;
                if (user.showCompetitor) {
                  url += `&show_competitor=0&supplier=${encodeURIComponent(
                    user.supplier.Text,
                  )}`;
                }
                request({
                  url: url,
                  method: 'get',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                    UserId: getUserId(),
                    System: getUserSystem(),
                  },
                }).then(result => {
                  each(result, prod => {
                    prod.WVProdDesc = encodeText(prod.WVProdDesc);
                  });
                  dispatch(fetchProductsAction(result));
                });
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    let reportType = store.getState().areaHeatmapReport.reportType;
    if (
      store.getState().areaHeatmapReport.reportType !== 'depot' &&
      store.getState().areaHeatmapReport.reportType !== 'supplier' &&
      store.getState().areaHeatmapReport.reportType !== 'member' &&
      store.getState().areaHeatmapReport.reportType !== 'brand'
    ) {
      reportType = 'level4';
    }
    dispatch(setLevel4Action({ reportType: reportType, Level5: [] }));
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      if (cat.ID === store.getState().areaHeatmapReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().areaHeatmapReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.Text === val) {
                if (
                  store.getState().areaHeatmapReport.reportType !== 'depot' &&
                  store.getState().areaHeatmapReport.reportType !==
                    'supplier' &&
                  store.getState().areaHeatmapReport.reportType !== 'member' &&
                  store.getState().areaHeatmapReport.reportType !== 'brand'
                ) {
                  lvl4.reportType = 'level5';
                } else {
                  lvl4.reportType =
                    store.getState().areaHeatmapReport.reportType;
                }
                dispatch(setLevel4Action(lvl4));
              }
            });
          }
        });
      }
    });
  }
};

export const setLevel5 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      if (cat.ID === store.getState().areaHeatmapReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().areaHeatmapReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().areaHeatmapReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.ID === val) {
                    dispatch(setLevel5Action(lvl5));
                    let state = store.getState().areaHeatmapReport;
                    let url = `/route.php?c=product/getAll&level5=${encodeURIComponent(
                      lvl5.Text,
                    )}`;
                    if (state.selectedMembers.length === 1) {
                      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
                    }
                    const user = store.getState().user.authUser;
                    if (user.showCompetitor) {
                      url += `&show_competitor=0&supplier=${encodeURIComponent(
                        user.supplier.Text,
                      )}`;
                    }
                    request({
                      url: url,
                      method: 'get',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: getToken(),
                        UserId: getUserId(),
                        System: getUserSystem(),
                      },
                    }).then(result => {
                      each(result, prod => {
                        prod.WVProdDesc = encodeText(prod.WVProdDesc);
                      });
                      dispatch(fetchProductsAction(result));
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().areaHeatmapReport.categories, cat => {
      if (cat.ID === store.getState().areaHeatmapReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().areaHeatmapReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().areaHeatmapReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.Text === val) {
                    dispatch(setLevel5Action(lvl5));
                  }
                });
              }
            });
          }
        });
      }
    });
  }
};

export const setRegion = val => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setRegionAction(val));
  loadDepots(store, dispatch);
  loadTable(store, dispatch);
};

export const setProduct = val => dispatch => {
  dispatch(setProductAction(val));
};

const loadChart = (store, dispatch) => {
  let state = store.getState().areaHeatmapReport;
  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });

  if (
    state?.filterValue?.length > 0 &&
    state.product !== null &&
    state.product.WVProdCode
  ) {
    let url =
      '/route.php?c=heatmap/getHeatmapReport&filter=' +
      encodeURIComponent(state.filter) +
      '&filterValue=' +
      encodeURIComponent(state.filterValue) +
      '&prod=' +
      state.product.WVProdCode;

    url += state?.selectedGroup?.length
      ? '&group=' +
        encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
      : '';
    if (memberIds?.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }

    if (state.ownBrand && state.ownBrand.Text) {
      url += `&ownBrand=${state.ownBrand.Text}`;
    }

    url +=
      state?.selectedBusinessType?.length > 0
        ? '&business_type=' +
          encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
        : '';
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(result => {
      let finalResult = {};
      _.each(result, res => {
        finalResult[res.Region] = res;
      });
      dispatch(setLoadingAction(false));
      dispatch(setProductDataAction(finalResult));
    });
  } else {
    dispatch(setLoadingAction(false));
    dispatch(setProductDataAction([]));
  }
};

const loadTable = (store, dispatch, isRegionTable = true) => {
  let state = store.getState().areaHeatmapReport;
  dispatch(setErrorMessage(''));
  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });

  if (
    state.filterValue?.length > 0 &&
    state.product !== null &&
    state.product.WVProdCode &&
    state.region !== null
  ) {
    if (isRegionTable) {
      // let url = '/route.php?c=heatmap/getHeatmapRegionReportTable&filter=' + encodeURIComponent(state.filter) + '&filterValue=' + encodeURIComponent(state.filterValue) + '&prod=' + state.product.WVProdCode + '&region=' + state.region;
      let url = `/route.php?c=heatmap/getHeatmapRegionReportTable&filter=${encodeURIComponent(
        state.filter,
      )}&filterValue=${encodeURIComponent(state.filterValue)}&prod=${
        state.product.WVProdCode
      }`;

      if (state.region) {
        url += `&region=${state.region}`;
      }

      url += state?.selectedGroup?.length
        ? '&group=' +
          encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
        : '';
      if (memberIds?.length > 0) {
        url += '&member=' + encodeURIComponent(memberIds);
      }

      if (state.ownBrand && state.ownBrand.Text) {
        url += `&ownBrand=${state.ownBrand.Text}`;
      }

      url +=
        state?.selectedBusinessType?.length > 0
          ? '&business_type=' +
            encodeURIComponent(
              map(state.selectedBusinessType, 'Text').join(','),
            )
          : '';

      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      })
        .then(result => {
          dispatch(setIsRegionTableAction(true));
          dispatch(setRegionalTableDataAction(result));
          if (typeof result !== 'undefined' && result?.length === 0) {
            dispatch(setErrorMessage(noResultsErrorMessage));
          }
          dispatch(setLoadingAction(false));
        })
        .catch(e => {
          dispatch(setTableDataAction([]));
          dispatch(setErrorMessage(failedRequestErrorMessage));
          dispatch(setLoadingAction(false));
        });
    } else {
      // let url = '/route.php?c=heatmap/getHeatmapRegionReportTable&filter=' + encodeURIComponent(state.filter) + '&filterValue=' + encodeURIComponent(state.filterValue) + '&prod=' + state.product.WVProdCode + '&region=' + state.region;
      let url = `/route.php?c=heatmap/getHeatmapReportTable&filter=${encodeURIComponent(
        state.filter,
      )}&filterValue=${encodeURIComponent(state.filterValue)}&prod=${
        state.product.WVProdCode
      }`;
      url += state?.selectedGroup?.length
        ? '&group=' +
          encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
        : '';
      if (memberIds?.length > 0) {
        url += '&member=' + encodeURIComponent(memberIds);
      }

      if (state.ownBrand && state.ownBrand.Text) {
        url += `&ownBrand=${state.ownBrand.Text}`;
      }

      url +=
        state.selectedBusinessType?.length > 0
          ? '&business_type=' +
            encodeURIComponent(
              map(state.selectedBusinessType, 'Text').join(','),
            )
          : '';
      try {
        request({
          url: url,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
            UserId: getUserId(),
            System: getUserSystem(),
          },
        }).then(result => {
          dispatch(setIsRegionTableAction(false));
          dispatch(setTableDataAction(result));
          if (typeof result !== 'undefined' && result?.length === 0) {
            dispatch(setErrorMessage(noResultsErrorMessage));
          }
          dispatch(setLoadingAction(false));
        });
      } catch {
        dispatch(setTableDataAction([]));
        dispatch(setErrorMessage(failedRequestErrorMessage));
        dispatch(setLoadingAction(false));
      }
    }
  } else {
    dispatch(setTableDataAction([]));
    dispatch(setLoadingAction(false));
  }
};

const loadDepots = (store, dispatch) => {
  let state = store.getState().areaHeatmapReport;
  let url =
    '/route.php?c=heatmap/getHeatmapRegionReport&filter=' +
    encodeURIComponent(state.filter) +
    '&filterValue=' +
    encodeURIComponent(state.filterValue) +
    '&prod=' +
    state.product.WVProdCode +
    '&region=' +
    state.region;
  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });

  if (memberIds?.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }
  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }
  url +=
    state?.selectedBusinessType?.length > 0
      ? '&business_type=' +
        encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setRegionDataAction(result));
  });
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export const setBusinessType = val => dispatch => {
  dispatch(setBusinessTypeAction(val));
};

export const setErrorMessage = val => dispatch => {
  dispatch(setErrorMessageAction(val));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      departments: payload.departments,
      categoryHierarchy: payload.categories,
      members: payload.members,
      groups: payload.groups,
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({ ...state, products: payload }),
    [SET_REPORT_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      department: payload,
      filter: 'department',
      filterValue: payload.Text,
      filterId: payload.ID,
      categories: payload.Categories,
      reportType: payload.reportType,
    }),
    [SET_REPORT_CATEGORY]: (state, { payload }) => ({
      ...state,
      category: payload,
      filter: 'category',
      filterValue: payload.Text,
      filterId: payload.ID,
      subCategories: payload.SubCategories,
      reportType: payload.reportType,
    }),
    [SET_SUBCATEGORY]: (state, { payload }) => ({
      ...state,
      subCategory: payload,
      filter: 'subcategory',
      filterValue: payload.Text,
      filterId: payload.ID,
      level4s: payload.Level4,
      reportType: payload.reportType,
    }),
    [SET_LEVEL4]: (state, { payload }) => ({
      ...state,
      level4: payload,
      filter: 'level4',
      filterValue: payload.Text,
      filterId: payload.ID,
      level5s: payload.Level5,
      reportType: payload.reportType,
    }),
    [SET_LEVEL5]: (state, { payload }) => ({
      ...state,
      filter: 'level5',
      filterValue: payload.Text,
      filterId: payload.ID,
      level5: payload,
    }),
    [SET_BUSINESS_TYPES]: (state, { payload }) => ({
      ...state,
      businessTypes: payload,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_PRODUCT]: (state, { payload }) => ({ ...state, product: payload }),
    [SET_REGION]: (state, { payload }) => ({ ...state, region: payload }),
    [SET_PRODUCT_DATA]: (state, { payload }) => ({
      ...state,
      productData: payload,
    }),
    [SET_TABLE_DATA]: (state, { payload }) => ({
      ...state,
      tableData: payload,
    }),
    [SET_REGIONAL_TABLE_DATA]: (state, { payload }) => ({
      ...state,
      regionalTableData: payload,
    }),
    [SET_IS_REGION_TABLE_DATA]: (state, { payload }) => ({
      ...state,
      isRegionTable: payload,
    }),
    [SET_REGION_DATA]: (state, { payload }) => ({
      ...state,
      regionData: payload,
    }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_ERROR_MSG]: (state, { payload }) => ({
      ...state,
      errorMessage: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      departments: [],
      department: {},
      categories: [],
      category: {},
      subCategories: [],
      subCategory: {},
      level4s: [],
      level4: {},
      level5s: [],
      level5: {},
      reportType: '',
      filter: '',
      filterValue: '',
      filterId: '',
      region: '',
      isLoading: false,
      product: {},
      selectedGroup: [],
      groups: [],
      businessTypes: [],
      selectedBusinessType: '',
      tableData: [],
      regionalTableData: [],
      isRegionTable: false,
      errorMessage: '',
    }),
  },
  initialState,
);
