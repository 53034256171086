import React from 'react';
import styled from 'styled-components';
import { Table, Button } from 'reactstrap';
import { formatNumber, formatPerc } from '../../../helpers/helpers';
import { spaceAndRange as explanation } from '../Explainer/Explanations';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const StyledTable = styled(Table)`
  color: #000;
  text-align: center;
  td:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  tr th:first-of-type {
    background-color: #fff;
  }
  th,
  td {
    text-align: left !important;
  }
`;

const HeaderContainer = styled.div`
  cursor: help;
`;

const CategoryPenetrationTable = ({
  data: { table, graph },
  isRollingDateRange,
  drillTableHeaders,
  history,
}) => {
  return (
    <StyledTable>
      <tbody>
        <tr>
          <th></th>

          <th colSpan={3}>
            <HeaderContainer>Category Penetration</HeaderContainer>
          </th>

          <th colSpan={2}>
            <Tooltip title={explanation.table.units}>
              <HeaderContainer>Units</HeaderContainer>
            </Tooltip>
          </th>
          <th colSpan={2}>
            <Tooltip title={explanation.table.value}>
              <HeaderContainer>Value</HeaderContainer>
            </Tooltip>
          </th>
          <th colSpan={2}>
            <Tooltip title={explanation.table.baskets}>
              <HeaderContainer>Baskets</HeaderContainer>
            </Tooltip>
          </th>
          <th colSpan={2}>
            <Tooltip title={explanation.table.avgBasket}>
              <HeaderContainer>Avg Basket Spend</HeaderContainer>
            </Tooltip>
          </th>
        </tr>
        <tr>
          <th></th>
          <th> Sku Share</th>
          <th> Sales Share</th>
          <th> Sales/Sku Index</th>
          <th> {isRollingDateRange ? 'Current' : 'TY'}</th>
          <th> {isRollingDateRange ? 'Previous' : 'LY'}</th>
          <th> {isRollingDateRange ? 'Current' : 'TY'}</th>
          <th> {isRollingDateRange ? 'Previous' : 'LY'}</th>
          <th> {isRollingDateRange ? 'Current' : 'TY'}</th>
          <th> {isRollingDateRange ? 'Previous' : 'LY'}</th>
          <th> {isRollingDateRange ? 'Current' : 'TY'}</th>
          <th> {isRollingDateRange ? 'Previous' : 'LY'}</th>
        </tr>
        {table &&
          table.map((row, i) => <CategoryPenetrationRow drillTableHeaders={val => { 
            history.push(
            `/retail/category-penetration/graph/pareto`); 
            drillTableHeaders(val);
            }} key={i} data={row} />)}
      </tbody>
    </StyledTable>
  );
};

export const CategoryPenetrationRow = ({
  data: {
    Name,
    MarketShare_TY,
    SkuShare_TY,
    Sales_Sku_Index_TY,
    SummaryValue_TY,
    SummaryValue_LY,
    QTY_TY,
    QTY_LY,
    AvSpend_TY,
    AvSpend_LY,
    Baskets_TY,
    Baskets_LY,
  },
  drillTableHeaders
}) => {
  return (
    <tr>
      <td>
        {Name} 
        <Button onClick={() => drillTableHeaders(Name)} className="float-right">
          Graph
        </Button>
      </td>
      <td className={Sales_Sku_Index_TY >= 1 ? 'green-cell' : 'red-cell'}>{(MarketShare_TY*100).toFixed(2)+'%'}</td>
      <td className={Sales_Sku_Index_TY >= 1 ? 'green-cell' : 'red-cell'}>{(SkuShare_TY*100).toFixed(2)+'%'}</td>
      <td className={Sales_Sku_Index_TY >= 1 ? 'green-cell' : 'red-cell'}>{Sales_Sku_Index_TY >= 1 ? '' : '0'}{Sales_Sku_Index_TY}</td>
      <td>{formatNumber(QTY_TY)}</td>
      <td>{formatNumber(QTY_LY)}</td>
      <td>{formatNumber(SummaryValue_TY, '£')}</td>
      <td>{formatNumber(SummaryValue_LY, '£')}</td>
      <td>{Baskets_TY}</td>
      <td>{Baskets_LY}</td>
      <td>{formatNumber(AvSpend_TY, '£')}</td>
      <td>{formatNumber(AvSpend_LY, '£')}</td>
    </tr>
  );
};

export default CategoryPenetrationTable;
