import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { skuPerformance as explanation } from '../Explainer/Explanations';
import { coloredTableTheme } from '../../../config/tableStyles';
import { formatToCurrency } from '../../../helpers/helpers';

const StyledContainer = styled.div`
  div[role='rowgroup'] div[role='row'] div[role='gridcell']:first-child {
    padding: 1rem 0.6rem;
    color: #000;
    text-align: left;
    font-weight: 600;
  }

.rdt_Table < div {
	overflow-x: hidden;	 
 }

 .rdt_Table{
	margin-left:0.2rem;
	margin-bottom:2rem;
	padding:2rem 1.8rem;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
	overflow-x: hidden;	 
 }

 .rdt_TableHeadRow{
	background: ${({ theme }) => theme.tableColor1};
 }
 
  .rdt_TableCol{
    border-right: 1px solid ${({ theme }) => theme.tableColor1};
	
 }

 .rdt_TableBody .rdt_TableRow:nth-child(even) {
  background-color: #F7F7F7;
 }
 
 .rdt_TableCell{
	font-size: 0.9rem; 
  justify-content: center;
 }

 .rdt_TableCell > div {
    width: auto !important;
    font-size: 0.75rem !important;
    overflow: visible;
}

  .rdt_TableHeadRow .rdt_TableCol {
    color:#000;
	padding: 8px;
  }

  h2 {
    font-size: 0.75rem !important;
  }
  
 .rdt_TableHeadRow {
   padding-right: 14px;
 }
 
/* Works on Firefox */
.rdt_Table {
  scrollbar-width: thin;
}


.rdt_TableBody::-webkit-scrollbar {
    width: 14px;
}

.rdt_TableBody::-webkit-scrollbar-track {
	background: ${({ theme }) => theme.primary};
    border-left: 6px solid white;
    border-right: 7px solid white;
	
}

.rdt_TableBody::-webkit-scrollbar-thumb {
    box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme }) => theme.primary} !important; 
}
`;

const { tableHeaders } = explanation;

const SkuPerformanceTable = ({ data }) => {
  const columns = [
    {
      selector: 'WVProdDesc',
      name: <TableHeaderTooltip text="Product" />,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'QtyTY',
      name: (
        <TableHeaderTooltip
          text="Total Retail Units TY"
          explanationTooltip={tableHeaders.quantity}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },

      format: row => `${parseInt(row.QtyTY).toLocaleString(0)}`,
      sortFunction: (rowA, rowB) => rowA.QtyTY - rowB.QtyTY,
    },
    {
      selector: 'QtyLY',
      name: (
        <TableHeaderTooltip
          text="Total Retail Units LY"
          explanationTooltip={tableHeaders.quantity}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },

      format: row => `${parseInt(row.QtyLY).toLocaleString(0)}`,
      sortFunction: (rowA, rowB) => rowA.QtyLY - rowB.QtyLY,
    },
    {
      selector: 'SummaryValueTY',
      name: (
        <TableHeaderTooltip
          text="Total Retail Value TY"
          explanationTooltip={tableHeaders.summaryValue}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.SummaryValueTY, 0)}`,
      sortFunction: (rowA, rowB) => rowA.SummaryValueTY - rowB.SummaryValueTY,
    },
    {
      selector: 'SummaryValueLY',
      name: (
        <TableHeaderTooltip
          text="Total Retail Value LY"
          explanationTooltip={tableHeaders.summaryValue}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.SummaryValueLY, 0)}`,
      sortFunction: (rowA, rowB) => rowA.SummaryValueLY - rowB.SummaryValueLY,
    },
    {
      selector: 'UnitsPerWeekTY',
      name: (
        <TableHeaderTooltip
          text="Retail Units Per Store Per Week TY"
          explanationTooltip={tableHeaders.unitsPerWeek}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },

      format: row => `${parseFloat(row.UnitsPerWeekTY).toFixed(2)}`,
      sortFunction: (rowA, rowB) => rowA.UnitsPerWeekTY - rowB.UnitsPerWeekTY,
    },
    {
      selector: 'UnitsPerWeekLY',
      name: (
        <TableHeaderTooltip
          text="Retail Units Per Store Per Week LY"
          explanationTooltip={tableHeaders.unitsPerWeek}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },
      format: row => `${parseFloat(row.UnitsPerWeekLY).toFixed(2)}`,
      sortFunction: (rowA, rowB) => rowA.UnitsPerWeekLY - rowB.UnitsPerWeekLY,
    },
    {
      selector: 'StoreCountTY',
      name: (
        <TableHeaderTooltip
          text="Count of Stores Where Stocked TY"
          explanationTooltip={tableHeaders.storeCount}
        />
      ),
      sortable: true,

      format: row => `${parseInt(row.StoreCountTY).toFixed(0)}`,
      sortFunction: (rowA, rowB) => rowA.StoreCountTY - rowB.StoreCountTY,
    },
    {
      selector: 'StoreCountLY',
      name: (
        <TableHeaderTooltip
          text="Count of Stores Where Stocked LY"
          explanationTooltip={tableHeaders.storeCount}
        />
      ),
      sortable: true,

      format: row => `${parseInt(row.StoreCountLY).toFixed(0)}`,
      sortFunction: (rowA, rowB) => rowA.StoreCountLY - rowB.StoreCountLY,
    },
    {
      selector: 'AvUnitPriceTY',
      name: (
        <TableHeaderTooltip
          text="Avg RSP TY"
          explanationTooltip={tableHeaders.avUnitPrice}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },
      format: row => `${formatToCurrency(row.AvUnitPriceTY)}`,
      sortFunction: (rowA, rowB) => rowA.AvUnitPriceTY - rowB.AvUnitPriceTY,
    },
    {
      selector: 'AvUnitPriceLY',
      name: (
        <TableHeaderTooltip
          text="Avg RSP LY"
          explanationTooltip={tableHeaders.avUnitPrice}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },
      format: row => `${formatToCurrency(row.AvUnitPriceLY)}`,
      sortFunction: (rowA, rowB) => rowA.AvUnitPriceLY - rowB.AvUnitPriceLY,
    },
    {
      selector: 'ContributionPerStorePerWeekTY',
      name: (
        <TableHeaderTooltip
          text="Retail Value Per Store Per Week TY"
          explanationTooltip={tableHeaders.contributionPerStorePerWeek}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.ContributionPerStorePerWeekTY)}`,
      sortFunction: (rowA, rowB) =>
        rowA.ContributionPerStorePerWeekTY - rowB.ContributionPerStorePerWeekTY,
    },
    {
      selector: 'ContributionPerStorePerWeekLY',
      name: (
        <TableHeaderTooltip
          text="Retail Value Per Store Per Week LY"
          explanationTooltip={tableHeaders.contributionPerStorePerWeek}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.ContributionPerStorePerWeekLY)}`,
      sortFunction: (rowA, rowB) =>
        rowA.ContributionPerStorePerWeekLY - rowB.ContributionPerStorePerWeekLY,
    },
    {
      selector: 'CVTotalTY',
      name: (
        <TableHeaderTooltip
          text="Category Weighted Distribution TY"
          explanationTooltip={tableHeaders.cvTotal}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },
      format: row => `${parseFloat(row.CVTotalTY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.CVTotalTY - rowB.CVTotalTY,
    },
    {
      selector: 'CVTotalLY',
      name: (
        <TableHeaderTooltip
          text="Category Weighted Distribution LY"
          explanationTooltip={tableHeaders.cvTotal}
        />
      ),
      sortable: true,
      style: {
        backgroundColor: '#eeeeee',
      },
      format: row => `${parseFloat(row.CVTotalLY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.CVTotalLY - rowB.CVTotalLY,
    },
    {
      selector: 'ACVTotalTY',
      name: (
        <TableHeaderTooltip
          text="Percentage ACV Distribution TY"
          explanationTooltip={tableHeaders.acvTotal}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.ACVTotalTY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.ACVTotalTY - rowB.ACVTotalTY,
    },
    {
      selector: 'ACVTotalLY',
      name: (
        <TableHeaderTooltip
          text="Percentage ACV Distribution LY"
          explanationTooltip={tableHeaders.acvTotal}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.ACVTotalLY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.ACVTotalLY - rowB.ACVTotalLY,
    },
  ];
  return (
    <StyledContainer>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'SummaryValueTY'}
        defaultSortAsc={false}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default SkuPerformanceTable;
