import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import styled, { ThemeContext } from 'styled-components';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { formatToCurrency } from '../../../helpers/helpers';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import 'chartjs-plugin-labels';

Chart.plugins.register([ChartAnnotation]);

const htmlToReactParser = new HtmlToReactParser();

const ImgHolder = styled.div`
  padding: 0 0.4rem;
  color: ${({ theme: { secondary } }) => secondary};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledTitle = styled.h2`
  font-size: 1.125rem;
`;

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  margin: 1rem 0;
  position: relative;

  canvas + div {
    margin: 3rem 2rem !important;
  }
`;

const KPIBarChart = ({
  data,
  id,
  title,
  value,
  drillGraphResults,
  tooltip,
  className,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const theme = useContext(ThemeContext);

  const colorArray = theme.colorArray || ['#6C36C4', '#CAB8E6 ', '#8464B9'];
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }
    if (chartRef.current) {
      const summaryDataValues =
        data &&
        data.map((item, idx) => ({
          data: [item[`${value}_Percentage`]],
          label: item.Name,
          backgroundColor: [colorArray[idx % 3]],
          borderColor: ['White'],
          borderWidth: 1,
          borderRadius: 5,
          hoverBorderColor: ['White'],
          hoverBackgroundColor: [colorArray[idx % 3]],
          itemName: item.Name,
          dataValue: item[value],
        }));

      // If the total percentage for the data is over 100 the graph breaks and is not full width

      const summaryChartData = {
        datasets: summaryDataValues,
        labels: [title],
      };
      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, {
        type: 'horizontalBar',
        data: summaryChartData,
        lineAtIndex: [1],
        options: {
          annotation: {
            annotations: [
              {
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: '0',
                borderColor: 'red',
                borderWidth: 5,
                label: {
                  content: 'Negative             Positive',
                  enabled: true,
                  position: 'top',
                  backgroundColor: 'rgba(255,255,255,0)',
                  fontFamily: theme.font,
                  fontSize: 16,
                  fontStyle: 'bold',
                  fontColor: theme.fontColor,
                  yAdjust: -5,
                },
              },
            ],
          },
          legendCallback: function(chart) {
            var text = [];
            text.push(
              '<div style="color: #000; display: flex; margin: 3rem 0" class="' +
                chart.id +
                '-legend">',
            );

            const negatives = chart.data.datasets
              .filter(d => d.data[0] < 0)
              .reverse();

            const positives = chart.data.datasets.filter(d => d.data[0] >= 0);
            const sortedData = [...negatives, ...positives];

            // Calc total value of all data for width measurement
            let totalValue = 0;
            for (var i = 0; i < sortedData.length; i++) {
              const curVal = sortedData[i].data[0];
              totalValue += curVal < 0 ? Math.abs(curVal) : curVal;
            }

            for (var i = 0; i < sortedData.length; i++) {
              const curVal = sortedData[i].data[0];
              curVal < 0 ? Math.abs(curVal) : curVal;
              let hideSmallClass = '';
              const hideSmallerThan = 5;
              if (sortedData[i].data[0] < hideSmallerThan) {
                hideSmallClass = '';
              }
              text.push(
                '<div class="' +
                  '2' +
                  '" style="display: flex; justify-content: center; text-align: center; width:' +
                  ((curVal < 0 ? Math.abs(curVal) : curVal) / totalValue) *
                    100 +
                  '%"><div class="label-chart"><p>',
              );
              if (sortedData[i].label) {
                text.push(sortedData[i].label);
              }
              text.push(`</p><p>${formatToCurrency(curVal)}</p></div></div>`);
            }
            text.push('</div>');
            return text.join('');
          },

          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,

            custom: function(tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title, i) {
                  innerHtml +=
                    '<tr style="display:flex; justify-content: space-between"><th>' +
                    title +
                    '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();
              const labelColor = tooltipModel.labelColors[0].backgroundColor;
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.background = 'black';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: function(tooltipItem, data) {
                return `${data.datasets[tooltipItem.datasetIndex].label} : ${
                  id !== 'sku'
                    ? `${formatToCurrency(
                        data.datasets[tooltipItem.datasetIndex].dataValue,
                        0,
                      )}`
                    : data.datasets[tooltipItem.datasetIndex].dataValue
                }`;
              },
            },
            backgroundColor: '#fff',
            titleFontSize: 16,
            titleFontColor: '#0066ff',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: true,
            mode: 'dataset',
            position: 'nearest',
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: false,
                gridLines: {
                  display: false,
                },
                barPercentage: 1,
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: false,
                gridLines: {
                  drawBorder: false,
                },
                ticks: {
                  mirror: true,
                  fontColor: '#fff',
                  fontStyle: 'normal',
                },
              },
            ],
          },
        },
      });
      setChartInstance(myChart);

      myChart.generateLegend();
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard className={className}>
      <Container>
        <StyledTitle className="text-uppercase">{title}</StyledTitle>
        {tooltip && (
          <ImgHolder>
            <Tooltip
              title={tooltip}
              position="bottom"
              trigger="mouseenter"
              theme="light">
              <HelpOutlineIcon style={{ cursor: 'pointer' }} />
            </Tooltip>
          </ImgHolder>
        )}
      </Container>
      <canvas ref={chartRef} id={`myChart-${id}`} width="600" height="100" />
      {chartRef &&
        chartRef.current &&
        chartInstance != null &&
        htmlToReactParser.parse(chartInstance.generateLegend())}
    </StyledCard>
  );
};

export default KPIBarChart;
