import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  fetchHistory,
  scheduleQuery,
  resetQuerySchedule,
} from '../../redux/reducers/query';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem } from '../../../helpers/util';

const QuerySchedule = ({
  data,
  selectedQuery,
  loadMoreQueries,
  isLoading,
  onChange,
  isQueryScheduled,
  onScheduleQuery,
}) => (
  <main>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName="c-alchemy-toasty c-alchemy-toasty--query"
    />
    <div className="container mt-40">
      <div className="row">
        <div className="col">
          <div className="query-builder query-builder__steps">
            <h2 className="text-uppercase mb-4">Query Schedule</h2>
            <hr />
          </div>
          <div className="row query-builder">
            Query: {selectedQuery.id} <br />
            Ran at:{' '}
            {moment(selectedQuery.Last_Ran).format('h:mm a, DD MMMM, YYYY')}
            <div
              className="u-layout u-layout--between"
              style={{ width: '100%' }}>
              <div
                className="o-wrap o-wrap--summary u-1_3"
                style={{
                  margin: '10px',
                  marginBottom: '10px',
                  width: '100%',
                  overflow: 'hidden',
                }}>
                <p>
                  <strong>Measures:</strong> {selectedQuery.Measures.join(', ')}
                </p>
                {/* <p><strong>Time Frames:</strong></p> */}
                {/*   { data.timeFrames.map((time, key) => { */}
                {/*     return <p>{time} <input type="checkbox" /></p> */}
                {/*   })} */}
                <p>
                  <strong>Wholesalers:</strong>{' '}
                  {selectedQuery.Wholesalers.map(
                    place => place.wholesalerName,
                  ).join(',')}
                </p>
                <p>
                  <strong>RUN:</strong>
                </p>
                <select value={data.run} onChange={onChange}>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              <div
                className="o-wrap o-wrap--summary u-1_3"
                style={{
                  margin: '10px',
                  marginBottom: '10px',
                  width: '100%',
                  overflow: 'hidden',
                }}>
                <p>
                  <strong>Chosen Fields</strong>
                </p>
                <p>{selectedQuery.Month_Day}</p>
              </div>
              <div
                className="o-wrap o-wrap--summary u-1_3"
                style={{
                  margin: '10px',
                  marginBottom: '10px',
                  width: '100%',
                  overflow: 'hidden',
                }}>
                <p>
                  <strong>Chosen Filters</strong>
                </p>
                <p>
                  {selectedQuery.Filters.map(filter => filter.field).join(',')}
                </p>
              </div>
            </div>
            <div>
              Weekly reports will run on Thursday Morning, monthly reports run
              on the 10th of every month
            </div>
            <div style={{ width: '100%' }}>
              <button
                className={`u-right btn alchemy-btn--${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                }`}
                style={{ margin: '10px' }}
                onClick={onScheduleQuery}>
                Save Changes
              </button>
            </div>
            {isQueryScheduled && (
              <div style={{ color: 'green' }}>
                Query was scheduled successfully.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </main>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.query.isLoading,
    selectedQuery: state.query.selectedQuery,
    isQueryScheduled: state.query.isQueryScheduled,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, { fetchHistory, scheduleQuery, resetQuerySchedule }),
  lifecycle({
    componentDidMount() {
      this.props.resetQuerySchedule();
    },
  }),
  withState('data', 'handleData', {
    offset: 10,
    timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
      'Last Week TY',
      'Last Week LY',
      'Last 4 Weeks TY',
      'Last 4 Weeks LY',
      'Last 13 Weeks TY',
      'Last 13 Weeks LY',
      '4 Weeks Rolling',
      '13 Weeks Rolling',
      'YTD TY',
      'YTD LY',
      'MAT TY',
      'MAT LY',
    ],
    run: 'weekly',
  }),
  withHandlers({
    onScheduleQuery: ({
      data,
      handleData,
      selectedQuery,
      scheduleQuery,
    }) => () => {
      scheduleQuery(selectedQuery.id, data.run);
    },
    onChange: ({ data, handleData }) => ev => {
      handleData({ ...data, run: ev.target.value });
    },
  }),
)(QuerySchedule);
