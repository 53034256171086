import React from 'react';
import PropTypes from 'prop-types';
import { getUserSystem } from '../../../helpers/util';

class AlchToggleMenu extends React.Component {
  state = {
    value: undefined,
    init: false,
  };

  componentDidMount() {
    this.setValue();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    /* Dont update if without children as theres no point */
    if (this.props.children.length === 0 && nextProps.children.length === 0) {
      return false;
    }

    return true;
  }

  componentDidUpdate() {
    if (this.state.value === undefined && !this.state.init) {
      this.setValue();
    }
  }

  setValue = () => {
    let value;
    if (this.props.children.length === 0) {
      return false;
    }

    /* Set selected value */
    if (this.props.value) {
      value = this.props.value;
    }

    // Initialize default value only if current is empty
    if (this.props.defaultValue && this.state.value === undefined) {
      value = this.props.defaultValue;
    }

    // If all failed choose first child
    // value = this.props.children[0].id;
    // value = '13WK_TY';

    this.setState({
      value,
    });

    if (!this.state.init) {
      this.setState({
        init: true,
      });
      this.props.onInit(value, this.props.name);
    }
  };

  selectItem = (id, uid) => {
    if (id === this.state.value) return;
    this.setState({
      value: id,
    });

    this.props.onChange(id, this.props.name, uid);
  };

  renderItem = item => {
    const compClass = ['c-toggle-menu__item', `btn alchemy-btn--${getUserSystem() === 'unitas' ? 'unitas' : 'others'}`];
    if (item.id === this.state.value) {
      compClass.push(`active-bg--${getUserSystem() === 'unitas' ? 'unitas' : 'others'}`);
    }

    const uid = item.uid ? item.uid : null; /* currently used by date picker */

    return (
      <button
        className={compClass.join(' ')}
        key={item.id}
        onClick={e => {
          e.preventDefault();
          this.selectItem(item.id, uid);
        }}
      >
        {item.label}
      </button>
    );
  };

  render() {
    if (!this.props.children || this.props.children.length === 0) {
      return null;
    }

    const menuClassnames = ['c-toggle-menu'];
    if (this.props.vertical) {
      menuClassnames.push('c-toggle-menu--vertical');
    }

    return (
      <div className={menuClassnames.join(' ')} data-name={this.props.name}>
        {this.props.children.map(item => this.renderItem(item))}
      </div>
    );
  }
}

AlchToggleMenu.defaultProps = {
  children: Array.prototype,
  value: undefined,
  name: undefined,
  defaultValue: undefined,
  vertical: false,
  onChange: Function.prototype,
  onInit: Function.prototype,
};

AlchToggleMenu.propTypes = {
  children: PropTypes.array,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string, // @TODO AlchDateRangepicker - Should be required when date picker is fixed
  vertical: PropTypes.bool,
  onChange: PropTypes.func,
  onInit: PropTypes.func,
};

export default AlchToggleMenu;
