import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { getUserSystem, hasPermission } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import SkuPerformanceTable from '../../components/retail/SkuPerformanceTable';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import Loading from '../../components/Loading';

import { retailSkuPerformanceExportHeaders } from '../../../config/tableExportHeaders';
import { skuPerformance as explanation } from '../../components/Explainer/Explanations';
import { pageVariants } from '../../../config/pageVariants';
import {
  setAccountName,
  fetchAccountNames,
} from '../../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  resetReport,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRetailType,
  setRefreshDisabledStatus,
  loadSkuPerformanceData,
  setDrillParams,
} from '../../redux/reducers/retail';

export const SkuPerformance = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  onAccountNameChange,
  likeForLike,
  likeForLikes,
  unlockRefresh,
  setLoading,
  fetchCategories,
  fetchAccountNames,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  onLikeForLikeChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  selectedLevel4s,
  level4s,
  onLevel5Change,
  selectedLevel5s,
  level5s,
  loadSkuPerformanceData,
  skuPerformanceData,
  isLoading,
  isRefreshDisabled,
  location,
  onResetReport,
  isSkuDrill,
  drillData,
  setDrillParams,
  isLoaded,
  onRetailTypeChange,
  retailType,
  retailTypes,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('sku_performance', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });
    return () => onUnmount();
  }, []);
  return (
    <motion.div
      key={'Sku-Pereformance-REPORT-ID-1232'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        explanationTooltip={explanation.page.tooltip}
        pathname={location.pathname}
        refresh={{
          refreshHandler: () => loadSkuPerformanceData(),
          isRefreshDisabled,
        }}
        exports={{
          CSV: {
            headers: retailSkuPerformanceExportHeaders,
            data: skuPerformanceData,
          },
        }}
        title={'Sku Performance'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Sku Performance for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          {
            name: 'accountName',
            valueKey: 'RegNo',
            labelKey: 'CustomerName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
            clearable: true,
          },
        ]}>
        <DataDropdownsContainer
          unlockRefresh={() => unlockRefresh()}
          dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            },
            {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel4s,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            },
            {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel5s,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            },
            {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSuppliers,
              options: suppliers,
              handler: option => onSupplierChange(option),
              isMulti: true,
            },
            {
              name: 'Brand',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrands,
              options: brands,
              handler: option => onBrandChange(option),
              isMulti: true,
            },
            {
              name: 'retailType',
              condition: hasPermission(`role_view_${getUserSystem().toLowerCase()}_independent_data`) ? ['display']  : [],
              valueKey: 'ID',
              labelKey: 'Text',
              value: retailType,
              options: retailTypes,
              handler: option => onRetailTypeChange(option),
              isMulti: false,
            },
          ]}
        />
      </ReportSettingsContainer>
      <ReportContainer padding={3} display="block">
        <div className={isLoading ? 'w-100' : ''}>
          {isLoading && <Loading />}
        </div>
        {!isLoading && skuPerformanceData && skuPerformanceData.length > 0 && (
          <SkuPerformanceTable data={skuPerformanceData} />
        )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategories: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrands,
  selectedLevel4s: state.retail.selectedLevel4s,
  level4s: state.retail.level4s,
  selectedLevel5s: state.retail.selectedLevel5s,
  level5s: state.retail.level5s,
  retailType: state.retail.retailType,
  retailTypes: state.retail.retailTypes,
  skuPerformanceData:
    state.retail.skuPerformanceData && state.retail.skuPerformanceData.table,
  isLoading: state.retail.isLoading,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  isSkuDrill: state.retail.isSkuDrill,
  drillData: state.retail.drillData,
  isLoaded: state.retail.isLoaded,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onRetailTypeChange: option => dispatch(setRetailType(option || '')),
    loadSkuPerformanceData: () => dispatch(loadSkuPerformanceData()),
    unlockRefresh: () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(
        fetchCategories('retailer_performance', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
    setDrillParams: drillData => dispatch(setDrillParams(drillData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuPerformance);
