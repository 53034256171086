import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';
import Rating from 'react-rating';
import 'font-awesome/css/font-awesome.css';

class TableWholeview extends React.Component {
  // @TODO
  // - Add total
  state = {
    productId: '',
    isExpanded: false,
  };

  shouldComponentUpdate(nextProps) {
    return this.props.measure === nextProps.measure;
  }

  onClickParent = event => {
    this.setState({ productId: event.target.id, isExpanded: !this.state.isExpanded });
  };

  renderRow = (row, i, id) => {
    return (
      <div key={i} style={{ position: 'relative' }}>
        <div className="u-tac u-lg o-results__row" key={i} style={row.isSelected ? { background: 'green' } : {}}>
          {parseInt(row.ChildrenCount) > 0 && id !== 'wholeview_master_table' ? (
            <div onClick={this.onClickParent.bind(this)} id={row.ProductId} style={{ cursor: 'pointer' }} className="o-results__id">
              {this.state.isExpanded === true && this.state.productId === row.ProductId ? <i id={row.ProductId} className="fa fa-arrow-up"></i> : <i id={row.ProductId} className="fa fa-arrow-down"></i>}
            </div>
          ) : (
            ''
          )}
          {this.props.id === 'wholeview_filter_table' ? (
            <div className="o-results__id" onClick={this.props.onSelectedRow} id={row.ProductId} style={row.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
              <span>{row.MasterCode}</span>
            </div>
          ) : (
            ''
          )}
          <div className="o-results__product" onClick={this.props.onSelectedRow} id={row.ProductId} style={row.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
            {row.Description}
          </div>
          <div className="o-results__wholesaler" onClick={this.props.onSelectedRow} id={row.ProductId} style={row.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
            {row.Wholesaler}
          </div>
          <div className="o-results__cost" onClick={this.props.onSelectedRow} id={row.ProductId} style={row.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
            {row.Weight === '.00' ? <Rating start={0} initialRate={5} end={5} step={1} readonly={true} empty={['fa fa-gbp fa-2x low']} full={['fa fa-gbp fa-2x high']} /> : ''}
            {parseInt(row.Weight) < 6 && parseInt(row.Weight) > 0 ? <Rating start={0} initialRate={parseInt(row.Weight)} end={5} step={1} readonly={true} empty={['fa fa-gbp fa-2x low']} full={['fa fa-gbp fa-2x high']} /> : ''}
            {parseInt(row.Weight) === 6 ? <i className="fa fa-sitemap" style={{ fontSize: '25px', color: '#4a8b31' }}></i> : ''}
          </div>
        </div>
        {parseInt(row.ChildrenCount) > 0 && this.state.isExpanded && this.state.productId === row.ProductId
          ? row.Children.map((child, key) => {
              return (
                <div className="u-tac u-lg o-results__row" key={key} onClick={this.props.onSelectedRow} id={child.ProductId} style={child.isSelected ? { background: 'green' } : {}}>
                  {this.props.id === 'wholeview_filter_table' ? (
                    <div className="o-results__id o-results__id--is--expanded" id={child.ProductId} style={child.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
                      {child.MasterCode}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="o-results__product o-results__product--is--expanded" id={child.ProductId} style={child.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
                    {child.Description}
                  </div>
                  <div className="o-results__wholesaler o-results__wholesaler--is--expanded" id={child.ProductId} style={child.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
                    {child.Wholesaler}
                  </div>
                  <div className="o-results__cost o-results__cost--is--expanded" id={child.ProductId} style={child.isSelected ? { background: '#8fcb74', color: '#ffffff' } : {}}>
                    {child.Weight === '.00' ? <Rating start={0} initialRate={5} end={5} step={1} readonly={true} empty={['fa fa-gbp fa-2x low']} full={['fa fa-gbp fa-2x high']} /> : ''}
                    {parseInt(child.Weight) < 6 && parseInt(child.Weight) > 0 ? <Rating start={0} initialRate={parseInt(child.Weight)} end={5} step={1} readonly={true} empty={['fa fa-gbp fa-2x low']} full={['fa fa-gbp fa-2x high']} /> : ''}
                    {parseInt(child.Weight) === 6 ? <i className="fa fa-sitemap" style={{ fontSize: '25px', color: '#4a8b31' }}></i> : ''}
                  </div>
                </div>
              );
            })
          : ''}
      </div>
    );
  };

  render() {
    let scrollbarStyles = { borderRadius: 5 };

    return (
      <ScrollArea className="area" contentClassName="content" verticalScrollbarStyle={scrollbarStyles} verticalContainerStyle={scrollbarStyles} horizontalScrollbarStyle={scrollbarStyles} horizontalContainerStyle={scrollbarStyles} smoothScrolling={true} minScrollSize={40}>
        <div>
          {this.props.data.map((r, i) => {
            return this.renderRow(r, i, this.props.id);
          })}
        </div>
      </ScrollArea>
    );
  }
}

TableWholeview.defaultProps = {
  id: null,
  data: [],
};

TableWholeview.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  data: PropTypes.array,
};

export default TableWholeview;
