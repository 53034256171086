import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import AlchToggle from '../../components/AlchUI/AlchToggle';
import AlchSelectableBox from '../../components/AlchUI/AlchSelectableBox';
import AlchDateRangepicker from '../../components/AlchUI/AlchDateRangepicker';
import { getUserSystem } from '../../../helpers/util';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import StepCrumbs from './StepCrumbs';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  addPermission,
  removePermission,
  fetchAllPermissions,
} from '../../redux/reducers/user';
import * as _ from 'lodash';

const Permissions = ({
  data,
  onNext,
  permissions,
  renderSelectBox,
  isLoading,
  fetchAllPermissions,
}) => {
  useEffect(() => {
    fetchAllPermissions();
  }, []);

  return (
    <div className="o-canvas-wrap">
      <ToastContainer
        autoClose={false}
        style={{ top: '80px', zIndex: '10001' }}
        toastClassName={`c-alchemy-toasty`}
      />
      <div className="o-canvas">
        <div className="o-canvas__header u-layout u-layout--between">
          <div className="o-canvas__header-item u-layout u-layout--between-base">
            <h2 className="o-canvas__title">
              USER PERMISSIONS - Select the functions required
            </h2>
          </div>
        </div>
        <div className="o-canvas__body u-layout u-layout--spaced t-brand-c">
          <div className="u-1_1">
            <StepCrumbs step={1} id={data.id} system={getUserSystem()} />
            <div className="o-block pt-3">
              <h3>NAM DASH</h3>
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.namDash.map(renderSelectBox)}
              </ul>
              <h3>ANALYSIS</h3>
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.analysis.map(renderSelectBox)}
              </ul>
              {permissions?.retail && permissions?.retail?.length > 0 && (
                <>
                  <h3>Retail ANALYSIS</h3>
                  <ul className="o-multiselect u-reset-list u-layout">
                    {permissions.retail?.map(renderSelectBox)}
                  </ul>
                </>
              )}

              {permissions.system.length > 0 && <h3>SYSTEM</h3>}
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.system.map(renderSelectBox)}
              </ul>
              {permissions.competitiors.length > 0 && <h3>Site Permissions</h3>}
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.competitiors.map(renderSelectBox)}
              </ul>
              <h3>Query Builder</h3>
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.queryBuilders.map(renderSelectBox)}
              </ul>
              <h3>QA Report 1</h3>
              <ul className="o-multiselect u-reset-list u-layout">
                {permissions.qa.map(renderSelectBox)}
              </ul>
            </div>
            <button
              onClick={onNext}
              className={`btn alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              }  u-right`}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  let data = {
    isLoading: state.user.isLoading,
    permissions: state.user.permissions,
    user: state.user.user,
    selectedAccountType: state.user.selectedAccountType,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    addPermission,
    removePermission,
    fetchAllPermissions,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        prevProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.dismiss();
        toast.info('Loading, please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (
        prevProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
      }
    },
  }),
  withState('data', 'handleData', props => ({ id: props.match.params.id })),
  withHandlers({
    onNext: props => () => {
      if (props.match.params.id) {
        props.history.push(
          `${process.env.APP_ROOT}create_user/review/${props.match.params.id}`,
        );
      } else {
        props.history.push(`${process.env.APP_ROOT}create_user/review`);
      }
    },
    renderSelectBox: props => (select, i) => {
      let on = false;
      const permissions = _.filter(
        props.user.selectedPermissions,
        per => per.name === select.name,
      );
      if (permissions.length > 0) {
        on = true;
      } else {
        on = false;
      }

      let label = <span>{select.label}</span>;
      if (select.list) {
        label = <span>{select.label}</span>;
      }
      return (
        <li key={i}>
          <AlchSelectableBox
            label={label}
            id={select.name}
            className={`c-selectable-box--large c-selectable-box--${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }`}
            on={on}
            onChange={(val, name) => {
              let permission = {};
              _.filter(props.permissions.namDash, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              _.filter(props.permissions.analysis, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              _.filter(props.permissions.retail, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              _.filter(props.permissions.system, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              _.filter(props.permissions.queryBuilders, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              _.filter(props.permissions.qa, pr => {
                if (pr.name === name) {
                  permission = pr;
                }
              });
              if (
                _.filter(
                  props.user.selectedPermissions,
                  per => per.name === select.name,
                ).length > 0
              ) {
                props.removePermission(name, permission.type);
              } else {
                props.addPermission(name, permission.type);
              }
            }}
          />
        </li>
      );
    },
  }),
)(Permissions);
