const caterforceLogo =
  'https://caterforce-api.uat.twcgroup.net/logos/caterforce-logo.svg';
const unitasLogo = 'https://unitas-api.uat.twcgroup.net/logos/unitas-logo.svg';
const confexLogo = 'https://confex-api.uat.twcgroup.net/logos/confex-logo.png';
const parfettsLogo = 'https://parfetts-new-api.uat.twcgroup.net/logos/parfetts.png';
const cjLangLogo = 'https://cjlang-api.uat.twcgroup.net/logos/cjLangNew.png';
const surgoLogo = 'https://surgo-api.uat.twcgroup.net/logos/surgo-logo.svg';
const twcLogo = 'https://twc.twcgroup.net/assets/images/logos/twcLogo_dark.svg';
const crgLogo = 'https://crg-api.uat.twcgroup.net/logos/crg-logo.png';
const unitedLogo = 'https://united-api.uat.twcgroup.net/logos/united-logo.png';
const deebeeLogo = 'https://deebee-api.uat.twcgroup.net/logos/deebee_logo.png';
const cotswoldLogo = 'https://cotswold-fayre-api.uat.twcgroup.net/logos/cotswold.png';

const getBrandLogoUrl = brand => {
  let logoUrl;
  if (brand === 'unitas') {
    logoUrl = unitasLogo;
  } else if (brand === 'confex') {
    logoUrl = confexLogo;
  } else if (brand === 'caterforce') {
    logoUrl = caterforceLogo;
  } else if (brand === 'cjlang') {
    logoUrl = cjLangLogo;
  } else if (brand === 'sugro') {
    logoUrl = surgoLogo;
  } else if (brand === 'twc' || brand === 'suntory') {
    logoUrl = twcLogo;
  } else if (brand === 'cotswoldFayre' || brand === 'demo' ) {
    logoUrl = cotswoldLogo;
  } else if (brand === 'united') {
    logoUrl = unitedLogo;
  } else if (brand === 'countryRangeGroup') {
    logoUrl = crgLogo;
  } else if (brand === 'deebee') {
    logoUrl = deebeeLogo;
  } else if (brand === 'parfetts') {
    logoUrl = parfettsLogo;
  }
  
  return logoUrl;
};

export default getBrandLogoUrl;
