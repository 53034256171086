import React from 'react';
import styled from 'styled-components';
import { NavLink, NavItem } from 'reactstrap';

const StyledLink = styled(NavLink)`
  color: ${props => props.theme.primary};
  font-size: 16px;
  font-weight: 700;
  font-family: ${props => props.theme.font};
  &:hover,
  &.active.nav-link {
    color: ${props => props.theme.primary};
  }
`;

export const StyledNavLink = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export const StyledNavItem = ({ isActive, children, ...props }) => {
  return (
    <NavItem style={{ background: !isActive ? '#f8f8f8' : '#fff' }} {...props}>
      {children}
    </NavItem>
  );
};
