import React from 'react';
import PropTypes from 'prop-types';

import { formatPerc, formatNumber } from '../../helpers/helpers';
import { getUserSystem } from '../../helpers/util';

class TablePerformance extends React.Component {
  // @TODO
  // - Add total

  shouldComponentUpdate(nextProps) {
    return this.props.measure !== nextProps.measure;
  }

  selectRow(event) {
    this.props.onSelect(event.target.id);
  }

  renderRow = (row, i) => {
    const tdBrandClass = ['u-tac', 'u-lg', 'performance-col'];

    switch (i % 3) {
      case 1:
        tdBrandClass.push('u-bg--brand');
        break;
      case 2:
        tdBrandClass.push('u-bg--brand-d');
        break;
      default:
        tdBrandClass.push('u-bg--brand-dst');
    }
    let prefix = '';
    if (this.props.measure === 'Value' || this.props.measure === 'profit') {
      prefix = '£';
    }

    const formatted = {
      share: formatPerc(row.marketShare),
      change: formatPerc(row.change),
      value_ty: formatNumber(row.salesTY, prefix),
      value_ly: formatNumber(row.salesLY, prefix),
    };

    return (
      <tr key={i}>
        <td
          className={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {row.field && row.field.trim()}
        </td>
        <td
          className={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          <span className="bg-black text-white pl-1 pr-1 pt-1 pb-1">
            {formatted.share}
          </span>
        </td>
        <td
          className={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.value_ty}
        </td>
        <td
          className={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          {formatted.value_ly}
        </td>
        <td
          className={
            row.field && row.field.trim() !== 'TOTAL' ? '' : 'font-weight-bold'
          }>
          <span
            style={{ width: '5rem', maxWidth: '5rem' }}
            className={`bg-plus pl-1 pr-1 pt-1 pb-1 ${
              formatted.change.indexOf('-') >= 0 ? 'neg-change' : 'pos-change'
            } `}>
            {formatted.change}
          </span>
        </td>
        {this.props.isDrill ? (
          <td>
            {row.field && row.field.trim() !== 'TOTAL' ? (
              <button
                className={`btn btn-drill btn-primary ${
                  getUserSystem() === 'unitas'
                    ? 'alchemy-btn--unitas'
                    : 'alchemy-btn--others'
                }`}
                onClick={this.selectRow.bind(this)}
                id={row.field && row.field.trim()}>
                DRILL
              </button>
            ) : (
              ''
            )}
          </td>
        ) : (
          <></>
        )}
      </tr>
    );
  };

  render() {
    const tableClass = ['o-table'];

    if (
      this.props.reportType === 'category' ||
      this.props.reportType === 'subcategory' ||
      this.props.reportType === 'level4' ||
      this.props.reportType === 'level5'
    ) {
      tableClass.push(
        'o-product-performance o-product-performance--category-performance',
      );
    } else if (this.props.reportType === 'supplier') {
      tableClass.push(
        'o-product-performance o-product-performance--supplier-performance',
      );
    } else if (this.props.reportType === 'member') {
      tableClass.push(
        'o-product-performance o-product-performance--wholesaler-performance',
      );
    } else if (this.props.reportType === 'brand') {
      tableClass.push(
        'o-product-performance o-product-performance--brand-performance',
      );
    } else {
      tableClass.push(
        'o-product-performance o-product-performance--product-performance',
      );
    }
    const isRollingDateRange = this.props.isRollingDateRange;
    const titleOverride = this.props.titleOverride;
    const table = (
      <table className="table text-uppercase table-responsive text-center w-100">
        <thead>
          <tr>
            <th scope="col" colSpan={1}>
              {titleOverride.field ?? 'Category'}
            </th>
            <th scope="col" colSpan={1}>
              {titleOverride.marketShare ?? 'Share'}
            </th>
            <th scope="col" colSpan={1}>
              {titleOverride.salesTY ?? (isRollingDateRange ? 'Current' : 'Sales TY')}
            </th>
            <th scope="col" colSpan={1}>
              {titleOverride.salesLY ?? (isRollingDateRange ? 'Previous' : 'Sales LY')}
            </th>
            <th scope="col" colSpan={1}>
              {titleOverride.change ?? 'Change'}
            </th>
            <th scope="col" colSpan={1}></th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.map((r, i) => {
            return this.renderRow(r, i);
          })}
        </tbody>
      </table>
    );

    if (this.props.wrapperClass) {
      return <div className={this.props.wrapperClass}>{table}</div>;
    }

    return table;
  }
}

TablePerformance.defaultProps = {
  id: null,
  measure: '',
  purchase: '',
  data: [],
  titleOverride: {},
  isDrill: true,
  isLoading: false,
  isRollingDateRange: false,
};

TablePerformance.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  measure: PropTypes.string,
  purchase: PropTypes.string,
  data: PropTypes.array,
  data: PropTypes.obj,
  onSelect: PropTypes.func,
  isDrill: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRollingDateRange: PropTypes.bool,
};

export default TablePerformance;
