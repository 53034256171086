import theme from './config/Themes.js';
import { isUAT } from './helpers/util';

export const LogoUrl = require('./assets/images/logos/alchemy.svg');
export const LogoUrlCountryRangeGroup = require('./assets/images/logos/CRG insight logo Final reversed.png');
export const serverURL = isUAT() ? theme.uatApiUrl : theme.apiUrl;

//export const serverURL = "https://api-v2.twcgroup.net";

export const TopNavLinks = [
  {
    label: 'Nam Dash',
    url: `${process.env.APP_ROOT}nam_dash/`,
    brandClass: 't-brand-a',
    currentLinkMatch: '',
    image: require('./assets/images/icons/pie-chart.svg'),
  },
  {
    label: 'Analysis',
    url: `${process.env.APP_ROOT}analysis/`,
    brandClass: 't-brand-b',
    currentLinkMatch: '',
    image: require('./assets/images/icons/bar-graph.svg'),
  },
  {
    label: 'Query',
    url: `${process.env.APP_ROOT}query/`,
    brandClass: 't-brand-c',
    currentLinkMatch: '',
    image: require('./assets/images/icons/education-chart.svg'),
  },
  {
    label: 'Retail',
    url: `${process.env.APP_ROOT}retail/`,
    brandClass: 't-brand-a',
    currentLinkMatch: '',
  },
];

export const DATE_FORMAT_JQUERY = 'dd/mm/yy';
export const DATE_FORMAT = 'dd/mm/yyyy';

const API_ENDPOINTS = {};

/* PRODUCTION */
if (process.env.NODE_ENV !== 'development') {
  API_ENDPOINTS.LoginUrl = `${serverURL}/route.php`;
  API_ENDPOINTS.CSRFUrl = `${serverURL}/login.php`;
  API_ENDPOINTS.SupplierSelectWholesalerUrl = `${serverURL}/select-supplier.php`;
  API_ENDPOINTS.SupplierSelectUrl = `${serverURL}/admin/user/listener/supplier_lookup.php?compact=1`;
  API_ENDPOINTS.SupplierSelectCSRF = `${serverURL}/select-supplier.php`;
  API_ENDPOINTS.SupplierSelectSubmit = `${serverURL}/select-supplier.php`;
  API_ENDPOINTS.DepotFilterUrl = `${serverURL}/`;
  API_ENDPOINTS.DepotSelectUrl = `${serverURL}/`;
  API_ENDPOINTS.DepotUpdate = `${serverURL}/nam_dash/reports/listeners/depots.php`;
  API_ENDPOINTS.namDashUrl = '/test_api/nam_dash.html';
  API_ENDPOINTS.analysisUrl = '/test_api/analysis.html';
  API_ENDPOINTS.psaUrl = '/test_api/psa.html';
  API_ENDPOINTS.categoryPerformanceBase = `${serverURL}/nam_dash/reports/CategoryShare.php`;
  API_ENDPOINTS.categoryPerformanceData = `${serverURL}/nam_dash/reports/listeners/categoryPerformance.php`;
  API_ENDPOINTS.supplierPerformanceBase = `${serverURL}/include/graphOptions/supplierShare.php`;
  API_ENDPOINTS.supplierPerformanceData = `${serverURL}/nam_dash/reports/listeners/supplierPerformance.php`;
  API_ENDPOINTS.productPerformanceBase = `${serverURL}/include/graphOptions/myProducts.php`;
  API_ENDPOINTS.productPerformanceGraph = `${serverURL}/nam_dash/reports/listeners/myProducts.php`;
  API_ENDPOINTS.productPerformanceTable = `${serverURL}/nam_dash/reports/listeners/myProductsTable.php`;
  API_ENDPOINTS.priceSensitivityBase = `${serverURL}/analysis/reports/price-senstitivity.php`;
  API_ENDPOINTS.priceSensitivityData = `${serverURL}/analysis/reports/listeners/price_sens.php`;
  API_ENDPOINTS.heatmapBase = `${serverURL}/analysis/reports/hotspots-n.php`;
  API_ENDPOINTS.heatmapData1 = `${serverURL}/analysis/reports/listeners/heatmap.php`;
  API_ENDPOINTS.heatmapData2 = `${serverURL}/analysis/reports/listeners/heatmap.php`;
  API_ENDPOINTS.depotScorecard = `${serverURL}/analysis/reports/scorecard.php`;
  API_ENDPOINTS.queryBuilder = `${serverURL}/query/index.php`;
  API_ENDPOINTS.queryFilterValues = `${serverURL}/query/listeners/filters.php`;
  API_ENDPOINTS.queryBuilderFilter = `${serverURL}/query/listeners/filters.php`;
  API_ENDPOINTS.queryBuilderCSRF = `${serverURL}/query/last.php`;
  API_ENDPOINTS.queryBuilderSubmitQuery = `${serverURL}/query/last.php`;
  API_ENDPOINTS.myStockBase = `${serverURL}/nam_dash/reports/stock.php`;
  API_ENDPOINTS.myStockData = `${serverURL}/nam_dash/reports/listeners/stock.php`;
  API_ENDPOINTS.boughtBase = `${serverURL}/analysis/reports/bought-not-bought.php`;
  API_ENDPOINTS.boughtData = `${serverURL}/analysis/reports/listeners/bought-not-bought.php`;
  API_ENDPOINTS.gapAnalysisBase = `${serverURL}/analysis/reports/gap-analysis.php`;
  API_ENDPOINTS.gapAnalysisData = `${serverURL}/analysis/reports/listeners/gap-analysis.php`;
  API_ENDPOINTS.customReportGraph = `${serverURL}/nam_dash/reports/CustomReportGraph.php`;
  API_ENDPOINTS.customReport = `${serverURL}/nam_dash/reports/CustomReport.php`;
  // /route.php?c=customReporting/getChartData&qid=A3C34885-9088-12B2-652A-73B453432DD5&CGDescription=&timeframe=MAT&measure=Volume
  // distribution: /route.php?c=customReporting/quarterlyQtyAndCustCount
  API_ENDPOINTS.customReportData = `${serverURL}/route.php`;
  API_ENDPOINTS.customReportDataGraph = API_ENDPOINTS.customReportData;
  API_ENDPOINTS.retailAnalysis = `${serverURL}/route.php?c=retailGapAnalysis/retailGapAnalysisReport`;
}

/* DEVELOPMENT */
if (process.env.NODE_ENV === 'development') {
  API_ENDPOINTS.LoginUrl = `${serverURL}/route.php`;
  API_ENDPOINTS.CSRFUrl = '/test_api/csrf.html';
  API_ENDPOINTS.SupplierSelectWholesalerUrl = '/test_api/login.html';
  API_ENDPOINTS.SupplierSelectUrl = '/test_api/supplier.html?blabla=1';
  API_ENDPOINTS.SupplierSelectCSRF = '/test_api/login.html';
  API_ENDPOINTS.SupplierSelectSubmit = '/test_api/supplierSelect.html';
  API_ENDPOINTS.DepotFilterUrl = '/test_api/depotFilter.html';
  API_ENDPOINTS.DepotSelectUrl = '/test_api/depotSelect.html';
  API_ENDPOINTS.DepotUpdate = '/test_api/depotFilter.html';
  API_ENDPOINTS.namDashUrl = '/test_api/nam_dash.html';
  API_ENDPOINTS.analysisUrl = '/test_api/analysis.html';
  API_ENDPOINTS.psaUrl = '/test_api/psa.html';
  API_ENDPOINTS.categoryPerformanceBase =
    '/test_api/categoryPerformanceBase.html';
  API_ENDPOINTS.categoryPerformanceData =
    '/test_api/categoryPerformanceData.html';
  API_ENDPOINTS.supplierPerformanceBase =
    '/test_api/supplierPerformanceBase.html';
  API_ENDPOINTS.supplierPerformanceData =
    '/test_api/supplierPerformanceData.html';
  API_ENDPOINTS.productPerformanceBase =
    '/test_api/productPerformanceBase.html';
  API_ENDPOINTS.productPerformanceGraph =
    '/test_api/productPerformanceDataGraph.html';
  API_ENDPOINTS.productPerformanceTable =
    '/test_api/productPerformanceDataTable.html';
  API_ENDPOINTS.priceSensitivityBase = '/test_api/priceSensitivityBase.html';
  API_ENDPOINTS.priceSensitivityData = '/test_api/priceSensitivityData.html';
  API_ENDPOINTS.heatmapBase = '/test_api/heatmapBase.html';
  API_ENDPOINTS.heatmapData1 = '/test_api/heatmapData1.html';
  API_ENDPOINTS.heatmapData2 = '/test_api/heatmapData2.html';
  API_ENDPOINTS.depotScorecard = '/test_api/scorecard.html';
  API_ENDPOINTS.queryBuilder = '/test_api/queryBuilder.html';
  API_ENDPOINTS.queryBuilderFilter = '/test_api/queryBuilderFilter.html';
  API_ENDPOINTS.queryBuilderCSRF = '/test_api/login.html';
  API_ENDPOINTS.queryBuilderSubmitQuery = '/test_api/login.html';
  API_ENDPOINTS.myStockBase = '/test_api/myStockBase.html';
  API_ENDPOINTS.myStockData = '/test_api/myStockData.html';
  API_ENDPOINTS.boughtBase = '/test_api/bought_not_bought.html';
  API_ENDPOINTS.boughtData = '/test_api/bought_not_bought_data.html';
  API_ENDPOINTS.gapAnalysisBase = '/test_api/gap_analysis.html';
  API_ENDPOINTS.gapAnalysisData = '/test_api/gap_analysis_data.html';
  API_ENDPOINTS.customReportGraph = '/test_api/CustomReportGraph.html';
  API_ENDPOINTS.customReport = '/test_api/CustomReport.html';
  API_ENDPOINTS.customReportDataGraph = '/test_api/CustomReportGraphData.html';
  API_ENDPOINTS.customReportData = '/test_api/CustomReportData.html';
  API_ENDPOINTS.retailAnalysis = `${serverURL}/route.php?c=retailGapAnalysis/retailGapAnalysisReport`;
}

export { API_ENDPOINTS };
