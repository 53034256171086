import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import Msg from '../components/ToastExportToPDF';
import LaunchIcon from '@material-ui/icons/Launch';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { selectAdvancedBusinessTypes } from '../redux/reducers/filters';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import {
  getUserRoles,
  getUserSystem,
  getUserNamDashMeasures,
  getDrillInfo,
  getOnlyDrillInfo,
  hasSalesInPermissions,
  hasRetailPermissions,
  hasPermission,
  resetDrill,
  hideFor,
  showFor,
  membersCheck,
} from '../../helpers/util';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import CustomCountSelect from '../components/CustomCountSelect';
import ExportIcon from '../../assets/images/export-icon.svg';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import { filter, map } from 'lodash';
import { setAuthUser } from '../redux/reducers/user';
import { motion } from 'framer-motion';
import DataTypes from '../components/filters/DataTypes/DataTypes';
import { pageVariants } from '../../config/pageVariants';
import { fetchAccountNames, setAccountName } from '../redux/reducers/filters';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';
import {
  fetchCategories,
  setTimeFrame,
  setDepartment,
  setCategory,
  setSubCategory,
  setOnLoadParams,
  goToReferal,
  setSupplier,
  setMember,
  setBrand,
  setOwnBrand,
  setLimit,
  setPurchase,
  setMeasure,
  setLikeForLike,
  setPmp,
  setRegion,
  setFascia,
  setLevel4,
  setLevel5,
  exportToPDF,
  exportToCSV,
  exportAllToCSV,
  resetReport,
  loadReport,
  setDepot,
  setDataType,
  setCoreRange,
  setMemberType,
  setRetailType,
  setCommission,
  setBusinessType,
  setGroup,
} from '../redux/reducers/productPerformanceReport';
import { setIsDrill } from '../redux/reducers/performanceReport';
import {
  formatPerc,
  formatNumber,
} from '../../helpers/helpers';
import Select from 'react-select';
import TablePerformance from '../components/TablePerformance';
import GraphProductPerformance from '../components/GraphProductPerformance';
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/Loading';
import { Drill } from '../redux/models/Drill';
import ReportContainer from '../components/ReportContainer';
import AccountNames from '../components/filters/AccountNames/AccountNames';
import { getUserData } from '../../helpers/user';
import Commissions from '../components/filters/Commissions';

ReactModal.setAppElement('.alchemy_app');
const ProductPerformanceReport = ({
  data,
  match,
  hideAll,
  accountName,
  accountNames,
  onAccountNameChange,
  departments,
  selectedDepartments,
  categories,
  selectedCategories,
  selectedSubCategories,
  subCategories,
  suppliers,
  supplier,
  members,
  brands,
  brand,
  ownBrands,
  ownBrand,
  selectedMembers,
  level4s,
  selectedLevel4s,
  level5s,
  selectedLevel5s,
  onDepartmentChange,
  onCategoryChange,
  onExportData,
  onSubCategoryChange,
  timeframes,
  timeframe,
  purchases,
  purchase,
  likeForLikes,
  likeForLike,
  measures,
  measure,
  pmpList,
  pmp,
  region,
  regions,
  onRegionChange,
  fascia,
  fascias,
  onFasciaChange,
  productPerformanceDataGraph,
  productPerformanceDataGraphYKeys,
  productPerformanceDataTable,
  productPerformanceDataTableTotals,
  onTimeFrameChange,
  onPurchaseChange,
  onMeasureChange,
  onLikeForLikeChange,
  unlockRefresh,
  onPmpChange,
  onSupplierChange,
  onBrandChange,
  onMemberChange,
  onBackDrill,
  isLoading,
  isGraphLoaded,
  isTableLoaded,
  onLevel4Change,
  onLevel5Change,
  depots,
  selectedDepots,
  toggleExportMenu,
  onDepotChange,
  reportError,
  onRefreshReport,
  dataType,
  dataTypes,
  onDataTypeChange,
  onCoreRangeChange,
  coreRange,
  coreRanges,
  onMemberTypeChange,
  memberType,
  memberTypes,
  onRetailTypeChange,
  retailType,
  retailTypes,
  commission,
  commissions,
  onCommissionChange,
  onOwnBrandChange,
  businessTypes,
  selectedBusinessType,
  onBusinessTypeChange,
  resolveBusinessTypes,
  resolveDepots,
  resolveCommissionDepartment,
  resolveMeasures,
  history,
  onResetReport,
  onGroupChange,
  groups,
  selectedGroup,
  isRollingDateRange,
  selectAdvancedBusinessTypes,
  setCategoryStructure,
  setDisableCategoryStructure
}) => (
  <motion.div
    key={'PRODUCT-PERFORMANCEREPORT-ID-123415151121221221'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty c-alchemy-toasty--unitas`}
    />
    <div className="sub-menu-border bg-white">
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between pb-3">
            <div className="sub-menu__left">
              <h2 className="text-uppercase">My Product Performance</h2>
              {match.params.subpage.length > 0 &&
                getOnlyDrillInfo(match.params.subpage) && (
                  <button
                    onClick={onBackDrill}
                    className="performance-back-btn mb-2">
                    <i className="fa fa-angle-double-left" aria-hidden="true" />{' '}
                    BACK
                  </button>
                )}
              {hideFor('cjLang', 'countryRangeGroup', 'suntory') && (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Like For Like"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`min-wd-140 small-dropdown c-select-header ${
                      getUserSystem() === 'unitas'
                        ? 'c-select-header-row--unitas'
                        : 'c-select-header-row--others'
                    }`}
                    name="likeForLike"
                    id="likeForLike"
                    value={likeForLike}
                    onChange={onLikeForLikeChange}
                    options={likeForLikes}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                  />
                </div>
              )}
              {hasSalesInPermissions(match.params.subpage) ? (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Sales In"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`wd-100 small-dropdown c-select-header ${
                      getUserSystem() === 'unitas'
                        ? 'c-select-header-row--unitas'
                        : 'c-select-header-row--others'
                    }`}
                    name="purchases"
                    id="purchases"
                    value={purchase}
                    onChange={onPurchaseChange}
                    options={purchases}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                  />
                </div>
              ) : (
                <span className="mr-1 text-black btn-group">SALES OUT</span>
              )}
              <div className="btn-group text-uppercase">
                <span>By:</span>
              </div>
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Measure"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-130 small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name="measure"
                  id="measure"
                  value={measure}
                  onChange={onMeasureChange}
                  options={resolveMeasures(measures, dataType)}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                />
              </div>
              <div className="btn-group text-uppercase">
                <span>For Latest:</span>
              </div>
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Time"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-130 small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name="timeframe"
                  id="timeframe"
                  value={timeframe}
                  onChange={onTimeFrameChange}
                  options={timeframes}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                />
              </div>

              {hideFor('caterforce', 'countryRangeGroup', 'cotswold') && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="PMPList"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 pc-dropdown small-dropdown c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="pmp"
                      id="pmp"
                      value={pmp}
                      onChange={onPmpChange}
                      options={pmpList}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
              {hasRetailPermissions(match.params.subpage) && (
                <>
                  {hideFor("cjlangRetail") && (
                    <DataTypes
                      dataType={dataType}
                      onDataTypeChange={onDataTypeChange}
                      dataTypes={dataTypes}
                    />
                  )}
                  {resolveCommissionDepartment(departments) && (
                    <Commissions
                    commission={commission}
                    onCommissionChange={onCommissionChange}
                    commissions={commissions}
                    />
                  )}
                </>

              )}

              {accountNames && accountNames.length > 0 && (
                <AccountNames
                  value={accountName}
                  options={accountNames}
                  onChange={onAccountNameChange}
                />
              )}
            </div>
            <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div>
                  <button
                    onClick={() => {
                      resetDrill();
                      onResetReport();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    Reset
                    <RotateLeftIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      const win = window.open(
                        history.location.pathname,
                        '_blank',
                      );
                      win.focus();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    New tab
                    <LaunchIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <Dropdown
                    isOpen={data.isExportMenuOpen}
                    toggle={() => toggleExportMenu()}>
                    <DropdownToggle
                      className={`dropddown-toggle-btn alchemy-btn--others mr-2`}>
                      Export <img src={ExportIcon} alt="export" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv');
                        }}>
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv_all');
                        }}>
                        CSV - ALL
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    onClick={onRefreshReport}
                    disabled={data.isRefreshDisabled}
                    className={`btn alchemy-btn--others mr-2`}>
                    Refresh <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {filter(selectedCategories, { Text: 'WINE' }).length > 0 ||
        filter(selectedCategories, { Text: 'SPIRITS' }).length > 0 ? (
          <p className="warning-txt">
            Wines and Spirits are all reported at their lowest unit of sale
          </p>
        ) : null}
      </div>
      <div className="container sub-menu-bottom">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <div className="form-row">
                {departments.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {departments.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        onChangeCallback={ev => onDepartmentChange(ev)}
                        name="department"
                        id="department"
                        isMulti={true}
                        value={selectedDepartments}
                        options={departments}
                        type="Department"
                        types="Departments"
                        placeholder="Department"
                      />
                    ) : (
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="department"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="department"
                        placeholder="Department"
                        value={
                          selectedDepartments.length > 0
                            ? selectedDepartments[0].ID
                            : ''
                        }
                        onChange={opt => onDepartmentChange([opt])}
                        options={departments}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {categories.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {categories.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        onChangeCallback={ev => onCategoryChange(ev)}
                        name="category"
                        id="category"
                        isMulti={true}
                        value={selectedCategories}
                        options={categories}
                        type="Category"
                        types="Categories"
                        placeholder="Category"
                      />
                    ) : (
                      <Select
                        placeholder="Category"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="category"
                        id="category"
                        value={
                          selectedCategories && selectedCategories.length > 0
                            ? selectedCategories[0].ID
                            : ''
                        }
                        onChange={opt => onCategoryChange([opt])}
                        options={categories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}{' '}
                  </div>
                )}
                {subCategories.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {subCategories.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        onChangeCallback={ev => onSubCategoryChange(ev)}
                        name="subcategory"
                        id="subcategory"
                        isMulti={true}
                        value={selectedSubCategories}
                        options={subCategories}
                        type="SubCategory"
                        types="SubCategories"
                        placeholder="SubCategory"
                      />
                    ) : (
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="subcategory"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="subcategory"
                        placeholder="SubCategory"
                        value={
                          selectedSubCategories &&
                          selectedSubCategories.length > 0
                            ? selectedSubCategories[0].ID
                            : ''
                        }
                        onChange={opt => onSubCategoryChange([opt])}
                        options={subCategories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {level4s.length > 1 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {level4s.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        onChangeCallback={ev => onLevel4Change(ev)}
                        name="level4"
                        id="level4"
                        isMulti={true}
                        value={selectedLevel4s}
                        options={level4s}
                        type="Level4"
                        types="Level4s"
                        placeholder="Level4"
                      />
                    ) : (
                      <Select
                        placeholder="Level4"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level4"
                        id="level4"
                        value={
                          selectedLevel4s && selectedLevel4s.length > 0
                            ? selectedLevel4s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel4Change(opt)}
                        options={level4s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {level5s.length > 1 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {level5s.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        onChangeCallback={ev => onLevel5Change(ev)}
                        name="level5"
                        id="level5"
                        isMulti={true}
                        value={selectedLevel5s}
                        options={level5s}
                        type="Level5"
                        types="Level5s"
                        placeholder="Level5"
                      />
                    ) : (
                      <Select
                        placeholder="Level5"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level5"
                        id="level5"
                        value={
                          selectedLevel5s && selectedLevel5s.length > 0
                            ? selectedLevel4s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel5Change(opt)}
                        options={level5s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}

                {/* Group dropdown for demo system, used to filter members on demo system only */}
                {
                  // prettier-ignore
                  groups &&
                  groups.length > 0 &&
                  (showFor("caterforce", "countryRangeGroup")) && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onGroupChange(ev)}
                        name="group"
                        id="group"
                        isMulti={true}
                        value={selectedGroup}
                        options={groups}
                        type={"Group Supplier"}
                        types={"Group Suppliers"}
                        placeholder={"Group Supplier"}
                      />
                    </div>
                  )
                }
                {/* Member dropdown for standard systems, hidden for demo system */}

                {hideFor('demo', 'deebee') &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 0 &&
                  members &&
                  membersCheck(members) &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name={getUserSystem() == 'suntory' ? "group" : "member"}
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                {getUserSystem() == 'demo' &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 1 &&
                  selectedGroup &&
                  selectedGroup.length > 0 &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name={getUserSystem() == 'suntory' ? "group" : "member"}
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}
                {!hasPermission('role_view_caterforce_hide_category_totals') &&
                  suppliers &&
                  suppliers.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="supplier"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="supplier"
                        placeholder="Supplier"
                        value={supplier}
                        onChange={onSupplierChange}
                        options={suppliers}
                        valueKey="SupplierName"
                        labelKey="SupplierName"
                      />
                    </div>
                  )}
                {brands && brands.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Brand"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="brand"
                      id="brand"
                      value={brand.Brand}
                      onChange={onBrandChange}
                      options={brands}
                      valueKey="Brand"
                      labelKey="Brand"
                    />
                  </div>
                )}
                {regions && regions.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Region"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="region"
                      id="region"
                      value={region}
                      onChange={onRegionChange}
                      options={regions}
                      valueKey="value"
                      labelKey="value"
                    />
                  </div>
                )}
                {fascias && fascias.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Fascia"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="fascia"
                      id="fascia"
                      value={fascia}
                      onChange={onFasciaChange}
                      options={fascias}
                      valueKey="value"
                      labelKey="value"
                    />
                  </div>
                )}
                {getUserSystem() === 'caterforce' &&
                  brands &&
                  brands.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Own Brand"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="ownbrand"
                        id="ownbrand"
                        value={ownBrand}
                        onChange={onOwnBrandChange}
                        options={ownBrands}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                {businessTypes && businessTypes.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {businessTypes.length > 1 ? (
                      <CustomCountSelect
                        className={`c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onBusinessTypeChange(ev)}
                        name="businessType"
                        id="businessType"
                        isMulti={true}
                        value={selectedBusinessType}
                        options={resolveBusinessTypes(businessTypes)}
                        type="Business Type"
                        types="Business Types"
                        placeholder="Business Types"
                      />
                    ) : (
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="businessType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="businessType"
                        placeholder="Business Type"
                        value={
                          businessTypes.length > 0 ? businessTypes[0].ID : ''
                        }
                        onChange={opt => onBusinessTypeChange([opt])}
                        options={businessTypes}
                        valueKey="BusinessName"
                        labelKey="BusinessName"
                      />
                    )}
                  </div>
                )}
                {(getUserSystem() === 'unitas') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Depot"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="depot"
                        id="depot"
                        multi
                        value={selectedDepots}
                        onChange={onDepotChange}
                        options={depots}
                        valueKey="DepotName"
                        labelKey="DepotName"
                      />
                    </div>
                  )}
                  {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                      className={`c-select-header c-select-header-row--others`}
                      onChangeCallback={ev => onDepotChange(ev)}
                      name="depot"
                      id="depot"
                      isMulti={true}
                      placeholder="Depot"
                      value={selectedDepots}
                      options={resolveDepots(depots)}
                      type="Depot"
                      types="Depots"
                    />
                  </div>
                  )}
                <BusinessTypes unlockRefresh={unlockRefresh} />
                {getUserSystem() == 'united' || getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="CoreRange"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="CoreRange"
                        placeholder="Core Range"
                        value={coreRange}
                        onChange={onCoreRangeChange}
                        options={coreRanges}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="MemberType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="MemberType"
                        placeholder="Member Type"
                        value={memberType}
                        onChange={onMemberTypeChange}
                        options={memberTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {dataType && dataType.Text === 'Retail' && hasPermission(
                      `role_view_${getUserSystem().toLowerCase()}_independent_data`,
                    ) && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="RetailType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="RetailType"
                        placeholder="Retail Type"
                        value={retailType}
                        onChange={onRetailTypeChange}
                        options={retailTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReportContainer>
      <div className="container pt-3">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="nam-dash nam-dash-table">
              <div>{!isTableLoaded && <Loading />}</div>
              {reportError.length === 0 &&
              isTableLoaded &&
              !isLoading &&
              !hideAll ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}>
                  <TablePerformance
                    id="performance_table"
                    data={productPerformanceDataTable}
                    isLoading={isLoading}
                    purchase={purchase}
                    measure={measure}
                    isDrill={false}
                    isRollingDateRange={isRollingDateRange}
                  />
                  <table
                    className="o-product-performance o-product-performance--product-performance"
                    style={{
                      float: 'right',
                      fontWeight: 'bold',
                      marginRight: '1.15rem',
                    }}>
                    <tbody>
                      <tr>
                        <td></td>
                        <td className="u-tac">SUB TOTAL</td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.subTotal
                            .totalTY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.subTotal
                                  .totalTY ||
                                  productPerformanceDataTableTotals.subTotal[0]
                                    .totalTY,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.subTotal
                            .totalLY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.subTotal
                                  .totalLY ||
                                  productPerformanceDataTableTotals.subTotal[0]
                                    .totalLY,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.subTotal.change ===
                          0
                            ? '0%'
                            : formatPerc(
                                productPerformanceDataTableTotals.subTotal
                                  .change ||
                                  productPerformanceDataTableTotals.subTotal[0]
                                    .change,
                              )}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="u-tac">OTHER</td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.other.totalTY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.other
                                  .totalTY ||
                                  (productPerformanceDataTableTotals.other[0] &&
                                    productPerformanceDataTableTotals.other[0]
                                      .totalTY) ||
                                  0,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.other.totalLY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.other
                                  .totalLY ||
                                  (productPerformanceDataTableTotals.other[0] &&
                                    productPerformanceDataTableTotals.other[0]
                                      .totalLY) ||
                                  0,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.other.change === 0
                            ? '0%'
                            : formatPerc(
                                productPerformanceDataTableTotals.other
                                  .change ||
                                  (productPerformanceDataTableTotals.other[0] &&
                                    productPerformanceDataTableTotals.other[0]
                                      .change) ||
                                  0,
                              )}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="u-tac">GRAND TOTAL</td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.grandTotal
                            .totalTY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.grandTotal
                                  .totalTY ||
                                  productPerformanceDataTableTotals
                                    .grandTotal[0].totalTY,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.grandTotal
                            .totalLY === 0
                            ? '£0'
                            : formatNumber(
                                productPerformanceDataTableTotals.grandTotal
                                  .totalLY ||
                                  productPerformanceDataTableTotals
                                    .grandTotal[0].totalLY,
                                measure === 'Value' ? '£' : '',
                              )}
                        </td>
                        <td className="u-tac">
                          {productPerformanceDataTableTotals.grandTotal
                            .change === 0
                            ? '0%'
                            : formatPerc(
                                productPerformanceDataTableTotals.grandTotal
                                  .change ||
                                  productPerformanceDataTableTotals
                                    .grandTotal[0].change,
                              )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </motion.div>
              ) : (
                ''
              )}{' '}
              {reportError.length > 0 && <p>{reportError}</p>}
            </div>
          </div>
          <div className="col-12 col-md-5 nam-dash-graph">
            <div
              style={{
                position: 'absolute',
                display: 'table',
                margin: '0 auto',
                width: '100%',
                height: '100%',
                textAlign: 'center',
              }}>
              {!isGraphLoaded && <Loading />}
              {isGraphLoaded && !isLoading && hideAll === false ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}>
                  <GraphProductPerformance
                    data={productPerformanceDataGraph}
                    isLoading={isLoading}
                    keys={productPerformanceDataGraphYKeys}
                  />
                </motion.div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </ReportContainer>
  </motion.div>
);
function mapStateToProps(state) {
  const data = {
    departments: state.productPerformanceReport.departments,
    selectedDepartments: state.productPerformanceReport.selectedDepartments,
    categories: state.productPerformanceReport.categories,
    hideAll: state.productPerformanceReport.hideAll,
    subCategories: state.productPerformanceReport.subCategories,
    selectedCategories: state.productPerformanceReport.selectedCategories,
    selectedSubCategories: state.productPerformanceReport.selectedSubCategories,
    suppliers: state.productPerformanceReport.suppliers,
    supplier: state.productPerformanceReport.supplier,
    accountNames: state.filters.accountNames,
    accountName: state.filters.accountName,
    members: state.productPerformanceReport.members,
    brands: state.productPerformanceReport.brands,
    brand: state.productPerformanceReport.brand,
    region: state.productPerformanceReport.region,
    regions: state.productPerformanceReport.regions,
    fascia: state.productPerformanceReport.fascia,
    fascias: state.productPerformanceReport.fascias,
    ownBrands: state.productPerformanceReport.ownBrands,
    ownBrand: state.productPerformanceReport.ownBrand,
    selectedMembers: state.productPerformanceReport.selectedMembers,
    timeframes: state.productPerformanceReport.timeframes,
    timeframe: state.productPerformanceReport.timeframe,
    purchases: state.productPerformanceReport.purchases,
    purchase: state.productPerformanceReport.purchase,
    likeForLike: state.productPerformanceReport.likeForLike,
    likeForLikes: state.productPerformanceReport.likeForLikes,
    measures: state.productPerformanceReport.measures,
    measure: state.productPerformanceReport.measure,
    pmpList: state.productPerformanceReport.pmpList,
    pmp: state.productPerformanceReport.pmp,
    level4s: state.productPerformanceReport.level4s,
    selectedLevel4s: state.productPerformanceReport.selectedLevel4s,
    level5s: state.productPerformanceReport.level5s,
    selectedLevel5s: state.productPerformanceReport.selectedLevel5s,
    productPerformanceDataGraph:
      state.productPerformanceReport.productPerformanceDataGraph,
    productPerformanceDataGraphYKeys:
      state.productPerformanceReport.productPerformanceDataGraphYKeys,
    productPerformanceDataTable:
      state.productPerformanceReport.productPerformanceDataTable,
    productPerformanceDataTableTotals:
      state.productPerformanceReport.productPerformanceDataTableTotals,
    reportType: state.productPerformanceReport.reportType,
    isLoading: state.productPerformanceReport.isLoading,
    isExporting: state.productPerformanceReport.isExporting,
    isExported: state.productPerformanceReport.isExported,
    pdfURL: state.productPerformanceReport.pdfURL,
    isLoaded: state.productPerformanceReport.isLoaded,
    isTableLoaded: state.productPerformanceReport.isTableLoaded,
    isGraphLoaded: state.productPerformanceReport.isGraphLoaded,
    limit: state.productPerformanceReport.limit,
    depots: state.productPerformanceReport.depots,
    selectedDepots: state.productPerformanceReport.selectedDepots,
    authUser: state.user.authUser,
    reportError: state.productPerformanceReport.reportError,
    dataType: state.productPerformanceReport.dataType,
    dataTypes: state.productPerformanceReport.dataTypes,
    coreRange: state.productPerformanceReport.coreRange,
    coreRanges: state.productPerformanceReport.coreRanges,
    memberType: state.productPerformanceReport.memberType,
    memberTypes: state.productPerformanceReport.memberTypes,
    retailType: state.productPerformanceReport.retailType,
    retailTypes: state.productPerformanceReport.retailTypes,
    commission: state.productPerformanceReport.commission,
    commissions: state.productPerformanceReport.commissions,
    businessTypes: state.productPerformanceReport.businessTypes,
    selectedBusinessType: state.productPerformanceReport.selectedBusinessType,
    groups: state.productPerformanceReport.groups,
    selectedGroup: state.productPerformanceReport.selectedGroup,
    isRollingDateRange: state.productPerformanceReport.isRollingDateRange,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    setTimeFrame,
    setPurchase,
    setMeasure,
    setLikeForLike,
    setAccountName,
    fetchAccountNames,
    setPmp,
    setRegion,
    setFascia,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setSupplier,
    setOnLoadParams,
    exportToPDF,
    exportToCSV,
    exportAllToCSV,
    setBrand,
    setOwnBrand,
    setMember,
    setLevel5,
    setLimit,
    goToReferal,
    setIsDrill,
    resetReport,
    setAuthUser,
    loadReport,
    setDepot,
    setDataType,
    setCoreRange,
    setMemberType,
    setRetailType,
    setCommission,
    setBusinessType,
    setGroup,
    selectAdvancedBusinessTypes,
    setCategoryStructure,
    setDisableCategoryStructure
  }),
  lifecycle({
    componentDidMount() {
      this.props.setLikeForLike('like_for_like');
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(this.props.match.params.subpage) || {};
      const isSkip = drillObj.skip ? true : false;
      this.props.resetReport();
      if (getUserSystem() == "cjlangRetail") {
        this.props.setDataType({ ID: 1, Text: 'Retail' });
      }
      this.props.fetchCategories(isSkip);
      this.props.fetchAccountNames();
      if (this.props.authUser.userId.length === 0) {
        this.props.setAuthUser();
      }
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.setLikeForLike('like_for_like');
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const drillObj = getDrillInfo(this.props.match.params.subpage) || {};
        const isSkip = drillObj.skip ? true : false;
        this.props.fetchCategories(isSkip);
        this.props.loadReport();
      }
      if (nextProps.isLoaded !== this.props.isLoaded && this.props.isLoaded) {
        const drillObj = getDrillInfo(this.props.match.params.subpage) || {};

        this.props.setSupplier(drillObj.supplier);
        this.props.loadReport();
      }
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      let drillObj = new Drill();
      let isSkip = false;
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        drillObj = getDrillInfo(this.props.match.params.subpage) || new Drill();
        isSkip = drillObj.skip ? true : false;
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'Product Performance',
            });
          }, 1000);
        }, 1000);
      }
      if (nextProps.match.params.subpage !== this.props.match.params.subpage) {
        this.props.resetReport();
        this.props.fetchCategories();
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} type="performance" />, {
          autoClose: false,
        });
      }

      if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading &&
        !this.props.isLoaded
      ) {
        let reportType = '';
        if (this.props.match.params.subpage === 'category_performance') {
          reportType = 'category';
        } else if (this.props.match.params.subpage === 'supplier_performance') {
          reportType = 'supplier';
        } else if (this.props.match.params.subpage === 'brand_performance') {
          reportType = 'brand';
        } else if (
          this.props.match.params.subpage === 'wholesaler_performance'
        ) {
          reportType = 'member';
        }
        let isDepartmentSelected = false;
        let isCategorySelected = false;
        let isSubCategorySelected = false;
        let isLevel4Selected = false;
        let isLevel5Selected = false;
        let isBrandSelected = false;

        const departments =
          drillObj.departments && drillObj.departments.length > 0
            ? drillObj.departments.split(',')
            : [];

        if (
          (departments && drillObj.skip !== 'department') ||
          (drillObj.skip === 'department' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isDepartmentSelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'category';
          }
        }
        const categories =
          drillObj.categories && drillObj.categories.length > 0
            ? drillObj.categories.split(',')
            : [];
        if (
          (categories && drillObj.skip !== 'category') ||
          (drillObj.skip === 'category' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isCategorySelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'subcategory';
          }
        }
        const subCategories =
          drillObj.subCategories && drillObj.subCategories.length > 0
            ? drillObj.subCategories.split(',')
            : [];
        if (
          (subCategories && drillObj.skip !== 'subcategory') ||
          (drillObj.skip === 'subcategory' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isSubCategorySelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'level4';
          }
        }
        const level4s =
          drillObj.level4s && drillObj.level4s.length > 0
            ? drillObj.level4s.split(',')
            : [];
        if (
          (level4s && drillObj.skip !== 'level4') ||
          (drillObj.skip === 'level4' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isLevel4Selected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'level5';
          }
        }
        const level5s =
          drillObj.level5s && drillObj.level5s.length > 0
            ? drillObj.level5s.split(',')
            : [];
        if (
          (level5s && drillObj.skip !== 'level5') ||
          (drillObj.skip === 'level5' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isLevel5Selected = true;
        }
        const brand = drillObj.brand || '';
        if (
          (brand && drillObj.skip !== 'brand') ||
          (drillObj.skip === 'brand' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isBrandSelected = true;
        }
        let passedMembers = [];
        if (
          (this.props.members &&
            this.props.members.length > 0 &&
            drillObj.skip !== 'members') ||
          (drillObj.skip === 'members' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          if (drillObj.members !== null) {
            let members = [];
            let membs = drillObj.members;
            _.each(membs, memb => {
              const membb = _.filter(this.props.members, mem => {
                return (
                  mem.WholesalerNo === memb.WholesalerNo ||
                  memb === mem.WholesalerNo
                );
              })[0];
              members.push(membb);
            });
            passedMembers = members;
          }
        }
        let passedDepots = [];

        if (passedMembers.length === 0) {
          if (
            (this.props.depots &&
              this.props.depots.length > 0 &&
              drillObj.skip !== 'depots') ||
            (drillObj.skip === 'depots' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.depots !== null) {
              let depots = [];
              let depts =
                drillObj.depots && drillObj.depots.indexOf(',') >= 0
                  ? drillObj.depots.split(',')
                  : !drillObj.depots
                  ? []
                  : drillObj.depots;
              _.each(depts, dept => {
                _.each(this.props.depots, dpt => {
                  if (dpt.DepotName === dept) {
                    depots.push(dpt);
                  }
                });
              });
              passedDepots = depots;
            }
          }
        } else {
          passedDepots =
            drillObj.depots && drillObj.depots.indexOf(',') >= 0
              ? drillObj.depots.split(',')
              : !drillObj.depots
              ? []
              : drillObj.depots;
        }

        let passedSupplier = '';
        if (
          (this.props.suppliers &&
            drillObj.skip !== 'supplier' &&
            this.props.suppliers.length > 0) ||
          (drillObj.skip === 'supplier' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          if (drillObj.supplier !== null) {
            passedSupplier = drillObj.supplier;
          }
        }
        let ownBrand;
        if (drillObj.ownBrand && drillObj.ownBrand.Text) {
          ownBrand = drillObj.ownBrand.Text;
        }
        const measure = drillObj.measure;
        const type = drillObj.type;
        const timeframe = drillObj.timeframe;
        const accountName = drillObj.accountName;
        const likeForLike = drillObj.likeForLike;
        const selectedAdvancedBusinessTypes = drillObj.selectedAdvancedBusinessTypes;
        if (selectedAdvancedBusinessTypes?.length > 0) {
          selectedAdvancedBusinessTypes.forEach(businessType => {
            if (businessType.value.length > 0) {
              this.props.selectAdvancedBusinessTypes(businessType);
            }
          })
        }
        
        let passedBusinessType = '';
        if (
          drillObj.skip !== 'business_type' ||
          (drillObj.skip === 'business_type' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          if (drillObj.businessType !== null) {
            passedBusinessType = drillObj.businessType;
          }
        }

        let passedGroups = [];
        if (drillObj.groups) {
          passedGroups = drillObj.groups;
        }
        let dataType = '';
        if (drillObj.dataType !== null) {
          dataType = drillObj.dataType;
        }
        let commission = '';
        if (drillObj.commission !== null) {
          commission = drillObj.commission;
        }
        if (isLevel5Selected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              level4s,
              level5s,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              accountName,
              likeForLike,
              timeframe,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              dataType,
              commission,
              groups: passedGroups,
            },
            reportType,
          );
        } else if (isLevel4Selected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              level4s,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              accountName,
              likeForLike,
              timeframe,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              groups: passedGroups,
              dataType,
              commission
            },
            reportType,
          );
        } else if (isSubCategorySelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              likeForLike,
              accountName,
              timeframe,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              commission,
              dataType,
              groups: passedGroups,
            },
            reportType,
          );
        } else if (isCategorySelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              likeForLike,
              accountName,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              dataType,
              commission,
              groups: passedGroups,
            },
            reportType,
          );
        } else if (isDepartmentSelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              accountName,
              likeForLike,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              dataType,
              commission,
              groups: passedGroups,
            },
            reportType,
          );
        } else if (isBrandSelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              accountName,
              likeForLike,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              dataType,
              commission,
              groups: passedGroups,
            },
            reportType,
          );
        } else if (drillObj.skip) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              accountName,
              likeForLike,
              type,
              depots: passedDepots,
              businessType: passedBusinessType,
              ownBrand,
              dataType,
              commission,
              groups: passedGroups,
            },
            reportType,
          );
        }
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onBackDrill: props => value => {
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(props.match.params.subpage) || new Drill();
      if (drillObj.ref !== undefined && drillObj.ref !== null) {
        props.setIsDrill(true);
        props.goToReferal(drillObj.ref, drillObj.to);
        return;
      }
      switch (props.reportType) {
        case 'department':
          props.setDepartment([]);
          break;
        case 'subcategory':
          props.setCategory([]);
          break;
        case 'level4':
          props.setSubCategory([]);
          break;
        case 'level5':
          props.setLevel4([]);
          break;
        case 'supplier':
          if (props.level5.ID !== undefined) {
            props.setLevel5([]);
          } else if (props.level4.ID !== undefined) {
            props.setLevel4([]);
          } else if (props.subCategory.ID !== undefined) {
            props.setSubCategory([]);
          } else if (props.category.ID !== undefined) {
            props.setCategory([]);
          }
          break;
        case 'member':
          if (props.level5.ID !== undefined) {
            props.setLevel5([]);
          } else if (props.level4.ID !== undefined) {
            props.setLevel4([]);
          } else if (props.subCategory.ID !== undefined) {
            props.setSubCategory([]);
          } else if (props.category.ID !== undefined) {
            props.setCategory([]);
          }
          break;
      }
    },
    onAccountNameChange:
      ({ data, handleData, setAccountName }) =>
      opt => {
        if (opt) {
          setAccountName(opt);
        } else {
          setAccountName([]);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRegionChange:
      ({ data, handleData, setRegion }) =>
      selectedOption => {
        if (selectedOption === null) {
          setRegion('');
        } else {
          setRegion(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onFasciaChange:
      ({ data, handleData, setFascia }) =>
      selectedOption => {
        if (selectedOption === null) {
          setFascia('');
        } else {
          setFascia(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onTimeFrameChange:
      ({ data, handleData, setTimeFrame }) =>
      opt => {
        if (opt) {
          setTimeFrame(opt.ID);
        } else {
          setTimeFrame('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMeasureChange:
      ({ data, handleData, setMeasure }) =>
      opt => {
        if (opt) {
          setMeasure(opt.ID);
        } else {
          setMeasure('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLikeForLikeChange:
      ({ data, handleData, setLikeForLike }) =>
      opt => {
        if (opt) {
          setLikeForLike(opt.ID);
        } else {
          setLikeForLike('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    unlockRefresh:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment, selectedDepartments, departments }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedDepartments.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setDepartment(tempArray);
          } else {
            setDepartment(departments);
          }
        } else if (
          filter(selectedDepartments, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setDepartment([]);
        } else {
          setDepartment(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
    },
    resolveCommissionDepartment: () => (departments) => {
      let commissionDepartment = _.filter(departments, department => {
        if(department.Text == "COMMISSION"){
         return department;
        }
       });

       return commissionDepartment.length > 0;
    },
    resolveMeasures: () => (measures, dataType) => {
      return measures.filter(measure =>
        getUserNamDashMeasures(dataType.Text).includes(measure.ID.toLowerCase()));
    },
    resolveBusinessTypes: () => businessTypes => {
      businessTypes.forEach((type, index) => {
        type.Text = type.BusinessName;
        type.ID = index;
      });
      return businessTypes;
    },
    resolveDepots: () => depots => {
      depots.forEach((depot, index) => {
        depot.Text = depot.DepotName;
        depot.ID = index;
      });
      return depots;
    },
    onBusinessTypeChange:
      ({
        data,
        handleData,
        setBusinessType,
        selectedBusinessType,
        businessTypes,
      }) =>
      selectedOptions => {
        // const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        //Checks if select all is selected, if it has been unselected then empty the array
        // if (isSelectAll) {
        //   setBusinessType(businessTypes);
        // } else if (
        //   filter(selectedBusinessType, { ID: 0 }).length > 0 &&
        //   filter(selectedOptions, { ID: 0 }).length === 0
        // ) {
        //   setBusinessType([]);
        // } else {
        //   setBusinessType(selectedOptions);
        // }

        setBusinessType(selectedOptions);
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory, selectedCategories, categories }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setCategory(tempArray);
          } else {
            setCategory(categories);
          }
        } else if (
          filter(selectedCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setCategory([]);
        } else {
          setCategory(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({
        data,
        handleData,
        setSubCategory,
        selectedSubCategories,
        subCategories,
      }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedSubCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setSubCategory(tempArray);
          } else {
            setSubCategory(subCategories);
          }
        } else if (
          filter(selectedSubCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setSubCategory([]);
        } else {
          setSubCategory(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4, selectedLevel4s, level4s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel4s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel4(tempArray);
          } else {
            setLevel4(level4s);
          }
        } else if (
          filter(selectedLevel4s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setLevel4([]);
        } else {
          setLevel4(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5, selectedLevel5s, level5s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel5s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel5(tempArray);
          } else {
            setLevel5(level5s);
          }
        } else if (
          filter(selectedLevel5s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setLevel5([]);
        } else {
          setLevel5(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onSupplierChange:
      ({ data, handleData, setSupplier }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSupplier(0);
        } else {
          setSupplier(selectedOption.SupplierName);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand(0);
        } else {
          setBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onOwnBrandChange:
      ({ data, handleData, setOwnBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setOwnBrand('');
        } else {
          setOwnBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, setCategoryStructure, setDisableCategoryStructure }) =>
      selectedOption => {
        if (members.length > 2) {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
        if (getUserSystem() == "caterforce") {
          if (selectedOption?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }
      },
    onExportData: props => type => {
      if (type === 'csv') {
        // exportTableToCSV($('#performance_table'), `${getRandomNumber('export_')}.csv`);
        props.exportToCSV();
      }
      if (type === 'csv_all') {
        props.exportAllToCSV();
      }
      if (type === 'pdf') {
        let clonedObj = $('.nam-dash-table').clone(true);
        clonedObj.find('.drillTD').replaceWith('<span/>');
        var srctext = $('.nam-dash-graph').get(0).outerHTML;
        var re = /<svg ([^>]*)>*/;
        var newtext = srctext.replace(
          re,
          `<svg viewBox="0 0 600 600" height="600">`,
        );
        let fields = ``;
        fields +=
          props.selectedDepartments && props.selectedDepartments.length > 0
            ? `DEPARTMENT: ` +
              map(props.selectedDepartments, 'Text').join(',').toUpperCase() +
              `<br/>`
            : '';
        fields +=
          props.selectedCategories && props.selectedCategories.length > 0
            ? `CATEGORY: ` +
              map(props.selectedCategories, 'Text').join(',').toUpperCase() +
              `<br/>`
            : '';
        fields +=
          props.selectedSubCategories && props.selectedSubCategories.length > 0
            ? `SUB CATEGORY: ` +
              map(props.selectedSubCategories, 'Text').join(',').toUpperCase() +
              `<br/>`
            : '';
        fields +=
          props.selectedLevel4s && props.selectedLevel4s.length > 0
            ? `LEVEL4: ` +
              map(props.selectedLevel4s, 'Text').join(',').toUpperCase() +
              `<br/>`
            : '';
        fields +=
          props.selectedMembers.length > 0
            ? `MEMBERS: ` +
              props.selectedMembers.map(member => member.ActualName) +
              `<br/>`
            : '';
        fields += props.supplier.SupplierName
          ? `SUPPLIER: ` + props.supplier.SupplierName.toUpperCase() + `<br/>`
          : '';
        fields +=
          `LIKEFORLIKE: ` + props.likeForLike
            ? props.likeForLike.toUpperCase()
            : '' +
              `TIMEFRAME: ` +
              props.timeframe.toUpperCase() +
              `<br/>MEASURE: ` +
              props.measure.toUpperCase() +
              `<br/>PURCHASE: ` +
              props.purchase.toUpperCase();
        let selectedPmp = props.pmpList.find(pmp => pmp.ID === props.pmp);
        fields += `<br/>${selectedPmp.Text.toUpperCase()}: ${selectedPmp.ID}`;
        if (props.selectedDepots?.length > 0) {
          fields +=
            `<br/>DEPOT: ` + props.selectedDepots.map(depot => depot.DepotName);
        }
        let htmlString = `<html>
                        <head>
                          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                          <link rel="stylesheet" href="https://unitas-api.twcgroup.net/style.min.css"/>
                          <style>
                          .text-white {
                            color: #000 !important;
                          }
                          .table-responsive {
                            color: #000;
                            border: 1px solid #BABABA;
                          }
                          .table-responsive thead tr {
                            color: #000;
                            border: 1px solid #BABABA;
                          }
                          .table-responsive tbody tr {
                            color: #000;
                            border: 1px solid #BABABA;
                          }
                          .bg-dark {
                            color: #000;
                            background-color: #fff !important;
                            padding: 0px !important;
                          }
                          .btn-drill {
                            display: none;
                          }
                          .black-circle {
                            top: 85px;
                            right: 67px;
                            content: '';
                            border-radius: 100%;
                            width: 69%;
                            color: #000;
                            background: #fff;
                            display: inline-block;
                            position: absolute;
                            height: 356px;
                            box-shadow: 0px 20px 30px 50px #0000004d;
                            z-index: -1;
                          }
                          </style>
                        </head>
                        <body style="margin:0px;padding:0px;">
                            <h1 style="float:left;font-weight: 100;margin-left:10px;font-size:24px;">PRODUCT PERFORMANCE REPORT</h1><h4>${$(
                              '.c-paragraph--seconday',
                            ).text()}</h4>
                            <p style="position:absolute;left:10px;top:20px;">${moment().format(
                              'MM/DD/YYYY',
                            )}</p>
                            <div style="float:right;-webkit-margin-before: 5px;-webkit-margin-after: 0.67em;-webkit-margin-start: 0px;-webkit-margin-end: 10px;">
                            <p>${fields}</p></div>
                            <div style="height:400px;text-align:left;left:-20px;width:100%;position:relative;top:30px">
                            ${newtext
                              .replace('font-size: 14px', 'font-size: 9px')
                              .replace('font-size: 15px', 'font-size: 10px')
                              .replace(
                                'font-weight: 800',
                                'font-weight: 400',
                              )}</div>
                            <div style="position:relative;top:500px;color:#000">${clonedObj
                              .html()
                              .replace('.78125em', '.48125em')}</div>
                        </body>
                    </html>`;
        props.exportToPDF(htmlString);
      }
    },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      selectedOption => {
        setDepot(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCoreRangeChange:
      ({ data, handleData, setCoreRange }) =>
      opt => {
        if (opt) {
          setCoreRange(opt);
        } else {
          setCoreRange('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberTypeChange:
      ({ data, handleData, setMemberType }) =>
      opt => {
        if (opt) {
          setMemberType(opt);
        } else {
          setMemberType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRetailTypeChange:
      ({ data, handleData, setRetailType }) =>
      opt => {
        if (opt) {
          setRetailType(opt);
        } else {
          setRetailType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDataTypeChange:
      ({ setDataType, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDataType('');
        } else {
          setDataType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCommissionChange:
      ({ setCommission, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCommission('');
        } else {
          setCommission(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onResetReport: props => () => {
      props.setLikeForLike('like_for_like');
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(props.match.params.subpage) || {};
      const isSkip = drillObj.skip ? true : false;
      props.resetReport();
      props.fetchCategories(isSkip);
      if (props.authUser.userId.length === 0) {
        props.setAuthUser();
      }
    },
  }),
)(ProductPerformanceReport);
