export default {
    NameOfCompany: '',
    CompanyTradingName: '',
    StreetAddress: '',
    Town: '',
    City: '',
    County: '',
    Country: '',
    PostCode: '',
    PhoneNo: '',
    Email: '',
    Website: '',
    Lat: '',
    Lon: '',
    ID: '',
    Updated: '',
    Grocery: 0,
    Impulse: 0,
    ['Non Foods']: 0,
    Licensed: 0,
    Tobacco: 0,
    Frozen: 0,
    Chilled: 0,
    Catering: 0,
    ['On Trade']: 0,
    Description: '',
    Catalogue: ''
  }
  
