import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import Dropship from '../models/Dropship';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';
import { stringify } from 'qs';

const SET_IS_DROPSHIP_CREATED = 'pfpDropship/IS_DROPSHIP_CREATED';
const SET_IS_DROPSHIP_DELETED = 'pfpDropship/IS_DROPSHIP_DELETED';
const SET_DROPSHIP = 'pfpDropship/SET_DROPSHIP';
const FETCH_DROPSHIPS = 'pfpDropship/FETCH_DROPSHIPS';
const SET_LOADING = 'pfpDropship/SET_LOADING';
const SET_DEPARTMENT = 'pfpDropship/SET_DEPARTMENT';
const SET_CATEGORY = 'pfpDropship/SET_CATEGORY';
const FETCH_CATEGORIES = 'pfpDropship/FETCH_CATEGORIES';
const SET_FORM = 'pfpDropship/SET_FORM';
const RESET_ME = 'pfpDropship/RESET_ME';
const SET_DISTRIBUTION_ENGLAND = 'pfpDropship/SET_DISTRIBUTION_ENGLAND';
const SET_DISTRIBUTION_SCOTLAND = 'pfpDropship/SET_DISTRIBUTION_SCOTLAND';
const SET_DISTRIBUTION_WALES = 'pfpDropship/SET_DISTRIBUTION_WALES';
const SET_DISTRIBUTION_NI = 'pfpDropship/SET_DISTRIBUTION_NI';
const SET_RETAIL = 'pfpDropship/SET_RETAIL';
const SET_WHOLESALE = 'pfpDropship/SET_WHOLESALE';
const SET_FOODSERVICES = 'pfpDropship/SET_FOODSERVICES';
const SET_ONTRADE = 'pfpDropship/SET_ONTRADE';
const SET_NEWFLAG = 'pfpDropship/SET_NEWFLAG';

const baseURL = 'https://api.wsale.co.uk';

const initialState = {
  isLoading: false,
  department: '',
  departments: [],
  category: '',
  categories: [],
  isDropshipCreated: false,
  isDropshipDeleted: false,
  dropships: [],
  dropship: Dropship,
  dropshipForm: { ...Dropship },
};

const setDropshipAction = createAction(SET_DROPSHIP);
const fetchDropshipsAction = createAction(FETCH_DROPSHIPS);
const setIsDropshipCreatedAction = createAction(SET_IS_DROPSHIP_CREATED);
const setIsDropshipDeletedAction = createAction(SET_IS_DROPSHIP_DELETED);
const setLoadingAction = createAction(SET_LOADING);
const setDistributionEnglandAction = createAction(SET_DISTRIBUTION_ENGLAND);
const setDistributionScotlandAction = createAction(SET_DISTRIBUTION_SCOTLAND);
const setDistributionWalesAction = createAction(SET_DISTRIBUTION_WALES);
const setDistributionNIAction = createAction(SET_DISTRIBUTION_NI);
const setRetailAction = createAction(SET_RETAIL);
const setWholesaleAction = createAction(SET_WHOLESALE);
const setFoodservicesAction = createAction(SET_FOODSERVICES);
const setOnTradeAction = createAction(SET_ONTRADE);
const setNewFlagAction = createAction(SET_NEWFLAG);

const setDepartmentAction = createAction(SET_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const fetchCategoriesAction = createAction(FETCH_CATEGORIES);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const onDistributionEnglandChange = val => dispatch => {
  dispatch(setDistributionEnglandAction(val));
};

export const onDistributionScotlandChange = val => dispatch => {
  dispatch(setDistributionScotlandAction(val));
};

export const onDistributionWalesChange = val => dispatch => {
  dispatch(setDistributionWalesAction(val));
};

export const onDistributionNIChange = val => dispatch => {
  dispatch(setDistributionNIAction(val));
};

export const onRetailChange = val => dispatch => {
  dispatch(setRetailAction(val));
};

export const onWholesaleChange = val => dispatch => {
  dispatch(setWholesaleAction(val));
};

export const onFoodservicesChange = val => dispatch => {
  dispatch(setFoodservicesAction(val));
};

export const onOnTradeChange = val => dispatch => {
  dispatch(setOnTradeAction(val));
};

export const onNewFlagChange = val => dispatch => {
  dispatch(setNewFlagAction(val));
};

const baseUrl = 'https://api.wsale.co.uk';

export const fetchCategories = () => dispatch => {
  dispatch(setLoadingAction(true));
  let url = '/route.php?c=pfp/dropship/getActiveDropshipCategories';
  customRequest({
    url: baseURL + url,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(setLoadingAction(false));
    const departments = [];
    const categories = [];
    if (response.Departments) {
      _.each(response.Departments, department => {
        departments.push({ ID: department.Name, Text: department.Name, categories: department.Categories });
        if (department.Name == store.getState().pfpDropship.department.ID) {
          _.each(department.Categories, category => {
            categories.push({ ID: category.name, Text: category.name });
          });
        }
      });
    }
    dispatch(fetchCategoriesAction({ departments, categories }));
  });
};

export const onDepartmentChange = department => dispatch => {
  const categories = [];
  if (department && department.categories) {
    _.each(department.categories, category => {
      categories.push({ ID: category.name, Text: category.name });
    });
  }
  dispatch(setDepartmentAction({ department, categories }));
};

export const onCategoryChange = category => dispatch => {
  if (category) {
    category = category.ID;
  } else {
    category = '';
  }
  dispatch(setCategoryAction({ category: category }));
  dispatch(fetchDropships());
};

export const onSaveDropship = uploadFiles => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsDropshipCreatedAction(false));
  const { dropshipForm } = store.getState().pfpDropship;
  let data = { ...dropshipForm };
  //remove extra fields and normalize Department field
  data.Department = data.Department.ID;
  delete data.departments;
  delete data.categories;
  delete data.Updated;
  let url = '/route.php?c=pfp/dropship/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/dropship/update';
  } else {
    delete data.ID;
  }
  let formData = new FormData();
  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map(fieldName => {
    const file = uploadFiles[fieldName];
    formData.append(fieldName, file, file.name);
    delete data[fieldName];
  });

  Object.keys(data).map(fieldName => {
    formData.append(fieldName, data[fieldName]);
  });

  // if(uploadFiles['LogoFile']) {
  // 	formData.append('Logo', uploadFiles['LogoFile'], uploadFiles['LogoFile'].name);
  // }

  customRequest({
    url: baseURL + url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: formData,
  }).then(response => {
    dispatch(setLoadingAction(false));
    dispatch(setIsDropshipCreatedAction(true));
  });
};

export const fetchDropships = () => dispatch => {
  const category = store.getState().pfpDropship.category || '';
  dispatch(setLoadingAction(true));
  if (category) {
    let url = '/route.php?c=pfp/dropship/getActiveListingsInCategory&category=' + escape(category) + '';
    customRequest({
      url: baseURL + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(response => {
      dispatch(setLoadingAction(false));
      dispatch(fetchDropshipsAction(response || []));
    });
  } else {
    dispatch(setLoadingAction(false));
    dispatch(fetchDropshipsAction([]));
  }
};

export const onSetDropship = (id, isAddDropship, isDelete) => dispatch => {
  const { department, departments, category, categories } = store.getState().pfpDropship;
  isDelete = isDelete || false;
  let formData = { ...Dropship };
  if (isDelete && id) {
    formData.ID = id;
    dispatch(setDropshipAction({ dropshipForm: { ...formData, Department: department, departments, Category: category, categories } }));
  } else if (!isAddDropship && id) {
    dispatch(setLoadingAction(true));
    let url = '/route.php?c=pfp/dropship/getListingById&id=' + id + '';
    customRequest({
      url: baseURL + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(response => {
      dispatch(setLoadingAction(false));
      if (_.isArray(response)) {
        response = response[0];
      }
      if (_.isObject(response) && response.ID == id) {
        formData = { ...Dropship, ...response };
      }
      dispatch(setDropshipAction({ dropshipForm: { ...formData, Department: department, departments, Category: category, categories } }));
    });
  } else {
    dispatch(setDropshipAction({ dropshipForm: { ...formData, Department: department, departments, Category: category, categories } }));
  }
};

export const onSetForm = (fieldName, fieldValue) => dispatch => {
  const { dropshipForm } = store.getState().pfpDropship;
  if (fieldName == 'Department') {
    const department = fieldValue;
    const categories = [];
    if (department) {
      if (department && department.categories) {
        _.each(department.categories, category => {
          categories.push({ ID: category.name, Text: category.name });
        });
      }
      dropshipForm[fieldName] = department;
    } else {
      dropshipForm[fieldName] = '';
    }
    dropshipForm.categories = categories;
  } else if (fieldName == 'Category') {
    dropshipForm[fieldName] = fieldValue.ID || '';
  } else {
    dropshipForm[fieldName] = fieldValue;
  }
  dispatch(setFormAction({ dropshipForm: { ...dropshipForm } }));
};

export const onDeleteDropship = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsDropshipDeletedAction(false));
  let url = `/route.php?c=pfp/dropship/delete&id=${id}`;
  customRequest({
    url: baseURL + url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(setLoadingAction(false));
    dispatch(setIsDropshipDeletedAction(true));
  });
};

export const resetMe = () => dispatch => {
  dispatch(resetMeAction());
};

export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [FETCH_CATEGORIES]: (state, { payload }) => ({ ...state, departments: payload.departments, categories: payload.categories }),
    [SET_DEPARTMENT]: (state, { payload }) => ({ ...state, department: payload.department, categories: payload.categories }),
    [SET_CATEGORY]: (state, { payload }) => ({ ...state, category: payload.category }),
    [SET_DROPSHIP]: (state, { payload }) => ({ ...state, dropshipForm: payload.dropshipForm }),
    [FETCH_DROPSHIPS]: (state, { payload }) => ({ ...state, dropships: payload }),
    [SET_DISTRIBUTION_ENGLAND]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, DistributionEngland: payload } }),
    [SET_DISTRIBUTION_SCOTLAND]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, DistributionScotland: payload } }),
    [SET_DISTRIBUTION_WALES]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, DistributionWales: payload } }),
    [SET_DISTRIBUTION_NI]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, DistributionNI: payload } }),
    [SET_RETAIL]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, Retail: payload } }),
    [SET_WHOLESALE]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, Wholesale: payload } }),
    [SET_FOODSERVICES]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, Foodservices: payload } }),
    [SET_ONTRADE]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, OnTrade: payload } }),
    [SET_NEWFLAG]: (state, { payload }) => ({ ...state, dropshipForm: { ...state.dropshipForm, NewFlag: payload } }),
    [SET_IS_DROPSHIP_CREATED]: (state, { payload }) => ({ ...state, isDropshipCreated: payload }),
    [SET_IS_DROPSHIP_DELETED]: (state, { payload }) => ({ ...state, isDropshipDeleted: payload }),
    [SET_FORM]: (state, { payload }) => ({ ...state, dropshipForm: payload.dropshipForm }),
    [RESET_ME]: (state, { payload }) => ({ ...state, ...initialState }),
  },
  initialState,
);

export const selectors = {};
