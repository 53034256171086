export default {
  Supplier: '',
  Brand: '',
  Category: '',
  Department: '',
  Advert1: '',
  Advert2: '',
  Advert3: '',
  Advert4: '',
  Advert5: '',
  Image: '',
  StartDate: '',
  EndDate: '',
  ID: '',
  Updated: ''
}
