import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import LaunchIcon from '@material-ui/icons/Launch';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import {
  getUserRoles,
  getUserSystem,
  getDrillInfo,
  getOnlyDrillInfo,
  hasSalesInPermissions,
  hideFor,
} from '../../helpers/util';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Container,
} from 'reactstrap';
import CustomCountSelect from '../components/CustomCountSelect';
import ExportIcon from '../../assets/images/export-icon.svg';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import { filter } from 'lodash';
import { setAuthUser } from '../redux/reducers/user';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import AccountNames from '../components/filters/AccountNames/AccountNames';
import { fetchAccountNames, setAccountName } from '../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartment,
  setCategory,
  setSubCategory,
  setOnLoadParams,
  goToReferal,
  setSupplier,
  setMember,
  setBrand,
  setLimit,
  setPurchase,
  setMeasure,
  setLikeForLike,
  setPmp,
  setSort,
  setLevel4,
  setLevel5,
  setCoreRange,
  setMemberType,
  exportToPDF,
  exportToCSV,
  exportAllToCSV,
  resetReport,
  loadReport,
  setDepot,
  setGroup,
  setBusinessType,
} from '../redux/reducers/bestSellerReport';
import {
  exportTableToCSV,
  formatPerc,
  getRandomNumber,
  formatNumber,
} from '../../helpers/helpers';
import Select from 'react-select';
import TableBestSellers from '../components/TableBestSellers';
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/Loading';
import { Drill } from '../redux/models/Drill';
import ReportContainer from '../components/ReportContainer';
import { getUserData } from '../../helpers/user';

ReactModal.setAppElement('.alchemy_app');
const BestSellersReport = ({
  data,
  match,
  hideAll,
  onAccountNameChange,
  accountName,
  accountNames,
  departments,
  selectedDepartments,
  categories,
  selectedCategories,
  selectedSubCategories,
  subCategories,
  suppliers,
  supplier,
  members,
  brands,
  brand,
  selectedMembers,
  level4s,
  selectedLevel4s,
  level5s,
  selectedLevel5s,
  onDepartmentChange,
  onCategoryChange,
  onExportData,
  onSubCategoryChange,
  transformOptions,
  timeframes,
  timeframe,
  purchases,
  purchase,
  likeForLikes,
  likeForLike,
  measures,
  measure,
  pmpList,
  sortList,
  onSortChange,
  setCategoryStructure,
  setDisableCategoryStructure,
  unlockRefresh,
  pmp,
  sort,
  bestSellerDataTable,
  bestSellerDataTableTotals,
  onTimeFrameChange,
  onPurchaseChange,
  onMeasureChange,
  onLikeForLikeChange,
  onPmpChange,
  onSupplierChange,
  onBrandChange,
  onMemberChange,
  onBackDrill,
  goToReferal,
  limit,
  isLoading,
  isGraphLoaded,
  isTableLoaded,
  onSelect,
  onLevel4Change,
  onLevel5Change,
  onCoreRangeChange,
  coreRange,
  coreRanges,
  onMemberTypeChange,
  memberType,
  memberTypes,
  depots,
  selectedDepots,
  toggleExportMenu,
  onDepotChange,
  reportError,
  onRefreshReport,
  history,
  onResetReport,
  onGroupChange,
  groups,
  selectedGroup,
  onBusinessTypeChange,
  resolveBusinessTypes,
  resolveDepots,
  businessTypes,
  selectedBusinessType,
  isRollingDateRange,
}) => (
  <motion.div
    key={'BESTSELLERID1234151512212'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
        getUserSystem() === 'unitas' ? 'unitas' : 'others'
      }`}
    />
    <div className="sub-menu-border bg-white">
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between">
            <div className="sub-menu__left">
              <h2 className="text-uppercase">Best Sellers</h2>
              {match.params?.subpage?.length > 0 &&
                getOnlyDrillInfo(match.params.subpage) && (
                  <button
                    onClick={onBackDrill}
                    className="performance-back-btn mb-2">
                    <i className="fa fa-angle-double-left" aria-hidden="true" />{' '}
                    BACK
                  </button>
                )}
              {hideFor('cjLang', 'countryRangeGroup', 'suntory') && (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Like For Like"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`min-wd-140 small-dropdown c-select-header ${
                      getUserSystem() === 'unitas'
                        ? 'c-select-header-row--unitas'
                        : 'c-select-header-row--others'
                    }`}
                    name="likeForLike"
                    id="likeForLike"
                    value={likeForLike}
                    onChange={onLikeForLikeChange}
                    options={likeForLikes}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                  />
                </div>
              )}
              {hasSalesInPermissions(match.params.subpage) ? (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Sales In"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`wd-100 small-dropdown c-select-header ${
                      getUserSystem() === 'unitas'
                        ? 'c-select-header-row--unitas'
                        : 'c-select-header-row--others'
                    }`}
                    name="purchases"
                    id="purchases"
                    value={purchase}
                    onChange={onPurchaseChange}
                    options={purchases}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                  />
                </div>
              ) : (
                <span className="mr-1 text-black btn-group">SALES OUT</span>
              )}
              <div className="btn-group text-uppercase">
                <span>By:</span>
              </div>
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Measure"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-130 small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name="measure"
                  id="measure"
                  value={measure}
                  onChange={onMeasureChange}
                  options={measures}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                />
              </div>
              <div className="btn-group text-uppercase">
                <span>For Latest:</span>
              </div>
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Time"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-130 small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name="timeframe"
                  id="timeframe"
                  value={timeframe}
                  onChange={onTimeFrameChange}
                  options={timeframes}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                />
              </div>
              {hideFor('caterforce', 'countryRangeGroup', 'cotswold') && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="PMPList"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 pc-dropdown small-dropdown c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="pmp"
                      id="pmp"
                      value={pmp}
                      onChange={onPmpChange}
                      options={pmpList}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Sort By"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-200 pc-dropdown small-dropdown c-select-header ${
                    getUserSystem() === 'unitas'
                      ? 'c-select-header-row--unitas'
                      : 'c-select-header-row--others'
                  }`}
                  name="sort"
                  id="sort"
                  value={sort}
                  onChange={onSortChange}
                  options={sortList}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                />
              </div>
              {accountNames && accountNames.length > 0 && (
                <AccountNames
                  value={accountName}
                  options={accountNames}
                  onChange={onAccountNameChange}
                />
              )}
            </div>
            <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div>
                  <button
                    onClick={() => {
                      onResetReport();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    Reset
                    <RotateLeftIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      const win = window.open(
                        history.location.pathname,
                        '_blank',
                      );
                      win.focus();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    New tab
                    <LaunchIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <Dropdown
                    isOpen={data.isExportMenuOpen}
                    toggle={() => toggleExportMenu()}>
                    <DropdownToggle
                      className={`dropddown-toggle-btn alchemy-btn--others mr-2`}>
                      Export <img src={ExportIcon} alt="export" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv');
                        }}>
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv_all');
                        }}>
                        CSV - ALL
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    onClick={onRefreshReport}
                    disabled={data.isRefreshDisabled}
                    className={`btn alchemy-btn--others mr-2`}>
                    Refresh <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {filter(selectedCategories, { Text: 'WINE' }).length > 0 ||
        filter(selectedCategories, { Text: 'SPIRITS' }).length > 0 ? (
          <p className="warning-txt">
            Wines and Spirits are all reported at their lowest unit of sale
          </p>
        ) : (
          <p></p>
        )}
      </div>
      <div className="container sub-menu-bottom">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <div className="form-row">
                {departments?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {departments?.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onDepartmentChange(ev)}
                        name="department"
                        id="department"
                        isMulti={true}
                        value={selectedDepartments}
                        options={departments}
                        type="Department"
                        types="Departments"
                        placeholder="Department"
                      />
                    ) : (
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="department"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="department"
                        placeholder="Department"
                        value={
                          selectedDepartments?.length > 0
                            ? selectedDepartments[0].ID
                            : ''
                        }
                        onChange={opt => onDepartmentChange([opt])}
                        options={departments}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {categories?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {categories?.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onCategoryChange(ev)}
                        name="category"
                        id="category"
                        isMulti={true}
                        value={selectedCategories}
                        options={categories}
                        type="Category"
                        types="Categories"
                        placeholder="Category"
                      />
                    ) : (
                      <Select
                        placeholder="Category"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="category"
                        id="category"
                        value={
                          selectedCategories && selectedCategories.length > 0
                            ? selectedCategories[0].ID
                            : ''
                        }
                        onChange={opt => onCategoryChange([opt])}
                        options={categories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}{' '}
                  </div>
                )}
                {subCategories?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {subCategories?.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onSubCategoryChange(ev)}
                        name="subcategory"
                        id="subcategory"
                        isMulti={true}
                        value={selectedSubCategories}
                        options={subCategories}
                        type="SubCategory"
                        types="SubCategories"
                        placeholder="SubCategory"
                      />
                    ) : (
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="subcategory"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="subcategory"
                        placeholder="SubCategory"
                        value={
                          selectedSubCategories &&
                          selectedSubCategories.length > 0
                            ? selectedSubCategories[0].ID
                            : ''
                        }
                        onChange={opt => onSubCategoryChange([opt])}
                        options={subCategories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {level4s?.length > 1 ||
                (level4s?.length === 1 &&
                  _.filter(level4s, lvl => lvl.Text !== 'NIL').length > 0) ? (
                  <div className="form-group col-md-5 col-lg-3">
                    {level4s.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onLevel4Change(ev)}
                        name="level4"
                        id="level4"
                        isMulti={true}
                        value={selectedLevel4s}
                        options={level4s}
                        type="Level4"
                        types="Level4s"
                        placeholder="Level4"
                      />
                    ) : (
                      <Select
                        placeholder="Level4"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level4"
                        id="level4"
                        value={
                          selectedLevel4s && selectedLevel4s.length > 0
                            ? selectedLevel4s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel4Change(opt)}
                        options={level4s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                ) : null}
                {level5s?.length > 1 ||
                (level5s?.length === 1 &&
                  _.filter(level5s, lvl => lvl.Text !== 'NIL').length > 0) ? (
                  <div className="form-group col-md-5 col-lg-3">
                    {level5s?.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onLevel5Change(ev)}
                        name="level5"
                        id="level5"
                        isMulti={true}
                        value={selectedLevel5s}
                        options={level5s}
                        type="Level5"
                        types="Level5s"
                        placeholder="Level5"
                      />
                    ) : (
                      <Select
                        placeholder="Level5"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level5"
                        id="level5"
                        value={
                          selectedLevel5s && selectedLevel5s.length > 0
                            ? selectedLevel5s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel5Change(opt)}
                        options={level5s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                ) : null}

                {/* Group dropdown for demo system, used to filter members on demo system only */}
                {(getUserSystem() === 'demo' ||
                  getUserSystem() === 'caterforce' ||
                  getUserSystem() === 'countryRangeGroup') &&
                  departments &&
                  departments.length > 0 &&
                  groups &&
                  groups.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onGroupChange(ev)}
                        name="group"
                        id="group"
                        isMulti={true}
                        value={selectedGroup}
                        options={groups}
                        type={'Group Supplier'}
                        types={'Group Suppliers'}
                        placeholder={'Group Supplier'}
                      />
                    </div>
                  )}
                {/* Member dropdown for standard systems, hidden for demo system */}

                {getUserSystem() !== 'demo' &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 0 &&
                  members &&
                  members.length > 2 &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name="member"
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                                isDisabled: parseInt(mem.WholesalerNo) === 9999,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                {getUserSystem() == 'demo' &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 1 &&
                  selectedGroup &&
                  selectedGroup.length > 0 &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name="member"
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {suppliers && suppliers.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      className={`c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="supplier"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      id="supplier"
                      placeholder="Supplier"
                      value={supplier ? supplier.SupplierName : ''}
                      onChange={onSupplierChange}
                      options={suppliers}
                      valueKey="SupplierName"
                      labelKey="SupplierName"
                    />
                  </div>
                )}
                {brands && brands.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Brand"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="brand"
                      id="brand"
                      value={brand.Brand}
                      onChange={onBrandChange}
                      options={brands}
                      valueKey="Brand"
                      labelKey="Brand"
                    />
                  </div>
                )}
                {businessTypes && businessTypes.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <CustomCountSelect
                      className={`c-select-header c-select-header-row--others`}
                      onChangeCallback={ev => onBusinessTypeChange(ev)}
                      name="businessType"
                      id="businessType"
                      isMulti={true}
                      placeholder="Business Type"
                      value={selectedBusinessType}
                      options={businessTypes}
                      type="Business Type"
                      types="Business Types"
                    />
                  </div>
                )}
                {(getUserSystem() === 'unitas') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Depot"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="depot"
                        id="depot"
                        multi
                        value={selectedDepots}
                        onChange={onDepotChange}
                        options={depots}
                        valueKey="DepotName"
                        labelKey="DepotName"
                      />
                    </div>
                  )}
                  {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                      className={`c-select-header c-select-header-row--others`}
                      onChangeCallback={ev => onDepotChange(ev)}
                      name="depot"
                      id="depot"
                      isMulti={true}
                      placeholder="Depot"
                      value={selectedDepots}
                      options={resolveDepots(depots)}
                      type="Depot"
                      types="Depots"
                    />
                  </div>
                  )}
                <BusinessTypes unlockRefresh={unlockRefresh} />
                {getUserSystem() == 'united' || getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="CoreRange"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="CoreRange"
                        placeholder="Core Range"
                        value={coreRange}
                        onChange={onCoreRangeChange}
                        options={coreRanges}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="MemberType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="MemberType"
                        placeholder="Member Type"
                        value={memberType}
                        onChange={onMemberTypeChange}
                        options={memberTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReportContainer>
      <Container className="pt-3">
        <Row>
          <div className="col-12 col-md-12">
            <div className="nam-dash nam-dash-table">
              <div>{!isTableLoaded && <Loading />}</div>
              {reportError.length === 0 &&
              isTableLoaded &&
              !isLoading &&
              !hideAll ? (
                <div>
                  <TableBestSellers
                    id="performance_table"
                    data={bestSellerDataTable}
                    isLoading={isLoading}
                    purchase={purchase}
                    measure={measure}
                    isDrill={false}
                    bestSellerDataTableTotals={bestSellerDataTableTotals}
                    isRollingDateRange={isRollingDateRange}
                  />
                </div>
              ) : (
                ''
              )}{' '}
              {reportError.length > 0 && <p>{reportError}</p>}
            </div>
          </div>
        </Row>
      </Container>
    </ReportContainer>
  </motion.div>
);
function mapStateToProps(state) {
  const data = {
    departments: state.bestSellerReport.departments,
    selectedDepartments: state.bestSellerReport.selectedDepartments,
    categories: state.bestSellerReport.categories,
    hideAll: state.bestSellerReport.hideAll,
    subCategories: state.bestSellerReport.subCategories,
    selectedCategories: state.bestSellerReport.selectedCategories,
    selectedSubCategories: state.bestSellerReport.selectedSubCategories,
    accountNames: state.filters.accountNames,
    accountName: state.filters.accountName,
    suppliers: state.bestSellerReport.suppliers,
    supplier: state.bestSellerReport.supplier,
    members: state.bestSellerReport.members,
    brands: state.bestSellerReport.brands,
    brand: state.bestSellerReport.brand,
    selectedMembers: state.bestSellerReport.selectedMembers,
    timeframes: state.bestSellerReport.timeframes,
    timeframe: state.bestSellerReport.timeframe,
    purchases: state.bestSellerReport.purchases,
    purchase: state.bestSellerReport.purchase,
    likeForLike: state.bestSellerReport.likeForLike,
    likeForLikes: state.bestSellerReport.likeForLikes,
    measures: state.bestSellerReport.measures,
    measure: state.bestSellerReport.measure,
    coreRange: state.bestSellerReport.coreRange,
    coreRanges: state.bestSellerReport.coreRanges,
    memberType: state.bestSellerReport.memberType,
    memberTypes: state.bestSellerReport.memberTypes,
    pmpList: state.bestSellerReport.pmpList,
    sortList: state.bestSellerReport.sortList,
    pmp: state.bestSellerReport.pmp,
    sort: state.bestSellerReport.sort,
    level4s: state.bestSellerReport.level4s,
    selectedLevel4s: state.bestSellerReport.selectedLevel4s,
    level5s: state.bestSellerReport.level5s,
    selectedLevel5s: state.bestSellerReport.selectedLevel5s,
    bestSellerDataTable: state.bestSellerReport.bestSellerDataTable,
    bestSellerDataTableTotals: state.bestSellerReport.bestSellerDataTableTotals,
    reportType: state.bestSellerReport.reportType,
    isLoading: state.bestSellerReport.isLoading,
    isExporting: state.bestSellerReport.isExporting,
    isExported: state.bestSellerReport.isExported,
    pdfURL: state.bestSellerReport.pdfURL,
    isLoaded: state.bestSellerReport.isLoaded,
    isTableLoaded: state.bestSellerReport.isTableLoaded,
    isGraphLoaded: state.bestSellerReport.isGraphLoaded,
    limit: state.bestSellerReport.limit,
    depots: state.bestSellerReport.depots,
    selectedDepots: state.bestSellerReport.selectedDepots,
    authUser: state.user.authUser,
    reportError: state.bestSellerReport.reportError,
    groups: state.bestSellerReport.groups,
    selectedGroup: state.bestSellerReport.selectedGroup,
    businessTypes: state.bestSellerReport.businessTypes,
    selectedBusinessType: state.bestSellerReport.selectedBusinessType,
    isRollingDateRange: state.bestSellerReport.isRollingDateRange,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    setTimeFrame,
    setPurchase,
    setAccountName,
    fetchAccountNames,
    setMeasure,
    setLikeForLike,
    setPmp,
    setSort,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setSupplier,
    setOnLoadParams,
    exportToCSV,
    exportAllToCSV,
    setBrand,
    setMember,
    setLevel5,
    setCoreRange,
    setMemberType,
    setLimit,
    goToReferal,
    resetReport,
    setAuthUser,
    loadReport,
    setDepot,
    setGroup,
    setBusinessType,
    setCategoryStructure,
    setDisableCategoryStructure,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
      this.props.setLikeForLike('like_for_like');
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(this.props.match.params.subpage) || {};
      const isSkip = drillObj.skip ? true : false;
      this.props.resetReport();
      this.props.fetchCategories(isSkip);
      this.props.fetchAccountNames();
      if (this.props.authUser?.userId?.length === 0) {
        this.props.setAuthUser();
      }
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.fetchCategories();
        this.props.loadReport();
      }
      if (nextProps.isLoaded !== this.props.isLoaded && this.props.isLoaded) {
        this.props.loadReport();
      }
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      let drillObj = new Drill();
      let isSkip = false;
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        drillObj = getDrillInfo(this.props.match.params.subpage) || new Drill();
        isSkip = drillObj.skip ? true : false;
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
            });
          }, 1000);
        }, 1000);
      }
      if (nextProps.match.params.subpage !== this.props.match.params.subpage) {
        this.props.resetReport();
        this.props.fetchCategories();
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }

      if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading &&
        !this.props.isLoaded
      ) {
        let reportType = '';
        if (this.props.match.params.subpage === 'category_performance') {
          reportType = 'category';
        } else if (this.props.match.params.subpage === 'supplier_performance') {
          reportType = 'supplier';
        } else if (this.props.match.params.subpage === 'brand_performance') {
          reportType = 'brand';
        } else if (
          this.props.match.params.subpage === 'wholesaler_performance'
        ) {
          reportType = 'member';
        }
        let isDepartmentSelected = false;
        let isCategorySelected = false;
        let isSubCategorySelected = false;
        let isLevel4Selected = false;
        let isLevel5Selected = false;
        let isBrandSelected = false;

        const departments =
          drillObj.departments && drillObj.departments.length > 0
            ? drillObj.departments.split(',')
            : [];

        if (
          (departments && drillObj.skip !== 'department') ||
          (drillObj.skip === 'department' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isDepartmentSelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'category';
          }
        }
        const categories =
          drillObj.categories && drillObj.categories.length > 0
            ? drillObj.categories.split(',')
            : [];
        if (
          (categories && drillObj.skip !== 'category') ||
          (drillObj.skip === 'category' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isCategorySelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'subcategory';
          }
        }
        const subCategories =
          drillObj.subCategories && drillObj.subCategories.length > 0
            ? drillObj.subCategories.split(',')
            : [];
        if (
          (subCategories && drillObj.skip !== 'subcategory') ||
          (drillObj.skip === 'subcategory' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isSubCategorySelected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'level4';
          }
        }
        const level4s =
          drillObj.level4s && drillObj.level4s.length > 0
            ? drillObj.level4s.split(',')
            : [];
        if (
          (level4s && drillObj.skip !== 'level4') ||
          (drillObj.skip === 'level4' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isLevel4Selected = true;
          if (
            reportType !== 'supplier' &&
            reportType !== 'brand' &&
            reportType !== 'member' &&
            reportType !== 'depot' &&
            reportType !== 'business_type'
          ) {
            reportType = 'level5';
          }
        }
        const level5s =
          drillObj.level5s && drillObj.level5s.length > 0
            ? drillObj.level5s.split(',')
            : [];
        if (
          (level5s && drillObj.skip !== 'level5') ||
          (drillObj.skip === 'level5' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isLevel5Selected = true;
        }
        const brand = drillObj.brand || '';
        if (
          (brand && drillObj.skip !== 'brand') ||
          (drillObj.skip === 'brand' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          isBrandSelected = true;
        }
        let passedMembers = [];
        if (
          (this.props.members &&
            this.props.members.length > 0 &&
            drillObj.skip !== 'members') ||
          (drillObj.skip === 'members' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          if (drillObj.members !== null) {
            let members = [];

            let membs =
              drillObj.members && drillObj.members.indexOf(',') >= 0
                ? drillObj.members.split(',')
                : !drillObj.members
                ? []
                : drillObj.members;
            _.each(membs, memb => {
              _.each(this.props.members, mem => {
                if (mem.WholesalerNo === memb) {
                  members.push(mem);
                }
              });
            });
            passedMembers = members;
          }
        }
        let passedDepots = [];

        if (passedMembers.length === 0) {
          if (
            (this.props.depots &&
              this.props.depots.length > 0 &&
              drillObj.skip !== 'depots') ||
            (drillObj.skip === 'depots' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.depots !== null) {
              let depots = [];
              let depts =
                drillObj.depots && drillObj.depots.indexOf(',') >= 0
                  ? drillObj.depots.split(',')
                  : !drillObj.depots
                  ? []
                  : drillObj.depots;
              _.each(depts, dept => {
                _.each(this.props.depots, dpt => {
                  if (dpt.DepotName === dept) {
                    depots.push(dpt);
                  }
                });
              });
              passedDepots = depots;
            }
          }
        } else {
          passedDepots =
            drillObj.depots && drillObj.depots.indexOf(',') >= 0
              ? drillObj.depots.split(',')
              : !drillObj.depots
              ? []
              : drillObj.depots;
        }

        let passedSupplier = '';
        if (
          (this.props.suppliers &&
            drillObj.skip !== 'supplier' &&
            this.props.suppliers.length > 0) ||
          (drillObj.skip === 'supplier' &&
            drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
        ) {
          if (drillObj.supplier !== null) {
            passedSupplier = drillObj.supplier;
          }
        }

        const measure = drillObj.measure;
        const type = drillObj.type;
        const timeframe = drillObj.timeframe;
        const accountName = drillObj.accountName;
        const likeForLike = drillObj.likeForLike;

        if (isLevel5Selected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              level4s,
              level5s,
              accountName,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              likeForLike,
              timeframe,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (isLevel4Selected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              level4s,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              likeForLike,
              timeframe,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (isSubCategorySelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              subCategories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              likeForLike,
              timeframe,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (isCategorySelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              likeForLike,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (isDepartmentSelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              likeForLike,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (isBrandSelected === true) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              likeForLike,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        } else if (drillObj.skip) {
          this.props.setOnLoadParams(
            {
              departments,
              categories,
              members: passedMembers,
              supplier: passedSupplier,
              brand: brand,
              measure,
              timeframe,
              likeForLike,
              type,
              depots: passedDepots,
            },
            reportType,
          );
        }
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onAccountNameChange:
      ({ data, handleData, setAccountName }) =>
      opt => {
        if (opt) {
          setAccountName(opt);
        } else {
          setAccountName([]);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBackDrill: props => value => {
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(props.match.params.subpage) || new Drill();
      if (drillObj.ref !== undefined && drillObj.ref !== null) {
        props.goToReferal(drillObj.ref, drillObj.to);
        return;
      }
      switch (props.reportType) {
        case 'department':
          props.setDepartment([]);
          break;
        case 'subcategory':
          props.setCategory([]);
          break;
        case 'level4':
          props.setSubCategory([]);
          break;
        case 'level5':
          props.setLevel4([]);
          break;
        case 'supplier':
          if (props.level5.ID !== undefined) {
            props.setLevel5([]);
          } else if (props.level4.ID !== undefined) {
            props.setLevel4([]);
          } else if (props.subCategory.ID !== undefined) {
            props.setSubCategory([]);
          } else if (props.category.ID !== undefined) {
            props.setCategory([]);
          }
          break;
        case 'member':
          if (props.level5.ID !== undefined) {
            props.setLevel5([]);
          } else if (props.level4.ID !== undefined) {
            props.setLevel4([]);
          } else if (props.subCategory.ID !== undefined) {
            props.setSubCategory([]);
          } else if (props.category.ID !== undefined) {
            props.setCategory([]);
          }
          break;
      }
    },
    onTimeFrameChange:
      ({ data, handleData, setTimeFrame }) =>
      opt => {
        if (opt) {
          setTimeFrame(opt.ID);
        } else {
          setTimeFrame('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMeasureChange:
      ({ data, handleData, setMeasure }) =>
      opt => {
        if (opt) {
          setMeasure(opt.ID);
        } else {
          setMeasure('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLikeForLikeChange:
      ({ data, handleData, setLikeForLike }) =>
      opt => {
        if (opt) {
          setLikeForLike(opt.ID);
        } else {
          setLikeForLike('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSortChange:
      ({ data, handleData, setSort }) =>
      opt => {
        if (opt) {
          setSort(opt.ID);
        } else {
          setSort('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    resolveBusinessTypes: () => businessTypes => {
      businessTypes.forEach((type, index) => {
        type.Text = type.BusinessName;
        type.ID = index;
      });
      return businessTypes;
    },
    onBusinessTypeChange:
      ({ data, handleData, setBusinessType }) =>
      selectedOptions => {
        setBusinessType(selectedOptions);
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment, selectedDepartments, departments }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedDepartments.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setDepartment(tempArray);
          } else {
            setDepartment(departments);
          }
        } else if (
          filter(selectedDepartments, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setDepartment([]);
        } else {
          setDepartment(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory, selectedCategories, categories }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setCategory(tempArray);
          } else {
            setCategory(categories);
          }
        } else if (
          filter(selectedCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setCategory([]);
        } else {
          setCategory(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({
        data,
        handleData,
        setSubCategory,
        selectedSubCategories,
        subCategories,
      }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedSubCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setSubCategory(tempArray);
          } else {
            setSubCategory(subCategories);
          }
        } else if (
          filter(selectedSubCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setSubCategory([]);
        } else {
          setSubCategory(selectedOptions);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4, selectedLevel4s, level4s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel4s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel4(tempArray);
          } else {
            setLevel4(level4s);
          }
        } else if (
          filter(selectedLevel4s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setLevel4([]);
        } else {
          setLevel4(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5, selectedLevel5s, level5s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel5s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel5(tempArray);
          } else {
            setLevel5(level5s);
          }
        } else if (
          filter(selectedLevel5s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setLevel5([]);
        } else {
          setLevel5(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    unlockRefresh:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSupplierChange:
      ({ data, handleData, setSupplier }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSupplier(0);
        } else {
          setSupplier(selectedOption.SupplierName);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand(0);
        } else {
          setBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, setCategoryStructure, setDisableCategoryStructure }) =>
      selectedOption => {
        if (members.length > 2) {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }

        if (getUserSystem() == "caterforce") {
          if (selectedOption?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }

        if (getUserSystem() === 'demo') {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
      },
    onExportData: props => type => {
      if (type === 'csv') {
        props.exportToCSV();
      }
      if (type === 'csv_all') {
        props.exportAllToCSV();
      }
    },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      selectedOption => {
        setDepot(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCoreRangeChange:
      ({ data, handleData, setCoreRange }) =>
      opt => {
        if (opt) {
          setCoreRange(opt);
        } else {
          setCoreRange('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberTypeChange:
      ({ data, handleData, setMemberType }) =>
      opt => {
        if (opt) {
          setMemberType(opt);
        } else {
          setMemberType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    resolveDepots: () => depots => {
      depots.forEach((depot, index) => {
        depot.Text = depot.DepotName;
        depot.ID = index;
      });
      return depots;
    },
    onResetReport: props => () => {
      props.setLikeForLike('like_for_like');
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(props.match.params.subpage) || {};

      const isSkip = drillObj.skip ? true : false;
      props.resetReport();
      props.fetchCategories(isSkip);
      if (props.authUser?.userId?.length === 0) {
        props.setAuthUser();
      }
    },
  }),
)(BestSellersReport);
