import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import styled, { ThemeContext } from 'styled-components';
import 'react-select/dist/react-select.css';
import { sendSupportEmail } from '../redux/reducers/admin';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem, getUserSystemFormatted } from '../../helpers/util';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import { getUserData } from '../../helpers/user';

const StyledButton = styled.a`
  display: block;
  padding: 1.25em;
`;

const SupportPage = ({
  data,
  onSendSupportEmail,
  onTextChange,
  onGoToDashboard,
  handleSmallPrintModal,
}) => {
  const {
    manualUrl,
    weeklyNumbersUrl,
    categoryStructureUrl,
    primary,
    extraSupportUrls,
  } = useContext(ThemeContext);
  const userSystem = getUserSystemFormatted();

    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: 'eu1',
                    portalId: '26298820',
                    formId: 'c3030933-a8de-4988-8bd7-54a96cf3e3ab',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

  return (
    <div className="o-poster o-background o-background--data u-center-wrap align-items-start">
      <ToastContainer
        autoClose={8000}
        style={{ top: '80px', zIndex: '10001' }}
      />
      <div className="c-support-page">
        <div className="c-support-page__inner">
          <div className="c-support-page__name">
            <a href="javascript:void(0);" onClick={onGoToDashboard}>
              <i
                className="fa fa-arrow-circle-left"
                style={{ color: primary, fontSize: '25px' }}
              />
            </a>
            <h1 className="o-canvas__title">Get Help</h1>
          </div>
          <div
            id="mc_sd"
            data-username="Matt Chick"
            data-email="support@wsale.co.uk">
            <div className="o-canvas__header-item u-layout t-brand-c">
              <div className="u-1_1">
                <div className="u-1_2">
                  <div id="hubspotForm"></div>
                </div>
                <div className="u-1_2">
                  <div>
                    <StyledButton
                      className="btn mb-2 alchemy-btn--others"
                      href="javascript:void(0);"
                      onClick={() => handleSmallPrintModal()}>
                      Small Print{' '}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </StyledButton>
                    {manualUrl && (
                      <StyledButton
                        className="btn mb-2 alchemy-btn--others"
                        target="_blank"
                        href={manualUrl || '#'}>
                        Download User Manual{' '}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </StyledButton>
                    )}
                    {weeklyNumbersUrl && (
                      <StyledButton
                        className="btn mb-2 alchemy-btn--others"
                        target="_blank"
                        href={
                          weeklyNumbersUrl ||
                          'http://unitas-api.twcgroup.net/weeknumbers.xlsx'
                        }>
                        Download Weekly Numbers{' '}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </StyledButton>
                    )}
                    {categoryStructureUrl && (
                      <StyledButton
                        className="btn mb-2 alchemy-btn--others"
                        target="_blank"
                        href={categoryStructureUrl || '#'}>
                        Download Category List{' '}
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </StyledButton>
                    )}
                    {extraSupportUrls &&
                      extraSupportUrls.length > 0 &&
                      extraSupportUrls.map(item => (
                        <StyledButton
                          className="btn mb-2 alchemy-btn--others"
                          target="_blank"
                          href={item[1] || '#'}>
                          {item[0]}
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"></i>
                        </StyledButton>
                      ))}

                      {getUserSystem() == 'suntory' && (
                        <iframe width="560" height="315" src="
                        https://www.youtube.com/embed/d3nKS9CR7Og?si=c5KgCmm390pGcAKS"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={data.openSmallPrintModal} className="terms-modal">
        <ModalHeader
          className="text-center flex-auto"
          toggle={() => handleSmallPrintModal()}>
          {`${userSystem}`} Data Service Terms and Conditions of Use
        </ModalHeader>
        <ModalBody>
          <p>
            You have been granted access to the {`${userSystem}`} Data Service
            by {`${userSystem}`}
            Central Office. This access can be removed at any time but whilst
            your access is enabled you have the following responsibilities:
          </p>
          <p>
            To notify TWC / {`${userSystem}`} if you are changing roles and no
            longer working on the {`${userSystem}`} account
          </p>
          <p>
            To notify TWC / {`${userSystem}`} if you are changing companies and
            leaving your current employment
          </p>
          <p>
            To keep your password and access information private and to ensure
            it is not shared with anyone else whatsoever.
          </p>
          <p>
            To keep the data contained on the {`${userSystem}`} Data Service
            private and confidential. Named member data must only be shared with
            the named member not with others and any {`${userSystem}`} data must
            only be shared within {`${userSystem}`} or with colleagues in your
            business directly employed on the {`${userSystem}`} account. Data is
            not to be shared beyond your immediate team and must not be made
            available to any third party or into the public domain either to
            other wholesalers, suppliers or to trade press, financial
            institutions or industry bodies in any format whatsoever. Any
            inappropriate sharing of information will be considered a data
            breach for which you will be held responsible. Data sourced from the{' '}
            {`${userSystem}`} Data Service will remain the property of{' '}
            {`${userSystem}`} and TWC.
          </p>
          <p>
            TWC / {`${userSystem}`} confirms that whilst every effort is taken
            in the collation and reporting of this data, accuracy is not
            guaranteed. Any presentations, plans or agreements built from this
            data are the responsibility of the user not the {`${userSystem}`}{' '}
            Data Service or its providers and data is provided to you, the user,
            without any warranty either explicit or inferred.
          </p>
          <p>
            TWC / {`${userSystem}`} undertakes to provide their best endeavours
            at all times to facilitate uninterrupted access to the{' '}
            {`${userSystem}`} Data Service 24 / 7. However, unforeseen technical
            issues or data delivery or integrity issues do arise from time to
            time. Whilst TWC / {`${userSystem}`}
            will strive to remedy any technical issues preventing access as
            quickly as possible, neither party guarantees or infers specific
            service levels will be maintained.
          </p>
          <p>
            TWC / {`${userSystem}`} continually monitors usage of the{' '}
            {`${userSystem}`} Data Service. We do this via Cookies and
            specialist tracking software. Please be aware that your site visits
            may be recorded. This monitoring is solely for the purposes of
            improving customer experience and site functionality. If you do
            require further information on the data that we track please do not
            hesitate to contact support@wsale.co.uk.
          </p>
          <p>
            Reports and layouts contained on the {`${userSystem}`} Data Service
            are the intellectual property of TWC. No rights are transferred in
            the use of these reports via logged in users and reside solely with
            TWC.
          </p>
          <p>
            This agreement shall be governed by and construed and take effect in
            accordance with English law and each of the parties hereto submits
            to the exclusive jurisdiction of the English Courts.
          </p>
          <p>
            Nothing in this agreement shall, or is intended to, create rights
            and/or benefits by reason of the Contracts (Rights of Third Parties)
            Act 1999 or otherwise in favour of any person who is not party to
            this agreement.
          </p>
        </ModalBody>
        <ModalFooter className="justify-center">
          <Button
            color="primary"
            className="text-center"
            onClick={() => handleSmallPrintModal()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  const data = {
    isSupportEmailSent: state.admin.isSupportEmailSent,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, { sendSupportEmail }),
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
        setTimeout(() => {
          const user_data = JSON.parse(getUserData());
          window.hj('identify', user_data?.userId, {
            email: user_data?.email,
          });
        }, 1000);
      }, 1000);
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isSupportEmailSent === false &&
        this.props.isSupportEmailSent === true
      ) {
        toast.info('Support Email sent successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  }),
  withState('data', 'handleData', {
    subject: '',
    description: '',
    openSmallPrintModal: false,
  }),
  withHandlers({
    handleSmallPrintModal: ({ data, handleData }) => () => {
      handleData({ ...data, openSmallPrintModal: !data.openSmallPrintModal });
    },
    onGoToDashboard: props => () => {
      props.history.push(`${process.env.APP_ROOT}dashboard`);
    },
    onTextChange: ({ data, handleData }) => ({ target }) => {
      const { name, value } = target;
      handleData({ ...data, [name]: value });
    },
    onSendSupportEmail: ({
      data,
      handleData,
      sendSupportEmail,
    }) => selectedOption => {
      sendSupportEmail(data);
      handleData({ subject: '', description: '' });
    },
  }),
)(SupportPage);
