import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Table } from 'reactstrap';
import styled from 'styled-components';

import Explainer from '../Explainer/Explainer';
import { StyledCard } from '../Styled';
import { formatToCurrency } from '../../../helpers/helpers';

const StyledTable = styled(Table)`
  color: #000;
  text-align: center;

  td,
  th {
    text-align: center !important;
    vertical-align: middle;
  }

  th,
  tr td:first-of-type:not(tr:last-of-type td) {
    background: ${({ theme }) => theme.secondary};
    color: #fff;
    border: 2px solid #fff;
    vertical-align: middle;
  }
  td:nth-of-type(odd). td:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  tr th:first-of-type {
    background-color: #fff;
  }
  th {
    width: 7rem;
  }

  .rdt_Table {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.tableColor1} #fff;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '',
    },
    display: 'flex',
  },
}));

const SupplierCalculator = ({ data, ...props }) => {
  const {
    Pen: penetration,
    Price: price,
    ROS: ros,
    Value: value,
    CategorySize,
  } = data;
  const classes = useStyles();
  const [customerPenetration, setCustomerPenetration] = useState(penetration);
  const [ROS, setROS] = useState(ros);
  const [averagePrice, setAveragePrice] = useState(price);
  const [valueHasChanged, setValueHasChanged] = useState(false);
  const opportunityTotal =
    CategorySize * (customerPenetration / 100) * ROS * averagePrice;

  return (
    <StyledCard>
      <Explainer explainerText="Supplier Opportunity Calculator" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}>
        <StyledTable>
          <tbody>
            <tr>
              <th></th>
              <th>Customer Penetration</th>
              <th>Rate of Sale</th>
              <th>Average Price</th>
              <th>= Value Sales</th>
            </tr>
            <tr>
              <td>Current</td>
              <td>{penetration}</td>
              <td>{ros}</td>
              <td>{price}</td>
              <td>{formatToCurrency(value)}</td>
            </tr>
            <tr>
              <td>Target</td>
              <td>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    id="CustomerPenetration"
                    variant="outlined"
                    type="number"
                    value={customerPenetration}
                    onChange={ev => {
                      setCustomerPenetration(ev.target.value);
                      setValueHasChanged(true);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </form>
              </td>
              <td>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    id="ROS"
                    variant="outlined"
                    type="number"
                    value={ROS}
                    onChange={ev => {
                      setROS(ev.target.value);
                      setValueHasChanged(true);
                    }}
                  />
                </form>
              </td>
              <td>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    id="AveragePrice"
                    variant="outlined"
                    type="number"
                    value={averagePrice}
                    onChange={ev => {
                      setAveragePrice(ev.target.value);
                      setValueHasChanged(true);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                </form>
              </td>
              <td>
                {valueHasChanged
                  ? formatToCurrency(opportunityTotal)
                  : formatToCurrency(value)}
              </td>
            </tr>
            <tr>
              <td class="bg-white" colSpan={4}>
                Opportunity
              </td>

              <td>
                {valueHasChanged
                  ? formatToCurrency(opportunityTotal - value)
                  : formatToCurrency(value - value)}
              </td>
            </tr>
          </tbody>
        </StyledTable>
      </motion.div>
    </StyledCard>
  );
};

export default SupplierCalculator;
