import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId } from '../../../helpers/util';
import Deal from '../models/Deal';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';

const FETCH_DEALS = 'pfpAdvert/FETCH_DEALS';
const SET_TEXT_CHANGE = 'pfpDeal/SET_TEXT_CHANGE';
const SET_LOADING = 'pfpDeal/SET_LOADING';

const initialState = {
  isLoading: false,
  deal:Deal,
  };

const setTextChangeAction = createAction(SET_TEXT_CHANGE);
const setLoadingAction = createAction(SET_LOADING);

export const onDealTextChange = (name, value) => (dispatch) => {
  dispatch(setTextChangeAction({ name, value }));
}

export const fetchDeals = () => (dispatch) => { }

export default handleActions({
        [SET_LOADING] : (state, {payload}) => ({ ...state, isLoading: payload}),
        [SET_TEXT_CHANGE] : (state, {payload}) => ({ ...state, advert: { ...state.advert,
         [payload.name] : payload.value
        } })
}, initialState);

export const selectors = {};

