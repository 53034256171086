import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { supplierEnagagementSubCategorySupplierTable as explanation } from '../../Explainer/Explanations';
import { coloredTableTheme } from '../../../../config/tableStyles';
import { formatToCurrency } from '../../../../helpers/helpers';
import StyledContainer from './StyledContainer';

const StyledTitle = styled.h2`
  &&& {
    font-size: 1.125rem !important;
    padding: 1rem;
  }
`;

const { tableHeaders } = explanation;

const SubCategorySupplierTable = ({ data, title }) => {
  const columns = [
    {
      selector: 'SubCategory',
      name: <TableHeaderTooltip text="Category" />,
      sortable: true,
      grow: 2,
      wrap: true,
    },
		{
      selector: 'CategorySalesTY',
      name: (
        <TableHeaderTooltip
          text="Category Sales TY"
          explanationTooltip={tableHeaders.CategorySalesTY}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.CategorySalesTY, 0)}`,
      sortFunction: (rowA, rowB) => rowA.CategorySalesTY - rowB.CategorySalesTY,
    },
    {
      selector: 'SalesTY',
      name: (
        <TableHeaderTooltip
          text="Supplier Sales TY"
          explanationTooltip={tableHeaders.salesTY}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.SalesTY, 0)}`,
      sortFunction: (rowA, rowB) => rowA.SalesTY - rowB.SalesTY,
    },
	{
      selector: 'CategoryShareOfTotalCategory',
      name: (
        <TableHeaderTooltip
          text="Category – Sub Cat share of Total Category"
          explanationTooltip={tableHeaders.CategoryShareOfTotalCategory}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.CategoryShareOfTotalCategory, 2)}%`,
      sortFunction: (rowA, rowB) => rowA.CategoryShareOfTotalCategory - rowB.CategoryShareOfTotalCategory,
    },
	{
      selector: 'SupplierShareOfTotalCategory',
      name: (
        <TableHeaderTooltip
          text="Supplier – Sub Cat share of Total Category"
          explanationTooltip={tableHeaders.SupplierShareOfTotalCategory}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.SupplierShareOfTotalCategory, 2)}%`,
      sortFunction: (rowA, rowB) => rowA.SupplierShareOfTotalCategory - rowB.SupplierShareOfTotalCategory,
    },
	
	
  /*  {
      selector: 'ActualDifference',
      name: (
        <TableHeaderTooltip
          text="Actual Difference"
          explanationTooltip={tableHeaders.actualDifference}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ActualDifference < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {formatToCurrency(row.ActualDifference, 0)}
        </div>
      ),
      sortFunction: (rowA, rowB) =>
        rowA.ActualDifference - rowB.ActualDifference,
    },*/  
	{
      selector: 'CategoryChange',
      name: (
        <TableHeaderTooltip
          text="Category % Change"
          explanationTooltip={tableHeaders.categoryChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.CategoryChange < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {parseFloat(row.CategoryChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.CategoryChange - rowB.CategoryChange,
    },
    {
      selector: 'PerChange',
      name: (
        <TableHeaderTooltip
          text="Supplier % Change"
          explanationTooltip={tableHeaders.change}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.PerChange < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {parseFloat(row.PerChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.PerChange - rowB.PerChange,
    },
	    {
      selector: 'ActualDifference',
      name: (
        <TableHeaderTooltip
          text="Supplier Abs. Change"
          explanationTooltip={tableHeaders.change}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ActualDifference < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {formatToCurrency(row.ActualDifference, 0)}
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.ActualDifference - rowB.ActualDifference,
    },
    
  ];
  return (
    <StyledContainer>
      <StyledTitle className="text-uppercase">{title}</StyledTitle>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'Index'}
        defaultSortAsc={true}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default SubCategorySupplierTable;
