import React from 'react';
import PropTypes from 'prop-types';
import { regionConversion } from '../../helpers/helpers';

const TableHeatmapRow = ({ data, id, isRegionTable }) => (
  <tr key={id}>
    {isRegionTable ? (
      <>
        <td>{regionConversion(data.EER_RegionName)}</td>
        <td>{data.DisplayName}</td>
      </>
    ) : (
      <>
        <td>{data.EER_RegionName}</td>
      </>
    )}

    <td>{data.CustomerCount_TY}</td>
    <td>{data.ProductCount_TY}</td>
    <td>
      <span className="bg-black text-white pl-1 pr-1 pt-1 pb-1">
        {data.Share_TY}%
      </span>
    </td>
    <td>{data.CustomerCount_LY}</td>
    <td>{data.ProductCount_LY}</td>
    <td>
      <span className="bg-black text-white pl-1 pr-1 pt-1 pb-1">
        {data.Share_LY}%
      </span>
    </td>
  </tr>
);

TableHeatmapRow.defaultProps = {
  data: {},
  id: 'default-key',
};
TableHeatmapRow.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
};

const TableHeatmap = ({ tableData, isRegionTable }) => (
  <div className="nam-dash-table">
    <table className="table text-uppercase table-responsive text-center w-100 o-table">
      <thead>
        <tr>
          <th scope="col" />
          <th scope="col" />
          <th scope="col" colSpan={3}>
            Sales TY
          </th>
          <th scope="col" colSpan={3}>
            Sales LY
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          {isRegionTable ? (
            <>
              <th scope="col">Region</th>
              <th scope="col">Postcode</th>
            </>
          ) : (
            <>
              <th scope="col">Region</th>
            </>
          )}

          <th scope="col">Customers Category</th>
          <th scope="col">Customers This Sku</th>
          <th scope="col">%</th>
          <th scope="col">Customers Category</th>
          <th scope="col">Customers This Sku</th>
          <th scope="col">%</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, i) => (
            <TableHeatmapRow
              data={item}
              id={`table-heatmap${i}`}
              key={i}
              isRegionTable={isRegionTable}
            />
          ))}
      </tbody>
    </table>
  </div>
);

TableHeatmap.defaultProps = {
  tableData: [],
};

TableHeatmap.propTypes = {
  tableData: PropTypes.array,
};

export default TableHeatmap;
