import { getUserData } from './user';

export function getDrillInfo(url) {
  let data = localStorage.getItem('drill_data');
  if (data) {
    let drillData = JSON.parse(data);
    let exists = drillData.filter(dt => {
      if (dt.to.indexOf(url) >= 0 || dt.ref.indexOf(url) >= 0) {
        return dt;
      }
    });
    if (exists.length > 0) {
      if (exists[0].ref.indexOf(url) >= 0 && drillData.length === 1) {
        localStorage.removeItem('drill_data');
      }
      return exists[0];
    }
  }
}

export const membersCheck = arr => {
  const system = getUserSystem();
  let check = false;
  let selectAllSystems = ['unitas', 'caterforce', 'countryRangeGroup', 'sugro']
  if (selectAllSystems.includes(system)) {
    check = arr.length > 1;
  } else if (system === 'confex') {
    check = arr.length > 0;
  } else {
    check = arr.length > 2;
  }

  return check;
};

export function getOnlyDrillInfo(url) {
  let data = localStorage.getItem('drill_data');
  if (data) {
    let drillData = JSON.parse(data);
    let exists = drillData.filter(dt => {
      if (dt.to.indexOf(url) >= 0 || dt.ref.indexOf(url) >= 0) {
        return dt;
      }
    });
    return exists[0];
  }
}

export function removeDrillInfo(ref) {
  let data = localStorage.getItem('drill_data');
  if (data) {
    let drillData = JSON.parse(data);
    if (drillData.length > 0) {
      drillData = drillData[0];
      const DrillRemoverMap = {
        department: 'department',
        category: 'categories',
        subcategory: 'subCategories',
        level4: 'level4s',
        level5: 'level5s',
      };
      if (drillData.skip && DrillRemoverMap[drillData.skip]) {
        drillData[DrillRemoverMap[drillData.skip]] = '';
      }

      localStorage.setItem('drill_data', JSON.stringify([drillData]));
    }
  }
}

export const resetDrill = () => {
  localStorage.removeItem('drill_data');
};

export function addDrillInfo(drill) {
  let data = localStorage.getItem('drill_data');
  if (data) {
    let drillData = JSON.parse(data);
    let exists = drillData.filter(dt => {
      if (dt.to.indexOf(drill.to) >= 0) {
        return dt;
      }
    });
    if (exists.length > 0) {
      let existsData = [];
      drillData.map(dt => {
        if (dt.to.indexOf(drill.to) < 0) {
          existsData.push(dt);
        }
      });
      existsData.push(drill);
      localStorage.setItem('drill_data', JSON.stringify(existsData));
    } else {
      drillData.push(drill);
      localStorage.setItem('drill_data', JSON.stringify(drillData));
    }
  } else {
    const drillData = JSON.stringify([drill]);
    localStorage.setItem('drill_data', drillData);
  }
}

export function getToken() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.token.token;
  } else {
    if (document.location.pathname != '/') {
      document.location.href = '/';
    }
  }
}

export function getShowUOMPopUp() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.showUOMPopUp ? user.showUOMPopUp : 0;
  }
}

export function setShowQBPopUp() {
  sessionStorage.setItem('show_qb_popup', false);
}

export function getShowQBPopUp() {
  let data = sessionStorage.getItem('show_qb_popup');
  return data ? false : true;
}

export function getNoOfLogin() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.noOfLogin ? user.noOfLogin : 0;
  }
}

export function getUserDataSets() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.dataSets;
  } else {
    return [];
  }
}

export function getUserSystem() {
  let data = localStorage.getItem('system');
  if (data) {
    return data;
  } else {
    return '';
  }
}

export const getUserSystemFormatted = () => {
  let formattedSystem = '';
  const userSystem = getUserSystem();
  const capitalized = userSystem.charAt(0).toUpperCase() + userSystem.slice(1);
  if (capitalized === 'Cjlang') {
    formattedSystem = 'CJ Lang';
  } else {
    formattedSystem = capitalized;
  }
  return formattedSystem;
};

export function getUserRoles() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.roles;
  } else {
    if (document.location.pathname != '/') {
      document.location.href = '/';
    }
  }
}

export function getUserId() {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    return user.userId.substring(1, user.userId.length - 1);
  } else {
    if (document.location.pathname != '/') {
      document.location.href = '/';
    }
  }
}

export function getUserNamDashMeasures(categoryStructure) {
  let data = getUserData();
  if (data) {
    let user = JSON.parse(data);
    if (user?.namDashMeasures) {
      return categoryStructure.toLowerCase() == "retail" ? user?.namDashMeasures?.retail : user?.namDashMeasures?.wholesale;
    }
    return ['value', 'qty']
  } else {
    if (document.location.pathname != '/') {
      document.location.href = '/';
    }
  }
}

export function encodeText(txt) {
  return txt ? txt.replace('&#44;', ',').replace('&#39;', "'") : '';
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const hasSalesInPermissions = page => {
  if (JSON.parse(localStorage.getItem('salesIn_permissions')) === true) {
    let data = getUserData();
    let salesInPermission = [];
    if (data) {
      let user = JSON.parse(data);

      salesInPermission = user.roles.filter(
        permissions =>
          permissions.Title ===
          `role_view_${getUserSystem().toLowerCase()}_${page}_in`,
      );
    }
    return salesInPermission.length > 0;
  }
  return false;
};

export const hasMultipleSalesInPermissions = () => {
  let data = getUserData();
  let salesInPermission = [];
  if (data) {
    let user = JSON.parse(data);

    salesInPermission = user.roles.filter(
      ({ Title }) => Title.slice(Title.lastIndexOf('_')) === `_in`,
    );
  }
  return salesInPermission.length > 3;
};

export const isUAT = () => {
  const url = window.location.hostname.split('.');
  return url.includes('uat') || url.includes('docker') || window.location.hostname === '127.0.0.1';
};

export const hasRetailPermissions = page => {
  let data = getUserData();
  let retailPermission = [];
  if (data) {
    let user = JSON.parse(data);
    retailPermission = user.roles.filter(
      permissions =>
        permissions.Title ===
        `role_view_${getUserSystem().toLowerCase()}_${page}_retail`,
    );
  }
  return retailPermission.length > 0;
};

export const checkIsRetailOnly = () => {
  let data = getUserData();
  let retailOnlyPermission = false;
  if (data) {
    let user = JSON.parse(data);
    retailOnlyPermission = user.roles.find(
      permissions => permissions.Title ===
	          `role_view_${getUserSystem().toLowerCase()}_retail_only`,
    );
  }
  return retailOnlyPermission;
};

export const hasPermission = permission => {
  let data = getUserData();
  let retailPermission = [];
  if (data) {
    let user = JSON.parse(data);

    retailPermission = user.roles.filter(
      permissions => permissions.Title === permission,
    );
  }
  return retailPermission.length > 0;
};

export const hideFor = (...users) => {
  const system = getUserSystem();
  const currUser = users.filter(user => user === system);
  return currUser.length !== 1;
};

export const showFor = (...users) => {
  const system = getUserSystem();
  const currUser = users.filter(user => user === system);
  return currUser.length === 1;
};

export const hex2rgba = (hex, alpha = 0.7) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
