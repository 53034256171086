// @flow

import { encodeText, getUserSystem, getToken, getUserId, isUAT } from './util';

import axios from 'axios';
import assign from 'lodash/assign';
import get from 'lodash/get';
import * as _ from 'lodash';
import theme from '../config/Themes';
import { polyfill } from 'es6-promise';
polyfill();

type ActionFunc = any => Action;
type APIActions = {
  [key: string]: ActionFunc,
};

// const baseURL = 'http://wsale.esands.co.uk';
//LIVE API

// const baseURL = 'https://unitas-api.twcgroup.net';

const baseURL = isUAT() ? theme?.uatApiUrl : theme?.apiUrl;
const suffixParam = ''; //'&XDEBUG_SESSION_START=92E55496 ';

let store;

export const injectStore = _store => {
  store = _store;
};

// For adding header to use different category structure

axios.interceptors.request.use(
  function (config) {
    if (store) {
      if (!config.url.includes('amazonaws')) {
        config.headers.categoryStructure = JSON.stringify(
          store.getState().app.categoryStructure,
        );
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const createApiActions = (type: string): APIActions => ({
  success: (data): Action => ({ type, payload: data }),
  failure: (error: Error) => ({ type, error }),
  pending: (): Action => ({ type }),
});

const metaMaker =
  (options: Options) =>
  (done: boolean): { meta: ActionMeta } => ({
    meta: {
      http: {
        done,
        url: typeof options === 'object' ? options.url : options,
      },
    },
  });

export const requestGet = (axiosOptions: any) =>
  axios({
    url: (axiosOptions.baseURL || baseURL) + axiosOptions.url + suffixParam,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: axiosOptions.data,
  }).then(res => res.data);

export const requestBlob = (axiosOptions: any) =>
  axios({
    url: (axiosOptions.baseURL || baseURL) + axiosOptions.url + suffixParam,
    headers: axiosOptions.headers,
    method: axiosOptions.method,
    responseType: axiosOptions.responseType,
  }).then(res => res);

export const request = (axiosOptions: any) =>
  axios({
    url: (axiosOptions.baseURL || baseURL) + axiosOptions.url + suffixParam,
    headers: axiosOptions.headers,
    method: axiosOptions.method,
    data: axiosOptions.data,
    signal: axiosOptions.signal,
  }).then(res => res.data);

export const customRequest = (axiosOptions: any) =>
  axios({
    url: axiosOptions.url,
    headers: axiosOptions.headers,
    method: axiosOptions.method,
    data: axiosOptions.data,
  }).then(res => res.data);

export const getRequest = (axiosOptions: any) =>
  axios.get(axiosOptions.url, { headers: axiosOptions.headers });

export const apiRequestMiddleware =
  (selector: Function) =>
  (store: Object) =>
  (next: Function) =>
  (action: ActionWithMeta) => {
    const done = get(action, 'meta.http.done', false);

    if (done) {
      const requests = selector(store.getState());
      if (requests[action.meta.http.url]) {
        return;
      }
    }

    return next(action); // eslint-disable-line
  };

export const apiRequest =
  (actionType: string, options: Options) => (dispatch: Function) => {
    const actions = createApiActions(actionType);
    const makeMeta = metaMaker(options);
    const pendingAction = assign(actions.pending(), makeMeta(false));

    if (!dispatch(pendingAction)) {
      return new Promise(resolve => resolve(false)); // TODO: return promise of a pending request
    }

    return request(options)
      .then(data => {
        const successAction = assign(actions.success(data), makeMeta(true));
        dispatch(successAction);

        return data;
      })
      .catch(err => {
        console.warn(err); // eslint-disable-line

        const error = err.response ? err.response : err;
        const failureAction = assign(actions.failure(error), makeMeta(true));
        return dispatch(failureAction);
      });
  };
