import React from 'react';
import PropTypes from 'prop-types';

import pageStandard from '../components/wrappers/pageStandard';

import { API_ENDPOINTS } from '../../AlchemySettings';

import AlchSelect from '../components/AlchUI/AlchSelect2';
import AlchToggleMenu from '../components/AlchUI/AlchToggleMenu';
import AlchContextMenu from '../components/AlchUI/AlchContextMenu';

import TablePerformance from '../components/TablePerformance';
import GraphPerformance from '../components/GraphPerformance';

import { getPerformanceGraphPageBase, getPerformanceGraphData } from '../../helpers/apiMiddleware';
import { CustomError, formatPerc, getRandomNumber, exportTableToCSV } from '../../helpers/helpers';

const {
	categoryPerformanceBase,
	categoryPerformanceData,
	supplierPerformanceBase,
	supplierPerformanceData,
} = API_ENDPOINTS;

class PerformanceGraphPage extends React.Component {
	state = {
		/* Required structure */
		viewBase: {
			messages: {
				update_date: String.prototype,
			},
			selectDropdown: {
				category: Array.prototype,
				subcategory: Array.prototype,
			},
			toggleOptions: {
				timeframe: Array.prototype,
				measure: Array.prototype,
			},
		},

		viewData: {
			table: Array.prototype,
		},

		viewOptions: Object.prototype,
	}

	componentDidUpdate(nextProps) {
		if (nextProps.match.params.subpage !== this.props.match.params.subpage) {
			this.refreshPage();
		}
	}

	/* pageStandard hook */
	shouldUpdateData = (currentOptions, nextOptions)=>{
		/* if not all options are set, there's not enough data to make request */
		if (Object.keys(nextOptions).length < 3) {
			return false;
		}

		return true;
	}

	setPageConfig = ()=>{
		const baseURL = {};
		const dataURL = {};
		const middleware = {
			base: { root: (response)=>{
				const base = getPerformanceGraphPageBase(response);
				if (!base.selectDropdown.category.length && !base.selectDropdown.subcategory.length) {
					throw new CustomError('There are no data for this view');
				}

				return base;
			} },
			data: {
				root: getPerformanceGraphData,
				/* modify request params */
				requests: (params)=>{
					return {
						requestParams: {
							...params,
							type: 'table',
							getValues: true,
						},
					};
				},
			},
		};
		if (this.props.match.params.subpage === 'category_performance') {
			baseURL.root = categoryPerformanceBase;
			dataURL.root = categoryPerformanceData;
		} else if (this.props.match.params.subpage === 'supplier_performance') {
			baseURL.root = supplierPerformanceBase;
			dataURL.root = supplierPerformanceData;
		}

		return {
			baseURL,
			dataURL,
			middleware,
		};
	}

	getTitle = ()=>{
		switch (this.props.match.params.subpage) {
		case 'category_performance':
			return 'Category Performance';
		default:
			return 'Supplier Performance';
		}
	}

	getMostRecentUpdate = ()=> {
		return (
			<em className="c-paragraph c-paragraph--secondary">{this.state.viewBase.messages.update_date}</em>
		);
	}

	exportData = (type)=>{
		if (type === 'csv') {
			exportTableToCSV($('#performance_table'), `${getRandomNumber('export_')}.csv`);
		}

		if (type === 'pdf') {
			let pdfExportIframe = $('#pdf_export');
			// Create pdf export iframe
			if (pdfExportIframe.length === 0) {
				pdfExportIframe = $('<iframe />');
				pdfExportIframe.attr({
					src: 'http://wsale.esands.co.uk/test_pdf.php',
					id: 'pdf_export',
					name: 'pdf',
					style: 'display:none',
				});
				$('body').append(pdfExportIframe);
			}

			pdfExportIframe.contents().find('#table_html').val($('.c-objects-wrap--table').html());
			pdfExportIframe.contents().find('#graph_html').val($('.c-objects-wrap--graph').html());
			pdfExportIframe.contents().find('#title')
				.val(`<h1>Category Performance</h1><h4>${$('.c-paragraph--secondary').text()}</h4>`);
			pdfExportIframe.contents().find('#timeframe').val(this.state.viewOptions.timeframe);
			pdfExportIframe.contents().find('#measure').val(this.state.viewOptions.measure);
			pdfExportIframe.contents().find('#filter').val('');
			pdfExportIframe.contents().find('#filter_type').val('');
			pdfExportIframe.contents().find('#orientation').val('portrait');
			pdfExportIframe.contents().find('#pdf_form').submit();
		}
	}


	render() {
		const viewBase = this.state.viewBase;

		return (
			<div className="o-canvas-wrap">
				<div className="o-canvas">
					<div className="o-canvas__header u-layout u-layout--between">
						<div className="o-canvas__header-item u-layout u-layout--between-start">
							<h2 className="o-canvas__title">{this.getTitle()}
								{this.getMostRecentUpdate()}
							</h2>
							<AlchSelect
								id="category"
								{...this.setControlledComponent('category')}
							>
								{viewBase.selectDropdown.category}
							</AlchSelect>

							<AlchSelect
								id="subCat"
								{...this.setControlledComponent('subCat')}
							>
								{viewBase.selectDropdown.subcategory}
							</AlchSelect>
						</div>
						<div className="o-canvas__header-item u-layout u-layout--start">
							<AlchToggleMenu
								name="timeframe"
								{...this.setControlledComponent('timeframe')}
							>
								{viewBase.toggleOptions.timeframe}
							</AlchToggleMenu>
							<AlchToggleMenu
								name="measure"
								{...this.setControlledComponent('measure')}
							>
								{viewBase.toggleOptions.measure}
							</AlchToggleMenu>
						</div>
						<div className="o-canvas__header-item">
							<AlchContextMenu label="Export" onSelect={this.exportData}>
								{[
									{ id: 'csv', label: 'CSV' },
									{ id: 'pdf', label: 'PDF' },
								]}
							</AlchContextMenu>
						</div>
					</div>
					<div className="o-canvas__body u-layout u-layout--spaced t-brand-a">
						<div className="u-1_2">
							<TablePerformance
								id="performance_table"
								data={this.state.viewData.table}
								measure={this.state.viewOptions.measure}
							/>
						</div>
						<div className="u-1_2">
							<GraphPerformance
								data={this.state.viewData.graph}
								formatter={n=>formatPerc(n)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


PerformanceGraphPage.defaultProps = {
	match: { params: { path: 'category_performance' } },
	systemSettings: {},
};

PerformanceGraphPage.propTypes = {
	match: PropTypes.shape({ params: PropTypes.object, url: PropTypes.string }),
};

export default pageStandard(PerformanceGraphPage);
