import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import Msg from '../components/ToastExportToPDF';
import 'react-select/dist/react-select.css';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import CustomCountSelect from '../components/CustomCountSelect';
import {
  fetchData,
  setDepartment,
  setMember,
  setCategory,
  setSubCategory,
  setLevel4,
  setLevel5,
  setProduct,
  setRegion,
  exportToPDF,
  resetReport,
  loadReport,
  setGroup,
  setBusinessType,
  setIsRegional,
} from '../redux/reducers/areaHeatmapReport';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import AlchToolTip from '../components/AlchUI/AlchToolTip';
import Heatmap from '../components/Heatmap';
import TableHeatmap from '../components/TableHeatmap';
import {
  getUserSystem,
  membersCheck,
  showFor,
  hideFor,
} from '../../helpers/util';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ExportIcon from '../../assets/images/export-icon.svg';
import Loading from '../components/Loading';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import styled from 'styled-components';
import DateLabel from '../components/DateLabel';
import ReportContainer from '../components/ReportContainer';
import Error from '../components/AlchUI/Error';
import { getUserData } from '../../helpers/user';

const LoaderContainer = styled.div`
  margin-bottom: 10rem;
  margin-left: auto;
  margin-right: auto;
`;

ReactModal.setAppElement('.alchemy_app');
const AreaHeatmapReport = ({
  data,
  transformOptions,
  filterId,
  product,
  products,
  productData,
  regionData,
  tableData,
  members: members,
  selectedMembers,
  categoryHierarchy,
  departments,
  department,
  categories,
  category,
  subCategory,
  subCategories,
  level4s,
  level4,
  level5s,
  level5,
  onExportData,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onProductChange,
  onMapRegionClick,
  isLoading,
  onMemberChange,
  toggleExportMenu,
  onRefreshReport,
  onGroupChange,
  groups,
  selectedGroup,
  businessTypes,
  selectedBusinessType,
  onBusinessTypeChange,
  isRegionTable,
  regionalTableData,
  onTableBackClick,
  errorMessage,
  resolveBusinessTypes,
}) => (
  <main>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty c-alchemy-toasty--others`}
    />
    <div className="sub-menu-border bg-white">
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
            <div className="sub-menu__left">
              <h2 className="text-uppercase">
                {' '}
                <AlchToolTip label="Product Distribution Heatmap">
                  <h3>Key:</h3>
                  <ul className="u-reset-list">
                    <li className="u-key u-key--green">
                      Greater than 55% Distribution
                    </li>
                    <li className="u-key u-key--orange">
                      35 - 55 Distribution
                    </li>
                    <li className="u-key u-key--red">
                      Less than 35 Distribution
                    </li>
                  </ul>
                </AlchToolTip>
                <em className="c-paragraph c-paragraph--secondary" />
              </h2>
              <DateLabel weeks={13} />
            </div>
            <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div className="">
                  <button
                    onClick={onRefreshReport}
                    disabled={data.isRefreshDisabled}
                    className={`btn alchemy-btn--others mr-2`}>
                    Refresh <i className="fa fa-refresh" />
                  </button>
                </div>
                <div className="">
                  {getUserSystem() !== 'confex' && (
                    <Dropdown
                      isOpen={data.isExportMenuOpen}
                      toggle={() => toggleExportMenu()}>
                      <DropdownToggle
                        className={`dropddown-toggle-btn alchemy-btn--others`}>
                        Export <img src={ExportIcon} alt="export" />
                      </DropdownToggle>

                      <DropdownMenu>
                        {/* <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('pdf');
                          }}>
                          PDF
                        </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container sub-menu-bottom">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <div className="form-row">
                {departments?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="department"
                      id="department"
                      placeholder="Department"
                      value={department ? department.ID : ''}
                      onChange={onDepartmentChange}
                      options={departments}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {categories?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Category"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="category"
                      id="category"
                      value={category ? category.ID : ''}
                      onChange={onCategoryChange}
                      options={categories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {subCategories?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      className={`c-select-header c-select-header-row--others`}
                      name="subcategory"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      id="subcategory"
                      placeholder="SubCategory"
                      value={subCategory ? subCategory.ID : ''}
                      onChange={onSubCategoryChange}
                      options={subCategories}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {level4s?.length > 1 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Level4"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="level4"
                      id="level4"
                      value={level4 ? level4.ID : ''}
                      onChange={onLevel4Change}
                      options={level4s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}
                {level5s?.length > 1 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Level5"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="level5"
                      id="level5"
                      value={level5 ? level5.ID : ''}
                      onChange={onLevel5Change}
                      options={level5s}
                      valueKey="ID"
                      labelKey="Text"
                    />
                  </div>
                )}

                {products?.length > 0 &&
                  filterId?.toString().length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Product"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="category"
                        id="category"
                        value={product ? product.WVProdCode : ''}
                        onChange={onProductChange}
                        options={products}
                        valueKey="WVProdCode"
                        labelKey="WVProdDesc"
                      />
                    </div>
                  )}

                {hideFor('demo', 'deebee', 'parfetts') &&
                  members &&
                  membersCheck(members) && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name="member"
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members?.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                                isDisabled: parseInt(mem.WholesalerNo) === 9999,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {showFor('demo', 'deebee') &&
                  selectedGroup?.length > 0 &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name="member"
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members?.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {businessTypes?.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <CustomCountSelect
                      className={`c-select-header c-select-header-row--others`}
                      onChangeCallback={ev => onBusinessTypeChange(ev)}
                      name="businessType"
                      id="businessType"
                      isMulti={true}
                      placeholder="Business Type"
                      value={selectedBusinessType}
                      options={resolveBusinessTypes(businessTypes)}
                      type="Business Type"
                      types="Business Types"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReportContainer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Heatmap
              data={productData}
              regionData={regionData}
              onDataRegionClick={onMapRegionClick}
              isLoading={data.clearMarkers}
            />
            {!isLoading && errorMessage && <Error message={errorMessage} />}
          </div>
          {isLoading && tableData && (
            <LoaderContainer>
              <Loading />
            </LoaderContainer>
          )}
          {((tableData?.length) ||
            (regionalTableData?.length)) &&
            !isLoading && (
              <>
                {isRegionTable && (
                  <div className="col-2">
                    <button
                      className="btn alchemy-btn--others"
                      onClick={() => onTableBackClick()}>
                      Back
                    </button>
                  </div>
                )}
                <div className="col-10">
                  <TableHeatmap
                    tableData={isRegionTable ? regionalTableData : tableData}
                    isRegionTable={isRegionTable}
                  />
                </div>
              </>
            )}
        </div>
      </div>
    </ReportContainer>
  </main>
);
function mapStateToProps(state, ownProps) {
  const data = {
    categoryHierarchy: state.areaHeatmapReport.categoryHierarchy,
    departments: state.areaHeatmapReport.departments,
    categories: state.areaHeatmapReport.categories,
    subCategories: state.areaHeatmapReport.subCategories,
    department: state.areaHeatmapReport.department,
    category: state.areaHeatmapReport.category,
    subCategory: state.areaHeatmapReport.subCategory,
    level4s: state.areaHeatmapReport.level4s,
    level4: state.areaHeatmapReport.level4,
    level5s: state.areaHeatmapReport.level5s,
    level5: state.areaHeatmapReport.level5,
    filterId: state.areaHeatmapReport.filterId,
    filter: state.areaHeatmapReport.filter,
    filterValue: state.areaHeatmapReport.filterValue,
    products: state.areaHeatmapReport.products,
    product: state.areaHeatmapReport.product,
    productData: state.areaHeatmapReport.productData,
    regionData: state.areaHeatmapReport.regionData,
    tableData: state.areaHeatmapReport.tableData,
    isExporting: state.areaHeatmapReport.isExporting,
    isExported: state.areaHeatmapReport.isExported,
    pdfURL: state.areaHeatmapReport.pdfURL,
    members: state.areaHeatmapReport.members,
    selectedMembers: state.areaHeatmapReport.selectedMembers,
    isLoading: state.areaHeatmapReport.isLoading,
    groups: state.areaHeatmapReport.groups,
    selectedGroup: state.areaHeatmapReport.selectedGroup,
    businessTypes: state.areaHeatmapReport.businessTypes,
    selectedBusinessType: state.areaHeatmapReport.selectedBusinessType,
    isRegionTable: state.areaHeatmapReport.isRegionTable,
    regionalTableData: state.areaHeatmapReport.regionalTableData,
    errorMessage: state.areaHeatmapReport.errorMessage,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchData,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setLevel5,
    setProduct,
    setRegion,
    exportToPDF,
    resetReport,
    setMember,
    loadReport,
    setGroup,
    setBusinessType,
    setIsRegional,
    setCategoryStructure,
    setDisableCategoryStructure,
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetReport();
      this.props.fetchData();
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.fetchData();
      }
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'Area Heatmap',
            });
          }, 1000);
        }, 1000);
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} />, { autoClose: false });
      }
      this.props.history.listen((location, action) => {
        if (
          this.props.filterId?.length > 0 &&
          location.pathname === '/analysis/area_heatmap'
        ) {
          this.props.resetReport();
        }
      });
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
    clearMarkers: false,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true, clearMarkers: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onMapRegionClick:
      ({ data, handleData, setRegion }) =>
      event => {
        const id = event.feature.id || event.feature.geometry.id;
        setRegion(id);
        handleData({ ...data, isRefreshDisabled: false, clearMarkers: false });
      },
    onTableBackClick:
      ({ data, handleData, setIsRegional }) =>
      event => {
        setIsRegional(false);
        handleData({ ...data, isRefreshDisabled: false, clearMarkers: true });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, setCategoryStructure, setDisableCategoryStructure, selectedMembers }) =>
      opt => {
        if (getUserSystem() == "caterforce") {
          if (opt?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }
        if (members?.length > 2) {
          setMember(opt);
          handleData({ ...data, isRefreshDisabled: false });
        }
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDepartment(0);
        } else {
          setDepartment(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0);
        } else {
          setCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0);
        } else {
          setSubCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBusinessTypeChange:
      ({ setBusinessType, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBusinessType('');
        } else {
          setBusinessType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    resolveBusinessTypes: () => businessTypes => {
      businessTypes.forEach((type, index) => {
        type.Text = type.BusinessName;
        type.ID = index;
      });
      return businessTypes;
    },
    onLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0);
        } else {
          setLevel5(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0);
        } else {
          setLevel4(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onProductChange:
      ({ data, handleData, setProduct }) =>
      selectedOption => {
        setProduct(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onExportData: props => type => {
      if (type === 'print') {
        window.print();
      }

      if (type === 'pdf') {
        var srctext = $('.leaflet-zoom-animated').get(0).outerHTML;
        var re = /<svg ([^>]*)>*/;
        var newtext = srctext.replace(
          re,
          `<svg viewBox="500 100 320 320" width="500" height="686">`,
        );
        let fields =
          `FILTER VALUE: ` + props.filterValue.toUpperCase() + `<br/>`;
        fields += `FILTER: ` + props.filter.toUpperCase() + `<br/>`;
        if (props.product.WVProdDesc) {
          fields +=
            `WVProdDesc: ` + props.product.WVProdDesc.toUpperCase() + `<br/>`;
        }
        let htmlString = `<html>
                            <head>
                              <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                              <style>
                                 .o-canvas__body,h1,p{font-family:"Gotham Light",Helvetica,Verdana;font-weight:100}tbody,tr{border-color:inherit}@font-face{font-family:"Gotham Light";src:url(http://alchemy.todays.co.uk/assets/fonts/gothamlight-e8bf7.woff2) format("woff2"),url(/assets/fonts/gothamlight-66252.woff) format("woff");font-weight:100;font-style:normal;font-display:fallback}@font-face{font-family:"Gotham Book";src:url(http://alchemy.todays.co.uk/assets/fonts/gothambook-a315e.woff2) format("woff2"),url(/assets/fonts/gothambook-57f47.woff) format("woff");font-weight:600;font-style:normal;font-display:fallback}p{font-size:.7em}h1{font-size:1em}table td,table th{font-family:Arial}.o-table{width:95%;margin:0 auto}table{border-spacing:0;border-collapse:separate;font-size:.78125em}table th{padding:.8em;vertical-align:top}tr{display:table-row;vertical-align:inherit}tbody{display:table-row-group;vertical-align:middle}table td{-webkit-font-smoothing:antialiased;border-bottom:1px solid #e0e8ec;height:2em;padding:.5em}.o-table .u-tac{padding-left:.8em}.u-bg--brand{background-color:#bf73ab}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(2){background-color:#fff;border-bottom:none;border-right:0;font-weight:900}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(5){background-color:#fff;font-family:Arial;border-bottom:none;color:#384044;font-size:inherit;font-weight:900}.o-product-performance--product-performance .u-bg--brand,.o-product-performance--product-performance .u-bg--brand-dst{font-size:1.125em;text-align:center}.fa{display:block}.u-bg--brand-dst{background-color:#5a2f50}.u-bg--brand-d{background-color:#8c527e;text-align:center}[class*=u-bg--brand]{color:#fff}.o-product-performance--product-performance tbody td{height:2em;padding:.5em}
                              </style>
                            </head>
                            <body style="margin:0px;padding:0px;">
                                <h1 style="float:left;font-weight: 100;margin-left:10px">PRODUCT DISTRIBUTION HEATMAP</h1>
                                <div style="float:right;-webkit-margin-before: 5px;-webkit-margin-after: 0.67em;-webkit-margin-start: 0px;-webkit-margin-end: 10px;">
                                 <p>${fields}</p>
                                 </div>
                                ${newtext}
                            </body>
                        </html>`;
        props.exportToPDF(htmlString);
      }
    },
  }),
)(AreaHeatmapReport);
