import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { withRouter } from 'react-router';
import AlchSelectableBox from '../../components/AlchUI/AlchSelectableBox';
import 'react-select/dist/react-select.css';
import ColumnReorder from './ColumnReorder';
import PivotTable from './PivotTable';
import StepCrumbs from './StepCrumbs';
import { Link } from 'react-router-dom';
import QueryFilter from '../../components/QueryFilter';
import { ToastContainer, toast } from 'react-toastify';
import * as _ from 'lodash';
import { fetchCategories } from '../../redux/reducers/query';
import { setBlur } from '../../redux/reducers/app';
import {
  getUserSystem,
  getUserRoles,
  hasPermission,
} from '../../../helpers/util';
import Toggle from 'react-toggle';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageVariants } from '../../../config/pageVariants';

const StyledInput = styled.li`
  .c-selectable-box--large--disabled {
    cursor: not-allowed;

    label {
      cursor: not-allowed;
    }
  }
`;

const disabledPivotThemes = [];

const Step2 = ({
  data,
  showBlur,
  onToggleChange,
  saveTempOrder,
  renderSelectBox,
  onHideBlur,
  menuToggle,
  saveFilter,
  builtQuery,
  renderReorderCanvas,
  saveReorderColumns,
  saveReorderData,
  addPivotValue,
  onSearchProducts,
}) => (
  <motion.main
    key={'query-step2-id'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName="c-alchemy-toasty c-alchemy-toasty--query"
    />
    <div className={`container mt-40 ${showBlur ? 'blur' : ''}`}>
      <div className="row">
        <div className="col">
          <div className="query-builder query-builder__steps">
            <h2 className="text-uppercase mb-4">Query Builder</h2>
            <StepCrumbs step={2} />
            <p className="mt-3">
              Use the selectors below to choose the fields you want to report
              on. The funnel allows your to filter the results
            </p>
            <hr />
          </div>
          <div
            className={`query-builder toggle-${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }`}>
            <h2 className="text-uppercase mb-4 mt-4">
              Show Competitor Products - if not selected it will only return
              your own skus.
            </h2>
            <Toggle
              id="Show_Competitor"
              icons={false}
              checked={builtQuery.Show_Competitor === 1}
              onChange={ev => onToggleChange(ev)}
            />
            <hr />
          </div>
          <div className="query-builder">
            <h2 className="text-uppercase">
              Please select the fields to display
            </h2>
            <ul
              className={`radio-toolbar query-radio--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              } o-multiselect u-reset-list u-layout`}>
              {data.fields_to_display.map(renderSelectBox)}
            </ul>
            <hr />
          </div>

          {data.transaction_details &&
            data.transaction_details.length > 0 &&
            getUserSystem() != 'parfetts' && (
              <div className="query-builder">
                <h3 className="text-uppercase">Transaction Details</h3>
                <ul
                  className={`radio-toolbar query-radio--${
                    getUserSystem() === 'unitas' ? 'unitas' : 'others'
                  } o-multiselect u-reset-list u-layout`}>
                  {data.transaction_details.map(renderSelectBox)}
                </ul>
              </div>
            )}

          {getUserRoles().filter(
            role => role.Title === 'role_view_unitas_core_range_reporting',
          ).length > 0 && (
            <div className="query-builder">
              <h2 className="text-uppercase">Core Range fields</h2>
              <ul
                className={`radio-toolbar query-radio--${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                } o-multiselect u-reset-list u-layout`}>
                {data.core_range_fields.map(renderSelectBox)}
              </ul>
              <hr />
            </div>
          )}
          {builtQuery.Pivot_Table_Enabled === 0 && (
            <div className="query-builder">
              <h2 className="text-uppercase">
                Change the column order by dragging them into the correct order
              </h2>
              <ColumnReorder
                orderArray={[...builtQuery.Column_Order]}
                onChange={saveReorderColumns}>
                {builtQuery.Column_Order.map(renderReorderCanvas)}
              </ColumnReorder>
              <hr />
            </div>
          )}
          {!disabledPivotThemes.includes(getUserSystem().toLowerCase()) && (
          <div
            className={`query-builder toggle-${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }`}>
            <h2 className="text-uppercase mb-4 mt-4">
              Display results in pivot table
            </h2>
            <Toggle
              id="Pivot_Table_Enabled"
              icons={false}
              checked={builtQuery.Pivot_Table_Enabled === 1}
              onChange={ev => onToggleChange(ev)}
            />
            <hr />
          </div>
          )}
          {builtQuery.Pivot_Table_Enabled === 1 && (
            <div className="query-builder pivot-table" 
            key={`${JSON.stringify(builtQuery.Pivot_Table.columns) 
            + JSON.stringify(builtQuery.Pivot_Table.rows)
            + JSON.stringify(builtQuery.Pivot_Table.values)}`}>
              <h2 className="text-uppercase">
                Build your pivot table by dragging the chips below into your preferred structure.
              </h2>
              <PivotTable
                orderArray={[...builtQuery.Column_Order]}
                builtQuery={builtQuery}
                onAddValue={addPivotValue}
                onTempOrderChange={saveTempOrder}
                onColChange={saveReorderData}
                onChange={saveReorderColumns}>
              </PivotTable>
              <hr />
            </div>
          )}
          <div className="query-builder mt-5">
            <Link to={`${process.env.APP_ROOT}query/3`}>
              <button
                className={`btn btn-info alchemy-btn--${
                  getUserSystem() === 'unitas' ? 'unitas' : 'others'
                } text-uppercase float-right`}>
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <QueryFilter
      menuToggle={menuToggle}
      hideBlur={onHideBlur}
      filtersAvailable={data.currentFilter}
      filtersSelected={data.currentSelectedFilter}
      name={data.currentFilterObj.list}
      onChange={saveFilter}
      onSearch={
        data.currentFilterObj.list === 'productsForDesc' ||
        data.currentFilterObj.list === 'productsForCode' ||
        data.currentFilterObj.list === 'membersProductCode'
          ? onSearchProducts
          : null
      }
    />
  </motion.main>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.query.isLoading,
    departments: state.query.departments,
    categories: state.query.categories,
    subCategories: state.query.subCategories,
    level4s: state.query.level4s,
    level5s: state.query.level5s,
    brands: state.query.brands,
    productsForDesc: state.query.productsForDesc,
    membersProductCode: state.query.membersProductCode,
    productsForCode: state.query.productsForCode,
    suppliers: state.query.suppliers,
    businessTypes: state.query.businessTypes,
    builtQuery: state.query.builtQuery,
    depots: state.query.depots,
    members: state.query.members,
    offscreenOpen: state.systemSettings.offscreenOpen,
    showBlur: state.app.showBlur,
    supplierReporting: state.query.supplierReporting,
    wholesalerSupplier: state.query.wholesalerSupplier,
    advancedBusinessTypes: state.filters.advancedBusinessTypes,
  };
  return data;
}
const DEFAULT_STATE = {
  fields_to_display: [
    { label: 'Department', name: 'Department', list: 'departments' },
    { label: 'category name', name: 'CategoryName', list: 'categories' },
    { label: 'sub category', name: 'SGDescription', list: 'subCategories' },
    { label: 'level 4', name: 'Level4', list: 'level4s' },

    { label: 'brand name', name: 'Brand', list: 'brands' },
    {
      label: 'product description',
      name: 'ProductDescription',
      list: 'productsForDesc',
    },
    { label: 'product code', name: 'ProductCode', list: 'productsForCode' },
    { label: 'supplier', name: 'SupplierName', list: 'suppliers' },
    { label: 'business type', name: 'BusinessType', list: 'businessTypes' },
    ...(getUserRoles() !== undefined && getUserRoles()?.filter(role => role.Title === 'role_view_caterforce_wholesaler_codes_on_query').length > 0
          ? [
            {
              label: 'member product code',
              name: 'MembersProductCode',
              list: 'membersProductCode',
            },
          ]
        : []),
  ],
  date_fields: [
    { label: 'year number', name: 'YearNumber', list: false },
    { label: 'week number', name: 'WeekNo', list: false },
    { label: 'year week number', name: 'YearNoWeekNo', list: false },
    { label: 'week commencing date', name: 'WeekCommenceDate', list: false },
  ],
  transaction_details: [],
  core_range_fields: [
    { label: 'Core Range Description', name: 'CoreRangeDescription', list: '' },
    { label: 'Core Range Pack Size', name: 'CoreRangePackSize', list: '' },
    { label: 'Core Range Category', name: 'CoreRangeCategory', list: '' },
    { label: 'Core Range PMP', name: 'CoreRangePmp', list: '' },
    { label: 'Core Range England', name: 'CoreRangeEw', list: '' },
    { label: 'Core Range Scotland', name: 'CoreRangeScot', list: '' },
    { label: 'Core Range Northern Ireland', name: 'CoreRangeNi', list: '' },
    { label: 'Core Range Best Seller', name: 'BestSeller', list: '' },
    { label: 'Core Range Type', name: 'CoreRangeType', list: '' },
    { label: 'Core Range Code', name: 'CoreRangeCode', list: '' },
  ],
  currentFilter: [],
  currentFilterObj: {
    list: '',
  },
  currentSelectedFilter: [],
  tmpSelectedProducts: [],
};

const getFilterListKeys = function () {
  let keys = [];
  _.each(
    _.filter(DEFAULT_STATE.fields_to_display, field => field.list !== false),
    field => {
      keys.push(field.list);
    },
  );
  _.each(
    _.filter(DEFAULT_STATE.date_fields, field => field.list !== false),
    field => {
      keys.push(field.list);
    },
  );
  _.each(
    _.filter(DEFAULT_STATE.transaction_details, field => field.list !== false),
    field => {
      keys.push(field.list);
    },
  );
  return keys;
};
export default compose(
  withRouter,
  connect(mapStateToProps, { fetchCategories, setBlur }),
  withState('data', 'handleData', { ...DEFAULT_STATE }),
  lifecycle({
    componentDidMount() {
      let advancedBusinessTypes = this.props.advancedBusinessTypes;
      let accountNames = this.props.accountNames;
      let fieldsToDisplay = this.props.data.fields_to_display;
      let accountNamesObject = [];
      if (advancedBusinessTypes?.length > 0) {
        advancedBusinessTypes = advancedBusinessTypes.map(
          ({ DisplayName, ApiName }) => ({
            label: DisplayName,
            name: ApiName,
            list: `BusType-${ApiName}`,
          }),
        );
        fieldsToDisplay = fieldsToDisplay.filter(
          item => item.name !== 'BusinessType',
        );
      }
      if (accountNames?.length > 0) {
        accountNamesObject = [
          {
            label: 'account number',
            name: 'AccountNames',
            list: `accountNames`,
          },
        ];
      }
      let nonCRGFields = [];
      if (getUserSystem() === 'countryRangeGroup') {
        this.props.handleData({
          ...this.props.data,
          fields_to_display: [
            ...fieldsToDisplay,
            {
              label: 'group supplier',
              name: 'SupplierReporting',
              list: 'supplierReporting',
            },
            {
              label: 'wholesaler supplier',
              name: 'WholesalerSupplier',
              list: '',
            },
            ...advancedBusinessTypes,
          ],
          transaction_details: [
            {
              label: 'Member',
              name: 'WholesalerName',
              list: 'members',
              viewIn: 'countryRangeGroup',
            },
          ],
        });
      }
      if (getUserSystem() !== 'countryRangeGroup') {
        nonCRGFields = [
          { label: 'member', name: 'WholesalerName', list: '' },
          { label: 'depot', name: 'Depot', list: '' },
          { label: 'pack', name: 'Pack', list: '' },
          { label: 'size', name: 'Size', list: '' },
          { label: 'measure', name: 'Measure', list: '' },
          { label: 'pmp', name: 'PMP', list: '' },
        ];
        if (getUserSystem() !== 'caterforce') {
          this.props.handleData({
            ...this.props.data,
            fields_to_display: [
              ...fieldsToDisplay,
              { label: 'level 5', name: 'Level5', list: 'level5s' },
              ...(getUserSystem() == 'united'
                ? [
                  {
                    label: 'core range',
                    name: 'CoreRange',
                    list: false,
                  },
                ]
              : []),
              ...nonCRGFields,
              ...advancedBusinessTypes,
              ...accountNamesObject,
            ],
          });
        }
      }

      if (getUserSystem() === 'caterforce') {
        this.props.handleData({
          ...this.props.data,
          fields_to_display: [
            ...fieldsToDisplay,
            {
              label: 'group reporting',
              name: 'SupplierReporting',
              list: 'supplierReporting',
            },
            { label: 'level 5', name: 'Level5', list: 'level5s' },
            ...nonCRGFields,
            ...advancedBusinessTypes,
          ],
        });
      }

      if (this.props.builtQuery.newDateRanges.length === 0) {
        this.props.history.push('/query/1/');
      }
      this.props.categories.length === 0 && this.props.fetchCategories();
      if (
        getUserRoles().filter(
          role => role.Title === 'role_view_unitas_ht_drinks',
        ).length > 0
      ) {
        this.props.handleData({
          ...this.props.data,
          fields_to_display: [
            ...fieldsToDisplay,
            {
              label: 'ht drinks customer category',
              name: 'HTDrinksCustomerCategory',
              list: '',
            },
          ],
        });
      }
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading. Please wait...');
      }
    },
  }),
  withHandlers({
    onHideBlur: props => () => {
      props.setBlur(false);
    },
    renderSelectBox: props => (select, i) => {
      let otherMembers = false;
      if (typeof props.builtQuery.Wholesalers[0] !== 'undefined') {
        otherMembers =
          props.builtQuery.Wholesalers.filter(
            memb => parseInt(memb.value) === 9999,
          ).length > 0;
      }
      // If other members is selected on previous step, then render a disabled select box
      const disabledSelectBox =
        (otherMembers && select.label === 'depot') ||
        (otherMembers && select.label === 'member');
      if (
        ((props.theme.name === 'cjlang' || props.theme.name === 'caterforce') &&
          select.label === 'depot') ||
        (props.theme.name === 'cjlang' && select.label === 'member')
      ) {
        return;
      }

      if (props.theme.name === 'caterforce' && select.label === 'pmp') {
        return;
      }

      // Hide business types filter if user has 'role_view_caterforce_hide_business_type_dropdown' permission or is cjlang
      if (
        (props.theme.name === 'cjlang' || props.theme.name === 'unitas') &&
        select.label === 'business type'
      ) {
        return;
      }

      if (
        select.label === 'business type' &&
        hasPermission('role_view_caterforce_hide_business_type_dropdown')
      ) {
        return;
      }

      // Hide supplier reporting if they do not have permission
      if (
        (select.label === 'supplier reporting' ||
          select.label === 'group reporting') &&
        !hasPermission(
          `role_view_${getUserSystem().toLowerCase()}_supplier_group`,
        )
      ) {
        return;
      }
      const on = props.builtQuery[`Show_${select.name}`] === 1;
      const viewIn = select.viewIn || 'all';
      if (viewIn !== 'all' && viewIn !== getUserSystem()) {
        return null;
      }
      let label = <span>{select.label}</span>;
      if (select.list) {
        const isFiltered =
          (select.list === 'productsForDesc' &&
            props.builtQuery['Filter_ProductDescription']?.length > 0) ||
          (select.list === 'membersProductCode' && 
            props.builtQuery['Filter_MemberProductCode']?.length > 0) || 
          (select.list === 'productsForCode' &&
            props.builtQuery['Filter_ProductCode']?.length > 0) ||
          (select.list === 'departments' &&
            props.builtQuery['Filter_Department']?.length > 0) ||
          (select.list === 'categories' &&
            props.builtQuery['Filter_CategoryName']?.length > 0) ||
          (select.list === 'subCategories' &&
            props.builtQuery['Filter_SGDescription']?.length > 0) ||
          (select.list === 'level4s' &&
            props.builtQuery['Filter_Level4']?.length > 0) ||
          (select.list === 'level5s' &&
            props.builtQuery['Filter_Level5']?.length > 0) ||
          (select.list === 'brands' &&
            props.builtQuery['Filter_Brand']?.length > 0) ||
          (select.list === 'suppliers' &&
            props.builtQuery['Filter_SupplierName']?.length > 0) ||
          (select.list === 'businessTypes' &&
            props.builtQuery['Filter_BusinessType']?.length > 0) ||
          (select.list === 'depots' &&
            props.builtQuery['Filter_DepotName']?.length > 0) ||
          (select.list === 'supplierReporting' &&
            props.builtQuery['Filter_SupplierReporting']?.length > 0) ||
          (select.list === 'wholesalerSupplier' &&
            props.builtQuery['Filter_WholesalerSupplier']?.length > 0) ||
          (select.list === 'members' &&
            props.builtQuery['Filter_WholesalerName']?.length > 0) ||
          (select.list === 'accountNames' &&
            props.builtQuery['Filter_AccountNames']?.length > 0) ||
          (select.list.includes('BusType-') &&
            props.builtQuery[`Filter_${select.name}`]?.length > 0);
        label = (
          <span>
            <button
              className={`c-selectable-box__sub u-icon u-icon--filter ${
                isFiltered && 'u-icon--filter__filtered'
              }`}
              onClick={() => {
                props.menuToggle('query-filter');
                if (props.offscreenOpen?.includes('query-filter')) {
                  return;
                }
                let response = '';
                let name = '';
                //TODO: Add member product code here
                switch (true) {
                  case select.list === 'departments':
                    response = props.departments;
                    name = 'Department';
                    break;
                  case select.list === 'categories':
                    response = props.categories;
                    name = 'CategoryName';
                    break;
                  case select.list === 'subCategories':
                    response = props.subCategories;
                    name = 'SGDescription';
                    break;
                  case select.list === 'level4s':
                    response = props.level4s;
                    name = 'Level4';
                    break;
                  case select.list === 'level5s':
                    response = props.level5s;
                    name = 'Level5';
                    break;
                  case select.list === 'brands':
                    response = props.brands;
                    name = 'Brand';
                    break;
                  case select.list === 'productsForDesc':
                    response = props.productsForDesc;
                    name = 'ProductDescription';
                    break;
                  case select.list === 'membersProductCode':
                    response = props.membersProductCode
                    name = 'MembersProductCode'
                    break; 
                  case select.list === 'productsForCode':
                    response = props.productsForCode;
                    name = 'ProductCode';
                    break;
                  case select.list === 'suppliers':
                    response = props.suppliers;
                    name = 'SupplierName';
                    break;
                  case select.list === 'businessTypes':
                    response = props.businessTypes;
                    name = 'BusinessType';
                    break;
                  case select.list === 'depots':
                    response = props.depots;
                    name = 'DepotName';
                    break;
                  case select.list === 'supplierReporting':
                    response = props.supplierReporting;
                    name = 'SupplierReporting';
                    break;
                  case select.list === 'wholesalerSupplier':
                    response = props.wholesalerSupplier;
                    name = 'WholesalerSupplier';
                    break;
                  case select.list === 'members':
                    response = props.members.map(member => {
                      return {
                        fieldKey: member.WholesalerNo,
                        fieldValue: member.ActualName,
                      };
                    });
                    name = 'WholesalerName';
                    break;
                  case select.list === 'accountNames':
                    response = props.accountNames.map(
                      ({ RegNo, CustomerName }) => ({
                        fieldKey: RegNo,
                        fieldValue: CustomerName,
                      }),
                    );
                    name = select.name;
                    break;
                  case select.list.includes('BusType-'):
                    response = props.advancedBusinessTypes
                      ?.find(type => type.ApiName === select.name)
                      ?.values?.map(({ value, label }) => ({
                        fieldKey: label,
                        fieldValue: value,
                      }));
                    name = select.name;
                    break;
                }

                if (!props.offscreenOpen?.includes('query-filter')) {
                  props.setBlur(true);
                } else {
                  props.setBlur(false);
                }
                props.handleData({
                  ...props.data,
                  currentFilterObj: select,
                  currentFilter:
                    props.builtQuery['Filter_' + name] === ''
                      ? response
                      : _.differenceWith(
                          response,
                          props.builtQuery['Filter_' + name],
                          (arr1, arr2) =>
                            arr1.fieldKey === arr2.fieldKey &&
                            arr1.fieldValue === arr2.fieldValue,
                        ),
                  currentSelectedFilter:
                    props.builtQuery['Filter_' + name] === ''
                      ? []
                      : props.builtQuery['Filter_' + name],
                });
              }}>
              filter
            </button>
            {select.label}
          </span>
        );
      }

      return disabledSelectBox ? (
        <StyledInput key={i}>
          <Tooltip
            trigger="mouseenter"
            position="bottom"
            animation="scale"
            theme="green"
            arrow="true"
            title={`You are unable to select ${select.name} if you have select Others in step 1`}>
            <AlchSelectableBox
              label={label}
              id={select.name}
              className="c-selectable-box--large c-selectable-box--large--disabled"
              on={on}
              onChange={(val, id) => {
                props.updateQueryFieldVisibility(
                  id,
                  val,
                  select.label,
                  select.name,
                );
              }}
              isDisabled={disabledSelectBox}
            />
          </Tooltip>
        </StyledInput>
      ) : (
        <StyledInput key={i}>
          <AlchSelectableBox
            label={label}
            id={select.name}
            className="c-selectable-box--large"
            on={on}
            onChange={(val, id) => {
              props.updateQueryFieldVisibility(
                id,
                val,
                select.label,
                select.name,
              );
            }}
            isDisabled={false}
          />
        </StyledInput>
      );
    },
    saveFilter: props => filters => {
      props.saveFilter(filters, props.data.currentFilterObj.name);
    },
    onSearchProducts: props => (keyword, selectedProducts) => {
      props.onSearchProducts(keyword, props.data.currentFilterObj.list);
      props.handleData({
        ...props.data,
        tmpSelectedProducts: selectedProducts,
      });
    },
    renderReorderCanvas:
      ({ data, handleData }) =>
      item => {
        return (
          <li key={`${item.sort_name}`} data-label={`${item.sort_name}`}>
            <AlchSelectableBox
              label={item.label}
              id={item.sort_name}
              className="c-selectable-box"
              on={false}
            />
          </li>
        );
      },
    saveTempOrder: props => order => {
      props.updateField('tempPrePivotOrder', order);
    },
    onToggleChange: props => ev => {
      const { id } = ev.target;
      const newVal = props.builtQuery[id] === 0 ? 1 : 0;
      if (id == "Pivot_Table_Enabled" && newVal === 0) {
        props.updateField('Column_Order', props.builtQuery.tempPrePivotOrder);
        props.updateField('Pivot_Table', {
          columns: [],
          rows: [],
          values: [],
        })
      } else if (id == "Pivot_Table_Enabled" && newVal === 1) {
        props.updateField('tempPrePivotOrder', props.builtQuery.Column_Order);
        props.builtQuery.Column_Order.push({
          label: 'Reporting Measure', 
          name: 'ReportingMeasure' , 
          resultset: 'reportingMeasure', 
          sort_name: 'reportingMeasure'
      },
      {
          label: "Year",
          name: 'Year',
          resultset: 'year',
          sort_name: 'year'
      });
      }

      //TODO: Check if id is pivot table and if the old value is 1 (so it's being turned off) erase all pivot data
      props.updateField(id, newVal);
    },
  }),
  withPropsOnChange(getFilterListKeys(), props => {
    //if filter selected then only update
    if (props.data.currentFilterObj.list.length) {
      let name = props.data.currentFilterObj.name;
      let response = props[props.data.currentFilterObj.list];
      let filtersSelected = props.builtQuery['Filter_' + name] || [];
      if (
        props.data.currentFilterObj.list === 'productsForDesc' ||
        props.data.currentFilterObj.list === 'productsForCode' ||
        props.data.currentFilterObj.list === 'membersProductCode' 
      ) {
        filtersSelected = [...props.data.tmpSelectedProducts];
      }
      if (props.data.currentFilterObj.list === 'productsForCode') {
        props.handleData({
          ...props.data,
          currentFilter:
            filtersSelected.length === 0
              ? response
              : _.differenceWith(
                  response,
                  filtersSelected,
                  (arr1, arr2) => arr1.fieldKey === arr2.fieldKey,
                ),
          currentSelectedFilter: filtersSelected,
        });
      } else {
        props.handleData({
          ...props.data,
          currentFilter:
            filtersSelected.length === 0
              ? response
              : _.differenceWith(
                  response,
                  filtersSelected,
                  (arr1, arr2) =>
                    arr1.fieldKey === arr2.fieldKey &&
                    arr1.fieldValue === arr2.fieldValue,
                ),
          currentSelectedFilter: filtersSelected,
        });
      }
    }
  }),
)(Step2);
