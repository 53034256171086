import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from '../../components/navigations/BottomNav';
import { getSubmenuPages } from '../../../helpers/api';

import CampaignsList from '../PSA/campaigns-list';
import Campaign from '../PSA/campaign';
import DistributionLists from '../PSA/distribution-lists';
import UploadDetails from '../PSA/upload-details';
import ViewCampaigns from '../PSA/view-campaigns';

export default class PSA extends React.Component {
        state = {
                subpage_nav: [],
        }

        render() {
                return (
                                <Switch>
                                        <Route
                                          path={`${process.env.APP_ROOT}psa/campaigns`}
                                          render={pageProps=>(<CampaignsList {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}psa/campaign/:id?`}
                                          render={pageProps=>(<Campaign {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}psa/distribution-lists`}
                                          render={pageProps=>(<DistributionLists {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}psa/view-campaigns/:id?`}
                                          render={pageProps=>(<ViewCampaigns {...this.props} {...this.pageProps} />)}
                                        />
                                        <Route
                                          path={`${process.env.APP_ROOT}psa/upload-details/:id`}
                                          render={pageProps=>(<UploadDetails {...this.props} {...this.pageProps} />)}
                                        />
                                        <Redirect
                                                from={`${process.env.APP_ROOT}psa`}
                                                to={`${process.env.APP_ROOT}psa/campaigns`}
                                        />
                                </Switch>
                );
        }
}

PSA.defaultProps = {
        location: {},
};

PSA.propTypes = {
        alertAdd: PropTypes.func.isRequired,
        location: PropTypes.shape({ pathname: PropTypes.string }),
};

