const Menu = [
    {
        name: 'Content and Adverts',
        icon: 'drivers-license-o',
      path: '/pfp/adverts'
    },
    // {
    //     name: 'Deals',
    //     icon: 'flash',
    //   path: '/pfp/deals'
    // },
    {
        name: 'Dropship',
        icon: 'ship',
      path: '/pfp/dropship'
    },
    {
        name: 'Core Range',
        icon: 'tty',
      path: '/pfp/corerange'
    },
    {
        name: 'Npd',
        icon: 'comment-o',
      path: '/pfp/npd'
    },
    {
      name: 'Planogram',
      icon: 'area-chart',
    path: '/pfp/planogram'
  },
  {
    name: 'Wholesalers',
    icon: 'building',
  path: '/pfp/wholesalers'
},
];

export default Menu;
