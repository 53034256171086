export const spaceAndRange = {
  page: {
    tooltip:
      'This report shows category, supplier or brand performance compared to the number of sku sold.  This allows you analyse range and space performance to see if you are under or over ranged for particular categories, suppliers or brands',
  },
  bars: {
    value:
      'The total turnover of the combined stores, split by category, supplier or brand.  In category view, click to drill into a particular category',
    sku:
      'The distinct count of skus, sold spilt by category, supplier or brand.  In category view, click to drill into a particular category',
  },

  table: {
    title: 'Explanation Title',
    body:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
    units:
      'The total number of retail units sold during the timeframe (This year v Last year)',
    value: 'The total sales value for the timeframe (This year v Last year)',
    marketShare:
      'The % share, based on TY sales value, that the given category, supplier or brand has (depending on selection)',
    baskets:
      'The total number of baskets sold during the time period containing the given category, supplier or brand (depending on selection)',
    avgBasket:
      'When a basket contains the category, supplier or brand (depending on selection), how much on average is spent on that given category, supplier or brand ',
  },
};

export const skuPerformance = {
  page: {
    tooltip:
      'Retail Product  Performance for the time filters selected against key performance metrics',
  },
  tableHeaders: {
    quantity: 'Number of retail units of the product sold during the timeframe',
    summaryValue:
      'The total sales value of the product sold during the timeframe',
    unitsPerWeek:
      'The average number of retail units sold per week during the timeframe',
    storeCount:
      'The number of stores that sold the product during the timeframe',
    avUnitPrice: 'The average sales price per retail unit during the timeframe',
    contributionPerStorePerWeek:
      'The average turnover per store, per week for the product during the time period',
    cvTotal:
      'Category Weighted Distribution for that product for that timeframe. Category Weighted Distribution gives an opportunity to understand the quality of distribution. It is a percentage of stores that sell the product from the total number of stores weighted by store turnover for the category of product',
    acvTotal:
      'Percentage ACV Distribution for that product for that timeframe – All Category Value (ACV) Distribution shows the percentage of stores selling a product weighted by stores turnover of all products',
  },
};

export const salesByWeek = {
  page: {
    tooltip:
      'Make a selection with or without a product filter - view Category performance, supplier or brand performance or product to see year on year sales by week.',
  },
};

export const bostonMatrixCategories = {
  page: {
    tooltip:
      'This Boston Matrix positions categories based on their Share and Growth, relative to the overall performance of stores.  The overall turnover of a category is indicated by the size of the bubble within the quadrant',
  },
  graph: {
    questionMarks:
      'Question Marks are categories with Low Share but High Growth',
    stars: 'Stars are categories with High Share and High Growth',
    dogs: 'Dogs are categories with Low Share and Low Growth',
    cows: 'Cash Cows are categories with High Share and Low Growth',
  },
};

export const bostonMatrixStores = {
  page: {
    tooltip:
      'This Boston Matrix positions stores based on their Share and Growth, relative to the overall performance of stores.  The overall turnover of a category is indicated by the size of the bubble within the quadrant.  Click on the quadrant Section Header to get more details on the individual stores within that performance quadrant.',
  },
  graph: {
    questionMarks: 'Question Marks are stores with Low Share but High Growth',
    stars: 'Stars are stores with High Share and High Growth',
    dogs: 'Dogs are stores with Low Share and Low Growth',
    cows: 'Cash Cows are stores with High Share and Low Growth',
  },
};

export const basketOverview = {
  page: {
    warning: 'This report excludes Commision Sales',
    tooltip:
      'This page gives a top level overview of Basket Analysis for your selected stores',
  },
};

export const basketProductAnalysis = {
  page: {
    tooltip: '',
  },
  tableHeaders: {
    name: 'Name of the Product Purchased',
    quantity: 'Number of retail units of the product sold during the timeframe',
    visits:
      'The number of customers that have purchased this product (measured in baskets)',
    productPenetration:
      'The percentage of baskets that have included this product along with one or more products from your filter',
    support:
      'How frequently the collection of items occur together as a percentage of all transactions',
    confidence:
      'How often an item appears in transactions that contain of your filters',
    lift:
      'How much confidence has increased that this product will be purchased when a basket contains your flters',
  },
};

export const supplierEnagagementSubCategorySupplierTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    categoryChange:
      'The Category percentage change is in Sales TY v LY for the selected time period',
  },
};

/*
export const supplierEnagagementSubCategorySupplierTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY:
      'The Supplier share this year',
    supplierShareLY:
      'The Supplier share last year',
   },
};*/

export const supplierEnagagementSupplierSupplierTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
  },
};

export const supplierEnagagementSupplierBrandTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
  },
};

export const supplierEnagagementCategoryBrandTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
  },
};

export const supplierEnagagementMemberCategoryTable = {
  tableHeaders: {
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
    opportunityOfShare:
      'The value that the supplier would generate if they achieved the share in line with their average share',
    growthOpportunity:
      'The value that the supplier would generate if they performed at the level of the market',
  },
};

export const supplierEnagagementMemberDriversOfPerformance = {
  tableHeaders: {
    customerPenetration: 'The percentage of customers',
    changeOfCustomers:
      'The percentage change in the number of customers, this year compared to last year ',
    rateofSale:
      'The average number of products that each customer has bought during this time frame',
    changeInRateOfSale:
      'The percentage difference in the average number of products that each customer has bought during this time frame',
    avPrice: 'The Average Price of each case for the time period',
    avPriceChange:
      'The change in the Average Price this year compared to last year',
    changeInSkuCount:
      'The change in the number of skus this year compared to last',
  },
};

export const supplierEnagagementSupplierDriversOfPerformance = {
  tableHeaders: {
    customerPenetration: 'The percentage of customers',
    changeOfCustomers:
      'The percentage change in the number of customers, this year compared to last year ',
    rateofSale:
      'The average number of products that each customer has bought during this time frame',
    changeInRateOfSale:
      'The percentage difference in the average number of products that each customer has bought during this time frame',
    avPrice: 'The Average Price of each case for the time period',
    avPriceChange:
      'The change in the Average Price this year compared to last year',
    changeInSkuCount:
      'The change in the number of skus this year compared to last',
  },
};

export const supplierEnagagementBrandDriversOfPerformance = {
  tableHeaders: {
    customerPenetration: 'The percentage of customers',
    changeOfCustomers:
      'The percentage change in the number of customers, this year compared to last year ',
    rateofSale:
      'The average number of products that each customer has bought during this time frame',
    changeInRateOfSale:
      'The percentage difference in the average number of products that each customer has bought during this time frame',
    avPrice: 'The Average Price of each case for the time period',
    avPriceChange:
      'The change in the Average Price this year compared to last year',
    changeInSkuCount:
      'The change in the number of skus this year compared to last',
  },
};

export const supplierEnagagementSubCategoryDriversOfPerformance = {
  tableHeaders: {
    customerPenetration: 'The percentage of customers',
    changeOfCustomers:
      'The percentage change in the number of customers, this year compared to last year ',
    rateofSale:
      'The average number of products that each customer has bought during this time frame',
    changeInRateOfSale:
      'The percentage difference in the average number of products that each customer has bought during this time frame',
    avPrice: 'The Average Price of each case for the time period',
    avPriceChange:
      'The change in the Average Price this year compared to last year',
    changeInSkuCount:
      'The change in the number of skus this year compared to last',
  },
};

export const supplierEnagagementSubCategoryOpportunity = {
  tableHeaders: {
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
    opportunityOfShare:
      'The value that the supplier would generate if they achieved the share in line with their average share',
    growthOpportunity:
      'The value that the supplier would generate if they performed at the level of the market',
  },
};
export const supplierEnagagementMemberSupplierTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    categoryChange:
      "The Category Change (This Year's Category Share v Last Year's Share)",
  },
};

export const supplierEnagagementKPI = {
  tableHeaders: {
    ty: 'Value for the period selected (this year)',
    ly: 'Value for the period selected (last year)',
    supplierChange: 'The suppliers year on year change for this matric',
    categoryChange: 'The suppliers year on year change for this matric',
    sizeOfPrize:
      'The amount this matric has contributed to the overall year on year performance for the supplier',
  },
  rowHeaders: {
    Value: 'The (£) value for the given time frame',
    Volume:
      'The (£) value for the given time frameThe number of cases sold in the given time frame',
    'Value Share of Category': 'The suppliers share of the category, by value',
    'Volume Share of Category':
      'The suppliers share of the category, by volume',
    'Category Customer Size':
      'The distinct number of customers that purchase this category',
    'Supplier Customer Size':
      'The distinct number of customers that purchase the suppliers products in this  category',
    'Category Penetration':
      'The percentage of customers that purchase products in category, and purchase the suppliers products in this category',
    'Category Average Price': 'The average price of a case in the category',
    'Supplier Average Price':
      'The average price of a suppliers case in the category',
    'Trip Frequency':
      'The average price of a case in the categoryThe average price of a suppliers case in the categoryHow many times, on average, the typical customer has purchased during the time period selected',
    'Cases per Trip': 'How many cases a customer buys, on average, each trip',
    ROS:
      'The total number of cases a customer buys, on average, for the selected time period',
  },
};

export const supplierEnagagementCategoryMemberTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    supplierPointChange:
      "The Supplier Share Point Change (This Year's Supplier Share divided by Last Year's Supplier Share)",
  },
};
export const supplierEnagagementProductTable = {
  tableHeaders: {
    salesTY: 'Sales for the period selected (this year)',
    salesLY: 'Sales for the period selected (last year)',
    actualDifference:
      'The year on year difference compared to last years sales (in £s)',
    change:
      'The Suppliers percentage change is in Sales TY v LY for the selected time period',
    supplierShareTY: 'The Supplier share this year',
    supplierShareLY: 'The Supplier share last year',
    shareDifference:
      "The Supplier Share Difference (This Year's Supplier Market minus last years)",
  },
};
