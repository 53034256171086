import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import NavMainMenu from './NavMainMenu';
import NavMainMobileMenu from './NavMainMobileMenu';
import { LogoUrlCountryRangeGroup } from '../../../AlchemySettings';
import {
  getToken,
  getUserId,
  getUserSystem,
  getUserRoles,
  hideFor,
  isUAT,
  hasPermission,
} from '../../../helpers/util';
import { request } from '../../../helpers/axios';
import LogoUrl from '../../../assets/images/alchemy-logo-minimised.svg';
import DashboardLogo from '../../../assets/images/dashboard-logo.svg';
import DashboardLogoBlack from '../../../assets/images/dashboard-logo-black.svg';
import UserLogo from '../../../assets/images/user.svg';
import UserLogoBlack from '../../../assets/images/user-black.svg';
import LogoutLogo from '../../../assets/images/logout.svg';
import LogoutLogoBlack from '../../../assets/images/logout-black.svg';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import BrandLogo from '../util/BrandLogo';
import { setCategoryStructure } from '../../redux/reducers/app';
import Toggle from 'react-toggle';

const FlexOne = styled.div`
  flex: 1;
`;

const FlexTwo = styled.div`
  flex: 2;
`;

const UtilitiesContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${getUserSystem() !== 'caterforce' ||
  getUserSystem() !== 'cjlang' || getUserSystem() !== 'cjlangRetail'
    ? 'flex-end'
    : 'space-between'};
`;

class TopNav extends React.Component {
  state = {
    isUserMenuOpen: false,
  };

  renderMainMenu = () => {
    if (this.props.currentPage !== 'dashboard') {
      return (
        <NavMainMenu
          page={this.props.currentPage}
          showQBPopUp={val => this.props.showQBPopUp(val)}
          goToURL={url => {
            this.props.goToURL(url);
          }}
        />
      );
    }
    return null;
  };

  renderMainMobileMenu = () => {
    if (this.props.currentPage !== 'dashboard') {
      return <NavMainMobileMenu page={this.props.currentPage} />;
    }
    return null;
  };
  
  toggleUserMenu() {
    this.setState({
      ...this.state,
      isUserMenuOpen: !this.state.isUserMenuOpen,
    });
  }

  logout = () => {
    this.props.logoutUser();
    localStorage.removeItem('user_data');
    localStorage.removeItem('system');
    localStorage.removeItem('menus');
    localStorage.removeItem('drill_data');
    this.props.logout();
  };

  render() {
    const system = getUserSystem();
    let logoImg;
    let logoClass = 'o-top-nav__item--logo';
    if (system === 'countryRangeGroup') {
      logoImg = LogoUrlCountryRangeGroup;
      logoClass = 'o-top-nav__item--logo1';
    }
    const { showBlur, showAlert, label } = this.props;

    return (
      <header className={showBlur ? 'blur' : ''}>
        <div
          className={`container-fluid d-flex justify-content-between align-items-center bg-dark bg-dark--${
            getUserSystem() === 'unitas' ? 'unitas' : 'others'
          }  top-row pt-0 pl-0`}>
          <FlexOne className="top-row__left d-flex align-items-center mb-3 mb-md-0">
            <BrandLogo isInTopNav />

            <div
              className={`mr-2 ${this.props.theme.name === 'twc' && 'my-2'}`}>
              <img src={LogoUrl} alt="alchemy logo" />
            </div>
            <div
              className={`top-row__date ${
                this.props.theme.name === 'twc' ? 'my-2' : ''
              }`}>
              <span className="text-uppercase d-block">Displaying Date To</span>
              <span className="text-uppercase">W/E: {label}</span>

              {showAlert && hideFor('demo', 'countryRangeGroup') && (
                <Link to="/likeforlike/status_report">
                  <i className="fa fa-exclamation-triangle"></i>
                </Link>
              )}
            </div>
          </FlexOne>
          <FlexTwo className="top-row__middle d-none d-md-block">
            {this.renderMainMenu()}
          </FlexTwo>
          <UtilitiesContainer className="top-row__right">
            <div className="ml-3 mr-1 d-flex align-items-center">
              {hasPermission(
                `role_view_${getUserSystem().toLowerCase()}_wholesaler_category_hierarchy`,
              ) && (
                <div className="mb-0 mr-3 d-flex">
                  <h2 className={`${this.props.theme.isIconBlack ? 'text-black' : 'text-white' } mb-0 mr-2`}>
                    {this.props.categoryStructure ? 'Local ' : 'Smart '}View
                  </h2>

                  <Toggle
                    id={`category_structure`}
                    className=""
                    icons={false}
                    disabled={getUserSystem() == 'caterforce' && this.props.categoryStructureDisabled}
                    checked={this.props.categoryStructure}
                    onChange={ev => this.props.onCategoryStructureChange(ev)}
                  />
                </div>
              )}
              {isUAT() && <h2 className={`${this.props.theme.isIconBlack ? 'text-black' : 'text-white' } mb-0 `}>DEMO</h2>}
            </div>

            <div>
              <img
                src={
                  this.props.theme.isIconBlack
                    ? DashboardLogoBlack
                    : DashboardLogo
                }
                alt="dashboard logo"
              />
              {this.props.currentPage !== 'supplier_select' &&
                this.props.currentPage !== 'dashboard' &&
                this.props.currentPage !== 'forgot_password' && (
                  <Dropdown
                    isOpen={this.state.isUserMenuOpen}
                    toggle={() => this.toggleUserMenu()}
                    className="user-dropdown vertical-auto-margin">
                    <DropdownToggle className="dropddown-toggle-btn">
                      <img
                        src={
                          this.props.theme.isIconBlack
                            ? UserLogoBlack
                            : UserLogo
                        }
                        alt="user"
                        className="top-row__icon ml-2 mr-2 dropdown-toggle"
                      />
                    </DropdownToggle>
                    <DropdownMenu style={{ zIndex: 9999 }}>
                      {getUserSystem() !== 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role => role.Title === 'role_system_admin',
                        ).length > 0 && (
                          <DropdownItem tag={Link} to="/admin/classification">
                            Cleansing
                          </DropdownItem>
                        )}
                      {getUserRoles().filter(
                        role =>
                          role.Title === 'role_system_admin' ||
                          role.Title === 'role_wholesaler_admin',
                      ).length > 0 && (
                        <DropdownItem tag={Link} to="/list_users">
                          List Users
                        </DropdownItem>
                      )}
                      {getUserRoles().filter(
                        role =>
                          role.Title === 'role_system_admin' ||
                          role.Title === 'role_wholesaler_admin',
                      ).length > 0 && (
                        <DropdownItem tag={Link} to="/create_user/user">
                          Create User
                        </DropdownItem>
                      )}
                      {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem tag={Link} to="/qa_report">
                            QA Report
                          </DropdownItem>
                        )}
                        {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem tag={Link} to="/qa_report_weeks_in">
                            QA Report (in)
                          </DropdownItem>
                        )}
                      {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem tag={Link} to="/qa_report_days_out">
                            QA Report - Missing Days (out)
                          </DropdownItem>
                        )}
                      {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem tag={Link} to="/qa_report_days_in">
                            QA Report - Missing Days (in)
                          </DropdownItem>
                        )}
                      {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem
                            tag={Link}
                            to="/customer_compliance_report">
                            Customer Compliance Report
                          </DropdownItem>
                        )}
                      {getUserSystem() !== 'unitas' &&
                        getUserSystem() === 'countryRangeGroup' &&
                        getUserRoles().filter(
                          role =>
                            role.Title === 'role_system_admin' ||
                            role.Title === 'role_view_unitas_q2' ||
                            role.Title === 'role_view_countryrangegroup_q2',
                        ).length > 0 && (
                          <DropdownItem
                            tag={Link}
                            to="/barcode_compliance_report">
                            Barcode Compliance Report
                          </DropdownItem>
                        )}
                      <DropdownItem tag={Link} to="/support">
                        Support
                      </DropdownItem>
                      <DropdownItem tag={Link} to="/contact-us">
                        Request a New User Account
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}

              <div
                className="vertical-auto-margin d-inline-block"
                title="Log Out"
                onClick={this.logout}>
                <img
                  src={
                    this.props.theme.isIconBlack ? LogoutLogoBlack : LogoutLogo
                  }
                  alt="user"
                  className="top-row__icon  mr-2"
                />
              </div>
            </div>
          </UtilitiesContainer>
        </div>
        <div className="container-fluid d-flex justify-content-between align-items-center bg-dark d-block d-md-none">
          <div className="top-row__mobile w-100 pt-3">
            {this.renderMainMobileMenu()}
          </div>
        </div>
      </header>
    );
  }
}

TopNav.defaultProps = {
  currentPage: '',
};

TopNav.propTypes = {
  toggleOffscreen: PropTypes.func.isRequired,
  systemSettings: PropTypes.shape({
    depotSelectType: PropTypes.string,
    depotSelectFilterData: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ]),
    offscreenOpen: PropTypes.array,
  }).isRequired,
  setDepotSelectFilterData: PropTypes.func.isRequired,
  menuToggle: PropTypes.func.isRequired,
  currentPage: PropTypes.string,
  alertAdd: PropTypes.func.isRequired,
  isUploadDetails: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  showBlur: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let data = {
    categoryStructure: state.app.categoryStructure,
    categoryStructureDisabled: state.app.categoryStructureDisabled,
  };
  return data;
}
export default compose(
  connect(mapStateToProps, {
    setCategoryStructure,
  }),
  lifecycle({}),
  withState('data', 'handleData', {}),
  withTheme,
  withHandlers({
    onCategoryStructureChange: props => val => {
      // if user is on brand performance page reroute to category performance as we do not have brand data for own category structures
      if (props.location.pathname.includes('brand')) {
        props.history.push('/nam_dash/category_performance/');
      } else if (props.location.pathname.includes('wholesaler')) {
        props.history.push('/nam_dash/category_performance/');
      }
      props.setCategoryStructure(!props.categoryStructure);
      // props.location.pathname;
    },
  }),
)(TopNav);
