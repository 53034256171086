import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import styled, { ThemeContext } from 'styled-components';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import 'chartjs-plugin-labels';

const ImgHolder = styled.div`
  padding: 0 0.4rem;
  color: ${({ theme: { secondary } }) => secondary};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StyledTitle = styled.h2`
  font-size: 1.125rem;
`;

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 1rem 1rem 1rem;
  margin: 0 1rem;
  position: relative;
`;

const VerticalBarChart = ({
  data,
  id,
  title,
  value,
  drillGraphResults,
  tooltip,
  className,
  xAxisLabel,
  yAxisLabel,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const theme = useContext(ThemeContext);

  const chartRef = useRef(null);
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }
    if (chartRef.current) {
      // If the total percentage for the data is over 100 the graph breaks and is not full width

      const summaryChartData = {
        datasets: data && data.datasets,
        labels: data && data.labels,
      };

      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, {
        type: 'bar',
        data: summaryChartData,
        options: {
          plugins: {
            labels: {
              render: 'label',
              fontColor: '#000',
              position: 'outside',
              textMargin: 6,
              fontSize: 0,
              // render: function(args) {
              //   if (args.percentage > 2) {
              //     return args.label;
              //   } else {
              //     return '';
              //   }
              // },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,

                scaleLabel: {
                  display: true,
                  labelString: xAxisLabel || 'Date',
                },
                ticks: {
                  display: true,
                },
              },
            ],
            yAxes: [
              {
                display: true,

                scaleLabel: {
                  display: true,
                  labelString: yAxisLabel || 'Volume',
                },
                ticks: {
                  display: false,
                },
              },
            ],
          },
        },
      });
      setChartInstance(myChart);
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard className={className}>
      {title && (
        <Container>
          <StyledTitle className="text-uppercase">{title}</StyledTitle>
          <ImgHolder>
            <Tooltip
              title={tooltip}
              position="bottom"
              trigger="mouseenter"
              theme="light">
              <HelpOutlineIcon style={{ cursor: 'pointer' }} />
            </Tooltip>
          </ImgHolder>
        </Container>
      )}
      <canvas ref={chartRef} id={`myChart-${id}`} width="600" height="250" />
    </StyledCard>
  );
};

export default VerticalBarChart;
