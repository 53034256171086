import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import StepCrumbs from './StepCrumbs';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';
import {
  createUser,
  updateUser,
  addPermission,
} from '../../redux/reducers/user';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem } from '../../../helpers/util';

const Permissions = (permissions, per) => {
  const analysisPer = filter(
    permissions.analysis,
    perr => perr.name === per.name,
  );
  const namDashPer = filter(
    permissions.namDash,
    perr => perr.name === per.name,
  );
  const otherPer = filter(permissions.other, perr => perr.name === per.name);
  const qaPer = filter(permissions.qa, perr => perr.name === per.name);
  const qbPer = filter(
    permissions.queryBuilders,
    perr => perr.name === per.name,
  );
  const sysPer = filter(permissions.system, perr => perr.name === per.name);
  const retailPer = filter(permissions.retail, perr => perr.name === per.name);

  let permission = '';
  if (analysisPer.length > 0) permission = analysisPer[0].value;
  if (namDashPer.length > 0) permission = namDashPer[0].value;
  if (otherPer.length > 0) permission = otherPer[0].value;
  if (qaPer.length > 0) permission = qaPer[0].value;
  if (qbPer.length > 0) permission = qbPer[0].value;
  if (sysPer.length > 0) permission = sysPer[0].value;
  if (retailPer.length > 0) permission = retailPer[0].value;

  return permission;
};

const Review = ({
  data,
  createUser,
  updateUser,
  permissions,
  suppliers,
  selectedSuppliers,
  selectedDepartments,
  selectedCategories,
  selectedSubCategories,
  selectedLevel4s,
  selectedLevel5s,
  selectedAccountType,
  user,
}) => (
  <div className="o-canvas-wrap">
    <ToastContainer
      autoClose={false}
      style={{ top: '80px', zIndex: '10001' }}
      toastClassName={`c-alchemy-toasty`}
    />
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout u-layout--between-base">
          <h2 className="o-canvas__title">REVIEW</h2>
        </div>
      </div>
      <div className="o-canvas__body u-layout u-layout--spaced t-brand-c">
        <div className="u-1_1">
          <StepCrumbs step={3} id={data.id} system={getUserSystem()} />
          <div className="o-block pt-3">
            <div className="u-vertical">
              <div className="o-block u-1_4__inline">
                <h3 class="mb-4">USER DETAILS</h3>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      First Name
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.firstName}
                    </label>
                  </div>
                </div>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      Last Name
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.lastName}
                    </label>
                  </div>
                </div>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      Email
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.email}
                    </label>
                  </div>
                </div>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      Telephone
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.telephone}
                    </label>
                  </div>
                </div>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      Tags
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.tags}
                    </label>
                  </div>
                </div>
                <h3 class="mb-4">ACCOUNT DETAILS</h3>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      System
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {user.selectedAccountType ? user.selectedAccountType : ''}
                    </label>
                  </div>
                </div>
                <div className="u-1_4">
                  <div className="c-select-wrap-component">
                    <label htmlFor="retailclub" className="review_label">
                      Supplier Filter
                    </label>
                    <label htmlFor="retailclub" className="review_label">
                      {selectedSuppliers.map(sup => sup.Text).join(', ')}
                    </label>
                  </div>
                </div>
                {selectedDepartments.length > 0 && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Departments
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {selectedDepartments.map(dept => dept.Text).join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {selectedCategories.length > 0 && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Categories
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {selectedCategories.map(cat => cat.Text).join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {selectedSubCategories.length > 0 && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Sub Categories
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {selectedSubCategories
                          .map(subcat => subcat.Text)
                          .join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {selectedLevel4s.length > 0 && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Level4
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {selectedLevel4s.map(lvl4 => lvl4.Text).join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {selectedLevel5s.length > 0 && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Level5
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {selectedLevel5s.map(lvl5 => lvl5.Text).join(', ')}
                      </label>
                    </div>
                  </div>
                )}
                {getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' && (
                  <div className="u-1_4">
                    <div className="c-select-wrap-component">
                      <label htmlFor="retailclub" className="review_label">
                        Depot Filter
                      </label>
                      <label htmlFor="retailclub" className="review_label">
                        {user.selectedDepot.Text}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <h3 class="mb-4">PERMISSIONS</h3>
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  {user.selectedPermissions.length > 0 &&
                    getUserSystem() !== 'todays' &&
                    user.selectedPermissions.map((per, idx) => {
                      return (
                        <label
                          key={idx}
                          htmlFor="retailclub"
                          className="review_label w-100"
                          style={{ display: 'block' }}>
                          {Permissions(permissions, per)}
                        </label>
                      );
                    })}
                  {getUserSystem() === 'todays' && (
                    <div>
                      <label className="review_label w-100">Todays User</label>
                      <label className="review_label w-100">
                        {' '}
                        Wholesaler Admin
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {data.id && data.id.length > 0 ? (
            <button
              className={`btn alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              }  u-right`}
              onClick={updateUser}>
              UPDATE USER
            </button>
          ) : (
            <button
              className={`btn alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              }  u-right`}
              onClick={createUser}>
              CREATE USER
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isSuccess: state.user.isSuccess,
    isLoading: state.user.isLoading,
    permissions: state.user.permissions,
    suppliers: state.user.suppliers,
    user: state.user.user,
    selectedSuppliers: state.user.selectedSuppliers,
    selectedDepartments: state.user.selectedDepartments,
    selectedCategories: state.user.selectedCategories,
    selectedSubCategories: state.user.selectedSubCategories,
    selectedLevel4s: state.user.selectedLevel4s,
    selectedLevel5s: state.user.selectedLevel5s,
    message: state.user.message,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    createUser,
    updateUser,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.isLoading === false && this.props.isLoading === true) {
        toast.dismiss();
        toast.info('Processing, Please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (
        prevProps.isLoading === true &&
        this.props.isLoading === false &&
        this.props.isSuccess
      ) {
        toast.dismiss();
        toast.info('User created successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.history.push(`${process.env.APP_ROOT}list_users`);
      }
      if (
        prevProps.isLoading === true &&
        this.props.isLoading === false &&
        !this.props.isSuccess
      ) {
        toast.dismiss();
        toast.info(this.props.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  }),
  withState('data', 'handleData', props => ({ id: props.match.params.id })),
  withHandlers({
    createUser: props => () => {
      props.createUser();
    },
    updateUser: props => () => {
      props.updateUser(props.match.params.id);
    },
  }),
)(Review);
