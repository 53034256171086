import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  BrandDriversTable,
  BrandSupplierTable,
  BrandCategoryTable,
} from '../../components/SupplierEngagement';
import { getUserSystem } from '../../../helpers/util';

const Brand = ({ data, ...props }) => {
  return (
    <>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand/category`,
          )
        }>
        Category Top and Bottom Performers
      </Button>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand/supplier`,
          )
        }>
        Supplier Brand Performers
      </Button>
      {getUserSystem().toLowerCase() != 'confex' && (
        <Button
          onClick={() =>
            props.history.push(
              `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand/drivers`,
            )
          }>
          Brand Drivers of Performance
        </Button>
      )}

      <Switch>
        <Route
          path="/nam_dash/supplier_engagement_report/brand/category"
          render={pageProps => (
            <BrandCategoryTable
              {...pageProps}
              title="Category Table"
              data={data && data.category}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/brand/supplier"
          render={pageProps => (
            <BrandSupplierTable
              {...pageProps}
              title="Supplier Table"
              data={data && data.supplier}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/brand/drivers"
          render={pageProps => (
            <BrandDriversTable
              {...pageProps}
              title="Drivers Table"
              data={data && data.drivers}
            />
          )}
        />
        <Redirect
          to={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/brand/category`}
        />
      </Switch>
    </>
  );
};

export default Brand;
