export default {
  Department: '',
  Range: '',
  BestSeller: 0,
  DisplayOrder: '',
  ProductName: '',
  PackSize: '',
  PMP: 0,
  Category: '',
  Supplier: '',
  Country: '',
  Image: '',
  ID:'',
  Origin: '',
  Updated:''
}
