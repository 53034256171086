import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import CoreRange from '../models/CoreRange';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';
import { stringify } from 'qs';

const SET_IS_CORERANGE_CREATED = 'pfpCoreRange/IS_CORERANGE_CREATED';
const SET_IS_CORERANGE_DELETED = 'pfpCoreRange/IS_CORERANGE_DELETED';
const SET_CORERANGE = 'pfpCoreRange/SET_CORERANGE';
const FETCH_CORERANGES = 'pfpCoreRange/FETCH_CORERANGES';
const SET_LOADING = 'pfpCoreRange/SET_LOADING';
const SET_DEPARTMENT = 'pfpCoreRange/SET_DEPARTMENT';
const SET_CATEGORY = 'pfpCoreRange/SET_CATEGORY';
const FETCH_CATEGORIES = 'pfpCoreRange/FETCH_CATEGORIES';
const SET_FORM = 'pfpCoreRange/SET_FORM';
const RESET_ME = 'pfpCoreRange/RESET_ME';
const SET_BEST_SELLER = 'pfpCoreRange/SET_BEST_SELLER';
const SET_PMP = 'pfpCoreRange/SET_PMP';

const baseURL = 'https://api.wsale.co.uk';
// const baseURL = 'https://api-dev.twcgroup.net';

const initialState = {
  isLoading: false,
  department: '',
  departments: [],
  category: '',
  categories: [],
  isCoreRangeCreated: false,
  isCoreRangeDeleted: false,
  coreRanges: [],
  coreRange: CoreRange,
  coreRangeForm: { ...CoreRange },
};

const setCoreRangeAction = createAction(SET_CORERANGE);
const fetchCoreRangesAction = createAction(FETCH_CORERANGES);
const setIsCoreRangeCreatedAction = createAction(SET_IS_CORERANGE_CREATED);
const setIsCoreRangeDeletedAction = createAction(SET_IS_CORERANGE_DELETED);
const setLoadingAction = createAction(SET_LOADING);
const setBestSellerAction = createAction(SET_BEST_SELLER);
const setPMPAction = createAction(SET_PMP);

const setDepartmentAction = createAction(SET_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const fetchCategoriesAction = createAction(FETCH_CATEGORIES);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const onBestSellerChange = val => dispatch => {
  dispatch(setBestSellerAction(val));
};

export const onPMPChange = val => dispatch => {
  dispatch(setPMPAction(val));
};

export const fetchCategories = () => dispatch => {
  dispatch(setLoadingAction(true));
  let url = '/route.php?c=pfp/coreRange/getCategoryStructure';
  customRequest({
    url: baseURL + url,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(setLoadingAction(false));
    const departments = [];
    const categories = [];
    if (response.Departments) {
      _.each(response.Departments, department => {
        departments.push({ ID: department.Name, Text: department.Name, categories: department.Categories });
        if (department.Name == store.getState().pfpCoreRange.department.ID) {
          _.each(department.Categories, category => {
            categories.push({ ID: category.name, Text: category.name });
          });
        }
      });
    }
    dispatch(fetchCategoriesAction({ departments, categories }));
  });
};

export const onDepartmentChange = department => dispatch => {
  const categories = [];
  if (department && department.categories) {
    _.each(department.categories, category => {
      categories.push({ ID: category.name, Text: category.name });
    });
  }
  dispatch(setDepartmentAction({ department, categories }));
};

export const onCategoryChange = category => dispatch => {
  if (category) {
    category = category.ID;
  } else {
    category = '';
  }
  dispatch(setCategoryAction({ category: category }));
  dispatch(fetchCoreRanges());
};

export const onSaveCoreRange = uploadFiles => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsCoreRangeCreatedAction(false));
  const { coreRangeForm } = store.getState().pfpCoreRange;
  let data = { ...coreRangeForm };
  //remove extra fields and normalize Department field
  data.Department = data.Department.ID;
  delete data.departments;
  delete data.categories;
  delete data.Updated;
  let url = '/route.php?c=pfp/coreRange/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/coreRange/update';
  } else {
    delete data.ID;
  }
  let formData = new FormData();
  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map(fieldName => {
    const file = uploadFiles[fieldName];
    formData.append(fieldName, file, file.name);
    delete data[fieldName];
  });

  Object.keys(data).map(fieldName => {
    formData.append(fieldName, data[fieldName]);
  });

  // if(uploadFiles['LogoFile']) {
  // 	formData.append('Logo', uploadFiles['LogoFile'], uploadFiles['LogoFile'].name);
  // }

  customRequest({
    url: baseURL + url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: formData,
  }).then(response => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCoreRangeCreatedAction(true));
  });
};

export const fetchCoreRanges = () => dispatch => {
  const category = store.getState().pfpCoreRange.category || '';
  dispatch(setLoadingAction(true));
  if (category) {
    let url = '/route.php?c=pfp/coreRange/getCoreRangeByCategory&category=' + escape(category) + '';
    customRequest({
      url: baseURL + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(response => {
      dispatch(setLoadingAction(false));
      dispatch(fetchCoreRangesAction(response || []));
    });
  } else {
    dispatch(setLoadingAction(false));
    dispatch(fetchCoreRangesAction([]));
  }
};

export const onSetCoreRange = (id, isAddCoreRange, isDelete) => dispatch => {
  const { department, departments, category, categories } = store.getState().pfpCoreRange;
  isDelete = isDelete || false;
  let formData = { ...CoreRange };
  if (isDelete && id) {
    formData.ID = id;
    dispatch(setCoreRangeAction({ coreRangeForm: { ...formData, Department: department, departments, Category: category, categories } }));
  } else if (!isAddCoreRange && id) {
    dispatch(setLoadingAction(true));

    let url = '/route.php?c=pfp/coreRange/getListingById&id=' + id + '';
    customRequest({
      url: baseURL + url,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(response => {
      dispatch(setLoadingAction(false));
      if (_.isArray(response)) {
        response = response[0];
      }
      if (_.isObject(response) && response.ID == id) {
        formData = { ...CoreRange, ...response };
      }
      dispatch(setCoreRangeAction({ coreRangeForm: { ...formData, Department: department, departments, Category: category, categories } }));
    });
  } else {
    dispatch(setCoreRangeAction({ coreRangeForm: { ...formData, Department: department, departments, Category: category, categories } }));
  }
};

export const onSetForm = (fieldName, fieldValue) => dispatch => {
  const { coreRangeForm } = store.getState().pfpCoreRange;
  if (fieldName == 'Department') {
    const department = fieldValue;
    const categories = [];
    if (department) {
      if (department && department.categories) {
        _.each(department.categories, category => {
          categories.push({ ID: category.name, Text: category.name });
        });
      }
      coreRangeForm[fieldName] = department;
    } else {
      coreRangeForm[fieldName] = '';
    }
    coreRangeForm.categories = categories;
  } else if (fieldName == 'Category') {
    coreRangeForm[fieldName] = fieldValue.ID || '';
  } else {
    coreRangeForm[fieldName] = fieldValue;
  }
  dispatch(setFormAction({ coreRangeForm: { ...coreRangeForm } }));
};

export const onDeleteCoreRange = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsCoreRangeDeletedAction(false));
  let url = `/route.php?c=pfp/coreRange/delete&id=${id}`;
  customRequest({
    url: baseURL + url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(response => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCoreRangeDeletedAction(true));
  });
};

export const resetMe = () => dispatch => {
  dispatch(resetMeAction());
};

export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [FETCH_CATEGORIES]: (state, { payload }) => ({ ...state, departments: payload.departments, categories: payload.categories }),
    [SET_DEPARTMENT]: (state, { payload }) => ({ ...state, department: payload.department, categories: payload.categories }),
    [SET_CATEGORY]: (state, { payload }) => ({ ...state, category: payload.category }),
    [SET_BEST_SELLER]: (state, { payload }) => ({ ...state, coreRangeForm: { ...state.coreRangeForm, BestSeller: payload } }),
    [SET_PMP]: (state, { payload }) => ({ ...state, coreRangeForm: { ...state.coreRangeForm, PMP: payload } }),
    [SET_CORERANGE]: (state, { payload }) => ({ ...state, coreRangeForm: payload.coreRangeForm }),
    [FETCH_CORERANGES]: (state, { payload }) => ({ ...state, coreRanges: payload }),
    [SET_IS_CORERANGE_CREATED]: (state, { payload }) => ({ ...state, isCoreRangeCreated: payload }),
    [SET_IS_CORERANGE_DELETED]: (state, { payload }) => ({ ...state, isCoreRangeDeleted: payload }),
    [SET_FORM]: (state, { payload }) => ({ ...state, coreRangeForm: payload.coreRangeForm }),
    [RESET_ME]: (state, { payload }) => ({ ...state, ...initialState }),
  },
  initialState,
);

export const selectors = {};
