import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import styled, { ThemeContext } from 'styled-components';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import 'chartjs-plugin-labels';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const ImgHolder = styled.div`
  padding: 0 0.4rem;
  color: ${({ theme: { secondary } }) => secondary};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const htmlToReactParser = new HtmlToReactParser();

const StyledTitle = styled.h2`
  font-size: 1.125rem;
`;

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 1rem 5rem 1rem;
  margin: 0 1rem;
  position: relative;
`;

const GraphPerformancePDF = ({
  data,
  id,
  title,
  value,
  tooltip,
  className,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const theme = useContext(ThemeContext);
  const { primary, colorArray = ['#e8b879', '#f2cfa0 ', '#e8a751'] } = theme;

  const chartRef = useRef(null);
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }
    if (chartRef.current && data) {
      // If the total percentage for the data is over 100 the graph breaks and is not full width

      const summaryChartData = {
        datasets: [
          {
            data: data.map(({ value }) => value),
            backgroundColor: data.map((val, i) => colorArray[i % 3]),
          },
        ],
        labels: data.map(({ label }) => (label ? label[0] || label : '')),
      };

      const config = {
        type: 'pie',
        data: summaryChartData,
        options: {
          plugins: {
            labels: {
              render: 'label',
              fontColor: '#000',
              position: 'outside',
              fontSize: 20,
              textMargin: 6,
              render: function (args) {
                if (args.percentage > 2) {
                  return args.label;
                } else {
                  return '';
                }
              },
            },
          },
          legend: {
            display: false,
          },
        },
      };

      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, config);
      setChartInstance(myChart);
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard className={className}>
      <Container>
        <StyledTitle className="text-uppercase">{title}</StyledTitle>
        <ImgHolder />
      </Container>
      <canvas ref={chartRef} className={`pdf-chart`} width="600" height="250" />
    </StyledCard>
  );
};

export default GraphPerformancePDF;
