import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { Switch, Route } from 'react-router-dom';
import { getUserSystem, hasPermission } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import RetailerPerformanceGraph from '../../components/retail/RetailerPerformanceGraph';
import RetailerPerformanceTable from '../../components/retail/RetailerPerformanceTable';
import { retailerPerformanceExportHeaders } from '../../../config/tableExportHeaders';
import { bostonMatrixStores as explanation } from '../../components/Explainer/Explanations';
import { pageVariants } from '../../../config/pageVariants';
import {
  setAccountName,
  fetchAccountNames,
} from '../../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRetailType,
  setRefreshDisabledStatus,
  loadRetailerPerformanceData,
  loadRetailerPerformanceTableData,
  resetReport,
} from '../../redux/reducers/retail';
import Loading from '../../components/Loading';

const RetailerPerformance = ({
  timeframe,
  timeframes,
  likeForLike,
  likeForLikes,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  onLikeForLikeChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  accountNames,
  accountName,
  onAccountNameChange,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  level4,
  level4s,
  onLevel5Change,
  level5,
  level5s,
  loadRetailerPerformanceData,
  retailerPerformanceData,
  loadRetailerPerformanceTableData,
  retailerPerformanceTableData,
  isLoading,
  isRefreshDisabled,
  unlockRefresh,
  location,
  onResetReport,
  isRollingDateRange,
  onRetailTypeChange,
  retailType,
  retailTypes,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('retailer_performance', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });
    fetchAccountNames();
    return () => onUnmount();
  }, []);

  return (
    <motion.div
      key={'Retailer-Pereformance-REPORT-ID-1232'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        explanationTooltip={explanation.page.tooltip}
        refresh={{
          refreshHandler: () => loadRetailerPerformanceData(),
          isRefreshDisabled,
        }}
        exports={{
          CSV: {
            headers: retailerPerformanceExportHeaders,
            data: retailerPerformanceTableData,
          },
        }}
        title={'Boston Matrix - Stores'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Boston Matrix - Stores for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          { 
            name: 'accountName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
          }
        ]}>
        <DataDropdownsContainer
          unlockRefresh={() => unlockRefresh()}
          dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            },
            {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level4,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            },
            {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level5,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            },
            {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSuppliers,
              options: suppliers,
              handler: option => onSupplierChange(option),
              isMulti: true,
            },
            {
              name: 'Brand',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrands,
              options: brands,
              handler: option => onBrandChange(option),
              isMulti: true,
            },
            {
              name: 'retailType',
              condition: hasPermission(`role_view_${getUserSystem().toLowerCase()}_independent_data`) ? ['display']  : [],
              valueKey: 'ID',
              labelKey: 'Text',
              value: retailType,
              options: retailTypes,
              handler: option => onRetailTypeChange(option),
              isMulti: false,
            },
          ]}
        />
      </ReportSettingsContainer>
      <ReportContainer padding={2}>
        {isLoading && <Loading />}
        {!isLoading && (
          <Switch>
            <Route
              path="/retail/retailer-performance/table/:tableType"
              render={pageProps => (
                <RetailerPerformanceTable
                  {...pageProps}
                  tabs={[
                    {
                      text: 'Question Mark',
                      color: 'yellow',
                    },
                    { text: 'Star', color: 'green' },
                    { text: 'Dog', color: 'red' },
                    { text: 'Cash Cow', color: 'yellow' },
                  ]}
                  retailers={retailerPerformanceTableData}
                  isLoading={isLoading}
                  isRollingDateRange={isRollingDateRange}
                />
              )}
            />
            <Route
              path="/retail/retailer-performance"
              render={pageProps => (
                <RetailerPerformanceGraph
                  {...pageProps}
                  retailerPerformanceData={retailerPerformanceData}
                  isLoading={isLoading}
                />
              )}
            />
          </Switch>
        )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategory: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrands,
  level4: state.retail.level4,
  level4s: state.retail.level4s,
  level5: state.retail.level5,
  level5s: state.retail.level5s,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  retailerPerformanceData: state.retail.retailerPerformanceData,
  retailerPerformanceTableData: state.retail.retailerPerformanceTableData,
  isLoading: state.retail.isLoading,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  isRollingDateRange: state.retail.isRollingDateRange,
  retailType: state.retail.retailType,
  retailTypes: state.retail.retailTypes,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onRetailTypeChange: option => dispatch(setRetailType(option || '')),
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    loadRetailerPerformanceData: () => {
      dispatch(loadRetailerPerformanceData());
      dispatch(loadRetailerPerformanceTableData());
    },
    unlockRefresh: () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(fetchAccountNames());
      dispatch(
        fetchCategories('retailer_performance', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailerPerformance);
