import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  SupplierDriversTable,
  SupplierTopTable,
  SupplierCalculator,
} from '../../components/SupplierEngagement';
import { getUserSystem } from '../../../helpers/util';

const Supplier = ({ data, calculatorData, ...props }) => {
  return (
    <>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier/top_10`,
          )
        }>
        Supplier Top 10
      </Button>

      {getUserSystem().toLowerCase() != 'confex' && (
        <>
          <Button
            className="mr-4"
            onClick={() =>
              props.history.push(
                `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier/drivers`,
              )
            }>
            Supplier Drivers of Performance
          </Button>

          <Button
            className="mr-4"
            onClick={() =>
              props.history.push(
                `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier/calculator`,
              )
            }>
            Supplier Opportunity Calculator
          </Button>
        </>
      )}

      <Switch>
        <Route
          path="/nam_dash/supplier_engagement_report/supplier/top_10"
          render={pageProps => (
            <SupplierTopTable
              {...pageProps}
              title="Top 10 Table"
              data={data && data.overview}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/supplier/drivers"
          render={pageProps => (
            <SupplierDriversTable
              {...pageProps}
              title="Drivers Table"
              data={data && data.drivers}
            />
          )}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/supplier/calculator"
          render={pageProps => (
            <SupplierCalculator
              {...pageProps}
              title="Supplier Opportunity Calculator"
              data={calculatorData}
            />
          )}
        />
        <Redirect
          to={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report/supplier/top_10`}
        />
      </Switch>
    </>
  );
};

export default Supplier;
