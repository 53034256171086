import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreators from '../redux/actions/actionCreators';
import MainWrapper from './MainWrapper';
import InnerWrap from './InnerWrap';

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    settings: state.settings,
    alerts: state.alerts,
    systemSettings: state.systemSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const ReduxMapping = withRouter(connect(mapStateToProps, mapDispatchToProps)(MainWrapper));

const ReduxRouteMap = props => (
  <ReduxMapping>
    <InnerWrap {...props} />
  </ReduxMapping>
);

export default ReduxRouteMap;
