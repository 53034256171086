import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Explainer from '../Explainer/Explainer';
import { formatYoYData } from '../../../helpers/formatters';
import LineGraphDualAxis from '../LineGraphDualAxis';
import { StyledCard } from '../Styled';
const YOYWeekly = ({ data, props }) => {
  const [chartData, setChartData] = useState({});
  useEffect(() => {
    const formattedData = data && formatYoYData(data);
    setChartData(formattedData);
  }, []);

  return (
    <StyledCard>
      <Explainer explainerText="YOY Weekly" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}>
        <LineGraphDualAxis
          isLoading={false}
          data={chartData}
          isSingleYAxes
          yAxisLabel="Value"
        />
      </motion.div>
    </StyledCard>
  );
};

export default YOYWeekly;
