function settings(state = {}, action) {

	switch (action.type) {
		case 'USER_LOGOUT':
			return {};
		default:
			return state;		
	}

	return state;
}

export default settings;
