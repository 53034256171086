export class Drill {
  constructor() {
    this.measure = '';
    this.categories = '';
    this.subCategories = '';
    this.level4s = '';
    this.level5s = '';
    this.members = '';
    this.supplier = '';
    this.skip = '';
    this.ref = '';
    this.to = '';
    this.measure = '';
    this.timeframe = '';
    this.businessType = '';
    this.ownBrand = '';
    this.dataType = '';
    this.brand = '';
    this.groups = '';
  }
}
