import { map } from 'lodash';

export const setBrandUrl = (state, url, selectedOption) => {
  if (state.selectedDepartments?.length > 0) {
    url += `&department=${encodeURIComponent(
      map(state.selectedDepartments, 'Text').join(','),
    )}`;
  }
  if (state.selectedCategories?.length > 0) {
    url += `&category=${encodeURIComponent(
      map(state.selectedCategories, 'Text').join(','),
    )}`;
  }
  if (state.selectedSubCategories?.length > 0) {
    url += `&subcategory=${encodeURIComponent(
      map(state.selectedSubCategories, 'Text').join(','),
    )}`;
  }
  if (state.selectedLevel4s?.length > 0) {
    url += `&level4=${encodeURIComponent(
      map(state.selectedLevel4s, 'Text').join(','),
    )}`;
  }
  if (state.selectedLevel5s?.length > 0) {
    url += `&level5=${encodeURIComponent(
      map(state.selectedLevel5s, 'Text').join(','),
    )}`;
  }
  const [selectedOptionKey, selectedOptionValue] = selectedOption;

  if (selectedOptionKey === 'supplier' && selectedOptionValue?.SupplierName?.length > 0) {
    url += `&supplier=${encodeURIComponent(selectedOptionValue?.SupplierName)}`;
  }
  return url;
};
