import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddWholesaler from '../../components/modals/AddWholesaler';
import { fetchWholesalers, onSetForm, onDeleteWholesaler, onSaveWholesaler, onSetWholesaler, onGroceryChange, onImpulseChange, onNonFoodsChange, onLicensedChange, onTobaccoChange, onFrozenChange, onChilledChange, onCateringChange, onOnTradeChange, resetMe } from '../../redux/reducers/pfpWholesaler';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { throws } from 'assert';

ReactModal.setAppElement('.alchemy_app');
const Wholesalers = ({
  data,
  isLoading,
  wholesalers,
  wholesalerForm,
  onFormSelectChange,
  onFormTextChange,
  onFormFileChange,
  onSaveWholesaler,
  onDeleteWholesaler,
  onGroceryChange,
  onImpulseChange,
  onNonFoodsChange,
  onLicensedChange,
  onTobaccoChange,
  onFrozenChange,
  onChilledChange,
  onCateringChange,
  onOnTradeChange,
  handleWholesalerDeleteModal,
  handleWholesalerAddModal,
}) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openWholesalerAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handleWholesalerAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openWholesalerAddModal} contentLabel="" className="performance-modal">
          <AddWholesaler
            handleWholesalerAddModal={() => handleWholesalerAddModal(null)}
            isLoading={isLoading}
            wholesalerTypes={data.wholesalerTypes}
            targetTypes={data.targetTypes}
            pages={data.pages}
            statuses={data.statuses}
            displayOrder={data.displayOrder}
            positions={data.positions}
            wholesalerForm={wholesalerForm}
            onSaveWholesaler={onSaveWholesaler}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
            onGroceryChange={onGroceryChange}
            onImpulseChange={onImpulseChange}
            onNonFoodsChange={onNonFoodsChange}
            onLicensedChange={onLicensedChange}
            onTobaccoChange={onTobaccoChange}
            onFrozenChange={onFrozenChange}
            onChilledChange={onChilledChange}
            onCateringChange={onCateringChange}
            onOnTradeChange={onOnTradeChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>Wholesalers</h4>
          {wholesalers.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Name Of Company</th>
                  <th>Company Trading Name</th>
                  <th>Street Address</th>
                  <th>Town</th>
                  <th>City</th>
                  <th>County</th>
                  <th>Country</th>
                  <th>Post Code</th>
                  <th>Phone No</th>
                  <th>Email</th>
                  <th>Website</th>
                  <th>Lat</th>
                  <th>Lon</th>
                  <th>Updated</th>
                  <th>Grocery</th>
                  <th>Impulse</th>
                  <th>Non Foods</th>
                  <th>Licensed</th>
                  <th>Tobacco</th>
                  <th>Frozen</th>
                  <th>Chilled</th>
                  <th>Catering</th>
                  <th>On Trade</th>
                  <th>Description</th>
                  <th>Catalogue</th>
                </tr>
              </thead>
              <tbody>
                {wholesalers.map((wholesaler, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleWholesalerAddModal(wholesaler.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleWholesalerDeleteModal(wholesaler.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{wholesaler.NameOfCompany}</td>
                      <td>{wholesaler.CompanyTradingName}</td>
                      <td>{wholesaler.StreetAddress}</td>
                      <td>{wholesaler.Town}</td>
                      <td>{wholesaler.City}</td>
                      <td>{wholesaler.County}</td>
                      <td>{wholesaler.Country}</td>
                      <td>{wholesaler.PostCode}</td>
                      <td>{wholesaler.PhoneNo}</td>
                      <td>{wholesaler.Email}</td>
                      <td>{wholesaler.Website}</td>
                      <td>{wholesaler.Lat}</td>
                      <td>{wholesaler.Lon}</td>
                      <td>{wholesaler.Updated}</td>
                      <td>{wholesaler.Grocery}</td>
                      <td>{wholesaler.Impulse}</td>
                      <td>{wholesaler['Non Foods']}</td>
                      <td>{wholesaler.Licensed}</td>
                      <td>{wholesaler.Tobacco}</td>
                      <td>{wholesaler.Frozen}</td>
                      <td>{wholesaler.Chilled}</td>
                      <td>{wholesaler.Catering}</td>
                      <td>{wholesaler['On Trade']}</td>
                      <td>{wholesaler.Description}</td>
                      <td>{wholesaler.Catalogue}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openWholesalerDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this wholesaler?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteWholesaler()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleWholesalerDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Wholesalers found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpWholesaler.isLoading,
    isWholesalerCreated: state.pfpWholesaler.isWholesalerCreated,
    isWholesalerDeleted: state.pfpWholesaler.isWholesalerDeleted,
    wholesalers: state.pfpWholesaler.wholesalers,
    wholesalerForm: state.pfpWholesaler.wholesalerForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchWholesalers,
    onSetForm,
    onDeleteWholesaler,
    onSaveWholesaler,
    onSetWholesaler,
    onGroceryChange,
    onImpulseChange,
    onNonFoodsChange,
    onLicensedChange,
    onTobaccoChange,
    onFrozenChange,
    onChilledChange,
    onCateringChange,
    onOnTradeChange,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      //this.props.departments.length === 0 &&
      this.props.fetchWholesalers();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isWholesalerCreated !== this.props.isWholesalerCreated && this.props.isWholesalerCreated) {
        this.props.fetchWholesalers();
        this.uploadFiles = [];
        toast.info((this.props.wholesalerForm.ID ? 'Updated' : 'Created') + ' Wholesaler Successfully...');
      }
      if (prevProps.isWholesalerDeleted !== this.props.isWholesalerDeleted && this.props.isWholesalerDeleted) {
        this.props.fetchWholesalers();
        toast.info('Deleted Wholesaler Successfully...');
      }
    },
  }),
  withState('data', 'handleData', {
    isAddWholesaler: false,
    openWholesalerAddModal: false,
    openWholesalerDeleteModal: false,
    wholesalerTypes: [
      { label: 'ContentFeature', value: 'ContentFeature' },
      { label: 'MenuAd', value: 'MenuAd' },
      { label: 'Splash', value: 'Splash' },
      { label: 'SkyScrapper', value: 'SkyScrapper' },
      { label: 'Carousel', value: 'Carousel' },
      { label: 'Popup', value: 'Popup' },
    ],
    targetTypes: [
      { label: 'Internal', value: 'Internal' },
      { label: 'External', value: 'External' },
    ],
    statuses: [
      { label: 'Enable', value: 1 },
      { label: 'Disable', value: 0 },
    ],
    displayOrder: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
    ],
    pages: [
      { label: 'Home', value: 'Home' },
      { label: 'Menu', value: 'Menu' },
      { label: 'CoreRange', value: 'CoreRange' },
      { label: 'Planograms', value: 'Planograms' },
      { label: 'ROI', value: 'ROI' },
      { label: 'Deals', value: 'Deals' },
      { label: 'Wholesaler', value: 'Wholesaler' },
      { label: 'NPD', value: 'NPD' },
      { label: 'DropShip', value: 'DropShip' },
    ],
    positions: [
      { label: 'Home Page Feature 2', value: 'Home Page Feature 2' },
      { label: 'Home Page Feature 3', value: 'Home Page Feature 3' },
      { label: 'Home Page Feature 4', value: 'Home Page Feature 4' },
      { label: 'About Advice', value: 'About Advice' },
      { label: 'Splash', value: 'Splash' },
      { label: 'SkyScrapper', value: 'SkyScrapper' },
      { label: 'Carousel', value: 'Carousel' },
      { label: 'Popup', value: 'Popup' },
    ],
  }),
  withHandlers({
    onGroceryChange: ({ data, handleData, onGroceryChange }) => event => {
      onGroceryChange(parseInt(event.target.id));
    },
    onImpulseChange: ({ data, handleData, onImpulseChange }) => event => {
      onImpulseChange(parseInt(event.target.id));
    },
    onNonFoodsChange: ({ data, handleData, onNonFoodsChange }) => event => {
      onNonFoodsChange(parseInt(event.target.id));
    },
    onLicensedChange: ({ data, handleData, onLicensedChange }) => event => {
      onLicensedChange(parseInt(event.target.id));
    },
    onTobaccoChange: ({ data, handleData, onTobaccoChange }) => event => {
      onTobaccoChange(parseInt(event.target.id));
    },
    onFrozenChange: ({ data, handleData, onFrozenChange }) => event => {
      onFrozenChange(parseInt(event.target.id));
    },
    onChilledChange: ({ data, handleData, onChilledChange }) => event => {
      onChilledChange(parseInt(event.target.id));
    },
    onCateringChange: ({ data, handleData, onCateringChange }) => event => {
      onCateringChange(parseInt(event.target.id));
    },
    onOnTradeChange: ({ data, handleData, onOnTradeChange }) => event => {
      onOnTradeChange(parseInt(event.target.id));
    },
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.ID);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSaveWholesaler: ({ data, handleData, onSaveWholesaler }) => () => {
      onSaveWholesaler(this.uploadFiles || []);
      handleData({ ...data, isAddWholesaler: false, openWholesalerAddModal: false });
    },
    onDeleteWholesaler: ({ data, handleData, onDeleteWholesaler, wholesalerForm }) => () => {
      onDeleteWholesaler(wholesalerForm.ID);
      handleData({ ...data, isAddWholesaler: false, openWholesalerDeleteModal: false });
    },
    handleWholesalerDeleteModal: ({ data, handleData, wholesalers, onSetWholesaler }) => id => {
      handleData({ ...data, openWholesalerDeleteModal: !data.openWholesalerDeleteModal });
      onSetWholesaler(id || '', false, true);
    },
    handleWholesalerAddModal: ({ data, handleData, onSetWholesaler }) => id => {
      const isAddWholesaler = id ? false : true;
      this.uploadFiles = []; //reset
      const openWholesalerAddModal = !data.openWholesalerAddModal;
      handleData({ ...data, isAddWholesaler, openWholesalerAddModal: openWholesalerAddModal });
      if (openWholesalerAddModal) {
        onSetWholesaler(id, isAddWholesaler);
      } else {
        onSetWholesaler(null, isAddWholesaler);
      }
    },
  }),
)(Wholesalers);
