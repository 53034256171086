import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const Container = styled.div`
  &&& h2 {
    color: ${props =>
      props.coloured
        ? props.theme.primary
        : props.colour
        ? props.colour
        : '#fff'} !important;
  }

  div {
    display: flex !important;
  }
`;

const TableHeaderTooltip = ({ text, explanationTooltip, coloured, colour }) =>
  explanationTooltip ? (
    <Container coloured={coloured} colour={colour}>
      <Tooltip
        title={explanationTooltip}
        position={'bottom'}
        trigger="mouseenter">
        <h2 className="text-uppercase mb-0">{text}</h2>
      </Tooltip>
    </Container>
  ) : (
    <Container coloured={coloured} colour={colour}>
      <h2 className="text-uppercase mb-0">{text}</h2>
    </Container>
  );

export default TableHeaderTooltip;
