import React from 'react';
import PropTypes from 'prop-types';
import { each } from 'lodash';
import { withTheme } from 'styled-components';

import { getRandomNumber } from '../../helpers/helpers';
import { getUserSystem } from '../../helpers/util';

import makeMorris from '../../assets/js/morris';

class GraphProductPerformance extends React.Component {
  state = {
    graphID: getRandomNumber('graph_'),
  };

  componentDidMount() {
    this.renderGraph();
  }

  shouldComponentUpdate(nextProps) {
    return this.props.isLoading === nextProps.isLoading;
  }

  shouldComponentUpdate(nextProps) {
    this.props.isLoading === nextProps.isLoading;
    const oldData = JSON.stringify(this.props.data);
    const newData = JSON.stringify(nextProps.data);
    if (oldData === newData) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    this.destroyGraph();
    this.renderGraph();
  }

  destroyGraph = () => {
    $(window).off('resize');
    $(`#${this.state.graphID}`).html('');
    window.morrisObj = NaN;
  };

  renderGraph = () => {
    const Morris = makeMorris($);
    this.destroyGraph();

    each(this.props.data, dt => {
      dt.y = dt.y;
    });
    var formatY = function (y) {
        return y;
      },
      formatX = function (x) {
        return x.src.y;
      },
      config = {
        data: this.props.data,
        xkey: 'y',
        ykeys: this.props.keys,
        labels: ['salesTY', 'salesLY'],
        fillOpacity: 0.6,
        hideHover: 'auto',
        stacked: false,
        horizontal: true,
        resize: true,
        pointFillColors: ['#ffffff'],
        pointStrokeColors: ['black'],
        // barColors: getUserSystem() === 'unitas' ? ['#e30613', '#fd5272'] : ['#e30613', '#9ada7d'],
        barColors: [this.props.theme.primary, this.props.theme.primaryDark],
        yLabelFormat: formatY,
        xLabelFormat: formatX,
      };
    config.element = this.state.graphID;
    if (config.data && config.data.length > 0) {
      Morris.Bar(config);
    }
  };

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          id={this.state.graphID}
          style={{ height: '800px' }}
          className="c-objects-wrap c-objects-wrap--graph"
        />
      </div>
    );
  }
}

GraphProductPerformance.defaultProps = {
  data: [],
  keys: [],
  formatter: t => t,
  isLoading: false,
};

GraphProductPerformance.propTypes = {
  data: PropTypes.array,
  keys: PropTypes.array,
  formatter: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withTheme(GraphProductPerformance);
