import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import TableHeaderTooltip from '../Explainer/TableHeaderTooltip';
import { basketProductAnalysis as explanation } from '../Explainer/Explanations';
import { lightTableTheme } from '../../../config/tableStyles';

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;

  div[role='rowgroup'] div[role='row'] div[role='gridcell']:first-child {
    text-align: left;
    padding: 0.5rem;
  }

     .rdt_TableCell{
	font-size: 0.9rem; 
 }

 .rdt_TableHeadRow {
   padding-right: 14px;
 }

  .rdt_TableHeadRow .rdt_TableCol {
  
	padding: 8px;
  }

  

/* Works on Firefox */
.rdt_Table {
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.tableColor1} #fff;
}


.rdt_TableBody::-webkit-scrollbar {
    width: 14px;
}

.rdt_TableBody::-webkit-scrollbar-track {
	background: ${({ theme }) => theme.primary};
    border-left: 6px solid white;
    border-right: 7px solid white;
	
}

.rdt_TableBody::-webkit-scrollbar-thumb {
    box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme }) => theme.primary} !important; 
}
`;

// header shadow      .rdt_TableHeadRow .rdt_TableCol { box-shadow: 0px 6px 3px -2px #00000029; }

const BasketProductAnalysisTable = ({ data }) => {
  const { tableHeaders } = explanation;
  const columns = [
    {
      selector: 'ProductDesc',
      name: (
        <TableHeaderTooltip
          coloured
          text="Product Description"
          explanationTooltip={tableHeaders.name}
        />
      ),
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'Visits',
      name: (
        <TableHeaderTooltip
          coloured
          text="Visits"
          explanationTooltip={tableHeaders.visits}
        />
      ),
      sortable: true,

      sortFunction: (rowA, rowB) =>
        parseInt(rowA.Visits.replace(',', '')) -
        parseInt(rowB.Visits.replace(',', '')),
    },
    {
      selector: 'QtyBought',
      name: (
        <TableHeaderTooltip
          coloured
          text="Quantity Bought"
          explanationTooltip={tableHeaders.quantity}
        />
      ),
      sortable: true,

      sortFunction: (rowA, rowB) =>
        parseInt(rowA.QtyBought.replace(',', '')) -
        parseInt(rowB.QtyBought.replace(',', '')),
    },
    {
      selector: 'ProductPenetration',
      name: (
        <TableHeaderTooltip
          coloured
          text="Product Penetration"
          explanationTooltip={tableHeaders.productPenetration}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.ProductPenetration).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        rowA.ProductPenetration.replace('%', '') -
        rowB.ProductPenetration.replace('%', ''),
    },
    {
      selector: 'Support',
      name: (
        <TableHeaderTooltip
          coloured
          text="Support"
          explanationTooltip={tableHeaders.support}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.Support).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.Support.replace(',', '')) -
        parseInt(rowB.Support.replace(',', '')),
    },
    {
      selector: 'Confidence',
      name: (
        <TableHeaderTooltip
          coloured
          text="Confidence"
          explanationTooltip={tableHeaders.confidence}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.Confidence).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        parseFloat(rowA.Confidence.replace(',', '')) -
        parseFloat(rowB.Confidence.replace(',', '')),
    },
    {
      selector: 'Lift',
      name: (
        <TableHeaderTooltip
          coloured
          text="Lift"
          explanationTooltip={tableHeaders.lift}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.Lift).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        parseFloat(rowA.Lift.replace(',', '')) -
        parseFloat(rowB.Lift.replace(',', '')),
    },
  ];

  return (
    <StyledCard>
      <DataTable
        data={data}
        columns={columns}
        customStyles={lightTableTheme}
        striped
        defaultSortField={'ProductPenetration'}
        defaultSortAsc={false}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon />}
      />
    </StyledCard>
  );
};

export default BasketProductAnalysisTable;
