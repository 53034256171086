import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddPlanogram from '../../components/modals/AddPlanogram';
import { fetchCategories, fetchPlanograms, onDepartmentChange, onCategoryChange, onSetForm, onDeletePlanogram, onSavePlanogram, onSetPlanogram, resetMe } from '../../redux/reducers/pfpPlanogram';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { throws } from 'assert';

ReactModal.setAppElement('.alchemy_app');
const Planograms = ({
  data,
  isLoading,
  planograms,
  department,
  departments,
  category,
  categories,
  planogramForm,
  onDepartmentChange,
  onCategoryChange,
  onFormSelectChange,
  onFormTextChange,
  onFormDepartmentChange,
  onFormCategoryChange,
  onFormFileChange,
  onSavePlanogram,
  onDeletePlanogram,
  handlePlanogramDeleteModal,
  handlePlanogramAddModal,
}) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openPlanogramAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handlePlanogramAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openPlanogramAddModal} contentLabel="" className="performance-modal">
          <AddPlanogram
            handlePlanogramAddModal={() => handlePlanogramAddModal(null)}
            isLoading={isLoading}
            planogramForm={planogramForm}
            onSavePlanogram={onSavePlanogram}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
            onFormDepartmentChange={onFormDepartmentChange}
            onFormCategoryChange={onFormCategoryChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>Planogram</h4>
          <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
            <div className="u-1_3 mb-0">
              <div className="form-group">
                <h3 className="o-canvas__search-title ">Department</h3>
                <Select value={department} onChange={onDepartmentChange} options={departments} valueKey="ID" labelKey="Text" />
              </div>
            </div>
            {categories.length ? (
              <div className="u-1_3 mb-0">
                <div className="form-group">
                  <h3 className="o-canvas__search-title ">Category</h3>
                  <Select value={category} onChange={onCategoryChange} options={categories} valueKey="ID" labelKey="Text" />
                </div>
              </div>
            ) : null}
          </div>

          {planograms.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Department</th>
                  <th>Category</th>
                  <th>DocumentURL</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>ImageName</th>
                  <th>DisplayOrder</th>
                  <th>Dimensions</th>
                  <th>Country</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                {planograms.map((planogram, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handlePlanogramAddModal(planogram)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handlePlanogramDeleteModal(planogram)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{planogram.Department}</td>
                      <td>{planogram.Category}</td>
                      <td>{planogram.DocumentURL}</td>
                      <td>{planogram.Title}</td>
                      <td>{planogram.Description}</td>
                      <td>{planogram.ImageName}</td>
                      <td>{planogram.DisplayOrder}</td>
                      <td>{planogram.Dimensions}</td>
                      <td>{planogram.Country}</td>
                      <td>{planogram.Updated}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openPlanogramDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this planogram?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeletePlanogram()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handlePlanogramDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Planograms found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpPlanogram.isLoading,
    department: state.pfpPlanogram.department,
    departments: state.pfpPlanogram.departments,
    category: state.pfpPlanogram.category,
    categories: state.pfpPlanogram.categories,
    isPlanogramCreated: state.pfpPlanogram.isPlanogramCreated,
    isPlanogramDeleted: state.pfpPlanogram.isPlanogramDeleted,
    planograms: state.pfpPlanogram.planograms,
    planogramForm: state.pfpPlanogram.planogramForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    fetchPlanograms,
    onDepartmentChange,
    onCategoryChange,
    onSetForm,
    onDeletePlanogram,
    onSavePlanogram,
    onSetPlanogram,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      //this.props.departments.length === 0 &&
      this.props.fetchCategories();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isPlanogramCreated !== this.props.isPlanogramCreated && this.props.isPlanogramCreated) {
        this.props.fetchPlanograms();
        this.uploadFiles = [];
        toast.info((this.props.planogramForm.ID ? 'Updated' : 'Created') + ' Planogram Successfully...');
      }
      if (prevProps.isPlanogramDeleted !== this.props.isPlanogramDeleted && this.props.isPlanogramDeleted) {
        this.props.fetchPlanograms();
        toast.info('Deleted Planogram Successfully...');
      }
    },
  }),
  withState('data', 'handleData', { isAddPlanogram: false, openPlanogramAddModal: false, openPlanogramDeleteModal: false }),
  withHandlers({
    onDepartmentChange: ({ data, handleData, onDepartmentChange }) => target => {
      onDepartmentChange(target);
    },
    onCategoryChange: ({ data, handleData, onCategoryChange }) => target => {
      onCategoryChange(target);
    },
    onFormDepartmentChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Department', target);
    },
    onFormCategoryChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Category', target);
    },
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.ID);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSavePlanogram: ({ data, handleData, onSavePlanogram }) => () => {
      onSavePlanogram(this.uploadFiles || []);
      handleData({ ...data, isAddPlanogram: false, openPlanogramAddModal: false });
    },
    onDeletePlanogram: ({ data, handleData, onDeletePlanogram, planogramForm }) => () => {
      onDeletePlanogram(planogramForm.ID);
      handleData({ ...data, isAddPlanogram: false, openPlanogramDeleteModal: false });
    },
    handlePlanogramDeleteModal: ({ data, handleData, planograms, onSetPlanogram }) => record => {
      handleData({ ...data, openPlanogramDeleteModal: !data.openPlanogramDeleteModal });
      onSetPlanogram(record, false, true);
    },
    handlePlanogramAddModal: ({ data, handleData, onSetPlanogram }) => record => {
      const isAddPlanogram = record ? false : true;
      const openPlanogramAddModal = !data.openPlanogramAddModal;
      this.uploadFiles = []; //reset
      handleData({ ...data, isAddPlanogram, openPlanogramAddModal: openPlanogramAddModal });
      if (openPlanogramAddModal) {
        onSetPlanogram(record, isAddPlanogram);
      } else {
        onSetPlanogram(null, isAddPlanogram);
      }
    },
  }),
)(Planograms);
