import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getUserSystem } from '../../../helpers/util';

// We have had to repalce nav links with span tags only on step one to ensure users select a wholesalers before progressing to step 2

export default function StepCrumbs(props) {
  const steps = [1, 2, 3, 4];
  const activeClassName =
    getUserSystem() === 'unitas'
      ? 'step-active--unitas'
      : 'step-active--others';
  const step1 = `/query/1`;
  const location = window.location.pathname;
  return steps.map(step => {
    const stepClass = [''];
    if (step < props.step) {
      stepClass.push(activeClassName);
    }
    const disablePrev = props.disablePrev || false;

    const isActive = `${process.env.APP_ROOT}query/${step}` === location;
    const isStep1 = step1 === location;
    return (
      <React.Fragment key={step}>
        {!props.condition && isStep1 ? (
          <span
            onClick={props.handleClick}
            className={`${step} btn btn-info text-uppercase not-active ${
              isActive ? activeClassName : ''
            }`}>
            Step {step}
          </span>
        ) : (
          <NavLink
            to={
              disablePrev && stepClass.length > 1
                ? `#`
                : `${process.env.APP_ROOT}query/${step}`
            }
            activeClassName={activeClassName}
            className={`btn btn-info text-uppercase not-active`}>
            Step {step}
          </NavLink>
        )}
      </React.Fragment>
    );
  });
}

StepCrumbs.defaultProps = {
  step: 1,
};

StepCrumbs.propTypes = {
  step: PropTypes.number,
};
