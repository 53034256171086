import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import {
  Row,
  Col,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { getUserSystem } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import BasketOverviewChart from '../../components/retail/BasketOverviewChart';
import DashboardCard from '../../components/retail/DashboardCard';
import BasketOverviewTable from '../../components/retail/BasketOverviewTable';
import Loading from '../../components/Loading';
import {
  StyledNavLink,
  StyledNavItem,
} from '../../components/navigations/StyledNavLink';
import { setAccountName } from '../../redux/reducers/filters';
import { basketOverview as explanation } from '../../components/Explainer/Explanations';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  loadBasketOverviewData,
  resetReport,
  setRefreshDisabledStatus,
} from '../../redux/reducers/retail';
import { pageVariants } from '../../../config/pageVariants';

export const BasketOverview = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  onAccountNameChange,
  likeForLike,
  likeForLikes,
  setLoading,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  onLikeForLikeChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  level4,
  level4s,
  onLevel5Change,
  level5,
  level5s,
  loadBasketOverviewData,
  basketOverviewData,
  isLoading,
  isRefreshDisabled,
  location,
  onResetReport,
  isRollingDateRange,
  unlockRefresh,
  ...props
}) => {
  useEffect(() => {
    const fetchData = async () => {
      await fetchCategories('basket_overview', false, {
        timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
      });
      loadBasketOverviewData();
    };
    fetchData();
  }, []);

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <motion.div
      key={'BasketOverview-REPORT-ID'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        explanationTooltip={explanation.page.tooltip}
        warningTooltip={explanation.page.warning}
        refresh={{
          refreshHandler: () => loadBasketOverviewData(),
          isRefreshDisabled,
        }}
        title={'Basket Overview'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Basket Overview for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          {
            name: 'accountName',
            valueKey: 'RegNo',
            labelKey: 'CustomerName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
            clearable: true,
          },
        ]}>
        <DataDropdownsContainer unlockRefresh={unlockRefresh} retail />
      </ReportSettingsContainer>
      <ReportContainer padding={3} display="block">
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Container>
              <Row className="mb-5">
                {basketOverviewData &&
                  basketOverviewData.basketTotalsData &&
                  basketOverviewData.basketTotalsData.length > 0 &&
                  basketOverviewData.basketTotalsData.map(data => (
                    <Col key={data.Card}>
                      <DashboardCard data={data} />
                    </Col>
                  ))}
              </Row>
            </Container>
            <Container>
              <Nav tabs>
                <StyledNavItem isActive={activeTab === 1}>
                  <StyledNavLink
                    className={activeTab === '1' ? 'active' : ''}
                    onClick={() => {
                      toggle('1');
                    }}>
                    Best Performing Skus
                  </StyledNavLink>
                </StyledNavItem>
                <StyledNavItem isActive={activeTab === 2}>
                  <StyledNavLink
                    className={activeTab === '2' ? 'active' : ''}
                    onClick={() => {
                      toggle('2');
                    }}>
                    Fastest Growing Skus
                  </StyledNavLink>
                </StyledNavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row className="mb-5">
                    {basketOverviewData &&
                      basketOverviewData.bestPerformersData &&
                      basketOverviewData.bestPerformersData.bestSellers && (
                        <Col md={12}>
                          <BasketOverviewTable
                            title="Best Performing Skus"
                            data={
                              basketOverviewData.bestPerformersData.bestSellers
                            }
                            isRollingDateRange={isRollingDateRange}
                          />
                        </Col>
                      )}
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="mb-5">
                    {basketOverviewData &&
                      basketOverviewData.bestPerformersData &&
                      basketOverviewData.bestPerformersData.fastest && (
                        <Col md={12}>
                          <BasketOverviewTable
                            title="Fastest Growing Skus"
                            data={basketOverviewData.bestPerformersData.fastest}
                            isRollingDateRange={isRollingDateRange}
                          />
                        </Col>
                      )}
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
            <Container>
              <Row className="mb-5">
                {basketOverviewData &&
                  basketOverviewData.basketOverviewGraphData &&
                  basketOverviewData.basketOverviewGraphData.TY &&
                  basketOverviewData.basketOverviewGraphData.TY.table && (
                    <Col md={6}>
                      <BasketOverviewChart
                        title={`Average Basket Spend by Category - ${
                          isRollingDateRange ? 'Current' : 'TY'
                        } `}
                        data={
                          basketOverviewData.basketOverviewGraphData.TY.table
                        }
                        id="TY"
                        total={
                          basketOverviewData.basketOverviewGraphData.TY.total
                        }
                      />
                    </Col>
                  )}
                {basketOverviewData &&
                  basketOverviewData.basketOverviewGraphData &&
                  basketOverviewData.basketOverviewGraphData.LY &&
                  basketOverviewData.basketOverviewGraphData.LY.table && (
                    <Col md={6}>
                      <BasketOverviewChart
                        title={`Average Basket Spend by Category - ${
                          isRollingDateRange ? 'Previous' : 'LY'
                        } `}
                        data={
                          basketOverviewData.basketOverviewGraphData.LY.table
                        }
                        id="LY"
                        total={
                          basketOverviewData.basketOverviewGraphData.LY.total
                        }
                      />
                    </Col>
                  )}
              </Row>
            </Container>
          </>
        )}
      </ReportContainer>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategory: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrands,
  level4: state.retail.level4,
  level4s: state.retail.level4s,
  level5: state.retail.level5,
  level5s: state.retail.level5s,
  basketOverviewData: state.retail.basketOverviewData,
  isLoading: state.retail.isLoading,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  isRollingDateRange: state.retail.isRollingDateRange,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),
    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    unlockRefresh: () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    loadBasketOverviewData: () => dispatch(loadBasketOverviewData()),
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(
        fetchCategories('basket_overview', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketOverview);
