import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const yesNoSelect = [
  { ID: '1', Text: 'Yes' },
  { ID: '0', Text: 'No' },
];

const AddPlanogram = ({ isLoading, planogramForm, onSavePlanogram, onFormSelectChange, onFormTextChange, onFormFileChange, onFormDepartmentChange, onFormCategoryChange, handlePlanogramAddModal }) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{planogramForm.ID ? 'Update' : 'Add New'} Planogram</span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Department</h3>
              <Select value={planogramForm.Department} onChange={onFormDepartmentChange} options={planogramForm.departments} valueKey="ID" labelKey="Text" />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Categories</h3>
              <Select value={planogramForm.Category} onChange={onFormCategoryChange} options={planogramForm.categories} valueKey="ID" labelKey="Text" />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Description</h3>
              <textarea id="description" rows="7" cols="82" className="ReactModal--input-green" name="Description" onChange={evt => onFormTextChange('Description', evt.target)} value={planogramForm.Description || ''}></textarea>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Dimensions</h3>
              <input type="text" id="dimensions" className="ReactModal--input-green" name="dimensions" value={planogramForm.Dimensions || ''} onChange={evt => onFormTextChange('Dimensions', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Title</h3>
              <input type="text" id="title" className="ReactModal--input-green" name="title" value={planogramForm.Title || ''} onChange={evt => onFormTextChange('Title', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">DisplayOrder</h3>
              <input type="text" id="displayOrder" className="ReactModal--input-green" name="displayOrder" value={planogramForm.DisplayOrder || ''} onChange={evt => onFormTextChange('DisplayOrder', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Country</h3>
              <input type="text" id="country" className="ReactModal--input-green" name="country" value={planogramForm.Country || ''} onChange={evt => onFormTextChange('Country', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>{planogramForm.DocumentURL}</div>
              <h3 className="o-canvas__search-title ">DocumentURL</h3>
              <input type="file" name="DocumentURL" onChange={evt => onFormFileChange('DocumentURL', evt.target.files[0])} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={planogramForm.ImageName} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">ImageName</h3>
              <input type="file" name="ImageName" onChange={evt => onFormFileChange('ImageName', evt.target.files[0])} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSavePlanogram()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handlePlanogramAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPlanogram;
