import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { getUserSystem, getUserNamDashMeasures } from '../../helpers/util';
import CustomCountSelect from '../components/CustomCountSelect';
import * as _ from 'lodash';
import Toggle from 'react-toggle';
import theme from '../../config/Themes';
const themeName = theme.name;

const WeeklyReport = ({
  report,
  measures,
  timeframes,
  departments,
  categories,
  subCategories,
  level4s,
  level5s,
  members,
  suppliers,
  businessTypes,
  index,
  onChange,
  onMemberChange,
  onSupplierChange,
  onBusinessTypeChange,
  onMeasureChange,
  onTimeframeChange,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onCloneReport,
  onDeleteReport,
  totalNoOfReports,
  onMoveUp,
  onMoveDown,
  limits,
  onLimitChange,
  resolveMeasures,
}) => (
  <div>
    <div className="row">
      <div className="col-12 col-sm-6 col-md-9">
        <div className="row">
          <div className="col-12">
            <h2 className="text-uppercase d-inline">
              REPORT {index}: {report.name}
            </h2>
            <Select
              placeholder="BY VALUE"
              className={`wd-100 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                getUserSystem() === 'unitas'
                  ? 'c-select-header-row--unitas'
                  : 'c-select-header-row--others'
              }`}
              name={`${report.sortOrder}`}
              id={`${report.sortOrder}`}
              options={resolveMeasures(measures, "Wholesale")}
              onChange={opt => onMeasureChange(opt, report.sortOrder)}
              value={report.measure}
              valueKey="ID"
              labelKey="Text"
            />
            {report.showInterval && (
              <Select
                placeholder="FOR LATEST"
                className={`wd-100 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                  getUserSystem() === 'unitas'
                    ? 'c-select-header-row--unitas'
                    : 'c-select-header-row--others'
                }`}
                name={`timeframe${report.sortOrder}`}
                id={`timeframe${report.sortOrder}`}
                options={timeframes}
                onChange={opt => onTimeframeChange(opt, report.sortOrder)}
                value={report.timeframe}
                valueKey="ID"
                labelKey="Text"
              />
            )}
            {report.showLimit && (
              <Select
                placeholder="Display Top"
                className={`wd-100 d-inline-block weekly-dp-small small-dropdown c-select-header ${
                  getUserSystem() === 'unitas'
                    ? 'c-select-header-row--unitas'
                    : 'c-select-header-row--others'
                }`}
                name={`limit`}
                id={`limit`}
                options={limits}
                onChange={opt => onLimitChange(report.reportKey, opt)}
                value={report.limit}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-6 col-md-3">
        <div
          className={`no-space weekly-toggle row toggle-${
            getUserSystem() === 'unitas' ? 'unitas' : 'others'
          }`}>
          <div className="col-3 col-sm-3 m-0 p-0">
            {!report.isCloned ? (
              <button
                id={`${report.sortOrder}`}
                onClick={onCloneReport}
                className={`pull-right btn ${
                  getUserSystem() === 'unitas'
                    ? 'alchemy-btn--unitas'
                    : 'alchemy-btn--others'
                }`}>
                CLONE
              </button>
            ) : (
              <button
                id={`${report.sortOrder}`}
                onClick={onDeleteReport}
                className={`pull-right btn btn-dark-gray`}>
                DELETE
              </button>
            )}
          </div>
          <div className="col-7 col-sm-7 m-0 p-0">
            <Toggle
              id={`${report.sortOrder}`}
              className="pull-right"
              icons={false}
              checked={report.isIncluded}
              onChange={onChange}
            />
            <p className="pull-right mr-2">INCLUDE</p>
          </div>
          <div className="col-2 col-sm-2 m-0 p-0 text-right">
            {report.sortOrder > 1 && (
              <a
                href="javascript:void(0);"
                className="p-0 m-0 u-left"
                onClick={() => onMoveUp(report.reportKey, report.sortOrder)}>
                <i className="fa fa-arrow-up red-text p-2"></i>
              </a>
            )}
            {report.sortOrder < totalNoOfReports && (
              <a
                href="javascript:void(0);"
                className="p-0 m-0 u-right"
                onClick={() => onMoveDown(report.reportKey, report.sortOrder)}>
                <i className="fa fa-arrow-down red-text p-0 order-right-btn"></i>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-6 col-md-4">
        {report.reportKey === 'cat_trend' && (
          <img
            src={require(`../../assets/images/${themeName}/cat_trend.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'top_skus' && (
          <img
            src={require(`../../assets/images/${themeName}/prod_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'sup_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/supp_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'supp_trend' && (
          <img
            src={require(`../../assets/images/${themeName}/supp_trend.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'mem_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/mem_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'cat_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/cat_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'dep_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/dep_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'bt_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/bt_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'brand_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/brand_perf.jpg`)}
            className="example-img"
          />
        )}
        {report.reportKey === 'cs_perf' && (
          <img
            src={require(`../../assets/images/${themeName}/cs_perf.jpg`)}
            className="example-img"
          />
        )}
      </div>
      <div className="col-12 col-sm-6 col-md-8">
        <p className="mb-0">FILTERS (leave blank for all)</p>
        <div className="row">
          <CustomCountSelect
            className={`wd-200 pr-2 c-select-header ${
              getUserSystem() === 'unitas'
                ? 'c-select-header-row--unitas'
                : 'c-select-header-row--others'
            }`}
            onChangeCallback={opt => onDepartmentChange(opt, report.sortOrder)}
            name={`department${report.sortOrder}`}
            id={`department${report.sortOrder}`}
            value={report.departments || []}
            isMulti={true}
            options={departments?.length > 0 ? departments : []}
            type="Department"
            types="Departments"
            placeholder="Department"
          />
          {report?.departments?.length > 0 && (
            <CustomCountSelect
              className={`wd-200 pr-2 c-select-header ${
                getUserSystem() === 'unitas'
                  ? 'c-select-header-row--unitas'
                  : 'c-select-header-row--others'
              }`}
              onChangeCallback={opt => onCategoryChange(opt, report.sortOrder)}
              name={`category${report.sortOrder}`}
              id={`category${report.sortOrder}`}
              value={report.categories || []}
              isMulti={true}
              options={categories?.length > 0 ? categories : []}
              type="Category"
              types="Categories"
              placeholder="Category"
            />
          )}
          {report?.categories?.length > 0 && (
            <CustomCountSelect
              className={`wd-200 pr-2 c-select-header ${
                getUserSystem() === 'unitas'
                  ? 'c-select-header-row--unitas'
                  : 'c-select-header-row--others'
              }`}
              onChangeCallback={opt =>
                onSubCategoryChange(opt, report.sortOrder)
              }
              name={`subcategory${report.sortOrder}`}
              id={`subcategory${report.sortOrder}`}
              value={report.subCategories || []}
              isMulti={true}
              options={
                subCategories?.length > 0 ? subCategories : []
              }
              type="SubCategory"
              types="SubCategories"
              placeholder="SubCategory"
            />
          )}
        </div>
        {report?.subCategories?.length > 0 && (
          <div className="row">
            {level4s?.length > 0 && (
              <CustomCountSelect
                className={`wd-200 pr-2 c-select-header ${
                  getUserSystem() === 'unitas'
                    ? 'c-select-header-row--unitas'
                    : 'c-select-header-row--others'
                }`}
                onChangeCallback={opt => onLevel4Change(opt, report.sortOrder)}
                name={`level4${report.sortOrder}`}
                id={`level4${report.sortOrder}`}
                value={report.level4s || []}
                isMulti={true}
                options={level4s?.length > 0 ? level4s : []}
                type="Level4"
                types="Level4s"
                placeholder="Level4"
              />
            )}
            {report?.level4s?.length > 0 && (
              <CustomCountSelect
                className={`wd-200 pr-2 c-select-header ${
                  getUserSystem() === 'unitas'
                    ? 'c-select-header-row--unitas'
                    : 'c-select-header-row--others'
                }`}
                onChangeCallback={opt => onLevel5Change(opt, report.sortOrder)}
                name={`level5${report.sortOrder}`}
                id={`level5${report.sortOrder}`}
                value={report.level5s || []}
                isMulti={true}
                options={level5s?.length > 0 ? level5s : []}
                type="Level5"
                types="Level5s"
                placeholder="Level5"
              />
            )}
          </div>
        )}

        <div className="row">
          {members?.length > 0 && (
            <CustomCountSelect
              className={`wd-200 pr-2 c-select-header ${
                getUserSystem() === 'unitas'
                  ? 'c-select-header-row--unitas'
                  : 'c-select-header-row--others'
              }`}
              onChangeCallback={opt => onMemberChange(opt, report.sortOrder)}
              name={`member${report.sortOrder}`}
              id={`member${report.sortOrder}`}
              value={report.members || []}
              isMulti={true}
              options={
                members?.length > 0
                  ? members.map(mem => ({
                      ...mem,
                      isDisabled: parseInt(mem.ID) === 9999,
                    }))
                  : []
              }
              type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
              types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
              placeholder="Member"
            />
          )}
          <Select
            style={{ minWidth: '200px', textAlign: 'left' }}
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              spellCheck: 'off',
            }}
            className={`wd-200 pr-2 c-select-header ${
              getUserSystem() === 'unitas'
                ? 'c-select-header-row--unitas'
                : 'c-select-header-row--others'
            }`}
            name={`supplier${report.sortOrder}`}
            id={`supplier${report.sortOrder}`}
            value={report.suppliers || ''}
            onChange={opt => onSupplierChange(opt, report.sortOrder)}
            options={suppliers?.length > 0 ? suppliers : []}
            valueKey="ID"
            labelKey="Text"
            placeholder="Select supplier"
          />
          {businessTypes?.length > 0 && (
            <Select
              style={{ minWidth: '200px', textAlign: 'left' }}
              inputProps={{
                autoComplete: 'off',
                autoCorrect: 'off',
                spellCheck: 'off',
              }}
              className={`wd-200 pr-2 pl-2 c-select-header ${
                getUserSystem() === 'unitas'
                  ? 'c-select-header-row--unitas'
                  : 'c-select-header-row--others'
              }`}
              name={`businesstype${report.sortOrder}`}
              id={`businesstype${report.sortOrder}`}
              value={report.businessTypes || ''}
              onChange={opt => onBusinessTypeChange(opt, report.sortOrder)}
              options={businessTypes?.length > 0 ? businessTypes : []}
              valueKey="ID"
              labelKey="Text"
              placeholder="Select business type"
            />
          )}
        </div>
      </div>
    </div>
    {report.isValidationFailed && (
      <p className="red">
        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{' '}
        {report.message}
      </p>
    )}
    <hr />
  </div>
);
function mapStateToProps(state) {
  let data = {};
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {}),
  lifecycle({
    componentDidMount() {},
  }),
  withState('data', 'handleData', {
    updateDate: '',
    message: '',
  }),
  withHandlers({
    resolveMeasures: () => (measures, dataType) => {
      return measures.filter(measure =>
        getUserNamDashMeasures(dataType).includes(measure.ID.toLowerCase()));
    },
  }),
)(WeeklyReport);
