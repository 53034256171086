import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import { withTheme } from 'styled-components';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import {
  fetchUsers,
  filterUsers,
  deleteUser,
  resetPassword,
  handleDeleteUserModal,
} from '../redux/reducers/user';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import * as _ from 'lodash';
import { getUserSystem } from '../../helpers/util';

const tableClass = ['table-striped o-table'];

const ListUsers = ({
  data,
  theme,
  onNext,
  users,
  usersCount,
  onChange,
  onGoToPage,
  toggleTooltip,
  handleDeleteUserModal,
  openDeleteUserModal,
  onDeleteUser,
  onResetPassword,
  onAddNewUser,
  onEditUser,
  onFilterUsers,
  usersPageNo,
}) => (
  <div className="o-canvas-wrap">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <div className="b-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout">
          <h2 className="o-canvas__title u-100">Users List</h2>
        </div>
      </div>
      <div
        className="o-canvas__header-search u-layout mb-3 u-1_5__inline d-flex align-items-center justify-content-between"
        style={{zIndex: '1000' }}>
        <button
          className={`btn alchemy-btn--${
            getUserSystem() === 'unitas' ? 'unitas' : 'others'
          }  u-right btn-psa`}
          onClick={() => onAddNewUser()}>
          Add New User
        </button>
        <div className="d-flex align-items-center">
          <div className="u-1_5" style={{ margin: '0px 10px' }}>
            <input
              type="text"
              placeholder="Name"
              name="name"
              className="filter-text-box"
              onChange={ev => onChange(ev)}
            />
          </div>
          <div className="u-1_5" style={{ margin: '0px 10px' }}>
            <input
              type="email"
              placeholder="Email"
              name="email"
              className="filter-text-box"
              onChange={ev => onChange(ev)}
            />
          </div>
          <div className="u-1_5" style={{ margin: '0px 10px' }}>
            <input
              type="text"
              placeholder="Supplier"
              name="supplier"
              className="filter-text-box"
              onChange={ev => onChange(ev)}
            />
          </div>
          <div className={`u-1_5 ${data.memberSystems.includes(getUserSystem())
              ? '' : 'd-none'
              }`} style={{ margin: '0px 10px' }}>
            <input
              type="text"
              placeholder="Member"
              name="member"
              className="filter-text-box"
              onChange={ev => onChange(ev)}
            />
          </div>
          <div className="u-1_5" style={{ margin: '0px 10px' }}>
            <input
              type="text"
              placeholder="Tag"
              name="tag"
              className="filter-text-box"
              onChange={ev => onChange(ev)}
            />
          </div>
          <div className="u-1_5">
            <button
              id="btnFilter"
              onClick={() => onFilterUsers()}
              className={`btn alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              }  btn-table mr-10 btn-psa`}>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div
          className="c-objects-wrap--table"
          style={{ position: 'relative', textAlign: 'center' }}>
          <table className={tableClass.join(' ')}>
            <thead>
              <tr>
                <th>
                  <b>Name</b>
                </th>
                <th>
                  <b>Email</b>
                </th>
                <th>
                  <b>Phone</b>
                </th>
                <th>
                  <b>Permissions</b>
                </th>
                <th>
                  <b>Supplier</b>
                </th>
                <th className={data.memberSystems.includes(getUserSystem())
                  ? '' : 'd-none'
                  }>
                  <b>Member</b>
                </th>
                <th>
                  <b>Tags</b>
                </th>
                <th>
                  <b>Actions</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="align-center">
                        {user.firstName} {user.lastName}
                      </td>
                      <td className="align-center">{user.email}</td>
                      <td className="align-center">{user.telephone}</td>
                      <td className="align-center">
                        {user.permissions && user.permissions.length > 0 && (
                          <div>
                            <a
                              href="javascript:void(0);"
                              id={`Tooltip${user.id}`}>
                              View Permissions
                            </a>
                            <Tooltip
                              style={{
                                backgroundColor: 'black',
                                color: '#fff',
                                fontSize: '12px',
                                padding: '10px',
                              }}
                              placement="right"
                              isOpen={data.selectedTooltipId === user.id}
                              target={`Tooltip${user.id}`}
                              toggle={() => toggleTooltip(user.id)}>
                              {user.permissions.split(',').map((per, idx) => (
                                <p
                                  key={idx}
                                  style={{
                                    padding: '0px',
                                    margin: '0px',
                                    backgroundColor: 'black',
                                    color: '#fff',
                                  }}>
                                  {per.split(':')[1]}
                                </p>
                              ))}
                            </Tooltip>
                          </div>
                        )}
                      </td>
                      <td>
                        {user.selectedSuppliers}
                      </td>
                      <td className={data.memberSystems.includes(getUserSystem())
                        ? '' : 'd-none'
                        }>
                        {user.WholesalerName}
                      </td>
                      <td>
                        {user.tags && user.tags.length > 20 ? (
                          <div>
                            <span className="d-block">
                            {user.tags.slice(0, 20)+"..."}
                            </span>
                            <a
                              style={{
                                fontWeight: '700',
                              }}
                              href="javascript:void(0);"
                              id={`Tooltip${user.id+"tag"}`}>
                              View All
                            </a>
                            
                            <Tooltip
                              style={{
                                backgroundColor: 'black',
                                color: '#fff',
                                fontSize: '12px',
                                padding: '10px',
                              }}
                              placement="right"
                              isOpen={data.selectedTooltipId === user.id+"tag"}
                              target={`Tooltip${user.id+"tag"}`}
                              toggle={() => toggleTooltip(user.id+"tag")}>
                              {user.tags.split(',').map((per, idx) => (
                                <p
                                  key={idx+"tag"}
                                  style={{
                                    padding: '0px',
                                    margin: '0px',
                                    backgroundColor: 'black',
                                    color: '#fff',
                                  }}>
                                  {per}
                                </p>
                              ))}
                            </Tooltip>
                          </div>
                        ) : (
                          user.tags
                          )}


                      </td>
                      <td className="align-center u-right">
                        <div>
                          <button
                            id="btnResetPassword"
                            onClick={() => onResetPassword(user.userId)}
                            className={`btn alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            }  btn-table mr-10 btn-psa`}>
                            Reset Password
                          </button>
                          <button
                            id="btnEdit"
                            onClick={() => onEditUser(user.userId)}
                            className={`btn alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            }  btn-table mr-10 btn-psa`}>
                            Edit
                          </button>
                          <button
                            id="btnDelete"
                            onClick={() => handleDeleteUserModal(user.userId)}
                            className={`btn alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            }  btn-table  btn-psa`}>
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              <Modal isOpen={openDeleteUserModal}>
                <ModalHeader
                  toggle={() => handleDeleteUserModal(data.selectedUserId)}>
                  Confirm Delete User
                </ModalHeader>
                <ModalBody>
                  Are you sure you would like to delete this user?
                </ModalBody>
                <ModalFooter>
                  
                  <button
                  className="btn"
                  style={{
                    backgroundColor: theme.buttons,
                    borderColor: theme.buttons,
                  }}
                  onClick={() => onDeleteUser()}>
                    Yes
                  </button>{' '}
                  <button
                  className="btn"
                   style={{
                      backgroundColor: theme.buttons,
                      borderColor: theme.buttons,
                    }}
                    onClick={() => handleDeleteUserModal(data.selectedUserId)}>
                    No
                  </button>
                </ModalFooter>
              </Modal>
            </tbody>
          </table>
          {usersCount > 20 && (
            <div className="pagination" style={{ padding: '16px' }}>
              <a href="javascript:void(0);" onClick={() => onGoToPage(1)}>
                &laquo;
              </a>
              {_.times(usersCount / 20, c => {
                return (
                  <a
                    href="javascript:void(0);"
                    style={
                      usersPageNo === c + 1
                        ? { backgroundColor: '#6bbe58' }
                        : {}
                    }
                    onClick={() => onGoToPage(c + 1)}>
                    {c + 1}
                  </a>
                );
              })}
              <a
                href="javascript:void(0);"
                onClick={() => onGoToPage(usersCount / 20)}>
                &raquo;
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.user.isLoading,
    isDeleteUserSuccess: state.user.isDeleteUserSuccess,
    isPasswordResetSuccess: state.user.isPasswordResetSuccess,
    openDeleteUserModal: state.user.openDeleteUserModal,
    message: state.user.message,
    users: state.user.users,
    usersPageNo: state.user.usersPageNo,
    usersCount: state.user.usersCount,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    deleteUser,
    handleDeleteUserModal,
    filterUsers,
    resetPassword,
    fetchUsers,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchUsers(0);
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.isDeleteUserSuccess !== this.props.isDeleteUserSuccess &&
        this.props.isDeleteUserSuccess
      ) {
        toast.dismiss();
        toast.info('User deleted successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.fetchUsers(this.props.usersPageNo);
      }
      if (
        prevProps.isPasswordResetSuccess !==
          this.props.isPasswordResetSuccess &&
        this.props.isPasswordResetSuccess
      ) {
        toast.dismiss();
        toast.info('Reset Password successful', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (
        prevProps.isLoading === true &&
        this.props.isLoading === false &&
        !this.props.isDeleteUserSuccess &&
        this.props.message &&
        this.props.message.length > 0
      ) {
        toast.dismiss();
        toast.info(this.props.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  }),
  withState('data', 'handleData', {
    selectedTooltipId: '',
    selectedUserId: 0,
    tag: '',
    name: '',
    email: '',
    memberSystems: ['countryRangeGroup', 'unitas', 'confex', 'caterforce' ],
  }),
  withTheme,
  withHandlers({
    onEditUser: props => id => {
      props.history.push(`${process.env.APP_ROOT}create_user/user/${id}`);
    },
    onResetPassword: props => id => {
      props.resetPassword(id);
    },
    onAddNewUser: props => () => {
      props.history.push(`${process.env.APP_ROOT}create_user/user`);
    },
    onGoToPage: ({ data, handleData, fetchUsers }) => pageNo => {
      fetchUsers(pageNo);
    },
    onFilterUsers: ({ data, handleData, filterUsers }) => () => {
      filterUsers(0, data.name, data.email, data.tag, data.supplier, data.member);
    },
    onChange: ({ data, handleData }) => ev => {
      const { name, value } = ev.target;
      handleData({ ...data, [name]: value });
    },
    onDeleteUser: ({ data, handleData, deleteUser }) => () => {
      deleteUser(data.selectedUserId);
    },
    handleDeleteUserModal: ({
      data,
      handleData,
      handleDeleteUserModal,
    }) => id => {
      handleData({ ...data, selectedUserId: id });
      handleDeleteUserModal();
    },
    toggleTooltip: ({ data, handleData }) => id => {
      if (data.selectedTooltipId.length > 0) {
        handleData({ ...data, selectedTooltipId: '' });
      } else {
        handleData({ ...data, selectedTooltipId: id });
      }
    },
  }),
)(ListUsers);
