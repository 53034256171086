import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import HtmlEditor from '../HtmlEditor';

const yesNoOptions = [
  { ID: 1, Text: 'Yes' },
  { ID: 0, Text: 'No' },
];

const AddDropship = ({
  isLoading,
  isAddDropship,
  dropshipForm,
  onSaveDropship,
  onFormSelectChange,
  onFormTextChange,
  onFormFileChange,
  onFormDepartmentChange,
  onFormCategoryChange,
  onDistributionEnglandChange,
  onDistributionScotlandChange,
  onDistributionWalesChange,
  onDistributionNIChange,
  onRetailChange,
  onWholesaleChange,
  onFoodservicesChange,
  onOnTradeChange,
  onNewFlagChange,
  handleDropshipAddModal,
}) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{!isAddDropship ? 'Update' : 'Add New'} Dropship</span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Department</h3>
              <Select value={dropshipForm.Department} onChange={onFormDepartmentChange} options={dropshipForm.departments} valueKey="ID" labelKey="Text" />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Categories</h3>
              <Select value={dropshipForm.Category} onChange={onFormCategoryChange} options={dropshipForm.categories} valueKey="ID" labelKey="Text" />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Description</h3>
              <input type="text" id="description" className="ReactModal--input-green" name="description" value={dropshipForm.Description || ''} onChange={evt => onFormTextChange('Description', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Details</h3>
              <HtmlEditor value={dropshipForm.Details || ''} onChange={value => onFormTextChange('Details', { value: value })} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Supplier Name</h3>
              <input type="text" id="supplierName" className="ReactModal--input-green" name="supplierName" value={dropshipForm.SupplierName || ''} onChange={evt => onFormTextChange('SupplierName', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Distribution England</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.DistributionEngland) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onDistributionEnglandChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Distribution Scotland</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.DistributionScotland) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onDistributionScotlandChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Distribution NI</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.DistributionNI) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onDistributionNIChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Distribution Wales</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.DistributionWales) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onDistributionWalesChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Retail</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.Retail) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onRetailChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Wholesale</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.Wholesale) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onWholesaleChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Food Services</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.Foodservices) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onFoodservicesChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">On Trade</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(dropshipForm.OnTrade) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onOnTradeChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Advert1} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert1</h3>
              <input type="file" name="Advert1" onChange={evt => onFormFileChange('Advert1', evt.target.files[0])} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Advert2} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert2</h3>
              <input type="file" name="Advert2" onChange={evt => onFormFileChange('Advert2', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Advert3} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert3</h3>
              <input type="file" name="Advert3" onChange={evt => onFormFileChange('Advert3', evt.target.files[0])} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Advert4} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert4</h3>
              <input type="file" name="Advert4" onChange={evt => onFormFileChange('Advert4', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Advert5} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert5</h3>
              <input type="file" name="Advert5" onChange={evt => onFormFileChange('Advert5', evt.target.files[0])} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={dropshipForm.Logo} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Logo</h3>
              <input type="file" name="Logo" onChange={evt => onFormFileChange('Logo', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Retailer Terms</h3>
              <input type="text" id="retailerTerms" className="ReactModal--input-green" name="RetailerTerms" value={dropshipForm.RetailerTerms || ''} onChange={evt => onFormTextChange('RetailerTerms', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Minimum Drop</h3>
              <input type="text" id="minimumDrop" className="ReactModal--input-green" name="minimumDrop" value={dropshipForm.MinimumDrop || ''} onChange={evt => onFormTextChange('MinimumDrop', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Job Title</h3>
              <input type="text" id="jobTitle" className="ReactModal--input-green" name="jobTitle" value={dropshipForm.JobTitle || ''} onChange={evt => onFormTextChange('JobTitle', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Contact Name</h3>
              <input type="text" id="contactName" className="ReactModal--input-green" name="contactName" value={dropshipForm.ContactName || ''} onChange={evt => onFormTextChange('ContactName', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Telephone Number</h3>
              <input type="text" id="telephoneNumber" className="ReactModal--input-green" name="telephoneNumber" value={dropshipForm.TelephoneNumber || ''} onChange={evt => onFormTextChange('TelephoneNumber', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Email Address</h3>
              <input type="text" id="emailAddress" className="ReactModal--input-green" name="emailAddress" value={dropshipForm.EmailAddress || ''} onChange={evt => onFormTextChange('EmailAddress', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">New Until</h3>
              <Datetime value={dropshipForm.NewUntil} onChange={date => onFormTextChange('NewUntil', { value: date.format('YYYY-MM-DD HH:mm:ss') })} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSaveDropship()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handleDropshipAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDropship;
