import React from 'react';
import PropTypes from 'prop-types';

class MainWrapper extends React.Component {
	render() {
		return (
			React.cloneElement(this.props.children, this.props)
		);
	}
}

MainWrapper.defaultProps = { children: null };

MainWrapper.propTypes = {
	children: PropTypes.any,
};

export default MainWrapper;
