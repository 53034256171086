import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import jQuery from 'jquery';
import { render } from 'react-dom';
import Es6Promise from 'es6-promise';
import Routing from './Routing';
//import { hotjar } from 'react-hotjar';
import GlobalStyle from '../src/react/components/GlobalStyles';
require('./assets/sass/style.scss');

const values = require('object.values');

/* Global jQuery */
window.jQuery = jQuery;
window.$ = jQuery;

Es6Promise.polyfill();

/* Object.values function */
if (!Object.values) {
  values.shim();
}

const AppContainer = document.querySelector('.alchemy_app');

if (AppContainer) {
  require('./Sentry');

  render(
    <>
      <GlobalStyle />
      <Routing />
    </>,
    document.querySelector('.alchemy_app'),
  );
  //hotjar.initialize(1844215, 6);
} else {
  console.info('Development environment');
  import('./helpers/RenderReactAsync').then(RenderReactAsync => {
    RenderReactAsync.default();
  });
}

if ('serviceWorker' in navigator) {
  const path = `${process.env.APP_ROOT}sw.js`;

  navigator.serviceWorker
    .register(path)
    .then(() => {
      console.log('SW registered successfully');
    })
    .catch(e => {
      console.warn('SW: Not registered', e);
    });
}
