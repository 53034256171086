import React from 'react';
import PropTypes from 'prop-types';

import pageStandard from '../components/wrappers/pageStandard';

import { API_ENDPOINTS } from '../../AlchemySettings';

import AlchContextMenu from '../components/AlchUI/AlchContextMenu';
import AlchDateRangepicker from '../components/AlchUI/AlchDateRangepicker';
import TableSimple from '../components/TableSimple';

import { getMyStockBase, getMyStockData } from '../../helpers/apiMiddleware';

import { dateToYearWeek, getRandomNumber, exportTableToCSV } from '../../helpers/helpers';

const { myStockBase, myStockData } = API_ENDPOINTS;

class MyStock extends React.Component {
  state = {
    viewBase: {
      custom: {
        datesPreselect: Array.prototype,
      },
      dates: {
        start: Date.prototype,
        end: Date.prototype,
      },
    },
    viewData: {
      headers: Array.prototype,
      rows: Array.prototype,
    },
    viewOptions: {
      dateRange: undefined,
    },
  };

  dataRequestMiddleware = (params) => {
    return {
      requestParams: {
        startDate: dateToYearWeek(params.dateRange.start),
        endDate: dateToYearWeek(params.dateRange.end),
      },
    };
  };

  setPageConfig = () => {
    const baseURL = {
      root: myStockBase,
    };
    const dataURL = {
      root: myStockData,
    };
    const middleware = {
      base: { root: getMyStockBase },
      data: {
        root: getMyStockData,
        requests: this.dataRequestMiddleware,
      },
    };

    return {
      baseURL,
      dataURL,
      middleware,
    };
  };

  exportData = (type) => {
    if (type === 'csv') {
      exportTableToCSV($('.c-table'), `${getRandomNumber('export_')}.csv`);
    }

    if (type === 'pdf') {
      let pdfExportIframe = $('#pdf_export');
      // Create pdf export iframe
      if (pdfExportIframe.length === 0) {
        pdfExportIframe = $('<iframe />');
        pdfExportIframe.attr({
          src: 'http://wsale.esands.co.uk/test_pdf.php',
          id: 'pdf_export',
          name: 'pdf',
          style: 'display:none',
        });
        $('body').append(pdfExportIframe);
      }

      pdfExportIframe.contents().find('#table_html').val($('.c-objects-wrap--table').html());
      pdfExportIframe.contents().find('#graph_html').val('');
      pdfExportIframe
        .contents()
        .find('#title')
        .val(`<h1>Stock Report</h1><h4>${$('.c-paragraph--secondary').text()}</h4>`);
      pdfExportIframe.contents().find('#timeframe').val('');
      pdfExportIframe.contents().find('#measure').val('');
      pdfExportIframe.contents().find('#filter').val('');
      pdfExportIframe.contents().find('#filter_type').val('');
      pdfExportIframe.contents().find('#orientation').val('landscape');
      pdfExportIframe.contents().find('#pdf_form').submit();
    }
  };

  parseTableCells = (cell) => {
    return <div dangerouslySetInnerHTML={{ __html: cell }} />;
  };

  render() {
    return (
      <motion.div key={'MYSTOCK12414214'} initial="initial" animate="in" exit="out" variants={pageVariants}>
        <div className="o-canvas-wrap">
          <div className="o-canvas">
            <div className="o-canvas__header u-layout u-layout--between">
              <div className="o-canvas__header-item u-layout u-layout--between-base">
                <h2 className="o-canvas__title">WEEKLY STOCK &amp; SALES</h2>
              </div>
              <div className="o-canvas__header-item">
                <AlchContextMenu label="Export" onSelect={this.exportData}>
                  {[
                    { id: 'csv', label: 'CSV' },
                    { id: 'pdf', label: 'PDF' },
                  ]}
                </AlchContextMenu>
              </div>
            </div>
            <div className="o-canvas__body u-layout u-layout--spaced t-brand-a">
              <div className="u-1_1">
                <AlchDateRangepicker name="dateRange" preselects={this.state.viewBase.custom.datesPreselect} defaultValue={this.state.viewBase.dates} {...this.setControlledComponent('dateRange')} />
              </div>
              <div className="u-1_1 u-space--l">
                <TableSimple className="c-objects-wrap--sticky" tableClassName="c-table c-table--standard" data={this.state.viewData} parseEachCell={this.parseTableCells} />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

MyStock.defaultProps = {
  systemSettings: {},
};

MyStock.propTypes = {
  alertAdd: PropTypes.func.isRequired,
};

export default pageStandard(MyStock);
