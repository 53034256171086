import React from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import data1 from './component_data/topo.json';
import northernIrelandData from './component_data/northernIreland.json';
import isleOfManData from './component_data/isleOfMan.json';
import { getUserSystem } from '../../helpers/util';
// Use styles from live site
// fix the stylesheet
import '../../assets/css/leaflet.css';
import * as topojson from 'topojson';

class Heatmap extends React.Component {
  state = { lat: 54.522064, lon: -3.460449, isZoomed: false };

  componentDidMount() {
    this.initializeMap();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.updateMapData(nextProps.data);
    }
    if (this.props.regionData !== nextProps.regionData) {
      this.updateRegionData(nextProps.regionData);
    }

    if (nextProps.isLoading) {
      this.markersGroup.clearLayers();
    }
  }

  initializeMap = () => {
    if (this.Map) {
      this.Map.remove();
    }

    window.L_PREFER_CANVAS = true;
    this.Map = Leaflet.map('map', '', { zoomControl: false }).setView(
      [this.state.lat, this.state.lon],
      5,
    );
    this.Map.touchZoom.disable();
    this.Map.doubleClickZoom.disable();
    this.Map.scrollWheelZoom.disable();
    this.Map.keyboard.disable();
    this.layerGroup = Leaflet.layerGroup().addTo(this.Map);
    this.markersGroup = Leaflet.layerGroup().addTo(this.Map);
    this.Map.on('zoom', () => {
      var currentZoom = this.Map.getZoom();
      if (currentZoom > 7) {
        this.setState(prevState => ({ ...prevState, isZoomed: true }));
      } else {
        this.setState(prevState => ({ ...prevState, isZoomed: false }));
      }
    });
    this.drawLayers();
  };

  drawLayers = () => {
    // Northern Ireland
    //   const data = require('./component_data/topo2.json');
    //   $.each(data.objects.eer.geometries, (i, item) => {
    //     const eer = topojson.feature(data, item);

    //     const layer = Leaflet.geoJson(null, {
    //       details: {
    //         id: item.id,
    //         region: item.properties.PC_NAME,
    //       },
    //       style: {
    //         fillColor: '#a9c5d1',
    //         color: '#a9c5d1',
    //         weight: 1,
    //         opacity: 1,
    //       },
    //     });
    //     layer.addTo(this.layerGroup);
    //     layer.addData(eer);
    //     this.geojson = layer;
    //     layer.on('click', (e) => {
    //       this.regionClick(e, layer);
    //     });
    //   });

    northernIrelandData.geometry.id = 'N06000013';
    const NIlayer = Leaflet.geoJson(null, {
      details: {
        // ONS CODE N07000001
        id: 'N06000013',
        region: 'Northern Ireland',
      },
      style: {
        fillColor: '#a9c5d1',
        color: '#a9c5d1',
        weight: 1,
        opacity: 1,
      },
    });
    NIlayer.addTo(this.layerGroup);
    NIlayer.addData(northernIrelandData.geometry);
    this.geojson = NIlayer;
    NIlayer.on('click', e => {
      this.regionClick(e, NIlayer);
    });

    // UK

    $.each(data1.objects.eer.geometries, (i, item) => {
      const eer = topojson.feature(data1, item);
      const layer = Leaflet.geoJson(null, {
        details: {
          id: item.id,
          region: item.properties.EER13NM,
        },
        style: {
          fillColor: '#a9c5d1',
          color: '#a9c5d1',
          weight: 1,
          opacity: 1,
        },
      });
      layer.addTo(this.layerGroup);
      layer.addData(eer);
      this.geojson = layer;
      layer.on('click', e => {
        this.regionClick(e, layer);
      });
    });

    isleOfManData.geometry.id = 'IM000001';

    const layer = Leaflet.geoJson(null, {
      details: {
        // ONS Code M00000001
        id: 'IM000001',
        region: isleOfManData.properties.ADMIN,
      },
      style: {
        fillColor: '#a9c5d1',
        color: '#a9c5d1',
        weight: 1,
        opacity: 1,
      },
    });
    layer.addTo(this.layerGroup);
    layer.addData(isleOfManData.geometry);
    this.geojson = layer;
    layer.on('click', e => {
      this.regionClick(e, layer);
    });
  };

  updateMapData = layersData => {
    // this.drawLayers();
    // loop through each layer and style
    if (Object.keys(layersData).length < 1) {
      this.initializeMap();
      return;
    }

    this.layerGroup.eachLayer(layer => {
      layer.setStyle({});
      /* Check if we have data for layer if not theres nothing to do */
      const layerID = layer.options.details.id;
      if (!layersData[layerID]) {
        layer.setStyle({});
        return;
      }
      // set the style based on the css details in the json.
      layer.setStyle(layersData[layerID].css);
    });
  };

  updateRegionData = regionData => {
    const dotIcon = Leaflet.divIcon({ className: 'c-dot-icon' });
    const getPostcode = displayName => {
      const split = displayName.split('- ');
      if (split && split.length > 0) {
        return split[1];
      }
      return '';
    };
    // const postcodeIcon = Leaflet.icon({
    //   iconUrl: 'my-icon.png',
    //   iconSize: [38, 95],
    //   iconAnchor: [22, 94],
    //   popupAnchor: [-3, -76],
    //   shadowUrl: 'my-icon-shadow.png',
    //   shadowSize: [68, 95],
    //   shadowAnchor: [22, 94],
    // });
    /* Clear layers */
    this.markersGroup.clearLayers();

    // if ()

    regionData.forEach(item => {
      let popupContent = '';
      popupContent += `
				<div class="pop-header">
					<h2>${item.DisplayName}</h2>
					<div class="depot-share">Distribution Share: ${Math.round(
            item.DepoptShare * 100,
            2,
          )}%</div>
				</div>
				<div class="pop-up ${item.Class}">
					<p>${item.DisplayBlob}</p>
                                        <p>Customer Count: ${
                                          item.CustomerCount
                                        }</p>
                                        <p>Product Count: ${
                                          item.ProductCount
                                        }</p>
				</div>`;

      const marker = Leaflet.marker([item.Lat, item.Long], {
        icon:
          getUserSystem() === 'countryRangeGroup'
            ? Leaflet.divIcon({
                className: 'c-postcode-icon',
                html: `<span>${getPostcode(item.DisplayName)}</span>`,
              })
            : dotIcon,
      }).addTo(this.markersGroup);
      marker.bindPopup(popupContent);
    });
  };

  regionClick = (e, layer) => {
    const region = e.layer;
    this.props.onRegionClick(region, this.Map, layer);
    if (region.options.className) {
      this.props.onDataRegionClick(region, this.Map, layer);
    }
  };

  render() {
    return (
      <div
        id="map"
        className={`c-objects-wrap c-objects-wrap--leaflet ${
          this.state.isZoomed ? 'area-heatmap--is-zoomed' : ''
        }`}
        ref={this.mapEl}
      />
    );
  }
}

Heatmap.defaultProps = {
  data: Object.prototype,
  regionData: Array.prototype,
  onRegionClick: Function.prototype,
  onDataRegionClick: Function.prototype,
};
/* eslint react/no-unused-prop-types:0 */
Heatmap.propTypes = {
  data: PropTypes.object,
  regionData: PropTypes.array,
  onRegionClick: PropTypes.func,
  onDataRegionClick: PropTypes.func,
};

export default Heatmap;
