import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 3rem;
`;

const Error = ({ message }) => {
  return (
    <ErrorContainer>
      <ErrorOutlineIcon style={{ color: 'red' }} />
      <p>{message}</p>
    </ErrorContainer>
  );
};

export default Error;
