import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { supplierEnagagementKPI as explanation } from '../../Explainer/Explanations';
import { coloredTableTheme } from '../../../../config/tableStyles';
import { formatToCurrency } from '../../../../helpers/helpers';
import StyledContainer from './StyledContainer';
import { supplierEngagementKPI } from '../../Explainer/Explanations';

const StyledTitle = styled.h2`
  &&& {
    font-size: 1.125rem !important;
    padding: 1rem;
  }
`;

const { tableHeaders, rowHeaders } = explanation;

const KPITable = ({ data, title, extraColumn, columnTitle }) => {
  const columns = [
    {
      selector: 'Name',
      name: <TableHeaderTooltip text={columnTitle || 'Title'} />,
      sortable: true,
      grow: 2,
      wrap: true,
      cell: row => (
        <TableHeaderTooltip
          text={row.Name}
          explanationTooltip={rowHeaders[row.Name]}
          colour="#000"
        />
      ),
    },

    {
      selector: 'TY',
      name: (
        <TableHeaderTooltip text="TY" explanationTooltip={tableHeaders.ty} />
      ),
      sortable: true,
      cell: row => (
        <div
          className={row.TY < 0 ? 'negative-table-cell' : ''}
          dangerouslySetInnerHTML={{
            __html: row.TY,
          }}></div>
      ),
      sortFunction: (rowA, rowB) => rowA.TY - rowB.TY,
    },
    {
      selector: 'LY',
      name: (
        <TableHeaderTooltip text="LY" explanationTooltip={tableHeaders.ly} />
      ),
      sortable: true,

      cell: row => (
        <div
          className={row.LY < 0 ? 'negative-table-cell' : ''}
          dangerouslySetInnerHTML={{
            __html: row.LY,
          }}></div>
      ),
      sortFunction: (rowA, rowB) => rowA.LY - rowB.LY,
    },
    {
      selector: 'Supplier Change',
      name: (
        <TableHeaderTooltip
          text="Supplier Change"
          explanationTooltip={tableHeaders.supplierChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.SupplierChange.replace('%', '') < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {parseFloat(row.SupplierChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.SupplierChange - rowB.SupplierChange,
    },
    {
      selector: 'CategoryChange',
      name: (
        <TableHeaderTooltip
          text="Category Change"
          explanationTooltip={tableHeaders.categoryChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.CategoryChange.replace('%', '') < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {row.CategoryChange === '-'
            ? row.CategoryChange
            : `${parseFloat(row.CategoryChange).toFixed(2)}%`}
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.CategoryChange - rowB.CategoryChange,
    },
    /*  {
      selector: 'SizeOfPrize',
      name: (
        <TableHeaderTooltip
          text="Contribution"
          explanationTooltip={tableHeaders.sizeOfPrize}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.SizeOfPrize < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }
          dangerouslySetInnerHTML={{
            __html: row.SizeOfPrize,
          }}></div>
      ),
      sortFunction: (rowA, rowB) => rowA.SizeOfPrize - rowB.SizeOfPrize,
    },*/
  ];

  extraColumn &&
    columns.splice(1, 0, {
      selector: extraColumn.selector,
      name: (
        <TableHeaderTooltip
          text={extraColumn.selector}
          explanationTooltip={extraColumn.tooltip}
        />
      ),
    });

  return (
    <StyledContainer>
      <StyledTitle className="text-uppercase">{title}</StyledTitle>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'Index'}
        defaultSortAsc={true}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default KPITable;
