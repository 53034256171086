import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Button from '../Button';
import { motion, AnimatePresence } from 'framer-motion';
import Collapsible from 'react-collapsible';
import { Row, Col, Button as BootstrapButton } from 'reactstrap';

import Explainer from '../Explainer/Explainer';
import DropdownButton from '../DropdownButton';
import DataFiltersContainer from './DataFiltersContainer';
import AppliedFilters from './AppliedFilters';

const ReportFiltersContainer = ({
  title,
  dropdowns,
  children,
  exports,
  currentReportType,
  switchableReportTypes,
  onSwitchReportType,
  isCollapsible,
  refresh,
  reset,
  sections,
  addFilterGroup,
  warningTooltip,
  explanationTooltip,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [appliedFilters, setAppliedFilers] = useState([]);
  useEffect(() => {
    const dropdowns = sections.reduce((accumulator, element) => {
      return [...accumulator, ...element.dropdowns];
    }, []);
    const appliedFilters = dropdowns.filter(
      dropdown =>
        (dropdown.value && dropdown.value.Text) ||
        (dropdown.value && dropdown.value.WVProdDesc),
    );
    setAppliedFilers(appliedFilters);
  }, [sections]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between pb-3">
            <div className="sub-menu__left">
              <div className="d-flex justify-content-start">
                {switchableReportTypes ? (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <h2
                      className="text-uppercase mb-0 mr-2"
                      style={{ paddingTop: '0.6rem', fontWeight: '700' }}>
                      {title}
                    </h2>
                    <Select
                      className={`wd-140 small-dropdown c-select-header c-select-header-row--others ml-0`}
                      name={'ReportType'}
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      id={'ReportType'}
                      placeholder={'Report Type'}
                      value={currentReportType}
                      onChange={option => onSwitchReportType(option)}
                      options={switchableReportTypes}
                      valueKey={'ID'}
                      labelKey={'Text'}
                      clearable={false}
                      searchable={false}
                      disabled={isDisabled}
                    />
                  </div>
                ) : (
                  <Explainer
                    explainerText={title}
                    warningTooltip={warningTooltip}
                    explanationTooltip={explanationTooltip}
                  />
                )}
              </div>
              {dropdowns &&
                dropdowns.map(
                  (
                    {
                      value,
                      options,
                      handler,
                      valueKey,
                      labelKey,
                      name,
                      isText,
                      text,
                      clearable,
                    },
                    i,
                  ) => {
                    return text ? (
                      <div
                        key={`dropdown-${i}`}
                        className="btn-group text-uppercase">
                        <span className="dropdown-align-fix">{text}</span>
                      </div>
                    ) : (
                      <div
                        key={`dropdown-${i}`}
                        className="btn-group text-uppercase dropdown-align-fix-placeholder">
                        <Select
                          className={`wd-140 small-dropdown c-select-header c-select-header-row--others`}
                          name={name}
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          id={name}
                          placeholder={name}
                          value={value}
                          onChange={option => handler(option)}
                          options={options}
                          valueKey={valueKey}
                          labelKey={labelKey}
                          clearable={!!clearable}
                          searchable={false}
                        />
                      </div>
                    );
                  },
                )}
              <BootstrapButton
                className="ml-2"
                onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? 'Hide Filters' : 'Show Filters'}
              </BootstrapButton>
            </div>
          </div>

          <div className="sub-menu__right d-flex align-items-start">
            <div className="d-flex flex-wrap__wrap">
              {/* <div className="col-12 col-md-6 no-space">
                  <Button
                    onClickHandler={refreshHandler}
                    isDisabled={isRefreshDisabled}
                    iconClass="fa fa-refresh"
                    text="Refresh"
                  />
                </div> */}

              <div className="col-12 col-md-6 no-space">
                <DropdownButton
                  classes="mt-2"
                  isOpen={true}
                  dropdownItems={[
                    {
                      text: 'pdf',
                    },
                    {
                      text: 'csv',
                      exportData: exports.CSV || {},
                    },
                    ,
                    {
                      text: 'csv_all',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {appliedFilters && (
          <AppliedFilters
            as={motion.div}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0, scale: 0 }}
            appliedFilters={appliedFilters}
          />
        )}
      </AnimatePresence>
      <Row>
        <Col>
          <Collapsible
            open={isOpen}
            trigger={<Button classes="d-none" text="Filters" />}
            className=""
            openedClassName=" is-open"
            triggerClassName="collapse-trigger d-flex justify-content-end m-2"
            triggerOpenedClassName="collapse-trigger d-flex justify-content-end m-2"
            overflowWhenOpen="visible"
            transitionTime={200}>
            <DataFiltersContainer
              addFilterGroup={addFilterGroup}
              refresh={() =>
                typeof refresh === 'function'
                  ? refresh()
                  : refresh.refreshHandler()
              }
              reset={reset}
              setIsOpen={setIsOpen}
              sections={sections}
            />
          </Collapsible>
        </Col>
      </Row>
    </>
  );
};

export default ReportFiltersContainer;
