import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import { fetchBarcodeComplianceReport } from '../redux/reducers/complianceReport';
import { exportTableToCSV, getRandomNumber } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import TableBarcodeComplianceReport from '../components/TableBarcodeComplianceReport';
import Loading from '../components/Loading';
import AlchContextMenu from '../components/AlchUI/AlchContextMenu';
import * as _ from 'lodash';

const tableClass = ['o-table'];

const BarcodeComplianceReport = ({
  data,
  isLoading,
  onExportData,
  barcodeComplianceReport,
}) => (
  <div className="o-canvas-wrap">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout">
          <h2 className="o-canvas__title u-100">Barcode Compliance Report</h2>
        </div>
        <div className="o-canvas__header-item">
          <AlchContextMenu label="Export" onSelect={onExportData}>
            {[{ id: 'csv', label: 'CSV' }]}
          </AlchContextMenu>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div
          className="c-objects-wrap--table"
          style={{ position: 'relative', textAlign: 'center' }}>
          <div>{isLoading && <Loading />}</div>
          <p>
            CRG - Barcode Compliance - Key: No of Skus in Category / % of Sku
            with Barcode / % of Sku Sales with Barcodes
          </p>
          {isLoading === false && (
            <TableBarcodeComplianceReport
              id="barcode_compliance_report_table"
              data={barcodeComplianceReport}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.complianceReport.isLoading,
    barcodeComplianceReport: state.complianceReport.barcodeComplianceReport,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchBarcodeComplianceReport,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchBarcodeComplianceReport();
    },
    componentDidUpdate(prevProps) {},
  }),
  withState('data', 'handleData', {}),
  withHandlers({
    onExportData: props => type => {
      if (type === 'csv') {
        exportTableToCSV(
          $('#barcode_compliance_report_table'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
      }
    },
  }),
)(BarcodeComplianceReport);
