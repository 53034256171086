import styled from 'styled-components';

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  margin: 1rem 0;
  position: relative;
`;

export default StyledCard;
