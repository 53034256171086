import theme from './Themes.js';

export const lightTableTheme = {
  table: {
    style: {
      color: `${theme.primary} !important`,
      //  backgroundColor: theme.background.default,
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  rows: {
    style: {
      minHeight: '72px', // override the row height
      fontSize: '16px',
      color: `${theme.primary} !important`,
    },
  },
  headRow: {
    style: {
      color: theme.primary,
    },
  },
  headCells: {
    style: {
      paddingLeft: '0', // override the cell padding for head cells
      paddingRight: '0',
      fontSize: '16px',
      color: theme.primary,
    },
    activeSortStyle: {
      color: theme.primary,
    },
    inactiveSortStyle: {
      '&:focus': {
        color: theme.primary,
      },
      '&:hover': {
        color: theme.primary,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '0', // override the cell padding for data cells
      paddingRight: '0',
    },
  },
  rows: {
    style: {
      fontSize: '16px',
      color: `${theme.primary} !important`,
    },
  },
};

export const coloredTableTheme = {
  table: {
    style: {
      color: theme.fontColor,
      //  backgroundColor: theme.background.default,
      textAlign: 'center',
      fontSize: '16px',
    },
  },
  rows: {
    style: {
      minHeight: '60px', // override the row height
      fontSize: '16px',
      color: theme.fontColor,
    },
  },
  headRow: {
    style: {
      backgroundColor: theme.primary,
      minHeight: '70px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '0', // override the cell padding for head cells
      paddingRight: '0',
      fontSize: '16px',
      color: '#fff',
    },
    activeSortStyle: {
      color: '#fff',
    },
    inactiveSortStyle: {
      '&:focus': {
        color: '#fff',
      },
      '&:hover': {
        color: '#fff',
      },
    },
  },
  cells: {
    style: {
      paddingLeft: '0', // override the cell padding for data cells
      paddingRight: '0',
      width: 'auto'
    },
  },
};
