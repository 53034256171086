import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import Advert from '../models/Advert';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';

const SET_IS_ADVERT_CREATED = 'pfpAdvert/IS_ADVERT_CREATED';
const SET_IS_ADVERT_DELETED = 'pfpAdvert/IS_ADVERT_DELETED';
const SET_ADVERT = 'pfpAdvert/SET_ADVERT';
const FETCH_ADVERTS = 'pfpAdvert/FETCH_ADVERTS';
const SET_LOADING = 'pfpAdvert/SET_LOADING';
const SET_FORM = 'pfpAdvert/SET_FORM';
const RESET_ME = 'pfpAdvert/RESET_ME';

const baseURL = 'https://api.wsale.co.uk';

const initialState = {
  isLoading: false,
  isAdvertCreated: false,
  isAdvertDeleted: false,
  adverts: [],
  advertForm: {...Advert}
  };

  
const setAdvertAction = createAction(SET_ADVERT);
const fetchAdvertsAction = createAction(FETCH_ADVERTS);
const setIsAdvertCreatedAction = createAction(SET_IS_ADVERT_CREATED);
const setIsAdvertDeletedAction = createAction(SET_IS_ADVERT_DELETED);
const setLoadingAction = createAction(SET_LOADING);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const onSaveAdvert = (uploadFiles) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsAdvertCreatedAction(false));
  const { advertForm } = store.getState().pfpAdvert;
  let data = {...advertForm};
  delete data.Updated;
  let url = '/route.php?c=pfp/contentAdvert/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/contentAdvert/update';
  } else {
    delete data.ID;
  }
  let formData = new FormData();
  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map((fieldName) =>{
    const file = uploadFiles[fieldName];
    formData.append(fieldName, file, file.name);
    delete data[fieldName];
  });

  Object.keys(data).map((fieldName) =>{
    formData.append(fieldName, data[fieldName]);
  });

  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          },
          data: formData
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsAdvertCreatedAction(true));
  });
}



export const fetchAdverts = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  let url = '/route.php?c=pfp/contentAdvert/getAll&inc_future=1';
  customRequest({url: baseURL + url,
    method: 'get',
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'Authorization': getToken(),
        'UserId': getUserId(),
        'System': getUserSystem()
    }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(fetchAdvertsAction(response || []));
  });
}

export const onSetAdvert = (id, isAddAdvert, isDelete) => (dispatch) => {
  isDelete = isDelete || false;
  let formData = {...Advert};
  if(isDelete && id) {
    formData.ID = id;
    dispatch(setAdvertAction({advertForm: {...formData} }));
  }else if(!isAddAdvert && id) {
    dispatch(setLoadingAction(true));
    let url = '/route.php?c=pfp/contentAdvert/getById&id=' + id  + '';
    customRequest({url: baseURL + url,
      method: 'get',
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
          'Authorization': getToken(),
          'UserId': getUserId(),
          'System': getUserSystem()
      }
    }).then((response) => {
      dispatch(setLoadingAction(false));
      if(_.isArray(response)) {
        response = response[0];
      }
      if(_.isObject(response) && response.ID == id) {
        formData = {...Advert, ...response};
      }
      dispatch(setAdvertAction({advertForm: {...formData} }));
    });
  } else {
    dispatch(setAdvertAction({advertForm: {...formData} }));
  }

}

export const onSetForm = (fieldName, fieldValue) => (dispatch) => {
  const { advertForm } = store.getState().pfpAdvert;

  advertForm[fieldName] = fieldValue;

  dispatch(setFormAction({advertForm: {...advertForm}}));
}


export const onDeleteAdvert = (id) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsAdvertDeletedAction(false));
  let url = `/route.php?c=pfp/contentAdvert/delete&id=${id}`;
  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsAdvertDeletedAction(true));
  });
}

export const resetMe = () => (dispatch) => {
  dispatch(resetMeAction());
}

export default handleActions({
    [SET_LOADING] : (state, {payload}) => ({ ...state, isLoading: payload}),
    [SET_ADVERT] : (state, {payload}) => ({ ...state, advertForm: payload.advertForm }),
    [FETCH_ADVERTS] : (state, {payload}) => ({ ...state, adverts: payload }),
    [SET_IS_ADVERT_CREATED] : (state, {payload}) => ({ ...state, isAdvertCreated: payload }),
    [SET_IS_ADVERT_DELETED] : (state, {payload}) => ({ ...state, isAdvertDeleted: payload }),
    [SET_FORM] : (state, {payload}) => ({ ...state, advertForm: payload.advertForm }),
    [RESET_ME]: (state, {payload}) => ({...state, ...initialState}),
}, initialState);

export const selectors = {};

