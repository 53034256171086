import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import styled, { ThemeContext } from 'styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { formatToCurrency } from '../../../helpers/helpers';

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
`;

const ImgHolder = styled.div`
  padding: 0.3rem;
  color: #fff;
`;

const Title = styled.div``;

const StyledTitle = styled(Title)`
  display: flex;
  justify-content: center;
  &&& {
    background-color: ${props => props.theme.primary} !important;
    h3 {
      color: #fff !important;
      padding: 0.5rem;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 0;
    }
  }
`;

const CategoryPerformanceChart = ({
  data,
  id,
  title,
  axis,
  tooltip,
  drillBubbleGraph,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const theme = useContext(ThemeContext);
  const { primary } = theme;
  const chartRef = useRef(null);
  let OverviewChart;
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }

    if (chartRef.current) {
      const summaryDataValues =
        data &&
        data.map(
          ({
            AverageGrowth,
            AveragerCategory,
            BCGMatrix,
            CategoryGrowth,
            CircleSize,
            DifferenceFromMarketSharePercentage,
            DifferernceFromAverageShareChange,
            MarketShare,
            PercentageGrowth,
            Primary,
            Value,
          }) => ({
            data: [
              {
                x: DifferenceFromMarketSharePercentage,
                y: DifferernceFromAverageShareChange,
                r: CircleSize / 1,
              },
            ],
            label: Primary,
            backgroundColor: primary,
            borderColor: primary,

            dataValue: Value,
          }),
        );
      const chartData = {
        datasets: summaryDataValues,
        labels: [title],
      };
      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, {
        type: 'bubble',
        data: chartData,
        options: {
          onClick: function(evt, element) {
            if (element.length > 0) {
              const chart = element[0]._chart;
              const el = chart.getElementAtEvent(evt)[0];
              const dataset = chart.data.datasets[el._datasetIndex];
              const xLabel = chart.data.labels[el._index];
              const value = dataset.data[el._index];
              document.querySelector('#chartjs-tooltip').remove();
              drillBubbleGraph(dataset.label);
            }
          },
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },

          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,

            custom: function(tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function(body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();
              const labelColor = tooltipModel.labelColors[0].backgroundColor;
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.background = 'black';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: function(tooltipItem, data) {
                return `${
                  data.datasets[tooltipItem.datasetIndex].label
                } : ${formatToCurrency(
                  data.datasets[tooltipItem.datasetIndex].dataValue,
                  0,
                )}`;
              },
            },
            mode: 'dataset',
            backgroundColor: '#fff',
            titleFontSize: 16,
            titleFontColor: '#0066ff',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: true,
            mode: 'dataset',
            position: 'nearest',
          },

          scales: {
            yAxes: [
              {
                display: false,
                // ticks: {
                //   min: parseInt(axis.y.min),
                //   max: parseInt(axis.y.max),
                // },
              },
            ],
            xAxes: [
              {
                display: false,
                // ticks: {
                //   min: parseInt(axis.x.min),
                //   max: parseInt(axis.x.max),
                // },
              },
            ],
          },
        },
      });
      setChartInstance(myChart);
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard>
      <StyledTitle>
        <h3>{title}</h3>
        <ImgHolder>
          <Tooltip title={tooltip} position="bottom" trigger="mouseenter">
            <HelpOutlineIcon style={{ cursor: 'pointer' }} />
          </Tooltip>
        </ImgHolder>
      </StyledTitle>

      <canvas ref={chartRef} id={`myChart-${id}`} width="600" height="250" />
    </StyledCard>
  );
};

export default CategoryPerformanceChart;
