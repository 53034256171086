import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import PlotGraph from '../components/PlotGraph';
import Msg from '../components/ToastExportToPDF';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import 'react-select/dist/react-select.css';
import {
  getUserSystem,
  hasSalesInPermissions,
  hideFor,
  membersCheck,
} from '../../helpers/util';
import CustomCountSelect from '../components/CustomCountSelect';
import Select from 'react-select';
import LaunchIcon from '@material-ui/icons/Launch';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import {
  setDepartment,
  setCategory,
  setPmp,
  setSubCategory,
  setLevel4,
  setLevel5,
  fetchPerformanceData,
  resetReport,
  setMember,
  setPurchase,
  setPerformanceData,
  setSortOrder,
  setDepot,
  loadReport,
  setGroup,
  setBusinessType,
} from '../redux/reducers/priceSensitivityReport';
import { setAuthUser } from '../redux/reducers/user';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/Loading';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import ReportContainer from '../components/ReportContainer';
import { getUserData } from '../../helpers/user';

const PriceSensitivityReport = ({
  data,
  match,
  priceSensitivityGraph,
  isLoading,
  performanceData,
  onMemberChange,
  members,
  hideProducts,
  selectedMembers,
  departments,
  selectedPerformanceData,
  onPerformanceDataChange,
  filterId,
  purchases,
  purchase,
  onPurchaseChange,
  depots,
  unlockRefresh,
  onPmpChange,
  selectedDepots,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onDepotChange,
  department,
  categories,
  category,
  subCategory,
  subCategories,
  level4s,
  level4,
  level5s,
  level5,
  pmpList,
  pmp,
  onRefreshReport,
  isChartLoaded,
  history,
  onResetReport,
  selectedGroup,
  businessTypes,
  selectedBusinessType,
  onBusinessTypeChange,
  setCategoryStructure,
  setDisableCategoryStructure,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <motion.div
      key={'price-sensitivity-report-id'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--unitas`}
      />
      <div className="sub-menu-border bg-white">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
              <div className="sub-menu__left">
                <h2 className="text-uppercase">Price Sensitivity</h2>

                {!hasSalesInPermissions(match.params.subpage) && (
                  <span className="mr-1 text-black">SALES OUT</span>
                )}

                {hideFor('caterforce', 'countryRangeGroup', 'cotswold') && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="PMPList"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 pc-dropdown small-dropdown c-select-header c-select-header-row--others`}
                      name="pmp"
                      id="pmp"
                      value={pmp}
                      onChange={onPmpChange}
                      options={pmpList}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}

                {hasSalesInPermissions(match.params.subpage) && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="Sales In"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 small-dropdown c-select-header c-select-header-row--others`}
                      name="purchases"
                      id="purchases"
                      value={purchase}
                      onChange={onPurchaseChange}
                      options={purchases}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
              </div>
              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <button
                      onClick={() => {
                        onResetReport();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      Reset
                      <RotateLeftIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        const win = window.open(
                          history.location.pathname,
                          '_blank',
                        );
                        win.focus();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      New tab
                      <LaunchIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={onRefreshReport}
                      disabled={data.isRefreshDisabled}
                      className={`btn alchemy-btn--others mr-2`}>
                      Refresh <i className="fa fa-refresh" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {filterId === '1-1' && getUserSystem() !== 'countryRangeGroup' && (
            <p className="warning-txt">
              Wines and Spirits are all reported at their lowest unit of sale
            </p>
          )}
        </div>
        <div className="container sub-menu-bottom">
          <div className="row">
            <div className="col-12">
              <div className="nam-dash nam-dash-select-row">
                <div className="form-row">
                  {departments.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="department"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="department"
                        placeholder="Department"
                        value={department ? department.ID : ''}
                        onChange={onDepartmentChange}
                        options={departments}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {categories.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Category"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="category"
                        id="category"
                        value={category ? category.ID : ''}
                        onChange={onCategoryChange}
                        options={categories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {subCategories.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="subcategory"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="subcategory"
                        placeholder="SubCategory"
                        value={subCategory ? subCategory.ID : ''}
                        onChange={onSubCategoryChange}
                        options={subCategories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {level4s.length > 1 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Level4"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="level4"
                        id="level4"
                        value={level4 ? level4.ID : ''}
                        onChange={onLevel4Change}
                        options={level4s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {level5s.length > 1 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Level5"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="level5"
                        id="level5"
                        value={level5 ? level5.ID : ''}
                        onChange={onLevel5Change}
                        options={level5s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}

                  {!hideProducts && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="product"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="product"
                        value={
                          selectedPerformanceData
                            ? selectedPerformanceData.WVProdCode
                            : ''
                        }
                        onChange={onPerformanceDataChange}
                        options={performanceData}
                        placeholder="Product"
                        valueKey="WVProdCode"
                        labelKey="WVProdDesc"
                      />
                    </div>
                  )}

                  {getUserSystem() !== 'demo' &&
                    getUserSystem() !== 'cjlang' &&
                    getUserSystem() !== 'parfetts' &&
                    members &&
                    membersCheck(members) && (
                      <div className="form-group col-md-5 col-lg-3">
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onMemberChange(ev)}
                          name="member"
                          id="member"
                          isMulti={true}
                          value={selectedMembers}
                          options={
                            members && members.length > 0
                              ? members.map(mem => ({
                                  Text: mem.ActualName,
                                  ID: mem.WholesalerNo ?? mem.ActualName,
                                  isDisabled:
                                    parseInt(mem.WholesalerNo) === 9999,
                                }))
                              : []
                          }
                          type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                          types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                          placeholder="Member"
                        />
                      </div>
                    )}

                  {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                  {getUserSystem() == 'demo' &&
                    selectedGroup &&
                    selectedGroup.length > 0 &&
                    getUserSystem() !== 'parfetts' && (
                      <div className="form-group col-md-5 col-lg-3">
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onMemberChange(ev)}
                          name="member"
                          id="member"
                          isMulti={true}
                          value={selectedMembers}
                          options={
                            members && members.length > 0
                              ? members.map(mem => ({
                                  Text: mem.ActualName,
                                  ID: mem.WholesalerNo ?? mem.ActualName,
                                }))
                              : []
                          }
                          type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                          types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                          placeholder="Member"
                        />
                      </div>
                    )}

                  {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
                    getUserSystem() === 'unitas') &&
                  depots &&
                  depots.length > 0 ? (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="depot"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="depot"
                        multi
                        placeholder="Select Depot"
                        value={selectedDepots}
                        onChange={onDepotChange}
                        options={depots}
                        valueKey="DepotName"
                        labelKey="DepotName"
                      />
                    </div>
                  ) : null}
                  {businessTypes && businessTypes.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="businessType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="businessType"
                        placeholder="Business Type"
                        value={selectedBusinessType}
                        onChange={onBusinessTypeChange}
                        options={businessTypes}
                        valueKey="BusinessName"
                        labelKey="BusinessName"
                      />
                    </div>
                  )}
                  <BusinessTypes unlockRefresh={unlockRefresh} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportContainer>
        <div className="container pt-3">
          <div className="row">
            <div className="col-12 col-md-12">
              <div>
                <div>
                  {isLoading && <Loading />}
                  {isChartLoaded && priceSensitivityGraph.tick && (
                    <PlotGraph
                      data={priceSensitivityGraph}
                      className="c-price-sensitivity-plot"
                    />
                  )}
                </div>

                {isChartLoaded && !priceSensitivityGraph.tick && (
                  <p>No data was found for the selected params.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReportContainer>
    </motion.div>
  );
};
function mapStateToProps(state) {
  const data = {
    performanceData: state.priceSensitivityReport.performanceData,
    selectedPerformanceData:
      state.priceSensitivityReport.selectedPerformanceData,
    sortBySales: state.priceSensitivityReport.sortBySales,
    priceSensitivityGraph: state.priceSensitivityReport.priceSensitivityGraph,
    members: state.priceSensitivityReport.members,
    hideProducts: state.priceSensitivityReport.hideProducts,
    departments: state.priceSensitivityReport.departments,
    categories: state.priceSensitivityReport.categories,
    purchases: state.priceSensitivityReport.purchases,
    purchase: state.priceSensitivityReport.purchase,
    selectedMembers: state.priceSensitivityReport.selectedMembers,
    filterId: state.priceSensitivityReport.filterId,
    filterValue: state.priceSensitivityReport.filterValue,
    filter: state.priceSensitivityReport.filter,
    isExported: state.priceSensitivityReport.isExported,
    pdfURL: state.priceSensitivityReport.pdfURL,
    isExporting: state.priceSensitivityReport.isExporting,
    isLoading: state.priceSensitivityReport.isLoading,
    depots: state.priceSensitivityReport.depots,
    pmpList: state.priceSensitivityReport.pmpList,
    pmp: state.priceSensitivityReport.pmp,
    selectedDepots: state.priceSensitivityReport.selectedDepots,
    categories: state.priceSensitivityReport.categories,
    subCategories: state.priceSensitivityReport.subCategories,
    department: state.priceSensitivityReport.department,
    category: state.priceSensitivityReport.category,
    subCategory: state.priceSensitivityReport.subCategory,
    level4s: state.priceSensitivityReport.level4s,
    level4: state.priceSensitivityReport.level4,
    level5s: state.priceSensitivityReport.level5s,
    level5: state.priceSensitivityReport.level5,
    authUser: state.user.authUser,
    isChartLoaded: state.priceSensitivityReport.isChartLoaded,
    groups: state.priceSensitivityReport.groups,
    selectedGroup: state.priceSensitivityReport.selectedGroup,
    businessTypes: state.priceSensitivityReport.businessTypes,
    selectedBusinessType: state.priceSensitivityReport.selectedBusinessType,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchPerformanceData,
    setPerformanceData,
    setMember,
    setPurchase,
    resetReport,
    setSortOrder,
    setPmp,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setLevel5,
    setDepot,
    setAuthUser,
    loadReport,
    setGroup,
    setBusinessType,
    setCategoryStructure,
    setDisableCategoryStructure,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
      this.props.resetReport();
      this.props.fetchPerformanceData();
      if (this.props.authUser.userId.length === 0) {
        this.props.setAuthUser();
      }
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.fetchPerformanceData();
      }
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report. Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'Price Sensitivity',
            });
          }, 1000);
        }, 1000);
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting Report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} />, { autoClose: false });
      }
      this.props.history.listen((location, action) => {
        if (
          this.props.selectedPerformanceData &&
          this.props.selectedPerformanceData.WVProdCode !== undefined &&
          nextProps.match.params.subpage !== this.props.match.params.subpage
        ) {
          this.props.resetReport();
          this.props.fetchPerformanceData();
        }
      });
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDepartment(0);
        } else {
          setDepartment(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0);
        } else {
          setCategory(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0);
        } else {
          setSubCategory(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0);
        } else {
          setLevel5(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0);
        } else {
          setLevel4(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onBusinessTypeChange:
      ({ setBusinessType, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBusinessType('');
        } else {
          setBusinessType(selectedOption.BusinessName);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, setCategoryStructure, setDisableCategoryStructure  }) =>
      opt => {
        // Changed from "members.length > 2 to work for demo account "
        if (members.length > 0) {
          setMember(opt);
          handleData({ ...data, isRefreshDisabled: false });
        }
        if (getUserSystem() == "caterforce") {
          if (opt?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }
      },
    unlockRefresh:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: false });
    },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSetSortOrder:
      ({ data, handleData, setSortOrder }) =>
      event => {
        setSortOrder();
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPerformanceDataChange:
      ({ data, handleData, setPerformanceData }) =>
      selectedOption => {
        setPerformanceData(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      selectedOption => {
        setDepot(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onResetReport: props => () => {
      props.resetReport();
      props.fetchPerformanceData();
      if (props.authUser.userId.length === 0) {
        props.setAuthUser();
      }
    },
  }),
)(PriceSensitivityReport);
