import React, { useState, useEffect } from 'react';
import { history } from '../redux/store';
import { isAuthenticated } from '../../helpers/user';

export const useDashboardIfAuthed = () => {
  const [isUserAuthed] = useState(isAuthenticated());

  useEffect(() => {
    if (isUserAuthed) {
      console.log('User already logged in. Redirecting.');

      // return history.push({
      //   pathname: '/dashboard',
      // });
    }
  }, [isUserAuthed]);
}
