import React from 'react';

import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import pageStandard from '../components/wrappers/pageStandard';

import { API_ENDPOINTS } from '../../AlchemySettings';

import AlchSelect from '../components/AlchUI/AlchSelect2';
import AlchContextMenu from '../components/AlchUI/AlchContextMenu';
import TableSimple from '../components/TableSimple';
import StackedGraph from '../components/StackedGraph';

import { parseCustomReportBase, parseCustomReportData } from '../../helpers/apiMiddleware';

// Alias destruct due to name conflict
const { customReportGraph: customReportBase, customReportDataGraph } = API_ENDPOINTS;
class CustomReportGraph extends React.Component {
  state = {
    viewBase: {
      custom: {
        options: Array.prototype,
      },
    },
    viewData: {
      title: 'Custom report',
      table: {},
    },
    viewOptions: {
      /* @TODO: Come up with some way to get this dynamically */
      c: 'customReporting/getChartData',
      qid: 'A3C34885-9088-12B2-652A-73B453432DD5',
    },
  };

  setPageConfig = () => {
    const baseURL = {
      root: customReportBase,
    };
    const dataURL = {
      root: customReportDataGraph,
    };
    const middleware = {
      base: { root: parseCustomReportBase },
      data: {
        root: parseCustomReportData,
        requests: undefined,
      },
    };

    return {
      baseURL,
      dataURL,
      middleware,
    };
  };

  componentDidMount() {
    this.setDefaults();
  }

  refreshPage() {
    this.setDefaults();
  }

  setDefaults() {
    this.setViewOption('customReporting/getChartData', 'c');
    this.setViewOption('A3C34885-9088-12B2-652A-73B453432DD5', 'qid');
  }

  renderSelects() {
    const options = this.state.viewBase.custom.options;

    return options.map((option) => {
      return (
        <div className="u-1_3" key={option.selectId}>
          <AlchSelect id={option.selectId} label={option.label} labelClassName="u-space-v" multiple={option.multiSelect} {...this.setControlledComponent(option.selectId)}>
            {option.options}
          </AlchSelect>
        </div>
      );
    });
  }

  render() {
    return (
      <motion.div key={'CUSTOMERREPORTID123415151'} initial="initial" animate="in" exit="out" variants={pageVariants}>
        <div className="o-canvas-wrap">
          <div className="o-canvas">
            <div className="o-canvas__header u-layout u-layout--between">
              <div className="o-canvas__header-item u-layout u-layout--between-base">
                <h2 className="o-canvas__title">{this.state.viewData.title}</h2>
              </div>
              <div className="o-canvas__header-item">
                <AlchContextMenu label="Export" onSelect={this.exportData}>
                  {[
                    { id: 'csv', label: 'CSV' },
                    { id: 'pdf', label: 'PDF' },
                  ]}
                </AlchContextMenu>
              </div>
            </div>
            <div className="o-canvas__body u-layout u-layout--spaced t-brand-a">
              <div className="u-layout u-layout--spaced u-1_1">{this.renderSelects()}</div>
              <div className="u-1_1">
                <StackedGraph data={this.state.viewData.graph} />
              </div>
              <div className="u-1_1 u-space--l">
                <TableSimple data={this.state.viewData.table} className="c-objects-wrap--sticky" tableClassName="c-table c-table--standard" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default pageStandard(CustomReportGraph);
