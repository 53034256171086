import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import * as _ from 'lodash';
import { fetchCampaigns, deleteCampaignByID, getReportByCampaign, archiveCampaignByID } from '../../redux/reducers/psa';

const tableClass = ['o-table'];

const campaignsList = ({ data, campaigns, onViewCampaign, onEditCampaign, onDeleteCampaign, onAddNewCampaign, onArchiveCampaign, onGetReportByCampaign, isLoading }) => (
  <div className="o-canvas-wrap">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout">
          <h2 className="o-canvas__title u-100">Manage Campaigns</h2>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
          <table className={tableClass.join(' ')}>
            <thead>
              <tr>
                <th>
                  <b>Campaign Name</b>
                </th>
                <th>
                  <b>Activities</b>
                </th>
                <th>
                  <b>Actions</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {campaigns &&
                _.orderBy(campaigns, 'archived', ['desc']).map((campaign, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="align-center">{campaign.name}</td>
                      <td className="align-center">
                        {campaign.activities.map((act, idx) => {
                          if (idx < campaign.activities.length - 1) {
                            return `${act.name}, `;
                          } else {
                            return act.name;
                          }
                        })}
                      </td>
                      <td className="align-center">
                        <button id="btnView" className="btn-table mr-10 btn-psa" onClick={() => onViewCampaign(campaign.id)}>
                          View Results
                        </button>
                        <button id="btnEdit" onClick={() => onEditCampaign(campaign.id)} className="btn-table mr-10 btn-psa">
                          Edit
                        </button>
                        <button id="btnDelete" onClick={() => onDeleteCampaign(campaign.id)} className="btn-table mr-10 btn-psa">
                          Delete
                        </button>
                        <button id="btnViewReport" className="btn-table mr-10 btn-psa" onClick={() => onGetReportByCampaign(campaign.id)}>
                          View Report
                        </button>
                        <div className="c-toggle-menu btn-table" style={{ display: 'inline' }} data-name="purchase">
                          {data.archieves.map((arc, key) => {
                            return (
                              <button key={key} id={arc.value.toString()} className={campaign.archived === arc.value ? 'c-toggle-menu__item_new is-active' : 'c-toggle-menu__item_new'} onClick={() => onArchiveCampaign(campaign.id, arc.value ? 0 : 1)}>
                                {arc.label}
                              </button>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button className="u-right mt-10 btn-psa" onClick={() => onAddNewCampaign()}>
            Add New Campaign
          </button>
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = { campaigns: state.psa.campaigns, isCampaignUpdated: state.psa.isCampaignUpdated, isCampaignDeleted: state.psa.isCampaignDeleted, isLoading: state.psa.isLoading };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCampaigns,
    getReportByCampaign,
    archiveCampaignByID,
    deleteCampaignByID,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchCampaigns();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isCampaignUpdated !== this.props.isCampaignUpdated && this.props.isCampaignUpdated) {
        toast.dismiss();
        toast.info('Campaign updated successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.fetchCampaigns();
      }
      if (prevProps.isCampaignDeleted !== this.props.isCampaignDeleted && this.props.isCampaignDeleted) {
        toast.dismiss();
        toast.info('Campaign deleted successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.fetchCampaigns();
      }
    },
  }),
  withState('data', 'handleData', {
    archieves: [
      { value: true, label: 'Open' },
      { value: false, label: 'Closed' },
    ],
  }),
  withHandlers({
    onGetReportByCampaign: ({ data, handleData, getReportByCampaign }) => id => {
      getReportByCampaign(id);
    },
    onViewCampaign: ({ data, handleData, history }) => id => {
      history.push(`${process.env.APP_ROOT}psa/view-campaigns/${id}`);
    },
    onEditCampaign: ({ data, handleData, history }) => id => {
      history.push(`${process.env.APP_ROOT}psa/campaign/${id}`);
    },
    onAddNewCampaign: ({ data, handleData, history }) => event => {
      history.push(`${process.env.APP_ROOT}psa/campaign`);
    },
    onDeleteCampaign: ({ data, handleData, deleteCampaignByID }) => id => {
      deleteCampaignByID(id);
    },
    onArchiveCampaign: ({ data, handleData, archiveCampaignByID }) => (id, active) => {
      archiveCampaignByID(id, active);
    },
  }),
)(campaignsList);
