import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const ImgHolder = styled.div`
  margin-left: 1rem;
  color: ${({ theme: { secondary } }) => secondary};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Explainer = ({
  explainerText,
  explanationTooltip,
  warningTooltip,
  openModal,
  className,
}) => {
  return (
    <Container>
      <h2 className={`text-uppercase ${className ? className : ''}`}>
        {explainerText}
      </h2>
      {explanationTooltip && (
        <ImgHolder>
          <Tooltip
            title={explanationTooltip}
            position="bottom"
            trigger="mouseenter"
            theme="light">
            <HelpOutlineIcon style={{ cursor: 'pointer' }} />
          </Tooltip>
        </ImgHolder>
      )}{' '}
      {warningTooltip && (
        <ImgHolder>
          <Tooltip
            title={warningTooltip}
            position="bottom"
            trigger="mouseenter"
            theme="light">
            <ErrorOutlineIcon style={{ cursor: 'pointer' }} />
          </Tooltip>
        </ImgHolder>
      )}
      {openModal && (
        <ImgHolder>
          <HelpOutlineIcon
            onClick={() => openModal(true)}
            style={{ cursor: 'pointer' }}
          />
        </ImgHolder>
      )}
    </Container>
  );
};

export default Explainer;
