import React from 'react';
import Leaflet from 'leaflet';
import PropTypes from 'prop-types';
import ScotlandData from '../../components/component_data/scotland.json';
import * as topojson from 'topojson';

class RetailMap extends React.Component {
  state = { lat: 56.8384472805354, lon: -4.052788366746179 };
  componentDidMount() {
    this.initializeMap();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.updateMapData(nextProps.data);
    }
    if (this.props.regionData !== nextProps.regionData) {
      this.updateRegionData(nextProps.regionData);
    }
  }

  initializeMap = () => {
    if (this.Map) {
      this.Map.remove();
    }

    window.L_PREFER_CANVAS = true;
    this.Map = Leaflet.map('map', '', { zoomControl: false }).setView(
      [this.state.lat, this.state.lon],
      6.25,
    );
    this.Map.touchZoom.disable();
    this.Map.doubleClickZoom.disable();
    this.Map.scrollWheelZoom.disable();
    this.Map.keyboard.disable();
    this.layerGroup = Leaflet.layerGroup().addTo(this.Map);
    this.markersGroup = Leaflet.layerGroup().addTo(this.Map);
    this.drawLayers();
  };

  drawLayers = () => {
    // ScotlandData.geometry.id = 'N06000013';
    $.each(ScotlandData.objects.eer.geometries, (i, item) => {
      const eer = topojson.feature(ScotlandData, item);
      const layer = Leaflet.geoJson(null, {
        details: {
          id: item.id,
          region: item.properties.EER13NM,
        },
        style: {
          fillColor: '#000',
          color: '#2a3439',
          weight: 1,
          opacity: 1,
        },
      });
      layer.addTo(this.layerGroup);
      layer.addData(eer);
      this.geojson = layer;
      layer.on('click', e => {
        this.regionClick(e, layer);
      });
    });
  };

  updateMapData = layersData => {
    // this.drawLayers();
    // loop through each layer and style
    if (Object.keys(layersData).length < 1) {
      this.initializeMap();
      return;
    }

    this.layerGroup.eachLayer(layer => {
      layer.setStyle({});
      /* Check if we have data for layer if not theres nothing to do */
      const layerID = layer.options.details.id;
      if (!layersData[layerID]) {
        layer.setStyle({});
        return;
      }
      // set the style based on the css details in the json.
      layer.setStyle(layersData[layerID].css);
    });
  };

  updateRegionData = regionData => {
    const dotIcon = Leaflet.divIcon({ className: 'c-dot-icon' });
    /* Clear layers */
    this.markersGroup.clearLayers();

    // if ()

    regionData.forEach(item => {
      let popupContent = '';
      popupContent += `
				<div class="pop-header">
					<h2>${item.DisplayName}</h2>
					<div class="depot-share">Distribution Share: ${Math.round(
            item.DepoptShare * 100,
            2,
          )}%</div>
				</div>
				<div class="pop-up ${item.Class}">
					<p>${item.DisplayBlob}</p>
                                        <p>Customer Count: ${
                                          item.CustomerCount
                                        }</p>
                                        <p>Product Count: ${
                                          item.ProductCount
                                        }</p>
				</div>`;

      const marker = Leaflet.marker([item.Lat, item.Long], {
        icon: dotIcon,
      }).addTo(this.markersGroup);
      marker.bindPopup(popupContent);
    });
  };

  regionClick = (e, layer) => {
    const region = e.layer;
    this.props.onRegionClick(region, this.Map, layer);
    if (region.options.className) {
      this.props.onDataRegionClick(region, this.Map, layer);
    }
  };

  render() {
    return <div id="map" className="c-objects-wrap c-objects-wrap--leaflet" />;
  }
}

RetailMap.defaultProps = {
  data: Object.prototype,
  regionData: Array.prototype,
  onRegionClick: Function.prototype,
  onDataRegionClick: Function.prototype,
};
/* eslint react/no-unused-prop-types:0 */
RetailMap.propTypes = {
  data: PropTypes.object,
  regionData: PropTypes.array,
  onRegionClick: PropTypes.func,
  onDataRegionClick: PropTypes.func,
};

export default RetailMap;
