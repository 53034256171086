import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import BottomNav from '../../components/navigations/BottomNav';
import { getSubmenuPages } from '../../../helpers/api';

import ContactUsPage from '../ContactUsPage';

export default class ContactUs extends React.Component {
  state = {
    subpage_nav: [],
  };

  render() {
    return (
      <div>
        <Switch>
          <Route path={`${process.env.APP_ROOT}contact-us`} render={(pageProps) => <ContactUsPage {...this.props} {...pageProps} />} />
        </Switch>
        <BottomNav links={this.state.subpage_nav} currentUrl={this.props.location.pathname} brand="a" />
      </div>
    );
  }
}

ContactUs.defaultProps = {
  location: {},
};

ContactUs.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
