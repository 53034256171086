import React from 'react';
import PropTypes from 'prop-types';
import 'react-tippy/dist/tippy.css';

class TableQueryBuilderStep4 extends React.Component {
  renderHeader = (header, i) => {
    return (
      <th className="u-tac u-grey-bg" style={{ backgroundColor: '#e0e9ec' }} key={i}>
        {header}
      </th>
    );
  };

  renderRow = (row, i) => {
    return (
      <tr key={i}>
        {Object.keys(row).map((column, j) => {
          return (
            <td className="u-text-center" style={{ borderRight: '1px solid #e0e9ec' }} key={j}>
              {row[column]}
            </td>
          );
        })}
      </tr>
    );
  };

  render() {
    if (!this.props.data) return null;
    return (
      <div className={`c-objects-wrap c-objects-wrap--table ${this.props.className}`} style={{ margin: '10px' }}>
        <table className={this.props.tableClassName} id={this.props.id} style={{ width: '100%' }}>
          <thead>
            <tr>{this.props.headers && this.props.headers.map(this.renderHeader)}</tr>
          </thead>
          <tbody>{this.props.data && this.props.data.map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  }
}

TableQueryBuilderStep4.defaultProps = {
  data: [],
  headers: [],
  className: '',
  tableClassName: '',
};

TableQueryBuilderStep4.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  id: PropTypes.string,
  className: PropTypes.string,
  tableClassName: PropTypes.string,
};

export default TableQueryBuilderStep4;
