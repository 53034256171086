import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Select from 'react-select';
import Msg from '../components/ToastExportToPDF';
import 'react-select/dist/react-select.css';
import ExportIcon from '../../assets/images/export-icon.svg';
import CustomCountSelect from '../components/CustomCountSelect';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LaunchIcon from '@material-ui/icons/Launch';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import {
  fetchDefaultData,
  setTitle,
  setDepartment,
  setCategory,
  setSubCategory,
  setLevel4,
  setLevel5,
  setBrand,
  selectAllCustomerTypes,
  deselectAllCustomerTypes,
  bnbMemberChanged,
  bnbProductChanged,
  bnbBrandChanged,
  bnbCategoryChanged,
  exportToPDF,
  setPmp,
  customerTypeChanged,
  setDepot,
  setBusinessType,
  setCompanyType,
  loadReport,
  reset,
  setGroup,
  setTimeFrame,
} from '../redux/reducers/reportBuilder';
import {
  calcPercentage,
  exportTableToCSV,
  formatNumber,
  getRandomNumber,
} from '../../helpers/helpers';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import TableSimple from '../components/TableSimple';
import GraphPerformance from '../components/GraphPerformance';
import { ToastContainer, toast } from 'react-toastify';
import { getUserSystem, hideFor, showFor } from '../../helpers/util';
import Loading from '../components/Loading';
import DateLabel from '../components/DateLabel';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import ReportContainer from '../components/ReportContainer';
import { getUserData } from '../../helpers/user';

const ReportBuilder = ({
  data,
  categoryStructure,
  title,
  bnbTableData,
  bnbGraphData,
  bnbTotal,
  unlockRefresh,
  selectedBnBMembers,
  selectedBnBProduct,
  selectedBnBBrand,
  selectedBnBCategory,
  bnbCategoryHierarchy,
  bnbProducts,
  bnbBrands,
  bnbCategories,
  filterId,
  filterValue,
  hideProducts,
  bnbMembers,
  customerTypes,
  departments,
  department,
  categories,
  category,
  subCategories,
  subCategory,
  level4s,
  level4,
  level5s,
  level5,
  brands,
  brand,
  pmpList,
  pmp,
  onPmpChange,
  onDepartmentChange,
  onCategoryChange,
  onSubCategoryChange,
  onLevel4Change,
  onLevel5Change,
  onBrandChange,
  onBnBProductChanged,
  onBnBBrandChanged,
  onBnBMemberChanged,
  onBnBCategoryChanged,
  selectedCustomerTypes,
  onCustomerTypeChanged,
  onExportData,
  onSelectAllCustomerTypes,
  onDeselectAllCustomerTypes,
  isLoading,
  depots,
  selectedDepots,
  onDepotChange,
  bnbBusinessTypes,
  selectedBusinessType,
  onBusinessTypeChange,
  resolveBusinessTypes,
  toggleExportMenu,
  reportError,
  onRefreshReport,
  history,
  onResetReport,
  companyTypes,
  selectedCompanyType,
  onCompanyTypeChange,
  onGroupChange,
  groups,
  selectedGroup,
  timeframes,
  selectedTimeFrame,
  onTimeFrameChange,
  alt_departments,
  alt_department,
  alt_categories,
  alt_category,
  alt_subCategories,
  alt_subCategory,
  alt_level4s,
  alt_level4,
  alt_level5s,
  alt_level5,
  alt_brands,
  alt_brand,
  alt_products,
  alt_product,
  onAltDepartmentChange,
  onAltCategoryChange,
  onAltSubCategoryChange,
  onAltLevel4Change,
  onAltLevel5Change,
  onAltBrandChange,
  onAltProductChange,
  alt_productsIsLoading,
  productsIsLoading,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <motion.div
      key={'report-builder-id'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--others`}
      />
      <div className="sub-menu-border bg-white">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between">
              <div className="sub-menu__left">
                <h2 className="text-uppercase">{title}</h2>
                <div className="d-flex">
                  {getUserSystem() !== 'countryRangeGroup' && (
                    <DateLabel weeks={13} />
                  )}
                  {hideFor('caterforce', 'countryRangeGroup', 'cotswold') && (
                      <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                        <Select
                          placeholder="PMPList"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          className={`wd-100 pc-dropdown small-dropdown c-select-header c-select-header-row--others`}
                          name="pmp"
                          id="pmp"
                          value={pmp}
                          onChange={onPmpChange}
                          options={pmpList}
                          valueKey="ID"
                          labelKey="Text"
                          clearable={false}
                          searchable={false}
                        />
                      </div>
                    )}
                  {getUserSystem() === 'countryRangeGroup' && (
                    <>
                      <div className="btn-group text-uppercase">
                        <span className="dropdown-align-fix">For Latest:</span>
                      </div>
                      <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                        <Select
                          placeholder="Time"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          className={`wd-130 small-dropdown c-select-header c-select-header-row--others`}
                          name="timeframe"
                          id="timeframe"
                          value={selectedTimeFrame}
                          onChange={onTimeFrameChange}
                          options={timeframes}
                          valueKey="ID"
                          labelKey="Text"
                          clearable={false}
                          searchable={false}
                          isMulti={false}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <button
                      onClick={() => {
                        onResetReport();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      Reset
                      <RotateLeftIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        const win = window.open(
                          history.location.pathname,
                          '_blank',
                        );
                        win.focus();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      New tab
                      <LaunchIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <Dropdown
                      isOpen={data.isExportMenuOpen}
                      toggle={() => toggleExportMenu()}>
                      <DropdownToggle
                        className={`dropddown-toggle-btn alchemy-btn--others mr-2`}>
                        Export <img src={ExportIcon} alt="export" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('csv');
                          }}>
                          CSV
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('pdf');
                          }}>
                          PDF
                        </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div>
                    <button
                      onClick={onRefreshReport}
                      disabled={data.isRefreshDisabled}
                      className={`btn alchemy-btn--others mr-2`}>
                      Refresh <i className="fa fa-refresh" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportContainer>
        <div className="container pt-3">
          <div className="row">
            <div className="col-12 col-lg-7 sub-menu-bottom">
              <div className="analysis analysis-select-row">
                {theme.name !== 'cjlang' && (
                  <div className="form-row">
                    <div className="form-group col-md-5 pr-2">
                      <p>{bnbMembers.title}</p>
                    </div>
                    <div className="form-group col-md-7">
                      {/* Group dropdown for demo system, used to filter members on demo system only */}

                      {/* {getUserSystem() === 'demo' ||
                        getUserSystem() === 'caterforce' ||
                        (getUserSystem() === 'countryRangeGroup' &&
                          departments &&
                          departments.length > 0 &&
                          groups &&
                          groups.length > 0 && (
                            <CustomCountSelect
                              className={`Select c-select-header c-select-header-row--others`}
                              onChangeCallback={ev => onGroupChange(ev)}
                              name="group"
                              id="group"
                              isMulti={true}
                              value={selectedGroup}
                              options={groups}
                              type={getUserSystem() === 'caterforce' ? `Core Supplier` : `Group`}
                              types={getUserSystem() === 'caterforce' ? `Core Suppliers` : `Groups`}
                              placeholder={getUserSystem() === 'caterforce' ? `Core Supplier` : `Group`}
                            />
                          ))} */}

                      {/* Member dropdown for standard systems, hidden for demo system */}

                      {getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ? (
                        <Select
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          className={`Select c-select-header c-select-header-row--others`}
                          name="depot"
                          id="depot"
                          multi
                          value={selectedDepots}
                          onChange={onDepotChange}
                          options={depots}
                          valueKey="DepotName"
                          labelKey="DepotName"
                        />
                      ) : hideFor('demo', 'deebee') ? (
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onBnBMemberChanged(ev)}
                          name="bnbMember"
                          id="bnbMember"
                          isMulti={true}
                          value={selectedBnBMembers}
                          options={
                            bnbMembers.options && bnbMembers.options.length > 0
                              ? bnbMembers.options
                                  .map(mem => ({
                                    Text: mem.ActualName,
                                    ID: mem.WholesalerNo ?? mem.ActualName,
                                  }))
                                  .filter(
                                    mem =>
                                      !mem.Text.toLowerCase().includes(
                                        'others',
                                      ),
                                  )
                              : []
                          }
                          placeholder="Member"
                          type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                          types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                          disableAll={true}
                        />
                      ) : (
                        ''
                      )}

                      {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                      {showFor('demo', 'deebee') && (
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onBnBMemberChanged(ev)}
                          name="member"
                          id="member"
                          isMulti={true}
                          value={selectedBnBMembers}
                          options={
                            bnbMembers.options && bnbMembers.options.length > 0
                              ? bnbMembers.options
                                  .map(mem => ({
                                    Text: mem.ActualName,
                                    ID: mem.WholesalerNo ?? mem.ActualName,
                                  }))
                                  .filter(
                                    mem =>
                                      !mem.Text.toLowerCase().includes(
                                        'others',
                                      ),
                                  )
                              : []
                          }
                          type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                          types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                          placeholder="Member"
                        />
                      )}
                    <BusinessTypes inGroup={true} unlockRefresh={unlockRefresh} />
                    </div>
                  </div>
                )}
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <p>
                      {theme.name == 'cjlang'
                        ? 'Who have bought'
                        : theme.name === 'countryRangeGroup'
                        ? 'And have bought in the selected timeframe'
                        : bnbCategoryHierarchy.title}
                    </p>
                    <p>Criteria 1</p>
                  </div>
                  <div className="form-group col-md-7">
                    <div className="mb-2">
                      {departments.length > 0 && (
                        <Select
                          className={`Select c-select-header  c-select-header-row--others`}
                          name="department"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          id="department"
                          placeholder="Department"
                          value={department ? department.ID : ''}
                          onChange={onDepartmentChange}
                          options={departments}
                          valueKey="ID"
                          labelKey="Text"
                        />
                      )}
                      {categories.length > 0 && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="category"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="category"
                            placeholder="Category"
                            value={category ? category.ID : ''}
                            onChange={onCategoryChange}
                            options={categories}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {subCategories.length > 0 && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="subcategory"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="subcategory"
                            placeholder="Sub Category"
                            value={subCategory ? subCategory.ID : ''}
                            onChange={onSubCategoryChange}
                            options={subCategories}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {level4s.length > 1 && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="level4"
                            id="level4"
                            placeholder="Level 4"
                            value={level4 ? level4.ID : ''}
                            onChange={onLevel4Change}
                            options={level4s}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {level5s.length > 1 && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="level5"
                            id="level5"
                            placeholder="Level 5"
                            value={level5 ? level5.ID : ''}
                            onChange={onLevel5Change}
                            options={level5s}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {department && department.Text && !categoryStructure && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="brand"
                            id="brand"
                            placeholder="Brand"
                            value={brand ? brand.Brand : ''}
                            onChange={onBrandChange}
                            options={brands}
                            valueKey="Brand"
                            labelKey="Brand"
                            disabled={brands.length === 0}
                            isLoading={brands.length === 0}
                          />
                        </div>
                      )}
                      {department && department.Text && (
                        <div>
                          <label htmlFor="retailclub"> </label>
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="bnbProduct"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="bnbProduct"
                            placeholder="Product"
                            value={
                              selectedBnBProduct
                                ? selectedBnBProduct.WVProdCode
                                : ''
                            }
                            onChange={onBnBProductChanged}
                            options={bnbProducts.options || []}
                            disabled={productsIsLoading}
                            isLoading={productsIsLoading}
                            valueKey="WVProdCode"
                            labelKey="WVProdDesc"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {
                  <div className="form-row">
                    <div className="form-group col-md-5 pr-2">
                      <p>Who haven't bought</p>
                      <p>Criteria 2</p>
                    </div>
                    <div className="form-group col-md-7">
                      {alt_departments.length > 0 && (
                        <div className="mb-4">
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="department"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="department"
                            placeholder="Department"
                            value={alt_department ? alt_department.ID : ''}
                            onChange={onAltDepartmentChange}
                            options={alt_departments}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {alt_categories.length > 0 && (
                        <div className="mb-4">
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="category"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="category"
                            placeholder="Category"
                            value={alt_category ? alt_category.ID : ''}
                            onChange={onAltCategoryChange}
                            options={alt_categories}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {alt_subCategories.length > 0 && (
                        <div className="mb-4">
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="subcategory"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            id="subcategory"
                            placeholder="Sub Category"
                            value={alt_subCategory ? alt_subCategory.ID : ''}
                            onChange={onAltSubCategoryChange}
                            options={alt_subCategories}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {alt_level4s.length > 1 && (
                        <div className="mb-4">
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="level4"
                            id="level4"
                            placeholder="Level 4"
                            value={alt_level4 ? alt_level4.ID : ''}
                            onChange={onAltLevel4Change}
                            options={alt_level4s}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {alt_level5s.length > 1 && (
                        <div className="mb-4">
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="level5"
                            id="level5"
                            placeholder="Level 5"
                            value={alt_level5 ? alt_level5.ID : ''}
                            onChange={onAltLevel5Change}
                            options={alt_level5s}
                            valueKey="ID"
                            labelKey="Text"
                          />
                        </div>
                      )}
                      {alt_department && alt_department.Text &&  !categoryStructure && (
                        <div className="mb-4">
                          <Select
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            disabled={alt_brands.length === 0}
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="brand"
                            id="brand"
                            placeholder="Brand"
                            value={alt_brand ? alt_brand.Brand : ''}
                            onChange={onAltBrandChange}
                            options={alt_brands}
                            valueKey="Brand"
                            labelKey="Brand"
                            isLoading={alt_brands.length === 0}
                          />
                        </div>
                      )}
                      {alt_department && alt_department.Text && (
                        <div className="mb-4">
                          <Select
                            className={`Select c-select-header  c-select-header-row--others`}
                            name="alt_product"
                            inputProps={{
                              autoComplete: 'off',
                              autoCorrect: 'off',
                              spellCheck: 'off',
                            }}
                            disabled={alt_productsIsLoading}
                            isLoading={alt_productsIsLoading}
                            id="alt_product"
                            placeholder="Product"
                            value={alt_product ? alt_product.WVProdCode : ''}
                            onChange={onAltProductChange}
                            options={alt_products}
                            valueKey="WVProdCode"
                            labelKey="WVProdDesc"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                }
                {bnbBusinessTypes.options.length > 0 && (
                  <div className="form-row">
                    <div className="form-group col-md-5">
                      <p>{bnbBusinessTypes.title}</p>
                    </div>
                    <div className="form-group col-md-7">
                      <CustomCountSelect
                        className={`c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onBusinessTypeChange(ev)}
                        name="businessType"
                        id="businessType"
                        isMulti={true}
                        placeholder="Business Type"
                        value={selectedBusinessType}
                        options={resolveBusinessTypes(bnbBusinessTypes.options)}
                        type="Business Type"
                        types="Business Types"
                      />
                    </div>
                  </div>
                )}

                {showFor('cjlang') &&
                  companyTypes &&
                  companyTypes.options.length > 1 && (
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <p>{companyTypes.title}</p>
                      </div>
                      <div className="form-group col-md-7">
                        <Select
                          className={`Select c-select-header  c-select-header-row--others`}
                          name="companyType"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          id="companyType"
                          value={selectedCompanyType}
                          onChange={onCompanyTypeChange}
                          options={companyTypes.options}
                          valueKey="ID"
                          labelKey="Text"
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-12 col-lg-5 mt-3">
              {isLoading && <Loading />}
              {reportError.length > 0 && <p>{reportError}</p>}
              {reportError.length === 0 && !bnbGraphData.error && (
                <div className="graph-reset-right">
                  <GraphPerformance
                    data={bnbGraphData}
                    section="Analysis"
                    formatter={fr => {
                      return `${fr} (${calcPercentage(fr, bnbTotal)})`;
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              {reportError.length === 0 &&
                bnbTableData.rows &&
                bnbTableData.rows.length && (
                  <TableSimple
                    data={bnbTableData}
                    tableClassName="c-table c-table--standard export-table"
                    isLoading={isLoading}
                    parseEachCell={(text, row, cell) => {
                      if (row > -1 && cell === 1) {
                        return formatNumber(text, '£');
                      }
                      return text;
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </ReportContainer>
    </motion.div>
  );
};
function mapStateToProps(state) {
  const data = {
    title: state.reportBuilder.title,
    bnbConditionCustomers: state.reportBuilder.bnbConditionCustomers,
    bnbCategoryHierarchy: state.reportBuilder.bnbCategoryHierarchy,
    bnbProducts: state.reportBuilder.bnbProducts,
    bnbMembers: state.reportBuilder.bnbMembers,
    categoryStructure: state.app.categoryStructure,
    hideProducts: state.reportBuilder.hideProducts,
    filterId: state.reportBuilder.filterId,
    filter: state.reportBuilder.filter,
    filterValue: state.reportBuilder.filterValue,
    customerTypes: state.reportBuilder.customerTypes,
    selectedBnBMembers: state.reportBuilder.selectedBnBMembers,
    selectedCustomerTypes: state.reportBuilder.selectedCustomerTypes,
    selectedBnBProduct: state.reportBuilder.selectedBnBProduct,
    selectedBnBBrand: state.reportBuilder.selectedBnBBrand,
    selectedBnBCategory: state.reportBuilder.selectedBnBCategory,
    bnbGraphData: state.reportBuilder.bnbGraphData,
    bnbTableData: state.reportBuilder.bnbTableData,
    bnbTotal: state.reportBuilder.bnbTotal,
    isExporting: state.reportBuilder.isExporting,
    isExported: state.reportBuilder.isExported,
    pdfURL: state.reportBuilder.pdfURL,
    pmpList: state.reportBuilder.pmpList,
    pmp: state.reportBuilder.pmp,
    isLoading: state.reportBuilder.isLoading,
    depots: state.reportBuilder.depots,
    departments: state.reportBuilder.departments,
    categories: state.reportBuilder.categories,
    subCategories: state.reportBuilder.subCategories,
    level4s: state.reportBuilder.level4s,
    level5s: state.reportBuilder.level5s,
    alt_departments: state.reportBuilder.alt_departments,
    alt_department: state.reportBuilder.alt_department,
    alt_categories: state.reportBuilder.alt_categories,
    alt_category: state.reportBuilder.alt_category,
    alt_subCategories: state.reportBuilder.alt_subCategories,
    alt_subCategory: state.reportBuilder.alt_subCategory,
    alt_level4s: state.reportBuilder.alt_level4s,
    alt_level4: state.reportBuilder.alt_level4,
    alt_level5s: state.reportBuilder.alt_level5s,
    alt_level5: state.reportBuilder.alt_level5,
    alt_brands: state.reportBuilder.alt_brands,
    alt_brand: state.reportBuilder.alt_brand,
    alt_products: state.reportBuilder.alt_products,
    alt_product: state.reportBuilder.alt_product,
    alt_productsIsLoading: state.reportBuilder.alt_productsIsLoading,
    productsIsLoading: state.reportBuilder.productsIsLoading,
    brands: state.reportBuilder.brands,
    bnbBrands: state.reportBuilder.bnbBrands,
    bnbCategories: state.reportBuilder.bnbCategories,
    department: state.reportBuilder.department,
    category: state.reportBuilder.category,
    subCategory: state.reportBuilder.subCategory,
    level4: state.reportBuilder.level4,
    level5: state.reportBuilder.level5,
    brand: state.reportBuilder.brand,
    selectedDepots: state.reportBuilder.selectedDepots,
    bnbBusinessTypes: state.reportBuilder.bnbBusinessTypes,
    selectedBusinessType: state.reportBuilder.selectedBusinessType,
    reportError: state.reportBuilder.reportError,
    companyTypes: state.reportBuilder.companyTypes,
    selectedCompanyType: state.reportBuilder.selectedCompanyType,
    groups: state.reportBuilder.groups,
    selectedGroup: state.reportBuilder.selectedGroup,
    timeframes: state.reportBuilder.timeframes,
    selectedTimeFrame: state.reportBuilder.selectedTimeFrame,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    setTitle,
    selectAllCustomerTypes,
    deselectAllCustomerTypes,
    fetchDefaultData,
    bnbProductChanged,
    bnbBrandChanged,
    bnbMemberChanged,
    bnbCategoryChanged,
    customerTypeChanged,
    exportToPDF,
    setPmp,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setLevel5,
    setBrand,
    setDepot,
    setBusinessType,
    setCompanyType,
    loadReport,
    reset,
    setGroup,
    setTimeFrame,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setTitle('BOUGHT, NOT BOUGHT');
      this.props.fetchDefaultData('BNB');
      this.props.reset();
    },
    componentDidUpdate(lastProps) {
      if (
        lastProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        lastProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: this.props.title,
            });
          }, 1000);
        }, 1000);
      }
      if (
        lastProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        lastProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} />, { autoClose: false });
      }
      if (lastProps.match.params.subpage !== this.props.match.params.subpage) {
        this.props.setTitle('BOUGHT, NOT BOUGHT');
        this.props.fetchDefaultData('BNB');
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onTimeFrameChange:
      ({ data, handleData, setTimeFrame }) =>
      opt => {
        if (opt) {
          setTimeFrame(opt.ID);
        } else {
          setTimeFrame('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepartmentChange:
      ({ data, handleData, setDepartment, alt_department}) =>
      selectedOption => {
        if (selectedOption === null) {
          if (alt_department?.Categories) {
            handleData({ ...data, isRefreshDisabled: false });
          } else {
            handleData({ ...data, isRefreshDisabled: true });
          }
          setDepartment(0);

        } else {
          setDepartment(selectedOption.ID);
          handleData({ ...data, isRefreshDisabled: false });
        }
      },
    onCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0);
        } else {
          setCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0);
        } else {
          setSubCategory(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltDepartmentChange:
      ({ data, handleData, setDepartment, department }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDepartment(0, true);
          if (department) {
            handleData({ ...data, isRefreshDisabled: false });
          } else {
            handleData({ ...data, isRefreshDisabled: true });
          }
        } else {
          setDepartment(selectedOption.ID, true);
          handleData({ ...data, isRefreshDisabled: false });
        }
      },
    onAltCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0, true);
        } else {
          setCategory(selectedOption.ID, true);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0, true);
        } else {
          setSubCategory(selectedOption.ID, true);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand('');
        } else {
          setBrand(selectedOption.Brand);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand('', true);
        } else {
          setBrand(selectedOption.Brand, true);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0);
        } else {
          setLevel5(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0);
        } else {
          setLevel4(selectedOption.ID);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0, true);
        } else {
          setLevel5(selectedOption.ID, true);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0, true);
        } else {
          setLevel4(selectedOption.ID, true);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAltProductChange:
      ({ data, handleData, bnbProductChanged }) =>
      selectedOption => {
        bnbProductChanged(selectedOption, true);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBnBMemberChanged:
      ({ data, handleData, bnbMemberChanged, bnbMembers }) =>
      selectedOption => {
        if (bnbMembers.options.length > 1) {
          bnbMemberChanged(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
      },
    onCustomerTypeChanged:
      ({ data, handleData, customerTypeChanged }) =>
      selectedOption => {
        customerTypeChanged(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBnBProductChanged:
      ({ data, handleData, bnbProductChanged }) =>
      selectedOption => {
        bnbProductChanged(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBnBBrandChanged:
      ({ data, handleData, bnbBrandChanged }) =>
      selectedOption => {
        bnbBrandChanged(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBnBCategoryChanged:
      ({ data, handleData, bnbCategoryChanged }) =>
      selectedOption => {
        bnbCategoryChanged(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    unlockRefresh:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: false });
    },
    onSelectAllCustomerTypes:
      ({ data, handleData, selectAllCustomerTypes }) =>
      event => {
        selectAllCustomerTypes();
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDeselectAllCustomerTypes:
      ({ data, handleData, deselectAllCustomerTypes }) =>
      event => {
        deselectAllCustomerTypes();
        handleData({ ...data, isRefreshDisabled: false });
      },
    onExportData: props => type => {
      if (type === 'csv') {
        exportTableToCSV(
          $('.export-table'),
          `${getRandomNumber('export_')}.csv`,
        );
      }

      if (type === 'pdf') {
        let fields =
          `FILTER VALUE: ` + props.filterValue.toUpperCase() + `<br/>`;
        fields += `FILTER: ` + props.filter.toUpperCase() + `<br/>`;
        if (props.selectedBnBProduct.WVProdDesc) {
          fields +=
            `WVProdDesc: ` +
            props.selectedBnBProduct.WVProdDesc.toUpperCase() +
            `<br/>`;
        }
        let selectedPmp = props.pmpList.find(pmp => pmp.ID === props.pmp);
        fields += `<br/>${selectedPmp.Text.toUpperCase()}: ${selectedPmp.ID}`;
        var srctext = $('.c-objects-wrap--graph').get(0).outerHTML;
        var re = /<svg ([^>]*)>*/;
        var newtext = srctext.replace(
          re,
          `<svg viewBox="-100 0 800 800" width="735" height="450">`,
        );
        let htmlString = `<html>
                                    <head>
                                      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                                      <style>
                                         .o-canvas__body,h1,p{font-family:"Gotham Light",Helvetica,Verdana;font-weight:100}tbody,tr{border-color:inherit}@font-face{font-family:"Gotham Light";src:url(http://alchemy.todays.co.uk/assets/fonts/gothamlight-e8bf7.woff2) format("woff2"),url(/assets/fonts/gothamlight-66252.woff) format("woff");font-weight:100;font-style:normal;font-display:fallback}@font-face{font-family:"Gotham Book";src:url(http://alchemy.todays.co.uk/assets/fonts/gothambook-a315e.woff2) format("woff2"),url(/assets/fonts/gothambook-57f47.woff) format("woff");font-weight:600;font-style:normal;font-display:fallback}p{font-size:.7em}h1{font-size:1em}table td,table th{font-family:Arial}.o-table{width:95%;margin:0 auto}table{border-spacing:0;border-collapse:separate;font-size:.78125em}table th{padding:.8em;vertical-align:top}tr{display:table-row;vertical-align:inherit}tbody{display:table-row-group;vertical-align:middle}table td{-webkit-font-smoothing:antialiased;border-bottom:1px solid #e0e8ec;height:2em;padding:.5em}.o-table .u-tac{padding-left:.8em}.u-bg--brand{background-color:#bf73ab}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(2){background-color:#fff;border-bottom:none;border-right:0;font-weight:900}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(5){background-color:#fff;font-family:Arial;border-bottom:none;color:#384044;font-size:inherit;font-weight:900}.o-product-performance--product-performance .u-bg--brand,.o-product-performance--product-performance .u-bg--brand-dst{font-size:1.125em;text-align:center}.fa{display:block}.u-bg--brand-dst{background-color:#5a2f50}.u-bg--brand-d{background-color:#8c527e;text-align:center}[class*=u-bg--brand]{color:#fff}.o-product-performance--product-performance tbody td{height:2em;padding:.5em}.c-objects-wrap{width:100%;text-align:center;position:relative}
                                      </style>
                                    </head>
                                    <body style="margin:0px;padding:0px;">
                                        <h1 style="float:left;font-weight: 100;margin-left:10px">BOUGHT, NOT BOUGHT</h1>
                                        <div style="float:right;-webkit-margin-before: 5px;-webkit-margin-after: 0.67em;-webkit-margin-start: 0px;-webkit-margin-end: 10px;">
                                         <p>${fields}</p>
                                         </div>
                                        <div style="top:100px;position:absolute;margin:0 auto;width:100%">
                                        ${newtext
                                          .replace(
                                            'font-size: 15px',
                                            'font-size: 10px',
                                          )
                                          .replace(
                                            'font-size: 14px',
                                            'font-size: 9px',
                                          )}
                                        </div>
                                    </body>
                                </html>`;
        props.exportToPDF(htmlString);
      }
    },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      opt => {
        setDepot(opt || {});
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBusinessTypeChange:
      ({ data, handleData, setBusinessType }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBusinessType('');
        } else {
          setBusinessType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    resolveBusinessTypes: () => businessTypes => {
      businessTypes.forEach((type, index) => {
        type.Text = type.BusinessName;
        type.ID = index;
      });
      return businessTypes;
    },
    onCompanyTypeChange:
      ({ data, handleData, setCompanyType }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCompanyType('');
        } else {
          setCompanyType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onResetReport: props => () => {
      props.setTitle('BOUGHT, NOT BOUGHT');
      props.fetchDefaultData('BNB');
      props.reset();
    },
  }),
)(ReportBuilder);
