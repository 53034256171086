"use strict";
exports.__esModule = true;
exports.dev = exports.demo = exports.suntory = exports.jamesHall = exports.cotswoldFayre = exports.twc = exports.sugro = exports.caterforce = exports.cjLang = exports.cjLangRetail = exports.unitas = exports.deebee = exports.parfettsNew= exports.united = exports.crg = exports.confex = void 0;
exports.confex = {
    name: 'confex',
    fontColor: '#0055A6',
    font: 'open-sans, sans-serif',
    primary: '#FBAF3D',
    secondary: '#0055A6',
    buttons: '#0055A6',
    subMenuButtons: '#FBAF3D',
    primaryDark: '#EB9B23',
    secondaryDark: '#0055A6',
    boxShadow: 'rgba(251, 175, 61, 0.5)',
    toastColor: '#FBAF3D',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#FBAF3D',
    loginRight: '#0055A6',
    outlineColor: '#0055A6',
    // NavBar
    navBar: '#005597',
    subNavBar: '#FBAF3D',
    navBarActive: '#00488D',
    subNavBarActive: '#EB9B23',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#00488D',
    navBarText: '#fff',
    subNavBarText: '#fff',
    navBarSubText: '',
    navIcons: '#FBAF3D',
    isIconBlack: false,
    //Charts
    donutBg: '#0055A6',
    donutColor1: '90-#FBAF3D-#EB9B23:60',
    donutColor2: '90-#EB9B23-#FBAF3D:60',
    donutColor3: '90-#e39c32-#e8ab4f:60',
    donutColor4: '90-#ffbc57-#f2a024:60',
    donutColor5: '90-#ffa317-#ffbf5e:60',
    lineGraph1A: '#FBAF3D',
    lineGraph1B: '#EB9B23',
    lineGraph1C: '#FBAF3D',
    lineGraph2A: '#0055A6',
    lineGraph2B: '#0055A9',
    lineGraph2C: '#0055A6',
    lineGraph3A: '#458FBF',
    lineGraph3B: '#458FBF',
    lineGraph3C: '#458FBF',
    // Table Colours
    tableColor1: '0055A6',
    tableColor2: '0055A6',
    colorArray: ['#FBAF3D', '#EB9B23 ', '#e8ab4f'],
    // API
    apiUrl: 'https://confex-api.twcgroup.net',
    uatApiUrl: 'https://confex-api.uat.twcgroup.net',
    manualUrl: 'https://confex-api.twcgroup.net/ConfexUserGuide.pdf',
    weeklyNumbersUrl: 'https://confex-api.twcgroup.net/WeekNumbers.xlsx',
    extraSupportUrls: [
        [
            'Food Services Category Structure',
            'https://confex-api.twcgroup.net/CONFEX_FOOD_SERVICES_CATEGORY_STRUCTURE.xlsx',
        ],
        [
            'Retail Category Structure',
            'https://confex-api.twcgroup.net/CONFEX_RETAIL_CATEGORY_STRUCTURE.xlsx',
        ],
        [
            'How is Central Distribution Data Managed',
            'https://confex-api.twcgroup.net/confex_central_data.pdf',
        ],
    ]
};
exports.crg = {
    name: 'countryRangeGroup',
    fontColor: '#363333',
    font: 'PT Sans',
    primary: '#ff7753',
    secondary: '#ABD59B',
    primaryDark: '#ff7753',
    secondaryDark: '#0055A6',
    boxShadow: 'rgba(231, 43, 54, 0.5);',
    buttons: '#ff7753',
    subMenuButtons: '#ff7753',
    toastColor: '#ABD59B',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#ABD59B',
    loginRight: '#e8b879',
    outlineColor: '#3e4d5b',
    // Nav
    navBar: '#363333',
    subNavBar: '#ABD59B',
    navBarActive: '#ABD59B',
    subNavBarActive: '#DDEED7',
    navBarTextActive: '#363333',
    navBarTextHover: '#363333',
    navBarHover: '#f7f7f7',
    navBarText: '#e6e6e6',
    subNavBarText: '#474848 ',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //Charts
    donutBg: '#363333',
    donutColor1: '#8ec679',
    donutColor2: '#a8d298',
    donutColor3: '#c2deb7',
    donutColor4: '#dcead6',
    donutColor5: '#B1DEA0',
    lineGraph1A: '#75A364',
    lineGraph1B: '#ABF092',
    lineGraph1C: '#628A54',
    lineGraph2A: '#D16345',
    lineGraph2B: '#FF7954',
    lineGraph2C: '#ED714E',
    lineGraph3A: '#c2deb7',
    lineGraph3B: '#c2deb7',
    lineGraph3C: '#c2deb7',
    tableColor1: '#8EC679',
    tableColor2: '#ff7753',
    colorArray: ['#e8b879', '#f2cfa0 ', '#e8a751'],
    // API
    apiUrl: 'https://crg-api.twcgroup.net',
    uatApiUrl: 'https://crg-api.uat.twcgroup.net',
    manualUrl: 'https://crg-api.uat.twcgroup.net/crg_user_guide.pptx',
    categoryStructureUrl: 'https://crg-api.uat.twcgroup.net/crg_category_structure.xlsx',
    extraSupportUrls: [
        [
            'Key Facts Guide',
            'https://confex-api.twcgroup.net/crg_key_facts_guide.pptx',
        ],
    ]
};
exports.united = {
    name: 'united',
    fontColor: '#3e4d5b',
    font: 'Gotham-Medium, Arial, Helvetica, sans-serif',
    primary: '#3a4d5c',
    secondary: '#3e4d5b',
    primaryDark: '#f71212',
    secondaryDark: '#0055A6',
    boxShadow: 'rgba(231, 43, 54, 0.5);',
    buttons: '#3a4d5c',
    subMenuButtons: '#e30914',
    toastColor: '#e30914',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#3a4d5c',
    loginRight: '#23303b',
    outlineColor: '#3e4d5b',
    // Nav
    navBar: '#e30914',
    subNavBar: '#3a4d5c',
    navBarActive: '#b9060f',
    subNavBarActive: '#23303b',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#b9060f',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //Charts
    donutBg: '#23303B',
    donutColor1: '#475e73',
    donutColor2: '#5d7c97',
    donutColor3: '#4e687f',
    donutColor4: '#56728b',
    donutColor5: '#6786a1',
    lineGraph1A: '#6786A1',
    lineGraph1B: '#3E5161',
    lineGraph1C: '#90BBE0',
    lineGraph2A: '#3E5161',
    lineGraph2B: '#7FA5C7',
    lineGraph2C: '#3a4d5c',
    lineGraph3A: '#3E5161',
    lineGraph3B: '#98C5ED',
    lineGraph3C: '#90BBE0',
    tableColor1: '#3a4d5c',
    tableColor2: '#d1352e',
    colorArray: ['#3a4d5c', '#d1352e ', '#475E70'],
    // API
    apiUrl: 'https://united-api.twcgroup.net',
    uatApiUrl: 'https://united-api.uat.twcgroup.net',
    weeklyNumbersUrl: 'https://united-api.twcgroup.net/WeekNumbers.xlsx',
};
exports.deebee = {
    name: 'deebee',
    fontColor: '#1C3E94',
    font: 'PT Sans',
    primary: '#1C3E94',
    secondary: '#A0CF67',
    primaryDark: '#285ad6',
    secondaryDark: '#0055A6',
    boxShadow: 'rgba(28, 62, 148, 0.5);',
    buttons: '#1C3E94',
    subMenuButtons: '#1C3E94',
    toastColor: '#a0cf67',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#1C3E94',
    loginRight: '#A0CF67',
    outlineColor: '#243F8F',
    // Nav
    navBar: '#1C3E94',
    subNavBar: '#A0CF67',
    navBarActive: '#A0CF67',
    subNavBarActive: '#BBF07A',
    navBarTextActive: '#1C3E94',
    navBarTextHover: '#1C3E94',
    navBarHover: '#A0CF67',
    navBarText: '#fff',
    subNavBarText: '#1C3E94 ',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //Charts
    donutBg: '#163074',
    donutColor1: '#8ec679',
    donutColor2: '#a8d298',
    donutColor3: '#c2deb7',
    donutColor4: '#a0cf67',
    donutColor5: '#B1DEA0',
    lineGraph1A: '#1C3E94',
    lineGraph1B: '#2B5EE0',
    lineGraph1C: '#102354',
    lineGraph2A: '#AACD74',
    lineGraph2B: '#94B366',
    lineGraph2C: '#B3D97C',
    lineGraph3A: '#c2deb7',
    lineGraph3B: '#c2deb7',
    lineGraph3C: '#c2deb7',
    tableColor1: '#1C3E94',
    tableColor2: '#A0CF67',
    colorArray: ['#e8b879', '#f2cfa0 ', '#e8a751'],
    apiUrl: 'https://deebee-api.twcgroup.net',
    uatApiUrl: 'https://deebee-api.twcgroup.net'
};
exports.parfettsNew = {
    name: 'parfettsNew',
    fontColor: '#1A202C',
    font: 'PT Sans',
    primary: '#D31E25',
    secondary: '#212121',
    primaryDark: '#B5191F',
    secondaryDark: '#212121',
    boxShadow: 'rgba(28, 62, 148, 0.5);',
    buttons: '#212121',
    subMenuButtons: '#212121',
    toastColor: '#D31E25',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#D31E25',
    loginRight: '#212121',
    outlineColor: '#212121',
    // Nav
    navBar: '#fff',
    subNavBar: '#D31E25',
    navBarActive: '#D31E25',
    subNavBarActive: '#A6171C',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#D31E25',
    navBarText: '#212121',
    subNavBarText: '#fff ',
    isIconBlack: true,
    navBarSubText: '#212121',
    navIcons: '#212121',
    //Charts
    donutBg: '#212121',
    donutColor1: '#D31E25',
    donutColor2: '#941519',
    donutColor3: '#540C0E',
    donutColor4: '#E01F26',
    donutColor5: '#BA1A1F',
    lineGraph1A: '#D31E25',
    lineGraph1B: '#941519',
    lineGraph1C: '#540C0E',
    lineGraph2A: '#E01F26',
    lineGraph2B: '#94B366',
    lineGraph2C: '#BA1A1F',
    lineGraph3A: '#D31E25',
    lineGraph3B: '#D31E25',
    lineGraph3C: '#D31E25',
    tableColor1: '#D31E25',
    tableColor2: '#212121',
    colorArray: ['#D31E25', '#212121 ', '#E01F26'],
    apiUrl: 'https://parfetts-new-api.twcgroup.net',
    uatApiUrl: 'https://parfetts-new-api.uat.twcgroup.net'
};
exports.unitas = {
    name: 'unitas',
    fontColor: '#4a2683',
    font: 'Gotham-Medium, Arial, Helvetica, sans-serif',
    primary: '#e30613',
    secondary: '#4a2683',
    primaryDark: '#f71212',
    secondaryDark: '#0055A6',
    boxShadow: 'rgba(231, 43, 54, 0.5);',
    buttons: '#e30613',
    subMenuButtons: '#e30613',
    toastColor: '#e30613',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#transparent linear-gradient(180deg, #4A2583 0%, #4A2583 41%, #341A5D 100%) 0% 0% no-repeat padding-box',
    loginLeft: 'linear-gradient(14deg, #4a2583 17%, #341a5d 45%);',
    loginRight: 'linear-gradient(350deg, #4a2583 20%, #341a5d 50%)',
    outlineColor: '#fff',
    // Nav
    navBar: '#4a2683',
    subNavBar: '#e30613',
    navBarActive: '#4a2683',
    subNavBarActive: '#f71212',
    navBarTextActive: '#e30613',
    navBarTextHover: '#e30613',
    navBarHover: '#f7f7f7',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //Charts
    donutBg: '#4a2683',
    donutColor1: '90-#b9040e-#d50410:60',
    donutColor2: '90-#b60711-#e30613:60',
    donutColor3: '90-#d24d4e-#f96060:60',
    donutColor4: '90-#cb3839-#f14243:60',
    donutColor5: '90-#b9040e-#d50410:60',
    lineGraph1A: '#e30613',
    lineGraph1B: '#f71212',
    lineGraph1C: '#e30613',
    lineGraph2A: '#4a2683',
    lineGraph2B: '#0055A6',
    lineGraph2C: '#4a2683',
    lineGraph3A: '#458FBF',
    lineGraph3B: '#458FBF',
    lineGraph3C: '#458FBF',
    tableColor1: '#e30613',
    tableColor2: '#e30613',
    colorArray: ['#6C36C4', '#CAB8E6 ', '#8464B9'],
    // API
    apiUrl: 'https://unitas-api.twcgroup.net',
    uatApiUrl: 'https://unitas-api.uat.twcgroup.net',
    //manual
    manualUrl: 'https://unitas-api.twcgroup.net/ALCHEMY_UNITAS_V2.pdf',
    weeklyNumbersUrl: 'https://unitas-api.twcgroup.net/WeekNumbers.xlsx',
    extraSupportUrls: [
        [
            'Retail Category Structure',
            'https://unitas-api.twcgroup.net/unitas_categories_retail.xlsx',
        ],
        [
            'Catering Category Structure',
            'https://unitas-api.twcgroup.net/unitas_categories_catering.xlsx',
        ],
    ]
};
exports.cjLang = {
    name: 'cjlang',
    fontColor: '#005597',
    font: 'proxima-nova, sans-serif',
    primary: '#005597',
    secondary: '#E93A3E',
    primaryDark: '#004897',
    secondaryDark: '#E93A3E',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#005597',
    subMenuButtons: '#005597',
    toastColor: '#005597',
    dropdownBackground: '#fff',
    // Logiing Page
    loginBg: '#fff',
    loginRight: '#005597',
    loginLeft: 'linear-gradient(248deg, #005587 50%,#014b84 100%)',
    outlineColor: '#005597',
    // Nav Bar
    navBar: '#fff',
    subNavBar: '#E93A3E',
    navBarActive: '#E93A3E',
    subNavBarActive: '#CB2327',
    navBarTextActive: '#fff',
    navBarTextHover: '#004897',
    navBarHover: '#f7f7f7',
    navBarText: '#005597',
    subNavBarText: '#fff',
    isIconBlack: true,
    navBarSubText: '#000',
    navIcons: '#fff',
    // Charts
    donutBg: '#042B4A',
    donutColor1: '90-#014376-#005597:60',
    donutColor2: '90-#005597-#014376:60',
    donutColor3: '90-#014376-#005597:60',
    donutColor4: '90-#014376-#005597:60',
    donutColor5: '90-#005597-#014376:60',
    lineGraph1A: '#2C78A9',
    lineGraph1B: '#363680',
    lineGraph1C: '#363680',
    lineGraph2A: '#00005A',
    lineGraph2B: '#000048',
    lineGraph2C: '#000048',
    lineGraph3A: '#458FBF',
    lineGraph3B: '#458FBF',
    lineGraph3C: '#458FBF',
    tableColor1: '#005597',
    tableColor2: '#005597',
    colorArray: ['#096bb2', '#378fce', '#084b7b'],
    // API
    apiUrl: 'https://cjlang-api.twcgroup.net',
    uatApiUrl: 'https://cjlang-api.uat.twcgroup.net',
    //manual
    manualUrl: 'https://cjlang-api.twcgroup.net/spar_user_guide.pdf',
    weeklyNumbersUrl: 'https://cjlang-api.twcgroup.net/weeknumbers.xlsx',
    categoryStructureUrl: 'https://cjlang-api.twcgroup.net/CJLANG_MASTER_CATEGORY_STRUCTURE.xlsx'
};
exports.cjLangRetail = {
    name: 'cjlangRetail',
    fontColor: '#108e45',
    font: 'proxima-nova, sans-serif',
    primary: '#108e45',
    secondary: '#E93A3E',
    primaryDark: '#0D703C',
    secondaryDark: '#E93A3E',
    boxShadow: 'rgba(18,163,78,0.5)',
    buttons: '#108e45',
    subMenuButtons: '#108e45',
    toastColor: '#108e45',
    dropdownBackground: '#fff',
    // Logiing Page
    loginBg: '#fff',
    loginRight: '#108e45',
    loginLeft: 'linear-gradient(248deg, #109447 50%,#0E753E 100%)',
    outlineColor: '#108e45',
    // Nav Bar
    navBar: '#fff',
    subNavBar: '#E93A3E',
    navBarActive: '#E93A3E',
    subNavBarActive: '#CB2327',
    navBarTextActive: '#fff',
    navBarTextHover: '#0D703C',
    navBarHover: '#f7f7f7',
    navBarText: '#108e45',
    subNavBarText: '#fff',
    isIconBlack: true,
    navBarSubText: '#000',
    navIcons: '#fff',
    // Charts
    donutBg: '#0A542D',
    donutColor1: '90-#0D703C-#108e45:60',
    donutColor2: '90-#108e45-#0D703C:60',
    donutColor3: '90-#0D703C-#108e45:60',
    donutColor4: '90-#0D703C-#108e45:60',
    donutColor5: '90-#108e45-#0D703C:60',
    lineGraph1A: '#13AD53',
    lineGraph1B: '#0C6335',
    lineGraph1C: '#0C6335',
    lineGraph2A: '#0A542D',
    lineGraph2B: '#0D703C',
    lineGraph2C: '#0D703C',
    lineGraph3A: '#108e45',
    lineGraph3B: '#108e45',
    lineGraph3C: '#108e45',
    tableColor1: '#108e45',
    tableColor2: '#108e45',
    colorArray: ['#108e45', '#13AD53', '#0D703C'],
    // API
    apiUrl: 'https://cjlangretail-api.twcgroup.net',
    uatApiUrl: 'https://cjlangretail-api.uat.twcgroup.net',
    //manual
    manualUrl: 'https://cjlangretail-api.twcgroup.net/spar_user_guide.pdf',
    weeklyNumbersUrl: 'https://cjlangretail-api.twcgroup.net/weeknumbers.xlsx',
    categoryStructureUrl: 'https://cjlangretail-api.twcgroup.net/CJLANG_MASTER_CATEGORY_STRUCTURE.xlsx'
};
exports.caterforce = {
    name: 'caterforce',
    fontColor: '#2C78A9',
    font: 'proxima-nova, sans-serif',
    primary: '#2C78A9',
    secondary: '#00005A',
    primaryDark: '#363680',
    secondaryDark: '#000048',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#2C78A9',
    subMenuButtons: '#2C78A9',
    toastColor: '#2C78A9',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: 'linear-gradient(160deg, #00005A 15%, #000048 50%)',
    loginRight: 'linear-gradient(162deg,#00005A 50%, #000048 100%)',
    outlineColor: '#00005A',
    // Nav
    navBar: '#00005A',
    subNavBar: '#2C78A9',
    navBarActive: '#363680',
    subNavBarActive: '#458FBF',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#363680',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#2C78A9',
    // Charts
    donutBg: '#00005A',
    donutColor1: '#2C78A9',
    donutColor2: '#458FBF',
    donutColor3: '#2C78A9',
    donutColor4: '#458FBF',
    donutColor5: '#2C78A9',
    lineGraph1A: '#2C78A9',
    lineGraph1B: '#363680',
    lineGraph1C: '#363680',
    lineGraph2A: '#00005A',
    lineGraph2B: '#000048',
    lineGraph2C: '#000048',
    lineGraph3A: '#458FBF',
    lineGraph3B: '#458FBF',
    lineGraph3C: '#458FBF',
    tableColor1: '#2C78A9',
    tableColor2: '#2C78A9',
    colorArray: ['#2C78A9', '#458FBF ', '#363680'],
    // API
    apiUrl: 'https://caterforce-api.twcgroup.net',
    uatApiUrl: 'https://caterforce-api.uat.twcgroup.net',
    //manual
    manualUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_SALES_HUB_USER_GUIDE_150321.pdf',
    weeklyNumbersUrl: 'https://caterforce-api.twcgroup.net/weeknumbers.xlsx',
    categoryStructureUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_CATEGORY_STRUCTURE_25_05_2021.xlsx'
};
exports.sugro = {
    name: 'sugro',
    fontColor: '#000',
    font: 'Gotham-Medium, Arial, Helvetica, sans-serif',
    primary: '#444',
    secondary: '#de0e26',
    primaryDark: '#b50d20',
    secondaryDark: '#b50d20',
    boxShadow: 'rgba(231, 43, 54, 0.5);',
    buttons: '#de0e26',
    subMenuButtons: '#444',
    toastColor: '#444',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: '#444',
    loginRight: '#de0e26',
    outlineColor: '#444',
    // Nav
    navBar: '#fff',
    subNavBar: '#de0e26',
    navBarActive: '#de0e26',
    subNavBarActive: '#b50d20',
    navBarTextActive: '#fff',
    navBarTextHover: '#de0e26',
    navBarHover: '#f7f7f7',
    navBarText: '#de0e26',
    subNavBarText: '#fff',
    isIconBlack: true,
    navBarSubText: '#000',
    navIcons: '#444',
    //Charts
    donutBg: '#363636',
    donutColor1: '#DE0E26',
    donutColor2: '#C40C21',
    donutColor3: '#DE0E26',
    donutColor4: '#9E091B',
    donutColor5: '#C40C21',
    lineGraph1A: '#444',
    lineGraph1B: '#de0e26',
    lineGraph1C: '#b00b1e',
    lineGraph2A: '#de0e26',
    lineGraph2B: '#c70c22',
    lineGraph2C: '#b00b1e',
    lineGraph3A: '#444',
    lineGraph3B: '#444',
    lineGraph3C: '#444',
    tableColor1: '#de0e26',
    tableColor2: '#de0e26',
    colorArray: ['#DE0E26', '#C40C21 ', '#9E091B'],
    // API
    apiUrl: 'https://sugro-api.twcgroup.net',
    uatApiUrl: 'https://sugro-api.uat.twcgroup.net'
};
exports.twc = {
    name: 'twc',
    fontColor: '#2C78A9',
    font: 'proxima-nova, sans-serif',
    primary: '#1C2C3A',
    secondary: '#00005A',
    primaryDark: '#363680',
    secondaryDark: '#000048',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#1C2C3A',
    subMenuButtons: '#1C2C3A',
    toastColor: '#FAC600',
    dropdownBackground: '#fff',
    //   Login Page
    loginBg: '#fff',
    loginLeft: '#1C2C3A',
    loginRight: '#FAC600',
    outlineColor: '#1C2C3A',
    // Nav
    navBar: '#1C2C3A',
    subNavBar: '#FAC600',
    navBarActive: '#2F406E',
    subNavBarActive: '#E0B400',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#2F406E',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //  Charts
    donutBg: '#1C2C3A',
    donutColor1: '#FAC600',
    donutColor2: '#E3A20B',
    donutColor3: '#FFB60D',
    donutColor4: '#FAC600',
    donutColor5: '#E3A20B',
    lineGraph1A: '#FAC600',
    lineGraph1B: '#FAC600',
    lineGraph1C: '#FAC600',
    lineGraph2A: '#E3A20B',
    lineGraph2B: '#E3A20B',
    lineGraph2C: '#E3A20B',
    lineGraph3A: '#FFB60D',
    lineGraph3B: '#FFB60D',
    lineGraph3C: '#FFB60D',
    tableColor1: '#E3A20B',
    tableColor2: '#E3A20B',
    colorArray: ['#3D5F7D', '#E3A20B', '#FAC600'],
    // API
    apiUrl: 'https://unitas-api.twcgroup.net',
    uatApiUrl: 'https://unitas-api.uat.twcgroup.net'
};

exports.cotswoldFayre = {
    name: 'cotswold',
    fontColor: '#252525',
    font: 'proxima-nova, sans-serif',
    primary: '#252525',
    secondary: '#6c1c4f',
    primaryDark: '#252525',
    secondaryDark: '#451232',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#72BE38',
    subMenuButtons: '#72BE38',
    toastColor: '#6c1c4f',
    dropdownBackground: '#fff',
    //   Login Page
    loginBg: '#fff',
    loginLeft: '#252525',
    loginRight: '#6c1c4f',
    outlineColor: '#6c1c4f',
    // Nav
    navBar: '#fff',
    subNavBar: '#6c1c4f',
    navBarActive: '#6c1c4f',
    subNavBarActive: '#471334',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#6c1c4f',
    navBarText: '#000',
    subNavBarText: '#fff',
    isIconBlack: true,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //  Charts
    donutBg: '#252525',
    donutColor1: '#6B1C4E',
    donutColor2: '#99286F',
    donutColor3: '#C2328D',
    donutColor4: '#471334',
    donutColor5: '#C73491',
    lineGraph1A: '#6c1c4f',
    lineGraph1B: '#6c1c4f',
    lineGraph1C: '#6c1c4f',
    lineGraph2A: '#72be38',
    lineGraph2B: '#72be38',
    lineGraph2C: '#72be38',
    lineGraph3A: '#52153B',
    lineGraph3B: '#52153B',
    lineGraph3C: '#52153B',
    tableColor1: '#781F57',
    tableColor2: '#781F57',
    colorArray: ['#AB2E7D', '#781F57', '#6c1c4f'],
    // API
    apiUrl: 'https://cotswold-fayre-api.twcgroup.net',
    uatApiUrl: 'https://cotswold-fayre-api.uat.twcgroup.net',
    // apiUrl: 'https://demo-api.twcgroup.net',
    // uatApiUrl: 'https://demo-api.uat.twcgroup.net'
};

exports.jamesHall = {
    name: 'jamesHall',
    fontColor: '#252525',
    font: 'proxima-nova, sans-serif',
    primary: '#252525',
    secondary: '#6c1c4f',
    primaryDark: '#252525',
    secondaryDark: '#451232',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#91c03e',
    subMenuButtons: '#91c03e',
    toastColor: '#6c1c4f',
    dropdownBackground: '#fff',
    //   Login Page
    loginBg: '#fff',
    loginLeft: '#252525',
    loginRight: '#6c1c4f',
    outlineColor: '#6c1c4f',
    // Nav
    navBar: '#fff',
    subNavBar: '#222',
    navBarActive: '#222',
    subNavBarActive: '#471334',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#6c1c4f',
    navBarText: '#000',
    subNavBarText: '#fff',
    isIconBlack: true,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //  Charts
    donutBg: '#252525',
    donutColor1: '#6B1C4E',
    donutColor2: '#99286F',
    donutColor3: '#C2328D',
    donutColor4: '#471334',
    donutColor5: '#C73491',
    lineGraph1A: '#6c1c4f',
    lineGraph1B: '#6c1c4f',
    lineGraph1C: '#6c1c4f',
    lineGraph2A: '#72be38',
    lineGraph2B: '#72be38',
    lineGraph2C: '#72be38',
    lineGraph3A: '#52153B',
    lineGraph3B: '#52153B',
    lineGraph3C: '#52153B',
    tableColor1: '#781F57',
    tableColor2: '#781F57',
    colorArray: ['#AB2E7D', '#781F57', '#6c1c4f'],
    // API
    // apiUrl: 'https://james-hall-api.twcgroup.net',
    // uatApiUrl: 'https://james-hall-api.uat.twcgroup.net',
    apiUrl: 'https://demo-api.twcgroup.net',
    uatApiUrl: 'https://demo-api.uat.twcgroup.net'
};

exports.suntory = {
    name: 'suntory',
    fontColor: '#2C78A9',
    font: 'proxima-nova, sans-serif',
    primary: '#1C2C3A',
    secondary: '#00005A',
    primaryDark: '#363680',
    secondaryDark: '#000048',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#1C2C3A',
    subMenuButtons: '#1C2C3A',
    toastColor: '#FAC600',
    dropdownBackground: '#fff',
    //   Login Page
    loginBg: '#fff',
    loginLeft: '#1C2C3A',
    loginRight: '#FAC600',
    outlineColor: '#1C2C3A',
    // Nav
    navBar: '#1C2C3A',
    subNavBar: '#FAC600',
    navBarActive: '#2F406E',
    subNavBarActive: '#E0B400',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#2F406E',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //  Charts
    donutBg: '#1C2C3A',
    donutColor1: '#FAC600',
    donutColor2: '#E3A20B',
    donutColor3: '#FFB60D',
    donutColor4: '#FAC600',
    donutColor5: '#E3A20B',
    lineGraph1A: '#FAC600',
    lineGraph1B: '#FAC600',
    lineGraph1C: '#FAC600',
    lineGraph2A: '#E3A20B',
    lineGraph2B: '#E3A20B',
    lineGraph2C: '#E3A20B',
    lineGraph3A: '#FFB60D',
    lineGraph3B: '#FFB60D',
    lineGraph3C: '#FFB60D',
    tableColor1: '#E3A20B',
    tableColor2: '#E3A20B',
    colorArray: ['#3D5F7D', '#E3A20B', '#FAC600'],
    // API
    apiUrl: 'https://suntory-api.twcgroup.net',
    uatApiUrl: 'https://suntory-api.uat.twcgroup.net',
    categoryStructureUrl: 'https://suntory-api.twcgroup.net/assests/Suntory_category_structure_.pdf',

    extraSupportUrls: [
        [
            'About the TWC & Smartview',
            'https://suntory-api.twcgroup.net/assests/About_TWC_and_the_Suntory_SmartView_Cube.pdf',
        ],
        [
            'Monthly Details',
            'https://suntory-api.twcgroup.net/assests/suntory_monthly_details.pdf',
        ],
        [
            'Contact Details',
            'https://suntory-api.twcgroup.net/assests/support_contact_details.pdf',
        ],
        [
            'ISO Weeks',
            'https://suntory-api.twcgroup.net/assests/IsoWeeks.pdf',
        ],
        [
            'Suntory Reporting Schedule',
            'https://suntory-api.twcgroup.net/assests/SuntoryReportingSchedule2023.pdf',
        ],

    ]
};
exports.demo = {
    name: 'demo',
    //  TODO: Uncomment original demo theme
    fontColor: '#2C78A9',
    font: 'proxima-nova, sans-serif',
    primary: '#1C2C3A',
    secondary: '#00005A',
    primaryDark: '#363680',
    secondaryDark: '#000048',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#1C2C3A',
    subMenuButtons: '#1C2C3A',
    toastColor: '#FAC600',
    dropdownBackground: '#fff',
    //   Login Page
    loginBg: '#fff',
    loginLeft: '#1C2C3A',
    loginRight: '#FAC600',
    outlineColor: '#1C2C3A',
    // Nav
    navBar: '#1C2C3A',
    subNavBar: '#FAC600',
    navBarActive: '#2F406E',
    subNavBarActive: '#E0B400',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#2F406E',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    //  Charts
    donutBg: '#1C2C3A',
    donutColor1: '#FAC600',
    donutColor2: '#E3A20B',
    donutColor3: '#FFB60D',
    donutColor4: '#FAC600',
    donutColor5: '#E3A20B',
    lineGraph1A: '#FAC600',
    lineGraph1B: '#FAC600',
    lineGraph1C: '#FAC600',
    lineGraph2A: '#E3A20B',
    lineGraph2B: '#E3A20B',
    lineGraph2C: '#E3A20B',
    lineGraph3A: '#FFB60D',
    lineGraph3B: '#FFB60D',
    lineGraph3C: '#FFB60D',
    tableColor1: '#E3A20B',
    tableColor2: '#E3A20B',
    colorArray: ['#3D5F7D', '#E3A20B', '#FAC600'],
    // // API
    apiUrl: 'https://demo-api.twcgroup.net',
    uatApiUrl: 'https://demo-api.uat.twcgroup.net',
    //manual
    manualUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_SALES_HUB_USER_GUIDE_150321.pdf',
    weeklyNumbersUrl: 'https://caterforce-api.twcgroup.net/weeknumbers.xlsx',
    categoryStructureUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_CATEGORY_STRUCTURE_25_05_2021.xlsx'
};
exports.dev = {
    name: 'deebee',
    fontColor: '#2C78A9',
    font: 'proxima-nova, sans-serif',
    primary: '#2C78A9',
    secondary: '#00005A',
    primaryDark: '#363680',
    secondaryDark: '#000048',
    boxShadow: 'rgba(44,120,169,0.5)',
    buttons: '#2C78A9',
    subMenuButtons: '#2C78A9',
    toastColor: '#2C78A9',
    dropdownBackground: '#fff',
    // Login Page
    loginBg: '#fff',
    loginLeft: 'linear-gradient(160deg, #00005A 15%, #000048 50%)',
    loginRight: 'linear-gradient(162deg,#00005A 50%, #000048 100%)',
    outlineColor: '#00005A',
    // Nav
    navBar: '#00005A',
    subNavBar: '#2C78A9',
    navBarActive: '#363680',
    subNavBarActive: '#458FBF',
    navBarTextActive: '#fff',
    navBarTextHover: '#fff',
    navBarHover: '#363680',
    navBarText: '#fff',
    subNavBarText: '#fff',
    isIconBlack: false,
    navBarSubText: '#fff',
    navIcons: '#fff',
    // Charts
    donutBg: '#00005A',
    donutColor1: '#2C78A9',
    donutColor2: '#458FBF',
    donutColor3: '#2C78A9',
    donutColor4: '#458FBF',
    donutColor5: '#2C78A9',
    lineGraph1A: '#2C78A9',
    lineGraph1B: '#363680',
    lineGraph1C: '#363680',
    lineGraph2A: '#00005A',
    lineGraph2B: '#000048',
    lineGraph2C: '#000048',
    lineGraph3A: '#458FBF',
    lineGraph3B: '#458FBF',
    lineGraph3C: '#458FBF',
    tableColor1: '#2C78A9',
    tableColor2: '#2C78A9',
    colorArray: ['#2C78A9', '#52a0d2', '#71b1da'],
    // API
    apiUrl: 'https://develop-api.twcgroup.net',
    uatApiUrl: 'https://develop-api.twcgroup.net',
    //manual
    manualUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_SALES_HUB_USER_GUIDE_150321.pdf',
    weeklyNumbersUrl: 'https://caterforce-api.twcgroup.net/weeknumbers.xlsx',
    categoryStructureUrl: 'https://caterforce-api.twcgroup.net/CATERFORCE_CATEGORY_STRUCTURE_25_05_2021.xlsx'
};
var url = window.location.hostname.split('.')[0];
var theme;
if (url.includes('caterforce')) {
    theme = exports.caterforce;
}
else if (url.includes('unitas')) {
    theme = exports.unitas;
}
else if (url.includes('confex')) {
    theme = exports.confex;
}
else if (url.includes('cjlangretail')) {
    theme = exports.cjLangRetail
}
else if (url.includes('cjlang')) {
    theme = exports.cjLang;
}
else if (url.includes('deebee')) {
    theme = exports.deebee;
}
else if (url.includes('sugro')) {
    theme = exports.sugro;
}
else if (url.includes('twc')) {
    theme = exports.twc;
}
else if (url.includes('demo')) {
    theme = exports.demo;
}
else if (url.includes('suntory')) {
    theme = exports.suntory;
}
else if (url.includes('cotswold')) {
    theme = exports.cotswoldFayre;
}
else if (url.includes('james')) {
    theme = exports.jamesHall;
}
else if (url.includes('crg')) {
    theme = exports.crg;
}
else if (url.includes('united')) {
    theme = exports.united;
}
else if (url.includes('develop')) {
    theme = exports.dev;
}
else if (url.includes('parfetts-new')) {
    theme = exports.parfettsNew;
}
else {
    theme = exports.cjLangRetail;
}
exports["default"] = theme;
