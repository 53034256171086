import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import HtmlEditor from '../HtmlEditor';

const yesNoSelect = [
  { ID: '1', Text: 'Yes' },
  { ID: '0', Text: 'No' },
];

const AddAdvert = ({ isLoading, advertTypes, targetTypes, pages, positions, displayOrder, statuses, advertForm, onSaveAdvert, onFormSelectChange, onFormTextChange, onFormFileChange, handleAdvertAddModal }) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{advertForm.ID ? 'Update' : 'Add New'} Advert </span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Advert Name</h3>
              <input type="text" id="advertName" className="ReactModal--input-green" name="advertName" value={advertForm.AdvertName || ''} onChange={evt => onFormTextChange('AdvertName', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Type</h3>
              <Select value={advertForm.Type || ''} onChange={target => onFormSelectChange('Type', target)} options={advertTypes} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Title</h3>
              <input type="text" id="title" className="ReactModal--input-green" name="title" value={advertForm.Title || ''} onChange={evt => onFormTextChange('Title', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Text</h3>
              <input type="text" id="text" className="ReactModal--input-green" name="text" value={advertForm.Text || ''} onChange={evt => onFormTextChange('Text', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Target Type</h3>
              <Select value={advertForm.TargetType || ''} onChange={target => onFormSelectChange('TargetType', target)} options={targetTypes} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Target</h3>
              <input type="text" id="target" className="ReactModal--input-green" name="target" value={advertForm.Target || ''} onChange={evt => onFormTextChange('Target', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Status</h3>
              <Select value={advertForm.Status || ''} onChange={target => onFormSelectChange('Status', target)} options={statuses} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Display From</h3>
              <Datetime value={advertForm.DisplayFrom} onChange={date => onFormTextChange('DisplayFrom', { value: date.format('YYYY-MM-DD HH:mm:ss') })} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Display To</h3>
              <Datetime value={advertForm.DisplayTo} onChange={date => onFormTextChange('DisplayTo', { value: date.format('YYYY-MM-DD HH:mm:ss') })} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Page</h3>
              <Select value={advertForm.Page || ''} onChange={target => onFormSelectChange('Page', target)} options={pages} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Position</h3>
              <Select value={advertForm.Position || ''} onChange={target => onFormSelectChange('Position', target)} options={positions} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Display Order</h3>
              <Select value={advertForm.DisplayOrder || ''} onChange={target => onFormSelectChange('DisplayOrder', target)} options={displayOrder} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={advertForm.AssistUrl} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">AssistUrl</h3>
              <input type="file" name="AssistUrl" onChange={evt => onFormFileChange('AssistUrl', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Unquie Key</h3>
              <input type="text" id="unquieKey" className="ReactModal--input-green" name="unquieKey" value={advertForm.UnquieKey || ''} onChange={evt => onFormTextChange('UnquieKey', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSaveAdvert()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handleAdvertAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAdvert;
