import React from 'react';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import caterforceLogo from '../../../assets/images/logos/caterforce-logo.svg';
import unitasLogo from '../../../assets/images/logos/unitas-logo.svg';
import confexLogo from '../../../assets/images/logos/confex-logo.jpg';
import cjLangLogo from '../../../assets/images/logos/cjLangNew.png';
import parfettsLogo from '../../../assets/images/logos/parfetts.png';
import surgoLogo from '../../../assets/images/logos/sugro-logo.png';
import twcLogo from '../../../assets/images/logos/twcLogo.svg';
import altTwcLogo from '../../../assets/images/logos/twcLogo_dark.svg';
import crgLogo from '../../../assets/images/logos/crg-logo.jpg';
import unitedLogo from '../../../assets/images/logos/united-logo.png';
import deebeeLogo from '../../../assets/images/logos/deebee_logo.png';
import indyTrackLogo from '../../../assets/images/logos/IndyTrack.png';
import cotswoldLogo from '../../../assets/images/logos/cotswold.png';
import jamesHallLogo from '../../../assets/images/logos/jameshall.jpg';

const StyledBrandLogo = styled.img`
  height: ${props =>
    props.theme.name === 'demo' ||
    props.theme.name === 'cotswold'
      ? '35px'
      : '48px'};
  background: ${props =>
    props.theme.name === 'unitas' ||
    props.theme.name === 'twc' ||
    props.theme.name === 'demo' ||
    props.theme.name === 'suntory' ||
    props.theme.name === 'smartviewRetail' || 
    props.theme.name === 'cotswold'
      ? 'transparent'
      : '#fff'};
`;

const BrandLogo = ({ isInTopNav, isForPDF }) => {
  const theme = useContext(ThemeContext);
  let logoUrl;
  if (theme.name === 'unitas') {
    logoUrl = unitasLogo;
  } else if (theme.name === 'confex') {
    logoUrl = confexLogo;
  } else if (theme.name === 'caterforce') {
    logoUrl = caterforceLogo;
  } else if (theme.name === 'cjlang' ) {
    logoUrl = cjLangLogo;
  } else if (theme.name === 'cjlangRetail') {
    logoUrl = indyTrackLogo;
  } else if (theme.name === 'cotswold') {
    logoUrl = cotswoldLogo;
  } else if (theme.name === 'sugro') {
    logoUrl = surgoLogo;
  } else if (theme.name === 'twc') {
    logoUrl = twcLogo;
  } else if (theme.name === 'united') {
    logoUrl = unitedLogo;
  } else if (theme.name === 'demo' || theme.name === 'smartviewRetail' || theme.name === 'suntory') {
    logoUrl = altTwcLogo;
  } else if (theme.name === 'countryRangeGroup') {
    logoUrl = crgLogo;
  } else if (theme.name === 'deebee') {
    logoUrl = deebeeLogo;
  } else if (theme.name === 'parfetts' || theme.name === 'parfettsNew') {
    logoUrl = parfettsLogo;
  }
  return isInTopNav ? (
    <StyledBrandLogo
      src={theme.name === 'demo' || theme.name === 'suntory' ? twcLogo : logoUrl}
      alt="logo"
      className={`img-fluid mr-2 ${theme.name === 'twc' ? 'ml-2 my-1' : ''}`}
      style={{
        padding:
          theme.name === 'countryRangeGroup' || 'cotswold' ? '0.25rem' : 'none',
      }}
    />
  ) : (
    <img
      src={logoUrl}
      className={`img-fluid d-block m-auto`}
    />
  );
};

export default BrandLogo;
