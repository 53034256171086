import { handleActions, createAction } from 'redux-actions';
import { request, requestBlob } from '../../../helpers/axios';
import moment from 'moment';
import ExampleUrl from '../../../assets/images/example.png';
import { getToken, getUserId, getUserSystem } from '../../../helpers/util';
import store from '../store';
import * as _ from 'lodash';
import Reports from '../models/Reports';

const SET_IS_UPDATE_SAVED = 'update/SET_IS_UPDATE_SAVED';
const SET_IS_RECIPIENT_ERRORED = 'update/SET_IS_RECIPIENT_ERRORED'
const FETCH_DEFAULT_DATA = 'update/FETCH_DEFAULT_DATA';
const FETCH_UPDATES = 'update/FETCH_UPDATES';
const FETCH_UPDATE_ENABLED = 'update/FETCH_UPDATE_ENABLED';
const DEPARTMENT_CHANGE = 'update/DEPARTMENT_CHANGE';
const CATEGORY_CHANGE = 'update/CATEGORY_CHANGE';
const SUB_CATEGORY_CHANGE = 'update/SUB_CATEGORY_CHANGE';
const LEVEL4_CHANGE = 'update/LEVEL4_CHANGE';
const LEVEL5_CHANGE = 'update/LEVEL5_CHANGE';
const MEMBER_CHANGE = 'update/MEMBER_CHANGE';
const SUPPLIER_CHANGE = 'update/SUPPLIER_CHANGE';
const BUSINESS_TYPE_CHANGE = 'update/BUSINESS_TYPE_CHANGE'
const MEASURE_CHANGE = 'update/MEASURE_CHANGE';
const TIMEFRAME_CHANGE = 'update/TIMEFRAME_CHANGE';
const RECIPIENT_CHANGE = 'update/RECIPIENT_CHANGE';
const UPDATE_ENABLED_CHANGE = 'update/UPDATE_ENABLED_CHANGE';
const INCLUDED_CHANGE = 'update/INCLUDED_CHANGE';
const CLONE_REPORT = 'update/CLONE_REPORT';
const DELETE_REPORT = 'update/DELETE_REPORT';
const WEEKLY_UPDATE_STATUS_CHANGE = 'update/WEEKLY_UPDATE_STATUS_CHANGE';
const SET_REPORT_VALIDATION_FAILED = 'update/SET_REPORT_VALIDATION_FAILED';
const SORT_REPORTS = 'update/SORT_REPORTS';
const SET_LOADING = 'update/SET_LOADING';
const SET_LOADING_REPORTS = 'update/SET_LOADING_REPORTS';
const SET_NEW_NAME = 'update/SET_NEW_NAME';
const SET_NAME_EDITABLE = 'update/SET_NAME_EDITABLE';
const SET_REPORT_NAMES = 'update/SET_REPORT_NAMES';
const SET_ACTIVE_REPORT_NAME = 'update/SET_ACTIVE_REPORT_NAME';
const SET_REPORT_LIMIT = 'update/SET_REPORT_LIMIT';
const SET_REPORT_DAY = 'update/SET_REPORT_DAY';
const SET_REPORT_PERIOD = 'update/SET_REPORT_PERIOD';
const SET_IS_PREVIEW_DOWNLOADING = 'update/SET_IS_PREVIEW_DOWNLOADING';
const SET_WEEKLY_UPDATES_DELETED = 'update/SET_WEEKLY_UPDATES_DELETED';
const RESET = 'update/RESET';

const initialState = {
  isLoading: false,
  isLoadingReports: false,
  isPreviewDownloading: false,
  isWeeklyUpdatesDeleted: false,
  reportNames: [],
  activeReportName: {value: 'default', label: 'Default'},
  suppliers: [],
  members: [],
  businessTypes: [],
  isUpdateSaved: false,
  isRecipientErrored: false,
  isNameEditable: false,
  isUpdateEnabled: false,
  isWeeklyUpdateStatusChanged: false,
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
        { ID: '4wks', Text: '4 Wk' },
        { ID: '13wks', Text: '13 Wk' },
        { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
        { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
        { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
        { ID: 'YTD', Text: 'YTD' },
        { ID: 'MAT', Text: 'MAT' },
      ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  allDepartments: [],
  reports: Reports,
};

const setIsLoadingReportsAction = createAction(SET_LOADING_REPORTS);
const setNewNameAction = createAction(SET_NEW_NAME);
const setIsNameEditableAction = createAction(SET_NAME_EDITABLE);
const setReportNamesAction = createAction(SET_REPORT_NAMES);
const setActiveReportNameAction = createAction(SET_ACTIVE_REPORT_NAME);
const setIsUpdateSavedAction = createAction(SET_IS_UPDATE_SAVED);
const setIsRecipientErrored = createAction(SET_IS_RECIPIENT_ERRORED);
const fetchDefaultDataAction = createAction(FETCH_DEFAULT_DATA);
const fetchUpdatesAction = createAction(FETCH_UPDATES);
const departmentChangeAction = createAction(DEPARTMENT_CHANGE);
const categoryChangeAction = createAction(CATEGORY_CHANGE);
const subCategoryChangeAction = createAction(SUB_CATEGORY_CHANGE);
const level4ChangeAction = createAction(LEVEL4_CHANGE);
const level5ChangeAction = createAction(LEVEL5_CHANGE);
const memberChangeAction = createAction(MEMBER_CHANGE);
const supplierChangeAction = createAction(SUPPLIER_CHANGE);
const businessTypeChangeAction = createAction(BUSINESS_TYPE_CHANGE);
const measureChangeAction = createAction(MEASURE_CHANGE);
const timeframeChangeAction = createAction(TIMEFRAME_CHANGE);
const recipientChangeAction = createAction(RECIPIENT_CHANGE);
const updateEnabledChangeAction = createAction(UPDATE_ENABLED_CHANGE);
const includedChangeAction = createAction(INCLUDED_CHANGE);
const cloneReportAction = createAction(CLONE_REPORT);
const weeklyUpdateStatusChangeAction = createAction(WEEKLY_UPDATE_STATUS_CHANGE);
const fetchUpdateEnabledAction = createAction(FETCH_UPDATE_ENABLED);
const deleteReportAction = createAction(DELETE_REPORT);
const setReportValidationFailedAction = createAction(SET_REPORT_VALIDATION_FAILED);
const setLoadingAction = createAction(SET_LOADING);
const sortReportsAction = createAction(SORT_REPORTS);
const setReportLimitAction = createAction(SET_REPORT_LIMIT);
const setReportDayAction = createAction(SET_REPORT_DAY);
const setReportPeriodAction = createAction(SET_REPORT_PERIOD);
const setIsPreviewDownloadingAction = createAction(SET_IS_PREVIEW_DOWNLOADING);
const setWeeklyUpdatesDeletedAction = createAction(SET_WEEKLY_UPDATES_DELETED);
const resetAction = createAction(RESET);

export const resetReports = () => (dispatch) => {
  dispatch(resetAction());
};

export const setReportDay = (day) => (dispatch) => {
  dispatch(setReportDayAction(day));
};

export const setReportPeriod = (period) => (dispatch) => {
  dispatch(setReportPeriodAction(period));
};

export const setReportLimit = (key, limit) => (dispatch) => {
  dispatch(setReportLimitAction({ key, limit }));
};

export const setReportValidationFailed = (obj) => (dispatch) => {
  dispatch(setReportValidationFailedAction(obj));
};

export const departmentChange = (obj) => (dispatch) => {
  dispatch(departmentChangeAction(obj));
};

export const categoryChange = (obj) => (dispatch) => {
  dispatch(categoryChangeAction(obj));
};

export const subCategoryChange = (obj) => (dispatch) => {
  dispatch(subCategoryChangeAction(obj));
};

export const level4Change = (obj) => (dispatch) => {
  dispatch(level4ChangeAction(obj));
};

export const level5Change = (obj) => (dispatch) => {
  dispatch(level5ChangeAction({ level5: obj.level5, idx: obj.idx }));
};

export const memberChange = (obj) => (dispatch) => {
  dispatch(memberChangeAction({ member: obj.member, idx: obj.idx }));
};

export const supplierChange = (obj) => (dispatch) => {
  dispatch(supplierChangeAction({ supplier: obj.supplier, idx: obj.idx }));
};

export const businessTypeChange = (obj) => (dispatch) => {
  dispatch(businessTypeChangeAction({ businessType: obj.businessType, idx: obj.idx }));
};

export const measureChange = (obj) => (dispatch) => {
  dispatch(measureChangeAction({ measure: obj.measure || '', idx: obj.idx }));
};

export const timeframeChange = (obj) => (dispatch) => {
  dispatch(timeframeChangeAction({ timeframe: obj.timeframe || '', idx: obj.idx }));
};

export const recipientChange = (obj) => (dispatch) => {
  dispatch(recipientChangeAction({ recipients: obj.recipients || '' }));
};

export const clearReports = () => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  let url = '/route.php?c=weeklyUpdate/deleteWeeklyUpdates';
  dispatch(setWeeklyUpdatesDeletedAction(false));
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then((result) => {
      let rpts = Reports.map((rpt) => {
        dispatch(setReportValidationFailedAction({ key: rpt.reportKey, sortOrder: rpt.sortOrder, value: true, message: 'Please select values for department, timeframe and measure.' }));
        return {
          ...rpt,
          members: state.members.length === 1 ? state.members : [],
          businessTypes: state.businessTypes.length === 1 ? state.businessTypes : [],
          departments: state.allDepartments.length === 1 ? state.allDepartments : [],
          categories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 ? state.allDepartments[0].Categories : [],
          allCategories: state.allDepartments.length === 1 ? state.allDepartments[0].Categories : [],
          subCategories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories : [],
          allSubCategories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories : [],
          level4s:
            state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 && state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1
              ? state.allDepartments[0].Categories[0].SubCategories[0].Level4
              : [],
          allLevel4s: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories[0].Level4 : [],
          level5s:
            state.allDepartments.length === 1 &&
            state.allDepartments[0].Categories.length === 1 &&
            state.allDepartments[0].Categories[0].SubCategories.length === 1 &&
            state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1 &&
            state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5.length === 1
              ? state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5
              : [],
          allLevel5s:
            state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 && state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1
              ? state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5
              : [],
          isValidationFailed: true,
          message: 'Please select values for department, timeframe and measure.',
        };
      });
      dispatch(fetchUpdatesAction(rpts));
      dispatch(setWeeklyUpdatesDeletedAction(true));
    })
    .catch((err) => {
      dispatch(setWeeklyUpdatesDeletedAction(true));
    });
};

export const updateEnabledChange = (obj) => (dispatch) => {
  dispatch(updateEnabledChangeAction(obj));
  dispatch(weeklyUpdateStatusChangeAction(false));
  let url = '/route.php?c=weeklyUpdate/enable';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then((result) => {
      dispatch(weeklyUpdateStatusChangeAction(true));
      dispatch(setLoadingAction(false));
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
    });
};

export const includedChange = (id) => (dispatch) => {
  dispatch(includedChangeAction(id));
};

export const cloneReport = (id) => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  let cloned = state.reports.filter((rpt) => {
    if (rpt.sortOrder.toString() === id) {
      return rpt;
    }
  })[0];
  let reports = state.reports;
  reports.map((rpt) => {
    if (rpt.sortOrder > parseInt(id)) {
      rpt.sortOrder += 1;
      return rpt;
    }
  });
  reports.push({
    ...cloned,
    sortOrder: cloned.sortOrder + 1,
    isCloned: true,
  });
  dispatch(cloneReportAction(reports));
};

export const sortReports = (reports) => (dispatch) => {
  dispatch(sortReportsAction(reports));
};

export const deleteReport = (id) => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  const reports = state.reports.filter((rpt) => {
    if (rpt.sortOrder !== parseInt(id)) {
      return rpt;
    }
  });
  reports.map((rpt) => {
    if (rpt.sortOrder > parseInt(id)) {
      rpt.sortOrder -= 1;
      return rpt;
    }
  });
  dispatch(deleteReportAction(reports));
};

export const previewWeeklyUpdate = (data, reportName) => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  dispatch(setIsPreviewDownloadingAction(true));
  let url = `/route.php?c=weeklyUpdate/save&name=${reportName}&newName=${state.newName}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data,
  }).then((result) => {
    let url1 = `/route.php?c=taskManager/weeklyReports/preview&name=${state.newName}`;
    requestBlob({
      url: url1,
      method: 'get',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `preview-${moment().format('X')}.pdf`);
        document.body.appendChild(link);
        link.click();
        dispatch(setIsPreviewDownloadingAction(false));
      })
      .catch((err) => {
        dispatch(setIsPreviewDownloadingAction(false));
      });
  });
};

export const saveRecipients = (data) => (dispatch) => {
  dispatch(setIsUpdateSavedAction(false));
  let url = '/route.php?c=weeklyUpdate/saveCopiedContacts';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data,
  })
  .then((result) => {
    if (result.success) {
      dispatch(setIsUpdateSavedAction(true));
    } else {
      dispatch(setIsRecipientErrored(true));
    }
    dispatch(setLoadingAction(false));
  })
  .catch((err) => {
    dispatch(setLoadingAction(false));
  });
};

export const saveWeeklyUpdate = (data, reportName) => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  dispatch(setIsUpdateSavedAction(false));
  if(reportName == 'createNew') {
    reportName = 'report-' + state.reportNames.length;
  }
  let url = `/route.php?c=weeklyUpdate/save&name=${reportName}&newName=${state.newName}`;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data,
  })
    .then((result) => {
      if (result.success) {
        dispatch(setIsUpdateSavedAction(true));
        reportNamesRequest(dispatch);
      } else {
        dispatch(setIsUpdateSavedAction(false));
      }

      dispatch(setLoadingAction(false));
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
    });
};

export const setActiveReport = (name) => (dispatch) => {
    dispatch(setActiveReportNameAction(name));
}

export const getReportNames = () => (dispatch) => {
  reportNamesRequest(dispatch);
}

const reportNamesRequest = (dispatch) => {
  const url = '/route.php?c=weeklyUpdate/getNames';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then((results) => {
      let nameList = [];
      results.forEach(result => {
        if(result.name == "createNew") {
          return;
        }
        let name = result.name == "" ? 'default' : result.name; 
        nameList.push({value:  name, label: name});
      });
      nameList.push({value: "createNew", label: "Create new Key Facts Report"})
      dispatch(setReportNamesAction(nameList));
    });
}

export const createNewReport = () => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  let rpts = state.reports.map((rpt) => {
    if (rpt.cloned == "1") {
      return null;
    }
    let validationValue = false;
    let validationMsg = '';

    if (rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0))) {
      validationValue = true;
      validationMsg = 'Please select values for department, timeframe and measure.';
    }
    dispatch(setReportValidationFailedAction({ key: rpt.reportKey, sortOrder: rpt.sortOrder, value: true, message: 'Please select values for department, timeframe and measure.' }));
    return {
      ...rpt,
      members: [],
      enabled: "1",
      categoryFilters: "",
      departmentFilters: "",
      supplierFilters: "",
      subCategoryFilters: "",
      suppliers: null,
      departments: [],
      categories: [],
      allCategories: [],
      subCategories: [],
      allSubCategories: [],
      level4s: [],
      allLevel4s: [],
      level5s: [],
      isValidationFailed: validationValue,
      message: validationMsg,
    };
  });
  dispatch(setIsLoadingReportsAction(false));
  dispatch(fetchUpdatesAction(rpts));
  dispatch(setNewNameAction('report-' + state.reportNames.length));
}

export const setNewName = (value) => (dispatch) => {
  dispatch(setNewNameAction(value));
}

export const setIsNameEditable = (value) => (dispatch) => {
  dispatch(setIsNameEditableAction(value));
}

export const fetchUpdates = (name) => (dispatch) => {
  const state = store.getState().weeklyUpdate;
  let url = '/route.php?c=weeklyUpdate/getEnabledStatus';
  dispatch(setIsLoadingReportsAction(true));
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((result) => {
    dispatch(fetchUpdateEnabledAction(result.enabled));
  });
  url = `/route.php?c=weeklyUpdate/getWeeklyUpdate&name=${name}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then((result) => {
      //Should return a single report, no changes should be needed to accomodate multiple key facts reports.
      //Exclude all other systems from Business types
      if (getUserSystem() !== "caterforce") {
        result = result.filter(report => report.reportKey !== "cs_perf");
        if (getUserSystem() !== "countryRangeGroup") {
          result = result.filter(report => report.reportKey !== "bt_perf");
        }
      }
      if (result.length > 0) {
        let rpts = [];
        result.map((rpt) => {
          let name = '';
          let showLimit = true;
          switch (rpt.reportKey) {
            case 'supp_trend':
              name = 'Supplier Trend Report';
              showLimit = false;
              break;
            case 'cat_trend':
              name = 'Category Trend Report';
              showLimit = false;
              break;
            case 'top_skus':
              name = 'Product Performance';
              break;
            case 'cat_perf':
              name = 'Category Performance';
              break;
            case 'dep_perf':
              name = 'Department Performance';
              break;
            case 'sup_perf':
              name = 'Supplier Performance';
              break;
            case 'mem_perf':
              name = 'Member Performance';
              break;
            case 'bt_perf':
              name = 'Business Type Performance';
              break;
            case 'brand_perf':
              name = 'Brand Performance';
              break;
            case 'cs_perf':
              name = 'Group Supplier Performance';
              break;
          }
          let depts = [];
          let allCats = [];
          let allSubCats = [];
          let allLvl4s = [];
          let allLvl5s = [];
          if (state.allDepartments.length > 0 && rpt.departmentFilters && rpt.departmentFilters.length > 0) {
            rpt.departmentFilters.split(',').map((dpt) => {
              const opt = _.filter(state.allDepartments, (dept) => {
                if (dept.Text === dpt) {
                  return dept;
                }
              })[0];
              if (opt) {
                depts.push(opt);
              }
            });
          }

          if (depts.length > 0) {
            depts.map((dept) => {
              if (dept) {
                allCats = [...allCats, ...dept.Categories.map((cat) => cat)];
              }
            });
          }

          let cats = [];
          if (allCats.length > 0 && rpt.categoryFilters && rpt.categoryFilters.length > 0) {
            rpt.categoryFilters.split(',').map((ct) => {
              const opt = _.filter(allCats, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              if (opt) {
                cats.push(opt);
              }
            });
          }

          if (cats.length > 0) {
            cats.map((cat) => {
              if (cat) {
                allSubCats = [...allSubCats, ...cat.SubCategories.map((cat) => cat)];
              }
            });
          }

          let subCats = [];
          if (allSubCats.length > 0 && rpt.subCategoryFilters && rpt.subCategoryFilters.length > 0) {
            rpt.subCategoryFilters.split(',').map((ct) => {
              const opt = _.filter(allSubCats, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              if (opt) {
                subCats.push(opt);
              }
            });
          }

          if (subCats.length > 0) {
            subCats.map((subCat) => {
              if (subCat) {
                allLvl4s = [...allLvl4s, ...subCat.Level4.map((cat) => cat)];
              }
            });
          }

          let lvl4s = [];
          if (allLvl4s.length > 0 && rpt.level4Filters && rpt.level4Filters.length > 0) {
            rpt.level4Filters.split(',').map((ct) => {
              const opt = _.filter(allLvl4s, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              if (opt) {
                lvl4s.push(opt);
              }
            });
          }

          if (lvl4s.length > 0) {
            lvl4s.map((lvl4) => {
              if (lvl4) {
                allLvl5s = [...allLvl5s, ...lvl4.Level5.map((cat) => cat)];
              }
            });
          }

          let lvl5s = [];
          if (allLvl5s.length > 0 && rpt.level5Filters && rpt.level5Filters.length > 0) {
            rpt.level5Filters.split(',').map((ct) => {
              const opt = _.filter(allLvl5s, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              if (opt) {
                lvl5s.push(opt);
              }
            });
          }

          let supps = '';
          if (state.suppliers.length > 0 && rpt.supplierFilters && rpt.supplierFilters.length > 0) {
            rpt.supplierFilters.split(',').map((ct) => {
              const opt = _.filter(state.suppliers, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              supps = opt;
            });
          }

          let busTypes = '';
          if (state.businessTypes && state.businessTypes.length > 0 && rpt.businessTypeFilters && rpt.businessTypeFilters.length > 0) {
            rpt.businessTypeFilters.split(',').map((ct) => {
              const opt = _.filter(state.businessTypes, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              busTypes = opt;
            });
          }

          let mems = [];
          if (state.members.length > 0 && rpt.memberFilters && rpt.memberFilters.length > 0) {
            rpt.memberFilters.split(',').map((ct) => {
              const opt = _.filter(state.members, (cat) => {
                if (cat.Text === ct) {
                  return cat;
                }
              })[0];
              if (opt) {
                mems.push(opt);
              }
            });
          }

          let measure = '';

          if (state.measures.length > 0 && rpt.measure && rpt.measure.length > 0) {
            measure = _.filter(state.measures, (msr) => {
              if (msr.ID === rpt.measure) {
                return msr;
              }
            })[0];
          }

          let timeframe = '';

          if (state.timeframes.length > 0 && rpt.timeframe && rpt.timeframe.length > 0) {
            timeframe = _.filter(state.timeframes, (msr) => {
              if (msr.ID === rpt.timeframe) {
                return msr;
              }
            })[0];
          }

          let validationValue = false;
          let validationMsg = '';

          if (rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0))) {
            validationValue = true;
            validationMsg = 'Please select values for department, timeframe and measure.';
          }

          let selectedDepartments = [];
          let selectedCategories = [];
          let selectedSubCategories = [];
          let selectedLevel4s = [];
          let selectedLevel5s = [];

          if (state.allDepartments.length === 1) {
            selectedDepartments = state.allDepartments;
            selectedCategories = state.allDepartments[0].Categories;
            if (selectedCategories.length === 1 && selectedCategories[0].SubCategories.length === 1) {
              allCats = state.allDepartments[0].Categories;
              selectedSubCategories = selectedCategories[0].SubCategories;
              allSubCats = selectedCategories[0].SubCategories;
              if (selectedSubCategories.length === 1 && selectedSubCategories[0].Level4.length === 1) {
                selectedLevel4s = selectedSubCategories[0].Level4;
                allLvl4s = selectedSubCategories[0].Level4;
                if (selectedLevel4s.length === 1 && selectedLevel4s[0].Level5.length === 1) {
                  selectedLevel5s = selectedLevel4s[0].Level5;
                  allLvl5s = selectedLevel4s[0].Level5;
                } else {
                  allLvl5s = selectedLevel4s.flatMap((cat) => cat.Level5);
                }
              } else {
                allLvl4s = selectedSubCategories.flatMap((cat) => cat.Level4);
              }
            } else {
              allSubCats = selectedCategories.flatMap((cat) => cat.SubCategories);
            }
          }

          if (depts.length > 0) {
            selectedDepartments = depts.map((dept) => ({ ...dept, Categories: dept.Categories }));
            allCats = selectedDepartments.flatMap((dept) => dept.Categories);
          }

          if (cats.length > 0) {
            selectedCategories = cats.map((cat) => ({ ...cat, SubCategories: cat.SubCategories }));
          }

          if (subCats.length > 0) {
            selectedSubCategories = subCats.map((subCat) => ({ ...subCat, Level4: subCat.Level4 }));
          }

          if (lvl4s.length > 0) {
            selectedLevel4s = lvl4s.map((lvl4) => ({ ...lvl4, Level5: lvl4.Level5 }));
          }

          if (lvl5s.length > 0) {
            selectedLevel5s = lvl5s;
          }

          const rptAdd = {
            ...rpt,
            name,
            imageUrl: ExampleUrl,
            departments: selectedDepartments,
            categories: selectedCategories,
            subCategories: selectedSubCategories,
            level4s: selectedLevel4s,
            level5s: selectedLevel5s,
            suppliers: supps,
            businessTypes: busTypes,
            members: mems.length > 0 ? mems : [],
            allCategories: allCats,
            allSubCategories: allSubCats,
            allLevel4s: allLvl4s,
            allLevel5s: allLvl5s,
            isIncluded: rpt.enabled === '1' ? true : false,
            isCloned: rpt.cloned === '1' ? true : false,
            disableClone: true,
            sortOrder: parseInt(rpt.sortOrder),
            showInterval: rpt.reportKey === 'supp_trend' || rpt.reportKey === 'cat_trend' ? false : true,
            limit: rpt.limit && rpt.limit.toString().length > 0 ? { label: rpt.limit, value: parseInt(rpt.limit) } : { label: '10', value: 10 },
            day: rpt.day && rpt.day.length > 0 ? { label: rpt.day, value: rpt.day } : '',
            showLimit,
            isValidationFailed: validationValue,
            message: validationMsg,
          };
          rpts.push(rptAdd);
        });
        dispatch(setNewNameAction(name));
        dispatch(setIsLoadingReportsAction(false));
        dispatch(fetchUpdatesAction(rpts));
      } else {
        let rpts = state.reports.map((rpt) => {
          let validationValue = false;
          let validationMsg = '';

          if (rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0))) {
            validationValue = true;
            validationMsg = 'Please select values for department, timeframe and measure.';
          }
          dispatch(setReportValidationFailedAction({ key: rpt.reportKey, sortOrder: rpt.sortOrder, value: true, message: 'Please select values for department, timeframe and measure.' }));
          return {
            ...rpt,
            members: state.members.length === 1 ? state.members : [],
            departments: state.allDepartments.length === 1 ? state.allDepartments : [],
            categories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 ? state.allDepartments[0].Categories : [],
            allCategories: state.allDepartments.length === 1 ? state.allDepartments[0].Categories : [],
            subCategories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories : [],
            allSubCategories: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories : [],
            level4s:
              state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 && state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1
                ? state.allDepartments[0].Categories[0].SubCategories[0].Level4
                : [],
            allLevel4s: state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 ? state.allDepartments[0].Categories[0].SubCategories[0].Level4 : [],
            level5s:
              state.allDepartments.length === 1 &&
              state.allDepartments[0].Categories.length === 1 &&
              state.allDepartments[0].Categories[0].SubCategories.length === 1 &&
              state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1 &&
              state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5.length === 1
                ? state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5
                : [],
            allLevel5s:
              state.allDepartments.length === 1 && state.allDepartments[0].Categories.length === 1 && state.allDepartments[0].Categories[0].SubCategories.length === 1 && state.allDepartments[0].Categories[0].SubCategories[0].Level4.length === 1
                ? state.allDepartments[0].Categories[0].SubCategories[0].Level4[0].Level5
                : [],
            isValidationFailed: validationValue,
            message: validationMsg,
          };
        });
        dispatch(setIsLoadingReportsAction(false));
        dispatch(fetchUpdatesAction(rpts));
      }
    })
    .catch((err) => {
      dispatch(setIsLoadingReportsAction(false));
    });
};

export const fetchDefaultData = () => (dispatch) => {
  let url = '/route.php?c=category/getHierarchy';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then((result) => {
      url = '/route.php?c=wholesaler/getAll';
      request({
        url: url,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: getUserSystem(),
        },
      })
        .then((members) => {
          let wholesalers = [];
          if (members.length > 2) {
            members.map((mem) => {
              wholesalers.push({ ID: mem.WholesalerNo ?? mem.ActualName, Text: mem.ActualName });
            });
          } else if (members.length === 2) {
            _.filter(members, (mem) => mem.WholesalerNo !== 0).map((mem) => {
              wholesalers.push({ ID: mem.WholesalerNo ?? mem.ActualName, Text: mem.ActualName });
            });
          }
          url = '/route.php?c=supplier/getAll';
          request({
            url: url,
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: getToken(),
              UserId: getUserId(),
              System: getUserSystem(),
            },
          })
          .then((suppliers) => {
            let supplierData = [];
            suppliers.map((sup) => {
              supplierData.push({ ID: sup.SupplierName, Text: sup.SupplierName });
            });
            if (getUserSystem() === 'countryRangeGroup' ) {
              url = '/route.php?c=customer/getAllBusinessTypes';
              request({
                url: url,
                method: 'get',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: getToken(),
                  UserId: getUserId(),
                  System: getUserSystem(),
                },
              })
              .then((businessTypes) => {
                let businessTypeData = [];
                businessTypes.map((bus) => {
                  businessTypeData.push({ ID: bus, Text: bus });
                });

                url = '/route.php?c=weeklyUpdate/getCopiedRecipients';
                request({
                  url: url,
                  method: 'get',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: getToken(),
                    UserId: getUserId(),
                    System: getUserSystem(),
                  },
                })
                .then((recipients) => {
                  dispatch(setLoadingAction(false));
                  dispatch(
                    fetchDefaultDataAction({
                      departments: result.Departments,
                      suppliers: supplierData,
                      members: wholesalers,
                      businessTypes: businessTypeData,
                      recipients: recipients.copied_recipients,
                    }),
                  );
                });
              })
              .catch((err) => {
                dispatch(setLoadingAction(false));
              });
            } else {
              url = '/route.php?c=weeklyUpdate/getCopiedRecipients';
              request({
                url: url,
                method: 'get',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: getToken(),
                  UserId: getUserId(),
                  System: getUserSystem(),
                },
              })
              .then((recipients) => {
                dispatch(setLoadingAction(false));
                dispatch(
                  fetchDefaultDataAction({
                    departments: result.Departments,
                    suppliers: supplierData,
                    members: wholesalers,
                    recipients: recipients.copied_recipients,
                  }),
                );
              });
            }
          })
          .catch((err) => {
            dispatch(setLoadingAction(false));
          });
        })
        .catch((err) => {
          dispatch(setLoadingAction(false));
        });
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
    });
};

export default handleActions(
  {
    [RESET]: (state, { payload }) => ({ 
      ...state, 
      reports: Reports, 
      isLoading: false, 
      isLoadingReports: false, 
      reportNames: [], 
      activeReportName: {value: 'default', label: 'default'}, 
      isPreviewDownloading: false, 
      isUpdateSaved: false, 
      isRecipientErrored: false, 
      isNameEditable: false,
      isUpdateEnabled: false, 
      isWeeklyUpdateStatusChanged: false }),
    [SET_WEEKLY_UPDATES_DELETED]: (state, { payload }) => ({ ...state, isWeeklyUpdatesDeleted: payload }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_LOADING_REPORTS]: (state, { payload }) => ({ ...state, isLoadingReports: payload }),
    [SET_NEW_NAME]: (state, { payload }) => ({ ...state, newName: payload}),
    [SET_NAME_EDITABLE]: (state, { payload }) => ({ ...state, isNameEditable: payload }),
    [SET_REPORT_NAMES]: (state, { payload }) => ({ ...state, reportNames: payload }),
    [SET_ACTIVE_REPORT_NAME]: (state, { payload }) => ({ ...state, activeReportName: payload }),
    [SET_IS_PREVIEW_DOWNLOADING]: (state, { payload }) => ({ ...state, isPreviewDownloading: payload }),
    [SET_REPORT_DAY]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        rpt.day = payload?.value;
        return rpt;
      }),
    }),
    [SET_REPORT_PERIOD]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        rpt.period = payload?.value;
        return rpt;
      }),
    }),
    [SET_REPORT_LIMIT]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.reportKey === payload.key) {
          rpt.limit = payload.limit;
        }
        return rpt;
      }),
    }),
    [SORT_REPORTS]: (state, { payload }) => ({ ...state, reports: payload }),
    [SET_IS_UPDATE_SAVED]: (state, { payload }) => ({ ...state, isUpdateSaved: payload }),
    [SET_IS_RECIPIENT_ERRORED]: (state, { payload }) => ({ ...state, isRecipientErrored: payload }),
    [SET_REPORT_VALIDATION_FAILED]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.sortOrder && rpt.reportKey === payload.key) {
          return Object.assign({}, rpt, { isValidationFailed: payload.value, message: !payload.value ? '' : payload.message });
        }
        return rpt;
      }),
    }),
    [DEPARTMENT_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, {
            departments: payload.department,
            allCategories: payload.categories,
            disableClone: false,
            isValidationFailed: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? true : false,
            message: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? 'Please select values for department, timeframe and measure.' : '',
          });
        }
        return rpt;
      }),
    }),
    [CATEGORY_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { categories: payload.category, allSubCategories: payload.subCategories, disableClone: false });
        }
        return rpt;
      }),
    }),
    [SUB_CATEGORY_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { subCategories: payload.subCategory, allLevel4s: payload.level4s, disableClone: false });
        }
        return rpt;
      }),
    }),
    [LEVEL4_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { level4s: payload.level4, allLevel5s: payload.level5s, disableClone: false });
        }
        return rpt;
      }),
    }),
    [LEVEL5_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { level5s: payload.level5, disableClone: false });
        }
        return rpt;
      }),
    }),
    [MEMBER_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { members: payload.member, disableClone: false });
        }
        return rpt;
      }),
    }),
    [SUPPLIER_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, {
            suppliers: payload.supplier,
            disableClone: false,
            isValidationFailed: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? true : false,
            message: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? 'Please select values for department, timeframe and measure.' : '',
          });
        }
        return rpt;
      }),
    }),
    [BUSINESS_TYPE_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, { businessTypes: payload.businessType, disableClone: false });
        }
        return rpt;
      }),
    }),
    [MEASURE_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, {
            measure: payload.measure ? payload.measure.ID : '',
            disableClone: false,
            isValidationFailed: rpt.isIncluded && (payload.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? true : false,
            message: rpt.isIncluded && (payload.measure.length === 0 || (rpt.showInterval && rpt.timeframe.length === 0)) ? 'Please select values for department, timeframe and measure.' : '',
          });
        }
        return rpt;
      }),
    }),
    [TIMEFRAME_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder === payload.idx) {
          return Object.assign({}, rpt, {
            timeframe: payload.timeframe ? payload.timeframe.ID : '',
            disableClone: false,
            isValidationFailed: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && payload.timeframe.length === 0)) ? true : false,
            message: rpt.isIncluded && (rpt.measure.length === 0 || (rpt.showInterval && payload.timeframe.length === 0)) ? 'Please select values for department, timeframe and measure.' : '',
          });
        }
        return rpt;
      }),
    }),
    [RECIPIENT_CHANGE]: (state, { payload }) => ({
      ...state,
      recipients: payload.recipients,
    }),
    [UPDATE_ENABLED_CHANGE]: (state, { payload }) => ({ ...state, isUpdateEnabled: payload }),
    [INCLUDED_CHANGE]: (state, { payload }) => ({
      ...state,
      reports: state.reports.map((rpt) => {
        if (rpt.sortOrder.toString() === payload) {
          return Object.assign({}, rpt, { isIncluded: !rpt.isIncluded, disableClone: false });
        }
        return rpt;
      }),
    }),
    [FETCH_UPDATES]: (state, { payload }) => ({
      ...state,
      reports: payload.map((rpt) => {
        return Object.assign({}, rpt);
      }),
    }),
    [CLONE_REPORT]: (state, { payload }) => ({
      ...state,
      reports: payload.map((rpt) => {
        return Object.assign({}, rpt);
      }),
    }),
    [DELETE_REPORT]: (state, { payload }) => ({
      ...state,
      reports: payload.map((rpt) => {
        return Object.assign({}, rpt);
      }),
    }),
    [FETCH_UPDATE_ENABLED]: (state, { payload }) => ({ ...state, isUpdateEnabled: payload }),
    [WEEKLY_UPDATE_STATUS_CHANGE]: (state, { payload }) => ({ ...state, isWeeklyUpdateStatusChanged: payload }),
    [FETCH_DEFAULT_DATA]: (state, { payload }) => ({ ...state, allDepartments: payload.departments, suppliers: payload.suppliers, members: payload.members, businessTypes: payload.businessTypes, recipients: payload.recipients }),
  },
  initialState,
);
