import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import Msg from '../components/ToastExportToPDF';
import LaunchIcon from '@material-ui/icons/Launch';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DataTypes from '../components/filters/DataTypes/DataTypes';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import { fetchAccountNames, setAccountName } from '../redux/reducers/filters';

import {
  fetchCategories,
  setDepartment,
  setPmp,
  setRegion,
  setFascia,
  setCategory,
  setSubCategory,
  setProduct,
  setMember,
  setDataType,
  setPurchase,
  setLevel4,
  setLevel5,
  exportToPDF,
  resetReport,
  setDepot,
  loadReport,
  setCoreRange,
  setMemberType,
  setRetailType,
  setGroup,
} from '../redux/reducers/skuTrackerReport';
import AccountNames from '../components/filters/AccountNames/AccountNames';
import { setAuthUser } from '../redux/reducers/user';
import { exportTableToCSV, getRandomNumber } from '../../helpers/helpers';
import Select from 'react-select';
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import LineGraphDualAxis from '../components/LineGraphDualAxis';
import {
  getUserSystem,
  getUserRoles,
  hasSalesInPermissions,
  hasRetailPermissions,
  membersCheck,
  hideFor,
  hasPermission,
} from '../../helpers/util';
import CustomCountSelect from '../components/CustomCountSelect';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ExportIcon from '../../assets/images/export-icon.svg';
import TableSimpleSku from '../components/TableSimpleSku';
import Loading from '../components/Loading';

import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import { hotjar } from 'react-hotjar';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import ReportContainer from '../components/ReportContainer';
import { getUserData } from '../../helpers/user';

const system = getUserSystem();
ReactModal.setAppElement('.alchemy_app');
const SkuTrackerReport = ({
  data,
  hideProducts,
  departments,
  department,
  categories,
  category,
  subCategory,
  subCategories,
  products,
  selectedProducts,
  members,
  selectedMembers,
  level4s,
  level4,
  level5s,
  level5,
  region,
  regions,
  fascia,
  fascias,
  onRegionChange,
  onFasciaChange,
  onDepartmentChange,
  onCategoryChange,
  onExportData,
  onSubCategoryChange,
  transformOptions,
  skuPerformanceDataGraph,
  skuPerformanceDataTable,
  onProductChange,
  onMemberChange,
  isLoading,
  onSelect,
  onLevel4Change,
  onLevel5Change,
  coreRange,
  coreRanges,
  onCoreRangeChange,
  memberType,
  memberTypes,
  onMemberTypeChange,
  retailType,
  retailTypes,
  onRetailTypeChange,
  depots,
  selectedDepots,
  onDepotChange,
  toggleExportMenu,
  onRefreshReport,
  onPmpChange,
  pmp,
  pmpList,
  history,
  onResetReport,
  onGroupChange,
  groups,
  selectedGroup,
  purchases,
  purchase,
  onPurchaseChange,
  accountName,
  accountNames,
  onAccountNameChange,
  resolveDepots,
  match,
  dataType,
  dataTypes,
  onDataTypeChange,
  setCategoryStructure,
  setDisableCategoryStructure
}) => {
  const theme = useContext(ThemeContext);
  return (
    <motion.div
      key={'213214151515'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
      />
      <div className="sub-menu-border bg-white">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
              <div className="sub-menu__left">
                <h2 className="text-uppercase">SKU TRACKER</h2>
                {hideFor('caterforce', 'countryRangeGroup', 'suntory', 'cotswold') && (
                    <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                      <Select
                        placeholder="PMPList"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`wd-100 pc-dropdown small-dropdown c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="pmp"
                        id="pmp"
                        value={pmp}
                        onChange={onPmpChange}
                        options={pmpList}
                        valueKey="ID"
                        labelKey="Text"
                        clearable={false}
                        searchable={false}
                      />
                    </div>
                  )}
                {hasSalesInPermissions(match.params.subpage) && (
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <Select
                      placeholder="Sales In"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`wd-100 small-dropdown c-select-header ${
                        getUserSystem() === 'unitas'
                          ? 'c-select-header-row--unitas'
                          : 'c-select-header-row--others'
                      }`}
                      name="purchases"
                      id="purchases"
                      value={purchase}
                      onChange={onPurchaseChange}
                      options={purchases}
                      valueKey="ID"
                      labelKey="Text"
                      clearable={false}
                      searchable={false}
                    />
                  </div>
                )}
                {hasRetailPermissions(match.params.subpage) && hideFor("cjlangRetail") && (
                  <DataTypes
                    dataType={dataType}
                    onDataTypeChange={onDataTypeChange}
                    dataTypes={dataTypes}
                  />
                )}
                {accountNames && accountNames.length > 0 && (
                  <AccountNames
                    value={accountName}
                    options={accountNames}
                    onChange={onAccountNameChange}
                  />
                )}
              </div>
              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <button
                      onClick={() => {
                        onResetReport();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      Reset
                      <RotateLeftIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        const win = window.open(
                          history.location.pathname,
                          '_blank',
                        );
                        win.focus();
                      }}
                      className="btn alchemy-btn--others d-flex mr-2">
                      New tab
                      <LaunchIcon
                        style={{
                          height: '1rem',
                          width: '1rem',
                          marginLeft: '2px',
                          marginTop: '1px',
                        }}
                      />
                    </button>
                  </div>
                  <div>
                    <Dropdown
                      isOpen={data.isExportMenuOpen}
                      toggle={() => toggleExportMenu()}>
                      <DropdownToggle
                        className={`dropddown-toggle-btn mr-2 alchemy-btn--others`}>
                        Export <img src={ExportIcon} alt="export" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('csv');
                          }}>
                          CSV
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('pdf');
                          }}>
                          PDF
                        </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div>
                    <button
                      onClick={onRefreshReport}
                      disabled={data.isRefreshDisabled}
                      className={`btn alchemy-btn--others mr-2`}>
                      Refresh <i className="fa fa-refresh" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container sub-menu-bottom">
          <div className="row">
            <div className="col-12">
              <div className="nam-dash nam-dash-select-row">
                <div className="form-row">
                  {departments.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="department"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="department"
                        placeholder="Department"
                        value={department ? department.ID : ''}
                        onChange={onDepartmentChange}
                        options={departments}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {categories.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Category"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="category"
                        id="category"
                        value={category ? category.ID : ''}
                        onChange={onCategoryChange}
                        options={categories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {subCategories.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="subcategory"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="subcategory"
                        placeholder="SubCategory"
                        value={subCategory ? subCategory.ID : ''}
                        onChange={onSubCategoryChange}
                        options={subCategories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {level4s.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Level4"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level4"
                        id="level4"
                        value={level4 ? level4.ID : ''}
                        onChange={onLevel4Change}
                        options={level4s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {level5s.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Level5"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="level5"
                        id="level5"
                        value={level5 ? level5.ID : ''}
                        onChange={onLevel5Change}
                        options={level5s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}

                  {/* Group dropdown for demo system, used to filter members on demo system only */}
                  {/* Removed From Sku Tracket Report */}
                  {/* {(getUserSystem() === 'demo' ||
                    getUserSystem() === 'caterforce' ||
                    getUserSystem() === 'countryRangeGroup') &&
                    departments &&
                    departments.length > 0 &&
                    groups &&
                    groups.length > 0 && (
                      <div className="form-group col-md-5 col-lg-3">
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onGroupChange(ev)}
                          name="group"
                          id="group"
                          isMulti={true}
                          value={selectedGroup}
                          options={groups}
                          type={
                            getUserSystem() === 'caterforce'
                              ? `Core Supplier`
                              : `Group`
                          }
                          types={
                            getUserSystem() === 'caterforce'
                              ? `Core Suppliers`
                              : `Groups`
                          }
                          placeholder={
                            getUserSystem() === 'caterforce'
                              ? `Core Supplier`
                              : `Group`
                          }
                        />
                      </div>
                    )} */}
                  {/* Member dropdown for standard systems, hidden for demo system */}
                  {theme.name !== 'cjlang' &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 0 &&
                  getUserSystem() != 'demo' &&
                  members &&
                  membersCheck(members) &&
                  system !== 'parfetts' &&
                  !hideProducts ? (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name={getUserSystem() == 'suntory' ? "group" : "member"}
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                                isDisabled: parseInt(mem.WholesalerNo) === 9999,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  ) : (
                    ''
                  )}

                  {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                  {getUserSystem() == 'demo' &&
                    getUserRoles().filter(
                      role => role.Title === 'role_view_demo_group_performance',
                    ).length === 1 &&
                    selectedGroup &&
                    selectedGroup.length > 0 &&
                    getUserSystem() !== 'parfetts' && (
                      <div className="form-group col-md-5 col-lg-3">
                        <CustomCountSelect
                          className={`Select c-select-header c-select-header-row--others`}
                          onChangeCallback={ev => onMemberChange(ev)}
                          name={getUserSystem() == 'suntory' ? "group" : "member"}
                          id="member"
                          isMulti={true}
                          value={selectedMembers}
                          options={
                            members && members.length > 0
                              ? members.map(mem => ({
                                  Text: mem.ActualName,
                                  ID: mem.WholesalerNo ?? mem.ActualName,
                                  isDisabled:
                                    parseInt(mem.WholesalerNo) === 9999,
                                }))
                              : []
                          }
                          type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                          types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                          placeholder="Member"
                        />
                      </div>
                    )}

                  {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onDepotChange(ev)}
                        name="depot"
                        id="depot"
                        isMulti={true}
                        placeholder="Depot"
                        value={selectedDepots}
                        options={resolveDepots(depots)}
                        type="Depot"
                        types="Depots"
                        />
                    </div>
                  )}
                  {(department.ID || category.ID) &&
                  products &&
                  products.length > 0 ? (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Product"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header ${
                          getUserSystem() === 'unitas'
                            ? 'c-select-header-row--unitas'
                            : 'c-select-header-row--others'
                        }`}
                        name="product"
                        id="product"
                        multi
                        value={selectedProducts}
                        onChange={onProductChange}
                        options={products}
                        valueKey="WVProdCode"
                        labelKey="WVProdDesc"
                        style={{ minWidth: '175px' }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {regions && regions.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Region"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="region"
                        id="region"
                        value={region}
                        onChange={onRegionChange}
                        options={regions}
                        valueKey="value"
                        labelKey="value"
                      />
                    </div>
                )}
                {fascias && fascias.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Fascia"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="fascia"
                      id="fascia"
                      value={fascia}
                      onChange={onFasciaChange}
                      options={fascias}
                      valueKey="value"
                      labelKey="value"
                    />
                    </div>
                )}
                {getUserSystem() == 'united' || getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="CoreRange"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="CoreRange"
                        placeholder="Core Range"
                        value={coreRange}
                        onChange={onCoreRangeChange}
                        options={coreRanges}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="MemberType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="MemberType"
                        placeholder="Member Type"
                        value={memberType}
                        onChange={onMemberTypeChange}
                        options={memberTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {dataType && dataType.Text === 'Retail' && hasPermission(
                      `role_view_${getUserSystem().toLowerCase()}_independent_data`,
                    ) && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="RetailType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="RetailType"
                        placeholder="Retail Type"
                        value={retailType}
                        onChange={onRetailTypeChange}
                        options={retailTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportContainer>
        <div className="container pt-3">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="nam-dash nam-dash-table">
                <div>{isLoading && <Loading />}</div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}>
                  <LineGraphDualAxis data={skuPerformanceDataGraph} />
                </motion.div>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div>{isLoading && <Loading />}</div>
              {isLoading === false ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ type: 'spring', duration: 0.5 }}>
                  <TableSimpleSku
                    id="sku_table"
                    data={skuPerformanceDataTable}
                    className="c-objects-wrap--sticky"
                    tableClassName="c-table c-table--standard"
                  />
                </motion.div>
              ) : (
                ''
              )}{' '}
            </div>
          </div>
        </div>
      </ReportContainer>
    </motion.div>
  );
};
function mapStateToProps(state) {
  const data = {
    hideProducts: state.skuTrackerReport.hideProducts,
    departments: state.skuTrackerReport.departments,
    department: state.skuTrackerReport.department,
    categories: state.skuTrackerReport.categories,
    subCategories: state.skuTrackerReport.subCategories,
    category: state.skuTrackerReport.category,
    subCategory: state.skuTrackerReport.subCategory,
    products: state.skuTrackerReport.products,
    selectedProducts: state.skuTrackerReport.selectedProducts,
    members: state.skuTrackerReport.members,
    selectedMembers: state.skuTrackerReport.selectedMembers,
    level4s: state.skuTrackerReport.level4s,
    level4: state.skuTrackerReport.level4,
    level5s: state.skuTrackerReport.level5s,
    level5: state.skuTrackerReport.level5,
    region: state.skuTrackerReport.region,
    regions: state.skuTrackerReport.regions,
    fascia: state.skuTrackerReport.fascia,
    fascias: state.skuTrackerReport.fascias,
    coreRange: state.skuTrackerReport.coreRange,
    coreRanges: state.skuTrackerReport.coreRanges,
    memberType: state.skuTrackerReport.memberType,
    memberTypes: state.skuTrackerReport.memberTypes,
    retailType: state.skuTrackerReport.retailType,
    retailTypes: state.skuTrackerReport.retailTypes,
    skuPerformanceDataGraph: state.skuTrackerReport.skuPerformanceDataGraph,
    skuPerformanceDataTable: state.skuTrackerReport.skuPerformanceDataTable,
    accountNames: state.filters.accountNames,
    accountName: state.filters.accountName,
    isExporting: state.skuTrackerReport.isExporting,
    isExported: state.skuTrackerReport.isExported,
    pdfURL: state.skuTrackerReport.pdfURL,
    isLoading: state.skuTrackerReport.isLoading,
    depots: state.skuTrackerReport.depots,
    selectedDepots: state.skuTrackerReport.selectedDepots,
    authUser: state.user.authUser,
    pmpList: state.skuTrackerReport.pmpList,
    pmp: state.skuTrackerReport.pmp,
    groups: state.skuTrackerReport.groups,
    selectedGroup: state.skuTrackerReport.selectedGroup,
    purchases: state.skuTrackerReport.purchases,
    purchase: state.skuTrackerReport.purchase,
    dataType: state.skuTrackerReport.dataType,
    dataTypes: state.skuTrackerReport.dataTypes,
    isOwnCategoryStructure: state.app.categoryStructure,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    setDepartment,
    setCategory,
    setSubCategory,
    setLevel4,
    setLevel5,
    setProduct,
    setAccountName,
    fetchAccountNames,
    setMember,
    exportToPDF,
    resetReport,
    setAuthUser,
    setDepot,
    loadReport,
    setPmp,
    setRegion,
    setFascia,
    setGroup,
    setPurchase,
    setDataType,
    setCoreRange,
    setMemberType,
    setRetailType,
    setCategoryStructure,
    setDisableCategoryStructure
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetReport();
      if (getUserSystem() == "cjlangRetail") {
        this.props.setDataType({ ID: 1, Text: 'Retail' });
      }
      this.props.fetchCategories();
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
      this.props.fetchAccountNames();
      if (this.props.authUser.userId.length === 0) {
        this.props.setAuthUser();
      }
    },
    componentDidUpdate(nextProps) {
      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.fetchCategories();
      }
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report.Please wait...');
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'SKU Tracker',
            });
          }, 1000);
        }, 1000);
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} type="performance" />, {
          autoClose: false,
        });
      }
      if (
        nextProps.categories &&
        this.props.categories &&
        nextProps.categories.length !== this.props.categories.length &&
        this.props.categories.length > 0
      ) {
        const category = params.get('category');
        if (category) {
          this.props.setCategory(category);
        }
      }
      if (
        this.props.category.ID &&
        nextProps.category.ID !== this.props.category.ID
      ) {
        const subCategory = params.get('subcategory');
        if (subCategory) {
          this.props.setSubCategory(subCategory);
        }
      }
      if (
        this.props.subCategory.ID &&
        nextProps.subCategory.ID !== this.props.subCategory.ID
      ) {
        const level4 = params.get('level4');
        if (level4) {
          this.props.setLevel4(level4);
        }
      }
      if (
        this.props.level4.ID &&
        nextProps.level4.ID !== this.props.level4.ID
      ) {
        const level5 = params.get('level5');
        if (level5) {
          this.props.setLevel5(level5);
        }
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onProductChange:
      ({ data, handleData, setProduct }) =>
      opt => {
        setProduct(opt);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPmpChange:
      ({ data, handleData, setPmp }) =>
      opt => {
        if (opt) {
          setPmp(opt.ID);
        } else {
          setPmp('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRegionChange:
      ({ data, handleData, setRegion }) =>
      selectedOption => {
        if (selectedOption === null) {
          setRegion('');
        } else {
          setRegion(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onFasciaChange:
      ({ data, handleData, setFascia }) =>
      selectedOption => {
        if (selectedOption === null) {
          setFascia('');
        } else {
          setFascia(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAccountNameChange:
      ({ data, handleData, setAccountName }) =>
      opt => {
        if (opt) {
          setAccountName(opt);
        } else {
          setAccountName([]);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    resolveDepots: () => depots => {
      depots.forEach((depot, index) => {
        depot.Text = depot.DepotName;
        depot.ID = index;
      });
      return depots;
    },
    onExportData: props => type => {
      if (type === 'csv') {
        exportTableToCSV(
          $('#sku_table'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
      }
      if (type === 'pdf') {
        let fields = ``;
        if (props.department.Text) {
          fields +=
            `DEPARTMENT: ` + props.department.Text.toUpperCase() + `<br/>`;
        }
        if (props.category.Text) {
          fields += `CATEGORY: ` + props.category.Text.toUpperCase() + `<br/>`;
        }
        if (props.subCategory.Text) {
          fields +=
            `SUB CATEGORY: ` + props.subCategory.Text.toUpperCase() + `<br/>`;
        }
        if (props.level4.Text) {
          fields += `LEVEL4: ` + props.level4.Text.toUpperCase() + `<br/>`;
        }
        if (props.selectedMembers.length > 0) {
          fields +=
            `MEMBERS: ` +
            props.selectedMembers.map(member => member.ActualName) +
            `<br/>`;
        }
        if (props.selectedProducts.length > 0) {
          fields +=
            `PRODUCTS: ` +
            props.selectedProducts.map(prod => prod.WVProdDesc) +
            `<br/>`;
        }
        if (props.selectedDepots?.length > 0) {
          fields +=
            `<br/>DEPOT: ` + props.selectedDepots.map(depot => depot.DepotName);
        }
        let selectedPmp = props.pmpList.find(pmp => pmp.ID === props.pmp);
        fields += `<br/>${selectedPmp.Text.toUpperCase()}: ${selectedPmp.ID}`;
        let htmlString = `<html>
                  <head>
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                    <style>
                       .o-canvas__body,h1,p{font-family:"Gotham Light",Helvetica,Verdana;font-weight:100}tbody,tr{border-color:inherit}@font-face{font-family:"Gotham Light";src:url(http://alchemy.todays.co.uk/assets/fonts/gothamlight-e8bf7.woff2) format("woff2"),url(/assets/fonts/gothamlight-66252.woff) format("woff");font-weight:100;font-style:normal;font-display:fallback}@font-face{font-family:"Gotham Book";src:url(http://alchemy.todays.co.uk/assets/fonts/gothambook-a315e.woff2) format("woff2"),url(/assets/fonts/gothambook-57f47.woff) format("woff");font-weight:600;font-style:normal;font-display:fallback}p{font-size:.7em}h1{font-size:1em}table td,table th{font-family:Arial}.o-table{width:95%;margin:0 auto}table{border-spacing:0;border-collapse:separate;font-size:.58125em}table th{padding:.8em;vertical-align:top}tr{display:table-row;vertical-align:inherit}tbody{display:table-row-group;vertical-align:middle}table td{-webkit-font-smoothing:antialiased;border-bottom:1px solid #e0e8ec;height:2em;padding:.5em}.o-table .u-tac{padding-left:.8em}.u-bg--brand{background-color:#bf73ab}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(2){background-color:#fff;border-bottom:none;border-right:0;font-weight:900}.o-product-performance--product-performance tbody tr:last-of-type td:nth-child(5){background-color:#fff;font-family:Arial;border-bottom:none;color:#384044;font-size:inherit;font-weight:900}.o-product-performance--product-performance .u-bg--brand,.o-product-performance--product-performance .u-bg--brand-dst{font-size:1.125em;text-align:center}.fa{display:block}.u-bg--brand-dst{background-color:#5a2f50}.u-bg--brand-d{background-color:#8c527e;text-align:center}[class*=u-bg--brand]{color:#fff}.o-product-performance--product-performance tbody td{height:2em;padding:.5em}
                    </style>
                  </head>
                  <body style="margin:0px;padding:0px;">
                      <h1 style="float:left;font-weight: 100;margin-left:10px">SKU TRACKER</h1><h4>${$(
                        '.c-paragraph--seconday',
                      ).text()}</h4>
                      <p style="position:absolute;left:10px;top:20px;">${moment().format(
                        'MM/DD/YYYY',
                      )}</p>
                      <div style="float:right;-webkit-margin-before: 5px;-webkit-margin-after: 0.67em;-webkit-margin-start: 0px;-webkit-margin-end: 10px;">
                       <p>${fields}</p>
                      </div>
                      <div style="width:100%;text-align:center"><img src="${document
                        .getElementById('canvas')
                        .toDataURL(
                          'image/png',
                        )}" style="height:450px;top:50px;"/></div>
                      <div style="">${$('#sku-table').html()}</div>
                  </body>
              </html>`;
        props.exportToPDF(htmlString);
      }
    },
    onDepartmentChange:
      ({ data, handleData, setDepartment }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDepartment(0);
        } else {
          setDepartment(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCategory(0);
        } else {
          setCategory(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel5(0);
        } else {
          setLevel5(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4 }) =>
      selectedOption => {
        if (selectedOption === null) {
          setLevel4(0);
        } else {
          setLevel4(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDataTypeChange:
      ({ setDataType, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDataType('');
        } else {
          setDataType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, setCategoryStructure, setDisableCategoryStructure }) =>
      selectedOption => {
        if (members.length > 2) {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
        if (getUserSystem() === 'demo') {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
        if (getUserSystem() == "caterforce") {
          if (selectedOption?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({ data, handleData, setSubCategory }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSubCategory(0);
        } else {
          setSubCategory(selectedOption.ID);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      selectedOption => {
        setDepot(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCoreRangeChange:
      ({ data, handleData, setCoreRange }) =>
      opt => {
        if (opt) {
          setCoreRange(opt);
        } else {
          setCoreRange('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberTypeChange:
      ({ data, handleData, setMemberType }) =>
      opt => {
        if (opt) {
          setMemberType(opt);
        } else {
          setMemberType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRetailTypeChange:
      ({ data, handleData, setRetailType }) =>
      opt => {
        if (opt) {
          setRetailType(opt);
        } else {
          setRetailType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onResetReport: props => () => {
      props.resetReport();
      props.fetchCategories();
      if (props.authUser.userId.length === 0) {
        props.setAuthUser();
      }
    },
  }),
)(SkuTrackerReport);
