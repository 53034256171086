import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Collapsible from 'react-collapsible';
import { fetchCampaigns, setViewCampaignFilter, fetchCampaignsForSearch, selectResponse, downloadAll, downloadSelected, resetCampaigns, fetchResponsesForCampaign, fetchCampaignByID, fetchDistributionLists } from '../../redux/reducers/psa';

const tableClass = ['o-table'];

const ViewCampaigns = ({
  data,
  campaign,
  campaigns,
  filterCampaigns,
  filterActivities,
  filterRecipientGroups,
  distributionLists,
  selectedResponses,
  onAddNewDistributionList,
  onSelectAllChecked,
  onDownloadAllClick,
  onDownloadSelectedClick,
  onResponseChecked,
  onCampaignChange,
  onActivityChange,
  onRecipientGroupChange,
  viewCampaignFilters,
  userResponses,
  isLoading,
}) => (
  <div className="o-canvas-wrap">
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between" style={{ width: '100%' }}>
        <div className="o-canvas__header-item u-layout" style={{ width: '100%' }}>
          <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '100%' }}>
            <div className="u-1_4">
              <div className="form-group" style={{ height: '80px' }}>
                <h2 className="o-canvas__title u-100">View Campaigns</h2>
              </div>
            </div>
            <div className="u-1_4">
              <div className="form-group" style={{ height: '80px' }}>
                <Select
                  style={{ minWidth: '200px' }}
                  placeholder="Select Campaign"
                  className="c-select-header c-select-header-row--green"
                  name="campaigns"
                  id="campaigns"
                  options={filterCampaigns}
                  onChange={opt => onCampaignChange(opt)}
                  value={viewCampaignFilters.campaign}
                  valueKey="ID"
                  labelKey="Text"
                />
              </div>
            </div>
            <div className="u-1_4">
              <div className="form-group" style={{ height: '80px' }}>
                <Select
                  style={{ minWidth: '200px' }}
                  placeholder="Select Activity"
                  className="c-select-header c-select-header-row--green"
                  name="activities"
                  id="activities"
                  options={filterActivities}
                  onChange={opt => onActivityChange(opt)}
                  value={viewCampaignFilters.activity}
                  valueKey="ID"
                  labelKey="Text"
                />
              </div>
            </div>
            <div className="u-1_4">
              <div className="form-group" style={{ height: '80px' }}>
                <Select
                  style={{ minWidth: '200px' }}
                  placeholder="Select Recipient Group"
                  className="c-select-header c-select-header-row--green"
                  name="recipientGroups"
                  id="recipientGroups"
                  options={filterRecipientGroups}
                  onChange={opt => onRecipientGroupChange(opt)}
                  value={viewCampaignFilters.recipientGroupName}
                  valueKey="ID"
                  labelKey="Text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
          <div className="u-1_4">
            <div className="form-group">
              <h2 className="o-canvas__title u-100">{campaign.name}</h2>
            </div>
          </div>
          {campaign.activities.length > 0 ? (
            campaign.activities.map((act, idx) => {
              return (
                <Collapsible trigger={act.name} key={idx}>
                  <span style={{ float: 'right' }}>
                    <input type="checkbox" id={act.id} name={act.id} onChange={ev => onSelectAllChecked(ev)} />
                    select all
                  </span>
                  <div className="o-canvas__header-search u-layout">
                    {userResponses &&
                      userResponses.length > 0 &&
                      userResponses
                        .filter(resp => resp.ActivityID === act.id)
                        .map((resp, idx) => {
                          return (
                            <div className="form-group" style={{ margin: '10px', width: '150px' }} key={idx}>
                              {`${resp.DistributionListName}(${resp.RecipientGroupName})`}
                              <br />
                              <input type="checkbox" id={resp.ResponseID} name={resp.ResponseID} checked={selectedResponses.filter(rsp => rsp === resp.ResponseID).length > 0 ? true : false} onChange={ev => onResponseChecked(ev)} style={{ margin: '10px' }} />
                              {`${resp.FirstName} ${resp.LastName}`}
                              <img src={resp.Image} style={{ width: '150px' }} />
                              {resp.Notes}
                            </div>
                          );
                        })}
                  </div>
                </Collapsible>
              );
            })
          ) : (
            <p className="align-center">No Results Found</p>
          )}
          {campaign.activities.length > 0 && (
            <button className="u-right mt-10 btn-psa" onClick={() => onDownloadAllClick(campaign.id)}>
              Download All
            </button>
          )}
          {campaign.activities.length > 0 && (
            <button className="u-right mt-10 mr-10 btn-psa" onClick={() => onDownloadSelectedClick(campaign.id)}>
              Download Selected
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.psa.isLoading,
    campaign: state.psa.campaign,
    campaigns: state.psa.campaigns,
    selectedResponses: state.psa.selectedResponses,
    filterCampaigns: state.psa.filterCampaigns,
    filterRecipientGroups: state.psa.filterRecipientGroups,
    filterActivities: state.psa.filterActivities,
    userResponses: state.psa.userResponses,
    viewCampaignFilters: state.psa.viewCampaignFilters,
    distributionLists: state.psa.distributionLists,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCampaignByID,
    fetchCampaigns,
    fetchCampaignsForSearch,
    fetchResponsesForCampaign,
    fetchDistributionLists,
    selectResponse,
    downloadAll,
    downloadSelected,
    setViewCampaignFilter,
    resetCampaigns,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchCampaigns();
      this.props.fetchDistributionLists();
      this.props.resetCampaigns();
      if (this.props.match.params.id && this.props.match.params.id.length > 0) {
        this.props.fetchCampaignByID(this.props.match.params.id);
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.campaign !== this.props.campaign && this.props.campaign.id.length > 0 && !this.props.isLoading) {
        this.props.fetchResponsesForCampaign(this.props.campaign.id);
      }
    },
  }),
  withState('data', 'handleData', {}),
  withHandlers({
    onResponseChecked: ({ data, handleData, selectResponse }) => ev => {
      const { id, value } = ev.target;
      selectResponse(id);
    },
    onDownloadAllClick: ({ data, handleData, downloadAll }) => id => {
      downloadAll(id);
    },
    onDownloadSelectedClick: ({ data, handleData, downloadSelected }) => id => {
      downloadSelected(id);
    },
    onSelectAllChecked: ({ data, handleData, selectResponse, userResponses }) => ev => {
      const { name, value } = ev.target;
      const responses = userResponses.filter(act => act.ActivityID === name);
      responses.map(rsp => {
        selectResponse(rsp.ResponseID);
      });
    },
    onCampaignChange: ({ data, handleData, fetchCampaignsForSearch, setViewCampaignFilter }) => opt => {
      if (opt) {
        const { ID, Text } = opt;
        setViewCampaignFilter('campaign', ID);
      } else {
        setViewCampaignFilter('campaign', '');
      }
      fetchCampaignsForSearch();
    },
    onActivityChange: ({ data, handleData, fetchCampaignsForSearch, setViewCampaignFilter }) => opt => {
      if (opt) {
        const { ID, Text } = opt;
        setViewCampaignFilter('activity', ID);
      } else {
        setViewCampaignFilter('activity', '');
      }
      fetchCampaignsForSearch();
    },
    onRecipientGroupChange: ({ data, handleData, fetchCampaignsForSearch, setViewCampaignFilter }) => opt => {
      if (opt) {
        const { ID, Text } = opt;
        setViewCampaignFilter('recipientGroupName', ID);
      } else {
        setViewCampaignFilter('recipientGroupName', '');
      }
      fetchCampaignsForSearch();
    },
    onAddNewActivity: ({ data, handleData, history }) => event => {
      history.push(`${process.env.APP_ROOT}psa/campaign`);
    },
  }),
)(ViewCampaigns);
