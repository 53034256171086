import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import Menu from './Menu.js';

const SidebarItem = ({item, isActive}) => (
    <li className={ isActive ? 'active' : '' }>
        <Link to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <span className={`fa fa-${item.icon}`}></span>}{' '}
            <span className="sidebar-text">{item.name}</span>
        </Link>
    </li>
)

const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

class Sidebar extends Component {

    state = {
        collapse: {}
    }

    componentDidMount() {
        this.buildCollapseList()
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let collapse = {};
        Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse});
    }

    navigator(route) {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        if (paths.indexOf(this.props.location.pathname) > -1) //.replace('/','')
            return true;
        return false;
    }


    render() {
        return (
            <aside className='aside-container'>
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        <ul className="sidebar-nav">
                            {
                                Menu.map((item, i) => {
                                      return (
                                          <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                      )
                                })
                            }
                        </ul>
                    </nav>
                </div>
            </aside>
        );
    }
}

export default withRouter(Sidebar);
