import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withTheme } from 'styled-components';

class TableSystemSalesOutByWeek extends React.Component {
  renderHeader = head => {
    return (
      <tr>
        {this.sortTableDataAlphabetically(head).map((key, i) => {
          return (
            <th
              style={{
                width: '100px',
                backgroundColor: this.props.theme.primaryDark,
                color: '#fff',
              }}
              key={i}
            >
              <span>{key.replace('_', ' ')}</span>
            </th>
          );
        })}
      </tr>
    );
  };

  renderRow = (row, i) => {
    return (
      <tr key={i}>
        {this.sortTableDataAlphabetically(row).map((key, k) => {
          if (k === 0)
            return (
              <td className="w-100" key={k}>
                <span>{row[key]}</span>
              </td>
            );
          else
            return (
              <td key={k} className="w-100">
                <span>
                  <NumberFormat displayType="text" thousandSeparator={true} prefix={'£'} value={parseFloat(row[key]).toFixed(0)} />
                </span>
              </td>
            );
        })}
      </tr>
    );
  };

  sortTableDataAlphabetically = obj => {
    const sortedObj = Object.keys(obj)
      .sort()
      .slice(0, -1);
    sortedObj.unshift('YearNoWeekNo');
    return sortedObj;
  };

  render() {
    const tableClass = ['o-table o-product-performance o-product-performance--product-performance table-striped u-text-center wrapper'];

    return (
      <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
        <table className={tableClass.join(' ')} id={this.props.id}>
          <thead>{this.props.data[0] && this.renderHeader(this.props.data[0])}</thead>
          <tbody className="table-fixed-body" style={{ display: 'table-row-group' }}>
            {this.props.data.map((r, i) => {
              return this.renderRow(r, i);
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

TableSystemSalesOutByWeek.defaultProps = {
  data: [],
  isLoading: false,
};

TableSystemSalesOutByWeek.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default withTheme(TableSystemSalesOutByWeek);
