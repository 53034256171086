import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CollapsibleHeader extends React.Component {

        state = { };

        componentWillMount() { }

        render() {
          const { name } = this.props;
                return (
                  <div>
                    { name }
                  </div>
                );
        }
}

CollapsibleHeader.propTypes = { };

const mapDispatchToProps = dispatch => {
    return { };
};

export default connect(null, mapDispatchToProps)(CollapsibleHeader);
