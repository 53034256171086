import React from 'react';
import Select from 'react-select';
import CustomCountSelect from '../components/CustomCountSelect';
import { motion } from 'framer-motion';
import { capitalizeAllWords } from '../../helpers/helpers';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';

// Array of objects for each dropdown, including Value, Options and OnChange, isMulti, name, id, condition

const checkCondition = condition => {
  if (typeof condition === 'undefined') {
    return true;
  } else if (condition && condition.length > 0) {
    return true;
  } else {
    return false;
  }
};

const DataDropdownsContainer = ({
  dropdowns,
  retail = true,
  unlockRefresh,
}) => {
  return (
    <div className="container pb-3 sub-menu-bottom">
      <div className="row">
        <div className="col-12">
          <div className="nam-dash nam-dash-select-row">
            <div className="form-row">
              {dropdowns &&
                dropdowns.map(
                  (
                    {
                      value,
                      options,
                      handler,
                      valueKey,
                      labelKey,
                      name,
                      isMulti,
                      condition,
                      required,
                      disabled,
                      isLoading = false,
                    },
                    i,
                  ) => {
                    return !isMulti
                      ? options &&
                          options.length > 0 &&
                          checkCondition(condition) && (
                            <motion.div
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              key={`dropdown-${i}`}
                              className="form-group col-md-5 col-lg-3">
                              <Select
                                className={`c-select-header c-select-header-row--others`}
                                name={name}
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                id={name}
                                placeholder={capitalizeAllWords(name)}
                                value={value}
                                onChange={selectedOptions =>
                                  handler(selectedOptions)
                                }
                                options={options}
                                valueKey={valueKey}
                                labelKey={labelKey}
                                clearable={true}
                                searchable={true}
                                isMulti={false}
                              />
                            </motion.div>
                          )
                      : options &&
                          options.length > 0 &&
                          checkCondition(condition) && (
                            <motion.div
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              transition={{ duration: 0.3 }}
                              key={`dropdown-${i}`}
                              className="form-group  col-md-5 col-lg-3">
                              {options && options.length > 0 && (
                                <CustomCountSelect
                                  className={`Select c-select-header c-select-header-row--others`}
                                  onChangeCallback={ev => handler(ev)}
                                  name={name}
                                  id={name}
                                  isMulti={true}
                                  value={value}
                                  options={options}
                                  type={capitalizeAllWords(name)}
                                  types={capitalizeAllWords(name)}
                                  placeholder={capitalizeAllWords(name)}
                                  required={true}
                                  isLoading={isLoading}
                                  valueKey={valueKey}
                                  labelKey={labelKey}
                                />
                              )}
                            </motion.div>
                          );
                  },
                )}
              {retail && (
                <BusinessTypes unlockRefresh={() => unlockRefresh()} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataDropdownsContainer;
