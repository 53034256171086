import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const yesNoSelect = [
  { ID: '1', Text: 'Yes' },
  { ID: '0', Text: 'No' },
];

const AddNpd = ({ isLoading, npdForm, onSaveNpd, onFormSelectChange, onFormFileChange, onFormTextChange, onFormDepartmentChange, onFormCategoryChange, handleNpdAddModal }) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{npdForm.ID ? 'Update' : 'Add New'} NPD</span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Department</h3>
              <Select value={npdForm.Department} onChange={onFormDepartmentChange} options={npdForm.departments} valueKey="ID" labelKey="Text" />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Categories</h3>
              <Select value={npdForm.Category} onChange={onFormCategoryChange} options={npdForm.categories} valueKey="ID" labelKey="Text" />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Supplier</h3>
              <input type="text" id="supplier" className="ReactModal--input-green" name="supplier" value={npdForm.Supplier || ''} onChange={evt => onFormTextChange('Supplier', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Brand</h3>
              <input type="text" id="brand" className="ReactModal--input-green" name="brand" value={npdForm.Brand || ''} onChange={evt => onFormTextChange('Brand', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div style={{ height: '105px' }}>
              <img src={npdForm.Advert1} style={{ width: 'auto', height: '100px' }} />
            </div>
            <h3 className="o-canvas__search-title ">Advert1</h3>
            <input type="file" name="Advert1" onChange={evt => onFormFileChange('Advert1', evt.target.files[0])} />
          </div>
          <div className="u-1_3 mb-0">
            <div style={{ height: '105px' }}>
              <img src={npdForm.Advert2} style={{ width: 'auto', height: '100px' }} />
            </div>
            <h3 className="o-canvas__search-title ">Advert2</h3>
            <input type="file" name="Advert2" onChange={evt => onFormFileChange('Advert2', evt.target.files[0])} />
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div style={{ height: '105px' }}>
              <img src={npdForm.Advert3} style={{ width: 'auto', height: '100px' }} />
            </div>
            <h3 className="o-canvas__search-title ">Advert3</h3>
            <input type="file" name="Advert3" onChange={evt => onFormFileChange('Advert3', evt.target.files[0])} />
          </div>
          <div className="u-1_3 mb-0">
            <div style={{ height: '105px' }}>
              <img src={npdForm.Advert4} style={{ width: 'auto', height: '100px' }} />
            </div>
            <h3 className="o-canvas__search-title ">Advert4</h3>
            <input type="file" name="Advert4" onChange={evt => onFormFileChange('Advert4', evt.target.files[0])} />
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={npdForm.Advert5} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Advert5</h3>
              <input type="file" name="Advert5" onChange={evt => onFormFileChange('Advert5', evt.target.files[0])} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={npdForm.Image} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Image</h3>
              <input type="file" name="Image" onChange={evt => onFormFileChange('Image', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Start Date</h3>
              <Datetime value={npdForm.StartDate} onChange={date => onFormTextChange('StartDate', { value: date.format('YYYY-MM-DD HH:mm:ss') })} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">End Date</h3>
              <Datetime value={npdForm.EndDate} onChange={date => onFormTextChange('EndDate', { value: date.format('YYYY-MM-DD HH:mm:ss') })} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSaveNpd()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handleNpdAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddNpd;
