import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { selectors } from '../redux/reducers/admin';

const BulkCleansingPage = ({data,
                            isLoading}) => (
                              <div className="o-canvas-wrap">
          <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }}/>
          <div className="o-canvas">
                  <div className="o-canvas__header u-layout u-layout--between">
                          <div className="o-canvas__header-item u-layout">
                                  <h2 className="o-canvas__title u-100">
                                    BULK CLEANSING
                                  </h2>
                          </div>
                  </div>
                  <div className="o-canvas__header-search u-layout">
                    <div className="u-1_2">
                      <button style={{ marginLeft: '10px' }}>UPLOAD</button>
                      <button style={{ marginLeft: '10px' }}>DOWNLOAD</button>
                    </div>
                  </div>
          </div>
  </div>
);
function mapStateToProps(state){
  let data = {
               isLoading: state.admin.isLoading,
             };
  return data;
}
export default compose(
withRouter,
  connect( mapStateToProps , { }),
  lifecycle({
    componentDidMount() {

    },
    componentDidUpdate(prevProps){
    }
  }),
  withState('data', 'handleData', {
  }),
  withHandlers({
  })
)(BulkCleansingPage);


