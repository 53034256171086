import React, { useState } from 'react';
import { connect } from 'react-redux';
import lifecycle from 'recompose/lifecycle';
import compose from 'recompose/compose';
import { TopNavLinks } from '../../AlchemySettings';
import { withRouter } from 'react-router';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { useCookies } from 'react-cookie';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  getUserSystem,
  getUserRoles,
  setShowQBPopUp,
  getNoOfLogin,
  showFor,
  getUserSystemFormatted,
} from '../../helpers/util';
import { requestGet } from '../../helpers/axios';
import { hotjar } from 'react-hotjar';
import 'react-image-gallery/styles/css/image-gallery.css';
import { setMenuOptions, getMenus } from '../redux/reducers/app';
import { checkStatusReport } from '../redux/reducers/likeForLike';
import step1Content from '../../assets/general/1.png';
import step2Content from '../../assets/setup/2.png';
import step3Content from '../../assets/setup/3.png';
import step4Content from '../../assets/setup/4.png';
import step5Content from '../../assets/setup/5.png';
import step6Content from '../../assets/setup/6.png';
import step7Content from '../../assets/setup/7.png';
import step8Content from '../../assets/setup/8.png';
import step9Content from '../../assets/setup/9.png';
import step10Content from '../../assets/setup/10.png';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import BrandLogo from '../components/util/BrandLogo';
import Loading from '../components/Loading';
import queryBuilderImage from '../../assets/qb/query_builder.png';
import { pageVariants } from '../../config/pageVariants';
import { AnimatePresence, motion } from 'framer-motion';
import ThemedModal from '../components/ThemedModal';

import expandedDataServicesImage from '../../assets/images/popups/15-Expanded_Data_Service@2x.png';
import { getUserData } from '../../helpers/user';

const variants = {
  initial: {
    opacity: 0,
    scale: 0,
    transition: { duration: 1, type: 'spring' },
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
  },
};

const formatLinks = link => {
  if (getUserSystem() === 'cjlang') {
    if (link.toLowerCase() === 'retail') {
      return 'Retail Analysis';
    } else if (link.toLowerCase() === 'analysis') {
      return 'Wholesale Analysis';
    } else {
      return link;
    }
  } else if (getUserSystem() === 'cjlangRetail') {
    if (link.toLowerCase() === 'retail') {
      return 'Store Analysis';
    } else if (link.toLowerCase() === 'nam dash') {
      return 'My Dashboard';
    } else {
      return link;
    }
  } else {
    return link;
  }
};

export const Dashboard = ({
  menus,
  data,
  onLinkClicked,
}) => {
  const userSystem = getUserSystemFormatted();
  const theme = useContext(ThemeContext);
  const [cookies, setCookie] = useCookies(['termsAndConditions']);
  const [isModalOpen, setisModalOpen] = useState(!cookies.termsAndConditions);
  const [isDemoModalOpen, setisDemoModalOpen] = useState(true);
  const closeDemoModal = () => {
    setisDemoModalOpen(false);
  };
  const closeModal = () => {
    setisModalOpen(false);
    setCookie('termsAndConditions', true, { path: '/' });
  };
  return (
    <>
      <motion.div
        key={'dashboard-ID-123415152211'}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        className="splash">
        <div className="splash">
          {(getNoOfLogin() >= 0 || data.showDashboard) && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="splash__buttons text-center mb-2">
                <div className="d-block splash__image mx-auto">
                  <BrandLogo />
                </div>{' '}
                {data.isLoading && <Loading classList={'mt-3'} />}
                {!data.isLoading && (
                  <div className="d-block mx-auto mt-5">
                    <AnimatePresence key="dashboard-link-container-123321">
                      {TopNavLinks.filter(link => {
                        if (
                          link.label === 'Analysis' &&
                          menus.analysis.length === 0
                        ) {
                          return false;
                        }
                        if (
                          link.label === 'Nam Dash' &&
                          getUserRoles()?.filter(
                            role => role.Description === 'NAMDASH',
                          ).length === 0
                        ) {
                          return false;
                        }
                        if (
                          link.label === 'Analysis' &&
                          getUserRoles()?.filter(
                            role => role.Description === 'ANALYSIS',
                          ).length === 0
                        ) {
                          return false;
                        }
                        if (
                          link.label === 'Query' &&
                          getUserRoles()?.filter(
                            role => role.Description === 'QUERYBUILDER',
                          ).length === 0
                        ) {
                          return false;
                        }
                        if (
                          link.label === 'Retail' &&
                          getUserRoles()?.filter(
                            role => role.Description === 'RETAIL',
                          ).length === 0
                        ) {
                          return false;
                        }
                        return true;
                      }).map((link, i) => {
                        return (
                          <motion.div
                            key={`dashboard-icon-${i}`}
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={variants}
                            onClick={() => onLinkClicked(link)}
                            className="btn btn-outline-primary text-uppercase ml-2 mr-2 mb-3 rounded-0">
                            {formatLinks(link.label)}
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="purple-triangle purple-triangle__left"></div>
        <div className="purple-triangle purple-triangle__right"></div>
      </motion.div>
      {showFor('caterforce', 'confex') && (
        <ThemedModal
          isOpen={isModalOpen}
          overlayClassName="performance-modal__overlay"
          isModalAnimating={isModalOpen}
          className={`performance-modal terms_modal`}>
          <div
            className="d-flex justify-content-center mb-5"
            style={{ maxWidth: '50%', margin: '0 auto' }}>
            <BrandLogo />
          </div>
          <p>
            You have been granted access to the {`${userSystem}`} Data Service
            by {`${userSystem}`}
            Central Office. This access can be removed at any time but whilst
            your access is enabled you have the following responsibilities:
          </p>
          <p>
            To notify TWC / {`${userSystem}`} if you are changing roles and no
            longer working on the {`${userSystem}`} account
          </p>
          <p>
            To notify TWC / {`${userSystem}`} if you are changing companies and
            leaving your current employment
          </p>
          <p>
            To keep your password and access information private and to ensure
            it is not shared with anyone else whatsoever.
          </p>
          <p>
            To keep the data contained on the {`${userSystem}`} Data Service
            private and confidential. Named member data must only be shared with
            the named member not with others and any {`${userSystem}`} data must
            only be shared within {`${userSystem}`} or with colleagues in your
            business directly employed on the {`${userSystem}`} account. Data is
            not to be shared beyond your immediate team and must not be made
            available to any third party or into the public domain either to
            other wholesalers, suppliers or to trade press, financial
            institutions or industry bodies in any format whatsoever. Any
            inappropriate sharing of information will be considered a data
            breach for which you will be held responsible. Data sourced from the{' '}
            {`${userSystem}`} Data Service will remain the property of{' '}
            {`${userSystem}`} and TWC.
          </p>
          <p>
            TWC / {`${userSystem}`} confirms that whilst every effort is taken
            in the collation and reporting of this data, accuracy is not
            guaranteed. Any presentations, plans or agreements built from this
            data are the responsibility of the user not the {`${userSystem}`}{' '}
            Data Service or its providers and data is provided to you, the user,
            without any warranty either explicit or inferred.
          </p>
          <p>
            TWC / {`${userSystem}`} undertakes to provide their best endeavours
            at all times to facilitate uninterrupted access to the{' '}
            {`${userSystem}`} Data Service 24 / 7. However, unforeseen technical
            issues or data delivery or integrity issues do arise from time to
            time. Whilst TWC / {`${userSystem}`}
            will strive to remedy any technical issues preventing access as
            quickly as possible, neither party guarantees or infers specific
            service levels will be maintained.
          </p>
          <p>
            TWC / {`${userSystem}`} continually monitors usage of the{' '}
            {`${userSystem}`} Data Service. We do this via Cookies and
            specialist tracking software. Please be aware that your site visits
            may be recorded. This monitoring is solely for the purposes of
            improving customer experience and site functionality. If you do
            require further information on the data that we track please do not
            hesitate to contact support@wsale.co.uk.
          </p>
          <p>
            Reports and layouts contained on the {`${userSystem}`} Data Service
            are the intellectual property of TWC. No rights are transferred in
            the use of these reports via logged in users and reside solely with
            TWC.
          </p>
          <p>
            This agreement shall be governed by and construed and take effect in
            accordance with English law and each of the parties hereto submits
            to the exclusive jurisdiction of the English Courts.
          </p>
          <p>
            Nothing in this agreement shall, or is intended to, create rights
            and/or benefits by reason of the Contracts (Rights of Third Parties)
            Act 1999 or otherwise in favour of any person who is not party to
            this agreement.
          </p>
          <div className="justify-center modal-footer">
            <button
              className="btn"
              style={{
                backgroundColor: `${theme.primary}`,
                borderColor: `${theme.primary} `,
              }}
              onClick={closeModal}>
              Accept
            </button>
          </div>
        </ThemedModal>
      )}

      {showFor('demo') && (
        <ThemedModal
          isOpen={isDemoModalOpen}
          overlayClassName="performance-modal__overlay"
          isModalAnimating={isDemoModalOpen}
          className={`performance-modal terms_modal`}>
          <div
            className="d-flex justify-content-center mb-5"
            style={{ maxWidth: '50%', margin: '0 auto' }}>
            <BrandLogo />
          </div>
          <p>
            The data on this site is for demo purposes only. These figures are
            not reflective of the trading situations of the named businesses. By
            using this site, you agree to our terms and conditions, and
            acknowledge that the figures reported on the site are for
            illustrative purposes only.
          </p>
          <div className="justify-center modal-footer">
            <button
              className="btn"
              style={{
                backgroundColor: `${theme.primary}`,
                borderColor: `${theme.primary} `,
              }}
              onClick={closeDemoModal}>
              Accept
            </button>
          </div>
        </ThemedModal>
      )}
    </>
  );
};
function mapStateToProps(state) {
  let data = {
    menus: state.app.menus,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    setMenuOptions,
    getMenus,
    checkStatusReport,
  }),

  withState('data', 'handleData', props => ({
    showAppButton: false,
    showDashboard: false,
    showQBPopUp: false,
    linkURL: '',
    queryBuilderImage: [{ original: queryBuilderImage }],
    generalImages: [{ original: expandedDataServicesImage }],
    isLoading: false,
    images: [
      { original: step1Content },
      { original: step2Content },
      { original: step3Content },
      { original: step4Content },
      { original: step5Content },
      { original: step6Content },
      { original: step7Content },
      { original: step8Content },
      { original: step9Content },
      { original: step10Content },
    ],
  })),
  lifecycle({
    async componentDidMount() {
      this.props.handleData({ ...this.props.data, isLoading: true });
      this.props.checkStatusReport();

      const namDashReportsUrl =
        '/route.php?c=reportMenu/getNAMDashReports';

      const analysisReportsUrl =
        '/route.php?c=reportMenu/getAnalysisReports';

      const queryBuilderReportsUrl =
        '/route.php?c=reportMenu/getQueryBuilderReports';

      const retailDataUrl = '/route.php?c=reportMenu/getRetailReports';

      const [namDashReports, analysisReports, queryBuilderReports, retailData] =
        await Promise.all([
          requestGet({ url: namDashReportsUrl }),
          requestGet({ url: analysisReportsUrl }),
          requestGet({ url: queryBuilderReportsUrl }),
          requestGet({ url: retailDataUrl }),
        ]);

      const options = {
        namdash: namDashReports,
        analysis: analysisReports,
        queryBuilder: queryBuilderReports,
        psa: ['manage_distribution', 'manage_campaigns', 'view_campaign'],
        retail: retailData || [],
      };
      this.props.setMenuOptions(options);
      this.props.handleData({ ...this.props.data, isLoading: false });
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.menus.namdash.length === 0 &&
        this.props.menus.analysis.length === 0
      ) {
        this.props.getMenus();
      }
    },
  }),
  withHandlers({
    onQBGoToApp:
      ({ data, history }) =>
      () => {
        setShowQBPopUp();
        history.push(data.linkURL);
      },
    onGoToApp:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, showDashboard: true });
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: 'Dashboard',
            });
          }, 2000);
        }, 2000);
      },
    onSlide:
      ({ data, handleData }) =>
      val => {
        if (val === data.images.length - 1) {
          handleData({ ...data, showAppButton: true });
        } else {
          handleData({ ...data, showAppButton: false });
        }
      },
    onLinkClicked:
      ({ history }) =>
      link => {
        history.push(link.url);
      },
    onGeneralSlide:
      ({ data, handleData }) =>
      val => {
        if (val === data.generalImages.length - 1) {
          handleData({ ...data, showAppButton: true });
        } else {
          handleData({ ...data, showAppButton: false });
        }
      },
    onNextStep:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, currentStep: data.currentStep + 1 });
      },
    onPreviousStep:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, currentStep: data.currentStep - 1 });
      },
    onEditUser: props => id => {
      props.history.push(`${process.env.APP_ROOT}create_user/user/${id}`);
    },
  }),
)(Dashboard);
