import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Loading from '../../components/Loading';
import { bostonMatrixStores as explanation } from '../Explainer/Explanations';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Container = styled.div`
  position: relative;
  height: 100%;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #000;
  }

  .border__0 {
    position: relative;
  }
  .border__0::after {
    content: '';
    position: absolute;
    bottom: -1px;
    border-bottom: 2px solid #000;
    width: 95%;
    height: 1px;
    right: 0;
  }
  .border__1 {
    border-bottom: 1px solid #000;
  }
  .border__1::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-left: 2px solid #000;
    width: 1px;
    height: 95%;
    left: -1px;
  }
  .border__2 {
  }
  .border__3 {
    position: relative;
    border-top: 1px solid #000;
  }
  .border__3::after {
    content: '';
    position: absolute;
    top: 0;
    border-left: 2px solid #000;
    width: 1px;
    height: 95%;
    left: -1px;
  }
`;

const SectionContainer = styled.div``;

const Section = styled.div`
  margin: 1rem;
  height: calc(100% - 1rem);
  cursor: pointer;
  .section__green {
    background: ${({ theme: { primary } }) => primary};
    display: flex;
    justify-content: center;
     cursor: pointer;
}
  }
  .section__red {
    background: ${({ theme: { primary } }) => primary};
    display: flex;
    justify-content: center;
    cursor: pointer;
}
  }
  .section__yellow {
    background: ${({ theme: { primary } }) => primary};
    display: flex;
    justify-content: center;
    cursor: pointer;
}
  }
`;

const SectionHeader = styled.div`
  color: #fff !important;
  padding: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
`;

const LabelShare = styled.div`
  position: absolute;
  position: absolute;
  color: #000;
  bottom: 15px;
  font-weight: bold;
`;
const LabelGrowth = styled.div`
  position: absolute;
  color: #000;
  bottom: 100px;
  font-weight: bold;
  left: -30px;
  transform: rotate(-90deg);
`;

const ImgHolder = styled.div`
  padding: 0 0.3rem;
  color: #fff;
`;

const getTooltip = str => {
  const { questionMarks, stars, dogs, cows } = explanation.graph;
  const tooltipMap = {
    'question mark': questionMarks,
    star: stars,
    dog: dogs,
    'cash cow': cows,
  };
  return tooltipMap[str.toString().toLowerCase()];
};

const RetailerPerformanceGraph = ({
  retailerPerformanceData,
  history,
  isLoading,
}) => {
  const theme = useContext(ThemeContext);
  const { primary } = theme;
  return (
    <AnimatePresence>
      <div className={isLoading ? 'w-100' : ''}>{isLoading && <Loading />}</div>
      {!isLoading &&
        retailerPerformanceData &&
        retailerPerformanceData.length > 0 && (
          <div
            key={'retailer-performance'}
            className="container-fluid  pb-5 pl-4">
            <LabelShare>Share</LabelShare>
            <LabelGrowth>Market Growth</LabelGrowth>
            <Container className="arrow-head">
              <div className="row h-100">
                {retailerPerformanceData &&
                  retailerPerformanceData.length > 0 &&
                  retailerPerformanceData.map(
                    ({ radius, stores, text, color, value }, index, arr) => {
                      return (
                        <SectionContainer
                          className={`col-12 col-lg-6  border__${index}`}
                          key={`retailer-graph-${index}`}>
                          <Section
                            onClick={() =>
                              history.push(
                                `/retail/retailer-performance/table/${text}`,
                              )
                            }>
                            <SectionHeader className={`section__${color}`}>
                              {text}
                              <ImgHolder>
                                <Tooltip
                                  title={getTooltip(text)}
                                  position="bottom"
                                  trigger="mouseenter">
                                  <HelpOutlineIcon
                                    style={{ cursor: 'pointer' }}
                                  />
                                </Tooltip>
                              </ImgHolder>
                            </SectionHeader>
                            <motion.svg
                              initial={{ scale: 0, opacity: 0 }}
                              animate={{ scale: 1, opacity: 1 }}
                              transition={{ duration: 0.4 }}
                              style={{
                                width: '100%',
                                height: 'calc(100% - 50px)',
                              }}>
                              <circle
                                cx="50%"
                                cy="50%"
                                r={radius}
                                stroke={primary}
                                strokeWidth="4"
                                fill={primary}
                              />
                              <circle
                                cx={`${50 + radius / 10}%`}
                                cy={`${50 - radius / 5}%`}
                                r={radius / 2}
                                stroke={primary}
                                strokeWidth="4"
                                fill="#fff"
                              />
                              <text
                                x={`${50 + radius / 10}%`}
                                y={`${50 - radius / 5}%`}
                                textAnchor="middle"
                                stroke="#000"
                                strokeWidth="2px"
                                dy=".3em">
                                {stores} {radius > 80 ? 'Stores' : ''}
                              </text>
                            </motion.svg>
                          </Section>
                        </SectionContainer>
                      );
                    },
                  )}
              </div>
            </Container>
          </div>
        )}
    </AnimatePresence>
  );
};

export default RetailerPerformanceGraph;
