import React from 'react';
import styled from 'styled-components';
import { Button, Row } from 'reactstrap';
import LineGraphDualAxis from '../../components/LineGraphDualAxis';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;

  & .section {
    color: #fff;
    text-align: center;
    padding: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  & .section__last {
    margin-right: 0 !important;
  }

  & .section__background {
    background: ${({ theme: { secondary } }) => secondary};
  }

  & .section-tab__active {
    box-shadow: 1px 1px 7px 1px ${({ theme: { secondary } }) => secondary};
  }
`;

const DistributionGraph = ({
  data,
  history,
  match: {
    params: { graphType },
  },
  isLoading,
  xAxisLabel
}) => {
  const tabs = [
    { text: 'Pareto Chart', url: 'pareto' },
    { text: 'Sales by Store Chart', url: 'sales_by_store' },
  ];

  const tabData = data[graphType];
  
  return (
    <Container className="container-fluid">
      <Row>
        <div className="col-12">
          <Button
            className="mb-2"
            onClick={() => history.push(`/retail/category-penetration`)}>
            Back
          </Button>
          <div className="row no-gutters">
            {tabs &&
              tabs.map(({ text, color, url }, index) => {
                return (
                  <div className="col-6" key={`tab-${url}`}>
                    <div
                      className={`section section__background ${
                        index === 3 ? 'section__last' : ''
                      } ${graphType === url ? 'section-tab__active' : ''}`}
                      onClick={() =>
                        history.push(
                          `/retail/category-penetration/graph/${url}`,
                        )
                      }>
                      {text}
                    </div>
                  </div>
                );
              })}
              <LineGraphDualAxis 
              isLoading={isLoading}
              page="catPen"
              yAxisLabel="Value TY"
              yAxisLabel1={history.location.pathname == '/retail/category-penetration/graph/pareto' ? 'Percentage' :'Sales Per Store'}
              xAxisLabel={xAxisLabel}
              data={tabData} />
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default DistributionGraph;
