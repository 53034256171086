import React from 'react';
import styled from 'styled-components';
import whiteFilterImg from '../../assets/images/icons/filter-white.svg';

const ThemeWrapperContainer = styled.div`
  h1, h2, h3, h4, h5, h6, p, .text-brand-color, .o-filter--query .o-wrap, .pull-left a, .theme-font {
    color: ${props => props.theme.fontColor} !important;
    font-family: ${props => props.theme.font} !important;
  }

  .rdt_Table {
     font-family: ${props => props.theme.font} !important;
  }

  font-family: ${props =>
    props.theme.name === 'unitas'
      ? "'Gotham Light', Helvetica, Verdana"
      : props.theme.font}
  

  .sub-menu__nam-dash .sub-menu__left, .nam-dash-table table th {
    font-family: ${props => props.theme.font} !important;
    font-weight: 700
  }

  .nam-dash-table table tbody td {
    font-family: ${props => props.theme.font} !important;
  }

  .c-objects-wrap--graph text {
    font-family: ${props =>
      props.theme.name === 'unitas'
        ? "'Gotham Light', Helvetica, Verdana"
        : props.theme.font} !important;
  }

  .c-select-header-row--unitas .Select-arrow-zone, .c-select-header-row--unitas .ui-select__indicators, .c-select-header-row--others .Select-arrow-zone, .c-select-header-row--others .ui-select__indicators, .Select-clear-zone {
    background: ${props => props.theme.primary} !important;
  }

  && {
 .c-select-header-row--others .ui-select__indicators, .c-select-header-row--others .Select-arrow-zone, .Select-clear-zone {
    ${({ theme }) =>
      theme.name === 'countryRangeGroup' &&
      `
    background: ${theme.navBar} !important;
  `}
  }
}

 && {
 .ui-select__control, .sub-menu-bottom .c-select-header > div:first-of-type {
    ${({ theme }) =>
      theme.name === 'countryRangeGroup' &&
      `
    border-color: ${theme.navBar} !important;
  `}
  }
}



  [class*='t-brand-c'] .u-icon--back, .u-icon--filter__filtered {
     background-color: ${props => props.theme.primaryDark} !important;
 
  }

  .u-icon--filter.u-icon--filter__filtered {
  background-image: url(${whiteFilterImg});
  border-right: 1px solid #fff
  }
  .sub-menu-border {
 
  }
  .sub-menu-bottom {
    .Select-control {
      height: 38px;
    }

   .c-select-header-row--others .Select-arrow-zone, .c-select-header-row--unitas .Select-arrow-zone, .c-select-header-row--others .ui-select__indicators, .c-select-header-row--others .ui-select__indicators {
    
    width: 40px;
    transition: all .1s ease-out;

    .Select-arrow {
    border-color: #fff;
    border-style: solid;
    display: inline-block;
    height: 10px;
    width: 10px;
    position: relative;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
    border-radius: 0;
    transition: all 0.2s ease-out;
    transform-origin: 60% 60%;
    }
  }

  .c-select-header.is-open, .c-select-header .ui-select__control--menu-is-open {
      .Select-arrow {
    transform: rotate(-135deg);
    }
  }

   .c-select-header > div:first-of-type { 
    border: 1px solid ${({ theme }) => theme.primary};
  }

  .ui-select__indicators {
    border-radius: 0;
    display: flex;
    justify-content: center;
  }
  .ui-select__indicator-separator {
    display: none
  }

  .ui-select__indicators .ui-select__indicator {
    position: static
  }

  .c-select-header .ui-select__value-container.ui-select__value-container--is-multi.ui-select__value-container--has-value + .ui-select__indicators {
    width: 70px !important;
  }

   .c-select-header .ui-select__value-container.ui-select__value-container--is-multi.ui-select__value-container--has-value {
    color: #333 !important;
  }
}



  .alchemy-btn--unitas,
  .alchemy-btn--others, .alchemy-btn--login, .btn.btn-primary, .btn.btn-secondary {
    background: ${props =>
      props.theme.buttons
        ? props.theme.buttons
        : props.theme.primary} !important;
    border-color: ${props =>
      props.theme.buttons
        ? props.theme.buttons
        : props.theme.primary} !important;
  }

  .btn-primary:focus, .btn-focus, .btn-primary.focus, btn-outline-primary:focus, btn-outline-primary:active, .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.boxShadow} !important;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
     box-shadow: 0 0 0 0.2rem ${props => props.theme.boxShadow} !important;
  }

  .btn-info:hover {
    background: ${props => props.theme.primaryDark} !important;
    border-color: ${props => props.theme.primaryDark} !important;
  }

  .bg-dark--unitas,
  .bg-dark--unitas .dropddown-toggle-btn, .bg-dark, .bg-dark--others,
  .bg-dark--others .dropddown-toggle-btn {
    background: ${props => props.theme.navBar} !important;
  }

  .lower-menu--nam-dash-bg {
    background: ${props => props.theme.subNavBar} !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lower-menu--nam-dash-bg .fa-chevron-left:before , .lower-menu--nam-dash-bg .fa-chevron-right:before {
    color: ${props => props.theme.subNavBarText} !important;
  }


  .lower-menu.lower-menu--nam-dash-bg {
    width: 100%;
    align-items: stretch;
  }

  .active--unitas, .active--others {
    color: #fff
    &:hover {
      color: ${props => props.theme.primary} !important;
    }
  }

  .active-bg--unitas. .active-bg--others {
    background-color: ${props => props.theme.primaryDark} !important;
    &:hover {
        background-color: ${props => props.theme.primaryDark} !important;
      }
  }

  .top-row {
   
    &__middle { 
      .list-inline-item:nth-child(1) {
        a:hover {
         
        }
      }
      .list-inline-item:nth-child(2) {
        a:hover {
          
        }
      }
      .list-inline-item:nth-child(3) {
        a:hover {
         
        }
      }
  
      .list-inline-item:nth-child(1).active div, .list-inline-item:nth-child(4).active div, .list-inline-item:nth-child(2).active div, .list-inline-item:nth-child(3).active div {
        color:${props => props.theme.navBarTextActive} !important;;
      }
  
       
      
    }
  
    &__right {
      .dropdown-menu {
        background-color: ${props => props.theme.primaryDark} !important;;
        
      }
    }
  
    &__mobile {
      .list-inline-item:nth-child(1).active div {
        color: ${props => props.theme.primary} !important;
      }
  
 
    }
  }

  .step-active--unitas, .step-active--others {
    background: ${props => props.theme.primary} !important;
  }

  .query-radio--unitas input[type='checkbox']:checked + label. .query-radio--others input[type='checkbox']:checked + label {
    background: ${props => props.theme.primary} !important;
    
  }
  .toggle-unitas .react-toggle--checked .react-toggle-track, .toggle-others .react-toggle--checked .react-toggle-track {
    background: ${props => props.theme.primary} !important;
  }
  .toggle-unitas .react-toggle--checked .react-toggle-track:hover, .toggle-others .react-toggle--checked .react-toggle-track:hover {
    background: ${props => props.theme.primaryDark} !important;
  }

  .toggle-unitas, .toggle-others {
    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
      background-color:  ${props => props.theme.primary} !important;
    }
  }
  .o-filter--query {
    border-color: ${props => props.theme.primary} !important;
  }

  .black-circle {
    background:  ${props => props.theme.donutBg} !important;
  }

  .c-alchemy-toasty--unitas, .c-alchemy-toasty,  .c-alchemy-toasty--others {
    background: ${props =>
      props.theme.toastColor
        ? props.theme.toastColor
        : props.theme.primary} !important;
  }

  li.list-inline-item.active {
    background: ${props => props.theme.navBarActive}
  }


  .c-select-header-row--unitas, .c-select-header-row--others {
    .Select-menu-outer {
      background-color: ${props => props.theme.dropdownBackground} !important;
    }


    .Select-option {
      background-color: ${props => props.theme.dropdownBackground} !important;
      color: ${({ theme }) => theme.fontColor}
    }
  }

  .splash {
    background: ${props => props.theme.loginBg} !important;
  }

  .purple-triangle__left {
    background: ${props => props.theme.loginLeft} !important;
  }
  .purple-triangle__right {
    background: ${props => props.theme.loginRight} !important;
  }

  .btn-outline-primary:active {
    background: ${props => props.theme.primary} !important;
    border-color: ${props => props.theme.primary} !important
  }

  .splash .btn-outline-primary {
    background: ${props =>
      props.theme.loginBg === '#fff' ? '#fff' : 'transparent'} !important;
    border-color: ${props => props.theme.outlineColor} !important;
    color: ${props =>
      props.theme.loginBg === '#fff'
        ? props.theme.outlineColor
        : '#fff'} !important;
   
  }

  .splash .btn-outline-primary:hover {
    background: ${props =>
      props.theme.loginBg !== '#fff'
        ? '#fff'
        : props.theme.outlineColor} !important;
    color: ${props =>
      props.theme.loginBg !== '#fff' ? '#000' : '#fff'} !important;
   
  }
 
  .splash__image img {
    content: ${props => props.theme.brandLogo} !important
  }

  
  .performance-modal {
    border-top: 4px solid ${props => props.theme.primary} !important
  }

  .performance-back-btn {
    background: ${props => props.theme.primary} !important;
    border: 1px solid ${props => props.theme.primary};
  }

  .nam-dash-select-row .Select--multi .Select-value {
    background: ${props => props.theme.primary} !important
  }

  .nam-dash-select-row .Select--multi .Select-value-icon {
    background: ${props => props.theme.primary} !important
  }

  .text-white {
    color: #fff !important;
  }
  
  .u-layout {
    display: flex;
    flex-wrap: wrap;
  }

  .c-selectable-box input:checked + label {
    background: ${props => props.theme.primary} !important
  }

  .vertical-auto-margin {
    margin: auto 0;
  }
  
 .top-row__date {
  ${props =>
    props.theme.navBarText
      ? `color: ${props.theme.navBarText} !important`
      : ''}
 }

 .alchemy-lnk--unitas, .alchemy-lnk--others {
  ${props =>
    props.theme.navBarSubText
      ? `color: ${props.theme.navBarSubText} !important`
      : ''}
 }

 li.list-inline-item:not(.active):hover .alchemy-lnk--unitas, li.list-inline-item:not(.active):hover .alchemy-lnk--others {
  color: ${props => props.theme.navBarTextHover} !important;
 }

 
  li.list-inline-item .alchemy-lnk--unitas, li.list-inline-item .alchemy-lnk--others {
  color: ${props => props.theme.navBarText} !important;
  }
  
  li.list-inline-item:not(.active):hover {
    background: ${({ theme }) => theme.navBarHover}
  }

  .sub-menu__nam-dash {
    
    .alchemy-btn--unitas,
  .alchemy-btn--unitas, .alchemy-btn--login, .alchemy-btn--others,
  .alchemy-btn--others, .ReactModal__Content .alchemy-btn--login {
    background: ${props =>
      props.theme.subMenuButtons
        ? props.theme.subMenuButtons
        : props.theme.primary} !important;
    border-color: ${props =>
      props.theme.subMenuButtons
        ? props.theme.subMenuButtons
        : props.theme.primary}  !important;
  }

    & .Select-value {
      display: flex;
      align-items: center;
    }


    & .c-select-header-row--unita .Select-arrow, .c-select-header-row--others .Select-arrow {
      border-color: ${props =>
        props.theme.primary} transparent transparent !important;
    }

    & .c-select-header-row--unitas .Select-arrow-zone, .c-select-header-row--others .Select-control .Select-clear-zone, .c-select-header-row--unitas .ui-select__indicators, .c-select-header-row--others .Select-arrow-zone, .c-select-header-row--others .ui-select__indicators {
      background: #fff !important;
    }

    & .c-select-header-row--others .Select-control .Select-clear-zone .Select-clear {
      color: ${props => props.theme.primary} !important;
    }

    & .Select-arrow {
      border-width: 8px 5px 0 !important; 
    }

    & .Select.is-open > .Select-control .Select-arrow {
      border-color: transparent transparent ${props =>
        props.theme.primary} !important;
      border-width: 0 5px 8px !important;
    }

    & .c-select-header-row--unitas .Select-menu-outer, .c-select-header-row--others .Select-menu-outer {
      backgroud: #fff;
      color: #000
    }

    & .Select-menu-outer {
      background: #fff !important;
      color: ${props => props.theme.primary} !important;
    }

    & .Select-option {
      background: #fff !important;
      color: ${props => props.theme.primary} !important;
    }

    & .Select-value-label {
      color: ${props => props.theme.primary} !important;
    }
  }

  .performance-modal {
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
  }


.sub-menu-left .c-select-header div {
  border: none;
}

.o-breadcrumb__item--others .is-active, .o-breadcrumb__item--others:hover a {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
}
 
.Select--multi .Select-value {
   background-color: ${({ theme }) => theme.secondary};
}

.nam-dash-select .Select--multi .Select-value-icon {
  background-color: ${({ theme }) => theme.secondary} !important;
}

.u-vertical label {
   color: ${props => props.theme.fontColor} !important;
    font-family: ${props => props.theme.font} !important;
}

.o-bottom-nav__item_others a.nav-lnk, .o-bottom-nav__item_unitas a.nav-lnk {
   color: ${({ theme: { subNavBarText } }) => subNavBarText} !important;
   font-weight: bold
}

.dropdown-item.active, .dropdown-item:active {
  background-color:  ${({ theme: { subNavBar } }) => subNavBar} !important;
  color: ${({ theme: { subNavBarText } }) => subNavBarText} !important;
}

.c-support-page__guide {
    background-color:  ${({ theme: { navBar } }) => navBar};
}

// p.label-chart {
//  color: #fff !important;
// }

.negative-table-cell, .positive-table-cell {
  height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.negative-table-cell {
  background-color: #FFC7CE
}

.positive-table-cell {
  background-color: #C6EFCE
}

.rdt_TableCell > div {
  height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px
}

.c-charts-tooltip {
    background: #fff;
    color: #000;
     box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px;
  border-top: 1px solid ${({ theme: { primary } }) => primary} !important;
}

.ui-select__indicator.ui-select__loading-indicator ~ .ui-select__dropdown-indicator {
    display: none;
     pointer-events: none;
}

.is-loading .Select-loading-zone {
      position: relative;
    right: -27px;
}

.is-loading .Select-arrow {
  display: none !important;
}

.leaflet-marker-icon.c-postcode-icon.leaflet-zoom-animated.leaflet-interactive {
    width: 30px !important;
    height: 20px !important;
    background: #000;
    box-shadow: 0px 4px 32px 1px #00000029;
    border-radius: 6px;
    display: grid;
    place-content: center;
}

.leaflet-marker-icon.c-postcode-icon.leaflet-zoom-animated.leaflet-interactive span{
    font-size: .5rem;
    
}

.area-heatmap--is-zoomed .leaflet-marker-icon.c-postcode-icon.leaflet-zoom-animated.leaflet-interactive {
    width: 50px !important;
    height: 30px !important;
      border-radius: 10px;
    
}

.area-heatmap--is-zoomed .leaflet-marker-icon.c-postcode-icon.leaflet-zoom-animated.leaflet-interactive span{
    font-size: 1.2rem;
    
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  font-size: 12px
}

.tippy-tooltip.light-theme > .tippy-tooltip-content {
  background-color: #fff !important;
  color: ${({ theme }) => theme.primary} !important;
  border: 0px;
  border-top: 4px solid ${({ theme }) => theme.primary};
  padding: 2rem;
}


`;
const ThemeWrapper = ({ children }) => {
  return (
    <ThemeWrapperContainer
      className={`themed-wrapper ${
        window.location.pathname === '/dashboard' ? 'dashboard-wrapper' : ''
      }`}
      key="themed-wrapper">
      {children}
    </ThemeWrapperContainer>
  );
};

export default ThemeWrapper;
