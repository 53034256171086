import React from 'react';
import PropTypes from 'prop-types';

export default class AlchLoading extends React.Component {
	componentDidMount = ()=>{
		this.addElement();
	}

	componentDidCatch(error, info) {
		console.warn('Alch Loading: ', error, info);
	}

	componentWillUnmount = ()=>{
		this.removeElement();
	}

	addElement = ()=>{
		if ($('.js-loader').length > 0) { return; }

		const overlayStyle = `background-color: rgba(0, 0, 0, 0.8);
			z-index: 2017;
		`;

		const matchBrandColor = ($('.o-top-nav').attr('class')) ? $('.o-top-nav').attr('class').match(/t-brand-\w/) : null;
		const brandClassName = matchBrandColor ? matchBrandColor[0] : '';

		const loaderEl = $(`<div class="u-overlay js-loader ${brandClassName}" style="${overlayStyle}">
			<aside class="u-loader" />
		</div>`);

		$('body').append(loaderEl);
	}

	removeElement = ()=>{
		if (!$('.js-loader').length > 0) { return; }
		$('.js-loader').remove();
	}

	render() {
		if (this.props.loading) {
			this.addElement();
			return <span className="loading-element-placeholder" />;
		}

		this.removeElement();
		return null;
	}
}

AlchLoading.defaultProps = {
	loading: false,
};

AlchLoading.propTypes = {
	loading: PropTypes.bool,
};
