import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { showLogoutScreen } from '../../redux/reducers/user';
import BackgroundImage from '../../../assets/images/popups/logout_popup.jpg';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 99999;
  background-image: url(${BackgroundImage});
  background-size: cover;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: transparent
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 100%
    )
    0% 0% no-repeat padding-box;
`;
const TextContainer = styled.div`
  &&& {
    color: #233947 !important;
  }
  &&& h1 {
    font-size: 60px;
    margin-bottom: 1.5rem;
    color: #233947 !important;
    font-family: 'Nunito', sans-serif !important;
  }

  &&& p {
    color: #233947 !important;
    font-family: 'Nunito', sans-serif !important;
  }

  &&& a {
    color: #233947 !important;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 800;
  }

  &&& div {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled.a`
  background: transparent;
  border: 3px solid #233947;
  padding: 0.4rem 2rem;
  margin-bottom: 1rem;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
`;

const LogoutButton = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoutPopup = ({ showScreen, setShowPopup }) => {
  return (
    <>
      {showScreen && (
        <Container>
          <SubContainer className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-5 offset-1">
                <TextContainer>
                  <h1>
                    Suppliers! Enjoy an unbeatable view of your wholesale data
                    with <strong>WholeView.</strong>
                  </h1>
                  <div>
                    <StyledButton
                      href="https://twcgroup.net/wholeview/"
                      target="_self"
                      onClick={setShowPopup}>
                      WATCH THE VIDEO
                    </StyledButton>
                  </div>
                  <p>
                    Maybe another time,
                    <LogoutButton onClick={setShowPopup}>
                      &nbsp;logout &gt;
                    </LogoutButton>
                  </p>
                </TextContainer>
              </div>
            </div>
          </SubContainer>
        </Container>
      )}
    </>
  );
};
const mapStateToProps = state => ({
  showScreen: state.user.showLogoutScreen,
});

const mapDispatchToProps = dispatch => {
  return {
    setShowPopup: () => dispatch(showLogoutScreen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPopup);
