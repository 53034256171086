import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';


class TableSimple extends React.Component {
	renderHeader = (header, i)=>{
		return (
			<th colSpan={header.colspan} key={i}>
				{this.props.parseEachCell(header.text, -1, i)}
			</th>
		);
	}

	renderRow = (row, i)=>{
		return (
			<tr key={i}>
				{row.map((column, j)=>{
					return (
						<td colSpan={column.colspan} key={j}>
							{this.props.parseEachCell(column.text, i, j)}
						</td>
					);
				})}
			</tr>
		);
	}


	render() {
		if (!this.props.data || !this.props.data.headers) return null;
		return (
			<div className={`c-objects-wrap c-objects-wrap--table ${this.props.className}`} style={{position: 'relative'}}>
                          <div style={{ position: 'absolute', display: 'table', margin: '0 auto', width: '100%', height: '100%', textAlign: 'center',top: '50%'}}><ClipLoader color={'#123abc'} loading={this.props.isLoading} /></div>
				<table className={this.props.tableClassName}>
					<thead>
						<tr>
							{this.props.data.headers.map(this.renderHeader)}
						</tr>
					</thead>
					<tbody>
						{this.props.data.rows.map(this.renderRow)}
					</tbody>
				</table>
			</div>
		);
	}
}

TableSimple.defaultProps = {
	data: [],
	parseEachCell: cell=>cell,
	className: '',
  tableClassName: '',
  isLoading: false
};

TableSimple.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	parseEachCell: PropTypes.func,
	className: PropTypes.string,
  tableClassName: PropTypes.string,
  isLoading: PropTypes.bool
};

export default TableSimple;
