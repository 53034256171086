import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import StepCrumbs from './StepCrumbs';
// import { WithContext as ReactTags } from 'react-tag-input';
import { fetchUser, fetchAllPermissions, onChangeUserProps, resetUser, fetchAllCategories } from '../../redux/reducers/user';
import { getUserSystem } from '../../../helpers/util';

const User = ({ data, onNext, onChange, handleDelete, handleAddition, user }) => (
  <div className="o-canvas-wrap">
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout u-layout--between-base">
          <h2 className="o-canvas__title">Create User</h2>
        </div>
      </div>
      <div className="o-canvas__body u-layout u-layout--spaced t-brand-c">
        <div className="u-1_1">
          <StepCrumbs step={{ value: 1, label: 'User' }} id={data.id} system={getUserSystem()} />
          <div className="u-vertical pt-3">
            <div className="o-block u-1_4__inline">
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">Email</label>
                  <input type="text" name="email" value={user.email || ''} onChange={onChange} style={{ width: '300px' }} />
                </div>
              </div>
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">First Name</label>
                  <input type="text" name="firstName" value={user.firstName || ''} onChange={onChange} style={{ width: '300px' }} />
                </div>
              </div>
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">Last Name</label>
                  <input type="text" name="lastName" value={user.lastName || ''} onChange={onChange} style={{ width: '300px' }} />
                </div>
              </div>
              <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="retailclub">Telephone</label>
                  <input type="text" name="telephone" value={user.telephone || ''} onChange={onChange} style={{ width: '300px' }} />
                </div>
              </div>
              {/* <div className="u-1_4">
                <div className="c-select-wrap-component">
                  <label htmlFor="tags">Tags</label>
                  <ReactTags tags={user.tags} suggestions={[]} handleDelete={handleDelete} handleAddition={handleAddition} delimiters={[188, 13]} />
                </div>
              </div> */}
            </div>
          </div>
          <button onClick={onNext} className={`btn alchemy-btn--${getUserSystem() === 'unitas' ? 'unitas' : 'others'}  u-right`}>
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  const tags = state.user.user.tags.length > 0 ? state.user.user.tags.split(',') : [];
  let newTags = [];
  if (tags.length > 0) {
    newTags = tags.map((tag) => {
      return {
        id: tag,
        text: tag,
      };
    });
  }
  let data = {
    isLoading: state.user.isLoading,
    user: {
      ...state.user.user,
      tags: newTags,
    },
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    onChangeUserProps,
    fetchUser,
    resetUser,
    fetchAllCategories,
    fetchAllPermissions,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchAllPermissions();
      if (this.props.match.params.id) {
        this.props.fetchUser(this.props.match.params.id);
      } else {
        this.props.fetchAllCategories();
        this.props.resetUser();
      }
    },
  }),
  withState('data', 'handleData', (props) => ({ id: props.match.params.id })),
  withHandlers({
    onNext: (props) => () => {
      if (props.match.params.id) {
        props.history.push(`${process.env.APP_ROOT}create_user/account/${props.match.params.id}`);
      } else {
        props.history.push(`${process.env.APP_ROOT}create_user/account`);
      }
    },
    handleAddition: ({ data, handleData, onChangeUserProps }) => (tag) => {
      onChangeUserProps('tags', tag);
    },
    handleDelete: ({ data, handleData, user, onChangeUserProps }) => (tag) => {
      const tagValue = user.tags[tag];
      onChangeUserProps('tags', tagValue);
    },
    onChange: ({ data, handleData, onChangeUserProps }) => (event) => {
      const { name, value } = event.target;
      switch (name) {
        case 'email':
          onChangeUserProps('email', value);
          break;
        case 'firstName':
          onChangeUserProps('firstName', value);
          break;
        case 'lastName':
          onChangeUserProps('lastName', value);
          break;
        case 'telephone':
          onChangeUserProps('telephone', value);
          break;
        default:
          break;
      }
    },
  }),
)(User);
