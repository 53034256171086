import React from 'react';
import { connect } from 'react-redux';
import { filter, map } from 'lodash';
import moment from 'moment';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import { exportTableToCSV, getRandomNumber } from '../../../helpers/helpers';
import { getUserSystem } from '../../../helpers/util';
import {
  exportToPDF,
  fetchStatusReport,
  fetchTooltips,
  reset,
  fetchMissingDays,
} from '../../redux/reducers/likeForLike';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ExportIcon from '../../../assets/images/export-icon.svg';
import Msg from '../../components/ToastExportToPDF';
import TableStatusReport from '../../components/TableStatusReport';
import { ToastContainer, toast } from 'react-toastify';
import GreenTick from '../../../assets/images/green-check.svg';
import RedTick from '../../../assets/images/red-x.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Loading from '../../components/Loading';
import styled from 'styled-components';
import ProgressProvider from '../../components/ProgressProvider';
import ErrorMessage from '../../components/util/ErrorMessage';

const ReportingData = styled.p`
  display: inline-block;
  padding: 0 0 0 1rem;
`;

export const StatusReport = ({
  tooltipsData,
  timeLabel,
  statusReportData,
  totalMissingDaysPercent,
  isLoading,
  totalDaysReported,
  totalMissingDays,
  isError,
}) => {
  const [valueEnd, setValueEnd] = React.useState(totalMissingDaysPercent);

  return (
    <main>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        autoClose={false}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
      />
      <div className="sub-menu-border bg-white mb-3 section-header">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-start pb-3">
              <div className="sub-menu__left">
                <h2 className="text-uppercase">DATA STATUS OVERVIEW</h2>
              </div>
              {!totalMissingDaysPercent && (
                <div className="mt-3 ml-4">
                  <CircularProgressbar
                    styles={buildStyles({
                      display: 'inline-block',
                      pathColor: '#6cbe58',
                      pathTransitionDuration: 0.5,
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                    value={0}
                    strokeWidth={5}
                    className="circle-progress"
                    text={`${0}%`}
                  />{' '}
                </div>
              )}

              {totalMissingDaysPercent && (
                <div className="mt-3 ml-4">
                  <CircularProgressbar
                    styles={buildStyles({
                      display: 'inline-block',
                      pathColor: '#6cbe58',
                      pathTransitionDuration: 0.5,
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                    value={totalMissingDaysPercent}
                    strokeWidth={5}
                    className="circle-progress"
                    text={`${totalMissingDaysPercent}%`}
                  />

                  <ReportingData>
                    Reporting on {totalDaysReported - totalMissingDays} of{' '}
                    {totalDaysReported}.
                  </ReportingData>

                  {/* ADD IN WHEN WE CAN USE REACT HOOKS
                  <ProgressProvider valueStart={0} valueEnd={valueEnd}>
                    {(value) => <CircularProgressbar value={value} text={`${value}%`} className="circle-progress" styles={buildStyles({ display: 'inline-block', pathColor: '#6cbe58', pathTransitionDuration: 1, trailColor: '#d6d6d6', backgroundColor: '#3e98c7' })} />}
                  </ProgressProvider> */}
                </div>
              )}
              {/* <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div className="col-md-6 no-space">
                  <Dropdown isOpen={data.isExportMenuOpen} toggle={() => toggleExportMenu()}>
                    <DropdownToggle className={`dropddown-toggle-btn ${getUserSystem() === 'unitas' ? 'alchemy-btn--unitas' : 'alchemy-btn--others'}`}>
                      Export <img src={ExportIcon} alt="export" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          onExportData('csv');
                        }}
                      >
                        CSV
                      </DropdownItem>
                      {/*<DropdownItem
                        onClick={(e) => {
                          e.preventDefault();
                          onExportData('pdf');
                        }}
                      >
                        PDF
                      </DropdownItem>*/}
              {/* </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
                   </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <h2 className="pb-3">
                The below table shows the data status for each contributing
                member
              </h2>
              <div className="pull-left">
                <div className="o-circle o-circle--green pull-left">
                  <img src={GreenTick} alt="green" />
                </div>
                <span className="black-color" style={{ padding: '0px 10px' }}>
                  {' '}
                  We are reporting all data for that member for the given week
                </span>
              </div>
              <div className="pull-left pb-3">
                <div className="o-circle o-circle--red pull-left">
                  <img src={RedTick} alt="red" />
                </div>
                <span className="black-color" style={{ padding: '0px 10px' }}>
                  {' '}
                  We are not reporting all data for the given member. When doing
                  like for like comparisons the equivalent week last year will
                  also be excluded
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="status-container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="nam-dash nam-dash-table">
              {isLoading && <Loading />}
              {isError && <ErrorMessage error="Error loading report" />}
              {!isLoading && !isError && (
                <TableStatusReport
                  tableClassName="o-table o-table-stock o-table-status "
                  tooltipsData={tooltipsData}
                  data={statusReportData}
                  timeLabel={timeLabel}
                  id="stocked_table"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
function mapStateToProps(state) {
  const data = {
    statusReportData: state.likeForLike.statusReportData,
    tooltipsData: state.likeForLike.tooltipsData,
    pdfURL: state.likeForLike.pdfURL,
    isLoading: state.likeForLike.isLoading,
    isExporting: state.likeForLike.isExporting,
    isExported: state.likeForLike.isExported,
    isError: state.likeForLike.isError,
    timeLabel: state.app.timeLabel,
    totalMissingDaysPercent:
      state.likeForLike.missingDays &&
      state.likeForLike.missingDays.percentage_reporting,
    totalDaysReported:
      state.likeForLike.missingDays && state.likeForLike.missingDays.total_days,
    totalMissingDays:
      state.likeForLike.missingDays &&
      state.likeForLike.missingDays.missing_days,
  };

  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    exportToPDF,
    fetchStatusReport,
    fetchTooltips,
    reset,
    fetchMissingDays,
  }),
  lifecycle({
    componentDidMount() {
      this.props.reset();
      this.props.fetchStatusReport();
      this.props.fetchTooltips();
      this.props.fetchMissingDays();
    },
    componentDidUpdate(nextProps) {
      // if (
      //   nextProps.isLoading !== this.props.isLoading &&
      //   this.props.isLoading
      // ) {
      //   toast.info('Loading Report.Please wait...');
      // } else if (
      //   nextProps.isLoading !== this.props.isLoading &&
      //   !this.props.isLoading
      // ) {
      //   toast.dismiss();
      // }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} />, { autoClose: false });
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
    isRefreshDisabled: true,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onExportData: props => type => {
      if (type === 'csv') {
        exportTableToCSV(
          $('#stocked_table'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
      }
      /*if (type === 'pdf') {
        let html = $('#stocked_table').html();
        html = html.replace(/<img/g, '<img style="width:30px"');
        html = html.replace(/\/assets\/images\/red-x-a2ffa.svg/g, 'https://i.ibb.co/rcFcbGT/red.jpg');
        html = html.replace(/\/assets\/images\/green-check-675a0.svg/g, 'https://i.ibb.co/R7w5dRf/green.jpg');
        let htmlString = `<html>
                              <head>
                                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                                <style>
                                .hide{display: none}.o-table{width:100%}.o-table td,.o-table th{padding-left:0}.o-table .u-tac{padding-left:.8em}.o-table--nowrap th{padding:8px;white-space:nowrap}.o-table--nowrap td{padding:8px;white-space:nowrap;text-align:center}.o-table--nowrap td:first-child{text-align:left}.o-table--stripes tbody tr:nth-of-type(2n+1){background-color:#f9f9f9}  
                                </style>
                              </head>
                              <body style="margin:0px;padding:0px;">
                                  <h1 style="float:left;font-weight: 100;margin-left:10px">DATA STATUS OVERVIEW</h1>
                                  <table class="o-table o-table-stock">
                                  ${html}
                                  </table>
                              </body>
                          </html>`;
        props.exportToPDF(htmlString);
      }*/
    },
  }),
)(StatusReport);
