import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import Wholesaler from '../models/Wholesaler';
import { each } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';
import { responseNotesLocalChange } from './psa';

const SET_IS_WHOLESALER_CREATED = 'pfpWholesaler/IS_WHOLESALER_CREATED';
const SET_IS_WHOLESALER_DELETED = 'pfpWholesaler/IS_WHOLESALER_DELETED';
const SET_WHOLESALER = 'pfpWholesaler/SET_WHOLESALER';
const FETCH_WHOLESALERS = 'pfpWholesaler/FETCH_WHOLESALERS';
const SET_LOADING = 'pfpWholesaler/SET_LOADING';
const SET_FORM = 'pfpWholesaler/SET_FORM';
const SET_GROCERY = 'pfpWholesaler/SET_GROCERY';
const SET_IMPULSE = 'pfpWholesaler/SET_IMPULSE';
const SET_NONFOODS = 'pfpWholesaler/SET_NONFOODS';
const SET_LICENSED = 'pfpWholesaler/SET_LICENSED';
const SET_TOBACCO = 'pfpWholesaler/SET_TOBACCO';
const SET_FROZEN = 'pfpWholesaler/SET_FROZEN';
const SET_CHILLED = 'pfpWholesaler/SET_CHILLED';
const SET_CATERING = 'pfpWholesaler/SET_CATERING';
const SET_ONTRADE = 'pfpWholesaler/SET_ONTRADE';
const RESET_ME = 'pfpWholesaler/RESET_ME';

const baseURL = 'https://api.wsale.co.uk';

const initialState = {
  isLoading: false,
  isWholesalerCreated: false,
  isWholesalerDeleted: false,
  wholesalers: [],
  wholesalerForm: {...Wholesaler}
  };

  
const setWholesalerAction = createAction(SET_WHOLESALER);
const fetchWholesalersAction = createAction(FETCH_WHOLESALERS);
const setIsWholesalerCreatedAction = createAction(SET_IS_WHOLESALER_CREATED);
const setIsWholesalerDeletedAction = createAction(SET_IS_WHOLESALER_DELETED);
const setGroceryAction = createAction(SET_GROCERY);
const setImpulseAction = createAction(SET_IMPULSE);
const setNonFoodsAction = createAction(SET_NONFOODS);
const setLicensedAction = createAction(SET_LICENSED);
const setTobaccoAction = createAction(SET_TOBACCO);
const setFrozenAction = createAction(SET_FROZEN);
const setChilledAction = createAction(SET_CHILLED);
const setCateringAction = createAction(SET_CATERING);
const setOnTradeAction = createAction(SET_ONTRADE);
const setLoadingAction = createAction(SET_LOADING);

const setFormAction = createAction(SET_FORM);
const resetMeAction = createAction(RESET_ME);

export const onGroceryChange = (val) => (dispatch) => {
 dispatch(setGroceryAction(val));
}

export const onImpulseChange = (val) => (dispatch) => {
 dispatch(setImpulseAction(val));
}

export const onNonFoodsChange = (val) => (dispatch) => {
 dispatch(setNonFoodsAction(val));
}

export const onLicensedChange = (val) => (dispatch) => {
 dispatch(setLicensedAction(val));
}

export const onTobaccoChange = (val) => (dispatch) => {
 dispatch(setTobaccoAction(val));
}

export const onFrozenChange = (val) => (dispatch) => {
 dispatch(setFrozenAction(val));
}

export const onChilledChange = (val) => (dispatch) => {
 dispatch(setChilledAction(val));
}

export const onCateringChange = (val) => (dispatch) => {
 dispatch(setCateringAction(val));
}

export const onOnTradeChange = (val) => (dispatch) => {
 dispatch(setOnTradeAction(val));
}

export const onSaveWholesaler = (uploadFiles) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsWholesalerCreatedAction(false));
  const { wholesalerForm } = store.getState().pfpWholesaler;
  let data = {...wholesalerForm};
  delete data.Updated;
  let url = '/route.php?c=pfp/wholesaler/insert';
  //update if record ID present
  if (data.ID) {
    url = '/route.php?c=pfp/wholesaler/update';
  } else {
    delete data.ID;
  }
  let formData = new FormData();
  uploadFiles = uploadFiles || [];
  Object.keys(uploadFiles).map((fieldName) =>{
    const file = uploadFiles[fieldName];
    formData.append(fieldName, file, file.name);
    delete data[fieldName];
  });

  Object.keys(data).map((fieldName) =>{
    formData.append(fieldName, data[fieldName]);
  });

  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/json',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          },
          data: formData
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsWholesalerCreatedAction(true));
  });
}



export const fetchWholesalers = () => (dispatch) => {
  dispatch(setLoadingAction(true));
  let url = '/route.php?c=pfp/wholesaler/getWholesalers&lat=53.5228&long=1.1285&limit=9999';
  customRequest({url: baseURL + url,
    method: 'get',
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'Authorization': getToken(),
        'UserId': getUserId(),
        'System': getUserSystem()
    }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(fetchWholesalersAction(response || []));
  });
}

export const onSetWholesaler = (id, isAddWholesaler, isDelete) => (dispatch) => {
  let formData = {...Wholesaler};
  isDelete = isDelete || false;
  if (isDelete && id) {
    formData.ID = id;
    dispatch(setWholesalerAction({wholesalerForm: {...formData} }));
  }  else if(!isAddWholesaler && id) {
    dispatch(setLoadingAction(true));
    let url = '/route.php?c=pfp/wholesaler/getById&id=' + id  + '';
    customRequest({url: baseURL + url,
      method: 'get',
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
          'Authorization': getToken(),
          'UserId': getUserId(),
          'System': getUserSystem()
      }
    }).then((response) => {
      dispatch(setLoadingAction(false));
      if(_.isArray(response)) {
        response = response[0];
      }
      if(_.isObject(response) && response.ID == id) {
        formData = {...Wholesaler, ...response};
      }
      dispatch(setWholesalerAction({wholesalerForm: {...formData} }));
    });
  } else {
    dispatch(setWholesalerAction({wholesalerForm: {...formData} }));
  }

}

export const onSetForm = (fieldName, fieldValue) => (dispatch) => {
  const { wholesalerForm } = store.getState().pfpWholesaler;

  wholesalerForm[fieldName] = fieldValue;

  dispatch(setFormAction({wholesalerForm: {...wholesalerForm}}));
}


export const onDeleteWholesaler = (id) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setIsWholesalerDeletedAction(false));
  let url = `/route.php?c=pfp/wholesaler/delete&id=${id}`;
  customRequest({url: baseURL + url,
          method: 'post',
          headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded',
                  'Authorization': getToken(),
                  'UserId': getUserId(),
                  'System': getUserSystem()
          }
  }).then((response) => {
    dispatch(setLoadingAction(false));
    dispatch(setIsWholesalerDeletedAction(true));
  });
}

export const resetMe = () => (dispatch) => {
  dispatch(resetMeAction());
}

export default handleActions({
    [SET_LOADING] : (state, {payload}) => ({ ...state, isLoading: payload}),
    [SET_WHOLESALER] : (state, {payload}) => ({ ...state, wholesalerForm: payload.wholesalerForm }),
    [FETCH_WHOLESALERS] : (state, {payload}) => ({ ...state, wholesalers: payload }),
    [SET_IS_WHOLESALER_CREATED] : (state, {payload}) => ({ ...state, isWholesalerCreated: payload }),
    [SET_IS_WHOLESALER_DELETED] : (state, {payload}) => ({ ...state, isWholesalerDeleted: payload }),
    [SET_FORM] : (state, {payload}) => ({ ...state, wholesalerForm: payload.wholesalerForm }),
    [SET_GROCERY] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Grocery: payload } }),
    [SET_IMPULSE] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Impulse: payload } }),
    [SET_NONFOODS] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, ['Non Foods']: payload } }),
    [SET_LICENSED] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Licensed: payload } }),
    [SET_TOBACCO] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Tobacco: payload } }),
    [SET_FROZEN] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Frozen: payload } }),
    [SET_CHILLED] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Chilled: payload } }),
    [SET_CATERING] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, Catering: payload } }),
    [SET_ONTRADE] : (state, {payload}) => ({ ...state, wholesalerForm: { ...state.wholesalerForm, ['On Trade']: payload } }),
    [RESET_ME]: (state, {payload}) => ({...state, ...initialState}),
}, initialState);

export const selectors = {};

