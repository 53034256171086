import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Switch, Route } from 'react-router-dom';

import { YOY4Week, YOYWeekly } from '../../components/SupplierEngagement';

const YoYCharts = ({ dataTrend, data4Week, ...props }) => {
  return (
    <>
      <Button
        className="mr-4"
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/yoy_charts/weekly_value`,
          )
        }>
        Weekly Value Sales
      </Button>
      <Button
        onClick={() =>
          props.history.push(
            `${process.env.APP_ROOT}nam_dash/supplier_engagement_report/yoy_charts/four_week`,
          )
        }>
        4W Value Sales
      </Button>

      <Switch>
        <Route
          path="/nam_dash/supplier_engagement_report/yoy_charts/four_week"
          render={pageProps => <YOY4Week data={data4Week} {...pageProps} />}
        />
        <Route
          path="/nam_dash/supplier_engagement_report/yoy_charts/weekly_value"
          render={pageProps => <YOYWeekly data={dataTrend} {...pageProps} />}
        />
      </Switch>
    </>
  );
};

export default YoYCharts;
