import React from 'react';

import AlchLoading from '../AlchUI/AlchLoading';

/* eslint prefer-rest-params:0 */

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName ||
			WrappedComponent.name ||
			'Component';
}

/* PAGE CONFIG STRUCTURE PROPOSAL */
// {
// 	baseURL: Object,
// 	dataURL: Object,
// 	middleware: {
// 		base: Object,
// 		data: Object,
// 	},
// }

/* PAGE BASE STRUCTURE PROPOSAL */
// {
// 	data_type: {
// 		data_label : data,
// 		data_label2 : data,
// 	},
// 	data_type2: {
// 		data_label: data,
// 	}
// }


/**
 * pageStandard
 * - extending component using inversed inheritance
 * to provide common functionality for components
 *
 * @param {reactComponent} componentWrapper
 * @returns {reactComponent}
 */
function pageStandard(componentWrapper) {
	return class HOC extends componentWrapper {
		/* SET MEANINGFUL DISPLAY NAME */
		static displayName = `HOC_${getDisplayName(componentWrapper)}`;

		state = {
			hasError: false,
			loading: false,
			baseLoaded: false, // used to hide view when base is not loaded
			viewData: {},
			viewOptions: {},
			...this.state,
		}

		/* Flag to queue data update in */
		dueDataUpdate = false
		/* viewOptions cache */
		viewOptionsCache = {}
		/* current request data cache */
		currentRequestOptions = JSON.stringify(this.state.viewOptions)
		/* object to store loading state and xhr objects */
		dataLoaders = {}

		shouldComponentUpdate(nextProps, nextState) {
			if (super.shouldComponentUpdate) { super.shouldComponentUpdate(...arguments); }

			if (!this.state.baseLoaded && !nextState.baseLoaded) { return false; }

			return true;
		}

		componentWillMount() {
			if (super.componentWillMount) { super.componentWillMount(...arguments); }
			this.createViewBase();
			this.props.pageGotUpdated();
		}


		componentWillUpdate(nextProps, nextState) {
			/* @TODO schedule an update on depot update */
			this.checkDepotFilter(this.props, nextProps);

			/* UPDATE ON VIEW OPTION CHANGE */
			if (this.dueDataUpdate) {
				const newOptions = JSON.stringify(nextState.viewOptions);
				if (newOptions !== this.currentRequestOptions) {
					this.currentRequestOptions = JSON.stringify(nextState.viewOptions);
					/* ASSESS COMPONENT WHETHER AN UPDATE MAKE SENSE */
					if (this.shouldUpdateData &&
						this.shouldUpdateData(this.state.viewOptions, nextState.viewOptions) === false) {
						// dont update data
					} else {
						this.updateViewData(nextState);
					}
				} else {
					this.dueDataUpdate = false;
				}
			}

			if (super.componentWillUpdate) { super.componentWillUpdate(...arguments); }
		}

		componentDidCatch(error, info) {
			if (super.componentDidCatch) { super.componentDidCatch(...arguments); }
			this.setState({
				hasError: true,
			});
		}


		componentWillUnmount() {
			this.cancelAllDataLoaders();
		}

		refreshPage = ()=>{
			this.cancelAllDataLoaders();
			this.setState({
				...this.state,
				loading: false,
				baseLoaded: false,
				viewData: {},
				viewOptions: {},
				viewBase: {},
			});
			/* reset flags */
			this.viewOptionsCache = {};
			/* current request data cache */
			this.currentRequestOptions = '';
			/* object to store loading state and xhr objects */
			this.dataLoaders = {};
			this.createViewBase();
			this.props.pageGotUpdated();

			if (super.refreshPage) { super.refreshPage(); }
		}


		cancelAllDataLoaders() {
			Object.keys(this.dataLoaders).forEach((cat)=>{
				const categoryLoaders = this.dataLoaders[cat];
				Object.keys(categoryLoaders).forEach((label)=>{
					if (categoryLoaders[label] && categoryLoaders[label].abort) {
						categoryLoaders[label].abort();
						this.unsetDataLoader(cat, label);
					}
				});
			});
		}

		/* SAVE ACTIVE AJAX REQUEST */
		setDataLoader(cat, label, xhr) {
			this.dataLoaders[cat] = this.dataLoaders[cat] || {};
			/* If request exists abort it */
			if (this.dataLoaders[cat][label] && this.dataLoaders[cat][label].abort) {
				this.dataLoaders[cat][label].abort();
			}
			this.dataLoaders[cat][label] = xhr;

			/* SET LOADING STATE ON */
			if (!this.state.loading) {
				this.setState({ loading: true });
			}
		}

		/* UNSET COMPLETED REQUEST */
		unsetDataLoader(cat, label) {
			delete this.dataLoaders[cat][label];
			if (Object.keys(this.dataLoaders[cat]).length === 0) {
				delete this.dataLoaders[cat];
			}

			/* Determine if component is still loading */
			const loading = Object.keys(this.dataLoaders).some((loaderCat)=>{
				return Object.keys(this.dataLoaders[loaderCat]).length > 0;
			});

			/* Align loading state with saved loaders */
			if (this.state.loading !== loading) {
				this.setState({
					loading,
				});
			}
		}


		/**
		 * Calls for data, setup state or trigger an error
		 * - if needed supply request object for page base
		 * @param {Object} params (optional)
		 */
		createViewBase = (params)=> {
			this.getViewBase(params).then((viewBaseResponses)=>{
				/* Merge data streams into one response */
				const viewBase = viewBaseResponses.reduce((base, stream)=>{
					if (stream.label === 'root') {
						return {
							...base,
							...stream.response,
						};
					}
					base[stream.label] = stream.response;
					return base;
				}, {});
				this.setState({
					viewBase,
					baseLoaded: true,
				});
			}).catch((err)=> {
				this.setState({
					baseLoaded: true,
				});

				this.props.alertAdd(`Problem occurred getting the page data - ${err}`, 'error');
			});
		}


		/**
		 * Get page basic data for component
		 * - see example structures for ref
		 * - loop through configured object with urls and return promise.all
		 *
		 * @param {Object} [params={}] request parameters
		 * @returns {Promise}
		 */
		getViewBase = (params = {})=>{
			/* get config from page */
			const pageConfig = this.setPageConfig();

			/* Loop through object properties with urls */
			const viewRequests = this.groupRequestPromises(
				pageConfig.baseURL,
				pageConfig.middleware.base,
				params,
				'viewBase',
			);

			return Promise.all(viewRequests);
		}


		/**
		 * Set view option so it can be used for API request
		 *
		 * @param {any} optionValue - first arguments as for components its useful to return value first
		 * @param {any} optionLabel
		 */
		setViewOption = (optionValue, optionLabel)=>{
			/* Create a hook for child component */
			if (this.onSetViewOption) { this.onSetViewOption(optionValue, optionLabel, this.viewOptionsCache); }

			/* Give up reseting option to same value */
			if (this.viewOptionsCache[optionLabel] === optionValue) return;

			/* Cache this to prevent overwrite */
			this.viewOptionsCache[optionLabel] = optionValue;

			/* Schedule data update on next component update */
			this.dueDataUpdate = true;

			/* Update state options */
			this.setState({
				viewOptions: {
					...this.viewOptionsCache,
				},
			});
		}


		/**
		 * Set onchange handler and value to component
		 *
		 * @param {any} componentLabel
		 * @returns {Object} - onChange and value props
		 */
		setControlledComponent = (componentLabel)=>{
			return {
				onChange: this.setViewOption,
				onInit: this.setViewOption,
				value: this.state.viewOptions[componentLabel],
			};
		}


		/**
		 * Updates the state with data for components
		 *
		 */
		updateViewData = (state = this.state)=>{
			this.getViewData(state).then((viewDataResponses)=>{
				/* Merge data streams into one response */
				const viewDataStream = viewDataResponses.reduce((base, stream)=>{
					if (stream.label === 'root') {
						return {
							...base,
							...stream.response,
						};
					}
					base[stream.label] = stream.response;
					return base;
				}, {});

				this.dueDataUpdate = false;
				this.setState({
					viewData: {
						...this.state.viewData,
						...viewDataStream,
					},
				});
			}).catch((err)=>{
				this.props.alertAdd(`Could not get view data: ${err}`, 'error');
				this.dueDataUpdate = false;
			});
		}


		/* Create ajax request for data using view options */
		getViewData = (state = this.state)=>{
			const pageConfig = this.setPageConfig();

			const dataRequests = this.groupRequestPromises(
				pageConfig.dataURL,
				pageConfig.middleware.data,
				state.viewOptions,
				'viewData',
			);
			return Promise.all(dataRequests);
		}


		/**
		 * Group request promises in array
		 * so we can use Promise.all
		 *
		 * @param {Object} endpoints - object with keys as a labels for endpoints
		 * @param {Object} middlewares - object to align with endpoints
		 * @param {Object} params - params to be used for ajax request (viewOptions)
		 * @param {String} category - category to group loaders
		 *
		 * @returns {Array} Array of promises
		 */
		groupRequestPromises = (endpoints, middlewares = {}, params = {}, category = 'global')=>{
			return Object.keys(endpoints).reduce((requests, label)=>{
				let requestURL = endpoints[label];
				let requestParams = params;
				/* RUN REQUEST MIDDLEWARE TO CUSTOMIZE XHR PARAMS */
				if (middlewares.requests) {
					const updatedRequest = middlewares.requests(requestParams, label, requestURL) || {};
					requestURL = (updatedRequest.requestURL !== undefined) ? updatedRequest.requestURL : requestURL;
					requestParams = (updatedRequest.requestParams !== undefined) ?
						updatedRequest.requestParams : requestParams;
				}

				/* IF URL IS EMPTY DON'T BOTHER CONTINUING */
				if (!requestURL) { return requests; }


				/* IF MAKING REQUEST CREATE PROMISE WHICH WILL BE QUEUED IN */
				const request = new Promise((resolve, reject)=>{
					/* Create XHR */
					const ajaxRequest = $.ajax(requestURL, { data: requestParams });


					/* SAVE XHR */
					this.setDataLoader(category, label, ajaxRequest);

					/* RUN XHR */
					ajaxRequest.done((response)=>{
						/* Run data through middleware if supplied */
						if (middlewares[label]) {
							try {
								response = middlewares[label](response);
							} catch (e) {
								let errorMessage = 'Error parsing view data';
								if (e && e.customMessage) {
									errorMessage = e.message;
								}
								reject(`${errorMessage} (${label})`);
							}
						} else {
							/* If middleware is not defined default to empty response */
							response = null;
						}

						// remove this loader
						this.unsetDataLoader(category, label);
						// return labeled response
						resolve({
							label,
							response,
						});
					}).fail((xhr, textStatus)=>{
						if (textStatus === 'abort') {
							return; // silently ignore aborted fail so it doesn't trigger error
						}
						/* Unset only if was not aborted by another call */
						/* Original will be overwritten, and this call could cancel new one */
						if (textStatus !== 'abort') {
							this.unsetDataLoader(category, label);
						}
						reject(`Data collection has failed. (${label})`);
					});
				});
				requests.push(request);
				return requests;
			}, []);
		}

		checkDepotFilter = (current, next)=>{
			if (current.systemSettings.rehydrated &&
				next.systemSettings.depotDataUpdateDue &&
				this.state.baseLoaded &&
				!this.state.loading) {
				this.refreshPage();
			}
		}


		render() {
			/* Hide view if we dont have data */
			/* Display canvas to prevent empty page */
			if (!this.state.baseLoaded) {
				return (
					<div className="o-canvas-wrap">
						<AlchLoading loading={this.state.loading} />
						<div className="o-canvas" />
					</div>
				);
			}


			if (this.state.hasError) {
				return (
					<div className="o-canvas-wrap">
						<div className="o-canvas">
							<p>Looks like we are experiencing some issues on this page. 
								Please try again later, while we do our best to fix this</p>
						</div>
					</div>
				);
			}

			return (
				<div>
					<AlchLoading loading={this.state.loading} />
					{super.render()}
				</div>
			);
		}
	};
}

export default pageStandard;
