import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { motion, AnimatePresence } from 'framer-motion';

const FilterContainer = styled.div`
  display: flex;
  justify-content: flext-start;
  background: #d7d7d7;
  padding: 0.5rem 2rem;
  flex-wrap: wrap;
`;

const Filter = styled.div`
  background: #fff;
  color: ${props => props.theme.fontColor};
  padding: 0 1rem;
  margin: 0 0.6rem;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;

  span {
    margin-right: 1rem;
    font-family: ${props => props.theme.font};
  }
`;

const AppliedFilters = ({ appliedFilters }) => {
  return (
    <FilterContainer
      style={{ display: appliedFilters.length > 0 ? 'flex' : 'none' }}>
      <AnimatePresence>
        {appliedFilters &&
          appliedFilters.length > 0 &&
          appliedFilters.map(({ value, handler }, i) => (
            <Filter
              as={motion.div}
              key={i}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2 }}
              exit={{ opacity: 0, scale: 0 }}>
              <span>{value.Text || value.WVProdDesc}</span>
              <CancelOutlinedIcon
                onClick={() => handler('')}
                color="action"
                style={{ cursor: 'pointer' }}
              />
            </Filter>
          ))}
      </AnimatePresence>
    </FilterContainer>
  );
};

export default AppliedFilters;
