import { handleActions, createAction } from 'redux-actions';
import { request, requestGet, customRequest } from '../../../helpers/axios';
import {
  encodeText,
  getUserSystem,
  getUserNamDashMeasures,
  getToken,
  getUserId,
  removeDrillInfo,
} from '../../../helpers/util';
import { setDisableCategoryStructure } from './app';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import store from '../store';
import { Drill } from '../models/Drill';
import { push } from 'react-router-redux';
import Json2csv from 'json2csv';
import uuid from 'uuid/v4';
import { fetchAccountNames, setAccountName } from './filters';

import {
  formatUrlWithParameters,
  sortDuplicateNextLevelDown,
} from '../../../helpers/helpers';
import { setBrandUrl } from '../../../helpers/reduxHelpers/setBrandUrl';

const Json2csvParser = Json2csv.Parser;

const supplierUrl = '/route.php?c=supplier/getAll&prodPerformance=1';

const FETCH_DATA = 'product/FETCH_DATA';
const FETCH_PRODUCT_PERFORMANCE_DATA_GRAPH =
  'product/FETCH_PRODUCT_PERFORMANCE_DATA_GRAPH';
const FETCH_PRODUCT_PERFORMANCE_DATA_TABLE =
  'product/FETCH_PRODUCT_PERFORMANCE_DATA_TABLE';
const FETCH_PRODUCT_PERFORMANCE_ALL_DATA_TABLE =
  'product/FETCH_PRODUCT_PERFORMANCE_ALL_DATA_TABLE';
const SET_REPORT_DEPARTMENTS = 'product/SET_REPORT_DEPARTMENTS';
const SET_REPORT_CATEGORIES = 'product/SET_CATEGORIES';
const SET_MEASURE = 'product/SET_MEASURE';
const SET_LIKE_FOR_LIKE = 'product/SET_LIKE_FOR_LIKE';
const SET_PMP = 'product/SET_PMP';
const SET_PURCHASE = 'product/SET_PURCHASE';
const SET_TIMEFRAME = 'product/SET_TIMEFRAME';
const SET_SUBCATEGORIES = 'product/SET_SUBCATEGORIES';
const SET_LEVEL4S = 'product/SET_LEVEL4S';
const SET_LEVEL5S = 'product/SET_LEVEL5S';
const SET_SUPPLIER = 'product/SET_SUPPLIER';
const SET_SUPPLIERS = 'product/SET_SUPPLIERS';
const SET_BRANDS = 'product/SET_BRANDS';
const SET_BRAND = 'proudct/SET_BRAND';
const SET_REGION = 'report/SET_REGION';
const SET_FASCIA = 'report/SET_FASCIA';
const SET_OWN_BRAND = 'report/SET_OWN_BRAND';
const SET_SELECTED_MEMBERS = 'product/SET_SELECTED_MEMBERS';
const SET_LOADING = 'product/SET_LOADING';
const SET_LOADED = 'product/SET_LOADED';
const SET_GRAPH_LOADED = 'product/SET_GRAPH_LOADED';
const SET_TABLE_LOADED = 'product/SET_TABLE_LOADED';
const SET_HIDE_ALL = 'product/SET_HIDE_ALL';
const SET_EXPORTING = 'product/SET_EXPORTING';
const SET_EXPORTED = 'product/SET_EXPORTED';
const SET_LIMIT = 'product/SET_LIMIT';
const EXPORT_TO_PDF_SUCCESS = 'product/EXPORT_TO_PDF_SUCCESS';
const EXPORT_TO_PDF_FAILED = 'product/EXPORT_TO_PDF_FAILED';
const RESET = 'product/RESET';
const SET_TABLE_REQUEST_ID = 'product/SET_TABLE_REQUEST_ID';
const SET_CHART_REQUEST_ID = 'product/SET_CHART_REQUEST_ID';
const SET_DEPOT = 'product/SET_DEPOT';
const SET_REPORT_ERROR = 'product/SET_REPORT_ERROR';
const SET_DEPOTS = 'product/SET_DEPOTS';
const SET_CORE_RANGE = 'product/SET_CORE_RANGE';
const SET_MEMBER_TYPE = 'product/SET_MEMBER_TYPE';
const SET_RETAIL_TYPE = 'product/SET_RETAIL_TYPE';
const ADD_MEASURE = 'product/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'product/RESET_MEASURE_OPTIONS';
const SET_DATA_TYPE = 'product/SET_DATA_TYPE';
const SET_COMMISSION = 'product/SET_COMMISSION';
const SET_BUSINESS_TYPE = 'product/SET_BUSINESS_TYPE';
const SET_SELECTED_GROUP = 'product/SET_SELECTED_GROUP';
const SET_MEMBERS = 'product/SET_MEMBERS';

const initialState = {
  departments: [],
  selectedDepartments: [],
  categories: [],
  selectedCategories: [],
  subCategories: [],
  selectedSubCategories: [],
  suppliers: [],
  supplier: '',
  members: [],
  brands: [],
  brand: '',
  region: '',
  regions: [],
  fascia: '',
  fascias: [],
  selectedMembers: [],
  level4s: [],
  pdfURL: '',
  selectedLevel4s: [],
  level5s: [],
  selectedLevel5s: [],
  accountName: [],
  accountNames: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: '',
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  coreRanges: [
    { ID: 'yes', Text: 'Yes' },
    { ID: 'no', Text: 'No' },
  ],
  coreRange: '',
  memberTypes: [
    { ID: 'retail', Text: 'Retail' },
    { ID: 'foodservice', Text: 'Foodservice' },
  ],
  memberType: '',
  retailTypes: [
    { ID: 'independent', Text: 'Independent' },
    { ID: 'company_owned', Text: 'Company Owned' },
  ],
  retailType: '',
  measure: '',
  likeForLike: '',
  purchase: '',
  ownBrand: '',
  ownBrands: [
    { ID: 0, Text: 'All' },
    { ID: 1, Text: 'Yes' },
    { ID: 2, Text: 'No' },
  ],
  limit: 25,
  productPerformanceDataGraph: [],
  productPerformanceDataGraphYKeys: [],
  productPerformanceDataTable: [],
  productPerformanceAllDataTable: [],
  productPerformanceDataTableTotals: {
    subTotal: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
    grandTotal: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
    other: {
      totalTY: 0,
      totalLY: 0,
      change: 0,
    },
  },
  isLoading: false,
  isExporting: false,
  isExported: false,
  isLoaded: false,
  isGraphLoaded: false,
  isTableLoaded: false,
  hideAll: false,
  tableRequestId: '',
  chartRequestId: '',
  reportType: 'category',
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  depots: [],
  selectedDepots: [],
  reportError: '',
  dataType: { ID: 0, Text: 'Wholesale' },
  dataTypes: [
    { ID: 0, Text: 'Wholesale' },
    { ID: 1, Text: 'Retail' },
  ],
  commission: { ID: 0, Text: 'include' },
  commissions: [
    { ID: 0, Text: 'include' },
    { ID: 1, Text: 'exclude' },
  ], 
  businessTypes: [],
  selectedBusinessType: '',
  selectedGroup: [],
  groups: [],
  isRollingDateRange: false,
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchProductPerformanceDataGraphAction = createAction(
  FETCH_PRODUCT_PERFORMANCE_DATA_GRAPH,
);
const fetchProductPerformanceDataTableAction = createAction(
  FETCH_PRODUCT_PERFORMANCE_DATA_TABLE,
);
const fetchProductPerformanceAllDataTableAction = createAction(
  FETCH_PRODUCT_PERFORMANCE_ALL_DATA_TABLE,
);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setMeasureAction = createAction(SET_MEASURE);
const setCoreRangeAction = createAction(SET_CORE_RANGE);
const setMemberTypeAction = createAction(SET_MEMBER_TYPE);
const setRetailTypeAction = createAction(SET_RETAIL_TYPE);
const setLikeForLikeAction = createAction(SET_LIKE_FOR_LIKE);
const setPmpAction = createAction(SET_PMP);
const setPurchaseAction = createAction(SET_PURCHASE);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setSupplierAction = createAction(SET_SUPPLIER);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setBrandAction = createAction(SET_BRAND);
const setRegionAction = createAction(SET_REGION);
const setFasciaAction = createAction(SET_FASCIA);
const setOwnBrandAction = createAction(SET_OWN_BRAND);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setLoadingAction = createAction(SET_LOADING);
const setLoadedAction = createAction(SET_LOADED);
const setGraphLoadedAction = createAction(SET_GRAPH_LOADED);
const setTableLoadedAction = createAction(SET_TABLE_LOADED);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setExportedAction = createAction(SET_EXPORTED);
const setExportingAction = createAction(SET_EXPORTING);
const setLimitAction = createAction(SET_LIMIT);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const exportToPdfFailedAction = createAction(EXPORT_TO_PDF_FAILED);
const setTableRequestIdAction = createAction(SET_TABLE_REQUEST_ID);
const setChartRequestIdAction = createAction(SET_CHART_REQUEST_ID);
const setDepotAction = createAction(SET_DEPOT);
const resetAction = createAction(RESET);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setDepotsAction = createAction(SET_DEPOTS);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setDataTypeAction = createAction(SET_DATA_TYPE);
const setCommissionAction = createAction(SET_COMMISSION);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);
const setMembersAction = createAction(SET_MEMBERS);

export const exportToCSV = () => async dispatch => {
  let state = store.getState().productPerformanceReport;

  const guid = uuid();
  dispatch(setChartRequestIdAction(guid));

  let url =
    '/route.php?c=performance/getProductPerformanceTable&limit=25&measure=' +
    state.measure +
    '&timeframe=' +
    state.timeframe +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&retail_type=' +
    (state.retailType && state.retailType.ID ? state.retailType.ID : '') +
    '&like_for_like=' +
    state.likeForLike +
    '&type=' +
    state.purchase +
    '&pmp=' +
    state.pmp +
    '&requestId=' +
    guid;

  url +=
    state.selectedDepartments && state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories && state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories && state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s && state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s && state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  url +=
    state.selectedBusinessType.length > 0
      ? '&business_type=' +
        encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';

  url +=
    state.accountName.length > 0
      ? '&storeid=' + state.accountName
      .map(val => encodeURIComponent(val.RegNo))
      .join(',')
      : '';

  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });
  if (memberIds.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }
  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }
  if (state.dataType && state.dataType.Text) {
    url += `&dataType=${encodeURIComponent(state.dataType.Text)}`;
  }
  if (state.commission && state.commission.Text) {
    url += `&commission=${encodeURIComponent(state.commission.Text)}`;
  }

  const response = await requestGet({ url: url });
  response.results.map(result => {
    result.change = result.change * 100 + '%';
    result.marketShare = result.marketShare * 100 + '%';
  });
  const parser = new Json2csvParser([
    'ProductDescription',
    'change',
    'marketShare',
    'salesLY',
    'salesTY',
  ]);
  const url1 = window.URL.createObjectURL(
    new Blob([parser.parse(response.results)]),
  );
  const link = document.createElement('a');
  link.href = url1;
  link.setAttribute('download', 'product_performance.csv');
  document.body.appendChild(link);
  link.click();
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'product-performance-report-' + new Date().toISOString(),
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));

  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setTableLoadedAction(false));
  dispatch(setGraphLoadedAction(false));
  loadChart(store, dispatch);
  loadTable(store, dispatch);
};

export const fetchCategories = isReferal => async dispatch => {
  const userObj = JSON.parse(localStorage.user_data);
  let url = '/route.php';
  if (userObj.departments.length === 0 && userObj.categories.length === 0) {
    url += '?c=category/getHierarchy';
  } else {
    url += '?c=category/getUserHierarchy';
  }

  const state = store.getState().productPerformanceReport;
  if (state.selectedMembers.length === 1) {
    url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  dispatch(setLoadingAction(true));
  const result = await requestGet({ url: url });

  let url1 = supplierUrl;
  if (state.selectedMembers.length === 1) {
    url1 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }

  const response = await requestGet({ url: url1 });

  dispatch(fetchAccountNames());

  let regions = [];
  let fascias = [];

  if (getUserSystem() == 'smartviewRetail') {
    let urlRegion = '/route.php?c=region/getRegions';
    let regionArray = await requestGet({ url: urlRegion });
    regionArray.forEach(region => {
      regions.push({"value": region})
    });
    
    let urlFascias = '/route.php?c=fascia/getFascias';
    let fasciaArray = await requestGet({ url: urlFascias });
    fasciaArray.forEach(fascia => {
      fascias.push({"value": fascia})
    });
  }

  url = '/route.php?c=depot/getDepots';

  each(response, supp => {
    supp.SupplierName = encodeText(supp.SupplierName);
  });

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });
  let url2 = '/route.php?c=wholesaler/getAll';

  let members = await requestGet({ url: url2 });

  if (members.length === 1) {
    dispatch(setDisableCategoryStructure(false));
  }
  
  let url3 = '/route.php?c=brand/getAll';
  if (state.selectedMembers.length === 1) {
    url3 += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
  }
  let brands = await requestGet({ url: url3 });
  brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
  each(brands, brd => {
    brd.Brand = encodeText(brd.Brand);
  });
  each(members, mem => {
    mem.Text = encodeText(mem.Text);
  });
  
  if (
    members.length === 1
  ) {
    if (result.Categories && result.Categories.length > 0) {
      dispatch(setHideAllAction(true));
    }
    dispatch(
      setSelectedMembersAction([
        { Text: members[0].ActualName, ID: members[0].WholesalerNo },
      ]),
    );
    members = [members[0]];
  }

  let allDepartments = result.Departments ? result.Departments : [];

  let allCategories = result.Categories ? result.Categories : [];

  if (allDepartments.length > 1) {
    allDepartments.push({ ID: 0, Text: 'Select all', Categories: [] });
  }

  if (allCategories.length > 1) {
    allCategories.push({ ID: 0, Text: 'Select all', SubCategories: [] });
  }

  let businessTypes = [];

  try {
    const businessTypesUrl = '/route.php?c=customer/getAllBusinessTypes';
    businessTypes = await requestGet({ url: businessTypesUrl });
    businessTypes =
      businessTypes.map(businessType => {
        return { BusinessName: businessType };
      }) || [];
  } catch {
    console.warn('No business types permission');
  }

  url = '/route.php?c=groupSupplier/getAll';

  let orderedGroups = [];
  try {
    let groups = await requestGet({ url: url });

    groups = groups.map((group, i) => ({ Text: group, ID: i }));
    orderedGroups = orderBy(groups, ['Text']);
  } catch (e) {
    console.warn('Error Fetching Groups', e);
  }

  let defaultMeasure = 'Value';

  let NamDashMeasures = getUserNamDashMeasures(store.getState().performanceReport.dataType.Text);

  if (NamDashMeasures?.length > 0) {
    defaultMeasure = NamDashMeasures[0].charAt(0).toUpperCase() + NamDashMeasures[0].slice(1)
  }

  dispatch(
    fetchDataAction({
      departments: orderBy(allDepartments, ['ID']),
      categories: orderBy(allCategories, ['ID']),
      suppliers: response,
      members: members,
      brands: brands,
      regions: regions,
      fascias: fascias,
      depots: depots,
      groups: orderedGroups,
      purchase: isReferal
        ? store.getState().productPerformanceReport.purchase
        : 'out',
      measure: isReferal
        ? store.getState().productPerformanceReport.measure
        : defaultMeasure,
      coreRange: isReferal
        ? store.getState().productPerformanceReport.coreRange
        : '',
      memberType: isReferal
        ? store.getState().productPerformanceReport.memberType
        : '',
      retailType: isReferal
        ? store.getState().productPerformanceReport.retailType
        : '',
      likeForLike: isReferal
        ? store.getState().productPerformanceReport.likeForLike
        : 'like_for_like',
      pmp: isReferal ? store.getState().productPerformanceReport.pmp : '0',
      timeframe: isReferal
        ? store.getState().productPerformanceReport.timeframe
        : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
      businessTypes: businessTypes,
    }),
  );

  let categories = result.Categories || [];
  if (result.Departments && result.Departments.length === 1) {
    let dept = result.Departments[0];
    let reportType = '';
    if (
      store.getState().productPerformanceReport.reportType !== 'supplier' &&
      store.getState().productPerformanceReport.reportType !== 'member' &&
      store.getState().productPerformanceReport.reportType !== 'brand'
    ) {
      reportType = 'category';
    } else {
      reportType = store.getState().productPerformanceReport.reportType;
    }
    dispatch(setReportDepartmentsAction({ departments: [dept], reportType }));
    //set categories;
    categories = dept.Categories;
  }
  each(categories, cat => {
    cat.Text = encodeText(cat.Text);
  });

  if (categories && categories.length === 1) {
    let catt = categories[0];
    let reportType = '';
    if (
      store.getState().productPerformanceReport.reportType !== 'supplier' &&
      store.getState().productPerformanceReport.reportType !== 'member'
    ) {
      reportType = 'subcategory';
    } else {
      reportType = store.getState().productPerformanceReport.reportType;
    }
    dispatch(setReportCategoriesAction({ categories: [catt], reportType }));
    if (categories[0].SubCategories.length === 1) {
      let subcat = categories[0].SubCategories[0];
      let reportType = '';
      if (
        store.getState().productPerformanceReport.reportType !== 'supplier' &&
        store.getState().productPerformanceReport.reportType !== 'member'
      ) {
        reportType = 'level4';
      } else {
        reportType = store.getState().productPerformanceReport.reportType;
      }
      dispatch(setSubCategoriesAction({ subCategories: [subcat], reportType }));
    }
  }

  if (!store.getState().productPerformanceReport.hideAll && !isReferal) {
    dispatch(setLoadingAction(false));
    dispatch(setLoadedAction(true));
  } else {
    dispatch(setLoadingAction(false));
    dispatch(setLoadedAction(true));
  }
};

export const setOnLoadParams = (params, reportType) => async dispatch => {
  const state = store.getState().productPerformanceReport;

  dispatch(setLoadedAction(false));
  if (params.measure) dispatch(setMeasureAction(params.measure));
  if (params.coreRange) dispatch(setCoreRangeAction(params.coreRange));
  if (params.memberType) dispatch(setMemberTypeAction(params.memberType));
  if (params.retailType) dispatch(setRetailTypeAction(params.retailType));
  if (params.accountName) dispatch(setAccountName(params.accountName));
  if (params.pmp) {
    dispatch(setPmpAction(params.pmp));
  } else {
    dispatch(setPmpAction('0'));
  }
  if (params.type) dispatch(setPurchaseAction(params.type));
  if (params.timeframe) dispatch(setTimeFrameAction(params.timeframe));
  if (params.likeForLike) dispatch(setLikeForLikeAction(params.likeForLike));
  if (params.dataType) dispatch(setDataTypeAction(params.dataType));
  if (params.commission) dispatch(setDataTypeAction(params.commission));
  if (params.ownBrand)
    dispatch(setOwnBrandAction({ ID: params.ownBrand, Text: params.ownBrand }));
  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (params.departments && params.departments.length > 0) {
    _.each(params.departments, dept => {
      if (_.filter(state.departments, { Text: dept }).length > 0) {
        departments.push(_.filter(state.departments, { Text: dept })[0]);
      }
    });
    dispatch(setReportDepartmentsAction({ departments, reportType }));
  }
  if (params.categories && params.categories.length > 0) {
    _.each(params.categories, catt => {
      if (
        _.filter(flatten(map(departments, dept => dept.Categories)), {
          Text: catt,
        }).length > 0
      ) {
        categories.push(
          _.filter(flatten(map(departments, dept => dept.Categories)), {
            Text: catt,
          })[0],
        );
      }
    });
    if (categories.length > 0) {
      dispatch(setReportCategoriesAction({ categories, reportType }));
    }
  }
  if (params.subCategories && params.subCategories.length > 0) {
    _.each(params.subCategories, subcat => {
      if (
        _.filter(flatten(map(categories, cat => cat.SubCategories)), {
          Text: subcat,
        }).length > 0
      ) {
        subCategories.push(
          _.filter(flatten(map(categories, cat => cat.SubCategories)), {
            Text: subcat,
          })[0],
        );
      }
    });
    if (subCategories.length > 0) {
      dispatch(setSubCategoriesAction({ subCategories, reportType }));
    }
  }
  if (params.level4s && params.level4s.length > 0) {
    _.each(params.level4s, lvl4 => {
      if (
        _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
          Text: lvl4,
        }).length > 0
      ) {
        level4s.push(
          _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
            Text: lvl4,
          })[0],
        );
      }
    });
    if (level4s.length > 0) {
      dispatch(setLevel4sAction({ level4s, reportType }));
    }
  }
  if (params.level5s && params.level5s.length > 0) {
    _.each(params.level5s, lvl5 => {
      if (
        _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
          ID: parseInt(lvl5),
        }).length > 0
      ) {
        level5s.push(
          _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
            ID: parseInt(lvl5),
          })[0],
        );
      }
    });
    if (level5s.length > 0) {
      dispatch(setLevel5sAction({ level5s, reportType }));
    }
  }
  if (params.brand.length > 0) {
    let brand = _.filter(state.brands, brd => {
      if (brd.Brand.trim() === params.brand.trim()) {
        return brd;
      }
    });
    if (brand.length > 0) {
      let brnd = brand[0];
      brnd.reportType = reportType;
      dispatch(setBrandAction(brnd));
    }
  }

  if (typeof params.supplier !== 'undefined' && params.supplier.length > 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName.trim() === params.supplier.trim()) {
        sup.SupplierName = sup.SupplierName.trim();
        dispatch(setSupplierAction(sup));
      }
    });
  }

  if (params.groups && params.groups.length > 0) {
    dispatch(setSelectedGroupAction(params.groups));
  }

  if (params.members.length > 0) {
    const formattedMembers = params.members.map(member => ({
      Text: member.ActualName,
      WholesalerNo: member.WholesalerNo,
    }));
    dispatch(setSelectedMembersAction(formattedMembers));
    const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(
      params.members,
      v => v.Text,
    ).join(','))}`;

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    dispatch(setDepotsAction(depots));

    if (params.depots.length > 0) {
      let procDepots = [];
      each(params.depots, dept => {
        each(depots, dpt => {
          if (dpt.DepotName === dept) {
            procDepots.push(dpt);
          }
        });
      });
      dispatch(setDepotAction({ selectedDepots: procDepots }));
    }
  } else {
    dispatch(setDepotAction([]));
  }

  if (params.businessType) {
    const paramsBusinessTypes = params.businessType.split(',');
    const selectedBusinessTypes = state.businessTypes.filter(type =>
      paramsBusinessTypes.find(busType => busType === type.BusinessName),
    );

    dispatch(
      setBusinessTypeAction({
        selectedBusinessType: selectedBusinessTypes,
        reportType: state.reportType,
      }),
    );
  }

  dispatch(setHideAllAction(false));
  dispatch(setLoadedAction(true));
};

export const setSupplier = val => async dispatch => {
  const state = store.getState().productPerformanceReport;
  if (val && val.length > 0) {
    let supplier = _.filter(state.suppliers, sup => {
      if (sup.SupplierName && sup.SupplierName.trim() === val.trim()) {
        return sup;
      }
    })[0];
    dispatch(setSupplierAction(supplier));

    let url = '/route.php?c=brand/getAll';

    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    url = setBrandUrl(state, url, ['supplier', supplier]);

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

  } else {
    dispatch(setSupplierAction(''));
    let url = '/route.php?c=brand/getAll';

    if (state.selectedMembers.length === 1) {
      url += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    url = setBrandUrl(state, url, ['supplier', {}]);

    let brands = [];
    try {
      brands = await requestGet({ url });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn(error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setMember = val => async dispatch => {
  dispatch(setSelectedMembersAction(val));

  const url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(map(val, v => v.Text).join(
    ',',
  ))}`;

  const depots = await requestGet({ url: url });

  each(depots, depot => {
    depot.DepotName = encodeText(depot.DepotName);
  });

  dispatch(setDepotsAction(depots));
};

export const setBrand = val => dispatch => {
  dispatch(setBrandAction(val));
};

export const setRegion = val => dispatch => {
  dispatch(setRegionAction(val));
};

export const setFascia = val => dispatch => {
  dispatch(setFasciaAction(val));
};

export const setOwnBrand = val => dispatch => {
  dispatch(setOwnBrandAction(val));
};

export const setDepot = val => dispatch => {
  dispatch(setDepotAction({ selectedDepots: val }));
};

export const setDepartment = depts => async dispatch => {
  const state = store.getState().productPerformanceReport;

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept.Text === 'TOBACCO') {
        return dept;
      }
    });
    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    let reportType = '';
    if (
      state.reportType !== 'depot' &&
      state.reportType !== 'supplier' &&
      state.reportType !== 'member' &&
      state.reportType !== 'brand' &&
      state.reportType !== 'level4' &&
      state.reportType !== 'level5'
    ) {
      reportType = 'category';
    } else {
      reportType = state.reportType;
    }

    dispatch(setReportDepartmentsAction({ departments: depts, reportType }));

    let newSupplierUrl = supplierUrl;
    if (state.selectedMembers.length === 1) {
      newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    newSupplierUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    let suppliers = await requestGet({ url: newSupplierUrl });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    brandUrl += `&department=${encodeURIComponent(
      map(depts, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({ url: brandUrl });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  } else {
    let rptType = state.reportType;
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'department';
    }
    dispatch(
      setReportDepartmentsAction({ reportType: rptType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: rptType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));
    let newSupplierUrl = supplierUrl;
    if (state.selectedMembers.length === 1) {
      newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    let suppliers = await requestGet({
      url: newSupplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brandUrl = `/route.php?c=brand/getAll`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  }
};

export const setCategory = cats => async dispatch => {
  const state = store.getState().productPerformanceReport;
  let rptType = state.reportType;
  if (cats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setReportCategoriesAction({ categories: cats, reportType: rptType }),
    );
    let newSupplierUrl = supplierUrl +
    '&category=' +
    encodeURIComponent(map(cats, 'Text').join(',')) +
    '';
    if (state.selectedMembers.length === 1) {
      newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let suppliers = await requestGet({
      url:newSupplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));


    let brandUrl = `/route.php?c=brand/getAll&category=${encodeURIComponent(
      map(cats, 'Text').join(','),
    )}`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    dispatch(setHideAllAction(false));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand' &&
      rptType !== 'level4' &&
      rptType !== 'level5'
    ) {
      rptType = 'category';
    }
    dispatch(
      setReportCategoriesAction({ reportType: rptType, categories: [] }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));

    let newSupplierUrl = supplierUrl;
    if (state.selectedMembers.length === 1) {
      newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }

    let suppliers = await requestGet({
      url: newSupplierUrl,
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brandUrl = `/route.php?c=brand/getAll`;
    if (state.selectedMembers.length === 1) {
      brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
    }
    let brands = await requestGet({
      url: brandUrl,
    });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));

    dispatch(setHideAllAction(false));
  }
};

export const goToReferal = (val, to, skip) => dispatch => {
  removeDrillInfo(to);
  dispatch(push(val));
};

export const setSubCategory = subCats => async dispatch => {
  let state = store.getState().productPerformanceReport;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = state.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(
      setSubCategoriesAction({ subCategories: subCats, reportType: rptType }),
    );
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {
      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brands;
    try {
      
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url: brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Permission Denied', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction([]));
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {
      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
  state = store.getState().productPerformanceReport;
  if (state.level4s.length === 1 && state.level4s[0].Text !== 'NIL') {
    dispatch(
      setLevel4sAction({ level4s: state.level4s, reportType: 'level5' }),
    );
  }
};

export const setLevel4 = opts => async dispatch => {
  let state = store.getState().productPerformanceReport;
  let rptType = state.reportType;
  if (opts.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level5';
    }
    dispatch(setLevel4sAction({ level4s: opts, reportType: rptType }));
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {
      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
          encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });

      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brand Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }
    dispatch(setLevel4sAction({ reportType: rptType, level4s: [] }));
    dispatch(setLevel5sAction([]));
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {
      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Supplier Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setLevel5 = lvl5s => async dispatch => {
  let state = store.getState().productPerformanceReport;
  if (lvl5s.length > 0) {
    dispatch(setLevel5sAction(lvl5s));
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {

      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
          encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '&level5=' +
          encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '&level5=' +
      encodeURIComponent(map(state.selectedLevel5s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url:brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(setLevel5sAction([]));
    state = store.getState().productPerformanceReport;
    let suppliers;
    try {
      let newSupplierUrl = '/route.php?c=supplier/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
          encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        newSupplierUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      suppliers = await requestGet({
        url:newSupplierUrl,
      });
      if (typeof suppliers == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      suppliers = [];
      console.warn('Failed Supplier Fetch', error);
    }
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    let brands;
    try {
      let brandUrl = '/route.php?c=brand/getAll&department=' +
      encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
      '&category=' +
      encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
      '&subcategory=' +
      encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
      '&level4=' +
      encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
      '';
      if (state.selectedMembers.length === 1) {
        brandUrl += '&member=' + encodeURIComponent(state.selectedMembers[0].Text)
      }
      brands = await requestGet({
        url: brandUrl,
      });
      if (typeof brands == 'string') {
        throw 'Permission Denied';
      }
    } catch (error) {
      brands = [];
      console.warn('Failed Brands Fetch', error);
    }
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setDataType = val => dispatch => {
  dispatch(setDataTypeAction(val));
};

export const setCommission = val => dispatch => {
  dispatch(setCommissionAction(val));
};

export const setTimeFrame = val => dispatch => {
  dispatch(setTimeFrameAction(val));
};

export const setMeasure = val => dispatch => {
  dispatch(setMeasureAction(val));
};

export const setCoreRange = val => dispatch => {
  dispatch(setCoreRangeAction(val));
};

export const setMemberType = val => dispatch => {
  dispatch(setMemberTypeAction(val));
};

export const setRetailType = val => dispatch => {
  dispatch(setRetailTypeAction(val));
};

export const setLikeForLike = val => dispatch => {
  dispatch(setLikeForLikeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const setBusinessType = val => dispatch => {
  dispatch(
    setBusinessTypeAction({
      selectedBusinessType: val,
      reportType: store.getState().performanceReport.reportType,
    }),
  );
};

export const setPmp = val => dispatch => {
  dispatch(setPmpAction(val));
};

export const setLimit = limit => dispatch => {
  dispatch(setLimitAction(limit.value));
};

const loadChart = (store, dispatch) => {
  dispatch(setGraphLoadedAction(false));
  let state = store.getState().productPerformanceReport;
  const guid = uuid();
  dispatch(setChartRequestIdAction(guid));

  const user = store.getState().user.authUser;

  let url = formatUrlWithParameters(
    `/route.php?c=performance/getProductPerformance&requestId=${guid}&limit=${state.limit}`,
    state,
    user,
  );

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      if (response.requestId) {
        if (
          response.requestId ===
          store.getState().productPerformanceReport.chartRequestId
        ) {
          let data = [];
          let yKeys = ['a', 'b'];
          let values = response.results;
          _.each(values, result => {
            let obj = {};
            obj['y'] = result.ProductDescription;
            obj['a'] = result.salesTY;
            obj['b'] = result.salesLY;
            data.push(obj);
          });
          dispatch(
            fetchProductPerformanceDataGraphAction({
              data: data,
              yKeys: yKeys,
            }),
          );
          dispatch(setLoadingAction(false));
          dispatch(setGraphLoadedAction(true));
        }
      } else {
        dispatch(
          fetchProductPerformanceDataGraphAction({ data: [], yKeys: [] }),
        );
        dispatch(setLoadingAction(false));
        dispatch(setGraphLoadedAction(true));
      }
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setGraphLoadedAction(true));
    });
};

export const exportAllToCSV = () => async dispatch => {
  let state = store.getState().productPerformanceReport;

  const guid = uuid();
  dispatch(setChartRequestIdAction(guid));

  let url =
    '/route.php?c=performance/getProductPerformanceTable&limit=1000&measure=' +
    state.measure +
    '&timeframe=' +
    state.timeframe +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&retail_type=' +
    (state.retailType && state.retailType.ID ? state.retailType.ID : '') +
    '&like_for_like=' +
    state.likeForLike +
    '&type=' +
    state.purchase +
    '&pmp=' +
    state.pmp +
    '&requestId=' +
    guid;

  url +=
    state.selectedDepartments && state.selectedDepartments.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories && state.selectedCategories.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories && state.selectedSubCategories.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s && state.selectedLevel4s.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel5s && state.selectedLevel5s.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  url += state.supplier.SupplierName
    ? '&supplier=' + encodeURIComponent(state.supplier.SupplierName)
    : '';
  url += state.brand.Brand
    ? '&brand=' + encodeURIComponent(state.brand.Brand)
    : '';
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  url +=
    state.selectedBusinessType !== ''
      ? '&business_type=' + encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';
  url +=
    state.accountName.length > 0
      ? '&storeid=' + state.accountName
      .map(val => encodeURIComponent(val.RegNo))
      .join(',')
      : '';

  let memberIds = [];

  _.each(state.selectedMembers, member => {
    memberIds.push(member.Text);
  });
  if (memberIds.length > 0) {
    url += '&member=' + encodeURIComponent(memberIds);
  }

  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }
  if (state.dataType && state.dataType.Text) {
    url += `&dataType=${encodeURIComponent(state.dataType.Text)}`;
  }
  if (state.commission && state.commission.Text) {
    url += `&commission=${encodeURIComponent(state.commission.Text)}`;
  }

  const response = await requestGet({ url: url });
  response.results.map(result => {
    result.change = result.change * 100 + '%';
    result.marketShare = result.marketShare * 100 + '%';
  });
  const parser = new Json2csvParser([
    'ProductDescription',
    'change',
    'marketShare',
    'salesLY',
    'salesTY',
  ]);
  const url1 = window.URL.createObjectURL(
    new Blob([parser.parse(response.results)]),
  );
  const link = document.createElement('a');
  link.href = url1;
  link.setAttribute('download', 'product_performance.csv');
  document.body.appendChild(link);
  link.click();
};

const loadTable = (store, dispatch) => {
  dispatch(setTableLoadedAction(false));
  dispatch(setReportErrorAction(''));
  let state = store.getState().productPerformanceReport;
  const guid = uuid();
  dispatch(setTableRequestIdAction(guid));

  const user = store.getState().user.authUser;

  let url = formatUrlWithParameters(
    `/route.php?c=performance/getProductPerformanceTable&requestId=${guid}&limit=${state.limit}`,
    state,
    user,
  );

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(response => {
      if (
        response.requestId &&
        response.results &&
        response.results.length > 0
      ) {
        if (
          response.requestId ===
          store.getState().productPerformanceReport.tableRequestId
        ) {
          let data = [];
          _.each(response.results, result => {
            data.push({
              field: result.ProductDescription,
              marketShare: result.marketShare,
              salesTY: result.salesTY,
              salesLY: result.salesLY,
              change: result.change,
            });
          });
          dispatch(
            fetchProductPerformanceDataTableAction({
              data,
              totals: response.totals,
            }),
          );
          dispatch(setLoadingAction(false));
          dispatch(setTableLoadedAction(true));
        }
      } else {
        dispatch(setLoadingAction(false));
        dispatch(setTableLoadedAction(true));
        dispatch(
          setReportErrorAction('Failed to load report, please try again.'),
        );
      }
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setTableLoadedAction(true));
    });
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }
  dispatch(setMembersAction(members));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => {
      let orderedDepartments = uniqBy(
        orderBy(
          filter(
            payload.departments,
            cat => cat !== undefined && cat.Text !== 'Select All',
          ),
          ['Text'],
        ),
        'Text',
      );
      if (orderedDepartments.length > 1) {
        orderedDepartments.unshift({
          ID: 0,
          Text: 'Select all',
          SubCategories: [],
        });
      }

      return {
        ...state,
        departments: orderedDepartments,
        categories: payload.categories,
        suppliers: payload.suppliers,
        brands: payload.brands,
        regions: payload.regions,
        fascias: payload.fascias,
        members: payload.members,
        purchase: payload.purchase,
        measure: payload.measure,
        coreRange: payload.coreRange,
        memberType: payload.memberType,
        retailType: payload.retailType,
        likeForLike: payload.likeForLike,
        pmp: payload.pmp,
        timeframe: payload.timeframe,
        depots: payload.depots,
        businessTypes: payload.businessTypes,
        groups: payload.groups,
      };
    },
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        payload,
      ],
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      let orderedCategories = uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedCategories.length > 1) {
        orderedCategories.unshift({
          ID: 0,
          Text: 'Select all',
          SubCategories: [],
        });
      }
      return {
        ...state,
        selectedDepartments: payload.departments,
        categories: orderedCategories,
        reportType: payload.reportType,
      };
    },
    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );

      let orderedSubCategories = orderBy(
        filter(allSubCategories, subCat => subCat !== undefined),
        ['Text'],
      );
      orderedSubCategories = sortDuplicateNextLevelDown(
        orderedSubCategories,
        'Level4',
      );

      orderedSubCategories = uniqBy(orderedSubCategories, 'Text');

      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType,
      };
    },
    [SET_CHART_REQUEST_ID]: (state, { payload }) => ({
      ...state,
      chartRequestId: payload,
    }),
    [SET_TABLE_REQUEST_ID]: (state, { payload }) => ({
      ...state,
      tableRequestId: payload,
    }),
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );

      let orderedLvl4s = uniqBy(
        orderBy(
          filter(allLevel4s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl4s.length > 1) {
        orderedLvl4s.unshift({ ID: 0, Text: 'Select all', Level5s: [] });
      }

      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: orderedLvl4s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );

      let orderedLvl5s = uniqBy(
        orderBy(
          filter(allLevel5s, lvl => lvl !== undefined),
          ['Text'],
        ),
        'ID',
      );
      if (orderedLvl5s.length > 1) {
        orderedLvl5s.unshift({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: orderedLvl5s,
        reportType: payload.reportType,
      };
    },
    [SET_LEVEL5S]: (state, { payload }) => ({
      ...state,
      selectedLevel5s: payload.level5s,
      reportType: payload.reportType,
    }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),
    [SET_SUPPLIER]: (state, { payload }) => ({ ...state, supplier: payload }),
    [SET_REGION]: (state, { payload }) => ({ ...state, region: payload }),
    [SET_FASCIA]: (state, { payload }) => ({ ...state, fascia: payload }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload }),
    [SET_OWN_BRAND]: (state, { payload }) => ({
      ...state,
      ownBrand: payload,
    }),
    [SET_DATA_TYPE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: payload.Text === 'Retail' ? 'Retail Units' : 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
      dataType: payload,
    }),
    [SET_COMMISSION]: (state, { payload }) => ({
      ...state,
      commission: payload,
    }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_MEMBERS]: (state, { payload }) => ({
      ...state,
      members: payload,
    }),
    [SET_BRANDS]: (state, { payload }) => ({ ...state, brands: payload }),
    [SET_SUPPLIERS]: (state, { payload }) => ({ ...state, suppliers: payload }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_MEASURE]: (state, { payload }) => ({ ...state, measure: payload }),
    [SET_CORE_RANGE]: (state, { payload }) => ({ ...state, coreRange: payload }),
    [SET_MEMBER_TYPE]: (state, { payload }) => ({ ...state, memberType: payload }),
    [SET_RETAIL_TYPE]: (state, { payload }) => ({ ...state, retailType: payload }),
    [SET_LIKE_FOR_LIKE]: (state, { payload }) => ({
      ...state,
      likeForLike: payload,
    }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_TIMEFRAME]: (state, { payload }) => ({ ...state, timeframe: payload }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_GRAPH_LOADED]: (state, { payload }) => ({
      ...state,
      isGraphLoaded: payload,
    }),
    [SET_TABLE_LOADED]: (state, { payload }) => ({
      ...state,
      isTableLoaded: payload,
    }),
    [SET_LIMIT]: (state, { payload }) => ({ ...state, limit: payload }),
    [FETCH_PRODUCT_PERFORMANCE_DATA_GRAPH]: (state, { payload }) => ({
      ...state,
      productPerformanceDataGraph: payload.data,
      productPerformanceDataGraphYKeys: payload.yKeys,
    }),
    [FETCH_PRODUCT_PERFORMANCE_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      productPerformanceDataTable: payload.data,
      productPerformanceDataTableTotals: payload.totals,

      isRollingDateRange: state.timeframe.includes('Rolling'),
    }),
    [FETCH_PRODUCT_PERFORMANCE_ALL_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      productPerformanceAllDataTable: payload,
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload.selectedBusinessType,
      reportType: payload.reportType,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      departments: [],
      selectedDepartments: [],
      categories: [],
      selectedCategories: [],
      subCategories: [],
      selectedSubCategories: [],
      suppliers: [],
      supplier: '',
      members: [],
      region: '',
      regions: [],
      fascia: '',
      fascias: [],
      brands: [],
      brand: '',
      selectedMembers: [],
      level4s: [],
      pdfURL: '',
      selectedLevel4s: [],
      level5s: [],
      selectedLevel5s: [],
      timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
        { ID: '4wks', Text: '4 Wk' },
        { ID: '13wks', Text: '13 Wk' },
        { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
        { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
        { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
        { ID: 'YTD', Text: 'YTD' },
        { ID: 'MAT', Text: 'MAT' },
      ],
      timeframe: '',
      purchases: [
        { ID: 'in', Text: 'In' },
        { ID: 'out', Text: 'Out' },
      ],
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
      likeForLikes: [
        { ID: 'like_for_like', Text: 'Like For Like' },
        { ID: 'absolute', Text: 'Absolute' },
      ],
      coreRanges: [
        { ID: 'yes', Text: 'Yes' },
        { ID: 'no', Text: 'No' },
      ],
      coreRange: '',
      memberTypes: [
        { ID: 'retail', Text: 'Retail' },
        { ID: 'foodservice', Text: 'Foodservice' },
      ],
      memberType: '',
      retailTypes: [
        { ID: 'independent', Text: 'Independent' },
        { ID: 'company_owned', Text: 'Company Owned' },
      ],
      retailType: '',
      measure: '',
      likeForLike: '',
      purchase: '',
      ownBrand: '',
      ownBrands: [
        { ID: 0, Text: 'All' },
        { ID: 1, Text: 'Yes' },
        { ID: 2, Text: 'No' },
      ],
      limit: 25,
      productPerformanceDataGraph: [],
      productPerformanceDataGraphYKeys: [],
      productPerformanceDataTable: [],
      productPerformanceAllDataTable: [],
      productPerformanceDataTableTotals: {
        subTotal: {
          totalTY: 0,
          totalLY: 0,
          change: 0,
        },
        grandTotal: {
          totalTY: 0,
          totalLY: 0,
          change: 0,
        },
        other: {
          totalTY: 0,
          totalLY: 0,
          change: 0,
        },
      },
      isLoading: false,
      isExporting: false,
      isExported: false,
      isLoaded: false,
      isGraphLoaded: false,
      isTableLoaded: false,
      hideAll: false,
      tableRequestId: '',
      chartRequestId: '',
      reportType: 'category',
      pmpList: [
        { ID: '0', Text: 'Parent' },
        { ID: '1', Text: 'Child' },
      ],
      pmp: '',
      depots: [],
      selectedDepots: [],
      reportError: '',
      dataType: { ID: 0, Text: 'Wholesale' },
      dataTypes: [
        { ID: 0, Text: 'Wholesale' },
        { ID: 1, Text: 'Retail' },
      ],
      commission: { ID: 0, Text: 'include' },
      commissions: [
        { ID: 0, Text: 'include' },
        { ID: 1, Text: 'exclude' },
      ],
      businessTypes: [],
      selectedBusinessType: '',
      selectedGroup: [],
      groups: [],
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
