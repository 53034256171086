import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { difference } from 'underscore';

/* eslint no-underscore-dangle: 0 */

class AlchSelect extends Component {
	state = {
		id: this.props.id,
		init: false, // will become ref for instance
		value: undefined,
		sort: 'default',
	}

	componentWillMount() {
		/* Require scripts and styles */
		import('select2');
		import('select2/src/scss/core.scss');

		if ($(`#${this.state.id}`).length > 1) {
			console.error(`AlchSelect: Conflicting select id (${this.state.id}) found!`);
		}
	}

	componentDidMount() {
		if (this.props.children.length > 0) {
			this.initSelect2();
		}

		this._isMounted = true;
	}

	componentWillReceiveProps(nextProps) {
		if (Array.isArray(nextProps.value) && Array.isArray(this.state.value)) {
			if (difference(nextProps.value, this.state.value).length === 0 &&
				nextProps.value.length === this.state.value.length) {
				return;
			}
		}

		if (nextProps.value && nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value,
			});
		}
	}

	shouldComponentUpdate(nextProps) {
		/* Dont update if without children as theres no point */
		if (this.props.children.length === 0 && nextProps.children.length === 0) {
			return false;
		}

		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.children.length !== this.props.children.length && this.props.children.length > 0) {
			this.initSelect2();
		}

		if (prevState.sort !== this.state.sort) {
			this.initSelect2();
		}

		if (Array.isArray(prevProps.value) && Array.isArray(this.state.value)) {
			if (difference(prevProps.value, this.props.value).length === 0 &&
			prevProps.value.length === this.props.value.length) {
				return;
			}
		}

		if (this.state.init && prevProps.value !== this.props.value) {
			this.state.init.val(this.props.value).trigger('change');
		}
	}

	componentWillUnmount = ()=>{
		this._isMounted = false;
	}

	onChange = ()=>{
		const changeValue = $(`#${this.state.id}`).val();
		/* Dont trigger value change if its equal to current */
		if (changeValue === this.state.value) { return; }
		if (changeValue && this.state.value &&
		difference(changeValue, this.state.value).length === 0 &&
		changeValue.length === this.props.value.length) {
			return;
		}

		const selectName = this.props.name || this.state.id;
		if (!this.props.value) {
			this.setState({
				value: changeValue,
			});
		}
		this.props.onChange(changeValue, selectName);
	}

	setValue = ()=>{
		if (this.props.children.length === 0) { return false; }

		/* Set selected value */
		if (this.props.value) {
			this.setState({
				value: this.props.value,
			});
			return;
		}

		// Initialize default value only if current is empty
		if (this.props.defaultValue && this.state.value === undefined) {
			this.setState({
				value: this.props.defaultValue,
			});
			return;
		}

		// If all failed choose first child
		if (!this.props.multiple && this.props.placeholder === '') {
			this.setState({
				value: this.props.children[0].id,
			});
		}
	}

	formatOptions = (item)=>{
		if (item.parent) {
			return $(`<div class="c-select__parent">${item.text}</div>`);
		}
		return item.text;
	}

	templateSelection = (item)=>{
		const $selectEl = $(`#${this.state.id}`);
		if (!this.props.multiple || $selectEl.val().length < 2) {
			return $(`
				<span
					class="select2-selection__rendered
					${item.parent ? 'select2-selection__parent' : ''}
					"
					title="${item.id}"
				>
					${item.text}
				</span>
			`);
		}

		return $(`
			<span
				class="select2-selection__rendered select2-selection__multiple"
			>
				${$selectEl.val().length} items selected
			</span>
		`);
	}


	/* Initialize select2 on select element - can be used to re-init */
	initSelect2 = ()=>{
		this.setValue();
		/* repeat import to make sure asset is available */
		import('select2').then(()=>{
			if (this._isMounted !== true) {
				return;
			}

			const selectId = this.state.id;

			const children = Array.from(this.props.children);
			if (this.state.sort !== 'default') {
				children.sort((itemA, itemB)=>{
					const valueA = parseFloat(itemA[this.state.sort]);
					const valueB = parseFloat(itemB[this.state.sort]);
					return valueB - valueA;
				});
			}

			const select2Config = {
				minimumResultsForSearch: (this.props.search) ? '0' : 'Infinity',
				placeholder: this.props.placeholder,
				templateResult: this.formatOptions,
				templateSelection: this.templateSelection,
				data: children,
			};

			/* use max select only if other than default */
			if (this.props.maxSelect !== 1) {
				select2Config.maximumSelectionLength = this.props.maxSelect;
			}

			/* Destroy select2 if already been initialized to prevent duplicate inits */
			/* Removing option elements as it would use it again on next init */
			if (this.state.init) {
				$(`#${selectId}`).select2('destroy');
				$(`#${selectId}`).html(null);
				$(`#${selectId}`).off('change', this.onChange);
			}

			/* initialize select2 */
			const select2Instance = $(`#${selectId}`).select2(select2Config);

			/* add event listened */
			$(`#${selectId}`).on('change', this.onChange);

			/* set default value */
			select2Instance.val(this.state.value).trigger('change');

			/* set init flag on */
			if (this.state.init === false) {
				this.setState({
					init: select2Instance,
				});
				const selectName = this.props.name || this.state.id;
				this.props.onInit(this.state.value, selectName);
			}
		});
	}

	reorder = (id, e)=>{
		e.preventDefault();
		this.setState({
			sort: id,
		});

		this.props.onSort();
	}

	toggleAll = (select, e)=>{
		const $selectEl = $(`#${this.state.id}`);
		if ($selectEl.length === 0) { return; }
		e.target.blur();
		e.preventDefault();

		let selected;
		/* deselect all */
		if (select === false) {
			selected = '';
		}

		/* select all */
		if (select === true) {
			selected = this.props.children.map(c=>c.id);
		}

		$selectEl.val(selected);
		$selectEl.trigger('change');
	}

	renderOrderButton = ()=>{
		const { sort } = this.props;
		if (sort.length < 2) { return null; }

		/* Find next label, either next in array or first */
		const currentI = this.props.sort.findIndex(i=>i.id === this.state.sort);
		const nextSelect = (sort[currentI + 1]) ? sort[currentI + 1] : sort[0];

		return (
			<div className="c-select__sort-wrap">
				<button
					className="c-select__sort"
					onClick={e=>this.reorder(nextSelect.id, e)}
				>
					{nextSelect.text}
				</button>
			</div>
		);
	}

	renderSelectAll = ()=>{
		return ([
			<button
				className="c-select__button"
				key="select_all"
				onClick={(e)=>{ this.toggleAll(true, e); }}
			>Select all</button>,
			<button
				className="c-select__button c-select__button--secondary"
				key="deselect_all"
				onClick={(e)=>{ this.toggleAll(false, e); }}
			>Deselect all</button>,
		]);
	}

	render() {
		const label = this.props.label;
		const selectId = this.state.id;
		const selectName = this.props.name || selectId;

		const selectClasses = ['c-select'];
		if (this.props.multiple) {
			selectClasses.push('c-select--multiple');
		}
		if (this.props.className) {
			selectClasses.push(this.props.className);
		}

		if (this.props.children.length < 1) { return null; }

		return (
			<div className="c-select-wrap-component">
				{
					label && <label className={this.props.labelClassName} htmlFor={selectId}>{label}</label>
				}
				{
					this.props.selectAll && this.renderSelectAll()
				}
				<select
					name={selectName}
					id={selectId}
					value={this.state.value}
					multiple={this.props.multiple}
					className={selectClasses.join(' ')}
				>
					{
						this.props.placeholder !== '' &&
						<option value="" />
					}
				</select>
				{this.renderOrderButton()}
			</div>
		);
	}
}

AlchSelect.defaultProps = {
	name: undefined,
	children: Array.prototype,
	value: undefined,
	defaultValue: undefined,
	className: undefined,
	multiple: false,
	label: undefined,
	labelClassName: undefined,
	placeholder: '',
	search: false,
	maxSelect: 1,
	selectAll: false,
	onChange: Function.prototype,
	onInit: Function.prototype,
	onSort: Function.prototype,
	sort: Array.prototype,
};

AlchSelect.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	/* strict shape of array for children dropdown */
	children: PropTypes.array,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	defaultValue: PropTypes.string,
	className: PropTypes.string,
	multiple: PropTypes.bool,
	label: PropTypes.string,
	labelClassName: PropTypes.string,
	placeholder: PropTypes.string,
	search: PropTypes.bool,
	maxSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	selectAll: PropTypes.bool,
	onChange: PropTypes.func,
	onInit: PropTypes.func,
	onSort: PropTypes.func,
	sort: PropTypes.array,
};

export default AlchSelect;
