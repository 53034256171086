import { handleActions, createAction } from 'redux-actions';
import moment from 'moment';
import { requestGet, request, requestBlob } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
  getUserRoles,
} from '../../../helpers/util';
import { push } from 'react-router-redux';
import store from '../store';
import * as _ from 'lodash';
import { type } from 'jquery';
import { resetUser } from './user';
import { filter } from 'lodash';

const FETCH_COMPLETED_DATA = 'query/FETCH_COMPLETED_DATA';
const FETCH_QUERY = 'query/FETCH_QUERY';
const SET_QUERY_NAME = 'query/SET_QUERY_NAME';
const SAVE_QUERY = 'query/SAVE_QUERY';
const FETCH_HISTORY = 'query/FETCH_HISTORY';
const FETCH_DATA = 'query/FETCH_DATA';
const SET_SELECTED_MEMBERS = 'query/SET_SELECTED_MEMBERS';
const SET_CATEGORIES = 'query/SET_CATEGORIES';
const SET_SUBCATEGORIES = 'query/SET_SUBCATEGORIES';
const SET_LEVEL4S = 'query/SET_LEVEL4S';
const SET_LEVEL5S = 'query/SET_LEVEL5S';
const SET_LOADING = 'query/SET_LOADING';
const SET_TIMER = 'query/SET_TIMER';
const CLEAR_TIMER = 'query/CLEAR_TIMER';
const SET_STATUS_COMPLETED = 'query/SET_STATUS_COMPLETED';
const SET_SELECTED_QUERY = 'query/SET_SELECTED_QUERY';
const SET_RERUN_QUERY = 'query/SET_RERUN_QUERY';
const UPDATE_QUERY_FIELD_VISIBILITY = 'query/UPDATE_QUERY_FIELD_VISIBILITY';
const REMOVE_DATE_RANGE = 'query/REMOVE_DATE_RANGE';
const ADD_DATE_RANGES = 'query/ADD_DATE_RANGES';
const ADD_WHOLESALER = 'query/ADD_WHOLESALER';
const UPDATE_FIELD = 'query/UPDATE_FIELD';
const SAVE_FILTER = 'query/SAVE_FILTER';
const SAVE_REORDER_COLUMNS = 'query/SAVE_REORDER_COLUMNS';
const SAVE_REORDER_DATA = 'query/SAVE_REORDER_DATA';
const PIVOT_CHANGE_DATA = 'query/PIVOT_CHANGE_DATA';
const UPDATE_DATE_RANGE = 'query/UPDATE_DATE_RANGE';
const RESET_QUERY_BUILDER = 'query/RESET_QUERY_BUILDER';
const FETCH_TIMEFRAMES = 'query/FETCH_TIMEFRAMES';
const QUERY_SCHEDULED = 'query/QUERY_SCHEDULED';
const RESET_QUERY_SCHEDULE = 'query/RESET_QUERY_SCHEDULE';
const FETCH_PRODUCTS = 'query/FETCH_PRODUCTS';
const SET_IS_DOWNLOADING = 'query/SET_IS_DOWNLOADING';

const initialState = {
  isLoading: false,
  isDownloading: false,
  name: '',
  core_range_description: '',
  core_range_pack_size: '',
  core_range_pmp: '',
  core_range_ew: '',
  core_range_scot: '',
  core_range_ni: '',
  best_seller: '',
  departments: [],
  department: {},
  categoryHierarchy: [],
  categories: [],
  subCategories: [],
  level4s: [],
  level5s: [],
  brands: [],
  businessTypes: [],
  productsForDesc: [],
  membersProductCode: [],
  productsForCode: [],
  members: [],
  selectedMembers: [],
  suppliers: [],
  queries: [],
  queryId: '',
  queryStatus: '',
  timer: '',
  selectedQuery: {},
  completedData: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [],
  defaultDateRange: null,
  isQueryScheduled: false,
  depots: [],
  supplierReporting: [
    { fieldKey: 'yes', fieldValue: 'Yes', label: 'Yes' },
    { fieldKey: 'no', fieldValue: 'No', label: 'No' },
  ],
  builtQuery: {
    uid: '',
    Date_Created: '',
    Show_YearNumber: 0,
    Show_DepotNo: 0,
    Show_CustomerCount: 0,
    Show_Value: 0,
    Show_Volume: 0,
    Show_ValueIn: 0,
    Show_VolumeIn: 0,
    Show_QtyHectolitres: 0,
    Show_Qty9L: 0,
    Show_QtyStick: 0,
    Show_QtyGrams: 0,
    Show_YearNoWeekNo: 0,
    Show_RepCode: 0,
    Show_RegNo: 0,
    Show_WholesalerName: 0,
    Show_Department: 0,
    Show_CategoryName: 0,
    Show_Level4: 0,
    Show_Brand: 0,
    Show_BusinessType: 0,
    Show_Level5: 0,
    Show_SalePrice: 0,
    Show_CostPrice: 0,
    Show_SupplierName: 0,
    Show_Pack: 0,
    Show_Size: 0,
    Show_PMP: 0,
    Show_SubGroup: 0,
    Show_SGDescription: 0,
    Show_Member: 0,
    Show_Depot: 0,
    Show_CustomerType: 0,
    Show_Weeks: 0,
    Show_LikeForLike: 0,
    dataType: 0,
    Show_ProductDescription: 0,
    Show_MemberProductCode: 0,
    Show_ProductCode: 0,
    Show_Competitor: 1,
    Pivot_Table_Enabled: 0,
    Pivot_Table: {
      columns: [],
      rows: [],
      values: [],
    },
    Show_Val13WK_TY: 0,
    Show_WeekNo: 0,
    Show_WeekCommenceDate: 0,
    Show_DepotName: 0,
    Show_HTDrinksCustomerCategory: 0,
    Show_CoreRangeDescription: 0,
    Show_CoreRangePackSize: 0,
    Show_CoreRangeCategory: 0,
    Show_CoreRangePmp: 0,
    Show_CoreRangeEw: 0,
    Show_CoreRangeScot: 0,
    Show_CoreRangeNi: 0,
    Show_BestSeller: 0,
    Filter_AccountNames: '',
    Filter_YearNumber: '',
    Filter_SupplierCode: '',
    Filter_DepotNo: '',
    Filter_YearNoWeekNo: '',
    Filter_RepCode: '',
    Filter_RegNo: '',
    Filter_Department: '',
    Filter_CategoryName: '',
    Filter_Level4: '',
    Filter_Brand: '',
    Filter_Level5: '',
    Filter_Brand: '',
    Filter_WholesalerName: '',
    Filter_SupplierName: '',
    Filter_BusinessType: '',
    Filter_ProductDescription: '',
    Filter_MemberProductCode: '',
    Filter_ProductCode: '',
    Filter_Pack: '',
    Filter_Size: '',
    Filter_PMP: '',
    Filter_SubGroup: '',
    Filter_SGDescription: '',
    Filter_SupplierReporting: '',
    Filter_WholesalerSupplier: '',
    Filter_AdvancedCustomerType1: '',
    Filter_AdvancedCustomerType2: '',
    Filter_AdvancedCustomerType3: '',
    Filter_AdvancedCustomerType4: '',
    Filter_AdvancedCustomerType5: '',
    id: '',
    Column_Order: [],
    tempPrePivotOrder: [],
    Filter_CustomerType: '',
    WholesalerNo: '',
    WholeViewQuery: '',
    Join_Supplier: '',
    Join_SupplierCode: '',
    CustomDates: '',
    Date_Code_1: '',
    Date_Code_2: '',
    date_fields: [],
    Filter_SalePrice: '',
    Wholesalers: [],
    Filter_DepotName: '',
    newDateRanges: [],
    IsRetail: 0,
  },
};

const fetchCompletedDataAction = createAction(FETCH_COMPLETED_DATA);
const setQueryNameAction = createAction(SET_QUERY_NAME);
const fetchTimeframesAction = createAction(FETCH_TIMEFRAMES);
const fetchQueryAction = createAction(FETCH_QUERY);
const saveQueryAction = createAction(SAVE_QUERY);
const fetchHistoryAction = createAction(FETCH_HISTORY);
const fetchDataAction = createAction(FETCH_DATA);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setLoadingAction = createAction(SET_LOADING);
const setTimerAction = createAction(SET_TIMER);
const setCategoriesAction = createAction(SET_CATEGORIES);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setStatusCompletedAction = createAction(SET_STATUS_COMPLETED);
const setSelectedQueryAction = createAction(SET_SELECTED_QUERY);
const setReRunQueryAction = createAction(SET_RERUN_QUERY);
const updateQueryFieldVisibilityAction = createAction(
  UPDATE_QUERY_FIELD_VISIBILITY,
);
const removeDateRangeParamsAction = createAction(REMOVE_DATE_RANGE);
const addDateRangesAction = createAction(ADD_DATE_RANGES);
const addWholesalerAction = createAction(ADD_WHOLESALER);
const updateFieldAction = createAction(UPDATE_FIELD);
const saveFilterAction = createAction(SAVE_FILTER);
const saveReorderColumnsAction = createAction(SAVE_REORDER_COLUMNS);
const saveReorderDataAction = createAction(SAVE_REORDER_DATA);
const onPivotChangeAction = createAction(PIVOT_CHANGE_DATA);
const updateDateRangeAction = createAction(UPDATE_DATE_RANGE);
const resetQueryBuilderAction = createAction(RESET_QUERY_BUILDER);
const queryScheduledAction = createAction(QUERY_SCHEDULED);
const resetQueryScheduleAction = createAction(RESET_QUERY_SCHEDULE);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setIsDownloadingAction = createAction(SET_IS_DOWNLOADING);

export const resetQueryBuilder = params => dispatch => {
  dispatch(resetQueryBuilderAction());
};

//**** Built Query Start ***//

export const onUpdateQueryFieldVisibility = params => dispatch => {
  dispatch(updateQueryFieldVisibilityAction(params));
};

export const onRemoveDateRange = params => dispatch => {
  dispatch(removeDateRangeParamsAction(params));
};

export const onAddDateRanges = params => dispatch => {
  let newDateRanges = store.getState().query.builtQuery.newDateRanges;
  newDateRanges = [...newDateRanges, params.dateRange];
  dispatch(
    addDateRangesAction({ newDateRanges, columnOrder: params.columnOrder }),
  );
};

export const onAddWholesaler = params => dispatch => {
  dispatch(addWholesalerAction(params));
};

export const onUpdateField = params => dispatch => {
  dispatch(updateFieldAction(params));
};

export const onSaveFilter = params => dispatch => {
  let categoryHierarchy = store.getState().query.categoryHierarchy;
  let departments =
    categoryHierarchy.Departments && categoryHierarchy.Departments.length
      ? categoryHierarchy.Departments
      : [];
  if (params.name === 'Department') {
    let categories = [];
    let subCategories = [];
    let level4s = [];
    let level5s = [];
    let fieldKeys = _.map(params.filters, filter => filter.fieldKey);
    let selectedDepartments = departments;
    if (fieldKeys.length) {
      selectedDepartments = departments.filter(department =>
        fieldKeys.includes(department.ID),
      );
    }
    _.each(selectedDepartments, department => {
      _.each(department.Categories, (category, idx_cat) => {
        categories.push({ fieldKey: category.ID, fieldValue: category.Text });
        _.each(category.SubCategories, (subCategory, idx_subcat) => {
          subCategories.push({
            fieldKey: subCategory.ID,
            fieldValue: subCategory.Text,
          });
          _.each(subCategory.Level4, (level4, idx_level4) => {
            level4s.push({ fieldKey: level4.ID, fieldValue: level4.Text });
            _.each(level4.Level5, level5 => {
              level5s.push({ fieldKey: level5.ID, fieldValue: level5.Text });
            });
          });
        });
      });
    });
    dispatch(
      setCategoriesAction({ categories, subCategories, level4s, level5s }),
    );
  } else if (params.name === 'CategoryName') {
    let subCategories = [];
    let level4s = [];
    let level5s = [];
    let categoryKeys = _.map(params.filters, filter => filter.fieldKey);
    let departmentKeys = _.map(
      store.getState().query.builtQuery.Filter_Department,
      filter => filter.fieldKey,
    );
    if (departments.length === 0) {
      departments = [{ Categories: categoryHierarchy.Categories }];
      departmentKeys = [];
    }
    _.each(departments, department => {
      if (
        departmentKeys.length == 0 ||
        (departmentKeys.length > 0 && departmentKeys.includes(department.ID))
      ) {
        let categories = department.Categories;
        if (categoryKeys.length > 0) {
          categories = categories.filter(cat => categoryKeys.includes(cat.ID));
        }
        _.each(categories, category => {
          _.each(category.SubCategories, (subCategory, idx_subcat) => {
            subCategories.push({
              fieldKey: subCategory.ID,
              fieldValue: subCategory.Text,
            });
            _.each(subCategory.Level4, (level4, idx_level4) => {
              level4s.push({ fieldKey: level4.ID, fieldValue: level4.Text });
              _.each(level4.Level5, level5 => {
                level5s.push({ fieldKey: level5.ID, fieldValue: level5.Text });
              });
            });
          });
        });
      }
    });
    dispatch(
      setSubCategoriesAction({
        subCategories: _.uniqBy(subCategories, 'fieldKey'),
        level4s: _.uniqBy(level4s, 'fieldKey'),
        level5s: _.uniqBy(level5s, 'fieldKey'),
      }),
    );
  } else if (params.name === 'SGDescription') {
    let level4s = [];
    let level5s = [];
    let departmentKeys = _.map(
      store.getState().query.builtQuery.Filter_Department,
      filter => filter.fieldKey,
    );
    let categoryKeys = _.map(
      store.getState().query.builtQuery.Filter_CategorysName,
      filter => filter.fieldKey,
    );
    let fieldKeys = _.map(params.filters, filter => filter.fieldKey);
    if (departments.length === 0) {
      departments = [{ Categories: categoryHierarchy.Categories }];
      departmentKeys = [];
    }
    _.each(departments, department => {
      if (
        departmentKeys.length == 0 ||
        (departmentKeys.length > 0 && departmentKeys.includes(department.ID))
      ) {
        let categories = department.Categories;
        if (categoryKeys.length > 0) {
          categories = categories.filter(cat => categoryKeys.includes(cat.ID));
        }
        _.each(categories, category => {
          let subCategories = category.SubCategories;
          if (fieldKeys.length > 0) {
            subCategories = subCategories.filter(subcat =>
              fieldKeys.includes(subcat.ID),
            );
          }
          _.each(subCategories, subCategory => {
            _.each(subCategory.Level4, (level4, idx_level4) => {
              level4s.push({ fieldKey: level4.ID, fieldValue: level4.Text });
              _.each(level4.Level5, level5 => {
                level5s.push({ fieldKey: level5.ID, fieldValue: level5.Text });
              });
            });
          });
        });
      }
    });
    dispatch(setLevel4sAction({ level4s, level5s }));
  }
  dispatch(saveFilterAction(params));
};

export const onSaveReorderColumns = params => dispatch => {
  dispatch(saveReorderColumnsAction(params));
};

export const onSaveReorderData = params => dispatch => {
  dispatch(saveReorderDataAction(params));
}

export const onPivotChange = params => dispatch => {
  dispatch(onPivotChangeAction(params));
}

export const onUpdateDateRange =
  ({ index, ...params }) =>
  dispatch => {
    const builtQuery = store.getState().query.builtQuery;
    let newDateRanges = [...builtQuery.newDateRanges];
    let newColumnOrder = builtQuery.Column_Order;
    let dateRange = { ...newDateRanges[index], ...params };
    newDateRanges[index] = { ...dateRange };
    newColumnOrder = newColumnOrder.map(ord => {
      if (ord.sort_name.indexOf(dateRange.id) >= 0) {
        if (ord.label.indexOf('volume-hectolitres') >= 0) {
          ord.label = 'volume-hectolitres-' + dateRange.label;
        } else if (ord.label.indexOf('volume-9l') >= 0) {
          ord.label = 'volume-9l-' + dateRange.label;
        } else if (ord.label.indexOf('volume-stick') >= 0) {
          ord.label = 'volume-stick-' + dateRange.label;
        } else if (ord.label.indexOf('volume-grams') >= 0) {
          ord.label = 'volume-grams-' + dateRange.label;
        } else {
          ord.label = ord.label.split('-')[0] + '-' + dateRange.label;
        }
        if (dateRange.label === 'custom') {
          ord.label += `${index + 1}`;
        }
      }
      return ord;
    });

    dispatch(updateDateRangeAction({ newColumnOrder, newDateRanges }));
  };

//**** Built Query End ***//

export const setSelectedQuery = query => dispatch => {
  dispatch(setSelectedQueryAction(query));
  dispatch(push('/query/schedule'));
};

export const clearTimer = () => dispatch => {
  clearInterval(store.getState().query.timer);
  dispatch(setLoadingAction(false));
};

export const setTimer = timer => dispatch => {
  // dispatch(setLoadingAction(true));
  dispatch(setTimerAction(timer));
  // setTimeout(() => {
  //   clearInterval(store.getState().query.timer);
  //   dispatch(setStatusCompletedAction());
  //   dispatch(setLoadingAction(false));
  // },10000);
};

export const setMember = val => dispatch => {
  dispatch(setSelectedMembersAction(val));
};

export const setQueryName = name => dispatch => {
  dispatch(setQueryNameAction(name));
};

export const submitQuery = () => dispatch => {
  let data = {
    name: store.getState().query.name,
    core_range_description: store.getState().query.core_range_description,
    core_range_pack_size: store.getState().query.core_range_pack_size,
    core_range_pmp: store.getState().query.core_range_pmp,
    core_range_ew: store.getState().query.core_range_ew,
    core_range_ni: store.getState().query.core_range_ni,
    core_range_scot: store.getState().query.core_range_scot,
    best_seller: store.getState().query.best_seller,
    filter: [],
    show: [],
    Dates: [],
    Wholesalers: [],
  };

  let builtQuery = store.getState().query.builtQuery;

  if (builtQuery.Filter_Department.length > 0) {
    data.filter.push({
      field: 'Department',
      value: builtQuery.Filter_Department,
    });
  }

  if (builtQuery.Filter_CategoryName.length > 0) {
    data.filter.push({
      field: 'CategoryName',
      value: builtQuery.Filter_CategoryName,
    });
  }
  if (builtQuery.Filter_Level4.length > 0) {
    data.filter.push({ field: 'Level4', value: builtQuery.Filter_Level4 });
  }
  if (builtQuery.Filter_Level5.length > 0) {
    data.filter.push({ field: 'Level5', value: builtQuery.Filter_Level5 });
  }
  if (builtQuery.Filter_Brand.length > 0) {
    data.filter.push({ field: 'Brand', value: builtQuery.Filter_Brand });
  }
  if (builtQuery.Filter_SGDescription.length > 0) {
    data.filter.push({
      field: 'SGDescription',
      value: builtQuery.Filter_SGDescription,
    });
  }
  if (builtQuery.Filter_SupplierName.length > 0) {
    data.filter.push({
      field: 'SupplierName',
      value: builtQuery.Filter_SupplierName,
    });
  }
  if (builtQuery.Filter_BusinessType.length > 0) {
    data.filter.push({
      field: 'BusinessType',
      value: builtQuery.Filter_BusinessType,
    });
  }
  if (builtQuery.Filter_ProductDescription.length > 0) {
    data.filter.push({
      field: 'ProductDescription',
      value: builtQuery.Filter_ProductDescription,
    });
  }
  if (builtQuery.Filter_MemberProductCode.length > 0) {
    data.filter.push({
      field: 'MembersProductCode',
      value: builtQuery.Filter_MemberProductCode,
    });
  }
  if (builtQuery.Filter_ProductCode.length > 0) {
    data.filter.push({
      field: 'ProductCode',
      value: builtQuery.Filter_ProductCode,
    });
  }

  if (builtQuery.Filter_DepotName.length > 0) {
    data.filter.push({
      field: 'DepotName',
      value: builtQuery.Filter_DepotName,
    });
  }

  if (builtQuery.Filter_AccountNames.length > 0) {
    data.filter.push({
      field: 'AccountNames',
      value: builtQuery.Filter_AccountNames,
    })
  }

  if (builtQuery.Filter_SupplierReporting.length > 0) {
    data.filter.push({
      field: 'SupplierReporting',
      value: builtQuery.Filter_SupplierReporting,
    });
  }

  if (builtQuery.Filter_WholesalerSupplier.length > 0) {
    data.filter.push({
      field: 'WholesalerSupplier',
      value: builtQuery.Filter_WholesalerSupplier,
    });
  }

  if (builtQuery.Filter_AdvancedCustomerType1.length > 0) {
    data.filter.push({
      field: 'AdvancedCustomerType1',
      value: builtQuery[`Filter_AdvancedCustomerType1`],
    });
  }

  Object.entries(builtQuery).forEach(([key, value]) => {
    if (key.includes('AdvancedCustomerType')) {
      if (value.length > 0) {
        data.filter.push({
          field: key,
          value: value,
        });
      }
    }
  });

  _.each(builtQuery.newDateRanges, (dateRange, index) => {
    data.Dates.push({
      startYearNoWeekNo: dateRange.startWeek,
      endYearNoWeekNo: dateRange.endWeek,
      start: dateRange.start,
      end: dateRange.end,
      label:
        dateRange.label === 'custom'
          ? dateRange.label + (index + 1)
          : dateRange.label,
    });
  });

  _.each(builtQuery.Wholesalers, wholesaler => {
    data.Wholesalers.push({
      wholesalerName: wholesaler.label,
      wholesalerNo: wholesaler.value,
    });
  });

  _.each(builtQuery.Column_Order, order => {
    if (
      order.name.indexOf('Value') >= 0 ||
      order.name.indexOf('Volume') >= 0 ||
      order.name.indexOf('CustomerCount') >= 0 ||
      order.name.indexOf('Qty') >= 0
    ) {
      data.show.push(order.label);
    } else {
      data.show.push(order.name);
    }
  });

  data.WholeViewQuery = builtQuery.WholeViewQuery;

  data.Show_Competitor = builtQuery.Show_Competitor;

  data.Pivot_Table_Enabled = builtQuery.Pivot_Table_Enabled;

  data.Pivot_Table = builtQuery.Pivot_Table;

  data.Show_LikeForLike = builtQuery.Show_LikeForLike;

  data.dataType = builtQuery.dataType;

  if (data.Pivot_Table_Enabled == 1) {
    _.each(data.Pivot_Table.values, value => {
      if (
        value.name.indexOf('Value') >= 0 ||
        value.name.indexOf('Volume') >= 0 ||
        value.name.indexOf('CustomerCount') >= 0 ||
        value.name.indexOf('Qty') >= 0
      ) {
        data.show = data.show.concat(value.label);
      } else {
        data.show = data.show.concat(value.name);

      }
    });
    _.each(data.Pivot_Table.rows, row => {
      if (
        row.name.indexOf('Value') >= 0 ||
        row.name.indexOf('Volume') >= 0 ||
        row.name.indexOf('CustomerCount') >= 0 ||
        row.name.indexOf('Qty') >= 0
      ) {
        data.show = data.show.concat(row.label);
      } else {
        data.show = data.show.concat(row.name);

      }
    });
    _.each(data.Pivot_Table.columns, column => {
      if (
        column.name.indexOf('Value') >= 0 ||
        column.name.indexOf('Volume') >= 0 ||
        column.name.indexOf('CustomerCount') >= 0 ||
        column.name.indexOf('Qty') >= 0
      ) {
        data.show = data.show.concat(column.label);
      } else {
        data.show = data.show.concat(column.name);

      }
    });
  }
  let url = '/route.php?c=queryBuilder/SaveQuery';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
    data: data,
  }).then(result => {
    dispatch(
      saveQueryAction(result.QB_Id.substring(1, result.QB_Id.length - 1)),
    );
  });
};

export const reRunQuery = query => async dispatch => {
  let url = '/route.php?c=wholesaler/getAll';
  url += `&show_competitor=${query.Show_Competitor}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(members => {
    const preResetTimeframes = store.getState().query.timeframes;
    dispatch(resetQueryBuilderAction());

    let updatedQuery = {
      Dates: [],
      Wholesalers: [],
      filters: [],
      show: [],
    };
    // Filter out any wholesalers who are in the previous query but not returned from the API
    const filteredMembers = query.Wholesalers.filter(wholesaler =>
      members.find(member => {
        return member.WholesalerNo == wholesaler.wholesalerNo;
      }),
    );

    updatedQuery.Dates = query.TimeFrames;
    updatedQuery.show = query.ChosenFields;
    updatedQuery.Wholesalers = filteredMembers;
    updatedQuery.filters = query.Filters;
    updatedQuery.name = query.Name;

    const state = store.getState().query;
    state.timeframes = preResetTimeframes;

    let builtQuery = state.builtQuery;

    builtQuery.Show_Competitor = query.Show_Competitor;
    builtQuery.Pivot_Table_Enabled = typeof query.Pivot_Table_Enabled == 'undefined' ? 0 : query.Pivot_Table_Enabled;
    builtQuery.Pivot_Table = typeof query.Pivot_Table == 'undefined' 
      ? {
        columns: [],
        rows: [],
        values: [],
      } 
      : query.Pivot_Table;
    builtQuery.Show_LikeForLike = query.Show_LikeForLike;
    builtQuery.dataType = query.IsRetail;

    query.ChosenFields.forEach(field => {
      switch (field) {
        case 'YearNoWeekNo':
          builtQuery.Show_Weeks = 1;
          break;
        case 'Member':
          builtQuery.Show_WholesalerName = 1;
          break;
        case 'WholeViewQuery':
          builtQuery.WholeViewQuery = 1;
          break;
        default:
          builtQuery[`Show_${field}`] = 1;
      }
    });

    query.Filters.forEach(filter => {
      builtQuery[`Filter_${filter.field}`] = filter.value;
    });

    let newDateRanges = [];
    _.each(query.TimeFrames, (timeframe, index) => {
      newDateRanges.push({
        id: +new Date() + (index + 100),
        label:
          timeframe.label.indexOf('custom') >= 0 ? 'custom' : timeframe.label,
        startWeek:
          timeframe.label.indexOf('custom') < 0
            ? _.filter(state.timeframes, frame => {
                if (frame.id === timeframe.label) {
                  return frame;
                }
              })[0]?.uid?.startWeek
            : timeframe.startYearNoWeekNo,
        endWeek:
          timeframe.label.indexOf('custom') < 0
            ? _.filter(state.timeframes, frame => {
                if (frame.id === timeframe.label) {
                  return frame;
                }
              })[0]?.uid?.endWeek
            : timeframe.endYearNoWeekNo,
        start:
          timeframe.label.indexOf('custom') < 0
            ? _.filter(state.timeframes, frame => {
                if (frame.id === timeframe.label) {
                  return frame;
                }
              })[0]?.uid.start
            : timeframe.start,
        end:
          timeframe.label.indexOf('custom') < 0
            ? _.filter(state.timeframes, frame => {
                if (frame.id === timeframe.label) {
                  return frame;
                }
              })[0]?.uid.end
            : timeframe.end,
      });
    });
    builtQuery.newDateRanges = [...newDateRanges];

    builtQuery.Wholesalers = [];
    if (filteredMembers) {
      for (let idx = 0; idx < filteredMembers.length; idx++) {
        builtQuery.Wholesalers.push({
          value: filteredMembers[idx].wholesalerNo,
          label: filteredMembers[idx].wholesalerName,
        });
      }
    }
    builtQuery.Column_Order = [];
    for (let idx = 0; idx < query.ChosenFields.length; idx++) {
      if (query.ChosenFields[idx] === 'Department') {
        builtQuery.Column_Order.push({
          label: 'department',
          name: 'Department',
          sort_name: 'ord_Department',
          resultset: 'Department',
        });
      }
      if (query.ChosenFields[idx] === 'CategoryName') {
        builtQuery.Column_Order.push({
          label: 'category name',
          name: 'CategoryName',
          sort_name: 'ord_CategoryName',
          resultset: 'CategoryName',
        });
      }
      if (query.ChosenFields[idx] === 'Level4') {
        builtQuery.Column_Order.push({
          label: 'level 4',
          name: 'Level4',
          sort_name: 'ord_Level4',
          resultset: 'Level4',
        });
      }
      if (query.ChosenFields[idx] === 'Level5') {
        builtQuery.Column_Order.push({
          label: 'level 5',
          name: 'Level5',
          sort_name: 'ord_Level5',
          resultset: 'Level5',
        });
      }
      if (query.ChosenFields[idx] === 'Brand') {
        builtQuery.Column_Order.push({
          label: 'brand',
          name: 'Brand',
          sort_name: 'ord_Brand',
          resultset: 'Brand',
        });
      }
      if (query.ChosenFields[idx] === 'BusinessType') {
        builtQuery.Column_Order.push({
          label: 'businessType',
          name: 'BusinessType',
          sort_name: 'ord_BusinessType',
          resultset: 'BusinessType',
        });
      }
      if (
        query.ChosenFields[idx] === 'SubCategory' ||
        query.ChosenFields[idx] === 'SGDescription'
      ) {
        builtQuery.Column_Order.push({
          label: 'sub category',
          name: 'SGDescription',
          sort_name: 'ord_SGDescription',
          resultset: 'SGDescription',
        });
      }
      if (query.ChosenFields[idx] === 'ProductDescription') {
        builtQuery.Column_Order.push({
          label: 'product description',
          name: 'ProductDescription',
          sort_name: 'ord_ProductDescription',
          resultset: 'ProductDescription',
        });
      }
      if (query.ChosenFields[idx] === 'MembersProductCode') {
        builtQuery.Column_Order.push({
          label: 'member product code',
          name: 'MembersProductCode',
          sort_name: 'ord_MemberProductCode',
          resultset: 'MembersProductCode',
        });
      }
      if (query.ChosenFields[idx] === 'ProductCode') {
        builtQuery.Column_Order.push({
          label: 'product code',
          name: 'ProductCode',
          sort_name: 'ord_ProductCode',
          resultset: 'ProductCode',
        });
      }
      if (query.ChosenFields[idx] === 'PMP') {
        builtQuery.Column_Order.push({
          label: 'pmp',
          name: 'PMP',
          sort_name: 'ord_PMP',
          resultset: 'PMP',
        });
      }
      if (query.ChosenFields[idx] === 'Size') {
        builtQuery.Column_Order.push({
          label: 'size',
          name: 'Size',
          sort_name: 'ord_Size',
          resultset: 'Size',
        });
      }
      if (query.ChosenFields[idx] === 'Depot') {
        builtQuery.Column_Order.push({
          label: 'depot',
          name: 'Depot',
          sort_name: 'ord_DepotName',
          resultset: 'Depot',
        });
      }
      if (query.ChosenFields[idx] === 'Pack') {
        builtQuery.Column_Order.push({
          label: 'pack',
          name: 'Pack',
          sort_name: 'ord_Pack',
          resultset: 'Pack',
        });
      }
      if (query.ChosenFields[idx] === 'Measure') {
        builtQuery.Column_Order.push({
          label: 'measure',
          name: 'Measure',
          sort_name: 'ord_Measure',
          resultset: 'Measure',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRange') {
        builtQuery.Column_Order.push({
          label: 'core range',
          name: 'CoreRange',
          sort_name: 'ord_CoreRange',
          resultset: 'CoreRange',
        });
      }
      if (query.ChosenFields[idx] === 'SupplierName') {
        builtQuery.Column_Order.push({
          label: 'supplier',
          name: 'SupplierName',
          sort_name: 'ord_SupplierName',
          resultset: 'SupplierName',
        });
      }
      if (query.ChosenFields[idx] === 'YearNumber') {
        builtQuery.Column_Order.push({
          label: 'year number',
          name: 'YearNumber',
          sort_name: 'ord_YearNumber',
          resultset: 'YearNumber',
        });
      }
      if (query.ChosenFields[idx] === 'WeekNo') {
        builtQuery.Column_Order.push({
          label: 'week number',
          name: 'WeekNumber',
          sort_name: 'ord_WeekNo',
          resultset: 'WeekNo',
        });
      }
      if (query.ChosenFields[idx] === 'YearWeekNumber') {
        builtQuery.Column_Order.push({
          label: 'year week number',
          name: 'YearWeekNumber',
          sort_name: 'ord_YearWeekNo',
          resultset: 'YearWeekNo',
        });
      }
      if (query.ChosenFields[idx] === 'WeekCommencingDate') {
        builtQuery.Column_Order.push({
          label: 'week commencing date',
          name: 'WeekCommencingDate',
          sort_name: 'ord_WeekCommenceDate',
          resultset: 'WeekCommenceDate',
        });
      }
      if (query.ChosenFields[idx] === 'Member') {
        builtQuery.Column_Order.push({
          label: 'member',
          name: 'WholesalerName',
          sort_name: 'ord_WholesalerName',
          resultset: 'WholesalerName',
        });
      }
      if (query.ChosenFields[idx] === 'DepotName') {
        builtQuery.Column_Order.push({
          label: 'depot name',
          name: 'DepotName',
          sort_name: 'ord_DepotName',
          resultset: 'DepotName',
        });
      }
      if (query.ChosenFields[idx] === 'CustomerType') {
        builtQuery.Column_Order.push({
          label: 'customer type',
          name: 'CustomerType',
          sort_name: 'ord_CustomerType',
          resultset: 'CustomerType',
        });
      }
      if (query.ChosenFields[idx] === 'YearNoWeekNo') {
        builtQuery.Column_Order.push({
          label: 'Show Weeks',
          name: 'Show_Weeks',
          sort_name: 'ord_Show_Weeks',
          resultset: 'Show_Weeks',
        });
      }
      if (query.ChosenFields[idx] === 'LikeForLike') {
        builtQuery.Column_Order.push({
          label: 'Show LikeForLike',
          name: 'Show_LikeForLike',
          sort_name: 'ord_Show_LikeForLike',
          resultset: 'Show_LikeForLike',
        });
      }
      if (query.ChosenFields[idx] === 'HTDrinksCustomerCategory') {
        builtQuery.Column_Order.push({
          label: 'ht drinks customer category',
          name: 'HTDrinksCustomerCategory',
          sort_name: 'ord_HTDrinksCustomerCategory',
          resultset: 'HTDrinksCustomerCategory',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangeDescription') {
        builtQuery.Column_Order.push({
          label: 'Core Range Description',
          name: 'Show_CoreRangeDescription',
          sort_name: 'ord_Show_CoreRangeDescription',
          resultset: 'Show_CoreRangeDescription',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangePackSize') {
        builtQuery.Column_Order.push({
          label: 'Core Range Pack Size',
          name: 'Show_CoreRangePackSize',
          sort_name: 'ord_Show_CoreRangePackSize',
          resultset: 'Show_CoreRangePackSize',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangeCategory') {
        builtQuery.Column_Order.push({
          label: 'Core Range Category',
          name: 'Show_CoreRangeCategory',
          sort_name: 'ord_Show_CoreRangeCategory',
          resultset: 'Show_CoreRangeCategory',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangePmp') {
        builtQuery.Column_Order.push({
          label: 'Core Range Pmp',
          name: 'Show_CoreRangePmp',
          sort_name: 'ord_Show_CoreRangePmp',
          resultset: 'Show_CoreRangePmp',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangeEw') {
        builtQuery.Column_Order.push({
          label: 'Core Range Ew',
          name: 'Show_CoreRangeEw',
          sort_name: 'ord_Show_CoreRangeEw',
          resultset: 'Show_CoreRangeEw',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangeScot') {
        builtQuery.Column_Order.push({
          label: 'Core Range Scot',
          name: 'Show_CoreRangeScot',
          sort_name: 'ord_Show_CoreRangeScot',
          resultset: 'Show_CoreRangeScot',
        });
      }
      if (query.ChosenFields[idx] === 'CoreRangeNi') {
        builtQuery.Column_Order.push({
          label: 'Core Range Ni',
          name: 'Show_CoreRangeNi',
          sort_name: 'ord_Show_CoreRangeNi',
          resultset: 'Show_CoreRangeNi',
        });
      }
      if (query.ChosenFields[idx] === 'BestSeller') {
        builtQuery.Column_Order.push({
          label: 'Best Seller',
          name: 'Show_BestSeller',
          sort_name: 'ord_Show_BestSeller',
          resultset: 'Show_BestSeller',
        });
      }
      if (query.ChosenFields[idx] === 'SupplierReporting') {
        builtQuery.Column_Order.push({
          label: 'supplier reporting',
          name: 'SupplierReporting',
          sort_name: 'ord_SupplierReporting',
          resultset: 'SupplierReporting',
        });
      }
      if (query.ChosenFields[idx] === 'WholesalerSupplier') {
        builtQuery.Column_Order.push({
          label: 'Wholesaler Supplier',
          name: 'WholesalerSupplier',
          sort_name: 'ord_WholesalerSupplier',
          resultset: 'WholesalerSupplier',
        });
      }
    }

    let volumes = _.filter(
      query.ChosenFields,
      fl => fl.toLowerCase().indexOf('volume-') >= 0,
    );
    let values = _.filter(
      query.ChosenFields,
      fl => fl.toLowerCase().indexOf('value-') >= 0,
    );
    let customerCounts = _.filter(
      query.ChosenFields,
      fl => fl.toLowerCase().indexOf('customer count-') >= 0,
    );

    let volumeIns = _.filter(
      query.ChosenFields,
      fl => fl.toLowerCase().indexOf('volume in-') >= 0
    );
    let valueIns = _.filter(
      query.ChosenFields, 
      fl => fl.toLowerCase().indexOf('value in-') >= 0
    );


    for (let idx = 0; idx < volumes.length; idx++) {
      if (volumes[idx].toLowerCase().indexOf('volume-hectolitres') >= 0) {
        builtQuery.Show_QtyHectolitres = 1;
        builtQuery.Column_Order.push({
          label: volumes[idx].replace('Volume', 'volume'),
          name: volumes[idx],
          sort_name: `ord_Volume_Hectolitres_${idx + 1}`,
          resultset: 'QtyHectolitres',
        });
      } else if (volumes[idx].toLowerCase().indexOf('volume-9l') >= 0) {
        builtQuery.Show_Qty9L = 1;
        builtQuery.Column_Order.push({
          label: volumes[idx].replace('Volume', 'volume'),
          name: volumes[idx],
          sort_name: `ord_Volume_9L_${idx + 1}`,
          resultset: 'Qty9L',
        });
      } else if (volumes[idx].toLowerCase().indexOf('volume-stick') >= 0) {
        builtQuery.Show_QtyStick = 1;
        builtQuery.Column_Order.push({
          label: volumes[idx].replace('Volume', 'volume'),
          name: volumes[idx],
          sort_name: `ord_Volume_Stick_${idx + 1}`,
          resultset: 'QtyStick',
        });
      } else if (volumes[idx].toLowerCase().indexOf('volume-grams') >= 0) {
        builtQuery.Show_QtyGrams = 1;
        builtQuery.Column_Order.push({
          label: volumes[idx].replace('Volume', 'volume'),
          name: volumes[idx],
          sort_name: `ord_Volume_Grams_${idx + 1}`,
          resultset: 'QtyGrams',
        });
      } else {
        builtQuery.Show_Volume = 1;
        builtQuery.Column_Order.push({
          label: volumes[idx].replace('Volume', 'volume'),
          name: volumes[idx],
          sort_name: `ord_Volume_${idx + 1}`,
          resultset: 'Volume',
        });
      }
    }

    for (let idx = 0; idx < volumeIns.length; idx++) {
      builtQuery.Show_VolumeIn = 1;
      builtQuery.Column_Order.push({
        label: volumeIns[idx].replace('VolumeIn', 'volume in'),
        name: volumeIns[idx],
        sort_name: `ord_Volume_${idx + 1}`,
        resultset: 'VolumeIn',
      });
    }

    for (let idx = 0; idx < values.length; idx++) {
      builtQuery.Show_Value = 1;
      builtQuery.Column_Order.push({
        label: values[idx].replace('Value', 'value'),
        name: values[idx],
        sort_name: `ord_Value_${idx + 1}`,
        resultset: 'Value',
      });
    }

    for (let idx = 0; idx < valueIns.length; idx++) {
      builtQuery.Show_ValueIn = 1;
      builtQuery.Column_Order.push({
        label: valueIns[idx].replace('ValueIn', 'value in'),
        name: valueIns[idx],
        sort_name: `ord_Value_${idx + 1}`,
        resultset: 'ValueIn',
      });
    }

    for (let idx = 0; idx < customerCounts.length; idx++) {
      builtQuery.Show_CustomerCount = 1;
      builtQuery.Column_Order.push({
        label: customerCounts[idx].replace('CustomerCount', 'customer count'),
        name: customerCounts[idx],
        sort_name: `ord_CustomerCount_${idx + 1}`,
        resultset: 'CustomerCount',
      });
    }
    dispatch(setReRunQueryAction({ updatedQuery, builtQuery }));
    dispatch(push('/query/1'));
  });
};

export const fetchTimeframes = () => dispatch => {
  let url = '/route.php?c=date/getReportingTimeframes';
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    result.push({
      id: 'custom',
      label: 'custom',
      uid: {
        start: moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
        startWeek: moment().format('YYYY') + moment().format('WW'),
        endWeek: moment().format('YYYY') + moment().format('WW'),
      },
    });
    const defaultRange = getDefaultDateRange(result);
    const builtQuery = store.getState().query.builtQuery;
    if (builtQuery.newDateRanges.length === 0 && defaultRange) {
      builtQuery.newDateRanges.push({ ...defaultRange });
    }
    let formattedTimeframes = result.map(timeframe => {
      timeframe.uid.start = moment(timeframe.uid.start)
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
      return timeframe;
    });
    dispatch(
      fetchTimeframesAction({
        timeframes: formattedTimeframes,
        defaultRange: defaultRange,
        builtQuery,
      }),
    );
    dispatch(setLoadingAction(false));
  });
};

const getDefaultDateRange = result => {
  const found = result.find(item => item.id === '13WK_TY');
  if (found) {
    return {
      label: found.id,
      ...found.uid,
      id: +new Date(),
    };
  } else {
    return null;
  }
};

export const fetchCompletedData = () => dispatch => {
  if (!store.getState().query.queryId) {
    return null;
  }
  let url = '/route.php?c=queryBuilder/getPreview&uid=';
  
  dispatch(setLoadingAction(true));
  request({
    url: url + store.getState().query.queryId,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    dispatch(fetchCompletedDataAction(result));
    dispatch(setLoadingAction(false));
  });
};

export const renameQuery = (id, name) => dispatch => {
  let url = `/route.php?c=queryBuilder/renameQuery&uid=${id}&name=${name}`;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
  }).then(result => {
    url =
      '/route.php?c=queryBuilder/getQueries&limit=' +
      store.getState().query.limit;
    request({
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
      method: 'GET',
    }).then(result => {
      let queries = [];
      Object.keys(result).map((key, index) => {
        let query = result[key];
        query.id = key;
        if (query.ChosenFields) {
          query.ChosenFields = query.ChosenFields.map(field => {
            if (field === 'WholesalerName') {
              return 'Member';
            } else {
              return field;
            }
          });
        }
        queries.push(query);
      });
      dispatch(
        fetchHistoryAction({ queries, limit: store.getState().query.limit }),
      );
      dispatch(setLoadingAction(false));
    });
  });
};

export const deleteQuery = id => dispatch => {
  let url = `/route.php?c=queryBuilder/deleteQuery&uid=` + id;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'POST',
  }).then(result => {
    url =
      '/route.php?c=queryBuilder/getQueries&limit=' +
      store.getState().query.limit;
    request({
      url: url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
      method: 'GET',
    }).then(result => {
      let queries = [];
      Object.keys(result).map((key, index) => {
        let query = result[key];
        query.id = key;
        if (query.ChosenFields) {
          query.ChosenFields = query.ChosenFields.map(field => {
            if (field === 'WholesalerName') {
              return 'Member';
            } else {
              return field;
            }
          });
        }
        queries.push(query);
      });
      dispatch(
        fetchHistoryAction({ queries, limit: store.getState().query.limit }),
      );
      dispatch(setLoadingAction(false));
    });
  });
};
export const downloadQuery = id => dispatch => {
  dispatch(setIsDownloadingAction(true));
  let url = `/route.php?c=queryBuilder/getDownload`;
  if (id) {
    url += `&uid=${id}`;
  }
  dispatch(setLoadingAction(true));
  requestBlob({
    url: url,
    headers: {
      'content-type': 'application/vnd.ms-excel;charset=UTF-8',
      Authorization: getToken(),
      System: getUserSystem(),
      UserId: getUserId(),
    },
    responseType: 'blob',
    method: 'GET',
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click();
      dispatch(setLoadingAction(false));
      dispatch(setIsDownloadingAction(false));
    })
    .catch(err => {
      dispatch(setLoadingAction(false));
      dispatch(setIsDownloadingAction(false));
    });
};

export const fetchQuery = () => dispatch => {
  if (!store.getState().query.queryId) {
    return null;
  }
  let url =
    '/route.php?c=queryBuilder/getStatus&uid=' +
    store.getState().query.queryId;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    if (result.Status === 'Completed') {
      clearInterval(store.getState().query.timer);
      dispatch(setStatusCompletedAction());
    }
    dispatch(fetchQueryAction(result));
    dispatch(setLoadingAction(false));
  });
};

export const scheduleQuery = (id, run) => dispatch => {
  let runMonthly = run === 'monthly' ? 1 : 0;
  let runWeekly = run === 'weekly' ? 1 : 0;
  let url =
    '/route.php?c=queryBuilder/setScheduleQuery&uid=' +
    id +
    '&Run_Monthly=' +
    runMonthly +
    '&Run_Weekly=' +
    runWeekly;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    dispatch(queryScheduledAction());
    dispatch(setLoadingAction(false));
  });
};

export const resetQuerySchedule = () => dispatch => {
  dispatch(resetQueryScheduleAction());
};

export const fetchScheduledHistory = limit => dispatch => {
  let url =
    '/route.php?c=queryBuilder/getQueries&scheduled=1&limit=' + limit;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    let queries = [];
    Object.keys(result).map((key, index) => {
      let query = result[key];
      query.id = key;
      if (query.ChosenFields) {
        query.ChosenFields = query.ChosenFields.map(field => {
          if (field === 'WholesalerName') {
            return 'Member';
          } else {
            return field;
          }
        });
      }
      queries.push(query);
    });
    dispatch(fetchHistoryAction({ queries, limit }));
    dispatch(setLoadingAction(false));
  });
};

export const fetchHistory = limit => dispatch => {
  let url = '/route.php?c=queryBuilder/getQueries&limit=' + limit;
  dispatch(setLoadingAction(true));
  request({
    url: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    method: 'GET',
  }).then(result => {
    let queries = [];
    Object.keys(result).map((key, index) => {
      let query = result[key];
      query.id = key;
      if (query.ChosenFields) {
        query.ChosenFields = query.ChosenFields.map(field => {
          if (field === 'WholesalerName') {
            return 'Member';
          } else {
            return field;
          }
        });
      }
      queries.push(query);
    });
    dispatch(fetchHistoryAction({ queries, limit }));
    dispatch(setLoadingAction(false));
  });
};

export const fetchProducts = (keyword, filterType) => dispatch => {
  dispatch(setLoadingAction(true));
  const state = store.getState().query;
  let url = '';
  if (parseInt(keyword) > 0) {
    url = '/route.php?c=product/getAllById';
  } else {
    url = '/route.php?c=product/getAll';
  }
  url += `&show_competitor=${state.builtQuery.Show_Competitor}`;
  if (filterType == 'membersProductCode') {
    let memberIds = [];
    _.each(state.builtQuery.Wholesalers, member => {
      memberIds.push(member.label);
    });

    if (memberIds.length > 0) {
      url = '/route.php?c=product/getMemberProductCodes&member=' + encodeURIComponent(memberIds);
    }
  }
  if (keyword.length >= 3) {
    url = url + '&keywords=' + keyword;
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(prods => {
      let productsForDesc = [];
      let membersProductCode = [];
      let productsForCode = [];
      _.each(prods, product => {
        if (filterType == 'membersProductCode') {
          membersProductCode.push({
            fieldKey: product.WVProdCode,
            fieldValue: product.WVProdCode,
          });
        } else {
          productsForDesc.push({
            fieldKey: product.WVProdCode,
            fieldValue: product.WVProdDesc,
          });
          productsForCode.push({
            fieldKey: product.WVProdCode,
            fieldValue: product.WVProdCode,
          });
        }
      });

      if (filterType == 'membersProductCode') {
        
      }
      // merge older loaded products
      if (state.productsForDesc.length) {
        productsForDesc = _.unionWith(
          productsForDesc,
          state.productsForDesc,
          (arr1, arr2) =>
            arr1.fieldKey === arr2.fieldKey &&
            arr1.fieldValue === arr2.fieldValue,
        );
      }
      if (state.productsForCode.length) {
        productsForCode = _.unionWith(
          productsForCode,
          state.productsForCode,
          (arr1, arr2) =>
            arr1.fieldKey === arr2.fieldKey &&
            arr1.fieldValue === arr2.fieldValue,
        );
      }
      if (state.membersProductCode.length) {
        membersProductCode = _.unionWith(
          membersProductCode,
          state.membersProductCode,
          (arr1, arr2) =>
            arr1.fieldKey === arr2.fieldKey &&
            arr1.fieldValue === arr2.fieldValue,
        );
      }
      dispatch(fetchProductsAction({ productsForDesc, productsForCode, membersProductCode }));
      dispatch(setLoadingAction(true));
    });
  }
};

export const fetchCategories = value => async dispatch => {
  const state = store.getState().query;
  let showCompetitor = state.builtQuery.Show_Competitor;
  if (value !== undefined && value.toString().length > 0) {
    showCompetitor = value;
  }
  let url1 = '/route.php?c=category/getHierarchy';
  url1 += `&show_competitor=${showCompetitor}`;
  dispatch(setLoadingAction(true));
  const result = await requestGet({ url: url1 });

  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (result.Departments && result.Departments.length) {
    _.each(result.Departments, (department, idx_department) => {
      departments.push({
        fieldKey: department.ID,
        fieldValue: department.Text,
      });
      _.each(department.Categories, (category, idx_category) => {
        categories.push({ fieldKey: category.ID, fieldValue: category.Text });
        _.each(category.SubCategories, (subCategory, idx_subcat) => {
          subCategories.push({
            fieldKey: subCategory.ID,
            fieldValue: subCategory.Text,
          });
          _.each(subCategory.Level4, (level4, idx_level4) => {
            level4s.push({ fieldKey: level4.ID, fieldValue: level4.Text });
            _.each(level4.Level5, level5 => {
              level5s.push({ fieldKey: level5.ID, fieldValue: level5.Text });
            });
          });
        });
      });
    });
  } else if (result.Categories && result.Categories.length) {
    _.each(result.Categories, (category, idx_category) => {
      categories.push({ fieldKey: category.ID, fieldValue: category.Text });
      _.each(category.SubCategories, (subCategory, idx_subcat) => {
        subCategories.push({
          fieldKey: subCategory.ID,
          fieldValue: subCategory.Text,
        });
        _.each(subCategory.Level4, (level4, idx_level4) => {
          level4s.push({ fieldKey: level4.ID, fieldValue: level4.Text });
          _.each(level4.Level5, level5 => {
            level5s.push({ fieldKey: level5.ID, fieldValue: level5.Text });
          });
        });
      });
    });
  }

  let url2 = '/route.php?c=wholesaler/getAll';
  url2 += `&show_competitor=${showCompetitor}`;
  let members = await requestGet({ url: url2 });

  let url3 = '/route.php?c=supplier/getAll';
  url3 += `&show_competitor=${showCompetitor}`;
  const response = await requestGet({ url: url3 });

  let busTypes = [];
  try {
    let url4 = '/route.php?c=customer/getAllBusinessTypes';
    busTypes = await requestGet({ url: url4 });
  } catch (e) {
    console.warn(e);
  }

  let url5 = '/route.php?c=brand/getAll';
  url5 += `&show_competitor=${showCompetitor}`;
  const brds = await requestGet({ url: url5 });

  let url6 = '/route.php?c=depot/getDepots';
  const depotRes = await requestGet({ url: url6 });

  let depots = [];
  _.each(depotRes, depot => {
    depots.push({
      fieldKey: encodeText(depot.DepotName).replace(/\s/g, ''),
      fieldValue: depot.DepotName,
    });
  });

  let brands = [];
  _.each(brds, brand => {
    brands.push({ fieldKey: brand.Brand, fieldValue: brand.Brand });
  });

  let businessTypes = [];
  _.each(busTypes, type => {
    businessTypes.push({ fieldKey: type, fieldValue: type });
  });
  let suppliers = [];
  _.each(response, supplier => {
    if (supplier.SupplierName === 'BACARDI BROWN FOREMAN BRANDS') {
      return;
    }
    suppliers.push({
      fieldKey: supplier.SupplierName,
      fieldValue: supplier.SupplierName,
    });
  });
  if (
    members.length === 2 &&
    members.filter(mem => mem.ActualName === 'ALL').length > 0
  ) {
    members = [members[0]];
  }

  if (
    members.length > 2 &&
    getUserRoles().filter(role => role.Title === 'role_view_unitas_ht_drinks')
      .length > 0
  ) {
    const mem = _.filter(members, memm => memm.ActualName === 'HT Drinks')[0];
    members = [mem];
  }

  dispatch(
    fetchDataAction({
      categoryHierarchy: { ...result },
      departments: departments,
      categories: categories,
      subCategories: subCategories,
      level4s: level4s,
      level5s: level5s,
      brands: brands,
      businessTypes: businessTypes,
      members: members,
      suppliers: suppliers,
      depots: depots,
    }),
  );

  dispatch(setLoadingAction(false));
};

export default handleActions(
  {
    [SAVE_QUERY]: (state, { payload }) => ({ ...state, queryId: payload }),
    [FETCH_QUERY]: (state, { payload }) => ({
      ...state,
      queryStatus: payload.Status,
    }),
    [FETCH_HISTORY]: (state, { payload }) => ({
      ...state,
      queries: payload.queries,
      limit: payload.limit,
    }),
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      categoryHierarchy: payload.categoryHierarchy,
      departments: payload.departments,
      categories: payload.categories,
      subCategories: payload.subCategories,
      suppliers: payload.suppliers,
      members: payload.members,
      level4s: payload.level4s,
      level5s: payload.level5s,
      brands: payload.brands,
      businessTypes: payload.businessTypes,
      depots: payload.depots,
    }),
    [SET_CATEGORIES]: (state, { payload }) => ({
      ...state,
      categories: payload.categories,
      subCategories: payload.subCategories,
      level4s: payload.level4s,
      level5s: payload.level5s,
    }),
    [SET_SUBCATEGORIES]: (state, { payload }) => ({
      ...state,
      subCategories: payload.subCategories,
      level4s: payload.level4s,
      level5s: payload.level5s,
    }),
    [SET_LEVEL4S]: (state, { payload }) => ({
      ...state,
      level4s: payload.level4s,
      level5s: payload.level5s,
    }),
    [SET_LEVEL5S]: (state, { payload }) => ({ ...state, level5s: payload }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_IS_DOWNLOADING]: (state, { payload }) => ({
      ...state,
      isDownloading: payload,
    }),
    [SET_TIMER]: (state, { payload }) => ({ ...state, timer: payload }),
    [SET_QUERY_NAME]: (state, { payload }) => ({ ...state, name: payload }),
    [QUERY_SCHEDULED]: (state, { payload }) => ({
      ...state,
      isQueryScheduled: true,
    }),
    [RESET_QUERY_SCHEDULE]: (state, { payload }) => ({
      ...state,
      isQueryScheduled: false,
    }),
    [SET_RERUN_QUERY]: (state, { payload }) => ({
      ...state,
      name: payload.updatedQuery.name,
      Dates: payload.updatedQuery.Dates,
      Wholesalers: payload.updatedQuery.Wholesalers,
      filter: payload.updatedQuery.filter,
      show: payload.updatedQuery.show,
      builtQuery: payload.builtQuery,
    }),
    [SET_SELECTED_QUERY]: (state, { payload }) => ({
      ...state,
      selectedQuery: payload,
    }),
    [FETCH_TIMEFRAMES]: (state, { payload }) => ({
      ...state,
      timeframes: payload.timeframes,
      defaultDateRange: payload.defaultRange,
      builtQuery: payload.builtQuery,
    }),
    [SET_STATUS_COMPLETED]: (state, { payload }) => ({
      ...state,
      queryStatus: 'COMPLETED',
    }),
    [FETCH_COMPLETED_DATA]: (state, { payload }) => ({
      ...state,
      completedData: payload,
    }),
    [UPDATE_QUERY_FIELD_VISIBILITY]: (state, { payload }) => {
      const newState = {
        ...state,
        builtQuery: {
          ...state.builtQuery,
          Column_Order: payload.columnOrder,
          Pivot_Table: payload.pivotObject,
        },
      };
      payload.updatedValues.forEach(({ key, newVal }) => {
        newState.builtQuery[`Show_${key}`] = newVal;
      });

      return newState;
    },
    [REMOVE_DATE_RANGE]: (state, { payload }) => ({
      ...state,
      builtQuery: { ...payload.builtQuery },
    }),
    [ADD_DATE_RANGES]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        newDateRanges: payload.newDateRanges,
        Column_Order: payload.columnOrder,
      },
    }),
    [ADD_WHOLESALER]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        Wholesalers: payload.option,
      },
    }),
    [UPDATE_FIELD]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        [payload.key]: payload.value,
      },
    }),
    [SAVE_FILTER]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        [`Filter_${payload.name}`]: payload.filters,
      },
    }),
    [SAVE_REORDER_COLUMNS]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        Column_Order: [...payload.newColumnOrder],
      },
    }),
    [SAVE_REORDER_DATA]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        Pivot_Table: payload,
      },
    }),
    [PIVOT_CHANGE_DATA]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        Pivot_Table: payload,
      },
    }),
    [UPDATE_DATE_RANGE]: (state, { payload }) => ({
      ...state,
      builtQuery: {
        ...state.builtQuery,
        newDateRanges: [...payload.newDateRanges],
        Column_Order: [...payload.newColumnOrder],
      },
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      productsForDesc: payload.productsForDesc,
      productsForCode: payload.productsForCode,
      membersProductCode: payload.membersProductCode,
    }),
    [RESET_QUERY_BUILDER]: (state, { payload }) => ({
      ...initialState,
      builtQuery: {
        ...initialState.builtQuery,
      },
    }),
  },
  initialState,
);
