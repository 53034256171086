import React from 'react';
import PropTypes from 'prop-types';
import { each } from 'lodash';
import { withTheme } from 'styled-components';
import $ from 'jquery';
import { getRandomNumber } from '../../helpers/helpers';
import { getUserSystem } from '../../helpers/util';

import makeMorris from '../../assets/js/morris';

class GraphPerformance extends React.Component {
  state = {
    graphID: getRandomNumber('graph_'),
  };

  componentDidMount() {
    this.renderGraph();
  }

  shouldComponentUpdate(nextProps) {
    const oldData = JSON.stringify(this.props.data);
    const newData = JSON.stringify(nextProps.data);
    if (oldData === newData) {
      return false;
    }
    return true;
  }

  componentDidUpdate() {
    this.destroyGraph();
    this.renderGraph();
  }

  destroyGraph = () => {
    $(window).off('resize');
    $(`#${this.state.graphID}`).html('');
    window.morrisObj = NaN;
  };

  renderGraph = () => {
    each(this.props.data, data => (data.label = data.label));

    const Morris = makeMorris($);
    this.destroyGraph();
    if (this.props.section === 'Analysis') {
      Morris.Donut({
        element: 'graph',
        data: this.props.data,
        resize: true,
        labelColor: '#fff',
        //  colors: getUserSystem() === 'unitas' ? ['90-#b9040e-#d50410:60', '90-#b60711-#e30613:60', '90-#d24d4e-#f96060:60', '90-#cb3839-#f14243:60'] : ['#71a758', '#8fcb74', '#9ada7d', '#ace493'],
        colors: [
          this.props.theme.donutColor1,
          this.props.theme.donutColor2,
          this.props.theme.donutColor3,
          this.props.theme.donutColor4,
          this.props.theme.donutColor5,
        ],
        formatter: this.props.formatter,
      });
    } else {
      Morris.Donut({
        element: 'graph',
        data: this.props.data,
        resize: true,
        labelColor: '#fff',
        // Using colors
        // colors: getUserSystem() === 'unitas' ? ['90-#b9040e-#d50410:60', '90-#b60711-#e30613:60', '90-#d24d4e-#f96060:60', '90-#cb3839-#f14243:60'] : ['#71a758', '#8fcb74', '#9ada7d', '#ace493'],
        // Using styled component theme
        colors: [
          this.props.theme.donutColor1,
          this.props.theme.donutColor2,
          this.props.theme.donutColor3,
          this.props.theme.donutColor4,
          this.props.theme.donutColor5,
        ],
        formatter: this.props.formatter,
      });
    }
  };

  render() {
    if (this.props.data && this.props.data.length == 0) {
      return (
        <div id="graph" className="c-objects-wrap c-objects-wrap--graph"></div>
      );
    } else {
      return (
        <div>
          <div
            id="graph"
            className="c-objects-wrap c-objects-wrap--graph"></div>
          <div className="black-circle">&nbsp;</div>
        </div>
      );
    }
  }
}

GraphPerformance.defaultProps = {
  data: [],
  formatter: t => t,
};

GraphPerformance.propTypes = {
  data: PropTypes.array,
  formatter: PropTypes.func,
};

export default withTheme(GraphPerformance);
