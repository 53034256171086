import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { DATE_FORMAT, DATE_FORMAT_JQUERY } from '../../../AlchemySettings';

import { loadStylesheet, loadLibrary } from '../../../helpers/helpers';

import '../../../assets/css/jquery-ui.css';

import Modal from '../Modal';
import AlchToggleMenu from './AlchToggleMenu';

class AlchDateRangepicker extends React.Component {
  state = {
    modalOpen: false,
    value: {
      start: this.props.defaultValue.start
        ? new Date(this.props.defaultValue.start)
        : undefined,
      end: this.props.defaultValue.end
        ? new Date(this.props.defaultValue.end)
        : undefined,
      label: '13WK_TY',
    },
    isInitDatePicker: false,
  };

  componentWillMount() {
    require('../../../helpers/jquery.datepicker-weekpicker');

    const value = {};
    /* Overwrite default value if set */
    if (this.props.value.start && this.props.value.end) {
      value.start = new Date(this.props.value.start);
      value.end = new Date(this.props.value.end);
      value.label = this.props.value.label;
      this.setState({
        value,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.value.start !== 'undefined' &&
      typeof nextProps.value.end !== 'undefined' &&
      nextProps.value.start.length > 0 &&
      nextProps.value.end.length > 0
    ) {
      const value = {
        start: new Date(nextProps.value.start),
        end: new Date(nextProps.value.end),
        label: nextProps.value.label,
      };
      this.setState({
        value,
      });
    }
  }

  componentDidMount() {
    this.props.onInit(this.state.value, this.props.name);
  }

  componentDidUpdate() {
    if (this.state.modalOpen && !this.state.isInitDatePicker) {
      this.initDatepickers();
    }
  }

  /* Async preload library */
  libraryDep = require('../../../assets/js/jquery-ui.js');

  datesPreselectChange = (id, name, uid) => {
    if (!uid.start || !uid.end) {
      return;
    }
    const value = {
      start: new Date(uid.start),
      end: new Date(uid.end),
      label: id,
    };

    this.setState(
      {
        value,
      },
      () => {
        if ($(this.startDateInput).datepicker)
          $(this.startDateInput).datepicker('setDate', value.start);
        if ($(this.endDateInput).datepicker)
          $(this.endDateInput).datepicker('setDate', value.end);
      },
    );
  };

  initDatepickers = () => {
    //$(this.startDateInput).datepicker({ maxDate:  new Date() });
    $('.c-date').weekpicker(
      {
        dateFormat: DATE_FORMAT_JQUERY,
      },
      {
        startDate: this.startDateInput,
        endDate: this.endDateInput,
      },
    );

    $(this.startDateInput).on('weekselected', (e, dates) => {
      let newValue = {
        ...this.state.value,
        start: dates.start,
        label: 'custom',
      };
      if (dates.end) {
        newValue.end = dates.end;
      }
      this.setState(
        {
          value: newValue,
        },
        () => {
          $(this.startDateInput).datepicker('setDate', this.state.value.start);
          $(this.endDateInput).datepicker('setDate', this.state.value.end);
        },
      );
    });

    $(this.endDateInput).on('weekselected', (e, dates) => {
      let newValue = {
        ...this.state.value,
        end: dates.end,
        label: 'custom',
      };
      if (dates.start) {
        newValue.start = dates.start;
      }
      this.setState(
        {
          value: newValue,
        },
        () => {
          $(this.startDateInput).datepicker('setDate', this.state.value.start);
          $(this.endDateInput).datepicker('setDate', this.state.value.end);
        },
      );
    });

    /* Init values from state */
    $(this.startDateInput)
      .datepicker('option', 'maxDate', new Date())
      .datepicker('setDate', this.state.value.start);
    $(this.endDateInput)
      .datepicker('option', 'maxDate', new Date())
      .datepicker('setDate', this.state.value.end);

    this.setState({ isInitDatePicker: true });
  };

  openModal = e => {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      isInitDatePicker: false,
    });

    this.props.onChange(this.state.value, this.props.name);

    if (this.props.onSelect) {
      console.error(
        'On select is deprecated, update to use on change along with name attribute',
      );
      this.props.onSelect(this.state.value); // Obsolete
    }
  };

  renderDate = which => {
    const sDate = this.state.value[which];
    if (sDate && sDate !== undefined) {
      return dateFormat(sDate, DATE_FORMAT);
    }
    return 'select date';
  };

  onRemoveDateRange = evt => {
    evt.preventDefault();
    this.props.onRemoveDateRange(this.props.id);
  };

  render() {
    return (
      <div className="c-daterange-picker-wrap">
        <a
          href="#"
          className="c-daterange-picker-preview"
          onClick={this.openModal}>
          Date range: {this.state.value.label}
          {/* W/E: */}
          {/* <span>{this.renderDate('start')}</span> */}
          {/* - W/E: */}
          {/* <span>{this.renderDate('end')}</span> */}
        </a>
        &nbsp;&nbsp;
        <a href="#" onClick={this.onRemoveDateRange}>
          <i
            style={
              this.props.cantRemove ? { display: 'none' } : { color: 'red' }
            }
            className="fa fa-times"
          />
        </a>
        <Modal
          size="large"
          open={this.state.modalOpen}
          onCloseClick={this.closeModal}>
          {$(this.startDateInput).datepicker ? (
            <div className="c-daterange-picker t-brand-null u-layout">
              {this.props.preselects.length > 0 && (
                <div className="c-daterange-picker__column u-1_3">
                  <h4 className="c-daterange-picker__header">Common Dates</h4>
                  <AlchToggleMenu
                    vertical
                    value={this.state.value.label}
                    onChange={this.datesPreselectChange}>
                    {this.props.preselects}
                  </AlchToggleMenu>
                </div>
              )}
              <div className="c-daterange-picker__column u-1_3 text-center">
                <h4 className="c-daterange-picker__header">Start Date</h4>
                <p className="font-small">(week commencing date)</p>
                {this.state.value.start && (
                  <input
                    type="text"
                    ref={input => {
                      this.startDateInput = input;
                    }}
                    className="c-date startDate"
                    data-active-days="1"
                  />
                )}
              </div>
              <div className="c-daterange-picker__column u-1_3 text-center">
                <h4 className="c-daterange-picker__header">End date</h4>
                <p className="font-small">(week ending date)</p>
                {this.state.value.end && (
                  <input
                    type="text"
                    ref={input => {
                      this.endDateInput = input;
                    }}
                    className="c-date endDate"
                    data-active-days="0"
                  />
                )}
              </div>
            </div>
          ) : (
            <div class="text-center">
              <h2>
                Error <ErrorOutlineIcon />
              </h2>
              <button
                class="btn alchemy-btn--others"
                onClick={() => window.location.reload()}>
                Please click to refresh the page
              </button>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

AlchDateRangepicker.defaultProps = {
  preselects: Array.prototype,
  onSelect: undefined, // Obsolete
  onChange: Function.prototype,
  onInit: Function.prototype,
  defaultValue: { end: undefined, start: undefined },
  value: { end: undefined, start: undefined },
};

AlchDateRangepicker.propTypes = {
  name: PropTypes.string.isRequired, // To trigger change with label
  preselects: PropTypes.array,
  onInit: PropTypes.func,
  onSelect: PropTypes.func, // Obsolete
  onChange: PropTypes.func,
  value: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }),
  defaultValue: PropTypes.shape({
    start: PropTypes.any,
    end: PropTypes.any,
  }),
};

export default AlchDateRangepicker;
