import { handleActions, createAction } from 'redux-actions';
import store from '../store';
import { request } from '../../../helpers/axios';
import {
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';

const SET_PRODUCTS = 'prod/SET_PRODUCTS';
const SET_SEARCH = 'prod/SET_SEARCH';
const SET_TIMEFRAME = 'prod/SET_TIMEFRAME';
const SET_PURCHASE = 'prod/SET_PURCHASE';

const initialState = {
  products: '',
  search: '',
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },    
    ...(getUserSystem() === 'cjlang'
    ? [
        { ID: 'cjl_ytd', Text: 'Financial Year To Date'},
        { ID: 'cjl_fy', Text: 'Financial Year'},
      ]
    : []),

  ],
  timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : getUserSystem() == 'countryRangeGroup' ? 'MAT' : '4wks'),
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  purchase: getUserSystem() == 'countryRangeGroup' ? 'in' : 'out',
};
const setProductsAction = createAction(SET_PRODUCTS);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setSearchAction = createAction(SET_SEARCH);
const setPurchaseAction = createAction(SET_PURCHASE);

export const setSearchValue = (value) => dispatch => {
  dispatch(setSearchAction(value));
}

export const setProducts = (value) => dispatch => {
  dispatch(setProductsAction(value));
};

export const setTimeFrame = val => dispatch => {
  dispatch(setTimeFrameAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const fetchProducts = (searchQuery, dataType) => async dispatch => {
  let state = store.getState().productSearch;
  let url = '/route.php?c=productFinder/productFinderReport' +
  '&search=' +
  encodeURIComponent(searchQuery) +
  '&dataType=' +
  dataType + 
  '&type=' +
  state.purchase +
  '&timeframe=' +
  state.timeframe;

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setProductsAction(result.length > 0 ? result : 'empty'));
  });
};

export default handleActions(
  {
    [SET_PRODUCTS]: (state, { payload }) => ({ ...state, products: payload }),
    [SET_SEARCH]: (state, { payload }) => ({ ...state, search: payload }),
    [SET_TIMEFRAME]: (state, { payload }) => ({ ...state, timeframe: payload }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
  },
  initialState,
);
