import { handleActions, createAction } from 'redux-actions';
import { request, customRequest, requestGet } from '../../../helpers/axios';
import {
  encodeText,
  getToken,
  getUserId,
  getUserSystem,
} from '../../../helpers/util';
import store from '../store';
import { each, orderBy, map } from 'lodash';
import { CompareSharp } from '@material-ui/icons';

const FETCH_DATA = 'stocked/FETCH_DATA';
const FETCH_STOCKED_DATA = 'stocked/FETCH_STOCKED_DATA';
const EXPORT_TO_PDF_SUCCESS = 'stocked/EXPORT_TO_PDF_SUCCESS';
const EXPORT_TO_PDF_FAILED = 'stocked/EXPORT_TO_PDF_FAILED';
const SET_PMP = 'stocked/SET_PMP';
const SET_EXPORTING = 'stocked/SET_EXPORTING';
const SET_EXPORTED = 'stocked/SET_EXPORTED';
const SET_LOADING = 'stocked/SET_LOADING';
const SET_PURCHASE = 'report/SET_PURCHASE';
const RESET = 'stocked/RESET';
const SET_DEPARTMENT = 'stocked/SET_DEPARTMENT';
const SET_CATEGORY = 'stocked/SET_CATEGORY';
const SET_SUBCATEGORY = 'stocked/SET_SUBCATEGORY';
const SET_LEVEL4 = 'stocked/SET_LEVEL4';
const SET_LEVEL5 = 'stocked/SET_LEVEL5';
const SET_BRAND = 'stocked/SET_BRAND';
const SET_TIMEFRAME = 'stocked/SET_TIMEFRAME';
const SET_SELECTED_GROUP = 'stocked/SET_SELECTED_GROUP';
const FETCH_ALL_DATA_TABLE = 'stocked/FETCH_ALL_DATA_TABLE';

const initialState = {
  stockedReportData: {},
  exportAllData: {},
  isExporting: false,
  isExported: false,
  pdfURL: '',
  isLoading: false,
  departments: [],
  department: {},
  categories: [],
  category: {},
  subCategories: [],
  subCategory: {},
  level4s: [],
  level4: {},
  level5s: [],
  level5: {},
  brands: [],
  brand: {},
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  purchase: 'out',
  pmpList: [
    { ID: '0', Text: 'Parent' },
    { ID: '1', Text: 'Child' },
  ],
  pmp: '',
  timeframe: { ID: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'), Text: '4 Weeks' },
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Weeks' },
    { ID: '13wks', Text: '13 Weeks' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ],
  selectedGroup: [],
  groups: [],
};

const fetchAllDataTableAction = createAction(FETCH_ALL_DATA_TABLE);
const fetchDataAction = createAction(FETCH_DATA);
const fetchStockedData = createAction(FETCH_STOCKED_DATA);
const setPurchaseAction = createAction(SET_PURCHASE);
const setExportingAction = createAction(SET_EXPORTING);
const setExportedAction = createAction(SET_EXPORTED);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const exportToPdfFailedAction = createAction(EXPORT_TO_PDF_FAILED);
const setLoadingAction = createAction(SET_LOADING);
const setPmpAction = createAction(SET_PMP);
const resetAction = createAction(RESET);
const setDepartmentAction = createAction(SET_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setBrandAction = createAction(SET_BRAND);
const setTimeframeAction = createAction(SET_TIMEFRAME);
const setSelectedGroupAction = createAction(SET_SELECTED_GROUP);

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'new-stocked-report-' + new Date().toISOString(),
      layout: 'landscape',
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const loadReport = () => dispatch => {
  dispatch(fetchStockedData({}));
  dispatch(setLoadingAction(true));
  dispatch(loadTable());
};

export const fetchCategories = () => dispatch => {
  dispatch(setLoadingAction(true));
  const userObj = JSON.parse(localStorage.user_data);
  let url = '/route.php';
  if (userObj.departments.length === 0 && userObj.categories.length === 0) {
    url += '?c=category/getHierarchy';
  } else {
    url += '?c=category/getUserHierarchy';
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    let url3 = '/route.php?c=brand/getAll';
    request({
      url: url3,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(brands => {
      each(brands, brd => {
        brd.Brand = encodeText(brd.Brand);
      });

      each(result.Categories, cat => {
        cat.Text = encodeText(cat.Text);
      });

      requestGet({ url: '/route.php?c=groupSupplier/getAll' }).then(groups => {
        let groupsArr =
          (groups && groups.map((group, i) => ({ Text: group, ID: i }))) || [];
        let orderedGroups = orderBy(groupsArr, ['Text']);

        dispatch(
          fetchDataAction({
            departments: result.Departments ? result.Departments : [],
            categories: result.Categories ? result.Categories : [],
            purchase: 'out',
            pmp: '0',
            brands: brands,
            groups: orderedGroups,
          }),
        );

        if (result.Categories && result.Categories.length === 1) {
          let catt = result.Categories[0];
          each(catt.SubCategories, subCat => {
            subCat.Text = encodeText(subCat.Text);
          });
          dispatch(setCategoryAction(catt));
          if (catt.SubCategories.length === 1) {
            let subcat = catt.SubCategories[0];
            dispatch(setSubCategoryAction(subcat));
          }
        }
        dispatch(setLoadingAction(false));
        dispatch(loadTable());
      });
    });
  });
};

export const setPmp = val => dispatch => {
  dispatch(setPmpAction(val));
};

export const setTimeframe = val => dispatch => {
  dispatch(setTimeframeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setPurchaseAction(val));
};

export const setDepartment = val => dispatch => {
  dispatch(setLevel5Action({}));
  dispatch(setLevel4Action({ Level5: [] }));
  dispatch(setSubCategoryAction({ Level4: [] }));
  dispatch(setCategoryAction({ SubCategories: [] }));

  if (parseInt(val) > 0) {
    let department = _.filter(
      store.getState().stockedReport.departments,
      dpt => {
        if (dpt.ID === parseInt(val)) {
          return dpt;
        }
      },
    );
    let dpt = department[0];

    dispatch(setDepartmentAction(dpt));
    if (dpt.Categories.length === 1) {
      let cat = dpt.Categories[0];
      dispatch(setCategoryAction(cat));
      if (cat.SubCategories.length === 1) {
        let subcat = cat.SubCategories[0];
        dispatch(setSubCategoryAction(subcat));
      }
    }
  } else if (parseInt(val) === 0) {
    dispatch(setDepartmentAction({ Categories: [] }));
  } else {
    let department = _.filter(
      store.getState().stockedReport.departments,
      dpt => {
        if (dpt.Text === val) {
          return dpt;
        }
      },
    );
    let dpt = department[0];
    dispatch(setDepartmentAction(dpt));
  }
};

export const setCategory = val => dispatch => {
  dispatch(setLevel5Action({}));
  dispatch(setLevel4Action({ Level5: [] }));
  dispatch(setSubCategoryAction({ Level4: [] }));
  if (parseInt(val) > 0) {
    let category = _.filter(store.getState().stockedReport.categories, cat => {
      if (cat.ID === parseInt(val)) {
        return cat;
      }
    });
    let catt = category[0];
    dispatch(setCategoryAction(catt));
    if (catt.SubCategories.length === 1) {
      let subcat = catt.SubCategories[0];
      dispatch(setSubCategoryAction(subcat));
    }
  } else if (parseInt(val) === 0) {
    dispatch(setCategoryAction({ SubCategories: [] }));
  } else {
    let category = _.filter(store.getState().stockedReport.categories, cat => {
      if (cat.Text === val) {
        return cat;
      }
    });
    let catt = category[0];
    dispatch(setCategoryAction(catt));
  }
};

export const setSubCategory = val => dispatch => {
  dispatch(setLevel5Action({}));
  dispatch(setLevel4Action({ Level5: [] }));
  if (parseInt(val) > 0) {
    _.each(store.getState().stockedReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.ID === val) {
          dispatch(setSubCategoryAction(subcat));
        }
      });
    });
  } else if (parseInt(val) === 0) {
    dispatch(setSubCategoryAction({ Level4: [] }));
  } else {
    _.each(store.getState().stockedReport.categories, cat => {
      _.each(cat.SubCategories, subcat => {
        if (subcat.Text === val) {
          dispatch(setSubCategoryAction(subcat));
        }
      });
    });
  }
};

export const setLevel4 = val => dispatch => {
  dispatch(setLevel5Action({}));
  if (parseInt(val) > 0) {
    _.each(store.getState().stockedReport.categories, cat => {
      if (cat.ID === store.getState().stockedReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().stockedReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === val) {
                dispatch(setLevel4Action(lvl4));
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel4Action({ Level5: [] }));
  } else {
    _.each(store.getState().stockedReport.categories, cat => {
      if (cat.ID === store.getState().stockedReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().stockedReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.Text === val) {
                dispatch(setLevel4Action(lvl4));
              }
            });
          }
        });
      }
    });
  }
};

export const setLevel5 = val => dispatch => {
  if (parseInt(val) > 0) {
    _.each(store.getState().stockedReport.categories, cat => {
      if (cat.ID === store.getState().stockedReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().stockedReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().stockedReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.ID === val) {
                    dispatch(setLevel5Action(lvl5));
                  }
                });
              }
            });
          }
        });
      }
    });
  } else if (parseInt(val) === 0) {
    dispatch(setLevel5Action({}));
  } else {
    _.each(store.getState().stockedReport.categories, cat => {
      if (cat.ID === store.getState().stockedReport.category.ID) {
        _.each(cat.SubCategories, subcat => {
          if (subcat.ID === store.getState().stockedReport.subCategory.ID) {
            _.each(subcat.Level4, lvl4 => {
              if (lvl4.ID === store.getState().stockedReport.level4.ID) {
                _.each(lvl4.Level5, lvl5 => {
                  if (lvl5.Text === val) {
                    dispatch(setLevel5Action(lvl5));
                  }
                });
              }
            });
          }
        });
      }
    });
  }
};

export const setBrand = val => dispatch => {
  dispatch(setBrandAction(val));
};

export const setGroup = val => async dispatch => {
  dispatch(setSelectedGroupAction(val));
  let url = '/route.php?c=wholesaler/getAll';
  if (val && val.length > 0) {
    url += `&group=${encodeURIComponent(map(val, 'Text').join(','))}`;
  }
  let members;
  try {
    members = await requestGet({ url: url });
  } catch (error) {
    members = [];
    console.warn(error);
  }

  dispatch(setMembersAction(members));
};

export const loadTable = () => dispatch => {
  let url = '/route.php?c=stockedNotStocked/getStockedNotStockedReport';
  const state = store.getState().stockedReport;

  if (state.department.ID) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }

  if (state.category.ID) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }

  if (state.subCategory.ID) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }

  if (state.level4.ID) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }

  if (state.level5.ID) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }
  if (state.brand.Brand) {
    url += '&brand=' + encodeURIComponent(state.brand.Brand);
  }

  if (state.pmp.length > 0) {
    url += '&pmp=' + state.pmp;
  }

  if (state.purchase.length > 0) {
    url += '&type=' + state.purchase;
  }
  if (state.timeframe.ID) {
    url += '&timeframe=' + encodeURIComponent(state.timeframe.ID);
  }

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  dispatch(setLoadingAction(true));
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    let headers = [];
    let rows = [];
    Object.keys(result).map(function(keyName, keyIndex) {
      let valo = [];
      valo.push({ text: result[keyName].ProdName });
      Object.keys(result[keyName].Wholesalers).map(function(kName, kIndex) {
        let val = _.filter(headers, header => header.text === kName);
        if (val.length === 0) {
          headers.push({ text: kName });
        }
        let valu = result[keyName].Wholesalers[kName].status;
        let rest = '';
        switch (valu) {
          case 0:
            rest = 'Red';
            break;
          case 1:
            rest = 'Orange';
            break;
          case 2:
            rest = 'Green';
            break;
        }
        valo.push({
          text: rest,
          message: result[keyName].Wholesalers[kName].displayMessage,
        });
      });
      rows.push(valo);
    });
    dispatch(setLoadingAction(false));
    dispatch(fetchStockedData({ headers: headers, rows: rows }));
  });
};

export const exportAllToCSV = () => dispatch => {
  let url =
    '/route.php?c=stockedNotStocked/getStockedNotStockedReport&limit=99999';
  const state = store.getState().stockedReport;

  if (state.department.ID) {
    url += '&department=' + encodeURIComponent(state.department.Text);
  }

  if (state.category.ID) {
    url += '&category=' + encodeURIComponent(state.category.Text);
  }

  if (state.subCategory.ID) {
    url += '&subcategory=' + encodeURIComponent(state.subCategory.Text);
  }

  if (state.level4.ID) {
    url += '&level4=' + encodeURIComponent(state.level4.Text);
  }

  if (state.level5.ID) {
    url += '&level5=' + encodeURIComponent(state.level5.Text);
  }
  if (state.brand.Brand) {
    url += '&brand=' + encodeURIComponent(state.brand.Brand);
  }

  if (state.pmp.length > 0) {
    url += '&pmp=' + state.pmp;
  }

  if (state.purchase.length > 0) {
    url += '&type=' + state.purchase;
  }
  if (state.timeframe.ID) {
    url += '&timeframe=' + encodeURIComponent(state.timeframe.ID);
  }

  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  })
    .then(result => {
      let headers = [];
      let rows = [];
      Object.keys(result).map(function(keyName, keyIndex) {
        let valo = [];
        valo.push({ text: result[keyName].ProdName });
        Object.keys(result[keyName].Wholesalers).map(function(kName, kIndex) {
          let val = _.filter(headers, header => header.text === kName);
          if (val.length === 0) {
            headers.push({ text: kName });
          }
          let valu = result[keyName].Wholesalers[kName].status;
          let rest = '';
          switch (valu) {
            case 0:
              rest = 'Red';
              break;
            case 1:
              rest = 'Orange';
              break;
            case 2:
              rest = 'Green';
              break;
          }
          valo.push({
            text: rest,
            message: result[keyName].Wholesalers[kName].displayMessage,
          });
        });
        rows.push(valo);
      });
      dispatch(setLoadingAction(false));
      dispatch(fetchAllDataTableAction({ headers: headers, rows: rows }));
    })
    .catch(err => {
      console.warn(err);
    });
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      pmp: payload.pmp,
      purchase: payload.purchase,
      departments: payload.departments,
      categories: payload.categories,
      brands: payload.brands,
      groups: payload.groups,
    }),
    [FETCH_ALL_DATA_TABLE]: (state, { payload }) => ({
      ...state,
      exportAllData: payload,
    }),
    [FETCH_STOCKED_DATA]: (state, { payload }) => ({
      ...state,
      stockedReportData: payload,
    }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_PMP]: (state, { payload }) => ({ ...state, pmp: payload }),
    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      department: payload,
      categories: payload.Categories,
    }),
    [SET_CATEGORY]: (state, { payload }) => ({
      ...state,
      category: payload,
      subCategories: payload.SubCategories,
    }),
    [SET_SUBCATEGORY]: (state, { payload }) => ({
      ...state,
      subCategory: payload,
      level4s: payload.Level4,
    }),
    [SET_LEVEL4]: (state, { payload }) => ({
      ...state,
      level4: payload,
      level5s: payload.Level5,
    }),
    [SET_LEVEL5]: (state, { payload }) => ({ ...state, level5: payload }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload }),
    [SET_SELECTED_GROUP]: (state, { payload }) => ({
      ...state,
      selectedGroup: payload,
    }),
    [SET_TIMEFRAME]: (state, { payload }) => ({ ...state, timeframe: payload }),
    [RESET]: (state, { payload }) => ({ ...state, ...initialState }),
  },
  initialState,
);
