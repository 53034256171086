import { handleActions, createAction } from 'redux-actions';
import moment from 'moment';
import { requestGet, customRequest } from '../../../helpers/axios';

const FETCH_MISSING_DAYS = 'likeforlike/FETCH_MISSING_DAYS';
const FETCH_STATUS_REPORT = 'likeforlike/FETCH_STATUS_REPORT';
const FETCH_TOOLTIPS = 'likeforlike/FETCH_TOOLTIPS';
const SET_STATUS_REPORT_EXISTS = 'likeforlike/SET_STATUS_REPORT_EXISTS';
const EXPORT_TO_PDF_SUCCESS = 'stocked/EXPORT_TO_PDF_SUCCESS';
const EXPORT_TO_PDF_FAILED = 'stocked/EXPORT_TO_PDF_FAILED';
const SET_EXPORTING = 'stocked/SET_EXPORTING';
const SET_EXPORTED = 'stocked/SET_EXPORTED';
const SET_LOADING = 'likeforlike/SET_LOADING';
const SET_ERROR = 'likeforlike/SET_ERROR';
const RESET = 'likeforlike/RESET';

const initialState = {
  isLoading: false,
  statusReportData: {},
  tooltipsData: [],
  pdfURL: '',
  isExporting: false,
  isExported: false,
  statusReportExists: false,
  missingDays: {},
  isError: false,
};

const fetchMissingDaysAction = createAction(FETCH_MISSING_DAYS);
const fetchStatusReportAction = createAction(FETCH_STATUS_REPORT);
const fetchTooltipsAction = createAction(FETCH_TOOLTIPS);
const setStatusReportExistsAction = createAction(SET_STATUS_REPORT_EXISTS);
const setExportingAction = createAction(SET_EXPORTING);
const setExportedAction = createAction(SET_EXPORTED);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const exportToPdfFailedAction = createAction(EXPORT_TO_PDF_FAILED);
const setLoadingAction = createAction(SET_LOADING);
const setIsError = createAction(SET_ERROR);
const resetAction = createAction(RESET);

export const reset = () => dispatch => {
  dispatch(resetAction());
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName: 'status-report-' + new Date().toISOString(),
      layout: 'landscape',
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const checkStatusReport = () => async dispatch => {
  dispatch(setLoadingAction(true));
  const result = await requestGet({
    url: '/route.php?c=likeForLike/checkStatusReport',
  });
  dispatch(setStatusReportExistsAction(result));
  dispatch(setLoadingAction(false));
};

export const fetchTooltips = () => async dispatch => {
  dispatch(setLoadingAction(true));
  try {
    const result = await requestGet({
      url: '/route.php?c=likeForLike/getTooltips',
    });

    dispatch(fetchTooltipsAction(result));
  } catch (e) {
    dispatch(setIsError(true));
    dispatch(setLoadingAction(false));
  }
};

export const fetchMissingDays = () => async dispatch => {
  dispatch(setLoadingAction(true));

  try {
    const result = await requestGet({
      url: '/route.php?c=likeForLike/getTotalMissingDays',
    });

    dispatch(fetchMissingDaysAction(result));
  } catch (e) {
    dispatch(setIsError(true));
    dispatch(setLoadingAction(false));
  }
};

export const fetchStatusReport = () => async dispatch => {
  dispatch(setLoadingAction(true));
  try {
    const result = await requestGet({
      url: '/route.php?c=likeForLike/getStatusReport',
    });

    let headers = [];
    let rows = [];
    Object.keys(result).map(function (keyName, keyIndex) {
      let valo = [];
      let header = '';
      Object.keys(result[keyName]).map(function (kName, kIndex) {
        let row = {};
        if (kIndex === 0) {
          row.text = moment(result[keyName].YearNoWeekNo, 'DD-MM-YY').format(
            'DD/MM/YY',
          );
        } else {
          let val = _.filter(headers, header => header.text === kName);
          if (val.length === 0) {
            row.header = kName.replace('_', ' ');
            headers.push({ text: kName });
          }
          if (result[keyName][kName] === 'RED') {
            dispatch(setStatusReportExistsAction(true));
          }
          row.text = result[keyName][kName];
          row.header = kName.replace('_', ' ');
        }
        valo.push(row);
      });
      rows.push(valo);
    });
    dispatch(setLoadingAction(false));
    dispatch(fetchStatusReportAction({ headers: headers, rows: rows }));
  } catch (e) {
    dispatch(setIsError(true));
    dispatch(setLoadingAction(false));
  }
};

export default handleActions(
  {
    [RESET]: (state, { payload }) => ({
      ...initialState,
      statusReportData: {},
    }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_ERROR]: (state, { payload }) => ({ ...state, isError: payload }),
    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_STATUS_REPORT_EXISTS]: (state, { payload }) => ({
      ...state,
      statusReportExists: payload,
    }),
    [FETCH_TOOLTIPS]: (state, { payload }) => ({
      ...state,
      tooltipsData: payload,
    }),
    [FETCH_STATUS_REPORT]: (state, { payload }) => ({
      ...state,
      statusReportData: payload,
    }),
    [FETCH_MISSING_DAYS]: (state, { payload }) => ({
      ...state,
      missingDays: payload,
    }),
  },
  initialState,
);

export const selectors = {};
