import { handleActions, createAction } from 'redux-actions';
import { request } from '../../../helpers/axios';
import { encodeText, getUserSystem, getToken, getUserId } from '../../../helpers/util';
import { createSelector } from 'reselect';
import { filter } from 'lodash';
import store from '../store';
import uuid from 'uuid/v4';

const DELETE_USER_RESPONSE_LOCAL = 'DELETE_USER_RESPONSE_LOCAL';
const DELETE_DISTRIBUTION_LIST_BY_NAME = 'psa/DELETE_DISTRIBUTION_LIST_BY_NAME';
const SET_VIEW_CAMPAIGN_FILTER = 'psa/SET_VIEW_CAMPAIGN_FILTER';
const FETCH_DISTRIBUTION_LISTS = 'psa/FETCH_DISTRIBUTION_LISTS';
const FETCH_CAMPAIGNS = 'psa/FETCH_CAMPAIGNS';
const FETCH_CAMPAIGN = 'psa/FETCH_CAMPAIGN';
const FETCH_USER_ACTIVITIES = 'psa/FETCH_USER_ACTIVITIES';
const FETCH_USER_RESPONSES = 'psa/FETCH_USER_RESPONSES';
const SET_IS_DISTRIBUTION_LIST_ADDED = 'psa/SET_IS_DISTRIBUTION_LIST_ADDED';
const SET_IS_DISTRIBUTION_LIST_DELETED = 'psa/SET_IS_DISTRIBUTION_LIST_DELETED';
const SET_IS_RECIPIENT_GROUP_ADDED = 'psa/SET_IS_RECIPIENT_GROUP_ADDED';
const SET_IS_RECIPIENT_GROUP_DELETED = 'psa/SET_IS_RECIPIENT_GROUP_DELETED';
const SET_IS_DISTRIBUTION_LIST_UPDATED = 'psa/SET_IS_DISTRIBUTION_LIST_UPDATED';
const SET_IS_USER_RESPONSE_DELETED = 'psa/SET_IS_USER_RESPONSE_DELETED';
const SET_DISTRIBUTION_LIST_ERROR = 'psa/SET_DISTRIBUTION_LIST_ERROR';
const SET_SAVING_RESPONSE = 'psa/SET_SAVING_RESPONSE';
const ADD_NEW_DISTRIBUTION_LIST = 'psa/ADD_NEW_DISTRIBUTION_LIST';
const ADD_NEW_RECIPIENT_GROUP = 'psa/ADD_NEW_RECIPIENT_GROUP';
const UPDATE_RECIPIENT_GROUP = 'psa/UPDATE_RECIPIENT_GROUP';
const ADD_NEW_MEMBER = 'psa/ADD_NEW_MEMBER';
const DELETE_MEMBER = 'psa/DELETE_MEMBER';
const SET_IS_CAMPAIGN_UPDATED = 'psa/SET_IS_CAMPAIGN_UPDATED';
const SET_IS_CAMPAIGN_DELETED = 'psa/SET_IS_CAMPAIGN_DELETED';
const SET_IS_CAMPAIGN_ADDED = 'psa/SET_IS_CAMPAIGN_ADDED';
const SET_IS_CAMPAIGN_SENT = 'psa/SET_IS_CAMPAIGN_SENT';
const SET_IS_USER_RESPONSE_ADDED = 'psa/SET_IS_USER_RESPONSE_ADDED';
const SET_FILTER_CAMPAIGNS = 'psa/SET_FILTER_CAMPAIGNS';
const SET_FILTER_ACTIVITIES = 'psa/SET_FILTER_ACTIVITIES';
const SET_FILTER_RECIPIENT_GROUPS = 'psa/SET_FILTER_RECIPIENT_GROUPS';
const SET_CAMPAIGN_TEXT = 'psa/SET_CAMPAIGN_TEXT';
const SET_CAMPAIGN_DESCRIPTION = 'psa/SET_CAMPAIGN_DESCRIPTION';
const REMOVE_ACTIVITY = 'psa/REMOVE_ACTIVITY';
const ADD_NEW_ACTIVITY = 'psa/ADD_NEW_ACTIVITY';
const ADD_NEW_CAMPAIGN = 'psa/ADD_NEW_CAMPAIGN';
const SET_ACTIVITY_IMAGE = 'psa/SET_ACTIVITY_IMAGE';
const RESET_CAMPAIGN = 'psa/RESET_CAMPAIGN';
const RESET_CAMPAIGNS = 'psa/RESET_CAMPAIGNS';
const ADD_USER_RESPONSE_LOCAL = 'psa/ADD_USER_RESPONSE_LOCAL';
const SET_RESPONSE_NOTES_LOCAL = 'psa/SET_RESPONSE_NOTES_LOCAL';
const REMOVE_SELECTED_RESPONSE = 'psa/REMOVE_SELECTED_RESPONSE';
const ADD_SELECTED_RESPONSE = 'psa/ADD_SELECTED_RESPONSE';
const SET_LOADING = 'psa/SET_LOADING';

const initialState = {
  viewCampaignFilters: {
    campaign: '',
    activity: '',
    recipientGroupName: '',
  },
  campaign: {
    name: '',
    activityName: '',
    archieved: false,
    id: '',
    activities: [],
  },
  userActivities: [],
  userResponses: [],
  userResponseLocal: {},
  userResponsesLocal: [],
  campaigns: [],
  distributionLists: [],
  filterCampaigns: [],
  filterActivities: [],
  filterRecipientGroups: [],
  selectedResponses: [],
  isDistributionListUpdated: false,
  isDistributionListDeleted: false,
  isDistributionListAdded: false,
  isRecipientGroupDeleted: false,
  isCampaignAdded: false,
  isCampaignUpdated: false,
  isCampaignSent: false,
  isUserResponseDeleted: false,
  isRecipientGroupUpdated: false,
  isUserResponseAdded: false,
  isSavingResponse: false,
  error: '',
  isLoading: false,
};

const authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IlRFTVAyVVNFUiI=.1EsTlybbFx+SdddIozAtzpC9wKJQUcDmXYfycYEPzy4=';
const userId = '053D4BB4-E0A3-2654-6441-264497ABFC94';
const system = 'bdm';

const deleteUserResponseLocalAction = createAction(DELETE_USER_RESPONSE_LOCAL);
const setViewCampaignFilterAction = createAction(SET_VIEW_CAMPAIGN_FILTER);
const deleteDistributionListByNameAction = createAction(DELETE_DISTRIBUTION_LIST_BY_NAME);
const fetchDistributionListsAction = createAction(FETCH_DISTRIBUTION_LISTS);
const fetchCampaignsAction = createAction(FETCH_CAMPAIGNS);
const fetchCampaignAction = createAction(FETCH_CAMPAIGN);
const fetchUserActivitiesAction = createAction(FETCH_USER_ACTIVITIES);
const fetchUserResponsesAction = createAction(FETCH_USER_RESPONSES);
const setIsDistributionListDeletedAction = createAction(SET_IS_DISTRIBUTION_LIST_DELETED);
const setIsDistributionListAddedAction = createAction(SET_IS_DISTRIBUTION_LIST_ADDED);
const setIsRecipientGroupAddedAction = createAction(SET_IS_RECIPIENT_GROUP_ADDED);
const setIsDistributionListUpdatedAction = createAction(SET_IS_DISTRIBUTION_LIST_UPDATED);
const setIsUserResponseAddedAction = createAction(SET_IS_USER_RESPONSE_ADDED);
const addNewDistributionListAction = createAction(ADD_NEW_DISTRIBUTION_LIST);
const setDistributionListErrorAction = createAction(SET_DISTRIBUTION_LIST_ERROR);
const addNewRecipientGroupAction = createAction(ADD_NEW_RECIPIENT_GROUP);
const updateRecipientGroupAction = createAction(UPDATE_RECIPIENT_GROUP);
const addNewMemberAction = createAction(ADD_NEW_MEMBER);
const addNewCampaignAction = createAction(ADD_NEW_CAMPAIGN);
const deleteMemberAction = createAction(DELETE_MEMBER);
const setIsCampaignDeletedAction = createAction(SET_IS_CAMPAIGN_DELETED);
const setIsCampaignAddedAction = createAction(SET_IS_CAMPAIGN_ADDED);
const setIsCampaignUpdatedAction = createAction(SET_IS_CAMPAIGN_UPDATED);
const setIsCampaignSentAction = createAction(SET_IS_CAMPAIGN_SENT);
const setFilterCampaignsAction = createAction(SET_FILTER_CAMPAIGNS);
const setFilterActivitiesAction = createAction(SET_FILTER_ACTIVITIES);
const setFilterRecipientGroupsAction = createAction(SET_FILTER_RECIPIENT_GROUPS);
const setCampaignTextAction = createAction(SET_CAMPAIGN_TEXT);
const setCampaignDescriptionAction = createAction(SET_CAMPAIGN_DESCRIPTION);
const setIsUserResponseDeletedAction = createAction(SET_IS_USER_RESPONSE_DELETED);
const setIsRecipientGroupDeletedAction = createAction(SET_IS_RECIPIENT_GROUP_DELETED);
const removeActivityAction = createAction(REMOVE_ACTIVITY);
const addNewActivityAction = createAction(ADD_NEW_ACTIVITY);
const setActivityImageAction = createAction(SET_ACTIVITY_IMAGE);
const resetCampaignAction = createAction(RESET_CAMPAIGN);
const resetCampaignsAction = createAction(RESET_CAMPAIGNS);
const addUserResponseLocalAction = createAction(ADD_USER_RESPONSE_LOCAL);
const setResponseNotesLocalAction = createAction(SET_RESPONSE_NOTES_LOCAL);
const removeSelectedResponseAction = createAction(REMOVE_SELECTED_RESPONSE);
const addSelectedResponseAction = createAction(ADD_SELECTED_RESPONSE);
const setSavingResponseAction = createAction(SET_SAVING_RESPONSE);
const setLoadingAction = createAction(SET_LOADING);

export const deleteUserResponseLocal = id => dispatch => {
  dispatch(deleteUserResponseLocalAction(id));
};

export const setViewCampaignFilter = (name, value) => dispatch => {
  dispatch(setViewCampaignFilterAction({ name, value }));
};

export const selectResponse = id => dispatch => {
  if (store.getState().psa.selectedResponses.filter(resp => resp === id).length > 0) {
    dispatch(removeSelectedResponseAction(id));
  } else {
    dispatch(addSelectedResponseAction(id));
  }
};

export const addUserResponseLocal = response => dispatch => {
  dispatch(addUserResponseLocalAction(response));
};

export const responseNotesLocalChange = (id, value) => dispatch => {
  dispatch(setResponseNotesLocalAction({ id, value }));
};

export const resetCampaigns = () => dispatch => {
  dispatch(resetCampaignsAction());
};

export const resetCampaign = () => dispatch => {
  dispatch(resetCampaignAction());
};

export const setActivityImage = (id, image) => dispatch => {
  dispatch(setActivityImageAction({ id, image }));
};

export const setCampaignText = value => dispatch => {
  dispatch(setCampaignTextAction(value));
};

export const setCampaignDescription = (id, value) => dispatch => {
  dispatch(setCampaignDescriptionAction({ id, value }));
};

export const removeActivity = id => dispatch => {
  dispatch(removeActivityAction(id));
};

export const addNewActivity = data => dispatch => {
  dispatch(addNewActivityAction(data));
};

export const addNewCampaign = data => dispatch => {
  dispatch(addNewCampaignAction(data));
};

export const getReportByCampaign = id => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=reports/getReportByCampaign&campaignID=${id}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    const link = document.createElement('a');
    link.href = result.reportUrl;
    document.body.appendChild(link);
    link.click();
    dispatch(setLoadingAction(false));
  });
};

export const downloadSelected = id => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=download/downloadSelected&campaignID=${id}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: { selected: store.getState().psa.selectedResponses },
  }).then(result => {
    const link = document.createElement('a');
    link.href = result.reportUrl;
    document.body.appendChild(link);
    link.click();
    dispatch(setLoadingAction(false));
  });
};

export const downloadAll = id => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=download/downloadAll&campaignID=${id}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    const link = document.createElement('a');
    link.href = result.reportUrl;
    document.body.appendChild(link);
    link.click();
    dispatch(setLoadingAction(false));
  });
};

export const deleteUserResponse = (id, isUploadDetails) => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsUserResponseDeletedAction(false));
  let url = `/route.php?c=response/deleteResponse&uid=${id}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: isUploadDetails ? authToken : getToken(),
      UserId: isUploadDetails ? userId : getUserId(),
      System: isUploadDetails ? system : getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsUserResponseDeletedAction(true));
  });
};

export const addUserResponse = (data, isUploadDetails) => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setSavingResponseAction(true));
  dispatch(setIsUserResponseAddedAction({ value: false, id: data.id }));
  let url = `/route.php?c=response/addResponses&responseId=${data.responseId}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: isUploadDetails ? authToken : getToken(),
      UserId: isUploadDetails ? userId : getUserId(),
      System: isUploadDetails ? system : getUserSystem(),
    },
    data: {
      responses: [data],
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setSavingResponseAction(false));
    dispatch(setIsUserResponseAddedAction({ value: true, id: data.id }));
  });
};

export const fetchResponsesForCampaign = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(fetchUserResponsesAction([]));
  let url = `/route.php?c=response/getAll&campaignID=${id}`;
  if (store.getState().psa.viewCampaignFilters.activity.length > 0) {
    url += `&activityID=${store.getState().psa.viewCampaignFilters.activity}`;
  }
  if (store.getState().psa.viewCampaignFilters.recipientGroupName.length > 0) {
    url += `&recipientGroupID=${store.getState().psa.viewCampaignFilters.recipientGroupName}`;
  }
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(fetchUserResponsesAction(result));
  });
};

export const fetchUserResponsesForRecipient = (ids, isUploadDetails) => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=response/getAll&campaignID=${ids[0]}&recipientID=${ids[1]}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: isUploadDetails ? authToken : getToken(),
      UserId: isUploadDetails ? userId : getUserId(),
      System: isUploadDetails ? system : getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(fetchUserResponsesAction(result));
  });
};

export const fetchUserResponses = (id, isUploadDetails) => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=response/getAll&campaignID=${id}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: isUploadDetails ? authToken : getToken(),
      UserId: isUploadDetails ? userId : getUserId(),
      System: isUploadDetails ? system : getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(fetchUserResponsesAction(result));
  });
};

export const getActivitiesForUser = (uid, isUploadDetails) => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=response/getActivitiesForUser&uid=${uid}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: isUploadDetails ? authToken : getToken(),
      UserId: isUploadDetails ? userId : getUserId(),
      System: isUploadDetails ? system : getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(fetchUserActivitiesAction(result));
  });
};

export const fetchCampaignByID = id => dispatch => {
  dispatch(setLoadingAction(true));
  let url = `/route.php?c=campaign/getCampaignByID&campaignID=${id}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(fetchCampaignAction(result.campaign[0]));
  });
};

export const archiveCampaignByID = (id, active) => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsCampaignUpdatedAction(false));
  let url = `/route.php?c=campaign/archiveCampaignByID&campaignID=${id}&active=${active}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCampaignUpdatedAction(true));
  });
};

export const saveCampaign = () => dispatch => {
  const campaign = {
    campaign: [store.getState().psa.campaign],
  };
  dispatch(setLoadingAction(true));
  dispatch(setIsCampaignAddedAction(false));
  let url = `/route.php?c=campaign/saveCampaign`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: campaign,
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCampaignAddedAction(true));
  });
};

export const sendCampaign = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsCampaignSentAction(false));
  let url = `/route.php?c=campaign/sendCampaign&campaignID=${store.getState().psa.campaign.id}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCampaignSentAction(true));
  });
};

export const updateDistributionList = data => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsDistributionListUpdatedAction(false));
  let url = `/route.php?c=distributionList/saveDistributionList&originalDistributionListName=${data.name}`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: { distributionList: [data.list] },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsDistributionListUpdatedAction(true));
  });
};

export const saveDistributionList = data => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsDistributionListAddedAction(false));
  const list = { distributionLists: [data.list] };
  let url = `/route.php?c=distributionList/saveDistributionList`;
  request({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
    data: list,
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsDistributionListAddedAction(true));
  });
};

export const addNewMember = data => dispatch => {
  const list = store.getState().psa.distributionLists.filter(lst => lst.name === data.listName);
  if (list.length == 0) {
    dispatch(setDistributionListErrorAction('List with this name does not exists.'));
  } else {
    let selectedList = list[0];
    selectedList.recipientGroup.map(group => {
      if (group.name === data.groupName) {
        if (group.members.filter(mem => mem.email === data.email).length > 0) {
          group.members.map(mem => {
            if (mem.email === data.email) {
              mem.firstName = data.firstName;
              mem.lastName = data.lastName;
            }
          });
        } else {
          group.members.push({ id: uuid(), firstName: data.firstName, lastName: data.lastName, email: data.email });
        }
      }
    });
    dispatch(addNewMemberAction(selectedList));
  }
};

export const deleteMember = id => dispatch => {
  dispatch(deleteMemberAction(id));
};

export const addNewDistributionList = list => dispatch => {
  dispatch(addNewDistributionListAction(list));
};

export const updateRecipientGroup = (listId, groupId, groupName) => dispatch => {
  const list = store.getState().psa.distributionLists.filter(lst => lst.id === listId);
  if (list.length === 0) {
    dispatch(setDistributionListErrorAction('List with given name does not exist.'));
  } else {
    const disList = list[0];
    const group = disList.recipientGroup.filter(group => group.id === groupId);
    if (group.length === 0) {
      dispatch(setDistributionListErrorAction('Group does not exist.'));
    } else {
      dispatch(updateRecipientGroupAction({ listId, groupId, groupName }));
    }
  }
};

export const addNewRecipientGroup = (listId, groupName) => dispatch => {
  const list = store.getState().psa.distributionLists.filter(lst => lst.id === listId);
  const distList = list[0];
  const group = distList.recipientGroup.filter(group => group.name === groupName);
  if (group.length > 0) {
    dispatch(setDistributionListErrorAction('Group with same name already exists.'));
  } else {
    let selectedList = list[0];
    selectedList.recipientGroup.push({ id: uuid(), name: groupName, members: [] });
    dispatch(addNewRecipientGroupAction(selectedList));
  }
};

export const deleteRecipientGroupByID = data => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsRecipientGroupDeletedAction(false));
  let url = `/route.php?c=distributionList/deleteRecipentGroupByID&id=${data}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsRecipientGroupDeletedAction(true));
  });
};

export const deleteDistributionListByName = name => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsDistributionListDeletedAction(false));
  let url = `/route.php?c=distributionList/deleteDistributionListByName&distributionListName=${name}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsDistributionListDeletedAction(true));
  });
};

export const fetchDistributionLists = () => dispatch => {
  dispatch(setLoadingAction(true));
  let url = '/route.php?c=distributionList/getAll';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    let groups = [];
    result.distributionLists.map(lst => {
      lst.recipientGroup.map(grp => {
        groups.push({ ID: grp.id, Text: grp.name });
      });
    });
    dispatch(setFilterRecipientGroupsAction(groups));
    dispatch(setLoadingAction(false));
    dispatch(fetchDistributionListsAction(result.distributionLists || []));
  });
};

export const deleteCampaignByID = id => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(setIsCampaignDeletedAction(false));
  let url = `/route.php?c=campaign/deleteCampaignByID&campaignID=${id}`;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setLoadingAction(false));
    dispatch(setIsCampaignDeletedAction(true));
  });
};

export const fetchCampaignsForSearch = () => dispatch => {
  dispatch(setLoadingAction(true));
  if (store.getState().psa.viewCampaignFilters.campaign.length === 0 && store.getState().psa.viewCampaignFilters.activity.length === 0 && store.getState().psa.viewCampaignFilters.recipientGroupName.length === 0) {
    dispatch(resetCampaignAction());
  } else {
    dispatch(resetCampaignAction());
    let url = '/route.php?c=campaign/getAll';
    if (store.getState().psa.viewCampaignFilters.campaign.length > 0) {
      url += `&campaignID=${store.getState().psa.viewCampaignFilters.campaign}`;
    }
    if (store.getState().psa.viewCampaignFilters.activity.length > 0) {
      url += `&activityID=${store.getState().psa.viewCampaignFilters.activity}`;
    }
    if (store.getState().psa.viewCampaignFilters.recipientGroupName.length > 0) {
      url += `&recipientGroupID=${store.getState().psa.viewCampaignFilters.recipientGroupName}`;
    }
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: getUserSystem(),
      },
    }).then(result => {
      let campaigns = [];
      let activities = [];
      result.campaign.map(cpg => {
        campaigns.push({ ID: cpg.id, Text: cpg.name });
        cpg.activities.map(act => {
          activities.push({ ID: act.id, Text: act.name });
        });
      });
      dispatch(setLoadingAction(false));
      dispatch(fetchCampaignsAction(result.campaign || []));
      if (
        (store.getState().psa.viewCampaignFilters.campaign || store.getState().psa.viewCampaignFilters.activity || store.getState().psa.viewCampaignFilters.recipientGroupName) &&
        (store.getState().psa.viewCampaignFilters.campaign.length > 0 || store.getState().psa.viewCampaignFilters.activity.length > 0 || store.getState().psa.viewCampaignFilters.recipientGroupName.length > 0)
      ) {
        dispatch(fetchCampaignAction(result.campaign[0]));
      } else {
        dispatch(setFilterCampaignsAction(campaigns));
        dispatch(setFilterActivitiesAction(activities));
      }
    });
  }
};

export const fetchCampaigns = data => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(fetchCampaignsAction([]));
  dispatch(resetCampaignAction());
  let url = '/route.php?c=campaign/getAll';
  if (data && data.campaign && data.campaign.length > 0) {
    url += `&campaignID=${data.campaign}`;
  }
  if (data && data.activity && data.activity.length > 0) {
    url += `&activityID=${data.activity}`;
  }
  if (data && data.recipientGroupName && data.recipientGroupName.length > 0) {
    url += `&recipientGroupID=${data.recipientGroupName}`;
  }
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    let campaigns = [];
    let activities = [];
    result.campaign.map(cpg => {
      campaigns.push({ ID: cpg.id, Text: cpg.name });
      cpg.activities.map(act => {
        activities.push({ ID: act.id, Text: act.name });
      });
    });
    dispatch(setLoadingAction(false));
    dispatch(fetchCampaignsAction(result.campaign || []));
    if (data && (data.campaign || data.activity || data.recipientGroupName) && (data.campaign.length > 0 || data.activity.length > 0 || data.recipientGroupName.length > 0)) {
      dispatch(fetchCampaignAction(result.campaign[0]));
    } else {
      dispatch(setFilterCampaignsAction(campaigns));
      dispatch(setFilterActivitiesAction(activities));
    }
  });
};

export default handleActions(
  {
    [DELETE_USER_RESPONSE_LOCAL]: (state, { payload }) => ({ ...state, userResponsesLocal: state.userResponsesLocal.filter(resp => resp.id !== payload) }),
    [FETCH_DISTRIBUTION_LISTS]: (state, { payload }) => ({ ...state, distributionLists: payload }),
    [SET_VIEW_CAMPAIGN_FILTER]: (state, { payload }) => ({ ...state, viewCampaignFilters: { ...state.viewCampaignFilters, [payload.name]: payload.value } }),
    [FETCH_CAMPAIGNS]: (state, { payload }) => ({ ...state, campaigns: payload }),
    [FETCH_CAMPAIGN]: (state, { payload }) => ({ ...state, campaign: payload }),
    [FETCH_USER_RESPONSES]: (state, { payload }) => ({ ...state, userResponses: payload }),
    [ADD_USER_RESPONSE_LOCAL]: (state, { payload }) => ({ ...state, userResponsesLocal: [...state.userResponsesLocal, payload] }),
    [SET_RESPONSE_NOTES_LOCAL]: (state, { payload }) => ({
      ...state,
      userResponsesLocal: state.userResponsesLocal.map(resp => {
        if (resp.id === payload.id) {
          resp.notes = payload.value;
        }
        return resp;
      }),
    }),
    [RESET_CAMPAIGN]: (state, { payload }) => ({
      ...state,
      campaign: {
        name: '',
        activityName: '',
        archieved: false,
        id: '',
        activities: [],
      },
    }),
    [RESET_CAMPAIGNS]: (state, { payload }) => ({ ...state, campaigns: [] }),
    [FETCH_USER_ACTIVITIES]: (state, { payload }) => ({ ...state, userActivities: payload }),
    [SET_IS_DISTRIBUTION_LIST_ADDED]: (state, { payload }) => ({ ...state, isDistributionListAdded: payload }),
    [SET_IS_DISTRIBUTION_LIST_DELETED]: (state, { payload }) => ({ ...state, isDistributionListDeleted: payload }),
    [SET_IS_DISTRIBUTION_LIST_UPDATED]: (state, { payload }) => ({ ...state, isDistributionListUpdated: payload }),
    [SET_IS_RECIPIENT_GROUP_ADDED]: (state, { payload }) => ({ ...state, isRecipientGroupAdded: payload }),
    [SET_IS_RECIPIENT_GROUP_DELETED]: (state, { payload }) => ({ ...state, isRecipientGroupDeleted: payload }),
    [SET_IS_CAMPAIGN_DELETED]: (state, { payload }) => ({ ...state, isCampaignDeleted: payload }),
    [SET_IS_CAMPAIGN_ADDED]: (state, { payload }) => ({ ...state, isCampaignAdded: payload }),
    [SET_IS_CAMPAIGN_UPDATED]: (state, { payload }) => ({ ...state, isCampaignUpdated: payload }),
    [SET_IS_CAMPAIGN_SENT]: (state, { payload }) => ({ ...state, isCampaignSent: payload }),
    [REMOVE_SELECTED_RESPONSE]: (state, { payload }) => ({ ...state, selectedResponses: state.selectedResponses.filter(resp => resp !== payload) }),
    [ADD_SELECTED_RESPONSE]: (state, { payload }) => ({ ...state, selectedResponses: [...state.selectedResponses, payload] }),
    [SET_SAVING_RESPONSE]: (state, { payload }) => ({ ...state, isSavingResponse: payload }),
    [SET_IS_USER_RESPONSE_ADDED]: (state, { payload }) => ({ ...state, isUserResponseAdded: payload.value, userResponsesLocal: payload.value ? state.userResponsesLocal.filter(resp => resp.id !== payload.id) : state.userResponsesLocal }),
    [SET_DISTRIBUTION_LIST_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
    [SET_FILTER_CAMPAIGNS]: (state, { payload }) => ({ ...state, filterCampaigns: payload }),
    [SET_FILTER_ACTIVITIES]: (state, { payload }) => ({ ...state, filterActivities: payload }),
    [SET_FILTER_RECIPIENT_GROUPS]: (state, { payload }) => ({ ...state, filterRecipientGroups: payload }),
    [SET_CAMPAIGN_TEXT]: (state, { payload }) => ({ ...state, campaign: { ...state.campaign, name: payload } }),
    [SET_IS_USER_RESPONSE_DELETED]: (state, { payload }) => ({ ...state, isUserResponseDeleted: payload }),
    [SET_ACTIVITY_IMAGE]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        activities: state.campaign.activities.map(act => {
          if (act.id === payload.id) {
            act.exampleImage = payload.image;
          }
          return act;
        }),
      },
    }),
    [REMOVE_ACTIVITY]: (state, { payload }) => ({ ...state, campaign: { ...state.campaign, activities: state.campaign.activities.filter(act => act.id !== payload) } }),
    [ADD_NEW_CAMPAIGN]: (state, { payload }) => ({ ...state, campaign: payload }),
    [ADD_NEW_ACTIVITY]: (state, { payload }) => ({ ...state, campaign: { ...state.campaign, activities: [...state.campaign.activities, payload] } }),
    [SET_CAMPAIGN_DESCRIPTION]: (state, { payload }) => ({
      ...state,
      campaign: {
        ...state.campaign,
        activities: state.campaign.activities.map(act => {
          if (act.id === payload.id) {
            act.description = payload.value;
          }
          return act;
        }),
      },
    }),
    [DELETE_MEMBER]: (state, { payload }) => ({
      ...state,
      distributionLists: state.distributionLists.map(list => {
        list.recipientGroup.map(grp => {
          grp.members = grp.members.filter(mem => mem.id !== payload);
        });
        return list;
      }),
    }),
    [ADD_NEW_RECIPIENT_GROUP]: (state, { payload }) => ({
      ...state,
      distributionLists: state.distributionLists.map(list => {
        if (list.name === payload.name) {
          return payload;
        } else {
          return list;
        }
      }),
    }),
    [UPDATE_RECIPIENT_GROUP]: (state, { payload }) => ({
      ...state,
      distributionLists: state.distributionLists.map(list => {
        list.recipientGroup.map(grp => {
          if (grp.id === payload.groupId) {
            grp.name = payload.groupName;
          }
        });
        return list;
      }),
    }),
    [ADD_NEW_MEMBER]: (state, { payload }) => ({
      ...state,
      distributionLists: state.distributionLists.map(list => {
        if (list.name === payload.listName) {
          return payload;
        } else {
          return list;
        }
      }),
    }),
    [ADD_NEW_DISTRIBUTION_LIST]: (state, { payload }) => ({ ...state, distributionLists: [...state.distributionLists, payload] }),
    [SET_LOADING]: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },
  },
  initialState,
);

export const selectors = {};
