import React from 'react';
import { connect } from 'react-redux';
import { Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ExportIcon from '../../assets/images/export-icon.svg';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import styled from 'styled-components';
import Select from 'react-select';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import { withRouter } from 'react-router';
import 'react-select/dist/react-select.css';
import {
  fetchProducts,
  setSearchValue,
  setProducts,
  setPurchase,
  setTimeFrame,
} from '../redux/reducers/productSearch';
import ReactModal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import {
  getUserSystem, hasSalesInPermissions,
} from '../../helpers/util';
import { exportTableToCSV, getRandomNumber } from '../../helpers/helpers';
import Loading from '../components/Loading';
import { motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';
import ReportContainer from '../components/ReportContainer';
ReactModal.setAppElement('.alchemy_app');

const TableCell = styled.td`
  text-align: center;
`
const TableRow = styled.tr`
`
const TableHeading = styled.th`
  text-align: center;
  text-transform: capitalize;
  font-size: 0.75rem;
  font-weight: bold;
  vertical-align: bottom !important;
`
const ProductSearch = ({
  data,
  match,
  products,
  search,
  timeframes,
  timeframe,
  onTimeFrameChange,
  purchases,
  purchase,
  onPurchaseChange,
  onProductSearch,
  onSearchChange,
  onEnter,
  onExportData,
  resolvePercentage,
  resolveMoney,
  toggleExportMenu,
}) => {

  return (
    <motion.div
      key={'product_finder'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty c-alchemy-toasty--${
          getUserSystem() === 'unitas' ? 'unitas' : 'others'
        }`}
      />
      <div className="sub-menu-border bg-white">
        <div className="container">
          <div className="row">
            <div className="col sub-menu sub-menu__nam-dash d-flex justify-content-between pb-3">
              <div className="sub-menu__left">
                <h2 className="text-uppercase">Product Search  </h2>
                {hasSalesInPermissions(match.params.subpage) && (
                  <>
                    {getUserSystem() == 'countryRangeGroup' && (
                      <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                        <Select
                          placeholder="Sales In"
                          inputProps={{
                            autoComplete: 'off',
                            autoCorrect: 'off',
                            spellCheck: 'off',
                          }}
                          className={`wd-100 small-dropdown c-select-header ${
                            getUserSystem() === 'unitas'
                              ? 'c-select-header-row--unitas'
                              : 'c-select-header-row--others'
                          }`}
                          name="purchases"
                          id="purchases"
                          value={purchase}
                          onChange={onPurchaseChange}
                          options={purchases}
                          valueKey="ID"
                          labelKey="Text"
                          clearable={false}
                          searchable={false}
                        />
                      </div>
                    )}
                  </>
                )}
                
                
                <div className="btn-group text-uppercase">
                  <span className="dropdown-align-fix">For Latest:</span>
                </div>
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Time"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`wd-130 small-dropdown c-select-header c-select-header-row--others`}
                    name="timeframe"
                    id="timeframe"
                    value={timeframe}
                    onChange={onTimeFrameChange}
                    options={timeframes}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                    isMulti={false}
                  />
                </div>
              </div>
              <div className="sub-menu__right d-flex align-items-start">
                <div className="d-flex flex-wrap__wrap">
                  <div>
                    <Dropdown
                      isOpen={data.isExportMenuOpen}
                      toggle={() => toggleExportMenu()}>
                      <DropdownToggle 
                        disabled={products.length < 1 || products == '' || products == 'empty'}
                        className={`dropddown-toggle-btn alchemy-btn--others`}>
                        Export <img src={ExportIcon} alt="export" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={e => {
                            e.preventDefault();
                            onExportData('csv');
                          }}>
                          CSV
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportContainer padding={3} display="block">
        <div className={'container-fluid'}>
          <div className="d-flex mb-4">
            <input
              type="text"
              className="form-control query-builder__half"
              name="search"
              onChange={onSearchChange}
              onKeyDown={onEnter}
              value={search}
              placeholder="Enter search term"
            />
            <button
              type="button"
              onClick={onProductSearch}
              className={`btn btn-info alchemy-btn--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              } text-uppercase ml-2`}>
              Search
            </button>
          </div>
          {
            products !== 'empty' 
              ? products.length < 1
                ? products === '' 
                  ? <h2>Search for a product above to see a list of results</h2> 
                  : <Loading /> 
                : DataTable(products, resolvePercentage, resolveMoney) 
              : <h2>There are no results for your search. Please try again.</h2>
          }
        </div>
      </ReportContainer>
    </motion.div>
  );
};

const DataTable = (data, resolvePercentage, resolveMoney) =>
  <div style={{overflow: 'auto'}}>
    <Table bordered striped className={'text-black'} id="product_search">
      <thead>
      <TableRow>
        <TableHeading></TableHeading>
        {data[0].members.map(member => 
          <TableHeading>{member.name}</TableHeading>
        )}
      </TableRow>
      </thead>
      <tbody>
      {data.map(product =>
        <TableRow key={product.ProdDescription}>
          <TableCell className="text-left w-fit-content">
            <p className="mb-0">{product.ProdDescription}</p>
            <p className="mb-0 member-percent"> 
              {product.WVCategory !== "NIL" ? product.WVCategory : ""} 
              {product.WVSubCategory !== "NIL" ? " > " + product.WVSubCategory : ""} 
              {product.WVCategory2 !== "NIL" ? " > " + product.WVCategory2 : ""} 
              {product.WVSupplierName !== "NIL" ? " > " + product.WVSupplierName : ""} 
              {product.WVBrand !== "NIL" ? " | " + product.WVBrand : ""}
            </p>
            </TableCell>
          {product.members.map(member => 
            <TableCell style={{ verticalAlign: "middle" }}>
              <p className="mb-0">{resolveMoney(member)}</p>
              <p className={`mb-0 member-percent 
              ${member.TY/member.LY < 1 ? 'text-red' : 'text-green'} 
              ${member.LY == 0 ? 'd-none' : ''}`}>
                {resolvePercentage(member)}
              </p>
            </TableCell>
          )}
        </TableRow>
      )}
      </tbody>
    </Table>
  </div>

function mapStateToProps(state) {
  const data = {
    search: state.productSearch.search,
    products: state.productSearch.products,
    isLoading: state.productSearch.isLoading,
    timeframes: state.productSearch.timeframes,
    timeframe: state.productSearch.timeframe,
    purchases: state.productSearch.purchases,
    purchase:  state.productSearch.purchase,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchProducts,
    setSearchValue,
    setTimeFrame,
    setProducts,
    setPurchase,
  }),
  lifecycle({
    componentDidMount() {
      if (!hasSalesInPermissions(this.props.match.params.subpage)) {
        setPurchase('out')
      }
    },
  }),
  withState('data', 'handleData', {
    isExportMenuOpen: false,
  }),
  withHandlers({
    resolveMoney: props => (member) => {      
      return Math.round((member.TY)).toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });
    },
    resolvePercentage: props => ( member ) => {
      if (member.LY == 0) {
        return ""
      }
      let value = member.TY/member.LY;

      if (value < 1) {
        return ` - ${100- (Math.round(value*100))}%`
      } else {
        return ` + ${Math.round(value*100) - 100}%`
      }
    },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onExportData: props => () => {
        exportTableToCSV(
          $('#product_search'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
        );
    },
    onSearchChange: ({ setSearchValue }) => event => {
      const { value } = event.target;
      setSearchValue(value);
    },
    onEnter: ({ setProducts, fetchProducts, search }) => event => {
      if (event.key === 'Enter') {
        setProducts([]);
        fetchProducts(search, 'Wholesale');
      }
    },
    onProductSearch: props => () => {
      if (props.search.length < 4) {
        toast.error('Please ensure your search term is at least 4 characters long.');
      } else {
        props.setProducts([]);
        props.fetchProducts(props.search, 'Wholesale');
      }
    },
  onPurchaseChange:
    ({ data, handleData, setPurchase }) =>
    opt => {
      if (opt) {
        setPurchase(opt.ID);
      } else {
        setPurchase('');
      }
      handleData({ ...data, isRefreshDisabled: false });
    },
  onTimeFrameChange:
    ({ data, handleData, setTimeFrame }) =>
    opt => {
      if (opt) {
        setTimeFrame(opt.ID);
      } else {
        setTimeFrame('');
      }
      handleData({ ...data, isRefreshDisabled: false });
    },
  }),
)(ProductSearch);
