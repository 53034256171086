import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PerformanceGraphPage from '../PerformanceGraphPage';
import PerformanceReport from '../PerformanceReport';
import ProductPerformanceReport from '../ProductPerformanceReport';
import SkuTracker from '../SkuTrackerReport';
import ProductSearch from '../ProductSearch';
import BestSellers from '../BestSellersReport';
import MyStock from '../MyStock';
import CustomReportGraph from '../CustomReportGraph';
import DistributionPage from '../DistributionPage';
import SupplierEngagementReport from './SupplierEngagement';
import { getUserSystem, hasPermission } from '../../../helpers/util';
import KPI from '../KPI';
import DownloadCube from '../DownloadCube';

export default class NamDash extends React.Component {
  state = {
    subpage_nav: [],
  };

  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch>
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(category_performance|supplier_performance|brand_performance|wholesaler_performance|depot_performance|business_type_performance|group_performance|region_performance|fascia_performance)`}
              render={pageProps => (
                <PerformanceReport {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(product_performance)`}
              render={pageProps => (
                <ProductPerformanceReport {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(sku_tracker)`}
              render={pageProps => (
                <SkuTracker {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(product_finder)`}
              render={pageProps => (
                <ProductSearch {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(download_cube)`}
              render={pageProps => (
                <DownloadCube {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(kpi)`}
              render={pageProps => (
                <KPI {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(best_sellers)`}
              render={pageProps => (
                <BestSellers {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/supplier_engagement_report`}
              render={pageProps => (
                <SupplierEngagementReport {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(my_stock)`}
              render={pageProps => <MyStock {...this.props} {...pageProps} />}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(custom)`}
              render={pageProps => (
                <CustomReportGraph {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}nam_dash/:subpage(distribution)`}
              render={pageProps => (
                <DistributionPage {...this.props} {...pageProps} />
              )}
            />
            <Redirect
              from={`${process.env.APP_ROOT}nam_dash`}
              to={`${process.env.APP_ROOT}nam_dash/${
                getUserSystem() === 'suntory' 
                  ? 'kpi'
                  : hasPermission(`role_view_${getUserSystem().toLowerCase()}_category_performance`) || hasPermission(`role_view_${getUserSystem().toLowerCase()}_category_performance_retail`)
                    ? hasPermission(`role_view_${getUserSystem().toLowerCase()}_group_performance`)
                      ? 'group_performance'
                      : 'category_performance'
                    : 'product_finder'
              }/`}
            />
          </Switch>
        </AnimatePresence>
      </motion.div>
    );
  }
}

NamDash.defaultProps = {
  location: {},
};

NamDash.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
