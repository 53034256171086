import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import PropTypes from 'prop-types';

export default class HtmlEditor extends Component {
  static propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string
  };

  state = {
    value: RichTextEditor.createEmptyValue()
  }

  componentDidMount() {
	this.setState({value: RichTextEditor.createValueFromString(this.props.value, 'html')});
  }

  componentDidUpdate() {
	if (this.props.value !== this.state.value.toString('html')) {
		this.setState({value: RichTextEditor.createValueFromString(this.props.value, 'html')});
	};
  }

  onChange = (value) => {
    this.setState({value});
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  render () {
    return (
      <RichTextEditor
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}