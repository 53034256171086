import React from 'react';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { formatToCurrency } from '../../../helpers/helpers';

const StyledTable = styled(Table)`
  color: #000;
  text-align: center;

  td,
  th {
    text-align: left !important;
  }

  th,
  tr td:first-of-type {
    background: ${({ theme }) => theme.tableColor1};
    color: #fff;
    border: 2px solid #fff;
  }
  td:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  tr th:first-of-type {
    background-color: #fff;
  }
  th {
    width: 7rem;
  }

  .rdt_Table {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.tableColor1} #fff;
  }
`;

function SalesByWeekTable({ data: { headers, datasets } }) {
  return (
    <StyledTable>
      <tbody>
        <tr>
          <th></th>
          {headers &&
            headers.length > 0 &&
            headers.map(header => <th key={`${header}-key`}>{header}</th>)}
        </tr>
        {datasets &&
          datasets.length > 0 &&
          datasets.map(({ label, data }) => (
            <tr key={`${label}-key`}>
              <td>{label}</td>
              {data &&
                data.length > 0 &&
                data.map((data, id) => (
                  <td key={`${id}-table-data`}>{formatToCurrency(data)}</td>
                ))}
            </tr>
          ))}
      </tbody>
    </StyledTable>
  );
}

export default SalesByWeekTable;
