import { handleActions, createAction } from 'redux-actions';
import { request } from '../../../helpers/axios';
import { encodeText, getUserSystem, getToken, getUserId } from '../../../helpers/util';
import { createSelector } from 'reselect';
import { filter } from 'lodash';
import store from '../store';

const FETCH_DATA = 'admin/FETCH_DATA';
const FETCH_WHOLESALERS = 'admin/FETCH_WHOLESALERS';
const FETCH_TEXT_PERCENTAGE = 'admin/FETCH_TEXT_PERCENTAGE';
const SET_DEPARTMENT = 'admin/SET_DEPARTMENT';
const SET_PRODUCT_DEPARTMENT = 'admin/SET_PRODUCT_DEPARTMENT';
const SET_CATEGORY = 'admin/SET_CATEGORY';
const SET_PRODUCT_CATEGORY = 'admin/SET_PRODUCT_CATEGORY';
const SET_SUBCATEGORY = 'admin/SET_SUBCATEGORY';
const SET_PRODUCT_SUBCATEGORY = 'admin/SET_PRODUCT_SUBCATEGORY';
const SET_BRAND = 'admin/SET_BRAND';
const SET_PRODUCT_BRAND = 'admin/SET_PRODUCT_BRAND';
const SET_LEVEL_4 = 'admin/SET_LEVEL_4';
const SET_PRODUCT_LEVEL_4 = 'admin/SET_PRODUCT_LEVEL_4';
const SET_LEVEL_5 = 'admin/SET_LEVEL_5';
const SET_PRODUCT_LEVEL_5 = 'admin/SET_PRODUCT_LEVEL_5';
const SET_SUPPLIER = 'admin/SET_SUPPLIER';
const SET_PRODUCT_SUPPLIER = 'admin/SET_PRODUCT_SUPPLIER';
const SET_MASTERCODES = 'admin/SET_MASTERCODES';
const SET_MASTER = 'admin/SET_MASTER';
const SET_FILTER = 'admin/SET_FIlTER';
const SET_SELECTED = 'admin/SET_SELECTED';
const SET_MASTER_CODE = 'admin/SET_MASTER_CODE';
const SET_MASTER_TEXT = 'admin/SET_MASTER_TEXT';
const SET_MASTER_CODE_TEXT = 'admin/SET_MASTER_CODE_TEXT';
const SET_EDIT_MASTER_CODE = 'admin/SET_EDIT_MASTER_CODE';
const SAVE_DATA = 'admin/SAVE_DATA';
const SET_SUPPORT_EMAIL_SENT = 'admin/SET_SUPPORT_EMAIL_SENT';
const SET_ERROR = 'admin/SET_ERROR';
const SET_MESSAGE = 'admin/SET_MESSAGE';
const SET_LOADING = 'admin/SET_LOADING';
const SET_IS_PRODUCT_MODIFIED = 'admin/SET_IS_PRODUCT_MODIFIED';
const initialState = {
  masterCode: '',
  masterCodeText: '',
  masterText: '',
  wholeSaler: '',
  department: {},
  category: {},
  subcategory: {},
  supplier: {},
  wholesalers: [{ ID: 0, Text: 'Select Wholeview' }],
  mastercodes: [],
  departments: [{ ID: 0, Text: 'Select Department' }],
  department: 0,
  product_department: 0,
  categories: [{ ID: 0, Text: 'Select Category' }],
  product_category: 0,
  category: 0,
  subcategories: [{ ID: 0, Text: 'Select Sub Category' }],
  product_subcategory: 0,
  subcategory: 0,
  brands: [{ ID: 0, Text: 'Select Brand' }],
  product_brand: 0,
  brand: 0,
  level4s: [{ ID: 0, Text: 'Select Level 4' }],
  product_level4: 0,
  level4: 0,
  level5s: [{ ID: 0, Text: 'Select Level 5' }],
  product_level5: 0,
  level5: 0,
  suppliers: [{ ID: 0, Text: 'Select Supplier' }],
  supplier: 0,
  product_supplier: 0,
  bestseller: false,
  core_range: false,
  filterData: [],
  masterData: [],
  selectedData: [],
  textPercentage: '0',
  isSupportEmailSent: false,
  error: '',
  message: '',
  isLoading: false,
  isProductModified: false,
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchWholesalersAction = createAction(FETCH_WHOLESALERS);
const fetchTextPercentageAction = createAction(FETCH_TEXT_PERCENTAGE);
const setDepartmentAction = createAction(SET_DEPARTMENT);
const setProductDepartmentAction = createAction(SET_PRODUCT_DEPARTMENT);
const setCategoryAction = createAction(SET_CATEGORY);
const setProductCategoryAction = createAction(SET_PRODUCT_CATEGORY);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setProductSubCategoryAction = createAction(SET_PRODUCT_SUBCATEGORY);
const setBrandAction = createAction(SET_BRAND);
const setProductBrandAction = createAction(SET_PRODUCT_BRAND);
const setLevel4Action = createAction(SET_LEVEL_4);
const setProductLevel4Action = createAction(SET_PRODUCT_LEVEL_4);
const setLevel5Action = createAction(SET_LEVEL_5);
const setProductLevel5Action = createAction(SET_PRODUCT_LEVEL_5);
const setSupplierAction = createAction(SET_SUPPLIER);
const setProductSupplierAction = createAction(SET_PRODUCT_SUPPLIER);
const setMasterCodesAction = createAction(SET_MASTERCODES);
const setMasterAction = createAction(SET_MASTER);
const setMasterTextAction = createAction(SET_MASTER_TEXT);
const setFilterAction = createAction(SET_FILTER);
const setSelectedAction = createAction(SET_SELECTED);
const setMasterCodeAction = createAction(SET_MASTER_CODE);
const setMasterCodeTextAction = createAction(SET_MASTER_CODE_TEXT);
const setEditMasterCodeAction = createAction(SET_EDIT_MASTER_CODE);
const setSupportEmailSentAction = createAction(SET_SUPPORT_EMAIL_SENT);
const saveDataAction = createAction(SAVE_DATA);
const setErrorAction = createAction(SET_ERROR);
const setMessageAction = createAction(SET_MESSAGE);
const setLoadingAction = createAction(SET_LOADING);
const setIsProductModifiedAction = createAction(SET_IS_PRODUCT_MODIFIED);

// const baseURL = 'http://wsale.esands.co.uk';
const baseURL = 'https://api.wsale.co.uk';

const fetchTextPercentage = (val, dispatch) => {
  let url = '/route.php?c=cleansing/getStatus&wholeview=' + val;
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((result) => {
    dispatch(fetchTextPercentageAction(result.TextPercentage));
  });
};

export const sendSupportEmail = (options) => (dispatch) => {
  let url = '/route.php?c=support/send&supportBody=' + options.description + '&subject=' + options.subject;
  request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((data) => {
    dispatch(setSupportEmailSentAction());
  });
};

export const sendContactEmail = (options) => (dispatch) => {
  let url = '/route.php?c=support/sendContact&name=' + options.name + '&email=' + options.email + '&supplier=' + options.supplier + '&requestor=' + options.requestor;
  request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((data) => {
    dispatch(setSupportEmailSentAction());
  });
};

export const fetchDefaults = (val) => (dispatch) => {
  //let url = '/api/departments';
  let url = '/route.php?c=cleansing/getWholeviewCategoryStructure&wholeviewId=' + val + '';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((result) => {
    let departments = [{ ID: 0, Text: 'Select Department' }];
    let categories = [{ ID: 0, Text: 'Select Category' }];
    let subcategories = [{ ID: 0, Text: 'Select Sub Category' }];
    let brands = [{ ID: 0, Text: 'Select Brand' }];
    let level4s = [{ ID: 0, Text: 'Select Level 4' }];
    let level5s = [{ ID: 0, Text: 'Select Level 5' }];
    let suppliers = [{ ID: 0, Text: 'Select Supplier' }];
    dispatch(
      fetchDataAction({
        wholeSaler: { ID: result.wholeview.ID, Text: result.wholeview.Text },
        departments: [...departments, ...result.wholeview.Departments],
        categories: [...categories, ...result.wholeview.Departments[0].Categories],
        subcategories: [...subcategories, ...result.wholeview.Departments[0].Categories[0].SubCategories],
        level4s: [...level4s, ...result.wholeview.Departments[0].Categories[0].SubCategories[0].Level4],
        level5s: [...level5s, ...result.wholeview.Departments[0].Categories[0].SubCategories[0].Level4[0].Level5],
        brands: [...brands, ...result.wholeview.Departments[0].Categories[0].SubCategories[0].Level4[0].Level5[0].Brands],
        suppliers: [...suppliers, ...result.wholeview.suppliers],
      }),
    );
  });

  _.each(store.getState().admin.wholesalers, (wholesaler) => {
    if (wholesaler.wholeviewId === val) {
      fetchTextPercentage(wholesaler.wholeviewName, dispatch);
      window.setInterval(() => fetchTextPercentage(wholesaler.wholeviewName, dispatch), 300000);
    }
  });
};

export const fetchWholesalers = () => (dispatch) => {
  // let url = '/api/wholeviews';
  let url = '/route.php?c=cleansing/getWholeviews&data=jsonArray';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then((data) => {
    let wholesalers = [{ wholeviewId: 0, wholeviewName: 'Select Wholeview' }];
    dispatch(fetchWholesalersAction([...wholesalers, ...data]));
  });
};

export const setMasterCodeText = (text) => (dispatch) => {
  dispatch(setMasterCodeTextAction(text));
};

export const searchMasterCodes = (searchFor) => (dispatch) => {
  if (searchFor.length === 0) {
    dispatch(setFilterAction([]));
    return;
  }
  dispatch(setFilterAction([]));
  let state = store.getState().admin;
  let department = state.department ? state.department.Text : undefined,
    category = state.category ? state.category.Text : undefined,
    subcategory = state.subcategory ? state.subcategory.Text : undefined,
    supplier = state.supplier ? state.supplier.Text : undefined,
    level4 = state.level4 ? state.level4.Text : undefined,
    level5 = state.level5 ? state.level5.Text : undefined,
    brand = state.brand ? state.brand.Text : undefined;

  //let url = '/api/mastercodes';
  let url =
    '/route.php?c=cleansing/getParentProducts&keyword=' +
    encodeURIComponent(searchFor) +
    '&department=' +
    encodeURIComponent(department) +
    '&category=' +
    encodeURIComponent(category) +
    '&subcategory=' +
    encodeURIComponent(subcategory) +
    '&supplier=' +
    encodeURIComponent(supplier) +
    '&brand=' +
    encodeURIComponent(brand) +
    '&level4=' +
    encodeURIComponent(level4) +
    '&level5=' +
    encodeURIComponent(level5) +
    '&categoryStructure=xxJsonxx&data=jsonArray';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  }).then((data) => {
    //dispatch(setMasterCodesAction(_.take(data, 10)));
    dispatch(setMasterCodesAction(data));
  });
};

export const searchMaster = (searchFor) => (dispatch) => {
  //let url = '/api/masterdata';
  dispatch(setSelectedAction([]));
  dispatch(setMasterTextAction(searchFor));
  let state = store.getState().admin;
  let department = state.department ? state.department.Text : undefined,
    category = state.category ? state.category.Text : undefined,
    subcategory = state.subcategory ? state.subcategory.Text : undefined,
    supplier = state.supplier ? state.supplier.Text : undefined,
    brand = state.brand ? state.brand.Text : undefined,
    level4 = state.level4 ? state.level4.Text : undefined,
    level5 = state.level5 ? state.level5.Text : undefined;
  let url = '/route.php?c=cleansing/searchUncleansedProducts&keywords=' + encodeURIComponent(searchFor) + '&department=' + department + '&category=' + category + '&subcategory=' + subcategory + '&supplier=' + supplier + '&brand=' + brand + '&level4=' + level4 + '&level5=' + level5 + '';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  }).then((data) => {
    dispatch(setMasterAction(data));
  });
};

export const searchAllMaster = (searchFor) => (dispatch) => {
  //let url = '/api/masterdata';
  dispatch(setSelectedAction([]));
  let state = store.getState().admin;
  let department = state.department ? state.department.Text : undefined,
    category = state.category ? state.category.Text : undefined,
    subcategory = state.subcategory ? state.subcategory.Text : undefined,
    supplier = state.supplier ? state.supplier.Text : undefined,
    brand = state.brand ? state.brand.Text : undefined,
    level4 = state.level4 ? state.level4.Text : undefined,
    level5 = state.level5 ? state.level5.Text : undefined;

  let url = '/route.php?c=cleansing/searchUncleansedProducts&keywords=%&department=' + department + '&category=' + category + '&subcategory=' + subcategory + '&supplier=' + supplier + '&brand=' + brand + '&level4=' + level4 + '&level5=' + level5 + '';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  }).then((data) => {
    dispatch(setMasterAction(data));
  });
};

export const exportData = (opt) => (dispatch) => {
  if (opt === 'csv') {
    let url = `/route.php?c=cleansing/exportCSV`;
    dispatch(setLoadingAction(true));
    request({
      url: url,
      headers: {
        Authorization: getToken(),
        UserId: getUserId(),
      },
      responseType: 'blob',
      method: 'GET',
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setLoadingAction(false));
      });
  } else if (opt === 'coreRange') {
    let url = `/route.php?c=cleansing/exportCoreRangeCSV`;
    dispatch(setLoadingAction(true));
    request({
      url: url,
      headers: {
        Authorization: getToken(),
        UserId: getUserId(),
      },
      responseType: 'blob',
      method: 'GET',
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setLoadingAction(false));
      });
  } else if (opt === 'category_file') {
    let url = `/route.php?c=cleansing/exportCategoryCSV`;
    dispatch(setLoadingAction(true));
    request({
      url: url,
      headers: {
        Authorization: getToken(),
        UserId: getUserId(),
      },
      responseType: 'blob',
      method: 'GET',
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setLoadingAction(false));
      });
  } else if (opt === 'file') {
    let url = `/route.php?c=cleansing/exportCleansingFile`;
    dispatch(setLoadingAction(true));
    request({
      url: url,
      headers: {
        Authorization: getToken(),
        UserId: getUserId(),
      },
      responseType: 'blob',
      method: 'GET',
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setLoadingAction(false));
      });
  } else {
    let state = store.getState().admin;
    let department = state.department ? state.department.Text : undefined,
      category = state.category ? state.category.Text : undefined,
      subcategory = state.subcategory ? state.subcategory.Text : undefined,
      supplier = state.supplier ? state.supplier.Text : undefined,
      brand = state.brand ? state.brand.Text : undefined,
      level4 = state.level4 ? state.level4.Text : undefined,
      level5 = state.level5 ? state.level5.Text : undefined;
    let url = '/route.php?c=cleansing/exportMultiTieredCSV&department=' + department + '&category=' + category + '&subcategory=' + subcategory + '&supplier=' + supplier + '&brand=' + brand + '&level4=' + level4 + '&level5=' + level5 + '';
    dispatch(setLoadingAction(true));
    request({
      url: url,
      headers: {
        Authorization: getToken(),
        UserId: getUserId(),
      },
      responseType: 'blob',
      method: 'GET',
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        dispatch(setLoadingAction(false));
      })
      .catch((err) => {
        dispatch(setLoadingAction(false));
      });
  }
};

export const saveData = (desc) => (dispatch) => {
  dispatch(setLoadingAction(true));
  dispatch(setErrorAction(''));
  dispatch(setIsProductModifiedAction(false));
  let data = {
    WVProdCode: store.getState().admin.masterCode,
    products: store.getState().admin.filterData.filter((data) => data.WVProdCode !== '0'),
  };
  //let url = '/api/save';
  let url = '/route.php?c=cleansing/updateParentProduct';
  //let url = 'http://wsale.esands.co.uk/route.php?c=cleansing/careParentProduct&parentId=XX&data=jsonArray=1&department='+ data.department +'&category='+ data.category +'&subcategory='+ data.subcategory +'&description='+ desc +'';
  request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
    data: data,
  })
    .then((data) => {
      dispatch(setLoadingAction(false));
      dispatch(setMasterCodeAction(''));
      dispatch(setMasterCodeTextAction(''));
      dispatch(setFilterAction([]));
      dispatch(setSelectedAction([]));
      dispatch(setIsProductModifiedAction(true));

      let state = store.getState().admin;
      let department = state.department ? state.department.Text : undefined,
        category = state.category ? state.category.Text : undefined,
        subcategory = state.subcategory ? state.subcategory.Text : undefined,
        supplier = state.supplier ? state.supplier.Text : undefined,
        brand = state.brand ? state.brand.Text : undefined,
        level4 = state.level4 ? state.level4.Text : undefined,
        level5 = state.level5 ? state.level5.Text : undefined,
        searchFor = state.masterText;
      let url =
        '/route.php?c=cleansing/searchUncleansedProducts&keywords=' +
        encodeURIComponent(searchFor) +
        '&department=' +
        encodeURIComponent(department) +
        '&category=' +
        encodeURIComponent(category) +
        '&subcategory=' +
        encodeURIComponent(subcategory) +
        '&supplier=' +
        encodeURIComponent(supplier) +
        '&brand=' +
        encodeURIComponent(brand) +
        '&level4=' +
        encodeURIComponent(level4) +
        '&level5=' +
        encodeURIComponent(level5) +
        '';
      request({
        url: url,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
        },
      }).then((data) => {
        dispatch(setMasterAction(data));
      });
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
    });
};

export const saveProduct = (formData, isAddProduct) => (dispatch) => {
  dispatch(setErrorAction(''));
  dispatch(setIsProductModifiedAction(false));
  dispatch(setLoadingAction(true));
  let state = store.getState().admin;
  let data = {
    department: isAddProduct && formData.department ? formData.department.Text : state.product_department ? state.product_department.Text : undefined,
    category: isAddProduct && formData.category ? formData.category.Text : state.product_category ? state.product_category.Text : undefined,
    subcategory: isAddProduct && formData.subcategory ? formData.subcategory.Text : state.product_subcategory ? state.product_subcategory.Text : undefined,
    brand: isAddProduct && formData.brand ? formData.brand.Text : state.product_brand ? state.product_brand.Text : undefined,
    level4: isAddProduct && formData.level4 ? formData.level4.Text : state.product_level4 ? state.product_level4.Text : undefined,
    level5: isAddProduct && formData.level5 ? formData.level5.Text : state.product_level5 ? state.product_level5.Text : undefined,
    supplier: isAddProduct && formData.supplier ? formData.supplier.Text : state.product_supplier ? state.product_supplier.Text : undefined,
    id: store.getState().admin.masterCode,
  };
  let url = '';

  if (store.getState().admin.masterCode && store.getState().admin.masterCode.length > 0) {
    formData.id = store.getState().admin.masterCode;
    url = '/route.php?c=cleansing/updateProduct';
  } else {
    url = '/route.php?c=cleansing/createProduct';
  }
  url +=
    '&department=' +
    data.department +
    '&category=' +
    encodeURIComponent(data.category) +
    '&subcategory=' +
    encodeURIComponent(data.subcategory) +
    '&brand=' +
    encodeURIComponent(data.brand) +
    '&level4=' +
    encodeURIComponent(data.level4) +
    '&level5=' +
    encodeURIComponent(data.level5) +
    '&supplier=' +
    encodeURIComponent(data.supplier) +
    '&newdepartment=' +
    encodeURIComponent(formData.new_department) +
    '&newcategory=' +
    encodeURIComponent(formData.new_category) +
    '&newsubcategory=' +
    encodeURIComponent(formData.new_subcategory) +
    '&newBrand=' +
    encodeURIComponent(formData.new_brand) +
    '&newLevel4=' +
    encodeURIComponent(formData.new_level4) +
    '&newLevel5=' +
    encodeURIComponent(formData.new_level5) +
    '&newSupplier=' +
    encodeURIComponent(formData.new_supplier) +
    '&productdescription=' +
    encodeURIComponent(formData.description) +
    '&flavour=' +
    formData.flavour +
    '&variant=' +
    formData.variant +
    '&packsize=' +
    formData.pack +
    '&multipacksize=' +
    formData.multipack +
    '&case=' +
    formData.case +
    '&subbrand=' +
    formData.subbrand +
    '&id=' +
    formData.id +
    '&core_range=' +
    formData.core_range +
    '&bestseller=' +
    formData.bestseller;
  request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  })
    .then((data) => {
      if (data.Success) {
        dispatch(setIsProductModifiedAction(true));
        if (isAddProduct) {
          dispatch(setLoadingAction(false));
          dispatch(setMasterCodeAction(data.NewProductId));
          dispatch(setMasterCodeTextAction(data.Text));
        } else {
          dispatch(setMessageAction(data.Text));
        }
      } else {
        dispatch(setErrorAction(data.Text));
      }
    })
    .catch((err) => {
      dispatch(setLoadingAction(false));
    });
};

export const selectMaster = (id) => (dispatch) => {
  let result = _.filter(store.getState().admin.masterData, (data) => {
    if (data.ProductId === id) return data;
  });

  let products = _.map(store.getState().admin.masterData, (data) => {
    if (data.ProductId === id && !data.isSelected) {
      data.isSelected = true;
      dispatch(setSelectedAction([...store.getState().admin.selectedData, result[0]]));
    } else if (data.ProductId === id && data.isSelected) {
      data.isSelected = false;
      let selected = _.filter(store.getState().admin.selectedData, (dt) => {
        if (dt.ProductId !== id) return dt;
      });
      dispatch(setSelectedAction(selected));
    }
    return data;
  });

  dispatch(setMasterAction(products));
};

export const selectFilter = (id) => (dispatch) => {
  let result = _.filter(store.getState().admin.filterData, (data) => {
    if (data.ProductId === id) return data;
  });

  let products = _.map(store.getState().admin.filterData, (data) => {
    if (data.ProductId === id && !data.isSelected) {
      data.isSelected = true;
      dispatch(setSelectedAction([...store.getState().admin.selectedData, result[0]]));
    } else if (data.ProductId === id && data.isSelected) {
      data.isSelected = false;
      let selected = _.filter(store.getState().admin.selectedData, (dt) => {
        if (dt.ProductId !== id) return dt;
      });
      dispatch(setSelectedAction(selected));
    }
    return data;
  });

  dispatch(setFilterAction(products));
};

export const setMasterCode = (val) => (dispatch) => {
  //let url = '/api/filterdata';
  let url = '/route.php?c=cleansing/getChildProducts&parentId=' + val.WVProdCode + '';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  }).then((data) => {
    dispatch(setFilterAction(data));
    dispatch(setMasterCodeAction(val.WVProdCode));
    dispatch(setMasterCodeTextAction(val.WVProdDesc));
    dispatch(setMasterCodesAction([]));
  });
};

export const clearProduct = () => (dispatch) => {
  dispatch(
    setProductDepartmentAction({
      department: {},
      bestseller: false,
      core_range: false,
      category: {},
      categories: [{ ID: 0, Text: 'Select Category' }],
      subcategory: {},
      subcategories: [{ ID: 0, Text: 'Select Sub Category' }],
      level4: 0,
      level4s: [{ ID: 0, Text: 'Select Level 4' }],
      level5: 0,
      level5s: [{ ID: 0, Text: 'Select Level 5' }],
      supplier: 0,
      brands: [{ ID: 0, Text: 'Select Brand' }],
      brand: 0,
    }),
  );
};

export const setEditProduct = () => (dispatch) => {
  //http://wsale.esands.co.uk/route.php?c=cleansing/getParentProductDetails&wholeview=Today&parentId=29522
  let url1 = '/route.php?c=cleansing/getParentProductDetails&wholeview=Today&parentId=' + store.getState().admin.masterCode;
  request({
    url: url1,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
    },
  }).then((data) => {
    let department = {};
    let bestseller = data.bestseller;
    let core_range = data.core_range;
    let category = {};
    let categories = [];
    let subcategory = {};
    let subcategories = [];
    let level4 = {};
    let level4s = [];
    let level5 = {};
    let level5s = [];
    let brand = {};
    let brands = [];
    const suppliers = store.getState().admin.suppliers;
    let supplier = filter(suppliers, (sup) => sup.ID === data.supplier_code);

    _.each(store.getState().admin.departments, (dept) => {
      if (dept.ID === data.departmentId) {
        department = { ID: dept.ID, Text: dept.Text };
        categories = dept.Categories;
        _.each(dept.Categories, (cat) => {
          if (cat.ID === data.categoryId) {
            category = { ID: cat.ID, Text: cat.Text };
            subcategories = cat.SubCategories;
            _.each(cat.SubCategories, (subcat) => {
              if (subcat.ID === data.subcategoryId) {
                subcategory = { ID: subcat.ID, Text: subcat.Text };
                level4s = subcat.Level4;
                _.each(subcat.Level4, (lvl4) => {
                  if (lvl4.ID === data.level4Id) {
                    level4 = { ID: lvl4.ID, Text: lvl4.Text };
                    level5s = lvl4.Level5;
                    _.each(lvl4.Level5, (lvl5) => {
                      if (lvl5.ID === data.level5Id) {
                        level5 = { ID: lvl5.ID, Text: lvl5.Text };
                        brands = lvl5.Brands;
                        _.each(lvl5.Brands, (brnd) => {
                          if (parseInt(brnd.ID) === data.brandId) {
                            brand = { ID: brnd.ID, Text: brnd.Text };
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
    dispatch(
      setProductDepartmentAction({
        department: department,
        bestseller: bestseller,
        core_range: core_range,
        category: category,
        categories: categories,
        subcategory: subcategory,
        subcategories: subcategories,
        level4: level4,
        level4s: level4s,
        level5: level5,
        level5s: level5s,
        supplier: supplier[0],
        brands: brands,
        brand: brand,
      }),
    );
    // dispatch(setEditMasterCodeAction(data));
  });
};

export const setProductDepartment = (val) => (dispatch) => {
  let department = _.filter(store.getState().admin.departments, (dept) => {
    if (dept.ID === parseInt(val)) {
      return dept;
    }
  });
  let categories = [{ ID: 0, Text: 'Select Category' }];
  let subcategories = [{ ID: 0, Text: 'Select Sub Category' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  let level4s = [{ ID: 0, Text: 'Select Level 4' }];
  let level5s = [{ ID: 0, Text: 'Select Level 5' }];
  if (department[0].Categories !== undefined) {
    dispatch(
      setProductDepartmentAction({
        department: department[0],
        categories: [...categories, ...department[0].Categories],
        subcategories: [...subcategories, ...department[0].Categories[0].SubCategories],
        level4s: [...level4s, ...department[0].Categories[0].SubCategories[0].Level4],
        level5s: [...level5s, ...department[0].Categories[0].SubCategories[0].Level4[0].Level5],
        brands: [...brands, ...department[0].Categories[0].SubCategories[0].Level4[0].Level5[0].Brands],
      }),
    );
  } else {
    dispatch(setProductDepartmentAction({ department: {}, categories: categories, category: {}, subcategories: subcategories, subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};
export const setDepartment = (val) => (dispatch) => {
  let department = _.filter(store.getState().admin.departments, (dept) => {
    if (dept.ID === parseInt(val)) {
      return dept;
    }
  });
  let categories = [{ ID: 0, Text: 'Select Category' }];
  let subcategories = [{ ID: 0, Text: 'Select Sub Category' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  let level4s = [{ ID: 0, Text: 'Select Level 4' }];
  let level5s = [{ ID: 0, Text: 'Select Level 5' }];
  if (department[0].Categories !== undefined) {
    dispatch(
      setDepartmentAction({
        department: department[0],
        categories: [...categories, ...department[0].Categories],
        subcategories: [...subcategories, ...department[0].Categories[0].SubCategories],
        level4s: [...level4s, ...department[0].Categories[0].SubCategories[0].Level4],
        level5s: [...level5s, ...department[0].Categories[0].SubCategories[0].Level4[0].Level5],
        brands: [...brands, ...department[0].Categories[0].SubCategories[0].Level4[0].Level5[0].Brands],
      }),
    );
  } else {
    dispatch(setDepartmentAction({ department: {}, categories: categories, category: {}, subcategories: subcategories, subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setProductCategory = (val) => (dispatch) => {
  let category = _.filter(store.getState().admin.categories, (cat) => {
    if (cat.ID === parseInt(val)) {
      return cat;
    }
  });
  let subcategories = [{ ID: 0, Text: 'Select Sub Category' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  let level4s = [{ ID: 0, Text: 'Select Level4' }];
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  if (category[0].SubCategories !== undefined) {
    dispatch(
      setProductCategoryAction({
        category: category[0],
        subcategories: [...subcategories, ...category[0].SubCategories],
        level4s: [...level4s, ...category[0].SubCategories[0].Level4],
        level5s: [...level5s, ...category[0].SubCategories[0].Level4[0].Level5],
        brands: [...brands, ...category[0].SubCategories[0].Level4[0].Level5[0].Brands],
      }),
    );
  } else {
    dispatch(setProductCategoryAction({ category: {}, subcategories: subcategories, subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setCategory = (val) => (dispatch) => {
  let category = _.filter(store.getState().admin.categories, (cat) => {
    if (cat.ID === parseInt(val)) {
      return cat;
    }
  });
  let subcategories = [{ ID: 0, Text: 'Select Sub Category' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  let level4s = [{ ID: 0, Text: 'Select Level4' }];
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  if (category[0].SubCategories !== undefined) {
    dispatch(
      setCategoryAction({
        category: category[0],
        subcategories: [...subcategories, ...category[0].SubCategories],
        level4s: [...level4s, ...category[0].SubCategories[0].Level4],
        level5s: [...level5s, ...category[0].SubCategories[0].Level4[0].Level5],
        brands: [...brands, ...category[0].SubCategories[0].Level4[0].Level5[0].Brands],
      }),
    );
  } else {
    dispatch(setCategoryAction({ category: {}, subcategories: subcategories, subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setProductSubCategory = (val) => (dispatch) => {
  let subcategory = _.filter(store.getState().admin.subcategories, (subcat) => {
    if (subcat.ID === val) {
      return subcat;
    }
  });

  let level4s = [{ ID: 0, Text: 'Select Level4' }];
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (subcategory[0].Level4 !== undefined) {
    dispatch(setProductSubCategoryAction({ subcategory: subcategory[0], level4s: [...level4s, ...subcategory[0].Level4], level5s: [...level5s, ...subcategory[0].Level4[0].Level5], brands: [...brands, ...subcategory[0].Level4[0].Level5[0].Brands] }));
  } else {
    dispatch(setProductSubCategoryAction({ subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setSubCategory = (val) => (dispatch) => {
  let subcategory = _.filter(store.getState().admin.subcategories, (subcat) => {
    if (subcat.ID === val) {
      return subcat;
    }
  });

  let level4s = [{ ID: 0, Text: 'Select Level4' }];
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (subcategory[0].Level4 !== undefined) {
    dispatch(setSubCategoryAction({ subcategory: subcategory[0], level4s: [...level4s, ...subcategory[0].Level4], level5s: [...level5s, ...subcategory[0].Level4[0].Level5], brands: [...brands, ...subcategory[0].Level4[0].Level5[0].Brands] }));
  } else {
    dispatch(setSubCategoryAction({ subcategory: {}, level4s: level4s, level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setProductBrand = (val) => (dispatch) => {
  let brand = _.filter(store.getState().admin.brands, (brd) => {
    if (brd.ID === val) {
      return brd;
    }
  });
  if (val === 0) {
    dispatch(setProductBrandAction({}));
  } else {
    dispatch(setProductBrandAction(brand[0]));
  }
};

export const setBrand = (val) => (dispatch) => {
  let brand = _.filter(store.getState().admin.brands, (brd) => {
    if (brd.ID === val) {
      return brd;
    }
  });
  if (val === 0) {
    dispatch(setBrandAction({}));
  } else {
    dispatch(setBrandAction(brand[0]));
  }
};

export const setProductLevel4 = (val) => (dispatch) => {
  let level4 = _.filter(store.getState().admin.level4s, (lvl) => {
    if (lvl.ID === val) {
      return lvl;
    }
  });
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (level4[0].Level5 !== undefined) {
    dispatch(setProductLevel4Action({ level4: level4[0], level5s: [...level5s, ...level4[0].Level5], brands: [...brands, ...level4[0].Level5[0].Brands] }));
  } else {
    dispatch(setProductLevel4Action({ level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setLevel4 = (val) => (dispatch) => {
  let level4 = _.filter(store.getState().admin.level4s, (lvl) => {
    if (lvl.ID === val) {
      return lvl;
    }
  });
  let level5s = [{ ID: 0, Text: 'Select Level5' }];
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (level4[0].Level5 !== undefined) {
    dispatch(setLevel4Action({ level4: level4[0], level5s: [...level5s, ...level4[0].Level5], brands: [...brands, ...level4[0].Level5[0].Brands] }));
  } else {
    dispatch(setLevel4Action({ level4: {}, level5s: level5s, level5: {}, brands: brands, brand: {} }));
  }
};

export const setProductLevel5 = (val) => (dispatch) => {
  let level5 = _.filter(store.getState().admin.level5s, (lvl) => {
    if (lvl.ID === val) {
      return lvl;
    }
  });
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (level5[0].Brands !== undefined) {
    dispatch(setProductLevel5Action({ level5: level5[0], brands: [...brands, ...level5[0].Brands] }));
  } else {
    dispatch(setProductLevel5Action({ level5: {}, brands: brands, brand: {} }));
  }
};

export const setLevel5 = (val) => (dispatch) => {
  let level5 = _.filter(store.getState().admin.level5s, (lvl) => {
    if (lvl.ID === val) {
      return lvl;
    }
  });
  let brands = [{ ID: 0, Text: 'Select Brand' }];
  if (level5[0].Brands !== undefined) {
    dispatch(setLevel5Action({ level5: level5[0], brands: [...brands, ...level5[0].Brands] }));
  } else {
    dispatch(setLevel5Action({ level5: {}, brands: brands, brand: {} }));
  }
};

export const setProductSupplier = (val) => (dispatch) => {
  let supplier = _.filter(store.getState().admin.suppliers, (sup) => {
    if (sup.ID === val) {
      return sup;
    }
  });
  if (val === 0) {
    dispatch(setProductSupplierAction({}));
  } else {
    dispatch(setProductSupplierAction(supplier[0]));
  }
};

export const setSupplier = (val) => (dispatch) => {
  let supplier = _.filter(store.getState().admin.suppliers, (sup) => {
    if (sup.ID === val) {
      return sup;
    }
  });
  if (val === 0) {
    dispatch(setSupplierAction({}));
  } else {
    dispatch(setSupplierAction(supplier[0]));
  }
};

export const removeFilterData = (val) => (dispatch) => {
  _.map(store.getState().admin.selectedData, (data) => {
    data.isSelected = undefined;
  });
  dispatch(setFilterAction(_.difference(store.getState().admin.filterData, store.getState().admin.selectedData)));
  let products = _.difference(store.getState().admin.filterData, store.getState().admin.selectedData);
  dispatch(setMasterAction(_.union(store.getState().admin.masterData, store.getState().admin.selectedData)));
  dispatch(setFilterAction(products));
  dispatch(setSelectedAction([]));
};

export const addFilterData = (val) => (dispatch) => {
  _.map(store.getState().admin.selectedData, (data) => {
    data.isSelected = undefined;
  });
  dispatch(setFilterAction(_.union(store.getState().admin.filterData, store.getState().admin.selectedData)));
  let products = _.difference(store.getState().admin.masterData, store.getState().admin.selectedData);
  dispatch(setMasterAction(products));
  dispatch(setSelectedAction([]));
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({ ...state, wholeSaler: payload.wholeSaler, departments: payload.departments, categories: payload.categories, subcategories: payload.subcategories, brands: payload.brands, level4s: payload.level4s, level5s: payload.level5s, suppliers: payload.suppliers }),
    [FETCH_WHOLESALERS]: (state, { payload }) => ({ ...state, wholesalers: payload }),
    [FETCH_TEXT_PERCENTAGE]: (state, { payload }) => ({ ...state, textPercentage: payload }),
    [SET_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      product_department: payload.department,
      department: payload.department,
      categories: payload.categories,
      category: payload.category,
      subcategories: payload.subcategories,
      subcategory: payload.subcategory,
      brands: payload.brands,
      brand: payload.brand,
      level4s: payload.level4s,
      level4: payload.level4,
      level5s: payload.level5s,
      level5: payload.level5,
    }),
    [SET_PRODUCT_DEPARTMENT]: (state, { payload }) => ({
      ...state,
      categories: payload.categories,
      subcategories: payload.subcategories,
      level4s: payload.level4s,
      level5s: payload.level5s,
      brands: payload.brands,
      bestseller: payload.bestseller,
      core_range: payload.core_range,
      product_supplier: payload.supplier,
      product_department: payload.department,
      product_category: payload.category,
      product_subcategory: payload.subcategory,
      product_level4: payload.level4,
      product_level5: payload.level5,
      product_brand: payload.brand,
    }),
    [SET_CATEGORY]: (state, { payload }) => ({ ...state, category: payload.category, product_category: payload.category, subcategories: payload.subcategories }),
    [SET_PRODUCT_CATEGORY]: (state, { payload }) => ({ ...state, product_category: payload.category, subcategories: payload.subcategories }),
    [SET_PRODUCT_SUBCATEGORY]: (state, { payload }) => ({ ...state, product_subcategory: payload.subcategory, brands: payload.brands, brand: payload.brand, level4s: payload.level4s, level4: payload.level4, level5s: payload.level5s, level5: payload.level5 }),
    [SET_SUBCATEGORY]: (state, { payload }) => ({ ...state, subcategory: payload.subcategory, product_subcategory: payload.subcategory, brands: payload.brands, brand: payload.brand, level4s: payload.level4s, level4: payload.level4, level5s: payload.level5s, level5: payload.level5 }),
    [SET_BRAND]: (state, { payload }) => ({ ...state, brand: payload, product_brand: payload }),
    [SET_PRODUCT_BRAND]: (state, { payload }) => ({ ...state, product_brand: payload }),
    [SET_PRODUCT_LEVEL_4]: (state, { payload }) => ({ ...state, product_level4: payload.level4, level5s: payload.level5s, level5: payload.level5, brands: payload.brands, brand: payload.brand }),
    [SET_LEVEL_4]: (state, { payload }) => ({ ...state, level4: payload.level4, product_level4: payload.level4, level5s: payload.level5s, level5: payload.level5, brands: payload.brands, brand: payload.brand }),
    [SET_LEVEL_5]: (state, { payload }) => ({ ...state, level5: payload.level5, product_level5: payload.level5, brands: payload.brands, brand: payload.brand }),
    [SET_PRODUCT_LEVEL_5]: (state, { payload }) => ({ ...state, product_level5: payload.level5, brands: payload.brands, brand: payload.brand }),
    [SET_SUPPLIER]: (state, { payload }) => ({ ...state, supplier: payload }),
    [SET_PRODUCT_SUPPLIER]: (state, { payload }) => ({ ...state, product_supplier: payload }),
    [SET_MASTERCODES]: (state, { payload }) => ({ ...state, mastercodes: payload }),
    [SET_MASTER]: (state, { payload }) => ({ ...state, masterData: payload }),
    [SET_MASTER_TEXT]: (state, { payload }) => ({ ...state, masterText: payload }),
    [SET_FILTER]: (state, { payload }) => ({ ...state, filterData: payload }),
    [SET_SELECTED]: (state, { payload }) => ({ ...state, selectedData: payload }),
    [SET_MASTER_CODE]: (state, { payload }) => ({ ...state, masterCode: payload }),
    [SET_EDIT_MASTER_CODE]: (state, { payload }) => ({
      ...state,
      department: { ID: payload.departmentId, Text: payload.department },
      category: { ID: payload.categoryId, Text: payload.category },
      subcategory: { ID: payload.subcategoryId, Text: payload.subcategory },
      supplier: { ID: payload.supplier_code, Text: payload.supplier },
      brand: { ID: payload.brandId, Text: payload.brand },
      level4: { ID: payload.level4Id, Text: payload.Level4 },
      level5: { ID: payload.level5Id, Text: payload.Level5 },
    }),
    [SET_MASTER_CODE_TEXT]: (state, { payload }) => ({ ...state, masterCodeText: payload }),
    [SET_SUPPORT_EMAIL_SENT]: (state, { payload }) => ({ ...state, isSupportEmailSent: true }),
    [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
    [SET_MESSAGE]: (state, { payload }) => ({ ...state, message: payload }),
    [SET_LOADING]: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },
    [SET_IS_PRODUCT_MODIFIED]: (state, { payload }) => ({ ...state, isProductModified: payload }),
  },
  initialState,
);

const getDepartments = (state: RootState) => state.site.departments;
const getCategories = (state: RootState) => state.site.categories;
const getSubCategories = (state: RootState) => state.site.subcategories;
const getBrands = (state: RootState) => state.site.brands;
const getSuppliers = (state: RootState) => state.site.suppliers;

export const selectors = {
  getDepartments,
  getCategories,
  getSubCategories,
  getBrands,
  getSuppliers,
};
