export const retailSkuPerformanceExportHeaders = [
  {
    key: 'WVProdDesc',
    label: 'Product',
  },
  {
    key: 'QtyLY',
    label: 'Quantity Last Year',
  },
  {
    key: 'QtyTY',
    label: 'Quantity This Year',
  },
  {
    key: 'SummaryValueLY',
    label: 'Summary Value Last Year',
  },
  {
    key: 'SummaryValueTY',
    label: 'Summary Value This Year',
  },
  {
    key: 'UnitsPerWeekLY',
    label: 'Units Per Week Last Week',
  },
  {
    key: 'UnitsPerWeekTY',
    label: 'Units Per Week This Week',
  },
  {
    key: 'StoreCountLY',
    label: 'Store Count Last Year',
  },
  {
    key: 'StoreCountTY',
    label: 'Store Count This Year',
  },
  {
    key: 'AvUnitPriceLY',
    label: 'Average Unit Price Last Year',
  },
  {
    key: 'AvUnitPriceTY',
    label: 'Average Unit Price This Year',
  },
  {
    key: 'ContributionPerStorePerWeekLY',
    label: 'Contribution Per Store Per Week Last Year',
  },
  {
    key: 'ContributionPerStorePerWeekTY',
    label: 'Contribution Per Store Per Week This Year',
  },
  {
    key: 'CVTotalLY',
    label: 'Category Weighted Distribution Last Year',
  },
  {
    key: 'CVTotalTY',
    label: 'Category Weighted Distribution This Year',
  },
  {
    key: 'ACVTotalLY',
    label: 'Percentage AVG Distribution Last Year',
  },
  {
    key: 'ACVTotalTY',
    label: 'Percentage AVG Distribution This Year',
  },
];

export const categoryPenetrationExportHeaders = [
  {
    key: 'SkuCount_LY',
    label: 'SkuCount_LY',
  },
  {
    key: 'SkuCount_TY',
    label: 'SkuCount_TY',
  },
  {
    key: 'SummaryValue_LY',
    label: 'SummaryValue_LY',
  },
  {
    key: 'SummaryValue_TY',
    label: 'SummaryValue_TY',
  },
  {
    key: 'MarketShare_LY',
    label: 'MarketShare_LY',
  },
  {
    key: 'MarketShare_TY',
    label: 'MarketShare_TY',
  },
  {
    key: 'Baskets_LY',
    label: 'Baskets_LY',
  },
  {
    key: 'Baskets_TY',
    label: 'Baskets_TY',
  },
  {
    key: 'AvSpend_LY',
    label: 'AvSpend_LY',
  },
  {
    key: 'AvSpend_TY',
    label: 'AvSpend_TY',
  },
];

export const retailerPerformanceExportHeaders = [
  {
    key: 'BusinessName',
    label: 'BusinessName',
  },
  {
    key: 'CustomerNo',
    label: 'CustomerNo',
  },
  {
    key: 'QtyLY',
    label: 'QtyLY',
  },
  {
    key: 'QtyTY',
    label: 'QtyTY',
  },
  {
    key: 'QtyPercentChange',
    label: 'QtyPercentChange',
  },
  {
    key: 'ValueLY',
    label: 'ValueLY',
  },
  {
    key: 'ValueTY',
    label: 'ValueTY',
  },
  {
    key: 'ValuePercentChange',
    label: 'ValuePercentChange',
  },
  {
    key: 'AvSpend_LY',
    label: 'AvSpend_LY',
  },
  {
    key: 'Section',
    label: 'Section',
  },
];

export const basketProductAnalysisExportHeaders = [
  {
    key: 'ProductDesc',
    label: 'ProductDesc',
  },
  {
    key: 'Visits',
    label: 'Visits',
  },
  {
    key: 'QtyBought',
    label: 'QtyBought',
  },
  {
    key: 'ProductPenetration',
    label: 'ProductPenetration',
  },
  {
    key: 'Support',
    label: 'Support',
  },
  {
    key: 'Confidence',
    label: 'Confidence',
  },
  {
    key: 'Lift',
    label: 'Lift',
  },
];
