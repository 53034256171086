import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import StatusReport from '../LikeForLike/StatusReport';

export default class LikeForLike extends React.Component {
  state = {
    subpage_nav: [],
  };

  render() {
    return (
      <div>
        <Switch>
          <Route path={`${process.env.APP_ROOT}likeforlike/:subpage(status_report)`} render={(pageProps) => <StatusReport {...this.props} {...pageProps} />} />
          <Redirect from={`${process.env.APP_ROOT}likeforlike`} to={`${process.env.APP_ROOT}likeforlike/status_report/`} />
        </Switch>
      </div>
    );
  }
}

LikeForLike.defaultProps = {
  location: {},
};

LikeForLike.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
