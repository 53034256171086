import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import CustomCountSelect from '../../CustomCountSelect';
import { getUserSystem } from '../../../../helpers/util';

function AccountNames({ value, onChange, options }) {
  return (
    <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
      <CustomCountSelect
        className={`Select c-select-header c-select-header-row--others`}
        name="accountName"
        id="accountName"
        onChangeCallback={ev => onChange(ev)}
        isMulti={true}
        value={value}
        options={options}
        valueKey="RegNo"
        labelKey="CustomerName"
        type={getUserSystem() == 'cjlangRetail' ? "Customer Name" : "Account Number"}
        types={getUserSystem() == 'cjlangRetail' ? value.length == options.length ? "My Customer Names" : "Customer Names" : "Account Numbers"}
        placeholder={getUserSystem() == 'cjlangRetail' ? "Customer Name" : "Account Number"}
      />
    </div>
  );
}

AccountNames.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default AccountNames;
