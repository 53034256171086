import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import AlchSelect from '../AlchUI/AlchSelect2';
import AlchToggle from '../AlchUI/AlchToggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import HtmlEditor from '../HtmlEditor';

const yesNoOptions = [
  { ID: 1, Text: 'Yes' },
  { ID: 0, Text: 'No' },
];
const rangeOptions = [
  { ID: 'Core', Text: 'Core' },
  { ID: 'Extended', Text: 'Extended' },
];

const AddCoreRange = ({ isLoading, coreRangeForm, onSaveCoreRange, onFormSelectChange, onFormTextChange, onFormFileChange, onFormDepartmentChange, onFormRangeChange, onFormCategoryChange, onBestSellerChange, onPMPChange, handleCoreRangeAddModal }) => {
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <div style={{ width: '80%', position: 'relative', margin: '0 auto' }}>
        <div className="u-layout user-info">
          <div className="u-1_3 mb-0">
            <h1 className="o-canvas__modal-form-title u-tac" style={{ color: '#000' }}>
              <span>{coreRangeForm.ID ? 'Update' : 'Add New'} CoreRange</span>
            </h1>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Department</h3>
              <Select value={coreRangeForm.Department} onChange={onFormDepartmentChange} options={coreRangeForm.departments} valueKey="ID" labelKey="Text" />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Categories</h3>
              <Select value={coreRangeForm.Category} onChange={onFormCategoryChange} options={coreRangeForm.categories} valueKey="ID" labelKey="Text" />
            </div>
          </div>
        </div>

        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Range</h3>
              <Select name="Range" value={coreRangeForm.Range} onChange={onFormRangeChange} options={rangeOptions} valueKey="ID" labelKey="Text" />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Best Seller</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(coreRangeForm.BestSeller) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onBestSellerChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Display Order</h3>
              <input type="text" id="displayOrder" className="ReactModal--input-green" name="displayOrder" value={coreRangeForm.DisplayOrder || ''} onChange={evt => onFormTextChange('DisplayOrder', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Product Name</h3>
              <input type="text" id="productName" className="ReactModal--input-green" name="productName" value={coreRangeForm.ProductName || ''} onChange={evt => onFormTextChange('ProductName', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Category Order</h3>
              <input type="text" id="categoryOrder" className="ReactModal--input-green" name="categoryOrder" value={coreRangeForm.CategoryOrder || ''} onChange={evt => onFormTextChange('CategoryOrder', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Department Order</h3>
              <input type="text" id="departmentOrder" className="ReactModal--input-green" name="departmentOrder" value={coreRangeForm.DepartmentOrder || ''} onChange={evt => onFormTextChange('DepartmentOrder', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Pack Size</h3>
              <input type="text" id="packSize" className="ReactModal--input-green" name="packSize" value={coreRangeForm.PackSize || ''} onChange={evt => onFormTextChange('PackSize', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">PMP</h3>
              <div className="c-toggle-menu" data-name="timeframe">
                {yesNoOptions.map((opt, key) => {
                  return (
                    <button key={key} id={opt.ID} className={parseInt(coreRangeForm.PMP) === opt.ID ? 'a-toggle-menu__item is-active' : 'a-toggle-menu__item'} onClick={onPMPChange}>
                      {opt.Text}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Category</h3>
              <input type="text" id="category" className="ReactModal--input-green" name="category" value={coreRangeForm.Category || ''} onChange={evt => onFormTextChange('Category', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Supplier</h3>
              <input type="text" id="supplier" className="ReactModal--input-green" name="supplier" value={coreRangeForm.Supplier || ''} onChange={evt => onFormTextChange('Supplier', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Country</h3>
              <input type="text" id="country" className="ReactModal--input-green" name="country" value={coreRangeForm.Country || ''} onChange={evt => onFormTextChange('Country', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <div style={{ height: '105px' }}>
                <img src={coreRangeForm.Image} style={{ width: 'auto', height: '100px' }} />
              </div>
              <h3 className="o-canvas__search-title ">Image</h3>
              <input type="file" name="Image" onChange={evt => onFormFileChange('Image', evt.target.files[0])} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_3 mb-0">
            <div className="form-group">
              <h3 className="o-canvas__search-title ">Origin</h3>
              <input type="text" id="origin" className="ReactModal--input-green" name="country" value={coreRangeForm.Origin || ''} onChange={evt => onFormTextChange('Origin', evt.target)} style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
          <div className="u-1_1 mb-0">
            <div className="form-group" style={{ textAlign: 'center' }}>
              <button onClick={() => onSaveCoreRange()}>Save</button>
              <span>&#160;</span>
              <button onClick={() => handleCoreRangeAddModal()}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCoreRange;
