import {
	optionsToArray,
	labelRadioToArray,
	decomposeHTMLTable,
	removeAssets,
	optionFieldToToggleArray,
} from './helpers';


/* RESPONSE TYPE PICKER */
function reactPointWeekGetter($el) {
	if ($el) {
		return {
			startWeek: $el.data('start-week'),
			endWeek: $el.data('end-week'),
		};
	}
}

function getDecomposedTable($el) {
	if ($el) {
		return decomposeHTMLTable($($el).html());
	}
}


function conditionGetter($conditionBlocks) {
	const conditions = [];

	$conditionBlocks.each((i, conditionBlock)=>{
		const conditionOptionsEls = $(conditionBlock).find('select option');
		let conditionOptions = [];
		if (conditionOptionsEls.length > 0) {
			conditionOptions = optionsToArray(conditionOptionsEls);
		}

		let selectId;
		let multiSelect;
		switch ($(conditionBlock).find('select').attr('id')) {
		case 'ddl-filter-sub-cat':
			selectId = 'cat';
			break;
		case 'ddl-product':
			selectId = 'prod';
			break;
		case 'ddl-business':
			selectId = 'btype';
			multiSelect = true;
			break;
		case 'ddl-business-club':
			selectId = 'retailclub';
			break;
		default:
			selectId = $(conditionBlock).find('select').attr('id');
			multiSelect = false;
		}

		conditions.push({
			options: conditionOptions,
			label: (conditionBlock.childNodes[0].nodeType === 3) ? conditionBlock.childNodes[0].textContent : '',
			placeholder: 'Select',
			selectId,
			multiSelect,
		});
	});

	return conditions;
}


function optionsGetter($conditionBlocks) {
	const conditions = [];

	$conditionBlocks.each((i, conditionBlock)=>{
		const conditionOptionsEls = $(conditionBlock).find('select option');
		let conditionOptions = [];
		if (conditionOptionsEls.length > 0) {
			conditionOptions = optionsToArray(conditionOptionsEls);
		}

		let selectId;
		let multiSelect;
		switch ($(conditionBlock).find('select').attr('id')) {
		case 'category-select':
			selectId = 'CGDescription';
			break;
		case 'period-select':
			selectId = 'timeframe';
			break;
		case 'values-select':
			selectId = 'measure';
			break;
		default:
			selectId = $(conditionBlock).find('select').attr('id');
			multiSelect = false;
		}

		conditions.push({
			options: conditionOptions,
			label: ($(conditionBlock).parent().siblings('label').length > 0) ? $(conditionBlock).parent().siblings('label').text() : '',
			placeholder: 'Select',
			selectId,
			multiSelect,
		});
	});

	return conditions;
}

/* EXAMPLE STRUCTURE */
// {
// 	data_type: {
// 		data_label : data,
// 		data_label2 : data,
// 	},
// 	data_type2: {
// 		data_label: data,
// 	}
// }

/**
 * ResponseControl
 * takes html response and provides
 * interface to required data
 *
 * @param {string} response
 */
function ResponseControl(response) {
	const $response = $(removeAssets(response));
	/* Get last updated date message */
	this.getTextContent = function(findEl) {
		let text = $response.find(findEl).text();
		if (text.trim) {
			text = text.trim();
		}
		return text;
	};

	/* Get dropdown data formatted for select2 */
	this.getSelectDropdown = function(optionSelect) {
		const $options = $response.find(optionSelect);
		return optionsToArray($options);
	};

	/* Get toggle options in right format */
	this.getToggleMenu = function(labelSelect) {
		const labels = $response.find(labelSelect);
		return labelRadioToArray(labels);
	};


	this.getDate = function(selector) {
		const text = new Date(this.getTextContent(selector)) || false;
		return text;
	};

	/**
	 * Generate response
	 * takes object descriptor with object[type][label] = .sourceElement format
	 *
	 * @param {req} object describing data needed
	 */
	this.generateResponse = function(req) {
		const res = {};
		Object.keys(req).forEach((type)=>{
			const labels = req[type];
			Object.keys(labels).forEach((label)=>{
				const finder = labels[label];
				res[type] = res[type] || {};
				switch (type) {
				case 'messages':
					res[type][label] = this.getTextContent(finder);
					break;
				case 'selectDropdown':
					res[type][label] = this.getSelectDropdown(finder);
					break;
				case 'toggleOptions':
					res[type][label] = this.getToggleMenu(finder);
					break;
				case 'dates':
					res[type][label] = this.getDate(finder);
					break;
				default:
					/* eslint no-case-declarations:0 */
					const { el, getter } = finder;
					const $el = ($response.find(el).length) ? $response.find(el) : $response.filter(el);
					res[type][label] = getter($el);
					break;
				}
			});
		});

		return res;
	};
}

/* Category Performance */
export function getPerformanceGraphPageBase(response) {
	const source = new ResponseControl(response);
	return source.generateResponse({
		messages: {
			update_date: '.currentDate',
		},
		selectDropdown: {
			category: '#categories option',
			subcategory: '#ddl-filter-sub-cat option',
		},
		toggleOptions: {
			timeframe: '.timeframe.options label',
			measure: '.measure.options label',
		},
	});
}


/* Category performance data */
export function getPerformanceGraphData(response) {
	response = JSON.parse(response);
	function filterDataForGraph() {
		const filtered = response.map((item)=>{
			return {
				change: item.change,
				label: item.label,
				measure: item.measure,
				value: item.share,
			};
		});
		return filtered;
	}

	return {
		table: response,
		graph: filterDataForGraph(response),
	};
}


/* Product performance  */
export function getProductPerformanceBase(response) {
	const source = new ResponseControl(response);
	return source.generateResponse({
		dates: {
			start: '#js-start_week_1',
			end: '#js-end_week_1',
		},
		selectDropdown: {
			products: '#ddl-product option',
		},
		toggleOptions: {
			measure: '.measure.options label',
		},
		custom: {
			datesPreselect: { el: '.modal-content .js-preselect', getter: optionFieldToToggleArray },
		},
	});
}

/* My Stock page */
export function getMyStockBase(response) {
	const source = new ResponseControl(response);
	return source.generateResponse({
		dates: {
			start: '#js-start_week_1',
			end: '#js-end_week_1',
		},
		custom: {
			datesPreselect: { el: '.modal-content .js-preselect', getter: optionFieldToToggleArray },
		},
	});
}


/* My Stock data */
export function getMyStockData(data) {
	let dataTable = '';
	if ($(data).filter('.table-responsive.scorecard')) {
		dataTable = decomposeHTMLTable($(data).filter('.table-responsive.scorecard').html());
	}

	return dataTable;
}


/* Price sensitivity page */
export function getPriceSensitivityBase(response) {
	const source = new ResponseControl(response);
	return source.generateResponse({
		messages: {
			update_date: '.currentDate',
		},
		selectDropdown: {
			products: '#ddl-product option',
		},
		custom: {
			datesPreselect: {
				el: '.react_pickup_point',
				getter: reactPointWeekGetter,
			},
		},
	});
}


/* Heatmap page */
export function getHeatmapBase(response) {
	const source = new ResponseControl(response);
	return source.generateResponse({
		messages: {
			update_date: '.currentDate',
		},
		selectDropdown: {
			wholesaler: '#ddl-wholesaler option',
			products: '#ddl-product option',
		},
		custom: {
			datesPreselect: {
				el: '.react_pickup_point',
				getter: reactPointWeekGetter,
			},
		},
	});
}


export function parseReportBuilderBase(response) {
	const source = new ResponseControl(response);

	return source.generateResponse({
		messages: {
			update_date: '.currentDate',
		},
		custom: {
			conditions: {
				el: '.story h3',
				getter: conditionGetter,
			},
		},
	});
}

export function parseScorecardBase(response) {
	const source = new ResponseControl(response);

	return source.generateResponse({
		selectDropdown: {
			wholesaler: '#ddl-wholesaler option',
			category: '#categories option',
		},
		custom: {
			dataTable: {
				el: '.table-responsive.scorecard',
				getter: getDecomposedTable,
			},
		},
	});
}


export function parseCustomReportBase(response) {
	const source = new ResponseControl(response);

	return source.generateResponse({
		custom: {
			options: {
				el: '.ddl-filters .selector',
				getter: optionsGetter,
			},
		},
	});
}


export function parseCustomReportData(response) {
	const data = JSON.parse(response);
	const headersRaw = data.model.definition.data_series_table.reduce((merged, arr)=>{
		return merged.concat(arr);
	}, []);


	/* ######### PARSE TABLE DATA ########## */
	const headers = headersRaw.map((head)=>{
		return {
			colspan: 1,
			text: head,
		};
	});

	/* add empty offset */
	headers.unshift({
		colspan: 1, text: '',
	});


	const rows = data.graph.tick.reduce((allRows, tick, i)=>{
		const row = [{
			colspan: 1,
			text: tick,
		}];

		headersRaw.forEach((header)=>{
			row.push({
				colspan: 1,
				text: data.graph[header][i],
			});
		});

		allRows.push(row);
		return allRows;
	}, []);


	/* ######### PARSE GRAPH DATA ########## */
	const graphColors = [
		'rgb(213, 132, 196)',
		'rgb(140, 70, 123)',
		'rgb(135, 31, 110)',
		'rgb(193, 19, 153)',
		'rgb(104, 12, 83)',
	];
	const graphCollection = [];

	/* Merge graph series and label groups */
	const graphGroups = {};
	const graphSeries = data.model.definition.data_series_graph.reduce((merged, arr, i)=>{
		arr.forEach((label)=>{
			graphGroups[label] = i;
		});
		return merged.concat(arr);
	}, []);

	/* Closure for prefixes */
	let prefix = '';
	let suffix = '';

	graphSeries.forEach((label, i)=>{
		const barValues = data.graph[label];
		const barColor = graphColors[i % graphColors.length]; // Infinite loop through array values

		if (!barValues) { return; }

		/* Get prefix and suffixes */
		if (data.model.definition.prefix) {
			prefix = data.model.definition.prefix[label] || '';
		}
		if (data.model.definition.sufix) {
			suffix = data.model.definition.sufix[label] || '';
		}

		const barSpacing = 0.2 / Object.keys(graphGroups).length;
		const barWidth = (0.8 / graphSeries.length) - barSpacing * 2;

		graphCollection.push({
			data: barValues.map((val, y)=>{
				const xPos = y + ((barWidth + barSpacing) * graphGroups[label] * 2);
				return [xPos, val];
			}),
			color: barColor,
			opacity: 1,
			label,
			hoverable: true,
			barWidth: barWidth * 2,
			barSpacing,
		});
	});

	return {
		title: data.model.name ? data.model.name : 'Custom report',
		table: {
			headers,
			rows,
		},
		graph: {
			graphCollection,
			xticks: data.graph.tick.map((val, i)=> { return [i + 0.053 * Object.keys(graphGroups).length, val]; }),
			yPrefix: prefix,
			ySuffix: suffix,
		},
	};
}


export function parseDistributionData(response) {
	const data = JSON.parse(response);

	const firstTierHeaders = [{
		colspan: 3,
		text: '',
	}];
	const secondTierHeaders = [];
	const dataRows = [];
	const products = Object.keys(data);

	if (!products.length) {
		return {};
	}

	/* Second tier headers */
	Object.keys(data[products[0]].fields).forEach((header)=>{
		secondTierHeaders.push({
			colspan: 1,
			text: header,
		});
	});

	/* Get the quarters/years */
	Object.keys(data[products[0]].values).forEach((header)=>{
		firstTierHeaders.push({
			colspan: 2,
			text: header,
		});

		/* !CAREFUL - nested loop of object keys */
		/* We are at firstProduct[values][quarter][loop_of_keys] */
		Object.keys(data[products[0]].values[header]).forEach((secondHeader)=>{
			secondTierHeaders.push({
				colspan: 1,
				text: secondHeader,
			});
		});
	});

	/* And now populate data fields with values */
	products.forEach((product)=>{
		product = data[product]; // retrieve product object from actual data
		const row = [];
		Object.keys(product.fields).forEach((field)=>{
			row.push({
				colspan: 1,
				text: product.fields[field],
			});
		});

		/* Quarters - get customer count and quantity from here */
		Object.keys(product.values).forEach((quarter)=>{
			Object.keys(product.values[quarter]).forEach((field)=>{
				row.push({
					colspan: 1,
					text: product.values[quarter][field],
				});
			});
		});

		dataRows.push(row);
	});

	return {
		table: {
			headers: firstTierHeaders,
			rows: [secondTierHeaders, ...dataRows],
		},
	};
}
