import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import AlchSelectableBox from '../../components/AlchUI/AlchSelectableBox';
import AlchDateRangepicker from '../../components/AlchUI/AlchDateRangepicker';
import StepCrumbs from './StepCrumbs';
import { hasPermission } from '../../../helpers/util';
import { fetchAccountNames } from '../../redux/reducers/filters';
import {
  fetchCategories,
  fetchTimeframes,
  onUpdateQueryFieldVisibility,
} from '../../redux/reducers/query';
import * as _ from 'lodash';
import {
  getUserSystem,
  getUserNamDashMeasures,
  hideFor,
  showFor,
  hasMultipleSalesInPermissions,
} from '../../../helpers/util';
import CustomSelect from '../../components/CustomSelect';
import Toggle from 'react-toggle';
import { ToastContainer, toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { pageVariants } from '../../../config/pageVariants';

const Step1 = ({
  data,
  onMenuOpen,
  onMenuClose,
  renderMeasureSelectBoxes,
  renderDateRanges,
  addDateRange,
  timeframes,
  onToggleChange,
  onMemberChange,
  onNext,
  members,
  builtQuery,
  addWholesaler,
  wholesalers,
}) => {
  const [isSingleMember, setIsSingleMember] = useState(false);
  useEffect(() => {
    // Check if only one member returned from api
    if (members && members.length > 0) {
      const membersWithoutExtra = members.filter(
        ({ value }) => value !== 0 && value !== 1 && value !== 9999,
      );
      if (membersWithoutExtra.length === 1) {
        setIsSingleMember(true);
      } else {
        setIsSingleMember(false);
      }
      if (isSingleMember) {
        addWholesaler([membersWithoutExtra[0]]);
      }
    }
  }, [members.length, isSingleMember]);

  const stepOneConditionCheck = () => {
    return (
      builtQuery.Show_CustomerCount !== 0 ||
      builtQuery.Show_Value !== 0 ||
      builtQuery.Show_ValueIn !== 0 ||
      builtQuery.Show_Volume !== 0 ||
      builtQuery.Show_VolumeIn !== 0 ||
      builtQuery.Show_QtyHectolitres !== 0 ||
      builtQuery.Show_Qty9L !== 0 ||
      builtQuery.Show_QtyStick !== 0 ||
      builtQuery.Show_QtyGrams !== 0
    );
  };
  return (
    <main>
      <motion.div
        key={'query-step1-id'}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        className="container mt-40">
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          closeOnClick={false}
          pauseOnHover={false}
          closeButton={false}
          toastClassName={`c-alchemy-toasty`}
        />
        <div className="row">
          <div className="query-builder query-builder__steps">
            <h2 className="text-uppercase mb-4">Query Builder</h2>
            <StepCrumbs
              step={1}
              condition={wholesalers.length > 0 && stepOneConditionCheck()}
              handleClick={onNext}
            />
            <p className="mt-3">
              Please select your options for these mandatory fields
            </p>
            <hr />
          </div>
          <div className="query-builder query-builder__steps col-12">
            <h2 className="text-uppercase mb-4 mt-4">Measure</h2>
            <div
              className={`radio-toolbar query-radio--${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              } text-uppercase`}>
              <ul className="o-multiselect u-reset-list u-layout pd-0">
                {getUserSystem() === 'countryRangeGroup' &&
                  data.measure_fields_crg.map(renderMeasureSelectBoxes)}
                {getUserSystem() !== 'countryRangeGroup' &&
                  data.measure_fields.map(renderMeasureSelectBoxes)}
              </ul>
            </div>
            <hr />
          </div>
          <div className="query-builder col-12">
            <h2 className="text-uppercase mb-4 mt-4">Date Range</h2>
            {timeframes.length > 0 && renderDateRanges()}
            <br />
            <button
              className={`btn ${
                getUserSystem() === 'unitas'
                  ? 'alchemy-btn--unitas'
                  : 'alchemy-btn--others'
              }`}
              onClick={addDateRange}>
              Add date range
            </button>
            <hr />
          </div>
        </div>
        <div className="row">
          <div
            className={`query-builder col toggle-${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }`}>
            <h2 className="text-uppercase mb-4 mt-4">
              Include weeks in results
            </h2>
            <Toggle
              id="Show_Weeks"
              icons={false}
              checked={builtQuery.Show_Weeks === 1}
              onChange={ev => onToggleChange(ev)}
            />
            <hr />
          </div>
          { hasPermission(`role_view_${getUserSystem().toLowerCase()}_query_builder_retailer`) && (
          <div
            className={`query-builder col toggle-${
              getUserSystem() === 'unitas' ? 'unitas' : 'others'
            }`}>
            <h2 className="text-uppercase mb-4 mt-4">
              Retail or Wholesale data 
            </h2>
            <div className="d-flex">
              <Toggle
                id="dataType"
                icons={false}
                checked={builtQuery.dataType === 1}
                onChange={ev => onToggleChange(ev)}
              />
              <span className="text-black pl-2">{builtQuery.dataType === 1 ? "Retail" : "Wholesale"}</span>
            </div>
            <hr />
          </div>
      )}
      {hideFor('cjLang', 'countryRangeGroup') && !isSingleMember && (
            <div
              className={`query-builder col toggle-${
                getUserSystem() === 'unitas' ? 'unitas' : 'others'
              }  `}>
              <h2 className="text-uppercase mb-4 mt-4">
                Use Like for Like Reporting
              </h2>
              <Toggle
                id="Show_LikeForLike"
                icons={false}
                checked={builtQuery.Show_LikeForLike === 1}
                onChange={ev => onToggleChange(ev)}
              />
              <hr />
            </div>
          )}
        </div>
        <div className="row">

          {hideFor('cjLang', 'parfetts') && !isSingleMember && (
            <div className={`query-builder col`}>
              <h2 className="text-uppercase mb-4 mt-4">Show Wholesaler(s)</h2>
              <CustomSelect
                menuIsOpen={data.menuIsOpen}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                onChangeCallback={ev => onMemberChange(ev)}
                style={{ width: '200px', maxWidth: '200px' }}
                name="wholesaler"
                id="wholesaler"
                multi
                value={builtQuery.Wholesalers}
                options={members}
                valueKey="WholesalerNo"
                labelKey="ActualName"
                placeholder="Select Wholeview"
              />
              <p style={{ color: 'red' }}>
                {data.message.length > 0 && data.message}
              </p>
            </div>
          )}
          <div className="query-builder mt-5 col d-flex justify-content-end align-items-center">
            <button
              type="button"
              onClick={onNext}
              className={`btn btn-info alchemy-btn--others text-uppercase float-right`}>
              Next
            </button>
          </div>
        </div>
      </motion.div>
    </main>
  );
};
function mapStateToProps(state, ownProps) {
  let mems = state.query.members.map(mem => {
    return { value: parseInt(mem.WholesalerNo), label: mem.ActualName };
  });

  let members = _.orderBy(
    _.uniqBy(mems, e => {
      return e.value;
    }),
    ['label'],
  );
  if (getUserSystem() === 'unitas') {
    members.unshift({ value: 1, label: 'Select all (Named)' });
  }

  members.unshift({ value: 0, label: 'Select all' });

  let data = {
    members: members,
    timeframes: state.query.timeframes,
    wholesalers: state.query.builtQuery.Wholesalers,
    builtQuery: state.query.builtQuery,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    fetchTimeframes,
    onUpdateQueryFieldVisibility,
    fetchAccountNames,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchTimeframes();
      this.props.fetchCategories();

      this.props.fetchAccountNames();

      if (getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') {
        this.props.addWholesaler([
          {
            WholesalerNo: 110,
            ActualName: 'parfetts',
          },
        ]);
      }
    },
  }),
  withState('data', 'handleData', state => ({
    menuIsOpen: false,
    //If Updating measures, must update measure field in PivotTable measureValidation() method
    measure_fields: [
        ...(hasPermission(`role_view_${getUserSystem()}_hide_value`)
          ? []
          : [
            { label: 'value', name: 'Value', list: false },
          ] 
        ),
        { label: 'volume', name: 'Volume', list: false },
        ...(getUserSystem() !== 'smartviewRetail'
          ? [
              {
                label: 'volume-hectolitres',
                name: 'QtyHectolitres',
                list: false,
              },
              { label: 'volume-9l', name: 'Qty9L', list: false },
              { label: 'volume-stick', name: 'QtyStick', list: false },
              { label: 'volume-grams', name: 'QtyGrams', list: false },
              ...(getUserSystem() !== 'confex'
                ? [
                    {
                      label: 'customer count',
                      name: 'CustomerCount',
                      list: false,
                    },
                  ]
                : []),
            ]
          : []),
    ],
    measure_fields_crg: [
        { label: 'value', name: 'Value', list: false },
        { label: 'value in', name: 'ValueIn', list: false },
        { label: 'volume', name: 'Volume', list: false },
        { label: 'volume in', name: 'VolumeIn', list: false },
        { label: 'customer count', name: 'CustomerCount', list: false },
    ],
    fields_to_display: [
      { label: 'category name', name: 'CategoryName', list: 'categories' },
      { label: 'sub category', name: 'SGDescription', list: 'subCategories' },
      { label: 'product description', name: 'ProductDescription', list: false },
      { label: 'product code', name: 'ProductCode', list: false },
      { label: 'supplier', name: 'SupplierName', list: 'suppliers' },
    ],
    date_fields: [
      { label: 'year number', name: 'YearNumber', list: false },
      { label: 'week number', name: 'WeekNo', list: false },
      { label: 'year week number', name: 'YearNoWeekNo', list: false },
      { label: 'week commencing date', name: 'WeekCommenceDate', list: false },
    ],
    transaction_details: [
      { label: 'depot name', name: 'DepotName', list: 'depots' },
      { label: 'customer type', name: 'CustomerType', list: 'customerType' },
    ],
    wholesalerArray: [],
    updateDate: '',
    message: '',
  })),
  withHandlers({
    onMenuOpen:
      ({ data, handleData }) =>
      () => {
        if (!data.menuIsOpen) {
          handleData({ ...data, menuIsOpen: !data.menuIsOpen });
        }
      },
    onMenuClose:
      ({ data, handleData }) =>
      () => {
        if (data.menuIsOpen) {
          handleData({ ...data, menuIsOpen: !data.menuIsOpen });
        }
      },
    renderMeasureSelectBoxes: props => (select, key) => {
      const on = props.builtQuery[`Show_${select.name}`] === 1;
      if (select.name === 'CustomerCount' && getUserSystem() === 'confex') {
        return;
      }

      if (select.name === 'ValueIn' || select.name === 'VolumeIn') {
        if (!hasMultipleSalesInPermissions()) {
          return;
        }
      }
      return (
        <li key={key} style={{ display: 'inline-block' }}>
          <AlchSelectableBox
            label={select.label}
            id={select.name}
            uid={select}
            className="c-selectable-box--large"
            on={on}
            onChange={(val, id) => {
              if (getUserSystem() !== 'countryRangeGroup') {
                props.updateQueryFieldVisibility(
                  id,
                  val,
                  select.label,
                  select.name,
                );
                props.handleData({ ...props.data, message: '' });
              } else {
                if (id === 'ValueIn' || id === 'VolumeIn') {
                  props.updateQueryFieldVisibility([
                    {
                      key: 'Value',
                      value: false,
                      label: 'value',
                      name: 'Value',
                    },
                    {
                      key: 'Volume',
                      value: false,
                      label: 'volume',
                      name: 'Volume',
                    },
                    {
                      key: 'CustomerCount',
                      value: false,
                      label: 'customer',
                      name: 'CustomerCount',
                    },
                    {
                      key: id,
                      value: val,
                      label: select.label,
                      name: select.name,
                    },
                  ]);
                }
                if (
                  id === 'Value' ||
                  id === 'Volume' ||
                  id === 'CustomerCount'
                ) {
                  props.updateQueryFieldVisibility([
                    {
                      key: 'ValueIn',
                      value: false,
                      label: 'value in',
                      name: 'ValueIn',
                    },
                    {
                      key: 'VolumeIn',
                      value: false,
                      label: 'volume in',
                      name: 'VolumeIn',
                    },
                    {
                      key: id,
                      value: val,
                      label: select.label,
                      name: select.name,
                    },
                  ]);
                }

                props.handleData({ ...props.data, message: '' });
              }
            }}
          />
        </li>
      );
    },
    onNext: props => () => {
      const userSystem = getUserSystem();
      if (props.theme.name === 'cjlang') {
        props.history.push(`${process.env.APP_ROOT}query/2`);
      } else if (userSystem !== 'parfetts' && props.wholesalers.length === 0) {
        props.handleData({
          ...props.data,
        });
        toast.info('Please select atleast one wholesaler.');
      } else if (
        userSystem !== 'parfetts' &&
        props.builtQuery.Show_CustomerCount === 0 &&
        props.builtQuery.Show_Value === 0 &&
        props.builtQuery.Show_ValueIn === 0 &&
        props.builtQuery.Show_Volume === 0 &&
        props.builtQuery.Show_VolumeIn === 0 &&
        props.builtQuery.Show_QtyHectolitres === 0 &&
        props.builtQuery.Show_Qty9L === 0 &&
        props.builtQuery.Show_QtyStick === 0 &&
        props.builtQuery.Show_QtyGrams === 0
      ) {
        props.handleData({
          ...props.data,
        });
        toast.info('Please select atleast one measure.');
      } else {
        props.history.push(`${process.env.APP_ROOT}query/2`);
      }
    },
    addDateRange: props => () => {
      props.addDateRanges();
    },
    onMemberChange: props => options => {
      let exists = [];
      let selectAllNamed = [];
      if (options) {
        exists = _.filter(options, opt => {
          if (opt.value === 0) {
            return opt;
          }
        });

        selectAllNamed = _.filter(options, opt => {
          if (opt.value === 1) {
            return opt;
          }
        });

        if (exists.length > 0) {
          const membs = _.filter(
            props.members,
            mem => mem.value !== 0 && mem.value !== 1,
          );
          props.addWholesaler(membs);
        } else if (selectAllNamed.length > 0) {
          const membs = _.filter(
            props.members,
            mem => mem.value !== 0 && mem.value !== 1 && mem.value !== 9999,
          );
          props.addWholesaler(membs);
        } else {
          props.addWholesaler(options);
        }
      } else {
        props.addWholesaler('');
      }

      props.handleData({
        ...props.data,
        message: '',
        menuIsOpen: exists.length > 0 ? false : true,
      });
    },
    onToggleChange: props => ev => {
      const { id, value } = ev.target;
      let newVal = props.builtQuery[id] === 0 ? 1 : 0;

      if (id === 'Show_Weeks') {
        props.updateQueryFieldVisibility(id, newVal, 'Show Weeks', id);
      }
      props.updateField(id, newVal);
    },
    renderDateRanges: props => () => {
      const newDateRanges = props.builtQuery.newDateRanges;
      return newDateRanges.map((dateRange, index) => {
        const id = dateRange.id;
        const key = `dt_range_${id}`;
        return (
          <AlchDateRangepicker
            name="query_range"
            id={id}
            key={key}
            cantRemove={index === 0}
            onRemoveDateRange={props.removeDateRange}
            preselects={props.timeframes}
            onSelect={value => {
              props.updateDateRange(value, index);
            }}
            value={{
              start: dateRange.start,
              end: dateRange.end,
              label: dateRange.label,
            }}
          />
        );
      });
    },
  }),
)(Step1);
