import React from 'react';
import { Route } from 'react-router-dom';

import { connect } from 'react-redux';
import LoginPage from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import LoginSelect from '../pages/LoginSelect';
import SystemWrap from '../pages/SystemWrap';
import AlertHolder from './AlertHolder';
import ThemeWrapper from './ThemeWrapper';
import { AnimatePresence, motion } from 'framer-motion';
import { resetFilters } from '../redux/reducers/filters';

class InnerWrap extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // Reset Global Filter selections on route change
    this.props.resetFilters();
  }
  render() {
    return (
      <ThemeWrapper>
        <AnimatePresence
          key="top-level-page-wrapper"
          exitBeforeEnter={false}
          initial={false}>
          <Route
            key={'login-route'}
            exact
            path={process.env.APP_ROOT}
            render={props => <LoginPage {...this.props} {...props} />}
          />
          <Route
            key={'password-route'}
            exact
            path={`${process.env.APP_ROOT}forgot_password`}
            render={props => <ForgotPasswordPage {...this.props} {...props} />}
          />
          <Route
            key={'login-select-route'}
            path={`${process.env.APP_ROOT}supplier_select`}
            render={props => <LoginSelect {...this.props} {...props} />}
          />
          <Route
            key={'system-route'}
            path={`${process.env.APP_ROOT}:page`}
            render={props => <SystemWrap {...this.props} {...props} />}
          />
          <AlertHolder {...this.props} />
        </AnimatePresence>
      </ThemeWrapper>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  resetFilters: () => dispatch(resetFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InnerWrap);
