import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import PriceSensitivityReport from '../PriceSensitivityReport';
import AreaHeatmapReport from '../AreaHeatmapReport';
import StockedReport from '../StockedReport';
import ReportBuilder from '../ReportBuilder';
import { AnimatePresence, motion } from 'framer-motion';
import { getUserSystem, hasPermission } from '../../../helpers/util';

const setDefaultAnalysisPage = () => {
  const lowerCase = user => {
    if (user === 'countryRangeGroup') {
      return user.toLowerCase();
    } else {
      return user;
    }
  };

  const user = getUserSystem().toLowerCase();

  if (hasPermission(`role_view_${user}_price_sensitivity`)) {
    return 'analysis/price_sensitivity/';
  } else if (hasPermission(`role_view_${user}_area_heatmap`)) {
    return 'analysis/area_heatmap/';
  } else if (hasPermission(`role_view_${user}_bought_not_bought`)) {
    return 'analysis/bought_not_bought/';
  } else if (hasPermission(`role_view_${user}_stocked_not_stocked`)) {
    return 'analysis/stocked_not_stocked/';
  } else {
    return 'analysis/no-permissions';
  }
};

export default class NamDash extends React.Component {
  state = {
    subpage_nav: [],
  };

  render() {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch>
            <Route
              path={`${process.env.APP_ROOT}analysis/price_sensitivity/`}
              render={pageProps => (
                <PriceSensitivityReport {...this.props} {...pageProps} />
              )}
            />

            <Route
              path={`${process.env.APP_ROOT}analysis/area_heatmap/`}
              render={pageProps => (
                <AreaHeatmapReport {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}analysis/:subpage(stocked_not_stocked)`}
              render={pageProps => (
                <StockedReport {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}analysis/:subpage(bought_not_bought)`}
              render={pageProps => (
                <ReportBuilder {...this.props} {...pageProps} />
              )}
            />
            <Route
              path={`${process.env.APP_ROOT}analysis/no-permissions`}
              render={pageProps => <NoPermissions />}
            />
            <Redirect
              from={`${process.env.APP_ROOT}analysis`}
              to={`${process.env.APP_ROOT}${setDefaultAnalysisPage()}`}
            />
          </Switch>
        </AnimatePresence>
      </motion.div>
    );
  }
}

NamDash.defaultProps = {
  location: {},
};

NamDash.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

const NoPermissions = () => (
  <h1>Sorry, you do not have permissions to access this page.</h1>
);
