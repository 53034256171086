import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import Collapsible from 'react-collapsible';
import FileBase64 from 'react-file-base64';
import uuid from 'uuid/v4';
import { ToastContainer, toast } from 'react-toastify';
import { fetchDistributionLists, fetchCampaignByID, resetCampaign, removeActivity, addNewCampaign, addNewActivity, setCampaignText, setActivityImage, setCampaignDescription, sendCampaign, saveCampaign } from '../../redux/reducers/psa';
import CollapsibleHeader from '../../components/psa/collapsibleHeader';

const tableClass = ['o-table'];

const Campaign = ({ data, campaign, onTextChange, onCampaignNameChange, onDescriptionChange, onSelectDistributionList, distributionLists, onAddNewActivity, onSaveCampaign, onSendCampaign, onGetImage64, onRemoveActivity, isLoading }) => (
  <div className="o-canvas-wrap">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <div className="o-canvas">
      <div className="o-canvas__header u-layout u-layout--between">
        <div className="o-canvas__header-item u-layout">
          <h2 className="o-canvas__title u-100">Create a Campaign</h2>
        </div>
      </div>
      <div className="o-canvas__header-search">
        <div className="c-objects-wrap--table" style={{ position: 'relative' }}>
          <div className="o-canvas__header-search u-layout u-layout--spaced">
            <div className="u-1_3">
              <div className="form-group" style={{ height: '80px' }}>
                <h3 className="o-canvas__search-title">Campaign Name</h3>
                <input type="text" id="campaignName" className="ReactModal--input-green" name="campaignName" value={campaign.name || ''} onChange={onCampaignNameChange} />
              </div>
            </div>
          </div>
          <div className="o-canvas__header-search u-layout u-layout--spaced">
            <div className="u-1_3">
              <div className="form-group" style={{ height: '80px' }}>
                <h3 className="o-canvas__search-title">Add a new Activity</h3>
                <input type="text" id="activityName" className="ReactModal--input-green" name="activityName" value={data.activityName || ''} onChange={onTextChange} />
                <button className="ml-10 vk-btn btn-psa vk-btn-default vk-btn-l vk-fullwidth" onClick={() => onAddNewActivity()}>
                  ADD
                </button>
              </div>
            </div>
          </div>
          {campaign.activities.length > 0 &&
            campaign.activities.map((act, idx) => {
              return (
                <Collapsible
                  trigger={<CollapsibleHeader name={act.name} />}
                  triggerSibling={() => {
                    return (
                      <div>
                        <a href="javascript:void(0)" onClick={() => onRemoveActivity(act.id)}>
                          <span className="fa fa-remove collapsible-remove"></span>
                        </a>
                      </div>
                    );
                  }}
                  key={idx}
                >
                  <div className="o-canvas__header-search u-layout u-layout--spaced">
                    <div className="u-1_3">
                      <div className="form-group" style={{ height: '80px' }}>
                        <h3 className="o-canvas__search-title">Activity instructions (To be displayed to the user - e.g "please provide 3 images of X")</h3>
                        <input type="text" id={act.id} className="ReactModal--input-green collapsible-text" name={act.id} value={act.description || ''} onChange={onDescriptionChange} />
                      </div>
                    </div>
                  </div>
                  {act.exampleImage && act.exampleImage.trim().length > 0 && (
                    <div>
                      <img src={act.exampleImage} style={{ width: '200px', height: '200px' }} />
                      <br />
                    </div>
                  )}
                  <FileBase64 multiple={false} onDone={img => onGetImage64(img, act.id)} />
                  <br />
                  <br />
                  <div className="o-canvas__header-search u-layout u-layout--spaced">
                    {distributionLists.map((list, idx) => {
                      return (
                        <div className="u-1_3" key={idx}>
                          <div className="form-group" style={{ height: '20px' }}>
                            <h3 className="o-canvas__search-title">
                              {list.name}&nbsp;
                              <input type="checkbox" checked={act.distributionLists.filter(lst => lst.id === list.id).length > 0} name={`${act.id}=${list.id}`} onChange={onSelectDistributionList} />
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Collapsible>
              );
            })}
          {campaign.name.length > 0 && (
            <button className="u-right mt-10 mr-10 btn-psa" onClick={() => onSaveCampaign()}>
              Save
            </button>
          )}
          {campaign.name.length > 0 && (
            <button className="u-right mt-10 mr-10 btn-psa" onClick={() => onSendCampaign()}>
              Send
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);
function mapStateToProps(state) {
  let data = {
    isLoading: state.psa.isLoading,
    isCampaignAdded: state.psa.isCampaignAdded,
    isCampaignSent: state.psa.isCampaignSent,
    campaign: state.psa.campaign,
    distributionLists: state.psa.distributionLists,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchDistributionLists,
    fetchCampaignByID,
    resetCampaign,
    setCampaignText,
    setCampaignDescription,
    removeActivity,
    addNewActivity,
    addNewCampaign,
    setActivityImage,
    sendCampaign,
    saveCampaign,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchDistributionLists();
      if (this.props.match.params.id) {
        this.props.fetchCampaignByID(this.props.match.params.id);
      } else {
        this.props.resetCampaign();
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isCampaignAdded !== this.props.isCampaignAdded && this.props.isCampaignAdded) {
        this.props.history.push(`${process.env.APP_ROOT}psa/campaigns`);
      }
      if (prevProps.isCampaignSent !== this.props.isCampaignSent && this.props.isCampaignSent) {
        toast.dismiss();
        toast.info('Campaign sent successfully.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  }),
  withState('data', 'handleData', {
    activityName: '',
    campaignName: '',
  }),
  withHandlers({
    onTextChange: ({ data, handleData }) => ev => {
      const { name, value } = ev.target;
      handleData({ ...data, [name]: value });
    },
    onCampaignNameChange: ({ data, handleData, setCampaignText }) => ev => {
      const { name, value } = ev.target;
      setCampaignText(value);
    },
    onDescriptionChange: ({ data, handleData, setCampaignDescription }) => ev => {
      const { name, value } = ev.target;
      setCampaignDescription(name, value);
    },
    onGetImage64: ({ data, handleData, setActivityImage }) => (img, id) => {
      setActivityImage(id, img.base64);
    },
    onSendCampaign: ({ data, handleData, sendCampaign }) => () => {
      sendCampaign();
    },
    onSaveCampaign: ({ data, handleData, campaign, saveCampaign }) => () => {
      saveCampaign();
    },
    onRemoveActivity: ({ data, handleData, removeActivity }) => id => {
      removeActivity(id);
    },
    onSelectDistributionList: ({ data, handleData, campaign }) => ev => {
      const { name, value } = ev.target;
      const ids = name.split('=');
      handleData({
        ...data,
        activities: campaign.activities.map(act => {
          if (act.id === ids[0]) {
            const list = act.distributionLists.filter(list => list.id === ids[1]);
            if (list.length > 0) {
              act.distributionLists = act.distributionLists.filter(lst => lst.id !== ids[1]);
            } else {
              act.distributionLists.push({ id: ids[1] });
            }
          }
          return act;
        }),
      });
    },
    onAddNewActivity: ({ data, handleData, campaign, addNewActivity, addNewCampaign }) => () => {
      const activity = campaign.activities.filter(act => act.name === data.activityName);
      if (campaign.name.length === 0) {
        toast.info('Please enter campaign name.', {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      if (activity.length > 0) {
        toast.info('Activity with same name already exists.', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        if (campaign.id.length === 0) {
          let newCampaign = {
            name: campaign.name,
            archieved: false,
            id: uuid(),
            activities: [
              {
                name: data.activityName,
                description: '',
                exampleImage: '',
                id: uuid(),
                distributionLists: [],
              },
            ],
          };
          addNewCampaign(newCampaign);
        } else {
          addNewActivity({
            name: data.activityName,
            description: '',
            exampleImage: '',
            id: uuid(),
            distributionLists: [],
          });
        }
      }
    },
  }),
)(Campaign);
