import dateFormat from 'dateformat';
import moment from 'moment';
import theme from '../config/Themes.js';
import { each, map, flatten, filter, orderBy, uniqBy, isEmpty } from 'lodash';
import { requestGet } from './axios.js';
import { encodeText } from './util';

import store from '../react/redux/store';

export class CustomError {
  constructor(message) {
    this.customMessage = true;
    this.message = message;
  }
}

export function validateId(id) {
  return id
    .toString()
    .replace(/\//g, 'x')
    .replace(/:/g, 'x')
    .replace(/\./g, '_');
}

export function loadLibrary(href, priority = 0, lId = href.slice(-20)) {
  const validId = validateId(lId);
  /* Library has been loaded */
  if ($(`#${validId}`).length > 0) {
    const script = $(`#${validId}`);
    return {
      done(callback) {
        if (script.attr('data-loaded') === 'true') callback();
        script.one('load', callback);
      },
    };
  }

  const script = $('<script>');
  if (priority === 1) {
    $('body').append(script);
  } else {
    $('head').append(script);
  }
  // Add loaded flag
  script.on('load', () => {
    script.attr('data-loaded', 'true');
  });
  script.attr({
    src: href,
    id: validId,
    type: 'text/javascript',
    'data-loaded': 'false',
  });

  return {
    done(callback) {
      if (script.attr('data-loaded') === 'true') callback();
      script.one('load', callback);
    },
  };
}

export function loadStylesheet(href, priority = 0, lId = href.slice(-20)) {
  const validId = validateId(lId);

  if ($(`#${validId}`).length > 0) return;

  const cssLink = $('<link>');
  if (priority === -1) {
    $('head').prepend(cssLink);
  } else {
    $('head').append(cssLink);
  }

  cssLink.attr({
    rel: 'stylesheet',
    type: 'text/css',
    href,
    id: validId,
  });
}

export function getRandomNumber(key = '') {
  let r = Date.now().toString().slice(-3);
  r += Math.random().toString().slice(-3);
  return key + r;
}

/* SELECT-OPTION html to array */
export function optionsToArray($options) {
  const arr = [];
  $options.each((i, e) => {
    if (!$(e).attr('value')) {
      return;
    }
    arr.push({
      id: $(e).attr('value').trim(),
      text: $(e).text().trim(),
      parent: $(e).attr('class')
        ? $(e).attr('class').indexOf('parent') !== -1
        : false,
      ...$(e)[0].dataset,
    });
  });
  return arr;
}

export function getInternetExplorerVersion() {
  var rv = -1;
  if (navigator.appName == 'Microsoft Internet Explorer') {
    var ua = navigator.userAgent;
    var re = new RegExp('MSIE ([0-9]{1,}[\\.0-9]{0,})');
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  } else if (navigator.appName == 'Netscape') {
    var ua = navigator.userAgent;
    var re = new RegExp('Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})');
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  }
  return rv;
}

/* Convert input radios to AlchToggleMenu valid array */
export function labelRadioToArray($labels) {
  const arr = [];
  $labels.each((i, e) => {
    const $label = $(e);
    if (!$label.attr('data-value')) return;
    arr.push({
      id: $label.attr('data-value'),
      label: $label.text().trim(),
    });
  });
  return arr;
}

/* Format Percentage */
export function formatPerc(num) {
  num = parseFloat(num ?? 0);
  return `${(num * 100).toFixed(2)}%`;
}

/* Format Number */
export function formatNumber(num, prefix = '', suffix = '') {
  num = parseFloat(num ?? 0);
  return `${prefix}${num
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${suffix}`;
}

/* Calculate percentage */
export function calcPercentage(fr, total) {
  return formatPerc(fr / total);
}
/* Strip images off */
export function removeAssets(htmlStr) {
  return htmlStr.replace(/<(img|image)[^>]*\/?>/gi, '');
  // return htmlStr;
}

/* Object into query string */
export function objectToQuery(obj) {
  const params = [];
  Object.keys(obj).forEach(prop => {
    const param = prop.toString().trim();
    const val = encodeURIComponent(obj[prop].toString().trim());
    params.push(`${param}=${val}`);
  });

  return params.join('&');
}

/* OPTION FIELDS TO ARRAYS FOR TOGGLE MENU */
export function optionFieldToToggleArray($optFields) {
  const toggleArray = [];
  $optFields.each((i, e) => {
    const $e = $(e);

    if (!$e.data('datecode')) return;
    toggleArray.push({
      id: $e.data('datecode'),
      label: $e.text(),
      uid: $e.data(),
    });
  });

  return toggleArray;
}

/* eslint-disable prefer-template,prefer-arrow-callback, no-useless-concat */
/* CONVERT TABLE TO CSV */
export function exportTableToCSV(
  $table,
  filename,
  type,
  reportType,
  measure,
  timeFrame,
  likeForLike,
  filters,
) {
  const $rows = $table.find('tr:has(th), tr:has(td)');
  // Temporary delimiter characters unlikely to be typed by keyboard
  // This is to avoid accidentally splitting the actual contents
  const tmpColDelim = String.fromCharCode(11); // vertical tab character
  const tmpRowDelim = String.fromCharCode(0); // null character
  // actual delimiter characters for CSV format
  const colDelim = '","';
  const rowDelim = '"\r\n"';
  // Grab text from table into CSV formatted string
  let csv =
    '"' +
    $rows
      .map(function (i, row) {
        const $row = $(row);
        const $cols = $row.find('th, td');
        return $cols
          .map(function (j, col) {
            const length =
              $table[0].id === 'stocked_table' ||
              $table[0].id === 'stocked_table_all' ||
              $table[0].id === 'product_search' ||
              $table[0].id === 'sku_table'
                ? $cols.length
                : $cols.length - 1;
            if (j === length && type !== undefined) {
              return '';
            } else {
              const $col = $(col);
              const text = $.trim($col.text());
              return text.replace(/"/g, '""'); // escape double quotes
            }
          })
          .get()
          .join(tmpColDelim);
      })
      .get()
      .join(tmpRowDelim)
      .split(tmpRowDelim)
      .join(rowDelim)
      .split(tmpColDelim)
      .join(colDelim) +
    '"';

  csv = '\r\n' + csv;

  if (filters && filters.length > 0) {
    csv = '"FILTERS","' + filters + '", \r\n' + csv;
  }
  if (measure && measure.length > 0) {
    csv = '"MEASURE","' + measure + '", \r\n' + csv;
  }
  if (timeFrame && timeFrame.length > 0) {
    csv = '"TIMEFRAME","' + timeFrame + '", \r\n' + csv;
  }
  if (likeForLike && likeForLike.length > 0) {
    csv = '"LIKEFORLIKE", "' + likeForLike + '", \r\n' + csv;
  }
  if (reportType && reportType.length > 0) {
    csv = '"REPORT","' + reportType + '", \r\n' + csv;
  }
  if (reportType && reportType.length > 0) {
    csv = '"TIMESTAMP","' + dateToTimestamp(new Date()) + '", \r\n' + csv;
  }

  csv = '\ufeff' + csv;

  // Data URI
  // csvData = 'data:application/csv;charset=utf-8,' + '\ufeff' + encodeURIComponent(csv);
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    const a = document.createElement('a');
    if (window.navigator.msSaveOrOpenBlob) {
      const fileData = '\ufeff' + csv;
      const blobObject = new Blob([fileData], {
        type: 'text/csv;charset=utf-8, \ufeff',
      });
      a.onclick = function () {
        window.navigator.msSaveOrOpenBlob(blobObject, filename);
      };
    }
    a.appendChild(document.createTextNode('d'));
    document.body.appendChild(a);
    $(a).click();
  } else if (navigator.msSaveBlob) {
    var blob = new Blob([csv], { type: 'text/plain;charset=utf-8;' });
    return navigator.msSaveBlob(blob, filename);
  } else {
    const csvData =
      'data:application/csv;filename=' +
      filename +
      ';charset=utf-8,' +
      '\ufeff' +
      encodeURIComponent(csv.replace('Â', '').replace('¬', ''));
    const a = document.createElement('a');
    $(a).attr({
      download: filename,
      href: csvData,
    });
    a.appendChild(document.createTextNode('d'));
    document.body.appendChild(a);
    $(a).trigger('click');
    a.click();
    $(a).remove();
  }
}
/* eslint-enable prefer-template,prefer-arrow-callback, no-useless-concat */

export function decomposeHTMLTable(tableData) {
  /* Parsing cell to object */
  function parseCell(cell) {
    return {
      text: $(cell).html(),
      colspan: $(cell).attr('colspan') ? $(cell).attr('colspan') : 1,
    };
  }

  const tableObject = {
    headers: [],
    rows: [],
  };
  const $table = $(tableData).filter('table');

  if ($table.length === 0) {
    return false;
  }

  /* Headers */
  const $headers = $table.find('th');
  $headers.each((i, header) => {
    const tableHeader = parseCell(header);
    tableObject.headers.push(tableHeader);
  });

  /* ROWS */
  const $rows = $table.find('tr');
  $rows.each((i, row) => {
    const tableRow = [];

    /* row cells */
    $(row)
      .find('td')
      .each((j, cell) => {
        const tableCell = parseCell(cell);
        tableRow.push(tableCell);
      });
    if (tableRow.length > 0) {
      tableObject.rows.push(tableRow);
    }
  });

  return tableObject;
}

export function removeItemFromArray(arr, i) {
  arr.splice(i, 1);
  return arr;
}

export function dateToYearWeek(date) {
  if (!date.getFullYear) {
    console.info('Could not convert the date: ', date);
    return date;
  }
  return `${moment(date).isoWeekYear()}${date.getWeekNumber()}`;
}

export function dateToTimestamp(date) {
  return dateFormat(date, 'yyyy-mm-dd hh:mm');
}

export function dateToString(date) {
  return dateFormat(date, 'yyyy-mm-dd');
}

export const regionConversion = code => {
  let region;
  switch (code) {
    case 'E15000001':
      region = 'North East';
      break;
    case 'E15000002':
      region = 'North West';
      break;
    case 'E15000003':
      region = 'Yorkshire and The Humber';
      break;
    case 'E15000004':
      region = 'East Midlands';
      break;
    case 'E15000005':
      region = 'West Midlands';
      break;
    case 'E15000006':
      region = 'Eastern';
      break;
    case 'E15000007':
      region = 'London';
      break;
    case 'E15000008':
      region = 'South East';
      break;
    case 'E15000009':
      region = 'South West';
      break;
    case 'N07000001':
      region = 'Northern Ireland';
      break;
    case 'S15000001':
      region = 'Scotland';
      break;
    case 'W08000001':
      region = 'Wales';
      break;
  }
  return region;
};

export const isAfterToday = date => {
  const today = new Date();
  const checkDate = new Date(date);
  return today <= checkDate;
};

export const htmlDecodePound = val => {
  if (typeof val === 'string') {
    return val.replace(/&#163;/g, '£');
  } else {
    return val;
  }
};

export const formatSalesByWeekAPIData = salesByWeekGraphData => {
  let colors = {
    line1: theme.lineGraph1A,
    line2: theme.lineGraph2A,
    line3: theme.lineGraph3A,
    line4: theme.lineGraph1B,
    line5: theme.lineGraph2B,
    line6: theme.lineGraph3B,
    line7: theme.lineGraph1C,
    line8: theme.lineGraph2C,
    line0: theme.lineGraph3C,
  };

  // Get weeks in year
  const weeksInYear = Array.from([...Array(53).keys()], week =>
    week.toString(),
  );
  weeksInYear.shift();
  // Reorder weeks to match API
  const weekBeginning = salesByWeekGraphData.graph[0].WeekNumber;
  const weeksReordered = [
    ...weeksInYear.slice(weeksInYear.indexOf(weekBeginning)),
    ...weeksInYear.slice(0, weeksInYear.indexOf(weekBeginning)),
  ];
  const typeOfData =
    salesByWeekGraphData.graph[0].Type === 'Product' ||
    salesByWeekGraphData.graph[0].Year === 'Category'
      ? 'product'
      : 'yoy';
  let dataSets = [];

  const lyData = salesByWeekGraphData.graph.filter(
    item =>
      (item.Year === 'LY' && item.WeekNumber !== '53') ||
      (item.Type === 'Category' && item.WeekNumber !== '53') ||
      (item.Year === 'Category' && item.WeekNumber !== '53'),
  );
  const tyData = salesByWeekGraphData.graph.filter(
    item =>
      (item.Year === 'TY' && item.WeekNumber !== '53') ||
      (item.Type === 'Product' && item.WeekNumber !== '53'),
  );
  const datasets = [
    {
      label: typeOfData === 'yoy' ? 'TY' : 'Product',
      borderColor: colors[`line${1}`],
      backgroundColor: colors[`line${1}`],
      fill: false,
      data: tyData.map(item => item.total),
      yAxisID: `y-axis-1`,
    },
    {
      label: typeOfData === 'yoy' ? 'LY' : 'Category',
      borderColor: colors[`line${2}`],
      backgroundColor: colors[`line${2}`],
      fill: false,
      data: lyData.map(item => item.total),
      yAxisID: `y-axis-1`,
    },
  ];
  return {
    graph: { labels: weeksReordered, datasets: datasets },
    table: { headers: weeksReordered, datasets: datasets },
  };
};

export const encodeParameters = (parameters, field, key) => {
  if (typeof parameters == 'string') {
    return `${field}=${encodeURIComponent(parameters)}`;
  } else {
    return `${field}=${parameters
      .map(parameter => encodeURIComponent(parameter[key]))
      .join(',')}`;
  }
};

export const formatUrlWithParameters = (
  oldUrl,
  state,
  user,
  prevState,
  isSupplierOrBrand = false,
  fieldsToIgnore = {},
) => {
  const { selectedAdvancedBusinessTypes, accountName } =
    store.getState().filters;
  let url = oldUrl;
  prevState = prevState || {};

  if (!isSupplierOrBrand) {
    url += `&timeframe=${
      state.timeframe ? state.timeframe : prevState.timeframe || (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks')
    }`;
    url += `&likeForLike=${
      state.likeForLike
        ? state.likeForLike
        : prevState.likeForLike || 'like_for_like'
    }`;
    url += `&measure=${
      state.measure ? state.measure : prevState.measure || 'Value'
    }`;
    url += `&type=${
      state.purchase ? state.purchase : prevState.purchase || 'out'
    }`;

    if (user?.showCompetitor && user?.supplier.Text) {
      url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
    }
  }
  url +=
    state.selectedDepartments?.length > 0
      ? '&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(','))
      : '';
  url +=
    state.selectedCategories?.length > 0
      ? '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedSubCategories?.length > 0
      ? '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(','))
      : '';
  url +=
    state.selectedLevel4s?.length > 0
      ? '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(','))
      : '';
  url +=
      state.selectedLevel5s && state.selectedLevel5s?.length > 0
      ? '&level5=' +
        encodeURIComponent(map(state.selectedLevel5s, 'Text').join(','))
      : '';
  if (!isSupplierOrBrand) {
    url +=
      state?.selectedSuppliers?.length > 0 || state?.supplier?.SupplierName
        ? '&supplier=' +
          encodeURIComponent(
            state?.selectedSuppliers?.length > 0
              ? map(state?.selectedSuppliers, 'Text').join(',')
              : state?.supplier?.SupplierName,
          )
        : '';
  }
  if (!isSupplierOrBrand) {
    url +=
      state?.selectedBrands?.length > 0 || state?.brand?.Brand
        ? '&brand=' +
          encodeURIComponent(
            state?.selectedBrands?.length > 0
              ? map(state?.selectedBrands, 'Text').join(',')
              : state?.brand?.Brand,
          )
        : '';
  }
  url +=
    state.selectedDepots?.length > 0
      ? '&depot=' +
        state.selectedDepots
          .map(depot => encodeURIComponent(depot.DepotName))
          .join(',')
      : '';
  url +=
    state.selectedBusinessType?.length > 0
      ? '&business_type=' +
        encodeURIComponent(map(state.selectedBusinessType, 'Text').join(','))
      : '';

  url += state?.selectedGroup?.length
    ? '&group=' + encodeURIComponent(map(state.selectedGroup, 'Text').join(','))
    : '';

  url +=
    state.dataType && state.dataType.Text
      ? '&dataType=' + encodeURIComponent(state.dataType.Text)
      : '';

  url +=
    state.coreRange && state.coreRange.ID
      ? '&core_range=' + encodeURIComponent(state.coreRange.ID)
      : '';

  url +=
    state.memberType && state.memberType.ID
      ? '&member_type=' + encodeURIComponent(state.memberType.ID)
      : '';
  
  url +=
    state.retailType && state.retailType.ID
      ? '&retail_type=' + encodeURIComponent(state.retailType.ID)
      : '';

  url +=
    state.commission && state.commission.Text
      ? '&commission=' + encodeURIComponent(state.commission.Text)
      : '';

  url +=
    state.region && state.region.value
      ? '&region=' + encodeURIComponent(state.region.value)
      : '';

  url +=
    state.fascia && state.fascia.value
      ? '&fascia=' + encodeURIComponent(state.fascia.value)
      : '';

  url +=
    state?.pmp?.length > 0
      ? '&pmp=' + state.pmp
      : '';

  url +=
    state.excludedDepartments?.length > 0
      ? '&excludedDepartments=' +
        state.excludedDepartments
          .map(department => encodeURIComponent(department.Text))
          .join(',')
      : '';

  let memberIds = [];
  if (!fieldsToIgnore?.members) {
    if (
      _.filter(state.selectedMembers, member => member.Text === 'ALL').length >
      0
    ) {
      url += '&member=';
    } else {
      _.each(state.selectedMembers, member => {
        memberIds.push(member.Text || member.ActualName);
    });

    if (memberIds.length > 0) {
      url += '&member=' + encodeURIComponent(memberIds);
    }
    }
  }
  if (state.ownBrand && state.ownBrand.Text) {
    url += `&ownBrand=${state.ownBrand.Text}`;
  }
  if (state.penetrationReportType) {
    url += state.penetrationReportType.ID
      ? '&primary=' + encodeURIComponent(state.penetrationReportType.ID)
      : '';
  }

  url += state.selectedSupplier
    ? '&supplier=' + encodeURIComponent(state.selectedSupplier.Text)
    : '';
  url += state.selectedBrand
    ? '&brand=' + encodeURIComponent(state.selectedBrand.Text)
    : '';

  if (state.selectedProduct) {
    url += state.selectedProduct.WVProdDesc
      ? '&product=' + encodeURIComponent(state.selectedProduct.WVProdDesc)
      : '';
  }
  const excludeUrlsFromAccountAndAdvBusTypes = [
    'product/getAllRetail',
    'supplier/getAll',
  ];
  if (
    !excludeUrlsFromAccountAndAdvBusTypes.some(testUrl =>
      oldUrl.includes(testUrl),
    )
  ) {
    //exlude Account numbers from all boston matrix reports
    // if (!oldUrl.includes('bostonMatrix')) {
    if (accountName && accountName.length > 0) {
      url += `&storeid=${accountName
        .map(val => encodeURIComponent(val.RegNo))
        .join(',')}`;
      }
    // }
    selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
      if (value && value.length > 0) {
        url += `&${encodeURIComponent(ApiName)}=${value
          .map(val => encodeURIComponent(val.value))
          .join(',')}`;
      }
    });
  }

  return url;
};

export const formatUrlWithSingleParameter = (oldUrl, state, user) => {
  let url =
    oldUrl +
    '&timeframe=' +
    state.timeframe +
    '&like_for_like=' +
    state.likeForLike +
    '&measure=' +
    state.measure +
    '&member_type=' +
    (state.memberType && state.memberType.ID ? state.memberType.ID : '') +
    '&retail_type=' +
    (state.retailType && state.retailType.ID ? state.retailType.ID : '') +
    '&core_range=' +
    (state.coreRange && state.coreRange.ID ? state.coreRange.ID : '') +
    '&type=' +
    state.purchase;

  if (user.showCompetitor && user.supplier.Text) {
    url += `&supplier=${encodeURIComponent(user.supplier.Text)}`;
  }
  url += state.selectedDepartment
    ? '&department=' + encodeURIComponent(state.selectedDepartment.Text)
    : '';
  url += state.selectedCategory
    ? '&category=' + encodeURIComponent(state.selectedCategory.Text)
    : '';
  url += state.selectedSubCategory
    ? '&subcategory=' + encodeURIComponent(state.selectedSubCategory.Text)
    : '';
  url += state.selectedLevel4
    ? '&level4=' + encodeURIComponent(state.selectedLevel4.Text)
    : '';
  url += state.selectedLevel5
    ? '&level5=' + encodeURIComponent(state.selectedLevel5.Text)
    : '';
  url += state.selectedSupplier
    ? '&supplier=' + encodeURIComponent(state.selectedSupplier.Text)
    : '';
  url += state.selectedBrand
    ? '&brand=' + encodeURIComponent(state.selectedBrand.Text)
    : '';

  url += state.selectedProduct.WVProdDesc
    ? '&product=' + encodeURIComponent(state.selectedProduct.WVProdDesc)
    : '';

  if (oldUrl !== '/route.php?c=product/getAllRetail&cron=1') {
    const { selectedAdvancedBusinessTypes, accountName } =
      store.getState().filters;
    url +=
      accountName && accountName.length > 0
        ? '&storeid=' + encodeURIComponent(accountName)
        : '';
    selectedAdvancedBusinessTypes.forEach(({ ApiName, value }) => {
      if (value && value.length > 0) {
        url += `&${encodeURIComponent(ApiName)}=${value
          .map(val => encodeURIComponent(val.value))
          .join(',')}`;
      }
    });
  }
  return url;
};

export const formatToCurrency = (amount, decimalPlaces) => {
  // Create our number formatter.
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits:
      decimalPlaces || decimalPlaces === 0 ? decimalPlaces : 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const getLastSelectedFilter = state => {
  const filters = [
    { id: 'department', value: state.selectedDepartments },
    { id: 'category', value: state.selectedCategories },
    { id: 'subCategory', value: state.selectedSubCategories },
    { id: 'level4s', value: state.selectedLevel4s },
    { id: 'level5s', value: state.selectedLevel5s },
    { id: 'sku', value: state.selectedProduct },
  ];
  const removeEmptyFilters = filters.filter(item => !isEmpty(item.value));
  const lastSelectedFilter =
    removeEmptyFilters &&
    removeEmptyFilters[
      removeEmptyFilters.length > 0 ? removeEmptyFilters.length - 1 : 0
    ];
  return filters[removeEmptyFilters.length].id;
};

export const getLastSelectedFilterBNB = (state, isAlt, isBrandOrProduct) => {
  const index =
    isBrandOrProduct === 'product' || isBrandOrProduct === 'brand' ? 2 : 1;
  let filters = isAlt
    ? [
        { id: 'department', value: state.alt_department },
        { id: 'category', value: state.alt_category },
        { id: 'subcategory', value: state.alt_subCategory },
        { id: 'level4', value: state.alt_level4 },
        { id: 'level5', value: state.alt_level5 },
        { id: 'brand', value: state.alt_brand },
        { id: 'product', value: state.alt_product },
      ]
    : [
        { id: 'department', value: state.department },
        { id: 'category', value: state.category },
        { id: 'subcategory', value: state.subCategory },
        { id: 'level4', value: state.level4 },
        { id: 'level5', value: state.level5 },
        { id: 'brand', value: state.brand },
        { id: 'product', value: state.selectedBnBProduct },
      ];

  if (isEmpty(state.level4s)) {
    filters = filters.filter(item => item.id !== 'level4');
  }
  if (isEmpty(state.level5s)) {
    filters = filters.filter(item => item.id !== 'level5');
  }

  let lowestOption;
  filters.forEach(filter => {
    if (!isEmpty(filter.value)) lowestOption = filter;
  });
  return lowestOption;
};

export const getReportTypeForDrill = (drill, reportType) => {
  let currentReportType = reportType;

  const ReportMap = [
    { id: 'level5', value: drill.level5s },
    { id: 'level4', value: drill.level4s },
    { id: 'subcategory', value: drill.subCategories },
    { id: 'category', value: drill.categories },
    { id: 'department', value: drill.departments },
  ];
  if (
    reportType !== 'supplier' &&
    reportType !== 'brand' &&
    reportType !== 'member' &&
    reportType !== 'depot' &&
    reportType !== 'business_type'
  ) {
    ReportMap.forEach(report => {
      if (isEmpty(report.value)) currentReportType = report.id;
    });
  }

  return currentReportType;
};

export const formatBnBUrl = (oldUrl, state) => {
  let url = oldUrl;

  // Top Selection
  url += state.department?.Text
    ? '&department=' + encodeURIComponent(state.department.Text)
    : '';
  url += state.category?.Text
    ? '&category=' + encodeURIComponent(state.category.Text)
    : '';
  url += state.subCategory?.Text
    ? '&subcategory=' + encodeURIComponent(state.subCategory.Text)
    : '';
  url += state.level4?.Text
    ? '&level4=' + encodeURIComponent(state.level4.Text)
    : '';
  url += state.level5?.Text
    ? '&level5=' + encodeURIComponent(state.level5.Text)
    : '';
  // Alt selection

  url += state.alt_department?.Text
    ? '&alt_department=' + encodeURIComponent(state.alt_department.Text)
    : '';
  url += state.alt_category?.Text
    ? '&alt_category=' + encodeURIComponent(state.alt_category.Text)
    : '';
  url += state.alt_subCategory?.Text
    ? '&alt_subcategory=' + encodeURIComponent(state.alt_subCategory.Text)
    : '';
  url += state.alt_level4?.Text
    ? '&alt_level4=' + encodeURIComponent(state.alt_level4.Text)
    : '';
  url += state.alt_level5?.Text
    ? '&alt_level5=' + encodeURIComponent(state.alt_level5.Text)
    : '';

  return url;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeAllWords = string => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const sortDuplicateNextLevelDown = (arr, nextLevelDown) => {
  arr.forEach((element, index, array) => {
    if (index === array.length - 1) {
      return;
    }
    if (array[index].Text === array[index + 1].Text) {
      if (array[index][nextLevelDown] && array[index + 1][nextLevelDown]) {
        if (
          array[index][nextLevelDown].length <
          array[index + 1][nextLevelDown].length
        ) {
          let b = array[index + 1];
          array[index + 1] = array[index];
          array[index] = b;
        }
      }
    }
  });
  return arr;
};

export const fetchSuppliers = async ({ state, user }) => {
  let suppliers = [];
  try {
    suppliers = await requestGet({
      url: formatUrlWithParameters(
        '/route.php?c=supplier/getAll',
        state,
        user,
        {},
        true,
        { members: true },
      ),
    });
  } catch (error) {
    console.warn(error);
  }
  return suppliers;
};

export const fetchBrands = async ({ state, user }) => {
  let brands = [];
  try {
    brands = await requestGet({
      url: formatUrlWithParameters(
        '/route.php?c=brand/getAll',
        state,
        user,
        {},
        true,
      ),
    });
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
  } catch (error) {
    console.warn(error);
  }
  return brands;
};
