import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { pageVariants } from '../../../config/pageVariants';
import { spaceAndRange as explanation } from '../../components/Explainer/Explanations';
import { categoryPenetrationExportHeaders } from '../../../config/tableExportHeaders';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { getUserSystem } from '../../../helpers/util';

import ReportContainer from '../../components/ReportContainer';
import Loading from '../../components/Loading';
import CategoryPenetrationTable from '../../components/retail/CategoryPenetrationTable';
import DistributionGraph from '../../components/retail/DistributionGraph';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';
import CategoryPenetrationGraph from '../../components/retail/CategoryPenetrationGraph';
import ThemedModal from '../../components/ThemedModal';
import { setAccountName } from '../../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartments,
  setLikeForLike,
  setCategories,
  setSuppliers,
  setBrands,
  setSubCategories,
  setLevel4s,
  setLevel5s,
  setRefreshDisabledStatus,
  loadCategoryPenetrationData,
  setPenetrationReportType,
  drillGraphResults,
  drillTableHeaders,
  resetReport,
} from '../../redux/reducers/retail';

const StyledContainer = styled.div`
margin:2rem 0rem;
padding:2rem 1.8rem;
background-color:#ffffff;
box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

table {
	overflow-x: hidden;	 
	overflow-x: hidden;	 
	font-size: 0.9rem; 
 }

 tr td:first-of-type {
	font-weight:900;
	background-color:#white !important;
	text-align:right;
	color:${({ theme }) => theme.primary};
 }
td  {
	border-top:1px solid #fff;
	color:${({ theme }) => theme.primary};
 }
tr th:first-of-type {
  border:none;
 }
.table th {
	background: ${({ theme }) => theme.primary};
	border: 1px solid ${({ theme }) => theme.primary};
	color:#fff;
 }
.rdt_TableHeadRow .rdt_TableCol {
  color:#000;
	padding: 8px;
 }
h2 {
    font-size: 0.95rem !important;
 }
}
`;
export const CategoryPenetration = ({
  timeframe,
  timeframes,
  accountName,
  accountNames,
  onAccountNameChange,
  likeForLike,
  likeForLikes,
  setLoading,
  fetchCategories,
  onTimeFrameChange,
  selectedDepartments,
  departments,
  onDepartmentChange,
  selectedCategories,
  categories,
  onCategoryChange,
  onLikeForLikeChange,
  onUnmount,
  suppliers,
  selectedSuppliers,
  onSupplierChange,
  onSubCategoryChange,
  onBrandChange,
  brands,
  selectedBrands,
  subCategories,
  selectedSubCategories,
  onLevel4Change,
  selectedLevel4s,
  level4s,
  onLevel5Change,
  selectedLevel5s,
  level5s,
  loadCategoryPenetrationData,
  categoryPenetrationData,
  distributionGraphData,
  tableDrillValue,
  barChartDataSummary,
  barChartDataSku,
  isLoading,
  unlockRefresh,
  isRefreshDisabled,
  setReportType,
  reportType,
  reportTypes,
  drillGraphResults,
  drillTableHeaders,
  location,
  onResetReport,
  isRollingDateRange,
  ...props
}) => {
  useEffect(() => {
    fetchCategories('category_penetration', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });

    return () => onUnmount();
  }, []);

  const [showModal, setShowModal] = useState(false);

  return (
    <motion.div
      key={'Category-Penetration-ID'}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={false}
        closeButton={false}
        toastClassName={`c-alchemy-toasty`}
      />
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        explanationTooltip={explanation.page.tooltip}
        refresh={{
          refreshHandler: () => loadCategoryPenetrationData(),
          isRefreshDisabled,
        }}
        exports={{
          CSV: {
            headers: categoryPenetrationExportHeaders,
            data: categoryPenetrationData && categoryPenetrationData.table,
          },
        }}
        title={'Display By'}
        isDisabled={!selectedDepartments}
        currentReportType={reportType}
        switchableReportTypes={reportTypes}
        onSwitchReportType={type => setReportType(type)}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          },
          { text: 'Space & Ranging Analysis for latest' },
          {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
          {
            name: 'accountName',
            valueKey: 'RegNo',
            labelKey: 'CustomerName',
            value: accountName,
            options: accountNames,
            handler: option => onAccountNameChange(option),
            clearable: true,
          },
        ]}>
        <DataDropdownsContainer
          unlockRefresh={unlockRefresh}
          dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            },
            {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            },
            {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel4s,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            },
            {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedLevel5s,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            },
            {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSuppliers,
              options: suppliers,
              handler: option => onSupplierChange(option),
              isMulti: true,
            },
            {
              name: 'Brands',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrands,
              options: brands,
              handler: option => onBrandChange(option),
              isMulti: true,
            },
          ]}
        />
      </ReportSettingsContainer>
      <ReportContainer padding={3} column={12} layout="column">
        <div>{isLoading && <Loading />}</div>
        {!isLoading &&
                  categoryPenetrationData.table &&
                  categoryPenetrationData.table.length > 0 &&
                  categoryPenetrationData.graph &&
                  categoryPenetrationData.graph.length > 0 && (
          <Switch>
            <Route
              exact path="/retail/category-penetration"
              render={pageProps => (
                <div>
                  <CategoryPenetrationGraph
                    {...pageProps}
                    data={categoryPenetrationData.graph}
                    drillGraphResults={val => drillGraphResults(val)}
                  />
                  <StyledContainer column={12}>
                    <CategoryPenetrationTable
                      {...pageProps}
                      data={categoryPenetrationData}
                      isRollingDateRange={isRollingDateRange}
                      drillTableHeaders={val => drillTableHeaders(val)}
                    />
                  </StyledContainer>
                </div>
                    
              )}
            />
            <Route
              path="/retail/category-penetration/graph/:graphType"
              render={pageProps => (
               <DistributionGraph
                {...pageProps}
                xAxisLabel={tableDrillValue}
                data={distributionGraphData}
                isLoading={isLoading}
                />
              )}
            />
          </Switch>
        )}
      </ReportContainer>
      <ThemedModal
        isOpen={showModal}
        title={explanation.table.title}
        body={explanation.table.body}
        closeModal={() => setShowModal(false)}></ThemedModal>
    </motion.div>
  );
};

const mapStateToProps = state => ({
  timeframe: state.retail.timeframe,
  likeForLike: state.retail.likeForLike,
  departments: state.retail.departments,
  selectedDepartments: state.retail.selectedDepartments,
  categories: state.retail.categories,
  selectedCategories: state.retail.selectedCategories,
  subCategories: state.retail.subCategories,
  selectedSubCategories: state.retail.selectedSubCategories,
  members: state.retail.members,
  timeframes: state.retail.timeframes,
  likeForLikes: state.retail.likeForLikes,
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  suppliers: state.retail.suppliers,
  selectedSupplier: state.retail.selectedSuppliers,
  brands: state.retail.brands,
  selectedBrand: state.retail.selectedBrands,
  selectedLevel4s: state.retail.selectedLevel4s,
  level4s: state.retail.level4s,
  selectedLevel5s: state.retail.selectedLevel5s,
  level5s: state.retail.level5s,
  categoryPenetrationData: state.retail.categoryPenetrationData,
  distributionGraphData: state.retail.distributionGraphData,
  barChartDataSku:
    state.retail.categoryPenetrationData &&
    state.retail.categoryPenetrationData.chartData,
  isLoading: state.retail.isLoading,
  tableDrillValue: state.retail.tableDrillValue,
  isRefreshDisabled: state.retail.isRefreshDisabled,
  reportTypes: state.retail.penetrationReportTypes,
  reportType: state.retail.penetrationReportType,
  isRollingDateRange: state.retail.isRollingDateRange,
});

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferal, drillObj) =>
      dispatch(fetchCategories(type, isReferal, drillObj)),

    onTimeFrameChange: option => dispatch(setTimeFrame(option)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    unlockRefresh: () => {
      dispatch(setRefreshDisabledStatus(false));
    },
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onCategoryChange: selectedOptions =>
      dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: selectedOptions =>
      dispatch(setSuppliers(selectedOptions)),
    onBrandChange: selectedOptions => dispatch(setBrands(selectedOptions)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    loadCategoryPenetrationData: () => dispatch(loadCategoryPenetrationData()),
    setReportType: option => dispatch(setPenetrationReportType(option)),
    drillGraphResults: val => dispatch(drillGraphResults(val)),
    drillTableHeaders: (val) => {
      dispatch(drillTableHeaders(val));
    },
    onResetReport: () => {
      dispatch(setLikeForLike('like_for_like'));
      dispatch(resetReport());
      dispatch(
        fetchCategories('category_penetration', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryPenetration);
