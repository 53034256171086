import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import styled, { ThemeContext } from 'styled-components';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { formatToCurrency } from '../../../helpers/helpers';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Shelves from '../../../assets/images/planogram-view.jpg';
import { hex2rgba } from '../../../helpers/util';

// Value prop needs to match the dataset

const ImgHolder = styled.div`
  padding: 0 0.4rem;
  color: ${({ theme: { secondary } }) => secondary};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const htmlToReactParser = new HtmlToReactParser();

const StyledTitle = styled.h2`
  font-size: 1.125rem;
`;

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem 1rem 1rem 1rem;
  margin: 0 1rem;
  position: relative;
`;

const convertColorArrayToRGBA = array => {
  return array.map(color => hex2rgba(color));
};

const BarChart = ({
  data,
  id,
  title,
  value,
  drillGraphResults,
  tooltip,
  className,
}) => {
  const [chartInstance, setChartInstance] = useState(null);
  const theme = useContext(ThemeContext);
  const { primary, colorArray } = theme;
  const convertedColorArrayToRGBA = convertColorArrayToRGBA(colorArray);
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }
    if (chartRef.current) {
      const summaryDataValues =
        data &&
        data.map((item, idx) => ({
          data: [item[`${value}_Percentage`] * 100],
          label: item.Name,
          backgroundColor: [convertedColorArrayToRGBA[idx % 3]],
          borderColor: ['White'],
          borderWidth: 1,
          borderRadius: 5,
          hoverBorderColor: ['White'],
          itemName: item.Name,
          dataValue: item[value],
        }));

      // If the total percentage for the data is over 100 the graph breaks and is not full width

      const summaryChartData = {
        datasets: summaryDataValues,
        labels: [title],
      };

      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, {
        type: 'horizontalBar',
        data: summaryChartData,
        options: {
          onClick: function (evt, element) {
            if (element.length > 0) {
              const chart = element[0]._chart;
              const el = chart.getElementAtEvent(evt)[0];
              const dataset = chart.data.datasets[el._datasetIndex];
              const xLabel = chart.data.labels[el._index];
              const value = dataset.data[el._index];
              document.querySelector('#chartjs-tooltip').remove();
              drillGraphResults(dataset.label);
            }
          },
          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },

          legendCallback: function (chart) {
            var text = [];
            text.push(
              '<div style="color: #000; display: flex; margin: 3rem 0" class="' +
                chart.id +
                '-legend">',
            );
            for (var i = 0; i < chart.data.datasets.length; i++) {
              let hideSmallClass = '';
              const hideSmallerThan = 5;
              if (chart.data.datasets[i].data[0] < hideSmallerThan) {
                hideSmallClass = 'd-none';
              }
              text.push(
                '<div class="' +
                  '2' +
                  '" style="display: flex; justify-content: center; text-align: center; width:' +
                  chart.data.datasets[i].data[0] +
                  '%"><p class="label-chart ' +
                  hideSmallClass +
                  '">',
              );
              if (chart.data.datasets[i].label) {
                text.push(chart.data.datasets[i].label);
              }
              text.push('</p></div>');
            }
            text.push('</div>');
            return text.join('');
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title, i) {
                  innerHtml +=
                    '<tr style="display:flex; justify-content: space-between"><th>' +
                    title +
                    '</th><th>' +
                    parseFloat(tooltipModel.dataPoints[i].value).toFixed(2) +
                    '%</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();
              const labelColor = tooltipModel.labelColors[0].backgroundColor;
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.background = 'black';
              const total =
                position.left +
                window.pageXOffset +
                tooltipModel.caretX +
                tooltipModel.width;
              if (total > window.innerWidth) {
                tooltipEl.style.right = 10 + 'px';
                tooltipEl.style.left = 'initial';
              } else {
                tooltipEl.style.left =
                  position.left +
                  window.pageXOffset +
                  tooltipModel.caretX +
                  'px';
                tooltipEl.style.right = 'initial';
              }

              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: function (tooltipItem, data) {
                return `${data.datasets[tooltipItem.datasetIndex].label} : ${
                  id !== 'sku'
                    ? `${formatToCurrency(
                        data.datasets[tooltipItem.datasetIndex].dataValue,
                        0,
                      )}`
                    : data.datasets[tooltipItem.datasetIndex].dataValue
                }`;
              },
            },
            backgroundColor: '#fff',
            titleFontSize: 16,
            titleFontColor: '#0066ff',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: true,
            mode: 'dataset',
            position: 'nearest',
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: false,
                gridLines: {
                  display: false,
                },
                barPercentage: 1,
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: false,
                gridLines: {
                  drawBorder: false,
                },
                ticks: {
                  mirror: true,
                  fontColor: '#fff',
                  fontStyle: 'normal',
                },
              },
            ],
          },
        },
      });
      setChartInstance(myChart);

      myChart.generateLegend();
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard className={className}>
      <Container>
        <StyledTitle className="text-uppercase">{title}</StyledTitle>
        <ImgHolder>
          <Tooltip
            title={tooltip}
            position="bottom"
            trigger="mouseenter"
            theme="light">
            <HelpOutlineIcon style={{ cursor: 'pointer' }} />
          </Tooltip>
        </ImgHolder>
      </Container>
      <canvas
        ref={chartRef}
        id={`myChart-${id}`}
        width="600"
        height="250"
        style={{
          backgroundImage: `url(${Shelves})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      {chartRef &&
        chartRef.current &&
        chartInstance != null &&
        htmlToReactParser.parse(chartInstance.generateLegend())}
    </StyledCard>
  );
};

export default BarChart;
