import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { injectStore } from '../../helpers/axios';
import rootReducer from './reducers/index';

const defaultState = {
  currentUser: {},
  settings: {},
  alerts: [],
  systemSettings: {
    offscreenOpen: [],
    depotSelectType: 'filter',
    rehydrated: false,
  },
};

/* ACTIVATE REDUX DEV TOOLS */
//const enhancers = compose(autoRehydrate(), window.devToolsExtension ? window.devToolsExtension() : (f) => f);
const history = createBrowserHistory();

// const middleware = routerMiddleware(history);
const middleware = [thunkMiddleware, routerMiddleware(history)];
// const store = createStore(rootReducer, defaultState, enhancers, applyMiddleware(middleware));

// Enable tracing in redux dev tools

// const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//  trace: true,
//  traceLimit: 25,
//}); // eslint-disable-line

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // eslint-disable-line

const composeEnhancers = reduxDevTools || compose;
const store = createStore(
  rootReducer,
  defaultState,
  composeEnhancers(applyMiddleware(...middleware)),
);

persistStore(store, {
  blacklist: ['alerts'],
});

injectStore(store);

export default store;
export { history };
