import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dragula from 'react-dragula';
import 'dragula/dist/dragula.min.css';


class ColumnReorder extends Component {

	componentDidMount() {
		this.dragulaInit();
	}

	shouldComponentUpdate(nextProps) {
		return (nextProps.orderArray.length !== this.props.orderArray.length);
	}


	dragulaInit = ()=>{
		this.Drag = Dragula([this.reorderCanvas]).on('drop', this.reorderColumns);
	}


	reorderColumns = (el, target, source, nextEl)=>{
		const columnOrder = [...this.props.orderArray];
		const elSortName = $(el).data('label');
		const elIndex = columnOrder.findIndex(item=>item.sort_name === elSortName);
		/* if there no next element insert to the end of array */
		if (!nextEl) {
			const elItem = columnOrder.splice(elIndex, 1)[0];
			columnOrder.push(elItem);

		/* if there is insert before this element in an array */
		} else {
			const nextElSortName = $(nextEl).data('label');
			const elItem = columnOrder.splice(elIndex, 1)[0];
			const nextElIndex = columnOrder.findIndex(item=>item.sort_name === nextElSortName);
			columnOrder.splice(nextElIndex, 0, elItem);
		}
		this.props.onChange(columnOrder);
	}

	render() {
		return (
			<ul className="o-multiselect u-reset-list u-layout" ref={(el)=>{ this.reorderCanvas = el; }}>
				{this.props.children}
			</ul>
		);
	}
}

ColumnReorder.defaultProps = {
	children: {},
	onChange: Function.prototype,
};

ColumnReorder.propTypes = {
	orderArray: PropTypes.array.isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onChange: PropTypes.func,
};


export default ColumnReorder;
