import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

const StyledModal = styled(ReactModal)`
  ${props =>
    props.isSidebar && `border-right: 4px solid ${props.theme.primary}`}
  ${props =>
    !props.isSidebar && `border-top: 4px solid ${props.theme.primary}`};
  z-index: 2;
  & .btn.btn-info.alchemy-btn--unitas,
  & .btn.btn-info.alchemy-btn--others {
    background: ${props => props.theme.primary} !important;
    border-color: ${props => props.theme.primary} !important;
  }

  & .modal-title h2 {
    color: ${props => props.theme.fontColor};
  }

  & .c-select-header-row--unitas .Select-arrow-zone,
  & .c-select-header-row--others .Select-arrow-zone {
    background: ${props => props.theme.primary};
  }

  & .c-select-header-row--unitas .Select-arrow-zone,
  & .c-select-header-row--unitas .ui-select__indicators,
  & .c-select-header-row--others .Select-arrow-zone,
  & .c-select-header-row--others .ui-select__indicators {
    background: ${props => props.theme.primary};
  }

  p {
    color: ${props => props.theme.primary};
  }
`;

export default function ThemedModal({
  children,
  isOpen,
  isSidebar,
  overlayClassName,
  className,
  isModalAnimating,
  title,
  body,
  closeModal,
}) {
  const classes = [
    className || 'performance-modal',
    isSidebar && 'performance-modal-sidebar',
    isModalAnimating && 'modal-closing',
  ];

  return (
    <StyledModal
      closeTimeoutMS={500}
      appElement={document.querySelector('.alchemy_app')}
      isOpen={isOpen}
      overlayClassName={overlayClassName || 'performance-modal__overlay'}
      isSidebar={isSidebar}
      className={classes}>
      {body && title && (
        <>
          <div className="modal-title">
            <h2>{title}</h2>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => closeModal(false)}>
              ×
            </span>
          </div>
          <p>{body}</p>
        </>
      )}

      {children}
    </StyledModal>
  );
}
