import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';

export class DrillSetter {
  constructor(selectedAdvancedBusinessTypes, accountName, state, drillObj, value) {
    this.state = state;
    this.drillObj = drillObj;
    this.value = value;
    this.drillObj.measure = state.measure;
    this.drillObj.timeframe = state.timeframe;
    this.drillObj.likeForLike = state.likeForLike;
    this.drillObj.type = state.purchase;
    this.drillObj.dataType = state.dataType;
    this.drillObj.selectedAdvancedBusinessTypes = selectedAdvancedBusinessTypes;
    this.drillObj.accountName = accountName;
  }

  setDrillProperty = (prop, val) => {
    this.drillObj[prop] = val;
  };

  setDrillObj = () => {
    this.setDepartmentDrill();
    this.setCategoryDrill();
    this.setSubCategoryDrill();
    this.setLevel4Drill();
    this.setLevel5Drill();
    this.setMembersDrill();
    this.setSupplierDrill();
    this.setBrandDrill();
    this.setDepotsDrill();
    this.setGroupDrill();
    this.setIsOwnBrandDrill();
    this.setBusinessTypeDrill();
    this.setRefDrill();
  };

  setMembersDrill = () => {
    if (
      this.state.selectedMembers.length > 0 &&
      this.state.reportType !== 'member'
    ) {
      let mems = [];
      _.each(this.state.selectedMembers, membs => {
        mems.push(membs.ID);
      });
      this.drillObj.members = mems;
    } else if (this.state.reportType === 'member') {
      _.each(this.state.members, member => {
        if (member.Text === this.value || member.ActualName === this.value) {
          this.drillObj.members = [member];
          this.drillObj.skip = 'members';
        }
      });
    }
  };

  setBrandDrill = () => {
    if (
      this.state.brand.Brand !== undefined &&
      this.state.reportType !== 'brand'
    ) {
      this.drillObj.brand = this.state.brand.Brand;
    } else if (this.state.reportType === 'brand') {
      this.drillObj.brand = this.value;
      this.drillObj.skip = 'brand';
    }
  };

  setDepartmentDrill = () => {
    if (
      this.state.reportType === 'department' ||
      this.state.selectedDepartments.length > 0
    ) {
      if (this.state.reportType === 'department') {
        this.drillObj.departments = this.value;
        this.drillObj.skip = 'department';
      } else {
        this.drillObj.departments = map(
          filter(this.state.selectedDepartments, dept => dept.ID !== 0),
          'Text',
        ).join(',');
      }
    }
  };

  setCategoryDrill = () => {
    if (
      this.state.reportType === 'category' ||
      this.state.selectedCategories.length > 0
    ) {
      if (this.state.reportType === 'category') {
        this.drillObj.categories = this.value;
        this.drillObj.skip = 'category';
      } else {
        this.drillObj.categories = map(
          filter(this.state.selectedCategories, cat => cat.ID !== 0),
          'Text',
        ).join(',');
      }
    }
  };

  setSubCategoryDrill = () => {
    if (
      this.state.reportType === 'subcategory' ||
      this.state.selectedSubCategories.length > 0
    ) {
      if (this.state.reportType === 'subcategory') {
        this.drillObj.subCategories = this.value;
        this.drillObj.skip = 'subcategory';
      } else {
        this.drillObj.subCategories = map(
          filter(this.state.selectedSubCategories, subcat => subcat.ID !== 0),
          'Text',
        ).join(',');
      }
    }
  };

  setLevel4Drill = () => {
    if (
      this.state.reportType === 'level4' ||
      this.state.selectedLevel4s.length > 0
    ) {
      if (this.state.reportType === 'level4') {
        this.drillObj.level4s = this.value;
        this.drillObj.skip = 'level4';
      } else {
        this.drillObj.level4s = map(
          filter(this.state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
          'Text',
        ).join(',');
      }
    }
  };

  setLevel5Drill = () => {
    if (
      this.state.reportType === 'level5' ||
      this.state.selectedLevel5s.length > 0
    ) {
      if (this.state.reportType === 'level5') {
        this.drillObj.level5s = filter(this.state.level5s, {
          Text: this.value,
        })[0].ID.toString();
        this.drillObj.skip = 'level5';
      } else {
        this.drillObj.level5s = map(
          filter(this.state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
          'ID',
        ).join(',');
      }
    }
  };

  setBusinessTypeDrill = () => {
    if (
      this.state.reportType === 'business_type' ||
      this.state.selectedBusinessType.length > 0
    ) {
      if (this.state.reportType === 'business_type') {
        this.drillObj.businessType =
          this.state.businessTypes.filter(
            bus => bus.BusinessName === this.value,
          )[0].BusinessName || '';
        this.drillObj.skip = 'business_type';
      } else {
        this.drillObj.businessType = this.state.selectedBusinessType
          .map(bus => bus.BusinessName)
          .join(',');
      }
    }
  };

  setIsOwnBrandDrill = () => {
    if (this.state.ownBrand && this.state.ownBrand.Text) {
      this.drillObj.ownBrand = this.state.ownBrand;
    }
  };

  setRefDrill = () => {
    if (
      this.state.reportType === 'department' ||
      this.state.reportType === 'category' ||
      this.state.reportType === 'subcategory' ||
      this.state.reportType === 'level4' ||
      this.state.reportType === 'level5'
    ) {
      this.drillObj.ref =
        this.state.baseUrl + '/nam_dash/category_performance/';
    } else if (this.state.reportType === 'supplier') {
      this.drillObj.ref =
        this.state.baseUrl + '/nam_dash/supplier_performance/';
    } else if (this.state.reportType === 'member') {
      this.drillObj.ref =
        this.state.baseUrl + '/nam_dash/wholesaler_performance/';
    } else if (this.state.reportType === 'brand') {
      this.drillObj.ref = this.state.baseUrl + '/nam_dash/brand_performance/';
    } else if (this.state.reportType === 'depot') {
      this.drillObj.ref = this.state.baseUrl + '/nam_dash/depot_performance/';
    } else if (this.state.reportType === 'business_type') {
      this.drillObj.ref =
        this.state.baseUrl + '/nam_dash/business_type_performance/';
    }
  };

  setSupplierDrill = () => {
    if (
      this.state.supplier.SupplierName !== undefined &&
      this.state.reportType !== 'supplier'
    ) {
      this.drillObj.supplier = this.state.supplier.SupplierName;
    } else if (this.state.reportType === 'supplier') {
      this.drillObj.supplier = this.value;
      this.drillObj.skip = 'supplier';
    }
  };

  setDepotsDrill = () => {
    if (
      this.state.selectedDepots.length > 0 &&
      this.state.reportType !== 'depot'
    ) {
      let depts = [];
      _.each(this.state.selectedDepots, dept => {
        depts.push(dept.DepotName);
      });
      this.drillObj.depots = depts;
    } else if (this.state.reportType === 'depot') {
      _.each(this.state.depots, dept => {
        if (dept.DepotName === this.value) {
          this.drillObj.depots = dept.DepotName;
          this.drillObj.skip = 'depot';
        }
      });
    }
  };

  setGroupDrill = () => {
    if (this.state?.selectedGroup?.length > 0) {
      this.drillObj.groups = this.state.selectedGroup;
    }
  };
}
