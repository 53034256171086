import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

class TableBarcodeComplianceReport extends React.Component {
  renderHeader = head => {
    return (
      <tr>
        {this.sortTableHeaders(Object.keys(head)).map((key, i) => {
          return (
            <th
              style={{
                width: '120px',
                backgroundColor: this.props.theme.primaryDark,
                color: '#fff',
                paddingLeft: '10px',
              }}
              key={i}
            >
              <span className="w-120 d-block">{key.replace('_', ' ')}</span>
            </th>
          );
        })}
      </tr>
    );
  };

  renderRow = (isBold, row, i) => {
    return (
      <tr key={i}>
        {this.sortTableRows(row).map((key, k) => {
          if (isBold) {
            return (
              <td key={k} className="w-120">
                <span className="w-120 d-block">
                  <b>{row[key]}</b>
                </span>
              </td>
            );
          } else {
            return (
              <td key={k} className="w-120">
                <span className="w-120 d-block">{row[key]}</span>
              </td>
            );
          }
        })}
      </tr>
    );
  };

  sortTableHeaders = arr => {
    const departments = arr.shift();
    return [departments, ...arr.sort()];
  };

  sortTableRows = obj => {
    const sortedObj = {};
    sortedObj['Department'] = '';
    Object.keys(obj)
      .slice(1)
      .sort()
      .forEach(key => {
        sortedObj[key] = obj[key];
      });
    return Object.keys(sortedObj);
  };

  render() {
    const tableClass = ['o-table o-product-performance o-product-performance--product-performance table-striped u-text-center '];

    return (
      <div className="c-objects-wrap--table" style={{ position: 'relative', overflow: 'scroll' }}>
        <table className={tableClass.join(' ')} id={this.props.id}>
          <thead>{this.props.data[0] && this.renderHeader(this.props.data[0])}</thead>
          <tbody className="table-fixed-body" style={{ display: 'table-row-group' }}>
            {this.props.data.map((r, i) => {
              return this.renderRow(this.props.data?.length - 1 === i ? true : false, r, i);
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

TableBarcodeComplianceReport.defaultProps = {
  data: [],
  isLoading: false,
};

TableBarcodeComplianceReport.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default withTheme(TableBarcodeComplianceReport);
