import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { supplierEnagagementSubCategoryOpportunity as explanation } from '../../Explainer/Explanations';
import { coloredTableTheme } from '../../../../config/tableStyles';
import { formatToCurrency } from '../../../../helpers/helpers';
import StyledContainer from './StyledContainer';

const StyledTitle = styled.h2`
  &&& {
    font-size: 1.125rem !important;
    padding: 1rem;
  }
`;

const { tableHeaders } = explanation;

const SubCategoryOpportunityTable = ({ data, title }) => {
  const columns = [
    {
      selector: 'SubCategory',
      name: <TableHeaderTooltip text="Category" />,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'SupplierShareTY',
      name: (
        <TableHeaderTooltip
          text="Supplier Share TY"
          explanationTooltip={tableHeaders.supplierShareTY}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.SupplierShareTY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.SupplierShareTY - rowB.SupplierShareTY,
    },
    /*  {
      selector: 'SupplierShareLY',
      name: (
        <TableHeaderTooltip
          text="Supplier Share LY"
          explanationTooltip={tableHeaders.supplierShareLY}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.SupplierShareLY).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.SupplierShareLY - rowB.SupplierShareLY,
    },*/
    {
      selector: 'ShareChange',
      name: (
        <TableHeaderTooltip
          text="Share Change PerPoints"
          explanationTooltip={tableHeaders.supplierPointChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ShareChange < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {parseFloat(row.ShareChange).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.ShareChange - rowB.ShareChange,
    },
    {
      selector: 'OpportunityOfShare',
      name: (
        <TableHeaderTooltip
          text="Fair Share Opportunity"
          explanationTooltip={tableHeaders.opportunityOfShare}
        />
      ),
      cell: row => (
        <div
          className={row.TY < 0 ? 'negative-table-cell' : 'positive-table-cell'}
          dangerouslySetInnerHTML={{
            __html: row.OpportunityOfShare,
          }}></div>
      ),

      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'GorwthOpportunity',
      name: (
        <TableHeaderTooltip
          text="Growth Opportunity"
          explanationTooltip={tableHeaders.growthOpportunity}
        />
      ),
      cell: row => (
        <div
          className={row.TY < 0 ? 'negative-table-cell' : 'positive-table-cell'}
          dangerouslySetInnerHTML={{
            __html: row.GorwthOpportunity,
          }}></div>
      ),

      sortable: true,
      grow: 2,
      wrap: true,
    },
  ];
  return (
    <StyledContainer>
      <StyledTitle className="text-uppercase">{title}</StyledTitle>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'Index'}
        defaultSortAsc={true}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default SubCategoryOpportunityTable;
