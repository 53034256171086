import React from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import TableHeaderTooltip from '../../Explainer/TableHeaderTooltip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { supplierEnagagementBrandDriversOfPerformance as explanation } from '../../Explainer/Explanations';
import { coloredTableTheme } from '../../../../config/tableStyles';
import { formatToCurrency } from '../../../../helpers/helpers';
import StyledContainer from './StyledContainer';

const StyledTitle = styled.h2`
  &&& {
    font-size: 1.125rem !important;
    padding: 1rem;
  }
`;

const { tableHeaders } = explanation;

const BrandDriversTable = ({ data, title }) => {
  const columns = [
    {
      selector: 'WVBrand',
      name: <TableHeaderTooltip text="Brand" />,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      selector: 'CustomerPen',
      name: (
        <TableHeaderTooltip
          text="Customer Penetration"
          explanationTooltip={tableHeaders.customerPenetration}
        />
      ),
      sortable: true,

      format: row => `${parseFloat(row.CustomerPen).toFixed(2)}%`,
      sortFunction: (rowA, rowB) => rowA.CustomerPen - rowB.CustomerPen,
    },
    {
      selector: 'ChangeInCustomers',
      name: (
        <TableHeaderTooltip
          text="Percentage Point Difference"
          explanationTooltip={tableHeaders.changeOfCustomers}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ChangeInCustomers < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {parseFloat(row.ChangeInCustomers).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) =>
        rowA.ChangeInCustomers - rowB.ChangeInCustomers,
    },
    {
      selector: 'ROS',
      name: (
        <TableHeaderTooltip
          text="Rate Of Sale"
          explanationTooltip={tableHeaders.rateofSale}
        />
      ),
      sortable: true,
      cell: row => <div>{parseFloat(row.ROS).toFixed(2)}</div>,
      sortFunction: (rowA, rowB) => rowA.ROS - rowB.ROS,
    },
    {
      selector: 'ChangeInROS',
      name: (
        <TableHeaderTooltip
          text="Change In ROS"
          explanationTooltip={tableHeaders.changeInRateOfSale}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ChangeInROS < 0 ? 'negative-table-cell' : 'positive-table-cell'
          }>
          {parseFloat(row.ChangeInROS).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) => rowA.ChangeInROS - rowB.ChangeInROS,
    },
    {
      selector: 'AveragePrice',
      name: (
        <TableHeaderTooltip
          text="Avg Price"
          explanationTooltip={tableHeaders.avPrice}
        />
      ),
      sortable: true,

      format: row => `${formatToCurrency(row.AveragePrice)}`,
      sortFunction: (rowA, rowB) => rowA.AveragePrice - rowB.AveragePrice,
    },
    {
      selector: 'AveragePriceChangePer',
      name: (
        <TableHeaderTooltip
          text="Average Price Change %"
          explanationTooltip={tableHeaders.avPriceChange}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.AveragePriceChangePer < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {parseFloat(row.AveragePriceChangePer).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) =>
        rowA.AveragePriceChangePer - rowB.AveragePriceChangePer,
    },
    {
      selector: 'ChangeInSkuCount',
      name: (
        <TableHeaderTooltip
          text="Change In Sku Count"
          explanationTooltip={tableHeaders.changeInSkuCount}
        />
      ),
      sortable: true,
      cell: row => (
        <div
          className={
            row.ChangeInSkuCount < 0
              ? 'negative-table-cell'
              : 'positive-table-cell'
          }>
          {parseFloat(row.ChangeInSkuCount).toFixed(2)}%
        </div>
      ),
      sortFunction: (rowA, rowB) =>
        rowA.ChangeInSkuCount - rowB.ChangeInSkuCount,
    },
  ];
  return (
    <StyledContainer>
      <StyledTitle className="text-uppercase">{title}</StyledTitle>
      <DataTable
        data={data}
        columns={columns}
        customStyles={coloredTableTheme}
        striped
        defaultSortField={'CustomerPen'}
        defaultSortAsc={false}
        highlightOnHover
        fixedHeader
        noHeader
        fixedHeaderScrollHeight={'75vh'}
        sortIcon={<ArrowDownwardIcon style={{ fill: '#fff' }} />}
      />
    </StyledContainer>
  );
};

export default BrandDriversTable;
