import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { pageVariants } from '../../config/pageVariants';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const MessageContainer = styled.div`
  position: relative;
  text-align: center;
  top: 50px;
`;

const LoadingMessage = () => {
  const loadingMessages = [
    '',
    'This may take a while....',
    '',
    'Not long....',
    '',
    'Almost there....',
  ];
  const [messageIndex, setMessageIndex] = useState(0);
  useEffect(() => {
    let timeout = setInterval(() => {
      setMessageIndex(messageIndex =>
        messageIndex === loadingMessages.length ? 0 : messageIndex + 1,
      );
    }, 15000);
    return () => {
      clearInterval(timeout);
    };
  }, []);
  return (
    <MessageContainer>
      <FadingText text={loadingMessages[messageIndex]} />
    </MessageContainer>
  );
};

export const FadingText = ({ text }) => (
  <AnimatePresence exitBeforeEnter>
    <motion.p
      key={text}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      {text}
    </motion.p>
  </AnimatePresence>
);

const Loading = ({ theme, withMessages, classList }) => {
  if (theme.name === 'unitas' || theme.name === 'sugro') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <defs></defs>
          <title>loading icons</title>
          <path
            className="cls-1"
            d="M100.51,70.59a2,2,0,0,1-2-2v-26a2,2,0,0,1,4,0v26A2,2,0,0,1,100.51,70.59Z"
          />
          <path
            className="cls-1"
            d="M84.88,74.24a2,2,0,0,1-1.74-1l-13-22.52a2,2,0,0,1,3.47-2l13,22.52A2,2,0,0,1,85.88,74,2,2,0,0,1,84.88,74.24Z"
          />
          <path
            className="cls-2"
            d="M73.16,85.22a2,2,0,0,1-1-.27L49.65,72a2,2,0,1,1,2-3.46l22.51,13a2,2,0,0,1-1,3.73Z"
          />
          <path
            className="cls-3"
            d="M68.51,100.59h-26a2,2,0,1,1,0-4h26a2,2,0,1,1,0,4Z"
          />
          <path
            className="cls-4"
            d="M49.65,129.22a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.46L50.65,129A2,2,0,0,1,49.65,129.22Z"
          />
          <path
            className="cls-5"
            d="M70.14,150.45a2,2,0,0,1-1-.27,2,2,0,0,1-.73-2.73l13-22.52a2,2,0,0,1,3.47,2l-13,22.52A2,2,0,0,1,70.14,150.45Z"
          />
          <path
            className="cls-6"
            d="M98.51,158.59a2,2,0,0,1-2-2v-26a2,2,0,0,1,4,0v26A2,2,0,0,1,98.51,158.59Z"
          />
          <path
            className="cls-7"
            d="M127.15,151.45a2,2,0,0,1-1.74-1l-13-22.52a2,2,0,0,1,3.47-2l13,22.52a2,2,0,0,1-.74,2.73A2,2,0,0,1,127.15,151.45Z"
          />
          <path
            className="cls-8"
            d="M148.37,131a1.93,1.93,0,0,1-1-.27l-22.51-13a2,2,0,0,1,2-3.46l22.51,13a2,2,0,0,1-1,3.73Z"
          />
          <path
            className="cls-9"
            d="M156.51,102.59h-26a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
          />
          <path
            className="cls-10"
            d="M126.86,87a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.46l-22.51,13A1.93,1.93,0,0,1,126.86,87Z"
          />
          <path
            className="cls-1"
            d="M115.87,75.24a2,2,0,0,1-1.73-3l13-22.52a2,2,0,0,1,3.47,2l-13,22.52A2,2,0,0,1,115.87,75.24Z"
          />
        </svg>
        {withMessages && <LoadingMessage />}
      </LoaderContainer>
    );
  } else if (theme.name === 'confex') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#0055a6"
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#0055a6"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#0055a6"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#0055a6"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#0055a6"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#0055a6"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#0055a6"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#0055a6"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#0055a6"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#0055a6"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#0055a6"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#0055a6"
          />
        </svg>
      </LoaderContainer>
    );
  } else if (theme.name === 'caterforce') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#00005a"
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#00005a"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#00005a"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#00005a"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#00005a"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#00005a"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#00005a"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#00005a"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#00005a"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#00005a"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#00005a"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#00005a"
          />
        </svg>
      </LoaderContainer>
    );
  } else if (theme.name === 'cjlang' || theme.name === 'cjlangRetail') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#005490"
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#005490"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#005490"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#005490"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#005490"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#005490"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#005490"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#005490"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#005490"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#005490"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#005490"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#005490"
          />
        </svg>
      </LoaderContainer>
    );
  } else if (theme.name === 'countryRangeGroup') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#ff7753 "
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#ff7753"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#ff7753"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#ff7753"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#ff7753"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#ff7753"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#ff7753"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#ff7753"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#ff7753"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#ff7753"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#ff7753"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#ff7753"
          />
        </svg>
      </LoaderContainer>
    );
  } else if (theme.name === 'united') {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#005597 "
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#005597"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#005597"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#005597"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#005597"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#005597"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#005597"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#005597"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#005597"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#005597"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#005597"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#005597"
          />
        </svg>
      </LoaderContainer>
    );
  } else {
    return (
      <LoaderContainer className={`${classList}`}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="40 40 120 120"
          width="100px"
          height="100px">
          <path
            d="M101,71a2,2,0,0,1-2-2V43a2,2,0,0,1,4,0V69A2,2,0,0,1,101,71Z"
            fill="#005490"
          />
          <path
            d="M85.37,74.65a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,1,1,3.47-2l13,22.51a2,2,0,0,1-1.73,3Z"
            fill="#005490"
          />
          <path
            d="M73.65,85.63a2,2,0,0,1-1-.26l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.73Z"
            fill="#005490"
            opacity="0.9"
          />
          <path
            d="M69,101H43a2,2,0,0,1,0-4H69a2,2,0,0,1,0,4Z"
            fill="#005490"
            opacity="0.8"
          />
          <path
            d="M50.14,129.63a2,2,0,0,1-1-3.73l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,50.14,129.63Z"
            fill="#005490"
            opacity="0.7"
          />
          <path
            d="M70.63,150.86a2,2,0,0,1-1.73-3l13-22.51a2,2,0,0,1,3.47,2l-13,22.51A2,2,0,0,1,70.63,150.86Z"
            fill="#005490"
            opacity="0.6"
          />
          <path
            d="M99,159a2,2,0,0,1-2-2V131a2,2,0,0,1,4,0v26A2,2,0,0,1,99,159Z"
            fill="#005490"
            opacity="0.5"
          />
          <path
            d="M127.64,151.86a2,2,0,0,1-1.74-1l-13-22.51a2,2,0,0,1,3.47-2l13,22.51a2,2,0,0,1-.74,2.74A2,2,0,0,1,127.64,151.86Z"
            fill="#005490"
            opacity="0.4"
          />
          <path
            d="M148.86,131.37a2,2,0,0,1-1-.27l-22.51-13a2,2,0,1,1,2-3.47l22.51,13a2,2,0,0,1-1,3.74Z"
            fill="#005490"
            opacity="0.3"
          />
          <path
            d="M157,103H131a2,2,0,0,1,0-4h26a2,2,0,0,1,0,4Z"
            fill="#005490"
            opacity="0.2"
          />
          <path
            d="M127.35,87.37a2,2,0,0,1-1-3.74l22.51-13a2,2,0,1,1,2,3.47l-22.51,13A2,2,0,0,1,127.35,87.37Z"
            fill="#005490"
            opacity="0.1"
          />
          <path
            d="M116.36,75.65a2,2,0,0,1-1-.26,2,2,0,0,1-.74-2.74l13-22.51a2,2,0,1,1,3.47,2l-13,22.51A2,2,0,0,1,116.36,75.65Z"
            fill="#005490"
          />
        </svg>
      </LoaderContainer>
    );
  }
};

export default withTheme(Loading);
