import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getUserSystem } from '../../../helpers/util';

export default function StepCrumbs(props) {
  let steps = [];
  if (props.system === 'todays') {
    steps = [
      { value: 1, label: 'User' },
      { value: 2, label: 'Account' },
      { value: 3, label: 'Review' },
    ];
  } else {
    steps = [
      { value: 1, label: 'User' },
      { value: 2, label: 'Account' },
      { value: 3, label: 'Permissions' },
      { value: 4, label: 'Review' },
    ];
  }
  const activeClassName = 'step-active--unitas';
  return (
    <div className="query-builder query-builder__steps">
      {steps.map((step, i) => {
        const stepClass = [''];
        let URL = `${
          process.env.APP_ROOT
        }create_user/${step.label.toLowerCase()}`;
        if (props.id && props.id.length > 0) {
          URL += `/${props.id}`;
        }
        if (step.value < props.step.value) {
          stepClass.push(activeClassName);
        }

        return (
          <NavLink
            to={URL}
            key={`stepcrumb-${i}`}
            activeClassName={activeClassName}
            className={` ${i} btn btn-info text-uppercase not-active ${stepClass.join(
              ' ',
            )}`}>
            {step.label}
          </NavLink>
        );
      })}
    </div>
  );
}

StepCrumbs.defaultProps = {
  step: { value: 1, label: 'user' },
};

StepCrumbs.propTypes = {
  step: PropTypes.any,
  id: PropTypes.string,
  system: PropTypes.string,
};
