import { handleActions, createAction } from 'redux-actions';
import { request, customRequest } from '../../../helpers/axios';
import { encodeText, getToken, getUserId, getUserSystem } from '../../../helpers/util';
import { each, isArray } from 'lodash';
import { createSelector } from 'reselect';
import store from '../store';

const SET_TITLE = 'complianceReport/SET_TITLE';
const SET_LOADING = 'complianceReport/SET_LOADING';
const FETCH_CUSTOMER_COMPLIANCE_REPORT = 'complianceReport/FETCH_CUSTOMER_COMPLIANCE_REPORT';
const FETCH_BARCODE_COMPLIANCE_REPORT = 'complianceReport/FETCH_BARCODE_COMPLIANCE_REPORT';

const initialState = {
  title: '',
  customerComplianceReport: [],
  barcodeComplianceReport: [],
};

const fetchCustomerComplianceReportAction = createAction(FETCH_CUSTOMER_COMPLIANCE_REPORT);
const fetchBarcodeComplianceReportAction = createAction(FETCH_BARCODE_COMPLIANCE_REPORT);
const setLoadingAction = createAction(SET_LOADING);

export const fetchCustomerComplianceReport = () => dispatch => {
  let url = '/route.php?c=complianceReport/getCustomerComplianceReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(data => {
    dispatch(fetchCustomerComplianceReportAction(data));
    dispatch(setLoadingAction(false));
  });
};

export const fetchBarcodeComplianceReport = () => dispatch => {
  let url = '/route.php?c=complianceReport/getBarcodeComplianceReport';
  dispatch(setLoadingAction(true));
  request({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(data => {
    dispatch(fetchBarcodeComplianceReportAction(data));
    dispatch(setLoadingAction(false));
  });
};

export default handleActions(
  {
    [FETCH_CUSTOMER_COMPLIANCE_REPORT]: (state, { payload }) => ({ ...state, customerComplianceReport: payload }),
    [FETCH_BARCODE_COMPLIANCE_REPORT]: (state, { payload }) => ({ ...state, barcodeComplianceReport: payload }),
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
  },
  initialState,
);

export const selectors = {};
