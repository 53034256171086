import React from 'react';
import Select from 'react-select';
import CustomCountSelect from '../CustomCountSelect';
import { motion } from 'framer-motion';
import { validateId, capitalizeAllWords } from '../../../helpers/helpers';
import { Button, Col, Row } from 'reactstrap';

// Array of objects for each dropdown, including Value, Options and OnChange, isMulti, name, id

// ADD IN CONDITION PROP

const DataFiltersContainer = ({
  sections,
  refresh,
  reset,
  setIsOpen,
  addFilterGroup,
}) => {
  return (
    <div className="container pb-3 sub-menu-bottom">
      <div className="row">
        {sections.map(({ title, dropdowns }, i) => {
          return (
            <div className="col-12" key={i}>
              <h5>{title}</h5>
              <div className="nam-dash nam-dash-select-row">
                <div className="form-row">
                  {dropdowns &&
                    dropdowns.map(
                      (
                        {
                          value,
                          options,
                          handler,
                          valueKey,
                          labelKey,
                          name,
                          isMulti,
                        },
                        i,
                      ) => {
                        return !isMulti
                          ? options && options.length > 0 && (
                              <motion.div
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ duration: 0.3 }}
                                key={`dropdown-${i}`}
                                className="form-group col-md-5 col-lg-3">
                                <Select
                                  className={`c-select-header c-select-header-row--others`}
                                  name={name}
                                  inputProps={{
                                    autoComplete: 'off',
                                    autoCorrect: 'off',
                                    spellCheck: 'off',
                                  }}
                                  id={name}
                                  placeholder={capitalizeAllWords(name)}
                                  value={value}
                                  onChange={selectedOptions =>
                                    handler(selectedOptions)
                                  }
                                  options={options}
                                  valueKey={valueKey}
                                  labelKey={labelKey}
                                  clearable={true}
                                  searchable={true}
                                  isMulti={false}
                                />
                              </motion.div>
                            )
                          : options && options.length > 0 && (
                              <motion.div
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{ duration: 0.3 }}
                                key={`dropdown-${i}`}
                                className="form-group  col-md-5 col-lg-3">
                                {options && options.length > 0 && (
                                  <CustomCountSelect
                                    className={`Select c-select-header c-select-header-row--others`}
                                    onChangeCallback={ev => handler(ev)}
                                    name={name}
                                    id={name}
                                    isMulti={true}
                                    value={value}
                                    options={options}
                                    type={name}
                                    types={name}
                                    placeholder={capitalizeAllWords(name)}
                                  />
                                )}
                              </motion.div>
                            );
                      },
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Row>
        <Col>
          <Button
            className="text-uppercase mr-3"
            color="primary"
            onClick={() => {
              setIsOpen(false);
              refresh();
            }}>
            Apply Filters
          </Button>
          {/* {addFilterGroup && (
            <Button
              className="text-uppercase mr-3"
              color="primary"
              onClick={() => {
                addFilterGroup();
              }}>
              Create Filter Group
            </Button>
          )} */}
          <Button
            className="text-uppercase alchemy-btn--others"
            onClick={() => {
              setIsOpen(false);
              reset();
            }}>
            Reset & Close Filters
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DataFiltersContainer;
