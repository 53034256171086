import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { format4WeekData } from '../../../helpers/formatters';
import Explainer from '../Explainer/Explainer';
import VerticalBarChart from '../VerticalBarChart';
import { StyledCard } from '../Styled';

const YOY4Week = ({ data, ...props }) => {
  const [chartData, setChartData] = useState({});
  useEffect(() => {
    const formattedData = data && format4WeekData(data);
    setChartData(formattedData);
  }, []);
  return (
    <StyledCard>
      <Explainer explainerText="YOY 4 Weekly" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}>
        <VerticalBarChart isLoading={false} data={chartData} isSingleYAxes />
      </motion.div>
    </StyledCard>
  );
};

export default YOY4Week;
