'use strict';
import { handleActions, createAction } from 'redux-actions';
import { request, customRequest, requestGet } from '../../../helpers/axios';
import {
  encodeText,
  addDrillInfo,
  removeDrillInfo,
  getUserSystem,
} from '../../../helpers/util';
import { dropdownSelectionHelper } from '../../../helpers/dropdownSelectionHelper';
import { Drill } from '../models/Drill';
import store from '../store';
import { each, map, flatten, filter, orderBy, uniqBy } from 'lodash';
import { push } from 'react-router-redux';

import {
  formatUrlWithParameters,
  fetchSuppliers,
  fetchBrands,
} from '../../../helpers/helpers';

const FETCH_DATA = 'supplierEngagement/FETCH_DATA';

const FETCH_PRODUCTS = 'supplierEngagement/FETCH_PRODUCTS';
const SET_REPORT_DEPARTMENTS = 'supplierEngagement/SET_DEPARTMENTS';
const SET_REPORT_DEPARTMENT = 'supplierEngagement/SET_DEPARTMENT';
const SET_REPORT_CATEGORIES = 'supplierEngagement/SET_CATEGORIES';
const SET_REPORT_CATEGORY = 'supplierEngagement/SET_CATEGORY';
const SET_SUBCATEGORIES = 'supplierEngagement/SET_SUBCATEGORIES';
const SET_SUBCATEGORY = 'supplierEngagement/SET_SUBCATEGORY';
const SET_LEVEL4S = 'supplierEngagement/SET_LEVEL4S';
const SET_LEVEL5S = 'supplierEngagement/SET_LEVEL5S';
const SET_LEVEL4 = 'supplierEngagement/SET_LEVEL4';
const SET_LEVEL5 = 'supplierEngagement/SET_LEVEL5';
const SET_ACCOUNT_NAME = 'report/SET_ACCOUNT_NAME';
const SET_MEASURE = 'supplierEngagement/SET_MEASURE';
const SET_LIKE_FOR_LIKE = 'supplierEngagement/SET_LIKE_FOR_LIKE';
const SET_PURCHASE = 'supplierEngagement/SET_PURCHASE';
const SET_TIMEFRAME = 'supplierEngagement/SET_TIMEFRAME';
const SET_BRAND = 'supplierEngagement/SET_BRAND';
const SET_TITLE = 'supplierEngagement/SET_TITLE';
const SET_SUBPAGE = 'supplierEngagement/SET_SUBPAGE';
const SET_SELECTED_MEMBERS = 'supplierEngagement/SET_SELECTED_MEMBERS';
const SET_SUPPLIER = 'supplierEngagement/SET_SUPPLIER';
const SET_SUPPLIERS = 'supplierEngagement/SET_SUPPLIERS';
const SET_BRANDS = 'supplierEngagement/SET_BRANDS';
const SET_SELECTED_BRANDS = 'supplierEngagement/SET_SELECTED_BRANDS';
const SET_SELECTED_SUPPLIERS = 'supplierEngagement/SET_SELECTED_SUPPLIERS';
const SET_SELECTED_PRODUCT = 'supplierEngagement/SET_SELECTED_PRODUCT';
const SET_DEPOT = 'supplierEngagement/SET_DEPOT';
const SET_REPORT_SUPPLIER = 'supplierEngagement/SET_REPORT_SUPPLIER';
const SET_REPORT_MEMBER = 'supplierEngagement/SET_REPORT_MEMBER';
const SET_LOADING = 'supplierEngagement/SET_LOADING';
const SET_SUPPLIERS_LOADING = 'supplierEngagement/SET_SUPPLIERS_LOADING';
const SET_EXPORTING = 'supplierEngagement/SET_EXPORTING';
const SET_LOADED = 'supplierEngagement/SET_LOADED';
const SET_GRAPH_LOADED = 'supplierEngagement/SET_GRAPH_LOADED';
const SET_TABLE_LOADED = 'supplierEngagement/SET_TABLE_LOADED';
const SET_HIDE_ALL = 'supplierEngagement/SET_HIDE_ALL';
const EXPORT_TO_PDF_SUCCESS = 'supplierEngagement/EXPORT_TO_PDF_SUCCESS';
const RESET = 'supplierEngagement/RESET';
const SET_BUSINESS_TYPE = 'supplierEngagement/SET_BUSINESS_TYPE';
const SET_CURRENT_REPORT_PAGE = 'supplierEngagement/SET_CURRENT_REPORT_PAGE';
const SET_REPORT_ERROR = 'supplierEngagement/SET_REPORT_ERROR';
const SET_DEPOTS = 'supplierEngagement/SET_DEPOTS';
const ADD_MEASURE = 'supplierEngagement/ADD_MEASURE';
const RESET_MEASURE_OPTIONS = 'supplierEngagement/RESET_MEASURE_OPTIONS';
const SET_REPORT_TYPE = 'supplierEngagement/SET_REPORT_TYPE';
const SET_IS_REFRESH_DISABLED = 'supplierEngagement/SET_IS_REFRESH_DISABLED';
const FETCH_SUPPLIER_ENGAGMENT_DATA =
  'supplierEngagement/FETCH_SUPPLIER_ENGAGMENT_DATA';

const initialState = {
  // baseUrl: '/react',
  baseUrl: '',
  departments: [],
  selectedDepartments: [],
  selectedDepartment: '',
  categories: [],
  selectedCategories: [],
  selectedCategory: '',
  subCategories: [],
  selectedSubCategories: [],
  selectedSubCategory: '',
  accountName: [],
  accountNames: [],
  members: [],
  brands: [],
  brand: '',
  selectedBrands: [],
  selectedMembers: [],
  suppliers: [],
  supplier: '',
  selectedSuppliers: [],
  depots: [],
  selectedDepots: [],
  level4s: [],
  selectedLevel4s: [],
  selectedLevel4: '',
  level5s: [],
  selectedLevel5s: [],
  selectedLevel5: '',
  products: [],
  selectedProduct: '',
  selectedProducts: [],
  timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
    { ID: '4wks', Text: '4 Wk' },
    { ID: '13wks', Text: '13 Wk' },
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
    { ID: 'YTD', Text: 'YTD' },
    { ID: 'MAT', Text: 'MAT' },
    ...(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew'
    ? [
        { ID: 'FYTD', Text: 'FYTD'},
      ]
    : []),
  ],
  timeframe: '',
  purchases: [
    { ID: 'in', Text: 'SALES IN' },
    { ID: 'out', Text: 'SALES OUT' },
  ],
  measures: [
    { ID: 'Value', Text: 'Val' },
    { ID: 'Qty', Text: 'Qty' },
    { ID: 'customer_count', Text: 'Customer Count'},
    { ID: 'profit', Text: 'Profit'},
    { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
  ],
  likeForLikes: [
    { ID: 'like_for_like', Text: 'Like For Like' },
    { ID: 'absolute', Text: 'Absolute' },
  ],
  measure: '',
  likeForLike: '',
  purchase: 'out',
  title: '',
  subPage: '',
  isLoading: false,
  areSuppliersLoading: false,
  isGraphLoaded: false,
  isTableLoaded: false,
  isExporting: false,
  isExported: false,
  reportType: '',
  currentReportPage: '',
  isLoaded: false,
  hideAll: false,
  pdfURL: '',
  businessTypes: [],
  selectedBusinessType: '',
  reportError: '',
  isInitialLoadComplete: false,
  reportType: '',
  isRefreshDisabled: false,
  supplierEngagementData: {},
};

const fetchDataAction = createAction(FETCH_DATA);
const fetchProductsAction = createAction(FETCH_PRODUCTS);
const setReportDepartmentsAction = createAction(SET_REPORT_DEPARTMENTS);
const setReportDepartmentAction = createAction(SET_REPORT_DEPARTMENT);
const setReportCategoriesAction = createAction(SET_REPORT_CATEGORIES);
const setReportCategoryAction = createAction(SET_REPORT_CATEGORY);
const setMeasureAction = createAction(SET_MEASURE);
const setLikeForLikeAction = createAction(SET_LIKE_FOR_LIKE);
const setPurchaseAction = createAction(SET_PURCHASE);
const setTimeFrameAction = createAction(SET_TIMEFRAME);
const setCurrentReportPageAction = createAction(SET_CURRENT_REPORT_PAGE);
const setSubCategoriesAction = createAction(SET_SUBCATEGORIES);
const setSubCategoryAction = createAction(SET_SUBCATEGORY);
const setLevel4sAction = createAction(SET_LEVEL4S);
const setLevel5sAction = createAction(SET_LEVEL5S);
const setLevel4Action = createAction(SET_LEVEL4);
const setLevel5Action = createAction(SET_LEVEL5);
const setAccountNameAction = createAction(SET_ACCOUNT_NAME);
const setSelectedMembersAction = createAction(SET_SELECTED_MEMBERS);
const setBrandAction = createAction(SET_BRAND);
const setSupplierAction = createAction(SET_SUPPLIER);
const setSuppliersAction = createAction(SET_SUPPLIERS);
const setBrandsAction = createAction(SET_BRANDS);
const setSelectedSuppliersAction = createAction(SET_SELECTED_SUPPLIERS);
const setSelectedBrandsAction = createAction(SET_SELECTED_BRANDS);
const setSelectedProductAction = createAction(SET_SELECTED_PRODUCT);
const setDepotAction = createAction(SET_DEPOT);
const setTitleAction = createAction(SET_TITLE);
const setSubPageAction = createAction(SET_SUBPAGE);
const setReportSupplierAction = createAction(SET_REPORT_SUPPLIER);
const setReportMemberAction = createAction(SET_REPORT_MEMBER);
const setHideAllAction = createAction(SET_HIDE_ALL);
const setLoadingAction = createAction(SET_LOADING);
const setSuppliersLoadingAction = createAction(SET_SUPPLIERS_LOADING);
const setGraphLoadedAction = createAction(SET_GRAPH_LOADED);
const setTableLoadedAction = createAction(SET_TABLE_LOADED);
const setLoadedAction = createAction(SET_LOADED);
const setExportingAction = createAction(SET_EXPORTING);
const exportToPdfSuccessAction = createAction(EXPORT_TO_PDF_SUCCESS);
const resetAction = createAction(RESET);
const setBusinessTypeAction = createAction(SET_BUSINESS_TYPE);
const setReportErrorAction = createAction(SET_REPORT_ERROR);
const setDepotsAction = createAction(SET_DEPOTS);
const addMeasureAction = createAction(ADD_MEASURE);
const resetMeasureOptionsAction = createAction(RESET_MEASURE_OPTIONS);
const setReportTypeAction = createAction(SET_REPORT_TYPE);
const setSupplierEngagementData = createAction(FETCH_SUPPLIER_ENGAGMENT_DATA);
const setIsRefreshDisabled = createAction(SET_IS_REFRESH_DISABLED);

export const resetReport = () => dispatch => {
  dispatch(setLoadingAction(true));
  dispatch(resetAction());
};

export const exportToPDF = html => dispatch => {
  dispatch(setExportingAction({ isExporting: true, isExported: false }));
  const fileName = 'performance-report-' + new Date().toISOString() + '.pdf';
  customRequest({
    url: 'https://9opeabsuwf.execute-api.eu-west-2.amazonaws.com/prod/lambda',
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      htmlString: html,
      fileName,
    },
  })
    .then(response => {
      dispatch(exportToPdfSuccessAction(response.result));
      dispatch(setExportingAction({ isExporting: false, isExported: true }));
    })
    .catch(err =>
      dispatch(setExportingAction({ isExporting: false, isExported: false })),
    );
};

export const setCurrentReportPage = type => dispatch => {
  dispatch(setCurrentReportPageAction(type));
};

export const loadReport = () => dispatch => {
  dispatch(fetchPerformanceDataTableAction([]));
  dispatch(fetchPerformanceDataGraphAction([]));
  dispatch(setLoadingAction(true));
  dispatch(setTableLoadedAction(false));
  dispatch(setGraphLoadedAction(false));
  loadChart(store, dispatch);
  loadTable(store, dispatch);
};

export const fetchCategories =
  (type, isReferal, drillObj) => async dispatch => {
    dispatch(setLoadedAction(false));
    dispatch(setReportTypeAction(type));

    const userObj = JSON.parse(localStorage.user_data);
    let url = '/route.php';
    if (userObj.departments.length === 0 && userObj.categories.length === 0) {
      url += '?c=category/getHierarchy';
    } else {
      url += '?c=category/getUserHierarchy';
    }

    dispatch(setLoadingAction(true));
    const result = await requestGet({ url });
    let url1 = '/route.php?c=wholesaler/getAll';

    let members = await requestGet({ url: url1 });

    let url3 = '/route.php?c=brand/getAll';

    if (isReferal) {
      if (drillObj.departments && drillObj.departments.length > 0) {
        url3 += `&department=${encodeURIComponent(drillObj.departments)}`;
      }
      if (drillObj.categories && drillObj.categories.length > 0) {
        url3 += `&category=${encodeURIComponent(drillObj.categories)}`;
      }
      if (drillObj.subCategories && drillObj.subCategories.length > 0) {
        url3 += `&subcategory=${encodeURIComponent(drillObj.subCategories)}`;
      }
      if (drillObj.level4s && drillObj.level4s.length > 0) {
        url3 += `&level4=${encodeURIComponent(drillObj.level4s)}`;
      }
      if (drillObj.level5s && drillObj.level5s.length > 0) {
        url3 += `&level5=${encodeURIComponent(drillObj.level5s)}`;
      }
      if (drillObj.supplier && drillObj.supplier.length > 0) {
        url3 += `&supplier=${encodeURIComponent(drillObj.supplier)}`;
      }
    }

    let brands = await requestGet({ url: url3 });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });

    url = '/route.php?c=supplier/getAll';

    each(members, mem => {
      mem.Text = encodeText(mem.Text);
    });

    if (
      members.length === 1
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(
        setSelectedMembersAction([
          { Text: members[0].ActualName, ID: members[0].WholesalerNo },
        ]),
      );
      if (type === 'depot') {
        members = [members[0]];
      }
    }

    const response = await requestGet({ url: url });

    let suppliers = [];

    each(response, supp => {
      supp.SupplierName = encodeText(supp.SupplierName);
      suppliers.push(supp);
    });

    url = '/route.php?c=customer/getAllBusinessTypes';

    let businessTypes = [];

    try {
      businessTypes = await requestGet({ url: url });
    } catch {
      console.warn('No business types permission');
    }

    url='/route.php?c=customer/getRetailers';

    const accountNames = await requestGet({ url: url });

    url = '/route.php?c=depot/getDepots';

    if (isReferal && drillObj.members && drillObj.members.length > 0) {
      url = `/route.php?c=depot/getDepots&member=${encodeURIComponent(drillObj.members.split(
        ',',
      ))}`;
    }

    businessTypes =
      businessTypes.map(businessType => {
        return { BusinessName: businessType };
      }) || [];

    const depots = await requestGet({ url: url });

    each(depots, depot => {
      depot.DepotName = encodeText(depot.DepotName);
    });

    let departments = result.Departments ? result.Departments : [];
    let orderedDeprtments = orderBy(departments, ['Text']);
    let categories = result.Categories ? result.Categories : [];
    let orderedCategories = orderBy(categories, ['Text']);
    if (departments.length > 1) {
      orderedDeprtments.unshift({ ID: 0, Text: 'Select all', Categories: [] });
    }

    if (categories.length > 1) {
      orderedCategories.unshift({
        ID: 0,
        Text: 'Select all',
        SubCategories: [],
      });
    }

    // Format suppliers to fit { ID: *, Text: ""}
    let formattedSuppliers;
    if (suppliers.length > 0) {
      formattedSuppliers = suppliers.map(supplier => ({
        Text: supplier.SupplierName,
        ID: supplier.SupplierName,
      }));
    }

    // Format Brands to fit { ID: *, Text: ""}
    let formattedBrands;
    if (brands.length > 0) {
      formattedBrands = brands.map(brand => ({
        Text: brand.Brand,
        ID: brand.Brand,
      }));
    }

    dispatch(
      fetchDataAction({
        departments: orderedDeprtments,
        categories: orderedCategories,
        suppliers: formattedSuppliers || suppliers,
        brands: formattedBrands || brands,
        members: members,
        accountNames: accountNames,
        depots: depots,
        purchase: isReferal
          ? store.getState().supplierEngagement.purchase
          : 'out',
        measure: isReferal
          ? store.getState().supplierEngagement.measure
          : 'Value',
        likeForLike: isReferal
          ? store.getState().supplierEngagement.likeForLike
          : 'like_for_like',
        timeframe: isReferal
          ? store.getState().supplierEngagement.timeframe
          : !drillObj.timeframe
          ? (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks')
          : drillObj.timeframe,
        businessTypes: businessTypes,
      }),
    );

    each(result.Categories, cat => {
      cat.Text = encodeText(cat.Text);
    });
    const rptType = store.getState().supplierEngagement.reportType;
    if (result.Departments && result.Departments.length === 1) {
      let dept = result.Departments[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot' &&
        rptType !== 'level4' &&
        rptType !== 'level5'
      ) {
        reportType = 'category';
      } else {
        reportType = store.getState().supplierEngagement.reportType;
      }
      dispatch(setReportDepartmentsAction({ departments: [dept], reportType }));
      each(dept.Categories, cat => {
        cat.Text = encodeText(cat.Text);
      });
      if (dept.Categories.length === 1) {
        let cat = dept.Categories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'subcategory';
        } else {
          reportType = store.getState().supplierEngagement.reportType;
        }

        dispatch(setReportCategoriesAction({ categories: [cat], reportType }));
        each(cat.SubCategories, subCat => {
          subCat.Text = encodeText(subCat.Text);
        });
        if (cat.SubCategories.length === 1) {
          let subcat = cat.SubCategories[0];
          if (
            rptType !== 'supplier' &&
            rptType !== 'business_type' &&
            rptType !== 'member' &&
            rptType !== 'brand' &&
            rptType !== 'depot'
          ) {
            reportType = 'level4';
          } else {
            reportType = store.getState().supplierEngagement.reportType;
          }
          dispatch(
            setSubCategoriesAction({ subCategories: [subcat], reportType }),
          );
        }
      }
    }
    if (result.Categories && result.Categories.length === 1) {
      let catt = result.Categories[0];
      let reportType = '';
      if (
        rptType !== 'supplier' &&
        rptType !== 'business_type' &&
        rptType !== 'member' &&
        rptType !== 'brand' &&
        rptType !== 'depot'
      ) {
        reportType = 'subcategory';
      } else {
        reportType = store.getState().supplierEngagement.reportType;
      }
      dispatch(setReportCategoriesAction({ categories: [catt], reportType }));
      each(catt.SubCategories, subCat => {
        subCat.Text = encodeText(subCat.Text);
      });
      if (catt.SubCategories.length === 1) {
        let subcat = catt.SubCategories[0];
        if (
          rptType !== 'supplier' &&
          rptType !== 'business_type' &&
          rptType !== 'member' &&
          rptType !== 'brand' &&
          rptType !== 'depot'
        ) {
          reportType = 'level4';
        } else {
          reportType = store.getState().supplierEngagement.reportType;
        }
        dispatch(
          setSubCategoriesAction({ subCategories: [subcat], reportType }),
        );
      }
    } else if (!isReferal) {
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
      return;
    }

    if (!isReferal && store.getState().supplierEngagement.hideAll === false) {
      dispatch(setLoadingAction(false));
      dispatch(setLoadedAction(true));
    }
    dispatch(setLoadedAction(true));
  };

export const setOnLoadParams = (params, reportType) => async dispatch => {
  dispatch(setLoadedAction(false));
  const state = store.getState().supplierEngagement;
  if (params.measure) dispatch(setMeasureAction(params.measure));
  if (params.type) dispatch(setPurchaseAction(params.type));
  if (params.timeframe) dispatch(setTimeFrameAction(params.timeframe));
  if (params.accountName) dispatch(setTimeFrameAction(params.accountName));
  if (params.likeForLike) dispatch(setLikeForLikeAction(params.likeForLike));
  let departments = [];
  let categories = [];
  let subCategories = [];
  let level4s = [];
  let level5s = [];
  if (params.departments && params.departments.length > 0) {
    _.each(params.departments, dept => {
      if (_.filter(state.departments, { Text: dept }).length > 0) {
        departments.push(_.filter(state.departments, { Text: dept })[0]);
      }
    });
    dispatch(setReportDepartmentsAction({ departments, reportType }));
  }
  if (params.categories && params.categories.length > 0) {
    _.each(params.categories, catt => {
      if (
        _.filter(flatten(map(departments, dept => dept.Categories)), {
          Text: catt,
        }).length > 0
      ) {
        categories.push(
          _.filter(flatten(map(departments, dept => dept.Categories)), {
            Text: catt,
          })[0],
        );
      }
    });
    if (categories.length > 0) {
      dispatch(setReportCategoriesAction({ categories, reportType }));
    }
  }
  if (params.subCategories && params.subCategories.length > 0) {
    _.each(params.subCategories, subcat => {
      if (
        _.filter(flatten(map(categories, cat => cat.SubCategories)), {
          Text: subcat,
        }).length > 0
      ) {
        subCategories.push(
          _.filter(flatten(map(categories, cat => cat.SubCategories)), {
            Text: subcat,
          })[0],
        );
      }
    });
    if (subCategories.length > 0) {
      dispatch(setSubCategoriesAction({ subCategories, reportType }));
    }
  }
  if (params.level4s && params.level4s.length > 0) {
    _.each(params.level4s, lvl4 => {
      if (
        _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
          Text: lvl4,
        }).length > 0
      ) {
        level4s.push(
          _.filter(flatten(map(subCategories, subCat => subCat.Level4)), {
            Text: lvl4,
          })[0],
        );
      }
    });
    if (level4s.length > 0) {
      dispatch(setLevel4sAction({ level4s, reportType }));
    }
  }
  if (params.level5s && params.level5s.length > 0) {
    _.each(params.level5s, lvl5 => {
      if (
        _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
          ID: parseInt(lvl5),
        }).length > 0
      ) {
        level5s.push(
          _.filter(flatten(map(level4s, lvl => lvl.Level5)), {
            ID: parseInt(lvl5),
          })[0],
        );
      }
    });
    if (level5s.length > 0) {
      dispatch(setLevel5sAction({ level5s, reportType }));
    }
  }
  if (typeof params.supplier !== 'undefined' && params.supplier.length > 0) {
    _.each(state.suppliers, sup => {
      if (sup.SupplierName.trim() === params.supplier.trim()) {
        dispatch(
          setSupplierAction({
            supplier: sup,
            reportType: store.getState().supplierEngagement.reportType,
          }),
        );
      }
    });
  }

  if (params.brand) {
    let brand = _.filter(state.brands, brd => {
      if (brd.Brand.trim() === params.brand.trim()) {
        return brd;
      }
    });
    let brnd = brand[0];
    if (brnd) {
      brnd.reportType = reportType;
      dispatch(setBrandAction(brnd));
    }
  }

  if (params.members.length > 0) {
    let members = [];
    _.each(params.members, mem => {
      const memb = _.filter(
        state.members,
        memm => memm.WholesalerNo === mem.WholesalerNo,
      )[0];
      members.push({ Text: memb.ActualName, ID: memb.WholesalerNo });
    });
    dispatch(setSelectedMembersAction(members));

    if (params.depots.length > 0) {
      let procDepots = [];
      each(params.depots, dept => {
        each(state.depots, dpt => {
          if (dpt.DepotName === dept) {
            procDepots.push(dpt);
          }
        });
      });
      dispatch(
        setDepotAction({
          selectedDepots: procDepots,
          reportType: state.reportType,
        }),
      );
    }
  } else {
    let members = state.members;
    if (
      members.length === 2 &&
      _.filter(members, member => member.Text === 'ALL').length > 0
    ) {
      if (result.Categories && result.Categories.length > 0) {
        dispatch(setHideAllAction(true));
      }
      dispatch(setSelectedMembersAction([members[0]]));
    }
    dispatch(setDepotsAction([]));
  }

  if (params.selectedBusinessType !== undefined) {
    const businessType = _.find(state.businessTypes, businessType => {
      if (
        businessType.BusinessName.trim() === params.selectedBusinessType.trim()
      ) {
        return businessType;
      }
    });
    dispatch(setBusinessTypeAction(businessType.BusinessName));
  }

  dispatch(setHideAllAction(false));

  if (state.departments.length === 1 && state.reportType === 'department') {
    dispatch(setReportTypeAction('category'));
  }
};

export const brandSelect = value => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + '/nam_dash/brand_performance';
  let drillObj = new Drill();
  drillObj.to = '/nam_dash/brand_performance';
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subcat => subcat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName);
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }

  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const productSelect = value => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + '/nam_dash/product_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/product_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }
  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName.trim() === value.trim()) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + 'nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const depotSelect = (value, ref) => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + '/nam_dash/depot_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/depot_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = 'category';
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }
  if (state.reportType === 'supplier') {
    drillObj.supplier = value;
  } else {
    drillObj.supplier = state.supplier.SupplierName;
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.WholesalerNo;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName.trim() === value.trim()) {
        drillObj.depots = dept;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const supplierSelect = (value, ref) => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + '/nam_dash/supplier_performance?';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/supplier_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (state.brand.Brand !== undefined && state.reportType !== 'brand') {
    drillObj.brand = state.brand.Brand;
  } else if (state.reportType === 'brand') {
    drillObj.brand = value;
    drillObj.skip = 'brand';
  }

  if (state.supplier.SupplierName !== undefined) {
    drillObj.supplier = state.supplier.SupplierName;
  }

  if (state.selectedMembers.length > 0 && state.reportType !== 'member') {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  } else if (state.reportType === 'member') {
    _.each(state.members, member => {
      if (member.Text === value) {
        drillObj.members = member.ID;
        drillObj.skip = 'members';
      }
    });
  }
  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }
  addDrillInfo(drillObj);
  dispatch(push(url));
};
export const wholesalerSelect = (value, ref) => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + '/nam_dash/wholesaler_performance';
  let drillObj = new Drill();
  drillObj.measure = state.measure;
  drillObj.timeframe = state.timeframe;
  drillObj.likeForLike = state.likeForLike;
  drillObj.type = state.purchase;
  drillObj.to = '/nam_dash/wholesaler_performance';
  if (
    state.reportType === 'department' ||
    state.selectedDepartments.length > 0
  ) {
    if (state.reportType === 'department') {
      drillObj.departments = value;
      drillObj.skip = 'department';
    } else {
      drillObj.departments = map(
        filter(state.selectedDepartments, dept => dept.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'category' || state.selectedCategories.length > 0) {
    if (state.reportType === 'category') {
      drillObj.categories = value;
      drillObj.skip = 'category';
    } else {
      drillObj.categories = map(
        filter(state.selectedCategories, cat => cat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (
    state.reportType === 'subcategory' ||
    state.selectedSubCategories.length > 0
  ) {
    if (state.reportType === 'subcategory') {
      drillObj.subCategories = value;
      drillObj.skip = 'subcategory';
    } else {
      drillObj.subCategories = map(
        filter(state.selectedSubCategories, subCat => subCat.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level4' || state.selectedLevel4s.length > 0) {
    if (state.reportType === 'level4') {
      drillObj.level4s = value;
      drillObj.skip = 'level4';
    } else {
      drillObj.level4s = map(
        filter(state.selectedLevel4s, lvl4 => lvl4.ID !== 0),
        'Text',
      ).join(',');
    }
  }
  if (state.reportType === 'level5' || state.selectedLevel5s.length > 0) {
    if (state.reportType === 'level5') {
      drillObj.level5s = filter(state.level5s, {
        Text: value,
      })[0].ID.toString();
      drillObj.skip = 'level5';
    } else {
      drillObj.level5s = map(
        filter(state.selectedLevel5s, lvl5 => lvl5.ID !== 0),
        'ID',
      ).join(',');
    }
  }

  if (
    state.supplier.SupplierName !== undefined &&
    state.reportType !== 'supplier'
  ) {
    drillObj.supplier = state.supplier.SupplierName;
  } else if (state.reportType === 'supplier') {
    drillObj.supplier = value;
    drillObj.skip = 'supplier';
  }

  if (state.selectedDepots?.length > 0 && state.reportType !== 'depot') {
    let depts = [];
    _.each(state.selectedDepots, dept => {
      depts.push(dept.DepotName.trim());
    });
    drillObj.depots = depts;
  } else if (state.reportType === 'depot') {
    _.each(state.depots, dept => {
      if (dept.DepotName === value) {
        drillObj.depots = dept.DepotName;
        drillObj.skip = 'depot';
      }
    });
  }

  if (state.selectedMembers.length > 0) {
    let mems = [];
    _.each(state.selectedMembers, membs => {
      mems.push(membs.ID);
    });
    drillObj.members = mems;
  }
  if (
    state.reportType === 'department' ||
    state.reportType === 'category' ||
    state.reportType === 'subcategory' ||
    state.reportType === 'level4' ||
    state.reportType === 'level5'
  ) {
    drillObj.ref = state.baseUrl + '/nam_dash/category_performance/';
  } else if (state.reportType === 'supplier') {
    drillObj.ref = state.baseUrl + '/nam_dash/supplier_performance/';
  } else if (state.reportType === 'member') {
    drillObj.ref = state.baseUrl + '/nam_dash/wholesaler_performance/';
  } else if (state.reportType === 'brand') {
    drillObj.ref = state.baseUrl + '/nam_dash/brand_performance/';
  } else if (state.reportType === 'depot') {
    drillObj.ref = state.baseUrl + '/nam_dash/depot_performance/';
  }

  addDrillInfo(drillObj);
  dispatch(push(url));
};

export const goToReferal = (val, to, skip) => dispatch => {
  let state = store.getState().supplierEngagement;
  let url = state.baseUrl + val;
  removeDrillInfo(to);
  dispatch(push(url));
};

export const setReportSupplier = val => dispatch => {
  let supplier = _.filter(
    store.getState().supplierEngagement.performanceDataTable,
    sup => {
      if (sup.field === val) {
        return sup;
      }
    },
  );

  dispatch(setReportSupplierAction(supplier[0]));
};

export const setReportMember = val => dispatch => {
  let member = _.filter(
    store.getState().supplierEngagement.performanceDataTable,
    mem => {
      if (mem.field === val) {
        return mem;
      }
    },
  );

  dispatch(setReportMemberAction(member[0]));
};

export const setSelectedProduct = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));

  if (val) {
    dispatch(setSelectedProductAction(val));
  } else {
    dispatch(setSelectedProductAction(''));
  }
};

export const setDepartment = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let department = _.filter(
      store.getState().supplierEngagement.departments,
      dpt => {
        if (dpt.ID === parseInt(val.ID)) {
          return dpt;
        }
      },
    );
    let dpt = department[0];
    dispatch(setReportDepartmentAction(dpt));
    loadProducts(store, dispatch);
  } else {
    dispatch(setReportDepartmentAction(''));
    loadProducts(store, dispatch);
  }
};

export const setDepartments = depts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().supplierEngagement;
  let reportType = 'basket_analysis';

  if (depts.length > 0) {
    const beerExists = filter(depts, dept => {
      if (dept.Text === 'BEER LAGER CIDER') {
        return dept;
      }
    });
    const winesExists = filter(depts, dept => {
      if (dept.Text === 'WINES SPIRITS') {
        return dept;
      }
    });
    const tobaccoExists = filter(depts, dept => {
      if (dept.Text === 'TOBACCO') {
        return dept;
      }
    });

    if (beerExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Hectolitres', ID: 'qty_hect' }));
    } else if (winesExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-9L', ID: 'qty_9l' }));
    } else if (tobaccoExists.length > 0) {
      dispatch(addMeasureAction({ Text: 'QTY-Stick', ID: 'qty_stick' }));
    } else {
      dispatch(resetMeasureOptionsAction());
    }
    dropdownSelectionHelper(
      depts,
      reportType,
      'departments',
      state.departments,
      state.selectedDepartments,
      setReportDepartmentsAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().supplierEngagement;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  } else {
    dispatch(
      setReportDepartmentsAction({ reportType: reportType, departments: [] }),
    );
    dispatch(
      setReportCategoriesAction({ categories: [], reportType: reportType }),
    );
    dispatch(
      setSubCategoriesAction({ reportType: reportType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ reportType: reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType: reportType, level5s: [] }));

    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  }
};

export const setCategory = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let cat = _.filter(store.getState().supplierEngagement.categories, cat => {
      if (cat.ID === parseInt(val.ID)) {
        return cat;
      }
    });
    let category = cat[0];
    dispatch(setReportCategoryAction(category));
    loadProducts(store, dispatch);
  } else {
    dispatch(setReportCategoryAction(''));
    loadProducts(store, dispatch);
  }
};

export const setRefreshDisabledStatus = val => dispatch => {
  dispatch(setIsRefreshDisabled(val));
};

export const setCategories = cats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().supplierEngagement;
  let reportType = 'basket_analysis';
  if (cats.length > 0) {
    dropdownSelectionHelper(
      cats,
      reportType,
      'categories',
      state.categories,
      state.selectedCategories,
      setReportCategoriesAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().supplierEngagement;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  } else {
    dispatch(setReportCategoriesAction({ reportType, categories: [] }));
    dispatch(setSubCategoriesAction({ reportType, subCategories: [] }));
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url: '/route.php?c=supplier/getAll',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));

    dispatch(setHideAllAction(false));
  }
};

export const setSubCategory = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let subCat = _.filter(
      store.getState().supplierEngagement.subCategories,
      cat => {
        if (cat.ID === parseInt(val.ID)) {
          return cat;
        }
      },
    );
    let subCategory = subCat[0];

    dispatch(setSubCategoryAction(subCategory));
    loadProducts(store, dispatch);
  } else {
    dispatch(setSubCategoryAction(''));
    loadProducts(store, dispatch);
  }
};

export const setSubCategories = subCats => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().supplierEngagement;
  const tobaccoExists = filter(subCats, subCat => {
    if (subCat.Text === 'HAND ROLLING TOBACCO') {
      return subCat;
    }
  });

  if (tobaccoExists.length > 0) {
    dispatch(addMeasureAction({ Text: 'QTY-GRAMS', ID: 'qty_gms' }));
  }
  let rptType = state.reportType;

  if (subCats.length > 0) {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'level4';
    }

    dropdownSelectionHelper(
      subCats,
      rptType,
      'subCategories',
      state.subCategories,
      state.selectedSubCategories,
      setSubCategoriesAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().supplierEngagement;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));
  } else {
    if (
      rptType !== 'depot' &&
      rptType !== 'supplier' &&
      rptType !== 'member' &&
      rptType !== 'brand'
    ) {
      rptType = 'subcategory';
    }
    dispatch(
      setSubCategoriesAction({ reportType: rptType, subCategories: [] }),
    );
    dispatch(setLevel4sAction({ level4s: [], reportType: rptType }));
    dispatch(setLevel5sAction({ level5s: [], reportType: rptType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

export const setLevel4 = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let level4 = _.filter(store.getState().supplierEngagement.level4s, item => {
      if (item.ID === parseInt(val.ID)) {
        return item;
      }
    });
    let lvl4 = level4[0];
    dispatch(setLevel4Action(lvl4));
    loadProducts(store, dispatch);
  } else {
    dispatch(setLevel4Action(''));
    loadProducts(store, dispatch);
  }
};

export const setLevel4s = opts => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().supplierEngagement;
  let { reportType } = state;
  if (opts.length > 0) {
    dropdownSelectionHelper(
      opts,
      reportType,
      'level4s',
      state.level4s,
      state.selectedLevel4s,
      setLevel4sAction,
      dispatch,
    );

    const user = store.getState().user.authUser;
    state = store.getState().supplierEngagement;
    dispatch(setSuppliersLoadingAction(true));
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(false));
    dispatch(setSuppliersAction(suppliers));
  } else {
    dispatch(setLevel4sAction({ reportType, level4s: [] }));
    dispatch(setLevel5sAction({ reportType, level5s: [] }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

export const setLevel5 = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val && val.ID && parseInt(val.ID) > 0) {
    let level5 = _.filter(store.getState().supplierEngagement.level5s, item => {
      if (item.ID === parseInt(val.ID)) {
        return item;
      }
    });
    let lvl5 = level5[0];
    dispatch(setLevel5Action(lvl5));
    loadProducts(store, dispatch);
  } else {
    dispatch(setLevel5Action(''));
    loadProducts(store, dispatch);
  }
};

export const setLevel5s = lvl5s => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let state = store.getState().supplierEngagement;
  let { reportType } = state;
  if (lvl5s.length > 0) {
    dropdownSelectionHelper(
      lvl5s,
      reportType,
      'level5s',
      state.level5s,
      state.selectedLevel5s,
      setLevel5sAction,
      dispatch,
    );
    const user = store.getState().user.authUser;
    state = store.getState().supplierEngagement;
    let suppliers = await fetchSuppliers({ user, state });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersLoadingAction(true));
    dispatch(setSuppliersAction(suppliers));
    dispatch(setSuppliersLoadingAction(false));
  } else {
    dispatch(setLevel5sAction({ level5s: [], reportType }));

    let suppliers = await requestGet({
      url:
        '/route.php?c=supplier/getAll&department=' +
        encodeURIComponent(map(state.selectedDepartments, 'Text').join(',')) +
        '&category=' +
        encodeURIComponent(map(state.selectedCategories, 'Text').join(',')) +
        '&subcategory=' +
        encodeURIComponent(map(state.selectedSubCategories, 'Text').join(',')) +
        '&level4=' +
        encodeURIComponent(map(state.selectedLevel4s, 'Text').join(',')) +
        '',
    });
    suppliers = typeof suppliers != 'array' && typeof suppliers != 'object' ? [] : suppliers;
    dispatch(setSuppliersAction(suppliers));
  }
};

const loadProducts = async (store, dispatch) => {};

export const setAccountName = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setAccountNameAction(val));
};

export const setMember = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedMembersAction(val));
};

export const setSupplier = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  let supplier = {};
  if (val) {
    supplier = val;
    await dispatch(
      setSupplierAction({
        supplier: val,
        reportType: store.getState().supplierEngagement.reportType,
      }),
    );
    loadProducts(store, dispatch);
    const state = store.getState().supplierEngagement;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    url += `&supplier=${encodeURIComponent(supplier.Text)}`;

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Text = encodeText(brd.Brand);
      brd.ID = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  } else {
    dispatch(
      setSupplierAction({
        reportType: store.getState().supplierEngagement.reportType,
        supplier: '',
      }),
    );
    loadProducts(store, dispatch);
    const state = store.getState().supplierEngagement;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Text = encodeText(brd.Brand);
      brd.ID = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
  }
};

export const setSuppliers = val => async dispatch => {
  dispatch(setIsRefreshDisabled(false));
  if (val.length !== 0) {
    await dispatch(setSelectedSuppliersAction(val));
    const state = store.getState().supplierEngagement;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }
    url += `&supplier=${encodeURIComponent(
      map(state.selectedSuppliers, 'Text').join(','),
    )}`;
    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  } else {
    dispatch(setSelectedSuppliersAction([]));
    const state = store.getState().supplierEngagement;
    let url = '/route.php?c=brand/getAll';
    if (state.selectedDepartments.length > 0) {
      url += `&department=${encodeURIComponent(
        map(state.selectedDepartments, 'Text').join(','),
      )}`;
    }
    if (state.selectedCategories.length > 0) {
      url += `&category=${encodeURIComponent(
        map(state.selectedCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedSubCategories.length > 0) {
      url += `&subcategory=${encodeURIComponent(
        map(state.selectedSubCategories, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel4s.length > 0) {
      url += `&level4=${encodeURIComponent(
        map(state.selectedLevel4s, 'Text').join(','),
      )}`;
    }
    if (state.selectedLevel5s.length > 0) {
      url += `&level5=${encodeURIComponent(
        map(state.selectedLevel5s, 'Text').join(','),
      )}`;
    }

    let brands = await requestGet({ url });
    brands = typeof brands != 'array' && typeof brands != 'object' ? [] : brands;
    each(brands, brd => {
      brd.Brand = encodeText(brd.Brand);
    });
    dispatch(setBrandsAction(brands));
    loadProducts(store, dispatch);
  }
};

export const setDepot = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(
    setDepotAction({
      selectedDepots: val,
      reportType: store.getState().supplierEngagement.reportType,
    }),
  );
};

export const setTimeFrame = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setTimeFrameAction(val));
};

export const setBrand = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setBrandAction(val));
  loadProducts(store, dispatch);
};

export const setBrands = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setSelectedBrandsAction(val));
  loadProducts(store, dispatch);
};

export const setTitle = (val, type) => dispatch => {
  dispatch(setLoadingAction(true));
  const state = store.getState().supplierEngagement;
  if (type === 'department' && state.departments.length === 1) {
    type = 'category';
  }
  dispatch(setTitleAction({ title: val, reportType: type }));
  let members = state.members;
  if (
    members.length === 2 &&
    _.filter(members, member => member.Text === 'ALL').length > 0
  ) {
    if (result.Categories && result.Categories.length > 0) {
      dispatch(setHideAllAction(true));
    }
    dispatch(setSelectedMembersAction([members[0]]));
  }
};

export const setSubPage = (val, type) => dispatch => {
  dispatch(setSubPageAction(val));
};

export const setMeasure = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setMeasureAction(val));
};

export const setLikeForLike = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setLikeForLikeAction(val));
};

export const setPurchase = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(setPurchaseAction(val));
};

export const setBusinessType = val => dispatch => {
  dispatch(setIsRefreshDisabled(false));
  dispatch(
    setBusinessTypeAction({
      selectedBusinessType: val,
      reportType: store.getState().supplierEngagement.reportType,
    }),
  );
};

export const loadSupplierEngagementData = () => async dispatch => {
  dispatch(setIsRefreshDisabled(true));
  dispatch(setLoadingAction(true));
  dispatch(setReportErrorAction(''));
  const state = store.getState().supplierEngagement;
  const user = store.getState().user.authUser;
  const url = formatUrlWithParameters(
    '/route.php?c=supplierEngagement/reports',
    // Temp data for dev usage
    // '/route.php?c=supplierEngagement/reports_temp',
    state,
    user,
  );
  try {
    const supplierEngagementData = await requestGet({ url });
    dispatch(setSupplierEngagementData(supplierEngagementData));
  } catch (err) {
    dispatch(setReportErrorAction('Request failed, please try again.'));
  } finally {
    dispatch(setLoadingAction(false));
  }
};

export default handleActions(
  {
    [FETCH_DATA]: (state, { payload }) => ({
      ...state,
      departments: payload.departments,
      categories: payload.categories,
      members: payload.members,
      brands: payload.brands,
      suppliers: payload.suppliers,
      accountNames: payload.accountNames,
      depots: payload.depots,
      purchase: payload.purchase,
      measure: payload.measure,
      likeForLike: payload.likeForLike,
      timeframe: payload.timeframe,
      businessTypes: payload.businessTypes,
      isInitialLoadComplete: !state.isInitialLoadComplete,
    }),
    [RESET_MEASURE_OPTIONS]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
    }),
    [ADD_MEASURE]: (state, { payload }) => ({
      ...state,
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        payload,
      ],
    }),
    [SET_REPORT_DEPARTMENTS]: (state, { payload }) => {
      let allCategories = flatten(
        payload.departments.map(dept => {
          return dept.Categories;
        }),
      );

      uniqBy(
        orderBy(
          filter(allCategories, cat => cat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (allCategories.length > 1) {
        allCategories.unshift({ ID: 0, Text: 'Select all', SubCategories: [] });
      }

      return {
        ...state,
        selectedDepartment: payload.departments[0],
        selectedDepartments: payload.departments,
        categories: allCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_REPORT_DEPARTMENT]: (state, { payload: { ID, Text, Categories } }) => {
      return {
        ...state,
        selectedDepartment: ID && Text ? { ID, Text } : '',
        categories: Categories || [],
      };
    },

    [SET_REPORT_CATEGORIES]: (state, { payload }) => {
      let allSubCategories = flatten(
        payload.categories.map(cat => {
          return cat?.SubCategories;
        }),
      );
      let orderedSubCategories = uniqBy(
        orderBy(
          filter(allSubCategories, subCat => subCat !== undefined),
          ['Text'],
        ),
        'Text',
      );
      if (orderedSubCategories.length > 1) {
        orderedSubCategories.unshift({
          ID: 0,
          Text: 'Select all',
          Level4s: [],
        });
      }

      return {
        ...state,
        selectedCategories: payload.categories,
        subCategories: orderedSubCategories,
        reportType: payload.reportType || '',
      };
    },
    [SET_REPORT_CATEGORY]: (
      state,
      { payload: { ID, Text, SubCategories } },
    ) => ({
      ...state,
      selectedCategory: ID && Text ? { ID, Text } : '',
      subCategories: SubCategories || [],
    }),
    [SET_SUBCATEGORIES]: (state, { payload }) => {
      let allLevel4s = flatten(
        payload.subCategories.map(subCat => {
          return subCat.Level4;
        }),
      );
      if (allLevel4s.length > 1) {
        allLevel4s.push({ ID: 0, Text: 'Select all', Level5s: [] });
      }
      return {
        ...state,
        selectedSubCategories: payload.subCategories,
        level4s: uniqBy(
          orderBy(
            filter(allLevel4s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_SUBCATEGORY]: (state, { payload: { ID, Text, Level4 } }) => ({
      ...state,
      selectedSubCategory: ID && Text ? { ID, Text } : '',
      level4s: Level4 || [],
    }),
    [SET_LEVEL4S]: (state, { payload }) => {
      let allLevel5s = flatten(
        payload.level4s.map(lvl => {
          return lvl.Level5;
        }),
      );
      if (allLevel5s.length > 1) {
        allLevel5s.push({ ID: 0, Text: 'Select all' });
      }
      return {
        ...state,
        selectedLevel4s: payload.level4s,
        level5s: uniqBy(
          orderBy(
            filter(allLevel5s, lvl => lvl !== undefined),
            ['ID'],
          ),
          'Text',
        ),
        reportType: payload.reportType || '',
      };
    },
    [SET_LEVEL4]: (state, { payload: { ID, Text, Level5 } }) => ({
      ...state,
      selectedLevel4: ID && Text ? { ID, Text } : '',
      level5s: Level5 || [],
    }),
    [SET_LEVEL5S]: (state, { payload }) => ({
      ...state,
      selectedLevel5s: payload.level5s,
      reportType: payload.reportType,
    }),
    [SET_LEVEL5]: (state, { payload: { ID, Text } }) => ({
      ...state,
      selectedLevel5: ID && Text ? { ID, Text } : '',
    }),
    [SET_ACCOUNT_NAME]: (state, { payload }) => ({
      ...state,
      accountName:  payload,
    }),
    [SET_BRAND]: (state, { payload }) => ({
      ...state,
      brand: payload,
      selectedBrand: payload,
    }),
    [SET_SELECTED_PRODUCT]: (state, { payload }) => ({
      ...state,
      selectedProduct: payload,
    }),
    [SET_CURRENT_REPORT_PAGE]: (state, { payload }) => ({
      ...state,
      currentReportPage: payload,
    }),
    [SET_SELECTED_MEMBERS]: (state, { payload }) => ({
      ...state,
      selectedMembers: payload,
    }),
    [SET_SUPPLIERS]: (state, { payload }) => {
      let formattedSuppliers;
      if (payload[0] && payload[0].SupplierName) {
        formattedSuppliers = payload.map(supplier => ({
          Text: supplier.SupplierName,
          ID: supplier.SupplierName,
        }));
        return { ...state, suppliers: formattedSuppliers };
      } else {
        return { ...state, suppliers: payload };
      }
    },
    [SET_BRANDS]: (state, { payload }) => {
      let formattedBrands;
      if (payload[1] && payload[1].Brand) {
        formattedBrands = payload.map(brand => ({
          Text: brand.Brand,
          ID: brand.Brand,
        }));
        return { ...state, brands: formattedBrands };
      } else {
        return { ...state, brands: payload };
      }
    },
    [SET_SELECTED_SUPPLIERS]: (state, { payload }) => ({
      ...state,
      selectedSuppliers: payload,
    }),
    [SET_SELECTED_BRANDS]: (state, { payload }) => ({
      ...state,
      selectedBrands: payload,
    }),
    [SET_SUPPLIER]: (state, { payload }) => ({
      ...state,
      supplier: payload.supplier,
      selectedSupplier: payload.supplier,
      reportType: payload.reportType,
    }),
    [SET_DEPOT]: (state, { payload }) => ({
      ...state,
      selectedDepots: payload.selectedDepots,
      reportType: payload.reportType,
    }),
    [SET_MEASURE]: (state, { payload }) => ({ ...state, measure: payload }),
    [SET_LIKE_FOR_LIKE]: (state, { payload }) => ({
      ...state,
      likeForLike: payload,
    }),
    [FETCH_PRODUCTS]: (state, { payload }) => ({
      ...state,
      products: payload,
    }),

    [SET_PURCHASE]: (state, { payload }) => ({ ...state, purchase: payload }),
    [SET_TIMEFRAME]: (state, { payload }) => ({
      ...state,
      timeframe: payload.ID ? payload.ID : payload,
    }),
    [SET_REPORT_ERROR]: (state, { payload }) => ({
      ...state,
      reportError: payload,
    }),
    [SET_SUBPAGE]: (state, { payload }) => ({ ...state, subPage: payload }),
    [SET_REPORT_TYPE]: (state, { payload }) => ({
      ...state,
      reportType: payload,
    }),
    [SET_TITLE]: (state, { payload }) => {
      return {
        ...state,
        title: payload.title,
        reportType: payload.reportType,
        baseUrl: '',
        departments: [],
        selectedDepartments: [],
        selectedDepartment: '',
        categories: [],
        selectedCategories: [],
        selectedCategory: '',
        subCategories: [],
        selectedSubCategories: [],
        selectedSubCategory: '',
        members: [],
        brands: [],
        brand: '',
        selectedBrands: [],
        suppliers: [],
        supplier: '',
        selectedSuppliers: [],
        depots: [],
        selectedDepots: [],
        level4s: [],
        selectedLevel4s: [],
        selectedLevel4: '',
        level5s: [],
        selectedLevel5s: [],
        selectedLevel5: '',
        products: [],
        selectedProduct: '',
        selectedProducts: [],
        timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
          { ID: '4wks', Text: '4 Wk' },
          { ID: '13wks', Text: '13 Wk' },
          { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
          { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
          { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
          { ID: 'YTD', Text: 'YTD' },
          { ID: 'MAT', Text: 'MAT' },
        ],
        timeframe: '',
        purchases: [
          { ID: 'in', Text: 'IN' },
          { ID: 'out', Text: 'OUT' },
        ],
        measures: [
          { ID: 'Value', Text: 'Val' },
          { ID: 'Qty', Text: 'Qty' },
          { ID: 'customer_count', Text: 'Customer Count'},
          { ID: 'profit', Text: 'Profit'},
          { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
        ],
        likeForLikes: [
          { ID: 'like_for_like', Text: 'Like For Like' },
          { ID: 'absolute', Text: 'Absolute' },
        ],
        measure: '',
        likeForLike: '',
        purchase: 'out',
        subPage: '',
        isLoading: false,
        isGraphLoaded: false,
        isTableLoaded: false,
        isExporting: false,
        isExported: false,
        currentReportPage: '',
        isLoaded: false,
        hideAll: false,
        pdfURL: '',
        businessTypes: [],
        selectedBusinessType: '',
        reportError: '',
        isInitialLoadComplete: false,
        reportType: '',
        isRefreshDisabled: false,
        supplierEngagementData: {},
      };
    },
    [SET_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
    [SET_SUPPLIERS_LOADING]: (state, { payload }) => ({
      ...state,
      areSuppliersLoading: payload,
    }),
    [SET_DEPOTS]: (state, { payload }) => ({ ...state, depots: payload }),

    [SET_EXPORTING]: (state, { payload }) => ({
      ...state,
      isExporting: payload.isExporting,
      isExported: payload.isExported,
    }),
    [SET_HIDE_ALL]: (state, { payload }) => ({ ...state, hideAll: payload }),
    [SET_LOADED]: (state, { payload }) => ({ ...state, isLoaded: payload }),
    [SET_GRAPH_LOADED]: (state, { payload }) => ({
      ...state,
      isGraphLoaded: payload,
    }),
    [SET_TABLE_LOADED]: (state, { payload }) => ({
      ...state,
      isTableLoaded: payload,
    }),

    [EXPORT_TO_PDF_SUCCESS]: (state, { payload }) => ({
      ...state,
      pdfURL: payload,
    }),
    [SET_BUSINESS_TYPE]: (state, { payload }) => ({
      ...state,
      selectedBusinessType: payload.selectedBusinessType,
      reportType: payload.reportType,
    }),

    [SET_IS_REFRESH_DISABLED]: (state, { payload }) => ({
      ...state,
      isRefreshDisabled: payload,
    }),
    [FETCH_SUPPLIER_ENGAGMENT_DATA]: (state, { payload }) => ({
      ...state,
      supplierEngagementData: payload,
    }),
    [RESET]: (state, { payload }) => ({
      ...state,
      // baseUrl: '/react',
      baseUrl: '',
      departments: [],
      selectedDepartments: [],
      selectedDepartment: '',
      categories: [],
      selectedCategories: [],
      selectedCategory: '',
      subCategories: [],
      selectedSubCategories: [],
      selectedSubCategory: '',
      members: [],
      brands: [],
      brand: '',
      selectedBrands: [],
      selectedMembers: [],
      suppliers: [],
      supplier: '',
      selectedSuppliers: [],
      depots: [],
      selectedDepots: [],
      level4s: [],
      selectedLevel4s: [],
      selectedLevel4: '',
      level5s: [],
      selectedLevel5s: [],
      selectedLevel5: '',
      products: [],
      selectedProduct: '',
      selectedProducts: [],
      timeframes: getUserSystem() == 'cjlangRetail' ? [
    { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
    { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
  ] : [
        { ID: '4wks', Text: '4 Wk' },
        { ID: '13wks', Text: '13 Wk' },
        { ID: '4wksRolling', Text: '4 Wks v Previous 4 Wks' },
        { ID: '52wksRolling', Text: '52 Wks v Previous 52 Wks' },
        { ID: '13wksRolling', Text: '13 Wks v Previous 13 Wks' },
        { ID: 'YTD', Text: 'YTD' },
        { ID: 'MAT', Text: 'MAT' },
      ],
      timeframe: '',
      purchases: [
        { ID: 'in', Text: 'IN' },
        { ID: 'out', Text: 'OUT' },
      ],
      measures: [
        { ID: 'Value', Text: 'Val' },
        { ID: 'Qty', Text: 'Qty' },
        { ID: 'customer_count', Text: 'Customer Count'},
        { ID: 'profit', Text: 'Profit'},
        { ID: 'retail_unit_qty', Text: 'Retail Unit Qty'},
      ],
      likeForLikes: [
        { ID: 'like_for_like', Text: 'Like For Like' },
        { ID: 'absolute', Text: 'Absolute' },
      ],
      measure: '',
      likeForLike: '',
      purchase: 'out',
      title: '',
      subPage: '',
      isLoading: false,
      isGraphLoaded: false,
      isTableLoaded: false,
      isExporting: false,
      isExported: false,
      reportType: '',
      currentReportPage: '',
      isLoaded: false,
      hideAll: false,
      pdfURL: '',
      businessTypes: [],
      selectedBusinessType: '',
      reportError: '',
      isInitialLoadComplete: false,
      reportType: '',
      isRefreshDisabled: false,
      supplierEngagementData: {},
    }),
  },
  initialState,
);

const getCategories = state => state.site.categories;
const getSubCategories = state => state.site.subcategories;

export const selectors = {
  getCategories,
  getSubCategories,
};
