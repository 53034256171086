import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import ExportIcon from '../../assets/images/export-icon.svg';

const DropdownButton = ({ toggleIcon, dropdownItems, text, classes }) => {
  const [isOpen, toggleOpen] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => toggleOpen(!isOpen)}>
      <DropdownToggle
        className={`dropddown-toggle-btn alchemy-btn--others ${
          classes ? classes : ''
        }`}>
        {text ? text : 'Export'}{' '}
        <img src={toggleIcon ? toggleIcon : ExportIcon} alt="export" />
      </DropdownToggle>
      <DropdownMenu>
        {dropdownItems &&
          dropdownItems.map(({ text, exportData }) => {
            return (
              <DropdownItem key={`${text}-key`}>
                {exportData && exportData.data && exportData.data.length > 0 && (
                  <CSVLink
                    key={`id-${text}`}
                    data={exportData.data}
                    headers={exportData.headers}
                    style={{ color: '#000' }}>
                    {text.toUpperCase()}
                  </CSVLink>
                )}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownButton;
