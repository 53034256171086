import React from 'react';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import initHelpHero from 'helphero';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import { withTheme } from 'styled-components';
import { motion } from 'framer-motion';
import Msg from '../components/ToastExportToPDF';
import { setCategoryStructure, setDisableCategoryStructure } from '../redux/reducers/app';
import { selectAdvancedBusinessTypes } from '../redux/reducers/filters';
import CustomCountSelect from '../components/CustomCountSelect';
import 'react-select/dist/react-select.css';
import 'url-search-params-polyfill';
import LaunchIcon from '@material-ui/icons/Launch';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import DataTypes from '../components/filters/DataTypes/DataTypes';
import { HOTJAR_HJID, HOTJAR_HJSV } from '../../config/constants';
import {
  getUserSystem,
  getDrillInfo,
  getOnlyDrillInfo,
  getUserRoles,
  getUserNamDashMeasures,
  hasSalesInPermissions,
  getUserId,
  hasRetailPermissions,
  hasPermission,
  membersCheck,
  hideFor,
  showFor,
  resetDrill,
  getUserSystemFormatted,
} from '../../helpers/util';
import { getReportTypeForDrill } from '../../helpers/helpers';
import AccountNames from '../components/filters/AccountNames/AccountNames';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import ExportIcon from '../../assets/images/export-icon.svg';
import { hotjar } from 'react-hotjar';
import ThemedModal from '../components/ThemedModal';
import getBrandLogoUrl from '../../helpers/brandHelpers/getBrandLogoUrl';
import { pageVariants } from '../../config/pageVariants';
import { fetchAccountNames, setAccountName } from '../redux/reducers/filters';
import {
  fetchCategories,
  setTimeFrame,
  setDepartment,
  setCategory,
  setSubCategory,
  setMember,
  setSupplier,
  setDepot,
  setOwnBrand,
  setBrand,
  setRegion,
  setFascia,
  setOnLoadParams,
  setReportSupplier,
  setReportMember,
  goToReferal,
  setTitle,
  setSubPage,
  setPurchase,
  setMeasure,
  setCoreRange,
  setMemberType,
  setRetailType,
  setLikeForLike,
  wholesalerSelect,
  supplierSelect,
  depotSelect,
  productSelect,
  brandSelect,
  businessSelect,
  setCurrentReportPage,
  setLevel4,
  exportToPDF,
  exportAllToCSV,
  resetReport,
  setLevel5,
  setBusinessType,
  loadReport,
  setDataType,
  setCommission,
  setExcludedDepartments,
  setGroup,
  setIsExportingToPDF,
} from '../redux/reducers/performanceReport';
import { resetFilters } from '../redux/reducers/filters';
import { setAuthUser } from '../redux/reducers/user';
import {
  exportTableToCSV,
  formatPerc,
  getRandomNumber,
} from '../../helpers/helpers';
import Select from 'react-select';
import { filter, map, difference } from 'lodash';
import TablePerformance from '../components/TablePerformance';
import GraphPerformance from '../components/GraphPerformance';
import GraphPerformancePDF from '../components/GraphPerformancePDF';
import Loading from '../components/Loading';
import { ToastContainer, toast } from 'react-toastify';
import { Drill } from '../redux/models/Drill';
import ReportContainer from '../components/ReportContainer';
import BusinessTypes from '../components/filters/BusinessTypes/BusinessTypes';
import { getUserData } from '../../helpers/user';
import Commissions from '../components/filters/Commissions';

let help = initHelpHero('4GgzMsn8Pp');

export const PerformanceReport = ({
  data,
  match,
  title,
  subPage,
  hideAll,
  departments,
  selectedDepartments,
  categories,
  selectedCategories,
  selectedSubCategories,
  subCategories,
  members,
  selectedMembers,
  suppliers,
  brands,
  brand,
  region,
  regions,
  fascia,
  fascias,
  ownBrands,
  ownBrand,
  supplier,
  depots,
  selectedDepots,
  level4s,
  selectedLevel4s,
  level5s,
  selectedLevel5s,
  onDepartmentChange,
  onCategoryChange,
  onExportData,
  onSubCategoryChange,
  timeframes,
  timeframe,
  accountNames,
  accountName,
  excludedDepartments,
  measures,
  coreRanges,
  memberTypes,
  retailTypes,
  likeForLikes,
  purchases,
  measure,
  coreRange,
  memberType,
  retailType,
  likeForLike,
  purchase,
  performanceDataGraph,
  performanceAllDataTable,
  performanceDataTable,
  onTimeFrameChange,
  onAccountNameChange,
  onMeasureChange,
  onCoreRangeChange,
  onMemberTypeChange,
  onRetailTypeChange,
  onLikeForLikeChange,
  onPurchaseChange,
  closeModal,
  onMemberChange,
  onSupplierChange,
  onDepotChange,
  onBrandChange,
  onRegionChange,
  onFasciaChange,
  onSelect,
  reportType,
  onModalSelect,
  onWholesalerSelect,
  onSupplierSelect,
  onProductSelect,
  onDepotSelect,
  onBrandSelect,
  onBackDrill,
  resolveBusinessTypes,
  resolveDepots,
  resolveCommissionDepartment,
  resolveMeasures,
  onLevel4Change,
  isLoading,
  isGraphLoaded,
  isTableLoaded,
  subpage,
  onLevel5Change,
  businessTypes,
  advancedBusinessTypes,
  selectedBusinessType,
  onBusinessTypeChange,
  toggleExportMenu,
  reportError,
  onRefreshReport,
  menus,
  onOwnBrandChange,
  dataType,
  dataTypes,
  commission,
  commissions,
  onDataTypeChange,
  onCommissionChange,
  onExcludedDepartmentsChange,
  onBusinessSelect,
  history,
  onResetReport,
  onGroupChange,
  groups,
  selectedGroup,
  onExportingToPDF,
  isExportingToPDF,
  isRollingDateRange,
  unlockRefresh,
  selectAdvancedBusinessTypes,
  isOwnCategoryStructure,
  setCategoryStructure,
  setDisableCategoryStructure,
}) => (
  <motion.div
    key={'PERFORMANCE-REPORT-ID-123415151'}
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}>
    <ToastContainer
      position="top-center"
      hideProgressBar={true}
      closeOnClick={false}
      pauseOnHover={false}
      closeButton={false}
      toastClassName={`c-alchemy-toasty`}
    />
    <div className="sub-menu-border bg-white">
      <div className="container">
        <div className="row">
          <div className="col sub-menu sub-menu__nam-dash d-md-flex justify-content-between pb-3">
            <div className="sub-menu__left">
              <div className="d-flex justify-content-start">
                <h2 className="text-uppercase mr-2">{title}</h2>
                {subPage.length > 0 && getOnlyDrillInfo(subPage) && (
                  <button
                    onClick={onBackDrill}
                    className="performance-back-btn mb-2">
                    <i className="fa fa-angle-double-left" aria-hidden="true" />{' '}
                    BACK
                  </button>
                )}
              </div>
              {hideFor('cjLang', 'countryRangeGroup', 'suntory') && (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Like For Like"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`min-wd-140 small-dropdown c-select-header c-select-header-row--others`}
                    name="likeForLike"
                    id="likeForLike"
                    value={likeForLike}
                    onChange={onLikeForLikeChange}
                    options={likeForLikes}
                    valueKey="ID"
                    labelKey="Text"
                    isMulti={false}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              )}
              {hasSalesInPermissions(match.params.subpage) ? (
                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Sales In"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`wd-100 small-dropdown c-select-header c-select-header-row--others`}
                    name="purchases"
                    id="purchases"
                    value={purchase}
                    onChange={onPurchaseChange}
                    options={purchases}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                  />
                </div>
              ) : (
                <span className="mr-1 btn-group text-uppercase btn-group text-black dropdown-align-fix">
                  SALES OUT
                </span>
              )}

              <div className="btn-group">
                <div
                  className={`btn-group text-uppercase d-flex align-items-center`}>
                  <span className="dropdown-align-fix">By: </span>
                </div>

                <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                  <Select
                    placeholder="Measure"
                    inputProps={{
                      autoComplete: 'off',
                      autoCorrect: 'off',
                      spellCheck: 'off',
                    }}
                    className={`small-dropdown c-select-header c-select-header-row--others`}
                    name="measure"
                    id="measure"
                    value={measure}
                    onChange={onMeasureChange}
                    options={resolveMeasures(measures, dataType)}
                    valueKey="ID"
                    labelKey="Text"
                    clearable={false}
                    searchable={false}
                    style={{
                      width:
                        dataType && dataType.Text === 'Retail'
                          ? '150px'
                          : '130px',
                      margin: '0px 5px',
                      fontSize: '12px',
                      padding: '0px',
                    }}
                  />
                </div>
              </div>

              <div className="btn-group text-uppercase">
                <span className="dropdown-align-fix">For Latest:</span>
              </div>
              <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                <Select
                  placeholder="Time"
                  inputProps={{
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    spellCheck: 'off',
                  }}
                  className={`wd-130 small-dropdown c-select-header c-select-header-row--others`}
                  name="timeframe"
                  id="timeframe"
                  value={timeframe}
                  onChange={onTimeFrameChange}
                  options={timeframes}
                  valueKey="ID"
                  labelKey="Text"
                  clearable={false}
                  searchable={false}
                  isMulti={false}
                />
              </div>

              {accountNames && accountNames.length > 0 && (
                <AccountNames
                  value={accountName}
                  options={accountNames}
                  onChange={onAccountNameChange}
                />
              )}

              {hasRetailPermissions(subPage) && (
                <>
                {hideFor("cjlangRetail") && (
                  <DataTypes
                    dataType={dataType}
                    onDataTypeChange={onDataTypeChange}
                    dataTypes={dataTypes}
                  />
                )}
                  
                  {!(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') && resolveCommissionDepartment(departments) && (
                    <Commissions
                    commission={commission}
                    onCommissionChange={onCommissionChange}
                    commissions={commissions}
                    />
                  )}
                </>
              )}
              {getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' && departments.length > 1 && (
                <>
                  <div className="btn-group text-uppercase ml-1 mr-1">
                    <span className="dropdown-align-fix">Exclude</span>
                  </div>
                  <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
                    <CustomCountSelect
                      className={`Select c-select-header c-select-header-row--others`}
                      name="excludedDepartment"
                      id="excludedDepartment"
                      onChangeCallback={ev => onExcludedDepartmentsChange(ev)}
                      isMulti={true}
                      value={excludedDepartments}
                      options={departments}
                      type="Department"
                      types="Departments"
                      placeholder="Department"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="sub-menu__right d-flex align-items-start">
              <div className="d-flex flex-wrap__wrap">
                <div>
                  <button
                    onClick={() => {
                      onResetReport();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    Reset
                    <RotateLeftIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => {
                      const win = window.open(
                        history.location.pathname,
                        '_blank',
                      );
                      win.focus();
                    }}
                    className="btn alchemy-btn--others d-flex mr-2">
                    New tab
                    <LaunchIcon
                      style={{
                        height: '1rem',
                        width: '1rem',
                        marginLeft: '2px',
                        marginTop: '1px',
                      }}
                    />
                  </button>
                </div>
                <div>
                  <Dropdown
                    isOpen={data.isExportMenuOpen}
                    toggle={() => toggleExportMenu()}>
                    <DropdownToggle
                      className={`dropddown-toggle-btn mr-2 alchemy-btn--others`}>
                      Export <img src={ExportIcon} alt="export" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportingToPDF();
                          onExportData('pdf');
                        }}>
                        PDF
                      </DropdownItem>

                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv');
                        }}>
                        CSV
                      </DropdownItem>
                      <DropdownItem
                        onClick={e => {
                          e.preventDefault();
                          onExportData('csv_all');
                        }}>
                        CSV - ALL
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    onClick={onRefreshReport}
                    disabled={data.isRefreshDisabled}
                    className={`btn alchemy-btn--others mr-2`}>
                    Refresh <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container sub-menu-bottom">
        <div className="row">
          <div className="col-12">
            <div className="nam-dash nam-dash-select-row">
              <div className="form-row">
                {departments.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {departments.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onDepartmentChange(ev)}
                        name="department"
                        id="department"
                        isMulti={true}
                        value={selectedDepartments}
                        options={departments}
                        type="Department"
                        types="Departments"
                        placeholder="Department"
                      />
                    ) : (
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="department"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="department"
                        placeholder="Department"
                        value={
                          selectedDepartments.length > 0
                            ? selectedDepartments[0].ID
                            : ''
                        }
                        onChange={opt => onDepartmentChange([opt])}
                        options={departments}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {categories.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {categories.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onCategoryChange(ev)}
                        name="category"
                        id="category"
                        isMulti={true}
                        value={selectedCategories}
                        options={categories}
                        type="Category"
                        types="Categories"
                        placeholder="Category"
                      />
                    ) : (
                      <Select
                        placeholder="Category"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="category"
                        id="category"
                        value={
                          selectedCategories && selectedCategories.length > 0
                            ? selectedCategories[0].ID
                            : ''
                        }
                        onChange={opt => onCategoryChange([opt])}
                        options={categories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {subCategories.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {subCategories.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onSubCategoryChange(ev)}
                        name="subcategory"
                        id="subcategory"
                        isMulti={true}
                        value={selectedSubCategories}
                        options={subCategories}
                        type="SubCategory"
                        types="SubCategories"
                        placeholder="SubCategory"
                      />
                    ) : (
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="subcategory"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="subcategory"
                        placeholder="SubCategory"
                        value={
                          selectedSubCategories &&
                          selectedSubCategories.length > 0
                            ? selectedSubCategories[0].ID
                            : ''
                        }
                        onChange={opt => onSubCategoryChange([opt])}
                        options={subCategories}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                )}
                {level4s.length > 1 ||
                (level4s.length === 1 &&
                  _.filter(level4s, lvl => lvl.Text !== 'NIL').length > 0) ? (
                  <div className="form-group col-md-5 col-lg-3">
                    {level4s.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onLevel4Change(ev)}
                        name="level4"
                        id="level4"
                        isMulti={true}
                        value={selectedLevel4s}
                        options={level4s}
                        type="Level4"
                        types="Level4s"
                        placeholder="Level4"
                      />
                    ) : (
                      <Select
                        placeholder="Level4"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="level4"
                        id="level4"
                        value={
                          selectedLevel4s && selectedLevel4s.length > 0
                            ? selectedLevel4s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel4Change(opt)}
                        options={level4s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                ) : (
                  <p />
                )}
                {level5s.length > 1 ||
                (level5s.length === 1 &&
                  _.filter(level5s, lvl => lvl.Text !== 'NIL').length > 0) ? (
                  <div className="form-group col-md-5 col-lg-3">
                    {level5s.length > 1 ? (
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onLevel5Change(ev)}
                        name="level5"
                        id="level5"
                        isMulti={true}
                        value={selectedLevel5s}
                        options={level5s}
                        type="Level5"
                        types="Level5s"
                        placeholder="Level5"
                      />
                    ) : (
                      <Select
                        placeholder="Level5"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="level5"
                        id="level5"
                        value={
                          selectedLevel5s && selectedLevel5s.length > 0
                            ? selectedLevel4s[0].ID
                            : ''
                        }
                        onChange={opt => onLevel5Change(opt)}
                        options={level5s}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    )}
                  </div>
                ) : (
                  <p />
                )}
                {reportType !== 'brand' && brands && brands.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Brand"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="brand"
                      id="brand"
                      value={brand.Brand}
                      onChange={onBrandChange}
                      options={brands}
                      valueKey="Brand"
                      labelKey="Brand"
                    />
                  </div>
                )}
                {regions && regions.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Region"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="region"
                      id="region"
                      value={region}
                      onChange={onRegionChange}
                      options={regions}
                      valueKey="value"
                      labelKey="value"
                    />
                  </div>
                )}
                {fascias && fascias.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    <Select
                      placeholder="Fascia"
                      inputProps={{
                        autoComplete: 'off',
                        autoCorrect: 'off',
                        spellCheck: 'off',
                      }}
                      className={`c-select-header c-select-header-row--others`}
                      name="fascia"
                      id="fascia"
                      value={fascia}
                      onChange={onFasciaChange}
                      options={fascias}
                      valueKey="value"
                      labelKey="value"
                    />
                  </div>
                )}
                {getUserSystem() === 'caterforce' &&
                  brands &&
                  brands.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Own Brand"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="ownbrand"
                        id="ownbrand"
                        value={ownBrand}
                        onChange={onOwnBrandChange}
                        options={ownBrands}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}

                {/* Group dropdown for demo system, used to filter members on demo system only */}

                {(getUserSystem() === 'caterforce' ||
                  getUserSystem() === 'countryRangeGroup') &&
                  departments &&
                  departments.length > 0 &&
                  groups &&
                  groups.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onGroupChange(ev)}
                        name="group"
                        id="group"
                        isMulti={true}
                        value={selectedGroup}
                        options={groups}
                        type={'Group Supplier'}
                        types={'Group Suppliers'}
                        placeholder={'Group Supplier'}
                      />
                    </div>
                  )}
                {/* Member dropdown for standard systems, hidden for demo system */}

                {reportType !== 'member' &&
                  members &&
                  membersCheck(members) &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name={getUserSystem() == 'suntory' ? "group" : "member"}
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                      />
                    </div>
                  )}

                {/* Member dropdown for DEMO SYSTEM ONLY, filtered based on group selection */}

                {showFor('deebee') &&
                  reportType !== 'member' &&
                  getUserRoles().filter(
                    role => role.Title === 'role_view_demo_group_performance',
                  ).length === 1 &&
                  selectedGroup &&
                  selectedGroup.length > 0 &&
                  getUserSystem() !== 'parfetts' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                        className={`Select c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onMemberChange(ev)}
                        name={getUserSystem() == 'suntory' ? "group" : "member"}
                        id="member"
                        isMulti={true}
                        value={selectedMembers}
                        options={
                          members && members.length > 0
                            ? members.map(mem => ({
                                Text: mem.ActualName,
                                ID: mem.WholesalerNo ?? mem.ActualName,
                              }))
                            : []
                        }
                        type={getUserSystem() == 'suntory' ? "Wholesaler" : "Member"}
                        types={getUserSystem() == 'suntory' ? "Wholesalers" : "Members"}
                        placeholder="Member"
                      />
                    </div>
                  )}

                {(reportType === 'member' || reportType !== 'supplier') &&
                  !hasPermission('role_view_caterforce_hide_category_totals') &&
                  suppliers &&
                  suppliers.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="supplier"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="supplier"
                        placeholder="Supplier"
                        value={supplier}
                        onChange={onSupplierChange}
                        options={suppliers}
                        valueKey="SupplierName"
                        labelKey="SupplierName"
                      />
                    </div>
                  )}
                {businessTypes && businessTypes.length > 0 && (
                  <div className="form-group col-md-5 col-lg-3">
                    {businessTypes.length > 1 ? (
                      <CustomCountSelect
                        className={`c-select-header c-select-header-row--others`}
                        onChangeCallback={ev => onBusinessTypeChange(ev)}
                        name="businessType"
                        id="businessType"
                        isMulti={true}
                        placeholder="Business Type"
                        value={selectedBusinessType}
                        options={resolveBusinessTypes(businessTypes)}
                        type="Business Type"
                        types="Business Types"
                      />
                    ) : (
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="businessType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="businessType"
                        placeholder="Business Type"
                        value={
                          businessTypes.length > 0 ? businessTypes[0].ID : ''
                        }
                        onChange={opt => onBusinessTypeChange([opt])}
                        options={businessTypes}
                        valueKey="BusinessName"
                        labelKey="BusinessName"
                      />
                    )}
                  </div>
                )}
                {(getUserSystem() === 'unitas') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        placeholder="Depot"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        className={`c-select-header c-select-header-row--others`}
                        name="depot"
                        id="depot"
                        multi
                        value={selectedDepots}
                        onChange={onDepotChange}
                        options={depots}
                        valueKey="DepotName"
                        labelKey="DepotName"
                      />
                    </div>
                  )}

                  {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew') &&
                  depots &&
                  depots.length > 0 && (
                    <div className="form-group col-md-5 col-lg-3">
                      <CustomCountSelect
                      className={`c-select-header c-select-header-row--others`}
                      onChangeCallback={ev => onDepotChange(ev)}
                      name="depot"
                      id="depot"
                      isMulti={true}
                      placeholder="Depot"
                      value={selectedDepots}
                      options={resolveDepots(depots)}
                      type="Depot"
                      types="Depots"
                    />
                  </div>
                  )}
                <BusinessTypes unlockRefresh={unlockRefresh} />
                {getUserSystem() == 'united' || getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="CoreRange"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="CoreRange"
                        placeholder="Core Range"
                        value={coreRange}
                        onChange={onCoreRangeChange}
                        options={coreRanges}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {getUserSystem() == 'unitas' && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="MemberType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="MemberType"
                        placeholder="Member Type"
                        value={memberType}
                        onChange={onMemberTypeChange}
                        options={memberTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
                  {dataType && dataType.Text === 'Retail' && hasPermission(
                      `role_view_${getUserSystem().toLowerCase()}_independent_data`,
                    ) && (
                    <div className="form-group col-md-5 col-lg-3">
                      <Select
                        className={`c-select-header c-select-header-row--others`}
                        name="RetailType"
                        inputProps={{
                          autoComplete: 'off',
                          autoCorrect: 'off',
                          spellCheck: 'off',
                        }}
                        id="RetailType"
                        placeholder="Retail Type"
                        value={retailType}
                        onChange={onRetailTypeChange}
                        options={retailTypes}
                        valueKey="ID"
                        labelKey="Text"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReportContainer>
      <div className="container pt-3">
        <div className="row">
          <div className="col-12 col-lg-7">
            <div
              className="nam-dash nam-dash-table-all"
              style={{ display: 'none' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: 'spring', duration: 0.5 }}>
                <TablePerformance
                  id="performance_table_all"
                  reportType={reportType}
                  data={performanceAllDataTable}
                  isLoading={isLoading}
                  measure={measure}
                  onSelect={onSelect}
                  isRollingDateRange={isRollingDateRange}
                />
              </motion.div>
            </div>
            <div className="nam-dash nam-dash-table">
              <div>{!isTableLoaded && <Loading />}</div>
              {reportError.length === 0 &&
                isTableLoaded &&
                !isLoading &&
                !hideAll && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ type: 'spring', duration: 0.5 }}>
                    <TablePerformance
                      id="performance_table"
                      reportType={reportType}
                      data={performanceDataTable}
                      isLoading={isLoading}
                      measure={measure}
                      onSelect={onSelect}
                      isRollingDateRange={isRollingDateRange}
                    />
                  </motion.div>
                )}
              {reportError.length > 0 && <p>{reportError}</p>}
              <ThemedModal
                isOpen={data.showModal}
                overlayClassName="performance-modal__overlay"
                isModalAnimating={data.isModalAnimating}
                className={`performance-modal`}>
                <div className="modal-title">
                  <h2>DRILL RESULTS</h2>
                  <span onClick={closeModal} style={{ cursor: 'pointer' }}>
                    &times;
                  </span>
                </div>
                <div className="d-flex performance-modal-container">
                  <div className="left-modal">
                    {reportType !== 'supplier' &&
                      menus.namdash.indexOf('supplier_performance') >= 0 && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onSupplierSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Supplier
                          </button>
                        </div>
                      )}
                    {getUserSystem() !== 'parfetts' &&
                      members.length > 1 &&
                      reportType !== 'member' &&
                      menus.namdash.indexOf('wholesaler_performance') >= 0 && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onWholesalerSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--others text-uppercase`}>
                            Member
                          </button>
                        </div>
                      )}
                    {(getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
                      getUserSystem() === 'unitas') &&
                      reportType !== 'depot' && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onDepotSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Depot
                          </button>
                        </div>
                      )}
                    {menus.namdash.indexOf('product_performance') >= 0 && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={onProductSelect}>
                        <button className="btn btn-info alchemy-btn--unitas text-uppercase">
                          Product
                        </button>
                      </div>
                    )}
                    {!hasPermission(
                      'role_view_caterforce_hide_business_type_dropdown',
                    ) &&
                      reportType !== 'business_type' &&
                      menus.namdash.indexOf('business_type_performance') >=
                        0 && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onBusinessSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--others text-uppercase`}>
                            Business Type
                          </button>
                        </div>
                      )}
                    {getUserSystem() !== 'parfetts' &&
                      reportType !== 'brand' &&
                      menus.namdash.indexOf('brand_performance') >= 0 &&
                      !isOwnCategoryStructure && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onBrandSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Brand
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="right-modal">
                    <div>
                      {(reportType === 'supplier' ||
                        reportType === 'member' ||
                        reportType === 'brand') &&
                        selectedDepartments.length > 0 && (
                          <div>
                            DEPARTMENT:{' '}
                            {departments.length > 1 ? (
                              <CustomCountSelect
                                className={`Select c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                onChangeCallback={ev => onDepartmentChange(ev)}
                                name="department"
                                id="department"
                                isMulti={true}
                                value={selectedDepartments}
                                options={departments}
                                type="Department"
                                types="Departmentss"
                                placeholder="Department"
                              />
                            ) : (
                              <Select
                                className={`c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                name="department"
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                multi={departments.length > 1 ? true : false}
                                id="department"
                                placeholder="Department"
                                value={
                                  selectedDepartments &&
                                  selectedDepartments.length > 0
                                    ? selectedDepartments[0].ID
                                    : ''
                                }
                                onChange={opt => onDepartmentChange(opt)}
                                options={departments}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            )}
                          </div>
                        )}
                      {(reportType === 'supplier' ||
                        reportType === 'member' ||
                        reportType === 'brand') &&
                        selectedCategories &&
                        selectedCategories.length > 0 && (
                          <div>
                            CATEGORY:{' '}
                            {categories.length > 1 ? (
                              <CustomCountSelect
                                className={`Select c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                onChangeCallback={ev => onCategoryChange(ev)}
                                name="category"
                                id="category"
                                isMulti={true}
                                value={selectedCategories}
                                options={categories}
                                type="Category"
                                types="Categories"
                                placeholder="Category"
                              />
                            ) : (
                              <Select
                                style={{
                                  minWidth: '200px',
                                  textAlign: 'left',
                                }}
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                className={`c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                name="category"
                                id="category"
                                value={
                                  selectedCategories &&
                                  selectedCategories.length > 0
                                    ? selectedCategories[0].ID
                                    : ''
                                }
                                onChange={opt => onCategoryChange(opt)}
                                options={categories}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            )}
                          </div>
                        )}
                      {(reportType === 'supplier' ||
                        reportType === 'member' ||
                        reportType === 'brand') &&
                        selectedCategories &&
                        selectedCategories.length > 0 &&
                        selectedSubCategories &&
                        selectedSubCategories.length > 0 && (
                          <div>
                            SUB CATEGORY:{' '}
                            {subCategories.length > 1 ? (
                              <CustomCountSelect
                                className={`Select c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                onChangeCallback={ev => onSubCategoryChange(ev)}
                                name="subcategory"
                                id="subcategory"
                                isMulti={true}
                                value={selectedSubCategories}
                                options={subCategories}
                                type="SubCategory"
                                types="SubCategories"
                                placeholder="SubCategory"
                              />
                            ) : (
                              <Select
                                style={{
                                  minWidth: '200px',
                                  textAlign: 'left',
                                }}
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                className={`c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                name="subcategory"
                                id="subcategory"
                                value={
                                  selectedSubCategories &&
                                  selectedSubCategories.length > 0
                                    ? selectedSubCategories[0].ID
                                    : ''
                                }
                                onChange={opt => onSubCategoryChange(opt)}
                                options={subCategories}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            )}
                          </div>
                        )}
                      {(reportType === 'supplier' ||
                        reportType === 'member' ||
                        reportType === 'brand') &&
                        selectedCategories &&
                        selectedCategories.length > 0 &&
                        selectedSubCategories &&
                        selectedSubCategories.length > 0 &&
                        selectedLevel4s &&
                        selectedLevel4s.length > 0 && (
                          <div>
                            LEVEL 4:{' '}
                            {level4s.length > 1 ? (
                              <CustomCountSelect
                                className={`Select c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                onChangeCallback={ev => onLevel4Change(ev)}
                                name="level4"
                                id="level4"
                                isMulti={true}
                                value={selectedLevel4s}
                                options={level4s}
                                type="Level4"
                                types="Level4s"
                                placeholder="Level4"
                              />
                            ) : (
                              <Select
                                style={{
                                  minWidth: '200px',
                                  textAlign: 'left',
                                }}
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                className={`c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                name="level4"
                                id="level4"
                                value={
                                  selectedLevel4s && selectedLevel4s.length > 0
                                    ? selectedLevel4s[0].ID
                                    : ''
                                }
                                onChange={opt => onLevel4Change(opt)}
                                options={level4s}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            )}
                          </div>
                        )}
                      {(reportType === 'supplier' ||
                        reportType === 'member' ||
                        reportType === 'brand') &&
                        selectedCategories &&
                        selectedCategories.length > 0 &&
                        selectedSubCategories &&
                        selectedSubCategories.length > 0 &&
                        selectedLevel4s &&
                        selectedLevel4s.length > 0 &&
                        selectedLevel5s &&
                        selectedLevel5s.length > 0 && (
                          <div>
                            LEVEL 5:{' '}
                            {level5s.length > 1 ? (
                              <CustomCountSelect
                                className={`Select c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                onChangeCallback={ev => onLevel5Change(ev)}
                                name="level5"
                                id="level5"
                                isMulti={true}
                                value={selectedLevel5s}
                                options={level5s}
                                type="Level5"
                                types="Level5s"
                                placeholder="Level5"
                              />
                            ) : (
                              <Select
                                style={{
                                  minWidth: '200px',
                                  textAlign: 'left',
                                }}
                                inputProps={{
                                  autoComplete: 'off',
                                  autoCorrect: 'off',
                                  spellCheck: 'off',
                                }}
                                className={`c-select-header ${
                                  getUserSystem() === 'unitas'
                                    ? 'c-select-header-row--unitas'
                                    : 'c-select-header-row--others'
                                }`}
                                name="level5"
                                id="level5"
                                value={
                                  selectedLevel5s && selectedLevel5s.length > 0
                                    ? selectedLevel5s[0].ID
                                    : ''
                                }
                                onChange={opt => onLevel5Change(opt)}
                                options={level5s}
                                valueKey="ID"
                                labelKey="Text"
                              />
                            )}
                          </div>
                        )}
                      {reportType === 'department' && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onModalSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Category
                          </button>
                        </div>
                      )}
                      {reportType === 'category' && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onModalSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Sub Category
                          </button>
                        </div>
                      )}
                      {reportType === 'subcategory' && (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={onModalSelect}>
                          <button
                            className={`btn btn-info alchemy-btn--${
                              getUserSystem() === 'unitas' ? 'unitas' : 'others'
                            } text-uppercase`}>
                            Level 4
                          </button>
                        </div>
                      )}
                      {reportType === 'level4' &&
                        (getUserSystem() !== 'countryRangeGroup' ||
                          getUserSystem() !== 'unitas') && (
                          <div
                            style={{ cursor: 'pointer' }}
                            onClick={onModalSelect}>
                            <button
                              className={`btn btn-info alchemy-btn--${
                                getUserSystem() === 'unitas'
                                  ? 'unitas'
                                  : 'others'
                              } text-uppercase`}>
                              Level 5
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </ThemedModal>
            </div>
          </div>
          <div className="col-12 col-lg-5 nam-dash-graph" id="nam-dash-graph">
            <div>{!isGraphLoaded && <Loading />}</div>
            {isGraphLoaded 
          && !isLoading 
          && !hideAll 
          && (measure != 'customer_count' 
              || performanceDataGraph.length > 0) && (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', duration: 1 }}>
                <GraphPerformance
                  data={performanceDataGraph}
                  section="NamDash"
                  isLoading={isLoading}
                  formatter={n => formatPerc(n)}
                />
              </motion.div>
            )}
          </div>{' '}
          {isGraphLoaded 
          && !isLoading 
          && !hideAll 
          && (measure != 'customer_count' 
              || performanceDataGraph.length > 0) && (
            <div
              id="pdf-wrapper"
              className={`col-12 `}
              style={{
                position: 'absolute',
                opacity: '0',
                pointerEvents: 'none',
                top: '-1000px',
                left: 0,
              }}>
              <GraphPerformancePDF data={performanceDataGraph} />
            </div>
          )}
        </div>
      </div>
    </ReportContainer>
  </motion.div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    departments: state.performanceReport.departments,
    categories: state.performanceReport.categories,
    hideAll: state.performanceReport.hideAll,
    subCategories: state.performanceReport.subCategories,
    selectedDepartments: state.performanceReport.selectedDepartments,
    selectedCategories: state.performanceReport.selectedCategories,
    selectedSubCategories: state.performanceReport.selectedSubCategories,
    members: state.performanceReport.members,
    selectedMembers: state.performanceReport.selectedMembers,
    suppliers: state.performanceReport.suppliers,
    depots: state.performanceReport.depots,
    selectedDepots: state.performanceReport.selectedDepots,
    supplier: state.performanceReport.supplier,
    brands: state.performanceReport.brands,
    brand: state.performanceReport.brand,
    region: state.performanceReport.region,
    regions: state.performanceReport.regions,
    fascia: state.performanceReport.fascia,
    fascias: state.performanceReport.fascias,
    ownBrands: state.performanceReport.ownBrands,
    ownBrand: state.performanceReport.ownBrand,
    timeframes: state.performanceReport.timeframes,
    timeframe: state.performanceReport.timeframe,
    accountName: state.filters.accountName,
    accountNames: state.filters.accountNames,
    excludedDepartments: state.performanceReport.excludedDepartments,
    purchase: state.performanceReport.purchase,
    purchases: state.performanceReport.purchases,
    measure: state.performanceReport.measure,
    measures: state.performanceReport.measures,
    likeForLike: state.performanceReport.likeForLike,
    likeForLikes: state.performanceReport.likeForLikes,
    memberType: state.performanceReport.memberType,
    memberTypes: state.performanceReport.memberTypes,
    retailType: state.performanceReport.retailType,
    retailTypes: state.performanceReport.retailTypes,
    coreRange: state.performanceReport.coreRange,
    coreRanges: state.performanceReport.coreRanges,
    level4s: state.performanceReport.level4s,
    selectedLevel4s: state.performanceReport.selectedLevel4s,
    level5s: state.performanceReport.level5s,
    selectedLevel5s: state.performanceReport.selectedLevel5s,
    performanceDataGraph: state.performanceReport.performanceDataGraph,
    performanceDataTable: state.performanceReport.performanceDataTable,
    performanceAllDataTable: state.performanceReport.performanceAllDataTable,
    title: state.performanceReport.title,
    subPage: state.performanceReport.subPage,
    reportType: state.performanceReport.reportType,
    isLoading: state.performanceReport.isLoading,
    isExporting: state.performanceReport.isExporting,
    isExported: state.performanceReport.isExported,
    pdfURL: state.performanceReport.pdfURL,
    isLoaded: state.performanceReport.isLoaded,
    isInitialLoadComplete: state.performanceReport.isInitialLoadComplete,
    isTableLoaded: state.performanceReport.isTableLoaded,
    isGraphLoaded: state.performanceReport.isGraphLoaded,
    businessTypes: state.performanceReport.businessTypes,
    selectedBusinessType: state.performanceReport.selectedBusinessType,
    reportError: state.performanceReport.reportError,
    authUser: state.user.authUser,
    menus: state.app.menus,
    dataType: state.performanceReport.dataType,
    dataTypes: state.performanceReport.dataTypes,
    commissions: state.performanceReport.commissions,
    commission: state.performanceReport.commission,
    groups: state.performanceReport.groups,
    selectedGroup: state.performanceReport.selectedGroup,
    isExportingToPDF: state.performanceReport.isExportingToPDF,
    isDrill: state.performanceReport.isDrill,
    isRollingDateRange: state.performanceReport.isRollingDateRange,
    isOwnCategoryStructure: state.app.categoryStructure,
    advancedBusinessTypes: state.filters.advancedBusinessTypes,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    setTimeFrame,
    setMeasure,
    setLikeForLike,
    setCoreRange,
    setMemberType,
    setRetailType,
    setPurchase,
    setTitle,
    setSubPage,
    setDepartment,
    setCategory,
    setSubCategory,
    setBrand,
    setRegion,
    setFascia,
    setOwnBrand,
    setMember,
    setSupplier,
    setDepot,
    setBusinessType,
    fetchAccountNames,
    setReportSupplier,
    setReportMember,
    setLevel4,
    wholesalerSelect,
    productSelect,
    brandSelect,
    depotSelect,
    supplierSelect,
    businessSelect,
    goToReferal,
    setOnLoadParams,
    setCurrentReportPage,
    resetReport,
    exportToPDF,
    exportAllToCSV,
    setLevel5,
    setAuthUser,
    loadReport,
    setDataType,
    setCommission,
    setExcludedDepartments,
    setGroup,
    setAccountName,
    setIsExportingToPDF,
    resetFilters,
    selectAdvancedBusinessTypes,
    setCategoryStructure,
    setDisableCategoryStructure,
  }),
  lifecycle({
    componentDidMount() {
      help.identify(getUserId());
      this.props.resetReport();
      if (getUserSystem() == "cjlangRetail") {
        this.props.setDataType({ ID: 1, Text: 'Retail' });
      }
      this.props.resetFilters();
      this.props.setDisableCategoryStructure(true);
      this.props.setCategoryStructure(false);
      this.props.setLikeForLike('like_for_like');
      this.props.setSubPage(this.props.match.params.subpage);
      const isSkip = false;

      if (this.props.match.params.subpage === 'category_performance') {
        this.props.setCurrentReportPage('category');
        this.props.fetchCategories('category', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle(
          'CATEGORY PERFORMANCE',
          getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
            getUserSystem() === 'countryRangeGroup' ||
            getUserSystem() === 'cjlang' ||
            getUserSystem() === 'unitas' ||
            getUserSystem() === 'caterforce' ||
            getUserSystem() === 'demo'
            ? 'department'
            : 'category',
        );
      } else if (
        this.props.match.params.subpage === 'business_type_performance'
      ) {
        this.props.setCurrentReportPage('business_type');
        this.props.fetchCategories('business_type', isSkip, {
          timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        });
        this.props.setTitle('BUSINESS TYPE PERFORMANCE', 'business_type');
      } else if (this.props.match.params.subpage === 'supplier_performance') {
        this.props.setCurrentReportPage('supplier');
        this.props.fetchCategories('supplier', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle('SUPPLIER PERFORMANCE', 'supplier');
      } else if (this.props.match.params.subpage === 'brand_performance') {
        this.props.setCurrentReportPage('brand');
        this.props.fetchCategories('brand', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle('BRAND PERFORMANCE', 'brand');
      } else if (this.props.match.params.subpage === 'wholesaler_performance') {
        this.props.setCurrentReportPage('member');
        this.props.fetchCategories('member', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle('WHOLESALER PERFORMANCE', 'member');
      } else if (this.props.match.params.subpage === 'depot_performance') {
        this.props.setCurrentReportPage('depot');
        this.props.fetchCategories('depot', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle('DEPOT PERFORMANCE', 'depot');
      } else if (this.props.match.params.subpage === 'group_performance') {
        this.props.setCurrentReportPage('group');
        this.props.fetchCategories('group', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        this.props.setTitle('GROUP PERFORMANCE', 'group');
      }

      if (this.props.authUser.userId.length === 0) {
        this.props.setAuthUser();
      }
    },
    componentDidUpdate(nextProps) {
      if (
        this.props.accountNames.length === 0 &&
        !this.props.data.isAccountNamesFetched
      ) {
        this.props.fetchAccountNames();
        this.props.handleData({
          ...this.props.data,
          isAccountNamesFetched: true,
        });
      }

      if (
        nextProps.isOwnCategoryStructure !== this.props.isOwnCategoryStructure
      ) {
        this.props.setLikeForLike('like_for_like');
        this.props.setSubPage(this.props.match.params.subpage);
        const isSkip = false;

        if (this.props.match.params.subpage === 'category_performance') {
          this.props.setCurrentReportPage('category');
          this.props.fetchCategories('category', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle(
            'CATEGORY PERFORMANCE',
            getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
              getUserSystem() === 'countryRangeGroup' ||
              getUserSystem() === 'cjlang' ||
              getUserSystem() === 'unitas' ||
              getUserSystem() === 'caterforce' ||
              getUserSystem() === 'demo'
              ? 'department'
              : 'category',
          );
        } else if (
          this.props.match.params.subpage === 'business_type_performance'
        ) {
          this.props.setCurrentReportPage('business_type');
          this.props.fetchCategories('business_type', isSkip, {
            timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
          });
          this.props.setTitle('BUSINESS TYPE PERFORMANCE', 'business_type');
        } else if (this.props.match.params.subpage === 'supplier_performance') {
          this.props.setCurrentReportPage('supplier');
          this.props.fetchCategories('supplier', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle('SUPPLIER PERFORMANCE', 'supplier');
        } else if (this.props.match.params.subpage === 'brand_performance') {
          this.props.setCurrentReportPage('brand');
          this.props.fetchCategories('brand', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle('BRAND PERFORMANCE', 'brand');
        } else if (
          this.props.match.params.subpage === 'wholesaler_performance'
        ) {
          this.props.setCurrentReportPage('member');
          this.props.fetchCategories('member', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle('WHOLESALER PERFORMANCE', 'member');
        } else if (this.props.match.params.subpage === 'depot_performance') {
          this.props.setCurrentReportPage('depot');
          this.props.fetchCategories('depot', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle('DEPOT PERFORMANCE', 'depot');
        } else if (this.props.match.params.subpage === 'group_performance') {
          this.props.setCurrentReportPage('group');
          this.props.fetchCategories('group', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
          this.props.setTitle('GROUP PERFORMANCE', 'group');
        }
      }
      if (
        nextProps.isInitialLoadComplete !== this.props.isInitialLoadComplete &&
        this.props.isInitialLoadComplete
      ) {
        setTimeout(() => {
          hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
          setTimeout(() => {
            const user_data = JSON.parse(getUserData());
            window.hj('identify', user_data?.userId, {
              email: user_data?.email,
              page: this.props.title,
            });
          }, 2000);
        }, 2000);
      }
      if (nextProps.isLoaded !== this.props.isLoaded && this.props.isLoaded) {
        this.props.loadReport();
      }
      let drillObj = new Drill();
      let isSkip = false;
      if (
        nextProps.isLoading !== this.props.isLoading &&
        this.props.isLoading
      ) {
        toast.info('Loading Report. Please wait...', { autoClose: false });
      } else if (
        nextProps.isLoading !== this.props.isLoading &&
        !this.props.isLoading
      ) {
        toast.dismiss();

        drillObj = getDrillInfo(this.props.match.params.subpage) || new Drill();

        isSkip = drillObj.skip ? true : false;
      }
      if (
        nextProps.isExporting !== this.props.isExporting &&
        this.props.isExporting
      ) {
        toast.info('Exporting report. Please wait...');
      }
      if (
        nextProps.isExported !== this.props.isExported &&
        this.props.isExported
      ) {
        toast.info(<Msg pdf={this.props.pdfURL} type="performance" />, {
          autoClose: false,
        });
      }
      if (
        nextProps.performanceAllDataTable !==
          this.props.performanceAllDataTable &&
        this.props.performanceAllDataTable.length > 0
      ) {
        let reportType = '';
        switch (this.props.match.params.subpage) {
          case 'business_type_performance':
            reportType = 'BUSINESS TYPE PERFORMANCE';
            break;
          case 'category_performance':
            reportType = 'CATEGORY PERFORMANCE';
            break;
          case 'supplier_performance':
            reportType = 'SUPPLIER PERFORMANCE';
            break;
          case 'brand_performance':
            reportType = 'BRAND PERFORMANCE';
            break;
          case 'region_performance':
            reportType = 'REGION PERFORMANCE';
            break;
          case 'fascia_performance':
            reportType = 'FASCIA PERFORMANCE';
            break;
          case 'wholesaler_performance':
            reportType = 'WHOLESALER PERFORMNANCE';
            break;
          case 'depot_performance':
            reportType = 'DEPOT PERFORMANCE';
            break;
          case 'group_performance':
            reportType = 'GROUP PERFORMANCE';
            break;
        }
        let filters = '';
        filters +=
          this.props.selectedDepartments &&
          this.props.selectedDepartments.length > 0
            ? 'DEPARTMENT: ' +
              map(this.props.selectedDepartments, 'Text').join(',')
            : '';
        filters +=
          this.props.selectedCategories &&
          this.props.selectedCategories.length > 0
            ? ', CATEGORY: ' +
              map(this.props.selectedCategories, 'Text').join(',')
            : '';
        filters +=
          this.props.selectedSubCategories.Text &&
          this.props.selectedSubCategories.length > 0
            ? ', SUB_CATEGORY: ' +
              map(this.props.selectedSubCategories, 'Text').join(',')
            : '';
        filters +=
          this.props.selectedLevel4s && this.props.selectedLevel4s.length > 0
            ? ', LEVEL4: ' + map(this.props.selectedLevel4s, 'Text').join(',')
            : '';
        filters +=
          this.props.selectedLevel5s && this.props.selectedLevel5s.length > 0
            ? ', LEVEL5: ' + map(this.props.selectedLevel5s, 'Text').join(',')
            : '';
        filters +=
          this.props.selectedMembers && this.props.selectedMembers.length > 0
            ? ', MEMBERS: ' +
              this.props.selectedMembers.map(mem => mem.ActualName)
            : '';
        filters +=
          this.props.supplier.SupplierName &&
          this.props.supplier.SupplierName.length > 0
            ? ', SUPPLIER: ' + this.props.supplier.SupplierName
            : '';
        filters +=
          this.props.businessType && this.props.businessType.length > 0
            ? ', BUSINESS_TYPE: ' + this.props.businessType
            : '';
        exportTableToCSV(
          $('.nam-dash-table-all'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
          reportType,
          this.props.measure,
          this.props.coreRange,
          this.props.memberType,
          this.props.retailType,
          this.props.timeframe,
          this.props.likeForLike,
          filters,
        );
      }
      if (
        nextProps.match.params.subpage !== this.props.match.params.subpage ||
        nextProps.location.search !== this.props.location.search
      ) {
        this.props.setSubPage(this.props.match.params.subpage);
        if (this.props.match.params.subpage === 'category_performance') {
          this.props.setCurrentReportPage('category');
          this.props.fetchCategories('category', isSkip, drillObj.timeframe);
          this.props.setTitle(
            'CATEGORY PERFORMANCE',
            getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
              getUserSystem() === 'countryRangeGroup' ||
              getUserSystem() === 'cjlang' ||
              getUserSystem() === 'unitas' ||
              getUserSystem() === 'caterforce'
              ? 'department'
              : 'category',
          );
        } else if (
          this.props.match.params.subpage === 'business_type_performance'
        ) {
          this.props.setCurrentReportPage('business_type');
          this.props.fetchCategories('business_type', isSkip, drillObj);
          this.props.setTitle('BUSINESS TYPE PERFORMANCE', 'business_type');
        } else if (this.props.match.params.subpage === 'supplier_performance') {
          this.props.setCurrentReportPage('supplier');
          this.props.fetchCategories('supplier', isSkip, drillObj);
          this.props.setTitle('SUPPLIER PERFORMANCE', 'supplier');
        } else if (this.props.match.params.subpage === 'brand_performance') {
          this.props.setCurrentReportPage('brand');
          this.props.fetchCategories('brand', isSkip, drillObj);
          this.props.setTitle('BRAND PERFORMANCE', 'brand');
        } else if (this.props.match.params.subpage === 'region_performance') {
          this.props.setCurrentReportPage('region');
          this.props.fetchCategories('region', isSkip, drillObj);
          this.props.setTitle('REGION PERFORMANCE', 'region');
        } else if (this.props.match.params.subpage === 'fascia_performance') {
          this.props.setCurrentReportPage('fascia');
          this.props.fetchCategories('fascia', isSkip, drillObj);
          this.props.setTitle('FASCIA PERFORMANCE', 'fascia');
        } else if (
          this.props.match.params.subpage === 'wholesaler_performance'
        ) {
          this.props.setCurrentReportPage('member');
          this.props.fetchCategories('member', isSkip, drillObj);
          this.props.setTitle('WHOLESALER PERFORMANCE', 'member');
        } else if (this.props.match.params.subpage === 'depot_performance') {
          this.props.setCurrentReportPage('depot');
          this.props.fetchCategories('depot', isSkip, drillObj);
          this.props.setTitle('DEPOT PERFORMANCE', 'depot');
        } else if (this.props.match.params.subpage === 'group_performance') {
          this.props.setCurrentReportPage('group');
          this.props.fetchCategories('group', isSkip, drillObj);
          this.props.setTitle('GROUP PERFORMANCE', 'group');
        }
      }

      if (this.props.isDrill) {
        if (
          (nextProps.isLoading !== this.props.isLoading &&
            !this.props.isLoading) ||
          (this.props.departments !== nextProps.departments &&
            this.props.departments.length > 0) ||
          (this.props.categories !== nextProps.categories &&
            this.props.categories.length > 0)
        ) {
          let reportType = '';
          this.props.setSubPage(this.props.match.params.subpage);
          if (this.props.match.params.subpage === 'category_performance') {
            reportType =
              getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
              getUserSystem() === 'countryRangeGroup' ||
              getUserSystem() === 'cjlang' ||
              getUserSystem() === 'unitas' ||
              getUserSystem() === 'caterforce'
                ? 'department'
                : 'category';
          } else if (
            this.props.match.params.subpage === 'business_type_performance'
          ) {
            reportType = 'business_type';
          } else if (
            this.props.match.params.subpage === 'supplier_performance'
          ) {
            reportType = 'supplier';
          } else if (this.props.match.params.subpage === 'brand_performance') {
            reportType = 'brand';
          } else if (this.props.match.params.subpage === 'region_performance') {
            reportType = 'region';
          } else if (this.props.match.params.subpage === 'fascia_performance') {
            reportType = 'fascia';
          } else if (
            this.props.match.params.subpage === 'wholesaler_performance'
          ) {
            reportType = 'member';
          } else if (this.props.match.params.subpage === 'depot_performance') {
            reportType = 'depot';
          } else if (this.props.match.params.subpage === 'group_performance') {
            reportType = 'group';
          }
          let isDepartmentSelected = false;
          let isCategorySelected = false;
          let isSubCategorySelected = false;
          let isLevel4Selected = false;
          let isLevel5Selected = false;

          const departments =
            drillObj.departments && drillObj.departments.length > 0
              ? drillObj.departments.split(',')
              : [];

          // if (
          //   (departments && drillObj.skip !== 'department') ||
          //   (drillObj.skip === 'department' &&
          //     drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          // ) {
          //   isDepartmentSelected = true;
          //   if (
          //     reportType !== 'supplier' &&
          //     reportType !== 'brand' &&
          //     reportType !== 'member' &&
          //     reportType !== 'depot' &&
          //     reportType !== 'business_type'
          //   ) {
          //     reportType = 'category';
          //   }
          // }
          const categories =
            drillObj.categories && drillObj.categories.length > 0
              ? drillObj.categories.split(',')
              : [];

          const subCategories =
            drillObj.subCategories && drillObj.subCategories.length > 0
              ? drillObj.subCategories.split(',')
              : [];

          const level4s =
            drillObj.level4s && drillObj.level4s.length > 0
              ? drillObj.level4s.split(',')
              : [];

          const level5s =
            drillObj.level5s && drillObj.level5s.length > 0
              ? drillObj.level5s.split(',')
              : [];

          let passedBrand = '';
          if (
            drillObj.skip !== 'brand' ||
            (drillObj.skip === 'brand' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.brand !== null) {
              passedBrand = drillObj.brand;
            }
          }
          let passedMembers = [];
          if (
            (this.props.members &&
              this.props.members.length > 0 &&
              drillObj.skip !== 'members') ||
            (drillObj.skip === 'members' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.members !== null) {
              let members = [];
              let membs = drillObj.members;
              _.each(membs, memb => {
                const membb = _.filter(this.props.members, mem => {
                  return (
                    mem.WholesalerNo === memb.WholesalerNo ||
                    memb === mem.WholesalerNo
                  );
                })[0];
                members.push(membb);
              });
              passedMembers = members;
            }
          }
          let passedDepots = [];

          if (passedMembers.length === 0) {
            if (
              (this.props.depots &&
                this.props.depots.length > 0 &&
                drillObj.skip !== 'depots') ||
              (drillObj.skip === 'depots' &&
                drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
            ) {
              if (drillObj.depots !== null) {
                let depots = [];
                let depts =
                  drillObj.depots && drillObj.depots.indexOf(',') >= 0
                    ? drillObj.depots.split(',')
                    : !drillObj.depots
                    ? []
                    : drillObj.depots;
                _.each(depts, dept => {
                  _.each(this.props.depots, dpt => {
                    if (dpt.DepotName === dept) {
                      depots.push(dpt);
                    }
                  });
                });
                passedDepots = depots;
              }
            }
          } else {
            passedDepots =
              drillObj.depots && drillObj.depots.indexOf(',') >= 0
                ? drillObj.depots.split(',')
                : !drillObj.depots
                ? []
                : drillObj.depots;
          }

          let passedSupplier = '';
          if (
            (this.props.suppliers &&
              drillObj.skip !== 'supplier' &&
              this.props.suppliers.length > 0) ||
            (drillObj.skip === 'supplier' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.supplier !== null) {
              passedSupplier = drillObj.supplier;
            }
          }
          const measure = drillObj.measure;
          const coreRange = drillObj.coreRange;
          const memberType = drillObj.memberType;
          const retailType = drillObj.retailType;
          const timeframe = drillObj.timeframe;
          const accountName = drillObj.accountName;
          const likeForLike = drillObj.likeForLike;
          const type = drillObj.type;

          const selectedAdvancedBusinessTypes = drillObj.selectedAdvancedBusinessTypes;
          if (selectedAdvancedBusinessTypes?.length > 0) {
            selectedAdvancedBusinessTypes.forEach(businessType => {
              if (businessType.value.length > 0) {
                this.props.selectAdvancedBusinessTypes(businessType);
              }
            })
          }

          let ownBrand;
          if (drillObj.ownBrand && drillObj.ownBrand.Text) {
            ownBrand = drillObj.ownBrand.Text;
          }
          let passedBusinessType = '';
          if (
            drillObj.skip !== 'business_type' ||
            (drillObj.skip === 'business_type' &&
              drillObj.ref.indexOf(this.props.match.params.subpage) <= 0)
          ) {
            if (drillObj.businessType !== null) {
              passedBusinessType = drillObj.businessType;
            }
          }

          let dataType = '';
          if (drillObj.dataType !== null) {
            dataType = drillObj.dataType;
          }

          let commission = '';
          if (drillObj.commission !== null) {
            commission = drillObj.commission;
          }

          let passedGroups = [];
          if (drillObj.groups) {
            passedGroups = drillObj.groups;
          }

          if (
            this.props.departments.length > 0 ||
            this.props.categories.length > 0
          ) {
            const checkReportType = getReportTypeForDrill(drillObj, reportType);
            this.props.setOnLoadParams(
              {
                departments,
                categories,
                subCategories,
                level4s,
                level5s,
                members: passedMembers,
                supplier: passedSupplier,
                brand: passedBrand,
                measure,
                coreRange,
                memberType,
                retailType,
                timeframe,
                accountName,
                likeForLike,
                depots: passedDepots,
                businessType: passedBusinessType,
                type,
                ownBrand,
                dataType,
                commission,
                groups: passedGroups,
              },
              checkReportType,
            );
          }
        }
      }
      if (
        this.props.selectedDepartments.length > 0 &&
        nextProps.selectedDepartments !== this.props.selectedDepartments
      ) {
        this.props.setDepartment(this.props.selectedDepartments);
      }
    },
  }),
  withTheme,
  withState('data', 'handleData', {
    title: '',
    showModal: false,
    tempModalValue: '',
    isExportMenuOpen: false,
    isRefreshDisabled: true,
    isModalAnimating: false,
    isExportingToPDF: false,
    isAccountNamesFetched: false,
  }),
  withHandlers({
    onRefreshReport:
      ({ data, handleData, loadReport }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: true });
        loadReport();
      },
    toggleExportMenu:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isExportMenuOpen: !data.isExportMenuOpen });
      },
    onTimeFrameChange:
      ({ data, handleData, setTimeFrame }) =>
      opt => {
        if (opt) {
          setTimeFrame(opt.ID);
        } else {
          setTimeFrame('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onAccountNameChange:
      ({ data, handleData, setAccountName }) =>
      opt => {
        if (opt) {
          setAccountName(opt);
        } else {
          setAccountName([]);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCoreRangeChange:
      ({ data, handleData, setCoreRange }) =>
      opt => {
        if (opt) {
          setCoreRange(opt);
        } else {
          setCoreRange('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMemberTypeChange:
      ({ data, handleData, setMemberType }) =>
      opt => {
        if (opt) {
          setMemberType(opt);
        } else {
          setMemberType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRetailTypeChange:
      ({ data, handleData, setRetailType }) =>
      opt => {
        if (opt) {
          setRetailType(opt);
        } else {
          setRetailType('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onMeasureChange:
      ({ data, handleData, setMeasure }) =>
      opt => {
        if (opt) {
          setMeasure(opt.ID);
        } else {
          setMeasure('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onLikeForLikeChange:
      ({ data, handleData, setLikeForLike }) =>
      opt => {
        if (opt) {
          setLikeForLike(opt.ID);
        } else {
          setLikeForLike('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onPurchaseChange:
      ({ data, handleData, setPurchase }) =>
      opt => {
        if (opt) {
          setPurchase(opt.ID);
        } else {
          setPurchase('');
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onBackDrill: props => value => {
      const search = props.location.search;
      const params = new URLSearchParams(search);
      const drillObj = getDrillInfo(props.subPage) || new Drill();
      if (drillObj.ref !== undefined && drillObj.ref !== null) {
        props.goToReferal(drillObj.ref, drillObj.to);
      }
      switch (props.reportType) {
        case 'category':
          props.setDepartment([]);
        case 'subcategory':
          props.setCategory([]);
          break;
        case 'level4':
          props.setSubCategory([]);
          break;
        case 'level5':
          props.setLevel4([]);
          break;
        case 'member':
        case 'supplier':
        case 'brand':
        case 'depot':
          if (props.selectedLevel5s && props.selectedLevel5s.length > 0) {
            props.setLevel5([]);
          } else if (props.selectedLevel4s && props.selectedLevel4s.length > 0) {
            props.setLevel4([]);
          } else if (props.selectedSubCategories && props.selectedSubCategories.length > 0) {
            props.setSubCategory([]);
          } else if (props.selectedCategories && props.selectedCategories.length > 0) {
            props.setCategory([]);
          } else if (props.selectedDepartments && props.selectedDepartments.length > 0) {
            props.setDepartment([]);
          }
          break;
      }
    },
    onDepartmentChange:
      ({ data, handleData, setDepartment, selectedDepartments, departments }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedDepartments.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setDepartment(tempArray);
          } else {
            setDepartment(departments);
          }
        } else if (
          filter(selectedDepartments, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setDepartment([]);
        } else {
          setDepartment(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    resolveBusinessTypes: () => businessTypes => {
      businessTypes.forEach((type, index) => {
        type.Text = type.BusinessName;
        type.ID = index;
      });
      return businessTypes;
    },
    resolveDepots: () => depots => {
      depots.forEach((depot, index) => {
        depot.Text = depot.DepotName;
        depot.ID = index;
      });
      return depots;
    },
    resolveCommissionDepartment: () => (departments) => {
      let commissionDepartment = _.filter(departments, department => {
        if(department.Text == "COMMISSION"){
         return department;
        }
       });

       return commissionDepartment.length > 0;
    },
    resolveMeasures: () => (measures, dataType) => {
      return measures.filter(measure =>
        getUserNamDashMeasures(dataType.Text).includes(measure.ID.toLowerCase()));
    },
    onBusinessTypeChange:
      ({
        data,
        handleData,
        setBusinessType,
      }) =>
      selectedOptions => {
        setBusinessType(selectedOptions);
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onCategoryChange:
      ({ data, handleData, setCategory, selectedCategories, categories }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setCategory(tempArray);
          } else {
            setCategory(categories);
          }
        } else if (
          filter(selectedCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setCategory([]);
        } else {
          setCategory(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onSubCategoryChange:
      ({
        data,
        handleData,
        setSubCategory,
        selectedSubCategories,
        subCategories,
      }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedSubCategories.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setSubCategory(tempArray);
          } else {
            setSubCategory(subCategories);
          }
        } else if (
          filter(selectedSubCategories, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setSubCategory([]);
        } else {
          setSubCategory(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel4Change:
      ({ data, handleData, setLevel4, selectedLevel4s, level4s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel4s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel4(tempArray);
          } else {
            setLevel4(level4s);
          }
        } else if (
          (filter(selectedLevel4s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0) || selectedOptions == null
        ) {
          setLevel4([]);
        } else {
          setLevel4(Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onLevel5Change:
      ({ data, handleData, setLevel5, selectedLevel5s, level5s }) =>
      selectedOptions => {
        const isSelectAll = filter(selectedOptions, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (selectedLevel5s.length > selectedOptions.length){
            let tempArray = [...selectedOptions];
            tempArray.shift();
            setLevel5(tempArray);
          } else {
            setLevel5(level5s);
          }
        } else if (
          filter(selectedLevel5s, { ID: 0 }).length > 0 &&
          filter(selectedOptions, { ID: 0 }).length === 0
        ) {
          setLevel5([]);
        } else {
          setLevel5(selectedOptions);
        }
        handleData({ ...data, showModal: false, isRefreshDisabled: false });
      },
    onMemberChange:
      ({ data, handleData, setMember, members, reportType, setCategoryStructure, setDisableCategoryStructure }) =>
      selectedOption => {
        if (reportType !== 'depot' || membersCheck(members)) {
          setMember(selectedOption);
          handleData({ ...data, isRefreshDisabled: false });
        }
        if (getUserSystem() == "caterforce") {
          if (selectedOption?.length == 1) {
            setDisableCategoryStructure(false);
          } else {
            setCategoryStructure(false);
            setDisableCategoryStructure(true);
          }
        }
      },
    onSupplierChange:
      ({ data, handleData, setSupplier }) =>
      selectedOption => {
        if (selectedOption === null) {
          setSupplier(0);
        } else {
          setSupplier(selectedOption.SupplierName);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onDepotChange:
      ({ data, handleData, setDepot }) =>
      selectedOption => {
        setDepot(selectedOption);
        handleData({ ...data, isRefreshDisabled: false });
      },
    unlockRefresh:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isRefreshDisabled: false });
      },
    closeModal:
      ({ data, handleData }) =>
      () => {
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({ ...data, showModal: false, isModalAnimating: false });
        }, 300);
      },
    onProductSelect:
      ({ data, handleData, productSelect }) =>
      value => {
        productSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onBrandSelect:
      ({ data, handleData, brandSelect }) =>
      value => {
        brandSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onBrandChange:
      ({ data, handleData, setBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setBrand(0);
        } else {
          setBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onOwnBrandChange:
      ({ data, handleData, setOwnBrand }) =>
      selectedOption => {
        if (selectedOption === null) {
          setOwnBrand('');
        } else {
          setOwnBrand(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onRegionChange:
      ({ data, handleData, setRegion }) =>
      selectedOption => {
        if (selectedOption === null) {
          setRegion('');
        } else {
          setRegion(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onFasciaChange:
      ({ data, handleData, setFascia }) =>
      selectedOption => {
        if (selectedOption === null) {
          setFascia('');
        } else {
          setFascia(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onWholesalerSelect:
      ({ data, handleData, wholesalerSelect }) =>
      value => {
        wholesalerSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onSupplierSelect:
      ({ data, handleData, supplierSelect }) =>
      value => {
        supplierSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onDepotSelect:
      ({ data, handleData, depotSelect }) =>
      value => {
        depotSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onBusinessSelect:
      ({ data, handleData, businessSelect }) =>
      value => {
        businessSelect(data.tempModalValue);
        handleData({ ...data, isModalAnimating: true });
        setTimeout(() => {
          handleData({
            ...data,
            showModal: false,
            tempModalValue: '',
            isRefreshDisabled: false,
            isModalAnimating: false,
          });
        }, 300);
      },
    onSelect:
      ({ data, handleData }) =>
      value => {
        handleData({ ...data, showModal: true, tempModalValue: value });
      },
    onModalSelect:
      ({
        data,
        departments,
        categories,
        subCategories,
        level4s,
        level5s,
        handleData,
        loadReport,
        setDepartment,
        setCategory,
        setSubCategory,
        setLevel4,
        setLevel5,
        setReportSupplier,
        setReportMember,
        reportType,
        setBusinessType,
      }) =>
      value => {
        switch (reportType) {
          case 'department':
            const department = filter(departments, dept => {
              if (dept.Text === data.tempModalValue) {
                return dept;
              }
            });
            setDepartment(department);
            loadReport();
            break;
          case 'category':
            const category = filter(categories, cat => {
              if (cat.Text === data.tempModalValue) {
                return cat;
              }
            });
            setCategory(category);
            loadReport();
            break;
          case 'subcategory':
            const subCategory = filter(subCategories, subCat => {
              if (subCat.Text === data.tempModalValue) {
                return subCat;
              }
            });
            setSubCategory(subCategory);
            loadReport();
            break;
          case 'level4':
            const level4 = filter(level4s, lvl4 => {
              if (lvl4.Text === data.tempModalValue) {
                return lvl4;
              }
            });
            setLevel4(level4);
            loadReport();
            break;
          case 'level5':
            const level5 = filter(level5s, lvl5 => {
              if (lvl5.Text === data.tempModalValue) {
                return lvl5;
              }
            });
            setLevel5(level5);
            loadReport();
            break;
          case 'supplier':
            setReportSupplier(data.tempModalValue);
            break;
          case 'member':
            setReportMember(data.tempModalValue);
            break;
          case 'business_type':
            const businessType = filter(businessTypes, bus => {
              if (bus.Text === data.tempModalValue) {
                return bus;
              }
            });
            setBusinessType(businessType);
            loadReport();
            break;
        }
        handleData({ ...data, showModal: false, tempModalValue: '' });
      },
    onExportData: props => type => {
      let reportType = '';
      switch (props.match.params.subpage) {
        case 'business_type_performance':
          reportType = 'BUSINESS TYPE PERFORMANCE';
          break;
        case 'category_performance':
          reportType = 'CATEGORY PERFORMANCE';
          break;
        case 'supplier_performance':
          reportType = 'SUPPLIER PERFORMANCE';
          break;
        case 'brand_performance':
          reportType = 'BRAND PERFORMANCE';
          break;
        case 'region_performance':
          reportType = 'REGION PERFORMANCE';
          break;
        case 'fascia_performance':
          reportType = 'FASCIA PERFORMANCE';
          break;
        case 'wholesaler_performance':
          reportType = 'WHOLESALER PERFORMANCE';
          break;
        case 'depot_performance':
          reportType = 'DEPOT PERFORMANCE';
          break;
      }
      if (type === 'csv') {
        let filters = '';
        filters +=
          props.departments && props.departments.length > 0
            ? 'DEPARTMENT: ' + map(props.departments, 'Text').join(',')
            : '';
        filters +=
          props.categories && props.categories.length > 0
            ? ', CATEGORY: ' + map(props.categories, 'Text').join(',')
            : '';
        filters +=
          props.subCategories && props.subCategories.length > 0
            ? ', SUB_CATEGORY: ' + map(props.subCategories, 'Text').join(',')
            : '';
        filters +=
          props.level4s && props.level4s.length > 0
            ? ', LEVEL4: ' + map(props.level4s, 'Text').join(',')
            : '';
        filters +=
          props.level5s && props.level5s.length > 0
            ? ', LEVEL5: ' + map(props.level5s, 'Text').join(',')
            : '';
        filters +=
          props.selectedMembers && props.selectedMembers.length > 0
            ? ', MEMBERS: ' + props.selectedMembers.map(mem => mem.ActualName)
            : '';
        filters +=
          props.supplier.SupplierName && props.supplier.SupplierName.length > 0
            ? ', SUPPLIER: ' + props.supplier.SupplierName
            : '';
        filters +=
          props.businessType && props.businessType.length > 0
            ? ', BUSINESS_TYPE: ' + map(props.businessType, 'Text').join(',')
            : '';
        exportTableToCSV(
          $('.nam-dash-table'),
          `${getRandomNumber('export_')}.csv`,
          'drill',
          reportType,
          props.measure,
          props.coreRange,
          props.memberType,
          props.retailType,
          props.timeframe,
          props.accountName,
          props.likeForLike,
          filters,
        );
      }
      if (type === 'csv_all') {
        props.exportAllToCSV(props.match.params.subpage);
      }
      if (type === 'pdf') {
        let clonedObj = $('.nam-dash-table').clone(true);
        clonedObj.find('.drillTD').replaceWith('<span/>');
        let fields = ``;
        fields +=
          props.departments && props.departments.length > 0
            ? `<strong>DEPARTMENT</strong>: <ul>` +
              map(props.departments, item =>
                item.Text === 'SELECT ALL' ? '' : `<li>${item.Text}</li>`,
              )
                .join('')
                .toUpperCase() +
              `</ul>`
            : '';
        fields +=
          props.categories && props.categories.length > 0
            ? `<strong>CATEGORY</strong>: <ul>` +
              map(props.categories, item =>
                item.Text === 'SELECT ALL' ? '' : `<li>${item.Text}</li>`,
              )
                .join('')
                .toUpperCase() +
              `</ul>`
            : '';
        fields +=
          props.subCategories && props.subCategories.length > 0
            ? `<strong>SUB CATEGORY</strong>: <ul>` +
              map(props.subCategories, item =>
                item.Text === 'SELECT ALL' ? '' : `<li>${item.Text}</li>`,
              )
                .join('')
                .toUpperCase() +
              `</ul>`
            : '';
        fields +=
          props.level4s && props.level4s.length > 0
            ? `<strong>LEVEL4</strong>: <ul>` +
              map(props.level4s, item =>
                item.Text === 'SELECT ALL' ? '' : `<li>${item.Text}</li>`,
              )
                .join('')
                .toUpperCase() +
              `</ul>`
            : '';
        fields +=
          props.selectedMembers.length > 0
            ? `<strong>MEMBERS</strong>: <ul>` +
              props.selectedMembers.map(
                member => `<li>${member.ActualName}</li>`,
              ) +
              `</ul>`
            : '';
        fields += props.supplier.SupplierName
          ? `<strong>SUPPLIER</strong>: ` +
            props.supplier.SupplierName.toUpperCase() +
            `<br/>`
          : '';
        fields +=
          `<strong>LIKEFORLIKE</strong>: ` +
          props.likeForLike.toUpperCase() +
          `<br/><strong>TIMEFRAME</strong>: ` +
          props.timeframe.toUpperCase() +
          `<br/><strong>MEASURE</strong>: ` +
          props.measure.toUpperCase();
        fields +=
          props.selectedDepots?.length > 0
            ? `<br/><strong>DEPOT</strong>: <ul>` +
              props.selectedDepots.map(depot => `<li>${depot.DepotName}</li>`) +
              '</ul>'
            : '';

        //   nam-dash-graph
        html2canvas(document.getElementsByClassName('pdf-chart')[0], {
          allowTaint: false,
          useCORS: true,
          onclone: function (clonedDoc) {
            clonedDoc.getElementById('pdf-wrapper').style.opacity = '1';
          },
        }).then(function (canvas) {
          //  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">

          let htmlString = `<html>
              <head>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"/>
                <link rel="stylesheet" href="https://unitas-api.twcgroup.net/style.min.css"/>
                <link rel="stylesheet" href="https://unitas-api.twcgroup.net/pdf.css"/>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">

              </head>
              <style>
              body {
     padding: 5rem
    }

    header {
      width: 100%;
      background: ${props.theme.navBar} !important;
      display: flex;
      justify-content: flex-end;
    
      margin-bottom: 2rem;
    }

    footer {
      width: 100%;
      background: ${props.theme.navBar} !important;
      height: 60px;
      margin-top: 2rem;
    }

       ul {
                  display: flex;
                  justify-content: flex-start;
                  list-style: none;
                  padding: 0;
                  flex-wrap: wrap;
                }
                
                li {
                  border: 1px solid #000;
                  padding: 0.1rem .5rem;
                  border-radius: 10px;
                  margin-right: .5rem;
                  margin-bottom: .2rem
                }
    
    h1 {
      color: #000;
      font-size:20
    }
    
    .date {
      font-size: 2rem
    }
    
    .filter-values {
      font-weight: 700
    }

    tbody, thead {
      width: 100%;
      display: table;
    }

    td span {
    text-align: center;
    margin: 0 auto;
}

tr th:last-of-type, tr td:last-of-type {
  display: none
}

th, td {
    width: 20vw !important;
}

 table {
                  table-layout: fixed
                }

                 .bg-black.text-white { 
                color: #000 !important;
                text-align: center
                }


      .top-container {
        display: flex;
        justify-content: flex-start;
        align-items: self-start;
    }

    header .img-container {
            width: 15%;
            ${
              props.theme.name === 'unitas'
                ? 'background: #4a2683 !important;'
                : props.theme.name === 'countryRangeGroup'
                ? 'background: #363333 !important;'
                : ''
            }
    }

    .top-container img {
        max-width: 100%;
        height: auto
    }

     .bg-plus.pos-change {
    background : none !important;
    color: #6cbe58 !important 
  }
  
    .bg-plus.neg-change {
    background : none !important;
    color: red !important 
  }
              </style>
              <header> <div class="img-container"><img src="${getBrandLogoUrl(
                props.theme.name,
              )}"></div></header>
              <body style="height:1000px">
              
                  <div class="top-container">
                  <div> 
                  <h1>${reportType}</h1> 
                   <h2 class="date">${moment().format('MM/DD/YYYY')}</h2>
                  <div >
                    <p  class="filter-values">${fields}</p>
                  </div>
                  </div>
                  
                 </div>
                 
                  <div style="text-align:center; margin-bottom: 3rem">
                  <img src=${canvas.toDataURL()} style="margin:0px auto; display: block;" />
                  </div>                  
                  <div style="margin-bottom:0;text-align:center;margin:0px auto;">${clonedObj.html()}</div>
                  
                  </body>
          </html>`;
          props.exportToPDF(htmlString);
        });
      }
    },
    onExportingToPDF:
      ({ setIsExportingToPDF }) =>
      () => {
        setIsExportingToPDF(true);
      },
    onDataTypeChange:
      ({ setDataType, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setDataType('');
        } else {
          setDataType(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onCommissionChange:
      ({ setCommission, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setCommission('');
        } else {
          setCommission(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onExcludedDepartmentsChange:
      ({ setExcludedDepartments, data, handleData, departments, excludedDepartments}) =>
      opt => {
        const isSelectAll = filter(opt, { ID: 0 }).length > 0;
        if (isSelectAll) {
          if (excludedDepartments.length > opt.length){
            let tempArray = [...opt];
            tempArray.shift();
            setExcludedDepartments(tempArray);
          } else {
            setExcludedDepartments(departments);
          }
        } else if (
          filter(excludedDepartments, { ID: 0 }).length > 0 &&
          filter(opt, { ID: 0 }).length === 0
        ) {
          setExcludedDepartments([]);
        } else {
          setExcludedDepartments(opt);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onGroupChange:
      ({ setGroup, data, handleData }) =>
      selectedOption => {
        if (selectedOption === null) {
          setGroup('');
        } else {
          setGroup(selectedOption);
        }
        handleData({ ...data, isRefreshDisabled: false });
      },
    onResetReport: props => () => {
      props.resetReport();
      props.resetFilters();
      resetDrill();
      props.setLikeForLike('like_for_like');
      props.setSubPage(props.match.params.subpage);
      const isSkip = false;

      if (props.match.params.subpage === 'category_performance') {
        props.setCurrentReportPage('category');
        props.fetchCategories('category', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        props.setTitle(
          'CATEGORY PERFORMANCE',
          getUserSystem() === 'parfetts' || getUserSystem() === 'parfettsNew' ||
            getUserSystem() === 'countryRangeGroup' ||
            getUserSystem() === 'cjlang' ||
            getUserSystem() === 'unitas' ||
            getUserSystem() === 'caterforce'
            ? 'department'
            : 'category',
        );
      } else if (props.match.params.subpage === 'business_type_performance') {
        props.setCurrentReportPage('business_type');
        props.fetchCategories('business_type', isSkip, {
          timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        });
        props.setTitle('BUSINESS TYPE PERFORMANCE', 'business_type');
      } else if (props.match.params.subpage === 'supplier_performance') {
        props.setCurrentReportPage('supplier');
        props.fetchCategories('supplier', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        props.setTitle('SUPPLIER PERFORMANCE', 'supplier');
      } else if (props.match.params.subpage === 'brand_performance') {
        props.setCurrentReportPage('brand');
        props.fetchCategories('brand', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        props.setTitle('BRAND PERFORMANCE', 'brand');
      } else if (props.match.params.subpage === 'wholesaler_performance') {
        props.setCurrentReportPage('member');
        props.fetchCategories('member', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        props.setTitle('WHOLESALER PERFORMANCE', 'member');
      } else if (props.match.params.subpage === 'depot_performance') {
        props.setCurrentReportPage('depot');
        props.fetchCategories('depot', isSkip, { timeframe: (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks') });
        props.setTitle('DEPOT PERFORMANCE', 'depot');
      }

      if (props.authUser.userId.length === 0) {
        props.setAuthUser();
      }
    },
  }),
)(PerformanceReport);