import { handleActions, createAction } from 'redux-actions';
import { request } from '../../../helpers/axios';
import { getToken, getUserId, getUserSystem } from '../../../helpers/util';
import store from '../store';

const SET_MENU_OPTIONS = 'app/SET_MENU_OPTIONS';
const SET_SUB_PAGE_NAV = 'app/SET_SUB_PAGE_NAV';
const SET_BRAND = 'app/SET_BRAND';
const SET_BLUR = 'app/SET_BLUR';
const SHOW_WEEKLY_UPDATE = 'app/SHOW_WEEKLY_UPDATE';
const SET_TIME_LABEL = 'app/SET_TIME_LABEL';
const SET_CATEGORY_STRUCTURE = 'app/SET_CATEGORY_STRUCTURE';
const SET_CATEGORY_STRUCTURE_DISABLED = 'app/SET_CATEGORY_STRUCTURE_DISABLED';

const initialState = {
  menus: {
    namdash: [],
    analysis: [],
    psa: [],
  },
  showBlur: false,
  brand: '',
  subpage_nav: [],
  showWeeklyUpdate: false,
  categoryStructure: false,
  categoryStructureDisabled: true,
};

const setMenuOptionsAction = createAction(SET_MENU_OPTIONS);
const setSubPageNavAction = createAction(SET_SUB_PAGE_NAV);
const setBrandAction = createAction(SET_BRAND);
const setBlurAction = createAction(SET_BLUR);
const showWeeklyUpdateAction = createAction(SHOW_WEEKLY_UPDATE);
const setTimeLabelAction = createAction(SET_TIME_LABEL);
const setCategoryStructureAction = createAction(SET_CATEGORY_STRUCTURE);
const setDisableCategoryStructureAction = createAction(SET_CATEGORY_STRUCTURE_DISABLED);

export const getTimeLabel = () => dispatch => {
  let url = '/route.php?c=date/getTimeLabel';
  request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(result => {
    dispatch(setTimeLabelAction(result.Label));
  });
};

export const fetchMembers = () => dispatch => {
  const url = '/route.php?c=wholesaler/getAll&show_competitor=1';
  request({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getToken(),
      UserId: getUserId(),
      System: getUserSystem(),
    },
  }).then(members => {
    if (members.length > 1) {
      dispatch(showWeeklyUpdateAction());
    }
    const enableWeeklyUpdates = [
      'demo',
      'cjlang',
      'cjlangRetail',
      'caterforce',
      'confex',
      'united',
      'deebee',
      'parfettsnew',
      'cotswold',
    ];
    if (enableWeeklyUpdates.includes(getUserSystem().toLowerCase())) {
      dispatch(showWeeklyUpdateAction(true));
    }
  });
};

export const setBlur = val => dispatch => {
  dispatch(setBlurAction(val));
};

export const setBrand = val => dispatch => {
  dispatch(setBrandAction(val));
};
export const setCategoryStructure = (value) => dispatch => {
  dispatch(setCategoryStructureAction(value));
};

export const setDisableCategoryStructure = (value) => dispatch => {
  dispatch(setDisableCategoryStructureAction(value));
};

export const setSubPageNav = options => dispatch => {
  localStorage.setItem('subpage_nav', JSON.stringify(options));
  dispatch(setSubPageNavAction(options));
};

export const setMenuOptions = options => dispatch => {
  localStorage.setItem('menus', JSON.stringify(options));
  dispatch(setMenuOptionsAction(options));
};

export default handleActions(
  {
    [SET_TIME_LABEL]: (state, { payload }) => ({
      ...state,
      timeLabel: payload,
    }),
    [SHOW_WEEKLY_UPDATE]: (state, { payload }) => ({
      ...state,
      showWeeklyUpdate: true,
    }),
    [SET_MENU_OPTIONS]: (state, { payload }) => ({ ...state, menus: payload }),
    [SET_SUB_PAGE_NAV]: (state, { payload }) => ({
      ...state,
      subpage_nav: payload,
    }),
    [SET_BRAND]: (state, { payload }) => ({
      ...state,
      brand: payload,
    }),
    [SET_CATEGORY_STRUCTURE]: (state, { payload }) => ({
      ...state,
      categoryStructure: payload,
    }),
    [SET_CATEGORY_STRUCTURE_DISABLED]: (state, { payload }) => ({
      ...state,
      categoryStructureDisabled: payload,
    }),
    [SET_BLUR]: (state, { payload }) => ({
      ...state,
      showBlur: payload,
    }),
  },
  initialState,
);

export const getMenus = () => dispatch => {
  const state = store.getState().app;
  if (
    state.menus.namdash.length === 0 &&
    state.menus.analysis.length === 0 &&
    state.menus.psa.length === 0 &&
    localStorage.getItem('menus')
  ) {
    const menus = JSON.parse(localStorage.getItem('menus'));
    dispatch(setMenuOptionsAction(menus));
    return menus;
  } else {
    return state.menus;
  }
};

export const getSubPageNav = () => dispatch => {
  const state = store.getState().app;
  if (
    !state.subPageNav ||
    (state.subPageNav && state.subPageNav.length === 0)
  ) {
    const subpage_nav = JSON.parse(localStorage.getItem('subpage_nav'));
    dispatch(setSubPageNavAction(subpage_nav));
    return subpage_nav;
  } else {
    return state.subpage_nav;
  }
};

export const selectors = {
  getMenus,
};
