import styled from 'styled-components';

const StyledContainer = styled.div`
 
 background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  margin:  1rem 0;
  position: relative;

  div[role='rowgroup'] div[role='row'] div[role='gridcell']:first-child {
   
    color: #000;
    text-align: left;
    font-weight: 600;
  }

.rdt_Table < div {
	overflow-x: hidden;	 
 }

 .rdt_Table {
	margin-bottom:2rem;
	padding: 1rem;
	overflow-x: hidden;	 
 }

 .rdt_TableHeadRow{
	background: ${({ theme: { secondary } }) => secondary};
	background: ${({ theme: { loginLeft } }) => loginLeft} 
 }
 
  .rdt_TableCol{
    border-right: 1px solid ${({ theme: { secondary } }) => secondary};
	
 }
 
 .rdt_TableCell{
	font-size: 0.9rem; 
 }

  .rdt_TableHeadRow .rdt_TableCol {
    color:#000;
	padding: 8px;
  }

  h2 {
    font-size: 0.85rem !important;
  }
  
// .rdt_TableHeadRow {
//   padding-right: 14px;
// }
 
/* Works on Firefox */
.rdt_Table {
  scrollbar-width: thin;
}


.rdt_TableBody::-webkit-scrollbar {
    width: 14px;
}

.rdt_TableBody::-webkit-scrollbar-track {
	background: ${({ theme: { secondary } }) => secondary};
  border-left: 6px solid white;
  border-right: 7px solid white;
	
}

.rdt_TableBody::-webkit-scrollbar-thumb {
  box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme: { secondary } }) => secondary} !important; 
}
`;

export default StyledContainer;
