import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import ErrorIcon from '@material-ui/icons/Error';

const Container = styled.div`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`;

const ErrorMessage = ({ error }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Container
        as={motion.div}
        key={'error-message'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <ErrorIcon style={{ marginBottom: '1rem' }} />
        <h4>{error}</h4>
      </Container>
    </AnimatePresence>
  );
};

export default ErrorMessage;
