import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { exportTableToCSV, formatPerc, getRandomNumber } from '../../../helpers/helpers';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import AlchContextMenu from '../../components/AlchUI/AlchContextMenu';
import Sidebar from '../../components/Sidebar';
import AddDropship from '../../components/modals/AddDropship';
import {
  fetchCategories,
  fetchDropships,
  onDepartmentChange,
  onCategoryChange,
  onSetForm,
  onDeleteDropship,
  onSaveDropship,
  onSetDropship,
  onDistributionEnglandChange,
  onDistributionScotlandChange,
  onDistributionWalesChange,
  onDistributionNIChange,
  onRetailChange,
  onWholesaleChange,
  onFoodservicesChange,
  onOnTradeChange,
  onNewFlagChange,
  resetMe,
} from '../../redux/reducers/pfpDropship';
import { ToastContainer, toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Table } from 'reactstrap';
import { throws } from 'assert';

ReactModal.setAppElement('.alchemy_app');
const Dropships = ({
  data,
  isLoading,
  dropships,
  department,
  departments,
  category,
  categories,
  dropshipForm,
  onDepartmentChange,
  onCategoryChange,
  onFormSelectChange,
  onFormTextChange,
  onFormDepartmentChange,
  onFormCategoryChange,
  onFormFileChange,
  onSaveDropship,
  onDeleteDropship,
  onDistributionEnglandChange,
  onDistributionScotlandChange,
  onDistributionWalesChange,
  onDistributionNIChange,
  onRetailChange,
  onWholesaleChange,
  onFoodservicesChange,
  onOnTradeChange,
  onNewFlagChange,
  handleDropshipDeleteModal,
  handleDropshipAddModal,
}) => (
  <div className="wrapper">
    <ToastContainer autoClose={8000} style={{ top: '80px', zIndex: '10001' }} />
    <Sidebar />
    {isLoading && !data.openDropshipAddModal ? (
      <section className="section-container">
        <div>Loading...</div>
      </section>
    ) : (
      <section className="section-container">
        <button className="btn pull-right" onClick={() => handleDropshipAddModal(null)}>
          <i className="fa fa-plus"></i>
        </button>
        <ReactModal isOpen={data.openDropshipAddModal} contentLabel="" className="performance-modal">
          <AddDropship
            handleDropshipAddModal={() => handleDropshipAddModal(null)}
            isLoading={isLoading}
            isAddDropship={data.isAddDropship}
            dropshipForm={dropshipForm}
            onSaveDropship={onSaveDropship}
            onFormSelectChange={onFormSelectChange}
            onFormTextChange={onFormTextChange}
            onFormFileChange={onFormFileChange}
            onFormDepartmentChange={onFormDepartmentChange}
            onFormCategoryChange={onFormCategoryChange}
            onDistributionEnglandChange={onDistributionEnglandChange}
            onDistributionScotlandChange={onDistributionScotlandChange}
            onDistributionWalesChange={onDistributionWalesChange}
            onDistributionNIChange={onDistributionNIChange}
            onRetailChange={onRetailChange}
            onWholesaleChange={onWholesaleChange}
            onFoodservicesChange={onFoodservicesChange}
            onOnTradeChange={onOnTradeChange}
            onNewFlagChange={onNewFlagChange}
          />
        </ReactModal>
        <div className="content-wrapper">
          <h4>Dropship</h4>
          <div className="o-canvas__header-search u-layout u-layout--spaced" style={{ width: '80%', margin: '0px auto' }}>
            <div className="u-1_3 mb-0">
              <div className="form-group">
                <h3 className="o-canvas__search-title ">Department</h3>
                <Select value={department} onChange={onDepartmentChange} options={departments} valueKey="ID" labelKey="Text" />
              </div>
            </div>
            {categories.length ? (
              <div className="u-1_3 mb-0">
                <div className="form-group">
                  <h3 className="o-canvas__search-title ">Category</h3>
                  <Select value={category} onChange={onCategoryChange} options={categories} valueKey="ID" labelKey="Text" />
                </div>
              </div>
            ) : null}
          </div>

          {dropships.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Actions</th>
                  <th>Department</th>
                  <th>Category</th>
                  <th>SupplierName</th>
                  <th>Description</th>
                  <th>Logo</th>
                  <th>DistributionEngland</th>
                  <th>DistributionNI</th>
                  <th>DistributionScotland</th>
                  <th>DistributionWales</th>
                  <th>Retail</th>
                  <th>Wholesale</th>
                  <th>Foodservices</th>
                  <th>OnTrade</th>
                  <th>NewUntil</th>
                  <th>Updated</th>
                </tr>
              </thead>
              <tbody>
                {dropships.map((dropship, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <button className="btn pull-right" onClick={() => handleDropshipAddModal(dropship.ID)}>
                          <i className="fa fa-edit"></i>
                        </button>
                        <button className="btn pull-right" onClick={() => handleDropshipDeleteModal(dropship.ID)}>
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>{dropship.Department}</td>
                      <td>{dropship.Category}</td>
                      <td>{dropship.SupplierName}</td>
                      <td>{dropship.Description}</td>
                      <td>{dropship.Logo}</td>
                      <td>{dropship.DistributionEngland}</td>
                      <td>{dropship.DistributionNI}</td>
                      <td>{dropship.DistributionScotland}</td>
                      <td>{dropship.DistributionWales}</td>
                      <td>{dropship.Retail}</td>
                      <td>{dropship.Wholesale}</td>
                      <td>{dropship.Foodservices}</td>
                      <td>{dropship.OnTrade}</td>
                      <td>{dropship.NewUntil}</td>
                      <td>{dropship.Updated}</td>
                    </tr>
                  );
                })}
                <ReactModal isOpen={data.openDropshipDeleteModal} contentLabel="" className="delete-confirm-modal">
                  <label className="delete-confirm-text">Are you sure you would like to delete this dropship?</label>
                  <div className="o-block t-brand-b">
                    <button onClick={() => onDeleteDropship()} style={{ position: 'absolute', bottom: '10px', right: '150px' }}>
                      Yes
                    </button>
                    <button onClick={() => handleDropshipDeleteModal()} style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                      No
                    </button>
                  </div>
                </ReactModal>
              </tbody>
            </Table>
          ) : (
            <p>No Dropships found.</p>
          )}
        </div>
      </section>
    )}
  </div>
);
function mapStateToProps(state, ownProps) {
  const data = {
    isLoading: state.pfpDropship.isLoading,
    department: state.pfpDropship.department,
    departments: state.pfpDropship.departments,
    category: state.pfpDropship.category,
    categories: state.pfpDropship.categories,
    isDropshipCreated: state.pfpDropship.isDropshipCreated,
    isDropshipDeleted: state.pfpDropship.isDropshipDeleted,
    dropships: state.pfpDropship.dropships,
    dropshipForm: state.pfpDropship.dropshipForm,
  };
  return data;
}
export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCategories,
    fetchDropships,
    onDepartmentChange,
    onCategoryChange,
    onSetForm,
    onDeleteDropship,
    onSaveDropship,
    onSetDropship,
    onDistributionEnglandChange,
    onDistributionScotlandChange,
    onDistributionWalesChange,
    onDistributionNIChange,
    onRetailChange,
    onWholesaleChange,
    onFoodservicesChange,
    onOnTradeChange,
    onNewFlagChange,
    resetMe,
  }),
  lifecycle({
    componentDidMount() {
      //this.props.departments.length === 0 &&
      this.props.fetchCategories();
    },
    componentWillMount() {
      this.props.resetMe();
    },
    componentDidUpdate(prevProps) {
      if (prevProps.isDropshipCreated !== this.props.isDropshipCreated && this.props.isDropshipCreated) {
        this.props.fetchDropships();
        this.uploadFiles = [];
        toast.info((this.props.dropshipForm.ID ? 'Updated' : 'Created') + ' Dropship Successfully...');
      }
      if (prevProps.isDropshipDeleted !== this.props.isDropshipDeleted && this.props.isDropshipDeleted) {
        this.props.fetchDropships();
        toast.info('Deleted Dropship Successfully...');
      }
    },
  }),
  withState('data', 'handleData', { isAddDropship: false, openDropshipAddModal: false, openDropshipDeleteModal: false }),
  withHandlers({
    onDistributionEnglandChange: ({ data, handleData, onDistributionEnglandChange }) => event => {
      onDistributionEnglandChange(parseInt(event.target.id));
    },
    onDistributionScotlandChange: ({ data, handleData, onDistributionScotlandChange }) => event => {
      onDistributionScotlandChange(parseInt(event.target.id));
    },
    onDistributionWalesChange: ({ data, handleData, onDistributionWalesChange }) => event => {
      onDistributionWalesChange(parseInt(event.target.id));
    },
    onDistributionNIChange: ({ data, handleData, onDistributionNIChange }) => event => {
      onDistributionNIChange(parseInt(event.target.id));
    },
    onRetailChange: ({ data, handleData, onRetailChange }) => event => {
      onRetailChange(parseInt(event.target.id));
    },
    onWholesaleChange: ({ data, handleData, onWholesaleChange }) => event => {
      onWholesaleChange(parseInt(event.target.id));
    },
    onFoodservicesChange: ({ data, handleData, onFoodservicesChange }) => event => {
      onFoodservicesChange(parseInt(event.target.id));
    },
    onOnTradeChange: ({ data, handleData, onOnTradeChange }) => event => {
      onOnTradeChange(parseInt(event.target.id));
    },
    onNewFlagChange: ({ data, handleData, onNewFlagChange }) => event => {
      onNewFlagChange(parseInt(event.target.id));
    },
    onDepartmentChange: ({ data, handleData, onDepartmentChange }) => target => {
      onDepartmentChange(target);
    },
    onCategoryChange: ({ data, handleData, onCategoryChange }) => target => {
      onCategoryChange(target);
    },
    onFormDepartmentChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Department', target);
    },
    onFormCategoryChange: ({ data, handleData, onSetForm }) => target => {
      onSetForm('Category', target);
    },
    onFormSelectChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.ID);
    },
    onFormTextChange: ({ data, handleData, onSetForm }) => (name, target) => {
      onSetForm(name, target.value);
    },
    onFormFileChange: ({ data, handleData, onSetForm }) => (name, target) => {
      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles[name] = target;
    },
    onSaveDropship: ({ data, handleData, onSaveDropship }) => () => {
      onSaveDropship(this.uploadFiles || []);
      handleData({ ...data, isAddDropship: false, openDropshipAddModal: false });
    },
    onDeleteDropship: ({ data, handleData, onDeleteDropship, dropshipForm }) => () => {
      onDeleteDropship(dropshipForm.ID);
      handleData({ ...data, isAddDropship: false, openDropshipDeleteModal: false });
    },
    handleDropshipDeleteModal: ({ data, handleData, dropships, onSetDropship }) => id => {
      handleData({ ...data, openDropshipDeleteModal: !data.openDropshipDeleteModal });
      onSetDropship(id || '', false, true);
    },
    handleDropshipAddModal: ({ data, handleData, onSetDropship }) => id => {
      const isAddDropship = id ? false : true;
      this.uploadFiles = []; //reset
      const openDropshipAddModal = !data.openDropshipAddModal;
      handleData({ ...data, isAddDropship, openDropshipAddModal: openDropshipAddModal });
      if (openDropshipAddModal) {
        onSetDropship(id, isAddDropship);
      } else {
        onSetDropship(null, isAddDropship);
      }
    },
  }),
)(Dropships);
