import React from 'react';
import PropTypes from 'prop-types';
import { request } from '../../helpers/axios';
import { getToken, getUserId } from '../../helpers/util';
import { setMenuOptions } from '../redux/reducers/app';
import { getUserDataSets } from '../../helpers/util';
import Select from 'react-select';
import { connect } from 'react-redux';
import LogoUrl from '../../assets/images/logos/logo.png';
import { motion } from 'framer-motion';

class LoginSelect extends React.Component {
  state = {
    wholeview: '',
    wholeviewList: [],
  };

  onDropdownSelect = ({ id }) => {
    this.setState({
      wholeview: id,
    });
  };

  supplierSelectSubmit = (e) => {
    e.preventDefault();
    let url = '/route.php?c=reportMenu/getNAMDashReports';
    request({
      url: url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
        UserId: getUserId(),
        System: this.state.wholeview,
      },
    }).then((result) => {
      let url1 = '/route.php?c=reportMenu/getAnalysisReports';
      request({
        url: url1,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
          UserId: getUserId(),
          System: this.state.wholeview,
        },
      }).then((result1) => {
        let url2 = '/route.php?c=reportMenu/getQueryBuilderReports';
        request({
          url: url2,
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
            UserId: getUserId(),
            System: this.state.wholeview,
          },
        }).then((result2) => {
          this.props.setMenuOptions({ namdash: result, analysis: result1, queryBuilder: result2, psa: ['manage_distribution', 'manage_campaigns', 'view_campaign'] });
          this.props.setUserSystem(this.state.wholeview);
          localStorage.setItem('system', this.state.wholeview);
          if (this.state.wholeview === 'bdm') {
            this.props.history.push(`${process.env.APP_ROOT}psa`);
          } else if (this.state.wholeview === 'pfp') {
            this.props.history.push(`${process.env.APP_ROOT}pfp`);
          } else {
            this.props.history.push(`${process.env.APP_ROOT}dashboard`);
          }
        });
      });
    });
  };

  render() {
    let wholeviews = [];
    const allWholeviews = getUserDataSets();
    allWholeviews.map((wholeview) => {
      wholeviews.push({ id: wholeview.className, text: wholeview.className });
    });
    return (
      <motion.div key="loginSelect" className="splash">
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="splash__buttons text-center mb-2">
            <div className="d-block splash__image mx-auto">
              <img src={LogoUrl} alt="logo" className="img-fluid d-block" />
            </div>
            <div className="d-block mx-auto mt-5">
              <form action="#" onSubmit={this.supplierSelectSubmit}>
                <div className="form-group row">
                  <div className="col-sm-12">
                    {getUserDataSets().length > 0 && (
                      <Select style={{ minWidth: '200px' }} placeholder="Select Wholesaler" className="c-select-header c-select-header-row--others" name="wholeview" id="wholeview" value={this.state.wholeview} onChange={this.onDropdownSelect} options={wholeviews} valueKey="id" labelKey="text" />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <button type="submit" className="btn alchemy-btn--others" tabIndex="3">
                      Select
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

LoginSelect.propTypes = {
  alertAdd: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setDepotSelectType: PropTypes.func.isRequired,
  setUserWholesaler: PropTypes.func.isRequired,
  setUserSupplier: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMenuOptions: (data) => dispatch(setMenuOptions(data)),
  };
};

export default connect(null, mapDispatchToProps)(LoginSelect);
