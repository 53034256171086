import React, { useEffect } from 'react';
import DataDropdownsContainer from '../../components/DataDropdownsContainer';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tippy';
import _ from 'lodash';
import store from '../../redux/store';
import styled from 'styled-components';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import { getUserSystem, hasPermission } from '../../../helpers/util';
import GreenTick from '../../../assets/images/green-check.svg';
import RedTick from '../../../assets/images/red-x.svg';
import OrangeTick from '../../../assets/images/orange-x.png';
import { setAccountName } from '../../redux/reducers/filters';
import {
  fetchCategories,
  fetchRetailAnalysisReport,
  resetReport,
  setBrand,
  setCoreRange,
  setCategories,
  setDepartments,
  setLevel4s,
  setLevel5s,
  setLikeForLike,
  setRefreshDisabledStatus,
  setSubCategories,
  setSupplier,
  setRetailType,
  setTimeFrame,
} from '../../redux/reducers/retail';
import ReportSettingsContainer from '../../components/ReportSettingsContainer';

export const LOCALSTORAGE_RETAIL_ANALYSIS = 'retail_analysis';

const TableCell = styled.td`
  text-align: center;
`

const TableRow = styled.tr`
`

const TableHeading = styled.th`
  text-align: center;
  text-transform: capitalize;
  font-size: 0.75rem;
  font-weight: bold;
  vertical-align: bottom !important;
`

const RetailAnalysisReport = ({
  accountName,
  accountNames,
  brands,
  categories,
  departments,
  fetchCategories,
  fetchRetailAnalysisReport,
  level4,
  level4s,
  level5,
  level5s,
  likeForLike,
  likeForLikes,
  coreRange,
  coreRanges,
  location,
  resolveExportData,
  onAccountNameChange,
  onBrandChange,
  onCoreRangeChange,
  onCategoryChange,
  onDepartmentChange,
  onLevel4Change,
  onLevel5Change,
  onLikeForLikeChange,
  onResetReport,
  onSubCategoryChange,
  onSupplierChange,
  onTimeFrameChange,
  onUnmount,
  retailAnalysisReportData,
  isLoading,
  selectedBrand,
  selectedCategories,
  selectedDepartments,
  selectedSubCategories,
  selectedSupplier,
  setLoading,
  setSubPageNav,
  subCategories,
  suppliers,
  timeframe,
  timeframes,
  unlockRefresh,
  retailType,
  retailTypes,
  onRetailTypeChange,
  ...props
}) => {
  // const cachedResponse = localStorage.getItem(LOCALSTORAGE_RETAIL_ANALYSIS);
  const { selectedAdvancedBusinessTypes } = store.getState().filters;

  useEffect(() => {
    fetchCategories('basket_analysis', false, {
      timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
    });
    return () => onUnmount();
  }, []);

  return (
  <>
      <ReportSettingsContainer
        resetReport={() => onResetReport()}
        pathname={location.pathname}
        exports={{
          CSV: {
            headers: ['storeName', ...(retailAnalysisReportData ? Object.keys(retailAnalysisReportData[0].Products) : [])],
            data: resolveExportData(retailAnalysisReportData),
          },
        }}
        refresh={{
          refreshHandler: () => fetchRetailAnalysisReport(),
          isRefreshDisabled: false                                                                                                                                            ,
        }}
        title={'Gap Analysis Retail'}
        dropdowns={[
          {
            name: 'likeForLike',
            valueKey: 'ID',
            labelKey: 'Text',
            value: likeForLike,
            options: likeForLikes,
            handler: option => onLikeForLikeChange(option),
          }, {
          text: 'Stores for latest'
          }, {
            name: 'timeframe',
            valueKey: 'ID',
            labelKey: 'Text',
            value: timeframe,
            options: timeframes,
            handler: option => onTimeFrameChange(option),
          },
        ]}>
          <DataDropdownsContainer
            dropdowns={[
            {
              name: 'departments',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedDepartments,
              options: departments,
              handler: option => onDepartmentChange(option),
              isMulti: true,
            }, {
              name: 'categories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedCategories,
              options: categories,
              handler: option => onCategoryChange(option),
              isMulti: true,
            }, {
              name: 'subCategories',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSubCategories,
              options: subCategories,
              handler: option => onSubCategoryChange(option),
              isMulti: true,
            }, {
              name: 'level4',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level4,
              options: level4s,
              handler: option => onLevel4Change(option),
              isMulti: true,
            }, {
              name: 'level5',
              valueKey: 'ID',
              labelKey: 'Text',
              value: level5,
              options: level5s,
              handler: option => onLevel5Change(option),
              isMulti: true,
            }, {
              name: 'Suppliers',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedSupplier,
              options: suppliers,
              handler: option => onSupplierChange(option),
            }, {
              name: 'Brand',
              valueKey: 'ID',
              labelKey: 'Text',
              value: selectedBrand,
              options: brands,
              handler: option => onBrandChange(option),
            },
            {
              name: 'Core Range',
              valueKey: 'ID',
              labelKey: 'Text',
              value: coreRange,
              options: coreRanges,
              handler: option => onCoreRangeChange(option),
            },
            {
              name: 'retailType',
              condition: hasPermission(`role_view_${getUserSystem().toLowerCase()}_independent_data`) ? ['display']  : [],
              valueKey: 'ID',
              labelKey: 'Text',
              value: retailType,
              options: retailTypes,
              handler: option => onRetailTypeChange(option),
              isMulti: false,
            },
          ]}
          />
      </ReportSettingsContainer>
    <div className={'container-fluid'}>
      {null === retailAnalysisReportData && !isLoading
        ? <p className="text-center pt-3">Please refine your search and refresh to display data.</p> 
        : isLoading 
          ? <Loading /> 
          : DataTable(retailAnalysisReportData)}
    </div>
  </>
)}

const DataTable = (data) =>
  <div style={{overflow: 'scroll'}}>
    <Table bordered striped className={'text-black gap-analysis-retail'}>
      <thead>
      <TableRow>
        <TableHeading></TableHeading>
        {_.map(Object.keys(data[0].Products)).map(product =>
          <TableHeading key={product}>{product}</TableHeading>
        )}
      </TableRow>
      </thead>
      <tbody>
      {Object.values(data).map((retailer, index) =>
        <TableRow key={retailer.StoreName + "-" + index}>
          <TableCell>{retailer.StoreName}</TableCell>
          {Object.values(retailer.Products).map((product, subindex) =>
            <TableCell key={`${retailer.StoreName}-${subindex}`}>
              
              {product.status === 2 
              ?  <Tooltip
                  trigger="click"
                  position="bottom"
                  animation="scale"
                  theme="green"
                  arrow="true"
                  title={product.displayMessage}>
                  <div className="o-circle o-circle--green">
                    <img src={GreenTick} alt="green" />
                    <span className="hide">1</span>
                  </div>
                </Tooltip>
              : product.status === 1 
                ? <Tooltip
                    trigger="click"
                    position="bottom"
                    animation="scale"
                    theme="orange"
                    arrow="true"
                    title={product.displayMessage}>
                    <div className="o-circle o-circle--orange">
                      <img src={OrangeTick} alt="orange" />
                    </div>
                  </Tooltip>
                : <Tooltip
                    trigger="click"
                    position="bottom"
                    animation="scale"
                    theme="red"
                    arrow="true"
                    title={product.displayMessage}>
                    <div className="o-circle o-circle--red">
                      <img src={RedTick} alt="red" />
                    </div>
                  </Tooltip>
              }

            </TableCell>
          )}
        </TableRow>
      )}
      </tbody>
    </Table>
  </div>

const mapDispatchToProps = dispatch => {
  return {
    setLoading: () => dispatch(setLoading()),
    fetchCategories: (type, isReferral, drillObj) =>
      dispatch(fetchCategories(type, false, drillObj)),
    fetchRetailAnalysisReport: () =>
      dispatch(fetchRetailAnalysisReport()),
    onTimeFrameChange: option =>
      dispatch(setTimeFrame(option)),
    onDepartmentChange: selectedOptions =>
      dispatch(setDepartments(selectedOptions)),
    onLikeForLikeChange: opt => {
      if (opt) {
        dispatch(setLikeForLike(opt.ID));
      } else {
        dispatch(setLikeForLike(''));
      }
    },
    onAccountNameChange: opt => {
      if (opt) {
        dispatch(setAccountName(opt));
      } else {
        dispatch(setAccountName([]));
      }
      dispatch(setRefreshDisabledStatus(false));
    },
    resolveExportData: data => {
      if (!data) {
        return null;
      }

      let array = [];
      data.forEach((dataObj)=>{
        let productArray = {storeName: dataObj.StoreName};

        Object.entries(dataObj.Products).forEach(([key, value])=>{
          //Update here if value needs to change depending on status (0,1,2 may not be enough detail)
          productArray = {...productArray, [key]: value.status};
        })

        array.push(productArray);
      });

      return array;
    },
    onCategoryChange: selectedOptions => dispatch(setCategories(selectedOptions)),
    onUnmount: () => dispatch(resetReport()),
    onSupplierChange: option => dispatch(setSupplier(option)),
    onBrandChange: option => dispatch(setBrand(option)),
    onCoreRangeChange: option => dispatch(setCoreRange(option)),
    onSubCategoryChange: selectedOptions =>
      dispatch(setSubCategories(selectedOptions)),
    onLevel4Change: selectedOptions => dispatch(setLevel4s(selectedOptions)),
    onLevel5Change: selectedOptions => dispatch(setLevel5s(selectedOptions)),
    onRetailTypeChange: option => dispatch(setRetailType(option || '')),
    onResetReport: () => {
      dispatch(resetReport()),
      dispatch(
        fetchCategories('basket_analysis', false, {
          timeframe: getUserSystem() == 'united' ? '4wksRolling' : (getUserSystem() == 'cjlangRetail' ? '4wksRolling' : '4wks'),
        })
      );
    },
  };
};

const mapStateToProps = state => ({
  accountName: state.filters.accountName,
  accountNames: state.filters.accountNames,
  brands: state.retail.brands,
  categories: state.retail.categories,
  departments: state.retail.departments,
  level4: state.retail.level4,
  level4s: state.retail.level4s,
  level5: state.retail.level5,
  level5s: state.retail.level5s,
  retailType: state.retail.retailType,
  retailTypes: state.retail.retailTypes,
  likeForLike: state.retail.likeForLike,
  likeForLikes: state.retail.likeForLikes,
  coreRange: state.retail.coreRange,
  coreRanges: state.retail.coreRanges,
  members: state.retail.members,
  retailAnalysisReportData: state.retail.retailAnalysisReportData,
  selectedBrand: state.retail.brand,
  selectedCategories: state.retail.selectedCategories,
  selectedDepartments: state.retail.selectedDepartments,
  selectedSubCategories: state.retail.selectedSubCategories,
  selectedSupplier: state.retail.supplier,
  subCategories: state.retail.subCategories,
  suppliers: state.retail.suppliers,
  timeframe: state.retail.timeframe,
  timeframes: state.retail.timeframes,
  isLoading: state.retail.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetailAnalysisReport);
