function currentUser(state = {}, action) {
	switch (action.type) {
	case 'USER_LOGON':
		return {
			...state,
			username: action.username,
		};

	case 'USER_LOGOUT':
		return {};

	case 'USER_SET_WHOLESALER':
		return {
			...state,
			wholesaler: action.wid,
		};
	case 'USER_SET_SUPPLIER':
		return {
			...state,
			supplier: action.sid,
		};
        case 'USER_SET_SYSTEM':
               return {
                   ...state,
                   system: action.system
               };
	default:
		return state;
	}
}

export default currentUser;
