import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import DataTable from 'react-data-table-component';
import { Button, Row } from 'reactstrap';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import RetailMap from '../../components/retail/RetailMap';

import { lightTableTheme } from '../../../config/tableStyles';
import { formatToCurrency } from '../../../helpers/helpers';
import { getUserSystem } from '../../../helpers/util';

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;

  & .section {
    color: #fff;
    text-align: center;
    padding: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }

  & .section__last {
    margin-right: 0 !important;
  }

  & .section__green {
    background: ${({ theme: { secondary } }) => secondary};
  }
  & .section__red {
    background: ${({ theme: { secondary } }) => secondary};
  }
  & .section__yellow {
    background: ${({ theme: { secondary } }) => secondary};
  }
  & .section-tab__active {
    box-shadow: 1px 1px 7px 1px ${({ theme: { secondary } }) => secondary};
  }

  & .retailer-table__row {
    font-weight: bold;
    background: #e6e6e6;
    padding: 1rem;
  }

  & .retailer-table__row.odd-row {
    background: #f8f8f8;
  }

  div[role='rowgroup'] div[role='row'] div[role='gridcell']:first-child {
    text-align: left;
    padding: 0.5rem;
    
  }

   div[role='rowgroup'] div[role='row'] div[role='gridcell'] {
    text-align: left;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

   .rdt_TableCell{
	font-size: 0.9rem; 
 }

  .rdt_TableHeadRow .rdt_TableCol {
      display: flex;
      justify-content: flex-start;
	   
  }

  

/* Works on Firefox */
.rdt_Table {
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.tableColor1} #fff ;
}


.rdt_TableBody::-webkit-scrollbar {
    width: 14px;
}

.rdt_TableBody::-webkit-scrollbar-track {
	background: ${({ theme }) => theme.primary};
    border-left: 6px solid white;
    border-right: 7px solid white;
	
}

.rdt_TableBody::-webkit-scrollbar-thumb {
    box-shadow: offset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
	height:40px
	padding-top:10px;
	background: ${({ theme }) => theme.primary} !important; 
}
`;

const RetailerPerformanceTable = ({
  tabs,
  retailers,
  history,
  match: {
    params: { tableType },
  },
  isRollingDateRange,
}) => {
  const columns = [
    {
      selector: 'BusinessName',
      name: 'Business Name',
      sortable: true,
      wrap: true,
    },
    {
      selector: 'QtyLY',
      name: `Quantity ${isRollingDateRange ? 'Previous' : 'LY'}`,
      sortable: true,

      format: row => `${row.QtyLY}`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.QtyLY.replace(',', '')) -
        parseInt(rowB.QtyLY.replace(',', '')),
    },
    {
      selector: 'QtyTY',
      name: `Quantity ${isRollingDateRange ? 'Current' : 'TY'}`,
      sortable: true,

      format: row => `${row.QtyTY}`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.QtyTY.replace(',', '')) -
        parseInt(rowB.QtyTY.replace(',', '')),
    },
    {
      selector: 'QtyPercentChange',
      name: '%',
      sortable: true,

      format: row => `${parseInt(row.QtyPercentChange).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        rowA.QtyPercentChange.replace('%', '') -
        rowB.QtyPercentChange.replace('%', ''),
    },
    {
      selector: 'ValueLY',
      name: `Value ${isRollingDateRange ? 'Previous' : 'LY'}`,
      sortable: true,

      format: row => `${formatToCurrency(row.ValueLY, 0)}`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.ValueLY.replace(',', '')) -
        parseInt(rowB.ValueLY.replace(',', '')),
    },
    {
      selector: 'ValueTY',
      name: `Value ${isRollingDateRange ? 'Current' : 'TY'}`,
      sortable: true,

      format: row => `${formatToCurrency(row.ValueTY, 0)}`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.ValueTY.replace(',', '')) -
        parseInt(rowB.ValueTY.replace(',', '')),
    },
    {
      selector: 'ValuePercentChange',
      name: '%',
      sortable: true,

      format: row => `${parseInt(row.ValuePercentChange).toFixed(2)}%`,
      sortFunction: (rowA, rowB) =>
        parseInt(rowA.ValuePercentChange.replace('%', '')) -
        parseInt(rowB.ValuePercentChange.replace('%', '')),
    },
  ];
  const theme = useContext(ThemeContext);
  const { primary } = theme;
  const filteredRetailers =
    retailers &&
    retailers.length > 0 &&
    retailers.filter(retailer => retailer.Section == tableType);

  const hideMap = getUserSystem('demo');
  return (
    <Container className="container-fluid">
      <Row>
        <div className={hideMap ? 'col-12' : 'col-8'}>
          <Button
            className="mb-2"
            onClick={() => history.push(`/retail/retailer-performance/graph`)}>
            Back
          </Button>
          <div className="row no-gutters">
            {tabs &&
              tabs.map(({ text, color }, index) => {
                return (
                  <div className="col-3" key={`tab-${text}`}>
                    <div
                      className={`section section__${color} ${
                        index === 3 ? 'section__last' : ''
                      } ${tableType === text ? 'section-tab__active' : ''}`}
                      onClick={() =>
                        history.push(
                          `/retail/retailer-performance/table/${text}`,
                        )
                      }>
                      {text}
                    </div>
                  </div>
                );
              })}
            {filteredRetailers && filteredRetailers.length > 0 && (
              <DataTable
                data={filteredRetailers}
                columns={columns}
                customStyles={lightTableTheme}
                striped
                defaultSortField={'QtyTY'}
                defaultSortAsc={false}
                highlightOnHover
                fixedHeader
                noHeader
                fixedHeaderScrollHeight={'50vh'}
                sortIcon={<ArrowDownwardIcon />}
              />
            )}
          </div>
        </div>
        <div className={hideMap ? 'd-none' : 'col-4'}>
          <RetailMap />
        </div>
      </Row>
    </Container>
  );
};

export default RetailerPerformanceTable;
