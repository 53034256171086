import React from 'react';

const Button = ({
  onClickHandler,
  isDisabled,
  classes,
  iconClass,
  text,
  iconComponent,
}) => {
  return (
    <button
      onClick={onClickHandler}
      disabled={isDisabled}
      className={classes ? classes : `btn alchemy-btn--others`}>
      {text}{' '}
      {iconComponent ? (
        iconComponent
      ) : (
        <i className={iconClass ? iconClass : ``} />
      )}
    </button>
  );
};

export default Button;
