import React, { useEffect } from 'react';
import Select from 'react-select';
import { checkIsRetailOnly } from '../../../../helpers/util';

import { connect } from 'react-redux';

const DataTypes = ({
  dataType,
  dataTypes,
  onDataTypeChange,
  isOwnCategoryStructure,
}) => {
  const retailOnly = [dataTypes[1]];
  const wholesaleOnly = [dataTypes[0]];
  useEffect(() => {
    if (checkIsRetailOnly()) {
      // dataTypes[1] === Retail
      onDataTypeChange(dataTypes[1]);
    }
  }, []);

  useEffect(() => {
    if (isOwnCategoryStructure) {
      // dataTypes[0] === Wholesale
      onDataTypeChange(dataTypes[0]);
    }
  }, [isOwnCategoryStructure]);
  return (
    <>
      <div className="btn-group text-uppercase">
        <span className="dropdown-align-fix">Showing: </span>
      </div>
      <div className="btn-group text-uppercase dropdown-align-fix-placeholder">
        <Select
          placeholder="Data"
          inputProps={{
            autoComplete: 'off',
            autoCorrect: 'off',
            spellCheck: 'off',
          }}
          className={`small-dropdown c-select-header c-select-header-row--others`}
          name="timeframe"
          id="timeframe"
          value={dataType}
          onChange={onDataTypeChange}
          options={
            checkIsRetailOnly()
              ? retailOnly
              : isOwnCategoryStructure
              ? wholesaleOnly
              : dataTypes
          }
          valueKey="ID"
          labelKey="Text"
          clearable={false}
          searchable={false}
          isMulti={false}
          style={{
            width: '150px',
            margin: '0px 5px',
            fontSize: '12px',
            padding: '0px',
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isOwnCategoryStructure: state.app.categoryStructure,
});

export default connect(mapStateToProps)(DataTypes);
