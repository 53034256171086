import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import GreenTick from '../../assets/images/green-check.svg';
import RedTick from '../../assets/images/red-x.svg';

class TableStocked extends React.Component {
  renderHeader = (header, i) => {
    return (
      <th
        className="u-tac u-grey-bg"
        colSpan={header.colspan}
        style={{ fontSize: '0.75em' }}
        key={i}>
        {this.props.parseEachCell(header.text, -1, i)}
      </th>
    );
  };

  renderRow = (row, i) => {
    return (
      <tr key={i}>
        {row.map((column, j) => {
          return (
            <td
              className="u-text-center cursor-pointer"
              colSpan={column.colspan}
              key={j}>
              {column.text !== 'Green' &&
              column.text !== 'Orange' &&
              column.text !== 'Red'
                ? this.props.parseEachCell(column.text, i, j)
                : ''}
              {column.text === 'Green' ? (
                <Tooltip
                  trigger="click"
                  position="bottom"
                  animation="scale"
                  theme="green"
                  arrow="true"
                  title={column.message}>
                  <div className="o-circle o-circle--green">
                    <img src={GreenTick} alt="green" />
                    <span className="hide">1</span>
                  </div>
                </Tooltip>
              ) : (
                ''
              )}
              {column.text === 'Orange' ? (
                <Tooltip
                  trigger="click"
                  position="bottom"
                  animation="scale"
                  theme="orange"
                  arrow="true"
                  title={column.message}>
                  <div className="o-circle o-circle--orange"></div>
                </Tooltip>
              ) : (
                ''
              )}
              {column.text === 'Red' ? (
                <Tooltip
                  trigger="click"
                  position="bottom"
                  animation="scale"
                  theme="red"
                  arrow="true"
                  title={column.message}>
                  <div className="o-circle o-circle--red">
                    <img src={RedTick} alt="red" />
                  </div>
                  <span className="hide">0</span>
                </Tooltip>
              ) : (
                ''
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  render() {
    if (!this.props.data || !this.props.data.headers) return null;
    return (
      <div
        className={`analysis analysis-table table-responsive ${this.props.className}`}>
        <table className={this.props.tableClassName} id={this.props.id}>
          <thead style={{ width: '98.5%', fontSize: '0.75em !important' }}>
            <tr>
              <th
                style={{ fontSize: '.75em !important' }}
                className="u-border-th"></th>
              {this.props.data.headers.map(this.renderHeader)}
            </tr>
          </thead>
          <tbody>{this.props.data.rows.map(this.renderRow)}</tbody>
        </table>
      </div>
    );
  }
}

TableStocked.defaultProps = {
  data: [],
  parseEachCell: cell => cell,
  className: '',
  tableClassName: '',
};

TableStocked.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  parseEachCell: PropTypes.func,
  className: PropTypes.string,
  tableClassName: PropTypes.string,
};

export default TableStocked;
