import React, { useEffect, useRef, useContext, useState } from 'react';
import Chart from 'chart.js';
import styled, { ThemeContext } from 'styled-components';
import { formatToCurrency, htmlDecodePound } from '../../../helpers/helpers';
import 'chartjs-plugin-labels';

const StyledCard = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const TotalsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Total = styled.h3``;

const StyledTotal = styled(Total)`
  &&& {
    font-size: 1.8rem;
    color: ${props => props.theme.secondary} !important;
    margin-bottom: 1rem;
  }
`;

const BasketOverviewChart = ({ data, id, title, total }) => {
  const [chartInstance, setChartInstance] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const theme = useContext(ThemeContext);
  const { primary, colorArray } = theme;
  const chartRef = useRef(null);

  let OverviewChart;
  useEffect(() => {
    if (chartInstance != null) {
      chartInstance.destroy();
    }

    if (chartRef.current) {
      const summaryDataValues = {
        data: data && data.map(d => d.value),

        backgroundColor: data && data.map((d, idx) => colorArray[idx % 3]),
        borderColor: data && data.map(d => 'White'),
        borderWidth: 2,
        hoverBorderColor: data && data.map(d => 'White'),
      };
      const summaryChartData = {
        datasets: [summaryDataValues],
        labels: data && data.map(d => d.name),
      };
      const myChartRef = chartRef.current.getContext('2d');
      const myChart = new Chart(myChartRef, {
        type: 'pie',
        data: summaryChartData,
        options: {
          onHover: (evt, element) => {
            if (element.length > 0) {
              const chart = element[0]._chart;
              const el = chart.getElementAtEvent(evt)[0];
              const dataset = chart.data.datasets[el._datasetIndex];
              const xLabel = chart.data.labels[el._index];
              const value = dataset.data[el._index];
              setPercentage(
                `${xLabel} : ${total ? ((
                  (value / htmlDecodePound(total).replace('£', '')) *
                  100
                ).toFixed(2)) + '%' : ''}`,
              );
            } else {
              setPercentage(null);
            }
          },
          plugins: {
            labels: {
              render: 'label',
              fontColor: '#000',
              position: 'outside',
              textMargin: 6,
              render: function (args) {
                if (args.percentage > 2) {
                  return args.label;
                } else {
                  return '';
                }
              },
            },
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');
              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  var style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();
              const labelColor = tooltipModel.labelColors[0].backgroundColor;
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.background = 'black';
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding =
                tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            },
            callbacks: {
              label: function (tooltipItem, data) {
                const value =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                return `${data.labels[tooltipItem.index]} :  ${total ? ((
                  (value / parseFloat(htmlDecodePound(total).replace('£', '').replace(/,/g, ''))) *
                  100
                ).toFixed(2) + '% : ') : ''} ${formatToCurrency(value)}`;
              },
            },
            backgroundColor: '#fff',
            titleFontSize: 16,
            titleFontColor: '#0066ff',
            bodyFontColor: '#000',
            bodyFontSize: 14,
            displayColors: true,
            position: 'nearest',
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
              },
            ],
          },
        },
      });
      setChartInstance(myChart);
    }
  }, [data, chartRef.current]);
  return (
    <StyledCard>
      <h2 className="text-uppercase">{title}</h2>
      <TotalsContainer>
        <StyledTotal>{htmlDecodePound(total)}</StyledTotal>
        {/* <StyledTotal>{percentage}</StyledTotal> */}
      </TotalsContainer>
      <canvas ref={chartRef} id={`myChart-${id}`} width="600" height="350" />
    </StyledCard>
  );
};

export default BasketOverviewChart;
