import { active } from 'glamor';

/* eslint-disable */
Date.prototype.getWeekNumber = function() {
  var d = new Date(+this);
  d.setHours(0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  let weekNumber = Math.ceil(
    ((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7 + 1) / 7,
  );
  weekNumber = weekNumber < 10 ? '0' + weekNumber : weekNumber;
  return weekNumber;
};

/*
 * Requires jquery ui
 */
(function($) {
  // function selectCurrentWeek($calInst) {
  //     window.setTimeout(function () {
  //         var t = $calInst.find('.ui-datepicker-current-day a');//.addClass('ui-state-active');
  // 		t= t.closest('tr');
  // 		t.find('td>a').addClass('ui-state-active');//.parent().addClass('ui-state-active');
  //     }, 1);

  // }
  function onSelect(dateText, inst, $calInst, extras) {
    let date = $calInst.datepicker('getDate');
    let startDate = null;
    let endDate = null;
    if ($calInst.hasClass('startDate')) {
      startDate = date;
      if (
        extras &&
        extras.endDate &&
        $(extras.endDate).datepicker('getDate') <= startDate
      ) {
        endDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - date.getDay() + 7,
        );
      }
    } else if ($calInst.hasClass('endDate')) {
      endDate = date;
      if (
        extras &&
        extras.startDate &&
        $(extras.startDate).datepicker('getDate') >= endDate
      ) {
        startDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - date.getDay() - 6,
        );
      }
    }
    $calInst.trigger('weekselected', {
      start: startDate,
      end: endDate,
    });
    $calInst.trigger('blur');
  }

  $.fn.weekpicker = function(options, extras) {
    extras = extras || null;
    var $this = this;

    $this.each(function(i, e) {
      /* Set required options */
      var activeDays = ($(e).data('active-days') + '').split(',');
      var reqOpt = {
        /* Pass this current instance to selection */
        onSelect: function(dateText, inst) {
          onSelect(dateText, inst, $(e), extras);
        },
        showOtherMonths: true,
        selectOtherMonths: true,
        firstDay: 1,
        beforeShowDay: function(date) {
          if (
            activeDays.length === 0 ||
            activeDays.includes(date.getDay() + '')
          ) {
            return [true, ''];
          } else {
            return [false, ''];
          }
        },
      };
      var opts = $.extend(reqOpt, options);

      /* Init date picker */
      if ($(e.datepicker)) {
        $(e).datepicker(opts);
      }
      //events

      // const $dprow = $(e).parent().find('.ui-datepicker');
      // $dprow.on('mousemove','tr', function() {
      // 	$(this).addClass('ui-state-hover');
      // 	// $(this).find('td a').addClass('ui-state-hover');
      // });
      // $dprow.on('mouseleave','tr', function() {
      // 	$(this).removeClass('ui-state-hover');
      // 	// $(this).find('td a').removeClass('ui-state-hover');
      // });
    });
  };
})(jQuery);
